<div class="row">
  <div class="col-12">
    <form
      novalidate
      [formGroup]="form"
      [ngBusy]="[busy.load]"
      (validSubmit)="onValidSubmit()"
    >
      <!-- Grid -->
      <div>
        <ag-grid-angular
          #agGrid
          class="ag-theme-material"
          [gridOptions]="distributionGrid.gridOptions"
          [rowData]="distributionRows"
          (cellEditingStarted)="distributionGrid.cellEditingStarted($event)"
          (cellValueChanged)="distributionGrid.onValueChanged($event)"
        />
      </div>

      <button
        class="btn btn-secondary mt-1"
        type="button"
        (click)="addRows(DEFAULT_NUMBER_OF_ROWS)"
      >
        Add Lines
      </button>

      <small class="form-text text-muted float-right">
        *Will distribute even if there is a loss
      </small>

      <!-- New Distribution Account Cell -->
      <ng-template #newDistributionAccountCell let-row>
        <a
          ngbPopover="This account number and name combination has not been seen before. If you proceed, this account will be created by Access Ledger."
          placement="right"
          popoverTitle="New Account"
          *ngIf="
            row?.distributionSourceAccount &&
            !row.distributionSourceAccount.id &&
            row.distributionSourceAccount.accountName
          "
          [routerLink]="[]"
        >
          <span class="badge badge-danger">NEW</span>
        </a>
      </ng-template>

      <!-- New Account Cell -->
      <ng-template #newAccountCell let-row>
        <a
          ngbPopover="This account number and name combination has not been seen before. If you proceed, this account will be created by Access Ledger."
          placement="right"
          popoverTitle="New Account"
          *ngIf="
            row?.sourceAccount &&
            !row.sourceAccount.id &&
            row.sourceAccount.accountName
          "
          [routerLink]="[]"
        >
          <span class="badge badge-danger">NEW</span>
        </a>
      </ng-template>

      <!-- Account Search Result-->
      <ng-template #accountSearchTemplate let-result="result" let-term="term">
        <ngb-highlight
          [result]="
            (result.accountNo ? result.accountNo + '  ' : '') +
            result.accountName
          "
          [term]="term"
        />
      </ng-template>

      <!-- Classification Result-->
      <ng-template #classificationTemplate let-result="result" let-term="term">
        <ngb-highlight
          [result]="distributionGrid.classifications[result]"
          [term]="term"
        />
      </ng-template>

      <!-- Losses Tick Box -->
      <ng-template #lossesCheckbox let-row>
        <div
          class="btn-mini-menu w-100 d-flex justify-content-center"
          *ngIf="row"
        >
          <input
            title="Losses checkbox"
            type="checkbox"
            [checked]="!row.excludeIfLoss"
            (click)="onClickToggleLoss(row)"
          />
        </div>
      </ng-template>

      <!-- Delete Column-->
      <ng-template #optionsCell let-row>
        <div class="btn-mini-menu" *ngIf="row">
          <button
            class="btn-sm fas-btn-ghost"
            title="Delete Row"
            type="button"
            (click)="onClickDeleteRow(row)"
          >
            <i aria-hidden="true" class="fas fa-trash"></i>
          </button>
        </div>
      </ng-template>

      <div class="spacer-md"></div>

      <crs-form-error [error]="error" />

      <div class="btn-menu">
        <button
          class="btn btn-primary"
          type="submit"
          [promiseBtn]="busy.submit"
        >
          Save
        </button>
        <button
          class="btn btn-delete"
          title="Clear All"
          type="button"
          [promiseBtn]="busy.delete"
          (click)="onClickClearAll()"
        >
          <i aria-hidden="true" class="fas fa-trash"></i>
          Clear All
        </button>
      </div>
    </form>
  </div>
</div>
