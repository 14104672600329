import {
  DisclosureLevel,
  DisclosureAutoSelectionMethod,
  DisclosureVariant,
} from '.';

export enum DisclosureSelectionMode {
  Auto = 0,
  Yes = 1,
  No = 2,
}

export class Disclosure {
  id: number;
  disclosureTemplateChainId: string;
  fileId: string;
  name: string;
  level: DisclosureLevel;
  masterTemplateId: string;
  autoSelectionMethod: DisclosureAutoSelectionMethod;
  disclosureVariants: DisclosureVariant[];
  sortOrder: string;

  defaultDisclosureVariant: DisclosureVariant;
  selectedDisclosureVariantId: string;
  selectionMode: DisclosureSelectionMode;
  customise: boolean;
  content: any[];

  /**
   * Content of the linked Disclosure Variant, added dynamically by UI in report templates screen when requested
   */
  currentDisclosureVariant: DisclosureVariant;

  /**
   * Returns the selected variant if one is selected, or the default one if none is selected
   */
  getActiveVariant() {
    if (
      this.disclosureVariants &&
      this.disclosureVariants.length &&
      this.selectedDisclosureVariantId
    ) {
      return (
        this.disclosureVariants.find(
          (v) => v.id === this.selectedDisclosureVariantId
        ) ?? this.defaultDisclosureVariant
      );
    }
    return this.defaultDisclosureVariant;
  }

  constructor(data) {
    Object.assign(this, data);
    if (data.disclosureVariants)
      this.disclosureVariants = data.disclosureVariants.map(
        (v) => new DisclosureVariant(v)
      );
    if (data.defaultDisclosureVariant)
      this.defaultDisclosureVariant = new DisclosureVariant(
        data.defaultDisclosureVariant
      );
  }
}

export class DisclosureModel {
  disclosureTemplateChainId: string;
  selectionMode: DisclosureSelectionMode;
  selectedDisclosureVariantId: string;
  customise: boolean;
  content: any[];

  constructor(data) {
    this.disclosureTemplateChainId = data.disclosureTemplateChainId;
    this.selectionMode = data.selectionMode;
    this.selectedDisclosureVariantId = data.selectedDisclosureVariantId;
    this.customise = data.customise;
    this.content = !data.customise ? [] : data.content;
  }
}
