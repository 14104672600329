<div class="row">
  <div class="col-12 mb-4">
    <a
      type="button"
      class="btn btn-primary"
      [routerLink]="['/baco/feed-create']"
    >
      Create Feed
    </a>
  </div>
  <div class="col-6">
    <div class="mb-3"><strong>Feeds</strong></div>
    <ng-container *ngIf="feeds$ | async as feeds">
      <div class="mb-3" *ngFor="let feed of feeds">
        {{ feed.name }}
        <button
          type="button"
          class="btn btn-primary ms-3"
          (click)="selectFeed(feed)"
        >
          See Accounts
        </button>
        <a
          type="button"
          class="btn btn-primary ms-3"
          target="_blank"
          [routerLink]="['/baco/feed', feed.id, 'transactions']"
          [queryParams]="{
            feedId: feed.id,
            startDate: '2021-01-01',
            endDate: '2024-01-01',
          }"
        >
          Open
        </a>
        <button
          type="button"
          class="btn btn-primary ms-3"
          (click)="archiveFeed(feed)"
        >
          Archive
        </button>
      </div>
    </ng-container>
  </div>
  <div class="col-6" *ngIf="bankAccounts$ | async as bankAccounts">
    <div class="mb-3">
      <strong>Accounts for {{ selectedFeed.name }}</strong>
    </div>
    <ng-container>
      <div class="mb-3" *ngFor="let bankAccount of bankAccounts">
        {{ bankAccount.accountName }} -
        {{ bankAccount.accountNumber }}
        <a
          type="button"
          class="btn btn-primary ms-3"
          target="_blank"
          [routerLink]="['/baco/feed', selectedFeed.id, 'transactions']"
          [queryParams]="{
            feedId: selectedFeed.id,
            bankId: bankAccount.id,
            startDate: '2021-01-01',
            endDate: '2024-01-01',
          }"
        >
          Open
        </a>
        <button
          type="button"
          class="btn btn-primary ms-3"
          (click)="createMoreTransactions(bankAccount.id)"
        >
          Create More Transactions
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          (click)="createBankAccount(selectedFeed.id)"
        >
          Create new random bank account
        </button>
      </div>
    </ng-container>
  </div>
</div>
