<div style="overflow: hidden; flex-grow: 1">
  <crs-busy *ngIf="busy.accounts" />

  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    style="width: 100%; height: 100%"
    [gridOptions]="gridOptions"
    [rowData]="accounts"
  />
</div>

<ng-template #optionsCell let-row>
  <div class="btn-mini-menu">
    <a
      aria-haspopup="true"
      class="btn-sm fas-btn-primary-ghost"
      data-bs-toggle="dropdown"
    >
      <i aria-hidden="true" class="fas fa-ellipsis-v"></i>
      <span class="sr-only">Show Account Options</span>
    </a>
    <div class="dropdown-menu">
      <button
        class="btn dropdown-item text-info"
        type="button"
        (click)="onClickEditAccount(row)"
      >
        Edit Account
      </button>
      <button
        class="btn dropdown-item text-danger"
        type="button"
        *ngIf="row.isSortable"
        (click)="onClickDeleteAccount(row)"
      >
        Delete Account
      </button>
    </div>
  </div>
</ng-template>
