<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/central'">Central</a></li>
    <li aria-current="page" class="breadcrumb-item active">Databases</li>
  </ol>
</nav>

<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.databases"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <!-- <div class="btn-menu">
      <button type="button" class="btn btn-secondary" title="Create Firm" (click)="addDatabase()">
        <i class="fas fa-plus inset" aria-hidden="true"></i>
        New Firm
      </button>
    </div> -->

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="databases$ | async"
        (rowClicked)="selectDatabase($event)"
      >
        <ag-grid-column field="id" headerName="Id"></ag-grid-column>
        <ag-grid-column field="name" headerName="Name"></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>
