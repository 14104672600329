import { Pipe, PipeTransform } from '@angular/core';
import { StringHelpers } from '../utilities/string-helpers';
import {IListItem} from '../interfaces';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

  transform(value: any, notConvertLabel?: boolean): IListItem[] {
    const keys = Object.keys(value);
    const values = keys.length / 2;
    const array: IListItem[] = [];
    for (let i = 0; i < values; i++) {
      const converted = parseInt(keys[i], 10);
      if (!isNaN(converted)) {
        array.push({ value: converted, name: this._getName(keys[i + values], notConvertLabel) });
      } else {
        array.push({ value: keys[i], name: this._getName(keys[i + values], notConvertLabel) });
      }
    }
    return array;
  }

  private _getName(keyItem: string, notConvertLabel?: boolean): string {
    return notConvertLabel ? keyItem : StringHelpers.convertToSentence(keyItem);
  }

}
