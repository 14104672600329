<div class="card">
  <div
    class="card-header card-header-collapsible form-group-title card-header-split"
    data-bs-toggle="collapse"
    [attr.data-bs-target]="'#depreciation-record-content-' + formSet.year.year"
    [attr.aria-expanded]="!formSet.expanded"
    [attr.aria-controls]="'#depreciation-record-content-' + formSet.year.year"
    (click)="formSet.expanded = !formSet.expanded"
  >
    <div class="card-header-left">
      {{ formSet.year.year }}
    </div>

    <div class="card-header-center px-2">
      <span *ngIf="recordSummary.identical">
        {{ recordSummary.taxationText | sentence }}
      </span>

      <span *ngIf="!recordSummary.identical">
        {{ recordSummary.taxationText | sentence }} |
        {{ recordSummary.accountingText | sentence }}
      </span>
    </div>

    <div class="card-header-right">
      <i class="fas fa-caret-down" aria-hidden="true"></i>
    </div>
  </div>
  <div
    [id]="'depreciation-record-content-' + formSet.year.year"
    class="collapse show"
    [attr.aria-labelledby]="'#depreciation-record-content-' + formSet.year.year"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-label"></div>
        <div class="col-control col-header">Taxation</div>
        <div class="col-toggle"></div>
        <div class="col-control col-header">Accounting</div>
      </div>

      <!-- Inherited -->
      <div class="row" *ngFor="let fieldSet of [fieldSets.inherited]">
        <label class="col-label col-form-label"> Inherited </label>
        <div class="col-control">
          <div
            class="form-check text-center"
            *ngIf="fieldSet.showTaxation"
            [formGroup]="taxation"
          >
            <input
              type="checkbox"
              class="form-check-input"
              [formControlName]="fieldSet.field"
              title="Inherited"
            />
          </div>
        </div>
        <div class="col-toggle">
          <crs-asset-difference-toggle
            [key]="fieldSet.field"
            [parent]="component"
            *ngIf="fieldSet.showBoth"
          ></crs-asset-difference-toggle>
        </div>
        <div class="col-control">
          <div
            class="form-check text-center"
            *ngIf="fieldSet.showAccounting"
            [formGroup]="accounting"
          >
            <input
              type="checkbox"
              class="form-check-input"
              [formControlName]="fieldSet.field"
              disabled
              *ngIf="!toggles[fieldSet.field] && fieldSet.showBoth"
              title="Inherited"
            />
            <input
              type="checkbox"
              class="form-check-input"
              [formControlName]="fieldSet.field"
              *ngIf="toggles[fieldSet.field] || !fieldSet.showBoth"
              title="Inherited"
            />
          </div>
        </div>
      </div>

      <!-- Depreciation Method -->
      <ng-container *ngFor="let fieldSet of [fieldSets.depreciationMethod]">
        <div class="row required" *ngIf="!fieldSet.showNone">
          <label class="col-label col-form-label"> Depreciation Method </label>
          <div class="col-control">
            <div
              class="form-group"
              *ngIf="fieldSet.showTaxation"
              [formGroup]="taxation"
            >
              <ng-select
                [items]="depreciationMethods | enumToArray"
                bindValue="value"
                bindLabel="name"
                [formControlName]="fieldSet.field"
                [clearable]="false"
              ></ng-select>
            </div>
          </div>
          <div class="col-toggle">
            <crs-asset-difference-toggle
              [key]="fieldSet.field"
              [parent]="component"
              *ngIf="fieldSet.showBoth"
            ></crs-asset-difference-toggle>
          </div>
          <div class="col-control">
            <div
              class="form-group"
              *ngIf="fieldSet.showAccounting"
              [formGroup]="accounting"
            >
              <ng-select
                [items]="depreciationMethods | enumToArray"
                bindValue="value"
                bindLabel="name"
                [formControlName]="fieldSet.field"
                [clearable]="false"
                [readonly]="!toggles[fieldSet.field] && fieldSet.showBoth"
              ></ng-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Rate -->
      <ng-container *ngFor="let fieldSet of [fieldSets.rateEditable]">
        <div class="row required" *ngIf="!fieldSet.showNone">
          <label class="col-label col-form-label">Rate</label>
          <div class="col-control">
            <div *ngIf="fieldSet.showTaxation" [formGroup]="taxation">
              <div class="form-group mb-1">
                <div class="input-group">
                  <input
                    class="form-control"
                    currencyMask
                    [formControlName]="fieldSet.field"
                    title="Rate"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div class="form-check mb-3">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="isRateSelfAssessed"
                  title="Is the rate self assessed?"
                />
                Is the rate self assessed?
              </div>
            </div>
          </div>
          <div class="col-toggle">
            <crs-asset-difference-toggle
              [key]="fieldSet.field"
              [parent]="component"
              *ngIf="fieldSet.showBoth"
            ></crs-asset-difference-toggle>
          </div>
          <div class="col-control">
            <div
              class="form-group"
              *ngIf="fieldSet.showAccounting"
              [formGroup]="accounting"
            >
              <div class="input-group">
                <input
                  class="form-control"
                  currencyMask
                  [formControlName]="fieldSet.field"
                  [readOnly]="!toggles[fieldSet.field] && fieldSet.showBoth"
                  title="Percentage"
                />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Depreciation Pool -->
      <ng-container *ngFor="let fieldSet of [fieldSets.depreciationPool]">
        <div class="row required" *ngIf="!fieldSet.showNone">
          <label class="col-label col-form-label"> Depreciation Pool </label>
          <div class="col-control">
            <div
              class="form-group"
              *ngIf="fieldSet.showTaxation"
              [formGroup]="taxation"
            >
              <ng-select
                [items]="depreciationPools | enumToArray"
                bindValue="value"
                bindLabel="name"
                [formControlName]="fieldSet.field"
              ></ng-select>
            </div>
          </div>
          <div class="col-toggle">
            <crs-asset-difference-toggle
              [key]="fieldSet.field"
              [parent]="component"
              *ngIf="fieldSet.showBoth"
            ></crs-asset-difference-toggle>
          </div>
          <div class="col-control">
            <div
              class="form-group"
              *ngIf="fieldSet.showAccounting"
              [formGroup]="accounting"
            >
              <ng-select
                [items]="depreciationPools | enumToArray"
                bindValue="value"
                bindLabel="name"
                [formControlName]="fieldSet.field"
                [readonly]="!toggles[fieldSet.field] && fieldSet.showBoth"
              ></ng-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Manual Depreciation -->
      <ng-container *ngFor="let fieldSet of [fieldSets.manualDepreciation]">
        <div class="row required" *ngIf="!fieldSet.showNone">
          <label class="col-label col-form-label"> Depreciation Amount </label>
          <div class="col-control">
            <div
              class="form-group"
              *ngIf="fieldSet.showTaxation"
              [formGroup]="taxation"
            >
              <input
                class="form-control"
                currencyMask
                [formControlName]="fieldSet.field"
                title="Depreciation Amount"
              />
            </div>
          </div>
          <div class="col-toggle">
            <crs-asset-difference-toggle
              [key]="fieldSet.field"
              [parent]="component"
              *ngIf="fieldSet.showBoth"
            ></crs-asset-difference-toggle>
          </div>
          <div class="col-control">
            <div
              class="form-group"
              *ngIf="fieldSet.showAccounting"
              [formGroup]="accounting"
            >
              <input
                class="form-control"
                currencyMask
                [formControlName]="fieldSet.field"
                [readOnly]="!toggles[fieldSet.field] && fieldSet.showBoth"
                title="Depreciation Amount"
              />
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Private Percentage -->
      <ng-container *ngFor="let fieldSet of [fieldSets.privatePercentEditable]">
        <div class="row" *ngIf="fieldSet.showTaxation">
          <label class="col-label col-form-label">Private %</label>
          <div class="col-control">
            <div class="form-group" [formGroup]="taxation" *ngIf="taxation">
              <div class="input-group">
                <input
                  class="form-control"
                  currencyMask
                  [formControlName]="fieldSet.field"
                  title="Private Percentage"
                />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Residual Value -->
      <ng-container *ngFor="let fieldSet of [fieldSets.residualValue]">
        <div class="row" *ngIf="!fieldSet.showNone">
          <label class="col-label col-form-label">Residual Value</label>
          <div class="col-control"></div>
          <div class="col-toggle"></div>
          <div class="col-control">
            <div
              class="form-group"
              *ngIf="fieldSet.showAccounting"
              [formGroup]="accounting"
            >
              <input
                class="form-control"
                currencyMask
                [formControlName]="fieldSet.field"
                title="Residual Value"
              />
            </div>
          </div>
        </div>
      </ng-container>

      <div class="spacer"></div>

      <ng-container
        *ngFor="let fieldSet of [fieldSets.carryingAmountAdjustment]"
      >
        <ng-container *ngIf="!fieldSet.showNone">
          <strong class="text-muted">Advanced</strong>
          <hr class="mt-0" />

          <!-- Carrying Amount Adjustment -->
          <div class="row">
            <label class="col-label col-form-label">
              Adjustment to Opening Carrying Amount
            </label>
            <div class="col-control">
              <div
                class="form-group"
                *ngIf="fieldSet.showTaxation"
                [formGroup]="taxation"
              >
                <input
                  class="form-control"
                  currencyMask
                  [formControlName]="fieldSet.field"
                  title="Adjustment to Opening Carrying Amount"
                />
              </div>
            </div>
            <div class="col-toggle">
              <crs-asset-difference-toggle
                [key]="fieldSet.field"
                [parent]="component"
                *ngIf="fieldSet.showBoth"
              ></crs-asset-difference-toggle>
            </div>
            <div class="col-control">
              <div
                class="form-group"
                *ngIf="fieldSet.showAccounting"
                [formGroup]="accounting"
              >
                <input
                  class="form-control"
                  currencyMask
                  [formControlName]="fieldSet.field"
                  [readOnly]="!toggles[fieldSet.field] && fieldSet.showBoth"
                  title="Adjustment to Opening Carrying Amount"
                />
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
