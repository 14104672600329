import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { ReportingSuiteService } from '../';
import { MessageService } from '../../../../../core';
import {
  getDefaultGridOptions,
  accountingRenderers,
} from '../../../../../shared';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-reporting-suites',
  templateUrl: './reporting-suites.component.html',
})
export class ReportingSuitesComponent implements OnInit {
  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    reportingSuites: false,
  };
  reportingSuitesObservable: Observable<any>;

  gridOptions: GridOptions;
  renderers = accountingRenderers;

  constructor(
    private readonly _reportingSuiteService: ReportingSuiteService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService
  ) {}

  ngOnInit() {
    this.configureGridOptions();
    this.getReportingSuites();
  }

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      suppressCellFocus: true,
      columnDefs: [
        {
          cellRenderer: 'badgeRenderer',
          field: 'name',
          headerName: 'Name',
          cellRendererParams: { badgeText: 'New', monthsToCheck: 3 },
          flex: 1.5,
        },
        {
          field: 'reportingFramework',
          headerName: 'Reporting Framework',
          maxWidth: 300,
        },
        {
          field: 'applicationDate',
          headerName: 'Application Date',
          type: 'dateColumn',
          maxWidth: 200,
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 3,
        },
        {
          field: 'isMaster',
          headerName: 'Master',
          type: 'booleanColumn',
          cellClass: ['boolean-cell', 'centered', 'text-success'],
          maxWidth: 100,
          minWidth: 100,
        },
      ],
    };
  }

  getReportingSuites() {
    this.busy.reportingSuites = true;
    this.reportingSuitesObservable = this._reportingSuiteService.getAll().pipe(
      tap(() => (this.busy.reportingSuites = false)),
      catchError((err) => {
        this.showError(err);
        return of([]);
      })
    );
  }

  addReportingSuite() {
    this._router.navigate(['./add'], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectReportingSuite(param) {
    this._router.navigate(['./' + param.data.id], { relativeTo: this._route });
  }
}
