import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/accounting';
import { ActiveFileService } from 'src/app/accounting/active-file.service';
import { PlaceholderType, STANDARD_FORMULA } from './standard-formula.const';
import { MatChipGrid } from '@angular/material/chips';

@Component({
  selector: 'crs-financial-formula-editor',
  templateUrl: './financial-formula-editor.component.html',
  styleUrls: ['./financial-formula-editor.component.scss'],
})
export class FinancialFormulaEditorComponent implements OnInit {
  @Input() params: { formula: string };

  @ViewChild('matChipList')
  private _matChipList: MatChipGrid;

  public busy = {
    load: null,
  };
  public error: string;
  public isFileSelected = false;

  public placeholderTypes = [
    PlaceholderType.StandardFormula,
    PlaceholderType.MasterAccountId,
    PlaceholderType.AccountId,
  ];
  public standardFormulaPlaceholders = STANDARD_FORMULA;

  public editingIndex: number = null;
  public editingPreviousReplaceLength: number = null;

  public masterAccounts: any[];
  public accounts: any[];

  public placeholderRegex = /[^{}]*(?=\})/g;

  public leftChipRegex = /<mat-chip.+?>/g;
  public rightChipRegex = /<i.+?<\/mat-chip>/g;
  public highlightPlaceholderRegex = / style="background: whitesmoke;"/g;

  public form = this._formBuilder.group({
    safeFormulaHtml: [null, Validators.required],
    selectedPlaceholderType: [],
    placeholderValue: [],
  });

  private _caretPosition: number = null;

  public get editableHtml(): string {
    return this._matChipList['_elementRef'].nativeElement.innerHTML;
  }

  constructor(
    private _activeFileService: ActiveFileService,
    private _activeModal: NgbActiveModal,
    private _accountService: AccountService,
    private _formBuilder: UntypedFormBuilder,
    private _sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngOnInit() {
    this.isFileSelected =
      this._activeFileService.file &&
      this._activeFileService.file !== null &&
      this._activeFileService.file.id !== undefined;

    if (!this.isFileSelected) {
      this.placeholderTypes = [
        PlaceholderType.StandardFormula,
        PlaceholderType.MasterAccountId,
      ];
      this._accountService.searchMasters(null).subscribe((response) => {
        this.masterAccounts = response.records;
        this.buildFormula();
      });
    } else {
      this.placeholderTypes = [
        PlaceholderType.StandardFormula,
        PlaceholderType.AccountId,
      ];

      this._accountService.searchMasters(null).subscribe((response) => {
        this.masterAccounts = response.records;

        this._accountService
          .searchAccounts$({
            fileId: this._activeFileService.file.id,
            search: '',
            includeChildren: false,
            page: 1,
            pageSize: 250,
          })
          .subscribe((response) => {
            this.accounts = response.records;
            this.buildFormula();
          });
      });
    }
  }

  public removePlaceholder(html) {
    this.resetEditing();

    const formula = this.editableHtml
      .replace(html, '')
      .replace(this.highlightPlaceholderRegex, '');

    this.form.controls['safeFormulaHtml'].setValue(
      this._sanitizer.bypassSecurityTrustHtml(formula)
    );
  }

  public onAddPlaceholder() {
    let value = '';

    const placeholderValue = this.form.controls['placeholderValue'].value;
    value = placeholderValue.accountName
      ? `${this.form.controls['selectedPlaceholderType'].value}:${placeholderValue.accountName}:${placeholderValue.id}`
      : placeholderValue;

    this.form.controls['selectedPlaceholderType'].setValue(null);
    this.form.controls['placeholderValue'].setValue(null);

    if (this.editingIndex !== null) {
      this.updatePlaceholder(value);
      return;
    }

    if (this._caretPosition !== null) {
      this.insertPlaceholderAtCaret(value);
      return;
    }

    const formulaHtml = `${this.editableHtml} ${this.buildChipTemplate(value)}`;
    this.updateFormulaHtml(formulaHtml);
  }

  public onFormulaChange() {
    const selection = this._document.getSelection();

    const clickedOnRemove = selection.anchorNode.nodeName === 'I';
    if (clickedOnRemove) {
      this.removePlaceholder(selection.anchorNode.parentElement.outerHTML);
      return;
    }

    const clickedOnChip =
      selection.anchorNode.parentElement.nodeName === 'MAT-CHIP';
    if (clickedOnChip) {
      const chip = selection.anchorNode.parentElement;
      const currentValue = chip.innerText;

      const unHighlightedChipHtml = chip['outerHTML'];
      chip.style.background = 'whitesmoke';
      const highlightedChipHtml = chip['outerHTML'];

      const chipIndex = this.editableHtml.indexOf(chip['outerHTML']);

      this.editingIndex =
        chipIndex + chip['outerHTML'].split(currentValue)[0].length;
      this.editingPreviousReplaceLength = currentValue.length;

      this.onPlaceholderSelected(currentValue);
      selection.empty();

      const newHtml = this.editableHtml
        .replace(this.highlightPlaceholderRegex, '')
        .replace(unHighlightedChipHtml, highlightedChipHtml);
      this.updateFormulaHtml(newHtml);
      return;
    }

    const previousChip = selection.anchorNode.previousSibling;
    const previousChipIndex = previousChip
      ? this.editableHtml.indexOf(previousChip['outerHTML'])
      : 0;
    const previousChipLength = previousChip
      ? previousChip['outerHTML'].length
      : 0;

    this._caretPosition =
      previousChipIndex + previousChipLength + selection.anchorOffset;
  }

  public onPlaceholderSelected(value: string) {
    const placeholderType = this.getPlaceholderType(value);
    this.form.controls['selectedPlaceholderType'].setValue(placeholderType);

    if (placeholderType === PlaceholderType.StandardFormula) {
      this.form.controls['placeholderValue'].setValue(value);
      return;
    }

    this.form.controls['placeholderValue'].setValue({
      accountName: value.split(':')[1],
    });
  }

  public onCancelEdit() {
    this.resetEditing();
    this.updateFormulaHtml(
      this.editableHtml.replace(this.highlightPlaceholderRegex, '')
    );
  }

  public resetEditing() {
    this.editingIndex = null;
    this.editingPreviousReplaceLength = null;

    this.form.controls['selectedPlaceholderType'].setValue(null);
    this.form.controls['placeholderValue'].setValue(null);
  }

  public submit() {
    this._activeModal.close(this.parseFormula());
  }

  public cancel() {
    this._activeModal.dismiss();
  }

  private parseFormula(): string {
    let formulaModel = this.editableHtml
      .replace(this.leftChipRegex, '{')
      .replace(this.rightChipRegex, '}');

    let placeholders = formulaModel.match(this.placeholderRegex);
    if (!placeholders) {
      return formulaModel;
    }

    placeholders = Array.from(placeholders) as RegExpMatchArray;
    for (let i = 0; i < placeholders.length; i++) {
      const element = placeholders[i];

      if (!element.includes(':')) {
        continue;
      }

      const placeholderType = element.split(':')[0] as PlaceholderType;
      const elementName = element.split(':')[1];
      const elementId = element.split(':')[2]?.trim();
      const sourceAccounts =
        placeholderType === PlaceholderType.MasterAccountId
          ? this.masterAccounts
          : this.accounts;

      const account = elementId
        ? sourceAccounts.find(
            (a) => a.accountName === elementName && a.id == elementId
          )
        : sourceAccounts.find((a) => a.accountName === elementName);

      const parsedPlaceholder = `${placeholderType}:${account.id}`;
      formulaModel = formulaModel.replace(element, parsedPlaceholder);
    }

    return formulaModel;
  }

  private buildFormula() {
    let formula = this.params.formula?.trim() ?? '';

    const placeholders =
      this.params?.formula?.match(this.placeholderRegex) ?? null;

    if (placeholders) {
      placeholders
        .filter((c) => c)
        .map((placeholderWithType) => {
          let val = placeholderWithType;
          const placeholderType = this.getPlaceholderType(placeholderWithType);

          if (placeholderType === PlaceholderType.StandardFormula) {
            formula = formula.replace(`{${val}}`, this.buildChipTemplate(val));
          }

          if (placeholderType === PlaceholderType.MasterAccountId) {
            const masterAccount = this.masterAccounts.find(
              (a) => a.id === placeholderWithType.split(':')[1].trim()
            );
            val = `${PlaceholderType.MasterAccountId}:${masterAccount.accountName}:${masterAccount.id}`;
            formula = formula.replace(
              `{${placeholderWithType}}`,
              this.buildChipTemplate(val)
            );
          }

          if (placeholderType === PlaceholderType.AccountId) {
            const account = this.accounts.find(
              (a) => a.id === placeholderWithType.split(':')[1]
            );

            val = `${PlaceholderType.AccountId}:${account.accountName}`;

            formula = formula.replace(
              `{${placeholderWithType}}`,
              this.buildChipTemplate(val)
            );
          }

          return val;
        });
    }

    this.updateFormulaHtml(formula);
  }

  private updatePlaceholder(value: string) {
    const leftValue = this.editableHtml.substring(0, this.editingIndex);
    const rightValue = this.editableHtml.substring(
      this.editingIndex + this.editingPreviousReplaceLength,
      this.editableHtml.length
    );

    const formula = `${leftValue}${value}${rightValue}`;

    this.editingPreviousReplaceLength = null;
    this.editingIndex = null;

    this.updateFormulaHtml(formula.replace(this.highlightPlaceholderRegex, ''));
  }

  private insertPlaceholderAtCaret(value: string) {
    const leftValue = this.editableHtml.substring(0, this._caretPosition);
    const rightValue = this.editableHtml.substring(
      this._caretPosition,
      this.editableHtml.length
    );

    const formula = `${leftValue} ${this.buildChipTemplate(
      value
    )} ${rightValue}`;
    this._caretPosition = null;

    this.updateFormulaHtml(formula);
  }

  private updateFormulaHtml(htmlFormula: string) {
    this.form.controls['safeFormulaHtml'].setValue(
      this._sanitizer.bypassSecurityTrustHtml(htmlFormula)
    );
  }

  private getPlaceholderType(placeholderWithType: string) {
    return placeholderWithType.includes(':')
      ? (placeholderWithType.split(':')[0] as PlaceholderType)
      : PlaceholderType.StandardFormula;
  }

  private buildChipTemplate(value: string): string {
    const leftChipTemplate = ` <mat-chip color="secondary" selected="true"
      chip-value="${new Date().getMilliseconds()}">`;
    const rightChipTemplate = `<i class="fas fa-times ms-2 mt-1" aria-hidden="true" style="font-size: 0.8em;"></i></mat-chip> `;

    return `${leftChipTemplate}${value}${rightChipTemplate}`;
  }
}
