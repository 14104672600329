import { Component, Injector, OnInit } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { BacoReportService } from './services/baco-report.service';
import { ActivatedRoute } from '@angular/router';
import { OVERLAY_DATA } from '../../../../core';
import { ReportViewerParams } from '../../../../accounting/reports/reportViewer/models';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BACO_REPORT_TYPES } from '../main-menu';
import { BacoReportEnum } from '../../../enums';
import { ReportViewerComponent } from 'src/app/accounting/reports/reportViewer/report-viewer/report-viewer.component';
import { ApiError } from 'src/app/core/interceptors/api-error';

@Component({
  selector: 'crs-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss'],
  providers: [BacoReportService],
})
export class ReportPageComponent implements OnInit {
  public reportPortal$: Observable<ComponentPortal<ReportViewerComponent>>;
  public isLoading = false;
  public errorMessage: string;

  constructor(
    private bacoReportService: BacoReportService,
    private injector: Injector,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.reportPortal$ = this.route.params.pipe(
      tap(() => {
        this.errorMessage = null;
        this.isLoading = true;
      }),
      switchMap((params) => this.processParamsAndGetReport(params)),
      tap(() => (this.isLoading = false))
    );
  }

  private processParamsAndGetReport(params: any): Observable<any> {
    const reportStringKey = params['report-key'];
    const report = this.getReportFromKey(reportStringKey);

    if (!report) {
      this.errorMessage = 'Report path is wrong. Please check again.';
      return of(null);
    }

    return this.bacoReportService.get$(report).pipe(
      catchError((error) => this.handleError(error)),
      map((report) => this.getComponentPortal(report))
    );
  }

  private getReportFromKey(reportStringKey: string): BacoReportEnum | null {
    if (!reportStringKey) return null;

    for (const [key, value] of Object.entries(BACO_REPORT_TYPES)) {
      if (reportStringKey === value) {
        return parseInt(key) as BacoReportEnum;
      }
    }
    return null;
  }

  private handleError(error: any): Observable<null> {
    if (typeof error === 'string') {
      this.errorMessage = error;
    } else if (error instanceof ApiError) {
      this.errorMessage = error.messageString;
    }
    return of(null);
  }

  private getComponentPortal(
    report: any
  ): ComponentPortal<ReportViewerComponent> | null {
    if (!report) return null;
    const data = new ReportViewerParams({
      report,
      restrictSaveDocument: true,
      shouldUpdateCustomTableAutoTotal: false,
      isBankFeedReport: true,
    });

    return new ComponentPortal<ReportViewerComponent>(
      ReportViewerComponent,
      null,
      Injector.create({
        providers: [{ provide: OVERLAY_DATA, useValue: { data } }],
        parent: this.injector,
      })
    );
  }
}
