<ul class="nav nav-tabs">
  <ng-container *ngFor="let route of routes">
    <li class="nav-item" *ngIf="route.route !== 'reports'; else reportItem">
      <a
        class="nav-link"
        queryParamsHandling="merge"
        routerLinkActive="active"
        [routerLink]="[route.route]"
      >
        {{ route.name }}
      </a>
    </li>
  </ng-container>
</ul>

<ng-template #reportItem>
  <li class="nav-item dropdown">
    <a
      aria-expanded="false"
      aria-haspopup="true"
      class="nav-link dropdown-toggle"
      data-bs-toggle="dropdown"
      href="#"
      id="navbarReportDropdown"
      role="button"
      [class.active]="isReportPage"
    >
      Reports
    </a>
    <div aria-labelledby="navbarReportDropdown" class="dropdown-menu">
      <a
        class="dropdown-item"
        queryParamsHandling="merge"
        routerLinkActive="active"
        *ngFor="let reportRoute of reportRoutes"
        [routerLink]="['reports', reportRoute.route]"
      >
        {{ reportRoute.name }}
      </a>
    </div>
  </li>
</ng-template>
