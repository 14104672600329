import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { TaxCode } from './tax-code';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaxCodeService {
  readonly base: string = 'api/accounting/ledger/tax-codes';

  constructor(private apiService: ApiService) {}

  getAll$(fileId: string): Observable<TaxCode[]> {
    let url = `${this.base}/for-file`;
    url = this.apiService.addQuery(url, 'fileId', fileId);

    return this.apiService
      .get<TaxCode[]>(url)
      .pipe(map((taxCodes) => taxCodes.map((taxCode) => new TaxCode(taxCode))));
  }

  getTaxCode$(taxCodeId: string): Observable<TaxCode> {
    return this.apiService
      .get<TaxCode>(`${this.base}/${taxCodeId}`)
      .pipe(map((taxCode) => new TaxCode(taxCode)));
  }

  postTaxCode$(taxCodeToAdd: TaxCode): Observable<string> {
    return this.apiService.post(this.base, taxCodeToAdd);
  }

  putTaxCode$(taxCodeId: string, taxCodeToUpdate: TaxCode): Observable<{}> {
    return this.apiService.put(`${this.base}/${taxCodeId}`, taxCodeToUpdate);
  }

  deleteTaxCode$(taxCodeId: string): Observable<{}> {
    return this.apiService.delete(`${this.base}/${taxCodeId}`);
  }
}
