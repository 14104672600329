import { RuleMovementType } from "../enums";


export enum AllocationType {
  Amount = 0,
  Percentage = 1
}

export interface BacoRuleAllocation {
  allocationType: AllocationType;
  allocationValue: number;
  bacoAccountId: string;
  bacoTaxOptionId: string | null;
  description: string | null;
}

export interface BacoRuleDto {
  id: string;
  name: string;
  sortOrder: string;
  movementType: RuleMovementType;
  descriptionCondition: RuleStringCondition | null;
  amountCondition: RuleValueCondition | null;
  allocations: BacoRuleAllocation[];
}

export interface BacoRuleAllocationCreateModel {
  allocationType: AllocationType;
  allocationValue: number;
  bacoAccountId: string;
  bacoTaxOptionId: string | null;
  description: string | null;
}

export interface RuleValueCondition {
  conditionOption: ValueConditionOption;
  conditionValue: number;
}

export interface RuleStringCondition {
  conditionOption: StringConditionOption;
  conditionValue: string;
}

export enum StringConditionOption {
  AllWords = 0,
  AnyWords = 1,
  Contains = 2,
  Exact = 3
}

export enum ValueConditionOption {
  LessThan = 0,
  LessThanOrEqual,
  GreaterThan,
  GreaterThanOrEqual,
  EqualTo,
  NotEqualTo
}

export const StringConditionOptionNames = [
  { value: StringConditionOption.AllWords, name: "All of these words" },
  { value: StringConditionOption.AnyWords, name: "Any of these words" },
  { value: StringConditionOption.Contains, name: "Contains" },
  { value: StringConditionOption.Exact, name: "This exact wording" }
];

export const ValueConditionOptionNames = [
  { value: ValueConditionOption.LessThan, name: "Less than" },
  { value: ValueConditionOption.GreaterThan, name: "Greater than" },
  { value: ValueConditionOption.EqualTo, name: "Equal To" },
];

export interface BacoRuleCreateModel {
  movementType: RuleMovementType;
  name: string;
  descriptionConditionOption: StringConditionOption | null;
  descriptionConditionValue: string | null;
  amountConditionOption: ValueConditionOption | null;
  amountConditionValue: number | null;
  allocations: BacoRuleAllocationCreateModel[];
}

export interface BacoLedgerToLink {
  name: string;
  sourceId: string;
  fileName: string;
  sourceSystem: string;
}

export interface BacoAccountToUpsert {
  sourceId: string;
  accountNo: string;
  accountName: string;
  preferredTaxSourceId?: string;
}

export interface BacoTaxOptionToUpsert {
  sourceId: string;
  code: string;
  name: string;
  percentage: number;
  accountSourceId?: string;
}

export interface BacoSyncChartOfAccountsModel {
  accounts: BacoAccountToUpsert[],
  taxOptions: BacoTaxOptionToUpsert[],
}
