import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { BankAccountState } from 'src/app/baco/enums';
import { BacoBankAccountDto } from 'src/app/baco/interfaces';
import { BacoTransactionStore } from '../../baco-transaction.store';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'crs-bank-select',
  templateUrl: './bank-select.component.html',
  styleUrls: ['./bank-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankSelectComponent {
  @Input() clearable = false;
  @Input() readonly = false;

  public bankAccountStates = BankAccountState;
  public bankAccounts$ = this._bacoTransactionStore.bankAccounts$;
  public selectedBank$ = this._bacoTransactionStore.selectedBank$;
  public filteredBankAccounts$: Observable<{
    state: string;
    data: BacoBankAccountDto[];
  }>;

  constructor(private _bacoTransactionStore: BacoTransactionStore) {}

  ngOnInit() {
    // Assuming bankAccounts$ is a BehaviorSubject that emits an object with a 'data' field
    this.filteredBankAccounts$ = this._bacoTransactionStore.bankAccounts$.pipe(
      map((statefulAccounts) => ({
        ...statefulAccounts, // Preserve outer structure and state
        data: statefulAccounts.data.filter((account) => !account.isHidden), // Filter out hidden accounts
      })),
      tap(({ data }) => {
        const selectedBankId = this.selectedBank$.value?.id;
        if (!data.length) {
          return;
        }

        if (!data.find((bankAccount) => bankAccount.id === selectedBankId)) {
          this._bacoTransactionStore.changeSelectedBank(data[0]);
        }
      })
    );
  }

  public onSelectBank(bank: BacoBankAccountDto, event): void {
    this._bacoTransactionStore.changeSelectedBank(bank);
  }
}
