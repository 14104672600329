<span
  class="badge-success"
  title="coded by User"
  *ngIf="codedBy === transactionCodedBy.User"
  >Coded by user</span
>
<span
  class="badge-success coded-by-rule"
  title="coded by Rule"
  *ngIf="codedBy === transactionCodedBy.Rule"
  >Coded by rule</span
>

<span
  class="badge-warning"
  title="uncoded"
  *ngIf="
    codedBy !== transactionCodedBy.User && codedBy !== transactionCodedBy.Rule
  "
  >Uncoded</span
>
