import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { Subscription } from 'rxjs';

import { MessageService, ModalService } from 'src/app/core';
import { ActiveFileService } from '../../active-file.service';
import { TradingAccountService } from '../../setup';
import { TradingAccountComponent } from '../../setup/tradingAccounts/trading-account/trading-account.component';
import { TradingAccount } from './../../setup/tradingAccounts/tradingAccount';

@Component({
  selector: 'crs-dataset-livestock',
  templateUrl: './dataset-livestock.component.html',
  styleUrls: ['./dataset-livestock.component.scss'],
})
export class DatasetLivestockComponent implements OnInit, OnDestroy {
  activeTradingAccountId: string;
  fileId: string;
  livestockTradingAccounts: TradingAccount[];
  tradingAccounts: TradingAccount[];

  busy = {
    tradingAccounts: null,
  };

  private subscriptions: Subscription[] = [];

  constructor(
    private activeFileService: ActiveFileService,
    private messageService: MessageService,
    private modalService: ModalService,
    public route: ActivatedRoute,
    private router: Router,
    private tradingAccountService: TradingAccountService
  ) {}

  ngOnInit() {
    this.fileId = this.activeFileService.file.id;

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.setActiveTradingAccountId();
      }
    });

    this.setActiveTradingAccountId();
    this.fetchLivestockTradingAccounts();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async onClickAddTradingAccount(): Promise<void> {
    try {
      const id = await this.modalService.openModal(
        TradingAccountComponent,
        'add',
        {
          isLivestockTradingAccount: true,
          tradingAccounts: this.tradingAccounts,
        }
      );

      this.messageService.success(
        'Livestock trading account added successfully.'
      );
      this.fetchLivestockTradingAccounts();

      await this.router.navigate(['./', id], { relativeTo: this.route });
      this.setActiveTradingAccountId();
    } catch (error) {
      // on closing modal with `ESC` keyboard button, modal always return an error = 1, which we can ignore
      if (error && error !== 1) {
        this.messageService.error(error);
      }
    }
  }

  async onClickEditTradingAccount(
    event: PointerEvent,
    id: string
  ): Promise<void> {
    event.stopPropagation();

    try {
      const action = await this.modalService.openModal(
        TradingAccountComponent,
        id,
        {
          isLivestockTradingAccount: true,
          tradingAccounts: this.tradingAccounts,
        }
      );

      if (action === 'delete') {
        this.messageService.success(
          'Livestock trading account deleted successfully.'
        );
        await this.navigateToNextAvailableAccount(id);
        return;
      }

      if (action === 'edit') {
        this.messageService.success(
          'Livestock trading account saved successfully.'
        );
        this.fetchLivestockTradingAccounts();
      }
    } catch (error) {
      // on closing modal with `ESC` keyboard button, modal always return an error = 1, which we can ignore
      if (error && error !== 1) {
        this.messageService.error(error);
      }
    }
  }

  async onClickTradingAccount(id: string): Promise<void> {
    try {
      const navigated = await this.router.navigate(['./', id], {
        relativeTo: this.route,
      });

      if (navigated) {
        this.setActiveTradingAccountId();
      }
    } catch (error) {
      this.messageService.error(error);
    }
  }

  private fetchLivestockTradingAccounts(): void {
    this.busy.tradingAccounts = true;

    const tradingAccountsSubscription = this.tradingAccountService
      .getAll(this.fileId)
      .subscribe((tradingAccounts) => {
        this.tradingAccounts = tradingAccounts;
        this.livestockTradingAccounts = tradingAccounts.filter(
          (tradingAccount) => tradingAccount.isLivestockEnabled
        );
        this.busy.tradingAccounts = false;
      });

    this.subscriptions.push(tradingAccountsSubscription);
  }

  private setActiveTradingAccountId(): void {
    if (this.route.children.length > 0) {
      this.activeTradingAccountId =
        this.route.children[0].snapshot.paramMap.get('tradingAccountId');
    } else {
      this.activeTradingAccountId = null;
    }
  }

  private async navigateToNextAvailableAccount(
    currentId: string
  ): Promise<void> {
    try {
      let accountToNavigateTo: TradingAccount;

      const activeAccountIndex = this.livestockTradingAccounts.findIndex(
        ({ id }) => id === currentId
      );

      const next = this.livestockTradingAccounts[activeAccountIndex + 1];
      const previous = this.livestockTradingAccounts[activeAccountIndex - 1];

      if (next) {
        accountToNavigateTo = next;
      } else if (previous) {
        accountToNavigateTo = previous;
      } else {
        accountToNavigateTo = null;
      }

      this.fetchLivestockTradingAccounts();

      const urlFragments = accountToNavigateTo
        ? ['./', accountToNavigateTo.id]
        : ['./'];

      await this.router.navigate(urlFragments, { relativeTo: this.route });
      this.setActiveTradingAccountId();
    } catch (error) {
      // on closing modal with `ESC` keyboard button, modal always return an error = 1, which we can ignore
      if (error && error !== 1) {
        this.messageService.error(error);
      }
    }
  }
}
