<crs-report-editable-element [index]="index" [reportContent]="reportContent" [reportMetaData]="reportMetaData" [editable]="!output" [canClickToEdit]="false">

  <ng-container *ngIf="output">
    <ng-container *ngTemplateOutlet="viewer"></ng-container>
  </ng-container>

  <div viewer>
    <ng-container *ngTemplateOutlet="viewer"></ng-container>
  </div>

  <ng-template #viewer>

    <table class="financial-table">
      <caption class="sr-only">Financial Table</caption>
      <colgroup>
        <col class="financial-title-column" [style.width]="firstColumnWidth">
      </colgroup>

      <colgroup>
        <col [style.width]="noteWidth + 'px'">
        <col [style.width]="columnWidth + 'px'" *ngFor="let i of noOfColumns | fill">
      </colgroup>

      <thead *ngIf="element.header || !output">
        <tr class="financial-column-header-row" crs-report-financial-column-header-row [element]="element.header" *ngIf="output"></tr>
        <tr class="financial-column-header-row" crs-report-financial-column-header-row [element]="dummyHeader" *ngIf="!output"></tr>
      </thead>

      <tbody>
      <ng-container *ngFor="let e of element.elements; let i = index">
        <ng-container [ngSwitch]="e.financialType">
          <tr class="table-row financial-column-header-row" crs-report-financial-column-header-row [element]="e" *ngSwitchCase="financialElementTypes.ColumnHeaderRow"></tr>
          <tr class="table-row" [ngClass]="'financial-header' + (e.style == 1 ? '-alternative' : '') + ' ' + 'level-' + e.level" crs-report-financial-header [element]="e" *ngSwitchCase="financialElementTypes.Header"></tr>
          <tr class="table-row financial-row" [ngClass]="e.level ? 'level-' + e.level : ''" crs-report-financial-row [index]="i" [element]="e" [financialContent]="financialContent" *ngSwitchCase="financialElementTypes.Row"></tr>
          <tr class="table-row financial-total" [ngClass]="e.level ? 'level-' + e.level : ''" crs-report-financial-row [element]="e" [financialContent]="financialContent" *ngSwitchCase="financialElementTypes.Total"></tr>
          <tr class="table-row financial-spacer" crs-report-financial-spacer [element]="e" *ngSwitchCase="financialElementTypes.Spacer"></tr>
        </ng-container>
      </ng-container>
      </tbody>

    </table>

  </ng-template>

  <div editor>

    <table class="financial-table" cdkDropList (cdkDropListDropped)="dropRow($event)">
      <caption class="sr-only">Financial Table</caption>
      <colgroup>
        <col class="financial-title-column" [style.width]="firstColumnWidth">
      </colgroup>

      <colgroup>
        <col [style.width]="noteWidth + 'px'">
        <col [style.width]="columnWidth + 'px'" *ngFor="let i of noOfColumns | fill">
      </colgroup>

      <thead *ngIf="element.header || !output">
        <tr class="financial-column-header-row" crs-report-financial-column-header-row [element]="element.header" *ngIf="output"></tr>
        <tr class="financial-column-header-row" crs-report-financial-column-header-row [element]="dummyHeader" *ngIf="!output"></tr>
      </thead>

      <tbody class="editable-row-group">
        <tr class="insert-row">
          <td [colSpan]="noOfColumns + 2">
            <crs-financial-insert-element [index]="-1" [content]="element.elements" [reportMetaData]="reportMetaData"></crs-financial-insert-element>
          </td>
        </tr>
      </tbody>
      <tbody class="editable-row-group" *ngFor="let e of element.elements; let i = index">
        <ng-container [ngSwitch]="e.financialType">
          <tr class="financial-column-header-row" crs-report-financial-column-header-row [element]="e" *ngSwitchCase="financialElementTypes.ColumnHeaderRow" cdkDrag [cdkDragData]="e"></tr>
          <tr class="table-row" [ngClass]="'financial-header' + (e.style == 1 ? '-alternative' : '') + ' ' + 'level-' + e.level" crs-report-financial-header [index]="i" [element]="e" [financialContent]="financialContent" [isEditing]="true" *ngSwitchCase="financialElementTypes.Header"></tr>
          <tr class="table-row financial-row" [ngClass]="e.level ? 'level-' + e.level : ''" crs-report-financial-row [index]="i" [element]="e" [financialContent]="financialContent" [isEditing]="true" *ngSwitchCase="financialElementTypes.Row" cdkDrag [cdkDragData]="e"></tr>
          <tr class="table-row financial-total" [ngClass]="e.level ? 'level-' + e.level : ''" crs-report-financial-row [index]="i" [element]="e" [financialContent]="financialContent" [isEditing]="true" *ngSwitchCase="financialElementTypes.Total" cdkDrag [cdkDragData]="e"></tr>
          <tr class="table-row financial-spacer" crs-report-financial-spacer [index]="i" [element]="e" [financialContent]="financialContent" [isEditing]="true" *ngSwitchCase="financialElementTypes.Spacer" cdkDrag [cdkDragData]="e"></tr>
        </ng-container>
        <tr class="insert-row">
          <td [colSpan]="noOfColumns + 2">
            <crs-financial-insert-element [index]="i" [content]="element.elements" [reportMetaData]="reportMetaData"></crs-financial-insert-element>
          </td>
        </tr>

      </tbody>

    </table>

  </div>


</crs-report-editable-element>
