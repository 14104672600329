import { EntityLight } from '../entities/entity';

export class ClientEntitiesAndFiles {
  id: string;
  files: any[];
  entities: EntityLight[];
  constructor(data) {
    this.id = data.id;
    if (data.files) {
      this.files = data.files;
      this.files.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (data.entities) {
      this.entities = data.entities.map(f => new EntityLight(f));
      this.entities.sort((a, b) => a.legalName.localeCompare(b.legalName));
    }

  }
}
