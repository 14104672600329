import { StringHelpers } from './../utilities/string-helpers';
import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import { ProcessCellForExportParams } from 'ag-grid-community';
import { User } from 'src/app/firm';
import { Account, Source, SourceType } from 'src/app/accounting';
import { Division } from 'src/app/accounting/setup';

export const agGridExcelStyles = [
  {
    id: 'number-cell',
    numberFormat: {
      format: '#,##0.00;-#,##0.00',
    },
  },
  {
    id: 'dollar-cell',
    numberFormat: {
      format: '#,##0.00;-#,##0.00',
    },
  },
  {
    id: 'percent-cell',
    numberFormat: {
      format: '#,##0.00%;-#,##0.00%',
    },
  },
  {
    id: 'date-cell',
    dataType: 'dateTime',
    numberFormat: {
      format: 'd MMM yyyy',
    },
  },
  {
    id: 'time-cell',
    dataType: 'dateTime',
    numberFormat: {
      format: 'd MMM yyyy h:mm',
    },
  },
  {
    id: 'boolean-cell',
    dataType: 'boolean',
  },
];

export function agGridExportCellCallback(params: ProcessCellForExportParams) {
  const colDef = params.column.getColDef();
  const type = colDef.type;
  const renderer = colDef.cellRenderer;
  if (type === 'dateColumn') {
    return DateHelpers.convertDateToJsonString(params.value);
  }
  if (type === 'timeColumn') {
    return DateHelpers.convertTimeToJsonString(params.value);
  }
  if (type === 'enumColumn' || renderer === 'enumRenderer') {
    const enums = params.column.getColDef().cellRendererParams.enum;
    return StringHelpers.convertToSentence(enums[params.value]);
  }
  if (params.value instanceof User) {
    return params.value.displayName;
  }
  if (params.value instanceof Source) {
    return `[${params.value.sourceType.name}] ${params.value.name}`;
  }
  if (params.value instanceof SourceType) {
    return params.value.name;
  }
  if (params.value instanceof Account) {
    return params.value.displayName;
  }
  if (params.value instanceof Division) {
    return params.value.name;
  }
  return params.value;
}
