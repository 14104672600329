import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Subject, Subscription, forkJoin } from 'rxjs';
import { catchError, exhaustMap, finalize, tap } from 'rxjs/operators';

import { MessageService, ModalService } from 'src/app/core';
import { SourceAccountService } from './../../sourceAccounts/source-account.service';
import {
  DistributionFrequency,
  DistributionRow,
  DistributionRowToSave,
} from './distribution';
import { DistributionService } from './distribution.service';
import { DistributionGrid } from './distribution-grid';

@Component({
  selector: 'crs-distribution',
  templateUrl: './distribution.component.html',
})
export class DistributionComponent implements OnInit, OnDestroy {
  @ViewChild('classificationTemplate', { static: true })
  classificationTemplate: ElementRef;
  @ViewChild('accountSearchTemplate', { static: true })
  accountSearchTemplate: ElementRef;
  @ViewChild('newDistributionAccountCell', { static: true })
  newDistributionAccountCell: ElementRef;
  @ViewChild('newAccountCell', { static: true })
  newAccountCell: ElementRef;
  @ViewChild('lossesCheckbox', { static: true })
  lossesCheckbox: ElementRef;
  @ViewChild('optionsCell', { static: true })
  optionsCell: ElementRef;

  public readonly DEFAULT_NUMBER_OF_ROWS: number = 5;

  datasetId: string;
  busy = {
    load: null,
    submit: null,
    undoBalancingJournal: null,
    delete: null,
  };

  form = this.formBuilder.group({
    frequency: [DistributionFrequency.Annual, Validators.required],
  });

  distributionRows: DistributionRow[] = [];
  outOfBalance: number;

  distributionGrid: DistributionGrid;
  get gridApi() {
    return this.distributionGrid.gridApi;
  }

  error: string;

  private submitStream = new Subject<void>();
  private subscriptions: Subscription[] = [];

  canDrag = (params) => !params.node.group;
  rowDragText = () => 'Distribution Row...';

  constructor(
    private distributionService: DistributionService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private sourceAccountService: SourceAccountService
  ) {}

  ngOnInit(): void {
    this.configureGridOptions();
    this.configureSubmitStream();

    this.route.params.subscribe(() => {
      this.datasetId = this.route.snapshot.parent.paramMap.get('id');
      this.getRows();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  configureGridOptions(): void {
    this.distributionGrid = new DistributionGrid(
      this.classificationTemplate,
      this.accountSearchTemplate,
      null,
      this.updateOutOfBalance
    );

    this.distributionGrid.gridOptions = {
      ...this.distributionGrid.gridOptions,
      onGridReady: (event) => (this.distributionGrid.gridApi = event.api),
      components: this.distributionGrid.renderers,
      rowDragManaged: true,
      suppressHorizontalScroll: true,
      grandTotalRow: 'bottom',
      cellSelection: true,
      columnDefs: [
        {
          colId: 'drag',
          headerName: '',
          maxWidth: 30,
          minWidth: 30,
          rowDrag: true,
          rowDragText: this.rowDragText,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: 'Dividend/Distribution accounts',
          headerClass: ['centered', 'distribution-header-group'],
          marryChildren: true,
          children: [
            {
              colId: 'new',
              headerName: '',
              type: 'optionsColumn',
              cellRendererParams: {
                ngTemplate: this.newDistributionAccountCell,
              },
              cellStyle: { 'border-left': '1px solid #F2F5F6' },
              headerClass: ['border-left-cell'],
              maxWidth: 45,
              minWidth: 45,
              suppressHeaderMenuButton: true,
            },
            {
              colId: 'distributionAccountNo',
              field: 'distributionSourceAccount.accountNo',
              headerName: 'Account No',
              type: 'typeAheadColumn',
              editable: true,
              maxWidth: 160,
              minWidth: 110,
              cellEditorParams:
                this.distributionGrid.distributionAccountNoSearchParams,
              suppressHeaderMenuButton: true,
            },
            {
              colId: 'distributionAccountName',
              field: 'distributionSourceAccount.accountName',
              headerName: 'Account Name',
              type: 'typeAheadColumn',
              editable: true,
              maxWidth: 300,
              minWidth: 140,
              cellEditorParams:
                this.distributionGrid.distributionAccountNameSearchParams,
              suppressHeaderMenuButton: true,
            },
            {
              cellRenderer: 'classificationRenderer',
              colId: 'distributionClassification',
              field: 'distributionSourceAccount.classification',
              headerName: 'Class',
              headerClass: ['border-right-cell'],
              tooltipField:
                'distributionSourceAccount.classificationToolTipText',
              type: 'typeAheadColumn',
              editable: true,
              maxWidth: 150,
              minWidth: 120,
              cellStyle: { 'border-right': '1px solid #F2F5F6' },
              cellEditorParams:
                this.distributionGrid.classificationEditorParams,
              suppressHeaderMenuButton: true,
            },
          ],
        },
        {
          headerName: 'Payable/Liability accounts',
          headerClass: ['centered', 'distribution-header-group'],
          marryChildren: true,
          children: [
            {
              colId: 'new',
              headerName: '',
              headerClass: ['border-left-cell'],
              type: 'optionsColumn',
              cellRendererParams: { ngTemplate: this.newAccountCell },
              cellStyle: { 'border-left': '1px solid #F2F5F6' },
              maxWidth: 45,
              minWidth: 45,
              suppressHeaderMenuButton: true,
            },
            {
              colId: 'accountNo',
              field: 'sourceAccount.accountNo',
              headerName: 'Account No',
              type: 'typeAheadColumn',
              editable: true,
              maxWidth: 160,
              minWidth: 110,
              cellEditorParams: this.distributionGrid.accountNoSearchParams,
              suppressHeaderMenuButton: true,
            },
            {
              colId: 'accountName',
              field: 'sourceAccount.accountName',
              headerName: 'Account Name',
              type: 'typeAheadColumn',
              editable: true,
              maxWidth: 300,
              minWidth: 140,
              cellEditorParams: this.distributionGrid.accountNameSearchParams,
              suppressHeaderMenuButton: true,
            },
            {
              cellRenderer: 'classificationRenderer',
              colId: 'classification',
              field: 'sourceAccount.classification',
              headerName: 'Class',
              headerClass: ['border-right-cell'],
              tooltipField: 'sourceAccount.classificationToolTipText',
              type: 'typeAheadColumn',
              editable: true,
              maxWidth: 150,
              minWidth: 120,
              cellStyle: { 'border-right': '1px solid #F2F5F6' },
              cellEditorParams:
                this.distributionGrid.classificationEditorParams,
              suppressHeaderMenuButton: true,
            },
          ],
        },
        {
          field: 'memo',
          headerName: 'Memo',
          headerClass: ['px-2', 'distribution-header'],
          type: 'commentsColumn',
          editable: true,
          suppressHeaderMenuButton: true,
        },
        {
          colId: 'balance',
          field: 'balance',
          headerName: 'Balance',
          headerClass: ['distribution-header'],
          type: 'dollarColumn',
          cellClass: ['dollar-cell', 'ag-numeric-cell', 'highlight-column'],
          editable: true,
          suppressHeaderMenuButton: true,
        },
        {
          aggFunc: 'sum',
          colId: 'percent',
          field: 'percent',
          headerName: 'Percent',
          headerClass: ['distribution-header'],
          type: 'percentColumn',
          cellClass: ['percent-cell', 'ag-numeric-cell', 'highlight-column'],
          editable: true,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: 'Losses*',
          headerClass: ['distribution-header', 'centered'],
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.lossesCheckbox },
          maxWidth: 120,
          minWidth: 100,
          width: 120,
          suppressHeaderMenuButton: true,
        },
        {
          colId: 'delete',
          headerName: '',
          headerClass: ['distribution-header'],
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCell },
          maxWidth: 40,
          suppressHeaderMenuButton: true,
        },
      ],
    };
  }

  private configureSubmitStream(): void {
    const subscription = this.submitStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submit$()),
        tap(() =>
          this.messageService.success('Successfully saved distributions.')
        ),
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        })
      )
      .subscribe(() => this.getRows());

    this.subscriptions.push(subscription);
  }

  onValidSubmit(): void {
    this.submitStream.next();
  }

  private submit$() {
    const distributionRowsToSave = this.distributionRows
      .filter((row) => row.balance || row.percent)
      .map((row) => new DistributionRowToSave(row));

    const loading$ = new Subject<void>();
    this.busy.submit = loading$.subscribe();

    return this.distributionService
      .save$(this.datasetId, distributionRowsToSave)
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => loading$.complete())
      );
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error);
  }

  private getRows(): void {
    this.busy.load = forkJoin({
      distributionRows: this.distributionService.get$(this.datasetId),
      sourceAccounts: this.sourceAccountService.getForDataset$(this.datasetId),
    }).subscribe(
      ({ distributionRows, sourceAccounts }) => {
        this.setDistributionRows(distributionRows);
        this.distributionGrid.accounts = sourceAccounts;
      },
      (error) => this.showError(error)
    );
  }

  private setDistributionRows(distributionRows: DistributionRow[]): void {
    this.form.patchValue(distributionRows);

    this.distributionRows = distributionRows;
    this.gridApi.setGridOption('rowData', this.distributionRows);

    this.padRows(this.DEFAULT_NUMBER_OF_ROWS);
    this.updateOutOfBalance();
  }

  onClickClearAll(): void {
    this.modalService.confirmation(
      'Are you sure you want to clear all liability and distribution data? This cannot be undone.',
      () =>
        (this.busy.delete = this.distributionService
          .delete$(this.datasetId)
          .subscribe({
            next: () => this.getRows(),
            error: (error) => this.showError(error),
          })),
      true
    );
  }

  // ------ Journal Line Functions -----

  padRows(number: number): void {
    const current = this.distributionRows.length;
    if (current < number) {
      this.addRows(number - current);
    }
  }

  addRows(number: number): void {
    const newLines = new Array<DistributionRow>();
    for (let i = 1; i <= number; i++) {
      newLines.push(new DistributionRow(null));
    }
    Array.prototype.push.apply(this.distributionRows, newLines);

    this.gridApi?.applyTransaction({
      add: newLines,
    });
  }

  onClickDeleteRow(row: DistributionRow): void {
    this.gridApi.applyTransaction({ remove: [row] });
    const index = this.distributionRows.indexOf(row);
    this.distributionRows.splice(index, 1);
    this.updateOutOfBalance();
  }

  onClickToggleLoss(row: DistributionRow): void {
    row.excludeIfLoss = !row.excludeIfLoss;
  }

  private updateOutOfBalance(): void {
    let percent = 0;

    for (let i = 0; i < this.distributionRows.length; i++) {
      percent += this.distributionRows[i].percent;
    }

    this.outOfBalance = 1 - percent;
  }
}
