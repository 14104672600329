import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ReportMaster, ReportMasterService } from '../../admin/report-masters';

@Component({
  selector: 'crs-report-template-menu',
  templateUrl: './report-template-menu.component.html',
  styleUrls: ['./report-template-menu.component.scss'],
})
export class ReportTemplateMenuComponent implements OnInit, OnDestroy {
  public masters: ReportMaster[] = [];
  currentReportRoute: string;
  subscription: any;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private reportMasterService: ReportMasterService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.subscription = this.router.events
      .pipe(filter((event: any) => event instanceof NavigationStart))
      .subscribe((event) => {
        const slashIndex = event.url.lastIndexOf('/') + 1;
        const endIndex = event.url.length - 1;
        this.currentReportRoute = event.url.substr(slashIndex);
      });
  }

  public ngOnInit(): void {
    this._loadData();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public addReportTemplate(id: number) {
    if (!id && this.masters.length) {
      id = this.masters[0].id;
    }

    this.router.navigate(['../reports/templates/add', { masterId: id }], {
      relativeTo: this.activatedRoute,
    });
  }

  private _loadData(): void {
    const fileId = this.activatedRoute.snapshot.parent.paramMap.get('id');
    this.currentReportRoute = this.activatedRoute.children[0].routeConfig.path;
    this.activatedRoute.params
      .pipe(
        switchMap(() => this.reportMasterService.getAllForFile(fileId)),
        takeUntil(this._destroy$)
      )
      .subscribe((masters: ReportMaster[]) => (this.masters = masters));
  }
}
