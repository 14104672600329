export class AccountingFile {
  fileId: number;
  fileName: string;
  uri: string;
  entityTypeId: number;

  constructor(data: any) {
    Object.assign(this, data);
  }

}
