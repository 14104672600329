import { ProfitDisplayOption } from '../../enums/profit-display-option';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';

import { ReportMaster, ReportMasterFinancialPageDetail, ReportMasterPage } from '.';
import { ReportPageType } from '../../reportPages';
import { ProfitLossLayoutOption } from '../../enums/profit-loss-layout-option';
import { cashflowReportModeOptions } from '../../reportTemplates/report-template-page/cashflow-report-mode-options';

@Injectable({
  providedIn: 'root'
})
export class ReportMasterFormBuilder {

  constructor(private readonly _formBuilder: UntypedFormBuilder) {
  }

  buildForm(master: ReportMaster = null) {
    const form = this._formBuilder.group({
      id: [''],
      name: ['New Report Master', [Validators.required, Validators.maxLength(256)]],
      title: ['Report', [Validators.required, Validators.maxLength(256)]],
      defaultReportingSuite: [null, [Validators.required]],
      defaultFooterId: [null, [Validators.required]],
      reportStyle: [null],
      isDefault: [false, Validators.required],
      entityType: [],
      pages: this._formBuilder.array([])
    });

    if (!master) return form;

    this.buildPages(form.get('pages') as UntypedFormArray, master.pages);

    form.patchValue(master);
    return form;
  }

  private buildPages(pagesFormArray: UntypedFormArray, pages: ReportMasterPage[]) {
    // Todo: optimise this so we change the initPage to only have those parts that it requires
    pages.forEach(p => {
      if (p.financialDetail == null) p.financialDetail = new ReportMasterFinancialPageDetail({});
     });

    pages.forEach(() => {
      const pageForm = this.initPage(null);
      pagesFormArray.push(pageForm);
    });
  }

  initPage(type: ReportPageType) {
    return this._formBuilder.group({
      id: null,
      pageType: type,
      customTitle: null,  
      financialDetail: this._formBuilder.group({
        separateTradingAccounts: [false],
        profitDisplayOption: [ProfitDisplayOption.ProfitAfterTax],
        profitlossLayoutOption: [ProfitLossLayoutOption.Standard],
        layout: null,
        layoutFull: null
      }),
      cashflowDetail: this._formBuilder.group({
        cashflowMode: cashflowReportModeOptions.Auto,        
      })
    });
  }

}
