export enum CountryType {
  Australia = 0,
  NewZealand = 1,
}

export enum BankConnectionType {
  BankConnection = 'BankConnection',
  ManualImport = 'ManualImport',
}

export enum FormFlowStepsLegacy {
  FirstStep = 0,
  BankConnectionStep = 1,
  ManualImportStep = 2,
  SuccessStep = 3,
}
