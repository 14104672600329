<div class="row pad">
  <div class="col-12">
    <crs-busy *ngIf="busy.matchingRules"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="row">
      <div class="col-sm-8">
        <div class="mb-2" *ngIf="fileId">
          <a [routerLink]="'../../'"
            ><i aria-hidden="true" class="fas fa-angle-double-left"></i> Go to
            Matching Rule Sets</a
          >
        </div>
        <h3>GENERAL</h3>

        <form [formGroup]="form">
          <div class="form-group">
            <label class="control-label">Name</label>
            <input
              class="form-control"
              formControlName="name"
              type="text"
              [readonly]="true"
            />
          </div>
        </form>
      </div>
    </div>

    <div class="alert alert-info">
      Rules that appear at the bottom of the list will override rules at the top
      of the list.
    </div>

    <!-- Search -->
    <div class="row">
      <div class="col-sm-8">
        <div class="btn-toolbar d-flex mb-2" role="toolbar">
          <div>
            <button
              class="btn btn-secondary"
              title="Create Matching Rule"
              type="button"
              (click)="addMatchingRule()"
            >
              <i aria-hidden="true" class="fas fa-plus inset"></i>
              New Matching Rule
            </button>
          </div>

          <div class="icon-input-wrapper flex-grow-1 ml-2">
            <i aria-hidden="true" class="fas fa-search input-icon"></i>
            <input
              aria-describedby="searchIcon"
              placeholder="Search..."
              type="search"
              [formControl]="search"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <crs-progress-bar></crs-progress-bar>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material matching-rules-table"
        domLayout="normal"
        [frameworkComponents]="renderers"
        [gridOptions]="gridOptions"
        [rowData]="rules"
      >
        <ag-grid-column
          field="findDescription"
          headerName="Rule"
          [rowDrag]="true"
          [width]="400"
        ></ag-grid-column>
        <ag-grid-column
          field="account.accountName"
          headerName="Link to Account"
          *ngIf="!isMaster"
          [width]="200"
        ></ag-grid-column>
        <ag-grid-column
          field="masterAccount.accountName"
          headerName="Link to Master Account"
          *ngIf="isMaster"
          [width]="200"
        ></ag-grid-column>
        <ag-grid-column
          field="accountType.name"
          headerName="Link to Account Type"
          [width]="200"
        ></ag-grid-column>
        <ag-grid-column
          headerName=""
          type="optionsColumn"
          [cellRendererParams]="{ ngTemplate: optionsCell }"
        ></ag-grid-column>
      </ag-grid-angular>
    </div>

    <ng-template #optionsCell let-row>
      <div class="btn-mini-menu">
        <a
          class="btn-sm fas-btn-primary-ghost"
          [routerLink]="[]"
          (click)="selectMatchingRule(row)"
        >
          <i aria-hidden="true" class="fas fa-edit"></i>
        </a>
        <button
          class="btn-sm fas-btn-ghost"
          type="button"
          (click)="deleteMatchingRule(row)"
        >
          <i aria-hidden="true" class="fas fa-trash"></i>
        </button>
      </div>
    </ng-template>
  </div>
</div>
