import { DateHelpers } from '../../../shared/utilities/date-helpers';
import { SourceAccount } from '../..';
import { Division } from '../../setup';

/**
 * Package of UI info to help create a journal
 */
export class JournalPackage {
  defaultDate: Date;
  defaultJournalNo: string;
  sourceAccounts: SourceAccount[] = [];
  divisions: Division[] = [];

  constructor(data: any) {
    this.defaultDate = DateHelpers.dateFromApiResponse(data.defaultDate);
    this.defaultJournalNo = data.defaultJournalNo;
    if (data.sourceAccounts) this.sourceAccounts = data.sourceAccounts.map(a => new SourceAccount(a));
    if (data.divisions) this.divisions = data.divisions.map(a => new Division(a));
  }
}
