import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CentralComponent } from './central.component';
import { OwnerCreateModalComponent } from './firmUsers/owner-create-modal/owner-create-modal.component';
import { StorageAccountSelectComponent } from './storage-accounts/storage-account-select/storage-account-select.component';
// import { FirmsComponent } from './firms/firms/firms.component';
// import { FirmComponent } from './firms/firm/firm.component';
// import { DatabasesComponent } from './databases/databases/databases.component';
// import { DatabaseSelectComponent } from './databases/database-select/database-select.component';
// import { DatabaseComponent } from './databases/database/database.component';

@NgModule({
  declarations: [
    CentralComponent,
    // FirmsComponent,
    // FirmComponent,
    OwnerCreateModalComponent,
    // DatabasesComponent,
    // DatabaseSelectComponent,
    // DatabaseComponent,
    StorageAccountSelectComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class CentralModule {}
