import { UploadedFile } from 'src/app/shared/components/file-upload/file-upload.component';
import { Office } from 'src/app/firm';

export class Image {
    id: string;
    name: string;
    defaultHeight?: number;
    defaultWidth?: number;
    allowFileVariants: boolean;
    office: Office;
    fileId: string;
    parentImageId?: string;
    publicUrl: string;
    imageData: string;
    imageVariants: Image[] = [];

    constructor(data: any) {
      Object.assign(this, data);
      const mime = data.fileName?.endsWith('.png') ? 'png' : 'jpeg' ?? 'jpeg';
      if (data.image) this.imageData = `data:image/${mime};base64,${data.image}`;
      if (data.office) this.office = new Office(data.office);
      if (data.imageVariants) this.imageVariants = data.imageVariants.map(v => new Image(v));
    }
}

export class ImageModel {
  id: string;
  name: string;
  defaultHeight?: number;
  defaultWidth?: number;
  allowFileVariants: boolean;
  officeId: string;
  fileId: string;
  parentImageId?: string;
  uploadedFile: UploadedFile;

  constructor(data: Image, uploadedFile: UploadedFile) {
    this.id = data.id;
    this.name = data.name;
    this.defaultHeight = data.defaultHeight;
    this.defaultWidth = data.defaultWidth;
    this.allowFileVariants = data.allowFileVariants;
    this.parentImageId = data.parentImageId;
    this.officeId = data.office ? data.office.id : null;
    this.fileId = data.fileId;
    this.uploadedFile = uploadedFile;
  }
}
