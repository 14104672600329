import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../core';
import { Team, TeamModel } from './team';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  readonly base: string = 'api/teams';

  constructor(private readonly _apiService: ApiService) { }

  get(id: string) {
    return this._apiService.get<Team>(this.base + '/' + id).pipe(map(d => new Team(d)));
  }

  getAll() {
    return this._apiService.get<Team[]>(this.base).pipe(map(l => l.map(d => new Team(d))));
  }

  post(team: TeamModel) {
    return this._apiService.post<any>(this.base, team);
  }

  put(team: TeamModel) {
    return this._apiService.put(this.base + '/' + team.id, team);
  }

  delete(id: string) {
    return this._apiService.delete(this.base + '/' + id);
  }
}
