<ul class="nav-tabs nav navbar-expand-sm">
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link"
      routerLink="./templates"
      [class.active]="currentReportRoute == 'templates'"
    >
      Templates
    </a>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link"
      routerLink="./documents"
      [class.active]="currentReportRoute == 'documents'"
    >
      Documents
    </a>
  </li>
  <li class="nav-item dropdown ms-auto pt-2">
    <button
      aria-expanded="false"
      aria-haspopup="true"
      class="btn btn-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      type="button"
    >
      <i aria-hidden="true" class="fas fa-plus inset"></i>
      New Report
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <button
        class="dropdown-item"
        type="button"
        *ngFor="let master of masters"
        (click)="addReportTemplate(master.id)"
      >
        {{ master.name }}
        <i
          aria-hidden="true"
          class="fas fa-star text-warning"
          title="Default"
          *ngIf="master.isDefault"
        ></i>
      </button>
    </div>
  </li>
</ul>

<div class="nav-divider mx-5"></div>
