/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-057931}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Access_UK_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{APAC_Acquisition}_only_for_{13}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{APAC_Acquisition}_need_to_be_licensed___{APAC_Acquisition}_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{6_May_2025}____[v3]_[01]_MTc0NjQ4NjAwMDAwMA==8995cf3b2c95746c30172b045fb4961a'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
