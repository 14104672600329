import { ReportMetaData } from 'src/app/accounting/reports/reportViewer/report-output';
import { ReportContentInfo } from './../../report-content/report-content-info';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { ReportParagraph, ParagraphStyle, Alignment } from '..';

@Component({
  selector: 'crs-report-paragraph',
  templateUrl: './report-paragraph.component.html'
})
export class ReportParagraphComponent {

  form: UntypedFormGroup;
  component = this;

  constructor(readonly formBuilder: UntypedFormBuilder) {
  }

  @Input() element: ReportParagraph;
  @Input() index: number;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;

  isEditing = false;
  styles = ParagraphStyle;
  alignments = Alignment;

  onEdit() {
    this.form = this.formBuilder.group({
      style: [ParagraphStyle.Normal, Validators.required],
      alignment: [Alignment.Left, Validators.required],
      marginTop: [false, Validators.required],
      marginBottom: [true, Validators.required],
      text: [null, Validators.required],
    });
    this.form.patchValue(this.element);
    this.isEditing = true;
  }

  onSubmit = () => {
    this.form.markAsTouched();
    if (!this.form.valid) return false;

    Object.assign(this.element, this.form.value);
    return true;
  };

  onCancel() {
    this.isEditing = false;
    this.form = null;
  }

}
