import {
  Component,
  ViewChild,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatStepper } from '@angular/material/stepper';
import { MessageService, SessionService, Permissions } from 'src/app/core';
import {
  CountryType,
  BankConnectionType,
  FormFlowStepsLegacy,
} from './add-new-bank-connection.enum';
import { Subject, Subscription, Observable, EMPTY, throwError } from 'rxjs';
import {
  tap,
  exhaustMap,
  catchError,
  finalize,
  map,
  switchMap,
} from 'rxjs/operators';
import { Patterns } from 'src/app/shared/validators';
import {
  BacoBankConnectionDto,
  BacoInvitationModel,
} from 'src/app/baco/interfaces';
import { BankConnectionClient } from 'src/app/baco/common';
import { User } from 'src/app/firm';
import { BacoFeedStore } from '../../baco-feed.store';

@Component({
  selector: 'crs-add-new-bank-connection',
  templateUrl: './add-new-bank-connection.component.html',
  styleUrls: ['./add-new-bank-connection.component.scss'],
})
export class AddNewBankConnectionComponent implements OnInit, OnDestroy {
  objectTitle = 'Add Bank Connection';

  @Input() params: any;

  formFlowStepsLegacy = FormFlowStepsLegacy;
  CountryList = CountryType;
  BankConnectionTypeList = BankConnectionType;
  submitButtonStream = new Subject<void>();
  subscriptions: Subscription[] = [];
  busy = {
    loading: false,
    submit: null,
    delete: null,
  };
  error: string = null;
  redirectUri: string = '';
  newAddedBankConnection: BacoBankConnectionDto;
  permissions: Permissions = this.sessionService.permissions;

  @ViewChild('stepper')
  private stepper: MatStepper;

  public stepOneForm = new FormGroup({
    country: new FormControl<CountryType>(CountryType.Australia, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    connectionType: new FormControl<BankConnectionType>(
      this.sessionService.permissions.canSendClientRequest
        ? BankConnectionType.BankConnection
        : BankConnectionType.ManualImport,
      { nonNullable: true, validators: [Validators.required] }
    ),
  });

  public bankConnectionForm = new FormGroup({
    clientName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern(Patterns.emailRegExp),
      ],
    }),
    commencementLocalDate: new FormControl<Date>(
      new Date(new Date().setHours(0, 0, 0, 0)),
      { nonNullable: true, validators: [Validators.required] }
    ),
    user: new FormControl<User>(
      this.permissions.trustedAdvisor ? this.sessionService.user : null,
      { validators: [Validators.required] }
    ),
  });

  public manualInputForm = new FormGroup({
    accountName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  get selectedCountry(): CountryType {
    return this.stepOneForm.value.country!;
  }
  get selectedBankConnectionType(): BankConnectionType {
    return this.stepOneForm.value.connectionType!;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private bankConnectionService: BankConnectionClient,
    private cdr: ChangeDetectorRef,
    private feedStore: BacoFeedStore,
    private messageService: MessageService,
    private sessionService: SessionService
  ) {}

  public copiedToClipboard() {
    this.messageService.success('URL copied to your clipboard.');
  }

  ngOnInit() {
    this.configureSubmit();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  goForward() {
    const isBankConnectionStep =
      this.stepOneForm.value.connectionType ===
      BankConnectionType.BankConnection;

    const isManualImportStep =
      this.stepOneForm.value.connectionType === BankConnectionType.ManualImport;

    if (isBankConnectionStep) {
      this.stepper.selectedIndex = FormFlowStepsLegacy.BankConnectionStep;
    } else if (isManualImportStep) {
      this.stepper.selectedIndex = FormFlowStepsLegacy.ManualImportStep;
    }
  }

  getCurrentStep() {
    return this.stepper?.selectedIndex;
  }

  onSubmit() {
    this.submitButtonStream.next();
  }

  configureSubmit() {
    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.submit$())
        )
        .subscribe((result) => {
          if (
            this.selectedBankConnectionType ===
            BankConnectionType.BankConnection
          ) {
            this.redirectUri = result.redirectUri;
            this.newAddedBankConnection = result.bacoBankConnectionDto;
            this.stepper.selectedIndex = FormFlowStepsLegacy.SuccessStep;
          } else {
            this.newAddedBankConnection = result;
            this.activeModal.close(this.newAddedBankConnection);
            this.messageService.success(
              'Successfully created manual import bank account.'
            );
          }
        })
    );
  }

  submit$(): Observable<any> {
    const loading$ = new Subject<void>();
    this.busy.submit = loading$.subscribe();

    let observable$: Observable<string | BacoBankConnectionDto>;

    if (this.selectedBankConnectionType === BankConnectionType.BankConnection) {
      const bankConnectionFormValue = this.bankConnectionForm.value;
      const invitationModel: BacoInvitationModel = {
        clientName: bankConnectionFormValue.clientName,
        commencementLocalDate: new Date(
          new Date(bankConnectionFormValue.commencementLocalDate).setHours(
            0,
            0,
            0,
            0
          )
        ),
        email: bankConnectionFormValue.email,
        country: this.selectedCountry,
        trustedAdvisorId: bankConnectionFormValue.user?.id,
      };
      if (this.selectedCountry === CountryType.Australia) {
        observable$ = this.bankConnectionService
          .checkAbnExistOfTrustedAdvisor(bankConnectionFormValue.user?.id)
          .pipe(
            switchMap((dto) => {
              if (dto.isAbnExist) {
                return this.bankConnectionService.sendNewBankConnectionInvitation(
                  this.params.feedId,
                  invitationModel
                );
              }
              return throwError(
                `Please request Admin to update the ABN number for ${
                  dto.officeName || 'Practice'
                } in organisation page first`
              );
            })
          );
      } else {
        observable$ =
          this.bankConnectionService.sendNewBankConnectionInvitation(
            this.params.feedId,
            invitationModel
          );
      }
    } else {
      observable$ = this.bankConnectionService.createManualBankConnection(
        this.params.feedId,
        {
          accountName: this.manualInputForm.value.accountName!,
          country: this.selectedCountry,
        }
      );
    }

    return observable$.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => {
        this.feedStore.refreshBankConnections();
        loading$.complete();
      })
    );
  }

  showError(error) {
    this.error = error;
  }
}
