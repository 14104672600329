<div class="modal-header">
  <h4 class="modal-title">{{ isAdd ? 'Add' : 'Edit' }} source account</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="onClickClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="onValidSubmit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group required">
          <label class="control-label">Source</label>
          <crs-source-select formControlName="source" [readonly]="!isAdd" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group required">
          <label class="control-label">Account No</label>
          <input class="form-control" formControlName="accountNo" type="text" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group required">
          <label class="control-label">Account Name</label>
          <input
            class="form-control"
            formControlName="accountName"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group required">
          <label class="control-label">Classification</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="sourceClassification"
            [clearable]="false"
            [items]="classifications | enumToArray"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Division</label>
          <ng-select
            bindLabel="name"
            bindValue="id"
            formControlName="division"
            [items]="divisions"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <div
      class="row"
      *ngIf="form.value.source?.apiType === apiType.ChartOfAccounts"
    >
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Tax Code</label>
          <ng-select
            bindLabel="descriptionLabel"
            bindValue="taxCode"
            formControlName="taxCode"
            [items]="taxCodes"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Trading Account</label>
          <ng-select
            bindLabel="name"
            bindValue="id"
            formControlName="tradingAccount"
            [items]="tradingAccounts"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-check d-inline-block">
          <input
            class="form-check-input"
            formControlName="isInactive"
            id="isInactiveCheckbox"
            type="checkbox"
          />
          <label class="form-check-label" for="isInactiveCheckbox">
            Inactive
          </label>
        </div>
      </div>
    </div>

    <crs-form-error [error]="error" />
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add Source Account' : 'Save' }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="onClickClose()">
      Cancel
    </button>
  </div>
</form>
