import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  StringConditionOptionNames,
  ValueConditionOptionNames,
} from 'src/app/baco/interfaces';

@Component({
  selector: 'crs-condition-select',
  templateUrl: './condition-select.component.html',
  styleUrls: ['./condition-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConditionSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConditionSelectComponent {
  @Input() valueType: 'text' | 'number' = 'text';
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  get targetConditions() {
    return this.valueType == 'text'
      ? StringConditionOptionNames
      : ValueConditionOptionNames;
  }

  @ViewChild(NgSelectComponent, { static: true })
  private _valueAccessor: ControlValueAccessor;

  writeValue(value: any | any[]): void {
    this._valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }
}
