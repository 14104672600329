<div class="modal-header">
  <h4 class="modal-title">Save to Access Ledger</h4>
  <button aria-label="Close" class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form novalidate [formGroup]="form">
  <div class="modal-body modal-body-padding">
    <div class="form-group">
      <h5 class="section-title text-primary modal-body-title-header">
        Document Name
        <a
          class="btn-sm fas-btn-primary-ghost"
          ngbPopover="The document name does not need to be the same as the report title."
          placement="right"
          [routerLink]="[]"
        >
          <i aria-hidden="true" class="fas fa-info-circle"></i>
        </a>
      </h5>
      <input
        class="form-control"
        formControlName="name"
        type="text"
        title="Document Name"
      />
    </div>

    <div class="form-group">
      <h5 class="section-title text-primary modal-body-title-header">
        Report Title
      </h5>
      <input
        class="form-control"
        formControlName="title"
        readonly
        type="text"
        title="Report Title"
      />
    </div>

    <div class="form-group">
      <h5 class="section-title text-primary modal-body-title-header">
        Financial Year
      </h5>
      <input
        class="form-control"
        formControlName="financialYear"
        readonly
        type="text"
        title="Financial Year"
      />
    </div>
  </div>
</form>

<div class="modal-footer">
  <button
    class="btn btn-primary"
    type="submit"
    [promiseBtn]="busy.submit"
    (click)="submit()"
  >
    Save
  </button>
</div>
