<div class="position-relative">
  <ng-container *ngIf="feed$ | async as feedState">
    <ng-container [ngSwitch]="feedState.state">
      <ng-container
        *ngSwitchCase="'ERROR'"
        [ngTemplateOutlet]="error"
        [ngTemplateOutletContext]="{ err: feedState.errorMessage }"
      >
      </ng-container>
      <ng-container
        *ngSwitchDefault
        [ngTemplateOutlet]="success"
        [ngTemplateOutletContext]="{ feed: feedState.data }"
      >
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Only show loading when feed$ is null or in 'PENDING' state -->
  <ng-template #loading>
    <crs-busy></crs-busy>
  </ng-template>

  <ng-template #error let-err="err">
    <crs-error-view [message]="err"></crs-error-view>
  </ng-template>

  <ng-template #success let-feed="feed">
    <div class="page-wrapper min-width-tablet">
      <div class="header-container">
        <crs-main-menu></crs-main-menu>
      </div>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-template>

  <!-- Fallback loading indicator when feed$ is null or pending -->
  <ng-container *ngIf="!(feed$ | async)">
    <ng-template [ngTemplateOutlet]="loading"></ng-template>
  </ng-container>
</div>
