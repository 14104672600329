import { ApiType } from '../enums/apiType';
import { ApiFeatures } from './apiFeatures';

export class SourceType {
  id: number;
  apiType: ApiType;
  apiTypeDetail: ApiFeatures;
  name: string;
  image: string;
  icon: string;
  isBeta: boolean;

  get isReportance() {
    return this.apiType === ApiType.Reportance;
  }

  get isDataHub() {
    return this.apiType === ApiType.DataHub;
  }

  constructor(data: any) {
    Object.assign(this, data);
    this.image = 'assets/images/api/images/' + data.imagePath;
    this.icon = 'assets/images/api/icons/' + data.iconPath;
    if (data.apiTypeDetail) this.apiTypeDetail = new ApiFeatures(data.apiTypeDetail);
  }
}

// Replicated client-side here to reduce load on expensive calls such as in the 'accounts' screen
export const sourceTypes = [
  new SourceType({
    id: 1,
    apiType: ApiType.None,
    name: 'Excel',
    imagePath: 'Excel.png',
    iconPath: 'Icon_Excel.png',
    isBeta: false
  }),
  new SourceType({
    id: 2,
    apiType: ApiType.ChartOfAccounts,
    name: `Accountant's Chart`,
    imagePath: 'AccessGroupSource.png',
    iconPath: 'Icon_AccessGroupSource.png',
    isBeta: false
  }),
  new SourceType({
    id: 6,
    apiType: ApiType.Reportance,
    name: 'Access Ledger',
    imagePath: 'AccessGroupSource.png',
    iconPath: 'Icon_AccessGroupSource.png',
    isBeta: false
  }),
  new SourceType({
    id: 8,
    apiType: ApiType.DataHub,
    name: 'ZAP Data Hub',
    imagePath: 'DataHub.png',
    iconPath: 'Icon_DataHub.png',
    isBeta: false
  }),
  new SourceType({
    id: 9,
    apiType: ApiType.HandiLedger,
    name: 'HandiLedger',
    imagePath: 'HandiLedger.jpg',
    iconPath: 'Icon_HandiLedger.jpg',
    isBeta: false
  })
];
