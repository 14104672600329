import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { ReportingSuiteService } from '../';
import { MessageService } from '../../../../../core';
import { getDefaultGridOptions, accountingRenderers } from '../../../../../shared';

@Component({
  selector: 'crs-reporting-suites',
  templateUrl: './reporting-suites.component.html'
})
export class ReportingSuitesComponent implements OnInit {

  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    reportingSuites: false
  };
  reportingSuitesObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;

  constructor(private readonly _reportingSuiteService: ReportingSuiteService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService) { }

  ngOnInit() {
    this.getReportingSuites();
  }

  getReportingSuites() {
    this.busy.reportingSuites = true;
    this.reportingSuitesObservable = this._reportingSuiteService.getAll().pipe(
        tap(() => this.busy.reportingSuites = false),
        catchError(err => {
          this.showError(err);
          return of([]);
        }));
  }

  addReportingSuite() {
    this._router.navigate(['./add'], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectReportingSuite(param) {
    this._router.navigate(['./' + param.data.id], { relativeTo: this._route });
  }

}
