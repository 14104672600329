<div class="row"  *ngIf="!notSetup">
  <div class="col-sm-9 col-md-8 col-lg-6" [ngBusy]="busy.load">

    <crs-form-error [error]="error"></crs-form-error>

    <div class="spacer-md"></div>

    <p>Select a workpaper starter file to download <i class="fas fa-download" aria-hidden="true"></i></p>

    <crs-progress-bar></crs-progress-bar>

    <div>
      <ag-grid-angular #agGrid
                       style="width: 100%;"
                       class="ag-theme-material"
                       [gridOptions]="gridOptions"
                       [rowData]="starters"
                       rowClass="selectable-row"
                       (rowClicked)="selectStarter($event)">
        <ag-grid-column headerName="Name" field="name"></ag-grid-column>
        <ag-grid-column headerName="Period" field="periodDate" type="dateColumn"></ag-grid-column>
      </ag-grid-angular>
    </div>

  </div>
</div>


<div *ngIf="notSetup" class="center">

  <!-- No Entities -->
  <img width="70" src="https://cirrussitestorage.blob.core.windows.net/assets/logo/reportance-r-192.png" alt="Reportance Logo">

  <div class="spacer-md"></div>

  <p><strong>Integrate with Business Fitness Smart Workpapers</strong></p>

  <div class="spacer-md"></div>

  <p>
    Reportance supports a deep integration with Business Fitness Smart Workpapers. Please contact support at Business Fitness to configure your integration.
  </p>
  <p>
    You can <a href="https://www.businessfitness.com.au/solutions/smart-workpapers/" target="_blank" rel="noopener noreferrer">click here</a> to find out more about Smart Workpapers.
  </p>

</div>

