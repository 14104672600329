import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, concat, EMPTY, from } from 'rxjs';
import {
  exhaustMap,
  finalize,
  tap,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';
import { trigger, style, animate, transition } from '@angular/animations';

import {
  ReportPageTypeService,
  ReportPageCategory,
  FooterType,
  ReportPageType,
} from '../../reportPages';
import { HeaderTemplate, FooterTemplate } from '../../reportTemplates';
import { MessageService, ModalService } from '../../../../core';
import { ReportImage } from '../../reportViewer/elements/report-image/report-image';
import { SaveAsComponent } from '../../reportTemplates/save-as/save-as.component';
import { ReportElementTypeEnum } from '../../enums';

class InteractionOption {
  closeOnSuccess: boolean;
  interaction: Interaction;
  constructor(closeOnSuccess: boolean, interaction: Interaction) {
    this.closeOnSuccess = closeOnSuccess;
    this.interaction = interaction;
  }
}

enum Interaction {
  Save,
  SaveAs,
  Delete,
}

@Component({
  selector: 'crs-report-page-type',
  templateUrl: './report-page-type.component.html',
  styleUrls: ['./report-page-type.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('5s', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('5s', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ReportPageTypeComponent implements OnInit {
  id: number | string;
  isMaster: boolean;
  masterId: number | null;
  isAdd: boolean;
  busy = {
    load: null,
    submit: null,
    delete: null,
  };
  error: string;

  headers: HeaderTemplate[];
  footers: FooterTemplate[];

  footerTypes = FooterType;
  categories = ReportPageCategory;

  interactionStream = new Subject<InteractionOption>();

  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(128)]],
    title: [null, [Validators.required, Validators.maxLength(128)]],
    category: [null, [Validators.required]],
    contentType: [0, [Validators.required]],
    canHaveMultiple: [false, [Validators.required]],
    excludeFromContents: [false, [Validators.required]],
    headerId: [null, [Validators.required]],
    footerType: [0, [Validators.required]],
    customFooter: [null],
    customFooterId: [null],
    inactive: [true, [Validators.required]],
  });
  content = [];

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _reportPageTypeService: ReportPageTypeService,
    private readonly _modalService: ModalService,
    private _messageService: MessageService
  ) {
    this.route.params.subscribe((params) => {
      this.id = this.route.snapshot.paramMap.get('id');
      this.isAdd = this.id === 'add';
      this.load();
    });

    this.configureInteraction();
  }

  configureInteraction() {
    this.interactionStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap((options) => this.handleInteraction(options)),
        switchMap((observable) => observable)
      )
      .subscribe(({ closeOnSuccess }) => {
        if (closeOnSuccess) {
          this.close();
        }
      });
  }

  save(closeOnSuccess: boolean) {
    this.interactionStream.next({
      interaction: Interaction.Save,
      closeOnSuccess,
    });
  }

  saveAs(closeOnSuccess: boolean) {
    this.interactionStream.next({
      interaction: Interaction.SaveAs,
      closeOnSuccess,
    });
  }

  delete() {
    this.interactionStream.next({
      interaction: Interaction.Delete,
      closeOnSuccess: true,
    });
  }

  close() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  async handleInteraction(
    option: InteractionOption
  ): Promise<Observable<InteractionOption>> {
    let option$: Observable<InteractionOption>;
    const interaction = option.interaction;
    const loadingStream = new Subject<void>();
    let successMessage = `Successfully saved page template.`;

    // SAVING
    if (
      interaction === Interaction.Save ||
      interaction === Interaction.SaveAs
    ) {
      const model = this.form.value as ReportPageType;
      model.content = this.content;

      if (!(await this.checkImages(model.content))) {
        this.showError('Some images corrupted');
        return EMPTY;
      }

      if (this.isAdd) {
        option$ = this._reportPageTypeService
          .post(model)
          .pipe(map(() => option));
      } else if (interaction === Interaction.SaveAs) {
        const saveAsModal = this._modalService.openModal(
          SaveAsComponent,
          null,
          {
            text: 'Please provide a name for the new page template',
            defaultName: model.name,
          }
        );

        option$ = from(saveAsModal).pipe(
          catchError(() => EMPTY),
          switchMap((name: string) => {
            model.name = name;
            if (this.isMaster) {
              model.masterId = Number(this.id);
            }
            if (this.masterId) {
              model.masterId = this.masterId;
            }
            return this._reportPageTypeService.post(model);
          }),
          tap(
            () =>
              (successMessage = `Successfully saved a page template copy of ${model.name}`)
          ),
          map(() => option)
        );
      } else {
        if (this.isMaster) {
          model.masterId = this.id as number;
          option$ = this._reportPageTypeService
            .post(model)
            .pipe(map(() => option));
        } else {
          model.id = this.id as number;
          option$ = this._reportPageTypeService
            .put(model)
            .pipe(map(() => option));
        }
      }

      this.busy.submit = loadingStream.subscribe();
    }

    // DELETING
    if (interaction === Interaction.Delete) {
      if (this.isAdd) return EMPTY;
      option$ = this._reportPageTypeService.delete(this.id as number).pipe(
        tap(() => (successMessage = `Successfully deleted the page template`)),
        map(() => option)
      );
      this.busy.delete = loadingStream.subscribe();
    }

    option$ = option$.pipe(
      tap(() => this._messageService.success(successMessage)),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );

    return option$;
  }

  load() {
    this.busy.load = concat(
      this.getPackage(),
      this.getReportPageType()
    ).subscribe(
      () => {},
      (err) => this.showError(err)
    );
  }

  getPackage() {
    return this._reportPageTypeService.getUiPackage().pipe(
      tap((data) => {
        this.headers = data.headers;
        this.footers = data.footers;
      })
    );
  }

  getReportPageType() {
    if (this.isAdd) return EMPTY;

    return this._reportPageTypeService.get(this.id as number).pipe(
      tap((data) => {
        this.isCustomFooterText = !data.customFooterId;
        this.content = data.content;
        this.isMaster = data.isMaster;
        this.masterId = data.masterId;
        this.form.patchValue(data);
      })
    );
  }

  isCustomFooterText: boolean;

  changeCustomFooterOption() {
    if (this.isCustomFooterText)
      this.form.controls['customFooterId'].setValue(null);
    else this.form.controls['customFooterId'].setValue(this.footers[0].id);
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  ngOnInit() {}

  private async checkImages(content: any[]): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const imageContents = content
        .filter(
          (image) =>
            image.elementType === ReportElementTypeEnum.Image ||
            image instanceof ReportImage
        )
        .map((image) => image as ReportImage);

      if (imageContents.length == 0) resolve(true);

      const results = await Promise.all(
        imageContents.map((c) => this.imageExists(c.image.publicUrl))
      );
      resolve(!results.some((exists) => !exists));
    });
  }

  private async imageExists(url): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      var img = new Image();
      img.onload = function () {
        resolve(true);
      };
      img.onerror = function () {
        resolve(false);
      };
      //img.src = url; It is no longer possible to load the image from the Storage Account
      resolve(true);
    });
  }
}
