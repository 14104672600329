import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Observable, Subject, of, EMPTY } from 'rxjs';
import { tap, exhaustMap, finalize, catchError, map } from 'rxjs/operators';

import {
  DisclosureTemplate,
  DisclosureTemplateService,
  DisclosureLevel,
  DisclosureVariant,
} from '../';
import {
  MessageService,
  ModalService,
  SessionService,
} from '../../../../../core';
import { DisclosureAutoSelectionMethod } from '../disclosure-template';

enum Action {
  Submit,
  InheritFromThis,
}

@Component({
  selector: 'crs-disclosure-template',
  templateUrl: './disclosure-template.component.html',
})
export class DisclosureTemplateComponent implements OnInit {
  id;
  level: DisclosureLevel;

  // Add parameters only
  fileId: string;

  readonly: boolean;

  isAdd: boolean;
  busy = {
    load: null,
    submit: null,
    inherit: null,
  };
  error: string = null;

  isAdmin = false;
  isMaster: boolean;

  disclosureAutoSelectionMethods = DisclosureAutoSelectionMethod;
  levels = DisclosureLevel;

  interactionStream = new Subject<Action>();

  disclosureTemplate: DisclosureTemplate;
  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(128)]],
    description: [null, [Validators.maxLength(1024)]],
    autoSelectionMethod: [
      DisclosureAutoSelectionMethod.IfRelatedAccountTypeAppears,
    ],
    accountTypes: [],
  });
  search = new UntypedFormControl();

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _disclosureTemplateService: DisclosureTemplateService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    sessionService: SessionService
  ) {
    this.isAdmin = sessionService.permissions.isAdmin;
  }

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.id = this._route.snapshot.paramMap.get('id');
      this.isAdd = this.id === 'add';
      this.level = parseInt(
        this._route.snapshot.paramMap.get('level'),
        10
      ) as DisclosureLevel;
      this.fileId = this._route.snapshot.paramMap.get('fileId');

      this.loadDisclosureTemplate();
    });

    this.configureInteraction();
  }

  updateReadonly() {
    this.readonly =
      !this.isAdd &&
      this.disclosureTemplate &&
      this.disclosureTemplate.level < this.level;
  }

  submit() {
    this.interactionStream.next(Action.Submit);
  }

  inheritFromThis() {
    this.interactionStream.next(Action.InheritFromThis);
  }

  private configureInteraction() {
    this.interactionStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap((action) => this.handleInteraction(action))
      )
      .subscribe((x) => {
        if (x.action === Action.InheritFromThis || this.isAdd) {
          this._router.navigate(
            ['../' + x.result, { level: this.level, fileId: this.fileId }],
            { relativeTo: this._route }
          );
        } else this.close();
      });
  }

  private handleInteraction(action: Action) {
    let observable: Observable<any>;
    if (action === Action.Submit) observable = this.submitObservable();
    else if (action === Action.InheritFromThis)
      observable = this.inheritFromThisObservable();
    else observable = EMPTY;

    return observable.pipe(
      map((result) => {
        return {
          action: action,
          result: result,
        };
      })
    );
  }

  private submitObservable() {
    let observable: Observable<any>;
    const loadingStream = new Subject<void>();

    const model = this.form.value as DisclosureTemplate;

    if (this.isAdd) {
      model.fileId = this.fileId;
      observable = this._disclosureTemplateService.post(model);
    } else {
      model.id = this.id as string;
      observable = this._disclosureTemplateService.put(model);
    }

    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );

    return observable;
  }

  private inheritFromThisObservable() {
    const loadingStream = new Subject<void>();
    this.busy.inherit = loadingStream.subscribe();

    return this._disclosureTemplateService
      .createInherited(this.disclosureTemplate, this.fileId)
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => loadingStream.complete())
      );
  }

  private loadDisclosureTemplate() {
    const observable = this.isAdd
      ? of(
          new DisclosureTemplate({
            level: this.level,
            fileId: this.fileId,
          })
        )
      : this._disclosureTemplateService.get(this.id);

    this.busy.load = observable.subscribe(
      (disclosureTemplate) => {
        this.disclosureTemplate = disclosureTemplate;
        this.form.patchValue(disclosureTemplate);
        this.isMaster = disclosureTemplate.isMaster;
        this.updateReadonly();
      },
      (err) => this.showError(err)
    );
  }

  addDisclosureVariant() {
    this.disclosureTemplate.disclosureVariants.push(
      new DisclosureVariant({
        id: 'add',
        disclosureTemplateId: this.disclosureTemplate.id,
      })
    );
  }

  delete() {
    if (this.isAdd || !this.isAdmin) return;
    this._modalService.confirmation(
      'Are you sure you want to delete this Disclosure Template? This action cannot be undone.',
      () =>
        this._disclosureTemplateService.delete(this.id).subscribe(() => {
          this._messageService.success(
            'Successfully deleted Disclosure Template.'
          );
          this.close();
        }, this.showError),
      true
    );
  }

  close() {
    this._router.navigate(['../../'], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
    this._messageService.error(error);
  }
}
