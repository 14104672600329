import {
  AddHeaderFormType,
  StandardAccountsHeaderFormComponent,
} from '../standard-accounts-header-form/standard-accounts-header-form.component';
import { MoveProcessor } from '../../../ledger/accounts/accounts/moveProcessor';
import { AccountHeaderComponent } from '../../../ledger/accounts/account-header/account-header.component';
import { ModalService } from 'src/app/core';
import {
  MoveRequest,
  MoveResult,
  MoveAction,
  DragContext,
} from '../../../ledger/accounts/accounts/accountGridModel';
import { MessageService } from '../../../../core/messages/message.service';
import { StandardAccountService } from '../standard-account.service';
import { Account } from 'src/app/accounting';
import {
  Subscription,
  EMPTY,
  of,
  Observable,
  from,
  Subject,
  merge,
  fromEvent,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  finalize,
  catchError,
  map,
  mapTo,
  skip,
} from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { accountingRenderers, ProgressBarService } from 'src/app/shared';
import { StandardAccount } from '../standard-account';
import { StandardAccountGridManager } from './standardAccountGridManager';
import {
  AddAccountFormType,
  StandardAccountFormComponent,
} from '../standard-account-form/standard-account-form.component';

export class StandardAccountContext {
  entityType: number;
}
@Component({
  selector: 'crs-standard-account-headers',
  templateUrl: './standard-account-headers-grid.component.html',
  styleUrls: ['./standard-account-headers-grid.component.scss'],
})
export class StandardAccountHeadersGridComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedEntityType: number;
  @Input() search: string;
  @Input() classificationId: string;
  @Input() accountTypeId: string;
  @Input() isAccountsActivated: boolean;

  isFirstCall = true;
  busy = {
    headers: false,
  };
  observables = {
    allocated: null,
  };
  subscriptions: Subscription[] = [];
  gridComponents = accountingRenderers;
  standardAccountContext = new StandardAccountContext();

  headersGridMgr: StandardAccountGridManager;

  headersGroupColumnDef = {
    headerName: 'Headers',
    width: 250,
    menuTabs: [],
    flex: 1.5,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'accountHeaderRenderer',
    },
    valueGetter: (params) => {
      return params?.data?.displayName;
    },
  };

  search$ = new Subject<string>();
  accountTypeId$ = new Subject<string>();
  classificationId$ = new Subject<string>();

  dragContext = new DragContext();

  constructor(
    private standardAccountService: StandardAccountService,
    private messageService: MessageService,
    private modalService: ModalService,
    private progressBar: ProgressBarService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.standardAccountContext.entityType = this.selectedEntityType;

    this.search$.next(this.search);
    this.accountTypeId$.next(this.accountTypeId);
    this.classificationId$.next(this.classificationId);

    const getAllocatedMethod = (context: StandardAccountContext) =>
      this.getAccountsFromService$(context);
    const refreshMethod = (context: StandardAccountContext, ids) =>
      this.refreshAccountsFromService$(context, ids);

    const moveProcessor = new MoveProcessor(
      this.standardAccountContext,
      this.standardAccountService,
      this.messageService
    );

    this.headersGridMgr = new StandardAccountGridManager(
      'headersGrid',
      this,
      this.standardAccountContext,
      moveProcessor,
      this.dragContext,
      this.messageService,
      getAllocatedMethod,
      refreshMethod,
      this.progressBar.ref('headersBar')
    );

    moveProcessor.addAccountGridManagerToManagedCollection(this.headersGridMgr);

    this.subscriptions.push(
      merge(
        fromEvent(document, 'mousedown').pipe(
          mapTo(true),
          tap(() => this.dragContext.clear())
        ),
        fromEvent(document, 'mouseleave').pipe(mapTo(false)),
        fromEvent(document, 'mouseup').pipe(mapTo(false))
      ).subscribe((isDragging) => (this.dragContext.isDragging = isDragging))
    );

    // Accounts Filter/Search
    this.subscriptions.push(
      this.search$
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((data) => {
          this.setHeaderGridLevel(
            !!data || !!this.accountTypeId || !!this.classificationId
          );

          if (this.headersGridMgr.api) {
            this.headersGridMgr.api.setQuickFilter(data);
          }
        })
    );

    this.subscriptions.push(
      this.accountTypeId$
        .pipe(distinctUntilChanged(), skip(1))
        .subscribe((data) => {
          this.setHeaderGridLevel(
            !!data || !!this.search || !!this.classificationId
          );
          this.getStandardAccounts();
        })
    );

    this.subscriptions.push(
      this.classificationId$
        .pipe(distinctUntilChanged(), skip(1))
        .subscribe((data) => {
          this.setHeaderGridLevel(
            !!data || !!this.search || !!this.accountTypeId
          );
          this.getStandardAccounts();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.search$.next(this.search);
    this.accountTypeId$.next(this.accountTypeId);
    this.classificationId$.next(this.classificationId);

    if (changes.selectedEntityType) {
      this.standardAccountContext.entityType = this.selectedEntityType;
      this.getStandardAccounts();
    }
  }

  getStandardAccounts() {
    if (this.isFirstCall) {
      this.busy.headers = true;
      this.isFirstCall = false;
    }

    (
      this.getAccountsFromService$(
        this.standardAccountContext
      ) as Observable<any>
    )
      .pipe(
        catchError((e) => {
          this.showError(e);
          return of([]);
        }),
        tap((a) => this.headersGridMgr.setStore(a)),
        finalize(() => {
          this.busy.headers = false;
          this.headersGridMgr.api.refreshCells({ force: true });
          this.cd.detectChanges();
        })
      )
      .subscribe();
  }

  isSelectable(row) {
    return row.data.isSortable;
  }

  getRowClass(params: any) {
    return params?.data?.isHeader ? 'grid-header' : 'grid-account';
  }

  isRowDraggable(params) {
    return !!params.data && params.data.isSortable;
  }

  private setHeaderGridLevel(shouldExpand: boolean): void {
    setTimeout(() => {
      if (shouldExpand) {
        this.headersGridMgr?.expandAll();
      } else {
        this.headersGridMgr?.collapseGroupsToLevelOne();
      }
    }, 400);
  }

  quickGroup(parent: Account, sortOrder: number) {
    const gridManager = this.getGridManager();
    const modalPromise = this.modalService.openModal(
      AccountHeaderComponent,
      'add',
      {
        parent: parent,
        sortOrder: sortOrder,
      }
    );

    from(modalPromise)
      .pipe(
        catchError(() => EMPTY),
        tap(() => gridManager.startLoader()),
        switchMap((id) =>
          gridManager.refreshIdsObservable([parent.id]).pipe(map(() => id))
        ),
        map((id) => gridManager.api.getRowNode(id)),
        switchMap((node) => {
          const moveRequest = new MoveRequest(
            gridManager.moveProcessor.getSelectionAsMoveSource(),
            new MoveResult(gridManager, MoveAction.InsertInto, node)
          );
          return gridManager.moveProcessor.execute(moveRequest);
        }),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => {
          gridManager.completeLoader();
        })
      )
      .subscribe();
  }

  onClickAddHeader(account: StandardAccount): void {
    this.modalService
      .openModal(
        StandardAccountsHeaderFormComponent,
        AddHeaderFormType.AddFromExisting,
        account
      )
      .then(() => this.getStandardAccounts())
      .then(() => this.messageService.success('Header added successfully.'))
      .catch(() => true);
  }

  onClickEditHeader(account: StandardAccount): void {
    account.children = this.getChildAccounts(
      account,
      this.getGridManager().store
    );

    this.modalService
      .openModal(StandardAccountsHeaderFormComponent, account.id, account)
      .then(() => this.getStandardAccounts())
      .then(() => this.messageService.success('Header saved successfully.'))
      .catch(() => true);
  }

  onClickDeleteHeader(account: StandardAccount): void {
    const confirmationMessage =
      '<p>Are you sure you want to delete this header?</p></p>This action cannot be undone.</p>';

    this.modalService.confirmation(
      confirmationMessage,
      () => this.deleteHeader(account),
      true
    );
  }

  onClickAddAccount(headerAccount: StandardAccount): void {
    this.modalService
      .openModal(
        StandardAccountFormComponent,
        AddAccountFormType.AddFromExistingHeader,
        headerAccount
      )
      .then(() => this.getStandardAccounts())
      .then(() => this.messageService.success('Account added successfully.'))
      .catch(() => true);
  }

  onClickEditAccount(account: StandardAccount): void {
    this.modalService
      .openModal(StandardAccountFormComponent, account.id)
      .then(() => this.getStandardAccounts())
      .then(() => this.messageService.success('Account saved successfully.'))
      .catch(() => true);
  }

  getGridManager() {
    return this.headersGridMgr;
  }

  private deleteHeader(account: StandardAccount): void {
    const gridManager = this.getGridManager();
    gridManager.startLoader();

    this.standardAccountService
      .delete$(account.id)
      .pipe(
        switchMap(() => gridManager.refreshIdsObservable([account.parentId])),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        tap(() => this.messageService.success('Header deleted successfully.')),
        finalize(() => {
          gridManager.completeLoader();
          gridManager.refreshAll();
        })
      )
      .subscribe();
  }

  private showError(err) {
    this.messageService.error(err);
  }

  private getAccountsFromService$(context: StandardAccountContext) {
    return this.standardAccountService
      .getHierarchy$(
        this.selectedEntityType,
        this.classificationId,
        this.accountTypeId
      )
      .pipe(
        catchError((e) => {
          this.showError(e);
          return of([]);
        }),
        tap(() => {
          this.setHeaderGridLevel(
            !!this.search || !!this.classificationId || !!this.accountTypeId
          );
        })
      );
  }

  private getChildAccounts(headerAccount: StandardAccount, accounts: any[]) {
    return accounts.filter(
      (account) =>
        account?.hierarchy.includes(headerAccount.id) &&
        !account.isHeader &&
        account.level == headerAccount.level + 1
    );
  }

  private refreshAccountsFromService$(
    context: StandardAccountContext,
    ids: string[]
  ): Observable<StandardAccount[]> {
    const store = this.headersGridMgr.store;

    return this.standardAccountService
      .refreshHierarchy$(context.entityType, ids)
      .pipe(
        tap((account) => {
          const immutableStore = store.map((dataItem) => {
            const itemSelected = ids.includes(dataItem.id);
            if (itemSelected) {
              const selectedAccount = account.find(
                (acc) => acc.id === dataItem.id
              );
              return selectedAccount;
            } else {
              return dataItem;
            }
          });

          this.headersGridMgr.setStore(immutableStore);
        })
      );
  }
}
