import { GridOptions } from 'ag-grid-community';
import { ReportDocumentListItem } from '../../models';
import { ReportTemplateModel } from '../../reportTemplates';
import { ReportOutput } from '../report-output';

export class ReportViewerParams {
  constructor(data: Partial<ReportViewerParams>) {
    Object.assign(this, data);
  }

  report: ReportOutput;
  restrictSave: boolean;
  reportTemplate: ReportTemplateModel;
  reportDocumentId: string;
  restrictSaveDocument: boolean;
  reportDocumentListItem: ReportDocumentListItem;
  gridOptions: GridOptions;
  shouldUpdateCustomTableAutoTotal: boolean;
  isBankFeedReport: boolean;
}
