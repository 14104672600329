<div class="row" *ngIf="route.children.length === 0">
  <div class="col-12">
    <crs-busy *ngIf="busy.taxForms"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="taxFormsObservable | async"
      />
    </div>
  </div>
</div>

<div *ngIf="route.children.length !== 0">
  <router-outlet></router-outlet>
</div>
