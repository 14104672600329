import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';

import { AuthService, OnLoginResult } from './auth.service';
import { AuthConfig } from '../authConfig';
import { HttpClient } from '@angular/common/http';
import { Config } from '..';
import { MessageService } from '../messages/message.service';
import { ErrorPageType } from '../components/error-page/error-page.component';

@Injectable()
export class AuthInitService {
  constructor(
    private oauthService: OAuthService,
    private authService: AuthService,
    private router: Router,
    public http: HttpClient,
    private messageService: MessageService
  ) {}

  async init() {
    AuthConfig.init();

    this.oauthService.configure(AuthConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    // this.oauthService.events
    //   .pipe(filter(e => e.type === 'token_received'))
    //   .subscribe(e => {
    //     console.debug('oauth/token_received', e);
    //   });

    this.oauthService.events
      .pipe(filter((e) => e.type === 'session_terminated'))
      .subscribe((e) => {
        this.authService.onLogout();
      });

    this.oauthService.events.subscribe((e) => {
      console.debug('oauth/oidc event', e);
    });

    return this.oauthService
      .loadDiscoveryDocument(this.getDiscoveryDocumentEndpoint())
      .then(async () => {
        await this.oauthService.tryLogin();
      })
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          console.log('Access Token', this.oauthService.getAccessToken());
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      })
      .then((result) => {
        if (result) return this.authService.onLogin();
        return Promise.resolve(OnLoginResult.NotLoggedIn);
      })
      .catch((err) => {
        console.log('Error logging in', err);
        if (err.type === 'invalid_nonce_in_state') {
          return OnLoginResult.InvalidState;
        }
        return OnLoginResult.ServerDown;
      })
      .then((r) => {
        switch (r) {
          case OnLoginResult.Success:
            if (this.oauthService.state) {
              const state = decodeURIComponent(this.oauthService.state);
              this.router.navigateByUrl(state);
            }
            this.router.initialNavigation();
            break;
          case OnLoginResult.NotLoggedIn:
            this.router.initialNavigation();
            break;
          case OnLoginResult.NotAuthorised:
            this.router.navigate(['error', ErrorPageType.NotAuthorised]);
            break;
          case OnLoginResult.NotRegistered:
            this.router.navigate(['error', ErrorPageType.UnregisteredUser]);
            break;
          case OnLoginResult.ServerDown:
            this.router.navigate(['error', ErrorPageType.ServerDown]);
            break;
          case OnLoginResult.InactiveUser:
            this.router.navigate(['error', ErrorPageType.InactiveUser]);
            break;
          case OnLoginResult.InvalidState:
            this.authService.login();
            break;
        }
      });
  }

  private getDiscoveryDocumentEndpoint(): string {
    let pathname = window.location.pathname;
    switch (pathname) {
      case '/b2c_1a_v1_fa_sign_up':
        return AuthConfig.b2c_1a_v1_fa_sign_up;
      case '/b2c_1a_v1_fa_password_change':
        return AuthConfig.b2c_1a_v1_fa_password_change;
      case '/b2c_1a_v1_fa_sign_in_name_change':
        return AuthConfig.b2c_1a_v1_fa_sign_in_name_change;
      default:
        return AuthConfig.b2c_1a_v1_fa_sign_in;
    }
  }
}
