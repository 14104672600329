import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, exhaustMap, finalize, tap } from 'rxjs/operators';
import { ModalService } from 'src/app/core';
import { UploadedFile, UploadedImageFile } from 'src/app/shared/components/file-upload/file-upload.component';
import { ImageService } from '../../image.service';
import { Image, ImageModel } from '../../image.class';

@Component({
  selector: 'crs-base-image',
  templateUrl: './base-image.component.html',
  styleUrls: ['./base-image.component.scss']
})
export class BaseImageComponent implements OnInit, OnDestroy {

  @Input()
  image: Image;

  @Input('is-variant')
  isVariant: boolean = false;

  @Output('close')
  onClose = new EventEmitter<boolean>();

  @Output('submit')
  onSubmit = new EventEmitter<{ image: Image, uploadedFile: UploadedImageFile }>();

  @Input('uploaded-file')
  cachedImageFile: UploadedImageFile = null;

  uploadedImageFile: UploadedImageFile = null;
  error;  
  busy = {
    submit: null
  };

  form = this._formBuilder.group({
    name: [null, Validators.required],
    office: [],
    defaultWidth: [],
    defaultHeight: [],
    allowFileVariants: [false],
    uploadedFile: []
  });

  private _submit$ = new Subject();
  private _subscriptions: Subscription[] = [];

  get height() {
    const height = this.form.get('defaultHeight').value;
    if (height) return height + 'px';
    return 'auto';
  }

  get width() {
    const width = this.form.get('defaultWidth').value;
    if (width) return width + 'px';
    return 'auto';
  }

  get isAdd() {
    return !this.image.id;
  }

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _imageService: ImageService,
    private readonly _modalService: ModalService) { }

  ngOnInit(): void {
    this.form.reset();
    this.form.patchValue(this.image);
    this.form.get('office').setValidators(this.isVariant ? Validators.required : null);

    this._subscriptions.push(
      this._submit$
        .pipe(
          tap(() => this.error = null),
          exhaustMap(() => {
            return this._submitObservable();
          })
        )
        .subscribe(image => {
          this.onSubmit.emit(image);
        }));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  onImageUpload(files: {
    transfer: any,
    uploadedFile: UploadedFile
  }[]) {
    const control = this.form.get('uploadedFile');
    if (files && files.length) {
      control.setValue(files[0].uploadedFile);
      this.uploadedImageFile = files[0];
    } else {
      control.setValue(null);
      this.uploadedImageFile = this.cachedImageFile;
      this.cachedImageFile = null;
    }
  }

  delete() {
    this._modalService.confirmation('Are you sure you want to delete this image?',
      () => {
        this._imageService.delete(this.image.id).pipe(
          catchError(err => {
            this._showError(err);
            return EMPTY;
          }),
          tap(() => this.close(true))
        ).subscribe();
      }, true);
  }

  close(isDelete = false) {
    this.onClose.emit(isDelete);
  }

  submit() {
    if (this.isVariant && !this.image.parentImageId) {
      this.onSubmit.emit({ image: this.form.value, uploadedFile: this.uploadedImageFile });
      return;
    }

    this._submit$.next();
  }

  private _showError(err) {
    this.error = err;
  }

  private _submitObservable(): Observable<any> {
    let observable: Observable<any>;
    const model = new ImageModel(this.form.value, this.uploadedImageFile ? this.uploadedImageFile.uploadedFile : null);
    model.parentImageId = this.image.parentImageId;
    if (this.isAdd) {
      observable = this._imageService.post(model);
    } else {
      model.id = this.image.id;
      observable = this._imageService.put(model);
    }

    const loadingStream = new Subject();
    this.busy.submit = loadingStream.subscribe();

    return observable.pipe(catchError(err => {
      this._showError(err);
      return EMPTY;
    }), finalize(() => loadingStream.complete()));
  }
}
