import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ReportContentInfo } from '../../report-content/report-content-info';
import { ReportMetaData } from '../../report-output';

@Component({
  selector: 'crs-report-signature',
  templateUrl: './report-signature.component.html'
})
export class ReportSignatureComponent {

  form: UntypedFormGroup;
  component = this;

  @Input() element;
  @Input() index: number;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;
  isEditing = false;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  onEdit() {
    if (!this.reportContent.output) {
      this.form = this.formBuilder.group({
        name: [null],
        allResponsible: [false],
        useReportDate: [false]
      });
    } else {
      this.form = this.formBuilder.group({
        name: [null],
        date: [null]
      });
    }

    this.form.patchValue(this.element);
    this.isEditing = true;
  }

  onSubmit = () => {
    this.form.markAsTouched();
    if (!this.form.valid) return false;

    Object.assign(this.element, this.form.value);
    return true;
  }

  onCancel() {
    this.isEditing = false;
    this.form = null;
  }
}
