import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import {
  catchError,
  exhaustMap,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { File } from '../../files/file';
import { ActiveFileService } from '../../active-file.service';
import { FeedClient } from 'src/app/baco/common/clients/feed-client.service';
import { Feed } from 'src/app/baco/interfaces';

@Component({
  selector: 'crs-file-feeds-form',
  templateUrl: './file-feeds-form.component.html',
})
export class FileFeedsFormComponent implements OnInit, OnDestroy {
  @Input() params: {
    feedId?: string;
    feed?: Feed;
  };

  busy = {
    load: null,
    submit: null,
  };
  form = this.formBuilder.group({
    feedName: ['', [Validators.required]],
    billingRef: [null],
  });
  error: string = null;
  isEdit = false;

  private file: File;
  private submitButtonStream$ = new Subject<void>();
  private subscriptions: Subscription[] = [];

  constructor(
    private activeFileService: ActiveFileService,
    private activeModal: NgbActiveModal,
    private feedClient: FeedClient,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.params?.feed) {
      this.isEdit = true;
    }

    const activeFileStreamSubscription =
      this.activeFileService.stream.subscribe((file) => {
        this.file = file;
        const initialForm =
          this.params?.feed && this.isEdit
            ? {
                feedName: this.params.feed.name,
                billingRef: this.params.feed.billingRef,
              }
            : { feedName: this.file.name };

        this.form.patchValue(initialForm);
      });

    this.subscriptions.push(activeFileStreamSubscription);

    this.configureSubmit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onClickClose(): void {
    this.activeModal.dismiss();
  }

  onValidSubmit(): void {
    this.submitButtonStream$.next();
  }

  private configureSubmit(): void {
    const submitButtonStreamSubscription = this.submitButtonStream$
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() =>
          this.isEdit ? this.updateFeed$() : this.createFeed$()
        )
      )
      .subscribe((feedId) => {
        if (!this.isEdit) {
          this.reloadCurrentRoute();
        }

        this.activeModal.close(feedId);
      });

    this.subscriptions.push(submitButtonStreamSubscription);
  }

  private reloadCurrentRoute(): void {
    const currentRoute = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]);
    });
  }

  private createFeed$(): Observable<any> {
    const loading$ = new Subject<void>();
    this.busy.submit = loading$.subscribe();

    return this.feedClient
      .createFeed({
        feedName: this.form.value.feedName,
        billingRef: this.form.value.billingRef,
      })
      .pipe(
        switchMap((feedId) => {
          const ledgerToLink = {
            fileName: null,
            name: this.file.name,
            sourceId: this.file.id,
            sourceSystem: 'Access Ledger',
          };

          return this.feedClient.linkLedger$(feedId, ledgerToLink).pipe(
            map(() => feedId),
            catchError((error) => {
              this.showError(error);
              return EMPTY;
            })
          );
        }),
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        finalize(() => loading$.complete())
      );
  }

  private updateFeed$(): Observable<any> {
    const loading$ = new Subject<void>();
    this.busy.submit = loading$.subscribe();

    return this.feedClient
      .updateFeedDetail(this.params.feed.id, {
        feedName: this.form.value.feedName,
        billingRef: this.form.value.billingRef,
      })
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        finalize(() => loading$.complete())
      );
  }

  private showError(error) {
    this.error = error;
  }
}
