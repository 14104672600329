import { MessageService } from 'src/app/core';
import { Subscription, EMPTY, } from 'rxjs';
import { switchMap, tap, catchError, finalize, exhaustMap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { AssetsContextService } from '../../assets-context.service';
import { AssetsContext } from '../../assets-context';
import { DepreciationPool } from '../../assets/depreciation-pool';
import { AssetPoolingService } from '../asset-pooling.service';
import { ManagePoolBalances } from '../manage-pool-balances';

@Component({
  selector: 'crs-asset-pooling',
  templateUrl: './asset-pooling.component.html',
  styleUrls: ['./asset-pooling.component.scss']
})
export class AssetPoolingComponent implements OnInit, OnDestroy {

  error: null;
  busy = {
    loading: false
  };

  manageData: ManagePoolBalances;
  depreciationPools = DepreciationPool;

  assetsContext: AssetsContext;
  subscriptions: Subscription[] = [];

  constructor(private readonly _assetsContextService: AssetsContextService,
    private readonly _assetPoolingService: AssetPoolingService,
    private readonly _messageService: MessageService) { }

  ngOnInit() {

    this.subscriptions.push(
      this._assetsContextService.contextValid$.pipe(
        tap(c => this.assetsContext = c),
        switchMap(c => this.getManageDetails(c))
      ).subscribe()
    );

    this.subscriptions.push(
    this._assetPoolingService.balanceAction$.pipe(
      tap(() => this.error = null),
      tap(() => this.busy.loading = true),
      exhaustMap(a => a.pipe(
        tap(() => this._messageService.success('Successfully set pool balances')),
        catchError(e => {
          this.error = e;
          this.busy.loading = false;
          return EMPTY;
        }))),
      switchMap(() => this.getManageDetails(this.assetsContext)),
      ).subscribe()
    );

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  getManageDetails(context: AssetsContext) {
    this.busy.loading = true;
    this.error = null;

    return this._assetPoolingService.getPoolManage(context).pipe(
      tap(d => this.manageData = d),
      catchError(e => {
        this.error = e;
        return EMPTY;
      }),
      finalize(() => this.busy.loading = false)
    );
  }

}
