import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: any, digits?: number, zeroValue?: string, nullValue?: string): any {
    if (value === undefined || value === null)
      return nullValue === undefined ? null : nullValue;

    if (value === 0 && zeroValue !== undefined)
      value = zeroValue;
    if (digits === undefined || digits === null)
      digits = 2;

    const val = Number(value);
    if (isNaN(val))
      return value;

    const stringValue = this.decimalPipe.transform(Math.abs(val), `1.${digits}-${digits}`);

    return val < 0 ? `(${stringValue})` : stringValue;
  }
}
