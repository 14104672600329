import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import {
  Validators,
  FormArray,
  FormGroup,
  ValidatorFn,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { Subject, Observable, EMPTY } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  tap,
  exhaustMap,
  catchError,
  finalize,
  takeUntil,
} from 'rxjs/operators';

import { RuleMovementType } from 'src/app/baco/enums';
import { TransactionService } from 'src/app/baco/services';
import {
  BacoRuleDto,
  BacoRuleCreateModel,
  BacoTransactionDto,
  StringConditionOption,
  ValueConditionOption,
  BacoRuleAllocationCreateModel,
  AllocationType,
  BacoAccountDto,
  BacoTaxOptionDto,
} from 'src/app/baco/interfaces';
import { BacoRuleStore } from '../../baco-rule.store';
import { BacoFeedStore } from '../../baco-feed.store';
import { MessageService } from '../../../../../core';
import { CustomValidators } from '../../../../../shared/validators/custom-validators';

enum Action {
  Submit,
}

@Component({
  selector: 'crs-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.scss'],
})
export class RuleComponent implements OnInit, OnDestroy, AfterViewInit {
  private _destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild('conditionValueInput') conditionValueInputRef: any;

  @Input() id: string | null;
  @Input() params: any;

  allocationTypes = AllocationType;
  objectTitle = 'Rule';
  busy = {
    load: null,
    submit: null,
    delete: null,
  };
  public form = new FormGroup<RuleForm>(
    {
      name: new FormControl('', [Validators.required]),
      movementType: new FormControl<RuleMovementType>(
        RuleMovementType.MoneyIn,
        [Validators.required]
      ),
      descriptionCondition: new FormGroup({
        conditionOption: new FormControl<StringConditionOption>(
          StringConditionOption.Contains
        ),
        conditionValue: new FormControl<string>(''),
      }),
      amountCondition: new FormGroup({
        conditionOption: new FormControl<ValueConditionOption>(null),
        conditionValue: new FormControl<number>(null),
      }),
      allocateBy: new FormControl<AllocateBy>('Percentage Only'),
      amountAllocations: new FormArray<FormGroup<RuleAllocationFormRow>>(
        [this.createAllocationRow(AllocationType.Amount, 0)],
        []
      ),
      percentageAllocations: new FormArray<FormGroup<RuleAllocationFormRow>>(
        [this.createAllocationRow(AllocationType.Percentage, 100)],
        [this.sumValidator()]
      ),
    },
    [this.conditionValidator()]
  );

  error: string = null;
  percentageError: string = null;
  submitButtonStream = new Subject<Action>();
  descriptionPlaceholder = '';

  constructor(
    public activeModal: NgbActiveModal,
    private transactionService: TransactionService,
    private ruleStore: BacoRuleStore,
    private readonly _feedStore: BacoFeedStore,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) {}

  get allocationPercentageControls() {
    return this.form.controls.percentageAllocations;
  }

  get allocationAmountControls() {
    return this.form.controls.amountAllocations;
  }

  ngOnInit() {
    const transaction: BacoTransactionDto = this.params.transaction;
    this.configureSubmit();
    if (transaction) {
      this.transactionService.setDefaultTransactionIfEmpty(transaction);
      this.form.patchValue({
        name: transaction.originalDescription ?? transaction.amount.toString(),
        movementType: transaction.movementType,
        descriptionCondition: {
          conditionOption: transaction.originalDescription
            ? StringConditionOption.Exact
            : null,
          conditionValue: transaction.originalDescription,
        },
        amountCondition: {
          conditionOption: ValueConditionOption.EqualTo,
          conditionValue: transaction.amount,
        },
        allocateBy: 'Percentage Only',
        amountAllocations: [],
        percentageAllocations: [],
      });
      this.createAllocationRow(AllocationType.Percentage, 100);
    }

    const rule: BacoRuleDto = this.params.rule;
    if (rule) {
      const amountAllocations = rule.allocations.filter(
        (x) => x.allocationType === AllocationType.Amount
      );
      this.form.patchValue({
        name: rule.name,
        movementType: rule.movementType,
        descriptionCondition: {
          conditionOption: rule.descriptionCondition?.conditionOption,
          conditionValue: rule.descriptionCondition?.conditionValue,
        },
        amountCondition: {
          conditionOption: rule.amountCondition?.conditionOption,
          conditionValue: rule.amountCondition?.conditionValue,
        },
        allocateBy:
          amountAllocations.length > 0
            ? 'Amount & Percentage'
            : 'Percentage Only',
      });
      this.form.controls.amountAllocations.clear();
      this.form.controls.percentageAllocations.clear();

      amountAllocations.forEach((x) => {
        this.form.controls.amountAllocations.push(
          this.createAllocationRow(
            AllocationType.Amount,
            x.allocationValue,
            x.bacoAccountId,
            x.bacoTaxOptionId,
            x.description
          )
        );
      });

      rule.allocations
        .filter((x) => x.allocationType === AllocationType.Percentage)
        .forEach((x) => {
          this.form.controls.percentageAllocations.push(
            this.createAllocationRow(
              AllocationType.Percentage,
              x.allocationValue,
              x.bacoAccountId,
              x.bacoTaxOptionId,
              x.description
            )
          );
        });
    }

    this.form
      .get('descriptionCondition')
      .get('conditionOption')
      .valueChanges.pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        if (
          value === StringConditionOption.AllWords ||
          value === StringConditionOption.AnyWords
        ) {
          this.descriptionPlaceholder = 'eg. Petrol, Car';
        } else if (
          value === StringConditionOption.Contains ||
          value === StringConditionOption.Exact
        ) {
          this.descriptionPlaceholder = 'eg. Petrol';
        } else {
          this.descriptionPlaceholder = ''; // Clear if the value is null or undefined
        }
        this.focusConditionValueInput();
        this.cdr.markForCheck();
      });
  }

  ngAfterViewInit() {
    this.form.controls.allocateBy.valueChanges.subscribe(() => {
      if (
        !this.form?.controls?.amountAllocations?.controls[0]?.controls == null
      ) {
        Object.values(
          this.form.controls.amountAllocations.controls[0].controls
        ).forEach((control) => {
          control.updateValueAndValidity();
        });
      }
    });
  }

  submit() {
    this.submitButtonStream.next(Action.Submit);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  private focusConditionValueInput() {
    const conditionValueInputElement =
      this.conditionValueInputRef?.ngControl?.valueAccessor?._elementRef
        ?.nativeElement;

    if (conditionValueInputElement) {
      conditionValueInputElement.focus();
    }
  }

  private conditionValidator(): ValidatorFn {
    return (ruleForm: FormGroup<RuleForm>): ValidationErrors | null => {
      const description = ruleForm.controls.descriptionCondition;
      const amount = ruleForm.controls.amountCondition;

      let isDescriptionFilled = false;
      let isAmountFilled = false;

      if (
        description.controls.conditionOption != null &&
        description.controls.conditionValue.value
      ) {
        isDescriptionFilled = true;
      }

      if (
        amount.controls.conditionOption != null &&
        amount.controls.conditionValue.value > 0
      ) {
        isAmountFilled = true;
      }

      return isDescriptionFilled || isAmountFilled
        ? null
        : { descriptionOrAmount: true };
    };
  }

  private sumValidator(): ValidatorFn {
    return (
      formArray: FormArray<FormGroup<RuleAllocationFormRow>>
    ): ValidationErrors | null => {
      let currentTotal = this.getTotal(formArray);

      return currentTotal != 100 ? { percentageSum: true } : null;
    };
  }

  private getTotal(
    formArray: FormArray<FormGroup<RuleAllocationFormRow>>
  ): number {
    let currentTotal = formArray.controls
      .map((t) => t.controls.allocationValue)
      .filter((t) => t)
      .reduce((total, t) => t.value + total, 0);

    return currentTotal;
  }

  public addAllocationRow(allocationType: AllocationType) {
    if (allocationType === AllocationType.Percentage) {
      const currentTotal = this.getTotal(
        this.form.controls.percentageAllocations
      );
      const remaining = 100 - currentTotal;
      const allocationRow = this.createAllocationRow(
        allocationType,
        remaining <= 0 ? null : remaining
      );
      this.form.controls.percentageAllocations.push(allocationRow);
    } else {
      const allocationRow = this.createAllocationRow(allocationType);
      this.form.controls.amountAllocations.push(allocationRow);
    }
  }

  public deleteAllocationRow(allocationType: AllocationType, i: number) {
    const frmGroup =
      allocationType === AllocationType.Percentage
        ? this.form.controls.percentageAllocations
        : this.form.controls.amountAllocations;

    frmGroup.controls.splice(i, 1);
    frmGroup.updateValueAndValidity();
  }

  private createAllocationRow(
    allocationType: AllocationType,
    allocationValue: number = null,
    bacoAccountId: string = null,
    bacoTaxOptionId: string = null,
    description: string = null
  ): FormGroup<RuleAllocationFormRow> {
    const customValidatorRequired = CustomValidators.relativeToControl(
      'allocateBy',
      'Amount & Percentage',
      Validators.required
    );
    const customValidatorMinLength = CustomValidators.relativeToControl(
      'allocateBy',
      'Amount & Percentage',
      Validators.required
    );
    const valueValidators = [];

    if (allocationType === AllocationType.Percentage) {
      valueValidators.push(Validators.max(100));
      valueValidators.push(Validators.required);
    } else {
      valueValidators.push(customValidatorRequired);
      valueValidators.push(customValidatorMinLength);
    }

    const allocationRow: FormGroup<RuleAllocationFormRow> =
      new FormGroup<RuleAllocationFormRow>({
        allocationType: new FormControl(allocationType, [Validators.required]),
        allocationValue: new FormControl(allocationValue, [...valueValidators]),
        bacoAccountId: new FormControl(
          bacoAccountId,
          allocationType === AllocationType.Percentage
            ? Validators.required
            : customValidatorRequired
        ), //[Validators.required]
        bacoTaxOptionId: new FormControl(bacoTaxOptionId),
        description: new FormControl(description),
      });

    return allocationRow;
  }

  public onAccountSelectionChanged(
    newValue: BacoAccountDto,
    ruleAllocationFormGroup: FormGroup<RuleAllocationFormRow>
  ): void {
    const allocationBacoTaxOptionControl =
      ruleAllocationFormGroup.controls.bacoTaxOptionId;
    const accountOption = this._feedStore.getAccount(newValue?.accountId);

    const taxOption = accountOption?.taxOptionId
      ? this._feedStore.getTaxOption(accountOption.taxOptionId)
      : null;
    allocationBacoTaxOptionControl.setValue(taxOption?.taxId, {
      onlySelf: false,
      emitEvent: true,
    });
  }

  public onTaxOptionSelectionChanged(
    taxOption: BacoTaxOptionDto,
    lineIndex: number
  ): void {
    const allocationRow =
      this.form.controls.percentageAllocations.controls[lineIndex];
    if (allocationRow) {
      allocationRow.controls.bacoTaxOptionId.setValue(taxOption?.taxId, {
        onlySelf: true,
        emitEvent: true,
      });
      allocationRow.controls.bacoTaxOptionId.updateValueAndValidity();
    }
  }

  public ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  public onClearDescription(): void {
    this.form.controls.descriptionCondition.controls.conditionValue.setValue(
      null
    );
  }

  public onClearAmount(): void {
    this.form.controls.amountCondition.controls.conditionValue.setValue(null);
  }

  private configureSubmit() {
    this.submitButtonStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap((_) => this.submitObservable())
      )
      .subscribe((client) => {
        this.activeModal.close(client);
      });
  }

  private submitObservable(): Observable<BacoRuleDto> {
    let observable: Observable<BacoRuleDto>;
    const formValue = this.form.getRawValue();

    if (formValue.allocateBy === 'Percentage Only') {
      formValue.amountAllocations = [];
    }

    const descriptionConditionOption =
      formValue.descriptionCondition.conditionOption;
    const descriptionConditionValue =
      descriptionConditionOption === StringConditionOption.AllWords ||
      descriptionConditionOption === StringConditionOption.AnyWords
        ? formValue.descriptionCondition.conditionValue
            .replace(/\s+/g, ' ') // replaces all sequences of white spaces (one or more) with a single space
            .trim()
        : formValue.descriptionCondition.conditionValue;

    const formAllocations = [
      ...formValue.amountAllocations,
      ...formValue.percentageAllocations,
    ];
    const allocationConditions: BacoRuleAllocationCreateModel[] =
      formAllocations.map((x) => {
        return {
          allocationType: x.allocationType,
          allocationValue: x.allocationValue,
          bacoAccountId: x.bacoAccountId,
          bacoTaxOptionId: x.bacoTaxOptionId,
          description: x.description,
        };
      });

    const request: BacoRuleCreateModel = {
      name: formValue.name,
      amountConditionOption: formValue.amountCondition.conditionOption,
      amountConditionValue:
        typeof formValue.amountCondition.conditionValue === 'number'
          ? formValue.amountCondition.conditionValue
          : null,
      descriptionConditionOption,
      descriptionConditionValue,
      movementType: formValue.movementType,
      allocations: allocationConditions,
    };

    if (this.id) {
      observable = this.ruleStore.updateRule(this.id, request);
    } else {
      observable = this.ruleStore.createRule(request);
    }

    const loadingStream = new Subject();
    this.busy.submit = loadingStream.subscribe();

    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  private showError(error) {
    this.error = error;
    this.messageService.error(error);
  }
}

export interface RuleAllocationFormRow {
  allocationType: FormControl<AllocationType | null>;
  allocationValue: FormControl<number | null>;
  bacoAccountId: FormControl<string>;
  bacoTaxOptionId: FormControl<string | null>;
  description: FormControl<string | null>;
}

export interface RuleForm {
  name: FormControl<string>;
  movementType: FormControl<RuleMovementType>;
  descriptionCondition: FormGroup<DescriptionConditionForm>;
  amountCondition: FormGroup<AmountConditionForm>;
  allocateBy: FormControl<AllocateBy>;
  amountAllocations: FormArray<FormGroup<RuleAllocationFormRow>>;
  percentageAllocations: FormArray<FormGroup<RuleAllocationFormRow>>;
}

export interface DescriptionConditionForm {
  conditionOption: FormControl<StringConditionOption>;
  conditionValue: FormControl<string>;
}

export interface AmountConditionForm {
  conditionOption: FormControl<ValueConditionOption>;
  conditionValue: FormControl<number>;
}

export type AllocateBy = 'Percentage Only' | 'Amount & Percentage';
