<ng-select [items]="assetGroups$ | async"
           bindLabel="name"
           [clearable]="clearable"
           [compareWith]="compareWith"
           [readonly]="readonly"
           [placeholder]="placeholder"
           [closeOnSelect]="!multiple"
           (open)="onOpen()">
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="multiple">
    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.name}}
  </ng-template>
</ng-select>
