import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { ApiError } from './api-error';
import { Router } from '@angular/router';
import { ErrorPageType } from '../components';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(50000),
      catchError(err => {
      console.log(err);

      if(err.name === 'TimeoutError')
      {
        return this.buildError(0, 'This request is taking a while in the background, please check later to see if the request is completed.', true);
      }
      if(err.error?.type === 'abort')
      {
        return this.buildError(0, 'This request is taking a while in the background, please check later to see if the request is completed.', true);
      }

      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authService.onLogout();
        this.authService.login();
      }

      if (err.status === 0)
      {
        //console.log(err);
        return this.buildError(0, 'Web connectivity error.', false);
      }

      if (err.status === 400) {
        if (err.error && err.error.errors) {
          const errors = [err.error.title];
          Object.keys(err.error.errors).forEach(function(key, index) {
            errors.push( key + ': ' + err.error.errors[key]);
          });
          return this.buildError(err.status, errors, false);
        }
      }

      if (err.status === 403) {
        this.router.navigate(['error', ErrorPageType.AccessDenied]);
      }

      if (err.status === 404) {
        if (err.error) return this.buildError(err.status, err.error, false);
        else if (err.statusText === 'OK') return this.buildError(err.status, 'There was an API communication error.', false);
      }

      if (err.status === 415 || err.status === 405) {
        return this.buildError(err.status, 'Invalid call to the API.', false);
      }

      if (err.status === 500) {
        return this.buildError(err.status, 'Something went wrong. Please contact support if the issue persists.', false);
      }

      const error = err.error || err.statusText;
      return this.buildError(err.status, error, false);
    }));
  }

  buildError(code: number, messages: string | string[], isWarning: boolean) {
    return throwError(new ApiError(code, messages, isWarning));
  }
}
