import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../core';
import { TradingAccount } from './tradingAccount';

@Injectable({
  providedIn: 'root',
})
export class TradingAccountService {
  readonly base: string = 'api/accounting/tradingAccounts';

  constructor(private apiService: ApiService) {}

  get(id: string) {
    return this.apiService
      .get<TradingAccount>(`${this.base}/${id}`)
      .pipe(map((d) => new TradingAccount(d)));
  }

  getAll(fileId: string) {
    return this.apiService
      .get<TradingAccount[]>(`${this.base}/forfile/${fileId}`)
      .pipe(
        map((tradingAccounts) =>
          tradingAccounts.map(
            (tradingAccount) => new TradingAccount(tradingAccount)
          )
        )
      );
  }

  post(tradingAccount: TradingAccount) {
    return this.apiService.post<any>(this.base, tradingAccount);
  }

  put(tradingAccount: TradingAccount) {
    return this.apiService.put(
      `${this.base}/${tradingAccount.id}`,
      tradingAccount
    );
  }

  delete(id: string) {
    return this.apiService.delete(`${this.base}/${id}`);
  }
}
