import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../core';
import { ReportPageType, ReportPageTypeGroup } from './reportPageType';
import { HeaderTemplate, FooterTemplate } from '../reportTemplates';


@Injectable({
  providedIn: 'root'
})
export class ReportPageTypeService {

  readonly base = 'api/accounting/reports/pageTypes';


  constructor(private readonly _apiService: ApiService) { }

  get(id: number): Observable<ReportPageType> {
    return this._apiService.get<any>(this.base + '/' + id).pipe(map(l => new ReportPageType(l)));
  }

  getAll(): Observable<ReportPageType[]> {
    return this._apiService.get<any[]>(this.base).pipe(map(l => l.map(d => new ReportPageType(d))));
  }

  getAllForAdmin(): Observable<ReportPageType[]> {
    return this._apiService.get<any[]>(this.base + '/admin').pipe(map(l => l.map(d => new ReportPageType(d))));
  }

  getAllGrouped(): Observable<ReportPageTypeGroup[]> {
    return this.getAll().pipe(map(list => {
        const groups: ReportPageTypeGroup[] = [];

        list.forEach(detail => {
          var group = groups.find(g => g.key === detail.category);
          if (group) {
            group.items.push(detail);
          } else {
            groups.push(new ReportPageTypeGroup(detail.category, [detail]));
          }
        });

        return groups;
      }
    ));
  }

  getUiPackage(): Observable<{ headers: HeaderTemplate[], footers: FooterTemplate[] }>{

    return this._apiService.get<any>(this.base + '/package').pipe(map(p => {
      return {
        headers: p.headers.map(h => new HeaderTemplate(h)),
        footers: p.footers.map(f => new FooterTemplate(f))
      }
    }));

  }

  reorder(id: number, index: number): Observable<any> {
    return this._apiService.put(this.base + '/' + id + '/reorder', index);
  }

  post(client: ReportPageType) {
    return this._apiService.post<string>(this.base, client);
  }


  put(client: ReportPageType) {
    return this._apiService.put(this.base + '/' + client.id, client);
  }

  delete(id: number) {
    return this._apiService.delete(this.base + '/' + id);
  }


}
