import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuditorPracticeName } from '../model/auditor';
import { Address } from '../model/address';

@Component({
  selector: 'crs-auditor',
  templateUrl: './auditor.component.html',
  styleUrls: ['./auditor.component.scss'],
})
export class AuditorComponent implements OnInit {
  @Input('formGroup') formGroup: UntypedFormGroup;
  @Input('columnClass') columnClass?;
  string;
  @Input() copyFromAddress: Address;
  @Input() copyFromName: AuditorPracticeName;
  @Input() auditorNumberRequired: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.columnClass) this.columnClass = 'col-12';
  }

  executeCopyFrom(event) {
    if (!event.target.checked) return;

    this.formGroup.patchValue(this.copyFromAddress);
    this.formGroup.patchValue({ practiceName: this.copyFromName });
  }
}
