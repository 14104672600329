import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';

import { DatasetSelectComponent } from './datasets/dataset-select/dataset-select.component';
import { AccountTypeSelectComponent } from './ledger/account-types/account-type-select/account-type-select.component';
import { AccountSelectComponent } from './ledger/accounts/account-select/account-select.component';
import { MasterAccountSelectComponent } from './ledger/masterAccounts/master-account-select/master-account-select.component';
import { SourceSelectComponentSimple } from './source-data/sources/source-select-simple/source-select-simple.component';
import { SourceIconComponent } from './source-data/sources/source-icon/source-icon.component';
import { StandardAccountSelectComponent } from './ledger/standardAccounts/standard-account-select/standard-account-select.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    DatasetSelectComponent,
    AccountTypeSelectComponent,
    AccountSelectComponent,
    MasterAccountSelectComponent,
    SourceIconComponent,
    SourceSelectComponentSimple,
    StandardAccountSelectComponent,
  ],
  exports: [
    DatasetSelectComponent,
    AccountTypeSelectComponent,
    AccountSelectComponent,
    MasterAccountSelectComponent,
    SourceIconComponent,
    SourceSelectComponentSimple,
    StandardAccountSelectComponent,
  ],
})
export class AccountingCoreModule {}
