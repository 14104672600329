import { TaxSection } from './taxSection';

export class TaxLabel {
  id: number;
  sortOrder: number;
  section: TaxSection;
  labelNo: string;
  name: string;
  get displayName() {
    return this.labelNo + ' ' + this.name;
  }
  constructor(data){
    Object.assign(this, data);
    if (data.section) this.section = new TaxSection(data.section);
  }
}
