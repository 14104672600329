import {
  Component,
  ContentChildren,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ErrorMessage } from '../../Models/ErrorMessage';
import { ErrorMessageService } from '../../Services/error-message.service';
import { MessagesComponent } from '../messages/messages.component';

@Component({
  // tslint:disable:component-selector
  selector: '.form-group',
  template: `
    <ng-content></ng-content>
    <crs-messages
      class="invalid-feedback {{ errorsWidth }}"
      *ngIf="!messagesBlock"
      [messages]="messages"
    ></crs-messages>
  `,
})
export class FormGroupComponent implements OnInit, AfterContentInit {
  @ContentChildren(NgControl, { descendants: true })
  FormControls: QueryList<NgControl>;

  @Input() customErrorMessages: ErrorMessage[] = [];
  @Input() validationDisabled = false;
  @Input() errorsWidth = '';

  @HostBinding('class.is-invalid')
  get hasErrors() {
    return (
      this.FormControls.some((c) => !c.disabled && !c.valid && c.touched) &&
      !this.validationDisabled
    );
  }

  @ContentChild(MessagesComponent, { static: false })
  public messagesBlock: MessagesComponent;

  private errorMessages: ErrorMessage[];
  public messages = () => this.getMessages();

  constructor(
    private elRef: ElementRef,
    private errorMessageService: ErrorMessageService
  ) {}

  ngAfterContentInit() {
    if (this.messagesBlock) {
      this.messagesBlock.messages = this.messages;
    }
  }

  ngOnInit() {
    this.errorMessages = this.errorMessageService.errorMessages
      .concat(this.customErrorMessages)
      .reverse();
  }

  get label() {
    const label = this.elRef.nativeElement.querySelector('label');
    return label && label.textContent ? label.textContent.trim() : 'This field';
  }

  get isTouched() {
    return this.FormControls.some((c) => c.touched);
  }

  private getMessages(): string[] {
    const messages = [];
    if (!this.isTouched || this.validationDisabled) {
      return messages;
    }

    this.FormControls.filter((c) => !c.valid && !!c.errors).forEach(
      (control) => {
        Object.keys(control.errors).forEach((key) => {
          const error = this.errorMessages.find((err) => err.error === key);
          if (!error) {
            return;
          }
          messages.push(error?.format(this.label, control.errors[key]));
        });
      }
    );

    return messages;
  }
}
