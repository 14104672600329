<div
  class="h-100 d-flex align-items-center"
  *ngIf="currentState$ | async as currentState"
>
  <ng-container *ngIf="!currentState.isSplit">
    <div
      class="data-cell tax-account-name"
      *ngIf="currentState.selectedTax"
      [title]="currentState.selectedTax?.code - currentState.selectedTax?.name"
    >
      {{ currentState.selectedTax?.code }} -
      {{ currentState.selectedTax?.name }}
    </div>
    <div
      class="empty-cell d-flex align-items-center"
      *ngIf="!currentState.selectedTax && currentState.canEdit"
    >
      <div class="mr-5">Select Tax</div>
      <i class="fa fa-chevron-down"></i>
    </div>
  </ng-container>
</div>
