import { BehaviorSubject, combineLatest, EMPTY, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from "@angular/core";
import { Stateful, StatefulHelpers } from '../../interfaces/stateful.interface';
import { BacoRuleDto, BacoRuleCreateModel } from '../../interfaces';
import { BacoRuleClient, FeedClient } from '../../common';
import { BacoFeedStore } from './baco-feed.store';
import { BacoTransactionStore } from './baco-transaction.store';
import { MessageService } from 'src/app/core';

@Injectable()
export class BacoRuleStore implements OnDestroy {
  public _destroy$: Subject<boolean> = new Subject<boolean>();

  public readonly rules$ = new BehaviorSubject<Stateful<BacoRuleDto[]>>(StatefulHelpers.pending<BacoRuleDto[]>());
  public readonly refreshRules$ = new BehaviorSubject<void>(null);

  constructor(
    private readonly _feedClient: FeedClient, 
    private readonly _ruleClient: BacoRuleClient, 
    private readonly _feedStore: BacoFeedStore,
    private readonly _transactionStore: BacoTransactionStore,
    private readonly _messageService: MessageService) {
      this.init();
  }

  public init() {
    const feedChanged$ = this._feedStore.feed$.pipe(
      filter(statefulFeed => statefulFeed.state === 'SUCCESS'),
    );

    combineLatest([
      feedChanged$,
      this.refreshRules$
    ]).pipe(
      tap(() => {
        let transactions = this.rules$.getValue();
        transactions.state = 'PENDING';
        this.rules$.next(transactions);
      }),
      switchMap(([ feed ]) => this._feedClient.getRules(feed.data.id).pipe(
        catchError(err => {
          this.rules$.next(StatefulHelpers.error<BacoRuleDto[]>(err));
          return EMPTY;
        })
      )),
      tap((rules) => this.rules$.next(StatefulHelpers.success<BacoRuleDto[]>(rules))),
      takeUntil(this._destroy$),
    ).subscribe();
  }

  public createRule(request: BacoRuleCreateModel) {
    const feedId = this._feedStore.feed$.getValue().data.id;
    return this._feedClient.createRule(feedId, request)
      .pipe(
        tap(newRule => {
          const rules = this.rules$.getValue();
          rules.data.push(newRule);
          this.rules$.next(rules);
          this._transactionStore.refreshTransactions$.next();
        })
      );
  }

  public updateRule(ruleId: string, request: BacoRuleCreateModel) {
    return this._ruleClient.update(ruleId, request)
      .pipe(
        tap(newRule => {
          const rules = this.rules$.getValue();
          const oldRuleIndex = rules.data.findIndex(x => x.id === ruleId);
          if (oldRuleIndex >= 0) {
            rules.data.splice(oldRuleIndex, 1, newRule);
            this.rules$.next(rules);
          } else {
            this.refreshRules$.next();
          }
          this._transactionStore.refreshTransactions$.next();
        })
      );
  }

  public removeRule(ruleId: string) {
    const rules = this.rules$.getValue();
    const ruleIndex = rules.data.findIndex(x => x.id === ruleId);
    if (ruleIndex >= 0) {
      rules.data.splice(ruleIndex, 1);
      this.rules$.next(rules);
    }
  }

  public changeRuleOrder(rule: BacoRuleDto, newIndex: number) {
    const rules = this.rules$.getValue();
    const rulesData = rules.data;
    if (!rulesData) {
      return;
    }

    rulesData.splice(rulesData.findIndex(x => x.id === rule.id), 1);
    rulesData.splice(newIndex, 0, rule);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}