<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.load"></crs-busy>
    <crs-form-error [error]="error"></crs-form-error>
    <div class="btn-menu pad-no-bottom">
      <button
        class="btn btn-secondary"
        type="button"
        (click)="addDisclosureTemplate()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Disclosure Template
      </button>
    </div>
    <div>
      <crs-progress-bar id="gridLoadingBar"></crs-progress-bar>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="disclosureTemplates"
        (rowClicked)="selectDisclosureTemplate($event)"
      />
    </div>
  </div>
</div>

<div class="spacer-lg"></div>
