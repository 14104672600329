import { ModalService } from './../../../core/modals/modal.service';
import { OwnerCreateModalComponent } from './../../firmUsers/owner-create-modal/owner-create-modal.component';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Subject, Observable, EMPTY, concat } from 'rxjs';
import { tap, exhaustMap, catchError, finalize } from 'rxjs/operators';

import { getDefaultGridOptions } from 'src/app/shared';
import { FirmService } from '../firm.service';
import { MessageService } from 'src/app/core';
import { FirmCreateModel, FirmModel } from '../firm';
import { SecurityRole } from 'src/app/firm/users';
import { FirmUser } from '../../firmUsers/firmUser';
import { Patterns } from '../../../shared/validators';


@Component({
  selector: 'crs-firm',
  templateUrl: './firm.component.html'
})
export class FirmComponent implements OnInit {

  id: string;
  isAdd: boolean;
  busy = {
    load: null,
    loadUsers: null,
    submit: null
  };
  error: string = null;

  gridOptions = getDefaultGridOptions();

  interactionStream = new Subject();

  securityRoles = SecurityRole;
  owners: FirmUser[] = [];

  form = this._formBuilder.group({
    id: [null],
    tenantId: [null, [Validators.required, Validators.maxLength(64)]],
    name: [null, [Validators.required, Validators.maxLength(512)]],
    crmId: [null, [Validators.maxLength(16)]],
    database: [null, Validators.required],
    storageAccount: [null, Validators.required],
    workpapersFirmId: [null],
    email: [null],
    firstName: [null],
    lastName: [null]
  });

  constructor(private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _firmService: FirmService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService) {

    }

  ngOnInit() {

    this.gridOptions.getRowNodeId = data => data.id;

    this._route.params.subscribe(() => {
      this.id = this._route.snapshot.paramMap.get('id');
      this.isAdd = this.id === 'add';
      if (this.isAdd) {
        this.form.controls.email.setValidators([Validators.required, Validators.maxLength(512), Validators.pattern(Patterns.emailRegExp)]);
        this.form.controls.firstName.setValidators([Validators.required, Validators.maxLength(512)]);
        this.form.controls.lastName.setValidators([Validators.required, Validators.maxLength(512)]);
      }
      this.loadFirm();
    });

    this.configureInteraction();
  }

  submit() {
    this.interactionStream.next();
  }

  private configureInteraction() {
    this.interactionStream
      .pipe(
        tap(() => this.error = null),
        exhaustMap(() => this.handleInteraction())
      )
      .subscribe(() => {
        this.close();
      });
  }

  private handleInteraction(): Observable<any> {

    let observable: Observable<any>;
    const loadingStream = new Subject();


    if (this.isAdd) {
      const model = new FirmCreateModel(this.form.value);
      observable = this._firmService.post(model);
    } else {
      const model = new FirmModel(this.form.value);
      model.id = parseInt(this.id, 10);
      observable = this._firmService.put(model);
    }

    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete()));

    return observable;
  }

  private loadFirm() {
        this.busy.load = this.isAdd ? EMPTY : concat(
          this._firmService.get(parseInt(this.id, 10)).pipe(
            tap(firm => this.form.patchValue(firm))
          ),
        this._firmService.getOwners(parseInt(this.id, 10)).pipe(
          tap(users => this.owners = users)
        )).subscribe(() => true, e => this.showError(e));
  }

  addOwner() {
    this._modalService.openModal(OwnerCreateModalComponent, this.id).then(() => {
      this.busy.load = this._firmService.getOwners(parseInt(this.id, 10)).pipe(
        tap(users => this.owners = users)
      ).subscribe(() => true, e => this.showError(e));
    }).catch(() => true);
  }

  resendInvite(userId: string) {
    this._firmService.resendInvite(parseInt(this.id, 10), userId)
      .subscribe(() => this._messageService.success('Successfully sent invite'), e => this._messageService.error(e));
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
    this._messageService.error(error, true);
  }

}
