<div class="row">
  <div class="col-md-3" *ngIf="isConsolidated">
    <div class="left-menu p-4">
      <div>
        <crs-form-error [error]="error"></crs-form-error>

        <crs-busy *ngIf="busy.entities"></crs-busy>

        <div class="margin-bottom">
          <button
            class="btn btn-insert"
            title="Create Entity"
            title="Link an entity to this file"
            type="button"
            (click)="addEntity()"
          >
            <i aria-hidden="true" class="fas fa-plus inset"></i>
            Link entity
          </button>
        </div>

        <div class="list-group-primary" *ngIf="entities">
          <div
            class="list-group-item clickable"
            *ngFor="let entity of entities"
            [ngClass]="{ active: entity.id == entityId }"
            (click)="activateEntity(entity.id)"
          >
            <div
              class="d-flex justify-content-between align-items-center h-100"
            >
              <div class="d-inline-block">
                <i
                  aria-hidden="true"
                  class="fas fa-folder-open mr-2 fas-btn"
                ></i>
                <div class="d-inline-block align-middle">
                  <strong>{{ entity.legalName }}</strong
                  ><br />
                  <small>{{ entity.tradingName }}</small>
                </div>
              </div>

              <div class="d-inline-block">
                <i
                  aria-hidden="true"
                  class="fas fa-cog mr-3 fas-btn"
                  (click)="editEntity(entity.id)"
                ></i>
                <i
                  aria-hidden="true"
                  class="fas fa-trash mr-3 fas-btn"
                  (click)="removeEntity(entity)"
                ></i>
                <i aria-hidden="true" class="fas fa-chevron-right fas-btn"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="spacer"></div>
      </div>
    </div>
  </div>

  <div
    class="col-md-9"
    *ngIf="!isConsolidated || (entityId && !busy.entities)"
    [class.col-md-12]="!isConsolidated"
  >
    <crs-busy *ngIf="busy.datasets"></crs-busy>

    <div class="btn-menu pad-no-bottom">
      <button class="btn btn-secondary" (click)="addDataset(false)">
        <i aria-hidden="true" class="fas fa-file-download inset"></i>
        New dataset
      </button>
      <button class="btn btn-secondary" (click)="addDataset(true)">
        <i aria-hidden="true" class="fas fa-copy inset"></i>
        New consolidated dataset
      </button>
    </div>

    <div style="overflow: hidden; flex-grow: 1">
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="datasetsObservable | async"
        (rowClicked)="selectDataset($event)"
      >
        <ag-grid-column
          field="name"
          headerName="Name"
          [minWidth]="250"
        ></ag-grid-column>
        <ag-grid-column
          field="startDate"
          headerName="Start Date"
          type="dateColumn"
        ></ag-grid-column>
        <ag-grid-column
          field="endDate"
          headerName="End Date"
          type="dateColumn"
        ></ag-grid-column>
        <ag-grid-column
          cellRenderer="sourceRenderer"
          field="source"
          headerName="Source"
          [maxWidth]="500"
          [minWidth]="200"
        ></ag-grid-column>
        <ag-grid-column
          field="lastImported"
          headerName="Last Import"
          type="timeColumn"
        ></ag-grid-column>
        <ag-grid-column
          cellRenderer="userRenderer"
          field="lastImportedBy"
          headerName="Last Import By"
          [maxWidth]="300"
          [minWidth]="200"
        ></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>
