<div class="modal-header">
  <h4 class="modal-title text-danger">Confirm</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

  <div class="modal-body">

    <div class="alert alert-danger">
      Are you absolutely sure you want to delete this file? This action <strong>cannot</strong> be undone, it will permanently delete the file and all associated ledger and report data.
    </div>

    <div class="mt-3 mb-3">
      Please type in the name of the file, <strong>{{file.name}}</strong>, to confirm.
      <div class="form-group required">
        <input type="text" class="form-control" formControlName="passPhrase" />
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-danger" [promiseBtn]="busy.submit">I understand the consequences, delete this file</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  </div>

</form>
