<div *ngIf="active">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <iframe *ngIf="iframeMode" #iframe [class.d-none]="!url" style="width: 100%" height="600" (load)="onLoad(iframe)" [src]="url | safe" title="Third Party API Login"></iframe>
      <div *ngIf="!iframeMode" style="width: 100%; height: 600px">

          <div *ngIf="!processing">
            <div>Please login through the newly opened tab</div>
            <div class="btn-menu mt-2">
              <button class="btn btn-secondary" type="button" (click)="cancel()"> Cancel</button>
            </div>
          </div>
          <crs-busy *ngIf="processing"></crs-busy>

      </div>
    </div>
  </div>

</div>

