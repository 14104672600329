import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BACO_DATE_FORMAT } from '../baco.tokens';

@Injectable({
  providedIn: 'root'
})
export class ParamHelperService {

  constructor(
    private readonly _datePipe: DatePipe,
    @Inject(BACO_DATE_FORMAT) private dateFormat: string) {
  }

  public toStringOrNull(val: any) : string | null {
    return typeof val === 'string' ? val : null;
  }

  public toBooleanOrDefault(val: any, defaultValue = false) : boolean {
    if (val === 'true' || val === '1') {
      return true;
    } else if (val === 'false' || val === '0') {
      return false;
    }

    return defaultValue;
  }

  public toDateOrNull(val: any, throwOnInvalid = false) : Date | null {
    if (!val) {
      return null;
    }
    
    //For the yyyy-MM-dd date parse if there is no T00:00 prefix date parses string as UTC time.
    //Ex: new Date('2020-01-01').toJSON() will be '2020-01-01T00:00:00.000Z' **Wrong time for the client local time
    //Ex: new Date('2020-01-01T00:00').toJSON() will be '2019-12-31T14:00:00.000Z' **Expected UTC time for the client time
    if(/\d{4}-\d{2}-\d{2}/.test(val)){
      val += 'T00:00';
    }

    const date = new Date(val);

    if (isNaN(date.valueOf())) {
      if (throwOnInvalid) {
        throw `${val} is an invalid date`;
      }

      return null;
    }

    return date;
  }
}
