import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageModel, Image } from '../image.class';
import { ImageService } from '../image.service';
import { UploadedImageFile } from 'src/app/shared/components/file-upload/file-upload.component';
import { ModalService } from './../../../../../core/modals/modal.service';
import { getDefaultGridOptions } from 'src/app/shared';
import { ImageVariantComponent } from './image-variant/image-variant.component';

@Component({
  selector: 'crs-image',
  templateUrl: './image.component.html',
  styleUrls: [
    './image.component.scss'
  ]
})
export class ImageComponent implements OnInit {

  image: Image;
  id: string;
  isAdd: boolean;
  error;

  load = null;

  private _imageVariants: Image[] = [];
  set imageVariants(value: Image[]) {
    this._imageVariants = value ?? [];
    if (this.gridOptions.api) this.gridOptions.api.setRowData(this._imageVariants);
  }
  get imageVariants() {
    return this._imageVariants;
  }

  private _imageVariantsCache: { image: Image, uploadedFile: UploadedImageFile }[] = [];
  
  gridOptions = getDefaultGridOptions();

  constructor(private readonly _imageService: ImageService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _modalService: ModalService) { }

  ngOnInit(): void {
    this._route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.isAdd = this.id === 'add';
      this.getData();
    });

    
  }

  private getData() {
    this.image = null;

    if (this.isAdd) {
      const queryParams = this._route.snapshot.queryParamMap;
      const parentImageId = queryParams.get('parentImageId');
      this.image = new Image({ allowFileVariants: false, parentImageId: parentImageId });
      this.imageVariants = [];
    } else {
      this.load = this._imageService.get(this.id).subscribe(i => {
        this.image = i;
        this.imageVariants = i.imageVariants;
      }, err => this.showError(err));
    }
  }  

  addImageVariant() {
    const image = new Image({ allowFileVariants: false, parentImageId: this.image.id });
    this._modalService.openModal(ImageVariantComponent, this.image.id, { image: image } )
      .then(result => this.subminImageVariant(result))
      .catch(() => true);
  }

  selectImageVariant(image: Image) {
    if(this.isAdd) {
      const imageVariant = this._imageVariantsCache.find(i => i.image.id == image.id)

      this._modalService.openModal(ImageVariantComponent, this.image.id, imageVariant)
        .then(result => this.subminImageVariant(result))
        .catch(() => true);
    } else {
      this._imageService.get(image.id).subscribe(i => {
        this._modalService.openModal(ImageVariantComponent, this.image.id, { image: i })
        .then(result => this.subminImageVariant(result))
        .catch(() => true);
      }, err => this.showError(err));
    }
  }

  subminImageVariant({ image, isDelete }) {
    if(!this.isAdd) {
      this.getData();
    } else {
      if(isDelete) {
        this._imageVariantsCache = this._imageVariantsCache.filter(img => img.image.id != image.image.id);
      } else {
        this._imageVariantsCache.push(image);
      }

      this._imageVariantsCache.forEach((img, i) => {
        img.image.id = (i + 1).toString();
      });

      this.imageVariants = this._imageVariantsCache.map(img => img.image);
    }
  }

  private showError(err) {
    this.error = err;
  }

  submit(image) {
    if(this.isAdd && this._imageVariantsCache.length > 0) {
      const models = this._imageVariantsCache.map(img => {
        const model = new ImageModel(img.image, img.uploadedFile.uploadedFile);
        model.id = null;
        model.parentImageId = image.id;
        return model;
      });

      this._imageService.postVariants(models)
        .subscribe(() => this.close());
    } else {
      this.close();
    }
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }
}
