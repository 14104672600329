<ng-select
  bindLabel="name"
  [clearable]="clearable"
  [groupBy]="displayGrouping()"
  [items]="accountTypesObservable | async"
  [readonly]="readonly"
  [typeahead]="accountTypeInput"
  [virtualScroll]="true"
  (open)="onOpen()"
>
  <ng-template let-item="item" ng-optgroup-tmp>
    {{ classifications[item.classification] }}
  </ng-template>
</ng-select>
