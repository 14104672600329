import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import { Dataset } from '../..';

export class DepreciationYear {
  id: string;
  year: number;
  startDate: Date;
  endDate: Date;
  taxYear: number;
  useSmallBusinessDepreciation: boolean;
  useMonthlyBusinessDepreciation: boolean;
  isLocked: boolean;
  datasets: Dataset[] = [];

  constructor(data) {
    Object.assign(this, data);
    this.year = parseInt(data.year, 10);
    this.taxYear = parseInt(data.taxYear, 10);
    this.startDate = DateHelpers.dateFromApiResponse(data.startDate);
    this.endDate = DateHelpers.dateFromApiResponse(data.endDate);
    if (data.datasets) this.datasets = data.datasets.map(d => new Dataset(d));
  }

  public fallsInYear(date: Date) {
    if (!date) return false;
    const dateTime = date.getTime();
    return dateTime >= this.startDate.getTime() && dateTime <= this.endDate.getTime();
  }
}

export class DepreciationYearModel {
  id: string;
  fileId: string;
  entityId: string;
  year: number;
  startDate: Date;
  endDate: Date;
  taxYear: number;
  useSmallBusinessDepreciation: boolean;
  useMonthlyBusinessDepreciation: boolean;
  isLocked: boolean;
  datasetIds: string[];

  constructor(data) {
    Object.assign(this, data);
    if (data.datasets) this.datasetIds = data.datasets.map(d => d.id);
  }
}
