import { BehaviorSubject } from 'rxjs';
import { File } from './files/file';
import { filter } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class ActiveFileService {

  file: File;
  private _fileStream = new BehaviorSubject<File>(null);
  stream = this._fileStream.asObservable().pipe(filter(f => !!f));

  constructor() { }

  updatefile(file: File, force: boolean = false) {
    if (!this.file && !file) {
      this.file = new File({});
      return;
    }
    if (!force && this.file && file && this.file.id === file.id) return;
    this.file = file;
    this._fileStream.next(file);
  }

}
