import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../core';
import { Journal, JournalModel } from './journal';
import { JournalPackage } from './journal-package';

@Injectable({
  providedIn: 'root',
})
export class JournalService {
  readonly base: string = 'api/accounting/ledger/journals';

  constructor(private apiService: ApiService) {}

  get$(id: string, condenseGstIntoParent: boolean = false) {
    return this.apiService.get<Journal>(this.base + '/' + id).pipe(
      map((journal) => {
        if (condenseGstIntoParent) {
          this.mergeGstLines(journal);
        }

        return new Journal(journal);
      })
    );
  }

  getForDataset$(datasetId: string) {
    return this.apiService
      .get<Journal[]>(this.base + '/fordataset/' + datasetId)
      .pipe(map((a) => a.map((j) => new Journal(j))));
  }

  /**
   * Retrieves a package of related info to assist journal preparation in the UI
   * @param datasetId The dataset id for the journal
   */
  getPackage$(datasetId: string, isAdd: boolean) {
    let url = this.base + '/package';
    url = this.apiService.addQuery(url, 'datasetId', datasetId);
    url = this.apiService.addQuery(url, 'newJournal', isAdd);
    return this.apiService
      .get<any>(url)
      .pipe(map((p) => new JournalPackage(p)));
  }

  post$(journal: JournalModel) {
    return this.apiService.post<any>(this.base, journal);
  }

  postBalancingJournal$(
    datasetId: string,
    exportToSourceSystem: boolean,
    date: Date = null,
    ids: string[] = null
  ) {
    let url = this.base + '/balancing-journal/' + datasetId;
    const content = {
      exportToSourceSystem,
      date,
      ids,
    };
    return this.apiService.post<any>(url, content);
  }

  undoBalancingJournal$(journalId: string) {
    return this.apiService.delete(
      this.base + '/balancing-journal/' + journalId
    );
  }

  put$(journal: JournalModel) {
    return this.apiService.put(this.base + '/' + journal.id, journal);
  }

  delete$(id: string) {
    return this.apiService.delete(this.base + '/' + id);
  }

  mergeGstLines(journal: Journal) {
    const gstLineIdMap = {};

    // First pass: Create a map for quick look-up based on gstLineId
    for (let line of journal.journalLines) {
      if (line.gstLineId) {
        gstLineIdMap[line.gstLineId] = line;
      }
    }

    // Second pass: Merge the lines
    for (let i = 0; i < journal.journalLines.length; i++) {
      const line = journal.journalLines[i];
      if (line.gstParentId && gstLineIdMap[line.gstParentId]) {
        const parent = gstLineIdMap[line.gstParentId];

        // Merge the balance
        parent.balance = Number(parent.balance) + Number(line.balance);
        parent.taxAmount = Number(line.balance);

        // Remove the current line (with gstParentId) from the array
        journal.journalLines.splice(i, 1);
        i--; // Adjust the loop counter since we removed an item
      }
    }
  }
}
