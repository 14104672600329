import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from '../..';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ClientEntitySearchComponent, ClientEntitySelection } from '../../clients/client-entity-search/client-entity-search.component';

@Component({
  selector: 'crs-entity-select-modal',
  templateUrl: './entity-select-modal.component.html',
  styleUrls: ['./entity-select-modal.component.scss']
})
export class EntitySelectModalComponent implements OnInit {

  @Input() id: string;
  @Input() params: {clientId: string} = {clientId: null};

  @ViewChild(ClientEntitySearchComponent, {static: false})
  private clientEntitySearchComponent: ClientEntitySearchComponent;

  error: string;
  activeSearchSession: boolean;

  search = new UntypedFormControl();
  selection = new ClientEntitySelection(null, null);

  constructor(public activeModal: NgbActiveModal,
    private clientService: ClientService) { }

  ngOnInit() {

    this.search.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.activeSearchSession = true),
      tap(search => this.clientEntitySearchComponent.search(search as string))
    )
    .subscribe();
  }

  getClient() {

  }

  clearActiveClient() {
    this.selection.client = null;
    this.search.setValue('');
  }

  clearActiveEntity()  {
    this.selection.client = null;
    this.selection.entity = null;
  }

  showError(error) {
    this.error = error;
  }

  onSelect(selection: ClientEntitySelection) {
    this.selection = selection;
    if (this.selection.entity) this.close();
  }

  close() {
    this.activeModal.close(this.selection.entity);
  }

}
