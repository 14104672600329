import { ICssSelectorDescriptor } from './../models/css-class-descriptor.interface';
import { ICssClassGroup } from './../models/css-class-group.interface';
import { Injectable } from '@angular/core';
import { ICssVariable, ICssEditModel, ICssFontUrl } from '../models';
import { EditableStyleInfo } from '../models/editable-style-info';
import { ReportOutput } from '../../../reportViewer/report-output';

@Injectable({
  providedIn: 'root',
})
export class CssProcessingService {

  public parseFontUrlsToRaw(fontUrls: ICssFontUrl[]): string {
    let css = '';

    if (!fontUrls) {
      return css;
    }

    fontUrls.forEach(fontUrl => {
      css += `@import url("${fontUrl.url}");\n`;
    });

    return css;
  }

  public replaceVariables(css: string, report: ReportOutput): string {

    let formattedCss = css;

    const variables = ['Branding.PrimaryColour'];

    variables.forEach(variable => {
      if (formattedCss.includes(variable)) {
        formattedCss = formattedCss.split(`{{${variable}}}`).join(report.branding.primaryColour);
      }
    });

    return formattedCss;
  }

  public getCustomWithDefaults(info: EditableStyleInfo, customCss: string) {
    const defaults = this.parseCss(info.defaultCss, info.descriptors, true);
    const custom = this.parseCss(customCss, info.descriptors, false);

    // Links the custom css into the 'default css' class list and gets the relevant selector
    defaults.classGroups.forEach((d) => {
      const customClass = custom.classGroups.find(
        (c) => c.selector.selector === d.selector.selector
      );
      if (customClass) d.customCss = customClass.customCss;
    });

    return defaults;
  }

  private parseCss(
    cssToParse: string,
    descriptors: ICssSelectorDescriptor[],
    isDefault: boolean
  ): ICssEditModel {
    const classList = [];

    if (cssToParse) {
      const lineSplits = cssToParse.split('\n');

      let classCss = '';
      lineSplits.forEach(line => {
        if (line) {
          classCss = `${classCss}\n${line}`;

          if (line.trim() === '}') {
            classList.push(classCss);
            classCss = '';
          }
        }
      });
    }


    const variablesList: ICssVariable[] = [];
    const classGroups: ICssClassGroup[] = [];

    classList.forEach((css) => {
      const cssSection = css;

      const cssStartIndex = this.findCssStartIndex(cssSection);
      if (cssStartIndex < 0) return;

      const selector = cssSection.substring(0, cssStartIndex).trim();
      const cssValue = cssSection
        .substring(cssStartIndex, cssSection.length)
        .substring(1);
      const cssEndIndex = this.findCssEndIndex(cssValue);

      const cssLineSplits = cssValue
        .substring(0, cssEndIndex)
        .split('\n')
        .filter((line) => line.length > 1);
      let rawCss = '';
      cssLineSplits.forEach((line, index) => {
        rawCss += line.trim();

        if (index !== cssLineSplits.length - 1) {
          rawCss += '\n';
        }

        if (line.includes('{{')) {
          const variable = line.split('{{')[1].split('}}')[0];
          if (
            !variablesList.find(
              (existingVariable) => existingVariable.variableName === variable
            )
          ) {
            variablesList.push({ variableName: variable, value: '' });
          }
        }
      });

      let descriptor = descriptors.find((d) => d.selector === selector);
      if (!descriptor) {
        descriptor = {
          selector: selector,
          friendlyName: selector,
          description: null,
        };
      }

      classGroups.push({
        selector: descriptor,
        customCss: isDefault ? null : rawCss,
        defaultCss: isDefault ? rawCss : null,
      });
    });

    return {
      classGroups: classGroups,
      variables: variablesList,
    };
  }

  public parseToRawCss(model: ICssEditModel): string {
    let rawCss = '';

    const groups = model.classGroups.filter((c) => c.customCss);
    groups.forEach((classGroup, index) => {
      rawCss += `${classGroup.selector.selector} {\n`;
      rawCss += `${classGroup.customCss}\n`;
      rawCss += `}\n`;

      if (groups.length - 1 !== index) {
        rawCss += `\n`;
      }
    });

    return rawCss;
  }

  private findCssStartIndex(css: string): number {
    if (!css) return -1;
    for (let i = 0; i < css.length; i++) {
      if (css[i] === '{') {
        return i;
      }
    }
  }

  private findCssEndIndex(css: string): number {
    for (let i = css.length; i > 0; i--) {
      if (css[i] === '}') {
        return i;
      }
    }
  }
}
