<div class="modal-header">
  <h4 class="modal-title">
    Upload transaction
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row" *ngIf="bacoTransactionStore.selectedBank$ | async as selectedBank;">
    <div class="col-12">
      <div class="upload-area" #dropZone
           (dragover)="onDragOver($event)"
           (dragleave)="onDragLeave($event)"
           (drop)="onDrop($event)">
        <ng-container>
          <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.66655 25.1665C7.0249 25.1684 5.4403 24.5644 4.21633 23.4704C2.99235 22.3764 2.21507 20.8692 2.0334 19.2377C1.85172 17.6061 2.27843 15.9649 3.23177 14.6284C4.18511 13.2919 5.59804 12.3542 7.19988 11.9949C6.73641 9.83337 7.15059 7.57627 8.35129 5.72013C9.55199 3.86399 11.4409 2.56085 13.6024 2.09738C15.7639 1.63392 18.021 2.04809 19.8771 3.24879C21.7333 4.4495 23.0364 6.33837 23.4999 8.49988H23.6665C25.7331 8.49781 27.7268 9.26368 29.2604 10.6488C30.7941 12.034 31.7584 13.9396 31.9661 15.9957C32.1738 18.0518 31.61 20.1118 30.3844 21.7757C29.1587 23.4395 27.3585 24.5886 25.3332 24.9999M21.9999 20.1665L16.9999 15.1665M16.9999 15.1665L11.9999 20.1665M16.9999 15.1665V35.1665"
              stroke="#9CA3AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <crs-file-dragdrop-upload [folder]="azureFolder"
                           [default-input]="false"
                           [input-text]="'Click to upload'"
                           input-sub-text="into {{selectedBank.accountName}}"
                           (filesUploaded)="onFileUpload($event)"
                           [acceptedFiles]="acceptedFiles"
                           [outerDropZoneRef]=dropZone
          >
          </crs-file-dragdrop-upload>
          <small>Supported file types: QIF or CSV</small>
        </ng-container>
      </div>
    </div>
  </div>
  CSV file type: Download the Bank Feeds <a href="./assets/excel-templates/bank-feeds-csv-template.csv">transaction template</a> to add transactions.
</div>

<div class="modal-footer">
  <button *ngIf="uploadedFiles.length"
          class="btn btn-primary"
          (click)="submit()"
          [promiseBtn]="busy.submit">
    Upload
  </button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
</div>
