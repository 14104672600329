import { User } from '../users/user';
import { Office } from '../offices';
import { Team } from '../teams';

export class Client {
  id: string;
  code: string;
  name: string;
  office: Office;
  team: Team;
  partner: User;
  manager: User;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.office) this.office = new Office(data.office);
    if (data.team) this.team = new Team(data.team);
    if (data.partner) this.partner = new User(data.partner);
    if (data.manager) this.manager = new User(data.manager);
  }
}

export class ClientModel {
  id: string;
  code: string;
  name: string;
  officeId: string;
  teamId: string;
  partnerId: string;
  managerId: string;

  constructor(data: any) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    if (data.office) this.officeId = data.office.id;
    if (data.team) this.teamId = data.team.id;
    if (data.partner) this.partnerId = data.partner.id;
    if (data.manager) this.managerId = data.manager.id;

  }
}
