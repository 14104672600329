import { ApiService } from './../../core/services/api.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core';
import { tap, catchError } from 'rxjs/operators';
import { MessageService } from './../../core';
import { throwError } from 'rxjs';
import { SessionService } from '../../core/services/session.service';
import { UserService } from '..';

@Component({
  selector: 'crs-sign-up',
  templateUrl: './sign-up.component.html',
})
export class SignUpComponent {
  constructor(private readonly _userService: UserService, private _sessionService: SessionService, private _authService: AuthService, private _route: ActivatedRoute, private _apiService: ApiService, private _messageService: MessageService) {
  }

  isSuccess: any;
  firmName: any;
  error: any;

  getSignUpToken(inviteToken: string)
  {
    var base = 'api/checkinvite/invite';
    var url = this._apiService.addQuery(base, "inviteToken", inviteToken);
    this._apiService.get<string>(url, {responseType: 'text'})
      .pipe(
        tap(() => this._messageService.success('Invite token is valid.'),
        err => {
          this._messageService.error(err);
          this.error = err;
        }))
      .subscribe(params => {
        this._authService.signUp(params)
        });
  }

  public ngOnInit() {
    if (!this._sessionService.active) {
      this._route.queryParams
        .subscribe(params => {
          this.getSignUpToken(params.id_token_hint);
        }
      );
    }
    else {
      this._userService.clearInvites().subscribe(result => {
        this.isSuccess = result === true;
      }, error => {
        this.error = error;
      });
    }
  }
}
