<div class="modal-header">
  <h4 class="modal-title">Split transactions</h4>
  <button aria-label="Close" class="close" type="button" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form novalidate [formGroup]="form">
  <div class="modal-body">
    <div class="row">
      <div class="col-2 form-group">
        <label class="control-label">Date</label>
        <input
          class="form-control"
          formControlName="transactionDate"
          type="date"
          title="Transaction date"
        />
      </div>
      <div class="col-2 form-group">
        <label class="control-label">Amount</label>
        <input
          class="form-control"
          formControlName="amount"
          type="number"
          title="Transaction amount"
        />
      </div>
      <div class="col-2 form-group">
        <label class="control-label">Type</label>
        <input
          class="form-control"
          formControlName="type"
          type="text"
          title="Type"
        />
      </div>
      <div class="col-6 form-group">
        <label class="control-label">Description</label>
        <input
          class="form-control"
          formControlName="description"
          type="text"
          title="Description"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" type="button" (click)="addAsRule()">
          Add as rule <i class="fa fa-plus ms-1"></i>
        </button>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 overflow-auto">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Amount ($)</th>
              <th scope="col">Percent (%)</th>
              <th scope="col">QTY</th>
              <th scope="col">Account</th>
              <th scope="col">Tax Code</th>
              <th scope="col">Tax</th>
              <th scope="col">Description</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="splittedTransactions">
              <tr
                *ngFor="
                  let control of form.controls.splittedTransactions.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <td class="form-group">
                  <input
                    #amountInput
                    class="form-control"
                    formControlName="amount"
                    placeholder="amount"
                    step=".01"
                    type="number"
                    (input)="onAmountChanged($event, i)"
                    (click)="$event.target.select()"
                  />
                </td>
                <td class="form-group" [width]="110">
                  <input
                    class="form-control"
                    formControlName="percentage"
                    step=".01"
                    type="number"
                    (input)="onPercentageChanged($event, i)"
                    (click)="$event.target.select()"
                    title="Percentage"
                  />
                </td>
                <td class="form-group" [width]="110">
                  <input
                    class="form-control"
                    formControlName="quantity"
                    placeholder="QTY"
                    type="number"
                  />
                </td>
                <td class="form-group">
                  <crs-transaction-account-select
                    appendTo=".modal-body"
                    formControlName="bacoAccountId"
                    (change)="onAccountSelectionChanged($event, i)"
                  ></crs-transaction-account-select>
                </td>
                <td class="form-group">
                  <crs-transaction-tax-select
                    appendTo=".modal-body"
                    formControlName="bacoTaxOptionId"
                    [clearable]="true"
                    (change)="onTaxOptionSelectionChanged($event, i)"
                  ></crs-transaction-tax-select>
                </td>
                <td class="form-group">
                  <input
                    class="form-control form-tax-amount"
                    formControlName="taxAmount"
                    placeholder="Tax"
                    type="number"
                    (input)="onTaxAmountChanged($event, i)"
                  />
                </td>
                <td class="form-group">
                  <input
                    class="form-control"
                    formControlName="description"
                    placeholder="Start typing..."
                    type="text"
                  />
                </td>
                <td class="p-0">
                  <button
                    class="btn btn-sm d-block m-auto pt-3 pb-3"
                    title="Delete"
                    type="button"
                    *ngIf="splittedTransactions.controls.length > 1"
                    (click)="deleteLine(i)"
                  >
                    <i
                      aria-hidden="true"
                      class="fas fa-trash text-primary pt-2"
                    ></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div class="col-12 d-flex justify-content-between">
        <button
          class="btn btn-outline-primary ps-5 pe-5 pt-3 pb-3"
          type="button"
          (click)="addNewLine()"
        >
          Add line
        </button>
        <div class="d-flex">
          <div class="unlocated no-select me-3 px-3">
            Estimated tax: {{ totalTaxAmount | number: '1.2-2' }}
          </div>
          <div class="unlocated no-select px-3">
            Unallocated amount: {{ unallocatedAmount }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <crs-form-error
          *ngIf="
            splittedTransactions.hasError('invalidAmountSum') &&
            splittedTransactions.touched &&
            submitted
          "
          [error]="'Please allocate total amount before saving.'"
          (errorDismissed)="onErrorDismissed()"
        ></crs-form-error>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-delete"
      type="button"
      *ngIf="splittedTransactions.length >= 2"
      (click)="onDeleteSplitCoding()"
    >
      <i aria-hidden="true" class="fas fa-trash me-2"></i>Delete
    </button>

    <button class="btn btn-primary" type="button" (click)="submit()">
      Save transaction
    </button>

    <button class="btn btn-secondary" type="button" (click)="onCancel()">
      Cancel
    </button>
  </div>
</form>
