import { ReportContentInfo } from './../../report-content/report-content-info';
import { Component, Input } from '@angular/core';
import { ReportMetaData } from '../../report-output';

@Component({
  selector: 'crs-report-table-of-contents',
  templateUrl: './report-table-of-contents.component.html'
})
export class ReportTableOfContentsComponent {

  @Input() element;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;
}
