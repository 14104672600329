<div style="overflow: hidden; flex-grow: 1">
  <crs-busy *ngIf="busy.taxCodes" />

  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    rowSelection="multiple"
    style="width: 100%; height: 100%"
    [enableRangeSelection]="true"
    [frameworkComponents]="renderers"
    [gridOptions]="gridOptions"
    [rowData]="taxCodes"
    [suppressCellSelection]="false"
  >
    <ag-grid-column field="taxCode" headerName="Tax Code" />
    <ag-grid-column field="description" headerName="Description" />
    <ag-grid-column field="postingAccountNo" headerName="Posting Account" />
    <ag-grid-column
      field="basCode"
      headerName="BAS Code"
      [comparator]="sortValueComparator"
    />
    <ag-grid-column
      field="isCapital"
      headerName="Is Capital"
      type="booleanColumn"
      [cellClass]="['boolean-cell', 'centered', 'text-success']"
      [maxWidth]="120"
      [minWidth]="120"
    />
    <ag-grid-column
      pinned="none"
      type="optionsColumn"
      [cellRendererParams]="{ ngTemplate: optionsCell }"
    />
  </ag-grid-angular>
</div>

<ng-template #optionsCell let-row>
  <div class="btn-mini-menu" *ngIf="isCustomTaxCode(row) === true">
    <a
      aria-expanded="false"
      aria-haspopup="true"
      class="btn-sm fas-btn-primary-ghost"
      data-toggle="dropdown"
    >
      <i aria-hidden="true" class="fas fa-ellipsis-v"></i>
      <span class="sr-only">Show Account Options</span>
    </a>
    <div class="dropdown-menu">
      <button
        class="btn dropdown-item text-info"
        type="button"
        (click)="onClickEditTaxCode(row)"
      >
        Edit Tax Code
      </button>
      <button
        class="btn dropdown-item text-danger"
        type="button"
        (click)="onClickDeleteTaxCode(row)"
      >
        Delete Tax Code
      </button>
    </div>
  </div>
</ng-template>
