import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'crs-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() passPhrase: string = null;
  @Input() danger = false;
  @Input() showDefaultWarning: boolean = true;
  @Input() hideCancelButton: boolean = false;

  @Input() approveBtn: string;
  @Input() cancelBtn: string;

  form = this.formBuilder.group({
    passPhrase: [null],
  });
  passPhraseHtml: string;

  @Input() action: () => any = () => console.log('do something');

  @ViewChild('submitBtn') submitBtn;
  shouldFocusOnSubmitButton: boolean;

  public get approveBtnName(): string {
    if (!this.showDefaultWarning) {
      return this.approveBtn;
    }

    return `${this.approveBtn ?? 'Yes'}${
      this.danger ? ', I understand the consequences' : ''
    }`;
  }

  public get cancelBtnName(): string {
    return this.cancelBtn ?? 'Cancel';
  }
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.passPhrase) {
      this.passPhraseHtml = this.encodePassPhrase(this.passPhrase);
      this.form.controls['passPhrase'].setValidators([
        Validators.required,
        passPhraseValidator(this),
      ]);
    }
  }

  confirm() {
    if (this.passPhrase) {
      this.form.markAsTouched();
      if (!this.form.valid) return;
    }
    this.activeModal.close(this.action());
  }

  private encodePassPhrase(str) {
    str = str.toString();
    return (
      '<span>' +
      str
        .split('')
        .map(function (c) {
          if (c === ' ') return '&nbsp;';
          else return c;
        })
        .join('') +
      '</span>'
    );
  }

  ngAfterViewInit() {
    if (this.shouldFocusOnSubmitButton) {
      this.submitBtn.nativeElement.focus();
    }
  }
}

function passPhraseValidator(component: ConfirmationComponent): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!component.passPhrase) return null;
    const phrase = component.passPhrase.toString();
    if (control.value && control.value !== phrase) {
      return { passPhrase: true };
    }
    return null;
  };
}
