<crs-form-error [error]="error"></crs-form-error>

<div class="row pad-x">
  <div class="col-md-3 col-sm-4">
    <div class="left-menu">
      <div>
        <crs-busy *ngIf="busy.loading"></crs-busy>

        <div class="margin-bottom">
          <button
            class="btn btn-insert"
            title="Create Entity"
            title="Link an entity to this file"
            type="button"
            (click)="addGroup()"
          >
            <i aria-hidden="true" class="fas fa-plus inset"></i>
            New Asset Group
          </button>
        </div>

        <a
          *ngIf="!groups || !groups.length"
          [routerLink]="[]"
          (click)="importAssets()"
        >
          <i aria-hidden="true" class="fas fa-file-excel"></i> Import from Excel
        </a>

        <crs-progress-bar id="progressBar"></crs-progress-bar>

        <div
          cdkDropList
          class="list-group-primary"
          (cdkDropListDropped)="onDrop($event)"
        >
          <div
            cdkDrag
            class="list-group-item clickable"
            *ngFor="let group of groups"
            [cdkDragData]="group"
            [ngClass]="{ active: group.id == groupId }"
            [title]="group.name"
            (click)="activateGroup(group.id)"
          >
            <div
              class="d-flex flex-nowrap justify-content-between align-items-center h-100"
            >
              <div class="d-flex align-items-center overflow-hidden">
                <i
                  aria-hidden="true"
                  class="fas fa-folder-open mr-2 fas-btn"
                ></i>
                <div class="d-inline-block text-truncate">
                  <strong>{{ group.name }} </strong>
                  <br />
                </div>
              </div>

              <div class="d-flex flex-nowrap align-items-center">
                <i
                  aria-hidden="true"
                  class="fas fa-cog mr-3 fas-btn"
                  (click)="editGroup(group.id)"
                ></i>
                <i
                  aria-hidden="true"
                  class="fas fa-trash mr-3 fas-btn"
                  (click)="removeGroup(group)"
                ></i>
                <i aria-hidden="true" class="fas fa-chevron-right fas-btn"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="spacer"></div>
      </div>
    </div>
  </div>

  <div class="col-md-9 col-sm-8" *ngIf="groupId">
    <router-outlet></router-outlet>
  </div>
</div>
