import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagingService {

  pageSize: number;
  pageSizes: [10, 25, 50, 100, 200];

  constructor() {
    this.pageSize = this.getPageSize();
  }

  private getPageSize() {
      let size = parseInt(localStorage.getItem('StandardPageSize'));
      if (size == null || size === 0 || isNaN(size)) size = 50;
      return size;
    }

  changePageSize(size: number) {
    if (!size || isNaN(size)) size = 5;
    localStorage.setItem('StandardPageSize', size.toString());
    this.pageSize = size;
  }


}
