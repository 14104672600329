import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SourceAccountService } from './../../../../ledger/sourceAccounts/source-account.service';
import { DepreciationType } from './../../../assets/depreciation-type';
import { JournalGrid } from 'src/app/accounting/ledger/journals/journal/journal-grid';

@Component({
  selector: 'crs-asset-group-journal-settings',
  templateUrl: './asset-group-journal-settings.component.html',
})
export class AssetGroupJournalSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('classificationTemplate', { static: true })
  classificationTemplate: ElementRef;
  @ViewChild('accountSearchTemplate', { static: true })
  accountSearchTemplate: ElementRef;
  @ViewChild('newAccountCell', { static: true, read: TemplateRef })
  newAccountCell!: TemplateRef<any>;

  @Input() formGroup: UntypedFormGroup;
  @Output() remove = new EventEmitter<UntypedFormGroup>();

  get journalAccounts() {
    return this.formGroup.get('gridAccounts').value;
  }
  depreciationTypes = DepreciationType;
  error: string;

  journalGrid: JournalGrid;

  subscriptions: Subscription[] = [];

  constructor(private readonly _sourceAccountService: SourceAccountService) {}

  ngOnInit(): void {
    this.journalGrid = new JournalGrid(
      this.classificationTemplate,
      this.accountSearchTemplate,
      null,
      () => this.onValuesChanged()
    );

    this.journalGrid.gridOptions = {
      ...this.journalGrid.gridOptions,
      onGridReady: (event) => (this.journalGrid.gridApi = event.api),
      components: this.journalGrid.renderers,
      cellSelection: true,
      suppressHorizontalScroll: true,
      columnDefs: [
        { field: 'type', headerName: '', editable: true, flex: 3 },
        {
          colId: 'new',
          headerName: '',
          type: 'optionsColumn',
          cellRendererParams: {
            ngTemplate: this.newAccountCell,
          },
          maxWidth: 45,
          minWidth: 45,
        },
        {
          colId: 'accountNo',
          field: 'sourceAccount.accountNo',
          headerName: 'Account No',
          type: 'typeAheadColumn',
          cellEditorParams: this.journalGrid?.accountNoSearchParams,
          editable: true,
          maxWidth: 160,
          minWidth: 110,
          width: 110,
        },
        {
          colId: 'accountName',
          field: 'sourceAccount.accountName',
          headerName: 'Account Name',
          type: 'typeAheadColumn',
          cellEditorParams: this.journalGrid?.accountNameSearchParams,
          editable: true,
          maxWidth: 300,
          minWidth: 140,
          width: 200,
        },
        {
          colId: 'classification',
          field: 'sourceAccount.classification',
          headerName: 'Class',
          tooltipField: 'sourceAccount.classificationToolTipText',
          type: 'typeAheadColumn',
          cellEditorParams: this.journalGrid?.classificationEditorParams,
          cellRenderer: 'classificationRenderer',
          editable: true,
          maxWidth: 110,
          minWidth: 80,
        },
      ],
    };

    this._sourceAccountService
      .getForSource(this.formGroup.value.source.id)
      .subscribe({
        next: (a) => (this.journalGrid.accounts = a),
        error: (err) => (this.error = err),
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onValuesChanged() {
    this.formGroup
      .get('depreciationSourceAccount')
      .setValue(
        this.journalAccounts[0] ? this.journalAccounts[0].sourceAccount : null
      );
    this.formGroup
      .get('accumulatedSourceAccount')
      .setValue(
        this.journalAccounts[1] ? this.journalAccounts[1].sourceAccount : null
      );
  }

  close() {
    this.remove.next(this.formGroup);
  }
}
