import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'crs-busy-template',
  templateUrl: './busy-template.component.html',
  styleUrls: ['./busy-template.component.scss']
})
export class BusyTemplateComponent implements OnInit {

  @Input() small = false;
  
  constructor() { }

  ngOnInit() {
  }

}
