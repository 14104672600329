import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core';
import { StorageAccount } from './storage-account';

@Injectable({
  providedIn: 'root'
})
export class StorageAccountService {

  readonly base: string = 'api/central/storage-accounts';

  constructor(private readonly _apiService: ApiService) { }

  get(id: number) {
    return this._apiService.get<StorageAccount>(this.base + '/' + id).pipe(map(l => new StorageAccount(l)));
  }

  getAll() {
    return this._apiService.get<StorageAccount[]>(this.base).pipe(map(l => l.map(d => new StorageAccount(d))));
  }

  post(storageAccount: StorageAccount) {
    return throwError('We have not yet implemented storage account creation');
    // return this._apiService.post<any>(this.base, storageAccount);
  }

  put(storageAccount: StorageAccount) {
    return throwError('We do not allow you to edit a storage account details through the UI at this stage, it is a very dangerous operation!');
    // return this._apiService.put(this.base + '/' + storageAccount.id, storageAccount);
  }
}
