import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SourceType } from 'src/app/accounting/sourcedata';
import { MessageService, ModalService } from 'src/app/core';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { StandardAccountSettingService } from '../../standard-account-setting.service';

@Component({
  selector: 'crs-select-coa',
  templateUrl: './select-coa.component.html',
  styleUrls: ['./select-coa.component.scss'],
})
export class SelectCoaComponent {
  sourceTypes: SourceType[] = [    
    new SourceType({
      id: 1,
      name: 'APS',
      imagePath: 'AccessGroupSource.png',
      iconPath: 'Icon_AccessGroupSource.png',
    }),
    new SourceType({      
      id: 2,
      name: 'HandiSoft',
      imagePath: 'AccessGroupSource.png',
      iconPath: 'Icon_AccessGroupSource.png',
    }),
  ];

  busy = {
    load: null,
    submit: null,
    activate: null,
  };
  form = this.formBuilder.group({
    sourceType: [null, Validators.required],
  });
  activateObservable: Observable<any>;
  error: string = null;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: ModalService,
    private messageService: MessageService,
    private standardAccountSettingService: StandardAccountSettingService,
    private formBuilder: UntypedFormBuilder
  ) {}

  selectChartType(type: SourceType) {
    this.form.patchValue({ sourceType: type });
  }

  submit() {
    let coaSettingId = this.form.controls['sourceType']?.value?.id;
    let name = this.form.controls['sourceType']?.value?.name;
    this.modalService.confirmation(
      '<p>Are you sure you want to activate your chart of accounts using the ' +
        name +
        ' Chart of Accounts?</p><p>This cannot be undone.</p>',
      () => this.activate(coaSettingId, name),
      false,
      null,
      'Activate'
    );
  }

  activate(id : number, name: string) {
    this.busy.activate = true;
    this.standardAccountSettingService
      .activate(id, name)
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => (this.busy.activate = false))
      )
      .subscribe(() => this.activeModal.close(name));
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }
}
