import { ModalService } from './../../../../../core/modals/modal.service';
import { ActiveFileService } from './../../../../active-file.service';
import { ReportOutput, Warning } from './../../report-output';
import { Component, Input, OnInit } from '@angular/core';
import { EntityComponent } from 'src/app/firm/entities/entity/entity.component';

@Component({
  selector: 'crs-report-warning',
  templateUrl: './report-warning.component.html',
  styleUrls: ['./report-warning.component.scss']
})
export class ReportWarningComponent implements OnInit {

  @Input() report: ReportOutput;
  @Input() warnings: Warning[];
  @Input() small = false;
  fileId: string;

  constructor(private readonly _activeFileService: ActiveFileService,
    private readonly _modalService: ModalService) { }

  ngOnInit(): void {
    this.fileId = this._activeFileService.file.id;
  }

  editEntity() {
    this._modalService.openModal(EntityComponent, this.report.entity.id);
  }

  clearWarning(warning: Warning) {
    const index = this.warnings.indexOf(warning);
    this.warnings.splice(index, 1);
  }
}
