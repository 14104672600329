import { MasterAccountUltraLight } from './../masterAccounts/master-account-ultra-light';
import { AccountUltraLight } from './account-ultra-light';
import { EntityType } from './../../../firm/entities/entityType';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { ApiService, PagedResponse } from '../../../core';
import { Account } from './account';
import { ChildAccount } from './childAccount';
import { HeaderAccount } from './headerAccount';

type SearchAccountInput = {
  fileId: string;
  search: string;
  includeHeaders?: boolean;
  includeChildren?: boolean;
  page?: number;
  pageSize?: number;
  onlyShowFromCOA?: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  readonly base: string = 'api/accounting/ledger/accounts';

  constructor(private apiService: ApiService) {}

  get(id: string) {
    return this.apiService
      .get<Account>(this.base + '/' + id)
      .pipe(map((account) => new Account(account)));
  }

  getChildAccount(id: string) {
    return this.apiService
      .get<ChildAccount>(this.base + '/child/' + id)
      .pipe(map((account) => new ChildAccount(account)));
  }

  getHeaderAccount(id: string) {
    return this.apiService
      .get<HeaderAccount>(this.base + '/header/' + id)
      .pipe(map((account) => new HeaderAccount(account)));
  }

  getAllChildAccounts$(fileId: string) {
    let url = this.base + '/child-accounts/';
    url = this.apiService.addQuery(url, 'fileId', fileId);

    return this.apiService
      .get<AccountUltraLight[]>(url)
      .pipe(
        map((accounts) =>
          accounts.map((account) => new AccountUltraLight(account))
        )
      );
  }

  searchAccounts$({
    fileId,
    search,
    includeHeaders = true,
    includeChildren = true,
    page = 1,
    pageSize = 100,
  }: SearchAccountInput) {
    let url = this.base + '/search/' + fileId;
    url = this.apiService.addQuery(url, 'search', search);
    url = this.apiService.addQuery(url, 'headers', includeHeaders);
    url = this.apiService.addQuery(url, 'children', includeChildren);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    return this.apiService.get<PagedResponse<AccountUltraLight[]>>(url);
  }

  searchMasters(search: string, page: number = 1, pageSize: number = 100) {
    let url = this.base + '/search/master';
    url = this.apiService.addQuery(url, 'search', search);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    return this.apiService.get<PagedResponse<MasterAccountUltraLight[]>>(url);
  }

  getHierarchy(
    fileId: string,
    entityType: number,
    detailed: boolean,
    searchClassification: number,
    accountTypeId: number
  ) {
    let url = this.base + '/' + fileId + '/hierarchy';
    url = this.apiService.addQuery(url, 'entityType', entityType);
    url = this.apiService.addQuery(url, 'detailed', detailed);
    url = this.apiService.addQuery(
      url,
      'searchClassification',
      searchClassification
    );
    url = this.apiService.addQuery(url, 'accountTypeId', accountTypeId);

    return this.apiService
      .get<Account[]>(url)
      .pipe(map((accounts) => accounts.map((account) => new Account(account))));
  }

  getUnallocatedHierarchy(
    fileId: string,
    entityType: number,
    detailed: boolean,
    searchClassification: number,
    accountTypeId: number
  ) {
    let url = this.base + '/' + fileId + '/hierarchy';
    url = this.apiService.addQuery(url, 'unallocated', true);
    url = this.apiService.addQuery(url, 'entityType', entityType);
    url = this.apiService.addQuery(url, 'detailed', detailed);
    url = this.apiService.addQuery(
      url,
      'searchClassification',
      searchClassification
    );
    url = this.apiService.addQuery(url, 'accountTypeId', accountTypeId);

    return this.apiService.get<Account[]>(url).pipe(
      map((accounts) => accounts.map((account) => new Account(account))),
      map((accounts) =>
        accounts.filter((account) => account.hierarchy.length > 1)
      ), // removes the 'unallocated' header
      tap((accounts) =>
        accounts.forEach((account) => {
          if (account.hierarchy.length === 2) account.parentId = null; // treats classification headers as the root
          account.hierarchy.shift(); // removes 'unallocated' from the hierarchy
        })
      )
    );
  }

  refreshHierarchy(
    fileId: string,
    entityType: number,
    detailed: boolean,
    ids: string[]
  ) {
    let url = this.base + '/' + fileId + '/hierarchy/refresh';
    url = this.apiService.addQuery(url, 'entityType', entityType);
    url = this.apiService.addQuery(url, 'detailed', detailed);
    url = this.apiService.addQuery(url, 'ids', ids);
    return this.apiService
      .get<Account[]>(url)
      .pipe(map((accounts) => accounts.map((account) => new Account(account))));
  }

  refreshUnallocatedHierarchy(
    fileId: string,
    entityType: number,
    detailed: boolean,
    ids: string[]
  ) {
    let url = this.base + '/' + fileId + '/hierarchy/refresh';
    url = this.apiService.addQuery(url, 'entityType', entityType);
    url = this.apiService.addQuery(url, 'detailed', detailed);
    url = this.apiService.addQuery(url, 'ids', ids);
    return this.apiService.get<Account[]>(url).pipe(
      map((accounts) => accounts.map((account) => new Account(account))),
      map((accounts) =>
        accounts.filter((account) => account.hierarchy.length > 1)
      ), // removes the 'unallocated' header
      tap((accounts) =>
        accounts.forEach((account) => {
          if (account.hierarchy.length === 2) account.parentId = null; // treats classification headers as the root
          account.hierarchy.shift(); // removes 'unallocated' from the hierarchy
        })
      )
    );
  }

  putChild(account: ChildAccount) {
    return this.apiService.put(this.base + '/' + account.id, account);
  }

  postHeader(account: Account, position: number = null) {
    let url = this.base + '/header';
    url = this.apiService.addQuery(url, 'position', position);
    return this.apiService.post(url, account);
  }

  putHeader(account: Account) {
    return this.apiService.put(this.base + '/header/' + account.id, account);
  }

  deleteHeader(account: Account) {
    return this.apiService.delete(this.base + '/header/' + account.id);
  }

  move(targetId: string, action: number, movingIds: string[], fileId: string) {
    let url = this.base + '/move';
    url = this.apiService.addQuery(url, 'fileId', fileId);
    url = this.apiService.addQuery(url, 'targetId', targetId);
    url = this.apiService.addQuery(url, 'action', action);
    return this.apiService.put(url, movingIds);
  }

  reorder(headerId: string, orderBy: number) {
    let url = this.base + '/header/' + headerId + '/reorder';
    url = this.apiService.addQuery(url, 'orderBy', orderBy);
    return this.apiService.put(url, null);
  }
}
