<div class="modal-header">
  <h4 class="modal-title">{{ isAdd ? 'Add' : 'Edit' }} workpaper</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label" for="accountId">Select account</label>
          <crs-accounts-select
            formControlName="accountId"
            [isDisabled]="isAccountDisabled"
            [selectedValue]="form.value?.accountId"
            (selectedValueChange)="onAccountChange($event)"
          ></crs-accounts-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label" for="title">Workpaper title</label>
          <input
            class="form-control"
            formControlName="title"
            id="title"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="control-label" for="status"
            >Select workpaper status</label
          >
          <crs-workpaper-status-dropdown
            formControlName="status"
            [removeNoWorkpaperOption]="true"
            (selectedValueChange)="onStatusChange($event)"
          ></crs-workpaper-status-dropdown>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label" for="document">
            <strong>Supporting Documents</strong>
          </label>
          <div class="wp-documents mt-2">
            <div
              class="existing-wp-documents-container"
              formArrayName="documents"
              *ngIf="!!documents.controls.length"
            >
              <div
                class="existing-wp-document"
                *ngFor="let docCtrl of documents.controls; let i = index"
                [formGroupName]="i"
              >
                <div class="wp-input-container position-relative">
                  <i class="far fa-clipboard wp-icon-left"></i>
                  <input
                    class="form-control"
                    formControlName="url"
                    placeholder="Enter document url"
                    type="url"
                  />
                  <a
                    class="wp-icon-right"
                    target="_blank"
                    *ngIf="docCtrl.get('url').value"
                    [href]="getExternalUrl(docCtrl.get('url').value)"
                  >
                    <i class="fas fa-external-link-alt fas-btn-ghost"></i>
                  </a>
                </div>
                <div class="wp-btn-container">
                  <button
                    class="btn"
                    title="Remove document"
                    type="button"
                    (click)="removeDocument(i)"
                  >
                    <i aria-hidden="true" class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="new-wp-document">
              <div class="wp-input-container position-relative">
                <i class="far fa-clipboard wp-icon-left"></i>
                <input
                  #newDocumentUrlInput
                  class="form-control"
                  formControlName="newDocumentUrl"
                  placeholder="Enter document url"
                  type="url"
                />
              </div>
              <div class="wp-btn-container">
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  (click)="addNewDocument()"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label" for="comments">
            <strong>Comments</strong>
          </label>
          <div class="wp-comments mt-2">
            <div
              class="existing-wp-comments-container mb-2"
              formArrayName="comments"
              *ngIf="!!comments.controls.length"
            >
              <div
                class="existing-wp-comment"
                *ngFor="let commentCtrl of comments.controls; let i = index"
                [formGroupName]="i"
              >
                <div class="wp-input-container position-relative">
                  <div class="wp-input-comment-info">
                    <strong class="wp-comment-user">
                      {{ commentCtrl.get('commentUserName').value }}
                    </strong>
                    <span class="wp-comment-created me-1">
                      {{
                        commentCtrl.get('commentCreated').value | commentDateAgo
                      }}
                    </span>
                  </div>
                  <textarea
                    #commentTextarea
                    class="form-control wp-input-comment-textarea"
                    formControlName="comment"
                    placeholder="Enter comment"
                    [ngClass]="{ readonly: editingCommentIndex !== i }"
                    [readonly]="editingCommentIndex !== i"
                  ></textarea>
                </div>
                <div class="wp-btn-container">
                  <button
                    class="btn"
                    title="confirm comment"
                    type="button"
                    *ngIf="editingCommentIndex === i"
                    (click)="saveComment()"
                  >
                    <i aria-hidden="true" class="fas fa-check"></i>
                  </button>
                  <button
                    class="btn"
                    title="Cancel edit"
                    type="button"
                    *ngIf="editingCommentIndex === i"
                    (click)="cancelEditComment()"
                  >
                    <i aria-hidden="true" class="fas fa-times"></i>
                  </button>
                  <button
                    class="btn"
                    title="Edit comment"
                    type="button"
                    *ngIf="
                      editingCommentIndex !== i &&
                      commentCtrl.get('commentUserId').value === profileUser?.id
                    "
                    (click)="editComment(i)"
                  >
                    <i aria-hidden="true" class="fas fa-pen"></i>
                  </button>
                  <button
                    class="btn"
                    title="Remove comment"
                    type="button"
                    *ngIf="
                      commentCtrl.get('commentUserId').value === profileUser?.id
                    "
                    (click)="removeComment(i)"
                  >
                    <i aria-hidden="true" class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="new-wp-comment">
              <label class="control-label" for="newComment">
                <small><strong>Add a comment</strong></small>
              </label>
              <div class="wp-input-container position-relative">
                <textarea
                  #newCommentInput
                  class="form-control"
                  formControlName="newComment"
                  title="New comment"
                ></textarea>
              </div>
              <div class="wp-btn-container mt-2">
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  (click)="addNewComment()"
                >
                  Add comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-between flex-grow-1">
      <button class="btn btn-secondary" type="button" (click)="close()">
        Cancel
      </button>
    </div>
    <div class="d-flex">
      <button
        class="btn btn-delete"
        type="button"
        *ngIf="!isAdd"
        (click)="deleteWorkpaperConfirmation()"
      >
        Delete workpaper
      </button>
      <button
        class="btn btn-primary ms-2"
        type="submit"
        [promiseBtn]="busy.submit"
      >
        {{ isAdd ? 'Add Workpaper' : 'Save' }}
      </button>
    </div>
  </div>
</form>
