import {
  BankAccountState,
  BankConnectionState,
  RuleMovementType,
  TransactionImportType,
} from '../enums';
import { BacoRuleDto } from './baco-rule.interface';
import { BacoTransactionAccount } from './baco-transaction-account.interface';
import { BacoTransactionFile } from './baco-transaction-file.interface';
import { BacoCommentDto } from './transaction-list.interface';

export interface BacoBankConnectionDto {
  id: string;
  name: string;
  email: string;
  state: BankConnectionState;
  createdDate: Date;
  lastRefreshDate: Date;
  lastRefreshLocalDate: Date;
  transactionProvider: string;
  webhooksState: boolean;
  feedName?: string | null;
  feedId?: string | null;
  bankAccounts?: BacoBankAccountDto[];
}

export interface BacoBankAccountDto {
  id: string;
  accountName: string;
  bankName: string;
  bsb: string;
  state: BankAccountState;
  lastRefreshDate: Date;
  lastRefreshLocalDate: Date;
  accountNumber: string;
  bacoBankConnectionId: string;
  ledgerAccountCode?: string | null;
  ledgerAccountName?: string | null;
  isHidden: boolean;
  bacoFeedId?: string | null;
}

export interface BacoAccountTransferDto {
  bankAccountId: string;
  bankAccountConnectionId: string;
  destinationFeedId: string;
  sourceFeedId: string;
}

export interface BacoConnectionValidationDto {
  numberOfMismatchedTransactions: number;
  transactionList: BacoTransactionValidationDto[];
}
export interface BacoTransactionValidationDto {
  mismatched: boolean;
  id: string;
  transactionDate: string;
  transactionLocalDate: string;
  reference: string | null;
  transactionProviderId: string | null;
  type: string | null;
  description: string | null;
  originalDescription: string | null;
  movementType: RuleMovementType;
  amount: number;
  balance: number | null;
  locked: boolean;
  bacoRuleId: string | null;
  bacoRule: BacoRuleDto | null;
  transactionImportType: TransactionImportType;
  bacoBankAccountId: string;
  bacoBankAccountName: string | null;
  bacoBankAccountNumber: string | null;
}
