import { Inject, Injectable } from '@angular/core';
import { ReportTable, ReportTableCell, ReportTableColumnDefinition, ReportTableRow } from '../models';
import { ReportHeaderColumn } from '../../../report-output';
import { ColumnType } from '../../../../reportTemplates';
import { TableRowType } from '../enums';
import { Alignment } from '../../report-header/report-header';
import { ElementSizeMultiplier, getSize, NumberFormat, ReportElementTypeEnum } from '../../../../enums';
import { IAutoTotalTable, IReportTableConfig } from '../interfaces';
import { REPORT_TABLE_CONFIG } from '../report-table.contants';

@Injectable()
export class ReportTableBuilderService {

  constructor(@Inject(REPORT_TABLE_CONFIG) private readonly _config: IReportTableConfig) {
  }

  public buildBlankTable(reportColumns: ReportHeaderColumn[], autoTotalTableProps: IAutoTotalTable = null): ReportTable {
    /* TODO: Financial Formula example
            new ReportTableRow({
             rowType: CustomTableRowType.Row,
             height: this._config.cellDefaultHeight,
             cells: [
               new ReportTableCell({colSpan: 1, type: CellTypeEnum.financialFormula, totalDefinition: new TotalDefinition({formula: ''})}),
             ],
           })
       */

    const reportTable = new ReportTable({
      elementType: autoTotalTableProps ? ReportElementTypeEnum.SectionTable : ReportElementTypeEnum.Table,
      isNew: true,
      rows: [],
      columns: []
    });

    if (reportColumns?.length) {
      this._configuredTable(reportTable, reportColumns, autoTotalTableProps);
    } else {
      this._dumpTable(reportTable);
    }
    return reportTable;
  }

  private _configuredTable(reportTable: ReportTable, reportColumns: ReportHeaderColumn[], autoTotalTableProps: IAutoTotalTable): void {

    reportColumns.splice(2);
    if (reportColumns.length === 1) {
      reportColumns.push(new ReportHeaderColumn({periodText: 'Period 2', signText: '$'}));
    }

    reportTable.columns.push(new ReportTableColumnDefinition({
      valueType: ColumnType.Text,
      width: getSize(ElementSizeMultiplier.auto, this._config.cellDefaultWidth, 2 * this._config.cellDefaultWidth, 1),
      widthType: ElementSizeMultiplier.auto,
    }));

    reportColumns.forEach(() => {
      reportTable.columns.push(new ReportTableColumnDefinition({
        valueType: ColumnType.Number,
        numberFormat: NumberFormat.Normal,
        width: this._config.cellDefaultWidth,
        widthType: ElementSizeMultiplier['1x']
      }));
    });

    let totalColumn = null;
    if (autoTotalTableProps?.addTotalColumn) {
      totalColumn = new ReportTableColumnDefinition({
        valueType: ColumnType.AutoTotal,
        numberFormat: NumberFormat.Normal,
        width: this._config.cellDefaultWidth,
        widthType: ElementSizeMultiplier['1x']
      })
      reportTable.columns.push(totalColumn);
    }

    var headerRow = new ReportTableRow({
      rowType: TableRowType.ColumnHeader,
      height: this._config.cellDefaultHeight,
      cells: [
        new ReportTableCell({
          cellValue: null,
          colSpan: 1,
          alignment: Alignment.Left,
          valueType: ColumnType.Text,
          textBold: true
        }),
        ...reportColumns.map(column => new ReportTableCell({
          cellValue: [column.headerText, column.periodText, column.signText].filter(x => !!x).join('\n'),
          colSpan: 1,
          alignment: Alignment.Center,
          valueType: ColumnType.Text,
          textBold: true
        }))
      ]
    });

    if (autoTotalTableProps?.addTotalColumn) {
      headerRow.cells.push(new ReportTableCell({
        cellValue: ['Row', 'Total'].join('\n'),
        colSpan: 1,
        alignment: Alignment.Center,
        valueType: ColumnType.Text,
        textBold: true,
        parentColumn: totalColumn
      }));
    }

    reportTable.rows.push(headerRow);

    var standartRow = new ReportTableRow({
      rowType: TableRowType.Row,
      sections: ["Section1"],
      height: this._config.cellDefaultHeight,
      cells: [
        new ReportTableCell({
          cellValue: null,
          colSpan: 1,
          alignment: Alignment.Left,
          valueType: ColumnType.Text
        }),
        ...reportColumns.map(() => new ReportTableCell({
          cellValue: 0,
          colSpan: 1,
          alignment: Alignment.Right,
          valueType: ColumnType.Number,
          numberFormat: NumberFormat.Normal
        }))
      ]
    });

    reportTable.rows.push(standartRow);

    if (autoTotalTableProps?.addTotalRow) {
      standartRow.cells.push(new ReportTableCell({
        cellValue: 0,
        colSpan: 1,
        alignment: Alignment.Right,
        valueType: ColumnType.Number,
        numberFormat: NumberFormat.Normal,
        parentColumn: totalColumn
      }));

      reportTable.rows.push(new ReportTableRow({
        rowType: TableRowType.AutoTotal,
        sections: ["Section1"],
        height: this._config.cellDefaultHeight,
        cells: [
          new ReportTableCell({
            cellValue: 'Column Total',
            colSpan: 1,
            alignment: Alignment.Left,
            valueType: ColumnType.Text,
            textBold: true
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          })
        ]
      }));

      reportTable.rows.push(new ReportTableRow({
        rowType: TableRowType.Row,
        sections: ["Section2"],
        height: this._config.cellDefaultHeight,
        cells: [
          new ReportTableCell({
            cellValue: null,
            colSpan: 1,
            alignment: Alignment.Left,
            valueType: ColumnType.Text
          }),
          ...reportColumns.map(() => new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          })),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal,
            parentColumn: totalColumn
          })
        ]
      }));

      reportTable.rows.push(new ReportTableRow({
        rowType: TableRowType.AutoTotal,
        sections: ["Section2"],
        height: this._config.cellDefaultHeight,
        cells: [
          new ReportTableCell({
            cellValue: 'Column Total',
            colSpan: 1,
            alignment: Alignment.Left,
            valueType: ColumnType.Text,
            textBold: true
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          })
        ]
      }));

      reportTable.rows.push(new ReportTableRow({
        rowType: TableRowType.AutoGrandTotal,
        sections: ["Section1", "Section2"],
        height: this._config.cellDefaultHeight,
        cells: [
          new ReportTableCell({
            cellValue: 'Grand Total',
            colSpan: 1,
            alignment: Alignment.Left,
            valueType: ColumnType.Text,
            textBold: true
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          }),
          new ReportTableCell({
            cellValue: 0,
            colSpan: 1,
            alignment: Alignment.Right,
            valueType: ColumnType.Number,
            numberFormat: NumberFormat.Normal
          })
        ]
      }));
    }
  }

  private _dumpTable(reportTable: ReportTable): void {
    reportTable.columns.push(new ReportTableColumnDefinition({
      valueType: ColumnType.Text,
      width: getSize(ElementSizeMultiplier.auto, this._config.cellDefaultWidth, 2 * this._config.cellDefaultWidth, 1),
      widthType: ElementSizeMultiplier.auto
    }));

    reportTable.columns.push(new ReportTableColumnDefinition({
      valueType: ColumnType.Number,
      numberFormat: NumberFormat.Normal,
      width: this._config.cellDefaultWidth,
      widthType: ElementSizeMultiplier['1x']
    }));

    reportTable.columns.push(new ReportTableColumnDefinition({
      valueType: ColumnType.Number,
      numberFormat: NumberFormat.Normal,
      width: this._config.cellDefaultWidth,
      widthType: ElementSizeMultiplier['1x']
    }));

    reportTable.rows.push(new ReportTableRow({
      rowType: TableRowType.ColumnHeader,
      height: this._config.cellDefaultHeight,
      cells: [
        new ReportTableCell({
          cellValue: null,
          colSpan: 1,
          alignment: Alignment.Left,
          valueType: ColumnType.Text,
          textBold: true
        }),
        new ReportTableCell({
          cellValue: 'Period 1\n$',
          colSpan: 1,
          alignment: Alignment.Center,
          valueType: ColumnType.Text,
          textBold: true
        }),
        new ReportTableCell({
          cellValue: 'Period 2\n$',
          colSpan: 1,
          alignment: Alignment.Center,
          valueType: ColumnType.Text,
          textBold: true
        })
      ]
    }));

    reportTable.rows.push(new ReportTableRow({
      rowType: TableRowType.Row,
      height: this._config.cellDefaultHeight,
      cells: [
        new ReportTableCell({
          cellValue: null,
          colSpan: 1,
          alignment: Alignment.Left,
          valueType: ColumnType.Text
        }),
        new ReportTableCell({
          cellValue: 0,
          colSpan: 1,
          alignment: Alignment.Right,
          valueType: ColumnType.Number,
          numberFormat: NumberFormat.Normal,
        }),
        new ReportTableCell({
          cellValue: 0,
          colSpan: 1,
          alignment: Alignment.Right,
          valueType: ColumnType.Number,
          numberFormat: NumberFormat.Normal,
        })
      ]
    }));
  }
}
