import { ProfitDisplayOption } from '../enums/profit-display-option';

// tslint:disable: no-use-before-declare

import { DepreciationType } from './../../assets/assets/depreciation-type';
import { DepreciationPool } from './../../assets/assets/depreciation-pool';
import { DepreciationReportType } from './../../assets/asset-reports/depreciation-report-type';
import { AssetGroup } from './../../assets/asset-groups/asset-group';
import {
  ReportTemplateColumn,
  ReportTemplateColumnModel,
} from './reportTemplateColumn';
import { ReportPageType } from '../reportPages/reportPageType';
import {
  PolicySelector,
  PolicySelectorModel,
  PolicySelectionMode,
} from '../notes/policies/policySelector';
import { HeaderLayoutOptionModel } from './../layout/headerLayoutOption';
import { AccountLayout } from '../..';
import {
  Disclosure,
  DisclosureModel,
  DisclosureSelectionMode,
} from '../notes/disclosures/disclosure';
import { ReportingSuite } from '../notes/reportingSuites';
import { ProfitLossLayoutOption } from '../enums/profit-loss-layout-option';

export class ReportTemplatePage {
  id: string;
  title: string;
  sortOrder: number;
  pageType: ReportPageType;
  customTitle: string;
  financialDetail: ReportTemplateFinancialPageDetail;
  noteDetail: ReportTemplateNotePageDetail;
  depreciationDetail: ReportTemplateDepreciationPageDetail;
  cashflowDetail: ReportTemplateCashflowPageDetail;

  constructor(data) {
    Object.assign(this, data);
    if (data.pageType) this.pageType = new ReportPageType(data.pageType);
    if (data.financialDetail)
      this.financialDetail = new ReportTemplateFinancialPageDetail(
        this.financialDetail
      );
    if (data.noteDetail)
      this.noteDetail = new ReportTemplateNotePageDetail(this.noteDetail);
    if (data.depreciationDetail)
      this.depreciationDetail = new ReportTemplateDepreciationPageDetail(
        this.depreciationDetail
      );
    if (data.cashflowDetail)
      this.cashflowDetail = new ReportTemplateCashflowPageDetail(
        this.cashflowDetail
      );
  }
}

export class ReportTemplatePageModel {
  id: string;
  pageTypeId: number;
  customTitle: string;
  financialDetail: ReportTemplateFinancialPageDetailModel;
  noteDetail: ReportTemplateNotePageDetailModel;
  depreciationDetail: ReportTemplateDepreciationPageDetailModel;
  cashflowDetail: ReportTemplateCashflowPageDetailModel;

  constructor(data) {
    this.id = data.id;
    this.pageTypeId = data.pageTypeId;
    this.customTitle = data.customTitle;
    if (data.pageType) this.pageTypeId = data.pageType.id;

    if (data.pageType && !data.pageType.hasFinancialDetail)
      this.financialDetail = null;
    else if (data.financialDetail)
      this.financialDetail = new ReportTemplateFinancialPageDetailModel(
        data.financialDetail
      );

    if (data.pageType && !data.pageType.hasNoteDetail) this.noteDetail = null;
    else if (data.noteDetail)
      this.noteDetail = new ReportTemplateNotePageDetailModel(data.noteDetail);

    if (data.pageType && !data.pageType.hasDepreciationDetail)
      this.depreciationDetail = null;
    else if (data.depreciationDetail)
      this.depreciationDetail = new ReportTemplateDepreciationPageDetailModel(
        data.depreciationDetail
      );

    if (data.pageType && !data.pageType.hasCashFlowDetail)
      this.cashflowDetail = null;
    else if (data.cashflowDetail)
      this.cashflowDetail = new ReportTemplateCashflowPageDetailModel(
        data.cashflowDetail
      );
  }
}

// FINANCIAL PAGE

export class ReportTemplateFinancialPageDetail {
  customiseColumns: boolean;
  customColumns: ReportTemplateColumn[] = [];
  separateTradingAccounts: boolean;
  profitDisplayOption: ProfitDisplayOption;
  profitlossLayoutOption: ProfitLossLayoutOption;
  layout: HeaderLayoutOptionModel[];

  /**
   * Property added client side only when wanting to display full account layout
   */
  layoutFull: AccountLayout[];

  constructor(data) {
    Object.assign(this, data);
    if (data.customColumns) {
      this.customColumns = data.customColumns
        .map((c) => new ReportTemplateColumn(c))
        .sort((c) => c.sortOrder);
    }
    if (data.layout)
      this.layout = data.layout.map((a) => new HeaderLayoutOptionModel(a));
    if (data.layoutFull)
      this.layoutFull = data.layoutFull.map((a) => new AccountLayout(a));
  }
}

export class ReportTemplateFinancialPageDetailModel {
  customiseColumns: boolean;
  customColumns: ReportTemplateColumnModel[] = [];
  separateTradingAccounts: boolean;
  profitDisplayOption: ProfitDisplayOption;
  profitlossLayoutOption: ProfitLossLayoutOption;
  layout: HeaderLayoutOptionModel[];

  constructor(data) {
    this.customiseColumns = data.customiseColumns;
    this.separateTradingAccounts = data.separateTradingAccounts;
    this.profitDisplayOption = parseInt(data.profitDisplayOption, 10);
    this.profitlossLayoutOption = parseInt(data.profitlossLayoutOption, 10);
    if (data.customColumns)
      this.customColumns = data.customColumns.map(
        (c) => new ReportTemplateColumnModel(c)
      );
    else this.customColumns = [];
    if (data.layoutFull) {
      const layout = data.layoutFull as AccountLayout[];
      this.layout = layout
        .filter((l) => l.layoutOption && !l.layoutOption.isDefault)
        .map((l) => HeaderLayoutOptionModel.createForAccountLayout(l));
    } else if (data.layout) {
      this.layout = data.layout.map((l) => new HeaderLayoutOptionModel(l));
    }
  }
}

// NOTE PAGE

export class ReportTemplateNotePageDetail {
  reportingSuiteId: number;
  reportingSuite: ReportingSuite;
  auto: boolean;
  policySelectors: PolicySelectorModel[] = [];
  disclosures: DisclosureModel[] = [];

  /**
   * Properties added client side only when wanting to display full details for every policy and disclosure
   */
  policySelectorsFull: PolicySelector[];
  disclosuresFull: Disclosure[];

  constructor(data) {
    Object.assign(this, data);
    if (data.reportingSuite) {
      this.reportingSuiteId = data.reportingSuite.id;
      this.reportingSuite = new ReportingSuite(data.reportingSuite);
    }
    if (data.policySelectors) {
      this.policySelectors = data.policySelectors.map(
        (s) => new PolicySelectorModel(s)
      );
    }
    if (data.disclosures) {
      this.disclosures = data.disclosures.map((s) => new DisclosureModel(s));
    }
  }
}

export class ReportTemplateNotePageDetailModel {
  reportingSuiteId: number;
  auto: boolean;
  policySelectors: PolicySelectorModel[] = [];
  disclosures: DisclosureModel[] = [];

  constructor(data) {
    this.reportingSuiteId = data.reportingSuiteId;
    this.auto = data.auto;

    if (data.reportingSuite) {
      this.reportingSuiteId = data.reportingSuite.id;
    }
    if (!data.auto) {
      if (data.policySelectorsFull) {
        this.policySelectors = data.policySelectorsFull
          .filter(
            (p) =>
              p.selectedPolicyVariantId != null ||
              p.selectionMode !== PolicySelectionMode.Auto
          )
          .map((s) => new PolicySelectorModel(s));
      } else if (data.policySelectors) {
        this.policySelectors = data.policySelectors.map(
          (s) => new PolicySelectorModel(s)
        );
      }
    }
    if (data.disclosuresFull) {
      this.disclosures = data.disclosuresFull
        .filter(
          (p) =>
            p.selectedDisclosureVariantId != null ||
            p.selectionMode !== DisclosureSelectionMode.Auto ||
            p.customise
        )
        .map((s) => new DisclosureModel(s));
    } else if (data.disclosures) {
      this.disclosures = data.disclosures.map((s) => new DisclosureModel(s));
    }
  }
}

// DEPRECIATION PAGE

export class ReportTemplateDepreciationPageDetail {
  reportType: DepreciationReportType;
  entityId: string;
  year: number;
  toPeriod: number;
  pool: DepreciationPool;
  depreciationType: DepreciationType;
  assetGroups: AssetGroup[] = [];
  showPoolSummary: boolean;

  constructor(data) {
    Object.assign(this, data);
    if (data.assetGroups) {
      this.assetGroups = data.assetGroups.map((s) => new AssetGroup(s));
    } else {
      this.assetGroups = [];
    }
  }
}

export class ReportTemplateDepreciationPageDetailModel {
  reportType: DepreciationReportType;
  entityId: string;
  year: number;
  toPeriod: number;
  pool: DepreciationPool;
  depreciationType: DepreciationType;
  showPoolSummary: boolean;
  assetGroupIds: number[];

  constructor(data) {
    Object.assign(this, data);
    if (data.assetGroups) {
      this.assetGroupIds = data.assetGroups.map((a) => a.id);
    }
  }
}

// CASHFLOW PAGE

export class ReportTemplateCashflowPageDetail {
  cashflowMode: number;

  constructor(data) {
    Object.assign(this, data);
    this.cashflowMode = parseInt(data.cashflowMode, 10);
  }
}

export class ReportTemplateCashflowPageDetailModel {
  cashflowMode: number;

  constructor(data) {
    Object.assign(this, data);
    this.cashflowMode = parseInt(data.cashflowMode, 10);
  }
}
