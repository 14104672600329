import { DepreciationMethod } from './depreciation-method';
import { DepreciationPool } from './depreciation-pool';
import { getDepreciationMethodAbbrev } from './get-depreciation-method-abbrev';

export class DepreciationRecord {
  year: number;
  inherited: boolean;
  carryingAmountAdjustment: number;
  depreciationMethod: DepreciationMethod;
  rate: number;
  rateEditable: number;
  manualDepreciation: number;
  isRateSelfAssessed: boolean;
  residualValue: number;
  privatePercent: number;
  privatePercentEditable: number;
  depreciationPool: DepreciationPool;

  // calculation output
  additions: number;
  disposals: number;
  additionsAndDisposals: number;
  depreciationMethodDescription: string;

  constructor(data: any) {
    if (!data) return;
    Object.assign(this, data);

    // Todo: why is the API returning this as a string in the first place? System.Text.Json grrrr
    this.year = typeof data.year === 'string' ? parseInt(data.year, 10) : data.year;
    this.inherited = data.inherited;
    this.carryingAmountAdjustment = data.carryingAmountAdjustment;
    this.depreciationMethod = data.depreciationMethod;
    this.rate = data.rate === undefined ? null : data.rate;
    this.rateEditable = data.rateEditable !== undefined ? data.rateEditable : this.rate === null ? null : this.rate * 100;
    this.manualDepreciation = data.manualDepreciation;
    this.isRateSelfAssessed = data.isRateSelfAssessed;
    this.residualValue = data.residualValue;
    this.privatePercent = data.privatePercent === undefined ? null : data.privatePercent;
    this.privatePercentEditable = data.privatePercentEditable !== undefined ?
      data.privatePercentEditable :
      this.privatePercent === null ? null : this.privatePercent * 100;
    this.depreciationPool = data.depreciationPool;

    this.additions = data.additions ? data.additions : 0;
    this.disposals = data.disposals ? data.disposals : 0;
    this.additionsAndDisposals = this.additions + this.disposals;
    this.depreciationMethodDescription = getDepreciationMethodAbbrev(this.depreciationMethod, this.depreciationPool);
  }

  static buildDefault(year: number, inherited: boolean, smallBusinessDefaults: boolean): DepreciationRecord {
    const newRecord = new DepreciationRecord({});
    newRecord.year = year;
    newRecord.inherited = inherited;
    newRecord.depreciationPool = DepreciationPool.LowValuePool;
    if (smallBusinessDefaults) {
      newRecord.depreciationMethod = DepreciationMethod.Pool;
      newRecord.depreciationPool = DepreciationPool.GeneralPool;
    }
    return newRecord;
  }


}

export class DepreciationRecordModel {
  year: number;
  inherited: boolean;
  carryingAmountAdjustment: number;
  depreciationMethod: DepreciationMethod;
  rate: number;
  manualDepreciation: number;
  isRateSelfAssessed: boolean;
  residualValue: number;
  privatePercent: number;
  depreciationPool: DepreciationPool;

  constructor(data: DepreciationRecord) {
    this.year = data.year;
    this.inherited = data.inherited;
    this.carryingAmountAdjustment = data.carryingAmountAdjustment;
    this.depreciationMethod = data.depreciationMethod;
    this.rate = data.rateEditable === null ? null : data.rateEditable / 100;
    this.manualDepreciation = data.manualDepreciation;
    this.isRateSelfAssessed = !!data.isRateSelfAssessed;
    this.residualValue = data.residualValue;
    this.privatePercent = data.privatePercentEditable === null ? null : data.privatePercentEditable / 100;
    this.depreciationPool = this.depreciationMethod === DepreciationMethod.Pool ? data.depreciationPool : null;

    // Convert nulls to default values
    if (this.carryingAmountAdjustment == null) this.carryingAmountAdjustment = 0;
    if (isNaN(this.rate)) this.rate = null;
    if (this.manualDepreciation == null) this.manualDepreciation = 0;
    if (this.residualValue == null) this.residualValue = 0;
    if (this.privatePercent == null || isNaN(this.privatePercent)) this.privatePercent = 0;
  }

}
