import { ReportContentInfo } from './../../report-content/report-content-info';
import { Component, Input } from '@angular/core';
import { ReportMetaData } from '../../report-output';

@Component({
  selector: 'crs-report-horizontal-rule',
  templateUrl: './report-horizontal-rule.component.html'
})
export class ReportHorizontalRuleComponent {

  @Input() element;
  @Input() index: number;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;
}
