import { Account } from './account';
import { HeaderLayoutOption } from '../../reports/layout/headerLayoutOption';

export class AccountLayout extends Account {
  layoutOption: HeaderLayoutOption;
  constructor(data) {
    super(data);
    if (this.isHeader) this.layoutOption = new HeaderLayoutOption(data.layoutOption);
  }
}
