import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `
    <ng-select
      [(ngModel)]="value"
      [bindValue]="bindField"
      [items]="items"
      [virtualScroll]="true"
    >
      <ng-template let-item="item" ng-label-tmp>
        {{ item[bindField] }} - {{ item[displayField] }}
      </ng-template>
      <ng-template let-item="item" ng-option-tmp>
        <span class="label">
          {{ item[bindField] }} - {{ item[displayField] }}
        </span>
      </ng-template>
    </ng-select>
  `,
})
export class SelectRendererComponent implements ICellRendererAngularComp {
  public value: any;
  public items: any[];
  public bindField: string;
  public displayField: string;
  public searchFn: any;

  agInit(params: any): void {
    this.value = params.value;
    this.items = params.data.items;
    this.bindField = params.data.bindField || 'id';
    this.displayField = params.data.displayField || 'name';
  }

  getValue(): any {
    return this.value;
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }
}
