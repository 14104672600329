import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-hyperlink-renderer',
  template: `
    <a *ngIf="!noLink" [routerLink]="route">{{ value }}</a>
    <span *ngIf="noLink">{{ value }}</span>
  `,
})
export class HyperlinkRendererComponent implements ICellRendererAngularComp {
  noLink = false;
  value: boolean;
  route: string | any[];
  routeParam: any;

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: any): boolean {
    const direct = params['routeDirect'] as string | any[];
    if (direct) {
      this.route = direct;
      this.noLink = false;
    } else {
      const initialRoute = params['route'];
      this.noLink = !initialRoute;
      this.route = [initialRoute];
      if (params.node.data) {
        const routeParam = params.node.data[params['routeParam']];
        this.route.push(routeParam);
      }
    }

    this.value = params.value;
    return true;
  }
}
