import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Stateful, StatefulHelpers } from '../../interfaces/stateful.interface';
import {
  BacoBankAccountDto,
  BacoLoadableTransactionDto,
} from '../../interfaces';
import { BankAccountClient, FeedClient } from '../../common';
import { BacoFeedStore } from './baco-feed.store';
import { ParamHelperService } from '../../services/param-helper-service';

@Injectable()
export class BacoTransactionStore implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  public readonly bankAccounts$ = new BehaviorSubject<
    Stateful<BacoBankAccountDto[]>
  >(StatefulHelpers.pending<BacoBankAccountDto[]>());
  public readonly transactions$ = new BehaviorSubject<
    Stateful<BacoLoadableTransactionDto[]>
  >(StatefulHelpers.pending<BacoLoadableTransactionDto[]>());
  public readonly selectedBank$ =
    new BehaviorSubject<BacoBankAccountDto | null>(null);
  public readonly dateRange$ = new ReplaySubject<IDateRange>(1);

  public readonly refreshBankAccounts$ = new BehaviorSubject<void>(null);
  public readonly refreshTransactions$ = new BehaviorSubject<void>(null);

  public readonly descriptionEditable$ = new BehaviorSubject<boolean>(false);
  public readonly displayByAccountCode$ = new BehaviorSubject<boolean>(false);
  public readonly hideCodedTransactions$ = new BehaviorSubject<boolean>(false);
  public readonly hideLockedTransactions$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _feedClient: FeedClient,
    private readonly _bankAccountClient: BankAccountClient,
    private readonly _feedStore: BacoFeedStore,
    private readonly _paramHelperService: ParamHelperService
  ) {
    this.init();
  }

  public setTransaction(
    transactionId: string,
    newItem: BacoLoadableTransactionDto,
    loading = false
  ) {
    const transactions = this.transactions$.getValue();
    const transactionIndex = this.getTransactionIndex(transactionId);
    if (transactionIndex >= 0) {
      newItem.loading = loading;
      transactions.data[transactionIndex] = { ...newItem };
      this.transactions$.next(transactions);
    }
  }

  public setTransactionLoadingState(transactionId: string, loading = false) {
    const transactions = this.transactions$.getValue();
    const transactionIndex = transactions.data.findIndex(
      (x) => x.id === transactionId
    );
    if (transactionIndex >= 0) {
      const transaction = transactions.data[transactionIndex];
      transaction.loading = loading;
      transactions.data[transactionIndex] = { ...transaction };
      this.transactions$.next(transactions);
    }
  }

  public getTransaction(transactionId: string): BacoLoadableTransactionDto {
    const transactions = this.transactions$.getValue();
    const transaction = transactions.data.find((x) => x.id === transactionId);
    return transaction;
  }

  private getTransactionIndex(transactionId: string): number {
    const transactions = this.transactions$.getValue();
    const transactionIndex = transactions.data.findIndex(
      (x) => x.id === transactionId
    );
    return transactionIndex;
  }

  public changeSelectedBank(selectedBank: BacoBankAccountDto) {
    this._router.navigate([], {
      queryParams: {
        bankId: selectedBank.id,
      },
      queryParamsHandling: 'merge',
    });
  }

  public refreshTransactions() {
    this.refreshTransactions$.next();
  }

  public refreshBankAccounts() {
    this.refreshBankAccounts$.next();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private init(): void {
    this._route.queryParams
      .pipe(
        switchMap((params) =>
          of(
            this._paramHelperService.toBooleanOrDefault(
              params.descriptionEditable
            )
          )
        ),
        distinctUntilChanged(),
        tap((value) => {
          this.descriptionEditable$.next(value);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    this._route.queryParams
      .pipe(
        switchMap((params) =>
          of(
            this._paramHelperService.toBooleanOrDefault(
              params.displayByAccountCode
            )
          )
        ),
        distinctUntilChanged(),
        tap((value) => {
          this.displayByAccountCode$.next(value);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    this._route.queryParams
      .pipe(
        switchMap((params) =>
          of(
            this._paramHelperService.toBooleanOrDefault(
              params.hideCodedTransactions
            )
          )
        ),
        distinctUntilChanged(),
        tap((value) => {
          this.hideCodedTransactions$.next(value);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    this._route.queryParams
      .pipe(
        switchMap((params) =>
          of(
            this._paramHelperService.toBooleanOrDefault(
              params.hideLockedTransactions
            )
          )
        ),
        distinctUntilChanged(),
        tap((value) => {
          this.hideLockedTransactions$.next(value);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    const bankIdParam$: Observable<string | null> =
      this._route.queryParams.pipe(
        switchMap((params) =>
          of(this._paramHelperService.toStringOrNull(params.bankId))
        ),
        distinctUntilChanged()
      );

    // Whenever a new feed with status Success is fired, get bank accounts from API again
    combineLatest([this._feedStore.feed$, this.refreshBankAccounts$])
      .pipe(
        tap(() =>
          this.bankAccounts$.next(
            StatefulHelpers.pending<BacoBankAccountDto[]>()
          )
        ),
        filter(([statefulFeed]) => statefulFeed.state === 'SUCCESS'),
        switchMap(([statefulFeed]) =>
          this._feedClient.getBankAccounts(statefulFeed.data.id).pipe(
            catchError((err) => {
              this.bankAccounts$.next(
                StatefulHelpers.error<BacoBankAccountDto[]>(err)
              );
              return EMPTY;
            })
          )
        ),
        takeUntil(this._destroy$)
      )
      .subscribe({
        next: (result) => {
          this.bankAccounts$.next(
            StatefulHelpers.success<BacoBankAccountDto[]>(result)
          );
        },
        error: (err) => {
          this.bankAccounts$.next(
            StatefulHelpers.error<BacoBankAccountDto[]>(err)
          );
        },
      });

    this.refreshBankAccounts$.pipe();

    // Whenever either of these observables changes, check the bankId again.
    combineLatest([
      this.bankAccounts$.pipe(
        filter((stateful) => stateful.state === 'SUCCESS')
      ),
      bankIdParam$,
    ])
      .pipe(
        tap(([bankAccounts, bankId]) => {
          if (!bankId) {
            this.selectedBank$.next(bankAccounts.data[0] ?? null);
            return;
          }
          var bank = bankAccounts.data.find((x) => x.id == bankId);
          if (bank) {
            this.selectedBank$.next(bank);
          } else {
            this.bankAccounts$.next(
              StatefulHelpers.error<BacoBankAccountDto[]>(
                'Invalid bank id. Given id does not match any of the bank accounts for this feed.'
              )
            );
            this.selectedBank$.next(null);
          }
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    const startDateParam$: Observable<Date | null> =
      this._route.queryParams.pipe(
        switchMap((params) =>
          of(this._paramHelperService.toDateOrNull(params.startDate))
        ),
        distinctUntilChanged()
      );

    const endDateParam$: Observable<Date | null> = this._route.queryParams.pipe(
      switchMap((params) => {
        let date = this._paramHelperService.toDateOrNull(params.endDate);
        // Adjust the end date to include the entire day up to the last millisecond for filtering.
        if (date !== null) date = new Date(date.getTime() + 86399999);
        return of(date);
      }),
      distinctUntilChanged()
    );

    combineLatest([startDateParam$, endDateParam$])
      .pipe(
        switchMap(([startDate, endDate]) => {
          let finalStartDate: Date;
          let finalEndDate: Date;

          if (!startDate && !endDate) {
            const today = new Date();
            const firstDayOfCurrentMonth = new Date(
              today.getFullYear(),
              today.getMonth(),
              1
            );
            const lastDayOfCurrentMonth = new Date(
              today.getFullYear(),
              today.getMonth() + 1,
              0
            );

            finalStartDate = firstDayOfCurrentMonth;
            finalEndDate = lastDayOfCurrentMonth;
          } else if (!startDate) {
            const firstDayOfEndDateMonth = new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              1
            );

            finalStartDate = firstDayOfEndDateMonth;
          } else if (!endDate) {
            const lastDayOfStartDateMonth = new Date(
              startDate.getFullYear(),
              startDate.getMonth() + 1,
              0
            );

            finalEndDate = lastDayOfStartDateMonth;
          } else {
            finalStartDate = startDate;
            finalEndDate = endDate;
          }

          return of({
            startDate: finalStartDate,
            endDate: finalEndDate,
          } as IDateRange);
        }),
        distinctUntilChanged((prev, curr) => {
          return (
            prev.startDate == curr.startDate && prev.endDate == curr.endDate
          );
        }),
        tap((dateRange) => {
          this.dateRange$.next(dateRange);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

    combineLatest([
      this.selectedBank$.pipe(filter((selectedBank) => selectedBank != null)),
      this.dateRange$,
      this.refreshTransactions$,
    ])
      .pipe(
        tap(() => {
          let transactions = this.transactions$.getValue();
          transactions.state = 'PENDING';
          this.transactions$.next(transactions);
        }),
        switchMap(([selectedBank, dateRange]) =>
          this._bankAccountClient
            .getTransactions(
              selectedBank.id,
              dateRange.startDate,
              dateRange.endDate
            )
            .pipe(
              catchError((err) => {
                console.log(err);
                this.transactions$.next(
                  StatefulHelpers.error<BacoLoadableTransactionDto[]>(err)
                );
                return EMPTY;
              })
            )
        ),
        takeUntil(this._destroy$)
      )
      .subscribe((transactions) => {
        this.transactions$.next(
          StatefulHelpers.success<BacoLoadableTransactionDto[]>(
            transactions.map((x) => {
              return { ...x, loading: false };
            })
          )
        );
      });
  }
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export interface ILocalTransactionFilters {
  hideCoded: boolean;
  hideLocked: boolean;
}
