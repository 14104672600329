<div class="center">

  <img width="250" src="./assets/images/logo.png" alt="Access Logo">

  <div class="spacer-md"></div>

  <p><strong>{{title}}</strong></p>

  <div class="spacer-md"></div>

  <p>
    {{message}}
  </p>
  <button type="button" class="btn btn-primary" (click)="logout()" *ngIf="showLogout"> Logout </button>
  <a href="" type="button" class="btn btn-primary"*ngIf="!showLogout"> Return to the Home Page </a>

</div>
