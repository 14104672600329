export class Confirmation {
  constructor(obj: Partial<Confirmation>) {
    Object.assign(this, obj);
  }

  title: string;
  text: string;
  action: () => any;
  danger = false;
  passPhrase: string = null;
  shouldFocusOnSubmitButton: boolean;
}

export class EnhancedConfirmation extends Confirmation {
  constructor(obj: Partial<EnhancedConfirmation>) {
    super(obj);
    Object.assign(this, obj);
  }

  cancelButton: string;
  approveBtn: string;
  additionalInfoText: string;
  alertText: string;
  alertClass: 'alert-danger' | 'alert-warning' | 'alert-info' | 'alert-success';
  showUnderstandConsequencesSuffix = true;

  approveAction: () => any;
  cancelAction: () => any;
}
