<crs-form-error [error]="error"></crs-form-error>

<div class="btn-toolbar d-flex" role="toolbar">
  <button class="btn btn-secondary mr-2" (click)="addAsset()">
    <i aria-hidden="true" class="fas fa-plus inset"></i>
    New Asset
  </button>

  <div class="icon-input-wrapper flex-grow-1 mr-2">
    <i aria-hidden="true" class="fas fa-search input-icon"></i>
    <input
      aria-describedby="searchIcon"
      placeholder="Search..."
      type="search"
      [formControl]="search"
    />
  </div>

  <div class="dropdown mr-2">
    <button
      aria-expanded="false"
      aria-haspopup="true"
      class="btn btn-secondary dropdown-toggle"
      data-toggle="dropdown"
      type="button"
    >
      <i aria-hidden="true" class="fas fa-th-list"></i>
      Bulk Actions
    </button>
    <div class="dropdown-menu">
      <h6 class="dropdown-header">With selection:</h6>
      <button
        class="dropdown-item"
        type="button"
        [disabled]="!selectedAssets.length"
        (click)="moveSelectedAssets()"
      >
        <i aria-hidden="true" class="fas fa-dolly"></i>
        Move to Another Asset Group
      </button>
      <button
        class="dropdown-item"
        type="button"
        [disabled]="!selectedAssets.length"
        (click)="allocateSelectedAssetsToPool()"
      >
        <i aria-hidden="true" class="fas fa-layer-group"></i>
        Allocate to a Pool
      </button>
      <!-- <button type="button" class="dropdown-item" [disabled]="!selectedAssets.length">
        <i class="fas fa-file-invoice-dollar"></i>
        Dispose
      </button> -->
      <div class="dropdown-divider"></div>
      <button class="dropdown-item" type="button" (click)="importAssets()">
        <i aria-hidden="true" class="fas fa-file-excel"></i>
        Import from Excel
      </button>
    </div>
  </div>

  <button
    class="btn btn-secondary"
    title="Collapse All"
    title="Refresh"
    (click)="refresh()"
  >
    <i aria-hidden="true" class="fas fa-sync-alt"></i>
  </button>
</div>

<div>
  <span class="text-muted small">ADVANCED FILTERS</span>

  <div class="form-check ml-4 mt-2 d-inline-block">
    <input
      class="form-check-input"
      id="showAllCheck"
      type="checkbox"
      [checked]="showAll"
      (click)="showAll = !showAll; refresh()"
    />
    <label class="form-check-label" for="showAllCheck"
      >Show all assets for all years</label
    >
  </div>

  <div class="form-check ml-4 mt-2 d-inline-block">
    <input
      class="form-check-input"
      id="notPooledAssets"
      type="checkbox"
      [checked]="notPooledAssets"
      (click)="notPooledAssets = !notPooledAssets; refresh()"
    />
    <label class="form-check-label" for="notPooledAssets"
      >Show assets not allocated to a pool</label
    >
  </div>
</div>

<div class="spacer"></div>

<div class="position-relative">
  <crs-busy *ngIf="busy.loading"></crs-busy>

  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    rowSelection="multiple"
    style="width: 100%"
    [gridOptions]="gridOptions"
    [rowData]="assets"
    [rowMultiSelectWithClick]="true"
  >
    <ag-grid-column
      headerName=""
      [checkboxSelection]="true"
      [headerCheckboxSelection]="true"
      [maxWidth]="40"
      [suppressMenu]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="code"
      headerName="Code"
      [width]="50"
    ></ag-grid-column>
    <ag-grid-column
      field="name"
      headerName="Name"
      type="hyperlinkColumn"
      [flex]="3"
      [minWidth]="150"
    ></ag-grid-column>
    <ag-grid-column
      field="identifier"
      headerName="Identifier"
      [width]="70"
    ></ag-grid-column>
    <ag-grid-column
      field="cost"
      headerName="Cost"
      type="dollarColumn"
    ></ag-grid-column>
    <ag-grid-column
      field="acquisitionDate"
      headerName="Purchase Date"
      type="dateColumn"
      [maxWidth]="180"
    ></ag-grid-column>
  </ag-grid-angular>
</div>
