import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import {
  catchError,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { EnhancedConfirmation, MessageService, ModalService } from '../../core';
import { ElectronicSignatureType } from '../enums';
import { DocumentIntegrationService } from './document-integration.datasource';
import {
  ElectronicSignatureModel,
  PleaseSignModel,
} from './electronicSignature';

@Component({
  selector: 'crs-document-integration',
  templateUrl: './document-integration.component.html',
  providers: [DocumentIntegrationService],
  styleUrls: ['./document-integration.component.scss'],
})
export class DocumentIntegrationConfigurationComponent
  implements OnInit, OnDestroy
{
  busy = {
    load: null,
    submit: null,
    delete: null,
    pleaseSignKeysLoading: false,
    checkingAccessDocuments: false,
    checkingUserInput: false,
  };
  signatures = ElectronicSignatureType;
  error: string;

  public form: UntypedFormGroup;
  isAccessDocumentsReachable: boolean;
  private currentIntegrationValue: any = null;

  get isPleaseSign(): boolean {
    return this._isPleaseSign(this.signatureTypeControl.value);
  }

  get isAccessDocuments(): boolean {
    return this._isAccessDocuments(this.signatureTypeControl.value);
  }

  get signatureTypeControl(): AbstractControl {
    return this.form.controls['name'];
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private documentIntegrationService: DocumentIntegrationService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private modalService: ModalService,
    private router: Router
  ) {
    this.buildForm();
  }

  public ngOnInit() {
    this.formListening();
    this.loadESignatureSettings();
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onClickSubmit() {
    if (!this.form.valid) {
      this.form.markAsTouched();
      return;
    }
    this.handleInteraction();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      pleaseSign: this.formBuilder.group({
        publicKey: [null],
        secretKey: [null],
        version: [null],
      }),
    });
  }

  private formListening(): void {
    this.signatureTypeControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((eSignType) => {
        this.loadCurrentSignData(eSignType);
        this.setFormValidation(eSignType);
      });
  }

  private async loadCurrentSignData(eSignType: number): Promise<void> {
    if (this._isPleaseSign(eSignType)) {
      this.getPleaseSignKeys$().pipe(takeUntil(this.destroy$)).subscribe();
    }
    if (this._isAccessDocuments(eSignType)) {
      if (this.currentIntegrationValue === ElectronicSignatureType.PleaseSign) {
        this.busy.checkingUserInput = true;

        await this.modalService
          .confirmationEx(
            new EnhancedConfirmation({
              title: 'PleaseSign Will Be Disabled',
              additionalInfoText: `
                <p>You can only enable one document integration at a time.</p>
                <p>If you proceed with Access Documents, your PleaseSign integration will be disabled.</p>`,
              approveAction: () =>
                this.getAccessDocumentsStatus$().subscribe(
                  (status) => (this.isAccessDocumentsReachable = status)
                ),
              cancelAction: () => {},
              approveBtn: 'Enable Access Documents',
            })
          )
          .finally(() => (this.busy.checkingUserInput = false));
      } else {
        this.getAccessDocumentsStatus$().subscribe(
          (status) => (this.isAccessDocumentsReachable = status)
        );
      }
    }

    if (!this.currentIntegrationValue) {
      this.currentIntegrationValue = eSignType;
    }
  }

  private setFormValidation(eSignType: number): void {
    if (this._isPleaseSign(eSignType)) {
      this.setControlValidation('pleaseSign.publicKey', [
        Validators.required,
        Validators.pattern('^[0-9a-zA-Z]*$'),
        Validators.maxLength(50),
      ]);
      this.setControlValidation('pleaseSign.secretKey', [
        Validators.required,
        Validators.pattern('^[0-9a-zA-Z]*$'),
        Validators.maxLength(50),
      ]);
    } else if (this._isAccessDocuments(eSignType)) {
      ['pleaseSign.publicKey', 'pleaseSign.secretKey'].forEach((controlName) =>
        this.clearControlValidation(controlName)
      );
    } else {
      ['pleaseSign.publicKey', 'pleaseSign.secretKey'].forEach((controlName) =>
        this.clearControlValidation(controlName)
      );
    }
  }

  private setControlValidation(controlName: string, validators): void {
    this.form.get(controlName).setValidators(Validators.compose(validators));
    this.form.get(controlName).updateValueAndValidity();
  }

  private clearControlValidation(controlName: string): void {
    this.form.get(controlName).setValue(null);
    this.form.get(controlName).clearValidators();
    this.form.get(controlName).updateValueAndValidity();
  }

  private loadESignatureSettings() {
    this.busy.load = this.documentIntegrationService
      .getDocumentIntegrationSetting$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (eSignature) => this.completeForm(eSignature),
        (err) => this.showError(err)
      );
  }

  private completeForm(eSignature): void {
    const eSignatureModel = {
      name: parseInt(ElectronicSignatureType[eSignature.value], null),
    } as ElectronicSignatureModel;

    this.form.patchValue(eSignatureModel);
  }

  private handleInteraction(): void {
    const eSignature = this.form.value;

    this.busy.submit = this.documentIntegrationService
      .setESignatureSetting$({
        name: 'DefaultESignature',
        value: ElectronicSignatureType[eSignature.name],
      })
      .pipe(
        switchMap(() => {
          if (this._isPleaseSign(eSignature.name)) {
            const model = eSignature.pleaseSign as PleaseSignModel;
            return this.setPleaseSignKeys$(model);
          } else {
            return of(0);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.router.navigate(['../admin']);
        },
        (err) => this.showError(err)
      );
  }

  public onClickCancel(): void {
    this.router.navigate(['../admin']);
  }

  private setPleaseSignKeys$(model: PleaseSignModel): Observable<any> {
    return this.documentIntegrationService.setPleaseSignKeys$(model).pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      switchMap(() => this.getPleaseSignKeys$())
    );
  }

  private getPleaseSignKeys$(): Observable<PleaseSignModel> {
    this.busy.pleaseSignKeysLoading = true;

    return this.documentIntegrationService
      .getPleaseSignKeys$()
      .pipe(finalize(() => (this.busy.pleaseSignKeysLoading = false)))
      .pipe(tap((psKeys) => this.form.controls['pleaseSign'].setValue(psKeys)));
  }

  private showError(error) {
    this.error = error;
  }

  private _isPleaseSign(eSignType: number): boolean {
    return eSignType === ElectronicSignatureType.PleaseSign;
  }

  private _isAccessDocuments(eSignType: number): boolean {
    return eSignType === ElectronicSignatureType['Access Documents'];
  }

  private getAccessDocumentsStatus$(): Observable<boolean> {
    this.busy.checkingAccessDocuments = true;

    return this.documentIntegrationService.getAccessDocumentsStatus$().pipe(
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      }),
      tap((status) => {
        if (status === true)
          this.messageService.success('Access Documents connected');
        if (status === false)
          this.messageService.error('Access Documents disconnected');
      }),
      finalize(() => (this.busy.checkingAccessDocuments = false))
    );
  }
}
