<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item">
      <a [routerLink]="'/admin/reports/notes/reporting-suites'">Notes</a>
    </li>
    <li aria-current="page" class="breadcrumb-item active">Reporting Suite</li>
  </ol>
</nav>

<form
  class="pad-x"
  novalidate
  [formGroup]="form"
  [ngBusy]="busy.load"
  (validSubmit)="saveAs()"
>
  <h2 class="form-group-title">{{ isAdd ? 'New' : '' }} Reporting Suite</h2>

  <hr />

  <div class="row">
    <div class="col-form-md">
      <div class="form-group required">
        <label class="control-label">Name</label>
        <input class="form-control" formControlName="name" type="text" />
      </div>

      <div class="mb-4" *ngIf="isMaster">
        <span class="badge-pill badge-primary"
          >Maintained by Access Ledger</span
        >
      </div>

      <div class="form-group">
        <label class="control-label">Reporting Framework</label>
        <input
          class="form-control"
          formControlName="reportingFramework"
          type="text"
        />
      </div>
    </div>
    <div class="col-form-lg">
      <div class="form-group">
        <label class="control-label">Description</label>
        <textarea
          class="form-control"
          formControlName="description"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="col-form-md">
      <div class="form-group">
        <label class="control-label">Application Date</label>
        <crs-date-picker
          formControlName="applicationDate"
          [clearable]="true"
        ></crs-date-picker>
        <small class="form-text text-muted">
          Applies to reporting periods beginning on or after this date (if
          applicable)
        </small>
      </div>

      <div class="form-group">
        <label class="control-label">Earliest Application Date</label>
        <crs-date-picker
          formControlName="earliestApplicationDate"
          [clearable]="true"
        ></crs-date-picker>
        <small class="form-text text-muted">
          May optionally apply to reporting periods beginning on or after this
          date (if applicable)
        </small>
      </div>

      <div class="form-group">
        <label class="control-label">Expiry Date</label>
        <crs-date-picker
          formControlName="expiryDate"
          [clearable]="true"
        ></crs-date-picker>
        <small class="form-text text-muted">
          Should not be applied to reporting periods beginning on or after this
          date (if applicable)
        </small>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <div class="card">
    <h2
      aria-controls="collapseAccountingPolicies"
      class="card-header card-header-collapsible form-group-title"
      data-target="#collapseAccountingPolicies"
      data-toggle="collapse"
      [attr.aria-expanded]="accountingPoliciesCollapsed"
      (click)="accountingPoliciesCollapsed = !accountingPoliciesCollapsed"
    >
      Accounting Policies
      <i
        aria-hidden="true"
        class="fas fa-caret-down"
        *ngIf="accountingPoliciesCollapsed"
      ></i>
      <i
        aria-hidden="true"
        class="fas fa-caret-up"
        *ngIf="!accountingPoliciesCollapsed"
      ></i>
    </h2>
    <div
      aria-labelledby="collapseAccountingPolicies"
      class="collapse"
      id="collapseAccountingPolicies"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="btn-menu">
              <button
                class="btn btn-secondary"
                type="button"
                [promiseBtn]="busy.addPolicyVariant"
                (click)="addPolicyVariant()"
              >
                <i aria-hidden="true" class="fas fa-plus inset"></i>
                Add Policy Variant
              </button>
            </div>

            <ag-grid-angular
              #agGridOffice
              class="ag-theme-material"
              style="width: 100%"
              [class.d-none]="!policyVariants?.length"
              [gridOptions]="policyVariantOptions"
              [rowData]="policyVariants"
            >
              <ag-grid-column
                field="policyName"
                headerName="Policy"
              ></ag-grid-column>
              <ag-grid-column
                field="name"
                headerName="Variant"
              ></ag-grid-column>
              <ag-grid-column
                field="isDefault"
                headerName="Default"
                type="booleanColumn"
                [editable]="true"
                [maxWidth]="120"
                [minWidth]="120"
              ></ag-grid-column>
              <ag-grid-column
                type="optionsColumn"
                *ngIf="isAdmin"
                [cellRendererParams]="{ ngTemplate: policyVariantOptionsCell }"
                [maxWidth]="90"
                [minWidth]="90"
              ></ag-grid-column>
            </ag-grid-angular>

            <div
              class="alert alert-info"
              role="alert"
              *ngIf="!policyVariants?.length"
            >
              There are no linked policy variants for this Reporting Suite.
              Click <strong>Add Policy Variant</strong> above to link a policy
              variant to this reporting suite.
            </div>

            <ng-template #policyVariantOptionsCell let-row>
              <div
                class="btn-mini-menu"
                *ngIf="
                  !row.isHeader && !row.isSystemAccount && !row.isSourceAccount
                "
              >
                <a
                  class="btn-sm fas-btn-ghost"
                  [routerLink]="[]"
                  (click)="removePolicyVariant(row)"
                >
                  <i aria-hidden="true" class="fas fa-trash"></i>
                </a>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <h2
      aria-controls="collapseDisclosureTemplates"
      class="card-header card-header-collapsible form-group-title"
      data-target="#collapseDisclosureTemplates"
      data-toggle="collapse"
      [attr.aria-expanded]="disclosureTemplatesCollapsed"
      (click)="disclosureTemplatesCollapsed = !disclosureTemplatesCollapsed"
    >
      Disclosure Templates
      <i
        aria-hidden="true"
        class="fas fa-caret-down"
        *ngIf="disclosureTemplatesCollapsed"
      ></i>
      <i
        aria-hidden="true"
        class="fas fa-caret-up"
        *ngIf="!disclosureTemplatesCollapsed"
      ></i>
    </h2>
    <div
      aria-labelledby="collapseDisclosureTemplates"
      class="collapse"
      id="collapseDisclosureTemplates"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="btn-menu">
              <button
                class="btn btn-secondary"
                type="button"
                [promiseBtn]="busy.addDisclosureVariant"
                (click)="addDisclosureVariant()"
              >
                <i aria-hidden="true" class="fas fa-plus inset"></i>
                Add Disclosure Variant
              </button>
            </div>

            <ag-grid-angular
              #agGridOffice
              class="ag-theme-material"
              style="width: 100%"
              [class.d-none]="!disclosureVariants?.length"
              [gridOptions]="disclosureVariantOptions"
              [rowData]="disclosureVariants"
            >
              <ag-grid-column
                field="templateName"
                headerName="Disclosure"
              ></ag-grid-column>
              <ag-grid-column
                field="name"
                headerName="Variant"
              ></ag-grid-column>
              <ag-grid-column
                field="isDefault"
                headerName="Default"
                type="booleanColumn"
                [editable]="true"
                [maxWidth]="120"
                [minWidth]="120"
              ></ag-grid-column>
              <ag-grid-column
                type="optionsColumn"
                *ngIf="isAdmin"
                [cellRendererParams]="{
                  ngTemplate: disclosureVariantOptionsCell
                }"
                [maxWidth]="90"
                [minWidth]="90"
              ></ag-grid-column>
            </ag-grid-angular>

            <div
              class="alert alert-info"
              role="alert"
              *ngIf="!disclosureVariants?.length"
            >
              There are no linked disclosure variants for this Reporting Suite.
              Click <strong>Add Disclosure Variant</strong> above to link a
              disclosure variant to this reporting suite.
            </div>

            <ng-template #disclosureVariantOptionsCell let-row>
              <div
                class="btn-mini-menu"
                *ngIf="
                  !row.isHeader && !row.isSystemAccount && !row.isSourceAccount
                "
              >
                <a
                  class="btn-sm fas-btn-ghost"
                  [routerLink]="[]"
                  (click)="removeDisclosureVariant(row)"
                >
                  <i aria-hidden="true" class="fas fa-trash"></i>
                </a>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu form-bottom-menu" *ngIf="!isMaster">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        [promiseBtn]="busy.submit"
        (click)="save(true)"
      >
        Save and Close
      </button>
      <button
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        type="button"
      >
        <span class="sr-only">Save options</span>
      </button>
      <div class="dropdown-menu">
        <button class="dropdown-item" type="submit" (click)="save(false)">
          Save Only
        </button>
        <button class="dropdown-item" type="submit" (click)="saveAs()">
          Save As...
        </button>
      </div>
    </div>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
    <button
      class="btn btn-outline-danger float-right"
      title="Delete"
      type="button"
      *ngIf="!isAdd && isAdmin"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
  </div>

  <div class="btn-menu form-bottom-menu" *ngIf="isMaster">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      Save as New and Close
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Close
    </button>
  </div>
</form>
