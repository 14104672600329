
export class TrusteeType {
  id: number;
  name: string;

  constructor(id: number = null, name: string = null) {
    this.id = id;
    this.name = name;
  }
}

export const TrusteeTypes = [
  new TrusteeType(1, 'Corporate Trustee'),
  new TrusteeType(2, 'Individual'),
];