<nav
  aria-label="Report navigation"
  class="navbar navbar-dark bg-dark navbar-expand min-width-tablet d-flex justify-content-between"
>
  <ul class="navbar-nav mr-5" *ngIf="!restrictSave">
    <!-- <li class="nav-item">
      <a [routerLink]="" class="btn btn-sm btn-dark">
        <i class="fas fa-save" aria-hidden="true"></i><br />
        <small>SAVE</small>
      </a>
    </li> -->
    <li class="nav-item">
      <a
        class="btn btn-sm btn-dark"
        queryParamsHandling="merge"
        title="Export to PDF"
        [class.disabled]="
          busy.exportPdf ||
          (isFetchingConnectedToIs$ | async) ||
          (isFetchingDocumentIntegrationSetting$ | async)
        "
        [routerLink]="[]"
        (click)="exportAsPdf()"
      >
        <i
          aria-hidden="true"
          aria-hidden="true"
          class="fas fa-long-arrow-alt-down"
          [ngClass]="{ 'fa-spinner fa-pulse fa-fw': busy.exportPdf }"
        ></i>
        <br />
        <small>PDF</small>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="btn btn-sm btn-dark"
        queryParamsHandling="merge"
        title="Export to Excel"
        [class.disabled]="
          busy.exportExcel ||
          (isFetchingConnectedToIs$ | async) ||
          (isFetchingDocumentIntegrationSetting$ | async)
        "
        [routerLink]="[]"
        (click)="exportAsExcel()"
      >
        <i
          aria-hidden="true"
          aria-hidden="true"
          class="fas fa-long-arrow-alt-down"
          [ngClass]="{ 'fa-spinner fa-pulse fa-fw': busy.exportExcel }"
        ></i>
        <br />
        <small>EXCEL</small>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="btn btn-sm btn-dark"
        queryParamsHandling="merge"
        title="Export to Word"
        [class.disabled]="
          busy.exportWord ||
          (isFetchingConnectedToIs$ | async) ||
          (isFetchingDocumentIntegrationSetting$ | async)
        "
        [routerLink]="[]"
        (click)="exportAsWord()"
      >
        <i
          aria-hidden="true"
          aria-hidden="true"
          class="fas fa-long-arrow-alt-down"
          [ngClass]="{ 'fa-spinner fa-pulse fa-fw': busy.exportWord }"
        ></i>
        <br />
        <small>WORD</small>
      </a>
    </li>
    <li class="nav-item" *ngIf="isDocumentIntegrationPleaseSign">
      <a
        class="btn btn-sm btn-dark"
        queryParamsHandling="merge"
        title="Send to E-Signature"
        [class.disabled]="busy.sendESign"
        [routerLink]="[]"
        (click)="sendToESign()"
      >
        <i
          aria-hidden="true"
          aria-hidden="true"
          class="fas fa-long-arrow-alt-up"
        ></i>
        <br />
        <small>E-SIGN</small>
      </a>
    </li>
  </ul>

  <crs-report-top-navigation
    [activePageIndex]="activePageIndex"
    [pages]="report.pages"
    (selectedPage)="selectPage($event)"
    (selectedPageIndex)="selectPageIndex($event)"
  />

  <!-- <ul class="navbar-nav ml-4">
    <span class="bg-warning" *ngIf="report.pagesWithWarnings?.length">{{report.pagesWithWarnings.length}} Pages with Warnings</span>
  </ul> -->

  <ul class="navbar-nav">
    <li class="nav-item" *ngIf="!restrictSaveDocument">
      <a
        class="btn btn-dark"
        title="Save report data"
        [class.disabled]="
          busy.saveReportData || reportDocumentListItem?.reportSignStatus == 1
        "
        (click)="saveReportData()"
      >
        Save
        <i
          aria-hidden="true"
          aria-hidden="true"
          class="fas fa-save"
          [ngClass]="{ 'fa-spinner fa-pulse fa-fw': busy.saveReportData }"
        ></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="btn btn-dark" [routerLink]="[]" (click)="onClickClose()">
        Close <i aria-hidden="true" class="fas fa-times"></i>
      </a>
    </li>
  </ul>
</nav>

<div class="wrapper">
  <crs-report-sidebar
    [activePageIndex]="activePageIndex"
    [report]="report"
    (selectedPage)="selectPage($event)"
  />

  <!-- The Page Itself-->
  <div class="page-container">
    <div [innerHTML]="styles | safe : 'html'"></div>

    <div class="page shadow" [class.page-landscape]="page.orientation === 1">
      <!-- Report and Page warnings -->
      <div
        class="alert alert-background fade show"
        role="alert"
        *ngIf="report.warnings && report.warnings.length"
      >
        <crs-report-warning [report]="report" [warnings]="report.warnings" />
      </div>
      <div
        class="alert alert-background fade show"
        role="alert"
        *ngIf="page.warnings && page.warnings.length"
      >
        <crs-report-warning [report]="report" [warnings]="page.warnings" />
      </div>

      <div class="page-header">
        <crs-report-content
          [content]="page.pageContent.header"
          [output]="true"
          [reportColumns]="report.reportHeaderColumns"
          [reportMetaData]="reportMetaData"
          [reportSignStatus]="report.reportSignStatus"
          [style]="report.style"
        />
      </div>

      <div class="page-content">
        <crs-report-content
          [content]="page.pageContent.content"
          [output]="true"
          [reportColumns]="report.reportHeaderColumns"
          [reportMetaData]="reportMetaData"
          [reportSignStatus]="report.reportSignStatus"
          [style]="report.style"
        />
      </div>

      <div class="page-footer d-flex justify-content-between">
        <crs-report-footer
          [activePageIndex]="activePageIndex"
          [footer]="page.pageContent.footer"
        />
      </div>
    </div>
  </div>
</div>
