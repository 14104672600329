<!-- Title -->
  <td class="financial-title">
    <div class="financial-cell">
      <crs-financial-editable-element [index]="index" [element]="element" [financialContent]="financialContent" *ngIf="isEditing"></crs-financial-editable-element>
      {{element.title}}
    </div>
  </td>

  <!--Note -->
  <td class="financial-note">
    <div class="financial-cell">
      {{element.note}}
    </div>
  </td>

  <!-- Columns -->
  <ng-container *ngIf="financialContent.output">
    <td class="financial-value" [class.drill-down]="hasDrillDown(i)" (click)="drillDown(i)" *ngFor="let value of element.values; let i = index">
      <div class="financial-cell" [class.negative]="value < 0" [ngSwitch]="element.isPercent ? (columnMetadata[i]?.numberFormat >= 2 ? 3 : 1) : (columnMetadata ? columnMetadata[i].numberFormat : 0)">
        <span *ngSwitchCase="0"> {{value | formatNumber:rounding:'-'}}</span>
        <span *ngSwitchCase="1">
          <span *ngIf="value === 0">-</span>
          <span *ngIf="value !== 0">{{value | percent:percentFormatString}}</span>
        </span>
        <span *ngSwitchCase="2"> {{(value >= 0 ? value : -value) | formatNumber:rounding:'-'}} <span *ngIf="value > 0">F</span><span *ngIf="value < 0">U</span> </span>
        <span *ngSwitchCase="3">
          <span *ngIf="value === 0">-</span>
          <span *ngIf="value !== 0">
            {{ (value >= 0 ? value : -value) | percent:percentFormatString}} <span *ngIf="value > 0">F</span><span *ngIf="value < 0">U</span>
          </span>
        </span>
      </div>
    </td>
  </ng-container>

  <!-- Columns -->
  <ng-container *ngIf="!financialContent.output">
    <td [colSpan]="columnMetadata ? columnMetadata.length : 1">
      <crs-financial-formula [totalDefinition]="element.totalDefinition" [financialContent]="financialContent"></crs-financial-formula>
    </td>
  </ng-container>


