<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li aria-current="page" class="breadcrumb-item active">Practices</li>
  </ol>
</nav>

<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.offices"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-no-bottom">
      <button
        class="btn btn-secondary"
        title="Create Practice"
        type="button"
        (click)="addOffice()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Practice
      </button>
    </div>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [columnDefs]="columnDefs"
        [rowData]="officesObservable | async"
        (rowClicked)="selectOffice($event)"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>
