import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Injector,
  ViewChild,
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import {
  ITransactionTableContext,
  SplitTransactionComponent,
  TransactionAccountSelectComponent,
  TransactionTableComponent,
} from 'src/app/baco/components';
import { TransactionService } from 'src/app/baco/services';
import { ModalService } from 'src/app/core';
import {
  BacoAccountDto,
  BacoLoadableTransactionDto,
  getTransactionAccountSelectionsIdentifier,
} from '../../../interfaces';

@Component({
  selector: 'crs-transaction-account-editor',
  templateUrl: './transaction-account-editor.component.html',
  styleUrls: ['./transaction-account-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionAccountEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  @ViewChild(TransactionAccountSelectComponent, { static: false })
  accountSelect: TransactionAccountSelectComponent;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (
      this.elementRef.nativeElement.parentElement &&
      this.elementRef.nativeElement.parentElement.classList.contains(
        'ag-cell-focus'
      )
    ) {
      if (event.key === 'Escape' && this.currentState$.value.canEdit) {
        this.api.stopEditing();
      }
    }
  }

  public displayByAccountCode$;
  public currentState$ = new BehaviorSubject<AccountStateModel>({
    canEdit: false,
    isSplit: false,
    selectedAccount: null,
  });
  public parentComponent: TransactionTableComponent;

  private params: ICellEditorParams;
  private api: GridApi;
  private transaction: BacoLoadableTransactionDto;

  constructor(
    private readonly _transactionService: TransactionService,
    private readonly _modalService: ModalService,
    private injector: Injector,
    private elementRef: ElementRef
  ) {}

  getValue() {
    return getTransactionAccountSelectionsIdentifier(this.transaction);
  }

  public onSelectionChanged(newValue: BacoAccountDto): void {
    this._transactionService.changeAccountCoding(this.transaction, newValue);
    this.params.stopEditing(false);
    if (newValue) this.parentComponent.focusOnNextUncodedRow();
  }

  public splitTransaction() {
    this._modalService
      .openModal(
        SplitTransactionComponent,
        null,
        { transaction: this.transaction },
        { windowClass: 'split-modal', injector: this.injector }
      )
      .then((hasUpdates) => {
        if (hasUpdates) {
          this.params.stopEditing();
        }
      })
      .catch(() => true);
  }

  agInit(params: ICellEditorParams): void {
    this.api = params.api;
    this.params = params;
    this.parentComponent = (
      params.context as ITransactionTableContext
    ).parentComponent;
    this.displayByAccountCode$ =
      this.parentComponent.bacoTransactionStore.displayByAccountCode$;
    this.setDisplayValue(params.data);
  }

  private setDisplayValue(data: any) {
    this.transaction = data as BacoLoadableTransactionDto;

    this.currentState$.next({
      canEdit: !this.transaction.locked,
      isSplit: this.transaction.accountSelections.length > 1,
      selectedAccount: this.transaction.accountSelections[0]?.account,
    });
  }

  ngAfterViewInit(): void {
    if (this.accountSelect) {
      this.accountSelect.open();
    }
  }
}

export interface AccountStateModel {
  canEdit: boolean;
  isSplit: boolean;
  selectedAccount: BacoAccountDto | null;
}
