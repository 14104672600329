import {
  Component,
  OnInit,
  Input,
  Output,
  IterableDiffers,
  DoCheck,
  EventEmitter,
} from '@angular/core';

import { PagedResponse, PagingService, IPageReference } from '../../core';

@Component({
  selector: 'crs-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
})
export class PagingComponent implements OnInit, DoCheck {
  @Input() pagedResponse: PagedResponse<any>;
  @Output() navigate = new EventEmitter<IPageReference>();

  pagesList = [1];
  count = 0;
  pageSize = this.pagingService.pageSize;
  pageSizes = this.pagingService.pageSizes;

  differ = this.differs.find([]).create(null);

  constructor(
    private differs: IterableDiffers,
    private pagingService: PagingService
  ) {}

  changePageSize(size: number) {
    this.pagingService.changePageSize(size);
    this.goToPage(this.pagedResponse.page, size);
  }

  goToPage(page: number, pageSize: number) {
    this.navigate.emit({ page: page, pageSize: pageSize });
  }

  private getPagesList() {
    if (this.pagedResponse) {
      var start = Math.max(
        1,
        Math.min(this.pagedResponse.pages - 4, this.pagedResponse.page - 2)
      );
      var end = Math.min(this.pagedResponse.pages, start + 4);
      this.pagesList = [];
      for (var i = start; i <= end; i++) {
        this.pagesList.push(i);
      }
    } else {
      this.pagesList = [1];
    }
  }

  ngOnInit() {}

  ngDoCheck() {
    if (this.pagedResponse) {
      let change = this.differ.diff(this.pagedResponse.records);
      if (change) this.getPagesList();
    }
  }
}
