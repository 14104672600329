<form novalidate [formGroup]="form" (validSubmit)="submit()">
  <div class="card card-pop">
    <h2
      class="card-header card-header-collapsible form-group-title"
      data-bs-toggle="collapse"
      [attr.data-bs-target]="'#' + 'collapsed-' + index"
      (click)="collapsed = !collapsed; loadVariant()"
    >
      {{ isAdd ? 'New Variant' : variant.name }}
      <span class="badge text-dark mx-1" *ngIf="mode === 0">
        <i aria-hidden="true" class="fas fa-lock"></i>
      </span>
      <i aria-hidden="true" class="fas fa-caret-down" *ngIf="collapsed"></i>
      <i aria-hidden="true" class="fas fa-caret-up" *ngIf="!collapsed"></i>
    </h2>

    <div
      class="collapse"
      [class.show]="startOpen"
      [id]="'collapsed-' + index"
      [ngBusy]="busy.load"
    >
      <div class="card-body">
        <div class="mb-4" *ngIf="variant && mode === 0">
          {{ levels[variant.level] }} Master
          <button
            class="btn btn-sm btn-secondary"
            type="button"
            *ngIf="mode === 0 && allowedToCustomise"
            (click)="customise()"
          >
            Customise
          </button>
        </div>

        <div class="form-group required">
          <label class="control-label">Name</label>
          <input
            class="form-control"
            formControlName="name"
            type="text"
            [readonly]="mode === 0"
            title="Name"
          />
        </div>

        <div class="form-group">
          <label class="control-label">Description</label>
          <textarea
            class="form-control"
            formControlName="description"
            rows="2"
            [readonly]="mode === 0"
            title="Description"
          ></textarea>
        </div>

        <div class="row">
          <div class="col-form-md">
            <div class="form-group">
              <label class="control-label">Application Date</label>
              <crs-date-picker
                formControlName="applicationDate"
                [clearable]="true"
                [readonly]="mode === 0"
              ></crs-date-picker>
              <small class="form-text text-muted">
                Applies to reporting periods beginning on or after this date (if
                applicable)
              </small>
            </div>

            <div class="form-group">
              <label class="control-label">Earliest Application Date</label>
              <crs-date-picker
                formControlName="earliestApplicationDate"
                [clearable]="true"
                [readonly]="mode === 0"
              ></crs-date-picker>
              <small class="form-text text-muted">
                May optionally apply to reporting periods beginning on or after
                this date (if applicable)
              </small>
            </div>

            <div class="form-group">
              <label class="control-label">Expiry Date</label>
              <crs-date-picker
                formControlName="expiryDate"
                [clearable]="true"
                [readonly]="mode === 0"
              ></crs-date-picker>
              <small class="form-text text-muted">
                Should not be applied to reporting periods beginning on or after
                this date (if applicable)
              </small>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="control-label">Reporting Suites</label>
          <crs-reporting-suite-select
            formControlName="reportingSuites"
            [multiple]="true"
            [readonly]="mode === 0"
          ></crs-reporting-suite-select>
        </div>

        <div class="form-group">
          <label class="control-label">Entity Types</label>
          <ng-select
            bindLabel="name"
            bindValue="id"
            formControlName="entityTypes"
            placeholder="Applies to all entity types"
            [class.readonly]="mode === 0"
            [items]="entityTypes"
            [multiple]="true"
          ></ng-select>
        </div>

        <div
          class="alert alert-info alert-dismissible fade show"
          role="alert"
          *ngIf="this.variant.masterChanged"
        >
          The master policy variant to which this relates has changed since you
          last checked. Close this notice once done.
          <button
            aria-label="Close"
            class="close"
            type="button"
            (click)="confirmMasterChangesChecked()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div
          class="form-group"
          *ngIf="
            !variant.masterPolicyVariantContent?.length || hideMasterContent
          "
        >
          <label class="control-label w-100">
            Content
            <button
              class="btn btn-sm btn-secondary float-right"
              type="button"
              *ngIf="variant.masterPolicyVariantContent?.length"
              (click)="hideMasterContent = !hideMasterContent"
            >
              <i aria-hidden="true" class="fas fa-columns"></i>
              Toggle Master Content
            </button>
          </label>
          <div class="card">
            <div class="card-body" [class.readonly]="mode === 0">
              <crs-report-content
                [content]="variant.content"
                [readonly]="mode === 0"
              ></crs-report-content>
            </div>
          </div>
        </div>

        <div
          class="row"
          *ngIf="
            variant.masterPolicyVariantContent?.length && !hideMasterContent
          "
        >
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">Content</label>
              <div class="card">
                <div class="card-body">
                  <crs-report-content
                    [content]="variant.content"
                    [readonly]="mode === 0"
                  ></crs-report-content>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label w-100"
                >Master
                <button
                  class="btn btn-sm btn-secondary float-right"
                  type="button"
                  (click)="hideMasterContent = !hideMasterContent"
                >
                  <i aria-hidden="true" class="fas fa-columns"></i>
                  Toggle Master Content
                </button>
              </label>
              <div class="card">
                <div class="card-body readonly">
                  <crs-report-content
                    [content]="variant.masterPolicyVariantContent"
                    [readonly]="true"
                  ></crs-report-content>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer" *ngIf="mode !== 0 || allowedToCustomise">
        <div class="btn-mini-menu">
          <button
            class="btn btn-sm btn-primary"
            type="submit"
            *ngIf="mode > 0"
            [promiseBtn]="busy.submit"
          >
            Save
          </button>
          <button
            class="btn btn-sm btn-secondary"
            type="button"
            *ngIf="mode === 0 && allowedToCustomise"
            (click)="customise()"
          >
            Customise
          </button>
          <button
            class="btn btn-sm btn-secondary"
            type="button"
            *ngIf="mode > 0 && (mode != 3 || form.dirty)"
            (click)="cancel()"
          >
            Cancel
          </button>
          <button
            class="btn btn-sm btn-outline-danger float-right"
            title="Delete"
            type="button"
            *ngIf="mode == 3"
            [promiseBtn]="busy.delete"
            (click)="delete()"
          >
            <i aria-hidden="true" class="fas fa-trash"></i>
            {{
              variant.masterPolicyVariantId
                ? 'Delete Customised Version'
                : 'Delete'
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
