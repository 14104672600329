<ng-select
  [items]="sourcesObservable | async"
  bindLabel="name"
  [loading]="loading"
  [readonly]="readonly"
>
  <ng-template
    ng-option-tmp
    let-item="item"
    let-index="index"
    let-search="searchTerm"
  >
    <img
      [src]="item?.sourceType?.icon"
      class="icon-img me-2 align-middle"
      [alt]="item?.sourceType?.name"
    />
    <span [ngOptionHighlight]="search">{{ item.name }}</span>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <crs-source-icon [source]="item"></crs-source-icon>
  </ng-template>
</ng-select>
