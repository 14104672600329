<!-- Header -->
<div class="header-container">
  <div>
    <ul class="crumbtrail-nav">
      <li class="main align-middle">
        <div>
          <a class="align-middle" routerLink="/clients">
            <span class="align-middle">
              <i
                class="fas fa-chevron-left align-middle"
                aria-hidden="true"
              ></i>
            </span>
            Clients
          </a>
        </div>
      </li>
      <li>
        <span class="align-middle"> Central </span>
      </li>
    </ul>
  </div>
</div>

<!-- Navbar -->
<nav
  class="navbar navbar-expand-lg navbar-light bg-light"
  *ngIf="route.children.length"
>
  <ul class="navbar-nav">
    <li class="nav-item dropdown me-3">
      <a class="nav-link" routerLink="firms">Organisations</a>
    </li>
    <li class="nav-item dropdown me-3">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        id="navbarDropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Tenant Infrastructure
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        <a class="dropdown-item" routerLink="databases">Databases</a>
        <!-- <a class="dropdown-item" routerLink="storage-accounts">Storage Accounts</a> -->
      </div>
    </li>
  </ul>
</nav>

<!-- Main Content at Root -->
<div *ngIf="!route.children.length">
  <hr />

  <div class="spacer-md"></div>

  <div class="card-columns">
    <div class="card">
      <h5 class="card-header">Organisations</h5>
      <ul class="list-group list-group-flush">
        <a class="list-group-item list-group-item-action" routerLink="firms"
          >Organisations</a
        >
      </ul>
    </div>

    <div class="card">
      <h5 class="card-header">Tenant Infrastructure</h5>
      <ul class="list-group list-group-flush">
        <a class="list-group-item list-group-item-action" routerLink="databases"
          >Databases</a
        >
        <!-- <a class="list-group-item list-group-item-action" routerLink="storage-accounts">Storage Accounts</a> -->
      </ul>
    </div>
  </div>
</div>

<!-- Router Outlet -->
<router-outlet></router-outlet>
