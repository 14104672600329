import { PlaceholderType } from '../report-placeholder/report-placeholder';
import { IElementLine } from './element-line.interface';
import { ReportElementTypeEnum } from '../../../enums';

export const MAIN_MENU: IElementLine[] = [
  {
    name: 'Header',
    type: ReportElementTypeEnum.Header
  },
  {
    name: 'Paragraph',
    type: ReportElementTypeEnum.Paragraph
  },
  {
    name: 'Horizontal Rule',
    type: ReportElementTypeEnum.HorizontalRule
  }
];

export const SECONDARY_MENUS: IElementLine[][] = [
  [
    {
      name: 'Table',
      type: ReportElementTypeEnum.Table
    },
    {
      name: 'Image',
      type: ReportElementTypeEnum.Image
    },
    {
      name: 'Financial Table',
      type: ReportElementTypeEnum.FinancialTable
    },
    {
      name: 'Custom Snippet',
      type: ReportElementTypeEnum.Snippet
    },
    {
      name: 'Signature',
      type: ReportElementTypeEnum.Signature
    },
    {
      name: 'Spacer',
      type: ReportElementTypeEnum.Spacer
    }
  ]
];

export const PLACEHOLDERS_MENU: IElementLine[] = [
  {
    name: 'Contents',
    type: ReportElementTypeEnum.PlaceHolder,
    param: PlaceholderType.Contents
  },
  {
    name: 'Account Note',
    type: ReportElementTypeEnum.PlaceHolder,
    param: PlaceholderType.AccountNote
  }
];
