export enum BankConnectionState {
  Pending = 0,
  Active = 1,
  Disconnected = 2
}

export enum BankAccountState {
  Active = 1,
  Inactive = 2,
}
