import { ControlValueAccessor } from '@angular/forms';

export abstract class DefaultValueAccessor<T> implements ControlValueAccessor {
  private _value: T;

  public get value(): T {
    return this._value;
  }

  public set value(value: T) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
    }
  }

  public writeValue(value: T) {
    value = this.fromNgModel(value);
    if (value !== this._value) {
      this._value = value;
    }
  }

  public registerOnChange(fn: (value: T) => void) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  protected onChange(value: T) {
    this.onChangeCallback(this.toNgModel(value));
    this.onTouchedCallback();
  }

  protected toNgModel(value: T): T {
    return value;
  }

  protected fromNgModel(value: T): T {
    return value;
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (value: T) => void = () => {};
}
