import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BacoLoadableTransactionDto } from '../../../interfaces';
import { BehaviorSubject } from 'rxjs';
import { TaxStateModel } from '../../editors';

@Component({
  selector: 'crs-transaction-tax-renderer',
  templateUrl: './transaction-tax-renderer.component.html',
  styleUrls: ['./transaction-tax-renderer.component.scss'],
})
export class TransactionTaxRendererComponent
  implements ICellRendererAngularComp
{
  public currentState$ = new BehaviorSubject<TaxStateModel>({
    canEdit: false,
    isSplit: false,
    selectedTax: null,
  });

  private transaction: BacoLoadableTransactionDto;

  agInit(params: ICellRendererParams): void {
    this.setDisplayValue(params.data);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setDisplayValue(params.data);
    return true;
  }

  private setDisplayValue(data: any) {
    this.transaction = data as BacoLoadableTransactionDto;

    this.currentState$.next({
      canEdit: !this.transaction.locked,
      isSplit: this.transaction.accountSelections.length > 1,
      selectedTax: this.transaction.accountSelections[0]?.taxSelection ?? null,
    });
  }
}
