<div class="modal-header">
  <h4 class="modal-title">Select Entity</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="content">
    <div class="row">
      <div class="col-xl-6 col-lg-7 col-md-9 col-sm-10">
        <div class="form-group">
          <div class="icon-input-wrapper flex-grow-1 mr-2">
            <i aria-hidden="true" class="fas fa-search input-icon"></i>
            <input
              aria-describedby="searchIcon"
              placeholder="Search..."
              type="search"
              [formControl]="search"
            />
          </div>
        </div>

        <div class="spacer"></div>

        <div *ngIf="selection.client">
          <crs-client-search-result
            [clearable]="true"
            [client]="selection.client"
            (clear)="clearActiveClient()"
          ></crs-client-search-result>
          <div class="spacer"></div>
        </div>

        <div *ngIf="selection.entity">
          <crs-entity-search-result
            [clearable]="true"
            [entity]="selection.entity"
            (clear)="clearActiveEntity()"
          >
          </crs-entity-search-result>
          <div class="spacer"></div>
        </div>

        <!-- note if you put a ngIf on there being search results then it breaks the component code for an empty search -->
        <crs-client-entity-search
          [clientId]="params.clientId"
          [shouldDisplay]="!selection.entity"
          [shouldDisplayClients]="!selection.client"
          (select)="onSelect($event)"
        ></crs-client-entity-search>

        <div class="spacer"></div>
      </div>
    </div>
  </div>
</div>
