import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
  of,
} from 'rxjs';
import { catchError, exhaustMap, finalize, tap } from 'rxjs/operators';

import { ActiveFileService } from 'src/app/accounting/active-file.service';
import { File } from 'src/app/accounting/files';
import { ReportDocumentListItem } from 'src/app/accounting/reports/models';
import { ReportDocumentUtility } from 'src/app/accounting/reports/reportDocuments/report-document-utility';
import { DocumentIntegrationService } from 'src/app/configuration/document-integration/document-integration.datasource';
import { MessageService, SessionService } from 'src/app/core';
import { User } from 'src/app/firm/users/user';
import { DocumentTag } from '../../../../configuration/document-integration/access-documents/access-documents';
import { ReportOutput } from '../report-output';
import { ExportOption } from '../report-viewer/export-strategy';
import { ReportExportStrategy } from '../report-viewer/report-export-strategy';

@Component({
  selector: 'crs-report-export-modal',
  templateUrl: './report-export-modal.component.html',
  providers: [DocumentIntegrationService],
})
export class ReportExportModalComponent implements OnInit, OnDestroy {
  @Input() params: {
    canOpenReportExportModal: boolean;
    exportOption: ExportOption;
    report: ReportOutput;
    reportDocumentListItem: ReportDocumentListItem;
    shouldUpdateCustomTableAutoTotal: boolean;
  };

  busy = {
    load: null,
    submit: null,
  };
  error: string = null;

  form = this.formBuilder.group({
    documentTitle: ['', [Validators.required, Validators.maxLength(256)]],
    saveToAccessDocuments: [false],
    selectedDocumentTagIds: [null],
    saveToLocalDevice: [false],
  });

  file: File;
  user: User;

  isAccessDocumentsUserActive: boolean;
  isFetchingAccessDocumentsTags$ = new BehaviorSubject(false);
  isFetchingAccessDocumentsUserActive$ = new BehaviorSubject(false);

  modalTitle: string;
  documentTags: DocumentTag[];

  private submitButtonStream$ = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(
    private activeFileService: ActiveFileService,
    private activeModal: NgbActiveModal,
    private documentIntegrationService: DocumentIntegrationService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private reportExportStrategy: ReportExportStrategy,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.modalTitle = this.getModalTitle();
    this.user = this.sessionService.user;

    this.initForm();
    this.configureSubmit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onClickClose(): void {
    this.activeModal.dismiss();
  }

  onValidSubmit(): void {
    this.submitButtonStream$.next();
  }

  private configureSubmit(): void {
    this.subscriptions.push(
      this.submitButtonStream$
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.submit$())
        )
        .subscribe((result) => {
          console.log('result after submit:', result);

          if (result) {
            this.messageService.success(
              `The ${this.getExtensionTitle()} has been successfully exported to Access Documents`
            );

            this.activeModal.close(result);
          }

          if (result === null) {
            this.activeModal.close(result);
          }
        })
    );
  }

  private submit$(): Observable<any> {
    if (this.form.value.saveToLocalDevice) {
      this.saveToLocalDevice();
    }

    if (this.form.value.saveToAccessDocuments) {
      return this.saveToAccessDocuments$();
    }

    return of(null);
  }

  private saveToLocalDevice(): void {
    const report = this.params.report;
    const documentTitle = this.form.value.documentTitle;

    switch (this.params.exportOption) {
      case ExportOption.Pdf: {
        this.reportExportStrategy.exportAsPdf(report, documentTitle);
        break;
      }
      case ExportOption.Excel: {
        this.reportExportStrategy.exportAsExcel(report, documentTitle);
        break;
      }
      case ExportOption.Word: {
        this.reportExportStrategy.exportAsWord(report, documentTitle);
        break;
      }
      default: {
        break;
      }
    }
  }

  private saveToAccessDocuments$(): Observable<boolean> {
    const loading$ = new Subject();
    this.busy.submit = loading$.subscribe();

    const shouldUpdateCustomTableAutoTotal =
      this.params.shouldUpdateCustomTableAutoTotal;

    return this.reportExportStrategy
      .exportToAccessDocuments$({
        report: this.params.report,
        exportOption: this.params.exportOption,
        title: this.form.value.documentTitle,
        selectedDocumentTagIds: this.form.value.selectedDocumentTagIds,
        shouldUpdateCustomTableAutoTotal,
      })
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        finalize(() => loading$.complete())
      );
  }

  private initForm(): void {
    const documentTitle = this.getDocumentTitle();

    this.form.patchValue({ documentTitle });

    this.form.get('saveToAccessDocuments').disable();
    this.form.get('selectedDocumentTagIds').disable();

    if (this.params.canOpenReportExportModal) {
      this.fetchFile();
      this.fetchAccessDocumentsUserStatus();
    } else {
      this.form.get('saveToLocalDevice').patchValue(true);
    }
  }

  private getDocumentTitle(): string {
    if (this.params.reportDocumentListItem?.name) {
      return this.params.reportDocumentListItem?.name;
    }

    return ReportDocumentUtility.getTitle(this.params.report);
  }

  private getModalTitle(): string {
    switch (this.params.exportOption) {
      case ExportOption.Pdf: {
        return 'Export as PDF';
      }
      case ExportOption.Excel: {
        return 'Export as Excel';
      }
      case ExportOption.Word: {
        return 'Export as Word';
      }
      default: {
        return 'Export';
      }
    }
  }

  private getExtensionTitle(): string {
    switch (this.params.exportOption) {
      case ExportOption.Pdf: {
        return 'PDF';
      }
      case ExportOption.Excel: {
        return 'Excel';
      }
      case ExportOption.Word: {
        return 'Word';
      }
      default: {
        return '';
      }
    }
  }

  private showError(error) {
    this.error = error;
  }

  private fetchFile(): void {
    const activeFileStreamSubscription =
      this.activeFileService.stream.subscribe((file) => {
        this.file = file;
      });

    this.subscriptions.push(activeFileStreamSubscription);
  }

  private fetchAccessDocumentsUserStatus(): void {
    this.isFetchingAccessDocumentsUserActive$.next(true);

    this.documentIntegrationService
      .getAccessDocumentsUserStatus$({
        officeId: this.file?.entity?.office?.id,
        entityId: this.file?.entity?.id,
        isPractice: true,
        isClient: false,
      })
      .pipe(
        catchError((error) => {
          this.isAccessDocumentsUserActive = false;

          this.messageService.error(error);
          return EMPTY;
        }),
        finalize(() => this.isFetchingAccessDocumentsUserActive$.next(false))
      )
      .subscribe((userStatus) => {
        this.isAccessDocumentsUserActive = userStatus;

        if (this.isAccessDocumentsUserActive) {
          this.fetchDocumentTags();

          this.form.get('saveToAccessDocuments').enable();
          this.form.get('saveToAccessDocuments').patchValue(true);
          this.form.get('selectedDocumentTagIds').enable();
        } else {
          this.form.get('saveToLocalDevice').patchValue(true);
        }
      });
  }

  private fetchDocumentTags(): void {
    this.isFetchingAccessDocumentsTags$.next(true);

    const accessDocumentTagsSubscription = this.documentIntegrationService
      .getAccessDocumentsTags$({
        officeId: this.file?.entity?.office?.id,
        entityId: this.file?.entity?.id,
        isClient: true,
      })
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        finalize(() => this.isFetchingAccessDocumentsTags$.next(false))
      )
      .subscribe((documentTags) => {
        this.documentTags = documentTags;
      });

    this.subscriptions.push(accessDocumentTagsSubscription);
  }
}
