<crs-report-editable-element
  [index]="index"
  [reportContent]="reportContent"
  [reportMetaData]="reportMetaData"
  (edit)="onEdit()"
  [onSubmit]="onSubmit"
  [editable]="true"
  [cancellable]="true"
>
  <div viewer>
    <div
      *ngIf="image ? image.publicUrl : element.customUrl; else chooseImage"
      [class.align-left]="element.alignment == 0"
      [class.align-center]="element.alignment == 1"
      [class.align-right]="element.alignment == 2"
      [class.margin-top]="element.marginTop"
      [class.margin-bottom]="element.marginBottom"
    >
      <img
        [src]="imageData$ | async"
        [style.height]="height"
        [style.width]="width"
        [alt]="element.image ? element.image.name : 'Custom Image'"
      />
    </div>

    <ng-template #chooseImage>
      <div class="w-100 align-center">
        <div class="d-inline-block image-placeholder p-3 rounded">
          Choose an image
        </div>
      </div>
    </ng-template>
  </div>

  <div editor>
    <form [formGroup]="form" novalidate *ngIf="isEditing">
      <div class="form-group required" *ngIf="!isCustom?.value">
        <label class="control-label">Image</label>
        <crs-image-select
          formControlName="image"
          placeholder="Select image"
          [clearable]="false"
        ></crs-image-select>
      </div>

      <div class="form-group required" *ngIf="isCustom?.value">
        <label class="control-label">Image Url</label>
        <input type="text" class="form-control" formControlName="customUrl" />
      </div>

      <!-- <div class="form-group">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" [formControl]="isCustom">
          <label class="form-check-label">Select Custom Url</label>
        </div>
      </div> -->

      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="form-group required">
            <label class="control-label">Alignment</label>
            <ng-select
              [items]="alignments | enumToArray"
              bindValue="value"
              bindLabel="name"
              formControlName="alignment"
              [clearable]="false"
            ></ng-select>
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="form-group">
            <label class="control-label">Height (px)</label>
            <input
              type="number"
              min="1"
              step="1"
              class="form-control"
              formControlName="height"
            />
          </div>
        </div>

        <div class="col-md-3 col-sm-6">
          <div class="form-group">
            <label class="control-label">Width (px)</label>
            <input
              type="number"
              min="1"
              step="1"
              class="form-control"
              formControlName="width"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check me-3">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="marginTop"
          />
          <label class="form-check-label">Margin Above</label>
        </div>

        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="marginBottom"
          />
          <label class="form-check-label">Margin Below</label>
        </div>
      </div>
    </form>
  </div>
</crs-report-editable-element>
