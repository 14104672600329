import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, Subject, Subscription, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  finalize,
  tap,
} from 'rxjs/operators';
import { MessageService, ModalService } from 'src/app/core';
import {
  accountingRenderers,
  getDefaultGridOptions,
  TrialBalanceHeaderGroupComponent,
} from 'src/app/shared';
import { JournalService } from '../../ledger/journals/journal.service';
import { TrialBalanceReportRow } from '../../ledger/ledgerReports/trialBalanceReportRow';
import { ConsolidationMode } from '../../reports/reportTemplates';
import { Dataset } from '../dataset';
import { DatasetService } from '../dataset.service';
import {
  JournalCategory,
  JournalCategoryWithTrialBalanceOption,
} from './../../ledger/journals/journalCategory';
import { GroupByAccountType } from './../../ledger/ledgerReports/groupByAccountType';
import { LedgerReportService } from './../../ledger/ledgerReports/ledger-report.service';
import { ActiveFileService } from '../../active-file.service';
import { ColDef, ColGroupDef, GridApi } from 'ag-grid-community';
import {
  WorkpaperFormComponent,
  AddWorkpaperFormType,
} from '../../ledger/workpapers/workpaper-form/workpaper-form.component';
import { WorkpaperStatus } from 'src/app/shared/components/accounting/workpaper-status-dropdown/workpaper-status-dropdown.component';
import { WorkpapersTableComponent } from '../../ledger/workpapers/workpapers-table/workpapers-table.component';
import { FeatureFlagsService } from 'src/app/core/services/feature-flag.service';

enum Action {
  Update,
  ManualExportJournal,
  AutoExportJournal,
}

@Component({
  selector: 'crs-dataset-trial-balances',
  templateUrl: './dataset-trial-balances.component.html',
  styleUrls: ['./dataset-trial-balances.component.scss'],
})
export class DatasetTrialBalancesComponent implements OnInit, OnDestroy {
  fileId: string;
  datasetId: string;
  datasetName: string;
  busy = {
    update: null,
    exportJournal: null,
  };

  form = this.formBuilder.group({
    journalCategory: [
      JournalCategoryWithTrialBalanceOption.TrialBalance,
      Validators.required,
    ],
    groupByAccountType: [GroupByAccountType.Account, Validators.required],
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    consolidationMode: [ConsolidationMode.None, Validators.required],
    comparativeDataset: [null],
    isShowWorkpapers: [false],
    isShowNonAccountWorkpapers: [false],
  });
  search = new UntypedFormControl();
  selectedWorkpaperStatus: number | null = null;

  trialBalance: TrialBalanceReportRow[];
  trialBalanceTotal = [this.calculateTotals([])];

  isGroupDataset = false;
  showExportButton = false;
  canAutoExport = false;
  error: string;

  actionStream$ = new Subject<Action>();
  renderers = accountingRenderers;
  subscriptions: Subscription[] = [];

  gridApi: GridApi;

  gridOptions = {
    ...getDefaultGridOptions(),
    onGridReady: (event) => (this.gridApi = event.api),
    isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
    doesExternalFilterPass: this.doesExternalFilterPass.bind(this),
  };
  groupByAccountTypes = GroupByAccountType;
  journalCategories = JournalCategoryWithTrialBalanceOption;
  consolidationModes = ConsolidationMode;

  comparativeDatasets: Dataset[] = [];

  @ViewChild(WorkpapersTableComponent)
  workpapersTable: WorkpapersTableComponent;

  workpaperFlagEnabled = false;

  constructor(
    private activeFileService: ActiveFileService,
    private datasetService: DatasetService,
    private formBuilder: UntypedFormBuilder,
    private journalService: JournalService,
    private ledgerReportService: LedgerReportService,
    private messageService: MessageService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private featureFlagService: FeatureFlagsService
  ) {}

  ngOnInit(): void {
    this.customiseGridOptions();
    this.configureActionStream();

    this.route.params.subscribe(() => {
      this.datasetId = this.route.snapshot.parent.parent.paramMap.get('id');
      const category = this.route.snapshot.queryParamMap.get('journalCategory');

      if (category) {
        this.form.get('journalCategory').setValue(parseInt(category, 10));
      }

      this.getAndApplyDatasetInfo$()
        .pipe(
          exhaustMap(() => this.getApiFeatures$()),
          tap(() => this.getReport())
        )
        .subscribe();
    });

    this.subscriptions.push(
      this.activeFileService.stream.subscribe((file) => {
        this.fileId = file.id;
      })
    );

    this.fetchComparativeDatasets();

    this.form
      .get('isShowWorkpapers')
      .valueChanges.subscribe((value: boolean) => {
        this.updateColumnDefinitions();
        this.selectedWorkpaperStatus = null;

        if (value) {
          const count = this.countNonAccountWorkpapers();
          if (count > 0) {
            this.form.get('isShowNonAccountWorkpapers').setValue(true);
          }
        } else {
          this.gridApi.onFilterChanged();
          this.form.get('isShowNonAccountWorkpapers').setValue(false);
        }
      });

    this.form.get('groupByAccountType').valueChanges.subscribe((value) => {
      if (value === GroupByAccountType.SourceAccount) {
        this.form.get('isShowWorkpapers').setValue(false);
      }
    });

    // Subscribe to search input changes
    this.search.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((data) => {
        if (this.gridApi) {
          this.gridApi.onFilterChanged();
        }
      });

    this.featureFlagService.featureFlags$.subscribe((flags) => {
      const isFlag = flags?.WorkpapersFlagEnabled;
      this.workpaperFlagEnabled = isFlag;
      if (!isFlag) {
        this.form.get('isShowWorkpapers').setValue(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  configureActionStream(): void {
    this.actionStream$
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap((action) =>
          action === Action.Update
            ? this.getReport$()
            : this.exportObservable(action)
        ),
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  getReport(): void {
    this.actionStream$.next(Action.Update);
  }

  getReport$() {
    let category = this.form.controls.journalCategory
      .value as JournalCategoryWithTrialBalanceOption;

    if (category === JournalCategoryWithTrialBalanceOption.TrialBalance) {
      category = null;
    }

    const accountType = this.form.controls.groupByAccountType
      .value as GroupByAccountType;
    const startDate = this.form.controls.startDate.value as Date;
    const endDate = this.form.controls.endDate.value as Date;
    const consolidationMode = this.form.controls.consolidationMode
      .value as ConsolidationMode;
    const comparativeDatasetId = this.form.controls.comparativeDataset.value
      ?.id as string;

    const loading$ = new Subject<void>();
    this.busy.update = loading$.subscribe();

    return this.ledgerReportService
      .getTrialBalance$(
        this.datasetId,
        accountType,
        startDate,
        endDate,
        (<any>category) as JournalCategory,
        consolidationMode,
        [comparativeDatasetId]
      )
      .pipe(
        catchError((err) => {
          this.showError(err);
          return of([]);
        }),
        tap((data) => {
          this.trialBalanceTotal = [this.calculateTotals(data)];
          this.trialBalance = data;
          this.showExportButton = false;
          //category === JournalCategoryWithTrialBalanceOption.PendingExport &&
          //accountType === GroupByAccountType.SourceAccount;

          const newColumnDefs = this.getColumnDefinitions(
            this.form.controls.isShowWorkpapers.value
          );
          this.gridApi.setGridOption('columnDefs', newColumnDefs);
        }),
        tap((data) => {
          // Check if any records have workpaperDetails
          const hasWorkpaperDetails = data.some((row) => row.workpaperDetails);
          const accountTypeIsSource =
            this.form.get('groupByAccountType').value ===
            GroupByAccountType.SourceAccount;

          // Update the form isShowWorkpapers to true if there are workpaperDetails
          if (hasWorkpaperDetails && !accountTypeIsSource) {
            this.form.get('isShowWorkpapers').setValue(true);
          }
        }),
        finalize(() => loading$.complete())
      );
  }

  getAndApplyDatasetInfo$(): Observable<Dataset> {
    return this.datasetService.getLight$(this.datasetId).pipe(
      tap((dataset) => {
        this.datasetName = dataset.name;

        if (dataset.isGroupDataset) {
          this.isGroupDataset = true;
        }

        if (this.isGroupDataset) {
          this.form.controls['groupByAccountType'].setValue(
            GroupByAccountType.Account
          );
        }

        this.form.controls['startDate'].patchValue(dataset.startDate);
        this.form.controls['endDate'].patchValue(dataset.endDate);
      }),
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      })
    );
  }

  getApiFeatures$() {
    return this.datasetService.getApiFeatures$(this.datasetId).pipe(
      tap((apiFeatures) => {
        this.canAutoExport = apiFeatures.canExportJournals;
      }),
      catchError((error) => {
        this.canAutoExport = false;
        console.error('Error retrieving api features', error);
        return of(null);
      })
    );
  }

  calculateTotals(rows: TrialBalanceReportRow[]) {
    let debit = 0;
    let credit = 0;
    let quantity = 0;

    rows.forEach((row) => {
      debit += row.debit;
      credit += row.credit;
      quantity += row.quantity;
    });

    return {
      debit,
      credit,
      quantity,
    };
  }

  customiseGridOptions(): void {
    const columnDefs = this.getColumnDefinitions(
      this.form.controls.isShowWorkpapers.value
    );
    this.gridOptions.grandTotalRow = 'bottom';
    this.gridOptions.cellSelection = true;
    this.gridOptions.components = accountingRenderers;
    this.gridOptions.columnDefs = columnDefs;
    this.gridOptions.suppressHorizontalScroll = true;
    this.gridOptions.groupHeaderHeight = 75;
  }

  isExternalFilterPresent() {
    return (
      !!this.search.value ||
      this.selectedWorkpaperStatus !== null ||
      !this.form.get('isShowWorkpapers').value
    );
  }

  doesExternalFilterPass(node) {
    const searchTerm = this.search.value
      ? this.search.value?.toLowerCase()
      : '';
    const accountName = node.data.accountName?.toLowerCase() || '';
    const accountNo = node.data.accountNo?.toLowerCase() || '';

    const isShowWorkpapers = this.form.get('isShowWorkpapers').value;
    const workpaperTitle =
      node.data.workpaperDetails?.title?.toLowerCase() || '';
    const workpaperStatus =
      node.data.workpaperDetails?.status || WorkpaperStatus.NoWorkpaper;

    const matchesSearchTerm =
      accountName.includes(searchTerm) ||
      accountNo.includes(searchTerm) ||
      (isShowWorkpapers && workpaperTitle.includes(searchTerm));

    const matchesStatus =
      this.selectedWorkpaperStatus === null ||
      workpaperStatus === this.selectedWorkpaperStatus;

    const matchesBalanceAndWorkpaper =
      isShowWorkpapers ||
      node.data.balance !== 0 ||
      !node.data.workpaperDetails;

    const comparativeDatasetId = this.form.controls.comparativeDataset.value
      ?.id as string;

    const matchesComparativeBalanceAndWorkpaper =
      isShowWorkpapers ||
      node.data.balance !== 0 ||
      !node.data.workpaperDetails ||
      node.data?.comparatives?.[comparativeDatasetId]?.balance !== 0;

    return (
      matchesSearchTerm &&
      matchesStatus &&
      matchesBalanceAndWorkpaper &&
      matchesComparativeBalanceAndWorkpaper
    );
  }

  updateColumnDefinitions(): void {
    const isShowWorkpapers = this.form.get('isShowWorkpapers').value;

    if (this.gridApi) {
      this.gridApi.setGridOption(
        'columnDefs',
        this.getColumnDefinitions(isShowWorkpapers)
      );
    }
  }

  getColumnDefinitions(isShowWorkpapers: boolean): (ColDef | ColGroupDef)[] {
    const columnsWithoutComparatives: ColDef[] = [
      this.getAccountNoColumn(),
      this.getAccountNameColumn(),
      this.getClassificationColumn(),
      this.getDebitColumn(),
      this.getCreditColumn(),
      this.getQuantityColumn(),
    ];

    if (this.workpaperFlagEnabled && isShowWorkpapers) {
      columnsWithoutComparatives.push(this.getWorkpaperDetailsColumn());
    }

    const columnsWithComparatives: (ColDef | ColGroupDef)[] = [
      this.getAccountNoColumn(),
      this.getAccountNameColumn(),
      this.getClassificationColumn(),
      ...this.getCurrentColumns(isShowWorkpapers),
    ];

    columnsWithComparatives.push(
      ...this.getComparativeColumns(
        this.form.get('isShowWorkpapers').value,
        this.form.controls.comparativeDataset.value?.id
      )
    );

    return this.form.controls.comparativeDataset.value === null
      ? columnsWithoutComparatives
      : columnsWithComparatives;
  }

  export(auto: boolean): void {
    if (auto) {
      this.actionStream$.next(Action.AutoExportJournal);
    } else {
      this.actionStream$.next(Action.ManualExportJournal);
    }
  }

  exportObservable(action: Action) {
    const loading$ = new Subject<void>();
    this.busy.exportJournal = loading$.subscribe();

    return this.journalService
      .postBalancingJournal$(
        this.datasetId,
        action === Action.AutoExportJournal
      )
      .pipe(
        exhaustMap(() => {
          this.messageService.success(
            'Successfully processed balancing journal.'
          );
          return this.getReport$();
        }),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => loading$.complete())
      );
  }

  private showError(error): void {
    this.messageService.error(error);
  }

  private fetchComparativeDatasets(): void {
    this.datasetService
      .getAll$(this.fileId)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return of([]);
        }),
        tap((datasets: Dataset[]) => {
          this.comparativeDatasets = datasets.filter(
            ({ id }) => id !== this.datasetId
          );
        })
      )
      .subscribe();
  }

  private getAccountNoColumn(): ColDef {
    return {
      colId: 'accountNo',
      field: 'accountNo',
      headerName: 'Account No',
      minWidth: 100,
      maxWidth: 120,
      valueFormatter: this.emptyValueFormatter,
    };
  }

  private getAccountNameColumn(): ColDef {
    return {
      colId: 'accountName',
      field: 'accountName',
      headerName: 'Account Name',
      flex: 3,
    };
  }

  private getClassificationColumn(): ColDef {
    return {
      cellRenderer: accountingRenderers.classificationRenderer,
      colId: 'classification',
      field: 'classificationToolTipText',
      tooltipField: 'classificationToolTipText',
      headerName: 'Class',
      width: 110,
    };
  }

  private getDebitColumn(datasetId?: string, showLeftBorder?: boolean): ColDef {
    return {
      colId: 'debit',
      field: datasetId ? `comparatives.${datasetId}.debit` : 'debit',
      headerName: 'Debit',
      type: 'dollarColumn',
      cellClass: ['dollar-cell', 'ag-numeric-cell', 'highlight-column'],
      ...(showLeftBorder && { headerClass: ['centered', 'border-left-cell'] }),
      ...(showLeftBorder && {
        cellStyle: { 'border-left': '1px solid #F2F5F6' },
      }),
    };
  }

  private getCreditColumn(datasetId?: string): ColDef {
    return {
      colId: 'credit',
      field: datasetId ? `comparatives.${datasetId}.credit` : 'credit',
      headerName: 'Credit',
      type: 'dollarColumn',
      cellClass: ['dollar-cell', 'ag-numeric-cell', 'highlight-column'],
    };
  }

  private getQuantityColumn(
    datasetId?: string,
    showRightBorder?: boolean
  ): ColDef {
    return {
      colId: 'quantity',
      field: datasetId ? `comparatives.${datasetId}.quantity` : 'quantity',
      headerName: 'Quantity',
      type: 'numberColumn',
      minWidth: 50,
      width: 80,
      ...(showRightBorder && {
        headerClass: ['border-right-cell', 'centered'],
      }),
      ...(showRightBorder && {
        cellStyle: { 'border-right': '1px solid #F2F5F6' },
      }),
    };
  }

  private getWorkpaperDetailsColumn(): ColDef {
    return {
      cellRenderer:
        accountingRenderers.trialBalanceWorkpaperDetailsRendererComponent,
      colId: 'workpaperDetails',
      field: 'workpaperDetails',
      headerName: 'Workpaper details',
      headerClass: ['centered', 'border-left-cell'],
      cellStyle: {
        'background-color': '#f9f9fa',
        'border-left': '1px solid #d1d1d1',
      },
      flex: 3,
      minWidth: 300,
      maxWidth: 300,
      cellRendererParams: {
        datasetId: this.datasetId,
        getAccountId: (params) => params.data?.accountId,
        getAccountName: (params) => params.data?.accountName,
        refreshGrid: this.refreshGrid.bind(this),
        isFooter: (params) => params?.node.footer,
        isComparative: false,
      },
    };
  }

  private getComparativeWorkpaperDetailsColumn(
    comparativeDataset: Dataset
  ): ColDef {
    const comparativeDatasetId = comparativeDataset?.id;

    return {
      cellRenderer:
        accountingRenderers.trialBalanceWorkpaperDetailsRendererComponent,
      colId: 'workpaperDetails',
      field: `comparatives.${comparativeDatasetId}?.workpaperDetails`,
      headerName: '',
      headerClass: ['centered', 'border-left-cell'],
      cellStyle: {
        'border-left': '1px solid #d1d1d1',
      },
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      cellRendererParams: {
        datasetId: comparativeDatasetId,
        getAccountId: (params) => params.data?.accountId,
        getAccountName: (params) => params.data?.accountName,
        refreshGrid: this.refreshGrid.bind(this),
        isFooter: (params) => params?.node.footer,
        isComparative: true,
      },
    };
  }

  private getCurrentColumns(isShowWorkpapers: boolean): ColGroupDef[] {
    return [
      {
        headerName: `(Balance) ${this.datasetName}`,
        headerClass: [
          'ag-header-group-cell-label',
          'centered',
          'comparative-header-group',
        ],
        headerGroupComponent: TrialBalanceHeaderGroupComponent,
        headerGroupComponentParams: {
          datasetName: this.datasetName,
          heading: 'Balance',
        },
        marryChildren: true,
        children: [
          this.getDebitColumn(null, true),
          this.getCreditColumn(),
          this.getQuantityColumn(null, true),
          ...(this.workpaperFlagEnabled && isShowWorkpapers
            ? [this.getWorkpaperDetailsColumn()]
            : []),
        ],
      },
    ];
  }

  private getComparativeColumns(
    isShowWorkpapers: boolean,
    datasetId?: string
  ): ColGroupDef[] {
    const comparativeDataset = this.comparativeDatasets?.find(
      ({ id }) => id === datasetId
    );

    return [
      {
        headerName: `(Comparative) ${comparativeDataset?.name}`,
        headerClass: [
          'ag-header-group-cell-label',
          'centered',
          'comparative-header-group',
        ],
        headerGroupComponent: TrialBalanceHeaderGroupComponent,
        headerGroupComponentParams: {
          datasetName: comparativeDataset?.name,
          heading: 'Comparative',
        },
        marryChildren: true,
        children: [
          this.getDebitColumn(datasetId, true),
          this.getCreditColumn(datasetId),
          this.getQuantityColumn(datasetId, true),
          ...(isShowWorkpapers
            ? [this.getComparativeWorkpaperDetailsColumn(comparativeDataset)]
            : []),
        ],
      },
    ];
  }

  private emptyValueFormatter(params: any): string {
    if (params?.node?.footer) {
      return params.value;
    }
    return params.value != null && params.value !== '' && params.value !== 0
      ? params.value
      : '-';
  }

  onStatusChange(status: number | null): void {
    this.selectedWorkpaperStatus = status;
    if (this.gridApi) {
      this.gridApi.onFilterChanged();
    }
  }

  onClickAddWorkpaper(): void {
    this.modalService
      .openModal(WorkpaperFormComponent, AddWorkpaperFormType.AddNew, {
        datasetId: this.datasetId,
      })
      .then(() => {
        this.refreshGrid();
        this.workpapersTable.refreshGrid();
      })
      .catch(() => true);
  }

  refreshGrid(): void {
    this.getReport();
  }

  countNonAccountWorkpapers(): number {
    return this.workpapersTable?.gridApi?.getDisplayedRowCount();
  }
}
