<ng-select [items]="reportingSuites$ | async"
           bindLabel="name"
           [closeOnSelect]="!multiple"
           (open)="onOpen()"
           [clearable]="clearable"
           [placeholder]="placeholder"
           [readonly]="readonly">
           <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="multiple">
            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.name}}
        </ng-template>
</ng-select>
