import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { User, UserModel } from './user';
import { ApiService, PagedResponse } from '../../core';

@Injectable({ providedIn: 'root' })
export class UserService {

  readonly base: string = 'api/users';

  constructor(private apiService: ApiService) { }

  profile() {
    return this.apiService.get<User>(this.base + '/profile').pipe(map(d => new User(d)));
  }

  get(id: string) {
    return this.apiService.get<User>(this.base + '/' + id).pipe(map(d => new User(d)));
  }

  find(search: string, filterByPartner: boolean, filterByManager: boolean, filterByTrustedAdvisor: boolean, includeInactive: boolean, page: number, pageSize: number, orderBy: string = null, orderByAsc: boolean = true) {
    var url = this.base;
    url = this.apiService.addQuery(url, 'search', search);
    url = this.apiService.addQuery(url, 'filterByPartner', filterByPartner);
    url = this.apiService.addQuery(url, 'filterByManager', filterByManager);
    url = this.apiService.addQuery(url, 'filterByTrustedAdvisor', filterByTrustedAdvisor);
    url = this.apiService.addQuery(url, 'includeInactive', includeInactive);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    url = this.apiService.addQuery(url, 'orderBy', orderBy);
    url = this.apiService.addQuery(url, 'orderByAsc', orderByAsc);
    return this.apiService.get<PagedResponse<User>>(url)
      .pipe(
        map(response => new PagedResponse<User>(response)),
        map(response => {
          response.records = response.records.map(d => new User(d));
          return response;
        })
      );
  }

  post(client: UserModel) {
    return this.apiService.post<any>(this.base, client);
  }

  putWithSecurity(client: UserModel) {
    return this.apiService.put(this.base + '/' + client.id + '/security', client);
  }

  put(client: UserModel) {
    return this.apiService.put(this.base + '/' + client.id, client);
  }

  resendInvite(id: string) {
    return this.apiService.post<any>(this.base + '/' + id + '/resendInvite', null);
  }

  acceptInvite(token: string) {
    return this.apiService.post<any>(this.base + '/acceptInvite/' + token, null);
  }

  clearInvites() {
    return this.apiService.post<any>(this.base + '/clearInvites', null);
  }

  delete(id: string) {
    return this.apiService.delete(this.base + '/' + id);
  }

  getLicenseInfo() {
    return this.apiService.get<any>(this.base + '/license-info');
  }


}
