<div class="modal-header">
  <h4 class="modal-title">Move Assets</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

<div class="modal-body">

  <div class="content">
    <div class="row">
      <div class="col-xl-6 col-lg-7 col-md-9 col-sm-10">

        <p>Please select the asset group that you would like to relocate the {{params.length | formatNumber:0}} selected assets to:</p>

        <div class="spacer"></div>

        <div class="row">
          <div class="col-form-md required">
            <div class="form-group">
              <label class="control-label">Asset Group</label>
              <crs-asset-group-select formControlName="assetGroup"></crs-asset-group-select>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <crs-form-error [error]="error"></crs-form-error>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-primary" [promiseBtn]="busy.submit">
    Move Assets
  </button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
</div>

</form>
