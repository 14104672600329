import { DisclosureLevel } from './disclosure-level';
import { ReportingSuite } from '../reportingSuites';
import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import { DisclosureLink, DisclosureLinkModel } from './disclosure-link';

export class DisclosureVariant {
  id: string;
  templateId: string;
  fileId: string;
  name: string;
  description: string;
  applicationDate: Date;
  earliestApplicationDate: Date;
  expiryDate: Date;
  requiresCustomisation: boolean;
  level: DisclosureLevel;
  reportingSuites: ReportingSuite[];
  entityTypes: number[];
  disclosureLinks: DisclosureLink[];
  isDefault: boolean;
  masterDisclosureVariantId: string;
  masterChanged: boolean;

  content = [];
  masterDisclosureVariantContent = [];

  constructor(data) {
    Object.assign(this, data);
    if (data.reportingSuites) {
      this.reportingSuites = data.reportingSuites.map(s => new ReportingSuite(s));
    }
    this.applicationDate = DateHelpers.dateFromApiResponse(data.applicationDate);
    this.earliestApplicationDate = DateHelpers.dateFromApiResponse(data.earliestApplicationDate);
    this.expiryDate = DateHelpers.dateFromApiResponse(data.expiryDate);
    if (data.disclosureLinks) this.disclosureLinks = data.disclosureLinks.map(l => new DisclosureLink(l));
  }
}

export class DisclosureVariantModel {
  id: string;
  templateId: string;
  fileId: string;
  name: string;
  description: string;
  applicationDate: Date;
  earliestApplicationDate: Date;
  expiryDate: Date;
  requiresCustomisation: boolean;
  level: DisclosureLevel;
  reportingSuites: ReportingSuite[];
  entityTypes: number[];
  disclosureLinks: DisclosureLinkModel[];
  isDefault: boolean;
  masterDisclosureVariantId: string;
  masterChanged: boolean;

  content = [];
  masterDisclosureVariantContent = [];

  constructor(data) {
    Object.assign(this, data);
    if (data.reportingSuites) {
      this.reportingSuites = data.reportingSuites.map(s => new ReportingSuite(s));
    }
    this.applicationDate = DateHelpers.dateFromApiResponse(data.applicationDate);
    this.earliestApplicationDate = DateHelpers.dateFromApiResponse(data.earliestApplicationDate);
    this.expiryDate = DateHelpers.dateFromApiResponse(data.expiryDate);
    if (data.disclosureLinks) this.disclosureLinks = data.disclosureLinks.map(l => new DisclosureLinkModel(l));
  }
}
