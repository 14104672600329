import { SourceType, sourceTypes } from '../sourceTypes/sourceType';
import { AccountSystem } from '../enums/accountSystem';
import { File } from '../../files';

export enum SourceTypeId {
  Excel = '1',
  ChartOfAccounts = '2',
  Reportance = '6',
  HandiLedger = '9',
}

export class Source {
  id: string;
  fileId: string;
  sourceTypeId: string;
  sourceType: SourceType;
  name: string;
  accountSystem: AccountSystem = AccountSystem.None;
  apiFileId: string;
  apiFileName: string;
  apiFileUri: string;
  apiUsername: string;
  apiPassword: string;
  cirrusFile: File;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.sourceType) this.sourceType = new SourceType(data.sourceType);
    else if (data.sourceTypeId)
      this.sourceType = sourceTypes.find((s) => s.id == data.sourceTypeId); // coerce as id may be a string
    if (data.cirrusFile) this.cirrusFile = new File(data.cirrusFile);
  }
}

export class SourceModel {
  id: string;
  fileId: string;
  sourceTypeId: string;
  name: string;
  accountSystem: AccountSystem = AccountSystem.None;
  apiFileId: string;
  apiFileName: string;
  apiFileUri: string;
  apiUsername: string;
  apiPassword: string;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.sourceType) this.sourceTypeId = data.sourceType.id;
    if (data.cirrusFile) this.apiFileId = data.cirrusFile.id;
  }
}
