import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AssetGroup, AssetGroupModel } from './asset-group';
import { ApiService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class AssetGroupService {

  readonly base: string = 'api/accounting/assets/asset-groups';

  constructor(private apiService: ApiService) { }

  get(id: number) {
    return this.apiService.get<AssetGroup>(this.base + '/' + id).pipe(map(c => new AssetGroup(c)));
  }

  getAll(fileId: string, entityId: string) {
    let url = this.base;
    url = this.apiService.addQuery(url, 'fileId', fileId);
    url = this.apiService.addQuery(url, 'entityId', entityId);
    return this.apiService.get<AssetGroup[]>(url).pipe(map(r => r.map(y => new AssetGroup(y))));
  }

  post(model: AssetGroupModel) {
    return this.apiService.post<any>(this.base, model);
  }

  put(model: AssetGroupModel) {
    return this.apiService.put(this.base + '/' + model.id, model);
  }

  reorder(id: string, index: number) {
    return this.apiService.put(this.base + '/' + id + '/reorder', index);
  }

  delete(id: number) {
    return this.apiService.delete(this.base + '/' + id);
  }
}
