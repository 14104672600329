import { SourceAccount } from '../../sourceAccounts/sourceAccount';
import { IAccountsViewAccount } from './accounts-view-account';


/**
 * Model used by Source Accounts displayed in the 'Accounts' screen with hierarchy
 */
export class AccountsViewSourceAccount extends SourceAccount implements IAccountsViewAccount {
  isSourceAccount = true;
  hierarchy: string[] = [];

  constructor(data: SourceAccount|AccountsViewSourceAccount, hierarchy: string[]) {
    super(data);
    this.hierarchy = hierarchy;
  }

  get displayName(): string {
    if (this.accountNo) return this.accountNo + ' ' + this.accountName;
    else return this.accountName;
  }

  isParentOf(account: IAccountsViewAccount) {
    const position = this.hierarchy.length - 1;
    return account.hierarchy.length > position + 1 && account.hierarchy[position] === this.id;
  }

  isChildOf(account: IAccountsViewAccount) {
    const position = account.hierarchy.length - 1;
    return this.hierarchy.length > position + 1 && this.hierarchy[position] === account.id;
  }

  shallowCopy() {
    return new AccountsViewSourceAccount(this, [...this.hierarchy]);
  }
}
