export enum SecurityRole {
  User = 0,
  Admin = 1,
  Owner = 2
}

export enum HandisoftSecurityRole {
  User = 0,
  Admin = 2
}
