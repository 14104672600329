import { ProfitDisplayOption } from '../../enums/profit-display-option';
import { HeaderLayoutOptionModel } from './../../layout/headerLayoutOption';
import { MasterAccountLayout } from '../../../ledger/masterAccounts/masterAccountLayout';
import { ReportPageType } from '../../reportPages/reportPageType';
import { AccountLayout } from 'src/app/accounting';
import { ProfitLossLayoutOption } from '../../enums/profit-loss-layout-option';


export class ReportMasterPage {
  id: string;
  sortOrder: number;
  pageType: ReportPageType;
  customTitle: string;
  financialDetail: ReportMasterFinancialPageDetail;
  cashflowDetail: ReportMasterCashflowPageDetail;

  constructor(data) {
    Object.assign(this, data);
    if (data.pageType) this.pageType = new ReportPageType(data.pageType);
    if (data.financialDetail) this.financialDetail = new ReportMasterFinancialPageDetail(this.financialDetail);
    if (data.cashflowDetail) this.cashflowDetail = new ReportMasterCashflowPageDetail(this.cashflowDetail);
  }
}

export class ReportMasterPageModel {
  id: string;
  pageTypeId: number;
  customTitle: string;
  financialDetail: ReportMasterFinancialPageDetailModel;
  cashflowDetail: ReportMasterCashflowPageDetail;

  constructor(data) {
    Object.assign(this, data);
    if (data.pageType) this.pageTypeId = data.pageType.id;

    if (data.pageType && !data.pageType.hasFinancialDetail) this.financialDetail = null;
    else if (data.financialDetail) this.financialDetail = new ReportMasterFinancialPageDetailModel(this.financialDetail);

    if (data.pageType && !data.pageType.hasCashFlowDetail) this.cashflowDetail = null;
    else if (data.cashflowDetail) this.cashflowDetail = new ReportMasterCashflowPageDetailModel(this.cashflowDetail);
  }
}

export class ReportMasterFinancialPageDetail {
  separateTradingAccounts: boolean;
  profitDisplayOption: ProfitDisplayOption;
  profitlossLayoutOption: ProfitLossLayoutOption;
  layout: HeaderLayoutOptionModel[];

    /**
   * Property added client side only when wanting to display full account layout
   */
  layoutFull: AccountLayout[];

  constructor(data) {
    Object.assign(this, data);
    if (data.layout) this.layout = data.layout.map(l => new HeaderLayoutOptionModel(l));
    if (data.layoutFull) this.layoutFull = data.layoutFull.map(a => new AccountLayout(a));
  }
}

export class ReportMasterFinancialPageDetailModel {
  separateTradingAccounts: boolean;
  profitDisplayOption: ProfitDisplayOption;
  profitlossLayoutOption: ProfitLossLayoutOption;
  layout: HeaderLayoutOptionModel[];

  constructor(data) {
    this.separateTradingAccounts = data.separateTradingAccounts;
    this.profitDisplayOption = parseInt(data.profitDisplayOption, 10);
    this.profitlossLayoutOption = parseInt(data.profitlossLayoutOption, 10);
    if (data.layoutFull) {
      const layout = data.layoutFull as MasterAccountLayout[];
      this.layout = layout
        .filter(l => l.layoutOption && !l.layoutOption.isDefault)
        .map(l => HeaderLayoutOptionModel.createForMasterAccountLayout(l));
    } else if (data.layout) {
      this.layout = data.layout.map(l => new HeaderLayoutOptionModel(l));
    }
  }
}


export class ReportMasterCashflowPageDetail {
  cashflowMode: number;

  constructor(data) {
    Object.assign(this, data);
    this.cashflowMode = parseInt(data.cashflowMode, 10);     
  }
}

export class ReportMasterCashflowPageDetailModel {
  cashflowMode: number;

  constructor(data) {
    Object.assign(this, data);
    this.cashflowMode = parseInt(data.cashflowMode, 10);     
  }
}
