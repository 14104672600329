import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { DepreciationPool } from './../../assets/depreciation-pool';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { getDefaultGridOptions } from 'src/app/shared';
import { tap } from 'rxjs/operators';

import { AssetReportGrid } from '../asset-report-grid';
import { AssetReportPool } from './asset-report-pool';

@Component({
  selector: 'crs-asset-report-pool',
  templateUrl: './asset-report-pool.component.html',
  styleUrls: ['./asset-report-pool.component.scss'],
})
export class AssetReportPoolComponent implements OnInit, OnDestroy {
  @Input() content: AssetReportPool;
  @Input() poolType: DepreciationPool;

  depreciationPools = DepreciationPool;

  search = new UntypedFormControl();
  subscriptions: Subscription[] = [];

  gridOptions: GridOptions;
  gridApi: GridApi;
  assetReportDollarParams = AssetReportGrid.dollarParams;

  constructor() {}

  ngOnInit() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      onGridReady: (event) => (this.gridApi = event.api),
      grandTotalRow: 'bottom',
      columnDefs: [
        {
          field: 'asset.assetGroupName',
          headerName: 'Group',
          enableRowGroup: true,
          hide: true,
          rowGroup: true,
        },
        {
          field: 'asset.code',
          headerName: 'Code',
          hide: true,
        },
        {
          field: 'asset.identifier',
          headerName: 'Identifier',
          hide: true,
        },
        {
          field: 'asset.acquisitionDate',
          headerName: 'Acq Date',
          type: 'dateColumn',
        },
        {
          field: 'poolOpeningBalance',
          headerName: 'Opening Balance',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'poolAdditions',
          headerName: 'Additions',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'poolDisposals',
          headerName: 'Disposals',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'poolDepreciation',
          headerName: 'Depreciation',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'poolWriteOffs',
          headerName: 'Balance Ass(Ded)',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'poolClosingBalance',
          headerName: 'Closing Balance',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
      ],
    };
    AssetReportGrid.customiseGrid(this.gridOptions);

    this.subscriptions.push(
      this.search.valueChanges
        .pipe(
          tap((search) => {
            this.gridApi.setGridOption('quickFilterText', search);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }
}
