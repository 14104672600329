export class Workpaper {
  id: string;
  accountId: string;
  title: string;
  status: number;
  documents: WorkpaperDocument[];
  comments: WorkpaperComment[];

  constructor(data: Workpaper) {
    Object.assign(this, data);
  }
}

export class WorkpaperDocument {
  id: string;
  type: number;
  url: string;
  workpaperId: string;

  constructor(data) {
    Object.assign(this, data);
  }
}

export class WorkpaperComment {
  id: string;
  comment: string;
  workpaperId: string;
  createdDate: string;
  userInfo?: { userId: string; userName: string };

  constructor(data) {
    Object.assign(this, data);
  }
}

export class WorkpaperCreateModel {
  accountId: string;
  title: string;
  status: number;
  datasetId: string;
  documents?: WorkpaperDocument[];
  comments?: WorkpaperComment[];

  constructor(data: WorkpaperCreateModel) {
    Object.assign(this, data);
  }
}

export class WorkpaperUpdateModel {
  title: string;
  status: number;
  documents?: WorkpaperDocument[];
  comments?: WorkpaperComment[];

  constructor(data: WorkpaperUpdateModel) {
    Object.assign(this, data);
  }
}
