import {
  AssetReportTaxReturnSbe,
  AssetReportTaxReturnNonSbe,
} from './asset-report-tax-return/asset-report-tax-return';
import { DepreciationPool } from './../assets/depreciation-pool';
import { DepreciationType } from './../assets/depreciation-type';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { AssetsContext } from '../assets-context';
import { AssetReportFullSummaryReportRow } from './asset-report-full-summary/asset-report-full-summary-row';
import { map } from 'rxjs/operators';
import { AssetReportDetailRow } from './asset-report-detail/asset-report-detail-row';
import { AssetReportPool } from './asset-report-pool/asset-report-pool';
import { IStandardResponse } from 'src/app/core/services/standard-response.interface';

@Injectable({
  providedIn: 'root',
})
export class AssetReportService {
  readonly base: string = 'api/accounting/assets/reports';

  constructor(private apiService: ApiService) {}

  getFullSummary(context: AssetsContext, groupIds: number[], toPeriod: number) {
    let url = this.base + '/full-summary';
    url = this.addContextParams(url, context);
    if (groupIds) {
      groupIds.forEach((i) => {
        url = this.apiService.addQuery(url, 'assetGroupIds', i);
      });
    }
    if (context.year.useMonthlyBusinessDepreciation) {
      url = this.apiService.addQuery(url, 'toPeriod', toPeriod);
    }
    return this.apiService
      .get<AssetReportFullSummaryReportRow[]>(url)
      .pipe(map((v) => v.map((r) => new AssetReportFullSummaryReportRow(r))));
  }

  getFullSummaryExcel(
    context: AssetsContext,
    groupIds: number[],
    toPeriod: number
  ) {
    let url = this.base + '/full-summary/excel';
    url = this.addContextParams(url, context);
    if (groupIds) {
      groupIds.forEach((i) => {
        url = this.apiService.addQuery(url, 'assetGroupIds', i);
      });
    }
    if (context.year.useMonthlyBusinessDepreciation) {
      url = this.apiService.addQuery(url, 'toPeriod', toPeriod);
    }
    return this.apiService
      .get<IStandardResponse<string>>(url)
      .pipe(map((v) => v.data));
  }

  getDetail(
    context: AssetsContext,
    groupIds: number[],
    type: DepreciationType,
    toPeriod: number
  ) {
    let url = this.base + '/detail';
    url = this.addContextParams(url, context);
    url = this.apiService.addQuery(url, 'type', type);
    if (groupIds) {
      groupIds.forEach((i) => {
        url = this.apiService.addQuery(url, 'assetGroupIds', i);
      });
    }
    if (context.year.useMonthlyBusinessDepreciation) {
      url = this.apiService.addQuery(url, 'toPeriod', toPeriod);
    }
    return this.apiService
      .get<AssetReportDetailRow[]>(url)
      .pipe(map((v) => v.map((r) => new AssetReportDetailRow(r))));
  }

  getDetailExcel(
    context: AssetsContext,
    groupIds: number[],
    type: DepreciationType,
    toPeriod: number
  ) {
    let url = this.base + '/detail/excel';
    url = this.addContextParams(url, context);
    url = this.apiService.addQuery(url, 'type', type);
    if (groupIds) {
      groupIds.forEach((i) => {
        url = this.apiService.addQuery(url, 'assetGroupIds', i);
      });
    }
    if (context.year.useMonthlyBusinessDepreciation) {
      url = this.apiService.addQuery(url, 'toPeriod', toPeriod);
    }
    return this.apiService
      .get<IStandardResponse<string>>(url)
      .pipe(map((v) => v.data));
  }

  getPool(
    context: AssetsContext,
    pool: DepreciationPool,
    type: DepreciationType
  ) {
    let url = this.base + '/pool';
    url = this.addContextParams(url, context);
    url = this.apiService.addQuery(url, 'pool', pool);
    url = this.apiService.addQuery(url, 'type', type);
    return this.apiService
      .get<AssetReportPool>(url)
      .pipe(map((v) => new AssetReportPool(v)));
  }

  getPoolExcel(
    context: AssetsContext,
    pool: DepreciationPool,
    type: DepreciationType
  ) {
    let url = this.base + '/pool/excel';
    url = this.addContextParams(url, context);
    url = this.apiService.addQuery(url, 'pool', pool);
    url = this.apiService.addQuery(url, 'type', type);
    return this.apiService
      .get<IStandardResponse<string>>(url)
      .pipe(map((v) => v.data));
  }

  getTaxReturn(context: AssetsContext) {
    let url = this.base + '/tax-return/';
    url =
      url + (!context.year.useSmallBusinessDepreciation ? 'non-' : '') + 'sbe';
    url = this.addContextParams(url, context);
    if (context.year.useSmallBusinessDepreciation) {
      return this.apiService
        .get<AssetReportTaxReturnSbe>(url)
        .pipe(map((v) => new AssetReportTaxReturnSbe(v)));
    }
    return this.apiService
      .get<AssetReportTaxReturnNonSbe>(url)
      .pipe(map((v) => new AssetReportTaxReturnNonSbe(v)));
  }

  private addContextParams(url, context: AssetsContext): string {
    url = this.apiService.addQuery(url, 'fileId', context.file.id);
    url = this.apiService.addQuery(url, 'entityId', context.entity.id);
    url = this.apiService.addQuery(url, 'year', context.year.year);
    return url;
  }
}
