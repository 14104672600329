<div class="transaction-footer-component">
  <label class="control-label"> Total: {{ transactionsSummary?.total }} </label>
  <label>|</label>

  <label class="control-label">
    Uncoded: {{ transactionsSummary?.uncoded }}
  </label>
  <label>|</label>

  <label class="control-label">
    Coded by User: {{ transactionsSummary?.codedByUser }}
  </label>
  <label>|</label>

  <label class="control-label">
    Coded by Rule: {{ transactionsSummary?.codeByRule }}
  </label>
</div>
