<div class="pad">
  <h2 class="form-group-title d-inline-block">Integration Server</h2>

  <hr />

  <div class="spacer-md"></div>

  <div class="btn-menu">
    <!-- status header -->
    <b>Status</b>
    <mat-icon class="ms-5 me-1 text-secondary" *ngIf="!connected?.isConnected"
      >remove_circle</mat-icon
    >
    <mat-icon
      class="ms-5 me-1 text-primary"
      *ngIf="connected?.isConnected && connected?.isValid"
      >check_circle</mat-icon
    >
    <mat-icon
      class="ms-5 me-1 text-danger"
      *ngIf="connected?.isConnected && !connected?.isValid"
      >remove_circle</mat-icon
    >
    <span
      class="text-secondary font-weight-bold"
      *ngIf="!connected?.isConnected"
      >Not Connected</span
    >
    <span
      class="text-secondary font-weight-bold"
      *ngIf="connected?.isConnected && connected?.isValid"
      >Authenticated ({{ connected?.userName }})</span
    >
    <span
      class="text-secondary font-weight-bold"
      *ngIf="connected?.isConnected && !connected?.isValid"
      >Reauthorisation required ({{ connected?.userName }})</span
    >

    <div class="spacer-md"></div>

    <!-- button options-->
    <div class="d-flex mt-2" *ngIf="!connected?.isConnected">
      <button
        class="btn btn-primary connect"
        type="submit"
        [promiseBtn]="busy"
        (click)="onConnect()"
      >
        Connect
      </button>
    </div>

    <div
      class="d-flex mt-2"
      *ngIf="connected?.isConnected && !connected?.isValid"
    >
      <button
        class="btn btn-primary connect"
        type="submit"
        [promiseBtn]="busy"
        (click)="onReconnect()"
      >
        Reconnect
      </button>
      <button
        class="btn btn-secondary connect"
        type="submit"
        [promiseBtn]="busy"
        (click)="onRevoke()"
      >
        Revoke
      </button>
    </div>

    <div
      class="d-flex mt-2"
      *ngIf="connected?.isConnected && connected?.isValid"
    >
      <button
        class="btn btn-primary connect"
        type="submit"
        [promiseBtn]="busy"
        (click)="onRevoke()"
      >
        Revoke
      </button>
    </div>
  </div>
</div>
