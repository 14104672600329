import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { SessionService } from '../services/session.service';

@Injectable({
    providedIn: 'root'
})
export class SystemAdminGuard implements CanActivate {

    constructor(private authService: AuthService, private sessionService: SessionService,
        private _router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.sessionService.active) {
            const isSignOnServiceAccount = this.sessionService.user.email === 'ssa-arh@theaccessgroup.com';

            if(isSignOnServiceAccount) {
                return true;
            }

            this._router.navigate(['/clients']);
            return false;
        }

        this.authService.login(state.url);
    }
}
