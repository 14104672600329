<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item">
      <a [routerLink]="'/admin/chart/matching-rule-sets'">
        Matching Rule Sets
      </a>
    </li>
    <li aria-current="page" class="breadcrumb-item active">Matching Rules</li>
  </ol>
</nav>

<crs-matching-rules />
