import { BacoAccountDto } from './baco-account.interface';
import { BacoTaxOptionDto } from './tax-option.interface';

export interface Feed {
  id: string;
  name: string;
  ledgerSourceId: string | null;
  ledgerName: string | null;
  ledgerFileName: string | null;
  billingRef?: string;
  timeZone: string | null;
}

export interface FeedDetailed extends Feed {
  accounts: BacoAccountDto[];
  taxOptions: BacoTaxOptionDto[];
}

export interface TimeZoneResponse {
  timeZones: TimeZoneDto[];
  defaultTimeZone: string;
  australiaTimeZone: string;
  newZealandTimeZone: string;
}

export interface TimeZoneDto {
  id: string;
  name: string;
}
