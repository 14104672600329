import { DateHelpers } from './../../../shared/utilities/date-helpers';

export class ReportOutput {
  branding: {
    primaryColour: string;
  };
  style: ReportStyleInfo;
  title: string;
  entity: any;
  partner: any;
  pages: ReportPageOutput[];
  pagesWithWarnings: ReportPageOutput[];
  reportHeaderColumns: ReportHeaderColumn[];
  endDate: Date;
  startDate: Date;
  warnings: Warning[];
  reportSignStatus: number;
  office: any;
  isAuditorRequired: boolean;
  isAuditorSMSFRequired: boolean;

  constructor(data: any) {
    Object.assign(this, data);

    this.pagesWithWarnings = this.pages
      ? this.pages.filter((page) => page.warnings && page.warnings.length)
      : null;
    this.endDate = DateHelpers.dateFromApiResponse(data.endDate);
    this.startDate = DateHelpers.dateFromApiResponse(data.startDate);
  }
}

// Meta data for holding temporary states
export class ReportMetaData {
  isModified: boolean;
}

export class ReportStyleInfo {
  rounding: number;
  percentRounding: number;
  brandingCss: string;
  customCss: string;
  cssImports: string;
}

export class ReportPageOutput {
  title: string;
  orientation: number;
  pageContent: any;
  content?: any;
  warnings: Warning[];
  pageTypeMasterId: number;
}

export class ReportHeaderColumn {
  constructor(data: Partial<ReportHeaderColumn>) {
    Object.assign(this, data);
  }

  headerText: string;
  periodText: string;
  signText: string;
}

export class Warning {
  severity: WarningSeverity;
  message: string;
  detailedMessages: string[];
  linkType: WarningLinkType;
}

export enum WarningLinkType {
  Accounts = 1,
  Entity = 2,
}

export enum WarningSeverity {
  Info = 0,
  Warning = 1,
  Danger = 2,
}
