import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ModalService } from 'src/app/core';
import { ActiveFileService } from '../../active-file.service';
import { ReportTemplateDataService } from '../../reports/reportTemplates/report-template-data.service';
import { catchError, exhaustMap, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { ReportOutput } from '../../reports/reportViewer/report-output';
import { ReportViewerParams } from '../../reports/reportViewer/models';
import { ReportViewerComponent } from '../../reports/reportViewer/report-viewer/report-viewer.component';
import { IDialogPanel } from 'src/app/shared';
import { TaxReportService } from './dataset-tax-report-service';
import { DatasetService } from '../dataset.service';

export enum TaxReportEnum {
  GstDetail = 1,
  GstSummary = 2,
  BasDetail = 3,
  BasSummary = 4,
}

enum GenerateOption {
  Normal = 0,
}

export class TaxReportFilters {
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: 'crs-dataset-tax-report',
  templateUrl: './dataset-tax-report.component.html',
  styleUrls: ['./dataset-tax-report.component.scss'],
})
export class DatasetTaxReportComponent implements OnInit {
  filters: TaxReportFilters;

  error: string;
  busy = {
    update: null,
    load: false,
    submit: null,
    generate: null,
  };
  public generateOptions = GenerateOption;

  fileId: string;
  datasetId: string;
  startDate: Date;
  endDate: Date;

  subscriptions: Subscription[] = [];
  generateButtonStream = new Subject<GenerateOption>();

  form = this.formBuilder.group({
    reportCategory: [null, Validators.required],
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
  });

  taxReportCategories = [
    { name: 'GST Detail Report', value: TaxReportEnum.GstDetail },
    { name: 'GST Summary', value: TaxReportEnum.GstSummary },
    { name: 'BAS Detail Report', value: TaxReportEnum.BasDetail },
    { name: 'BAS Summary', value: TaxReportEnum.BasSummary },
  ];

  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private readonly _activeFileService: ActiveFileService,
    private readonly _datasetService: DatasetService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    private readonly _route: ActivatedRoute,
    private readonly _taxReportService: TaxReportService,
    public data: ReportTemplateDataService
  ) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.fileId = this._activeFileService.file.id;

      const url = this.router.url;
      const splits = url.split('/');
      const datasetIndex = splits.findIndex((segment) =>
        segment.includes('datasets')
      );

      if (datasetIndex >= 0 && splits.length > datasetIndex + 1) {
        this.datasetId = splits[datasetIndex + 1];
      }
      if (!this.filters || !this.filters.startDate || !this.filters.endDate) {
        this.getAndApplyDatasetInfo();
      }
    });

    this.subscriptions.push(
      this.generateButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap((opt) => this.generateObservable(opt))
        )
        .subscribe()
    );
  }

  generate(option: GenerateOption = GenerateOption.Normal) {
    this.generateButtonStream.next(option);
  }

  generateObservable(option: GenerateOption): Observable<any> {
    const loadingStream = new Subject<void>();
    this.busy.generate = loadingStream.subscribe();

    let method: Observable<any>;

    const reportType = this.getReportType();

    switch (option) {
      case GenerateOption.Normal:
        method = this._taxReportService
          .getTaxReport$(
            this.datasetId,
            reportType,
            this.fileId,
            this.form.controls['startDate'].value,
            this.form.controls['endDate'].value
          )
          .pipe(tap((report) => this.renderViewer(report)));
        break;
    }

    return method.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => {
        loadingStream.complete();
      })
    );
  }

  showError(err) {
    this.error = err;
    this._messageService.error(err);
  }

  private renderViewer(report: ReportOutput) {
    const data = new ReportViewerParams({
      report,
      restrictSaveDocument: true,
      shouldUpdateCustomTableAutoTotal: false,
    });
    this._modalService.overlay(ReportViewerComponent, {
      data,
    } as IDialogPanel<ReportViewerParams>);
  }

  private getReportType(): TaxReportEnum | null {
    if (!this.form.value) return null;
    return this.form.value?.reportCategory;
  }

  private getAndApplyDatasetInfo() {
    this._datasetService.getLight$(this.datasetId).subscribe(
      (dataset) => {
        this.startDate = dataset.startDate;
        this.endDate = dataset.endDate;
        this.form.controls['startDate'].patchValue(dataset.startDate);
        this.form.controls['endDate'].patchValue(dataset.endDate);
      },
      (e) => {
        console.error('Error retrieving dataset default dates', e);
      }
    );
  }
}
