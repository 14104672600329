import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionCommentEditorComponent } from './transaction-comment-editor';
import { BacoTransactionCommentManageService, BacoTransactionCommentService } from './services';
import { MessageService } from '../../../../../core';
import { IBacoTransactionComment } from '../../../../interfaces';
import { TransactionClient } from 'src/app/baco/common';

@Component({
  selector: 'crs-transaction-comments',
  templateUrl: './transaction-comments.component.html',
  styleUrls: ['./transaction-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BacoTransactionCommentService, BacoTransactionCommentManageService]
})
export class TransactionCommentsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() id: string;

  @ViewChild('editor') public editor: TransactionCommentEditorComponent;

  public busy = {
    load: null
  };

  public comments$: Observable<IBacoTransactionComment[]> = this._commentManagerService.comments$.asObservable();

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly _activeModal: NgbActiveModal,
    public readonly _commentService: BacoTransactionCommentService,
    private readonly _commentManagerService: BacoTransactionCommentManageService,
    private readonly _transactionService: TransactionClient) {
  }

  public ngOnInit() {
    this.loadComments();
  }

  public ngAfterViewInit(): void {
    this.editor.textArea.nativeElement.focus();
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public submit(): void {
    if (this.editor.textArea.nativeElement.value?.trim()?.length) {
      this._commentManagerService.submitComment$.next(this.editor.textArea.nativeElement.value);
      this.editor.textArea.nativeElement.value = '';
    }
  }

  public cancel(): void {
    this._activeModal.dismiss();
  }

  private loadComments(): void {
    this.busy.load = this._transactionService.getComments(this.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((comments) => this._commentManagerService.seTCommentsState(this.id, comments));
  }
}
