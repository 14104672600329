import { ICssSelectorDescriptor } from './css-class-descriptor.interface';

export class EditableStyleInfo {
  defaultCss: string;
  descriptors: ICssSelectorDescriptor[];

  constructor(data: any) {
    Object.assign(this, data);
  }
}
