import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  Account,
  Classification,
  SourceAccount,
  SourceAccountService,
} from '../..';

@Component({
  selector: 'crs-source-account-select',
  templateUrl: './source-account-select.component.html',
  styleUrls: ['./source-account-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SourceAccountSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SourceAccountSelectComponent
  implements OnInit, ControlValueAccessor
{
  @Input() datasetId: string;

  @Input() multiple = false;
  @Input() placeholder: string = null;

  @ViewChild(NgSelectComponent, { static: true })
  private valueAccessor: ControlValueAccessor;

  accountsObservable: Observable<Account[]>;
  loading = false;
  accountInput = new Subject<string>();

  classifications = Classification;

  constructor(private accountService: SourceAccountService) {}

  private searchAccounts(): Observable<SourceAccount[]> {
    return this.accountService.getForDataset$(this.datasetId);
  }

  writeValue(value: any | any[]): void {
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  ngOnInit() {
    (this.valueAccessor as NgSelectComponent).bindLabel = 'displayName';
    (this.valueAccessor as NgSelectComponent).multiple = this.multiple;

    this.accountsObservable = this.accountInput.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.loading = true)),
      switchMap((term) =>
        this.searchAccounts().pipe(
          map((sourceAccounts) =>
            sourceAccounts.map((account) => new SourceAccount(account))
          )
        )
      ),
      catchError((err) => {
        console.error('Error searching for accounts', err);
        return of([]);
      }),
      tap(() => (this.loading = false))
    );
  }
}
