import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, EMPTY } from 'rxjs';
import {
  tap,
  catchError,
  exhaustMap,
  finalize,
  switchMap,
} from 'rxjs/operators';

import { Policy, PolicyService, PolicyLocation, PolicyLevel } from '../';
import { MessageService } from '../../../../../core';
import {
  getDefaultGridOptions,
  ProgressBarService,
  NgProgressRef,
} from '../../../../../shared';
import {
  GridApi,
  GridOptions,
  RowDragEndEvent,
  RowDragLeaveEvent,
  RowDragMoveEvent,
} from 'ag-grid-community';

enum Interaction {
  Move,
  Add,
}

class InteractionData {
  interaction: Interaction;
  moveId: string;
  moveIndex: number;
}

@Component({
  selector: 'crs-firm-policies',
  templateUrl: './firm-policies.component.html',
})
export class FirmPoliciesComponent implements OnInit {
  constructor(
    private readonly _policyService: PolicyService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService,
    private readonly _progressBar: ProgressBarService
  ) {}

  error: string;

  busy = {
    load: false,
    interactionPending: false,
  };
  policiesObservable: Observable<any>;

  gridOptions: GridOptions;
  gridApi: GridApi;
  policyLocations = PolicyLocation;
  policies: Policy[] = [];
  pendingPolicies: Policy[] = [];

  progress: NgProgressRef;

  interactionStream = new Subject<InteractionData>();

  sortActive: boolean;
  filterActive: boolean;

  isMaster(params) {
    return !!params.data && params.data.level === 0;
  }

  ngOnInit() {
    this.getPolicies();
    this.configureGridOptions();
    this.configureInteractionStream();
    this.progress = this._progressBar.ref('gridLoadingBar');
  }

  getPolicies() {
    this.busy.load = true;
    this._policyService
      .getAll()
      .pipe(
        tap((policies) => {
          this.busy.load = false;
          this.policies = policies;
        }),
        catchError((err) => {
          this.showError(err);
          return of([]);
        })
      )
      .subscribe();
  }

  addPolicy() {
    this._router.navigate(['./firm/add', { level: PolicyLevel.Firm }], {
      relativeTo: this._route,
    });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectPolicy(param) {
    if (!param.data) return;
    this._router.navigate(
      ['./firm/' + param.data.id, { level: PolicyLevel.Firm }],
      { relativeTo: this._route }
    );
  }

  configureInteractionStream() {
    this.interactionStream
      .pipe(
        tap(() => {
          this.error = null;
        }),
        exhaustMap((param) => this.handleInteraction(param))
      )
      .subscribe();
  }

  handleInteraction(param: InteractionData): Observable<any> {
    let observable: Observable<any> = EMPTY;

    if (param.interaction === Interaction.Add) {
      this.addPolicy();
      return EMPTY;
    }

    if (param.interaction === Interaction.Move) {
      this.progress.start();
      observable = this._policyService
        .reorderFirmPolicy(param.moveId, param.moveIndex + 1)
        .pipe(
          switchMap(() => this._policyService.getAll()),
          tap((p) => {
            this.policies = p;
            this.gridApi.setGridOption('rowData', this.policies);
            this.pendingPolicies = null;
          }),
          catchError((err) => {
            this.pendingPolicies = null;
            this.gridApi.setGridOption('rowData', this.policies);
            this.showError(err);
            return EMPTY;
          })
        );
    }

    this.busy.interactionPending = true;
    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => {
        this.progress.complete();
        this.busy.interactionPending = false;
      })
    );
  }

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.id,
      onGridReady: (event) => (this.gridApi = event.api),
      onRowDragMove: (param) => this.onRowDragMove(param),
      onRowDragLeave: (param) => this.onRowDragLeave(param),
      onRowDragEnd: (param) => this.onRowDragEnd(param),
      groupDefaultExpanded: -1,
      groupDisplayType: 'groupRows',

      columnDefs: [
        {
          field: 'location',
          headerName: 'Location',
          hide: true,
          rowGroup: true,
          maxWidth: 160,
          valueGetter: (params) => PolicyLocation[params.data.location],
        },
        {
          cellRenderer: 'badgeRenderer',
          field: 'name',
          headerName: 'Name',
          cellRendererParams: { badgeText: 'New', monthsToCheck: 3 },
          rowDrag: true,
        },
        {
          field: 'isMaster',
          headerName: 'Master',
          type: 'booleanColumn',
          cellRendererParams: { iconClass: 'fas fa-lock' },
          maxWidth: 100,
          minWidth: 100,
          valueGetter: (params) => this.isMaster(params),
        },
      ],
    };
  }

  onRowDragMove(event: RowDragMoveEvent) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
  }

  onRowDragLeave(event: RowDragLeaveEvent) {
    this.pendingPolicies = this.policies;
    event.api.setGridOption('rowData', this.pendingPolicies);
  }

  onRowDragEnd(event: RowDragEndEvent) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);

    const param = new InteractionData();
    param.interaction = Interaction.Move;
    param.moveId = event.node.data.id;
    param.moveIndex = event.overNode.childIndex;
    this.interactionStream.next(param);
  }

  executeUIMove(event: RowDragMoveEvent | RowDragEndEvent) {
    const movingNode = event.node;
    const overNode = event.overNode;
    const rowNeedsToMove =
      movingNode !== overNode &&
      overNode.data &&
      movingNode.data.location === overNode.data.location;
    if (rowNeedsToMove) {
      const fromIndex = this.policies.indexOf(movingNode.data);
      const toIndex = this.policies.indexOf(overNode.data);
      this.pendingPolicies = this.policies.slice();
      moveInArray(this.pendingPolicies, fromIndex, toIndex);
      this.gridApi.setGridOption('rowData', this.pendingPolicies);
      this.gridApi.clearFocusedCell();
    }
    function moveInArray(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }
}
