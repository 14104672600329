import { SourceAccount } from '../sourceAccounts/sourceAccount';
import { Division } from '../../setup';

export class JournalLine {
  sourceAccount: SourceAccount = new SourceAccount({});
  memo: string;
  balance: number;
  division: Division;
  taxCode: string;
  taxAmount: number;
  balanceMinusTax: number;
  gstLineId: string;
  gstParentId: string;
  basCode: string;

  get debit(): number {
    return this.balance > 0 ? this.balance : 0;
  }
  set debit(value: number) {
    const balance = this.convertToNumber(value);
    if (balance === 0 && this.balance < 0) return;
    this.balance = balance;
  }

  get credit(): number {
    return this.balance < 0 ? -this.balance : 0;
  }
  set credit(value: number) {
    const balance = this.convertToNumber(value);
    if (balance === 0 && this.balance > 0) return;
    this.balance = -balance;
  }

  private _quantity = 0;
  get quantity(): number {
    return this._quantity;
  }
  set quantity(value: number) {
    this._quantity = this.convertToNumber(value, false);
  }

  get isValidAccName(): boolean {
    return (
      !this.sourceAccount.accountName &&
      (this.isValidValue(this.debit) || this.isValidValue(this.credit))
    );
  }

  constructor(data) {
    if (!data) return;
    Object.assign(this, data);
    if (data.sourceAccount) {
      this.sourceAccount = new SourceAccount(this.sourceAccount);
    }
    if (data.division) this.division = new Division(this.division);
  }

  private convertToNumber(
    balance: number | string,
    round: boolean = true
  ): number {
    let number = 0;
    if (typeof balance === 'string') {
      number = parseFloat(balance);
    } else number = balance;
    if (isNaN(number) || number == null) number = 0;
    if (round) return Number(Math.round(<any>(number + 'e2')) + 'e-2');
    return number;
  }

  private isValidValue(value: number): boolean {
    return !!value && value !== 0;
  }
}

export class JournalLineModel {
  sourceAccount: SourceAccount;
  memo: string;
  balance: number;
  quantity: number;
  divisionId: string;
  taxCode: string;
  gstLineId: string;
  gstParentId: string;
  basCode: string;

  constructor(data) {
    if (data.sourceAccount) {
      this.sourceAccount = new SourceAccount(data.sourceAccount);
    }
    this.memo = data.memo;
    this.balance = data.balance;
    this.quantity = data.quantity;
    this.divisionId = data.division ? data.division.id : data.divisionId;
    this.taxCode = data.taxCode;
    this.gstLineId = data.gstLineId;
    this.gstParentId = data.gstParentId;
    this.basCode = data.basCode;
  }
}
