import { Directive, HostBinding, Input, ElementRef, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[crsBusyButton]'
})
export class BusyButtonDirective implements AfterContentInit {

  @HostBinding('class.disabled')
  @HostBinding('class.busy-active')
  private _active: boolean;

  @Input('crsBusyButton')
  set active(value: boolean) {
    this.updateActive(value);
  }

  get active(): boolean {
    return this._active;
  }

  constructor(private el: ElementRef) {

  }

  ngAfterContentInit(): void {
    this.el.nativeElement.insertAdjacentHTML('beforeend', `
<div class="busy">
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>
`);
  }

  updateActive(active: boolean) {
    this._active = active;
  }

}
