import { getDefaultGridOptions } from 'src/app/shared';
import { MessageService } from './../../../../../core/messages/message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

import { ImageService } from '../image.service';
import { Image } from '../image.class';

@Component({
  selector: 'crs-images',
  templateUrl: './images.component.html'
})
export class ImagesComponent implements OnInit {

  error: string;

  busy = {
    loading: false
  };

  images$: Observable<Image[]>;
  gridOptions = getDefaultGridOptions();

  constructor(private readonly _imageService: ImageService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService) { }

  ngOnInit(): void {
    this.refresh();
  }

  private refresh() {
    this.busy.loading = true;

    this.images$ = this._imageService.getAll().pipe(
        catchError(err => {
          this.showError(err);
          return of([]);
        }),
        finalize(() => this.busy.loading = false));
  }

  addImage() {
    this._router.navigate(['./add'], { relativeTo: this._route });
  }

  selectImage(image: Image) {
    this._router.navigate(['./' + image.id], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
  }

}
