import { DateEditorComponent, TypeAheadEditorComponent } from './editors/';

export const columnTypes = {
  templateColumn: {
    cellRenderer: 'templateRenderer',
  },
  hyperlinkColumn: {
    cellRenderer: 'hyperlinkRenderer',
    cellRendererParams: {
      route: './',
      routeParam: 'id',
    }, // defaults
  },
  dateColumn: {
    cellClass: 'date-cell',
    cellRenderer: 'dateRenderer',
    minWidth: 110,
    width: 110,
    maxWidth: 120,
    cellEditor: DateEditorComponent,
  },
  timeColumn: {
    cellClass: 'time-cell',
    cellRenderer: 'dateRenderer',
    cellRendererParams: {
      hasTime: true,
    },
    minWidth: 120,
    width: 130,
    maxWidth: 150,
  },
  dollarColumn: {
    cellClass: ['dollar-cell', 'ag-numeric-cell'],
    cellRenderer: 'dollarRenderer',
    headerClass: 'centered',
    minWidth: 100,
    width: 120,
    maxWidth: 160,
    aggFunc: 'sum',
    enableValue: true,
  },
  numberColumn: {
    cellClass: ['number-cell', 'ag-numeric-cell'],
    cellRenderer: 'numberRenderer',
    headerClass: 'centered',
    minWidth: 100,
    width: 120,
    maxWidth: 160,
  },
  percentColumn: {
    cellClass: ['percent-cell', 'ag-numeric-cell'],
    cellRenderer: 'numberRenderer',
    cellRendererParams: {
      isPercent: true,
      rounding: 2,
    },
    headerClass: 'centered',
    minWidth: 100,
    width: 120,
    maxWidth: 160,
  },
  optionsColumn: {
    headerName: 'Options',
    colId: 'options',
    minWidth: 60,
    maxWidth: 100,
    headerClass: 'd-none',
    cellRenderer: 'templateRenderer',
    cellClass: 'options-cell',
    filter: false,
    //pinned: 'right',
    menuTabs: [],
  },
  userColumn: {
    cellRenderer: 'userRenderer',
    tooltipField: 'user.displayName',
    getQuickFilterText(params) {
      return params.value.displayName + ' ' + params.value.email;
    },
  },
  booleanColumn: {
    cellClass: ['booleanCell', 'centered'],
    minWidth: 60,
    maxWidth: 60,
    cellRenderer: 'checkRenderer',
    headerClass: 'centered',
  },
  enumColumn: {
    cellRenderer: 'enumRenderer',
    getQuickFilterText(params) {
      return params.colDef.cellRendererParams.enum[params.value];
    },
  },
  typeAheadColumn: {
    cellEditor: TypeAheadEditorComponent,
    suppressKeyboardEvent: (params) => {
      // return true (to suppress) if editing and user hit up/down keys
      const keyCode = params.event.keyCode;
      const KEY_UP = 38;
      const KEY_DOWN = 40;
      const gridShouldDoNothing =
        params.editing && (keyCode === KEY_UP || keyCode === KEY_DOWN);
      return gridShouldDoNothing;
    },
  },
  commentsColumn: {
    cellEditor: 'agLargeTextCellEditor',
    cellEditorParams: {
      maxLength: 2048,
      rows: 3,
    },
  },
};
