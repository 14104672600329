import { ProfitDisplayOption } from '../../../enums/profit-display-option';
import {
  Disclosure,
  DisclosureSelectionMode,
} from './../../../notes/disclosures/disclosure';

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { of, Subscription, Subject } from 'rxjs';

import { AccountLayout } from 'src/app/accounting';
import {
  LayoutOption,
  LayoutService,
  HeaderLayoutOptionModel,
  HeaderLayoutOption,
  HeaderMatch,
} from '../../../layout';
import { getDefaultGridOptions, accountingRenderers } from 'src/app/shared';
import { MessageService, ModalService } from 'src/app/core';
import { ReportPageType } from '../../../reportPages';
import { ReportTemplateFinancialPageDetail } from './../../reportTemplatePage';
import { ReportTemplateColumn } from './../../reportTemplateColumn';
import { ActiveFileService } from './../../../../active-file.service';
import { tap } from 'rxjs/operators';
import { DisclosureSelectorsService } from '../../../notes/disclosures/disclosure-selectors.service';
import { finalize, filter, map } from 'rxjs/operators';
import { DisclosureSelectorModalComponent } from '../disclosure-selector-modal/disclosure-selector-modal.component';
import { ProfitLossLayoutOption } from '../../../enums/profit-loss-layout-option';
import { GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-report-template-financial-page-detail',
  templateUrl: './report-template-financial-page-detail.component.html',
})
export class ReportTemplateFinancialPageDetailComponent
  implements OnInit, OnDestroy
{
  @ViewChild('optionsCell', { static: true, read: TemplateRef })
  optionsCell!: TemplateRef<AccountLayout>;
  @ViewChild('disclosuresCell', { static: true, read: TemplateRef })
  disclosuresCell!: TemplateRef<AccountLayout>;

  @Input('index') i: number;
  @Input() pageId: string = null;
  @Input() detail: UntypedFormGroup;

  @Input() set open(value: boolean) {
    if (value) this.initialise();
  }

  private _pageType: ReportPageType;
  @Input() set pageType(value: ReportPageType) {
    this._pageType = value;
    if (value) {
      this.showSeparateTradingAccounts = value.canUseTradingAccounts;
    }
  }
  get pageType() {
    return this._pageType;
  }

  customColumns: ReportTemplateColumn[] = [];
  layout: AccountLayout[];
  showSeparateTradingAccounts = false;
  profitDisplayOptions = ProfitDisplayOption;
  profitlossLayoutOptions = ProfitLossLayoutOption;
  updateDisclosureSelectors$ = new Subject<void>();
  disclosureSelectors: Disclosure[];

  initialised = {
    disclosures: false,
    layout: false,
  };
  busy = {
    load: false,
  };
  subscriptions: Subscription[] = [];

  gridOptions: GridOptions;
  gridApi: GridApi;
  groupColumnDef = {
    rowDrag: (params) => params.data && params.data.isSortable,
    headerName: 'Layout',
    width: 250,
    menuTabs: [],
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'accountHeaderRenderer',
    },
    valueGetter: (params) => {
      return params.data.accountName;
    },
  };

  constructor(
    private readonly _layoutService: LayoutService,
    private readonly _messageService: MessageService,
    private readonly _activeFileService: ActiveFileService,
    private readonly _disclosureSelectorsService: DisclosureSelectorsService,
    private readonly _modalService: ModalService
  ) {}

  ngOnInit() {
    this.customiseGridOptions();
    this.subscriptions.push(
      this.updateDisclosureSelectors$
        .pipe(
          map(() => {
            return {
              disclosureSelectors: this.disclosureSelectors,
              layout: this.detail.controls.layoutFull.value,
            };
          }),
          filter((r) => !!r.disclosureSelectors && !!r.layout),
          tap((r) =>
            this.processDisclosureDefaults(
              r.disclosureSelectors,
              r.layout.filter((l) => l.layoutOption)
            )
          )
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  customiseGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      getDataPath: (data) => data.hierarchy,
      getRowId: (params) => params.data.id,
      onGridReady: (event) => (this.gridApi = event.api),
      components: accountingRenderers,
      treeData: true,
      suppressCellFocus: true,
      groupDefaultExpanded: -1,
      onRowGroupOpened: this.toggleExpand.bind(this),
      onFirstDataRendered: this.updateExpandCollapse.bind(this),
      columnDefs: [
        {
          field: 'accountNo',
          headerName: 'Account No',
          hide: true,
        },
        {
          field: 'accountName',
          headerName: 'Account Name',
          hide: true,
        },
        {
          headerClass: 'centered',
          headerName: 'Disclosure',
          type: 'templateColumn',
          cellRendererParams: { ngTemplate: this.disclosuresCell },
          maxWidth: 220,
          minWidth: 200,
          width: 220,
        },
        {
          headerClass: 'centered',
          headerName: 'Show in Note',
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCell },
          maxWidth: 120,
          minWidth: 120,
          width: 120,
        },
      ],
    };
  }

  private updateExpandCollapse(params) {
    const api = params ? params.api : this.gridOptions.api;
    if (!api) return;
    api.forEachNode((n) => {
      const data = n.data as AccountLayout;
      if (!data.isHeader) return;
      n.expanded = data.layoutOption.layoutOption === LayoutOption.Show;
    });
    api.onGroupExpandedOrCollapsed();
  }

  initialise() {
    if (!this.initialised.disclosures) {
      this.subscriptions.push(
        this._disclosureSelectorsService
          .getDisclosureStream()
          .pipe(
            tap((selectors) => (this.disclosureSelectors = selectors)),
            tap(() => this.updateDisclosureSelectors$.next())
          )
          .subscribe()
      );
      this.subscriptions.push(
        this._disclosureSelectorsService.disclosuresUpdated$.subscribe(() =>
          this.updateDisclosureSelectors$.next()
        )
      );
      this.initialised.disclosures = true;
    }

    if (!this.initialised.layout) {
      this.getLayout();
    }
  }

  private getLayout() {
    const detail = this.detail.value as ReportTemplateFinancialPageDetail;

    let layoutObservable = !this.pageId
      ? this._layoutService
          .getTemplatePageAccountLayoutNew(
            this._activeFileService.file.id,
            this.pageType.id
          )
          .pipe(
            tap((data) => {
              // Update imported full layout with any layout records currently present
              const currentLayout = this.detail.controls.layout
                .value as HeaderLayoutOptionModel[];
              if (currentLayout && currentLayout.length) {
                currentLayout.forEach((l) => {
                  const record = data.find(
                    (d) => d.id === l.headerMatch.accountId
                  );
                  if (record != null)
                    record.layoutOption = new HeaderLayoutOption(l);
                });
              }
            })
          )
      : this._layoutService.getTemplatePageAccountLayoutExisting(
          this._activeFileService.file.id,
          this.pageId
        );

    if (detail.layoutFull) layoutObservable = of(detail.layoutFull);

    this.busy.load = true;
    return layoutObservable
      .pipe(finalize(() => (this.busy.load = false)))
      .subscribe({
        next: (data) => {
          this.layout = data;
          this.detail.controls.layoutFull.setValue(this.layout);
          this.initialised.layout = true;
          this.updateDisclosureSelectors$.next();
        },
        error: (err) => this.showError(err),
      });
  }

  private processDisclosureDefaults(
    disclosures: Disclosure[],
    layout: AccountLayout[]
  ) {
    // Reset and get Manually Selected Disclosures
    layout.forEach((l) => {
      l.layoutOption.disclosureLink = null;
      l.layoutOption.disclosureLinkDisclosure = null;
      if (l.layoutOption.disclosureTemplateChainId) {
        l.layoutOption.disclosure = disclosures.find(
          (d) =>
            d.disclosureTemplateChainId ===
            l.layoutOption.disclosureTemplateChainId
        );
      }
    });

    // Match Default Disclosure Links
    disclosures.reverse().forEach((s) => {
      const variant = s.getActiveVariant();
      if (s.selectionMode === DisclosureSelectionMode.No) return;
      if (!variant || !variant.disclosureLinks) return;
      variant.disclosureLinks.reverse().forEach((l) => {
        const match = l.headerMatch as HeaderMatch;
        const layoutRecord = layout.find((c) => match.matchToAccount(c));
        if (!layoutRecord) return;
        layoutRecord.layoutOption.disclosureLink = l;
        layoutRecord.layoutOption.disclosureLinkDisclosure = s;
      });
    });
  }

  toggleShowAsNote(row: AccountLayout) {
    const data = row as AccountLayout;

    if (data.layoutOption.showAsNote) {
      const node = this.gridApi.getRowNode(data.id);
      data.layoutOption.layoutOption = node.expanded
        ? LayoutOption.Show
        : LayoutOption.Hide;
    } else {
      data.layoutOption.layoutOption = LayoutOption.ShowInNote;
    }
  }

  toggleExpand(node) {
    const data = node.data as AccountLayout;
    if (data.layoutOption.layoutOption !== LayoutOption.ShowInNote) {
      data.layoutOption.layoutOption =
        data.layoutOption.layoutOption === LayoutOption.Show
          ? LayoutOption.Hide
          : LayoutOption.Show;
    }
  }

  editDisclosureOptions(row: AccountLayout) {
    this._modalService
      .openModal(DisclosureSelectorModalComponent, null, {
        layoutOption: row.layoutOption,
        disclosures: this.disclosureSelectors,
      })
      .then((r) => true)
      .catch(() => true);
  }

  customiseColumns(customise: boolean) {
    this.detail.controls['customiseColumns'].setValue(customise);
  }

  showError(err) {
    this._messageService.error(err);
  }
}
