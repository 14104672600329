import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, EMPTY } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { DatabaseService } from './../database.service';
import { Database } from '../database';

@Component({
  selector: 'crs-database-select',
  templateUrl: './database-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatabaseSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DatabaseSelectComponent  implements ControlValueAccessor, OnInit {

  @Input() clearable = true;
  @Input() readonly = false;

  @ViewChild(NgSelectComponent, { static: true }) private _valueAccessor: NgSelectComponent;

  database$: Observable<Database[]>;
  private _loaded = false;
  private _loading = false;

  constructor(private readonly _databaseService: DatabaseService) { }

  writeValue(value: Database): void {
    this._valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }

  onOpen() {
    if (!this._loaded && !this._loading) {
      this.load();
    }
  }

  load() {
    this._loading = true;
    this.database$ = this._databaseService.getAll().pipe(
      tap(() => this._loaded = true),
      catchError(error => {
        console.log(error);
        return EMPTY; // empty list on error
      }),
      finalize(() => this._loading = false));

  }

  searchNameAndNumber(term: string, item: Database) {
    term = term.toLowerCase();
    const searchText = (item.id + ' ' + item.name).toLowerCase();
    return searchText.indexOf(term) > -1;
  }

  ngOnInit() {
    //this._valueAccessor.bindLabel = 'name'; // Binding not working with html tag for some reason
  }

}
