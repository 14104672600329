<div [ngBusy]="[busy.loading]">
  <div class="list-group-primary" *ngIf="response">
    <ng-container *ngFor="let entity of response.entities">
      <!-- Entities -->
      <div
        class="list-group-item clickable"
        (click)="handleEntitySelection(entity)"
      >
        <div class="d-flex justify-content-between align-items-center h-100">
          <div class="d-inline-block">
            <i
              aria-hidden="true"
              [class]="
                getIconClass(entity.files[0]?.entity?.entityTypeId) +
                ' fas-btn mr-2'
              "
            ></i>
          </div>
          <div class="d-inline-block mr-2">
            {{ entity.legalName }}
            <small class="text-muted ml-2" *ngIf="entity.code">
              {{ entity.code }}
            </small>
          </div>
          <div class="d-inline-block ml-auto">
            <span *ngIf="!entity.files.length">
              <button
                class="btn btn-secondary btn-sm mr-2"
                [promiseBtn]="busy.creating"
                (click)="addFile(true, entity.id); $event.stopPropagation()"
              >
                Add File
              </button>
            </span>
          </div>
          <div class="d-inline-block">
            <div class="btn-mini-menu d-inline">
              <i
                aria-hidden="true"
                class="fas fa-plus fas-btn-muted"
                title="Add New File"
                [class.invisible]="!entity.files.length"
                (click)="addFile(false, entity.id); $event.stopPropagation()"
              ></i>
              <i
                aria-hidden="true"
                class="fas fa-pencil-alt fas-btn-muted"
                title="Edit Client Entity"
                (click)="editEntity(entity.id); $event.stopPropagation()"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <!-- Files -->
      <ng-container *ngIf="entity.files && entity.files.length > 1">
        <div
          class="list-group-item clickable"
          *ngFor="let file of entity.files"
          [routerLink]="['/accounting', file.id]"
        >
          <div class="d-flex justify-content-between align-items-center h-100">
            <div class="d-flex align-items-center">
              <div class="d-inline-block ml-4">
                <i aria-hidden="true" class="fas fa-file fas-btn mr-2"></i>
              </div>
              <div class="d-inline-block mr-2">
                {{ file.name }}
              </div>
            </div>

            <div class="d-inline-block hidden">
              <div class="btn-mini-menu d-inline">
                <i
                  aria-hidden="true"
                  class="far fa-plus fas-btn-muted"
                  title="Add Additional File"
                  (click)="addFile(entity.id); $event.stopPropagation()"
                ></i>
                <i
                  aria-hidden="true"
                  class="fas fa-pencil-alt fas-btn-muted"
                  title="Edit Client Entity"
                  (click)="editEntity(entity.id); $event.stopPropagation()"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!-- Consolidated Files -->
    <div
      class="list-group-item clickable"
      *ngFor="let file of response.files"
      [routerLink]="['/accounting', file?.id]"
    >
      <div class="d-flex justify-content-between align-items-center h-100">
        <div class="d-flex align-items-center mr-2">
          <div class="d-inline-block">
            <i aria-hidden="true" class="fas fa-file fas-btn mr-2"></i>
          </div>
          <div class="d-inline-block mr-2">
            {{ file.name }}
          </div>
        </div>

        <div class="d-inline-block hidden">
          <div class="btn-mini-menu d-inline">
            <i
              aria-hidden="true"
              class="far fa-plus fas-btn-muted"
              title="Add New File"
            ></i>
            <i
              aria-hidden="true"
              class="fas fa-pencil-alt fas-btn-muted"
              title="Edit Client Entity"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
