import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';

import { ActiveFileService } from 'src/app/accounting/active-file.service';
import { File } from 'src/app/accounting/files/file';
import { DocumentIntegrationService } from 'src/app/configuration/document-integration/document-integration.datasource';
import { DocumentIntegrationValue } from 'src/app/configuration/enums';
import { IntegrationServerDatasource } from 'src/app/configuration/integration-server/integration-server.datasource';
import {
  EnhancedConfirmation,
  MessageService,
  ModalService,
  OVERLAY_DATA,
  SessionService,
} from '../../../../core';
import { IDialogPanel } from '../../../../shared';
import { ReportDocumentUtility } from '../../reportDocuments/report-document-utility';
import {
  ReportDocumentSaveModelParams,
  ReportDocumentsSaveComponent,
} from '../../reportDocuments/report-documents-save/report-documents-save.component';
import { ESignatureSendingModalComponent } from '../../reportTemplates/report-template/esignature-sending-modal/esignature-sending-modal.component';
import { BaseReportViewerComponent } from '../base-report-viewer/base-report-viewer.component';
import { ReportViewerParams } from '../models';
import { ReportExportModalComponent } from '../report-export-modal/report-export-modal.component';
import { ExportOption, ExportStatus } from './export-strategy';
import { ReportExportStrategy } from './report-export-strategy';

@Component({
  selector: 'crs-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss'],
  providers: [DocumentIntegrationService, IntegrationServerDatasource],
})
export class ReportViewerComponent
  extends BaseReportViewerComponent
  implements OnInit, OnDestroy
{
  busy: ExportStatus;
  file: File;
  isBankFeedFeatureEnabledOnly: boolean;
  isConnectedToIs: boolean = false;
  isDocumentIntegrationAccessDocuments: boolean = false;
  isDocumentIntegrationPleaseSign: boolean = false;

  isFetchingConnectedToIs$ = new BehaviorSubject(false);
  isFetchingDocumentIntegrationSetting$ = new BehaviorSubject(false);

  constructor(
    @Inject(OVERLAY_DATA)
    private data: IDialogPanel<ReportViewerParams>,
    private activeFileService: ActiveFileService,
    private documentIntegrationService: DocumentIntegrationService,
    private integrationServerDatasource: IntegrationServerDatasource,
    private messageService: MessageService,
    private modalService: ModalService,
    private reportExportStrategy: ReportExportStrategy,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService
  ) {
    super();
    this.params = data.data;
    this.busy = this.reportExportStrategy.busy;
  }

  ngOnInit(): void {
    this.isBankFeedFeatureEnabledOnly =
      !this.sessionService.featureFlags.ledger &&
      this.sessionService.featureFlags.bankFeed;

    this.fetchFile();
    this.fetchIsConnectedToIntegrationServer();
    this.fetchDocumentIntegrationSetting();
  }

  exportAsPdf() {
    const canOpenReportExportModal =
      this.isConnectedToIs &&
      this.isDocumentIntegrationAccessDocuments &&
      !this.isBankFeedFeatureEnabledOnly;

    const report = this.report;
    const reportDocumentListItem = this.reportDocumentListItem;
    const shouldUpdateCustomTableAutoTotal =
      this.shouldUpdateCustomTableAutoTotal;

    if (canOpenReportExportModal) {
      this.modalService.openModal(ReportExportModalComponent, null, {
        canOpenReportExportModal,
        exportOption: ExportOption.Pdf,
        report,
        reportDocumentListItem,
        shouldUpdateCustomTableAutoTotal,
      });
      return;
    }

    const title =
      reportDocumentListItem?.name ?? ReportDocumentUtility.getTitle(report);
    this.reportExportStrategy.exportAsPdf(report, title);
  }

  exportAsExcel() {
    const canOpenReportExportModal =
      this.isConnectedToIs &&
      this.isDocumentIntegrationAccessDocuments &&
      !this.isBankFeedFeatureEnabledOnly;

    const report = this.report;
    const reportDocumentListItem = this.reportDocumentListItem;
    const shouldUpdateCustomTableAutoTotal =
      this.shouldUpdateCustomTableAutoTotal;

    if (canOpenReportExportModal) {
      this.modalService.openModal(ReportExportModalComponent, null, {
        canOpenReportExportModal,
        exportOption: ExportOption.Excel,
        report,
        reportDocumentListItem,
        shouldUpdateCustomTableAutoTotal,
      });
      return;
    }
    const title =
      reportDocumentListItem?.name ?? ReportDocumentUtility.getTitle(report);
    this.reportExportStrategy.exportAsExcel(report, title);
  }

  exportAsWord() {
    const canOpenReportExportModal =
      this.isConnectedToIs &&
      this.isDocumentIntegrationAccessDocuments &&
      !this.isBankFeedFeatureEnabledOnly;

    const report = this.report;
    const reportDocumentListItem = this.reportDocumentListItem;
    const shouldUpdateCustomTableAutoTotal =
      this.shouldUpdateCustomTableAutoTotal;

    if (canOpenReportExportModal) {
      this.modalService.openModal(ReportExportModalComponent, null, {
        canOpenReportExportModal,
        exportOption: ExportOption.Word,
        report,
        reportDocumentListItem,
        shouldUpdateCustomTableAutoTotal,
      });
      return;
    }

    const title =
      reportDocumentListItem?.name ?? ReportDocumentUtility.getTitle(report);
    this.reportExportStrategy.exportAsWord(report, title);
  }

  sendToESign() {
    this.busy.sendESign = true;

    const report = this.report;
    const reportDocumentListItem = this.reportDocumentListItem;
    const title =
      reportDocumentListItem?.name ?? ReportDocumentUtility.getTitle(report);
    const entity = {
      ...report.entity,
      partner: report.partner,
    };
    const params = {
      template: this.reportTemplate,
      report,
      title,
      entity,
      reportDocumentId: this.reportDocumentId,
      reportDocumentListItem,
    };

    this.modalService
      .openModal(ESignatureSendingModalComponent, null, params, {
        windowClass: 'esign-modal',
      })
      .then((r) => true)
      .catch(() => true)
      .finally(() => {
        this.busy.sendESign = false;

        // Only applicable to documents, not for templates.
        if (reportDocumentListItem) {
          reportDocumentListItem.reportSignStatus = report.reportSignStatus;
        }
      });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.modalService.isOpen) {
      return;
    }

    const isArrowLeftKey = event.key === 'ArrowLeft';
    const isArrowRightKey = event.key === 'ArrowRight';

    if (!isArrowLeftKey && !isArrowRightKey) {
      return;
    }

    const reportEditableElementsOnEditMode = document.querySelectorAll(
      'crs-report-editable-element div.editor-content'
    );

    if (reportEditableElementsOnEditMode.length !== 0) {
      return;
    }

    if (isArrowLeftKey) {
      this.selectPageIndex(this.activePageIndex - 1);
    }

    if (isArrowRightKey) {
      this.selectPageIndex(this.activePageIndex + 1);
    }
  }

  onClickClose() {
    if (this.isBankFeedReport) {
      const urlSegmentsArr = this.route.pathFromRoot
        .map((route) => route.snapshot.url)
        .filter((urlSegments) => !!urlSegments[0]);
      const paths = urlSegmentsArr.map(([urlSegment]) => urlSegment.path);
      const isOpenedFromLedger = paths.includes('accounting');
      const fileId = this.file?.id;
      const feedId = this.route.snapshot.queryParamMap.get('feedId');

      const url = isOpenedFromLedger
        ? `/accounting/${fileId}/feeds/${feedId}/transactions`
        : `/baco/feed/${feedId}/transactions`;

      this.router
        .navigate([url], {
          queryParamsHandling: 'merge',
        })
        .then();
      return;
    }

    if (this.reportMetaData.isModified) {
      this.showSaveReportInfoDialog().then();
    } else {
      this.modalService.closeOverlay();
    }
  }

  private showSaveReportInfoDialog() {
    const confirmation = new EnhancedConfirmation({
      title: this.reportDocumentId ? 'Save Changes' : 'Save Document',
      text: this.reportDocumentId
        ? `Would you like to save the changes you have made?`
        : `Would you like to save this document? You can continue editing it later.`,
      additionalInfoText: this.reportDocumentId
        ? 'Any unsaved changes will be lost.<br/>'
        : 'The saved document can be found under <b>Reports</b> tab.',
      approveAction: () => {
        this.saveReportData(true);
      },
      cancelAction: () => {
        this.modalService.closeOverlay();
      },
      danger: false,
      approveBtn: 'Save and Close',
      cancelButton: 'Close without Saving',
    });

    return this.modalService.confirmationEx(confirmation);
  }

  public saveReportData(isSaveAndClose: boolean = false): void {
    this.busy.saveReportData = true;

    this.modalService
      .openModal(
        ReportDocumentsSaveComponent,
        null,
        new ReportDocumentSaveModelParams({
          reportDocumentListItem: this.reportDocumentListItem,
          report: this.report,
          reportTemplate: this.reportTemplate,
          isSaveAndClose: isSaveAndClose,
          reportMetaData: this.reportMetaData,
          gridOptions: this.gridOptions,
        }),
        {
          windowClass: 'report-save-modal',
        }
      )
      .then()
      .catch(() => true)
      .finally(() => {
        this.busy.saveReportData = false;
      });
  }

  private fetchFile(): void {
    this.activeFileService?.stream?.subscribe((file) => {
      this.file = file;
    });
  }

  private fetchIsConnectedToIntegrationServer(): void {
    this.isFetchingConnectedToIs$.next(true);

    this.integrationServerDatasource
      .getStatus()
      .pipe(
        catchError((error) => {
          this.messageService.error(error);
          return EMPTY;
        }),
        first(),
        finalize(() => this.isFetchingConnectedToIs$.next(false))
      )
      .subscribe((res) => {
        this.isConnectedToIs = res.isConnected;
      });
  }

  private fetchDocumentIntegrationSetting() {
    this.isFetchingDocumentIntegrationSetting$.next(true);

    this.documentIntegrationService
      .getDocumentIntegrationSetting$()
      .pipe(
        catchError((error) => {
          this.messageService.error(error);
          return EMPTY;
        }),
        first(),
        finalize(() => this.isFetchingDocumentIntegrationSetting$.next(false))
      )
      .subscribe((eSignature) => {
        this.isDocumentIntegrationAccessDocuments =
          eSignature?.value === DocumentIntegrationValue.AccessDocuments;
        this.isDocumentIntegrationPleaseSign =
          eSignature?.value === DocumentIntegrationValue.PleaseSign;
      });
  }
}
