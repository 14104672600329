<div [formGroup]="detail">

  <!-- Profit and Loss Customisations -->
  <div class="row" *ngIf="showSeparateTradingAccounts">
    <div class="col-form-sm">

      <!-- Profit Display Option -->
      <div class="form-group required">
        <label class="control-label">Display Option</label>
        <ng-select [items]="profitDisplayOptions | enumToArray" bindValue="value" bindLabel="name" formControlName="profitDisplayOption" [clearable]="false"></ng-select>
      </div>

      <!-- Profit Losss Layout Option -->
      <div class="form-group required">
        <label class="control-label">Layout Option</label>
        <ng-select [items]="profitlossLayoutOptions | enumToArray" bindValue="value" bindLabel="name" formControlName="profitlossLayoutOption" [clearable]="false"></ng-select>
      </div>

      <!-- Separate Trading Accounts -->
      <div class="form-group">
        <div class="form-check">
          <input type="checkbox" readonly class="form-check-input" [id]="'separateCheck' + i" formControlName="separateTradingAccounts">
          <label class="form-check-label" [for]="'separateCheck' + i">Separate Trading Accounts</label>
        </div>
      </div>

    </div>
  </div>

  <!-- Layout -->
  <div class="row" [ngBusy]="busy.load">
      <div class="col-sm-9">


        <ag-grid-angular #allocatedGrid
                        style="width: 100%; height: 100%"
                        class="ag-theme-material"
                        [gridOptions]="gridOptions"
                        [rowData]="layout"
                        [autoGroupColumnDef]="groupColumnDef">
          <ag-grid-column headerName="Account No" field="accountNo" [hide]="true"></ag-grid-column>
          <ag-grid-column headerName="Account Name" field="accountName" [hide]="true"></ag-grid-column>
          <ag-grid-column headerName="Show in Note" headerClass="centered" [minWidth]="120" [maxWidth]="120" [width]="120" type="optionsColumn" [cellRendererParams]="{ngTemplate: optionsCell}" pinned="none"></ag-grid-column>
        </ag-grid-angular>

        <ng-template #optionsCell let-row>
            <div class="btn-mini-menu" *ngIf="row.layoutOption && !row.isPlaceholder">
              <input type="checkbox" [checked]="row.layoutOption.showAsNote" (click)="toggleShowAsNote(row)" title="Show in Note">
              <!-- Todo: Implement total and 'root' functions for layouts -->
              <!-- <a [routerLink]="" class="btn btn-sm fas-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                <span class="sr-only"> Show Display Options </span>
              </a>
              <div class="dropdown-menu">
                <button type="button" class="btn dropdown-item" (click)="editTotals(row)">
                  <i class="fas fa-stream" aria-hidden="true"></i>
                  Edit Totals
                </button>
                <button type="button" class="btn dropdown-item" (click)="moveToRoot(row)" *ngIf="row.hierarchy.length > 1">
                  <i class="fas fa-arrow-left" aria-hidden="true"></i>
                  Move to Root Level
                </button>
                <button type="button" class="btn dropdown-item" (click)="moveFromRoot(row)" *ngIf="row.hierarchy.length > 1">
                    <i class="fas fa-arrow-right" aria-hidden="true"></i>
                    Restore Normal Placement
                  </button>
              </div> -->
            </div>
          </ng-template>

      </div>
    </div>


</div>
