import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  FormControl,
} from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService, ModalService } from 'src/app/core';
import { ErrorMessageService } from 'src/app/shared/validation/Services';
import { BankConnectionSettingsService } from 'src/app/baco/services';
import { Subject, Subscription, Observable, EMPTY } from 'rxjs';
import {
  tap,
  exhaustMap,
  catchError,
  finalize,
  takeUntil,
  switchMap,
} from 'rxjs/operators';
import {
  BacoBankConnectionDto,
  BacoConnectionValidationDto,
  UpdateSchedulingSettingsModel,
} from 'src/app/baco/interfaces';
import { BankConnectionState } from 'src/app/baco/enums';
import { SchedulingSettingsInterval } from './bank-connection-settings.enum';
import { BankConnectionClient } from 'src/app/baco/common';
import { TransactionProvider } from '../bank-connection.enum';
import { BankConnectionTransactionValidationComponent } from '../bank-connection-transaction-validation/bank-connection-transaction-validation.component';
import { ProgressBarService } from 'src/app/shared';

@Component({
  selector: 'crs-bank-connection-settings',
  templateUrl: './bank-connection-settings.component.html',
  styleUrls: ['./bank-connection-settings.component.scss'],
})
export class BankConnectionSettingsComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() params: BacoBankConnectionDto;

  get lastRefreshLocalDate() {
    return this.params?.lastRefreshLocalDate;
  }

  objectTitle = 'Bank Connection Settings';
  dataStartDate: Date;

  private submitImportStream = new Subject<void>();
  private submitSchedulingStream = new Subject<void>();
  private submitValidateDataStream = new Subject<void>();
  private _destroy: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription[] = [];
  schedulingFormBusy = {
    loading: false,
    submit: null,
    delete: null,
  };
  importingFormBusy = {
    loading: false,
    submit: null,
    delete: null,
  };
  validatingFormBusy = {
    loading: false,
    submit: null,
    delete: null,
  };
  error: string = null;
  transactionProvider = TransactionProvider;
  schedulingSettingsIntervalList = SchedulingSettingsInterval;
  BankConnectionStates = BankConnectionState;
  schedulingSettingsForm: UntypedFormGroup = this.buildSchedulingSettingsForm();
  importNowForm: UntypedFormGroup = this.buildImportNowForm();
  validateDataForm: UntypedFormGroup = this.buildValidateDataForm();

  progressMessage: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _bankConnectionSettingsService: BankConnectionSettingsService,
    private readonly _bankConnectionClient: BankConnectionClient,
    private formBuilder: UntypedFormBuilder,
    private readonly _modalService: ModalService,
    private messageService: MessageService,
    private errorMessageService: ErrorMessageService,
    private modalService: ModalService,
    private progressBar: ProgressBarService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this._destroy.next(true);
    this._destroy.complete();
  }

  ngOnInit() {
    this.configureSchedulingFormSubmit();
    this.configureValidateDataSubmit();

    if (this.params.state === BankConnectionState.Active) {
      this.getValidationStartDate();
      this.getBankAccountSettingsDetails();
      this.configureImportNowSubmit();
    }
  }

  buildSchedulingSettingsForm(
    schedulingSettings: UpdateSchedulingSettingsModel = null
  ) {
    const form = this.formBuilder.group({
      schedulingSettingsInterval: [
        SchedulingSettingsInterval.Week,
        [Validators.required],
      ],
      intervalAmount: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$')],
      ],
      commencementLocalDate: new FormControl<Date>(
        new Date(new Date().setHours(0, 0, 0, 0)),
        { nonNullable: true, validators: [Validators.required] }
      ),
    });

    if (!schedulingSettings) return form;

    form.patchValue(schedulingSettings);

    return form;
  }

  buildImportNowForm(commencementLocalDate?: Date) {
    const initialDataStartDate = !!this.lastRefreshLocalDate
      ? new Date(this.lastRefreshLocalDate)
      : (commencementLocalDate && new Date(commencementLocalDate)) ||
        new Date();
    return this.formBuilder.group({
      dataStartDate: [initialDataStartDate, Validators.required],
    });
  }

  getValidationStartDate() {
    this.validatingFormBusy.loading = true;
    const progressBar = this.progressBar.ref('progressBar');
    progressBar.start();

    return this._bankConnectionSettingsService
      .retrieveValidationStartDate(this.id)
      .pipe(
        tap((x) => {
          this.validateDataForm = this.buildValidateDataForm();
          this.validateDataForm.patchValue({ validatingDataStartDate: x });
        }),
        finalize(() => {
          this.validatingFormBusy.loading = false;
          progressBar.complete();
        }),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  buildValidateDataForm() {
    return this.formBuilder.group({
      validatingDataStartDate: [null, Validators.required],
    });
  }

  getBankAccountSettingsDetails() {
    this.schedulingFormBusy.loading = true;
    return this._bankConnectionSettingsService
      .getBankAccountSettings(this.id)
      .pipe(
        tap((x) => {
          this.schedulingSettingsForm = this.buildSchedulingSettingsForm(x);
          this.importNowForm = this.buildImportNowForm(
            new Date(x.commencementLocalDate)
          );
        }),
        finalize(() => (this.schedulingFormBusy.loading = false)),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  onSchedulingSettingsSubmit() {
    this.submitSchedulingStream.next();
  }

  configureSchedulingFormSubmit() {
    this.subscriptions.push(
      this.submitSchedulingStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.submitSchedulingSettingsObservable())
        )
        .subscribe((result) => {
          this.messageService.success(
            'Bank Connection Settings have been Updated Successfully.'
          ),
            this.activeModal.close();
        })
    );
  }

  submitSchedulingSettingsObservable(): Observable<any> {
    let observable: Observable<any>;
    const formValue = this.schedulingSettingsForm.value;

    const loadingStream = new Subject<void>();
    this.schedulingFormBusy.submit = loadingStream.subscribe();

    const updateSchedulingSettingsModel = new UpdateSchedulingSettingsModel(
      formValue
    );
    updateSchedulingSettingsModel.commencementLocalDate = new Date(
      new Date(updateSchedulingSettingsModel.commencementLocalDate).setHours(
        0,
        0,
        0
      )
    );

    observable = this._bankConnectionSettingsService.updateSchedulingSettings(
      this.id,
      updateSchedulingSettingsModel
    );

    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  configureImportNowSubmit() {
    this.subscriptions.push(
      this.submitImportStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.importObservable())
        )
        .subscribe((result) => {
          this.messageService.success(
            'Bank connection Feeds have been imported Successfully.'
          );
        })
    );
  }

  importObservable(): Observable<any> {
    let observable: Observable<any>;

    const loadingStream = new Subject<void>();
    this.importingFormBusy.submit = loadingStream.subscribe();

    const dataStartDate = new Date(
      this.importNowForm.value.dataStartDate.setHours(0, 0, 0, 0)
    );
    observable = this._bankConnectionSettingsService.importFeedDataNow(
      this.id,
      dataStartDate
    );

    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  onImportNowSubmit() {
    this.submitImportStream.next();
  }

  onValidateDataSubmit() {
    this.submitValidateDataStream.next();
  }

  configureValidateDataSubmit() {
    this.subscriptions.push(
      this.submitValidateDataStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.validateDataObservable())
        )
        .subscribe((result) => {
          this.messageService.success('Data has been validated.');
        })
    );
  }

  validateDataObservable(): Observable<any> {
    let observable: Observable<any>;

    const loadingStream = new Subject<void>();
    this.validatingFormBusy.submit = loadingStream.subscribe();

    if (!this.validateDataForm.value.validatingDataStartDate) {
      this.error = 'Please select a date to validate data.';
      return EMPTY;
    }

    let validatingDataStartDate =
      this.validateDataForm.value.validatingDataStartDate;
    // Ensure validatingDataStartDate is a Date object
    if (!(validatingDataStartDate instanceof Date)) {
      validatingDataStartDate = new Date(validatingDataStartDate);
    }
    validatingDataStartDate.setHours(0, 0, 0, 0);

    this.progressMessage = 'Importing feed data...';
    const progressBar = this.progressBar.ref('progressBar');
    progressBar.start();

    observable = this._bankConnectionSettingsService
      .importFeedDataNow(this.id, validatingDataStartDate)
      .pipe(
        tap(() => {
          this.progressMessage = 'Validating data...';
        }),
        switchMap(() =>
          this._bankConnectionSettingsService.validateData(
            this.id,
            validatingDataStartDate
          )
        ),
        tap((validationData: BacoConnectionValidationDto) => {
          this.progressMessage = '';
          this.modalService.openModal(
            BankConnectionTransactionValidationComponent,
            this.id,
            {
              validationData,
              id: this.params.id,
              bankConnectionName: this.params?.name || '',
            }
          );
        }),
        catchError((err) => {
          this.progressMessage = '';
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => {
          progressBar.complete();
          loadingStream.complete();
        })
      );

    return observable;
  }

  showError(error) {
    this.error = error;
  }

  cancel() {
    this.activeModal.dismiss();
  }

  disconnectBankConnection() {
    this._modalService.confirmation(
      'Are you sure you want to disconnect this bank connection?',
      () => {
        this.schedulingFormBusy.loading = true;
        return this._bankConnectionClient
          .RevokeBankConnection(this.id)
          .pipe(
            finalize(() => (this.schedulingFormBusy.loading = false)),
            catchError((err) => {
              this.showError(err);
              return EMPTY;
            }),
            takeUntil(this._destroy)
          )
          .subscribe((_) => {
            this.messageService.success(
              'Bank connection has been Disconnected Successfully.'
            );
            this.activeModal.close({ reload: true });
          });
      },
      true
    );
  }

  reInviteClient(isDisconnected) {
    this.schedulingFormBusy.loading = true;
    return this._bankConnectionClient
      .ReInviteClientByBankConnection(this.id, isDisconnected)
      .pipe(
        finalize(() => (this.schedulingFormBusy.loading = false)),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        takeUntil(this._destroy)
      )
      .subscribe((_) =>
        this.messageService.success(
          'invitation has been sent to the user Successfully.'
        )
      );
  }
}
