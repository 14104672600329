import { Alignment } from '..';
import { IElement } from '../element';
import { ReportElementTypeEnum } from '../../../enums';

export class ReportImage implements IElement {
  elementType = ReportElementTypeEnum.Image;
  image: any;
  customUrl?: string;
  alignment: Alignment = Alignment.Left;
  marginTop: boolean;
  marginBottom: boolean;
  height?: number;
  width?: number;
  isNew = true;

  constructor(data?: Partial<ReportImage>) {
    if (data) Object.assign(this, data);
  }
}
