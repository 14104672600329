import { Subscription } from 'rxjs';
import { Component, forwardRef, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultValueAccessor } from 'src/app/shared/components/value-accessors';
import { ICssClassGroup } from '../../models';
import { ReportCssService } from '../../services';
import { tap } from 'rxjs/operators';

class CssProperty {
  name: string;
  value: string;
  defaultValue: string;
}

@Component({
    selector: 'crs-css-classes-editor',
    templateUrl: './classes-editor.component.html',
    styleUrls: ['./classes-editor.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CssClassesEditorComponent),
        multi: true
    }]
})

export class CssClassesEditorComponent extends DefaultValueAccessor<ICssClassGroup[]> implements OnInit, OnDestroy {

    public classGroups: ICssClassGroup[] = [];

    public classGroupControl = this._formBuilder.control(null);
    public selectedClassGroup: ICssClassGroup;

    public properties: CssProperty[] = [];
    public pendingProperty: CssProperty = new CssProperty();

    public isWhiteListEditing = true;
    public whiteListProperties = [];
    public fullWhiteListProperties = [];

    subscriptions: Subscription[] = [];

    constructor(private _reportCssDatasource: ReportCssService,
      private readonly _formBuilder: UntypedFormBuilder) {
        super();
    }

    public ngOnInit() {
        this._reportCssDatasource.getWhiteListCssProperties()
            .subscribe(properties => {
              this.whiteListProperties = properties;
              this.fullWhiteListProperties = properties;
            });

        this.subscriptions.push(
          this.classGroupControl.valueChanges.pipe(tap(v => {
            this.selectedClassGroup = v;
            this.refreshProperties();
          })).subscribe()
        );
    }

    public ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions = [];
    }

    public addProperty() {
        if (!this.pendingProperty.name || !(this.pendingProperty.value?.length > 1)) {
            return;
        }

        this.properties.push(this.pendingProperty);
        this.pendingProperty = new CssProperty();
        this.rebuildCss();
    }

    public removeProperty(property: CssProperty) {
      if (property.defaultValue) property.value = null;
      else {
        const index = this.properties.indexOf(property);
        this.properties.splice(index, 1);
      }
      this.rebuildCss();
    }

    public onRawCssInputModeChange() {
        this.isWhiteListEditing = !this.isWhiteListEditing;
        if (this.isWhiteListEditing) this.refreshProperties();
    }

    public reset() {
        this.classGroupControl.setValue(null);
    }

    private refreshProperties() {
      this.properties = this.getGroupProperties(this.selectedClassGroup);

      this.filterPropertyList();
    }

    private filterPropertyList() {
      const existingPropertyNames = this.properties.map(pr => pr.name);
      this.whiteListProperties = this.fullWhiteListProperties.filter(prop => !existingPropertyNames.includes(prop));
    }

    private getGroupProperties(classGroup: ICssClassGroup): CssProperty[] {

      if (!classGroup) return [];

      const properties: CssProperty[] = [];
      const defaultLines = !classGroup.defaultCss ? [] : classGroup.defaultCss.split(';');
      const customLines = !classGroup.customCss ? [] : classGroup.customCss.split(';');

      defaultLines.forEach(line => {
        const cssProperty = this.parseCssLine(line, true);
        if (!cssProperty) return;
        properties.push(cssProperty);
      });

      customLines.forEach(line => {
        const cssProperty = this.parseCssLine(line, false);
        if (!cssProperty) return;

        const existing = properties.find(p => p.name === cssProperty.name);
        if (existing) {
          existing.value = cssProperty.value;
        } else {
          properties.push(cssProperty);
        }
      });

      return properties;
    }

    private parseCssLine(line: string, isDefault: boolean) {
      if (!line) return null;
      const keyPair = line.split(':');
      if (keyPair.length !== 2) return null;
      const value = this.hasValue(keyPair[1]) ? keyPair[1].trim() : null;
      const cssProperty = {
        name: keyPair[0].trim(),
        value: isDefault ? null : value,
        defaultValue: isDefault ? value : null
      } as CssProperty;

      if (!cssProperty.name) return null;
      return cssProperty;
    }

    rebuildCss() {
      this.selectedClassGroup.customCss = this.properties
        .filter(p => this.hasValue(p.value))
        .map(p => p.name + ': ' + p.value + ';')
        .join('\n');

      this.filterPropertyList();
    }

    /**
     * Checks if the provided string is a valid value for css
     */
    hasValue(value: string) {
      return value != null && value.trim() !== '';
    }
}
