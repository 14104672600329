import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-source-renderer',
  template: `
    <span title="{{ params.value.name }}" *ngIf="params.value">
      <img class="icon-img mr-2" [src]="params.value.sourceType?.icon" />
      <span>{{ params.value.name }}</span>
    </span>
  `,
})
export class SourceRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
