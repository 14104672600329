import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

export enum WorkpaperStatus {
  NoWorkpaper = 0,
  NotStarted,
  ReadyToStart,
  InProgress,
  Queries,
  OnHold,
  ReadyForReview,
  InReview,
  ReWork,
  Completed,
}

@Component({
  selector: 'crs-workpaper-status-dropdown',
  templateUrl: './workpaper-status-dropdown.component.html',
  styleUrls: ['./workpaper-status-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: WorkpaperStatusDropdownComponent,
      multi: true,
    },
  ],
})
export class WorkpaperStatusDropdownComponent
  implements ControlValueAccessor, OnInit
{
  @Input() selectedValue: number;
  @Input() appendTo: string;
  @Input() variant: 'outline' | 'ghost' = 'outline';
  @Input() isClearable: boolean = false;
  @Input() removeNoWorkpaperOption: boolean = false;

  @Output() selectedValueChange = new EventEmitter<number>();

  statusOptions = [
    { value: WorkpaperStatus.NoWorkpaper, label: 'No Workpaper' },
    {
      value: WorkpaperStatus.NotStarted,
      label: 'Not started',
      class: 'status-not-started',
    },
    {
      value: WorkpaperStatus.ReadyToStart,
      label: 'Ready to start',
      class: 'status-ready-to-start',
    },
    {
      value: WorkpaperStatus.InProgress,
      label: 'In progress',
      class: 'status-in-progress',
    },
    {
      value: WorkpaperStatus.Queries,
      label: 'Queries',
      class: 'status-queries',
    },
    {
      value: WorkpaperStatus.OnHold,
      label: 'On Hold',
      class: 'status-on-hold',
    },
    {
      value: WorkpaperStatus.ReadyForReview,
      label: 'Ready for review',
      class: 'status-ready-for-review',
    },
    {
      value: WorkpaperStatus.InReview,
      label: 'In review',
      class: 'status-in-review',
    },
    {
      value: WorkpaperStatus.ReWork,
      label: 'Re-work',
      class: 'status-re-work',
    },
    {
      value: WorkpaperStatus.Completed,
      label: 'Completed',
      class: 'status-completed',
    },
  ];

  statusControl: FormControl;

  private onChange = (value: any) => {};
  private onTouched = () => {};

  ngOnInit() {
    this.statusControl = new FormControl(this.selectedValue);
    this.statusControl.valueChanges.subscribe((value) => {
      if (
        this.removeNoWorkpaperOption &&
        value === WorkpaperStatus.NoWorkpaper
      ) {
        this.statusControl.setValue(this.selectedValue, { emitEvent: false });
      } else {
        this.selectedValue = value;
        this.selectedValueChange.emit(this.selectedValue);
        this.onChange(this.selectedValue);
        this.onTouched();
      }
    });
  }

  writeValue(value: any): void {
    this.selectedValue = value;
    this.statusControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getSelectedLabel(): string {
    const selectedOption = this.statusOptions.find(
      (option) => option.value === this.selectedValue
    );

    return selectedOption ? selectedOption.label : '';
  }

  getStatusClass(status: number): string {
    const option = this.statusOptions.find((opt) => opt.value === status);
    return option ? option.class : '';
  }
}
