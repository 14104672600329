import { BacoAccountSelectionDto } from './interfaces';

export class BacoUtils {
  public static roundTo(num, roundTo) {
    return +(Math.round(Number(num + 'e+' + roundTo)) + 'e-' + roundTo);
  }

  public static roundToTwo(num) {
    return this.roundTo(num, 2);
  }

  public static getPercentageAmount(num: number, percent?: number | null): number | null {
    return percent == null ? null : this.roundToTwo((num * percent / 100));
  }

  //amount includes GST
  //for more info: https://taxsuperandyou.gov.au/gstcalculator
  public static calculateTax(amountIncludeGST: number, percent?: number | null): number | null {
    if (percent == null) {
      return null;
    }

    const taxRate = percent / 100;
    const amountMinusTax = amountIncludeGST / (1 + taxRate);
    const taxAmount = amountIncludeGST - amountMinusTax;

    return this.roundToTwo(taxAmount);
  }

  public static getPercentageValue(num: number, otherNum: number) {
    return this.roundTo((otherNum / num * 100), 4);
  }

  public static getTransactionTax(accounts: BacoAccountSelectionDto[]): number | null {
    if (!accounts?.length) {
      return 0;
    }
    return accounts.map(x => this.getAccountSelectionTax(x)).reduce((taxA, taxB) => taxA + taxB, 0);
  }

  public static getAccountSelectionTax(account: BacoAccountSelectionDto): number | null {
    const taxSelection = account.taxSelection;
    return !taxSelection ? 0 : this.roundToTwo((taxSelection.percentage * account.totalAmount / 100));
  }

  public static getAccountSelectionVisibleAmount(account: BacoAccountSelectionDto): number | null {
    return account.totalAmount - (this.getAccountSelectionTax(account));
  }
}
