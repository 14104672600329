export class DateHelpers {

  public static dateFromApiResponse(date: string | Date): Date {
    if (!date) return null;
    if (typeof date === 'string') {
      const lastCharacter = date[date.length - 1];
      if (lastCharacter !== 'z' && lastCharacter !== 'Z') date = date + 'Z';
      return new Date(date);
    }
    return date;
  }

  public static addDays(date: Date, days: number) {
    const day = date.getUTCDate() + days;
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), day));
  }

  public static addYears(date: Date, years: number) {
    return new Date(Date.UTC(date.getUTCFullYear() + years, date.getUTCMonth(), date.getUTCDate()));
  }

  public static convertTimeToJsonString(date: Date) {

    if (!date) return null;
    if (typeof date === 'string') return date;
    const fullYear = this.pad(date.getFullYear(), 4);
    const month = this.pad(date.getMonth() + 1, 2);
    const day = this.pad(date.getDate(), 2);
    const hours = this.pad(date.getHours(), 2);
    const minutes = this.pad(date.getMinutes(), 2);
    const seconds = this.pad(date.getSeconds(), 2);
    const milliseconds = this.pad(date.getMilliseconds(), 3);

    const jsonDate = `${fullYear}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    return jsonDate;
  }

  public static convertDateToJsonString(date: Date | string) {

    if (!date) return null;
    if (typeof date === 'string') return date;
    const fullYear = this.pad(date.getFullYear(), 4);
    const month = this.pad(date.getMonth() + 1, 2);
    const day = this.pad(date.getDate(), 2);

    const jsonDate = `${fullYear}-${month}-${day}`;
    return jsonDate;
  }

  public static isWithinLastXMonths(dateString: string, monthsToCheck: number): boolean {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    // Calculate the timestamp x months ago from the current date. (defaults to 3 months)
    const xMonthsAgoTimestamp = currentDate.getTime() - (60 * 60 * 24 * 30 * monthsToCheck * 1000);
  
    // Check if the input date is after the x months ago timestamp
    return inputDate.getTime() >= xMonthsAgoTimestamp;
  }
  
  private static pad(value: number, length: number) {
    if (!value) return ''.padStart(length, '0');
    return value.toString().padStart(length, '0');
  }


}
