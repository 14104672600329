import { Component, Input } from '@angular/core';
import { FinancialContentInfo } from '../../report-financial/report-content-info';

@Component({
  selector: '[crs-report-financial-header]',
  templateUrl: './report-financial-header.component.html'
})
export class ReportFinancialHeaderComponent {

  @Input() element;
  @Input() index: number;
  @Input() isEditing = false;
  @Input() financialContent: FinancialContentInfo;

}
