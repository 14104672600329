import { FirmModule } from './../../../../firm/firm.module';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ImagesComponent } from './images/images.component';
import { ImageComponent } from './image/image.component';
import { ImageSelectComponent } from './image-select/image-select.component';
import { BaseImageComponent } from './image/base-image/base-image.component';
import { ImageVariantComponent } from './image/image-variant/image-variant.component';

@NgModule({
    declarations: [
        ImagesComponent,
        ImageComponent,
        ImageSelectComponent,
        BaseImageComponent,
        ImageVariantComponent
    ],
    imports: [
        SharedModule,
        FirmModule
    ],
    exports: [
        ImagesComponent,
        ImageComponent,
        ImageSelectComponent
    ]
})
export class ImagesModule {
}
