import { LedgerSide } from '../..';
import { HeaderMatch } from './headerMatch';
export class TotalDefinition {
  name: string;
  alternateNameMixed: string;
  alternateNameNegative: string;
  ledgerSide: LedgerSide;
  isPercent: boolean;
  formula: string;
  showIfHeadersPresent: HeaderMatch[];
  constructor(data) {
    Object.assign(this, data);
    if (data.showIfHeadersPresent) {
      this.showIfHeadersPresent = data.showIfHeadersPresent.map(m => new HeaderMatch(m));
    }
  }
}
