import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'crs-slider-checkbox',
  templateUrl: './slider-checkbox.component.html',
  styleUrls: ['./slider-checkbox.component.scss'],
})
export class SliderCheckboxComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() control: UntypedFormControl;
  @Input() isDisabled: boolean = false; // Added isDisabled input

  constructor() {}

  ngOnInit(): void {
    if (!this.formGroup && !this.control) {
      throw new Error(
        'You must provide either formGroup and controlName, or control.'
      );
    }

    if (this.isDisabled && this.formGroup) {
      this.formGroup.get(this.controlName)?.disable();
    } else if (this.isDisabled && this.control) {
      this.control.disable();
    }
  }

  switchClicked(event) {
    if (this.isDisabled) {
      event.preventDefault();
      return;
    }

    let statusValue = event.target.checked;
    if (this.formGroup) {
      this.formGroup.patchValue({
        [this.controlName]: JSON.parse(statusValue),
      });
    } else if (this.control) {
      this.control.setValue(JSON.parse(statusValue));
    }
  }
}
