import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { TransactionTableComponent } from './transaction-table';
import { Subject } from 'rxjs';
import { BacoTransactionStore } from '../baco-transaction.store';
import { BacoCodedBy } from 'src/app/baco/enums';
import { DateService, SessionService } from 'src/app/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BacoBankAccountDto } from 'src/app/baco/interfaces';

@Component({
  selector: 'crs-transaction-page',
  templateUrl: './transaction-page.component.html',
  styleUrls: ['./transaction-page.component.scss'],
  providers: [],
})
export class TransactionPageComponent implements OnInit, OnDestroy {
  @ViewChild('transactionTable', { read: TransactionTableComponent })
  public transactionTable: TransactionTableComponent;
  public BacoCodedBy = BacoCodedBy;

  public isLedgerAndBankFeedFeaturesEnabled: boolean;
  public search = new FormControl();
  public startDate = new FormControl();
  public endDate = new FormControl();
  public bankAccounts$ = this.bacoTransactionStore.bankAccounts$;
  public selectedBank$ = this.bacoTransactionStore.selectedBank$;

  public selectedFilters = {
    codedBy: [],
    locked: [],
  };

  private get gridApi() {
    return this.transactionTable.gridApi;
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private bacoTransactionStore: BacoTransactionStore,
    private dateService: DateService,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService
  ) {}

  public ngOnInit(): void {
    this.isLedgerAndBankFeedFeaturesEnabled =
      this.sessionService.featureFlags.ledger &&
      this.sessionService.featureFlags.bankFeed;

    this.dateRangeSubscription();
    this.startDateSubscription();
    this.endDateSubscription();

    this.searchListening();
  }

  private searchListening(): void {
    this.search.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((search: string) => {
        this.gridApi.setGridOption('quickFilterText', search);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public filteredTransactions$ = this.bacoTransactionStore.transactions$.pipe(
    filter((transactions) => transactions.state === 'SUCCESS'),
    map((transactions) => this.getFilteredTransactions(transactions.data))
  );

  public isFilterSelected(key: 'codedBy' | 'locked', value: any): boolean {
    return this.selectedFilters[key].includes(value);
  }

  public toggleFilter(key: 'codedBy' | 'locked', value: any) {
    const filterValues = this.selectedFilters[key];

    if (filterValues.includes(value)) {
      this.selectedFilters[key] = filterValues.filter((v) => v !== value);
    } else {
      this.selectedFilters[key].push(value);
    }

    this.updateGridData();
  }

  public isLockFiltered(): boolean {
    return (
      this.isFilterSelected('locked', true) ||
      this.isFilterSelected('locked', false)
    );
  }

  public checkActiveBankAccounts = (bankAccounts: BacoBankAccountDto[]) => {
    return (
      !!bankAccounts &&
      bankAccounts.length > 0 &&
      bankAccounts?.some((account) => !account.isHidden)
    );
  };

  isTransactionFiltered(): boolean {
    return (
      this.isFilterSelected('codedBy', BacoCodedBy.User) ||
      this.isFilterSelected('codedBy', BacoCodedBy.Rule) ||
      this.isFilterSelected('codedBy', null)
    );
  }

  private getFilteredTransactions(transactions: any[]): any[] {
    // If no filters are selected, return all transactions.
    if (
      Object.values(this.selectedFilters).every(
        (filterArray) => filterArray.length === 0
      )
    ) {
      return transactions;
    }

    return transactions.filter((transaction) => {
      for (let filterKey in this.selectedFilters) {
        if (
          this.selectedFilters[filterKey].length > 0 &&
          !this.selectedFilters[filterKey].includes(transaction[filterKey])
        ) {
          return false;
        }
      }
      return true;
    });
  }

  private updateGridData() {
    if (this.transactionTable && this.filteredTransactions$) {
      this.filteredTransactions$.subscribe((transactions) => {
        this.transactionTable.updateGridData(transactions);
      });
    }
  }

  private dateRangeSubscription(): void {
    this.bacoTransactionStore.dateRange$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(({ startDate, endDate }) => {
        if (startDate) {
          this.startDate.patchValue(startDate);
        }

        if (endDate) {
          this.endDate.patchValue(endDate);
        }
      });
  }

  private startDateSubscription(): void {
    this.startDate.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((startDate: Date) => {
        if (!startDate || this.startDate.invalid) {
          return;
        }

        if (startDate > this.endDate.value) {
          this.endDate.patchValue(startDate);
        }

        const startDateString = this.dateService.toString(
          startDate,
          'yyyy-MM-dd'
        );
        const queryParams: Params = {
          startDate: startDateString,
        };

        this.updateQueryParamsWithoutReloading(queryParams);
      });
  }

  private endDateSubscription(): void {
    this.endDate.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((endDate: Date) => {
        if (!endDate || this.endDate.invalid) {
          return;
        }

        const endDateString = this.dateService.toString(endDate, 'yyyy-MM-dd');
        const queryParams: Params = {
          endDate: endDateString,
        };

        this.updateQueryParamsWithoutReloading(queryParams);
      });
  }

  private updateQueryParamsWithoutReloading(queryParams: Params): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }
}
