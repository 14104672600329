<div [formGroup]="formGroup">

  <strong>Custom Word Template</strong>

  <div class="card">
    <div class="card-body">

      <div *ngIf="isAdd || !formGroup.get('hasWordFile').value">

        <h4> 1) <small>Download the Word Master Template</small></h4>
        Download the master <a [routerLink]="[]" (click)="downloadMaster()">Report Template</a> as a starting point for your custom template.

        <div class="spacer-md"></div>

        <h4> 2) <small>Upload your Custom Word Template</small></h4>
        <crs-file-upload folder="5" (filesUploaded)="onFileUpload($event)" [acceptedFiles]="['.doc', '.docx']"></crs-file-upload>
        <div class="spacer-md"></div>

      </div>

      <div *ngIf="!isAdd && formGroup.get('hasWordFile').value">

        <button type="button" class="btn btn-outline-primary" (click)="downloadTemplate()" [promiseBtn]="busy.template" *ngIf="!formGroup.get('uploadedFile').value">
          <i class="fas fa-download" aria-hidden="true"></i>
          Download Existing Template
        </button>

        <div class="spacer-md"></div>

        <p>Click below to upload a new template</p>
        <crs-file-upload folder="5" (filesUploaded)="onFileUpload($event)" [acceptedFiles]="['.doc', '.docx']"></crs-file-upload>
        <div class="spacer-md"></div>

      </div>

    </div>
  </div>

  <strong>Word Rendering Options</strong>

  <div class="card">
    <div class="card-body">

      <div class="row">
        <div class="col-form-sm">

          <div class="form-group">
            <label class="control-label">No of Columns (Portrait)</label>
            <input type="number" step="1" min="2" max="20" class="form-control" placeholder="Default" formControlName="portraitColumnWidth" />
            <small class="form-text text-muted">
              The number of columns to span content such as headers and paragraphs on a normal portrait page.
            </small>
          </div>

          <div class="form-group">
            <label class="control-label">No of Columns (Landscape)</label>
            <input type="number" step="1" min="2" max="30" class="form-control" placeholder="Default" formControlName="landscapeColumnWidth" />
            <small class="form-text text-muted">
              The number of columns to span content such as headers and paragraphs on a normal landscape page.
            </small>
          </div>

          <div class="form-group">
            <label class="control-label">Margin Row Height</label>
            <input type="number" step="1" min="1" max="50" class="form-control" placeholder="Default" formControlName="marginRowHeight" />
            <small class="form-text text-muted">
              The point height for margin rows used in the report
            </small>
          </div>

          <div class="form-group">
            <label class="control-label">Margin Row Height - Totals</label>
            <input type="number" step="1" min="0" max="50" class="form-control" placeholder="Default" formControlName="marginTotalRowHeight" />
            <small class="form-text text-muted">
              The point height for margin rows that come after a total row
            </small>
          </div>

          <div class="form-group">
            <label class="control-label">Start Financial Indenting from Level</label>
            <input type="number" step="1" min="0" max="50" class="form-control" placeholder="Default" formControlName="startFinancialIndentingFromLevel" />
            <small class="form-text text-muted">
              All financial rows that are this level or below will be indented.
              For example, if it is '3', a header, row or total at level 3 will be indented one tab, a level 4 will be indented two tabs etc.
              <br> Specify 0 if you do not want any indenting.
            </small>
          </div>

          <div class="form-group">
            <label class="control-label">Paragraph Indent Tabs</label>
            <input type="number" step="1" min="0" max="5" class="form-control" placeholder="Default" formControlName="paragraphIndentSize" />
            <small class="form-text text-muted">
              The number of Word indent tabs to use for paragraphs formatted as 'indented'
            </small>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
