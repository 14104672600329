<crs-busy *ngIf="loading"></crs-busy>

<div
  *ngIf="
    (assetsContext?.entity && file.isConsolidated) ||
    (!file.isConsolidated && !assetsContext?.getStarted)
  "
>
  <nav class="navbar navbar-expand navbar-light">
    <ul class="navbar-nav nav-tabs">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="./asset-groups">Assets</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="./reports">Reports</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="./pooling">Pooling</a>
      </li>
    </ul>

    <div class="nav-divider mx-5"></div>

    <form class="form-inline" novalidate [formGroup]="form">
      <!-- Entity Selector -->
      <div class="form-group mr-5" *ngIf="file.isConsolidated">
        <label class="control-label navbar-text mr-3">Entity</label>
        <ng-select
          bindLabel="legalName"
          formControlName="entity"
          style="min-width: 200px"
          [clearable]="false"
          [items]="file.entities"
        ></ng-select>
      </div>

      <!-- Year Selector -->
      <div class="form-group mr-4" *ngIf="!assetsContext?.getStarted">
        <label class="control-label navbar-text mr-3">Year</label>
        <ng-select
          bindLabel="year"
          formControlName="year"
          [clearable]="false"
          [items]="assetsContext?.yearsDescending"
        ></ng-select>
      </div>
    </form>

    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="./depreciation-years">
          <i aria-hidden="true" class="fas fa-calendar"></i>
          Manage Years
        </a>
      </li>
    </ul>
  </nav>
</div>

<div class="center" *ngIf="assetsContext?.error">
  <div class="spacer-md"></div>
  <!-- Error -->
  <img alt="Access Logo" src="./assets/images/logo.png" width="70" />

  <div class="spacer-md"></div>

  <p><strong>Error</strong></p>

  <div class="spacer-md"></div>

  <p>
    There was an unexpected error retrieving assets information. Please try
    again later.
  </p>
</div>

<div class="center" *ngIf="assetsContext?.getStarted && !assetsContext?.error">
  <div class="spacer-md"></div>
  <!-- No Entities -->
  <img alt="Access Logo" src="./assets/images/logo.png" width="70" />

  <div class="spacer-md"></div>

  <p><strong>Get Started</strong></p>

  <div class="spacer-md"></div>

  <p>
    Create a depreciation year to start recording assets for this
    {{ file.isConsolidated ? 'entity' : 'file' }}.
  </p>
  <button class="btn btn-secondary" (click)="addYear()">
    Add Depreciation Year
  </button>
</div>

<div class="center" *ngIf="!assetsContext?.entity && !assetsContext?.error">
  <!-- No Entities -->
  <img alt="Access Logo" src="./assets/images/logo.png" width="70" />

  <div class="spacer-md"></div>

  <p><strong>Setup Consolidated File</strong></p>

  <div class="spacer-md"></div>

  <p>
    You must add at least one entity to this file. Return to the datasets menu
    and add an entity before returning here.
  </p>
</div>

<div class="spacer-md"></div>

<router-outlet
  *ngIf="
    assetsContext?.entity && !assetsContext?.getStarted && !assetsContext?.error
  "
></router-outlet>
