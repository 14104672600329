import { ModalService } from 'src/app/core';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Division, DivisionModel, DivisionService } from '../';
import { ActiveFileService } from '../../../active-file.service';
import { MessageService } from '../../../../core';
import { getDefaultGridOptions, accountingRenderers } from '../../../../shared';
import { SourceDivision } from '../../../sourcedata/sourceDivisions';
import { GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.scss'],
})
export class DivisionComponent implements OnInit {
  @ViewChild('optionsCell', { static: true, read: TemplateRef })
  optionsCell!: TemplateRef<SourceDivision>;

  @Input() id: string;
  @Input() params: any = { clientId: null };

  isAdd: boolean;
  objectTitle = 'Division';

  busy = {
    load: null,
    submit: null,
    delete: null,
  };
  error: string = null;

  form = this.formBuilder.group({
    fileId: [null],
    code: ['', [Validators.required, Validators.maxLength(64)]],
    name: ['', [Validators.required, Validators.maxLength(256)]],
    sourceDivisions: [[]],
  });
  sourceDivisions: SourceDivision[] = [];

  gridOptions: GridOptions;
  gridApi: GridApi;
  renderers = accountingRenderers;

  isCollapsed = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private activeFileService: ActiveFileService,
    private divisionService: DivisionService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.isAdd = this.id === 'add';
    this.configureGridOptions();
    this.getDivision();
  }

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      columnDefs: [
        {
          field: 'code',
          headerName: 'Code',
          minWidth: 100,
          width: 100,
        },
        {
          field: 'name',
          headerName: 'Name',
          minWidth: 200,
          width: 200,
        },
        {
          field: 'source',
          headerName: 'Source',
          cellRenderer: 'sourceRenderer',
          minWidth: 200,
          width: 200,
        },
        {
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCell },
        },
      ],
      components: accountingRenderers,
    };
  }

  submit() {
    if (this.showFromSourceForm) {
      this.showError(
        'Please add or cancel the adding of a source division first.'
      );
      return;
    }

    var division = this.form.value as Division;
    division.sourceDivisions = this.sourceDivisions;
    var model = new DivisionModel(division);
    this.error = null;

    if (this.isAdd) {
      model.fileId = this.activeFileService.file.id;
      this.busy.submit = this.divisionService.post(model).subscribe({
        next: () => this.activeModal.close(),
        error: (err) => this.showError(err),
      });
    } else {
      model.id = this.id;
      this.busy.submit = this.divisionService.put(model).subscribe({
        next: () => this.activeModal.close(),
        error: (err) => this.showError(err),
      });
    }
  }

  getDivision() {
    if (!this.isAdd) {
      this.busy.load = this.divisionService.get(this.id).subscribe({
        next: (data) => {
          this.form.patchValue(data);
          this.sourceDivisions = data.sourceDivisions;
        },
        error: (err) => this.showError(err),
      });
    }
  }

  onGridReady(param) {
    this.gridApi = param.api;
  }

  showFromSourceForm = false;

  addSourceDivision(sourceDivision) {
    console.log('completed!', sourceDivision);
    if (sourceDivision) {
      this.sourceDivisions.push(sourceDivision);
    }
    this.gridApi.setGridOption('rowData', this.sourceDivisions);
    this.showFromSourceForm = false;
  }

  removeSourceDivision(sourceDivision) {
    const index = this.sourceDivisions.indexOf(sourceDivision);
    if (index > -1) this.sourceDivisions.splice(index, 1);
    this.gridApi.setGridOption('rowData', this.sourceDivisions);
  }

  delete() {
    this.modalService.confirmation(
      'This action cannot be undone. Are you sure you want to delete this division?',
      () => this.performDelete()
    );
  }

  private performDelete() {
    this.busy.delete = this.divisionService.delete(this.id).subscribe({
      next: () => this.activeModal.close(),
      error: (err) => this.showError(err),
    });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  close() {
    this.activeModal.dismiss();
  }
}
