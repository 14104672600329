import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  templateUrl: './transaction-type-render.component.html',
  styleUrls: [ './transaction-type-render.component.scss' ]
})
export class TransactionTypeRenderComponent implements ICellRendererAngularComp {

  value: string;
  currentClass: string = '';

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: any): boolean {
    this.currentClass = params.value ? params.value.toLowerCase() : '';
    this.value = params.value;
    return true;
  }

}
