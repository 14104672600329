import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, of, merge } from 'rxjs';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  finalize,
} from 'rxjs/operators';

import { SourceDivision } from '../sourceDivision';
import { SourceService } from '../../sources/';
import { DivisionService } from '../../../setup/';
import { ActiveFileService } from '../../../active-file.service';

@Component({
  selector: 'crs-source-division-create',
  templateUrl: './source-division-create.component.html',
})
export class SourceDivisionCreateComponent implements OnInit {
  @Input() selectSource = false;
  @Input() selectDivision = false;

  sources: Observable<any>;
  divisions: Observable<any>;
  sourceDivisions: Observable<SourceDivision[]>;

  loading = false;
  userInput = new Subject<string>();
  userInputValue: string = null;

  error: string = null;

  form = this._formBuilder.group({
    division: ['', Validators.required],
    source: ['', Validators.required],
    sourceDivision: [null],
  });

  @Output() completed = new EventEmitter<SourceDivision>();

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _activeFileService: ActiveFileService,
    private readonly _sourceService: SourceService,
    private readonly _divisionService: DivisionService
  ) {}

  ngOnInit() {
    this._activeFileService.stream.subscribe((file) =>
      this.getSources(file.id)
    );

    this.sourceDivisions = merge(
      this.form.controls['source'].valueChanges,
      this.userInput.pipe(
        debounceTime(300),
        tap((term) => (this.userInputValue = term))
      )
    ).pipe(
      distinctUntilChanged(),
      tap((term) => (this.loading = true)),
      switchMap((term) => {
        if (this.form.controls['source'].value) {
          return this._sourceService
            .getSourceDivisions$(
              this.form.controls['source'].value.id,
              this.userInputValue
            )
            .pipe(
              catchError((err) => {
                console.log(err);
                return of([] as SourceDivision[]);
              })
            );
        } else {
          return of([] as SourceDivision[]);
        }
      }),
      catchError((err) => {
        console.log(err);
        return of([] as SourceDivision[]);
      }),
      tap(() => (this.loading = false))
    );
  }

  getSources(fileId: string) {
    this.sources = this._sourceService.getAll$(fileId, true);
    this.divisions = this._divisionService.getAll(fileId);
  }

  submit() {
    if (!this.form.controls['sourceDivision'].value) {
      this.error = 'Please select a valid item from the list.';
      return;
    }
    let sourceDivision = new SourceDivision({
      source: this.selectSource ? this.form.controls['source'].value : null,
      division: this.selectDivision
        ? this.form.controls['division'].value
        : null,
      sourceDivisionId:
        this.form.controls['sourceDivision'].value.sourceDivisionId,
      code: this.form.controls['sourceDivision'].value.code,
      name: this.form.controls['sourceDivision'].value.name,
    });
    this.form.reset();
    this.completed.emit(sourceDivision);
  }

  cancel() {
    this.completed.emit(null);
  }
}
