<div class="row" *ngIf="route.children.length === 0">
  <div class="col-12">

    <crs-busy *ngIf="busy.taxForms"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div>
      <ag-grid-angular #agGrid
                       style="width: 100%;"
                       class="ag-theme-material"
                       [gridOptions]="gridOptions"
                       [rowData]="taxFormsObservable | async"
                       rowClass="selectable-row"
                       (rowClicked)="selectTaxForm($event)">
        <ag-grid-column headerName="Code" field="code" [width]="80"></ag-grid-column>
        <ag-grid-column headerName="Name" field="name"></ag-grid-column>
      </ag-grid-angular>
    </div>

  </div>
</div>

<div *ngIf="route.children.length !== 0">
  <router-outlet></router-outlet>
</div>
