import { Component } from '@angular/core';

@Component({
  selector: 'crs-baco',
  template: `
    <router-outlet></router-outlet>
  `,
  providers: [  ]
})

export class BacoComponent {

  constructor() {
  }
}
