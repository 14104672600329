import { LiveStockAllocation } from './livestockAccount';

export enum TradingAccountLocation {
  None = 0,
  Income = 1,
  OtherIncome = 2,
}

export class TradingAccount {
  id: string;
  fileId: string;
  code: string;
  name: string;
  location: TradingAccountLocation;
  isPrimaryProduction: boolean;
  isLivestockEnabled: boolean;
  livestockAllocations?: LiveStockAllocation[];

  constructor(data) {
    Object.assign(this, data);
  }
}
