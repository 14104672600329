import { UploadedFile } from './../../../../../shared/components/file-upload/file-upload.component';
export class ReportStyle {
  id: string;
  name: string;
  isDefault: boolean;
  rounding: number;
  percentRounding: number;
  hasCss: boolean;
  hasExcel: boolean;
  hasWord: boolean;
  cssStyle: CssStyle;
  excelStyle: ExcelStyle;
  wordStyle: WordStyle;
  cssFontUrls: ICssFontUrl[];

  constructor(data: any) {
    Object.assign(this, data);
    if (typeof data.rounding === 'string') this.rounding = parseInt(data.rounding, 10);
    if (typeof data.percentRounding === 'string') this.percentRounding = parseInt(data.percentRounding, 10);
  }
}

export interface ICssFontUrl {
  name: string;
  url: string;
}

export class ReportStyleModel {
  id: string;
  name: string;
  isDefault: boolean;
  rounding: number;
  percentRounding: number;
  hasCss: boolean;
  hasExcel: boolean;
  hasWord: boolean;
  cssStyle: CssStyle;
  excelStyle: ExcelStyle;
  wordStyle: WordStyle;
  cssFontUrls: ICssFontUrl[];

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class ExcelStyle {
  hasExcelFile: boolean;
  portraitColumnWidth: number;
  landscapeColumnWidth: number;
  marginRowHeight: number;
  marginTotalRowHeight: number;
  startFinancialIndentingFromLevel: number;
  paragraphIndentSize: number;
  uploadedFile: UploadedFile;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class WordStyle {
  hasWordFile: boolean;
  portraitColumnWidth: number;
  landscapeColumnWidth: number;
  marginRowHeight: number;
  marginTotalRowHeight: number;
  startFinancialIndentingFromLevel: number;
  paragraphIndentSize: number;
  uploadedFile: UploadedFile;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export class CssStyle {
  hasCssFile: boolean;
  css: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
