<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.reportingSuites"></crs-busy>
    <crs-form-error [error]="error"></crs-form-error>
    <div class="btn-menu pad-x">
      <button
        class="btn btn-secondary"
        type="button"
        (click)="addReportingSuite()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Reporting Suite
      </button>
    </div>
    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="reportingSuitesObservable | async"
        (rowClicked)="selectReportingSuite($event)"
      >
        <ag-grid-column
          cellRenderer="badgeRenderer"
          field="name"
          headerName="Name"
          [cellRendererParams]="{ badgeText: 'New', monthsToCheck: 3 }"
          [flex]="1.5"
        ></ag-grid-column>

        <ag-grid-column
          field="reportingFramework"
          headerName="Reporting Framework"
          [maxWidth]="300"
        ></ag-grid-column>

        <ag-grid-column
          field="applicationDate"
          headerName="Application Date"
          type="dateColumn"
          [maxWidth]="200"
        ></ag-grid-column>

        <ag-grid-column
          field="description"
          headerName="Description"
          [flex]="3"
        ></ag-grid-column>

        <ag-grid-column
          field="isMaster"
          headerName="Master"
          type="booleanColumn"
          [cellClass]="['boolean-cell', 'centered', 'text-success']"
          [maxWidth]="100"
          [minWidth]="100"
        ></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="spacer-lg"></div>
