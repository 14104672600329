import { LedgerSide } from './../../../../ledger/accounts/ledgerSide';
import { IElement } from '../element';
import { ReportElementTypeEnum } from '../../../enums';

export enum PlaceholderType {
    FinancialTable = 0,
    Notes = 2,
    Contents = 3,
    AccountNote = 21,
    CashReconciliation = 31,
    CashflowReconciliation = 32
}

export class ReportPlaceholder implements IElement {
  elementType = ReportElementTypeEnum.PlaceHolder;
  placeholderType: PlaceholderType;
  identifier: string;
  title: string;
  addGrandTotal: boolean;
  grandTotalLedgerSide: LedgerSide;
  isNew = true;

  constructor(data?: Partial<ReportPlaceholder>) {
    if (data) Object.assign(this, data);
  }
}
