import { AssetReportDepreciationRecord } from '../asset-report-depreciation-record';

export class  AssetReportDetailRow extends AssetReportDepreciationRecord {

  constructor(data: any) {
    super(data);
  }
}


