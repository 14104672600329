export class ESignatureRecipientModel {
  fullName: string;
  sigName: string;
  email: string;
}

export class ReportsHavingSignatures {
  reportPageMasterId: number;
  reportPageCategory: number;
  eSignatureRecipientModel: ESignatureRecipientModel[] = [];
}

export class RecipientCategory {
  recipientCategory: number;

  static CLIENTS_CATEGORY: number = 1;
  static PARTNERS_CATEGORY: number = 2;
  static AUDITORS_CATEGORY: number = 3;
  static OTHERS_CATEGORY: number = 4;
}
