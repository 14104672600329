<!-- Title -->
<td class="financial-title">
</td>

<!--Note -->
<td class="financial-note">
  <div class="financial-cell">
    {{element.noteText}}
  </div>
</td>

<!-- Columns -->
<td class="financial-value" *ngFor="let header of element.columnHeaders">
  <div class="financial-cell">
     <span *ngIf="header.headerText">{{header.headerText}}<br /></span>
    {{header.periodText}}<br />
    {{header.signText}}
  </div>
</td>
