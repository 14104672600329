import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, Subscription, EMPTY } from 'rxjs';
import { tap, exhaustMap, catchError, finalize } from 'rxjs/operators';

import { AccountService, Account, HeaderAccount, AccountTypeMode } from '../';
import { Classification } from '../..';

@Component({
  selector: 'crs-account-header',
  templateUrl: './account-header.component.html',
})
export class AccountHeaderComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() set params(value: Account | { parent: Account; sortOrder: number }) {
    if (value instanceof Account) {
      this._parent = value;
    } else {
      this._parent = value.parent;
      this._sortOrder = value.sortOrder;
    }
  }

  private _parent: Account;
  get parent() {
    return this._parent;
  }

  private _sortOrder: number;
  get sortOrder() {
    return this._sortOrder;
  }

  isAdd: boolean;

  objectTitle = 'Header';

  busy = {
    load: null,
    submit: null,
    apply: null,
  };
  error: string = null;

  form = this.formBuilder.group({
    accountName: ['', [Validators.required, Validators.maxLength(256)]],
    sourceClassification: [Classification.Other, Validators.required],
    accountType: [null, Validators.required],
    accountTypeMode: [AccountTypeMode.Inherited, Validators.required],
    description: [null],
    openingBalanceAccountId: [null],
  });

  accountTypeModes = AccountTypeMode;
  classifications = Classification;
  submitButtonStream = new Subject<void>();
  subscriptions: Subscription[] = [];
  openingBalanceAccounts: Account[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.isAdd = this.id === 'add';
    this.getOpeningBalanceAccounts();
    this.getAccount();
    this.configureSubmit();
  }

  getOpeningBalanceAccounts() {
    if (this._parent?.children?.length > 0)
      this.openingBalanceAccounts.push(...this._parent.children);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  configureSubmit() {
    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.submitObservable())
        )
        .subscribe((id) => {
          this.activeModal.close(id);
        })
    );
  }

  submit() {
    this.submitButtonStream.next();
  }

  submitObservable(): Observable<any> {
    const account = new HeaderAccount(this.form.value);
    account.id = this.id;

    const submit = this.isAdd
      ? this.accountService.postHeader(
          account.getCreateModel(this.parent),
          this.sortOrder
        )
      : this.accountService.putHeader(account.getUpdateModel());

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return submit.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  getAccount() {
    if (this.isAdd) {
      this.form.patchValue({
        parent: this.parent,
        sourceClassification: this.parent.classification,
        accountType: this.parent.accountType,
      });
      return;
    }

    this.busy.load = this.accountService.getHeaderAccount(this.id).subscribe(
      (data) => {
        this._parent = data.parent;
        this.form.patchValue(data);
        this.configureControlSubscriptions();
      },
      (err) => this.showError(err)
    );
  }

  configureControlSubscriptions() {
    // Account Type Mode to 'Inherit' will grab parent's account type
    this.form.get('accountTypeMode').valueChanges.subscribe((a) => {
      if (a === AccountTypeMode.Inherited && this.parent) {
        this.form.patchValue({ accountType: this.parent.accountType });
      }
    });
  }

  showError(error) {
    this.error = error;
  }

  close() {
    this.activeModal.dismiss();
  }
}
