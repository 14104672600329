import { Alignment } from '../../report-header/report-header';
import { TableRowType } from '../enums';
import { ColumnType } from '../../../../reportTemplates';
import { ElementSizeMultiplier, NumberFormat, TableElement } from '../../../../enums';
import { HeaderStyle } from '../../financial/header-style';

export interface IToolsState {
  textBold?: boolean;
  textUnderline?: boolean;
  textItalic?: boolean;
  alignment?: Alignment;

  selectedElementType?: TableElement;
  selectedRowType?: TableRowType;
  selectedColumnType?: ColumnType;
  selectedCellType?: ColumnType;

  rowLevel?: number;
  rowStyle?: HeaderStyle;
  rowSizeX?: ElementSizeMultiplier;
  enableAutoColumnHeader?: boolean;
  allowAutoColumnHeader?: boolean;

  columnFormatting?: NumberFormat;
  columnRounding?: number;
  columnSizeX?: ElementSizeMultiplier;

  cellFormatting?: NumberFormat;
  cellRounding?: number;
}
