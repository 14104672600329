import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';

import { getDefaultGridOptions, accountingRenderers } from 'src/app/shared';
import { MessageService } from 'src/app/core';
import { FirmService } from '../firm.service';

@Component({
  selector: 'crs-firms',
  templateUrl: './firms.component.html',
  styleUrls: ['./firms.component.scss']
})
export class FirmsComponent implements OnInit {

  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    firms: false
  };
  firmsObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;

  constructor(private readonly _firmService: FirmService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService) { }

  ngOnInit() {
    this.getOffices();
  }

  getOffices() {
    this.busy.firms = true;
    this.firmsObservable = this._firmService.getAll().pipe(finalize(() => this.busy.firms = false), catchError(err => {
      this.showError(err);
      return of([]);
    }));
  }

  addFirm() {
    this._router.navigate(['./add'], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectFirm(param) {
    this._router.navigate(['./' + param.data.id], { relativeTo: this._route });
  }

}
