<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item active" aria-current="page">Notes</li>
  </ol>
</nav>

<ul class="nav nav-tabs">
  <li class="nav-item">
    <a routerLink="reporting-suites" routerLinkActive="active" class="nav-link">Reporting Suites</a>
  </li>
  <li class="nav-item">
    <a routerLink="policies" routerLinkActive="active" class="nav-link">Policies</a>
  </li>
  <li class="nav-item">
    <a routerLink="disclosure-templates" routerLinkActive="active" class="nav-link">Disclosures</a>
  </li>
</ul>

<div class="spacer-md"></div>

<router-outlet></router-outlet>
