import { Component } from '@angular/core';
import { MessageService } from 'src/app/core/messages/message.service';
import { ModalService } from 'src/app/core/modals/modal.service';
import { SelectCoaComponent } from './select-coa/select-coa.component';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'crs-admin-accounts',
  templateUrl: './admin-accounts.component.html',
  styleUrls: ['./admin-accounts.component.scss'],
})
export class AdminAccountsComponent {
  error: string = null;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private messageService: MessageService
  ) {}

  selectCOA() {
    this.modalService
      .openModal(SelectCoaComponent, null, null, {
        windowClass: 'activate-modal',
      })
      .then(
        () => {
          this.messageService.success(
            'Chart of Accounts activated successfully'
          );

          this.reloadCurrentRoute();
        },
        () => true
      );
  }

  private reloadCurrentRoute() {
    const currentRoute = this.router.url;
    const navigationExtras: NavigationExtras = { skipLocationChange: true };

    this.router.navigateByUrl('/', navigationExtras).then(() => {
      this.router.navigateByUrl(currentRoute);
    });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error, true);
  }
}
