<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.tradingAccounts"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-no-bottom">
      <button
        class="btn btn-secondary"
        title="Create Trading Account"
        type="button"
        (click)="addTradingAccount()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Trading Account
      </button>
    </div>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="tradingAccountsObservable | async"
        (rowClicked)="selectTradingAccount($event)"
      >
        <ag-grid-column field="code" headerName="Code"></ag-grid-column>

        <ag-grid-column field="name" headerName="Name"></ag-grid-column>

        <ag-grid-column
          field="location"
          headerName="Location"
          type="enumColumn"
          [cellRendererParams]="{ enum: locations }"
        ></ag-grid-column>

        <ag-grid-column
          headerName="Types"
          [cellRenderer]="typesCellRenderer"
          [maxWidth]="300"
          [minWidth]="200"
        ></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>
