<div class="modal-header">
  <h4 class="modal-title">Activate chart of accounts</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form novalidate [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <p><strong>Select a chart of account source</strong></p>

    <p>Once activated, the chart of account source cannot be changed</p>

    <div>
      <div class="btn-menu" [ngBusy]="[busy.load]">
        <button
          class="btn btn-card"
          type="button"
          *ngFor="let type of sourceTypes"
          [class.active]="form.controls['sourceType']?.value?.id == type.id"
          (click)="selectChartType(type)"
        >
          <div class="d-inline-block position-relative">
            <img height="50" src="{{ type.icon }}" [alt]="type.name" />
            <br />
            <p>{{ type.name }}</p>
          </div>
        </button>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!form.controls['sourceType']?.value"
      [promiseBtn]="busy.submit"
    >
      Activate
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
  </div>
</form>
