import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TeamUser, TeamUserRole } from '../';

export class TeamUserComponentParams {
  teamUser: TeamUser;
  hideUser: boolean;
  hideTeam: boolean;
  excludeIds: any[] = [];

  constructor(teamUser: TeamUser, hideUser: boolean, hideTeam: boolean, excludeIds: any[] = []) {
    this.teamUser = teamUser;
    this.hideUser = hideUser;
    this.hideTeam = hideTeam;
    this.excludeIds = excludeIds;
  }
}

@Component({
  selector: 'crs-team-user',
  templateUrl: './team-user.component.html'
})
export class TeamUserComponent implements OnInit {

  form = this._formBuilder.group({
    user: [null, Validators.required],
    team: [null, Validators.required],
    role: [0, Validators.required],
    isPartner: [false],
    isManager: [false]
  });

  private _teamUser: TeamUser;

  @Input() set params(params: TeamUserComponentParams) {

    this._teamUser = params.teamUser;
    if (params.teamUser.user) this.readonlyUser = true;
    if (params.teamUser.team) this.readonlyTeam = true;
    this.hideTeam = params.hideTeam;
    this.hideUser = params.hideUser;
    this.excludeIds = params.excludeIds;
    this.form.patchValue(params.teamUser);
  }

  get team(): TeamUser {
    const teamUser = this.form.value as TeamUser;
    if (this._teamUser) teamUser.id = this._teamUser.id;
    return teamUser;
  }

  hideUser = false;
  readonlyUser = false;
  hideTeam = false;
  readonlyTeam = false;
  excludeIds = [];
  roles = TeamUserRole;

  busy = {
    load: null
  }
  error: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  submit() {
    this.activeModal.close(this.team);
  }

  test() {
    console.log(this.form);
  }

}
