import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of, empty } from 'rxjs';
import { map, exhaustMap, finalize, catchError, tap } from 'rxjs/operators';

import { File, FileService } from '../';
import { ActiveFileService } from '../../active-file.service';

@Component({
  selector: 'crs-file-delete',
  templateUrl: './file-delete.component.html',
})
export class FileDeleteComponent implements OnInit {
  form = this._formBuilder.group({
    passPhrase: [null, [Validators.required, passPhraseValidator(this)]],
  });

  file: File;
  requiredPhrase: string;
  busy = { submit: null };
  submitButtonStream = new Subject<void>();
  error: string = null;

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    public readonly activeModal: NgbActiveModal,
    private readonly _activeFileService: ActiveFileService,
    private readonly _fileService: FileService
  ) {}

  ngOnInit() {
    this.file = this._activeFileService.file;
    this.configureSubmit();
  }

  configureSubmit() {
    this.submitButtonStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submitObservable())
      )
      .subscribe((client) => {
        this.activeModal.close(client);
      });
  }

  submit() {
    this.submitButtonStream.next();
  }

  submitObservable(): Observable<any> {
    let observable = this._fileService.delete$(
      this.file.id,
      this.form.get('passPhrase').value
    );

    var loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return empty();
      }),
      finalize(() => loadingStream.complete())
    );
  }

  showError(error) {
    this.error = error;
  }

  cancel() {
    this.activeModal.dismiss();
  }
}

function passPhraseValidator(component: FileDeleteComponent): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value !== undefined &&
      (!component.file || control.value !== component.file.name)
    ) {
      return { passPhrase: true };
    }
    return null;
  };
}
