<crs-report-editable-element [index]="index" [reportContent]="reportContent" [reportMetaData]="reportMetaData" [editable]="true">

  <div viewer [style.height.px]="element.height"></div>

  <div editor>
    <div class="form-group">
      <label class="control-label">Height <small>(in Pixels)</small></label>
      <input type="number" class="form-control" [formControl]="control" />
    </div>

  </div>

</crs-report-editable-element>


