import { Classification } from '../classifications';
import { Source } from '../../sourcedata/sources/source';
import { Division, TradingAccount } from '../../setup';

export enum HasEntriesStatusEnum {
  HasEntries = 'Has entries',
  NoEntries = 'No entries',
}

export const hasEntriesStatuses = [
  HasEntriesStatusEnum.HasEntries,
  HasEntriesStatusEnum.NoEntries,
];

export interface SourceAccountToUpsert {
  accountName?: string;
  accountNo?: string;
  classification?: Classification;
  divisionId?: string;
  inactive?: boolean;
  sourceId?: string;
  taxCode?: string;
  tradingAccountId?: string;
}

export class SourceAccount {
  id: string;
  accountNo: string;
  accountName: string;
  classification: Classification;
  classificationToolTipText?: string;
  source: Source;
  division: Division;
  taxCode: string;
  tradingAccount: TradingAccount;
  hasEntries: boolean;
  hasEntriesText?: string;
  inactive: boolean;
  inactiveText?: string;

  get displayName(): string {
    return this.accountNo
      ? `${this.accountNo} ${this.accountName}`
      : this.accountName;
  }

  constructor(data) {
    Object.assign(this, data);

    if (data.source) {
      this.source = new Source(data.source);
    }

    if (data.division) {
      this.division = new Division(data.division);
    }

    if (data.taxCode) {
      this.taxCode = data.taxCode;
    }

    if (data.tradingAccount) {
      this.tradingAccount = new TradingAccount(data.tradingAccount);
    }

    this.classificationToolTipText = this.classification
      ? Classification[this.classification]
      : '';

    this.hasEntriesText = this.hasEntries
      ? HasEntriesStatusEnum.HasEntries
      : HasEntriesStatusEnum.NoEntries;

    this.inactiveText = this.inactive ? 'Inactive' : 'Active';
  }
}

export class SourceAccountModel {
  accountName: string;
  accountNo: string;
  classification: Classification;
  divisionId: string;
  inactive: boolean;
  sourceId: string;
  taxCode: string;
  tradingAccountId: string;

  constructor(data) {
    if (data.sourceAccount) {
      this.accountName = data.sourceAccount.accountName;
      this.accountNo = data.sourceAccount.accountNo;
      this.classification = data.sourceAccount.classification;
      this.sourceId = data.sourceAccount?.source?.id;
    }

    if (data.division) {
      this.divisionId = data.division.id;
    }

    if (data.taxCode) {
      this.taxCode = data.taxCode;
    }

    if (data.tradingAccount) {
      this.tradingAccountId = data.tradingAccount.id;
    }

    this.inactive = data.inactive;
  }
}
