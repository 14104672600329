<div class="border p-2 bg-light" *ngIf="!reportContent.output" [style.height.px]="50">
  <i class="fas fa-bars" aria-hidden="true"></i>
  Table of Contents Placeholder
</div>

<div *ngIf="reportContent.output">
  <ng-container *ngFor="let paragraph of element.contents">
    <crs-report-paragraph [element]="paragraph" [reportContent]="reportContent"></crs-report-paragraph>
  </ng-container>
</div>


