export enum TableActionEnum {
  textUnderline = 0,
  textBold = 1,
  textItalic = 3,
  alignment = 4,

  selectedElementType,

  selectedTable,
  rowLevel,
  rowStyle,
  selectedRowType,
  enableAutoColumnHeader,
  allowAutoColumnHeader,

  selectedColumnType,
  columnFormatting,
  columnRounding,
  columnSizeX,

  selectedCellType,
  cellFormatting,
  cellRounding

}
