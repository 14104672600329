import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TrusteeTypes } from 'src/app/accounting/reports/enums/trustee-types';

@Component({
  selector: 'crs-trustee-form',
  templateUrl: './trustee-form.component.html',
  styleUrls: ['./trustee-form.component.scss']
})
export class TrusteeFormComponent implements OnInit {

  trusteeTypes = TrusteeTypes;
  corporateTrusteeType = 1;
  subscriptions: Subscription[] = [];
  @Input('formGroup') formGroup: UntypedFormGroup;
    
  constructor() { }

  ngOnInit(): void {    
  }

  ngAfterViewInit() {
    this.updateCompanyNameIfTrusteeTypeChanged();
  }

  private updateCompanyNameIfTrusteeTypeChanged() {
      const companyNameFormControl = this.formGroup.get('companyName');
      this.formGroup.get('trusteeTypeId').valueChanges.subscribe(trusteeTypeId => 
      {
        if(trusteeTypeId != this.corporateTrusteeType)
        {
          companyNameFormControl.setValue('');
        }          
      });
  }
}
