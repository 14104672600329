import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { BacoTransactionCommentManageService } from '.';
import { IBacoTransactionComment } from '../../../../../interfaces';
import { ApiService } from '../../../../../../core';

@Injectable()
export class BacoTransactionCommentService {

  private readonly base: string = 'api/baco/comments';

  constructor(private readonly _apiService: ApiService,
              private readonly _commentStateManager: BacoTransactionCommentManageService) {
  }

  public getById(commentId: string): Observable<IBacoTransactionComment> {
    return this._apiService.get<IBacoTransactionComment>(`${this.base}/${commentId}`);
  }

  public create(message: string): Observable<IBacoTransactionComment> {
    const tempComment: IBacoTransactionComment = {
      id: "temp-comment",
      createdDate: new Date(),
      message: message,
      userInfo: null
    };

    this._commentStateManager.createdComment(tempComment)

    return this._apiService.post<string>(this.base, { transactionId: this._commentStateManager.commentTransactionId, message })
      .pipe(
        switchMap((commentId) => this.getById(commentId)),
        tap((comment) => {
          this._commentStateManager.updatedComment(tempComment, comment)
        }));
  }

  public update(comment: IBacoTransactionComment, message: string): Observable<IBacoTransactionComment> {
    return this._apiService.put<string>(`${this.base}/${comment.id}`, { message: message })
      .pipe(switchMap((commentId) => this.getById(commentId)),
        tap((_comment) => this._commentStateManager.updatedComment()));
  }

  public delete(comment: IBacoTransactionComment): Observable<boolean> {
    const removedIndex = this._commentStateManager.removedComment(comment)
    return this._apiService.delete<boolean>(`${this.base}/${comment.id}`)
      .pipe(catchError(err => {
        this._commentStateManager.insertComment(comment, removedIndex);
        throw err;
      }));
  }

}
