import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BACO_DATE_FORMAT } from '../baco.tokens';

const INTERVALS = {
  year: 31536000,
  month: 2592000,
  week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
};
const ONLY_AGO = ['day', 'hour', 'minute', 'second'];

@Pipe({
  name: 'commentDateAgo',
  pure: false,
})
export class CommentDateAgoPipe implements PipeTransform {
  constructor(
    private readonly _datePipe: DatePipe,
    @Inject(BACO_DATE_FORMAT) private dateFormat: string
  ) {}

  transform(value: string): string {
    if (value) {
      // Parse the date string and ensure it is interpreted as UTC
      const utcDate = new Date(value);
      // Adjust to the local timezone
      const localDate = new Date(
        utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
      );
      const seconds = Math.floor((+new Date() - +localDate) / 1000);

      if (seconds < 0) {
        return null;
      }

      if (seconds < 29) {
        return 'Just now';
      }

      let counter;
      for (const interval in INTERVALS) {
        counter = Math.floor(seconds / INTERVALS[interval]);
        if (counter > 0) {
          if ((counter === 1 || counter === 2) && ONLY_AGO.includes(interval)) {
            return this.getIntervalAgo(counter, interval);
          } else {
            return `${this.getIntervalAgo(
              counter,
              interval
            )} | ${this._datePipe.transform(localDate, this.dateFormat)}`;
          }
        }
      }
    }
    return null;
  }

  private getIntervalAgo(counter: number, interval: string): string {
    if (counter === 1) {
      return `${counter} ${interval} ago`;
    } else {
      return `${counter} ${interval}s ago`;
    }
  }
}
