import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { getDefaultGridOptions } from 'src/app/shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'crs-add-suite-variant-modal',
  templateUrl: './add-suite-variant-modal.component.html'
})
export class AddSuiteVariantModalComponent implements OnInit, OnDestroy {

  @Input() params: {title: string, variants: [], type: 'policy' | 'disclosure'} = {
    title: null,
    variants: null,
    type: null
  };

  gridOptions = this.getGridOptions();
  subscriptions: Subscription[] = [];

  search = new UntypedFormControl();
  error: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.subscriptions.push(
    this.search.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(term => this.gridOptions.api.setQuickFilter(term))
    )
    .subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  private getGridOptions() {
    const options = getDefaultGridOptions();
    options.rowSelection = 'multiple';
    options.rowMultiSelectWithClick = true;
    return options;
  }

  select() {
    this.error = null;
    const datasets = this.gridOptions.api.getSelectedNodes().map(n => n.data);
    if (!datasets.length) {
      this.error = 'You must select at least one ' + this.params.title;
    } else {
      this.activeModal.close(datasets);
    }

  }

  cancel() {
    this.activeModal.dismiss();
  }

}
