import { Component, OnInit, Input, ViewEncapsulation, ViewChild, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, map, tap, switchMap, catchError, startWith } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

import { UserService } from '../user.service';
import { User } from '../user';
import { PagedResponse } from '../../../core';

@Component({
  selector: 'crs-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class UserSelectComponent implements OnInit, ControlValueAccessor {

  private _user: User;

  @Input() set user(value: User) {
    if (value != null) this.writeValue(value.id);
  }

  get user(): User {
    return this._user;
  }

  @Input() filterByPartner = false;
  @Input() filterByManager = false;
  @Input() includeInactive = false;
  @Input() readonly = false;
  @Input() clearable = true;
  @Input() excludeIds = [];
  @Input() filterByTrustedAdvisor = false;

  @ViewChild(NgSelectComponent, { static: true }) private valueAccessor: ControlValueAccessor;

  usersObservable: Observable<User[]>;
  loading = false;
  selectedStream: Subject<any> = new Subject<User>();
  userInput = new Subject<string>();

  constructor(private userService: UserService) { }

  private searchUsers(search): Observable<PagedResponse<User>> {
    return this.userService.find(search, this.filterByPartner, this.filterByManager, this.filterByTrustedAdvisor, this.includeInactive, 1, 100);
  }

  writeValue(value: any | any[]): void {
    this._user = value;
    // Sometimes this hasn't been set prior to writing value, possible bug in ng-select
    (this.valueAccessor as NgSelectComponent).bindLabel = 'displayName';
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  private configureStream() {

    this.usersObservable = this.userInput.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.loading = true),
      switchMap(term => this.searchUsers(term).pipe(
          map(response => response.records.filter(record=>!this.excludeIds.includes(record.id))),
          catchError(error => {
            console.log(error);
            return of([this.user]);
          }), // empty list on error
          tap(() => this.loading = false)
        )
      )
    );

  }

  ngOnInit() {
    this.configureStream();
  }

}
