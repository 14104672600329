import { AssetsImportComponent } from './../../assets-import/assets-import.component';
import { AllocateAssetsToPoolComponent } from './../../asset-pooling/allocate-assets-to-pool/allocate-assets-to-pool.component';
import { ModalService } from './../../../../core/modals/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetService } from '../asset.service';
import {
  switchMap,
  tap,
  catchError,
  map,
  filter,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Subscription, of, Subject, EMPTY, merge } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { AssetsContext } from '../../assets-context';
import { AssetsContextService } from '../../assets-context.service';
import { Asset } from '../asset';
import { getDefaultGridOptions } from 'src/app/shared';
import { MoveAssetsToNewGroupComponent } from '../../asset-groups/move-assets-to-new-group/move-assets-to-new-group.component';
import { GridOptions, GridApi } from 'ag-grid-community';

@Component({
  selector: 'crs-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit, OnDestroy {
  busy = {
    loading: false,
  };

  assetsContext: AssetsContext;

  groupId: number;
  assets: Asset[];

  selectedAssets: Asset[] = [];
  showAll: boolean;
  notPooledAssets: boolean;

  search = new UntypedFormControl();
  error: string = null;
  subscriptions: Subscription[] = [];
  refreshAssets$ = new Subject<void>();

  gridOptions: GridOptions;
  gridApi: GridApi;

  constructor(
    private readonly _assetsContextService: AssetsContextService,
    private readonly _assetService: AssetService,
    public readonly route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _modalService: ModalService
  ) {}

  ngOnInit() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      onGridReady: (event) => (this.gridApi = event.api),
      onSelectionChanged: (c) => {
        this.selectedAssets = c.api.getSelectedRows();
      },
      rowSelection: {
        mode: 'multiRow',
        enableClickSelection: true,
      },
      columnDefs: [
        {
          field: 'code',
          headerName: 'Code',
          width: 50,
        },
        {
          field: 'name',
          headerName: 'Name',
          type: 'hyperlinkColumn',
          flex: 3,
          minWidth: 150,
        },
        {
          field: 'identifier',
          headerName: 'Identifier',
          width: 70,
        },
        {
          field: 'cost',
          headerName: 'Cost',
          type: 'dollarColumn',
        },
        {
          field: 'acquisitionDate',
          headerName: 'Purchase Date',
          type: 'dateColumn',
          maxWidth: 180,
        },
      ],
    };

    // Group Id
    this.route.paramMap.subscribe((p) => {
      this.groupId = parseInt(p.get('groupId'), 10);
      this.refreshAssets$.next();
    });

    // Assets
    this.subscriptions.push(
      merge(
        this._assetsContextService.contextValid$,
        this.refreshAssets$.pipe(
          map(() => this._assetsContextService.currentContext),
          filter((c) => !!c && !!c.entity && !!c.file)
        )
      )
        .pipe(
          tap((c) => (this.assetsContext = c)),
          switchMap((c) => this.getAssets())
        )
        .subscribe()
    );

    // Accounts Filter/Search
    this.subscriptions.push(
      this.search.valueChanges
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((search) => {
          this.gridApi.setGridOption('quickFilterText', search);
        })
    );

    this.gridOptions.onSelectionChanged = (c) => {
      this.selectedAssets = c.api.getSelectedRows();
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private getAssets() {
    if (this.groupId == null) return EMPTY;
    this.busy.loading = true;
    return this._assetService
      .getAllForGroup(
        this.groupId,
        this.assetsContext.file.id,
        this.assetsContext.entity.id,
        this.showAll ? null : this.assetsContext.year.year,
        this.notPooledAssets
      )
      .pipe(
        catchError((err) => {
          this.busy.loading = false;
          this.showError(err);
          return of([]);
        }),
        tap((result) => {
          this.busy.loading = false;
          this.assets = result;
        })
      );
  }

  addAsset() {
    this._router.navigate(['./', 'add'], { relativeTo: this.route });
  }

  moveSelectedAssets() {
    this._modalService
      .openModal(MoveAssetsToNewGroupComponent, null, this.selectedAssets)
      .then(
        () => {
          this.refresh();
        },
        () => true
      );
  }

  allocateSelectedAssetsToPool() {
    this._modalService
      .openModal(AllocateAssetsToPoolComponent, null, this.selectedAssets, {
        size: 'lg',
      })
      .then(
        () => {
          this.refresh();
        },
        () => true
      );
  }

  importAssets() {
    this._modalService.openModal(AssetsImportComponent).then(
      () => {
        this._assetsContextService.refreshYears(); // refreshes groups
        this.refresh();
      },
      () => true
    );
  }

  refresh() {
    this.refreshAssets$.next();
  }

  private showError(err) {
    this.error = err;
  }
}
