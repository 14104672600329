import { Component } from '@angular/core';
import { User } from '../../../firm/users/user';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-user-renderer',
  template: `
    <span class="user-icon" style="outline:0" *ngIf="user" [routerLink]="[]">
      <img
        class="user-icon-img"
        ngxGravatar
        title="{{ user.displayName }}"
        [email]="user.icon.email"
        [size]="user.icon.size"
        [src]="user.icon.source"
      />
      <span>{{ user.displayName }}</span>
    </span>
  `,
})
export class UserRendererComponent implements ICellRendererAngularComp {
  public user: User;

  agInit(params: any): void {
    this.user = params.value;
  }

  refresh(): boolean {
    return false;
  }
}
