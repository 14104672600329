<div class="modal-header">
  <h4 class="modal-title">Entity</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form novalidate [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <h2 class="form-group-title">Entity Details</h2>

    <hr />

    <div class="form-group">
      <label class="control-label">Client Group</label>
      <ng-select
        bindLabel="name"
        bindValue="id"
        formControlName="clientId"
        [clearable]="false"
        [items]="availableClients$ | async"
      />
    </div>
    <hr />

    <div class="form-group">
      <label class="control-label">Code</label>
      <input
        class="form-control"
        formControlName="code"
        type="text"
        title="Code"
      />
    </div>

    <div class="form-group" *ngIf="isClientRefPresent">
      <label class="control-label">Client Reference</label>
      <input
        class="form-control"
        formControlName="clientRef"
        type="text"
        [readOnly]="true"
        title="Client Reference"
      />
    </div>

    <div class="form-group required">
      <label class="control-label">Client Name (legal name)</label>
      <input
        class="form-control"
        formControlName="legalName"
        type="text"
        title="Client Name (legal name)"
      />
    </div>

    <div class="form-group">
      <label class="control-label">Trading Name</label>
      <input
        class="form-control"
        formControlName="tradingName"
        type="text"
        title="Trading Name"
      />
    </div>

    <div class="form-group required">
      <label class="control-label">Entity Type</label>
      <ng-select
        bindLabel="name"
        bindValue="id"
        formControlName="entityTypeId"
        [clearable]="false"
        [items]="entityTypes"
      />
    </div>

    <div
      class="form-group required"
      *ngIf="form.get('entityTypeId').value === entityType.Association"
    >
      <label class="control-label">Association State</label>
      <ng-select
        bindLabel="name"
        bindValue="value"
        formControlName="associationStateId"
        [items]="associationStates | enumToArray"
      ></ng-select>
    </div>

    <div class="form-group">
      <label class="control-label">ABN</label>
      <input
        class="form-control"
        formControlName="abn"
        type="text"
        [crsBusinessNumber]="'abn'"
        [value]="form.get('abn').value | businessNumber: 'abn'"
        title="ABN"
      />
    </div>

    <div class="form-group">
      <label class="control-label">ABN Division</label>
      <input
        class="form-control"
        formControlName="abnDivision"
        type="text"
        title="ABN Division"
      />
    </div>

    <div
      class="form-group"
      *ngIf="
        form.get('entityTypeId').value === entityType.Company ||
        form.get('entityTypeId').value === entityType.Association ||
        form.get('entityTypeId').value === entityType.Unspecified
      "
    >
      <label class="control-label">ACN</label>
      <input
        class="form-control"
        formControlName="acn"
        type="text"
        [crsBusinessNumber]="'acn'"
        [value]="form.get('acn').value | businessNumber: 'acn'"
        title="ACN"
      />
    </div>

    <div class="form-group">
      <label class="control-label">Description</label>
      <textarea
        class="form-control"
        formControlName="description"
        rows="3"
        title="Description"
      ></textarea>
    </div>

    <div class="form-group">
      <crs-check-box formControlName="notForProfit">
        Not For Profit
      </crs-check-box>
    </div>

    <div class="form-group">
      <crs-check-box formControlName="isRegisteredForGst">
        Registered for GST
      </crs-check-box>
    </div>

    <div class="form-group">
      <crs-check-box formControlName="reportingEntity">
        Reporting Entity
      </crs-check-box>
    </div>

    <div class="form-group">
      <crs-check-box formControlName="isConsolidatedGroup">
        Consolidated Group
      </crs-check-box>
    </div>

    <div class="form-group">
      <crs-check-box formControlName="isLivestockEnabled">
        Livestock entity
      </crs-check-box>
    </div>

    <div class="spacer-md"></div>

    <div class="card card-pop mb-4">
      <h2
        aria-controls="collapseReportOptions"
        class="card-header card-header-collapsible form-group-title"
        data-bs-target="#collapseReportOptions"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="reportOptionsCollapsed"
        (click)="reportOptionsCollapsed = !reportOptionsCollapsed"
      >
        Report Options
        <i
          aria-hidden="true"
          class="fas fa-caret-down"
          *ngIf="reportOptionsCollapsed"
        ></i>
        <i
          aria-hidden="true"
          class="fas fa-caret-up"
          *ngIf="!reportOptionsCollapsed"
        ></i>
      </h2>
      <div
        aria-labelledby="collapseReportOptions"
        class="collapse show"
        id="collapseReportOptions"
      >
        <div class="card-body">
          <crs-address-form
            title="Registered Office"
            twoAddressLines="true"
            [formGroup]="form.controls['registeredOffice']"
          />

          <crs-address-form
            title="Principal Place of Business"
            twoAddressLines="true"
            [copyFrom]="form.controls['registeredOffice'].value"
            [formGroup]="form.controls['principalPlaceOfBusiness']"
          />

          <div class="form-group">
            <label class="control-label">Principal Activities</label>
            <textarea
              class="form-control"
              formControlName="principalActivities"
              rows="3"
              title="Principal Activities"
            ></textarea>
          </div>

          <crs-trustee-form
            *ngIf="
              form.get('entityTypeId').value === entityType.UnitTrust ||
              form.get('entityTypeId').value === entityType.DiscretionaryTrust
            "
            [formGroup]="form.controls['trusteeDetail']"
          />

          <div class="form-group">
            <label class="control-label">Responsible Persons</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="responsible"
              [items]="pluralities | enumToArray"
            />
          </div>

          <div class="jumbotron p-3 mt-3 mb-3">
            <h3>Responsible Person Details</h3>

            <div class="btn-toolbar" role="toolbar">
              <div class="btn-menu">
                <button
                  class="btn btn-secondary"
                  type="button"
                  (click)="onClickAddResponsiblePerson()"
                >
                  <i aria-hidden="true" class="fas fa-plus inset"></i>
                  Add Responsible Person
                </button>
              </div>
            </div>
            <div>
              <ag-grid-angular
                #agGrid
                class="ag-theme-material"
                style="width: 100%"
                [class.d-none]="!responsiblePersons?.length"
                [gridOptions]="gridOptions"
                [rowData]="responsiblePersons"
              >
              </ag-grid-angular>
            </div>

            <ng-template #optionsCell let-row>
              <div class="btn-mini-menu">
                <button
                  class="btn-sm fas-btn-ghost"
                  title="Remove Responsible Person"
                  type="button"
                  (click)="onClickRemoveResponsiblePerson(row)"
                >
                  <i aria-hidden="true" class="fas fa-trash"></i>
                </button>
              </div>
            </ng-template>
          </div>

          <div class="form-group">
            <label class="control-label">Owners</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="owners"
              [items]="pluralities | enumToArray"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card card-pop mb-4">
      <h2
        aria-controls="collapseManagement"
        class="card-header card-header-collapsible form-group-title"
        data-bs-target="#collapseManagement"
        data-bs-toggle="collapse"
        [attr.aria-expanded]="managementCollapsed"
        (click)="managementCollapsed = !managementCollapsed"
      >
        Management
        <i
          aria-hidden="true"
          class="fas fa-caret-down"
          *ngIf="managementCollapsed"
        ></i>
        <i
          aria-hidden="true"
          class="fas fa-caret-up"
          *ngIf="!managementCollapsed"
        ></i>
      </h2>
      <div
        aria-labelledby="collapseManagement"
        class="collapse show"
        id="collapseManagement"
      >
        <div class="card-body">
          <div class="form-group required">
            <label class="control-label">Practice</label>
            <crs-office-select
              formControlName="office"
              [clearable]="false"
              [readonly]="true"
            />
          </div>

          <div class="form-group">
            <label class="control-label">Team</label>
            <crs-team-select formControlName="team" />
          </div>

          <div class="form-group required">
            <label class="control-label">Partner</label>
            <crs-user-select
              formControlName="partner"
              [clearable]="false"
              [filterByPartner]="true"
            />
          </div>

          <div class="form-group">
            <label class="control-label">Manager</label>
            <crs-user-select
              formControlName="manager"
              [filterByManager]="true"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="isAdd">
      <crs-check-box [formControl]="createFileControl">
        Create a new file for this entity
      </crs-check-box>
    </div>

    <crs-form-error [error]="error" />
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-delete"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      [promiseBtn]="busy.delete"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      (click)="activeModal.close('Close click')"
    >
      Cancel
    </button>
  </div>
</form>
