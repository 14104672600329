import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, empty, EMPTY } from 'rxjs';
import { tap, catchError, exhaustMap, finalize, switchMap } from 'rxjs/operators';

import { ReportPageTypeService, ReportPageCategory, ReportPageType } from '../../reportPages';
import { ModalService, MessageService } from '../../../../core';
import { getDefaultGridOptions, accountingRenderers, ProgressBarService } from '../../../../shared';

enum Interaction {
  Move,
  Add
}

class InteractionData {
  interaction: Interaction;
  moveId: number;
  moveIndex: number;
}

@Component({
  selector: 'crs-report-page-types',
  templateUrl: './report-page-types.component.html'
})
export class ReportPageTypesComponent implements OnInit {

  collapsed: boolean;
  error: string;

  busy = {
    reportPageTypes: false,
    interactionPending: false
  };

  gridOptions: any = getDefaultGridOptions();
  renderers = accountingRenderers;
  sortActive: boolean;
  filterActive: boolean;
  gridApi;

  reportPageTypes: ReportPageType[] = [];
  reportPageCategories: ReportPageCategory;
  pendingReportPageTypes: ReportPageType[] = [];

  interactionStream = new Subject<InteractionData>();

  constructor(private reportPageTypeService: ReportPageTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private messageService: MessageService,
    private progressBar: ProgressBarService) { }

  ngOnInit() {
    this.configureGridOptions();
    this.configureInteractionStream();
    this.getReportPageTypes();
  }

  configureInteractionStream() {
    this.interactionStream.pipe(
      tap(() => { this.error = null; }),
      exhaustMap(param => this.handleInteraction(param))
    )
    .subscribe();
  }

  handleInteraction(param: InteractionData): Observable<any> {

    let observable: Observable<any> = EMPTY;

    if (param.interaction === Interaction.Add) {
      this.router.navigate(['./add'], { relativeTo: this.route });
      return EMPTY;
    }

    if (param.interaction === Interaction.Move) {
      observable = this.move(param);
    }

    this.busy.interactionPending = true;
    return observable.pipe(
      catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => this.busy.interactionPending = false));
  }

  private move(param: InteractionData) {
    const progress = this.progressBar.ref();
    progress.start();
    return this.reportPageTypeService.reorder(param.moveId, param.moveIndex + 1)
      .pipe(
        switchMap(() => this.reportPageTypeService.getAllForAdmin()),
        tap(data => {
          this.reportPageTypes = data;
          this.gridApi.setRowData(this.reportPageTypes);
        }),
        tap(() => {
          this.reportPageTypes = this.pendingReportPageTypes;
          console.log('successfully moved row');
        }),
        catchError(err => {
          this.pendingReportPageTypes = this.reportPageTypes;
          this.gridApi.setRowData(this.pendingReportPageTypes);
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => progress.complete()));
  }

  add() {
    const param = new InteractionData();
    param.interaction = Interaction.Add;
    this.interactionStream.next(param);
  }

  edit(id) {
    this.router.navigate(id, { relativeTo: this.route });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  getReportPageTypes() {
    this.busy.reportPageTypes = true;
    this.reportPageTypeService.getAllForAdmin()
    .pipe(
      tap(() => this.busy.reportPageTypes = false),
      catchError(err => {
        this.showError(err);
        return of([]);
      }))
    .subscribe(result => {
      this.reportPageTypes = result;
    });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  // Grid

  configureGridOptions() {
    this.gridOptions.immutableData = true;
    this.gridOptions.animateRows = true;
    this.gridOptions.enableSorting = false;
    this.gridOptions.getRowNodeId = node => node.id;
    this.gridOptions.onRowDragMove = param => this.onRowDragMove(param);
    this.gridOptions.onRowDragEnd = param => this.onRowDragEnd(param);
    this.gridOptions.groupDefaultExpanded = -1;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Category',
      cellRendererParams: {
        suppressCount: true
      }
    };
    this.gridOptions.groupUseEntireRow = true;
    this.gridOptions.groupRowInnerRenderer = 'enumRenderer';
    this.gridOptions.groupRowRendererParams = { enum: ReportPageCategory };
    // this.gridOptions.defaultGroupSortComparator = (nodeA, nodeB) => {
    //   if (nodeA.key < nodeB.key) {
    //     return -1;
    //   } else if (nodeA.key > nodeB.key) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // };
    this.gridOptions.onGridReady = (param) => {
      this.gridApi = param.api;
    };
    this.gridOptions.onSortChanged = () => {
      const sortModel = this.gridApi.getSortModel();
      this.sortActive = sortModel && sortModel.length > 0;
      this.updateRowDragSuppression();
    };
    this.gridOptions.onFilterChanged = () => {
      this.filterActive = this.gridApi.isAnyFilterPresent();
      this.updateRowDragSuppression();
    };

  }

  onRowDragMove(event) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
  }

  onRowDragEnd(event) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
    const param = new InteractionData();
    param.interaction = Interaction.Move;
    param.moveId = event.node.data.id;
    param.moveIndex = event.overNode.childIndex;
    this.interactionStream.next(param);
  }

  executeUIMove(event) {
    const movingNode = event.node;
    const overNode = event.overNode;
    const rowNeedsToMove = movingNode !== overNode && overNode.data && movingNode.data.category === overNode.data.category;
    if (rowNeedsToMove) {
      const fromIndex = this.reportPageTypes.indexOf(movingNode.data);
      const toIndex = this.reportPageTypes.indexOf(overNode.data);
      this.pendingReportPageTypes = this.reportPageTypes.slice();
      moveInArray(this.pendingReportPageTypes, fromIndex, toIndex);
      event.api.setRowData(this.pendingReportPageTypes);
      event.api.clearFocusedCell();
    }
    function moveInArray(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }

  updateRowDragSuppression() {
    const suppressRowDrag = this.sortActive || this.filterActive;
    this.gridApi.setSuppressRowDrag(suppressRowDrag);
  }

}
