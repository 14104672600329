<div
  class="h-100 d-flex align-items-center"
  tabindex="0"
  *ngIf="currentState$ | async as currentState"
>
  <ng-container class="split-btn-container" *ngIf="currentState.isSplit">
    <button
      class="btn"
      [class]="currentState.canEdit ? 'split-btn' : 'split-btn disabled'"
      (click)="splitTransaction()"
    >
      Split transaction
    </button>
    <button
      class="btn-sm fas-btn-ghost"
      title="Consolidate transaction"
      type="button"
      *ngIf="currentState.canEdit"
      (click)="deleteSplitCoding()"
    >
      <i aria-hidden="true" class="fas fa-times"></i>
    </button>
  </ng-container>

  <div
    class="account-name"
    *ngIf="!currentState.isSplit"
    [title]="currentState.selectedAccount?.accountName"
  >
    {{ currentState.selectedAccount?.accountName }}
    <div
      class="empty-cell d-flex align-items-center"
      *ngIf="!currentState.selectedAccount?.accountName"
    >
      <div class="mr-5">Select Account</div>
      <i class="fa fa-chevron-down"></i>
    </div>
  </div>
</div>
