<div class="modal-header">
  <h4 class="modal-title">{{ isAdd ? 'Add' : 'Edit' }} Tax code</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="onClickClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="onValidSubmit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Tax code</label>
          <input
            class="form-control"
            formControlName="taxCode"
            type="text"
            [readonly]="!isCustom"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Description</label>
          <input
            class="form-control"
            formControlName="description"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Tax rate</label>
          <input class="form-control" formControlName="rate" type="text" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Effective date</label>
          <crs-date-picker
            formControlName="effective_Date"
            [clearable]="false"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Posting account</label>
          <ng-select
            bindValue="accountNo"
            formControlName="postingAccountNo"
            placeholder="Select Account"
            [items]="postingAccounts"
            [searchFn]="searchFn"
            [virtualScroll]="true"
          >
            <ng-template let-item="item" ng-label-tmp>
              {{ item.accountNo }} - {{ item.accountName }}
            </ng-template>
            <ng-template let-item="item" ng-option-tmp>
              <span class="label">
                {{ item.accountNo }} - {{ item.accountName }}
              </span>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">BAS Code</label>
          <ng-select
            bindValue="basCode"
            formControlName="basCode"
            placeholder="Select Code"
            [items]="masterBasCodes"
            [searchFn]="searchBasCodesFn"
            [virtualScroll]="true"
            (change)="onChangeBasCode($event)"
          >
            <ng-template let-item="item" ng-label-tmp>
              {{ item.basCode }} - {{ item.description }}
            </ng-template>
            <ng-template let-item="item" ng-option-tmp>
              <span class="label">
                {{ item.basCode }} - {{ item.description }}
              </span>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isCapitalAllowed">
      <div class="col-md-10 col-lg-8">
        <div class="form-group">
          <crs-check-box formControlName="isCapital">
            Is Capital
          </crs-check-box>
        </div>
      </div>
    </div>

    <crs-form-error [error]="error" />
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!form.valid"
      [promiseBtn]="busy.submit"
    >
      {{ isAdd ? 'Add Tax Code' : 'Save' }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="onClickClose()">
      Cancel
    </button>
  </div>
</form>
