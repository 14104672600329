<div class="modal-header">
  <h4 class="modal-title">Transfer transactions to datasets</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="onClickClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="onValidSubmit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">Transaction period start date</label>
          <crs-date-picker formControlName="startDate" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group required">
          <label class="control-label">Transaction period end date</label>
          <crs-date-picker
            formControlName="endDate"
            [min]="form.value.startDate"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div
          class="form-group required"
          [customErrorMessages]="CUSTOM_ERROR_MESSAGES"
        >
          <label class="control-label">Destination dataset</label>
          <crs-dataset-select
            formControlName="destinationDataset"
            [datasets]="destinationDatasets"
            [placeholder]="'Select dataset'"
            [readonly]="!form.value.startDate || !form.value.endDate"
          />
        </div>
      </div>
    </div>

    <hr />

    <h5>Select accounts</h5>
    <ag-grid-angular
      class="ag-theme-material"
      style="width: 100%"
      [gridOptions]="gridOptions"
      [rowData]="bankAccounts"
      (gridReady)="onGridReady($event)"
    />

    <crs-form-error [error]="error" />
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!form.valid || !hasBankAccountToBeTransferred()"
      [promiseBtn]="busy.submit"
    >
      Transfer transactions
    </button>
    <button class="btn btn-secondary" type="button" (click)="onClickClose()">
      Cancel
    </button>
  </div>
</form>
