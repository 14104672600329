import { Component, HostListener, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ReportViewerParams } from '../models';
import {
  ReportMetaData,
  ReportOutput,
  ReportPageOutput,
} from '../report-output';
import { SafeHtml } from '@angular/platform-browser';
import { ReportTemplateModel } from '../../reportTemplates';
import { GridOptions } from 'ag-grid-community';
import { ReportDocumentListItem } from '../../models';

@Component({
  template: '',
  styleUrls: ['./base-report-viewer.component.scss'],
})
export class BaseReportViewerComponent implements OnDestroy {
  @Input() set params(params: ReportViewerParams) {
    this.setData(params);
    this.refreshStyles();
    this.selectPageIndex(this.activePageIndex);
    this.initialiseMetaData();
  }

  reportDocumentId: string;
  reportTemplate: ReportTemplateModel;
  reportDocumentListItem: ReportDocumentListItem;
  restrictSaveDocument: boolean;
  readonly = false;
  restrictSave = false;
  report: ReportOutput;
  reportMetaData: ReportMetaData;
  activePageIndex = 0;
  page: ReportPageOutput | any;
  gridOptions: GridOptions;
  shouldUpdateCustomTableAutoTotal: boolean;
  isBankFeedReport: boolean;

  styles: SafeHtml;

  protected destroy: Subject<boolean> = new Subject<boolean>();

  public ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.selectPageIndex(this.activePageIndex - 1);
    }
    if (event.key === 'ArrowRight') {
      this.selectPageIndex(this.activePageIndex + 1);
    }
  }

  public refreshStyles() {
    const reportStyles = this.report.style;

    let style = '<style>';
    if (reportStyles.cssImports) style += reportStyles.cssImports + ' ';
    if (reportStyles.brandingCss) style += reportStyles.brandingCss + ' ';
    if (reportStyles.customCss) style += reportStyles.customCss + ' ';
    this.styles = style + '</style>';
  }

  public initialiseMetaData() {
    this.reportMetaData = new ReportMetaData();
    this.reportMetaData.isModified = false;
  }

  public selectPage(page: ReportPageOutput) {
    const index = this.report.pages.indexOf(page);
    this.selectPageIndex(index);
  }

  public selectPageIndex(index: number) {
    if (index < 0) {
      index = 0;
    }

    if (this.report && this.report.pages) {
      if (index > this.report.pages.length - 1) {
        index = this.report.pages.length - 1; // Limit index to length of pages array
      }
      this.page = this.report.pages[index];
    }

    this.activePageIndex = index;
  }

  protected setData(params: ReportViewerParams): void {
    this.report = params.report;
    this.restrictSave = params.restrictSave;
    this.reportTemplate = params.reportTemplate;
    this.reportDocumentId = params.reportDocumentId;
    this.reportDocumentListItem = params.reportDocumentListItem;
    this.restrictSaveDocument = params.restrictSaveDocument;
    this.gridOptions = params.gridOptions;
    this.shouldUpdateCustomTableAutoTotal =
      params.shouldUpdateCustomTableAutoTotal;
    this.isBankFeedReport = params.isBankFeedReport;
  }
}
