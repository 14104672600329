import { Classification, CurrentClassification } from '../classifications';

export class AccountUltraLight {
  id: string;
  accountNo: string;
  accountName: string;
  classification: Classification;
  classificationLabel: string;

  get displayName(): string {
    if (this.accountNo) return this.accountNo + ' ' + this.accountName;
    else return this.accountName;
  }

  constructor(data: any) {
    this.id = data.id;
    this.accountNo = data.accountNo;
    this.accountName = data.accountName;
    this.classification = data.classification ? parseInt(data.classification, 10) : null;
    if (data.classification) {
      if (data.currentClassification) {
        this.classificationLabel = CurrentClassification[data.currentClassification] + ' ' + Classification[data.classification];
      } else {
        this.classificationLabel = Classification[data.classification];
      }
    }
  }
}
