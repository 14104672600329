import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../core';
import { ESignatureRequestHistory } from './eSignature';

@Injectable({
  providedIn: 'root'
})
export class ESignatureService {
  readonly base: string = 'api/accounting/e-signature';

  constructor(private apiService: ApiService) { }

  getRequestHistories(fileId : string) {
    let url = this.base + '/requesthistory';
    url = this.apiService.addQuery(url, 'fileId', fileId);
    return this.apiService.get<ESignatureRequestHistory[]>(url)
      .pipe(map(d => d.map(s => new ESignatureRequestHistory(s))));
  }
}
