import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultValueAccessor } from 'src/app/shared/components/value-accessors/default-value-accessor';
import { ICssFontUrl } from '../../models';

@Component({
    selector: 'crs-css-font-urls-editor',
    templateUrl: './font-urls-editor.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CssFontUrlsEditorComponent),
        multi: true
    }]
})

export class CssFontUrlsEditorComponent extends DefaultValueAccessor<ICssFontUrl[]> {
    public newFontName: string;
    public newFontUrl: string;

    public delete(fontUrl: ICssFontUrl) {
        const index = this.value.indexOf(fontUrl);
        this.value.splice(index, 1);
    }

    public add() {
        if (!this.value) {
            this.value = [];
        }

        this.value.push({ name: this.newFontName, url: this.newFontUrl });

        this.value = [...this.value];
        this.newFontUrl = '';
        this.newFontName = '';
    }
}
