import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../core';
import { OfficeUser, OfficeUserModel } from './officeUser';

@Injectable({
  providedIn: 'root'
})
export class OfficeUserService {

  readonly base: string = 'api/officeusers';

  constructor(private readonly _apiService: ApiService) { }

  get(id: string) {
    return this._apiService.get<OfficeUser>(this.base + '/' + id).pipe(map(d => new OfficeUser(d)));
  }

  getAllForOffice(officeId: string) {
    return this._apiService.get<OfficeUser[]>(this.base + '/foroffice/' + officeId).pipe(map(l => l.map(d => new OfficeUser(d))));
  }

  post(office: OfficeUserModel) {
    return this._apiService.post<any>(this.base, office);
  }

  updateUsersForOffice(officeId: string, officeUsers: OfficeUserModel[]) {
    return this._apiService.post<any>(this.base + '/updateUsersForOffice/' + officeId, officeUsers);
  }

  put(office: OfficeUserModel) {
    return this._apiService.put(this.base + '/' + office.id, office);
  }

  delete(id: number) {
    return this._apiService.delete(this.base + '/' + id);
  }
}
