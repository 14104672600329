import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { tap, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { FeedClient } from '../../common';
import { BacoFeedCreateRequest } from '../../interfaces/baco-feed-create-request.interface';

@Component({
  selector: 'crs-feed-create',
  templateUrl: './feed-create.component.html',
  styleUrls: ['./feed-create.component.scss']
})
export class FeedCreateComponent implements OnInit, OnDestroy {

  submitButtonStream = new Subject();
  subscriptions: Subscription[] = [];
  busy = {
    loading: false,
    submit: null,
    delete: null
  };
  error: string = null;
  formValid: boolean = false;

  formGroup = this._formBuilder.group({
    feedName: ['', [Validators.required]],
    billingRef: [null]
  });

  constructor(public activeModal: NgbActiveModal,
    private readonly _feedClient: FeedClient,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router) {
  }

  ngOnInit() {
    this.formGroup.statusChanges.subscribe(val => {
      this.formValid = val == 'VALID';
    });
    this.configureSubmit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSubmit() {
    this.submitButtonStream.next();
  }

  configureSubmit() {
    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => this.error = null),
          exhaustMap(() => this.submitObservable())
        )
        .subscribe(result => {
          this._router.navigate(['/baco/feeds'], {
            queryParams: { feedId: result },
          });
        }));
  }

  submitObservable(): Observable<any> {
    let observable: Observable<any>;
    var formArr = this.formGroup.value;
    const formValue = formArr as BacoFeedCreateRequest;

    const loadingStream = new Subject();
    this.busy.submit = loadingStream.subscribe();

    observable = this._feedClient.createFeed(formValue);

    return observable.pipe(catchError(err => {
      this.showError(err);
      return EMPTY;
    }),
      finalize(() => loadingStream.complete()));

  }

  showError(error) {
    this.error = error;
  }
}
