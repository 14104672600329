import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PleaseSignModel } from '../electronicSignature';

@Component({
  selector: 'crs-please-sign',
  templateUrl: './please-sign.component.html',
  styleUrls: ['./please-sign.component.scss'],
})
export class PleaseSignComponent {
  @Input('formGroup') formGroup: UntypedFormGroup;
  @Input() copyFrom: PleaseSignModel;

  showClearButton(field) {
    return this.formGroup.controls[field].value;
  }

  onClickClearInput(field) {
    this.formGroup.controls[field].setValue('');
  }
}
