<div class="datepicker-content input-group">
  <span
    class="datepicker-input-wrapper form-control"
    [class.readonly]="readonly"
  >
    <input
      #input
      autocomplete="off"
      type="text"
      [(ngModel)]="value"
      [matDatepicker]="picker"
      [max]="max"
      [min]="min"
      [placeholder]="placeholder"
      [readonly]="readonly"
      (dateChange)="onDateChanged()"
      (input)="onInput($event.target.value)"
    />
    <span
      *ngIf="showClearButton()"
      class="clear"
      title="Clear"
      (click)="clearInput()"
    >
      &times;
    </span>
  </span>

  <div class="input-group-append">
    <button
      class="btn btn-outline-secondary py-0 px-1 datepicker-button"
      type="button"
      [disabled]="readonly"
    >
      <mat-datepicker-toggle
        matSuffix
        [disableRipple]="true"
        [disabled]="readonly"
        [for]="picker"
      >
        <i
          matDatepickerToggleIcon
          aria-hidden="true"
          class="fas fa-calendar-alt datepicker-toggle-icon"
        ></i>
      </mat-datepicker-toggle>
      <!-- panelClass="ag-custom-component-popup" is important for `ag-grid-angular` -->
      <mat-datepicker
        #picker
        panelClass="ag-custom-component-popup"
        [startAt]="getStartDate()"
      ></mat-datepicker>
    </button>
  </div>
</div>
