import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BacoBankAccountDto, Feed } from 'src/app/baco/interfaces';
import { FeedClient } from 'src/app/baco/common/clients/feed-client.service';

@Component({
  selector: 'crs-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss'],
})
export class BankTransferComponent implements OnInit {
  @Input() params: BacoBankAccountDto;

  busy = {
    load: null,
    submit: null,
  };
  feeds: Feed[];
  form = new FormGroup({
    feed: new FormControl<Feed>(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });
  error: string = null;

  constructor(
    private activeModal: NgbActiveModal,
    private feedCient: FeedClient
  ) {}

  ngOnInit(): void {
    this.getFeedCollections();
  }

  onClickClose() {
    this.activeModal.dismiss();
  }

  onValidSubmit() {
    this.activeModal.close(this.form.value.feed);
  }

  private getFeedCollections() {
    this.busy.load = this.feedCient.getFeeds().subscribe(
      (feeds) =>
        (this.feeds = feeds.filter(
          (feed) => feed.id !== this.params.bacoFeedId
        )),
      (error) => this.showError(error)
    );
  }

  showError(error: any) {
    this.error = error;
  }
}
