<nav aria-label="breadcrumb" *ngIf="!this.fileId">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li aria-current="page" class="breadcrumb-item active">
      Matching Rule Sets
    </li>
  </ol>
</nav>

<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.matchingRuleSets"></crs-busy>
    <crs-form-error [error]="error"></crs-form-error>
    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="matchingRuleSetsObservable | async"
      />
    </div>
  </div>
</div>
