import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Injector,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { MessageService, ModalService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BacoFeedStore } from '../baco-feed.store';
import { AddNewBankConnectionComponent } from './add-new-bank-connection';
import { AddNewBankConnectionWizardComponent } from './add-new-bank-connection-wizard';
import { BacoBankConnectionDto, Feed } from 'src/app/baco/interfaces';
import { BankConnectionTableComponent } from './bank-connection-table';
import { BacoTransactionStore } from '../baco-transaction.store';
import { FileFeedsFormComponent } from 'src/app/accounting/file-feeds/file-feeds-form/file-feeds-form.component';

@Component({
  selector: 'crs-bank-connection',
  templateUrl: './bank-connection.component.html',
  styleUrls: ['./bank-connection.component.scss'],
})
export class BankConnectionPageComponent implements OnInit, OnDestroy {
  search = new UntypedFormControl();

  private _destroy: Subject<boolean> = new Subject<boolean>();

  @ViewChild(BankConnectionTableComponent)
  private _bankConnectionTable: BankConnectionTableComponent;

  constructor(
    private _router: Router,
    private _modalService: ModalService,
    private _messageService: MessageService,
    private _route: ActivatedRoute,
    private readonly _feedStore: BacoFeedStore,
    private injector: Injector,
    private readonly _transactionStore: BacoTransactionStore
  ) {}

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  public onClickAddBankAccounts(): void {
    const feedId = this._feedStore.feed$?.getValue()?.data.id;
    const feedName = this._feedStore.feed$?.getValue()?.data.name;

    this._modalService
      .openModal(
        AddNewBankConnectionWizardComponent,
        null,
        { feedId, feedName },
        { windowClass: 'add-connection-modal', injector: this.injector }
      )
      .then((result: BacoBankConnectionDto) => {
        this._bankConnectionTable.addNewBankConnection(result);
        this._transactionStore.refreshBankAccounts();
      })
      .catch(() => true);
  }

  public onClickEditBankFeed(): void {
    const feed = this._feedStore.feed$?.getValue()?.data;

    this._modalService
      .openModal(
        FileFeedsFormComponent,
        null,
        { feedId: feed.id, feed },
        {
          size: 'md',
          windowClass: 'activate-modal',
        }
      )
      .then(
        () => {
          this._messageService.success('Bank feed updated successfully');
          this._feedStore.loadFeed(of(feed.id));
        },
        () => true
      );
  }

  getFeedValue(value: keyof Feed) {
    return this._feedStore.feed$.getValue()?.data[value];
  }
}
