import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { BacoAccountDto } from '../../../interfaces';
import { DefaultValueAccessor } from '../../../../shared/components/value-accessors';
import { Stateful } from 'src/app/baco/interfaces/stateful.interface';
import { BacoFeedStore, BacoTransactionStore } from '../../feed-detail';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'crs-transaction-account-select',
  templateUrl: './transaction-account-select.component.html',
  styleUrls: ['./transaction-account-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TransactionAccountSelectComponent),
      multi: true,
    },
  ],
})
export class TransactionAccountSelectComponent
  extends DefaultValueAccessor<string>
  implements AfterViewInit
{
  @Input() appendTo = 'body';
  @Input() readonly = false;
  @Input() clearable = false;
  @Input() placeholder = 'Select Account';
  @Input() showSplit = false;
  @Input() autofocus = false;
  @Output() splitSelected = new EventEmitter<void>();
  @Output() change = new EventEmitter<BacoAccountDto>();

  @ViewChild(NgSelectComponent, { static: false })
  protected valueAccessor: ControlValueAccessor;
  @ViewChild(NgSelectComponent, { static: false })
  protected ngSelect: NgSelectComponent;
  public displayByAccountCode$ = this._transactionStore.displayByAccountCode$;
  public opened$ = new Subject();

  public accounts$: Observable<Stateful<BacoAccountDto[]>> =
    this._feedStore.accounts$;
  public sortedAccounts$: Observable<BacoAccountDto[]>;
  private search$ = new BehaviorSubject<string>('');

  constructor(
    protected readonly _feedStore: BacoFeedStore,
    protected readonly _transactionStore: BacoTransactionStore
  ) {
    super();
  }

  ngOnInit(): void {
    this.accounts$ = this._feedStore.accounts$;

    this.sortedAccounts$ = combineLatest([
      this.accounts$,
      this.search$.pipe(debounceTime(300), startWith('')),
    ]).pipe(
      map(([statefulAccounts, searchTerm]) =>
        this.sortAndFilterAccounts(statefulAccounts.data || [], searchTerm)
      )
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.ngSelect && this.autofocus) {
        this.ngSelect.focus();
      }
    });
  }

  public open() {
    setTimeout(() => {
      if (this.ngSelect && !this.ngSelect.isOpen) {
        this.ngSelect.open();
      }
    });
  }

  public close() {
    if (this.ngSelect.isOpen) {
      this.ngSelect.close();
    }
  }

  changed(data: BacoAccountDto) {
    this.change.emit(data);
  }

  onSplitClicked() {
    this.splitSelected.emit();
  }

  onSearch(search): void {
    if (search.term === '') {
      this.search$.next('');
    }
  }

  searchAccountNameOrNumber = (
    searchTerm: string,
    item: BacoAccountDto
  ): boolean => {
    this.search$.next(searchTerm);
    return true; // Let ng-select know to display all options; filtering is handled by sortedAccounts$
  };

  private sortAndFilterAccounts(
    accounts: BacoAccountDto[],
    searchTerm: string
  ): BacoAccountDto[] {
    searchTerm = searchTerm.toLowerCase();

    if (!searchTerm) {
      return accounts;
    }

    return accounts
      .filter(
        (account) =>
          account.accountName?.toLowerCase().includes(searchTerm) ||
          account.accountNo?.toString().includes(searchTerm)
      )
      .sort((a, b) => {
        // Prioritize matches at the start of the string
        const nameStartsWithA = a.accountName
          ?.toLowerCase()
          .startsWith(searchTerm);
        const nameStartsWithB = b.accountName
          ?.toLowerCase()
          .startsWith(searchTerm);
        const numberStartsWithA = a.accountNo
          ?.toString()
          .startsWith(searchTerm);
        const numberStartsWithB = b.accountNo
          ?.toString()
          .startsWith(searchTerm);

        // Prioritize matches at the start of the string for both name and number
        if (nameStartsWithA && !nameStartsWithB) return -1;
        if (!nameStartsWithA && nameStartsWithB) return 1;
        if (numberStartsWithA && !numberStartsWithB) return -1;
        if (!numberStartsWithA && numberStartsWithB) return 1;

        // If both start with the search term or neither does, fall back to sorting by accountName then accountNo
        const nameCompare = a.accountName?.localeCompare(b.accountName);
        if (nameCompare !== 0) return nameCompare;

        return a.accountNo?.localeCompare(b.accountNo);
      });
  }
}
