import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { DivisionComponent } from '../division/division.component';
import { DivisionService } from '../';
import { ModalService, MessageService } from '../../../../core';
import { getDefaultGridOptions, accountingRenderers } from '../../../../shared';

@Component({
  selector: 'crs-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.scss'],
})
export class DivisionsComponent implements OnInit {
  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    divisions: false,
  };
  divisionsObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;

  constructor(
    private divisionService: DivisionService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.configureGridOptions();

    this.route.params.subscribe(() => {
      this.fileId = this.route.snapshot.parent.paramMap.get('id');
      this.getDivisions();
    });
  }

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      suppressCellFocus: true,
      columnDefs: [
        { field: 'code', headerName: 'Code' },
        { field: 'name', headerName: 'Name' },
      ],
      components: accountingRenderers,
    };
  }

  getDivisions() {
    this.busy.divisions = true;
    this.divisionsObservable = this.divisionService.getAll(this.fileId).pipe(
      tap(() => (this.busy.divisions = false)),
      catchError((err) => {
        this.showError(err);
        return of([]);
      })
    );
  }

  addDivision() {
    this.modalService.openModal(DivisionComponent, 'add').then(
      () => {
        this.getDivisions();
      },
      () => true
    );
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  selectDivision(param) {
    this.modalService
      .openModal(DivisionComponent, param.data.id)
      .then(() => {
        this.getDivisions();
      })
      .catch(() => true);
  }
}
