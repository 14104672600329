import { Component, Input } from '@angular/core';
import { BacoCodedBy } from '../../../enums';

@Component({
  selector: 'crs-transaction-state-icon',
  templateUrl: './transaction-state-icon.component.html',
  styleUrls: [ './transaction-state-icon.component.scss' ]
})
export class TransactionStateIconComponent {
  @Input('coded-by') codedBy: BacoCodedBy;
  public transactionCodedBy = BacoCodedBy;
}
