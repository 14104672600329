import {
  Component,
  forwardRef,
  Input,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  UntypedFormControl,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import { DateTime } from 'luxon';
import { DefaultValueAccessor } from '../value-accessors';
import { LuxonDateAdapter } from './luxon-date-adapter';

@Component({
  selector: 'crs-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent
  extends DefaultValueAccessor<DateTime>
  implements ControlValueAccessor, Validator
{
  @Input() placeholder = '';
  @Input() clearable = false;
  @Input() defaultDate: Date = new Date();
  @Input() min: Date = new Date(1970, 0, 1);
  @Input() max: Date = null;
  @Input() readonly = false;

  @ViewChild('input', { static: true })
  private _dateInput: ElementRef;

  private _prevInput: string;

  constructor(
    @Inject(DateAdapter) protected _dateAdapter: LuxonDateAdapter,
    @Inject(MAT_DATE_FORMATS) protected _formats: MatDateFormats
  ) {
    super();
    _dateAdapter.setValidFormats([
      'd M yy',
      'dd M yy',
      'd MM yy',
      'dd MM yy',
      'd MMM yy',
      'dd MMM yy',
      'd MMMM yy',
      'dd MMMM yy',
      'd M yyyy',
      'dd M yyyy',
      'd MM yyyy',
      'dd MM yyyy',
      'd MMM yyyy',
      'dd MMM yyyy',
      'd MMMM yyyy',
      'dd MMMM yyyy',
      'd-M yy',
      'dd-M-yy',
      'd-MM yy',
      'dd-MM-yy',
      'd-MMM-yy',
      'dd-MMM-yy',
      'd-MMMM-yy',
      'dd-MMMM-yy',
      'd-M-yyyy',
      'dd-M-yyyy',
      'd-MM-yyyy',
      'dd-MM-yyyy',
      'd-MMM-yyyy',
      'dd-MMM-yyyy',
      'd-MMMM-yyyy',
      'dd-MMMM-yyyy',
      'd/M/yy',
      'dd/M/yy',
      'd/MM/yy',
      'dd/MM/yy',
      'd/MMM/yy',
      'dd/MMM/yy',
      'd/MMMM/yy',
      'dd/MMMM/yy',
      'd/M/yyyy',
      'dd/M/yyyy',
      'd/MM/yyyy',
      'dd/MM/yyyy',
      'd/MMM/yyyy',
      'dd/MMM/yyyy',
      'd/MMMM/yyyy',
      'dd/MMMM/yyyy',
      'yyyy-MM-dd',
    ]);
  }

  protected toNgModel(value): Date {
    if (this.isEmpty()) {
      return null;
    }

    if (value && value.isValid) {
      return value.toJSDate();
    }

    return new Date(value);
  }

  protected fromNgModel(value): string {
    if (!value) return null;
    const date = this._dateAdapter.parse(value, this._formats.parse.dateInput);
    return date;
  }

  public onInput(newInput) {
    // if we switch between empty and not-valid value we need to trigger validation forcibly
    if ((!newInput && this._prevInput) || (newInput && !this._prevInput)) {
      this.onChange(this.value);
    }

    this._prevInput = newInput;
  }

  public validate(control: UntypedFormControl) {
    if (this.isEmpty()) {
      return null;
    }

    if (this.value && this.value < this.min) {
      return {
        mindate: {
          date: this._dateAdapter.format(
            this._dateAdapter.parse(this.min, this._formats.parse.dateInput),
            'dd MMM yyyy'
          ),
        },
      };
    }

    if (this.value && this.max && this.value > this.max) {
      return {
        maxdate: {
          date: this._dateAdapter.format(
            this._dateAdapter.parse(this.max, this._formats.parse.dateInput),
            'dd MMM yyyy'
          ),
        },
      };
    }

    if (this.value && this.value.isValid) {
      return null;
    }

    return {
      invalidDateFormat: true,
    };
  }

  public showClearButton(): boolean {
    return (
      !this.readonly && this.clearable && this._dateInput.nativeElement.value
    );
  }

  public clearInput() {
    this._dateInput.nativeElement.value = null;
    this.value = null;
  }

  public getStartDate() {
    return this.value || this.defaultDate || new Date();
  }

  // This method is used for inheritor components
  public onDateChanged() {}

  private isEmpty() {
    return !this.value && !this._dateInput.nativeElement.value;
  }
}
