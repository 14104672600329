export class SortHelpers {
  public static sortValueComparator(valueA: string, valueB: string) {
    if (valueA === null || valueA === undefined) {
      return -1;
    }
    if (valueB === null || valueB === undefined) {
      return 1;
    }

    // Extract the numeric part of the values
    const numberA = parseInt(valueA.replace(/^\D+/g, ''), 10);
    const numberB = parseInt(valueB.replace(/^\D+/g, ''), 10);

    // If numeric parts are equal, compare the alphabetic parts
    if (numberA === numberB) {
      // Extract the alphabetic suffixes from the values
      const suffixA = valueA.replace(/\d+/g, '');
      const suffixB = valueB.replace(/\d+/g, '');

      // Compare the suffixes alphabetically
      return suffixA.localeCompare(suffixB);
    }

    // Compare the numeric parts if they are not equal
    return numberA - numberB;
  }
}
