import { Account, SourceAccount, JournalType } from '..';
import { Division } from '../../setup';

export class AccountTransaction {
  account: Account;
  sourceAccount: SourceAccount;
  date: Date;
  journalId: string;
  journalType: JournalType;
  journalNo: string;
  sourceJournalId: string;
  journalMemo: string;
  lineMemo: string;
  memo: string;
  division: Division;
  balance: number;
  quantity: number;
  subTotal: number;

  get debit() {
    return this.balance >= 0 ? this.balance : 0;
  }
  get credit() {
    return this.balance < 0 ? -this.balance : 0;
  }

  constructor(data) {
    Object.assign(this, data);

    if (data.sourceAccount) {
      this.sourceAccount = new SourceAccount(data.sourceAccount);
    }

    if (data.division) {
      this.division = new Division(data.division);
    }
    this.lineMemo = data.memo;

    if (!this.memo) {
      this.memo = this.journalMemo;
    }
  }
}

export class AccountWithTransactions extends Account {
  transactions: AccountTransaction[];

  constructor(data) {
    super(data.account);

    if (data.transactions) {
      this.transactions = data.transactions.map(
        (transaction) => new AccountTransaction(transaction)
      );

      this.transactions.forEach((transaction) => {
        transaction.account = this;
      });
    }
  }
}
