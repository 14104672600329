import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, catchError, finalize } from 'rxjs/operators';

import { ModalService, MessageService } from 'src/app/core';
import { getDefaultGridOptions, accountingRenderers } from 'src/app/shared';
import { TaxFormService } from '../../ledger/taxForms/tax-form.service';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-dataset-tax-forms',
  templateUrl: './dataset-tax-forms.component.html',
  styleUrls: ['./dataset-tax-forms.component.scss'],
})
export class DatasetTaxFormsComponent implements OnInit {
  datasetId: string;
  collapsed: boolean;
  error: string;

  busy = {
    taxForms: false,
  };
  taxFormsObservable: Observable<any>;

  gridOptions: GridOptions;
  renderers = accountingRenderers;

  constructor(
    private taxFormService: TaxFormService,
    public route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      columnDefs: [
        {
          headerName: 'Code',
          field: 'code',
          width: 80,
        },
        {
          headerName: 'Name',
          field: 'name',
        },
      ],
      rowClass: 'selectable-row',
      onRowClicked: (event) => this.selectTaxForm(event),
    };

    this.route.params.subscribe(() => {
      this.datasetId = this.route.snapshot.parent.parent.paramMap.get('id');
      this.getTaxForms();
    });
  }

  getTaxForms() {
    this.busy.taxForms = true;
    this.taxFormsObservable = this.taxFormService.getAll().pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => (this.busy.taxForms = false))
    );
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  selectTaxForm(param) {
    this.router.navigate(['./', param.data.id], { relativeTo: this.route });
  }
}
