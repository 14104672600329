<div class="row">
  <div class="col">
    <ul class="nav nav-pills">
      <li class="nav-item" *ngFor="let tab of tabs">
        <ng-container
          *ngTemplateOutlet="
            tab.disabled ? disabledTab : enabledTab;
            context: { tabName: tab.name, link: tab.link }
          "
        ></ng-container>
      </li>
    </ul>
  </div>
  <div
    class="col d-flex justify-content-end"
    *ngIf="this.router.url.includes('tax-forms/')"
  >
    <div
      ngbTooltip="You must have a license for HandiTax Cloud in order to use the Tax Transfer function"
    >
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!connectedToIs"
        [promiseBtn]="isTransferInAction"
        (click)="onTaxTransfer()"
      >
        Tax Transfer
      </button>
    </div>
  </div>
</div>
<hr />

<ng-template #enabledTab let-link="link" let-tabName="tabName">
  <a class="nav-link" routerLinkActive="active" [routerLink]="link">
    {{ tabName }}
  </a>
</ng-template>
<ng-template #disabledTab let-tabName="tabName">
  <div class="nav-link">
    {{ tabName }}
  </div>
</ng-template>

<router-outlet></router-outlet>
