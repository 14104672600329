<div *ngIf="formGroup; else withoutFormGroup" [formGroup]="formGroup">
  <div class="in-line-switch adv-setting-checkbox" id="adv-setting-slider">
    <label class="switch" [class.disabled]="isDisabled">
      <input
        class="form-control checkbox"
        type="checkbox"
        [formControlName]="controlName"
        (click)="switchClicked($event)"
      />
      <span class="slider"></span>
    </label>
  </div>
</div>

<ng-template #withoutFormGroup>
  <div class="in-line-switch adv-setting-checkbox" id="adv-setting-slider">
    <label class="switch" [class.disabled]="isDisabled">
      <input
        class="form-control checkbox"
        type="checkbox"
        [formControl]="control"
        (click)="switchClicked($event)"
      />
      <span class="slider"></span>
    </label>
  </div>
</ng-template>
