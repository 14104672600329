import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBacoTransactionComment } from '../../../../../interfaces';

@Component({
  selector: 'crs-transaction-comment-history',
  templateUrl: './transaction-comment-history.component.html',
  styleUrls: [ './transaction-comment-history.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionCommentHistoryComponent {
  @Input() comments: IBacoTransactionComment[] = [];
}
