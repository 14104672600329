<div class="d-flex h-100">
  <div
    class="col-xl-3 col-lg-4 col-md-5 col-sm-6 p-0 overflow-auto"
    [ngClass]="{
      'd-none d-lg-block':
        livestockTradingAccounts?.length > 0 && route.children.length > 0
    }"
  >
    <div class="left-menu">
      <div>
        <crs-busy *ngIf="busy.tradingAccounts" />

        <div class="list-group-primary">
          <div class="d-flex add-trading-account-list-item">
            <div class="btn-menu d-inline-block mr-auto">
              <button
                class="btn btn-primary"
                title="New livestock trading account"
                type="button"
                (click)="onClickAddTradingAccount()"
              >
                <i aria-hidden="true" class="fas fa-plus inset"></i>
                New livestock trading account
              </button>
            </div>
          </div>

          <div
            class="list-group-item clickable"
            *ngFor="let tradingAccount of livestockTradingAccounts"
            [ngClass]="{
              active: tradingAccount.id == activeTradingAccountId
            }"
            (click)="onClickTradingAccount(tradingAccount.id)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>{{ tradingAccount.name }}</div>

              <div class="d-inline-block ml-auto">
                <div>
                  <i
                    aria-hidden="true"
                    class="fas fa-pencil-alt fas-btn-muted"
                    (click)="
                      onClickEditTradingAccount($event, tradingAccount.id)
                    "
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-xl-9 col-lg-8 col-md-7 col-sm-6 center container-grey-bg"
    *ngIf="
      busy.tradingAccounts === false && livestockTradingAccounts?.length === 0
    "
  >
    <p class="p-3">No livestock trading accounts yet</p>
  </div>

  <div
    class="col-xl-9 col-lg-8 col-sm-12 pl-0 pl-lg-3 container-grey-bg"
    *ngIf="
      busy.tradingAccounts === false &&
      livestockTradingAccounts?.length > 0 &&
      route.children.length > 0
    "
  >
    <router-outlet />
  </div>
</div>
