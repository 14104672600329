import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import {
  EnhancedConfirmation,
  MessageService,
  ModalService,
  SessionService,
} from 'src/app/core';
import { BacoTransactionStore } from '../../baco-transaction.store';
import { UploadTransactionComponent } from '../upload-transaction';
import { DatePipe } from '@angular/common';
import { BACO_DATE_FORMAT } from 'src/app/baco/baco.tokens';
import {
  catchError,
  first,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TransferTransactionComponent } from '../transfer-transaction';
import { BacoFeedStore } from '../../baco-feed.store';
import { BankAccountClient } from 'src/app/baco/common';
import {
  BacoBankAccountDto,
  BacoLoadableTransactionDto,
  StatefulHelpers,
} from 'src/app/baco/interfaces';
import { EMPTY, Subject } from 'rxjs';

@Component({
  selector: 'crs-customize-preview-menu',
  templateUrl: './customize-preview-menu.component.html',
  styleUrls: ['./customize-preview-menu.component.scss'],
})
export class CustomizePreviewMenuComponent implements OnInit {
  @Input() selectedStartDate: Date;
  @Input() selectedEndDate: Date;

  public isLedgerAndBankFeedFeaturesEnabled: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public readonly transactionStore: BacoTransactionStore,
    private readonly modalService: ModalService,
    private bankAccountClient: BankAccountClient,
    private bacoFeedStore: BacoFeedStore,
    private readonly _datePipe: DatePipe,
    @Inject(BACO_DATE_FORMAT) private _dateFormat: string,
    private injector: Injector,
    private messageService: MessageService,
    private sessionService: SessionService
  ) {}

  public ngOnInit(): void {
    this.isLedgerAndBankFeedFeaturesEnabled =
      this.sessionService.featureFlags.ledger &&
      this.sessionService.featureFlags.bankFeed;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onClickUploadTransactions() {
    this.modalService
      .openModal(
        UploadTransactionComponent,
        null,
        {},
        { windowClass: 'upload-modal', injector: this.injector }
      )
      .then(() => this.transactionStore.refreshTransactions())
      .catch(() => true);
  }

  public onClickTransferTransactions(): void {
    const params = {
      fileId: this.bacoFeedStore.feed$.getValue()?.data?.ledgerSourceId,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
    };

    this.modalService
      .openModal(TransferTransactionComponent, null, params, {
        injector: this.injector,
      })
      .then(() => {
        this.messageService.success('Transactions transferred successfully');
        this.transactionStore.refreshTransactions();
      })
      .catch(() => true);
  }

  public onClickRevertTransactions() {
    const bankAccount = this.transactionStore.selectedBank$.getValue();

    const confirmation = new EnhancedConfirmation({
      title: 'Confirm',
      approveAction: () => this.revertTransactions(bankAccount),
      cancelAction: () => this.modalService.closeOverlay(),
      danger: true,
      approveBtn: 'Revert',
      shouldFocusOnSubmitButton: true,
    });

    this.transactionStore.dateRange$.pipe(first()).subscribe((dateRange) => {
      const startDate = this._datePipe.transform(
        dateRange.startDate,
        'dd MMM yyyy'
      );
      const endDate = this._datePipe.transform(
        dateRange.endDate,
        'dd MMM yyyy'
      );

      confirmation.additionalInfoText = `Revert all transactions between ${startDate} and ${endDate} for "${bankAccount.accountName}?"`;

      this.modalService.confirmationEx(confirmation).then();
    });
  }

  private revertTransactions(bankAccount: BacoBankAccountDto): void {
    this.transactionStore.dateRange$
      .pipe(
        take(1),
        switchMap((dateRange) => {
          return this.bankAccountClient
            .revertTransactions(bankAccount.id, dateRange)
            .pipe(
              catchError((err) => {
                this.messageService.error(err);
                this.transactionStore.transactions$.next(
                  StatefulHelpers.error<BacoLoadableTransactionDto[]>(err)
                );
                return EMPTY;
              }),
              tap(() => {
                this.messageService.success(
                  `Transactions reverted successfully for ${bankAccount.accountName}`
                );
                this.transactionStore.refreshTransactions();
              }),
              takeUntil(this.destroy$)
            );
        })
      )
      .subscribe();
  }
}
