import { CssEditorComponent } from './../css/css-editor/css-editor.component';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject, Observable, EMPTY, from } from 'rxjs';
import {
  tap,
  exhaustMap,
  switchMap,
  map,
  catchError,
  finalize,
} from 'rxjs/operators';

import { SaveAsComponent } from '../../../reportTemplates/save-as/save-as.component';
import { MessageService, ModalService } from 'src/app/core';
import {
  CssStyle,
  ICssFontUrl,
  ReportStyleModel,
} from '../models/report-style';
import { ReportStyleService } from '../report-style.service';

class SubmitOption {
  closeOnSuccess: boolean;
  saveAs: boolean;
  constructor(closeOnSuccess: boolean, saveAs: boolean) {
    this.closeOnSuccess = closeOnSuccess;
    this.saveAs = saveAs;
  }
}

@Component({
  selector: 'crs-report-style',
  templateUrl: './report-style.component.html',
  styleUrls: ['./report-style.component.scss'],
})
export class ReportStyleComponent implements OnInit, OnDestroy {
  id: string;
  isAdd: boolean;

  cssCollapsed = false;
  excelCollapsed = false;
  wordCollapsed = false;

  objectTitle = 'Report Style';
  error: string = null;

  subscriptions: Subscription[] = [];

  submitButtonStream = new Subject<SubmitOption>();
  generateButtonStream = new Subject<void>();
  busy = {
    load: null,
    submit: null,
  };

  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(32)]],
    isDefault: [false],
    rounding: [
      null,
      Validators.compose([
        Validators.min(0),
        Validators.max(5),
        Validators.pattern('^[0-5]+$'),
      ]),
    ],
    percentRounding: [
      null,
      Validators.compose([
        Validators.min(0),
        Validators.max(5),
        Validators.pattern('^[0-5]+$'),
      ]),
    ],
    hasCss: [false],
    hasExcel: [false],
    excelStyle: this._formBuilder.group({
      hasExcelFile: [false],
      portraitColumnWidth: [null, [Validators.min(2), Validators.max(20)]],
      landscapeColumnWidth: [null, [Validators.min(2), Validators.max(30)]],
      marginRowHeight: [null, [Validators.min(1), Validators.max(50)]],
      marginTotalRowHeight: [null, [Validators.min(0), Validators.max(50)]],
      startFinancialIndentingFromLevel: [
        null,
        [Validators.min(0), Validators.max(50)],
      ],
      paragraphIndentSize: [null, [Validators.min(0), Validators.max(5)]],
      uploadedFile: null,
    }),
    hasWord: [false],
    wordStyle: this._formBuilder.group({
      hasWordFile: [false],
      portraitColumnWidth: [null, [Validators.min(2), Validators.max(20)]],
      landscapeColumnWidth: [null, [Validators.min(2), Validators.max(30)]],
      marginRowHeight: [null, [Validators.min(1), Validators.max(50)]],
      marginTotalRowHeight: [null, [Validators.min(0), Validators.max(50)]],
      startFinancialIndentingFromLevel: [
        null,
        [Validators.min(0), Validators.max(50)],
      ],
      paragraphIndentSize: [null, [Validators.min(0), Validators.max(5)]],
      uploadedFile: null,
    }),
  });

  customCss: string;
  fontUrls: ICssFontUrl[];

  @ViewChild(CssEditorComponent, { static: true })
  private _cssEditorComponent: CssEditorComponent;

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _reportStyleService: ReportStyleService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _location: Location
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this._route.params.subscribe((params) => {
        this.id = params.id;
        this.isAdd = this.id === 'add';
        this.getData();
      })
    );

    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap((options) => {
            return this.submitObservable(options);
          })
        )
        .subscribe((options) => {
          if (options.closeOnSuccess) this.close();
          else this._messageService.success('Successfully saved style.');
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  getData() {
    if (this.isAdd) return;

    this.busy.load = this._reportStyleService
      .get(this.id)
      .pipe(
        tap((style) => {
          this.fontUrls = style.cssFontUrls;
          this.form.patchValue(style);
          this.customCss = style.cssStyle ? style.cssStyle.css : null;
        })
      )
      .subscribe(
        () => {},
        (err) => this.showError(err)
      );
  }

  save(closeOnSuccess: boolean) {
    const submitOption = new SubmitOption(closeOnSuccess, false);
    this.submitButtonStream.next(submitOption);
  }

  saveAs() {
    const submitOption = new SubmitOption(true, true);
    this.submitButtonStream.next(submitOption);
  }

  submitObservable(options: SubmitOption): Observable<SubmitOption> {
    let observable: Observable<SubmitOption>;
    this.form.markAsDirty();
    if (this.form.invalid) return EMPTY;

    const reportStyle = new ReportStyleModel(this.form.value);
    reportStyle.cssStyle = reportStyle.hasCss
      ? new CssStyle({
          css: this._cssEditorComponent.buildCustomCss(),
        })
      : null;

    reportStyle.cssFontUrls = this._cssEditorComponent.fontUrls || [];

    if (options.saveAs) {
      const saveAsModal = this._modalService.openModal(SaveAsComponent, null, {
        text: 'Please provide a name for the new report style',
      });
      observable = from(saveAsModal).pipe(
        switchMap((name) => {
          reportStyle.name = name as string;
          return this._reportStyleService.post(reportStyle);
        }),
        tap((id) => {
          this.isAdd = false;
          if (!options.closeOnSuccess) this.updateUrl();
        }),
        map(() => options)
      );
    } else if (this.isAdd) {
      observable = this._reportStyleService.post(reportStyle).pipe(
        tap((id) => {
          this.isAdd = false;
          if (!options.closeOnSuccess) this.updateUrl();
        }),
        map(() => options)
      );
    } else {
      reportStyle.id = this.id;
      observable = this._reportStyleService
        .put(reportStyle)
        .pipe(map(() => options));
    }

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(
      tap(() => this._messageService.success('Successfully saved style.')),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );

    return observable;
  }

  /**
   * After saving a new report template, updates url to reflect address of newly saved report
   */
  private updateUrl() {
    const url = this._router
      .createUrlTree(['../', this.id], { relativeTo: this._route })
      .toString();

    this._location.go(url);
  }

  delete() {
    return this._modalService.confirmation(
      'Are you sure you want to delete this report style? This action cannot be undone.',
      () => {
        this._reportStyleService.delete(this.id).subscribe(() => {
          this.close();
        }, this.showError);
      },
      true
    );
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  showError(err) {
    this.error = err;
    this._messageService.error(err);
  }
}
