import { IElement } from '../../element';
import { ReportTableColumnDefinition } from './report-table-column-definition';
import { ReportTableRow } from './report-table-row';
import { ReportElementTypeEnum } from '../../../../enums';

export class ReportTable implements IElement {

  elementType = ReportElementTypeEnum.Table;
  columns: ReportTableColumnDefinition[];
  rows: ReportTableRow[];
  isNew = true;
  isOutput = false;
  isEditable = true;
  showLedgerModal = false;

  constructor(data?: Partial<ReportTable>) {
    if (data) Object.assign(this, data);
  }
}
