import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  finalize,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { MessageService, ModalService } from 'src/app/core';
import { DateRendererComponent, getDefaultGridOptions } from 'src/app/shared';
import {
  Client,
  ClientService,
  EntityModel,
  EntityService,
  EntityTypeEnum,
  OfficeService,
  Plurality,
  buildAddressForm,
  entityTypes,
} from '../../';
import { AssociationState } from '../../../accounting';
import { IntegrationServerDatasource } from '../../../configuration/integration-server/integration-server.datasource';
import { BusinessNumberPipe, businessNumberValidator } from '../../../shared';
import { DateEditorComponent } from '../../../shared/ag-grid';
import { buildTrusteeDetailForm } from '../../model/trusteeDetail';
import { ResponsiblePerson } from '../entity';
import { GridApi, GridOptions } from 'ag-grid-community';

enum Action {
  Submit,
  Delete,
}

@Component({
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss'],
  providers: [BusinessNumberPipe, IntegrationServerDatasource, DatePipe],
})
export class EntityComponent implements OnInit, OnDestroy {
  @ViewChild('optionsCell', { static: true, read: TemplateRef })
  optionsCell: TemplateRef<ResponsiblePerson>;

  public frameworkComponents = {
    dateEditor: DateEditorComponent,
    dateView: DateRendererComponent,
  };

  @Input() id: string;
  @Input() params: Client = new Client({});
  isAdd: boolean;
  isClientRefPresent: boolean;
  objectTitle = 'Client Entity';
  busy = {
    load: null,
    submit: null,
    delete: null,
  };
  error: string = null;

  entityType = EntityTypeEnum;
  entityTypes = entityTypes;
  pluralities = Plurality;
  responsiblePersons: ResponsiblePerson[];
  gridOptions: GridOptions;
  gridApi: GridApi;

  reportOptionsCollapsed = false;
  managementCollapsed = false;
  subscriptions: Subscription[] = [];
  associationStates = AssociationState;

  form = this.formBuilder.group({
    clientId: [null],
    code: [null, Validators.maxLength(32)],
    clientRef: [null, Validators.maxLength(64)],
    legalName: [null, [Validators.required, Validators.maxLength(1024)]],
    tradingName: [null, Validators.maxLength(1024)],
    entityTypeId: [EntityTypeEnum.Unspecified, Validators.required],
    abn: [null, businessNumberValidator('abn')],
    abnDivision: [null, Validators.maxLength(3)],
    acn: [null, businessNumberValidator('acn')],
    registeredOffice: buildAddressForm(this.formBuilder),
    principalPlaceOfBusiness: buildAddressForm(this.formBuilder),
    principalActivities: [null, Validators.maxLength(512)],
    description: [null, Validators.maxLength(1024)],
    responsible: [0],
    owners: [0],
    isConsolidatedGroup: [false],
    isRegisteredForGst: [true],
    isLivestockEnabled: [false],
    office: [null, Validators.required],
    team: [null],
    partner: [null, Validators.required],
    manager: [null],
    associationStateId: [null],
    trusteeDetail: buildTrusteeDetailForm(this.formBuilder),
    notForProfit: [false],
    reportingEntity: [false],
  });

  createFileControl = this.formBuilder.control(true);

  public availableClients$: Observable<any>;

  constructor(
    public activeModal: NgbActiveModal,
    private clientService: ClientService,
    private datePipe: DatePipe,
    private entityService: EntityService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private modalService: ModalService,
    private officeService: OfficeService
  ) {}

  submitButtonStream$ = new Subject();

  configureSubmit(): void {
    this.subscriptions.push(
      this.submitButtonStream$
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap((action) =>
            action === Action.Delete ? this.delete$() : this.submit$()
          )
        )
        .subscribe({
          next: (client) => this.activeModal.close(client),
          error: (error) => (this.error = error),
        })
    );
  }

  submit(): void {
    this.submitButtonStream$.next(Action.Submit);
  }

  delete(): void {
    this.modalService.confirmation(
      'This action cannot be undone. Are you sure you want to delete this entity?',
      () => this.submitButtonStream$.next(Action.Delete),
      true
    );
  }

  submit$(): Observable<any> {
    let submit$: Observable<any>;
    const entity = new EntityModel(this.form.value);

    for (let i = 0; i < this.responsiblePersons.length; i++) {
      if (this.responsiblePersons[i].appointedDate === 'YYYY-MM-DD') {
        this.responsiblePersons[i].appointedDate = null;
      }
      if (this.responsiblePersons[i].resignedDate === 'YYYY-MM-DD') {
        this.responsiblePersons[i].resignedDate = null;
      }
    }

    entity.responsiblePersons = this.responsiblePersons;

    if (this.isAdd) {
      submit$ = this.entityService.post$(entity).pipe(
        map((entity) => {
          return {
            createFile: this.createFileControl.value,
            entityId: entity,
          };
        })
      );
    } else {
      entity.id = this.id;
      submit$ = this.entityService.put$(entity);
    }

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return submit$.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  delete$(): Observable<any> {
    const loadingStream = new Subject<void>();
    this.busy.delete = loadingStream.subscribe();

    return this.entityService.delete$(this.id).pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  private setupReactToEntityTypeChange() {
    const control = this.form.get('associationStateId');
    this.subscriptions.push(
      this.form
        .get('entityTypeId')
        .valueChanges.pipe(
          tap((entityTypeId) => {
            control.setValidators(
              entityTypeId === EntityTypeEnum.Association
                ? Validators.required
                : null
            );
          }),
          filter(
            (entityTypeId) =>
              this.form.get('office').value &&
              !control.value &&
              entityTypeId === EntityTypeEnum.Association
          ),
          switchMap(() =>
            this.officeService.get(this.form.get('office').value.id)
          ),
          filter(() => !control.value),
          tap((office) => control.setValue(office.defaultAssociationStateId))
        )
        .subscribe()
    );
  }

  private showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  onClickAddResponsiblePerson(): void {
    this.responsiblePersons.push(new ResponsiblePerson({ isSigning: true }));
    this.gridApi.setGridOption('rowData', this.responsiblePersons);
  }

  onClickRemoveResponsiblePerson(person: ResponsiblePerson): void {
    const index = this.responsiblePersons.indexOf(person);
    this.responsiblePersons.splice(index, 1);
    this.gridApi.setGridOption('rowData', this.responsiblePersons);
  }

  private getEntity(): void {
    if (this.isAdd) {
      this.responsiblePersons = [];
      this.form.patchValue({
        clientId: this.params.id,
        office: this.params.office,
        team: this.params.team,
        partner: this.params.partner,
        manager: this.params.manager,
      });
    } else {
      this.busy.load = this.entityService.get$(this.id).subscribe({
        next: (data) => {
          this.form.patchValue(data);

          // ClientRef is read from IS. If there is no clientRef, hide the control.
          if (data.clientRef) {
            this.isClientRefPresent = true;
          }

          this.responsiblePersons = data.responsiblePersons;
          for (let i = 0; i < data.responsiblePersons.length; i++) {
            data.responsiblePersons[i].appointedDate = this.datePipe.transform(
              data.responsiblePersons[i].appointedDate,
              'yyyy-MM-dd'
            );
            data.responsiblePersons[i].resignedDate = this.datePipe.transform(
              data.responsiblePersons[i].resignedDate,
              'yyyy-MM-dd'
            );
          }
          this.gridApi.setGridOption('rowData', this.responsiblePersons);
        },
        error: (err) => this.showError(err),
      });
    }
  }

  ngOnInit(): void {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      onGridReady: (event) => (this.gridApi = event.api),
      cellSelection: true,
      rowDragManaged: true,
      suppressHorizontalScroll: true,
      columnDefs: [
        {
          colId: 'drag',
          headerName: '',
          maxWidth: 30,
          minWidth: 30,
          rowDrag: true,
        },
        {
          field: 'title',
          headerName: 'Title',
          editable: true,
          maxWidth: 75,
        },
        {
          field: 'firstName',
          headerName: 'First Name',
          editable: true,
          flex: 3,
        },
        {
          field: 'lastName',
          headerName: 'Last Name',
          editable: true,
          flex: 3,
        },
        {
          field: 'email',
          headerName: 'Email',
          editable: true,
          flex: 3,
        },
        {
          field: 'isSigning',
          headerName: 'Signing',
          type: 'booleanColumn',
          cellRendererParams: { isToggleable: true },
          maxWidth: 120,
        },
        {
          field: 'appointedDate',
          headerName: 'Appointed',
          type: 'dateColumn',
          editable: true,
          maxWidth: 135,
        },
        {
          field: 'resignedDate',
          headerName: 'Resigned',
          type: 'dateColumn',
          editable: true,
          maxWidth: 130,
        },
        {
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCell },
        },
      ],
    };

    this.isAdd = this.id === 'add';
    this.isClientRefPresent = false;
    this.availableClients$ = this.clientService.getAllNames().pipe(
      first(),
      map((clients) => {
        const viewClients = [];

        clients.forEach((client) => {
          const clientWithSameName = viewClients.find(
            (viewClient) => viewClient.name === client.name
          );

          if (clientWithSameName) {
            client.name = `${client.name} - ${client.id}`;
            clientWithSameName.name = `${clientWithSameName.name} - ${clientWithSameName.id}`;
          }

          viewClients.push(client);
        });

        return viewClients;
      })
    );

    this.configureSubmit();
    this.setupReactToEntityTypeChange();

    this.getEntity();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
