import { Inject, Injectable } from '@angular/core';
import { ReportTable, ReportTableCell, ReportTableColumnDefinition, ReportTableRow } from '../index';
import { Alignment } from '../../index';
import { ElementSizeMultiplier, getSize, NumberFormat, ReportElementTypeEnum, TableElement } from '../../../../enums';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ColumnType, ColumnTypeUtils } from '../../../../reportTemplates';
import { IReportTableConfig, IToolsState } from '../interfaces';
import { TableActionEnum, TableRowType } from '../enums';
import { GROUP_TYPES, REPORT_TABLE_CONFIG } from '../report-table.contants';
import { ObjectHelpers } from '../../../../../../shared/utilities';
import { HeaderStyle } from '../../financial/header-style';
import { map } from 'rxjs/operators';
import { ReportHeaderColumn, ReportStyleInfo } from '../../../report-output';
import { ReportTableMessageService } from './report-table-message.service';
import { ReportTableUtility } from './report-table-autocalc-utility';

@Injectable()
export class ReportTableService {

  public groupTypes = GROUP_TYPES;
  public elementRemoved$: Subject<void> = new Subject<void>();

  public toolsState$: BehaviorSubject<IToolsState> = new BehaviorSubject<IToolsState>({});

  public selectedElementType$: Observable<TableElement> = this.toolsState$.pipe(map(x => x.selectedElementType));
  public selectedRowIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public selectedColumnIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public get isPeriodColumnHeaderCell(): boolean {
    return this._currentRow?.rowType === TableRowType.ColumnHeader && this._currentColumnIndex > 0;
  }

  public get reportTableData(): ReportTable {
    return this._reportTable;
  }

  public get reportStyle(): ReportStyleInfo {
    return this._reportStyle;
  }

  public get isSectionTable(): boolean {
    return this._reportTable.elementType === ReportElementTypeEnum.SectionTable;
  }

  private get _currentRowIndex(): number {
    return this.selectedRowIndex$.getValue();
  }

  private get _currentColumnIndex(): number {
    return this.selectedColumnIndex$.getValue();
  }

  private get _currentElement(): TableElement {
    return this.toolsState$.getValue()?.selectedElementType;
  }

  private get _currentRow(): ReportTableRow {
    return this._reportTable.rows[this._currentRowIndex];
  }

  private get _currentColumn(): ReportTableColumnDefinition {
    return this._reportTable.columns[this._currentColumnIndex];
  }

  private get _currentCell(): ReportTableCell {
    return this._reportTable.rows[this._currentRowIndex].cells[this._currentColumnIndex];
  }

  private get _noGroupRows(): ReportTableRow[] {
    return this._reportTable.rows.filter(row => !this.groupTypes.includes(row.rowType));
  }

  private get _groupRows(): ReportTableRow[] {
    return this._reportTable.rows.filter(row => this.groupTypes.includes(row.rowType));
  }

  private get _reportColumns(): ReportTableColumnDefinition[] {
    return this._reportTable.columns;
  }

  private get _autoWidthColumns(): ReportTableColumnDefinition[] {
    return this._reportTable.columns.filter(x => x.widthType == ElementSizeMultiplier.auto);
  }

  private _reportTable: ReportTable = new ReportTable();
  private _originalReportTable: ReportTable;
  private _reportStyle: ReportStyleInfo;
  private _reportTableHistory: ReportTable[] = [];
  private _historyIndex: number;

  constructor(@Inject(REPORT_TABLE_CONFIG) private readonly _config: IReportTableConfig,
              private readonly _tableMessageService: ReportTableMessageService) {
  }

  public setReportTableData(data: ReportTable): void {
    this._reportTable = data;
    this._originalReportTable = ObjectHelpers.deepCopy(data);
    this._resetEditSettings();
  }

  public setReportStyle(reportStyle: ReportStyleInfo) {
    this._reportStyle = reportStyle;
  }

  public setAutoColumnHeaders(dataSetColumns: ReportHeaderColumn[]) {
    ReportTableUtility.setAutoColumnHeaders(dataSetColumns, this._reportTable);    
  }

  public setCurrentTableInfo(): void {
    this._selected(TableElement.Table);
  }

  public setCurrentColumnInfo(cellIndex: number): void {
    this._selected(TableElement.Column, null, cellIndex);
  }

  public setCurrentRowInfo(rowIndex: number): void {
    this._selected(TableElement.Row, rowIndex);
  }

  public setCurrentCell(rowIndex: number, columnIndex): void {
    this._selected(TableElement.Cell, rowIndex, columnIndex);
  }

  public history(historyStep: number) {
    if (this._historyIndex <= this._reportTableHistory.length) {
      this._historyIndex += historyStep;
      const historyItem = this._reportTableHistory[this._historyIndex];
      if (historyItem) {
        this._reportTable = ObjectHelpers.deepCopy(historyItem);
      }
    }
  }

  public setValueType(valueType: ColumnType): void {
    const cells = this._getCells(false);

    if (ColumnTypeUtils.isNumeric(valueType) && cells.some((cell) => isNaN(Number(cell.cellValue)))) {
      this._tableMessageService.invalidTypeDialog();
      this._updateValueTypeState();
      return;
    }

    cells.forEach((cell) => {
      cell.valueType = valueType;
      if (ColumnTypeUtils.isNumeric(valueType)) {
        cell.rounding = this._reportStyle.rounding;
        cell.numberFormat = NumberFormat.Normal;
      }
    });

    if (this._currentElement === TableElement.Column) {
      this._currentColumn.valueType = valueType;
      if (ColumnTypeUtils.isNumeric(valueType)) {
        this._currentColumn.rounding = this._reportStyle.rounding;
        this._currentColumn.numberFormat = NumberFormat.Normal;
      }
    }

    this._updateValueTypeState();
  }

  public setRounding(rounding: number): boolean {

    const cells = this._getCells();

    cells.forEach((cell) => {
      cell.rounding = rounding;
      if (this._currentElement !== TableElement.Column) {
        this._changeState(TableActionEnum.cellRounding, rounding);
      }
    });

    if (this._currentElement === TableElement.Column) {
      this._currentColumn.rounding = rounding;
      this._changeState(TableActionEnum.columnRounding, rounding);
    }

    return true;
  }

  public setFormatting(numberFormat: NumberFormat): boolean {

    const cells = this._getCells();

    cells.forEach((cell) => {
      cell.numberFormat = numberFormat;
      cell.rounding = numberFormat === NumberFormat.Percent ? this.reportStyle.percentRounding : this.reportStyle.rounding;
      if (this._currentElement !== TableElement.Column) {
        this._changeState(TableActionEnum.cellFormatting, numberFormat);
      }
    });

    if (this._currentElement === TableElement.Column) {
      this._currentColumn.numberFormat = numberFormat;
      this._changeState(TableActionEnum.columnFormatting, numberFormat);
    }

    return true;
  }

  public toggleAutoColumnHeader(enableAutoColumnHeader: boolean, targetRow: ReportTableRow = this._currentRow): void {
    targetRow.enableAutoColumnHeader = enableAutoColumnHeader;
    this._changeState(TableActionEnum.enableAutoColumnHeader, enableAutoColumnHeader);
  }

  public disableAutoColumnHeader(): void {
    if (this._currentRow) {
      this.toggleAutoColumnHeader(false);
      this._currentRow.allowAutoColumnHeader = false;
    } else {
      this.reportTableData.rows.filter(r => r.rowType === TableRowType.ColumnHeader)
        .forEach(r => {
          this.toggleAutoColumnHeader(false, r);
          r.allowAutoColumnHeader = false;
        });
    }

    this._changeState(TableActionEnum.allowAutoColumnHeader, false);
  }

  public transformToRowType(toCustomTableRowType: TableRowType) {
    if (this._currentElement === TableElement.Row) {
      const row = this._currentRow;

      if (row.rowType !== toCustomTableRowType) {
        if (this.groupTypes.includes(toCustomTableRowType)) {
          this._leaveOnlyFirstColumn(row);
        } else {
          this._generateCellsForEachColumn(row);
        }

        row.rowType = toCustomTableRowType;

        this._clearCellValues(row);

        this._applyDefaultStyles(row);

        this._changeState(TableActionEnum.selectedRowType, toCustomTableRowType);

        this.setRowAndColumnTotalValues();
      }
    }
  }

  public setColumnSizeX(sizeX: ElementSizeMultiplier) {
    this._currentColumn.widthType = sizeX;

    if (sizeX !== ElementSizeMultiplier.auto) {
      this._currentColumn.width = getSize(sizeX, this._config.cellDefaultWidth);
    }

    this._autoWidthColumns.map(x => x.width = Math.round(this._calculateAutoColumnWidth()));

    this._changeState(TableActionEnum.columnSizeX, sizeX);
  }

  public align(align: Alignment): void {
    this._tableAction(TableActionEnum.alignment, align);
  }

  public textUnderLine(flag: boolean) {
    this._tableAction(TableActionEnum.textUnderline, flag);
  }

  public textBold(flag: boolean) {
    this._tableAction(TableActionEnum.textBold, flag);
  }

  public textItalic(flag: boolean) {
    this._tableAction(TableActionEnum.textItalic, flag);
  }

  // TODO: Update these methods so that changes are not saved until Save button is clicked
  public insertElement(): void {
    if (this._currentElement === TableElement.Row) {
      this._insertRow();
    } else if (this._currentElement === TableElement.Column) {
      this._insertColumn();
    }

    this.setRowAndColumnTotalValues();
  }

  public elementHasData(): boolean {
    if (this._currentElement === TableElement.Row) {
      return this._currentRow.cells.some(c => c.cellValue);
    } else if (this._currentElement === TableElement.Column) {
      return this._reportTable.rows.map(x => x.cells[this._currentColumnIndex]).some(c => !!c?.cellValue);
    }
  }

  public sectionHasOnlyOneRow(): boolean {
    if (this._reportTable.elementType !== ReportElementTypeEnum.SectionTable) {
      return false;
    }

    if (this._currentRow.rowType === TableRowType.Row && this._currentRow.sections?.length > 0) {
      return this._currentRow.sections
        .some(s1 => this._reportTable.rows
          .filter(r => r.rowType === TableRowType.Row && r.sections?.length > 0 && r.sections.some(s2 => s2 === s1)).length === 1);
    }

    return false;
  }

  public sectionHasOnlyOneColumn(): boolean {
    if (this._reportTable.elementType !== ReportElementTypeEnum.SectionTable) {
      return false;
    }

    if (this._currentElement === TableElement.Column) {
      return this._reportTable.rows.filter(r => r.rowType === TableRowType.Row && r.sections?.length > 0).map(r => r.cells)[0]
        .filter(x => x.valueType === ColumnType.Number && x.parentColumn === undefined).length === 1;
    }

    return false;
  }

  // TODO: Update these methods so that changes are not saved until Save button is clicked
  public removeElement(): void {
    if (this._currentElement === TableElement.Row) {
      this._removeRow();
    } else if (this._currentElement === TableElement.Column) {
      this._removeColumn();
    }

    this.elementRemoved$.next();

    this.clearSelection();
    this.setRowAndColumnTotalValues();
  }

  public saveChanges(): ReportTable {
    this._originalReportTable = ObjectHelpers.deepCopy(this._reportTable);
    return this._reportTable;
  }

  public cancelAllChanges(): ReportTable {
    this._reportTable = ObjectHelpers.deepCopy(this._originalReportTable);
    return this._reportTable;
  }

  public clearSelection(): void {
    this.selectElementType(null);
    this.selectedRowIndex$.next(null);
    this.selectedColumnIndex$.next(null);

    this._changeState(TableActionEnum.selectedTable);
  }

  public setRowStyle(rowStyle: HeaderStyle) {
    const row = this._currentRow;
    if (row) {
      row.style = rowStyle;
      this._changeState(TableActionEnum.rowStyle, rowStyle);
    }
  }

  public setRowLevel(rowLevel: number) {
    const row = this._currentRow;
    if (row) {
      row.level = rowLevel;
      this._changeState(TableActionEnum.rowLevel, rowLevel);
    }
  }

  public selectElementType(selectedElementType?: TableElement): void {
    this._changeState(TableActionEnum.selectedElementType, selectedElementType);
  }

  public setRowAndColumnTotalValues() {
    ReportTableUtility.setRowAndColumnTotalValues(this._reportTable);   
  }

  public checkSectionMovingRules(rows: ReportTableRow[], oldIndex: number, newIndex: number) {
    if (this._reportTable.elementType !== ReportElementTypeEnum.SectionTable) {
      return true;
    }

    const movingRow = rows[oldIndex];
    if (movingRow.rowType !== TableRowType.Row) {
      return false;
    }

    const sectionRowIndexes = rows.map((r, i) => {
      if (r.sections?.some(x => r.rowType === TableRowType.Row && movingRow.sections?.some(y => y === x))) {
        return i;
      }
    }).filter(x => x !== undefined);

    const sectionMin = Math.min(...sectionRowIndexes);
    const sectionMax = Math.max(...sectionRowIndexes);

    if (newIndex < sectionMin || newIndex > sectionMax) {
      return false;
    }

    return true;
  }

  private _insertColumn(): void {
    if (!this._reportTable.columns?.length || !this._currentColumn) {
      this._tableMessageService.invalidActionDialog();
      return;
    }

    const newColumn = ObjectHelpers.deepCopy(this._currentColumn);
    if (newColumn) {
      const newColumnIndex = this._currentColumnIndex + 1;
      this._reportTable.columns.splice(newColumnIndex, 0, newColumn);

      this._noGroupRows.forEach(r => {
        const newCell = new ReportTableCell({
          ...r.cells[this._currentColumnIndex],
          cellValue: null,
          });

        r.cells.splice(newColumnIndex, 0, newCell);
      });

      this._groupRows.forEach(r => {
        r.cells[0].colSpan += 1;
      });

      this.disableAutoColumnHeader();

      this._saveHistory();
    }
  }

  private _removeColumn(): void {
    const removeRowWithIDs: number[] = [];

    if (this._reportColumns && this._reportColumns.length > this._currentColumnIndex) {
      this._noGroupRows.forEach((row: ReportTableRow, rowIndex: number) => {
        row.cells.splice(this._currentColumnIndex, 1);

        if (!row.cells.length) {
          removeRowWithIDs.push(rowIndex);
        }
      });

      this._groupRows.forEach((row: ReportTableRow) => {
        row.cells[0].colSpan -= 1;
      });

      this._reportColumns.splice(this._currentColumnIndex, 1);

      const autoColumnCount = this._autoWidthColumns.length;
      if (autoColumnCount === 0) {
        this._reportTable.columns[0].widthType = ElementSizeMultiplier.auto;
        this._reportTable.columns[0].width = this._calculateAutoColumnWidth();
      }

      if (removeRowWithIDs.length) {
        removeRowWithIDs.forEach(value => this._reportTable.rows.splice(value, 1));
      }

      this.disableAutoColumnHeader();

      this._saveHistory();
    }
  }

  private _insertRow(): void {
    if (!this._currentRow) {
      this._tableMessageService.invalidActionDialog();
      return;
    }

    const newRow = ObjectHelpers.deepCopy(this._currentRow);
    if (newRow) {
      newRow.cells = this._currentRow.cells.map(c => new ReportTableCell({
        ...c,
        cellValue: null,
      }));

      this._applyDefaultStyles(newRow);
      this._reportTable.rows.splice(this._currentRowIndex + 1, 0, newRow);
      this._saveHistory();
    }
  }

  private _removeRow(): void {
    this._reportTable.rows.splice(this._currentRowIndex, 1);
    this._saveHistory();
  }

  private _cellAction(cell: ReportTableCell, action: TableActionEnum, value: any) {
    switch (action) {
      case TableActionEnum.alignment:
        cell.alignment = value;
        break;
      case TableActionEnum.textUnderline:
        cell.textUnderline = value;
        break;
      case TableActionEnum.textBold:
        cell.textBold = value;
        break;
      case TableActionEnum.textItalic:
        cell.textItalic = value;
        break;
    }

    this._changeState(action, value);
  }

  private _changeState(action: TableActionEnum, value: any = null): void {
    const currentState = this.toolsState$.getValue();
    switch (action) {
      case TableActionEnum.alignment:
        this.toolsState$.next({ ...currentState, alignment: value });
        break;
      case TableActionEnum.textUnderline:
        this.toolsState$.next({ ...currentState, textUnderline: value });
        break;

      case TableActionEnum.textBold:
        this.toolsState$.next({ ...currentState, textBold: value });
        break;

      case TableActionEnum.textItalic:
        this.toolsState$.next({ ...currentState, textItalic: value });
        break;

      case TableActionEnum.rowLevel:
        this.toolsState$.next({ ...currentState, rowLevel: value });
        break;

      case TableActionEnum.rowStyle:
        this.toolsState$.next({ ...currentState, rowStyle: value });
        break;

      case TableActionEnum.selectedTable:
        this.toolsState$.next({ ...currentState, selectedRowType: null, selectedColumnType: null, selectedCellType: null });
        break;

      case TableActionEnum.selectedRowType:
        this.toolsState$.next({ ...currentState, selectedRowType: value });
        break;

      case TableActionEnum.selectedColumnType:
        this.toolsState$.next({ ...currentState, selectedColumnType: value });
        break;

      case TableActionEnum.selectedCellType:
        this.toolsState$.next({ ...currentState, selectedCellType: value });
        break;

      case TableActionEnum.enableAutoColumnHeader:
        this.toolsState$.next({ ...currentState, enableAutoColumnHeader: value });
        break;

      case TableActionEnum.allowAutoColumnHeader:
        this.toolsState$.next({ ...currentState, allowAutoColumnHeader: value });
        break;

      case TableActionEnum.columnFormatting:
        this.toolsState$.next({ ...currentState, columnFormatting: value });
        break;

      case TableActionEnum.cellFormatting:
        this.toolsState$.next({ ...currentState, cellFormatting: value });
        break;

      case TableActionEnum.columnRounding:
        this.toolsState$.next({ ...currentState, columnRounding: value });
        break;

      case TableActionEnum.cellRounding:
        this.toolsState$.next({ ...currentState, cellRounding: value });
        break;

      case TableActionEnum.columnSizeX:
        this.toolsState$.next({ ...currentState, columnSizeX: value });
        break;

      case TableActionEnum.selectedElementType:
        this.toolsState$.next({ ...currentState, selectedElementType: value });
        break;
    }
  }

  private _selected(type: TableElement, rowIndex: number = null, columnIndex = null) {

    this.selectElementType(type);

    switch (type) {
      case TableElement.Table:
        this.selectedRowIndex$.next(null);
        this.selectedColumnIndex$.next(null);
        this._changeState(TableActionEnum.selectedTable);
        break;
      case TableElement.Row:
        this.selectedRowIndex$.next(rowIndex);
        this._changeState(TableActionEnum.selectedRowType, this._reportTable.rows[rowIndex].rowType);
        this._changeState(TableActionEnum.rowLevel, this._reportTable.rows[rowIndex].level);
        this._changeState(TableActionEnum.rowStyle, this._reportTable.rows[rowIndex].style);
        this._changeState(TableActionEnum.enableAutoColumnHeader, this._reportTable.rows[rowIndex].enableAutoColumnHeader);
        this._changeState(TableActionEnum.allowAutoColumnHeader, this._reportTable.rows[rowIndex].allowAutoColumnHeader);
        break;
      case TableElement.Column:
        this.selectedColumnIndex$.next(columnIndex);
        this._changeState(TableActionEnum.selectedColumnType, this._reportTable.columns[columnIndex].valueType);
        this._changeState(TableActionEnum.columnFormatting, this._reportTable.columns[columnIndex].numberFormat);
        this._changeState(TableActionEnum.columnRounding, this._reportTable.columns[columnIndex].rounding);
        this._changeState(TableActionEnum.columnSizeX, +this._reportTable.columns[columnIndex].widthType);
        break;
      case TableElement.Cell:
        this.selectedRowIndex$.next(rowIndex);
        this.selectedColumnIndex$.next(columnIndex);
        this._changeState(TableActionEnum.selectedCellType, this._reportTable.rows[rowIndex].cells[columnIndex].valueType);
        this._changeState(TableActionEnum.cellFormatting, this._reportTable.rows[rowIndex].cells[columnIndex].numberFormat);
        this._changeState(TableActionEnum.cellRounding, this._reportTable.rows[rowIndex].cells[columnIndex].rounding);
        break;
    }

    this._changeSharedToolsState();
  }

  private _changeSharedToolsState(): void {

    const cells = this._getCells();

    const alignLeft = cells.every(x => x.alignment === Alignment.Left);
    const alignCenter = cells.every(x => x.alignment === Alignment.Center);
    const alignJustify = cells.every(x => x.alignment === Alignment.Justify);
    const alignRight = cells.every(x => x.alignment === Alignment.Right);

    if (alignLeft) {
      this._changeState(TableActionEnum.alignment, Alignment.Left);
    } else if (alignCenter) {
      this._changeState(TableActionEnum.alignment, Alignment.Center);
    } else if (alignJustify) {
      this._changeState(TableActionEnum.alignment, Alignment.Justify);
    } else if (alignRight) {
      this._changeState(TableActionEnum.alignment, Alignment.Right);
    }

    this._changeState(TableActionEnum.textUnderline, cells.every(x => x.textUnderline));
    this._changeState(TableActionEnum.textItalic, cells.every(x => x.textItalic));
    this._changeState(TableActionEnum.textBold, cells.every(x => x.textBold));
  }

  private _resetEditSettings(): void {
    this.selectedRowIndex$.next(null);
    this.selectedColumnIndex$.next(null);

    this.selectElementType(null);
    this._changeState(TableActionEnum.selectedRowType, null);

    this._reportTableHistory = [];
    this._historyIndex = 0;
    this._saveHistory();
  }

  private _saveHistory(): void {
    this._reportTableHistory.push(ObjectHelpers.deepCopy(this._reportTable));
    this._historyIndex = this._reportTableHistory.length;
  }

  private _tableAction(action: TableActionEnum, value: any) {
    this._getCells().forEach(cell => this._cellAction(cell, action, value));
    this._saveHistory();
  }

  private _getCells(includeColumnHeaderCells: boolean = true): ReportTableCell[] {
    switch (this._currentElement) {
      case TableElement.Table:
        const cells = [];
        this._noGroupRows.forEach((row) => cells.push(...row.cells));
        return cells;
      case TableElement.Row:
        return this._currentRow.cells;
      case TableElement.Column:
        const columnCells = [];
        this._noGroupRows.forEach((row) => {
          const cell = row.cells[this._currentColumnIndex];
          if (cell) {
            if (row.rowType !== TableRowType.ColumnHeader || includeColumnHeaderCells) {
              columnCells.push(cell);
            }
          }
        });
        return columnCells;
      case TableElement.Cell:
        return [ this._currentRow.cells[this._currentColumnIndex] ];
    }
  }

  private _leaveOnlyFirstColumn(row: ReportTableRow) {
    row.cells.splice(1);
    row.cells[0].colSpan = this.reportTableData.columns.length;
  }

  private _clearCellValues(row: ReportTableRow) {
    if (row.rowType === TableRowType.Spacer) {
      row.cells.forEach((cell) => cell.cellValue = null);
    }
  }

  private _generateCellsForEachColumn(row: ReportTableRow) {
    row.cells = this._reportTable.columns.map((column, index) => <ReportTableCell>{
      alignment: row.cells[index] ? row.cells[index].alignment : Alignment.Left,
      cellValue: row.cells[index] ? row.cells[index].cellValue : null,
      colSpan: 1,
      valueType: row.cells[index] ? row.cells[index].valueType : column.valueType,
      numberFormat: row.cells[index] ? row.cells[index].numberFormat : column.numberFormat,
      rounding: row.cells[index] ? row.cells[index].rounding : column.rounding,
    });
  }

  private _applyDefaultStyles(row: ReportTableRow) {

    row.height = this._config.cellDefaultHeight;

    if (row.cells) {
      switch (row.rowType) {
        case TableRowType.Header:
        case TableRowType.ColumnHeader:
        case TableRowType.Total:
          row.cells.forEach((cell) => {
            cell.textBold = true;
          });
          break;
        case TableRowType.Row:
          row.cells.forEach((cell) => {
            cell.textBold = false;
          });
          break;
      }
    }
  }

  private _calculateAutoColumnWidth(): number {
    let nonAutoColumnWidthSum = this._reportTable.columns.filter(x => x.widthType !== ElementSizeMultiplier.auto).reduce((x, y) => x + y.width, 0);
    let autoColumnCount = this._autoWidthColumns.length;

    return getSize(ElementSizeMultiplier.auto, this._config.cellDefaultWidth, nonAutoColumnWidthSum, autoColumnCount);
  }

  private _updateValueTypeState() {
    return this._currentElement === TableElement.Column ?
      this._changeState(TableActionEnum.selectedColumnType, this._currentColumn.valueType) :
      this._changeState(TableActionEnum.selectedCellType, this._currentCell.valueType);
  }
}
