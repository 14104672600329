<div class="modal-header">
  <h4 class="modal-title">{{ isAdd ? 'Add' : 'Edit' }} header</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label" for="accountName">Header Name</label>
          <input
            class="form-control"
            formControlName="accountName"
            id="accountName"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Classification</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="sourceClassification"
            [clearable]="false"
            [items]="classifications | enumToArray"
            [readonly]="!isCustom"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label" for="headerSelect">Header</label>
          <div class="d-flex">
            <div class="flex-grow-1">
              <crs-standard-account-select
                formControlName="headerAccount"
                [clearable]="false"
                [headersOnly]="true"
                [readonly]="!isCustom || id !== addHeaderFormType.AddNew"
                [removeFilteredChildren]="true"
              />
            </div>
            <div
              class="ml-2"
              *ngIf="!isCustom || id !== addHeaderFormType.AddNew"
            >
              <a
                class="btn-sm fas-btn-primary-ghost"
                placement="left"
                popoverTitle="Change Custom Header Parent"
                [ngbPopover]="headerTooltipContent"
                [routerLink]="[]"
              >
                <i aria-hidden="true" class="fas fa-info-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Account Type</label>
          <crs-account-type-select
            formControlName="accountType"
            [clearable]="false"
            [isGrouped]="true"
            [readonly]="
              !isCustom ||
              form.value.accountTypeMode === accountTypeModes.Inherited
            "
            [useStandardAccountsAPI]="true"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Mode</label>
          <div class="input-group d-flex">
            <div class="flex-grow-1">
              <ng-select
                bindLabel="name"
                bindValue="value"
                formControlName="accountTypeMode"
                [clearable]="false"
                [items]="accountTypeModes | enumToArray"
                [readonly]="!isCustom"
              />
            </div>
            <div class="ml-2">
              <a
                class="btn-sm fas-btn-primary-ghost"
                placement="left"
                popoverTitle="Account Type Mode"
                [ngbPopover]="accountTypeContent"
                [routerLink]="[]"
              >
                <i aria-hidden="true" class="fas fa-info-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ng-template #headerTooltipContent>
        <p>
          To relocate custom headers to a different parent header, simply click
          on the
          <svg
            fill="#009999"
            height="16"
            stroke="#009999"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="2" x2="14" y1="5" y2="5" />
            <line x1="2" x2="14" y1="11" y2="11" />
          </svg>
          icon located in the last column of the grid, and then drag and drop it
          to the desired destination.
        </p>
      </ng-template>

      <ng-template #accountTypeContent>
        <p>
          Adjust this to control whether to set this manually, or whether it
          should be inherited.
        </p>
      </ng-template>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Opening Balance Account</label>
          <ng-select
            bindLabel="displayName"
            bindValue="id"
            formControlName="openingBalanceAccountId"
            [clearable]="true"
            [items]="openingBalanceAccounts"
            [readonly]="openingBalanceAccounts.length < 1"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-8">
        <h3 class="py-2">Entity types</h3>
      </div>
    </div>

    <ng-container
      *ngIf="isCustom; then restrictEntityTypes; else multipleHeaderNames"
    >
    </ng-container>

    <ng-template #restrictEntityTypes>
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label class="control-label">Restrict entity types</label>
            <ng-select
              bindLabel="name"
              bindValue="id"
              formControlName="entityTypes"
              placeholder="Applies to all entity types"
              [clearable]="true"
              [closeOnSelect]="false"
              [items]="entityTypes"
              [multiple]="true"
              [readonly]="!isCustom"
              [virtualScroll]="true"
            />
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #multipleHeaderNames>
      <div class="row">
        <div class="col-md-8">
          <div class="d-flex pb-3">
            <span class="pr-2">Show multiple header names</span>

            <div class="custom-control custom-switch">
              <input
                class="custom-control-input"
                formControlName="showMultipleHeaderNames"
                id="showMultipleHeaderNames"
                type="checkbox"
              />
              <label
                class="custom-control-label"
                for="showMultipleHeaderNames"
              ></label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="form.value.showMultipleHeaderNames">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label" for="accountNameForCompany">
                Company
              </label>
              <input
                class="form-control"
                formControlName="accountNameForCompany"
                id="accountNameForCompany"
                placeholder="Use the default header name"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label" for="accountNameForUnitTrust">
                Unit Trust
              </label>
              <input
                class="form-control"
                formControlName="accountNameForUnitTrust"
                id="accountNameForUnitTrust"
                placeholder="Use the default header name"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label
                class="control-label"
                for="accountNameForDiscretionaryTrust"
              >
                Discretionary Trust
              </label>
              <input
                class="form-control"
                formControlName="accountNameForDiscretionaryTrust"
                id="accountNameForDiscretionaryTrust"
                placeholder="Use the default header name"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label" for="accountNameForPartnership">
                Partnership
              </label>
              <input
                class="form-control"
                formControlName="accountNameForPartnership"
                id="accountNameForPartnership"
                placeholder="Use the default header name"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label" for="accountNameForSoleTrader">
                Sole Trader
              </label>
              <input
                class="form-control"
                formControlName="accountNameForSoleTrader"
                id="accountNameForSoleTrader"
                placeholder="Use the default header name"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label" for="accountNameForSuper">
                Self Managed Superannuation Fund
              </label>
              <input
                class="form-control"
                formControlName="accountNameForSuper"
                id="accountNameForSuper"
                placeholder="Use the default header name"
                type="text"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="control-label" for="accountNameForAssociation">
                Association
              </label>
              <input
                class="form-control"
                formControlName="accountNameForAssociation"
                id="accountNameForAssociation"
                placeholder="Use the default header name"
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <crs-form-error [error]="error" />
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add Header' : 'Save' }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>
</form>
