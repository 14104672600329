<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <ng-container *ngIf="route.children.length === 0">
      <li aria-current="page" class="breadcrumb-item active">Report Masters</li>
    </ng-container>
    <ng-container *ngIf="route.children.length !== 0">
      <li class="breadcrumb-item active">
        <a [routerLink]="['./']">Report Masters</a>
      </li>
      <li aria-current="page" class="breadcrumb-item active">Report Master</li>
    </ng-container>
  </ol>
</nav>

<div class="row" *ngIf="route.children.length === 0">
  <div class="col-12">
    <crs-busy *ngIf="busy.reportMasters"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-no-bottom">
      <button
        class="btn btn-secondary"
        title="Create Report Master"
        type="button"
        (click)="addReportMaster()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Report
      </button>
    </div>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="reportMastersObservable | async"
        (rowClicked)="selectReportMaster($event)"
      />
    </div>
  </div>
</div>

<router-outlet></router-outlet>
