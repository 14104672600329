import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { getDefaultGridOptions } from 'src/app/shared';
import { MatchingRuleSetsService } from './matching-rule-sets.service';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from 'src/app/core';

@Component({
  selector: 'crs-matching-rule-sets',
  templateUrl: './matching-rule-sets.component.html',
  styleUrls: ['./matching-rule-sets.component.scss'],
})
export class MatchingRuleSetsComponent {
  error: string;
  busy = {
    matchingRuleSets: false,
  };
  fileId?: string = null;
  matchingRuleSetsObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();

  constructor(
    private readonly _matchingRuleSetsService: MatchingRuleSetsService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService
  ) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.fileId = this._route.snapshot.parent.paramMap.get('id');

      if (this.fileId) {
        return this.getFileLevelMatchingRuleSets();
      }

      this.getMatchingRuleSets();
    });
  }

  getMatchingRuleSets() {
    this.busy.matchingRuleSets = true;
    this.matchingRuleSetsObservable = this._matchingRuleSetsService
      .getAllMasters$()
      .pipe(
        tap(() => (this.busy.matchingRuleSets = false)),
        catchError((err) => {
          this.showError(err);
          return of([]);
        })
      );
  }

  getFileLevelMatchingRuleSets() {
    this.busy.matchingRuleSets = true;
    this.matchingRuleSetsObservable = this._matchingRuleSetsService
      .getAll$(this.fileId)
      .pipe(
        tap(() => (this.busy.matchingRuleSets = false)),
        catchError((err) => {
          this.showError(err);
          return of([]);
        })
      );
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectMatchingRuleSet(param) {
    const matchingRulesType = this.fileId
      ? 'matching-rules'
      : 'master-matching-rules';

    const url = `./${param.data.id}/${matchingRulesType}`;
    this._router.navigate([url], { relativeTo: this._route });
  }
}
