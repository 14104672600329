<nav aria-label="breadcrumb" *ngIf="!isProfileView">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item"><a [routerLink]="['../']">Users</a></li>
    <li aria-current="page" class="breadcrumb-item active">
      {{ isAdd ? 'New' : '' }} User
    </li>
  </ol>
</nav>

<form
  class="pad-x"
  novalidate
  [formGroup]="form"
  [ngBusy]="busy.load"
  (validSubmit)="submit()"
>
  <div *ngIf="!user">
    <h2 class="form-group-title">{{ isAdd ? 'New' : '' }} User</h2>

    <hr />
  </div>

  <div class="row">
    <div class="col-md-8 col-xl-6">
      <div class="form-group" *ngIf="!isAdd && isOwnProfile">
        <img
          alt="User Image"
          class="user-img"
          ngxGravatar
          [alt]="user?.displayName"
          [email]="user?.image?.email ?? ''"
          [size]="user?.image?.size"
          [src]="user?.image?.source"
          [title]="user?.displayName"
        />
        <!-- <p><small><a href="https://en.gravatar.com/site/login" target="_blank" rel="noopener noreferrer" *ngIf="isOwnProfile">Manage Gravatar</a></small></p> -->
      </div>

      <div class="form-group" *ngIf="!isAdd && isAdmin && !isOwnProfile">
        <label class="control-label">Status</label>
        <div
          class="dropdown"
          *ngIf="
            user?.status === 'Pending' ||
              user?.status === 'Expired' ||
              user?.status === 'Active';
            else other
          "
        >
          <button
            aria-expanded="false"
            aria-haspopup="true"
            class="btn btn-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton"
            type="button"
          >
            {{ user?.status !== 'Active' ? 'Invite' : '' }} {{ user?.status }}
          </button>
          <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
            <a class="dropdown-item" [routerLink]="[]" (click)="resendInvite()">
              <i aria-hidden="true" class="fas fa-envelope"></i>
              Resend Invite
            </a>
          </div>
        </div>
        <ng-template #other>
          <div class="user-status">
            <button class="btn btn-secondary" type="button">
              {{ user?.status }}
            </button>
          </div>
        </ng-template>
      </div>

      <div class="form-group required">
        <label class="control-label">Email</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">&#64;</span>
          </div>
          <input
            class="form-control"
            formControlName="email"
            type="text"
            [readonly]="!isOwnProfile && !isAdd"
          />
        </div>
      </div>

      <div class="form-group required">
        <!--<button type="button" class="btn btn-secondary" (click)="changeSignIn()" *ngIf="isOwnProfile">
          Change Email
        </button>-->
        <button
          class="btn btn-secondary"
          type="button"
          *ngIf="isOwnProfile"
          (click)="changePassword()"
        >
          Change Password
        </button>
      </div>

      <div class="form-group required">
        <label class="control-label">First Name</label>
        <input class="form-control" formControlName="firstName" type="text" />
      </div>

      <div class="form-group required">
        <label class="control-label">Last Name</label>
        <input class="form-control" formControlName="lastName" type="text" />
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isAdmin">
    <div class="col-sm-10 col-md-5 col-xl-3">
      <div class="form-group">
        <label class="control-label">Security Role</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="role"
          [clearable]="false"
          [items]="handisoftSecurityRoles | enumToArray"
        ></ng-select>
      </div>

      <div class="form-group" *ngIf="!isAdd">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="inactive"
            type="checkbox"
          />
          <label class="form-check-label">Inactive</label>
        </div>
      </div>
    </div>
  </div>

  <div class="spacer-md" *ngIf="isAdmin"></div>

  <div class="row" *ngIf="isAdmin">
    <div class="col-12">
      <h2 class="form-group-title">Banking</h2>

      <hr />

      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="trustedAdvisor"
            type="checkbox"
            (change)="checkboxClicked()"
          />
          <label class="form-check-label"> Trust Advisor </label>
        </div>
      </div>
      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="canSendClientRequest"
            type="checkbox"
          />
          <label class="form-check-label">
            Permission to Send Client Request
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="spacer-md"></div>

  <div class="row">
    <div class="col-12">
      <h2 class="form-group-title">Practices</h2>

      <hr />

      <div class="btn-menu" *ngIf="isAdmin">
        <button
          class="btn btn-secondary"
          type="button"
          (click)="addOfficeUser()"
        >
          <i aria-hidden="true" class="fas fa-plus inset"></i>
          Add to Practice
        </button>
      </div>

      <div [class.d-none]="!userOfficesCount">
        <ag-grid-angular
          #agGridOffice
          class="ag-theme-material"
          [gridOptions]="officeGridOptions"
          [rowData]="userOffices"
        />
      </div>

      <div class="alert alert-warning" role="alert" *ngIf="!userOfficesCount">
        Please add this user to at least one practice.
      </div>

      <ng-template #optionsCellOffice let-row>
        <div class="btn-mini-menu">
          <button
            class="btn-sm fas-btn-primary-ghost"
            title="Edit Practice"
            type="button"
            (click)="editOfficeUser(row)"
          >
            <i aria-hidden="true" class="fas fa-edit"></i>
          </button>
          <button
            class="btn-sm fas-btn-ghost"
            title="Remove Practice"
            type="button"
            (click)="removeOfficeUser(row)"
          >
            <i aria-hidden="true" class="fas fa-trash"></i>
          </button>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="spacer-lg"></div>

  <div class="row">
    <div class="col-12">
      <h2 class="form-group-title">Teams</h2>

      <hr />

      <div class="btn-menu" *ngIf="isAdmin">
        <button class="btn btn-secondary" type="button" (click)="addTeamUser()">
          <i aria-hidden="true" class="fas fa-plus inset"></i>
          Add to Team
        </button>
      </div>

      <div [class.d-none]="!userTeamsCount">
        <ag-grid-angular
          #agGridTeam
          class="ag-theme-material"
          [gridOptions]="teamGridOptions"
          [rowData]="userTeams"
        />
      </div>

      <ng-template #optionsCellTeam let-row>
        <div class="btn-mini-menu">
          <button
            class="btn-sm fas-btn-primary-ghost"
            title="Edit Team"
            type="button"
            (click)="editTeamUser(row)"
          >
            <i aria-hidden="true" class="fas fa-edit"></i>
          </button>
          <button
            class="btn-sm fas-btn-ghost"
            title="Remove Team"
            type="button"
            (click)="removeTeamUser(row)"
          >
            <i aria-hidden="true" class="fas fa-trash"></i>
          </button>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="spacer"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu form-bottom-menu">
    <button
      class="btn btn-primary"
      type="submit"
      *ngIf="isAdmin || isOwnProfile"
      [promiseBtn]="busy.submit"
    >
      Save and Close
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      *ngIf="!isProfileView"
      (click)="close()"
    >
      Cancel
    </button>
    <a class="btn btn-secondary" routerLink="/clients" *ngIf="isProfileView"
      >Return to Clients</a
    >
  </div>
</form>
