import { Component, OnInit, Input } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Subject, Observable, empty, EMPTY } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  tap,
  exhaustMap,
  map,
  catchError,
  finalize,
  switchMap,
} from 'rxjs/operators';

import { MessageService } from 'src/app/core';
import { File, FileModel } from '..';
import { ActiveFileService } from 'src/app/accounting';
import { FileService } from './../file.service';

enum Action {
  Submit,
}

@Component({
  selector: 'crs-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
  @Input() id: string;
  @Input() param: FileModel;

  isAdd: boolean;
  objectTitle = 'File';
  busy = {
    load: null,
    submit: null,
    delete: null,
  };
  form = this.formBuilder.group({
    name: ['', Validators.required],
  });
  error: string = null;

  submitButtonStream = new Subject<Action>();

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private activeFileService: ActiveFileService,
    private messageService: MessageService
  ) {}

  configureSubmit() {
    this.submitButtonStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap((action) => this.submitObservable())
      )
      .subscribe((client) => {
        this.activeModal.close(client);
      });
  }

  submit() {
    this.submitButtonStream.next(Action.Submit);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  submitObservable(): Observable<File> {
    let observable: Observable<string>;
    const fileModel = new FileModel(this.form.value);
    if (this.isAdd) {
      fileModel.clientId = this.param.clientId;
      fileModel.entityId = this.param.entityId;
      observable = this.fileService.post$(fileModel);
    } else {
      fileModel.id = this.id;
      observable = this.fileService
        .put$(fileModel)
        .pipe(map(() => fileModel.id));
    }

    const loadingStream = new Subject();
    this.busy.submit = loadingStream.subscribe();

    return observable.pipe(
      switchMap((id) => this.fileService.get$(id)),
      tap((f) =>
        this.activeFileService.file.id === f.id
          ? this.activeFileService.updatefile(f, true)
          : true
      ),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  showError(error) {
    this.error = error;
  }

  ngOnInit() {
    this.configureSubmit();
    this.isAdd = this.id === 'add';
    if (this.isAdd) {
      this.form.patchValue(this.param);
    } else {
      this.busy.load = this.fileService.get$(this.id).subscribe(
        (data) => {
          this.form.patchValue(data);
        },
        (err) => this.showError(err)
      );
    }
  }
}
