import { PolicyVariant } from './policyVariant';
import { PolicyLevel } from './policyLevel';
import { PolicyLocation, PolicyAutoSelectionMethod } from './policy';

export enum PolicySelectionMode {
  Auto = 0,
  Yes = 1,
  No = 2
}

export class PolicySelector {
  id: string;
  policyChainId: string;
  fileId: string;
  name: string;
  location: PolicyLocation;
  level: PolicyLevel;
  masterPolicyId: string;
  autoSelectionMethod: PolicyAutoSelectionMethod;
  policyVariants: PolicyVariant[];

  defaultPolicyVariant: PolicyVariant;
  selectedPolicyVariantId: string;
  selectionMode: PolicySelectionMode;

  constructor(data) {
    Object.assign(this, data);
    if (data.policyVariants) this.policyVariants = data.policyVariants.map(v => new PolicyVariant(v));
    if (data.defaultPolicyVariant) this.defaultPolicyVariant = new PolicyVariant(data.defaultPolicyVariant);
  }
}

export class PolicySelectorModel {
  policyChainId: string;
  selectionMode: PolicySelectionMode;
  selectedPolicyVariantId: string;

  constructor(data) {
    this.policyChainId = data.policyChainId;
    this.selectionMode = data.selectionMode;
    this.selectedPolicyVariantId = data.selectedPolicyVariantId;
  }
}

