export class MasterBasCode {
  id: number;
  basCode: string;
  description: string;
  calculation: string;
  basForm: string;
  isTaxCode: boolean;
  isCapitalAllowed: boolean;
  isSummary: boolean;
  isDetail: boolean;

  constructor(data) {
    Object.assign(this, data);
  }
}
