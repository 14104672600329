<div class="modal-header">
  <h4 class="modal-title">Activate chart of accounts?</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="onClickClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body modal-body-padding">
  <div>
    In order for bank feeds to be enabled, the Client chart of accounts must be
    active.
    <br />
    <br />
    Activate chart of accounts?
  </div>
</div>

<div class="modal-footer">
  <button
    class="btn btn-primary"
    type="submit"
    [promiseBtn]="busy.submit"
    (click)="onClickActivateChartOfAccounts()"
  >
    Activate chart of accounts
  </button>
  <button class="btn btn-secondary" type="button" (click)="onClickClose()">
    Cancel
  </button>
</div>
