import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../../../shared/shared.module';
import {ReportEditableElementComponent} from './report-editable-element.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ReportEditableElementComponent
  ],
  exports: [
    ReportEditableElementComponent
  ]
})
export class ReportEditableElementModule {
}
