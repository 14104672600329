import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-account-header-renderer',
  template: `
    <div class="account-header-renderer">
      <i *ngIf="!params.data?.isHeader &&
          (params.data?.id === params.node?.parent?.data?.openingBalanceAccountId ||
          (params.data?.isSourceAccount && params.node?.parent?.data?.id === params.node?.parent?.parent?.data?.openingBalanceAccountId))"
          aria-hidden="true" class="fas fa-dollar-sign account-dollar-icon"></i>
      <span title="{{ getCustomTitle() }}">
        <span
          *ngIf="params.data?.isHeader"
          [title]=
        >
          <strong>{{ params.data?.accountName }} </strong>
          <small class="breadcrumbs" *ngIf="breadCrumbs">
            > {{ breadCrumbs }}
          </small>
        </span>
        <span *ngIf="!params.data?.isHeader">
          <span class="min-width: 60px">{{ params.data?.accountNo }}</span>
          {{ params.data?.accountName }}
        </span>
      </span>
    </div>
  `,
})
export class AccountHeaderRendererComponent
  implements ICellRendererAngularComp
{
  public params: any;
  breadCrumbs: string;

  private getParentNames(node: any): string[] {
    if (!node || !node.parent) {
      return [];
    }
    return [node.parent.data?.accountName, ...this.getParentNames(node.parent)];
  }

  agInit(params: any): void {
    this.params = params;

    const parentNames = this.getParentNames(this.params.node)
      .filter(Boolean)
      .reverse();
    this.breadCrumbs = parentNames.join(' > ');
  }

  refresh(): boolean {
    return false;
  }

  getCustomTitle() {
    const breadCrumbs = this.breadCrumbs ? `| ${this.breadCrumbs}` : '';

    if (this.params.data?.isHeader) {
      return `${this.params.data?.accountName} ${breadCrumbs}`.trim();
    } else {
      return `${this.params.data?.accountNo} ${this.params.data?.accountName} ${breadCrumbs}`.trim();
    }
  }
}
