import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { getDefaultGridOptions } from 'src/app/shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-add-suite-variant-modal',
  templateUrl: './add-suite-variant-modal.component.html',
})
export class AddSuiteVariantModalComponent implements OnInit, OnDestroy {
  @Input() params: {
    title: string;
    variants: [];
    type: 'policy' | 'disclosure';
  } = {
    title: null,
    variants: null,
    type: null,
  };

  gridOptions: GridOptions;
  gridApi: GridApi;
  subscriptions: Subscription[] = [];

  search = new UntypedFormControl();
  error: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.configureGridOptions();

    this.subscriptions.push(
      this.search.valueChanges
        .pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap((search) => {
            this.gridApi.setGridOption('quickFilterText', search);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  private configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      onGridReady: (event) => (this.gridApi = event.api),
      rowSelection: {
        mode: 'multiRow',
        enableClickSelection: true,
      },
      columnDefs: [
        {
          field: 'policyName',
          headerName: 'Policy',
          hide: this.params.type !== 'policy',
        },
        {
          field: 'templateName',
          headerName: 'Disclosure',
          hide: this.params.type !== 'disclosure',
        },
        {
          field: 'name',
          headerName: 'Variant',
          width: 120,
        },
      ],
    };
  }

  select() {
    this.error = null;
    const datasets = this.gridApi.getSelectedNodes().map((n) => n.data);
    if (!datasets.length) {
      this.error = 'You must select at least one ' + this.params.title;
    } else {
      this.activeModal.close(datasets);
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
