import { TableRowType } from './../enums/table-row-type.enum';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { Alignment } from '../../report-header/report-header';
import { ColumnType, ColumnTypeUtils } from '../../../../reportTemplates';
import { UntypedFormControl } from '@angular/forms';
import { ReportTableCell } from '../';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ReportTableMessageService, ReportTableService } from '../services';
import { NumberFormat, NumberFormatUtils, TableElement } from '../../../../enums';
import { moveSyntheticComments } from 'typescript';
import { DateTime } from 'luxon';

@Component({
  selector: '[crs-report-table-cell]',
  templateUrl: './report-table-cell.component.html'
})
export class ReportTableCellComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChildren('inputarea', { read: ElementRef })
  private _inputAreas: QueryList<ElementRef>;

  @Input() cell: ReportTableCell;
  @Input() rowIndex: number;
  @Input() cellIndex: number;
  @Input() isAlternative: boolean;
  @Input() isEditMode: boolean;
  @Input() isReadonly: boolean;
  @Input() crsColumnDefs: any;
  @Input() crsRowType: TableRowType;
  @Input() isSectionTableNoteHeader: boolean;

  get rounding(): number {
    if (this.cell.rounding != null)
      return this.cell.rounding;

    return NumberFormatUtils.isPercent(this.cell.numberFormat) ?
      this._reportTableService.reportStyle.percentRounding :
      this._reportTableService.reportStyle.rounding;
  }

  get cellNumberFormat(): NumberFormat {
    return NumberFormatUtils.isPercent(this.cell.numberFormat) ?
      (this.cell.numberFormat >= NumberFormat.FavourableUnfavourable ? NumberFormat.FavourableUnfavourablePercent : NumberFormat.Percent) :
      (this.cell.numberFormat ? this.cell.numberFormat : NumberFormat.Normal);
  }

  get readonly(): boolean {
    return (this.isReadonly || this.cell.parentColumn?.valueType === ColumnType.AutoTotal) && ColumnTypeUtils.isNumeric(this.cell.valueType);
  }

  public cellControl: UntypedFormControl = new UntypedFormControl(null);

  public numberFormats = NumberFormat;
  public columnValueTypes = ColumnType;
  public alignments = Alignment;
  public isEditing = false;
  public tableRowTypes = TableRowType;

  public toolsState$ = this._reportTableService.toolsState$;

  public selectedColumnOrCurrentCell$ = this.toolsState$.pipe(
    filter((state) => state.selectedElementType === TableElement.Column || state.selectedElementType === TableElement.Cell),
    filter(() => this._reportTableService.selectedColumnIndex$.getValue() === this.cellIndex)
  );

  public selectedAnotherCell$ = this.toolsState$.pipe(
    filter(() =>
      this._reportTableService.selectedRowIndex$.getValue() !== this.rowIndex ||
      this._reportTableService.selectedColumnIndex$.getValue() !== this.cellIndex));

  private regexCellNumber: RegExp = /^-?\d*\.?\d*$/;
  private validKeyList = '1234567890.-';
  private ignoredKeyList = [ 'Backspace', 'Delete', 'Control', 'Enter' ];

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly _reportTableService: ReportTableService,
              private readonly _tableMessageService: ReportTableMessageService) {
  }

  public ngOnInit(): void {
    this.selectedColumnOrCurrentCell$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.adjustValue());

    this.selectedAnotherCell$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.isEditing = false);

    this.cell.cellValue = ColumnTypeUtils.isNumeric(this.cell.valueType)
      ? this._parseCellValue(this.cell.cellValue)
      : this.cell.cellValue;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.cell && changes.cell.currentValue && !changes.cell.firstChange) {
      this.cellControl.setValue(this.cell.cellValue);
    }
  }

  public ngAfterViewInit() {
    this._inputAreas.changes
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._inputAreas.forEach(textarea => {
          this.autosize(textarea.nativeElement);
          this._setFocus(textarea.nativeElement);
        });
      });

    this._cellControlListening();
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public onSelectedCell(): void {
    this._reportTableService.setCurrentCell(this.rowIndex, this.cellIndex);
    if (this.isEditMode) {
      this.onEdit();
    }
  }

  public autosize(textarea) {
    setTimeout(function () {
      textarea.style.height = '0px';
      textarea.style.height = textarea.scrollHeight + 'px';
    }, 0);

    if (this._reportTableService.isPeriodColumnHeaderCell && textarea.value !== `Period ${ this.cellIndex }\n$`) {
      this._reportTableService.disableAutoColumnHeader();
    }
  }

  public onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Tab') {
      return;
    }

    if (!ColumnTypeUtils.isNumeric(this.cell.valueType)) {
      return;
    }

    if ($event.ctrlKey && $event.key === 'v') {
      return;
    }

    if (this.ignoredKeyList.includes($event.key)) {
      return;
    }

    if (!this.validKeyList.includes($event.key as string)) {
      $event.preventDefault();
    }
  }

  public onBeforeinput($event: InputEvent) {
    if ($event.inputType === 'insertFromPaste') {
      if (!this.isValid($event.data ?? '')) {
        this._invalidTypeDialog();
        $event.preventDefault();
      }
    }
  }

  public onFocus($event: FocusEvent) {
    this.onSelectedCell();

    if (this.cellControl.value === '-') {
      this.cellControl.setValue('');
    }
  }

  public adjustValue() {
    if (this.isValid(this.cellControl.value)) {
      if (!this.cellControl.value) {
        this.cellControl.setValue(this.cell.cellValue);
      }
    } else {
      if(ColumnTypeUtils.isNumeric(this.cell.valueType))
      {
        this._tableMessageService.invalidTypeDialog(() => this.cellControl.setValue(this.cell.cellValue), "Please ensure the input value is numeric and limited to 14 numerals before the decimal point", "Invalid Numeric Value");
      }
      else
      {
        this._tableMessageService.invalidTypeDialog(() => this.cellControl.setValue(this.cell.cellValue));
      }
    }
  }

  private onEdit(): void {
    this.cellControl.setValue(this.cell.cellValue);

    this.isEditing = true;

    this.adjustValue();
  }

  private _cellControlListening() {
    this.cellControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (this.isValid(value)) {
          this.cell.cellValue = ColumnTypeUtils.isNumeric(this.cell.valueType) ? this._parseCellValue(value) : value;
          this._setDefaultRounding();
          this._reportTableService.setRowAndColumnTotalValues();
        }
      });
  }

  private _setDefaultRounding() {
    if (ColumnTypeUtils.isNumeric(this.cell.valueType) && !this.cell.rounding) {
      this.cell.rounding = this.rounding;
    }
  }

  private _parseCellValue(value: string | number): string {
    const parsedValue = parseFloat(value + '');

    return isNaN(parsedValue) || parsedValue === 0 ? '-' :
      (NumberFormatUtils.isPercent(this.cell.numberFormat) ? value as string: parsedValue.toFixed(this.cell.rounding));
  }

  private isValid(value): boolean {
    if (!value) {
      return true;
    }

    if (this.cell.valueType === ColumnType.Text) {
      return true;
    }

    if(this.cell.valueType === ColumnType.Date){
      return DateTime.fromISO(value).isValid;
    }

    if (ColumnTypeUtils.isNumeric(this.cell.valueType) && this.regexCellNumber.test(value)) {
      if(value > 99999999999999)
      {
        return false;
      }
      return true;
    }

    return false;
  }

  get isHeaderRowCell(): boolean {
    return this.crsRowType == TableRowType.ColumnHeader || this.crsRowType == TableRowType.Header || this.crsRowType == TableRowType.HeaderColumnGroups;
  }

  private _invalidTypeDialog(): void {
    if(ColumnTypeUtils.isNumeric(this.cell.valueType))
    {
      this._tableMessageService.invalidTypeDialog(null, "Please ensure the input value is numeric and limited to 14 numerals before the decimal point", "Invalid Numeric Value");
    }
    else
    {
      this._tableMessageService.invalidTypeDialog();
    }
  }

  private _setFocus(textarea): void {
    textarea.focus();
  }
}
