<div>
  <div class="alert alert-warning" role="alert" *ngIf="hasUnSubscribedWebHooks">
    <i aria-hidden="true" class="fas fa-exclamation-triangle pe-1"></i>
    "A number of Akahu bank connections have un-subscribed webhooks. Please
    subscribe to them here."
    <button
      class="ms-3 btn btn-primary"
      type="button"
      (click)="subscribeAllMissingAkahuWebhooks()"
    >
      Subscribe All
    </button>
  </div>

  <div
    class="center container-grey-bg p-5"
    *ngIf="bankConnections && !bankConnections.length"
  >
    <h2 class="pb-2">You don’t currently have any bank connections</h2>
    <h3>Once you add a bank connection it will be stored here</h3>
  </div>

  <div class="bank-connection-panel" *ngFor="let connection of bankConnections">
    <div class="panel">
      <!-- If connection is Manual -->
      <div
        *ngIf="
          connection.transactionProvider === 'Manual';
          else bankConnectionDetail
        "
      >
        <div
          class="bank-connection-panel-heading"
          [ngClass]="{
            'panel-header-border':
              !!connection.bankAccounts?.length && !!connection.state,
          }"
        >
          <span><strong>Manual Import</strong></span>
        </div>
      </div>

      <!-- Otherwise, show bank connections -->
      <ng-template #bankConnectionDetail>
        <div
          class="bank-connection-panel-heading"
          [ngClass]="{
            'panel-header-border':
              connection.bankAccounts?.length && connection.state,
          }"
        >
          <div class="bank-connection-panel-heading-text-container">
            <span>
              <strong>Bank connection</strong>
              <span
                class="bank-state"
                [ngClass]="{
                  disconnected:
                    connection.state == bankConnectionStates.Disconnected,
                  pending: connection.state == bankConnectionStates.Pending,
                  active: connection.state == bankConnectionStates.Active,
                }"
              >
                {{ bankConnectionStates[connection.state] }}
                <i
                  aria-hidden="true"
                  class="fas fa-exclamation-triangle text-warning"
                  *ngIf="!connection.webhooksState"
                ></i>
              </span>
            </span>

            <span>
              <strong>Client name:</strong>
              <strong class="text-gray">
                {{ connection.name }} ({{ connection.email }})
              </strong>
            </span>

            <span>
              <strong>Expiry date:</strong>
              <strong class="text-gray">
                {{ toDateString(connection?.expiryDate) }}
              </strong>
            </span>

            <span>
              <strong>Last refresh date:</strong>
              <strong class="text-gray">
                {{ toDateString(connection?.lastRefreshLocalDate) }}
              </strong>
            </span>

            <span>
              <strong>Transaction provider:</strong>
              <strong class="text-gray">
                {{ connection.transactionProvider }}
              </strong>
            </span>
          </div>
          <div class="settings">
            <button
              class="fas-btn-primary-ghost"
              type="button"
              *ngIf="
                connection.transactionProvider !== transactionProvider.Manual
              "
              (click)="openBankConnectionSettingsModal(connection)"
            >
              <i aria-hidden="true" class="fas fa-cog align-middle"></i>
            </button>
          </div>
        </div>
      </ng-template>

      <div class="bank-account" *ngIf="!!connection.bankAccounts.length">
        <div class="bank-account-item-header">
          <div><strong>Bank account</strong></div>
          <div><strong>Chart code</strong></div>
          <div class="text-right"><strong>Hide</strong></div>
          <div></div>
        </div>

        <div
          class="bank-account-item"
          *ngFor="let bankAccount of connection.bankAccounts"
        >
          <div class="account-name">
            {{ bankAccount.accountName }}
            <span
              class="badge badge-danger"
              *ngIf="bankAccount.state === bankAccountStates.Inactive"
              >Inactive</span
            >
          </div>

          <div class="chart-code-select">
            <ng-select
              bindLabel="combinedLabel"
              placeholder="Select account"
              [(ngModel)]="getBindValueAndModel(bankAccount).model"
              [bindValue]="getBindValueAndModel(bankAccount).bindValue"
              [clearable]="false"
              [items]="filteredAccounts$ | async"
              [typeahead]="searchAccountsSubject"
              [virtualScroll]="true"
              (change)="updateBankAccountChartCodes($event, bankAccount)"
            >
              <ng-template
                let-item="item"
                let-search="searchTerm"
                ng-option-tmp
              >
                <div
                  class="ng-option-tmp d-flex justify-content-between"
                  [title]="item.combinedLabel"
                >
                  <span class="account-name" [ngOptionHighlight]="search">{{
                    item.accountName
                  }}</span>
                  <span class="ps-2" [ngOptionHighlight]="search">{{
                    item.accountNo
                  }}</span>
                </div>
              </ng-template>
            </ng-select>
            <i class="fa fa-chevron-down"></i>
          </div>

          <div class="is-hidden-checkbox">
            <crs-check-box
              [isInverted]="true"
              [ngModel]="bankAccount.isHidden"
              (change)="updateBankAccountIsHidden($event, bankAccount)"
            />
          </div>

          <div>
            <button
              class="btn btn-secondary"
              type="button"
              (click)="onClickTranferAccount(bankAccount)"
            >
              Transfer account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
