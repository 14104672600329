<div class="modal-header">
  <h4 class="modal-title">Save As</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="confirm()" novalidate>

  <div class="modal-body">

    <div [innerHTML]="params.text"></div>
    <div class="form-group">
      <input type="text" placeholder="Name..." class="form-control" formControlName="name" crsAutoFocus />
    </div>
    
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
  </div>

</form>
