<ul class="navbar-nav">
  <li class="nav-item">
    <a [routerLink]="[]" queryParamsHandling="merge" class="nav-link" (click)="selectPageIndex(activePageIndex - 1)"
       [class.disabled]="activePageIndex <= 0">
      <i class="fas fa-chevron-left" aria-hidden="true" aria-hidden="true"></i>
    </a>
  </li>
  <li class="nav-item dropdown page-selector">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
       aria-haspopup="true" aria-expanded="false">
      {{pages[activePageIndex].title}}
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <a class="dropdown-item" [routerLink]="[]" queryParamsHandling="merge" (click)="selectPage(page)"
         *ngFor="let page of pages; let i = index">
        {{page.title}} <span class="badge badge-warning badge-pill"
                             *ngIf="page.warnings && page.warnings.length">{{page.warnings.length}}</span>
      </a>
    </div>
  </li>
  <li class="nav-item">
    <a [routerLink]="[]" queryParamsHandling="merge" class="nav-link" (click)="selectPageIndex(activePageIndex + 1)"
       [class.disabled]="activePageIndex >= pages.length - 1">
      <i class="fas fa-chevron-right" aria-hidden="true"></i>
    </a>
  </li>
</ul>
