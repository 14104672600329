import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { SessionService } from './session.service';
import { ApiService } from './api.service';
import { MessageService } from '../messages/message.service';
import { User } from '../../firm/users/user';
import { ApiError } from '../interceptors/api-error';
import { AuthConfig } from '../authConfig';
import { SecurityRole } from 'src/app/firm/users';
import { FeatureFlagsService } from './feature-flag.service';

declare const window: any;

export enum OnLoginResult {
  Success,
  NotAuthorised,
  Requires2FA,
  NotLoggedIn,
  ServerDown,
  InvalidState,
  InactiveUser,
  NotRegistered,
}

class CustomClaims {
  mfa_enabled: string;
  crs_role: string[] | string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private oauthService: OAuthService,
    private sessionService: SessionService,
    private featureFlagsService: FeatureFlagsService,
    private router: Router,
    private apiService: ApiService,
    private messageService: MessageService
  ) {}

  public login(state: string = null) {
    if (!state) state = this.router.url;
    console.log('Logging in', state);
    this.oauthService.initLoginFlow(state);
  }

  public logout() {
    if (this.oauthService.revocationEndpoint)
      this.oauthService.revokeTokenAndLogout();
    else this.oauthService.logOut();
  }

  public changeSignIn() {
    this.oauthService.redirectUri =
      AuthConfig.redirectUri + '/b2c_1a_v1_fa_sign_in_name_change';
    this.oauthService
      .loadDiscoveryDocument(AuthConfig.b2c_1a_v1_fa_sign_in_name_change)
      .then(() => this.login());
  }

  public changePassword() {
    this.oauthService.redirectUri =
      AuthConfig.redirectUri + '/b2c_1a_v1_fa_password_change';
    this.oauthService
      .loadDiscoveryDocument(AuthConfig.b2c_1a_v1_fa_password_change)
      .then(() => this.login());
  }

  public signUp(idTokenHint) {
    this.oauthService.redirectUri =
      AuthConfig.redirectUri + '/b2c_1a_v1_fa_sign_up';
    this.oauthService.customQueryParams = {
      id_token_hint: idTokenHint,
      prompt: 'login', //fixes issue caused by another login on another browser tab
    };
    this.oauthService
      .loadDiscoveryDocument(AuthConfig.b2c_1a_v1_fa_sign_up)
      .then(() => this.login());
  }

  public onLogin(): Promise<OnLoginResult> {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      if (claims.fa_user_id) {
        //check for Access Ledger specific claims
        console.log('Access Ledger specific claims exist');

        if (
          this.sessionService.active &&
          this.sessionService.identityId === claims.fa_user_id
        ) {
          return Promise.resolve(OnLoginResult.Success);
        }

        const customClaims = this.getCustomClaims();
        //if (!this.checkMfa(claims, customClaims)) return Promise.resolve(OnLoginResult.Requires2FA);

        this.sessionService.load(claims.fa_user_id);

        // Can't access user service directly as it results in a circular reference
        return this.apiService
          .get<User>('api/users/profile')
          .toPromise()
          .then(async (profile) => {
            const isLocal = window.location?.href?.includes('localhost');
            const isDev = window.location?.href?.includes('dev-');
            const isQA = window.location?.href?.includes('qa-');

            if (!isLocal) {
              try {
                window.Intercom('boot', {
                  app_id: 'eswxt0ve',
                  email: claims.email,
                  user_id: claims.fa_user_id,
                  name: `${profile.firstName} ${profile.lastName}`,
                  firm_Id: claims.fa_firm_id,
                  tenant_Id: profile.tenantId,
                });
              } catch (e) {
                console.warn('Error loading Intercom', e);
              }
            }
            const excludedDomains = ['theaccessgroup.com'];

            const emailIsExcluded = excludedDomains.some(function (domain) {
              return (
                claims.email.toLowerCase().indexOf(domain.toLowerCase()) > -1
              );
            });

            if (!emailIsExcluded) {
              try {
                const createdAtTimestamp = Math.floor(
                  new Date(profile.createdDate).getTime() / 1000
                );

                window.wootricSettings = {
                  email: claims.email,
                  created_at: createdAtTimestamp,
                  product_name: 'Access Ledger',
                  account_token:
                    isLocal || isDev || isQA ? 'NPS-8a385e4e' : 'NPS-8c44132d',
                  properties: {
                    product: 'Access Ledger',
                    customerName: `${profile.firstName} ${profile.lastName}`,
                    userRole: SecurityRole[profile.role],
                    productReportingCode: 665,
                    deviceOS: 'Web',
                  },
                  disclaimer: {
                    // required GDPR Obligation - LEGAL
                    text: 'Learn how we handle your feedback',
                    link: 'https://www.theaccessgroup.com/en-gb/privacy-notice/',
                    link_word: 'here',
                  },
                };

                window.wootric('run');
              } catch (e) {
                console.warn('Error loading Wootric sdk', e);
              }
            }

            this.sessionService.loadUser(
              profile,
              this.getIsMaster(customClaims)
            );

            if (!isLocal) {
              try {
                await this.featureFlagsService.loadFeatureFlagsForUser();
              } catch (e) {
                console.error('Error loading feature flags', e);
              }
            }

            return this.sessionService.active
              ? OnLoginResult.Success
              : OnLoginResult.InactiveUser;
          })
          .catch((err) => {
            if (err instanceof ApiError) {
              console.log('error logging in', err);
              if (err.code === 0 || err.code === 500)
                return OnLoginResult.ServerDown;
              if (err.code === 401) return OnLoginResult.NotRegistered;
              return OnLoginResult.NotAuthorised;
            }
            this.messageService.error(
              'Error logging you in. ' + err.messageString
            );
            return OnLoginResult.NotAuthorised;
          });
      } else {
        console.log('no Access Ledger specific claims');
        this.sessionService.destroy();
        return Promise.resolve(OnLoginResult.NotRegistered); // claims exist, but no Access Ledger custom claims
      }
    }
    return Promise.resolve(OnLoginResult.NotLoggedIn); // no claims returned
  }

  public onLogout() {
    this.sessionService.destroy();
  }

  public getSession() {
    const claims = this.oauthService.getIdentityClaims();
    return JSON.stringify(claims);
  }

  private getCustomClaims(): CustomClaims {
    const accessToken = this.oauthService.getAccessToken();
    const customClaims = JSON.parse(atob(accessToken.split('.')[1]));
    return customClaims as CustomClaims;
  }

  private getIsMaster(customClaims: CustomClaims) {
    const roles = customClaims.crs_role;
    if (typeof roles === 'string') return false;
    return roles && roles.length && roles.some((r) => r === 'master');
  }
}
