import {
  TransactionDescriptionEditorComponent,
  TransactionAccountEditorComponent,
  TransactionTaxEditorComponent
} from "src/app/baco/ag-grid/editors";
import {
  TransactionAccountRendererComponent,
  TransactionTypeRenderComponent,
  TransactionTaxRendererComponent
} from 'src/app/baco/ag-grid/renders';
import { renderers } from "src/app/shared";


export const BACO_TRANSACTION_RENDERS = {
  ...renderers,
  'accountRender': TransactionAccountRendererComponent,
  'accountEditor': TransactionAccountEditorComponent,

  'transactionTypeRender': TransactionTypeRenderComponent,

  'taxRender': TransactionTaxRendererComponent,
  'taxEditor': TransactionTaxEditorComponent,

  'descriptionEditor': TransactionDescriptionEditorComponent
};
