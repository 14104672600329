import { CurrentClassification } from './../classifications/classification';
import { Classification } from '../classifications/classification';

export class MasterAccountUltraLight {
  id: number;
  accountName: string;
  classification: Classification;
  classificationLabel: string;

  constructor(data: any) {
    this.id = data.id ? parseInt(data.id, 10) : null;
    this.accountName = data.accountName;
    this.classification = data.classification ? parseInt(data.classification, 10) : null;
    if (data.classification) {
      if (data.currentClassification) {
        this.classificationLabel = CurrentClassification[data.currentClassification] + ' ' + Classification[data.classification];
      } else {
        this.classificationLabel = Classification[data.classification];
      }
    }
  }
}
