import { ColumnType } from "src/app/accounting/reports/reportTemplates";
import { ReportHeaderColumn } from "../../../report-output";
import { TableRowType } from "../enums";
import { ReportTableRow } from "../models";

export class ReportTableUtility {
  public static setAutoColumnHeaders(dataSetColumns: ReportHeaderColumn[], reportTable: any) {
    let autoColumnHeaderRows = reportTable.rows.filter(r => r.rowType == TableRowType.ColumnHeader && r.allowAutoColumnHeader && !!r.enableAutoColumnHeader);
    if (autoColumnHeaderRows.length) {
      for (let i = 0; i < dataSetColumns?.length && i < 2; i++) { // `i < 2` is intentionally requested by business for first iteration
        const columnTitle = [dataSetColumns[i].headerText, dataSetColumns[i].periodText, dataSetColumns[i].signText]
          .filter(x => !!x)
          .join('\n');

        autoColumnHeaderRows.forEach(r => r.cells[i + 1].cellValue = columnTitle);
      }
    }
  }

  public static setRowAndColumnTotalValues(reportTable: any) {
    reportTable.rows.forEach((row, rowIndex) => {
      if (row.sections?.length > 0) {
        row.cells.forEach((cell, cellIndex) => {
          if (cell.valueType !== ColumnType.Number)
            return;

          let calculationCells = [];
          switch (row.rowType) {
            case TableRowType.AutoTotal:
              calculationCells = reportTable.rows
                .filter((r, i) => this.isRelevantForAutoTotalCalculation(r, i, rowIndex, row.sections))
                .map(r => r.cells[cellIndex]);
              break;
            case TableRowType.AutoGrandTotal:
              calculationCells = reportTable.rows
                .filter((r, i) => this.isRelevantForAutoTotalCalculation(r, i, rowIndex, row.sections))
                .map(r => r.cells[cellIndex]);
              break;
            default:
              if (cell.parentColumn?.valueType === ColumnType.AutoTotal)
                calculationCells = row.cells
                  .filter((c, i) => c.valueType === ColumnType.Number && i !== cellIndex);
          }

          if (!!calculationCells.length) {
            const totalValue = calculationCells
              .map((c) => this.parseCellValue(c?.cellValue))
              .reduce((a, b) => a + b, 0);
            cell.cellValue = totalValue == 0 ? "-" : totalValue;
          }
        });
      }
    });
  }

  private static isRelevantForAutoTotalCalculation(selectedRow: ReportTableRow, selectedIndex: number, autoTotalRowIndex: number, sections: string[]) {
    return selectedRow.rowType === TableRowType.Row &&
      selectedRow.sections?.some(x => sections?.some(y => y === x)) && selectedIndex !== autoTotalRowIndex;
  }

  private static parseCellValue(cellValue: any): number {
    const parsed = parseFloat(cellValue + '');

    return isNaN(parsed) ? 0 : parsed;
  }
}