import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  catchError,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';

import { EMPTY, Subject, Subscription, of } from 'rxjs';
import { Dataset, DatasetService } from 'src/app/accounting';

class LoadSet {
  fileId: string;
  startDate: Date;
  endDate: Date;
  entityId: string;
  excludeGroupDatasets: boolean;
}

@Component({
  selector: 'crs-dataset-select',
  templateUrl: './dataset-select.component.html',
  styleUrls: ['./dataset-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatasetSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DatasetSelectComponent implements OnInit, OnDestroy {
  private _fileId: string;
  private _entityId: string;
  private _startDate: Date = null;
  private _endDate: Date = null;
  private _excludeGroupDatasets = false;

  private _datasets: Dataset[] = [];
  private _initialised = false;
  private _manual = false; // are datasets provided or do we search for them?

  @Input() set datasets(value: Dataset[]) {
    this._manual = true;
    this.updateDatasets(value);
  }

  get datasets(): Dataset[] {
    return this._datasets;
  }

  @Input() set fileId(value: string) {
    if (this._fileId !== value) {
      this._fileId = value;
      if (this._initialised) this.loadDatasets();
    }
  }

  @Input() set entityId(value: string) {
    if (this._entityId !== value) {
      this._entityId = value;
      if (this._initialised) this.loadDatasets();
    }
  }

  @Input() set startDate(value: Date) {
    if (this._startDate !== value) {
      this._startDate = value;
      if (this._initialised) this.loadDatasets();
    }
  }

  @Input() set endDate(value: Date) {
    if (this._endDate !== value) {
      this._endDate = value;
      if (this._initialised) this.loadDatasets();
    }
  }

  @Input() set excludeGroupDatasets(value: boolean) {
    if (this._excludeGroupDatasets !== value) {
      this._excludeGroupDatasets = value;
      if (this._initialised) this.loadDatasets();
    }
  }

  @Input() placeholder: string = null;
  @Input() readonly: boolean;

  loadStream = new Subject<LoadSet>();
  loadSubscription: Subscription;

  @ViewChild(NgSelectComponent, { static: true })
  private _valueAccessor: ControlValueAccessor;

  groupByFn: (item: Dataset) => string;
  groupValueFn: (
    _: string,
    children: Dataset[]
  ) => { name: any; total: number };
  compareWithFn = (a: Dataset, b: Dataset) =>
    (a === null && b === null) || a.id === b.id;

  constructor(private datasetService: DatasetService) {}

  setGrouping(show: boolean) {
    this.groupByFn = !show ? null : (item: Dataset) => item.entity.legalName;
    this.groupValueFn = !show
      ? null
      : (_: string, children: Dataset[]) => ({
          name: children[0].entity.legalName,
          total: children.length,
        });
  }

  writeValue(value: any | any[]): void {
    this._valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }

  loadDatasets() {
    if (this._manual) return;
    const loadSet = new LoadSet();
    loadSet.fileId = this._fileId;
    loadSet.entityId = this._entityId;
    loadSet.startDate = this._startDate;
    loadSet.endDate = this._endDate;
    loadSet.excludeGroupDatasets = this._excludeGroupDatasets;

    this.loadStream.next(loadSet);
  }

  configureLoadStream() {
    this.loadSubscription = this.loadStream
      .pipe(
        distinctUntilChanged(
          (prev, curr) =>
            prev.fileId === curr.fileId &&
            prev.endDate === curr.endDate &&
            prev.startDate === curr.startDate &&
            prev.excludeGroupDatasets === curr.excludeGroupDatasets
        ),
        switchMap((param) => {
          if (!param.fileId) return of([]);
          return this.datasetService
            .getAll$(
              param.fileId,
              param.entityId,
              param.startDate,
              param.endDate,
              param.excludeGroupDatasets
            )
            .pipe(catchError(() => EMPTY));
        }),
        tap((datasets) => this.updateDatasets(datasets))
      )
      .subscribe();
  }

  updateDatasets(datasets: Dataset[]) {
    this._datasets = datasets;
    this.setGrouping(
      !datasets?.length ||
        datasets.some((d) => d.entity.id !== datasets[0].entity.id)
    );
  }

  ngOnInit() {
    // Sometimes this hasn't been set prior to writing value, possible bug in ng-select
    (this._valueAccessor as NgSelectComponent).bindLabel = 'name';
    this.configureLoadStream();

    this._initialised = true;
    this.loadDatasets();
  }

  ngOnDestroy() {
    this.loadSubscription.unsubscribe();
  }
}
