<div class="modal-header">
  <h4 class="modal-title">
    Disclosure - Linked Account
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

  <div class="modal-body">

    <div class="row">
      <div class="col-form-md">

        <div formGroupName="headerMatch">

          <div class="form-group required" *ngIf="accountSelectType == 'account'">
            <label class="control-label">Account</label>
            <crs-account-select [includeChildren]="false" [placeholder]="headerMatchDisplay" formControlName="account"></crs-account-select>
          </div>

          <div class="form-group required" *ngIf="accountSelectType == 'master'">
            <label class="control-label">Account</label>
            <crs-master-account-select [placeholder]="headerMatchDisplay" formControlName="masterAccount"></crs-master-account-select>
          </div>

        </div>

        <div class="form-group required">
          <label class="control-label">Link Option</label>
          <ng-select [items]="linkOptions | enumToArray" bindLabel="name" bindValue="value" [clearable]="false" formControlName="linkOption"></ng-select>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" formControlName="forceLinkOption">
            <label class="form-check-label">Force Link Option</label>
            <small class="form-text text-muted">
              Prevents the user from being able to change the link option
            </small>
          </div>
        </div>

        <div class="form-group">
          <label class="control-label">Account Display Option</label>
          <ng-select [items]="layoutOptions | enumToArray" placeholder="User determined" bindLabel="name" bindValue="value" formControlName="layoutOption"></ng-select>
        </div>

        <div class="form-group">
          <label class="control-label">Placeholder Identifier</label>
          <input type="search" placeholder="User default" class="form-control" formControlName="placeholderIdentifier">
        </div>

      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  </div>

</form>
