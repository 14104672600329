import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/core';
import { DistributionRow, DistributionRowToSave } from './distribution';

@Injectable({
  providedIn: 'root',
})
export class DistributionService {
  readonly base: string = 'api/accounting/ledger/auto-journals/distributions';

  constructor(private apiService: ApiService) {}

  get$(datasetId: string): Observable<DistributionRow[]> {
    return this.apiService
      .get<DistributionRow[]>(`${this.base}/${datasetId}`)
      .pipe(
        map((distributionRows) =>
          distributionRows.map(
            (distributionRow) => new DistributionRow(distributionRow)
          )
        )
      );
  }

  save$(datasetId: string, distributionRows: DistributionRowToSave[]) {
    return this.apiService.put(`${this.base}/${datasetId}`, distributionRows);
  }

  delete$(datasetId: string) {
    return this.apiService.delete(`${this.base}/${datasetId}`);
  }
}
