<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="loginInProgress">
  <crs-api-authorisation
    *ngFor="let r of resetLogin"
    [apiType]="form.value.sourceType.apiType"
    (loginResponseReceived)="onLoginResponse($event)"
  ></crs-api-authorisation>
</div>

<form
  novalidaten
  *ngIf="!loginInProgress"
  [formGroup]="form"
  (validSubmit)="submit()"
>
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div
      class="modal-section"
      *ngIf="!isAdd && form.value && form.value.sourceType"
    >
      <img
        alt="Image of {{ form.value.sourceType.name }}"
        class="align-self-center"
        height="80"
        src="{{ form.value.sourceType.image }}"
        [alt]="form.value.sourceType.name"
      />
    </div>

    <!-- Select Source Type -->
    <div class="modal-section card" *ngIf="isAdd">
      <h2
        class="card-header card-header-collapsible form-group-title"
        [attr.aria-expanded]="!sourceTypesCollapsed"
        (click)="collapseSourceTypes.toggle()"
      >
        Source Types
        <i
          aria-hidden="true"
          class="fas"
          [ngClass]="{
            'fa-caret-down': sourceTypesCollapsed,
            'fa-caret-up': !sourceTypesCollapsed,
          }"
        ></i>
      </h2>

      <div
        #collapseSourceTypes="ngbCollapse"
        [(ngbCollapse)]="sourceTypesCollapsed"
      >
        <div class="card-body">
          <div class="alert alert-primary">
            Please select a 'Source Type' from the list below.
          </div>

          <div class="btn-menu" [ngBusy]="[busy.loadSourceTypes]">
            <button
              class="btn btn-card"
              type="button"
              *ngFor="let type of sourceTypes"
              [class.active]="form.controls['sourceType']?.value?.id == type.id"
              [disabled]="type.apiTypeDetail.isDisabled"
              (click)="selectSourceType(type)"
            >
              <div class="d-inline-block position-relative">
                <img
                  height="50"
                  src="{{ type.icon }}"
                  title="{{ type.name }}"
                  [alt]="type.name"
                />
                <br />
                <p>{{ type.name }}</p>
                <p style="font-size: 11px">{{ type.apiTypeDetail.message }}</p>
              </div>
              <div class="position-absolute float-right ms-80px">
                <small
                  ><span
                    class="badge badge-danger beta-tag"
                    *ngIf="type?.isBeta"
                    >BETA</span
                  ></small
                >
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Select File if Reportance API -->
    <div
      class="modal-section card"
      *ngIf="
        form.value?.sourceType?.apiTypeDetail?.userLevelApi &&
        form.value?.sourceType?.isReportance
      "
    >
      <h2
        class="card-header card-header-collapsible form-group-title"
        [attr.aria-expanded]="!fileListCollapsed"
        (click)="collapseFileList.toggle()"
      >
        Access Ledger Files
        <i
          aria-hidden="true"
          class="fas"
          [ngClass]="{
            'fa-caret-down': fileListCollapsed,
            'fa-caret-up': !fileListCollapsed,
          }"
        ></i>
      </h2>

      <div #collapseFileList="ngbCollapse" [(ngbCollapse)]="fileListCollapsed">
        <div class="card-body">
          <div class="alert alert-primary">
            Please choose an Access Ledger File below
          </div>
          <div class="row">
            <div class="col-md-10 col-lg-8">
              <div class="form-group">
                <label class="control-label">File</label>
                <crs-file-select formControlName="cirrusFile"></crs-file-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Provide Tenant Id if required -->
    <div
      class="modal-section card"
      *ngIf="
        form.value?.sourceType?.apiTypeDetail?.requiresTenantId &&
        !form.value?.sourceType?.isReportance
      "
    >
      <h2
        class="card-header card-header-collapsible form-group-title"
        [attr.aria-expanded]="tenantSelectCollapsed"
        (click)="collapseTenant.toggle()"
      >
        Tenant
        <i
          aria-hidden="true"
          class="fas"
          [ngClass]="{
            'fa-caret-down': tenantSelectCollapsed,
            'fa-caret-up': !tenantSelectCollapsed,
          }"
        ></i>
      </h2>

      <div
        #collapseTenant="ngbCollapse"
        [(ngbCollapse)]="tenantSelectCollapsed"
      >
        <div class="card-body">
          <div *ngIf="loginRequired; else notRequiredTenantSection">
            <div class="alert alert-primary">
              You must login to {{ form.value.sourceType.name }} in order to
              select an accounting file.
            </div>
            <button
              class="btn btn-secondary"
              type="button"
              (click)="attemptLoginAndLoadFiles()"
            >
              Login Now
            </button>
          </div>

          <ng-template #notRequiredTenantSection>
            <div class="alert alert-primary">
              Enter your tenant id to see the list of accounting files.
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="control-label">Tenant Id</label>
                  <input
                    autocomplete="off"
                    class="form-control not-readonly"
                    formControlName="apiTenantId"
                    type="text"
                    [attr.disabled]="apiTenantIdSet ? true : null"
                    title="Tenant id"
                  />
                </div>
                <button
                  class="btn btn-secondary"
                  type="button"
                  *ngIf="!apiTenantIdSet"
                  (click)="setTenantId(form.value.apiTenantId, true)"
                >
                  Set Tenant Id
                </button>
              </div>

              <div class="col-6">
                <div class="btn-menu text-right mt-4">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    (click)="
                      apiTenantIdSet
                        ? attemptLoginAndRemoveTenant()
                        : attemptLoginAndLoadFiles()
                    "
                  >
                    Switch {{ form.value?.sourceType?.name }} account
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Select Accounting File if User Level API -->
    <div
      class="modal-section card"
      *ngIf="
        form.value?.sourceType?.apiTypeDetail?.userLevelApi &&
        !form.value?.sourceType?.isReportance &&
        (!form.value?.sourceType?.apiTypeDetail?.requiresTenantId ||
          apiTenantIdSet)
      "
    >
      <h2
        class="card-header card-header-collapsible form-group-title"
        [attr.aria-expanded]="accountingFilesCollapsed"
        (click)="collapseAccountingFiles.toggle()"
      >
        Accounting Files
        <i
          aria-hidden="true"
          class="fas"
          [ngClass]="{
            'fa-caret-down': accountingFilesCollapsed,
            'fa-caret-up': !accountingFilesCollapsed,
          }"
        ></i>
      </h2>

      <div
        #collapseAccountingFiles="ngbCollapse"
        [(ngbCollapse)]="accountingFilesCollapsed"
      >
        <div class="card-body">
          <div *ngIf="loginRequired; else notRequiredAccountingSection">
            <div class="alert alert-primary">
              You must login to {{ form.value.sourceType.name }} in order to
              select an accounting file.
            </div>
            <button
              class="btn btn-secondary"
              type="button"
              (click)="attemptLoginAndLoadFiles()"
            >
              Login Now
            </button>
          </div>

          <ng-template #notRequiredAccountingSection>
            <div [ngBusy]="[busy.loadFiles]">
              <div class="alert alert-primary">
                Select the accounting file that you wish to use from the list
                below.
              </div>

              <div class="row justify-content-end me-1">
                <div class="form-group col-4">
                  <div class="icon-input-wrapper flex-grow-1 me-2">
                    <i aria-hidden="true" class="fas fa-search input-icon"></i>
                    <input
                      aria-describedby="searchIcon"
                      placeholder="Search..."
                      type="search"
                      [formControl]="search"
                    />
                  </div>
                </div>

                <div class="btn-menu">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    *ngIf="
                      form.value?.sourceType?.apiTypeDetail?.requiresFileAdd
                    "
                    (click)="addFile()"
                  >
                    Add {{ form.value?.sourceType?.name }} File
                  </button>
                  <button
                    class="btn btn-secondary"
                    type="button"
                    *ngIf="
                      !form.value?.sourceType?.apiTypeDetail?.requiresTenantId
                    "
                    (click)="attemptLoginAndLoadFiles()"
                  >
                    Switch {{ form.value?.sourceType?.name }} account
                  </button>
                  <button
                    class="btn btn-secondary"
                    title="Refresh Files List"
                    type="button"
                    (click)="getAccountingFiles(form.value.sourceType)"
                  >
                    <i aria-hidden="true" class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>

              <ag-grid-angular
                #agGrid
                class="ag-theme-material calas1"
                domLayout="normal"
                rowClass="selectable-row"
                style="width: 100%; height: 350px"
                [getRowNodeId]="getRowNodeId"
                [gridOptions]="gridOptions"
                [rowData]="files"
                (gridReady)="onGridReady($event)"
                (rowClicked)="selectFile($event)"
                (rowDataChanged)="updateSelection($event)"
              />
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Connect File if API -->
    <div
      class="modal-section card"
      *ngIf="
        form.value?.sourceType?.apiType !== 0 &&
        form.value?.sourceType?.isReportance === false &&
        form.value?.sourceType?.apiType !== apiType.ChartOfAccounts &&
        (form.value?.sourceType?.apiTypeDetail?.userLevelApi === false ||
          selectedFile)
      "
    >
      <h2
        class="card-header card-header-collapsible form-group-title"
        [attr.aria-expanded]="connectSectionCollapsed"
        (click)="collapseConnectSection.toggle()"
      >
        Connect API
        <i
          aria-hidden="true"
          class="fas"
          [ngClass]="{
            'fa-caret-down': connectSectionCollapsed,
            'fa-caret-up': !connectSectionCollapsed,
          }"
        ></i>
      </h2>

      <div
        #collapseConnectSection="ngbCollapse"
        [(ngbCollapse)]="connectSectionCollapsed"
      >
        <div class="card-body">
          <div class="d-flex">
            <div class="form-group">
              <button
                class="btn btn-major"
                type="button"
                [promiseBtn]="busy.connect"
                (click)="connect()"
              >
                <div style="display: inline-block">
                  <div>
                    <i aria-hidden="true" class="fas fa-plug"></i>
                  </div>
                  <div>Connect</div>
                </div>
              </button>
            </div>

            <div class="flex-grow-1 ps-3">
              <div *ngIf="form.value.apiFileId">
                Succesfully connected to the following file: <br />
                <strong class="text-primary">{{
                  form.value.apiFileName
                }}</strong>
              </div>

              <crs-form-error [error]="connectError"></crs-form-error>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Change Name -->
    <div
      class="modal-section card card-pop"
      *ngIf="
        form.value?.name ||
        form.value?.sourceType?.apiType === apiType.None ||
        form.value?.sourceType?.apiType === apiType.ChartOfAccounts
      "
    >
      <h2
        class="card-header card-header-collapsible form-group-title"
        [attr.aria-expanded]="fileNameCollapsed"
        (click)="collapseFileName.toggle()"
      >
        File name
        <i
          aria-hidden="true"
          class="fas"
          [ngClass]="{
            'fa-caret-down': fileNameCollapsed,
            'fa-caret-up': !fileNameCollapsed,
          }"
        ></i>
      </h2>

      <div #collapseFileName="ngbCollapse" [(ngbCollapse)]="fileNameCollapsed">
        <div class="card-body">
          <!-- Select Chart Type -->
          <div *ngIf="form.value?.sourceType?.id == 1">
            <div class="alert alert-primary">
              Please choose a default account numbering system to use for
              accounts created using this source.
            </div>

            <div class="form-group">
              <label class="control-label">Account Numbering System</label>
              <ng-select
                bindLabel="name"
                bindValue="value"
                formControlName="accountSystem"
                [items]="accountSystems | enumToArray"
              ></ng-select>
            </div>
          </div>

          <div
            class="alert alert-primary"
            *ngIf="form.value?.sourceType?.apiType !== apiType.ChartOfAccounts"
          >
            The name of the source is the default. If you wish to refer to it by
            another name in Access Ledger, please change below.
          </div>

          <div class="row">
            <div class="col-md-10 col-lg-8">
              <div class="form-group">
                <label class="control-label">Name</label>
                <input
                  class="form-control"
                  formControlName="name"
                  type="text"
                  [readonly]="
                    form.value?.sourceType?.apiType === apiType.ChartOfAccounts
                  "
                  title="Name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>

  <div class="modal-footer">
    <div *ngIf="form.value?.sourceType?.apiType === apiType.DataHub">
      * Powered by ZAP
    </div>
    <button
      class="btn btn-delete"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      [promiseBtn]="busy.delete"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>
</form>
