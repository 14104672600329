<div class="row" *ngIf="route.children.length === 0">
  <div class="col-12">
    <!-- <crs-busy *ngIf="busy.journals"></crs-busy> -->

    <!-- <div class="panel" [ngBusy]="[busy.journals]"></div> -->

    <div class="d-flex">
      <div class="btn-menu d-inline-block me-auto">
        <button
          class="btn btn-secondary"
          title="Create Journal"
          type="button"
          (click)="addJournal()"
        >
          <i aria-hidden="true" class="fas fa-plus inset"></i>
          New Journal
        </button>
      </div>

      <div class="d-inline-block">
        <button
          class="btn btn-secondary"
          title="Process Automatic Journals"
          type="button"
          [promiseBtn]="busy.autoJournals"
          (click)="processAutoJournals()"
        >
          <i aria-hidden="true" class="fas fa-magic inset"></i>
          Process Automatic Journals
        </button>
      </div>
    </div>

    <ag-grid-angular
      #agGrid
      class="ag-theme-material"
      [gridOptions]="gridOptionsMain"
      [rowData]="journalsObservable | async"
    />
  </div>
</div>

<div class="row" *ngIf="route.children.length !== 0">
  <div class="col-xl-2 d-sm-none d-md-none d-xl-block">
    <div class="left-menu">
      <crs-busy *ngIf="busy.journals"></crs-busy>

      <div class="btn-menu">
        <button
          class="btn btn-secondary"
          title="Create Journal"
          type="button"
          (click)="addJournal()"
        >
          <i aria-hidden="true" class="fas fa-plus inset"></i>
          New Journal
        </button>
      </div>

      <div class="left-menu-table">
        <ag-grid-angular
          #agGridSmall
          class="ag-theme-material"
          style="width: 100%"
          [gridOptions]="gridOptionsSmall"
          [rowData]="journalsObservable | async"
        />
      </div>
    </div>
  </div>

  <div class="col-xl-10">
    <router-outlet></router-outlet>
  </div>
</div>
