import {IListItem} from '../interfaces';

export class ArrayHelpers {
  public static groupBy(arr, criteria) {
    return arr.reduce(function (obj, item) {

      // Check if the criteria is a function to run on the item or a property of it
      const key = typeof criteria === 'function' ? criteria(item) : item[criteria];

      // If the key doesn't exist yet, create it
      if (!obj.hasOwnProperty(key)) {
        obj[key] = [];
        obj.length = !obj.length ? 1 : obj.length + 1;
      }

      // Push the value to the object
      obj[key].push(item);

      // Return the object to the next item in the loop
      return obj;

    }, {});
  }

  public static getListItem<T>(obj: T): IListItem[]  {
    return Object.entries(obj).map((prop: [string, string]) => <IListItem>{ value: prop[0], name: prop[1] } );
  }
}
