import { AccountUltraLight } from '../../ledger/accounts/account-ultra-light';

export enum LivestockCategory {
  Sales = 'Sales',
  OpeningStock = 'Opening stock',
  Purchases = 'Purchases',
  NaturalIncrease = 'Natural increase',
  KilledForRations = 'Killed for rations',
  Deaths = 'Deaths',
  ClosingStock = 'Closing stock',
  StockOnHand = 'Stock on hand',
}

export class LiveStockAllocation {
  tradingAccountId?: string;
  liveStockCategory: LivestockCategory;
  allocatedAccountId?: string;
  account: AccountUltraLight;

  constructor(data) {
    Object.assign(this, data);
  }
}
