<div
  class="p-5 center container-grey-bg"
  *ngIf="(isBankFeedEnabled$ | async) === false"
>
  <h2 class="mb-3">No bank feed collection yet</h2>
  <button class="btn btn-primary" (click)="onClickEnableBankFeeds()">
    Enable bank feeds
  </button>
</div>

<div class="row">
  <div class="busy-container col-md-12">
    <crs-busy *ngIf="(isFetchingBankFeeds$ | async) || busy.load" />
  </div>
</div>
