import { Subscription, Observable, EMPTY, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportTemplateModel, ReportTemplateService } from '../../';
import { Entity } from '../../../../../firm';
import { User, UserService } from '../../../../../firm/users';
import { Confirmation, MessageService } from '../../../../../core';
import { ModalService } from '../../../../../core';
import { ESignatureDocument } from '../../reportTemplate';
import { ReportOutput } from '../../../reportViewer/report-output';
import { Patterns } from '../../../../../shared/validators';
import { ReportDocumentService } from '../../../services';
import { ESignatureClientValidationRules } from './esignature-sending-client-validation-rules';
import {
  ESignatureRecipientModel,
  ReportsHavingSignatures,
} from './esignature-recipient-model';
import {
  ReportDocumentListItem,
  ReportDocumentModel,
  ReportSignStatus,
} from '../../../models';
import { ReportDocumentUtility } from '../../../reportDocuments/report-document-utility';

enum UserPleaseSignStatus {
  Pending = 'Pending',
  Identified = 'Identified',
  Inactive = 'Inactive',
  NotIdentified = 'Not Identified',
  Unknown = 'Unknown',
}
@Component({
  selector: 'crs-esignature-sending-modal',
  templateUrl: './esignature-sending-modal.component.html',
  styleUrls: ['./esignature-sending-modal.component.scss'],
})
export class ESignatureSendingModalComponent implements OnInit, OnDestroy {
  @Input() params: {
    template: ReportTemplateModel;
    title: string;
    entity: Entity;
    report: ReportOutput;
    reportDocumentId: string;
    reportDocumentListItem: ReportDocumentListItem;
  };

  busy = {
    load: false,
    submit: false,
    saveReportData: false,
  };

  private _destroy: Subject<boolean> = new Subject<boolean>();
  form: any;

  get clients() {
    return this.form.get('clients') as UntypedFormArray;
  }

  get auditors() {
    return this.form.get('auditors') as UntypedFormArray;
  }

  get partners() {
    return this.form.get('partners') as UntypedFormArray;
  }

  get others() {
    return this.form.get('others') as UntypedFormArray;
  }

  reporter: User;
  subscriptions: Subscription[] = [];
  error: string;
  _validationRules: ESignatureClientValidationRules;
  _showPleaseSignSendingModel: boolean = true;
  _isClientSignaturesRequired: boolean = true;
  _isPartnerSignaturesRequired: boolean = true;
  _isAuditorSignaturesRequired: boolean = true;
  _isOtherSignaturesRequired: boolean = true;
  _userPleaseSignStatus: string = UserPleaseSignStatus.Pending;
  _pleaseSignUserID: string = null;

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _modalService: ModalService,
    private readonly _reportTemplateService: ReportTemplateService,
    private readonly _userService: UserService,
    private readonly _messageService: MessageService,
    private readonly _reportDataService: ReportDocumentService,
    private _activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    const entity = this.params.entity;
    this.busy.load = true;
    this._validationRules = new ESignatureClientValidationRules();
    this._showPleaseSignSendingModel = true;
    this._isClientSignaturesRequired = true;
    this._isPartnerSignaturesRequired = true;
    this._isAuditorSignaturesRequired = true;
    this._isOtherSignaturesRequired = true;

    this.form = this._formBuilder.group({
      messageTitle: [
        null,
        [
          Validators.required,
          Validators.pattern('^[A-Z a-z 0-9]*$'),
          Validators.maxLength(50),
        ],
      ],
      message: [null, Validators.maxLength(1000)],
      clients: this._formBuilder.array([]),
      partners: this._formBuilder.array([]),
      auditors: this._formBuilder.array([]),
      others: this._formBuilder.array([]),
      requestorName: [''],
      document: [''],
      sendAsDraft: [true],
      senderId: [null],
    });

    this.subscriptions.push(
      this.getReporter()
        .pipe(finalize(() => (this.busy.load = false)))
        .subscribe(
          (user) => {
            const model = {
              requestorName: `${user.firstName} ${user.lastName}`.trim(),
              document: this.params.title,
            };

            this.queryUserStatus();

            var reportWithSignatures: ReportsHavingSignatures[] = [];

            if (
              !this._validationRules.isSignatureIncluded(
                this.params.report,
                reportWithSignatures
              )
            ) {
              this._showPleaseSignSendingModel = false;
              this._modalService
                .showInformationDialog(
                  'This report does not contain any signatories. Please add a signatory to proceed.',
                  true,
                  'OK, Got It',
                  'Signatory Required',
                  () => {
                    this._activeModal.dismiss();
                  }
                )
                .finally(() => {
                  this._activeModal.dismiss();
                });
            } else {
              this.handleClientSignatories(reportWithSignatures, entity);
              this.handlePartnerSignatories(reportWithSignatures, entity);
              this.handleAuditorSignatories(reportWithSignatures);
              this.handleOtherSignatories(reportWithSignatures);
              this.form.patchValue(model);
            }
          },
          (err) => this.showError(err)
        )
    );
  }

  private handleAuditorSignatories(
    reportWithSignatures: ReportsHavingSignatures[]
  ) {
    var auditorSignatories: ESignatureRecipientModel[] =
      this._validationRules.getAuditorsSignatories(reportWithSignatures);
    if (auditorSignatories?.length > 0) {
      this.auditors.clear();

      if (this.params.report.isAuditorRequired) {
        let signatureRecipientModel = new ESignatureRecipientModel();
        signatureRecipientModel.fullName =
          this.params.report.office?.auditor?.auditorName;
        signatureRecipientModel.email =
          this.params.report.office?.auditor?.auditorEmail;
        this._validationRules.mapPartnerSignatory(
          auditorSignatories,
          signatureRecipientModel
        );
      }

      if (this.params.report.isAuditorSMSFRequired) {
        let smsfRecipientModel = new ESignatureRecipientModel();
        smsfRecipientModel.fullName =
          this.params.report.office?.auditorSMSF?.auditorName;
        smsfRecipientModel.email =
          this.params.report.office?.auditorSMSF?.auditorEmail;
        this._validationRules.mapPartnerSignatory(
          auditorSignatories,
          smsfRecipientModel
        );
      }

      if (auditorSignatories?.length > 0) {
        auditorSignatories.forEach((auditorSig) =>
          this.auditors.push(
            this._formBuilder.group({
              fullname: [
                auditorSig.fullName?.trim(),
                [
                  Validators.required,
                  Validators.pattern('^[A-Z a-z 0-9]*$'),
                  Validators.maxLength(50),
                ],
              ],
              email: [
                auditorSig.email,
                Validators.compose([
                  Validators.required,
                  Validators.pattern(Patterns.emailRegExp),
                ]),
              ],
            })
          )
        );
      }
    } else {
      this._isAuditorSignaturesRequired = false;
      if (this.auditors) this.form.removeControl('auditors');
    }
    return auditorSignatories;
  }

  private handlePartnerSignatories(
    reportWithSignatures: ReportsHavingSignatures[],
    entity: Entity
  ) {
    var partnerSignatories: ESignatureRecipientModel[] =
      this._validationRules.getPartnersSignatories(reportWithSignatures);
    if (partnerSignatories?.length > 0) {
      this.partners.clear();
      let recipientModel = new ESignatureRecipientModel();
      recipientModel.fullName =
        `${entity.partner.firstName} ${entity.partner.lastName}`.trim();
      recipientModel.email = entity.partner.email;

      this._validationRules.mapPartnerSignatory(
        partnerSignatories,
        recipientModel
      );
      if (partnerSignatories?.length > 0) {
        partnerSignatories.forEach((auditorSig) =>
          this.partners.push(
            this._formBuilder.group({
              fullname: [
                auditorSig.fullName.trim(),
                [
                  Validators.required,
                  Validators.pattern('^[A-Z a-z 0-9]*$'),
                  Validators.maxLength(50),
                ],
              ],
              email: [
                auditorSig.email,
                Validators.compose([
                  Validators.required,
                  Validators.pattern(Patterns.emailRegExp),
                ]),
              ],
            })
          )
        );
      }
    } else {
      this._isPartnerSignaturesRequired = false;
      if (this.partners) this.form.removeControl('partners');
    }
    return partnerSignatories;
  }

  private handleClientSignatories(
    reportWithSignatures: ReportsHavingSignatures[],
    entity: Entity
  ) {
    var clientSignatories: ESignatureRecipientModel[] =
      this._validationRules.getResponsiblePersonsSignatories(
        reportWithSignatures
      );
    if (clientSignatories?.length > 0) {
      const filteredResponsiblePersons = entity.responsiblePersons.filter(
        (x) => x.isSigning
      );
      this._validationRules.mapClientSignatory(
        clientSignatories,
        filteredResponsiblePersons
      );
      this.clients.clear();
      clientSignatories.forEach((rp) =>
        this.clients.push(
          this._formBuilder.group({
            fullname: [
              rp.fullName.trim(),
              [
                Validators.required,
                Validators.pattern('^[A-Z a-z 0-9]*$'),
                Validators.maxLength(50),
              ],
            ],
            email: [
              rp.email,
              Validators.compose([
                Validators.required,
                Validators.pattern(Patterns.emailRegExp),
              ]),
            ],
          })
        )
      );
    } else {
      this._isClientSignaturesRequired = false;
      if (this.clients) this.form.removeControl('clients');
    }
  }

  private handleOtherSignatories(
    reportWithSignatures: ReportsHavingSignatures[]
  ) {
    var otherSignatories: ESignatureRecipientModel[] =
      this._validationRules.getOthersSignatories(reportWithSignatures);
    if (otherSignatories?.length > 0) {
      this.others.clear();

      this._validationRules.mapPartnerSignatory(
        otherSignatories,
        new ESignatureRecipientModel()
      );
      if (otherSignatories?.length > 0) {
        otherSignatories.forEach((auditorSig) =>
          this.others.push(
            this._formBuilder.group({
              fullname: [
                auditorSig.fullName.trim(),
                [
                  Validators.required,
                  Validators.pattern('^[A-Z a-z 0-9]*$'),
                  Validators.maxLength(50),
                ],
              ],
              email: [
                auditorSig.email,
                Validators.compose([
                  Validators.required,
                  Validators.pattern(Patterns.emailRegExp),
                ]),
              ],
            })
          )
        );
      }
    } else {
      this._isOtherSignaturesRequired = false;
      if (this.others) this.form.removeControl('others');
    }
    return otherSignatories;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
    this._destroy.next(true);
    this._destroy.complete();
  }

  getReporter() {
    return this._userService.profile().pipe(
      tap((user) => {
        this.reporter = user;
      })
    );
  }

  queryUserStatus() {
    this._reportTemplateService.eSignUserQuery(this.reporter.email).subscribe(
      (userQueryResponse) => {
        //process the response
        if (userQueryResponse.totalCount > 0) {
          if (userQueryResponse.data[0].active) {
            this._userPleaseSignStatus = UserPleaseSignStatus.Identified;
            this._pleaseSignUserID = userQueryResponse.data[0].id;
          } else {
            this._userPleaseSignStatus = UserPleaseSignStatus.Inactive;
          }
        } else {
          this._userPleaseSignStatus = UserPleaseSignStatus.NotIdentified;
        }
      },
      (err) => {
        this._messageService.error(
          '<h5>Unable to query your PleaseSign status</h5>' +
            '<div>We were unable to query your PleaseSign account status.</div>'
        );
        this._userPleaseSignStatus = UserPleaseSignStatus.Unknown;
      }
    );
  }

  submit() {
    this.error = null;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.showError(
        'Mandatory fields have not been completed or are missing information. Please review and complete prior to sending.'
      );
      return;
    }
    const payload = {
      eSignature: this.form.value as ESignatureDocument,
    };

    this.params.report.reportSignStatus = ReportSignStatus.REPORT_SIGNED;
    this.showSaveReportInfoDialog().then(() => {
      payload.eSignature.senderId = this._pleaseSignUserID;
      this.busy.submit = true;
      let observable: Observable<any>;
      if (this.params.report) {
        payload['report'] = this.params.report;
        observable = this._reportTemplateService.eSignFromReport(payload);
      } else if (this.params.template) {
        payload['template'] = this.params.template;
        observable = this._reportTemplateService.eSignFromTemplate(payload);
      }

      let messageId: number;
      this._messageService
        .info('Processing the PDF being pushed to PleaseSign', {
          disableTimeOut: true,
        })
        .then((toastId) => (messageId = toastId));

      observable
        .pipe(
          finalize(() => {
            this._messageService.remove(messageId);
            this.busy.submit = false;
          })
        )
        .subscribe(
          (success) => {
            this._activeModal.close();
            const messageBody = payload.eSignature.sendAsDraft
              ? '<h5>Document Has Been Sent</h5>' +
                '<div>The PDF was successfully passed to your PleaseSign account as a draft. To continue the electronic signature process please log into your <a target="_blank" href="https://secure.pleasesign.com.au">PleaseSign account</a></div>'
              : `<h5>Document Has Been Sent</h5><div>The PDF was successfully sent to the recipient</div>`;
            this._messageService.success(messageBody);
          },
          (err) => {
            this.showError(err);

            // If eSign process failed due to any reasons, change back the report to editable and update the ReportDocument table.
            this.params.report.reportSignStatus =
              ReportSignStatus.REPORT_UNSIGNED;
            this.saveReportData(false);

            this._messageService.error(
              '<h5>Document Failed To Be Sent</h5>' +
                '<div>We were unsuccessful in sending this PDF to your <a target="_blank" href="https://secure.pleasesign.com.au">PleaseSign account</a>. Please check your PleaseSign account settings in Access Ledger with your administrator.</div>'
            );
          }
        );
    });
  }

  cancel() {
    this.params.report.reportSignStatus = ReportSignStatus.REPORT_UNSIGNED;
    this._modalService.confirmation(
      'Are you sure you want to cancel this e-signature request?',
      () => this._activeModal.dismiss(),
      false,
      null,
      'Yes',
      'No'
    );
  }

  showError(error) {
    this.error = error;
  }

  showSaveReportInfoDialog() {
    const confirmation = new Confirmation({
      title: 'Save Report',
      text: `In order to proceed, this report will be saved to your account.`,
      action: () => this.saveReportData(true),
      danger: false,
    });
    return this._modalService.confirmation2(confirmation);
  }

  private saveReportData(displayStatus): void {
    this.busy.saveReportData = true;
    let model = new ReportDocumentModel();
    model.ReportDocumentHeader = this.params.reportDocumentListItem;
    model.ReportDocumentContents = this.params.report;

    let reportTemplate = this.params.template;
    // Create document to use following naming convention <FY> <Title> <Entity Name> as in eSign
    if (this.params.reportDocumentId == null) {
      reportTemplate.name =
        this.form.get('document')?.value ?? this.params.template.name;
    }

    const action$ = this.params.reportDocumentId
      ? this._reportDataService.update(this.params.reportDocumentId, model)
      : this._reportDataService
          .create({
            report: this.params.report,
            reportTemplateCreate: reportTemplate,
          })
          .pipe(
            tap(
              (reportDocumentId: string) =>
                (this.params.reportDocumentId = reportDocumentId)
            )
          );

    action$
      .pipe(
        catchError((err) => {
          this._messageService.error(err);
          this.params.report.reportSignStatus =
            ReportSignStatus.REPORT_UNSIGNED;
          return EMPTY;
        }),
        finalize(() => (this.busy.saveReportData = false)),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        if (displayStatus) {
          let reportDocumentName =
            this.params.reportDocumentListItem?.name ??
            ReportDocumentUtility.getTitle(this.params.report);
          this._messageService.success(
            `Report Document '${reportDocumentName}' saved`
          );
        }
      });
  }
}
