import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { cashflowReportModeOptions } from '../cashflow-report-mode-options';

@Component({
  selector: 'crs-report-template-cashflow-page-detail',
  templateUrl: './report-template-cashflow-page-detail.component.html',
})
export class ReportTemplateCashflowPageDetailComponent implements OnInit {

  @Input('index')  i: number;
  @Input() pageGroup: UntypedFormGroup;
  @Input() templateGroup: UntypedFormGroup;
  @Input() isAdd: boolean;
  private _detail: UntypedFormGroup;
  @Input() set detail(value: UntypedFormGroup) {
    this._detail = value;    
  }
  get detail() { return this._detail; }
  cashflowReportModeOptions = cashflowReportModeOptions;
  
  constructor() { }

  ngOnInit(): void {
  }

}
