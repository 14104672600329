import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import { finalize } from 'rxjs/operators';
import {
  HasEntriesStatusEnum,
  SourceAccountService,
} from '../../sourceAccounts';

export class SourceAccountDataSource implements IDatasource {
  busy: boolean;
  search: string;
  sourceId: string;
  classificationId: string;
  divisionId: string;
  tradingAccountId: string;
  selectedStatus: HasEntriesStatusEnum;

  constructor(
    private sourceAccountService: SourceAccountService,
    private fileId: string,
    private cacheSize: number
  ) {}

  getRows(params: IGetRowsParams): void {
    const { startRow, sortModel, successCallback, failCallback } = params;

    const page = startRow / this.cacheSize + 1;
    const pageSize = this.cacheSize;
    const orderBy = sortModel.length ? sortModel[0].colId : null;
    const orderByAsc = sortModel.length ? sortModel[0].sort === 'asc' : true;

    const hasEntries =
      this.selectedStatus &&
      this.selectedStatus === HasEntriesStatusEnum.HasEntries;

    this.busy = true;

    this.sourceAccountService
      .getForFilePaginated$({
        fileId: this.fileId,
        search: this.search,
        classificationId: this.classificationId,
        sourceId: this.sourceId,
        divisionId: this.divisionId,
        tradingAccountId: this.tradingAccountId,
        hasEntries,
        page,
        pageSize,
        orderBy,
        orderByAsc,
      })
      .pipe(
        finalize(() => {
          this.busy = false;
        })
      )
      .subscribe(
        (response) => {
          successCallback(response.records, response.recordsCount);
          this.checkPaginationRecordsCountCalc(response.recordsCount);
        },
        () => failCallback()
      );
  }

  // there is a bug in the ag grid pagination which occasionally returns a "?" instead of the recordsCount when filtering.
  // this minor hack fixes that issue
  checkPaginationRecordsCountCalc(recordsCount: number) {
    const lbLastRowOnPageEl = document.querySelector(
      `[ref=\'lbLastRowOnPage\']`
    );

    if (lbLastRowOnPageEl?.innerHTML === '?') {
      lbLastRowOnPageEl.innerHTML = recordsCount.toLocaleString();
    }
  }
}
