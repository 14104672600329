import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import {
  ReportTemplate,
  ReportTemplateModel,
  ReportTemplateService,
} from '../';
import { getDefaultGridOptions, accountingRenderers } from '../../../../shared';
import { ModalService, Confirmation } from '../../../../core';
import { ActiveFileService } from 'src/app/accounting/active-file.service';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-report-templates',
  templateUrl: './report-templates.component.html',
})
export class ReportTemplatesComponent implements OnInit, OnDestroy {
  @ViewChild('optionsCellOffice', { static: true, read: TemplateRef })
  optionsCellOffice!: TemplateRef<ReportTemplate>;

  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    load: null,
  };
  public templates: ReportTemplate[] = [];

  gridOptions: GridOptions;
  renderers = accountingRenderers;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly _modalService: ModalService,
    private readonly _reportTemplateService: ReportTemplateService,
    private activeFileService: ActiveFileService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  public ngOnInit() {
    this.configureGridOptions();
    this._loadReportTemplates();
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public editReportTemplate(reportTemplate: ReportTemplate) {
    this.router.navigate(['../templates/' + reportTemplate.id], {
      relativeTo: this.activatedRoute,
    });
  }

  public openRemoveReportTemplate(reportTemplate: ReportTemplate) {
    const confirmation = new Confirmation({
      title: 'Delete Template',
      text: `Are you sure you would like to delete the ${reportTemplate.name}? This action cannot be undone.`,
      action: () => this._removeReportTemplate(reportTemplate.id),
      danger: true,
    });
    return this._modalService.confirmation2(confirmation);
  }

  private configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      singleClickEdit: false,
      suppressCellFocus: true,
      columnDefs: [
        {
          field: 'name',
          headerName: 'Name',
        },
        {
          field: 'modifiedDate',
          headerName: 'Date Modified',
          type: 'timeColumn',
          editable: false,
          maxWidth: 400,
          minWidth: 200,
        },
        {
          headerName: '',
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCellOffice },
          maxWidth: 90,
          minWidth: 90,
        },
      ],
    };
  }

  private _updateReportTemplate(
    reportTemplateModel: ReportTemplateModel
  ): void {
    this._reportTemplateService
      .put(reportTemplateModel)
      .pipe(takeUntil(this._destroy$))
      .subscribe({ error: this._showError });
  }

  private _removeReportTemplate(reportTemplateId: string): void {
    this._reportTemplateService
      .delete(reportTemplateId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => this._loadReportTemplates(),
        error: this._showError,
      });
  }

  private _loadReportTemplates(): void {
    this.busy.load = true;
    this._reportTemplateService
      .getAll(this.activeFileService.file.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (data) => (this.templates = data),
        error: (err) => {
          this._showError(err);
          this.templates = [];
        },
        complete: () => (this.busy.load = false),
      });
  }

  private _showError(error) {
    this.error = error;
  }

  onValueChanged(event) {
    let name = event.node.data.name;
    this.busy.load = true;
    this._reportTemplateService
      .get(event.node.data.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (data) => {
          data.name = name;
          this._updateReportTemplate(new ReportTemplateModel(data));
        },
        error: this._showError,
        complete: () => (this.busy.load = false),
      });
  }
}
