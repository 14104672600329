import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core';
import {
  Workpaper,
  WorkpaperCreateModel,
  WorkpaperUpdateModel,
} from './workpaper';

@Injectable({ providedIn: 'root' })
export class WorkpaperService {
  readonly base: string = 'api/accounting/ledger/workpapers';

  constructor(private apiService: ApiService) {}

  get$(id: string) {
    return this.apiService
      .get<Workpaper>(`${this.base}/${id}`)
      .pipe(map((workpaper) => new Workpaper(workpaper)));
  }

  getWorkpapersByDataset$({
    datasetId,
    showNonAccountWorkpapers = false,
  }: {
    datasetId: string;
    showNonAccountWorkpapers?: boolean;
  }): Observable<Workpaper[]> {
    let url = `${this.base}/for-dataset/${datasetId}?showNonAccountWorkpapers=${showNonAccountWorkpapers}`;
    return this.apiService
      .get<Workpaper[]>(url)
      .pipe(
        map((workpapers) =>
          workpapers.map((workpaper) => new Workpaper(workpaper))
        )
      );
  }

  post$(workpaper: WorkpaperCreateModel): Observable<string> {
    return this.apiService.post<string>(this.base, workpaper);
  }

  put$(
    workpaperId: string,
    workpaperUpdateModel: WorkpaperUpdateModel
  ): Observable<string> {
    let url = `${this.base}/${workpaperId}`;
    return this.apiService.put<string>(url, workpaperUpdateModel);
  }

  delete$(workpaperId: string): Observable<string> {
    let url = `${this.base}/${workpaperId}`;
    return this.apiService.delete<string>(url);
  }
}
