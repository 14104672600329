import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/core';
import { DocumentTag } from './access-documents/access-documents';
import { PleaseSignModel } from './electronicSignature';
import { DocumentIntegrationValue } from '../enums';

type GetAccessDocumentsParams = {
  officeId: string;
  entityId: string;
  isPractice?: boolean | null;
  isClient?: boolean | null;
};

@Injectable()
export class DocumentIntegrationService {
  constructor(private apiService: ApiService) {}

  public getDocumentIntegrationSetting$() {
    return this.apiService.get<any>(`api/configuration/document-integration`);
  }

  public setESignatureSetting$(eSignature) {
    return this.apiService.put<boolean>(
      `api/configuration/document-integration`,
      eSignature
    );
  }

  public getPleaseSignKeys$() {
    return this.apiService.get<PleaseSignModel>(
      `api/configuration/document-integration/pleasesign`
    );
  }

  public setPleaseSignKeys$(model: PleaseSignModel) {
    return this.apiService.put<boolean>(
      `api/configuration/document-integration/pleasesign`,
      model
    );
  }

  public getAccessDocumentsStatus$(): Observable<boolean> {
    return this.apiService.get<boolean>(
      `api/configuration/document-integration/access-documents`
    );
  }

  public getAccessDocumentsUserStatus$({
    officeId,
    entityId,
    isPractice,
    isClient,
  }: GetAccessDocumentsParams): Observable<boolean> {
    let url = `api/configuration/document-integration/access-documents/user-status`;
    url = this.apiService.addQuery(url, 'officeId', officeId);
    url = this.apiService.addQuery(url, 'entityId', entityId);
    url = this.apiService.addQuery(url, 'isPractice', isPractice);
    url = this.apiService.addQuery(url, 'isClient', isClient);

    return this.apiService.get<boolean>(url);
  }

  public getAccessDocumentsTags$({
    officeId,
    entityId,
    isPractice,
    isClient,
  }: GetAccessDocumentsParams): Observable<DocumentTag[]> {
    let url = `api/configuration/document-integration/access-documents/document-tags`;
    url = this.apiService.addQuery(url, 'officeId', officeId);
    url = this.apiService.addQuery(url, 'entityId', entityId);
    url = this.apiService.addQuery(url, 'isPractice', isPractice);
    url = this.apiService.addQuery(url, 'isClient', isClient);

    return this.apiService.get<DocumentTag[]>(url);
  }

  public eSignatureDefined$(): Observable<boolean> {
    return this.getDocumentIntegrationSetting$().pipe(
      map(
        (eSignature) =>
          eSignature?.value === DocumentIntegrationValue.PleaseSign
      )
    );
  }
}
