import { Address } from '../model/address';
import { AuditorModel } from '../model/auditor';

export class Office {
  id: string;
  name: string;
  tradingName: string;
  address: Address;
  brandColour: string;
  defaultAssociationStateId: number;
  defaultReportSaveFormat: number;
  auditor: AuditorModel;
  constructor(data) {
    Object.assign(this, data);
    if (data.defaultAssociationStateId) this.defaultAssociationStateId = parseInt(data.defaultAssociationStateId,  10);
  }
}

export class OfficeCreateModel {
  id: string;
  name: string;
  tradingName: string;
  address: Address;
  officeUsers = [];
  brandColour: string;
  defaultAssociationStateId: number;
  defaultReportSaveFormat: number;
  auditor: AuditorModel;

  constructor(data: Office, users) {
    Object.assign(this, data);
    this.officeUsers = users;
  }
}
