export interface IPageReference {
  page: number;
  pageSize: number;
}

export class PagedResponse<T> implements IPageReference {

  records: T[];
  recordsCount: number;
  page: number;
  pages: number;
  pageSize: number;

  constructor(data: any) {
    this.records = data.records;
    this.recordsCount = parseInt(data.recordsCount, 10);
    this.page = parseInt(data.page, 10);
    this.pages = parseInt(data.pages, 10);
    this.pageSize = parseInt(data.pageSize, 10);
  }

}
