<div class="row" *ngIf="route.children.length === 0">
  <div class="col-12">
    <crs-busy *ngIf="busy.journals"></crs-busy>

    <div class="d-flex">
      <div class="btn-menu d-inline-block mr-auto">
        <button
          class="btn btn-secondary"
          title="Create Journal"
          type="button"
          (click)="addJournal()"
        >
          <i aria-hidden="true" class="fas fa-plus inset"></i>
          New Journal
        </button>
      </div>

      <div class="d-inline-block">
        <button
          class="btn btn-secondary"
          title="Process Automatic Journals"
          type="button"
          [promiseBtn]="busy.autoJournals"
          (click)="processAutoJournals()"
        >
          <i aria-hidden="true" class="fas fa-magic inset"></i>
          Process Automatic Journals
        </button>
      </div>
    </div>

    <ag-grid-angular
      #agGrid
      class="ag-theme-material"
      rowClass="selectable-row"
      rowSelection="single"
      [gridOptions]="gridOptions"
      [rowData]="journalsObservable | async"
      (rowSelected)="selectJournal($event)"
    >
      <ag-grid-column
        field="date"
        headerName="Date"
        type="dateColumn"
      ></ag-grid-column>
      <ag-grid-column
        field="journalNo"
        headerName="Journal No"
        [maxWidth]="180"
        [minWidth]="120"
      ></ag-grid-column>
      <ag-grid-column
        field="journalTypeToolTipText"
        headerName="Journal Type"
        [maxWidth]="250"
      ></ag-grid-column>
      <ag-grid-column
        field="memo"
        headerName="Memo"
        [minWidth]="120"
      ></ag-grid-column>
      <ag-grid-column
        field="balance"
        headerName="Balance"
        type="dollarColumn"
      ></ag-grid-column>
    </ag-grid-angular>
  </div>
</div>

<div class="row" *ngIf="route.children.length !== 0">
  <div class="col-xl-2 d-sm-none d-md-none d-xl-block">
    <div class="left-menu">
      <crs-busy *ngIf="busy.journals"></crs-busy>

      <div class="btn-menu">
        <button
          class="btn btn-secondary"
          title="Create Journal"
          type="button"
          (click)="addJournal()"
        >
          <i aria-hidden="true" class="fas fa-plus inset"></i>
          New Journal
        </button>
      </div>

      <div class="left-menu-table">
        <ag-grid-angular
          #agGridSmall
          class="ag-theme-material"
          rowClass="selectable-row"
          rowSelection="single"
          style="width: 100%"
          [gridOptions]="gridOptions"
          [rowData]="journalsObservable | async"
          (rowSelected)="selectJournal($event)"
        >
          <ag-grid-column
            field="date"
            headerName="Date"
            type="dateColumn"
          ></ag-grid-column>
          <ag-grid-column
            field="journalNo"
            headerName="Journal No"
            [maxWidth]="180"
            [minWidth]="120"
          ></ag-grid-column>
        </ag-grid-angular>
      </div>
    </div>
  </div>

  <div class="col-xl-10">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #optionsCell let-row>
  <div class="btn-mini-menu">
    <button
      class="btn-sm fas-btn-ghost"
      type="button"
      (click)="deleteJournal(row)"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
    </button>
  </div>
</ng-template>
