import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, EMPTY } from 'rxjs';
import { tap, catchError, first } from 'rxjs/operators';

import { ModalService, MessageService } from '../../../core';
import { EntityComponent } from '../entity/entity.component';
import { FileCreateComponent } from '../file-create/file-create.component';
import { ClientService, ClientEntitiesAndFiles } from '../../clients';
import { FileService, FileModel } from '../../../accounting';
import { EntityTypeEnum } from '../entityType';
import { EntityService } from '../entity.service';

@Component({
  selector: 'crs-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss'],
})
export class EntitiesComponent implements OnInit {
  busy = {
    loading: null,
    creating: null,
  };
  clientId: string;
  response: ClientEntitiesAndFiles;

  addEntitySub: Subscription;

  constructor(
    private readonly clientService: ClientService,
    private readonly entityService: EntityService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly messageService: MessageService,
    private readonly fileService: FileService
  ) {}

  ngOnInit() {
    this.refreshRoute();
    this.router.events.subscribe(() => this.refreshRoute());
    this.entityService.refreshEntities$.subscribe(() => {
      this.getEntities();
    });
  }

  refreshRoute() {
    const id = this.route.snapshot.paramMap.get('id');
    if (this.clientId !== id) {
      this.clientId = id;
      this.getEntities();
    }
  }

  addFile(isDefault: boolean, entityId: string) {
    const model = new FileModel({
      clientId: this.clientId,
      entityId: entityId,
      name: null,
    });

    if (isDefault)
      this.runAddFileObservable(this.fileService.postDefault$(model));
    else {
      this.modalService
        .openModal(FileCreateComponent, null, model, { size: null })
        .then((fileCreateModel) =>
          this.runAddFileObservable(this.fileService.post$(fileCreateModel))
        )
        .catch(() => true);
    }
  }

  private runAddFileObservable(observable: Observable<string>) {
    this.busy.creating = observable
      .pipe(
        tap((id) =>
          this.router.navigate(['/accounting', id], {
            relativeTo: this.route,
          })
        ),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  addEntity() {
    this.addEntitySub = this.clientService
      .get(this.clientId)
      .subscribe((client) => {
        this.modalService
          .openModal(EntityComponent, 'add', client)
          .then((r) => {
            if (r.createFile) {
              this.addFile(true, r.entityId);
            } else {
              this.getEntities();
            }
          })
          .catch(() => true);
      }, this.showError);
  }

  editEntity(id: string) {
    this.modalService
      .openModal(EntityComponent, id)
      .then(() => this.getEntities())
      .catch(() => true);
  }

  getEntities() {
    this.response = null;
    this.busy.loading = this.clientService
      .getEntitiesAndFilesForClient(this.clientId)
      .subscribe((data) => {
        this.response = data;
      });
  }

  getIconClass(entityTypeId: any): string {
    switch (entityTypeId) {
      case EntityTypeEnum.SoleTrader:
        return 'fas fa-user';
      case EntityTypeEnum.Partnership:
        return 'fas fa-user-friends';
      default:
        return 'fas fa-building'; // default icon
    }
  }

  handleEntitySelection(entity: ClientEntitiesAndFiles) {
    if (entity.files.length) {
      this.router.navigate(['/accounting', entity.files[0]?.id]);
    } else {
      this.showError('No files exist for this entity.');
    }
  }

  showError(error) {
    this.messageService.error(error);
  }
}
