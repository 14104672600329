<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li aria-current="page" class="breadcrumb-item active">Page Templates</li>
  </ol>
</nav>

<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.reportPageTypes"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-no-bottom">
      <button
        class="btn btn-secondary"
        title="Create a new Page Template"
        type="button"
        (click)="add()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Page Template
      </button>
    </div>

    <div>
      <crs-progress-bar></crs-progress-bar>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="reportPageTypes"
      >
        <ag-grid-column
          field="category"
          headerName="Category"
          type="enumColumn"
          [cellRendererParams]="{ enum: reportPageCategories }"
          [hide]="true"
          [maxWidth]="200"
          [rowGroup]="true"
        ></ag-grid-column>

        <ag-grid-column
          cellRenderer="badgeRenderer"
          field="name"
          headerName="Name"
          [cellRendererParams]="{ badgeText: 'New', monthsToCheck: 3 }"
          [rowDrag]="true"
        ></ag-grid-column>

        <ag-grid-column
          field="isMaster"
          headerName="Master"
          type="booleanColumn"
          [maxWidth]="120"
          [minWidth]="120"
        ></ag-grid-column>

        <ag-grid-column
          field="canHaveMultiple"
          headerName="Multiple"
          type="booleanColumn"
          [maxWidth]="120"
          [minWidth]="120"
        ></ag-grid-column>

        <ag-grid-column
          field="active"
          headerName="Active"
          type="booleanColumn"
          [cellClass]="['boolean-cell', 'centered', 'text-success']"
          [maxWidth]="100"
          [minWidth]="100"
        ></ag-grid-column>

        <ag-grid-column
          headerClass="centered"
          headerName="Edit"
          type="optionsColumn"
          [cellRendererParams]="{ ngTemplate: optionsCell }"
          [maxWidth]="100"
          [minWidth]="100"
        ></ag-grid-column>
      </ag-grid-angular>

      <ng-template #optionsCell let-row>
        <div class="btn-mini-menu">
          <a
            class="btn-sm fas-btn-primary-ghost"
            title="Edit"
            *ngIf="!row.isSystem"
            [routerLink]="['./', row.id]"
            (click)="scrollToTop()"
          >
            <i aria-hidden="true" class="fas fa-edit"></i>
          </a>
          <span
            title="This system page template may not be edited"
            *ngIf="row.isSystem"
          >
            <i aria-hidden="true" class="fas fa-lock text-light-gray"></i>
          </span>
          <!--<button type="button" class="fas-btn" (click)="deleteDataset(row)">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>-->
        </div>
      </ng-template>
    </div>
  </div>
</div>
