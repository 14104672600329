import { ModalService } from 'src/app/core';
import { Component, OnInit, Input } from '@angular/core';

import { AssetReportTaxReturnSbe, AssetReportTaxReturnNonSbe, AssetReportTaxAudit } from './asset-report-tax-return';

@Component({
  selector: 'crs-asset-report-tax-return',
  templateUrl: './asset-report-tax-return.component.html',
  styleUrls: ['./asset-report-tax-return.component.scss']
})
export class AssetReportTaxReturnComponent implements OnInit {

  @Input() content: AssetReportTaxReturnSbe | AssetReportTaxReturnNonSbe;
  @Input() smallBusinessDisclosures: boolean;

  sbeContent: AssetReportTaxReturnSbe;
  nonSbeContent: AssetReportTaxReturnNonSbe;

  auditContent:  AssetReportTaxAudit[];
  showAudit = false;


  constructor(private readonly _modalService: ModalService) { }

  ngOnInit() {
    this.sbeContent = this.content as AssetReportTaxReturnSbe;
    this.nonSbeContent = this.content as AssetReportTaxReturnNonSbe;
  }

  selectAudit(auditContent: AssetReportTaxAudit[]) {
    this.auditContent = auditContent;
    this.showAudit = true;
  }

  closeAudit() {
    this.showAudit = false;
  }

}
