import { ElementRef } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { Subject } from 'rxjs';

import { Division } from 'src/app/accounting/setup';
import { accountingRenderers, getDefaultGridOptions } from 'src/app/shared';
import {
  SourceAccount,
  Classification,
  classifications,
  JournalLine,
} from '../..';
import { TaxCode } from '../../tax-codes/tax-code';

export class JournalGrid {
  accounts: SourceAccount[] = [];
  divisions: Division[] = [];
  taxCodes: TaxCode[] = [];
  isGstEnabled: boolean = false;

  classifications = Classification;

  renderers = accountingRenderers;
  gridOptions = getDefaultGridOptions();

  get gridApi() {
    return this.gridOptions.api;
  }

  error: string;

  submitStream = new Subject();

  constructor(
    public classificationTemplate: ElementRef,
    public accountSearchTemplate: ElementRef,
    public taxCodeSearchTemplate: ElementRef,
    public totalsChanged?: () => void,
    public valuesChanged?: () => void
  ) {}

  private accountSearchParams(property) {
    return {
      getList: () => this.accounts,
      property,
      template: this.accountSearchTemplate,
      searchFn: this.accountSearch,
      onSelect: this.onAccountSelect,
      editable: true,
      waitForDropdown: true,
    };
  }

  accountNoSearchParams = () => {
    return this.accountSearchParams('accountNo');
  };

  accountNameSearchParams = () => {
    return this.accountSearchParams('accountName');
  };

  taxCodeSearchParams = () => {
    return {
      getList: () => this.taxCodes,
      property: 'taxCode',
      template: this.taxCodeSearchTemplate,
      searchFn: this.taxCodeSearch,
      onSelect: this.onTaxCodeSelect,
      editable: true,
      waitForDropdown: true,
      clearable: true,
    };
  };

  classificationEditorParams = () => {
    const searchFn = (data, term) => {
      const prop = typeof data === 'string' ? data : Classification[data];

      if (!prop) {
        return !term;
      }

      return prop.toLowerCase().indexOf(term.toLowerCase()) > -1;
    };

    return {
      list: classifications,
      template: this.classificationTemplate,
      formatter: (data) => Classification[data],
      searchFn,
      waitForDropdown: true,
    };
  };

  divisionEditorParams = () => {
    return {
      values: [new Division({})].concat(this.divisions),
      formatValue: (value) => {
        return value ? value.name : null;
      },
    };
  };

  canDrag = (params) => !params.node.group;
  rowDragText = () => 'Journal Row...';

  updateGrid = (gridApi: GridApi, data: JournalLine) => {
    gridApi.applyTransaction({
      update: [data],
    });
  };

  // ------ Functions for the account 'type ahead' controls ------

  accountSearch = (account: SourceAccount, search: string): boolean => {
    if (!search) {
      return true;
    }

    // Limits searching so that only if we haven't specified an account number do we start searching for another account
    // if (params.data.sourceAccount.accountNo) return false;
    const term = search.toLowerCase();
    return (
      (account.accountNo &&
        account.accountNo.toLowerCase().indexOf(term) > -1) ||
      (account.accountName &&
        account.accountName.toLowerCase().indexOf(term) > -1)
    );
  };

  taxCodeSearch = (taxCode: TaxCode, search: string): boolean => {
    if (!search) {
      return true;
    }

    const term = search.toLowerCase();
    return taxCode.taxCode && taxCode.taxCode.toLowerCase().indexOf(term) > -1;
  };

  checkIfGstAndCalculateTaxAmount = (node: {
    gridApi: GridApi;
    data: JournalLine;
  }) => {
    const { data: journalLine, gridApi } = node;
    const { balance } = journalLine;

    if (!this.isGstEnabled || !journalLine.taxCode || balance === undefined) {
      journalLine.taxAmount = null;
      return this.updateGrid(gridApi, journalLine);
    }

    const rate =
      this.taxCodes.find(({ taxCode }) => taxCode === journalLine.taxCode)
        ?.rate || null;

    if (rate === null) {
      journalLine.taxAmount = null;
      journalLine.balanceMinusTax = 0;
      return this.updateGrid(gridApi, journalLine);
    }

    const taxRate = rate / 100;
    const balanceMinusTax = balance / (1 + taxRate);
    const taxAmount = balance - balanceMinusTax;

    journalLine.balanceMinusTax = Number(balanceMinusTax?.toFixed(2));
    journalLine.taxAmount = Number(taxAmount?.toFixed(2));

    this.updateGrid(gridApi, journalLine);
  };

  onAccountSelect = (
    item: SourceAccount,
    node: { gridApi: GridApi; data: JournalLine }
  ) => {
    node.data.sourceAccount = new SourceAccount(item);
    node.gridApi.applyTransaction({
      update: [node.data],
    });

    if (this.isGstEnabled) {
      node.data.taxCode = node.data.sourceAccount?.taxCode;
    }

    this.checkIfGstAndCalculateTaxAmount(node);
  };

  onTaxCodeSelect = (
    item: TaxCode,
    node: { gridApi: GridApi; data: JournalLine }
  ) => {
    node.data.taxCode = item.taxCode;
    this.checkIfGstAndCalculateTaxAmount(node);
  };

  // ------- Grid Events ------

  onValueChanged(event) {
    if (
      (event.column.colId === 'accountNo' ||
        event.column.colId === 'accountName') &&
      event.newValue !== event.oldValue
    ) {
      // Update matched account
      event.node.data.sourceAccount.id = this.matchId(
        event.data.sourceAccount,
        this.accounts
      );
      this.gridApi.applyTransaction({
        update: [event.node.data],
      });
    }

    if (
      event.column.colId === 'debit' ||
      event.column.colId === 'credit' ||
      event.column.colId === 'quantity'
    ) {
      // Update total Rows
      this.totalsChanged?.();
    }

    if (event.column.colId === 'classification') {
      event.node.data.sourceAccount.classification = event.newValue;
      this.gridApi.applyTransaction({
        update: [event.node.data],
      });
    }

    this.valuesChanged?.();
  }

  cellEditingStarted(event) {
    const isClassificationAndHasSourceAccountId =
      event.column.colId === 'classification' &&
      event.node.data.sourceAccount.id;

    if (isClassificationAndHasSourceAccountId) {
      this.gridOptions.api.stopEditing();
    } else {
      this.gridOptions.api?.startEditingCell(event);
    }
  }

  protected matchId(account: SourceAccount, accounts: SourceAccount[]): string {
    if (!account.accountName) {
      return null;
    }

    const accountNo = account.accountNo
      ? account.accountNo.toLowerCase()
      : null;
    const accountName = account.accountName.toLowerCase();
    const matchedAccount = accounts.find(
      (account) =>
        ((!accountNo && !account.accountNo) ||
          (account.accountNo &&
            account.accountNo.toLowerCase() === accountNo)) &&
        account.accountName.toLowerCase() === accountName
    );

    if (matchedAccount) {
      return matchedAccount.id;
    } else {
      return null;
    }
  }
}
