import { CostCalculationMethod } from './cost-calculation-method';

export interface LivestockCalculatorToUpsert {
  fileId?: string;
  datasetId?: string;
  tradingAccountId?: string;
  costCalculationMethod?: CostCalculationMethod;
  closingStockValue?: number;
  naturalIncreaseUnitPrice?: number;
}

export interface LivestockCalculationResult {
  liveStockCategory: string;
  sourceAccountId: string;
  accountName: string;
  accountNo: string;
  totalQuantity: number;
  totalBalance: number;
  averageCost: number;
}

export interface LivestockCalculatorPostJournal {
  naturalIncreaseFinalQuantity: number;
  deathsFinalQuantity: number;
  closingStockFinalQuantity: number;
  totalClosingStockBalance: number;
}

export interface LivestockCalculation extends LivestockCalculationResult {
  finalQuantity: number;
}

export class LivestockCalculator {
  id: string;
  fileId: string;
  datasetId: string;
  tradingAccountId: string;
  costCalculationMethod: CostCalculationMethod;
  closingStockValue: number;
  naturalIncreaseUnitPrice: number;
  calculations: LivestockCalculationResult[];

  constructor(data) {
    Object.assign(this, data);
  }
}
