import { Component, Inject, OnDestroy, Renderer2 } from '@angular/core';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import {catchError, finalize, switchMap, takeUntil} from 'rxjs/operators';
import { BACO_DATE_FORMAT } from 'src/app/baco/baco.tokens';
import { UploadedFile } from 'src/app/shared/components/file-dragdrop-upload/file-dragdrop-upload.component';
import { BacoFeedStore } from '../../baco-feed.store';
import { BankAccountClient } from 'src/app/baco/common';
import { BacoTransactionStore, IDateRange } from '../../baco-transaction.store';
import { MessageService, ModalService } from '../../../../../core';
import { BacoTransactionValidationValidationStatus } from '../../../../interfaces';

@Component({
  selector: 'crs-upload-transaction',
  templateUrl: './upload-transaction.component.html',
  styleUrls: [ './upload-transaction.component.scss' ]
})
export class UploadTransactionComponent implements OnDestroy {
  public busy = {
    submit: null
  };

  public acceptedFiles = [ '.qif', '.csv' ];
  public azureFolder = 6;

  public uploadedFiles: UploadedFile[] = [];

  private _destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly _bankConnectionService: BacoFeedStore,
              private readonly _fb: UntypedFormBuilder,
              private readonly _activeModal: NgbActiveModal,
              private readonly _datePipe: DatePipe,
              private readonly _bankAccountClient: BankAccountClient,
              public readonly bacoTransactionStore: BacoTransactionStore,
              private readonly _messageService: MessageService,
              private readonly _modalService: ModalService,
              private readonly renderer: Renderer2,
              @Inject(BACO_DATE_FORMAT) private dateFormat: string) {
  }

  public ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  public submit() {
    if (!this.uploadedFiles.length) {
      this._messageService.error('You must choose a file to upload');
      return;
    }

    let dateRange: IDateRange;
    this.bacoTransactionStore.dateRange$.subscribe(val => dateRange = val);

    this._bankAccountClient.validateTransactions(this.bacoTransactionStore.selectedBank$.getValue().id, dateRange, this.uploadedFiles)
      .pipe(
        switchMap(res => {
          if (res.validationStatus === BacoTransactionValidationValidationStatus.Ok) {
            return of(res);
          }
          return new Observable(observer => {
            this._modalService.confirmation(
              res.validationMessages.map(message => `<div>${message}</div>`).join(''),
              () => {
                observer.next();
                observer.complete();
              },
              res.validationStatus == BacoTransactionValidationValidationStatus.Error
            ).catch(x => {
              this._activeModal.close();
            });
          });
        }),
        switchMap(() => {
          return this._bankAccountClient.uploadTransactions(this.bacoTransactionStore.selectedBank$.getValue().id, this.uploadedFiles);
        }),
        catchError((err) => {
          this._messageService.error(err);
          return EMPTY;
        }),
        finalize(() => this.uploadedFiles = []),
        takeUntil(this._destroy)
      )
      .subscribe(
        () => this._activeModal.close(),
        err => {
          console.error(err);
          this._activeModal.close();
        }
      );
  }

  public onFileUpload(uploadedFiles: UploadedFile[]) {
    this.uploadedFiles = uploadedFiles;
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.renderer.addClass(event.target, 'drag-over');
  }

  public onDragLeave(event: DragEvent): void {
    this.renderer.removeClass(event.target, 'drag-over');
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    this.renderer.removeClass(event.target, 'drag-over');
  }

  public cancel(): void {
    this._activeModal.dismiss();
  }
}
