<ng-container *ngIf="!!image" >
    <div class="modal-header">
        <h4 class="modal-title">{{!image.id ? 'New' : ''}} Image Variant</h4>    
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    
    <div class="modal-body">
        <crs-base-image 
            [image]="image" 
            [is-variant]="true"
            [uploaded-file]="uploadedImageFile"
            (close)="close($event)" 
            (submit)="submit($event)">
        </crs-base-image>
    </div>
</ng-container>
