<div class="modal-header">
  <h4 class="modal-title">{{ params.title }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="content">
    <div class="btn-menu w-100 text-right">
      <button
        class="btn btn-sm btn-secondary"
        type="button"
        *ngIf="params.templateContent"
        (click)="hideMasterContent = !hideMasterContent"
      >
        <i aria-hidden="true" class="fas fa-columns"></i>
        Toggle Master Content
      </button>
    </div>

    <div class="row">
      <div
        [class.col-md-12]="hideMasterContent"
        [class.col-md-6]="!hideMasterContent"
      >
        <label class="control-label" *ngIf="!hideMasterContent"
          >Custom Content</label
        >
        <div class="card">
          <div class="card-body">
            <crs-report-content
              [content]="params.content"
              [output]="params.output"
              [readonly]="params.readonly"
            ></crs-report-content>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="!hideMasterContent">
        <label class="control-label">Template</label>
        <div class="card">
          <div class="card-body readonly">
            <crs-report-content
              [content]="params.templateContent"
              [output]="params.output"
              [readonly]="true"
            ></crs-report-content>
          </div>
        </div>
      </div>
      <div class="spacer"></div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="close()">
      Close
    </button>
  </div>
</div>
