<ul class="nav-tabs nav navbar-expand-sm">
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link"
      routerLink="./accounts-allocation"
      [class.active]="currentRoute === 'accounts-allocation'"
    >
      Accounts
    </a>
  </li>
  <li class="nav-item" routerLinkActive="active">
    <a
      class="nav-link"
      routerLink="./source-accounts"
      [class.active]="currentRoute === 'source-accounts'"
    >
      Source Accounts
    </a>
  </li>
</ul>

<div class="nav-divider mx-5"></div>

<div class="spacer-md"></div>
