import { ImageService } from './../image.service';
import { Image } from './../image.class';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'crs-image-select',
  templateUrl: './image-select.component.html',
  styleUrls: ['./image-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ImageSelectComponent implements OnInit, ControlValueAccessor {

  @Input() clearable = true;
  @Input() readonly = false;
  @Input() placeholder: '';

  @ViewChild(NgSelectComponent, { static: true }) private _valueAccessor: NgSelectComponent;

  value: Image;
  values: string;

  images$: Observable<Image[]>;
  private _loaded = false;
  private _loading = false;

  constructor(private readonly _imageService: ImageService) { }

  writeValue(value: Image): void {
    this.value = value;
    if (value instanceof Array && value) {
      this.values = value.map(v => v.name).join(', ');
    }
    this._valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }

  onOpen() {
    if (!this._loaded && !this._loading) {
      this.load();
    }
  }

  load() {
    this._loading = true;
    this.images$ = this._imageService.getAll().pipe(
      tap(() => this._loaded = true),
      catchError(error => {
        console.log(error);
        return EMPTY; // empty list on error
      }),
      finalize(() => this._loading = false));

  }

  ngOnInit() {
    this._valueAccessor.bindLabel = 'name'; // Binding not working with html tag for some reason
  }

}
