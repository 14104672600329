import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { AccountingCoreModule } from '../core/accounting-core.module';
import { ESignatureRequestHistoryComponent } from './esignature-request-history/esignature-request-history.component';
import { ESignatureRootComponent } from './esignature.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AccountingCoreModule,
  ],
  declarations: [
    ESignatureRequestHistoryComponent,
    ESignatureRootComponent
  ]
})
export class ESignatureModule { }
