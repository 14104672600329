<form
  novalidate
  [formGroup]="form"
  [ngBusy]="[busy.load]"
  (validSubmit)="submit()"
>
  <div class="d-flex" style="justify-content: space-between; width: 100%">
    <!-- This div will take up space even if the button isn't displayed -->
    <div *ngIf="!isAdd; else placeholder">
      <button
        class="btn btn-secondary"
        title="Expand All"
        type="button"
        (click)="copyAsNewJournal()"
      >
        <i aria-hidden="true" class="fas fa-copy"></i>
        Copy as New Journal
      </button>
    </div>

    <ng-template #placeholder>
      <!-- Placeholder div  -->
      <div></div>
    </ng-template>

    <crs-check-box
      formControlName="isGstEnabled"
      *ngIf="this.isRegisteredForGst"
      (change)="handleGstCheckboxChange($event)"
      >Enable GST</crs-check-box
    >
  </div>

  <div class="spacer-md"></div>

  <div *ngIf="journal.journalType !== 0">
    <h4 class="mb-4">
      {{ journalTypes[journal.journalType] | sentence }}
    </h4>

    <div class="row">
      <div class="col-xs-10 col-md-6" *ngIf="journal.journalType == 11">
        <div class="form-group required">
          <label class="control-label">Start Date</label>
          <crs-date-picker formControlName="startDate"></crs-date-picker>
        </div>
      </div>

      <div class="col-xs-10 col-md-6">
        <div class="form-group required">
          <label class="control-label">
            <span *ngIf="journal.journalType == 11">End </span>
            Date
          </label>
          <crs-date-picker
            formControlName="date"
            [readonly]="[10, 13, 101].includes(journal.journalType)"
          ></crs-date-picker>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="journal.journalType === 0">
    <div class="row">
      <div class="col-xs-10 col-md-6">
        <div class="form-group required">
          <label class="control-label">Journal No</label>
          <input class="form-control" formControlName="journalNo" type="text" />
        </div>
      </div>
      <div class="col-xs-10 col-md-6">
        <div class="form-group required">
          <label class="control-label">Date</label>
          <crs-date-picker formControlName="date"></crs-date-picker>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label">Memo</label>
      <textarea class="form-control" formControlName="memo" rows="2"></textarea>
    </div>

    <!-- <div class="form-group" *ngIf="journal.canExport && journal.journalCategory !== journalCategories['Exported']">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="isExportable">
        <label class="form-check-label">Export Journal to Client File</label>
      </div>
    </div> -->

    <div
      class="form-group"
      *ngIf="journal.journalCategory === journalCategories['Exported']"
    >
      <span class="badge badge-primary mr-2"
        >Exported in Balancing Journal</span
      >
      <a
        *ngIf="journal.exportedJournalId"
        [routerLink]="['../' + journal.exportedJournalId]"
        >Review balancing journal</a
      >
    </div>
  </div>

  <div
    class="form-group"
    *ngIf="journal.journalType === journalTypes['BalancingJournal']"
  >
    <button
      class="btn btn-secondary"
      type="button"
      [promiseBtn]="busy.undoBalancingJournal"
      (click)="undoBalancingJournal()"
    >
      Undo Balancing Journal
    </button>
  </div>

  <div class="spacer-md"></div>

  <!-- Grid -->
  <!-- Search -->
  <div class="row">
    <div class="col-lg-12">
      <div class="btn-toolbar d-flex" role="toolbar">
        <div class="icon-input-wrapper flex-grow-1 mr-2">
          <i aria-hidden="true" class="fas fa-search input-icon"></i>
          <input
            aria-describedby="searchIcon"
            placeholder="Search..."
            type="search"
            [formControl]="search"
          />
        </div>

        <div class="btn-group d-inline-block mr-2" role="group">
          <button
            class="btn btn-secondary"
            title="Expand All"
            type="button"
            (click)="switchDebitCredit()"
          >
            <i aria-hidden="true" class="fas fa-random"></i>
            Switch Dr/Cr
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <ag-grid-angular
      #agGrid
      class="ag-theme-material"
      [enableRangeSelection]="true"
      [frameworkComponents]="journalGrid.renderers"
      [gridOptions]="journalGrid.gridOptions"
      [groupIncludeTotalFooter]="true"
      [rowData]="journal.journalLines"
      [rowDragManaged]="true"
      [suppressCellSelection]="false"
      [suppressHorizontalScroll]="true"
      (cellDoubleClicked)="onCellDoubleClicked($event)"
      (cellEditingStarted)="journalGrid.cellEditingStarted($event)"
      (cellValueChanged)="journalGrid.onValueChanged($event)"
    >
      <ag-grid-column
        colId="drag"
        headerName=""
        [maxWidth]="30"
        [minWidth]="30"
        [rowDrag]="canDrag"
        [rowDragText]="rowDragText"
      ></ag-grid-column>

      <ag-grid-column
        colId="new"
        headerName=""
        pinned="none"
        type="optionsColumn"
        [cellRendererParams]="{ ngTemplate: newAccountCell }"
        [maxWidth]="45"
        [minWidth]="45"
      ></ag-grid-column>

      <ag-grid-column
        colId="accountNo"
        field="sourceAccount.accountNo"
        headerName="Account No"
        type="typeAheadColumn"
        [cellEditorParams]="journalGrid.accountNoSearchParams"
        [editable]="true"
        [maxWidth]="160"
        [minWidth]="110"
        [width]="110"
      ></ag-grid-column>

      <ag-grid-column
        colId="accountName"
        field="sourceAccount.accountName"
        headerName="Account Name"
        type="typeAheadColumn"
        [cellEditorParams]="journalGrid.accountNameSearchParams"
        [editable]="true"
        [maxWidth]="300"
        [minWidth]="140"
        [width]="200"
      ></ag-grid-column>

      <ag-grid-column
        cellRenderer="classificationRenderer"
        colId="classification"
        field="sourceAccount.classification"
        headerName="Class"
        tooltipField="sourceAccount.classificationToolTipText"
        type="typeAheadColumn"
        [cellEditorParams]="journalGrid.classificationEditorParams"
        [editable]="isClassEditable"
        [maxWidth]="110"
        [minWidth]="80"
      ></ag-grid-column>

      <ag-grid-column
        colId="debit"
        field="debit"
        headerName="Debit"
        type="dollarColumn"
        [cellClass]="['dollar-cell', 'ag-numeric-cell', 'highlight-column']"
        [editable]="true"
        [onCellValueChanged]="checkIfGstAndCalculateTaxAmount"
      ></ag-grid-column>

      <ag-grid-column
        colId="credit"
        field="credit"
        headerName="Credit"
        type="dollarColumn"
        [cellClass]="['dollar-cell', 'ag-numeric-cell', 'highlight-column']"
        [editable]="true"
        [onCellValueChanged]="checkIfGstAndCalculateTaxAmount"
      ></ag-grid-column>

      <ag-grid-column
        colId="quantity"
        field="quantity"
        headerName="Quantity"
        type="numberColumn"
        [editable]="true"
        [minWidth]="100"
        [width]="120"
      ></ag-grid-column>

      <ag-grid-column
        cellEditor="agRichSelectCellEditor"
        cellRenderer="divisionRenderer"
        coldId="division"
        field="division"
        headerName="Division"
        [cellEditorParams]="journalGrid.divisionEditorParams"
        [editable]="true"
        [minWidth]="80"
        [width]="120"
      ></ag-grid-column>

      <ag-grid-column
        colId="taxCode"
        field="taxCode"
        headerName="Tax Code"
        type="typeAheadColumn"
        [cellEditorParams]="journalGrid.taxCodeSearchParams"
        [editable]="true"
        [minWidth]="100"
        [onCellValueChanged]="checkIfGstAndCalculateTaxAmount"
        [width]="250"
      ></ag-grid-column>

      <ag-grid-column
        colId="taxAmount"
        field="taxAmount"
        headerName="Tax Amount"
        type="dollarColumn"
        [editable]="true"
        [minWidth]="100"
        [valueGetter]="getAbsoluteValue"
        [valueSetter]="taxAmountSetter"
        [width]="250"
      ></ag-grid-column>

      <ag-grid-column
        colId="memo"
        field="memo"
        headerName="Memo"
        type="commentsColumn"
        [editable]="true"
        [minWidth]="100"
        [width]="250"
      ></ag-grid-column>

      <ag-grid-column
        colId="delete"
        headerName=""
        pinned="none"
        type="optionsColumn"
        [cellRendererParams]="{ ngTemplate: optionsCell }"
        [maxWidth]="40"
      ></ag-grid-column>
    </ag-grid-angular>
  </div>

  <!-- New Account Cell -->
  <ng-template #newAccountCell let-row>
    <a
      ngbPopover="This account number and name combination has not been seen before. If you proceed, this account will be created by Access Ledger."
      placement="right"
      popoverTitle="New Account"
      *ngIf="
        row &&
        row.sourceAccount &&
        !row.sourceAccount.id &&
        row.sourceAccount.accountName
      "
      [routerLink]="[]"
    >
      <span class="badge badge-danger">NEW</span>
    </a>
  </ng-template>

  <!-- Account Search Result-->
  <ng-template #accountSearchTemplate let-result="result" let-term="term">
    <ngb-highlight
      [result]="
        (result.accountNo ? result.accountNo + '  ' : '') + result.accountName
      "
      [term]="term"
    ></ngb-highlight>
  </ng-template>

  <!-- Classification Result-->
  <ng-template #classificationTemplate let-result="result" let-term="term">
    <ngb-highlight
      [result]="journalGrid.classifications[result]"
      [term]="term"
    ></ngb-highlight>
  </ng-template>

  <!-- Account Search Result-->
  <ng-template #taxCodeSearchTemplate let-result="result" let-term="term">
    <ngb-highlight
      [result]="result.descriptionLabel"
      [term]="term"
    ></ngb-highlight>
  </ng-template>

  <!-- Delete Column-->
  <ng-template #optionsCell let-row>
    <div class="btn-mini-menu" *ngIf="row">
      <button
        class="btn-sm fas-btn-ghost"
        title="Delete Row"
        type="button"
        (click)="removeJournalLine(row)"
      >
        <i aria-hidden="true" class="fas fa-trash"></i>
      </button>
    </div>
  </ng-template>

  <div class="spacer"></div>

  <button
    class="btn btn-secondary"
    type="button"
    *ngIf="!journal.isReadonly"
    (click)="addRows(5)"
  >
    Add Lines
  </button>

  <div class="float-right text-right" *ngIf="outOfBalance > 0.001">
    <p>
      Out of balance by <strong>${{ outOfBalance | money : 2 }}</strong
      ><br />
      <small>
        <i class="fas fa-info-circle text-primary"></i>
        Double click on a cell to assign the out of balance amount
      </small>
    </p>
  </div>

  <div class="spacer-md"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu">
    <button
      class="btn btn-primary"
      type="submit"
      *ngIf="!journal.isReadonly"
      [promiseBtn]="busy.submit"
    >
      {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      {{ journal.isReadonly ? 'Close' : 'Cancel' }}
    </button>
    <button
      class="btn btn-delete"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      [promiseBtn]="busy.delete"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
  </div>
</form>
