import { ComponentPortal, Portal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { CustomizedConfirmationComponent } from 'src/app/shared/components';
import { Confirmation, EnhancedConfirmation } from '.';

export const OVERLAY_DATA = new InjectionToken<{}>('OverlayData');

@Injectable({ providedIn: 'root' })
export class ModalService {
  isOpen = false;
  overlayPortal: Portal<any>;

  constructor(
    private readonly _ngbModal: NgbModal,
    private _injector: Injector
  ) {}

  openModal<T>(
    content: any,
    id: string = null,
    params: any = {},
    options?: NgbModalOptions
  ): Promise<any> {
    const consolidatedOptions = {
      ...{ size: 'lg', backdrop: 'static' },
      ...options,
    };
    if (content.modalSize) {
      consolidatedOptions.size = content.modalSize;
    }

    const modalRef = this._ngbModal.open(
      content,
      consolidatedOptions as NgbModalOptions
    );

    if (id) {
      modalRef.componentInstance.id = id;
    }
    if (params) {
      modalRef.componentInstance.params = params;
    }

    this.isOpen = true;
    modalRef.result.finally(() => (this.isOpen = false));

    return modalRef.result;
  }

  confirmation(
    text: string,
    action: () => any,
    danger: boolean = false,
    passPhrase: string = null,
    approveBtn: string = null,
    cancelBtn: string = null
  ) {
    const modalRef = this._ngbModal.open(ConfirmationComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.text = text;
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.danger = danger;
    modalRef.componentInstance.passPhrase = passPhrase;
    modalRef.componentInstance.approveBtn = approveBtn;
    modalRef.componentInstance.cancelBtn = cancelBtn;

    return modalRef.result;
  }

  confirmation2(confirmation: Confirmation) {
    const modalRef = this._ngbModal.open(ConfirmationComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.title = confirmation.title;
    modalRef.componentInstance.text = confirmation.text;
    modalRef.componentInstance.action = confirmation.action;
    modalRef.componentInstance.danger = confirmation.danger;
    modalRef.componentInstance.passPhrase = confirmation.passPhrase;
    modalRef.componentInstance.shouldFocusOnSubmitButton =
      confirmation.shouldFocusOnSubmitButton;

    return modalRef.result;
  }

  confirmationEx(confirmation: EnhancedConfirmation) {
    const modalRef = this._ngbModal.open(CustomizedConfirmationComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.title = confirmation.title;
    modalRef.componentInstance.text = confirmation.text;
    modalRef.componentInstance.approveAction = confirmation.approveAction;
    modalRef.componentInstance.cancelAction = confirmation.cancelAction;
    modalRef.componentInstance.danger = confirmation.danger;
    modalRef.componentInstance.showUnderstandConsequencesSuffix =
      confirmation.showUnderstandConsequencesSuffix;
    modalRef.componentInstance.passPhrase = confirmation.passPhrase;
    modalRef.componentInstance.cancelBtn = confirmation.cancelButton;
    modalRef.componentInstance.approveBtn = confirmation.approveBtn;
    modalRef.componentInstance.additionalInfoText =
      confirmation.additionalInfoText;
    modalRef.componentInstance.alertText = confirmation.alertText;
    modalRef.componentInstance.alertClass = confirmation.alertClass;

    return modalRef.result;
  }

  showInformationDialog(
    text: string,
    danger: boolean = false,
    approveBtn: string = null,
    title: string = null,
    action: () => any
  ) {
    const modalRef = this._ngbModal.open(ConfirmationComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.text = text;
    modalRef.componentInstance.danger = danger;
    modalRef.componentInstance.approveBtn = approveBtn;
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.showDefaultWarning = false;
    modalRef.componentInstance.hideCancelButton = true;
    modalRef.componentInstance.title = title;

    return modalRef.result;
  }

  overlay<T>(content: any, data: any) {
    this.overlayPortal = new ComponentPortal(
      content,
      null,
      this.createInjector(data)
    );

    window.scrollTo(0, 0);
  }

  closeOverlay() {
    this.overlayPortal = null;
  }

  private createInjector(data): PortalInjector {
    const injectorTokens = new WeakMap<any, any>([[OVERLAY_DATA, data]]);

    return new PortalInjector(this._injector, injectorTokens);
  }
}
