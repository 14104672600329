import { Directive, HostListener, ElementRef, OnInit, Input } from '@angular/core';
import { BusinessNumberPipe } from '../';

@Directive({
  selector: '[crsBusinessNumber]',
  providers: [BusinessNumberPipe]
})
export class BusinessNumberDirective {

  @Input('crsBusinessNumber') type: 'abn' | 'acn' | 'tfn';
  private el: any;

  constructor(private elementRef: ElementRef,
    private businessNumberPipe: BusinessNumberPipe) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.businessNumberPipe.transform(this.el.value, this.type);
  }

  //@HostListener("focus", ["$event.target.value"])
  //onFocus(value) {
  //  this.el.value = this.businessNumberPipe.parse(value, this.type);
  //}

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    this.el.value = this.businessNumberPipe.transform(value, this.type);
  }

}
