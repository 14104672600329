import { DisclosureVariantService } from './../../../notes/disclosures/disclosure-variant.service';
import { ReportContentModalComponent } from './../../../reportViewer/report-content-modal/report-content-modal.component';
import { DisclosureSelectorsService } from './../../../notes/disclosures/disclosure-selectors.service';
import { Subscription, merge, EMPTY, of, concat } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { ReportTemplateService } from '../../report-template.service';
import { ReportTemplate } from '../../reportTemplate';
import { PolicySelector } from '../../../notes/policies/policySelector';
import { PolicySelectionMode } from '../../../notes/policies/policySelector';
import { ActiveFileService } from '../../../../active-file.service';
import { MessageService, ModalService } from '../../../../../core';
import {
  tap,
  first,
  catchError,
  map,
  finalize,
  switchMap,
} from 'rxjs/operators';
import { DisclosureSelectionMode } from '../../../notes/disclosures';
import { Disclosure } from '../../../notes/disclosures/disclosure';

@Component({
  selector: 'crs-report-template-note-page-detail',
  templateUrl: './report-template-note-page-detail.component.html',
})
export class ReportTemplateNotePageDetailComponent
  implements OnInit, OnDestroy
{
  @Input('index') i: number;
  @Input() templateGroup: UntypedFormGroup;
  @Input() isAdd: boolean;
  @Input() set isExpanded(value: boolean) {
    if (value) {
      this.initialise();
    }
  }
  private _detail: UntypedFormGroup;
  @Input() set detail(value: UntypedFormGroup) {
    if (this._detail !== value) {
      this.unsubscribe();
      this._detail = value;
      this.initialiseForm();
    }
  }
  get detail() {
    return this._detail;
  }

  get selectorsArray(): UntypedFormArray {
    if (!this.detail) return null;
    return this.detail.get('policySelectorsFull') as UntypedFormArray;
  }

  get disclosuresArray(): UntypedFormArray {
    if (!this.detail) return null;
    return this.detail.get('disclosuresFull') as UntypedFormArray;
  }

  selectorsLoaded = {
    reportingSuiteId: null,
    entityType: null,
  };
  busy = {
    selectorsLoad: null,
  };
  initialised = {
    disclosures: false,
  };
  get disclosuresLoading() {
    return this._disclosureSelectorsService.isLoading;
  }
  policySelectorsList: PolicySelector[];
  policySelectionModes = PolicySelectionMode;
  disclosuresList: Disclosure[];
  disclosureSelectionModes = DisclosureSelectionMode;

  subscriptions: Subscription[] = [];
  disclosureFormSubscriptions: Subscription[] = [];

  constructor(
    private readonly _reportTemplateService: ReportTemplateService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _activeFileService: ActiveFileService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    private readonly _disclosureSelectorsService: DisclosureSelectorsService,
    private readonly _disclosureVariantService: DisclosureVariantService
  ) {}

  ngOnInit() {
    this.initialiseForm();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private initialise() {
    this.getPolicies$().subscribe();

    if (!this.initialised.disclosures) {
      this.subscriptions.push(
        this._disclosureSelectorsService.getDisclosureStream().subscribe(
          (disclosures) => {
            this.disclosuresList = this.sortDisclosuresList(disclosures);

            this.patchSelectors(
              this.disclosuresArray,
              this.disclosuresList,
              'disclosure'
            );
          },
          (err) => this.showError(err)
        )
      );
      this.initialised.disclosures = true;
    }
  }

  private initialiseForm() {
    // reset this as we haven't loaded the policies for the new form yet
    this.selectorsLoaded = {
      reportingSuiteId: null,
      entityType: null,
    };

    this.subscriptions.push(
      merge(
        this.detail.get('reportingSuite').valueChanges,
        this.detail.get('auto').valueChanges,
        this.templateGroup
          ? this.templateGroup.get('entityType').valueChanges
          : EMPTY
      )
        .pipe(
          switchMap(() => this.getPolicies$()),
          tap(() => this.updateDisclosureReportingSuite())
        )
        .subscribe()
    );
  }

  private unsubscribe() {
    this.subscriptions.forEach((s) => s.unsubscribe);
    this.subscriptions = [];
    this.unsubscribeDisclosureForm();
  }

  private unsubscribeDisclosureForm() {
    this.disclosureFormSubscriptions.forEach((s) => s.unsubscribe);
    this.disclosureFormSubscriptions = [];
  }

  private getPolicies$() {
    if (this.detail.controls['auto'].value) return of([]);

    const template = this.templateGroup.value as ReportTemplate;
    const reportingSuiteId = this.detail.get('reportingSuite').value.id;
    const entityType = template.entityType;

    if (
      this.selectorsLoaded.reportingSuiteId === reportingSuiteId &&
      this.selectorsLoaded.entityType === entityType
    ) {
      return of([]); // already loaded
    }

    this.busy.selectorsLoad = true;
    return this._reportTemplateService
      .getPolicies(
        this.isAdd ? 'add' : template.id,
        this._activeFileService.file.id,
        reportingSuiteId,
        entityType
      )
      .pipe(
        tap((selectors) => {
          this.policySelectorsList = selectors;
          this.patchSelectors(this.selectorsArray, selectors, 'selector');
          this.selectorsLoaded.reportingSuiteId = reportingSuiteId;
          this.selectorsLoaded.entityType = entityType;
        }),
        catchError((err) => {
          this.showError(err);
          return of([]);
        }),
        finalize(() => (this.busy.selectorsLoad = false))
      );
  }

  private updateDisclosureReportingSuite() {
    const reportingSuiteId = this.detail.get('reportingSuite').value.id;
    this._disclosureSelectorsService.updateContextProperties({
      reportingSuiteId: reportingSuiteId,
    });
  }

  private patchSelectors(
    formArray: UntypedFormArray,
    selectors: PolicySelector[] | Disclosure[],
    type: 'selector' | 'disclosure'
  ) {
    this.setNoOfSelectors(
      formArray,
      selectors && selectors.length ? selectors.length : 0,
      type
    );
    if (selectors) {
      formArray.patchValue(selectors);
    }
    // Subscribe to disclosure changes after patch so that updates can be passed on to other parts of the form
    if (type === 'disclosure') {
      this.unsubscribeDisclosureForm();
      this.subscribeToDisclosureChanges(formArray);
    }
  }

  private setNoOfSelectors(
    formArray: UntypedFormArray,
    count: number,
    type: 'selector' | 'disclosure'
  ) {
    while (formArray.length > 0) {
      formArray.removeAt(formArray.length - 1);
    }
    while (formArray.length < count) {
      if (type === 'selector') formArray.push(this.initSelector());
      else formArray.push(this.initDisclosure());
    }
  }

  private initSelector() {
    return this._formBuilder.group({
      policyChainId: [null],
      selectedPolicyVariantId: [null],
      selectionMode: [0, Validators.required],
    });
  }

  private initDisclosure() {
    return this._formBuilder.group({
      disclosureTemplateChainId: [null],
      selectedDisclosureVariantId: [null],
      selectionMode: [0, Validators.required],
      customise: [false],
      content: [[]],
    });
  }

  private subscribeToDisclosureChanges(disclosuresFormArray: UntypedFormArray) {
    disclosuresFormArray.controls.forEach((group) => {
      if (group instanceof UntypedFormGroup) {
        const changes = merge(
          group.get('selectedDisclosureVariantId').valueChanges.pipe(
            tap((v) => {
              const disclosure = this.disclosuresList.find(
                (d) =>
                  d.disclosureTemplateChainId ===
                  group.controls.disclosureTemplateChainId.value
              );
              if (disclosure) disclosure.selectedDisclosureVariantId = v;
            }),
            catchError(() => EMPTY)
          ),
          group.get('selectionMode').valueChanges.pipe(
            tap((v) => {
              const disclosure = this.disclosuresList.find(
                (d) =>
                  d.disclosureTemplateChainId ===
                  group.controls.disclosureTemplateChainId.value
              );
              if (disclosure) disclosure.selectionMode = v;
            }),
            catchError(() => EMPTY)
          )
        ).pipe(
          tap(() => {
            this._disclosureSelectorsService.disclosuresUpdated$.next();
            this.handleSortAfterSelectionModeChange();
          }),
          catchError(() => EMPTY)
        );
        this.disclosureFormSubscriptions.push(changes.subscribe());
      }
    });
  }

  private sortDisclosuresList(disclosures: Disclosure[]): Disclosure[] {
    return disclosures?.sort(
      (a, b) => Number(a.sortOrder) - Number(b.sortOrder)
    );
  }

  showError(err) {
    this._messageService.error(err);
  }

  editDisclosureContent(
    disclosureStatic: Disclosure,
    disclosureSelector: Disclosure
  ) {
    let stream = of(disclosureStatic.currentDisclosureVariant).pipe(first()); // first() ensures closing of observable
    const variantId = disclosureSelector.selectedDisclosureVariantId
      ? disclosureSelector.selectedDisclosureVariantId
      : disclosureStatic.defaultDisclosureVariant.id;

    if (
      !disclosureStatic.currentDisclosureVariant ||
      disclosureStatic.currentDisclosureVariant.id !== variantId
    ) {
      stream = this._disclosureVariantService
        .get(variantId)
        .pipe(tap((v) => (disclosureStatic.currentDisclosureVariant = v)));
    }

    stream.subscribe(
      (v) => {
        if (!disclosureSelector.content) {
          disclosureSelector.content = JSON.parse(JSON.stringify(v.content)); // Todo: can a deep clone be done a better way?
        }

        this._modalService.openModal(ReportContentModalComponent, null, {
          title: 'Customise Disclosure',
          content: disclosureSelector.content,
          templateContent: v.content,
          readonly: false,
          output: false,
        });
      },
      (e) => this.showError(e)
    );
  }

  handleSortAfterSelectionModeChange() {
    // Ensure to update the disclosuresList with the sorted list
    this.disclosuresList = this.sortDisclosuresList(this.disclosuresList);
  }
}
