import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, EMPTY } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';

import { ReportStyle } from '../models';
import { ReportStyleService } from '../report-style.service';

@Component({
  selector: 'crs-report-style-select',
  templateUrl: './report-style-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ReportStyleSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReportStyleSelectComponent implements ControlValueAccessor, OnInit {

  @Input() clearable = true;
  @Input() readonly = false;
  @Input() placeholder: string;

  @ViewChild(NgSelectComponent, { static: true }) private _valueAccessor: NgSelectComponent;

  reportingSuites$: Observable<ReportStyle[]>;
  private _loaded = false;
  private _loading = false;

  constructor(private readonly _reportStyleService: ReportStyleService) { }

  writeValue(value: ReportStyle): void {
    this._valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }

  onOpen() {
    if (!this._loaded && !this._loading) {
      this.load();
    }
  }

  load() {
    this._loading = true;
    this.reportingSuites$ = this._reportStyleService.getAll().pipe(
      tap(() => this._loaded = true),
      catchError(error => {
        console.log(error);
        return EMPTY; // empty list on error
      }),
      finalize(() => this._loading = false));

  }

  ngOnInit() {
    this._valueAccessor.bindLabel = 'name'; // Binding not working with html tag for some reason
  }

}
