export enum Classification {
  Assets = 1,
  Liabilities = 2,
  Equity = 3,
  Revenue = 4,
  'Cost of Sales' = 5,
  Expenses = 6,
  Other = 7
}

export enum CurrentClassification {
  Current = 1,
  'Non-Current' = 2
}



