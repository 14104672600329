import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription, Subject, merge, of, EMPTY } from 'rxjs';
import { map, filter, tap, switchMap, debounceTime, distinctUntilChanged, catchError, take, finalize } from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import { ModalService } from 'src/app/core';
import { AssetGroupComponent } from '../asset-group/asset-group.component';
import { getDefaultGridOptions, ProgressBarService } from 'src/app/shared';
import { AssetsContextService } from '../../assets-context.service';
import { AssetGroupService } from '../asset-group.service';
import { AssetsContext } from '../../assets-context';
import { AssetGroup } from '../asset-group';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsImportComponent } from '../../assets-import/assets-import.component';

@Component({
  selector: 'crs-asset-groups',
  templateUrl: './asset-groups.component.html',
  styleUrls: ['./asset-groups.component.scss']
})
export class AssetGroupsComponent implements OnInit, OnDestroy {

  busy = {
    loading: false
  };

  assetsContext: AssetsContext;

  groupId: number;
  groups: AssetGroup[];

  search = new UntypedFormControl();
  error: string = null;
  subscriptions: Subscription[] = [];
  refreshAssetGroups$ = new Subject();
  refreshedGroups$ = new Subject<AssetGroup[]>();

  gridOptions = getDefaultGridOptions();

  constructor(private readonly _assetsContextService: AssetsContextService,
    private readonly _assetGroupService: AssetGroupService,
    private readonly _modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private _progressBar: ProgressBarService) { }

  ngOnInit() {

    if (this.route.children.length > 0) {
      this.groupId = parseInt(this.route.children[0].snapshot.paramMap.get('groupId'), 10);
    }

    // Asset Groups
    this.subscriptions.push(
      merge(
        this._assetsContextService.contextValid$,
        this.refreshAssetGroups$.pipe(
          map(() => this._assetsContextService.currentContext),
          filter(c => !!c && !!c.entity && !!c.file)
        )
      )
      .pipe(
        tap(c => this.assetsContext = c),
        switchMap(c => this.getAssetGroups(c))
      )
      .subscribe()
    );

    // Accounts Filter/Search
    this.subscriptions.push(this.search.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged())
      .subscribe(data => {
        this.gridOptions.api.setQuickFilter(data);
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private getAssetGroups(c: AssetsContext) {
    this.busy.loading = true;
    return this._assetGroupService.getAll(c.file.id, c.entity.id).pipe(
      catchError(err => {
        this.busy.loading = false;
        this.showError(err);
        return of([]);
      }),
      tap(result => {
        this.busy.loading = false;
        this.refreshedGroups$.next(result);
        this.groups = result;
        if (!this.groups || !this.groups.length) this.activateGroup(null);
        else if (result.length && (!this.groupId || this.groups.every(g => g.id !== this.groupId))) this.activateGroup(result[0].id);
      })
    );
  }

  activateGroup(groupId: number) {
    this.groupId = groupId;
    if (!groupId) this.router.navigate(['./'], { relativeTo: this.route });
    else this.router.navigate([this.groupId, 'assets'], { relativeTo: this.route });
  }

  addGroup() {
    this.router.navigate(['./', 'add'], { relativeTo: this.route });
  }

  editGroup(id: number) {
    this.router.navigate(['./', id], { relativeTo: this.route });
  }

  removeGroup(group: AssetGroup) {
    this._modalService.confirmation(`
    <div class="alert alert-danger">Are you absolutely sure you want to delete this asset group?</div>

    <p>This action <strong>cannot</strong> be undone. This will permanently delete the asset group and all linked asset records.</p>`,
    () => this.deleteGroup(group.id), true, group.name);
  }

  deleteGroup(id: number) {
    this.error = null;
    this._assetGroupService.delete(id).pipe(
      catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      tap(() => this.refreshAssetGroups$.next()),
      ).subscribe();
  }

  importAssets() {
    this._modalService.openModal(AssetsImportComponent).then(() => {
      this.refreshAssetGroups$.next();
    }, () => true);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    console.log(event);
    const progressBar = this._progressBar.ref('progressBar');
    const original = this.groups.slice();

    // Execute in UI immediately for fluidity
    moveItemInArray(this.groups, event.previousIndex, event.currentIndex);

    // Execute in back-end
    this._assetGroupService.reorder(event.item.data.id, event.currentIndex + 1).pipe(
      tap(() => progressBar.start()),
      switchMap(() => this._assetGroupService.getAll(this.assetsContext.file.id, this.assetsContext.entity.id)),
      tap(g => this.groups = g),
      catchError(e => {
        this.error = e;
        this.groups = original;
        return EMPTY;
      }),
      finalize(() => progressBar.complete())
    ).subscribe();

  }

  private showError(err) {
    this.error = err;
  }

}
