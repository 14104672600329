import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { PortalModule } from '@angular/cdk/portal';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {MatStepperModule} from '@angular/material/stepper';

@NgModule({
  exports: [
    DragDropModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    PortalModule,
    MatChipsModule,
    MatMenuModule,
    MatStepperModule,
  ],
  providers: [
   {
    provide: MAT_DATE_LOCALE,
    useValue: 'en-AU'
   }
  ]
})
export class MaterialModule {
}
