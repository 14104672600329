import { Account } from './account';
import { AccountType } from '../account-types';
import { AccountTypeMode } from './accountTypeMode';

export class HeaderAccount {
  id: string;
  parentId: string;
  parent: Account;
  accountNo: string;
  accountName: string;
  accountType: AccountType;
  accountTypeMode: AccountTypeMode;
  openingBalanceAccountId: string;
  
  getCreateModel(parent?: Account) {
    const model = this.getUpdateModel();
    if (parent) model.parentId = parent.id;
    if (model.parent) model.parentId = model.parent.id;
    return model;
  }

  getUpdateModel() {
    const model = Object.assign(this);
    if (model.accountType) {
      model.accountTypeId = model.accountType.id;
      model.accountType = null;
    }
    return model;
  }

  constructor(data) {
    Object.assign(this, data);
    if (data.parent) {
      this.parent = new Account(data.parent);
    }
  }
}
