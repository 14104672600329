import {
  Component,
  ViewContainerRef,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, merge } from 'rxjs';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';
import { AgEditorComponent } from 'ag-grid-angular';

type SearchFunction = {
  (obj: any, term: string, params: any): boolean;
};
type SelectFunction = {
  (item: any, node: any): void;
};

@Component({
  selector: 'crs-type-ahead-editor',
  templateUrl: './type-ahead-editor.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TypeAheadEditorComponent
  implements AgEditorComponent, AfterViewInit
{
  @ViewChild('typeahead', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  list: any[] = [];
  property?: string;
  template?: any = null;
  node: any = null;
  editable = false;
  waitForDropdown = false;
  clearable = false;

  selected = new FormControl();
  maxResults = 15;
  focus$ = new Subject<string>();

  searchFn: SearchFunction = this.defaultSearchFn;
  onSelectFn: SelectFunction = () => false;

  search = (text$: Observable<string>): Observable<any[]> => {
    const debouncedText$ = text$.pipe(
      debounceTime(100),
      distinctUntilChanged()
    );

    const focus$ = this.focus$.pipe(filter(() => !this.waitForDropdown));

    return merge(debouncedText$, focus$).pipe(
      map((term) => this.performSearch(term))
    );
  };

  performSearch(term: string) {
    return term
      ? this.list
          .filter((x) => this.searchFn(x, term, this.node))
          .slice(0, this.maxResults)
      : this.list.slice(0, this.maxResults);
  }

  formatter = (x: any) =>
    this.property && x[this.property] ? x[this.property] : x;

  defaultSearchFn(obj: any, term: string): boolean {
    const prop = this.property ? obj[this.property] : obj;
    return prop ? prop.toLowerCase().includes(term.toLowerCase()) : false;
  }

  getValue(): any {
    return typeof this.selected.value === 'string'
      ? this.selected.value
      : this.property && this.selected.value
        ? this.selected.value[this.property]
        : this.selected.value;
  }

  isPopup(): boolean {
    return true;
  }

  onSelect(event: { item: any }): void {
    this.onSelectFn?.(event.item, this.node);
  }

  clearSelection(): void {
    this.selected.reset();
    this.onSelectFn?.('', this.node);
  }

  agInit(params: any): void {
    const {
      value,
      getList,
      list,
      property,
      template,
      searchFn,
      onSelect,
      formatter,
      node,
      editable,
      waitForDropdown,
      clearable,
    } = params;

    this.selected.setValue(value);
    this.list = getList ? getList() : list;
    this.property = property;
    this.template = template;
    this.node = node;
    this.editable = !!editable;
    this.waitForDropdown = !!waitForDropdown;
    this.clearable = !!clearable;

    if (searchFn) this.searchFn = searchFn;
    if (onSelect) this.onSelectFn = onSelect;
    if (formatter) this.formatter = formatter;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.container.element.nativeElement.focus();
      setTimeout(() => this.container.element.nativeElement.select(), 50);
    });
  }
}
