import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-account-headers-count-renderer',
  template: `
    <div class="account-headers-count" title="" *ngIf="params.data?.isHeader">
      <span>
        <small>{{ subHeaders }} sub headers | {{ accounts }} Accounts</small>
      </span>
    </div>
  `,
})
export class AccountHeadersCountRendererComponent
  implements ICellRendererAngularComp
{
  params: any;
  subHeaders: number = 0;
  accounts: number = 0;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const childrenNodes = this.collectChildrenNodes(params.node as RowNode);
    this.calculateCounts(childrenNodes);
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.subHeaders = 0;
    this.accounts = 0;

    const childrenNodes = this.collectChildrenNodes(params.node as RowNode);
    this.calculateCounts(childrenNodes);
    return true;
  }

  collectChildrenNodes(node: RowNode): RowNode[] {
    let childrenNodes: RowNode[] = [];
    if (node && node.childrenAfterGroup) {
      node.childrenAfterGroup.forEach((childNode) => {
        childrenNodes.push(childNode);
        const grandChildrenNodes = this.collectChildrenNodes(childNode);
        if (grandChildrenNodes.length > 0) {
          childrenNodes = childrenNodes.concat(grandChildrenNodes);
        }
      });
    }
    return childrenNodes;
  }

  calculateCounts(nodes: RowNode[]) {
    for (const node of nodes) {
      if (node.data?.isHeader) {
        this.subHeaders++;
      } else {
        this.accounts++;
      }
    }
  }
}
