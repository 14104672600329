import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModalService } from '../../../../core';
import { ActiveFileService } from '../../../active-file.service';
import { SourceService } from '../source.service';
import { SourceComponent } from '../source/source.component';

@Component({
  selector: 'crs-source-select',
  templateUrl: './source-select.component.html',
  styleUrls: ['./source-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SourceSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SourceSelectComponent implements OnInit, ControlValueAccessor {
  @Input() includeInactive = false;
  @Input() readonly = false;

  @Output() onRequestedAddSource: EventEmitter<boolean> = new EventEmitter();

  source: any;

  @ViewChild(NgSelectComponent, { static: true })
  private valueAccessor: ControlValueAccessor;

  sources$: Observable<any>;
  loading = false;
  onChange;

  constructor(
    private activeFileService: ActiveFileService,
    private modalService: ModalService,
    private sourceService: SourceService
  ) {}

  writeValue(value: any): void {
    this.source = value;
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  ngOnInit() {
    (this.valueAccessor as NgSelectComponent).bindLabel = 'name';
    this.loadData();
  }

  onClickAddSource(): void {
    this.onRequestedAddSource.emit(true);
    this.modalService
      .openModal(SourceComponent, 'add')
      .then((source) => {
        this.writeValue(source);
        this.onChange(source);
        this.loadData();
      })
      .catch(() => false);
  }

  loadData() {
    if (this.readonly) return;
    this.loading = true;
    this.sources$ = this.sourceService
      .getAll$(this.activeFileService.file.id)
      .pipe(tap(() => (this.loading = false)));
  }
}
