import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export class CustomValidators {
  public static relativeToControl(relativeControlName: string, expectedValue: any, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return this.getControlErrors({ control, relativeControlName, expectedValue, validator });
    };
  }

  private static getControlErrors({ control, relativeControlName, expectedValue, validator }): ValidationErrors {
    const dependentControl: AbstractControl = this.getControlByName(control, relativeControlName);

    if (dependentControl) {
      if (Array.isArray(expectedValue) && expectedValue.length && expectedValue.includes(dependentControl.value)) {
        return validator(control);
      }

      if (dependentControl.value === expectedValue) {
        return validator(control);
      }
    }

    return null;
  }

  private static getControlByName(control: AbstractControl, dependencyName: string): AbstractControl {
    let dependencyControl = null;

    if (control.parent) {
      if (control.parent.get(dependencyName)) {
        dependencyControl = control.parent.get(dependencyName);
      } else {
        dependencyControl = this.getControlByName(control.parent, dependencyName);
      }
    }

    return dependencyControl;
  }
}
