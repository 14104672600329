import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FirmModule } from './firm/firm.module';
import { AccountingModule } from './accounting/accounting.module';
import { CoreModule } from './core/core.module';
import { CentralModule } from './central/central.module';
import { AppRoutingModule } from './/app-routing.module';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { BacoModule } from './baco/baco.module';

@NgModule({
  declarations: [AppComponent, StyleGuideComponent],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    FirmModule,
    AccountingModule,
    ConfigurationModule,
    CentralModule,
    BacoModule,
    NoopAnimationsModule,
    SharedModule,
  ],
  providers: [Title],
  bootstrap: [AppComponent],
})
export class AppModule {}
