import { Database } from '../databases/database';
import { StorageAccount } from '../storage-accounts/storage-account';

export class Firm {
  id: number;
  tenantId: string;
  name: string;
  crmId: string;
  database: Database;
  storageAccount: StorageAccount;
  workpapersFirmId: string;

  constructor(data) {
    Object.assign(this, data);
  }
}

export class FirmModel {
  id: number;
  tenantId: string;
  name: string;
  crmId: string;
  databaseId: number;
  storageAccountId: number;
  workpapersFirmId: string;

  constructor(data: Firm) {
    Object.assign(this, data);
    this.databaseId = data.database.id;
    this.storageAccountId = data.storageAccount.id;
  }
}

export class FirmCreateModel extends FirmModel {
  email: string;
  firstName: string;
  lastName: string;

  constructor(data: any) {
    super(data);
  }
}
