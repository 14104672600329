<div class="modal-header">
  <h4 class="modal-title">Entity</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form novalidate [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <h2 class="form-group-title">Entity Details</h2>

    <hr />

    <div class="form-group">
      <label class="control-label">Client Group</label>
      <ng-select
        bindLabel="name"
        bindValue="id"
        formControlName="clientId"
        [clearable]="false"
        [items]="availableClients$ | async"
      />
    </div>
    <hr />

    <div class="form-group">
      <label class="control-label">Code</label>
      <input class="form-control" formControlName="code" type="text" />
    </div>

    <div class="form-group" *ngIf="isClientRefPresent">
      <label class="control-label">Client Reference</label>
      <input
        class="form-control"
        formControlName="clientRef"
        type="text"
        [readOnly]="true"
      />
    </div>

    <div class="form-group required">
      <label class="control-label">Client Name (legal name)</label>
      <input class="form-control" formControlName="legalName" type="text" />
    </div>

    <div class="form-group">
      <label class="control-label">Trading Name</label>
      <input class="form-control" formControlName="tradingName" type="text" />
    </div>

    <div class="form-group required">
      <label class="control-label">Entity Type</label>
      <ng-select
        bindLabel="name"
        bindValue="id"
        formControlName="entityTypeId"
        [clearable]="false"
        [items]="entityTypes"
      />
    </div>

    <div
      class="form-group required"
      *ngIf="form.get('entityTypeId').value === entityType.Association"
    >
      <label class="control-label">Association State</label>
      <ng-select
        bindLabel="name"
        bindValue="value"
        formControlName="associationStateId"
        [items]="associationStates | enumToArray"
      ></ng-select>
    </div>

    <div class="form-group">
      <label class="control-label">ABN</label>
      <input
        class="form-control"
        formControlName="abn"
        type="text"
        [crsBusinessNumber]="'abn'"
        [value]="form.get('abn').value | businessNumber : 'abn'"
      />
    </div>

    <div class="form-group">
      <label class="control-label">ABN Division</label>
      <input class="form-control" formControlName="abnDivision" type="text" />
    </div>

    <div
      class="form-group"
      *ngIf="
        form.get('entityTypeId').value === entityType.Company ||
        form.get('entityTypeId').value === entityType.Association ||
        form.get('entityTypeId').value === entityType.Unspecified
      "
    >
      <label class="control-label">ACN</label>
      <input
        class="form-control"
        formControlName="acn"
        type="text"
        [crsBusinessNumber]="'acn'"
        [value]="form.get('acn').value | businessNumber : 'acn'"
      />
    </div>

    <div class="form-group">
      <label class="control-label">Description</label>
      <textarea
        class="form-control"
        formControlName="description"
        rows="3"
      ></textarea>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          formControlName="notForProfit"
          type="checkbox"
        />
        <label class="form-check-label">Not For Profit</label>
      </div>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          formControlName="isRegisteredForGst"
          type="checkbox"
        />
        <label class="form-check-label">Registered for GST</label>
      </div>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          formControlName="reportingEntity"
          type="checkbox"
        />
        <label class="form-check-label">Reporting Entity</label>
      </div>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          formControlName="isConsolidatedGroup"
          type="checkbox"
        />
        <label class="form-check-label">Consolidated Group</label>
      </div>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          formControlName="isLivestockEnabled"
          type="checkbox"
        />
        <label class="form-check-label">Livestock entity</label>
      </div>
    </div>

    <div class="spacer-md"></div>

    <div class="card">
      <h2
        aria-controls="collapseReportOptions"
        class="card-header card-header-collapsible form-group-title"
        data-target="#collapseReportOptions"
        data-toggle="collapse"
        [attr.aria-expanded]="reportOptionsCollapsed"
        (click)="reportOptionsCollapsed = !reportOptionsCollapsed"
      >
        Report Options
        <i
          aria-hidden="true"
          class="fas fa-caret-down"
          *ngIf="reportOptionsCollapsed"
        ></i>
        <i
          aria-hidden="true"
          class="fas fa-caret-up"
          *ngIf="!reportOptionsCollapsed"
        ></i>
      </h2>
      <div
        aria-labelledby="collapseReportOptions"
        class="collapse show"
        id="collapseReportOptions"
      >
        <div class="card-body">
          <crs-address-form
            title="Registered Office"
            twoAddressLines="true"
            [formGroup]="form.controls['registeredOffice']"
          />

          <crs-address-form
            title="Principal Place of Business"
            twoAddressLines="true"
            [copyFrom]="form.controls['registeredOffice'].value"
            [formGroup]="form.controls['principalPlaceOfBusiness']"
          />

          <div class="form-group">
            <label class="control-label">Principal Activities</label>
            <textarea
              class="form-control"
              formControlName="principalActivities"
              rows="3"
            ></textarea>
          </div>

          <crs-trustee-form
            *ngIf="
              form.get('entityTypeId').value === entityType.UnitTrust ||
              form.get('entityTypeId').value === entityType.DiscretionaryTrust
            "
            [formGroup]="form.controls['trusteeDetail']"
          />

          <div class="form-group">
            <label class="control-label">Responsible Persons</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="responsible"
              [items]="pluralities | enumToArray"
            />
          </div>

          <div class="jumbotron p-3 mt-3 mb-3">
            <h3>Responsible Person Details</h3>

            <div class="btn-toolbar" role="toolbar">
              <div class="btn-menu">
                <button
                  class="btn btn-secondary"
                  type="button"
                  (click)="onClickAddResponsiblePerson()"
                >
                  <i aria-hidden="true" class="fas fa-plus inset"></i>
                  Add Responsible Person
                </button>
              </div>
            </div>
            <div>
              <ag-grid-angular
                #agGrid
                class="ag-theme-material"
                style="width: 100%"
                [class.d-none]="!responsiblePersons?.length"
                [enableRangeSelection]="true"
                [gridOptions]="gridOptions"
                [rowData]="responsiblePersons"
                [rowDragManaged]="true"
                [suppressCellSelection]="false"
                [suppressHorizontalScroll]="true"
              >
                <ag-grid-column
                  ag-grid-column
                  colId="drag"
                  headerName=""
                  [maxWidth]="30"
                  [minWidth]="30"
                  [rowDrag]="true"
                />
                <ag-grid-column
                  field="title"
                  headerName="Title"
                  [editable]="true"
                  [maxWidth]="75"
                />
                <ag-grid-column
                  field="firstName"
                  headerName="First Name"
                  [editable]="true"
                  [flex]="3"
                />
                <ag-grid-column
                  field="lastName"
                  headerName="Last Name"
                  [editable]="true"
                  [flex]="3"
                />
                <ag-grid-column
                  field="email"
                  headerName="Email"
                  [editable]="true"
                  [flex]="3"
                />
                <ag-grid-column
                  field="isSigning"
                  headerName="Signing"
                  type="booleanColumn"
                  [cellRendererParams]="{ isToggleable: true }"
                  [maxWidth]="120"
                />
                <!-- <ag-grid-column headerName="Inactive" field="inactive" [maxWidth]="120" [editable]="true" type="booleanColumn" /> -->
                <ag-grid-column
                  field="appointedDate"
                  headerName="Appointed"
                  type="dateColumn"
                  [editable]="true"
                  [maxWidth]="135"
                />
                <ag-grid-column
                  field="resignedDate"
                  headerName="Resigned"
                  type="dateColumn"
                  [editable]="true"
                  [maxWidth]="130"
                />
                <ag-grid-column
                  type="optionsColumn"
                  [cellRendererParams]="{ ngTemplate: optionsCell }"
                />
              </ag-grid-angular>
            </div>

            <ng-template #optionsCell let-row>
              <div class="btn-mini-menu">
                <button
                  class="btn-sm fas-btn-ghost"
                  title="Remove Responsible Person"
                  type="button"
                  (click)="onClickRemoveResponsiblePerson(row)"
                >
                  <i aria-hidden="true" class="fas fa-trash"></i>
                </button>
              </div>
            </ng-template>
          </div>

          <div class="form-group">
            <label class="control-label">Owners</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="owners"
              [items]="pluralities | enumToArray"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <h2
        aria-controls="collapseManagement"
        class="card-header card-header-collapsible form-group-title"
        data-target="#collapseManagement"
        data-toggle="collapse"
        [attr.aria-expanded]="managementCollapsed"
        (click)="managementCollapsed = !managementCollapsed"
      >
        Management
        <i
          aria-hidden="true"
          class="fas fa-caret-down"
          *ngIf="managementCollapsed"
        ></i>
        <i
          aria-hidden="true"
          class="fas fa-caret-up"
          *ngIf="!managementCollapsed"
        ></i>
      </h2>
      <div
        aria-labelledby="collapseManagement"
        class="collapse show"
        id="collapseManagement"
      >
        <div class="card-body">
          <div class="form-group required">
            <label class="control-label">Practice</label>
            <crs-office-select
              formControlName="office"
              [clearable]="false"
              [readonly]="true"
            />
          </div>

          <div class="form-group">
            <label class="control-label">Team</label>
            <crs-team-select formControlName="team" />
          </div>

          <div class="form-group required">
            <label class="control-label">Partner</label>
            <crs-user-select
              formControlName="partner"
              [clearable]="false"
              [filterByPartner]="true"
            />
          </div>

          <div class="form-group">
            <label class="control-label">Manager</label>
            <crs-user-select
              formControlName="manager"
              [filterByManager]="true"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="isAdd">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          [formControl]="createFileControl"
        />
        <label class="form-check-label">
          Create a new file for this entity
        </label>
      </div>
    </div>

    <crs-form-error [error]="error" />
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-delete"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      [promiseBtn]="busy.delete"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      (click)="activeModal.close('Close click')"
    >
      Cancel
    </button>
  </div>
</form>
