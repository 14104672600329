import { Component, Inject } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { DatePickerComponent } from '../../../components';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { LuxonDateAdapter } from '../../../components/date-picker/luxon-date-adapter';
import { DateService } from '../../../../core';

@Component({
  templateUrl: '../../../components/date-picker/date-picker.component.html',
  styleUrls: ['../../../components/date-picker/date-picker.component.scss']
})
export class DateEditorComponent extends DatePickerComponent implements ICellEditorAngularComp {

  public params: any;
  private format = 'yyyy-MM-dd';

  constructor(@Inject(DateAdapter) protected _dateAdapter: LuxonDateAdapter,
              @Inject(MAT_DATE_FORMATS) protected _formats: MatDateFormats,
              private readonly _dateService: DateService) {
    super(_dateAdapter, _formats);
  }

  public isPopup(): boolean {
    return true;
  }

  public agInit(params: any): void {
    this.params = params;
    if (params['format']) {
      this.format = params['format'];
    }

    this.value = params.value;
  }

  public getValue() {
    if (!this.value) {
      return null;
    }

    return this._dateService.toString(this.value, this.format);
  }

  public onDateChanged() {
    this.params.api.stopEditing(false);
  }
}
