import { Component, Inject, Input } from '@angular/core';
import { ReportTable, ReportTableRow } from '../';
import { ReportMetaData } from '../../../report-output';
import { ParagraphStyle } from '../../report-paragraph/report-paragraph';
import { Alignment } from '../../report-header/report-header';
import { ReportElementTypeEnum, TableElement } from '../../../../enums';
import { ReportTableService } from '../services';
import { IReportTableConfig } from '../interfaces';
import { CellTypeEnum, TableRowType } from '../enums';
import { REPORT_TABLE_CONFIG } from '../report-table.contants';
import { ColumnType } from '../../../../reportTemplates';
import { HeaderStyle } from '../../financial/header-style';
import { LedgerReportFilters } from 'src/app/accounting/ledger/ledger-report/ledger-report.component';
import { GroupByAccountType } from 'src/app/accounting/ledger/ledgerReports/groupByAccountType';
import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import { LedgerReportModalComponent, LedgerReportModalParams } from 'src/app/accounting/ledger/ledger-report-modal/ledger-report-modal.component';
import { ModalService } from 'src/app/core';
import { ReportOutputService } from '../../../report-output.service';
import { ActiveFileService } from 'src/app/accounting/active-file.service';

@Component({
  selector: 'crs-report-table-row',
  templateUrl: './report-table-row.component.html',
  styleUrls: ['./report-table-row.component.scss']
})
export class ReportTableRowComponent {

  @Input() row: ReportTableRow;
  @Input() isEditMode: boolean;

  private _element: ReportTable;
  @Input() set element(value: ReportTable) {
    this._element = value;
  }

  get element(): ReportTable {
    return this._element;
  }

  get isAlternative(): boolean {
    return this.row.style === HeaderStyle.Alternative;
  }

  @Input() reportMetaData: ReportMetaData;
  @Input() index;

  public selectedRowIndex$ = this._reportTableService.selectedRowIndex$;
  public selectedColumnIndex$ = this._reportTableService.selectedColumnIndex$;
  public selectedElementType$ = this._reportTableService.selectedElementType$;

  public tableElements = TableElement;
  public tableRowTypes = TableRowType;
  public styles = ParagraphStyle;
  public alignments = Alignment;
  public cellTypes = CellTypeEnum;
  public columnTypes = ColumnType;

  public isSectionTable() {
    return this.element.elementType === ReportElementTypeEnum.SectionTable;
  }

  constructor(private readonly _reportTableService: ReportTableService,
              private readonly _modalService: ModalService,
              private readonly _reportOutputService: ReportOutputService,
              private readonly _activeFileService: ActiveFileService,
              @Inject(REPORT_TABLE_CONFIG) public readonly config: IReportTableConfig) {
  }

  public onSelectedTable(): void {
    this._reportTableService.setCurrentTableInfo();
  }

  public onSelectedColumn(cellIndex: number): void {
    this._reportTableService.setCurrentColumnInfo(cellIndex);
  }

  public onSelectedRow(rowIndex: number): void {
    this._reportTableService.setCurrentRowInfo(rowIndex);
  }

  get isHeaderRow(): boolean {
    let thisRow: ReportTableRow = new ReportTableRow(this.row);
    return thisRow.isHeaderRow;
  }

  get isTotalRow(): boolean {
    let thisRow: ReportTableRow = new ReportTableRow(this.row);
    return thisRow.isTotalRow;
  }

  hasDrillDown(cellIndex) {
    if(!this.element.showLedgerModal) return false;
    
    let metadata = this._element.columns[cellIndex];
    if(!metadata?.datasetId) return false;

    let currentCell = this.row.cells[cellIndex];
    if(!currentCell) return false;
    return currentCell.elementIdType === 3 || currentCell.elementIdType === 4;
  }

  drillDown(cellIndex) {

    if(!this.hasDrillDown(cellIndex))
       return;

    const filters = new LedgerReportFilters();
    filters.groupByAccountType = GroupByAccountType.Account;

    let currentCell = this.row.cells[cellIndex];
    let selectedColumn = this._element.columns[cellIndex];

    filters.startDate = DateHelpers.dateFromApiResponse(selectedColumn.startDate);
    filters.endDate = DateHelpers.dateFromApiResponse(selectedColumn.endDate);
    const params = new LedgerReportModalParams(selectedColumn.datasetId, filters);

    this._reportOutputService.getIncludedAccounts(this._activeFileService.file.id, currentCell.elementId, currentCell.elementIdType)
      .subscribe(a => {
        params.filters.accounts = a;
        this._modalService.openModal(LedgerReportModalComponent, null, params);
      });
  }
}
