import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClientEntitySearchResult } from '../clientEntitySearchResult';

@Component({
  selector: 'crs-client-search-result',
  templateUrl: './client-search-result.component.html'
})
export class ClientSearchResultComponent {

  @Input() client: ClientEntitySearchResult;
  @Input() clearable = false;
  @Output() selected = new EventEmitter<ClientEntitySearchResult>();
  @Output() clear = new EventEmitter<boolean>();

  constructor() { }


}
