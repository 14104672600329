import { Classification } from './classification';

export const classifications: Classification[] = [
  Classification.Assets,
  Classification.Liabilities,
  Classification.Equity,
  Classification.Revenue,
  Classification['Cost of Sales'],
  Classification.Expenses,
  Classification.Other
];


