<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.load"></crs-busy>
    <crs-form-error [error]="error"></crs-form-error>
    <div class="btn-menu pad-no-bottom">
      <button class="btn btn-secondary" type="button" (click)="addPolicy()">
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Policy
      </button>
    </div>
    <div>
      <crs-progress-bar id="gridLoadingBar"></crs-progress-bar>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="policies"
        (rowClicked)="selectPolicy($event)"
      >
        <ag-grid-column
          field="location"
          headerName="Location"
          type="enumColumn"
          [cellRendererParams]="{ enum: policyLocations }"
          [hide]="true"
          [maxWidth]="160"
          [rowGroup]="true"
        ></ag-grid-column>

        <ag-grid-column
          cellRenderer="badgeRenderer"
          field="name"
          headerName="Name"
          [cellRendererParams]="{ badgeText: 'New', monthsToCheck: 3 }"
          [rowDrag]="rowDrag"
        ></ag-grid-column>

        <ag-grid-column
          field="level"
          headerName="Master"
          type="booleanColumn"
          [cellRendererParams]="{ iconClass: 'fas fa-lock' }"
          [maxWidth]="100"
          [minWidth]="100"
          [valueGetter]="isMaster"
        ></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="spacer-lg"></div>
