<form class="pad-x" [formGroup]="form" (validSubmit)="submit()">
  <h2 class="form-group-title d-inline-block">
    {{ isAdd ? 'New' : '' }} Image {{ isVariant ? 'Variant' : '' }}
  </h2>

  <hr />

  <crs-form-error [error]="error"></crs-form-error>

  <div>
    <img
      alt="Image"
      class="uploaded-image"
      *ngIf="uploadedImageFile"
      [flowSrc]="uploadedImageFile?.transfer"
      [style.height]="height"
      [style.width]="width"
    />
    <img
      alt="Image"
      class="uploaded-image"
      *ngIf="!uploadedImageFile && image.publicUrl"
      [src]="image.imageData"
      [style.height]="height"
      [style.width]="width"
    />
    <div
      class="d-inline-block image-placeholder"
      *ngIf="!uploadedImageFile && !image.publicUrl"
    >
      <span class="d-inline-block align-middle"> No image chosen </span>
    </div>

    <div class="mt-1">
      <crs-file-upload
        folder="1"
        [acceptedFiles]="['.jpg', '.jpeg', '.png']"
        (flowFilesUploaded)="onImageUpload($event)"
      ></crs-file-upload>
      <div class="spacer-md"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-form-md">
      <div class="form-group required" *ngIf="isVariant">
        <label class="control-label">Practice</label>
        <crs-office-select
          formControlName="office"
          [clearable]="false"
          [readonly]="!isAdd"
        ></crs-office-select>
      </div>

      <div class="form-group required">
        <label class="control-label">Name</label>
        <input
          class="form-control"
          formControlName="name"
          placeholder="eg 'Company Logo'"
          type="text"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-form-sm">
      <div class="form-group">
        <label class="control-label">Default Width (px)</label>
        <input
          class="form-control"
          formControlName="defaultWidth"
          min="1"
          step="1"
          type="number"
        />
        <small class="form-text text-muted">
          Default width in pixels. If not supplied, the image width will be used
          (subject to height)
        </small>
      </div>

      <div class="form-group">
        <label class="control-label">Default Height (px)</label>
        <input
          class="form-control"
          formControlName="defaultHeight"
          min="1"
          step="1"
          type="number"
        />
        <small class="form-text text-muted">
          Default height in pixels. If not supplied, the image height will be
          used (subject to width)
        </small>
      </div>
    </div>
  </div>

  <ng-content></ng-content>

  <div class="spacer-md"></div>

  <div class="btn-menu">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!form.valid"
      [promiseBtn]="busy.submit"
      (click)="submit()"
    >
      Save and Close
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>

    <button
      class="btn btn-outline-danger float-right"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
  </div>
</form>
