<div class="row container-fluid pad-x">
  <div class="col-12">
    <div class="headers-and-accounts-toolbar">
      <div class="btn-toolbar d-flex" role="toolbar">
        <div class="header-entity-select mb-2" *ngIf="useHeaders">
          <ng-select
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="selectedEntityType"
            [clearable]="false"
            [items]="entityTypes"
            [searchable]="false"
            [virtualScroll]="true"
          >
            <ng-template let-index="index" let-item="item" ng-option-tmp>
              <crs-truncated-name [length]="20" [name]="item.name" />
            </ng-template>
          </ng-select>
        </div>

        <div class="vertical-line-toolbar mx-3" *ngIf="useHeaders"></div>

        <div class="icon-input-wrapper flex-grow-1 me-2">
          <i aria-hidden="true" class="fas fa-search input-icon"></i>
          <input
            aria-describedby="searchIcon"
            placeholder="Search..."
            type="search"
            [(ngModel)]="search"
          />
        </div>

        <div class="header-class-select ms-2">
          <ng-select
            bindLabel="name"
            bindValue="value"
            placeholder="Class"
            [(ngModel)]="classificationId"
            [items]="classifications | enumToArray: true"
            [virtualScroll]="true"
          >
          </ng-select>
        </div>

        <div class="header-account-type-select ms-2">
          <ng-select
            bindLabel="name"
            bindValue="id"
            placeholder="Account type"
            [(ngModel)]="accountTypeId"
            [items]="accountTypes"
            [virtualScroll]="true"
          >
            <ng-template let-index="index" let-item="item" ng-option-tmp>
              <crs-truncated-name [length]="18" [name]="item.name" />
            </ng-template>
          </ng-select>
        </div>

        <div class="header-account-header-name-select ms-2" *ngIf="!useHeaders">
          <ng-select
            bindLabel="accountName"
            bindValue="id"
            groupBy="classificationLabel"
            placeholder="Header"
            [(ngModel)]="selectedHeaderAccountId"
            [items]="headerAccounts"
            [virtualScroll]="true"
          >
            <ng-template let-headerAccount="item" ng-option-tmp>
              <crs-truncated-name
                [length]="20"
                [name]="headerAccount.accountName"
              />
            </ng-template>
          </ng-select>
        </div>

        <div class="header-account-entity-type-select ms-2" *ngIf="!useHeaders">
          <ng-select
            bindLabel="name"
            bindValue="id"
            placeholder="Entity type"
            [(ngModel)]="selectedAccountEntityType"
            [items]="entityTypes"
            [virtualScroll]="true"
          >
            <ng-template let-accountEntityType="item" ng-option-tmp>
              <crs-truncated-name
                [length]="18"
                [name]="accountEntityType.name"
              />
            </ng-template>
          </ng-select>
        </div>

        <div class="ms-2">
          <button
            aria-label="Clear filters"
            class="btn"
            type="button"
            [disabled]="
              useHeaders
                ? !accountTypeId && !classificationId && !search
                : !accountTypeId &&
                  !classificationId &&
                  !search &&
                  !selectedHeaderAccountId &&
                  !selectedAccountEntityType
            "
            (click)="onClearFilters()"
          >
            Clear filters
          </button>
        </div>

        <div class="vertical-line-toolbar mx-3"></div>

        <div
          class="mdc-segmented-button mdc-segmented-button--single-select"
          role="radiogroup"
        >
          <button
            aria-checked="false"
            class="mdc-segmented-button__segment"
            role="radio"
            style="text-transform: none"
            [ngClass]="{
              'mdc-segmented-button__segment--selected': useHeaders,
            }"
            [routerLink]="['../headers']"
          >
            <div class="mdc-segmented-button__label">Headers</div>
          </button>
          <button
            aria-checked="false"
            class="mdc-segmented-button__segment"
            role="radio"
            style="text-transform: none"
            [ngClass]="{
              'mdc-segmented-button__segment--selected': !useHeaders,
            }"
            [routerLink]="['../accounts']"
          >
            <div class="mdc-segmented-button__label">Accounts</div>
          </button>
        </div>

        <div class="ms-2">
          <button
            class="btn btn-primary"
            type="submit"
            *ngIf="useHeaders"
            (click)="onClickAddHeader()"
          >
            <i aria-hidden="true" class="fas fa-plus"></i>
            Add Header
          </button>
          <button
            class="btn btn-primary"
            type="submit"
            *ngIf="!useHeaders"
            [disabled]="!(isActivated$ | async)"
            (click)="onClickAddAccount()"
          >
            <i aria-hidden="true" class="fas fa-plus"></i>
            Add Account
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<div class="row pad-x m-auto">
  <div class="col-12">
    <!-- Headers tab detail -->
    <crs-standard-account-headers
      *ngIf="useHeaders"
      [accountTypeId]="accountTypeId"
      [classificationId]="classificationId"
      [isAccountsActivated]="isActivated$ | async"
      [search]="search"
      [selectedEntityType]="selectedEntityType"
    />

    <!-- Accounts tab detail -->
    <crs-busy *ngIf="!useHeaders && isFetchingActivated$ | async" />

    <crs-admin-accounts
      *ngIf="!useHeaders && (isActivated$ | async) === false"
    />
    <crs-standard-accounts-table
      *ngIf="!useHeaders && (isActivated$ | async)"
      [accountTypeId]="accountTypeId"
      [classificationId]="classificationId"
      [search]="search"
      [selectedAccountEntityType]="selectedAccountEntityType"
      [selectedHeaderAccountId]="selectedHeaderAccountId"
      [shouldRefreshAccounts]="shouldRefreshAccounts$ | async"
      (fetchHeaderAccounts)="onFetchHeaderAccounts($event)"
    />
  </div>
</div>
