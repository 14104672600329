import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  EMPTY,
  Observable,
  Subject,
  Subscription,
  forkJoin,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  BankAccountClient,
  BankConnectionClient,
  FeedClient,
} from 'src/app/baco/common';
import {
  BacoAccountTransferDto,
  BacoBankAccountDto,
  BacoBankConnectionDto,
  BacoInvitationModel,
  Feed,
} from 'src/app/baco/interfaces';
import {
  EnhancedConfirmation,
  MessageService,
  ModalService,
  Permissions,
  SessionService,
} from 'src/app/core';
import { User } from 'src/app/firm';
import { Patterns } from 'src/app/shared/validators';
import { BacoFeedStore } from '../../baco-feed.store';
import {
  BankConnectionType,
  CountryType,
  FormFlowSteps,
} from './add-new-bank-connection-wizard.enum';
import { GridOptions } from 'ag-grid-community';
import { getDefaultGridOptions } from 'src/app/shared';
import { Title } from '@angular/platform-browser';
import { BacoTransactionStore } from '../../baco-transaction.store';

interface BacoBankAccountExDto extends BacoBankAccountDto {
  feedName: string;
  isSelected: boolean;
}

@Component({
  selector: 'crs-add-new-bank-connection-wizard',
  templateUrl: './add-new-bank-connection-wizard.component.html',
  styleUrls: ['./add-new-bank-connection-wizard.component.scss'],
})
export class AddNewBankConnectionWizardComponent implements OnInit, OnDestroy {
  modalTitle = 'Add bank connection';
  stepTitle = '';

  @Input() params: any;

  formFlowSteps = FormFlowSteps;
  CountryType = CountryType;
  BankConnectionTypeList = BankConnectionType;
  submitCheckEmailStream$ = new Subject<void>();
  submitButtonStream$ = new Subject<void>();
  subscriptions: Subscription[] = [];
  busy = {
    loading: false,
    submit: null,
    delete: null,
  };
  error: string = null;
  totalSteps = FormFlowSteps.CompleteSetup + 1;
  userBankAccounts: BacoBankAccountDto[] = [];
  hasUserBankAccounts: boolean;
  redirectUri: string = '';
  existingBankAccounts: BacoBankAccountExDto[] = [];
  private selectedExistingBankAccounts(): BacoBankAccountExDto[] {
    return this.existingBankAccounts.filter(
      (existingBankAccount) => existingBankAccount.isSelected
    );
  }
  private someSelected(): boolean {
    return this.existingBankAccounts.some(
      (existingBankAccount) => existingBankAccount.isSelected
    );
  }

  gridOptions: GridOptions;

  newAddedBankConnection: BacoBankConnectionDto;
  permissions: Permissions = this._sessionService.permissions;

  @ViewChild('stepper')
  private stepper: MatStepper;

  public countryAndImportTypeForm = new FormGroup({
    country: new FormControl<CountryType>(CountryType.Australia, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    connectionType: new FormControl<BankConnectionType>(
      this._sessionService.permissions.canSendClientRequest
        ? BankConnectionType.BankConnection
        : BankConnectionType.ManualImport,
      { nonNullable: true, validators: [Validators.required] }
    ),
  });

  public emailForm = new FormGroup({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern(Patterns.emailRegExp),
      ],
    }),
  });

  public bankConnectionForm = new FormGroup(
    {
      createNewBankConnection: new FormControl<boolean>(false, {}),
      clientName: new FormControl<string>('', {
        nonNullable: true,
        validators: [this.requiredIfCreatingNewConnection()],
      }),
      commencementLocalDate: new FormControl<Date>(
        new Date(new Date().setHours(0, 0, 0, 0)),
        {
          nonNullable: true,
          validators: [this.requiredIfCreatingNewConnection()],
        }
      ),
      user: new FormControl<User>(
        this.permissions.trustedAdvisor ? this._sessionService.user : null,
        { validators: [this.requiredIfCreatingNewConnection()] }
      ),
    },
    { validators: [this.requiredIfNoExistingAccountsSelected()] }
  );

  private requiredIfNoExistingAccountsSelected(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        this.bankConnectionForm?.controls?.createNewBankConnection?.value ===
        false
      ) {
        return !this.someSelected() ? { required: true } : null;
      }

      return null;
    };
  }

  private requiredIfCreatingNewConnection(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.bankConnectionForm?.controls?.createNewBankConnection?.value) {
        if (!control?.value) {
          return { required: true };
        }
      }

      return null;
    };
  }

  private isMobileRequired(): boolean {
    return (
      this.countryAndImportTypeForm?.controls?.country?.value ===
        CountryType.Australia &&
      this.bankConnectionForm?.controls?.createNewBankConnection?.value === true
    );
  }

  private requiredIfCountryIsAustralia(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.isMobileRequired()) {
        if (!control?.value) {
          return { required: true };
        }

        const invalid = !Patterns.phoneNumberRegExp.test(control?.value);
        return invalid ? { invalidPhoneNumber: true } : null;
      }
      return null;
    };
  }

  public selectExistingBankAccountForm = new FormGroup({
    bankAccountName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  public manualInputForm = new FormGroup({
    accountName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  get selectedCountry(): CountryType {
    return this.countryAndImportTypeForm.value.country!;
  }
  get selectedBankConnectionType(): BankConnectionType {
    return this.countryAndImportTypeForm.value.connectionType!;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private _bankConnectionService: BankConnectionClient,
    private _feedClient: FeedClient,
    private _feedStore: BacoFeedStore,
    private readonly _transactionStore: BacoTransactionStore,
    private readonly _bankAccountClient: BankAccountClient,
    private _messageService: MessageService,
    private _modalService: ModalService,
    private _sessionService: SessionService
  ) {}

  ngOnInit() {
    this.connectionTypeSubscription();
    this.configureGridOptions();
    this.configureCheckEmailSubmit();
    this.configureSubmit();
    this.cdr.detectChanges();
    this.updateTitle();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      columnDefs: [
        { field: 'accountName', headerName: 'Bank account' },
        { field: 'accountNumber', headerName: 'Account number' },
        { field: 'feedName', headerName: 'Feed collection name' },
        {
          field: 'isSelected',
          headerName: 'Select',
          type: 'booleanColumn',
          cellClass: 'cell-checkbox',
          cellRendererParams: { isToggleable: true },
          maxWidth: 120,
          minWidth: 120,
        },
      ],
    };
  }

  onClickClose() {
    this.activeModal.dismiss();
  }

  public onCopiedToClipboard() {
    this._messageService.success('URL copied to your clipboard.');
  }

  onClickGrid() {
    this.bankConnectionForm.updateValueAndValidity();
  }

  onClickNewBankConnection() {
    const value =
      this.bankConnectionForm.value.createNewBankConnection === true;
    this.bankConnectionForm.controls.createNewBankConnection.setValue(!value);
  }

  onClickNextStep(): void {
    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
    this.updateTitle();

    if (this.stepper.selectedIndex === FormFlowSteps.CompleteSetup) {
      this.getExistingBankAccounts();
    }
  }

  onClickPreviousStep(): void {
    this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
    this.updateTitle();
  }

  getCurrentStep(): number {
    return this.stepper?.selectedIndex;
  }

  handleConfirmationModalOpen(warningText?: string): void {
    const countSelected = this.selectedExistingBankAccounts().length;
    const selectedText =
      countSelected > 0
        ? `Transfer ${countSelected} account${
            countSelected === 1 ? '' : 's'
          } to the feed "${this.params.feedName}"?<br /><br />`
        : ``;
    const createNewBankConnection =
      this.bankConnectionForm.value.createNewBankConnection === true;
    const createNewBankConnectionText = createNewBankConnection
      ? `Add new bank connection to the feed "${this.params.feedName}"?`
      : ``;
    const infoText = `${selectedText}${createNewBankConnectionText}`;

    this._modalService.confirmationEx(
      new EnhancedConfirmation({
        title: 'Transfer accounts and add connections',
        alertText: warningText,
        alertClass: 'alert-warning',
        additionalInfoText: infoText,
        approveAction: () => this.submitButtonStream$.next(),
        cancelAction: () => false,
        danger: false,
        shouldFocusOnSubmitButton: true,
        approveBtn: 'Add accounts',
        cancelButton: 'Cancel',
      })
    );
  }

  onValidBankConnectionFormSubmit(): void {
    const selectedBankAccounts = this.selectedExistingBankAccounts();

    if (!selectedBankAccounts.length) {
      this.handleConfirmationModalOpen();
      return;
    }

    const transactionCountObservables = selectedBankAccounts.map(
      (bankAccount) =>
        this._bankAccountClient.getTransactionsCount(bankAccount.id).pipe(
          map(({ codedTransactionsCount }) => ({
            bankAccount,
            codedTransactionsCount,
          }))
        )
    );

    forkJoin(transactionCountObservables).subscribe((results) => {
      const warningTexts: string[] = results.map(
        ({ bankAccount, codedTransactionsCount }) => {
          if (bankAccount.bacoFeedId === this.params.feedId) {
            return '';
          }
          return codedTransactionsCount > 0
            ? `Transferring the bank account <strong>"${bankAccount.accountName}"</strong> to a new feed will remove the existing coding on ${codedTransactionsCount} transactions.<br>`
            : '';
        }
      );

      const combinedWarnings = warningTexts.join(' ').trim();

      const combinedWarningText = !!combinedWarnings.length
        ? `<strong>Are you sure you want to proceed?</strong><br>${combinedWarnings}`
        : '';

      this.handleConfirmationModalOpen(combinedWarningText);
    });
  }

  onValidManualImportFormSubmit(): void {
    this._modalService.confirmation(
      `Add new bank connection to the feed "${this.params.feedName}"?`,
      () => this.submitButtonStream$.next(),
      false,
      null,
      'Add connection'
    );
  }

  onCheckEmailValidSubmit(): void {
    this.submitCheckEmailStream$.next();
  }

  configureCheckEmailSubmit(): void {
    const checkEmailSubscription = this.submitCheckEmailStream$
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.getUserBankAccounts$())
      )
      .subscribe((userBankAccounts) => {
        this.userBankAccounts = userBankAccounts;
        this.hasUserBankAccounts = userBankAccounts?.length > 0 || false;

        this.onClickNextStep();
      });

    this.subscriptions.push(checkEmailSubscription);
  }

  getUserBankAccounts$(): Observable<BacoBankAccountDto[]> {
    const loading$ = new Subject<void>();
    this.busy.submit = loading$.subscribe();

    const email = this.emailForm.value.email;

    return this._feedClient.getUserBankAccounts(email).pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loading$.complete())
    );
  }

  configureSubmit() {
    const submitConnectionSubscription = this.submitButtonStream$
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submit$())
      )
      .subscribe((result) => {
        if (
          this.selectedBankConnectionType === BankConnectionType.BankConnection
        ) {
          this.redirectUri = result.redirectUri;
          this.newAddedBankConnection = result.bacoBankConnectionDto;
          if (this.bankConnectionForm.value.createNewBankConnection === true) {
            this._messageService.success('Bank connection added successfully.');
            this.stepper.selectedIndex = FormFlowSteps.RequestSent;
            this.updateTitle();
          } else {
            this.activeModal.close(true);
          }
        } else {
          this.newAddedBankConnection = result;
          this.activeModal.close(this.newAddedBankConnection);
          this._messageService.success(
            'Manual import bank connection added successfully.'
          );
        }
      });

    this.subscriptions.push(submitConnectionSubscription);
  }

  submit$(): Observable<any> {
    const loading$ = new Subject<void>();
    this.busy.submit = loading$.subscribe();

    let observable$: Observable<string | BacoBankConnectionDto | string[]> =
      of('');

    if (this.selectedBankConnectionType === BankConnectionType.BankConnection) {
      const bankConnectionFormValue = this.bankConnectionForm.value;
      const commencementLocalDate = new Date(
        new Date(bankConnectionFormValue.commencementLocalDate).setHours(
          0,
          0,
          0,
          0
        )
      );

      const invitationModel: BacoInvitationModel = {
        clientName: bankConnectionFormValue.clientName,
        commencementLocalDate,
        email: this.emailForm.value.email,
        country: this.selectedCountry,
        trustedAdvisorId: bankConnectionFormValue.user?.id,
      };

      if (this.someSelected()) {
        this._feedClient
          .transferBankAccounts(
            this.selectedExistingBankAccounts().map<BacoAccountTransferDto>(
              (bankAccount) => {
                const bacoAccountTransferDto: BacoAccountTransferDto = {
                  bankAccountId: bankAccount.id,
                  bankAccountConnectionId: bankAccount.bacoBankConnectionId,
                  destinationFeedId: this.params.feedId,
                  sourceFeedId: bankAccount.bacoFeedId,
                };
                return bacoAccountTransferDto;
              }
            )
          )
          .subscribe(() => {
            this._messageService.success(
              'Bank accounts transferred successfully'
            );
            this._feedStore.refreshBankConnections();
            this._transactionStore.refreshBankAccounts();
          });
      }

      if (this.bankConnectionForm.value.createNewBankConnection === true) {
        if (this.selectedCountry === CountryType.Australia) {
          observable$ = observable$.pipe(
            switchMap(() =>
              this._bankConnectionService
                .checkAbnExistOfTrustedAdvisor(bankConnectionFormValue.user?.id)
                .pipe(
                  switchMap((dto) => {
                    if (dto.isAbnExist) {
                      return this._bankConnectionService.sendNewBankConnectionInvitation(
                        this.params.feedId,
                        invitationModel
                      );
                    }
                    return throwError(
                      `Please request Admin to update the ABN number for ${
                        dto.officeName || 'Practice'
                      } in organisation page first`
                    );
                  })
                )
            )
          );
        }

        if (this.selectedCountry === CountryType.NewZealand) {
          observable$ = observable$.pipe(
            switchMap(() =>
              this._bankConnectionService.sendNewBankConnectionInvitation(
                this.params.feedId,
                invitationModel
              )
            )
          );
        }
      }
    }

    if (this.selectedBankConnectionType === BankConnectionType.ManualImport) {
      observable$ = observable$.pipe(
        switchMap(() =>
          this._bankConnectionService.createManualBankConnection(
            this.params.feedId,
            {
              accountName: this.manualInputForm.value.accountName!,
              country: this.selectedCountry,
            }
          )
        )
      );
    }

    return observable$.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => {
        this._feedStore.refreshBankConnections();
        loading$.complete();
      })
    );
  }

  public getCellClass(params) {
    return 'cell-checkbox';
  }

  private showError(error): void {
    this.error = error;
  }

  private connectionTypeSubscription(): void {
    const connectionTypeSubscription =
      this.countryAndImportTypeForm.controls.connectionType.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((connectionType) => {
          this.totalSteps =
            connectionType === BankConnectionType.BankConnection
              ? FormFlowSteps.CompleteSetup + 1
              : FormFlowSteps.EnterEmailOrManualImport + 1;
        });

    this.subscriptions.push(connectionTypeSubscription);
  }

  private updateTitle(): void {
    if (this.stepper.selectedIndex === FormFlowSteps.RequestSent) {
      this.modalTitle = 'Request sent';
      this.stepTitle = '';
      return;
    }

    if (this.stepper.selectedIndex === FormFlowSteps.CountryAndImportType) {
      this.stepTitle = 'Select country and import type - Step 1';
      return;
    }

    if (this.stepper.selectedIndex === FormFlowSteps.EnterEmailOrManualImport) {
      this.stepTitle =
        this.selectedBankConnectionType === BankConnectionType.BankConnection
          ? 'Enter email - Step 2'
          : 'Enter account name - Step 2';
      return;
    }

    const isAustralia =
      this.stepper.selectedIndex === FormFlowSteps.CompleteSetup &&
      this.selectedCountry === CountryType.Australia;

    if (isAustralia) {
      this.modalTitle = this.hasUserBankAccounts
        ? 'Add bank account'
        : 'Add bank connection';
      this.stepTitle = 'Complete setup - Step 3';
    }

    const isNewZealandAndHasAssociatedConnection =
      this.stepper.selectedIndex === FormFlowSteps.CompleteSetup &&
      this.selectedCountry === CountryType.NewZealand &&
      this.hasUserBankAccounts;

    if (isNewZealandAndHasAssociatedConnection) {
      this.modalTitle = 'Add bank account';
      this.stepTitle = 'Select bank accounts - Step 3';
      return;
    }

    const isNewZealandAndNoAssociatedConnection =
      this.stepper.selectedIndex === FormFlowSteps.CompleteSetup &&
      this.selectedCountry === CountryType.NewZealand &&
      !this.hasUserBankAccounts;

    if (isNewZealandAndNoAssociatedConnection) {
      this.modalTitle = 'Add bank connection';
      this.stepTitle = 'Create new bank connection - Step 3';
      return;
    }
  }

  private getExistingBankAccounts() {
    this._feedClient.getFeeds().subscribe((feeds) => {
      const feedMap = new Map<string, Feed>();

      for (let feed of feeds) feedMap.set(feed.id, feed);

      this._feedClient
        .getUserBankAccounts(this.emailForm.controls.email.value)
        .subscribe((bankAccounts) => {
          this.existingBankAccounts = bankAccounts
            .filter(
              (bankAccount) => bankAccount.bacoFeedId !== this.params.feedId
            )
            .map<BacoBankAccountExDto>((bankAccount) => {
              return {
                ...bankAccount,
                feedName: feedMap.get(bankAccount.bacoFeedId).name,
                isSelected: false,
              };
            });

          if (this.existingBankAccounts.length === 0)
            this.bankConnectionForm.controls.createNewBankConnection.setValue(
              true
            );
        });
    });
  }
}
