import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPercent'
})
export class FormatPercentPipe implements PipeTransform {
  constructor(private percentPipe: PercentPipe) { }

  transform(value: any, digits?: number, zeroValue?: string, nullValue?: string) {
    if (value == null)
      return nullValue ?? null;

    const val = Number(value);
    if (isNaN(val))
      return value;

    if (digits == null)
      digits = 2;

    return val === 0 ? zeroValue : this.percentPipe.transform(val, `1.${digits}-${digits}`);
  }
}
