import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { map, delay } from 'rxjs/operators';

import { ApiService } from '../../../../core';
import { Policy } from './policy';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  readonly base = 'api/accounting/reports/notes/policies';

  constructor(private readonly _apiService: ApiService) { }

  getAll(fileId: string = null, reportTemplateId: string = null) {
    var url = this.base;
    url = this._apiService.addQuery(url, 'fileId', fileId);
    url = this._apiService.addQuery(url, 'reportTemplateId', reportTemplateId);
    return this._apiService.get<Policy[]>(url).pipe(map(c => c.map(p => new Policy(p))));
  }

  get(id: string) {
    return this._apiService.get<Policy>(this.base + '/' + id).pipe(map(c => new Policy(c)));
  }

  post(policy: Policy): Observable<string> {
    if (policy.fileId) return this._apiService.post<string>(this.base + '/master/file', policy);
    else return this._apiService.post<string>(this.base + '/master/firm', policy);
  }

  createInherited(master: Policy, fileId: string = null, reportTemplateId: string = null): Observable<string> {
    var model = {
      masterPolicyId: master.id,
      fileId: fileId,
      reportTemplateId: reportTemplateId
    }
    return this._apiService.post<string>(this.base + '/inherit', model);
  }

  reorderFirmPolicy(policyId: string, index: number) {
    var url = this.base + '/' + policyId + '/reorder/firm';
    url = this._apiService.addQuery(url, 'index', index);
    return this._apiService.put(url, null);
  }

  reorderFilePolicy(policyId: string, orderBehindId: string) {
    var url = this.base + '/' + policyId + '/reorder/file';
    url = this._apiService.addQuery(url, 'orderBehindId', orderBehindId);
    return this._apiService.put(url, null);
  }

  put(policy: Policy) {
    return this._apiService.put(this.base + '/' + policy.id, policy);
  }

  delete(id: string) {
    return this._apiService.delete(this.base + '/' + id);
  }
}
