import { ReportStyle } from './../report-styles/models/report-style';
import { ReportMasterPage, ReportMasterPageModel } from './reportMasterPage';
import { ReportingSuite } from '../../notes/reportingSuites';

export class ReportMaster {
  id: number;
  name: string;
  title: string;
  isDefault: boolean;
  reportStyle: ReportStyle;
  header: string;
  defaultFooterId: number;
  defaultReportingSuite: ReportingSuite;
  entityType: number;
  pages: ReportMasterPage[];

  constructor(data) {
    Object.assign(this, data);
    if (data.reportStyle) this.reportStyle = new ReportStyle(data.reportStyle);
    if (data.pages) this.pages = data.pages.map(c => new ReportMasterPage(c));
    if (data.defaultReportingSuite) this.defaultReportingSuite = new ReportingSuite(data.defaultReportingSuite);
  }
}

export class ReportMasterModel {
  id: number;
  name: string;
  title: string;
  isDefault: boolean;
  reportStyleId: string;
  header: string;
  defaultFooterId: number;
  defaultReportingSuiteId: number;
  entityType: number;
  pages: ReportMasterPage[];

  constructor(data) {
    Object.assign(this, data);
    if (data.reportStyle) this.reportStyleId = data.reportStyle.id;
    if (data.pages) this.pages = data.pages.map(c => new ReportMasterPageModel(c));
    if (data.defaultReportingSuite) this.defaultReportingSuiteId = data.defaultReportingSuite.id;
  }
}
