export enum LedgerSide {
    Debit = 1,
    Credit = -1
}

export const ledgerSides = [
  {name: 'Debit', value: 1},
  {name: 'Credit', value: -1}
];


