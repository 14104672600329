import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { AccountingCoreModule } from '../core/accounting-core.module';
import { FinancialModule } from './reportViewer/elements/financial';
import { ReportTableModule } from './reportViewer/elements/report-table';

import { ReportTemplatesComponent } from './reportTemplates/report-templates/report-templates.component';
import { ReportTemplateComponent } from './reportTemplates/report-template/report-template.component';
import { ReportTemplateColumnsComponent } from './reportTemplates/report-template-columns/report-template-columns.component';
import { ReportTemplateColumnComponent } from './reportTemplates/report-template-column/report-template-column.component';
import { ReportTemplatePageComponent } from './reportTemplates/report-template-page/report-template-page.component';
import { ReportTemplatePagesComponent } from './reportTemplates/report-template-pages/report-template-pages.component';
import { ReportViewerComponent } from './reportViewer/report-viewer/report-viewer.component';
import { ReportParagraphComponent } from './reportViewer/elements/report-paragraph/report-paragraph.component';
import { ReportHeaderComponent } from './reportViewer/elements/report-header/report-header.component';
import { ReportSignatureComponent } from './reportViewer/elements/report-signature/report-signature.component';
import { ReportSnippetComponent } from './reportViewer/elements/report-snippet/report-snippet.component';
import { ReportHorizontalRuleComponent } from './reportViewer/elements/report-horizontal-rule/report-horizontal-rule.component';
import { ReportTableOfContentsComponent } from './reportViewer/elements/report-table-of-contents/report-table-of-contents.component';
import { ReportFinancialComponent } from './reportViewer/elements/report-financial/report-financial.component';
import { ReportPageTypesComponent } from './admin/report-page-types/report-page-types.component';
import { ReportMastersComponent } from './admin/report-masters/report-masters/report-masters.component';
import { ReportPageTypeComponent } from './admin/report-page-type/report-page-type.component';
import { ReportMasterComponent } from './admin/report-masters/report-master/report-master.component';
import { ReportMasterPagesComponent } from './admin/report-masters/report-master-pages/report-master-pages.component';
import { ReportMasterPageComponent } from './admin/report-masters/report-master-page/report-master-page.component';
import { SaveAsComponent } from './reportTemplates/save-as/save-as.component';
import { ReportChartComponent } from './reportViewer/elements/report-chart/report-chart.component';
import { ReportContentComponent } from './reportViewer/report-content/report-content.component';
import { ReportingSuitesComponent } from './notes/reportingSuites/reporting-suites/reporting-suites.component';
import { ReportingSuiteComponent } from './notes/reportingSuites/reporting-suite/reporting-suite.component';
import { FirmPoliciesComponent } from './notes/policies/firm-policies/firm-policies.component';
import { PolicyComponent } from './notes/policies/policy/policy.component';
import { NotesComponent } from './admin/notes/notes/notes.component';
import { PolicyVariantComponent } from './notes/policies/policy-variant/policy-variant.component';
import { FilePoliciesComponent } from './notes/policies/file-policies/file-policies.component';
import { FilePolicyComponent } from './notes/policies/file-policy/file-policy.component';
import { FirmPolicyComponent } from './notes/policies/firm-policy/firm-policy.component';
import { ReportingSuiteSelectComponent } from './notes/reportingSuites/reporting-suite-select/reporting-suite-select.component';
import { ReportTemplateNotePageDetailComponent } from './reportTemplates/report-template-page/report-template-note-page-detail/report-template-note-page-detail.component';
import { ReportTemplateFinancialPageDetailComponent } from './reportTemplates/report-template-page/report-template-financial-page-detail/report-template-financial-page-detail.component';
import { ReportSpacerComponent } from './reportViewer/elements/report-spacer/report-spacer.component';
import { ReportMasterFinancialPageDetailComponent } from './admin/report-masters/report-master-page/report-master-financial-page-detail/report-master-financial-page-detail.component';
import { InsertElementLineComponent } from './reportViewer/elements/insert-element-line/insert-element-line.component';
import { ReportPlaceholderComponent } from './reportViewer/elements/report-placeholder/report-placeholder.component';
import { AssetsModule } from '../assets/assets.module';
import { ReportTemplateDepreciationPageDetailComponent } from './reportTemplates/report-template-page/report-template-depreciation-page-detail';
import {
  ReportTemplateRootComponent,
  ReportTemplateMenuComponent,
} from './reportTemplates';
import { ReportDocumentsComponent } from './reportDocuments';
import { ReportStylesModule } from './admin/report-styles/report-styles.module';
import { AddSuiteVariantModalComponent } from './notes/reportingSuites/add-suite-variant-modal/add-suite-variant-modal.component';
import { FileDisclosureTemplatesComponent } from './notes/disclosures/file-disclosure-templates/file-disclosure-templates.component';
import { FirmDisclosureTemplatesComponent } from './notes/disclosures/firm-disclosure-templates/firm-disclosure-templates.component';
import { DisclosureVariantComponent } from './notes/disclosures/disclosure-variant/disclosure-variant.component';
import { FirmDisclosureTemplateComponent } from './notes/disclosures/firm-disclosure-template/firm-disclosure-template.component';
import { FileDisclosureTemplateComponent } from './notes/disclosures/file-disclosure-template/file-disclosure-template.component';
import { DisclosureTemplateComponent } from './notes/disclosures/disclosure-template/disclosure-template.component';
import { ReportContentModalComponent } from './reportViewer/report-content-modal/report-content-modal.component';
import { DisclosureSelectorModalComponent } from './reportTemplates/report-template-page/disclosure-selector-modal/disclosure-selector-modal.component';
import { DisclosureLinkModalComponent } from './notes/disclosures/disclosure-link-modal/disclosure-link-modal.component';
import { ReportWarningComponent } from './reportViewer/report-viewer/report-warning/report-warning.component';
import { ImagesModule } from './admin/images/images.module';
import { ReportImageComponent } from './reportViewer/elements/report-image/report-image.component';
import { ESignatureSendingModalComponent } from './reportTemplates/report-template/esignature-sending-modal/esignature-sending-modal.component';
import { ReportAuditorComponent } from './reportViewer/elements/report-auditor/report-auditor.component';
import { ReportDocumentsSaveComponent } from './reportDocuments/report-documents-save/report-documents-save.component';
import { ReportEditableElementModule } from './reportViewer/elements/report-editable-element/report-editable.element.module';
import { ReportTemplateCashflowPageDetailComponent } from './reportTemplates/report-template-page/report-template-cashflow-page-detail/report-template-cashflow-page-detail.component';
import { BaseReportViewerComponent } from './reportViewer/base-report-viewer/base-report-viewer.component';
import { ReportFooterComponent } from './reportViewer/report-footer/report-footer.component';
import { ReportSidebarComponent } from './reportViewer/report-sidebar/report-sidebar.component';
import { ReportTopNavigationComponent } from './reportViewer/report-top-navigation/report-top-navigation.component';
import { ReportExportModalComponent } from './reportViewer/report-export-modal/report-export-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReportEditableElementModule,
    AccountingCoreModule,
    AssetsModule,
    ReportStylesModule,
    ImagesModule,
    ReportTableModule,
    FinancialModule,
  ],
  exports: [
    BaseReportViewerComponent,
    ReportContentComponent,
    ReportFooterComponent,
    ReportSidebarComponent,
    ReportTopNavigationComponent,
    ReportWarningComponent,
  ],
  declarations: [
    AddSuiteVariantModalComponent,
    BaseReportViewerComponent,
    DisclosureLinkModalComponent,
    DisclosureSelectorModalComponent,
    DisclosureTemplateComponent,
    DisclosureVariantComponent,
    ESignatureSendingModalComponent,
    FileDisclosureTemplateComponent,
    FileDisclosureTemplatesComponent,
    FilePoliciesComponent,
    FilePolicyComponent,
    FirmDisclosureTemplateComponent,
    FirmDisclosureTemplatesComponent,
    FirmPoliciesComponent,
    FirmPolicyComponent,
    InsertElementLineComponent,
    NotesComponent,
    PolicyComponent,
    PolicyVariantComponent,
    ReportAuditorComponent,
    ReportChartComponent,
    ReportContentComponent,
    ReportContentModalComponent,
    ReportDocumentsComponent,
    ReportDocumentsSaveComponent,
    ReportExportModalComponent,
    ReportFinancialComponent,
    ReportFooterComponent,
    ReportHeaderComponent,
    ReportHorizontalRuleComponent,
    ReportImageComponent,
    ReportMasterComponent,
    ReportMasterFinancialPageDetailComponent,
    ReportMasterPageComponent,
    ReportMasterPagesComponent,
    ReportMastersComponent,
    ReportPageTypeComponent,
    ReportPageTypesComponent,
    ReportParagraphComponent,
    ReportPlaceholderComponent,
    ReportSidebarComponent,
    ReportSignatureComponent,
    ReportSnippetComponent,
    ReportSpacerComponent,
    ReportTableOfContentsComponent,
    ReportTemplateCashflowPageDetailComponent,
    ReportTemplateColumnComponent,
    ReportTemplateColumnsComponent,
    ReportTemplateComponent,
    ReportTemplateDepreciationPageDetailComponent,
    ReportTemplateFinancialPageDetailComponent,
    ReportTemplateMenuComponent,
    ReportTemplateNotePageDetailComponent,
    ReportTemplatePageComponent,
    ReportTemplatePagesComponent,
    ReportTemplateRootComponent,
    ReportTemplatesComponent,
    ReportTopNavigationComponent,
    ReportViewerComponent,
    ReportWarningComponent,
    ReportingSuiteComponent,
    ReportingSuiteSelectComponent,
    ReportingSuitesComponent,
    SaveAsComponent,
  ],
})
export class ReportsModule {}
