import { MasterAccount } from './masterAccount';
import { HeaderLayoutOption } from '../../reports/layout/headerLayoutOption';

export class MasterAccountLayout extends MasterAccount {
  layoutOption: HeaderLayoutOption;
  isPlaceholder = false;

  constructor(data) {
    super(data);
    this.isPlaceholder = data.isPlaceholder === true;
    this.layoutOption = new HeaderLayoutOption(data.layoutOption);
  }
}
