<crs-report-editable-element
  [index]="index"
  [reportContent]="reportContent"
  [reportMetaData]="reportMetaData"
  (edit)="onEdit()"
  [onSubmit]="onSubmit"
  [editable]="true"
  [cancellable]="true"
>
  <div viewer>
    <p class="signature-name">
      {{ element.auditorPracticeName }}
    </p>
    <p class="signature-name">
      {{ auditorAddress }}
    </p>

    <div class="signature-block">
      <hr />

      <p class="signature-name">
        {{ auditorNameWithTitle }}
      </p>
      <p class="signature-date">
        Date: {{ element.date | date: "d MMMM yyyy" }}
      </p>
    </div>
  </div>

  <div editor>
    <form [formGroup]="form" novalidate *ngIf="isEditing">
      <div class="form-group">
        <label class="control-label">Practice Name</label>
        <input
          type="text"
          class="form-control"
          formControlName="auditorPracticeName"
        />
      </div>

      <div class="form-group">
        <label class="control-label">Address</label>
        <input
          type="text"
          class="form-control"
          formControlName="auditorAddress"
        />
      </div>

      <div class="row">
        <div [ngClass]="columnClass">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="control-label">Suburb</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="auditorSuburb"
                />
              </div>
            </div>

            <div class="col-sm-3">
              <div class="form-group">
                <label class="control-label">State</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="auditorState"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label class="control-label">Post Code</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="auditorPostCode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="control-label">Signatory Name</label>
        <input type="text" class="form-control" formControlName="auditorName" />
      </div>

      <div class="form-group">
        <label class="control-label">Signatory Title</label>
        <input
          type="text"
          class="form-control"
          formControlName="auditorTitle"
        />
      </div>

      <div class="form-group">
        <label class="control-label">Date</label>
        <crs-date-picker
          formControlName="date"
          [clearable]="true"
        ></crs-date-picker>
      </div>

      <!--
      // Since the complete MVP solution will not be available soon we need to remove auto updating auditor details to practice
      // setting from report.
      // Will revisit this feature in the future with proper solution design
      <div class="form-group">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="updateAuditorDetails"
          />
          <label class="form-check-label"
            >Update auditor's details in Practice setting</label
          >
        </div>
      </div> -->
    </form>
  </div>
</crs-report-editable-element>
