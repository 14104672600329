import { columnTypes } from './../../../../shared/ag-grid/columnTypes';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
} from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { DateService } from '../../../../core';
import { ColumnType, ColumnCalculationType } from '../';
import { Dataset } from '../../../datasets';
import { ReportTemplateDataService } from '../report-template-data.service';
import { Subscription } from 'rxjs';
import { ReportTemplateFormBuilder } from '../report-template-form-builder';

@Component({
  selector: 'crs-report-template-columns',
  templateUrl: './report-template-columns.component.html',
})
export class ReportTemplateColumnsComponent implements OnInit, OnDestroy {
  private _formArray: UntypedFormArray;
  @Input() set formArray(value: UntypedFormArray) {
    if (this._formArray !== value) {
      this.unsubscribeAllColumnEvents();
      this._formArray = value;
      this.subscribeAllColumnEvents();
      this._formArray.controls.forEach((c) =>
        this.addGetFilterMethodToColumn(c as UntypedFormGroup)
      );
    }
  }
  get formArray() {
    return this._formArray;
  }

  private _columnSubscriptions: Subscription[][] = [];

  constructor(
    private readonly _reportTemplateFormBuilder: ReportTemplateFormBuilder,
    private readonly _dateService: DateService,
    public data: ReportTemplateDataService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribeAllColumnEvents();
  }

  /**
   * Attaches events to every column in the formArray
   */
  private subscribeAllColumnEvents() {
    for (let i = 0; i < this.formArray.length; i++) {
      const group = this.formArray.at(i) as UntypedFormGroup;
      this.subscribeColumnEvents(group, i);
    }
  }

  /**
   * Unsubscribes to all column events
   */
  private unsubscribeAllColumnEvents() {
    this._columnSubscriptions.forEach((c, i) =>
      this.unsubscribeColumnEvents(i)
    );
  }

  /**
   * Adds a custom 'getActiveFilters()' method to the form group which can be used by the UI to determine how many active filters there are
   * @param group
   */
  private addGetFilterMethodToColumn(group: UntypedFormGroup) {
    const groupObj = group as any;
    groupObj.getActiveFilters = () => {
      return (
        (groupObj.controls['startDate'].value ? 1 : 0) +
        (groupObj.controls['endDate'].value ? 1 : 0) +
        (groupObj.controls['division'].value ? 1 : 0) +
        (groupObj.controls['tradingAccount'].value ? 1 : 0)
      );
    };
  }

  private subscribeColumnEvents(group: UntypedFormGroup, index: number) {
    const subscriptions: Subscription[] = [];

    subscriptions.push(
      // On changing Dataset
      group.controls['dataset'].valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(
          (data) => {
            this.defaultPeriodText(group, data);
          },
          (error) => {
            console.log(
              'error setting default column header values based on dataset',
              error
            );
          }
        )
    );

    subscriptions.push(
      // On changing filtered date
      group.controls['endDate'].valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(
          (data) => {
            const periodControl = group.controls[
              'periodText'
            ] as UntypedFormControl;
            if (data) {
              let date = data;
              if (typeof data === 'string')
                date = this._dateService.convertToDateObj(data as string);
              periodControl.setValue(
                this._dateService.toString(date, 'MMM yyyy')
              );
            } else {
              this.defaultPeriodText(group, group.controls['dataset'].value);
            }
          },
          (error) => {
            console.log(
              'error setting default column header values based on filtered date'
            );
          }
        )
    );

    subscriptions.push(
      // On changing Calculation Type
      group.controls['calculationType'].valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(
          (calculationType) => {
            if (group.controls?.columnType?.value !== ColumnType.Number) {
              return;
            }

            let title = null;
            let sign = null;
            if (calculationType === ColumnCalculationType.Variance) {
              title = 'Variance';
              sign = '$';
            }
            if (calculationType === ColumnCalculationType.PercentVariance) {
              title = 'Variance';
              sign = '%';
            }
            if (calculationType === ColumnCalculationType.PercentOfSales) {
              title = null;
              sign = '% of Sales';
            }
            (group.controls['periodText'] as UntypedFormControl).setValue(
              title
            );
            (group.controls['signText'] as UntypedFormControl).setValue(sign);
          },
          (error) => {
            console.log(
              'error setting default period values based on calculation type'
            );
          }
        )
    );

    this._columnSubscriptions[index] = subscriptions;
  }

  private unsubscribeColumnEvents(index: number) {
    if (this._columnSubscriptions.length > index) {
      this._columnSubscriptions[index].forEach((s) => s.unsubscribe());
      this._columnSubscriptions[index] = [];
    }
  }

  defaultPeriodText(group: UntypedFormGroup, dataset: Dataset) {
    const periodControl = group.controls['periodText'] as UntypedFormControl;
    if (dataset) {
      const endDate = dataset.endDate;
      const startDate = dataset.startDate;
      if (endDate.getMonth() === 5 && startDate.getMonth() !== 5) {
        periodControl.setValue(endDate.getFullYear().toString());
      } else {
        periodControl.setValue(this._dateService.toString(endDate, 'MMM yyyy'));
      }
    } else {
      periodControl.setValue(null);
    }
  }

  addColumn(type: ColumnType = ColumnType.Text) {
    const group = this._reportTemplateFormBuilder.initColumn(type);
    this.addGetFilterMethodToColumn(group);
    this._columnSubscriptions.push([]);
    this.subscribeColumnEvents(group, this.formArray.length);
    this.formArray.push(group);
  }

  removeColumn(i: number) {
    this.formArray.removeAt(i);
    this.unsubscribeColumnEvents(i);
    this._columnSubscriptions.splice(i, 1);
  }

  dropColumn(event: CdkDragDrop<string[]>) {
    const oldIndex = parseInt(event.item.data, 10);
    const newIndex = parseInt(event.container.id.substr(6), 10);
    if (isNaN(newIndex) || isNaN(oldIndex)) return;
    if (oldIndex !== newIndex) {
      moveItemInArray(this.formArray.controls, oldIndex, newIndex);
      moveItemInArray(this._columnSubscriptions, oldIndex, newIndex);
    }
  }
}
