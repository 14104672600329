import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Subject, Subscription, forkJoin } from 'rxjs';
import { catchError, exhaustMap, finalize, tap } from 'rxjs/operators';

import { MessageService, ModalService } from 'src/app/core';
import { SourceAccountService } from './../../sourceAccounts/source-account.service';
import {
  DistributionFrequency,
  DistributionRow,
  DistributionRowToSave,
} from './distribution';
import { DistributionService } from './distribution.service';
import { DistributionGrid } from './distribution-grid';

@Component({
  selector: 'crs-distribution',
  templateUrl: './distribution.component.html',
})
export class DistributionComponent implements OnInit, OnDestroy {
  @ViewChild('classificationTemplate', { static: true })
  classificationTemplate: ElementRef;
  @ViewChild('accountSearchTemplate', { static: true })
  accountSearchTemplate: ElementRef;

  public readonly DEFAULT_NUMBER_OF_ROWS: number = 5;

  datasetId: string;
  busy = {
    load: null,
    submit: null,
    undoBalancingJournal: null,
    delete: null,
  };

  form = this.formBuilder.group({
    frequency: [DistributionFrequency.Annual, Validators.required],
  });

  distributionRows: DistributionRow[] = [];
  outOfBalance: number;

  distributionGrid: DistributionGrid;
  get gridApi() {
    return this.distributionGrid.gridApi;
  }

  error: string;

  private submitStream = new Subject();
  private subscriptions: Subscription[] = [];

  canDrag = (params) => !params.node.group;
  rowDragText = () => 'Distribution Row...';

  constructor(
    private distributionService: DistributionService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private sourceAccountService: SourceAccountService
  ) {}

  ngOnInit(): void {
    this.distributionGrid = new DistributionGrid(
      this.classificationTemplate,
      this.accountSearchTemplate,
      null,
      this.updateOutOfBalance
    );

    this.configureSubmitStream();

    this.route.params.subscribe(() => {
      this.datasetId = this.route.snapshot.parent.paramMap.get('id');
      this.getRows();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private configureSubmitStream(): void {
    const subscription = this.submitStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submit$()),
        tap(() =>
          this.messageService.success('Successfully saved distributions.')
        ),
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        })
      )
      .subscribe(() => this.getRows());

    this.subscriptions.push(subscription);
  }

  onValidSubmit(): void {
    this.submitStream.next();
  }

  private submit$() {
    const distributionRowsToSave = this.distributionRows
      .filter((row) => row.balance || row.percent)
      .map((row) => new DistributionRowToSave(row));

    const loading$ = new Subject();
    this.busy.submit = loading$.subscribe();

    return this.distributionService
      .save$(this.datasetId, distributionRowsToSave)
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => loading$.complete())
      );
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error);
  }

  private getRows(): void {
    this.busy.load = forkJoin({
      distributionRows: this.distributionService.get$(this.datasetId),
      sourceAccounts: this.sourceAccountService.getForDataset$(this.datasetId),
    }).subscribe(
      ({ distributionRows, sourceAccounts }) => {
        this.setDistributionRows(distributionRows);
        this.distributionGrid.accounts = sourceAccounts;
      },
      (error) => this.showError(error)
    );
  }

  private setDistributionRows(distributionRows: DistributionRow[]): void {
    this.form.patchValue(distributionRows);

    this.distributionRows = distributionRows;
    this.gridApi?.setRowData(this.distributionRows);

    this.padRows(this.DEFAULT_NUMBER_OF_ROWS);
    this.updateOutOfBalance();
  }

  onClickClearAll(): void {
    this.modalService.confirmation(
      'Are you sure you want to clear all liability and distribution data? This cannot be undone.',
      () =>
        (this.busy.delete = this.distributionService
          .delete$(this.datasetId)
          .subscribe(
            () => this.getRows(),
            (error) => this.showError(error)
          )),
      true
    );
  }

  // ------ Journal Line Functions -----

  padRows(number: number): void {
    const current = this.distributionRows.length;
    if (current < number) {
      this.addRows(number - current);
    }
  }

  addRows(number: number): void {
    const newLines = new Array<DistributionRow>();
    for (let i = 1; i <= number; i++) {
      newLines.push(new DistributionRow(null));
    }
    Array.prototype.push.apply(this.distributionRows, newLines);

    this.gridApi?.applyTransaction({
      add: newLines,
    });
  }

  onClickDeleteRow(row: DistributionRow): void {
    this.gridApi.applyTransaction({ remove: [row] });
    const index = this.distributionRows.indexOf(row);
    this.distributionRows.splice(index, 1);
    this.updateOutOfBalance();
  }

  onClickToggleLoss(row: DistributionRow): void {
    row.excludeIfLoss = !row.excludeIfLoss;
  }

  private updateOutOfBalance(): void {
    let percent = 0;

    for (let i = 0; i < this.distributionRows.length; i++) {
      percent += this.distributionRows[i].percent;
    }

    this.outOfBalance = 1 - percent;
  }
}
