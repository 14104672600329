import { getDefaultGridOptions, accountingRenderers } from 'src/app/shared';
import { Observable, Subscription, EMPTY, Subject } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';
import { NgProgressRef } from '../../../../shared';
import { MessageService } from '../../../../core';
import { Account } from '../';
import {
  DragContext,
  MoveResult,
  MoveAction,
  MovingRow,
  IAccountGridManager,
  MoveSource,
  MoveRequest,
} from './accountGridModel';
import { MoveProcessor } from './moveProcessor';
import { AccountsComponent, AccountContext } from './accounts.component';
import {
  GetContextMenuItemsParams,
  MenuItemDef,
  RowDragEvent,
  GridReadyEvent,
  RowNode,
} from 'ag-grid-community';
import { AccountsViewSourceAccount } from './accounts-view-source-account';
import { IAccountsViewAccount } from './accounts-view-account';

/**
 * This class manages all aspects of an account grid, including display, refreshing and drag/drop.
 * One is instantiated for the 'Allocated' accounts, and another the 'Unallocated' accounts.
 */
export class AccountGridManager implements IAccountGridManager {
  private readonly placeholderId = 'placeholder';

  constructor(
    public readonly id: 'allocatedGrid' | 'unallocatedGrid',
    private readonly _accountsComponent: AccountsComponent,
    private readonly _context: AccountContext,
    public readonly moveProcessor: MoveProcessor,
    public readonly _dragContext: DragContext,
    private readonly _messageService: MessageService,
    private readonly _refreshAll: (
      context: AccountContext
    ) => Observable<IAccountsViewAccount[]>,
    private readonly _refreshIds: (
      context: AccountContext,
      ids: string[]
    ) => Observable<IAccountsViewAccount[]>,
    private readonly _progressBar: NgProgressRef
  ) {
    this.configureGridOptions();
  }

  busy: Subscription;

  selection$ = new Subject<string>();

  store: IAccountsViewAccount[] = [];
  backupStore: IAccountsViewAccount[];
  get api() {
    return this.gridOptions.api;
  }

  sortActive = false;
  filterActive = false;

  gridOptions = getDefaultGridOptions();

  configureGridOptions() {
    this.gridOptions.frameworkComponents = accountingRenderers;
    this.gridOptions.domLayout = 'normal';
    this.gridOptions.context = this._dragContext;
    this.gridOptions.immutableData = true; // big performance gain - but be mindful of how updates work
    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.treeData = true;
    this.gridOptions.animateRows = true;
    this.gridOptions.defaultColDef.sortable = false;
    this.gridOptions.defaultColDef.suppressMovable = true;
    this.gridOptions.defaultColDef.suppressMenu = true;
    this.gridOptions.isRowSelectable = (row) => row.data && row.data.isSortable;
    this.gridOptions.onSortChanged = () => this.onSortChanged();
    this.gridOptions.onFilterChanged = () => this.onFilterChanged();
    this.gridOptions.suppressCellSelection = true;
    this.gridOptions.groupDefaultExpanded = -1;
    this.gridOptions.getDataPath = (data) => data.hierarchy;
    this.gridOptions.getRowNodeId = (data) => data.id;
    this.gridOptions.getContextMenuItems = (param) =>
      this.getContextMenu(param);
    this.gridOptions.onRowDragEnter = (param) => this.onRowDragEnter(param);
    this.gridOptions.onRowDragMove = (param) => this.onRowDragMove(param);
    this.gridOptions.onRowDragLeave = (param) => this.onRowDragLeave(param);
    this.gridOptions.onRowDragEnd = (param) => this.onRowDragEnd(param);
    this.gridOptions.onRowSelected = () => this.selection$.next(this.id);

    this.gridOptions.rowClassRules = {
      'account-link': this.getAccountLinkClass,
      'source-account': this.isSourceAccount,
    };
    this.gridOptions.isFullWidthCell = (rowNode) =>
      rowNode.data && rowNode.data.id === this.placeholderId;
    this.gridOptions.fullWidthCellRenderer = (params) =>
      '<div class="account-placeholder"> Insert here... </div>';

    this.gridOptions.onGridReady = (param) => this.configureDragDrop(param);
  }

  isSourceAccount(param: RowNode) {
    return param.data instanceof AccountsViewSourceAccount;
  }

  configureDragDrop(params: GridReadyEvent) {
    //agGridFixes(params);
    const api =
      this.id === 'allocatedGrid'
        ? this._accountsComponent.unallocatedGridMgr.api
        : this._accountsComponent.allocatedGridMgr.api;
    const dropZoneParams = api.getRowDropZoneParams({});
    params.api.addRowDropZone(dropZoneParams);
  }

  expandAll() {
    this.api.forEachNode((node) => (node.expanded = true));
    this.api.onGroupExpandedOrCollapsed();
  }

  collapseAll() {
    this.api.forEachNode((node) => (node.expanded = false));
    this.api.onGroupExpandedOrCollapsed();
  }

  onSortChanged() {
    const sortModel = this.api.getSortModel();
    this.sortActive = sortModel && sortModel.length > 0;
    this.updateRowDrag();
  }

  onFilterChanged() {
    this.filterActive = this.api.isAnyFilterPresent();
    this.updateRowDrag();
  }

  updateRowDrag() {
    const suppressRowDrag = this.sortActive || this.filterActive;
    this.api.setSuppressRowDrag(suppressRowDrag);
  }

  getAccountLinkClass(params: { data: Account; context: DragContext }) {
    const result = params.context.moveResult;
    if (!result || !result.overNode.data || !params.data) return false;

    if (
      result.overNode.data.id === params.data.id &&
      (result.action === MoveAction.Link ||
        result.action === MoveAction.InsertInto)
    ) {
      return true;
    }
    return false;
  }

  // --------------------------------------------
  // ----------- Context Menu Methods -----------
  // --------------------------------------------

  getContextMenu(param: GetContextMenuItemsParams) {
    if (param.node.data instanceof AccountsViewSourceAccount) return [];
    const targetAccount = param.node.data as Account;

    const cssClasses = ['clickable'];
    let menu = [];

    // GROUPING {
    if (targetAccount.isSortable && param.api.getSelectedRows().length > 1) {
      const parent = param.node.parent.data as Account;
      menu.push({
        name: 'Quick Group',
        icon: '<i class="fas fa-layer-group" aria-hidden="true"></i>',
        action: () =>
          this._accountsComponent.quickGroup(parent, param.node.childIndex + 1),
        cssClasses: cssClasses,
      });
    }

    // NORMAL EDIT MENU ITEMS - HEADER
    if (targetAccount.isHeader) {
      menu.push({
        name: 'Add Custom Header',
        icon: '<i class="fas fa-stream" aria-hidden="true"></i>',
        action: () => this._accountsComponent.addHeader(targetAccount),
        cssClasses: cssClasses,
      });
      if (targetAccount.isSortable) {
        menu.push({
          name: 'Edit',
          icon: '<i class="fas fa-edit" aria-hidden="true"></i>',
          action: () => this._accountsComponent.editHeader(targetAccount),
          cssClasses: cssClasses,
        });
      }
      menu.push(
        {
          name: 'Sort by Account Number',
          icon: '<i class="fas fa-sort-numeric-down" aria-hidden="true"></i>',
          action: () => this._accountsComponent.orderBy(targetAccount, 0),
          cssClasses: cssClasses,
        },
        {
          name: 'Sort by Account Name',
          icon: '<i class="fas fa-sort-alpha-down" aria-hidden="true"></i>',
          action: () => this._accountsComponent.orderBy(targetAccount, 1),
          cssClasses: cssClasses,
        }
      );
      if (targetAccount.isSortable) {
        menu.push({
          name: 'Delete',
          icon: '<i class="fas fa-trash" aria-hidden="true"></i>',
          action: () => this._accountsComponent.deleteHeader(targetAccount),
          cssClasses: cssClasses,
        });
      }
      menu.push('separator');
    }

    // NORMAL EDIT MENU ITEMS - ACCOUNT
    if (!targetAccount.isHeader && !targetAccount.systemAccount) {
      menu.push({
        name: 'Edit',
        icon: '<i class="fas fa-edit" aria-hidden="true"></i>',
        action: () => this._accountsComponent.editAccount(targetAccount),
        cssClasses: cssClasses,
      });
      menu.push('separator');
    }

    // DRAG DROP MENU ITEMS
    const source = this.moveProcessor.getSelectionAsMoveSource();
    if (!source) {
      menu.push({
        name: 'Please select some accounts to move here',
        disabled: true,
      });
    } else {
      const actions = this.getAvailableActions(targetAccount, source);
      menu = menu.concat(
        actions.map((a) => {
          const menuItem = this.getMoveActionMenuItem(a);
          menuItem.action = () =>
            this.moveProcessor
              .execute(
                new MoveRequest(source, new MoveResult(this, a, param.node))
              )
              .subscribe();
          menuItem.cssClasses = ['clickable'];
          return menuItem;
        })
      );
    }

    return menu;
  }

  private getAvailableActions(
    targetAccount: Account,
    source: MoveSource
  ): MoveAction[] {
    if (source.movingRows.some((r) => r.account.id === targetAccount.id))
      return [];

    if (targetAccount.hierarchy.length === 1) return [MoveAction.InsertInto];
    if (
      source.movingRows.some(
        (r) => r.isHeader && r.account.isParentOf(targetAccount)
      )
    )
      return [];

    const actions: MoveAction[] = [];
    actions.push(MoveAction.InsertAbove);
    if (targetAccount.isHeader) {
      actions.push(MoveAction.InsertInto);
    } else if (!source.isMovingAHeader) {
      actions.push(MoveAction.Link);
    }
    actions.push(MoveAction.InsertBelow);

    return actions;
  }

  // Todo: move this to a 'prototypes' class
  private getMoveActionMenuItem(action: MoveAction): MenuItemDef {
    if (action === MoveAction.InsertAbove) {
      return {
        name: 'Insert Selection Before',
        icon: '<i class="fas fa-chevron-up" aria-hidden="true" />',
      };
    }
    if (action === MoveAction.InsertInto) {
      return {
        name: 'Insert Selection into this Account',
        icon: '<i class="fas fa-level-down-alt" aria-hidden="true" />',
      };
    }
    if (action === MoveAction.Link) {
      return {
        name: 'Combine Selected accounts',
        icon: '<i class="fas fa-link" aria-hidden="true" />',
      };
    }
    if (action === MoveAction.InsertBelow) {
      return {
        name: 'Insert Selection After',
        icon: '<i class="fas fa-chevron-down" aria-hidden="true" />',
      };
    }
    return {
      name: MoveAction[action],
    };
  }

  // --------------------------------------------
  // --------- Drag and Drop Handlers -----------
  // --------------------------------------------

  onRowDragEnter(event: RowDragEvent) {
    if (this._progressBar.isStarted) return;

    if (event.event.type === 'mousemove') return;

    const node = event.node;
    if (!node.isSelected()) node.setSelected(true, true);
    this.startContext(node);
    this.createBackup();
    if (!event.nodes) event.nodes = [event.node];
    this.refreshGrid();
  }

  onRowDragMove(event: RowDragEvent) {
    if (!this._dragContext.isActiveDrag) return;
    this.updateDragResultContext(event);
    this.moveProcessor.visualise(this._dragContext);
  }

  onRowDragEnd(event: RowDragEvent) {
    if (!this._dragContext.moveSource) return;
    this.updateDragResultContext(event);
    this.moveProcessor
      .execute(this._dragContext)
      .pipe(finalize(() => this._dragContext.clear()))
      .subscribe();
  }

  onRowDragLeave(event: RowDragEvent) {
    this.restoreBackup();
    this.refreshGrid();
  }

  private buildMovingRows() {
    const rows = this.api.getSelectedNodes().map((r) => new MovingRow(r.data));
    rows.forEach((r) => {
      r.addAffectedChildren(this.store);
    });
    // Remove children of headers that are already selected
    return rows.filter(
      (r) =>
        !rows.some((sr) =>
          sr.affectedChildren.some((c) => c.id === r.account.id)
        )
    );
  }

  // --------------------------------------------
  // ----------- UI Update Methods --------------
  // --------------------------------------------
  startLoader() {
    this._progressBar.start();
  }

  completeLoader() {
    this._progressBar.complete();
  }

  visualiseRemove(previousResult: MoveResult) {
    this.removePlaceholder();
    if (previousResult && previousResult.overNode)
      this.refreshNode(previousResult.overNode);
  }

  visualiseAdd(result: MoveResult) {
    if (result && result.overNode) {
      this.refreshNode(result.overNode);
      this.insertPlaceholder(result);
    }
  }

  private refreshNode(node) {
    const index = this.store.findIndex((r) => r.id === node.data.id);
    if (index >= 0) {
      const newAccount = this.shallowCopyNodeData(node.data);
      this.store.splice(index, 1, newAccount);
    }
  }

  private removePlaceholder() {
    const previousIndex = this.store.findIndex(
      (r) => r.id === this.placeholderId
    );
    if (previousIndex >= 0) this.store.splice(previousIndex, 1);
  }

  private insertPlaceholder(moveResult: MoveResult) {
    const node = moveResult.overNode;
    const action = moveResult.action;

    if (!node || !node.data) return;

    if (action === MoveAction.Link || action === MoveAction.None) return;

    const placeholder = new Account({
      id: this.placeholderId,
      hierarchy: this.getBaseHierarchy(node, action).concat(this.placeholderId),
    });

    // Remove Old - ocassionally race condition means it hasn't been removed prior to this re-insert
    this.removePlaceholder();
    // Insert New
    const index =
      this.store.findIndex((r) => r.id === node.data.id) +
      (action === MoveAction.InsertAbove ? 0 : 1);
    if (index >= 0) this.store.splice(index, 0, placeholder);
  }

  private getBaseHierarchy(node, action) {
    const hierarchy = node.data.hierarchy.slice();
    if (action !== MoveAction.InsertInto) hierarchy.pop();
    return hierarchy;
  }

  removeRows(rows: IAccountsViewAccount[]) {
    rows.forEach((r) => {
      const index = this.store.findIndex((a) => a.id === r.id);
      if (index >= 0) this.store.splice(index, 1);
    });
  }

  insertRows(overNode: any, rows: IAccountsViewAccount[], adj: number = 0) {
    const index = this.store.findIndex((r) => r.id === overNode.data.id);
    let i = 0;
    rows.forEach((r) => {
      this.store.splice(index + adj + i, 0, r);
      i++;
    });
  }

  // --------------------------------------------
  // ------------- Data Methods -----------------
  // --------------------------------------------

  setStore(accounts: IAccountsViewAccount[]) {
    this.store = accounts;
    this.createBackup();
    this.refreshGrid();
  }

  restoreBackup() {
    this.store = this.backupStore
      .slice()
      .map((a) => this.shallowCopyNodeData(a));
  }

  createBackup() {
    this.backupStore = this.store
      .slice()
      .map((a) => this.shallowCopyNodeData(a));
  }

  refreshId(id) {
    this._progressBar.start();

    return this.refreshIdsObservable([id])
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => {
          this._progressBar.complete();
        })
      )
      .subscribe();
  }

  refreshAll() {
    this._progressBar.start();
    return this.refreshAllObservable()
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => {
          this._progressBar.complete();
        })
      )
      .subscribe();
  }

  refreshAllObservable() {
    return this._refreshAll(this._context).pipe(
      tap((data) => {
        this.setStore(data);
        this.createBackup();
        this.api.setRowData(this.store);
      }),
      catchError((e) => {
        this.api.setRowData(this.store);
        throw e;
      })
    );
  }

  refreshIdsObservable(ids: string[]) {
    return this._refreshIds(this._context, ids).pipe(
      tap((data) => {
        this.refreshStore(ids, data);
        this.createBackup();
        this.api.setRowData(this.store);
      }),
      catchError((e) => {
        this.api.setRowData(this.store);
        throw e;
      })
    );
  }

  /**
   * For the newly supplied header ids and accounts (retrieved from the API/database)
   * updates the relevant grid ids with these new values
   * @param headerIds The header ids affected
   * @param accounts The new accounts for these header ids (per the API response)
   */
  private refreshStore(headerIds: string[], accounts: IAccountsViewAccount[]) {
    headerIds.forEach((i) => {
      const headerIndex = this.store.findIndex((r) => r.id === i);
      const oldHeader = this.store[headerIndex];
      const oldAccounts = this.store.filter((a) => a.isChildOf(oldHeader));

      // Remove header
      this.store.splice(headerIndex, 1);

      // Remove children
      this.removeRows(oldAccounts);

      const newHeader = accounts.find((a) => a.id === i);
      const newAccounts = accounts.filter((a) => a.isChildOf(newHeader));

      this.store.splice(headerIndex, 0, newHeader);
      this.store.splice(headerIndex + 1, 0, ...newAccounts);
    });
  }

  refreshGrid() {
    this.api?.setRowData(this.store);
  }

  private shallowCopyNodeData(data: IAccountsViewAccount) {
    return data.shallowCopy();
  }

  // --------------------------------------------
  // ------------- Drag Context -----------------
  // --------------------------------------------

  getMoveSource(node = null): MoveSource {
    return new MoveSource(this, node, this.buildMovingRows());
  }

  private startContext(node) {
    this._dragContext.startDragging(this.getMoveSource(node));
  }

  private clearContext() {
    this._dragContext.clear();
  }

  private updateDragResultContext(event) {
    if (!event.overNode || !event.overNode.data) {
      this._dragContext.setNewMoveResult(null);
      return;
    }
    if (event.overNode.data.id === this.placeholderId) return;
    this._dragContext.setNewMoveResult(this.getDragResult(event));
  }

  private getDragResult(event): MoveResult {
    return new MoveResult(this, this.getAction(event), event.overNode);
  }

  private getAction(event): MoveAction {
    if (this._progressBar.isStarted) return MoveAction.None;
    if (event.overNode.data instanceof AccountsViewSourceAccount)
      return MoveAction.None;

    const y = event.y;
    const data = event.overNode.data as Account;
    const top = event.overNode.rowTop;
    const height = event.overNode.rowHeight;

    // if (!data || data.systemAccount) return DragAction.None;
    if (
      this._dragContext.moveSource.movingRows.some(
        (r) => r.account.id === data.id
      )
    )
      return MoveAction.None;
    if (data.hierarchy.length === 1) return MoveAction.InsertInto;
    if (
      this._dragContext.moveSource.movingRows.some(
        (r) => r.isHeader && r.account.isParentOf(data)
      )
    )
      return MoveAction.None;

    const topCentre = top + height * 0.32;
    const bottomCentre = top + height * 0.68;
    const middle = top + height * 0.5;
    if (y <= topCentre) return MoveAction.InsertAbove;
    if (y > topCentre && y < bottomCentre) {
      if (data.isHeader) return MoveAction.InsertInto;
      else if (this._dragContext.moveSource.isMovingAHeader)
        return y > middle ? MoveAction.InsertAbove : MoveAction.InsertBelow;
      else return MoveAction.Link;
    }
    if (y >= bottomCentre) return MoveAction.InsertBelow;
    return MoveAction.None;
  }

  private showError(error) {
    console.log(error);
    this._messageService.error(error);
  }
}
