import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { AccountService, ActiveFileService } from 'src/app/accounting';
import { AccountUltraLight } from 'src/app/accounting/ledger/accounts/account-ultra-light';

@Component({
  selector: 'crs-accounts-select',
  templateUrl: './accounts-select.component.html',
  styleUrls: ['./accounts-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountsSelectComponent),
      multi: true,
    },
  ],
})
export class AccountsSelectComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  @Input() selectedValue: string;
  @Input() appendTo: string;
  @Input() variant: 'outline' | 'ghost' = 'outline';
  @Input() isDisabled = false;
  @Output() selectedValueChange = new EventEmitter<{
    id: string;
    account: AccountUltraLight;
  }>();

  subscriptions: Subscription[] = [];
  fileId: string;

  accountsObservable: Observable<AccountUltraLight[]>;
  accountInput = new Subject<string>();
  loading = false;
  accountsList: AccountUltraLight[] = [];
  selectedAccount: AccountUltraLight;

  isFetchingAccounts$ = new BehaviorSubject(false);

  constructor(
    private accountService: AccountService,
    private activeFileService: ActiveFileService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.activeFileService.stream.subscribe((file) => {
        this.fileId = file.id;
        this.initializeAccounts();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private initializeAccounts() {
    this.isFetchingAccounts$.next(true);

    this.accountsObservable = this.accountInput.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(() => this.accountService.getAllChildAccounts$(this.fileId)),
      catchError((err: any) => {
        console.error('Error searching for accounts', err);
        return of([]);
      }),
      tap((accounts) => {
        this.accountsList = accounts;
        this.setSelectedAccount();
        this.isFetchingAccounts$.next(false);
      })
    );
  }

  private setSelectedAccount() {
    if (this.selectedValue) {
      this.selectedAccount = this.accountsList.find(
        (account) => account.id === this.selectedValue
      );
    }
  }

  compareAccounts = (a: AccountUltraLight, b: AccountUltraLight) => {
    return a && b && a.id === b.id;
  };

  private onChange = (value: any) => {};
  private onTouched = () => {};

  handleChange(option: AccountUltraLight) {
    if (option) {
      this.selectedValue = option.id;
    } else {
      this.selectedValue = null;
    }
    this.selectedValueChange.emit({ id: this.selectedValue, account: option });
    this.onChange(this.selectedValue);
    this.onTouched();
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedValue = value;
      this.setSelectedAccount();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  searchFn(term: string, item: any) {
    term = term?.toLowerCase();
    return (
      item.accountNo?.toLowerCase().includes(term) ||
      item.accountName?.toLowerCase().includes(term)
    );
  }
}
