import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, SessionService } from '../..';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'crs-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  logoSrc: string = './assets/images/AccessLedgerLogo.png';
  featureFlagsSubscription: Subscription;
  articles = [];

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private route: ActivatedRoute
  ) {}

  public login() {
    this.authService.login();
  }

  public logout() {
    this.authService.logout();
  }

  public get session(): SessionService {
    return this.sessionService;
  }

  ngOnInit() {
    this.featureFlagsSubscription =
      this.sessionService.featureFlagsLoaded$.subscribe((featureFlags) => {
        if (featureFlags) {
          this.setBrandLogo();
          this.generateKnowledgeBaseLinks();
        }
      });
  }

  ngOnDestroy() {
    if (this.featureFlagsSubscription) {
      this.featureFlagsSubscription.unsubscribe();
    }
  }

  setBrandLogo() {
    if (this.sessionService.featureFlags) {
      this.logoSrc = this.sessionService.featureFlags.ledger
        ? './assets/images/AccessLedgerLogo.png'
        : './assets/images/AccessBankFeedsLogo.png';
    }
  }

  generateKnowledgeBaseLinks() {
    if (this.sessionService.featureFlags.ledger) {
      this.articles.push({
        title: 'Admin Guide',
        link: 'https://accessgroup.my.site.com/Support/s/article/Access-Ledger-Administrator-User-Guide',
      });
      this.articles.push({
        title: 'User Guide',
        link: 'https://accessgroup.my.site.com/Support/s/article/Access-Ledger-User-Guide',
      });
      this.articles.push({
        title: 'Quick Reference Guides',
        link: 'https://accessgroup.my.site.com/Support/s/article/Access-Ledger-Quick-Reference-Guides',
      });
    }

    //(Tenant with bank feeds feature flag only)
    if (
      !this.sessionService.featureFlags.ledger &&
      this.sessionService.featureFlags.bankFeed
    ) {
      this.articles.push({
        title: 'User Guide',
        link: 'https://accessgroup.my.site.com/Support/s/article/Access-APS-Access-Bank-Feeds-User-Guide',
      });
    }
  }
}
