import { Injectable } from "@angular/core";
import { ApiService } from 'src/app/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportWordService {
  private base = 'api/accounting/reports/styles/word';
  
  constructor(private _apiService: ApiService) { }
  
  downloadMaster() {
    const url = this.base + '/download/master';
    return this._apiService.get<any>(url).pipe(map(s => s.data));
  }
  
  downloadTemplate(reportStyleId: string) {
    const url = this.base + '/download/' + reportStyleId;
    return this._apiService.get<any>(url).pipe(map(s => s.data));
  }
}