import { Component } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { IHeaderGroupParams } from 'ag-grid-community';

interface TrialBalanceHeaderGroupParams {
  datasetName: string;
  heading: string;
}

@Component({
  selector: 'crs-trial-balance-header-group',
  template: `
    <span>{{ params.heading }}</span>
    <br />
    <span style="font-size: large">{{ params.datasetName }}</span>
  `,
})
export class TrialBalanceHeaderGroupComponent
  implements IHeaderGroupAngularComp
{
  public params: IHeaderGroupParams & TrialBalanceHeaderGroupParams;

  agInit(params: IHeaderGroupParams & TrialBalanceHeaderGroupParams): void {
    this.params = params;
  }
}
