<div class="center">

  <img width="70" src="./assets/images/logo.png" alt="Access Logo">

  <div class="spacer-md"></div>

  <p><strong>Sorry, unable to find this page</strong></p>

  <div class="spacer-md"></div>

  <p>
    <a href="javascript:history.back();">Go back</a> and try again.
  </p>

</div>
