<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li aria-current="page" class="breadcrumb-item active">Image Library</li>
  </ol>
</nav>

<div>
  <crs-busy *ngIf="busy.loading"></crs-busy>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu pad-no-bottom">
    <button
      class="btn btn-secondary"
      title="Create Image"
      type="button"
      (click)="addImage()"
    >
      <i aria-hidden="true" class="fas fa-plus inset"></i>
      New Image
    </button>
  </div>

  <div class="row">
    <div class="col-12">
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        [gridOptions]="gridOptions"
        [rowData]="images$ | async"
        (rowClicked)="selectImage($event.data)"
      />
    </div>
  </div>
</div>
