import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  forkJoin,
  of,
} from 'rxjs';
import {
  catchError,
  concatMap,
  finalize,
  map,
  switchMap,
} from 'rxjs/operators';

import { FeedClient } from 'src/app/baco/common';
import {
  BacoAccountToUpsert,
  BacoSyncChartOfAccountsModel,
  BacoTaxOptionToUpsert,
} from 'src/app/baco/interfaces';
import { DateService, PagedResponse } from 'src/app/core';
import { MessageService } from 'src/app/core/messages/message.service';
import { ModalService } from 'src/app/core/modals/modal.service';
import { ActiveFileService } from '../../active-file.service';
import { StandardAccountSettingService } from '../../chart/headers-and-accounts/standard-account-setting.service';
import { SourceAccount } from '../../ledger';
import { SourceAccountService } from '../../ledger/sourceAccounts/source-account.service';
import { TaxCode } from '../../ledger/tax-codes/tax-code';
import { TaxCodeService } from '../../ledger/tax-codes/tax-code.service';
import { ApiType, Source, SourceService, SourceTypeId } from '../../sourcedata';
import { FileFeedsActivateChartOfAccountsComponent } from '../file-feeds-activate-chart-of-accounts/file-feeds-activate-chart-of-accounts.component';
import { FileFeedsFormComponent } from '../file-feeds-form/file-feeds-form.component';

@Component({
  selector: 'crs-file-feeds',
  templateUrl: './file-feeds.component.html',
  styleUrls: ['./file-feeds.component.scss'],
})
export class FileFeedsComponent implements OnInit, OnDestroy {
  busy = {
    load: null,
  };
  error: string = null;

  isBankFeedEnabled$: Observable<boolean>;
  isFetchingBankFeeds$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private fileId: string;
  private feedId: string;
  private isFileChartOfAccountsActivated: boolean;
  private isTenantChartOfAccountsActivated: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    private activeFileService: ActiveFileService,
    private activeModal: NgbActiveModal,
    private dateService: DateService,
    private feedClient: FeedClient,
    private messageService: MessageService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private sourceAccountService: SourceAccountService,
    private sourceService: SourceService,
    private standardAccountSettingService: StandardAccountSettingService,
    private taxCodeService: TaxCodeService
  ) {}

  ngOnInit(): void {
    this.fetchFileId();

    this.isBankFeedEnabled$ = this.getIsBankFeedEnabled$();
    this.syncChartOfAccountsIfFeedExists();
    this.fetchIsTenantChartOfAccountsActivated();
    this.fetchIsFileChartOfAccountsActivated();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getIsBankFeedEnabled$(): Observable<boolean> {
    this.isFetchingBankFeeds$.next(true);

    return this.feedClient.getForFile$(this.fileId).pipe(
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      }),
      map((feeds) => {
        if (feeds?.length <= 0) {
          return false;
        }

        this.feedId = feeds[0].id;
        return true;
      }),
      finalize(() => this.isFetchingBankFeeds$.next(false))
    );
  }

  onClickEnableBankFeeds(): void {
    if (!this.isTenantChartOfAccountsActivated) {
      const title = 'Chart of account not active';
      const text = `
        In order for bank feeds to be enabled, the accountant's chart of account must first be activated.
        <br />
        <br />
        Please contact your system administrator and ask them to enable accountant's chart of account within your organisation.
      `;

      this.modalService.showInformationDialog(text, false, 'Close', title, () =>
        this.activeModal.dismiss()
      );

      return;
    }

    if (!this.isFileChartOfAccountsActivated) {
      this.openFileFeedsActivateChartOfAccountsModal();

      return;
    }

    this.openFileFeedsFormModal();
  }

  private syncChartOfAccountsIfFeedExists(): void {
    this.isBankFeedEnabled$
      .pipe(
        switchMap((isBankFeedEnabled) => {
          this.busy.load = true;

          if (!isBankFeedEnabled) {
            return EMPTY;
          }

          return this.getSourcesForFile$();
        }),
        switchMap((sources) => {
          if (!sources) {
            return of(null);
          }

          const chartOfAccountsSource = sources.find(
            (source) => source.sourceTypeId === SourceTypeId.ChartOfAccounts
          );
          const sourceId = chartOfAccountsSource?.id;

          if (!sourceId) {
            return EMPTY;
          }

          return this.getSourceAccountsAndTaxCodes$(sourceId);
        }),
        concatMap(
          ([sourceAccountsResponse, taxCodes]: [
            PagedResponse<SourceAccount>,
            TaxCode[]
          ]) => {
            if (!sourceAccountsResponse || !taxCodes) {
              return EMPTY;
            }

            const sourceAccounts = sourceAccountsResponse?.records;

            return this.syncChartOfAccountsToFeed$(sourceAccounts, taxCodes);
          }
        ),
        concatMap(() => this.navigateToFeedDetail()),
        finalize(() => (this.busy.load = false))
      )
      .subscribe();
  }

  private getSourcesForFile$(): Observable<Source[]> {
    return this.sourceService.getAll$(this.fileId).pipe(
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      })
    );
  }

  private getSourceAccountsAndTaxCodes$(
    sourceId: string
  ): Observable<[PagedResponse<SourceAccount>, TaxCode[]]> {
    return forkJoin([
      this.sourceAccountService.getForFilePaginated$({
        fileId: this.fileId,
        sourceId,
        pageSize: 1000,
      }),
      this.taxCodeService.getAll$(this.fileId),
    ]).pipe(
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      })
    );
  }

  private syncChartOfAccountsToFeed$(
    sourceAccounts: SourceAccount[],
    taxCodes: TaxCode[]
  ) {
    const bacoAccountsToUpsert = sourceAccounts?.map((sourceAccount) => {
      const sourceAccountTaxCode = taxCodes?.find(
        (taxCode) => taxCode.taxCode === sourceAccount.taxCode
      );

      return {
        sourceId: sourceAccount.id,
        accountNo: sourceAccount.accountNo,
        accountName: sourceAccount.accountName,
        preferredTaxSourceId: sourceAccountTaxCode?.id ?? null,
      } as BacoAccountToUpsert;
    });

    const bacoTaxOptionsToUpsert = taxCodes?.map(
      (taxCode) =>
        ({
          sourceId: taxCode.id,
          code: taxCode.taxCode,
          name: taxCode.description,
          percentage: taxCode.rate,
        } as BacoTaxOptionToUpsert)
    );

    const bacoSyncChartOfAccountsModel: BacoSyncChartOfAccountsModel = {
      accounts: bacoAccountsToUpsert,
      taxOptions: bacoTaxOptionsToUpsert,
    };

    return this.feedClient
      .syncChartOfAccounts$(this.feedId, bacoSyncChartOfAccountsModel)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return of(null);
        })
      );
  }

  private navigateToFeedDetail(): Promise<boolean> {
    const lastFinancialYear = this.dateService.getLastFinancialYear();
    const lastFinancialYearStartDate = this.dateService.toString(
      lastFinancialYear.startDate,
      'yyyy-MM-dd'
    );
    const lastFinancialYearEndDate = this.dateService.toString(
      lastFinancialYear.endDate,
      'yyyy-MM-dd'
    );

    return this.router.navigate([`./${this.feedId}`], {
      queryParams: {
        feedId: this.feedId,
        startDate: lastFinancialYearStartDate,
        endDate: lastFinancialYearEndDate,
      },
      relativeTo: this.route,
    });
  }

  private fetchFileId(): void {
    const activeFileStreamSubscription =
      this.activeFileService.stream.subscribe((file) => {
        this.fileId = file?.id;
      });

    this.subscriptions.push(activeFileStreamSubscription);
  }

  private fetchIsTenantChartOfAccountsActivated(): void {
    const isTenantChartOfAccountsActivatedSubscription =
      this.standardAccountSettingService.isActive$().subscribe(
        (isActive) => (this.isTenantChartOfAccountsActivated = isActive),
        (error) => this.showError(error)
      );

    this.subscriptions.push(isTenantChartOfAccountsActivatedSubscription);
  }

  private fetchIsFileChartOfAccountsActivated(): void {
    const isFileChartOfAccountsActivatedSubscription = this.sourceService
      .getAll$(this.fileId)
      .subscribe(
        (sources) => {
          const hasChartOfAccountsSource = sources.some(
            (source) => source.sourceTypeId === SourceTypeId.ChartOfAccounts
          );
          this.isFileChartOfAccountsActivated = hasChartOfAccountsSource;
        },
        (error) => this.showError(error)
      );

    this.subscriptions.push(isFileChartOfAccountsActivatedSubscription);
  }

  private openFileFeedsActivateChartOfAccountsModal(): void {
    this.modalService
      .openModal(FileFeedsActivateChartOfAccountsComponent, null, null, {
        size: 'md',
        windowClass: 'activate-modal',
      })
      .then(
        (source: Source) => {
          if (source.sourceType.apiType !== ApiType.ChartOfAccounts) {
            return;
          }

          this.fetchIsFileChartOfAccountsActivated();
          this.messageService.success(
            `Accountant's Chart is activated successfully`
          );
          this.openFileFeedsFormModal();
        },
        () => true
      );
  }

  private openFileFeedsFormModal(): void {
    this.modalService
      .openModal(FileFeedsFormComponent, null, null, {
        size: 'md',
        windowClass: 'activate-modal',
      })
      .then(
        () => this.messageService.success('Bank feeds enabled successfully'),
        () => true
      );
  }

  private showError(error) {
    this.error = error;
    this.messageService.error(error);
  }
}
