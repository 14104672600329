import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { getDefaultGridOptions } from 'src/app/shared';
import { tap } from 'rxjs/operators';
import { AssetReportGrid } from '../asset-report-grid';

@Component({
  selector: 'crs-asset-report-detail',
  templateUrl: './asset-report-detail.component.html'
})
export class AssetReportDetailComponent implements OnInit, OnDestroy {

  @Input() content;

  search = new UntypedFormControl();
  subscriptions: Subscription[] = [];

  gridOptions = getDefaultGridOptions();
  assetReportDollarParams = AssetReportGrid.dollarParams;

  constructor() { }

  ngOnInit() {

    AssetReportGrid.customiseGrid(this.gridOptions);

    this.subscriptions.push(
    this.search.valueChanges.pipe(
      tap(v => this.gridOptions.api.setQuickFilter(v)))
    .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }
}
