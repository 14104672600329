<div class="report-warnings">
  <div class="d-flex align-items-start warning" [class.warning-sm]="small" *ngFor="let warning of warnings">

    <div class="inline-block" [class.mr-2]="!small" [class.mr-1]="small">
      <i class="fas fa-exclamation-circle warning-icon text-danger" aria-hidden="true" *ngIf="warning.severity == 2"></i>
      <i class="fas fa-exclamation-triangle warning-icon text-warning" aria-hidden="true" *ngIf="warning.severity == 1"></i>
      <i class="fas fa-exclamation-triangle warning-icon text-info" aria-hidden="true" *ngIf="warning.severity == 0"></i>
    </div>

    <div class="inline-block mr-1">
      <span *ngIf="!small">
        {{warning.message}}
      </span>
      <small *ngIf="small">
        {{warning.message}}
      </small>
      <ul *ngIf="warning.detailedMessages && warning.detailedMessages.length && !small">
        <li *ngFor="let message of warning.detailedMessages">
          <small>{{message}}</small>
        </li>
      </ul>
      <div *ngIf="warning.linkType">
        <span *ngIf="!small">
          <a [routerLink]="['accounting', fileId, 'accounts']" *ngIf="warning.linkType == 1" target="_blank" rel="noopener noreferrer">Click to go to accounts</a>
          <a [routerLink]="[]" (click)="editEntity()" *ngIf="warning.linkType == 2">Click to edit Entity Details</a>
        </span>
        <small *ngIf="small">
          <a [routerLink]="['accounting', fileId, 'accounts']" *ngIf="warning.linkType == 1" target="_blank" rel="noopener noreferrer">Click to go to accounts</a>
          <a [routerLink]="[]" (click)="editEntity()" *ngIf="warning.linkType == 2">Click to edit Entity Details</a>
        </small>
      </div>
    </div>

    <div class="ml-auto inline-block">
      <button type="button" class="close" aria-label="Close" (click)="clearWarning(warning)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </div>
</div>
