import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportPageOutput } from '../report-output';

@Component({
  selector: 'crs-report-top-navigation',
  templateUrl: './report-top-navigation.component.html',
  styleUrls: [ './report-top-navigation.component.scss' ]
})
export class ReportTopNavigationComponent {
  @Input() activePageIndex: number;
  @Input() pages: ReportPageOutput[];

  @Output() selectedPageIndex: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectedPage: EventEmitter<ReportPageOutput> = new EventEmitter<ReportPageOutput>();

  public selectPageIndex(number: number): void {
    this.selectedPageIndex.emit(number);
  }

  public selectPage(page: ReportPageOutput): void {
    this.selectedPage.emit(page);
  }
}
