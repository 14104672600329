import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Disclosure } from 'src/app/accounting/reports/notes/disclosures/disclosure';
import { DisclosureLinkOption } from './../../../notes/disclosures/disclosure-link';
import { HeaderLayoutOption } from './../../../layout/headerLayoutOption';

@Component({
  selector: 'crs-disclosure-selector-modal',
  templateUrl: './disclosure-selector-modal.component.html'
})
export class DisclosureSelectorModalComponent implements OnInit, OnDestroy {

  form = this._formBuilder.group({
    disclosure: [null],
    linkOption: [null],
    placeholderIdentifier: [null]
  });
  linkOptions = DisclosureLinkOption;

  @Input() params: {
    layoutOption: HeaderLayoutOption,
    disclosures: Disclosure[]
  };

  get isDefaultLinkOption() {
    return !this.form.get('disclosure').value && this.params.layoutOption.disclosureLink;
  }

  get hasNoDisclosure() {
    return !this.form.get('disclosure').value && !this.params.layoutOption.disclosureLink;
  }

  get isAppendDetail() {
    const linkOption = this.isDefaultLinkOption ?
      this.params.layoutOption.disclosureLink.linkOption :
      this.form.get('linkOption').value;
    return linkOption === DisclosureLinkOption.AppendDetail;
  }

  get defaultPlaceholder() {
    if (this.isDefaultLinkOption && this.params.layoutOption.disclosureLink.placeholderIdentifier) {
      return this.params.layoutOption.disclosureLink.placeholderIdentifier;
    }
    if (this.isDefaultLinkOption) return 'Auto: Use Default';
    return 'Use default';
  }

  get defaultLinkOption() {
    return this.isDefaultLinkOption ? 'Auto: ' + this.linkOptions[this.params.layoutOption.disclosureLink.linkOption] : null;
  }

  subscriptions: Subscription[] = [];

  error: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.subscriptions.push(
      this.form.get('disclosure').valueChanges.subscribe(v => {
        if (!v && !this.params.layoutOption.disclosureLink) this.form.get('linkOption').setValue(null);
        this.form.get('linkOption').setValidators(v ? Validators.required : null);
        this.form.markAsUntouched();
      })
    );

    const disclosure = this.params.disclosures
      .find(d => d.disclosureTemplateChainId === this.params.layoutOption.disclosureTemplateChainId);

    if (disclosure) this.form.get('disclosure').setValue(disclosure);
    this.form.get('linkOption').setValue(this.params.layoutOption.linkOption);
    this.form.get('placeholderIdentifier').setValue(this.params.layoutOption.placeholderIdentifier);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  submit() {
    this.params.layoutOption.disclosure = this.form.get('disclosure').value;
    this.params.layoutOption.linkOption = this.form.get('linkOption').value;
    this.params.layoutOption.placeholderIdentifier = this.form.get('placeholderIdentifier').value;
    this._activeModal.close();
  }

  cancel() {
    this._activeModal.dismiss();
  }


}
