import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { OfficeUser, OfficeUserRole } from '../';

export class OfficeUserComponentParams {
  officeUser: OfficeUser;
  hideUser: boolean;
  hideOffice: boolean;
  excludeIds: any[] = [];

  constructor(officeUser: OfficeUser, hideUser: boolean, hideOffice: boolean, excludeIds: any[] = []) {
    this.officeUser = officeUser;
    this.hideUser = hideUser;
    this.hideOffice = hideOffice;
    this.excludeIds = excludeIds;
  }
}

@Component({
  selector: 'crs-office-user',
  templateUrl: './office-user.component.html',
  styleUrls: ['./office-user.component.scss']
})

export class OfficeUserComponent implements OnInit {

  form = this._formBuilder.group({
    user: [null, Validators.required],
    office: [null, Validators.required],
    role: [0, Validators.required],
    isPartner: [false],
    isManager: [false],
    isDefaultPartner: [false]
  });

  private _officeUser: OfficeUser;

  @Input() set params(params: OfficeUserComponentParams) {

    this._officeUser = params.officeUser;
    if (params.officeUser.user) this.readonlyUser = true;
    if (params.officeUser.office) this.readonlyOffice = true;
    this.hideOffice = params.hideOffice;
    this.hideUser = params.hideUser;
    this.excludeIds = params.excludeIds;
    this.form.patchValue(params.officeUser);
  }

  get office(): OfficeUser {
    const officeUser = this.form.value as OfficeUser;
    if (this._officeUser) officeUser.id = this._officeUser.id;
    return officeUser;
  }

  hideUser = false;
  readonlyUser = false;
  hideOffice = false;
  readonlyOffice = false;
  excludeIds = [];
  roles = OfficeUserRole;

  error: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  submit() {
    this.activeModal.close(this.office);
  }

}
