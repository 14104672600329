<div class="center">

  <div class="spacer-md"></div>

  <div class="d-inline-block">
    <a type="button" routerLink="/register" class="btn btn-primary btn-block">Register</a>
  </div>

  <div class="spacer-md"></div>

</div>