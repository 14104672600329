import { UntypedFormBuilder } from '@angular/forms';
import { Subscription, EMPTY } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';

import { File } from '../../';
import { ActiveFileService } from '../../active-file.service';
import { AssetsContextService } from '../assets-context.service';
import { DepreciationYear } from '../depreciation-years/depreciation-year';
import { AssetsContext } from '../assets-context';
import { DepreciationYearComponent } from '../depreciation-years/depreciation-year/depreciation-year.component';
import { ModalService } from 'src/app/core';

@Component({
  selector: 'crs-assets-root',
  templateUrl: './assets-root.component.html',
  styleUrls: ['./assets-root.component.scss']
})
export class AssetsRootComponent implements OnInit, OnDestroy {

  file: File;
  assetsContext: AssetsContext;
  loading = true;

  private subscriptions: Subscription[] = [];
  private entityYearsubscriptions: Subscription[] = [];

  form = this._formBuilder.group({
    entity: [],
    year: []
  });

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _activeFileService: ActiveFileService,
    public assetsContextService: AssetsContextService,
    private readonly _modalService: ModalService) {

     }

  ngOnInit() {

    this.subscriptions.push(
      this._activeFileService.stream.pipe(
        tap(f => {
          this.loading = true;
          this.file = f;
        }          ),
        tap(f => {
          this.assetsContextService.updateFile(f);
        }))
    .subscribe());

    this.subscriptions.push(
      this.assetsContextService.context$.pipe(
        tap(c => {
          this.unsubscribeEntityYearSubscriptions();
          this.assetsContext = c;
          this.form.controls['entity'].setValue(!c ? null : c.entity);
          this.form.controls['year'].setValue(!c ? null : c.year);
          this.createEntityYearSubscription();
          this.loading = false;
        }),
        catchError(() => {
          this.loading = false;
          return EMPTY;
        }))
    .subscribe());

  }

  createEntityYearSubscription() {
    this.entityYearsubscriptions.push(
      this.form.get('year').valueChanges.pipe(
        tap(y => this.assetsContextService.updateYear(y))
      ).subscribe()
    );

    this.entityYearsubscriptions.push(
      this.form.get('entity').valueChanges.pipe(
        tap(e => this.assetsContextService.updateEntity(e))
      ).subscribe()
    );
  }

  unsubscribeEntityYearSubscriptions() {
    this.entityYearsubscriptions.forEach(s => s.unsubscribe());
    this.entityYearsubscriptions = [];
  }

  addYear() {
    this._modalService.openModal(DepreciationYearComponent, 'add');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.unsubscribeEntityYearSubscriptions();
  }

}
