import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core';
import { Account } from '../..';

@Injectable({
  providedIn: 'root'
})
export class ReportOutputService {

  readonly base: string = 'api/accounting/reports/output';

  constructor(private apiService: ApiService) { }

  getIncludedAccounts(fileId: string, accountId: string, elementIdType: number, entityType?: number) {
    let url = this.base + '/included-accounts';
    url = this.apiService.addQuery(url, 'fileId', fileId);
    url = this.apiService.addQuery(url, 'accountId', accountId);
    url = this.apiService.addQuery(url, 'elementIdType', elementIdType);
    url = this.apiService.addQuery(url, 'entityType', entityType);
    return this.apiService.get<Account[]>(url).pipe(map(r => r.map(a => new Account(a))));
  }
}
