<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-xs-10 col-sm-6 col-md-4">
        <div class="form-group">
          <label class="control-label">Account No</label>
          <input class="form-control" formControlName="accountNo" type="text" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Account Name</label>
          <input
            class="form-control"
            formControlName="accountName"
            type="text"
          />
        </div>

        <div class="form-group">
          <label class="control-label">Classification</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="sourceClassification"
            [clearable]="false"
            [items]="classifications | enumToArray : true"
          ></ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Header</label>
          <input
            class="form-control"
            read-only
            readonly
            type="text"
            [value]="originalAccount?.parent?.displayName"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Auto Match Mode</label>
          <div class="input-group d-flex">
            <div class="flex-grow-1">
              <ng-select
                bindLabel="name"
                bindValue="value"
                formControlName="autoMatchMode"
                [clearable]="false"
                [items]="autoMatchModes | enumToArray"
              ></ng-select>
            </div>
            <div class="ml-2">
              <a
                class="btn-sm fas-btn-primary-ghost"
                placement="left"
                popoverTitle="Auto Match Mode"
                [ngbPopover]="headerMatchingContent"
                [routerLink]="[]"
              >
                <i aria-hidden="true" class="fas fa-info-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ng-template #headerMatchingContent>
        <p>
          Adjust this to control whether auto match rules should be run on this
          account.
        </p>
        <p *ngIf="originalAccount && originalAccount.headerMatchingRule">
          <strong>This account was matched based on the following rule:</strong>
          <br />
          {{ originalAccount?.headerMatchingRule?.findDescription }}
        </p>
      </ng-template>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Account Type</label>
          <crs-account-type-select
            formControlName="accountType"
            [accountId]="id"
            [clearable]="false"
            [readonly]="form.value.accountTypeMode == 0"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Mode</label>
          <div class="input-group d-flex">
            <div class="flex-grow-1">
              <ng-select
                bindLabel="name"
                bindValue="value"
                formControlName="accountTypeMode"
                [clearable]="false"
                [items]="accountTypeModes | enumToArray"
              />
            </div>
            <div class="ml-2">
              <a
                class="btn-sm fas-btn-primary-ghost"
                placement="left"
                popoverTitle="Account Type Mode"
                [ngbPopover]="accountTypeContent"
                [routerLink]="[]"
              >
                <i aria-hidden="true" class="fas fa-info-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ng-template #accountTypeContent>
        <p>
          Adjust this to control whether auto match rules should be run on this
          account, or whether it should be inherited.
        </p>
        <p *ngIf="originalAccount && originalAccount.accountTypeMatchingRule">
          <strong
            >This account was assigned the account type based on the following
            rule:</strong
          ><br />
          {{ originalAccount?.accountTypeMatchingRule?.findDescription }}
        </p>
      </ng-template>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Default Division</label>
          <ng-select
            bindLabel="name"
            formControlName="division"
            [items]="divisions"
          />
        </div>

        <div class="form-group">
          <label class="control-label">Default Trading Account</label>
          <ng-select
            bindLabel="name"
            formControlName="tradingAccount"
            [items]="tradingAccounts"
          />
        </div>

        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="isConsolidated"
              type="checkbox"
            />
            <label class="form-check-label">Consolidate</label>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2 class="form-group-title">Source Accounts</h2>
      <div formArrayName="sourceAccounts">
        <div
          class="card mt-2"
          *ngFor="let control of sourceAccounts?.controls; let i = index"
        >
          <div class="card-body d-flex align-items-center" [formGroupName]="i">
            <div class="flex-grow-1 row d-flex align-items-center">
              <div class="col-sm-5 col-md-5 col-lg-4">
                <h5 class="mb-2">
                  {{ control.value.sourceAccount.displayName }}
                </h5>
                <div class="mb-2">
                  <crs-source-icon
                    [source]="control.value.sourceAccount.source"
                  />
                </div>
              </div>
              <div class="col-sm-5 col-md-5 col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="control-label">Division</label>
                      <ng-select
                        bindLabel="name"
                        formControlName="division"
                        [items]="divisions"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="control-label">Trading Account</label>
                      <ng-select
                        bindLabel="name"
                        formControlName="tradingAccount"
                        [items]="tradingAccounts"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group float-md-right">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      formControlName="inactive"
                      type="checkbox"
                    />
                    <label class="form-check-label">Inactive</label>
                  </div>
                </div>
              </div>
            </div>

            <a
              class="btn btn-sm fas-btn ml-2"
              title="Unlink Source Account"
              *ngIf="originalAccount.sourceAccounts.length > 1"
              [promiseBtn]="busy.unlink"
              [routerLink]="[]"
              (click)="unlink(control.value.sourceAccount)"
            >
              <i aria-hidden="true" class="fas fa-unlink"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <ng-template #optionsCell let-row>
      <div class="btn-mini-menu">
        <a
          class="btn-sm fas-btn-primary-ghost"
          title="Unlink Account"
          [routerLink]="['./', row.id]"
        >
          <i aria-hidden="true" class="fas fa-unlink"></i>
        </a>
      </div>
    </ng-template>

    <crs-form-error [error]="error"></crs-form-error>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      Save {{ objectTitle }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>
</form>
