export class ReportAuditorModel {
  practiceId: string;
  auditorPracticeName: string;
  auditorAddress: string;
  auditorSuburb: string;
  auditorPostCode: string;
  auditorName: string;
  auditorTitle: string;
  auditorEmail: string;
  date: string;
  updateAuditorDetails: string;

  constructor(data) {
    Object.assign(this, data);
  }
}

