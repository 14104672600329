<!-- Search -->
<div class="row">
  <div class="col-sm-12">
    <div class="icon-input-wrapper flex-grow-1 mr-2">
      <i aria-hidden="true" class="fas fa-search input-icon"></i>
      <input
        aria-describedby="searchIcon"
        placeholder="Search..."
        type="search"
        [formControl]="search"
      />
    </div>
  </div>
</div>

<div style="overflow: hidden; flex-grow: 1">
  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    style="width: 100%"
    [gridOptions]="gridOptions"
    [rowData]="content"
  >
    <ag-grid-column
      field="asset.assetGroupName"
      headerName="Group"
      [enableRowGroup]="true"
      [hide]="true"
      [rowGroup]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.code"
      headerName="Code"
      [hide]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.identifier"
      headerName="Identifier"
      [hide]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.acquisitionDate"
      headerName="Acq Date"
      type="dateColumn"
    ></ag-grid-column>
    <ag-grid-column
      field="accounting.closingCost"
      headerName="Cost"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="accounting.additions"
      headerName="Additions"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column headerClass="ag-column-header" headerName="Taxation">
      <ag-grid-column
        field="taxation.openingCarryingAmount"
        headerName="OWDV"
        type="dollarColumn"
        [cellRendererParams]="assetReportDollarParams"
      ></ag-grid-column>
      <ag-grid-column
        field="taxation.depreciationMethodDescription"
        headerName="T"
        [width]="60"
      ></ag-grid-column>
      <ag-grid-column
        field="taxation.rate"
        headerName="Rate"
        type="percentColumn"
      ></ag-grid-column>
      <ag-grid-column
        field="taxation.depreciation"
        headerName="Depr"
        type="dollarColumn"
        [cellRendererParams]="assetReportDollarParams"
      ></ag-grid-column>
      <ag-grid-column
        field="taxation.closingCarryingAmount"
        headerName="CWDV"
        type="dollarColumn"
        [cellRendererParams]="assetReportDollarParams"
      ></ag-grid-column>
      <ag-grid-column
        field="taxation.balancingAdjustment"
        headerName="Bal Adj"
        type="dollarColumn"
        [cellRendererParams]="assetReportDollarParams"
      ></ag-grid-column>
    </ag-grid-column>
    <ag-grid-column
      field="accounting"
      headerClass="ag-column-header"
      headerName="Accounting"
    >
      <ag-grid-column
        field="accounting.openingCarryingAmount"
        headerName="OWDV"
        type="dollarColumn"
        [cellRendererParams]="assetReportDollarParams"
      ></ag-grid-column>
      <ag-grid-column
        field="accounting.depreciationMethodDescription"
        headerName="T"
        [width]="60"
      ></ag-grid-column>
      <ag-grid-column
        field="accounting.rate"
        headerName="Rate"
        type="percentColumn"
      ></ag-grid-column>
      <ag-grid-column
        field="accounting.depreciation"
        headerName="Depr"
        type="dollarColumn"
        [cellRendererParams]="assetReportDollarParams"
      ></ag-grid-column>
      <ag-grid-column
        field="accounting.closingCarryingAmount"
        headerName="CWDV"
        type="dollarColumn"
        [cellRendererParams]="assetReportDollarParams"
      ></ag-grid-column>
      <ag-grid-column
        field="accounting.balancingAdjustment"
        headerName="Bal Adj"
        type="dollarColumn"
        [cellRendererParams]="assetReportDollarParams"
      ></ag-grid-column>
    </ag-grid-column>
  </ag-grid-angular>
</div>
