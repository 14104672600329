export const STANDARD_FORMULA = [
    'GrossProfit',
    'OperatingProfit',
    'EBITDA',
    'EBIT',
    'EarningsBeforeTax',
    'Profit',
];

export enum PlaceholderType {
    StandardFormula = 'Standard Formula',
    MasterAccountId = 'MasterAccountId',
    AccountId = 'AccountId'
}

