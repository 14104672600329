import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITransactionsSummary } from '../../../../interfaces';
import { BacoCodedBy } from '../../../../enums';

@Component({
  selector: 'crs-transaction-table-footer',
  templateUrl: './transaction-table-footer.component.html',
  styleUrls: [ './transaction-table-footer.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionTableFooterComponent {
  @Input('transactions-summary') public transactionsSummary?: ITransactionsSummary | null;
  public transactionCodedBy = BacoCodedBy;
}
