import { Directive, ElementRef, Inject, Input, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ElementSizeMultiplier } from 'src/app/accounting/reports/enums';
import { TableRowType } from '../enums';
import { IReportTableConfig } from '../interfaces';
import { REPORT_TABLE_CONFIG } from '../report-table.contants';

@Directive({
  selector: '[crsCellSpan]'
})
export class CellSpanDirective implements OnInit {
  private el: HTMLElement;

  @Input('crsCellSpan') span: number;

  constructor(private element: ElementRef,
              private _render: Renderer2,
              @Inject(REPORT_TABLE_CONFIG) private readonly _config: IReportTableConfig) {
    this.el = element.nativeElement;
  }

  public ngOnInit() {
    if(this.span > 1)
    {
      this._render.setStyle(this.el, 'grid-column-end', 'span '+ this.span);
      this._render.addClass(this.el.parentElement, 'header-column-groups-row' )
    }
  }

}
