import { AssetsContextService } from '../../assets-context.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { tap, catchError, switchMap, finalize } from 'rxjs/operators';

import { ModalService, MessageService } from 'src/app/core';
import { getDefaultGridOptions, renderers } from 'src/app/shared';
import { DepreciationYearService } from '../depreciation-year.service';
import { DepreciationYear } from '../depreciation-year';
import { AssetsContext } from '../../assets-context';
import { DepreciationYearComponent } from '../depreciation-year/depreciation-year.component';

@Component({
  selector: 'crs-depreciation-years',
  templateUrl: './depreciation-years.component.html',
  styleUrls: ['./depreciation-years.component.scss']
})
export class DepreciationYearsComponent implements OnInit, OnDestroy {

  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    depreciationYears: false
  };

  context: AssetsContext;
  gridOptions = getDefaultGridOptions();
  renderers = renderers;

  subscriptions: Subscription[] = [];

  constructor(private depreciationYearService: DepreciationYearService,
    private modalService: ModalService,
    private assetsContextService: AssetsContextService,
    private messageService: MessageService) { }

  ngOnInit() {

    const contextSubscription = this.assetsContextService.contextValid$.pipe(
      tap(c => this.context = c)
    ).subscribe();

    this.subscriptions.push(contextSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addDepreciationYear() {
    this.modalService.openModal(DepreciationYearComponent, 'add', {priorYear: this.getLastYear()});
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  selectDepreciationYear(param) {
    this.modalService.openModal(DepreciationYearComponent, param.data.id, {deletable: this.getLastYear().id === param.data.id});
  }

  private getLastYear() {
    return this.context.years[this.context.years.length - 1];
  }
}
