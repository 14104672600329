<div class="modal-header">
  <h4 class="modal-title">
    Add Owner
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('cancelled')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

  <div class="modal-body">

    <div class="form-group required">
      <label class="control-label">Email</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">&#64;</span>
          </div>
          <input type="text" class="form-control" formControlName="email" />
        </div>
    </div>

    <div class="form-group required">
      <label class="control-label">First Name</label>
      <input type="text" class="form-control" formControlName="firstName" />
    </div>

    <div class="form-group required">
      <label class="control-label">Last Name</label>
      <input type="text" class="form-control" formControlName="lastName" />
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary"  [promiseBtn]="busy.submit">Add</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancelled')">Cancel</button>
  </div>

</form>
