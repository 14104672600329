import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

export class Address {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  state: string;
  suburb: string;
  postCode: string;
}

export function buildAddressForm(formBuilder: UntypedFormBuilder) {
  return formBuilder.group({
    addressLine1: [null],
    addressLine2: [null],
    addressLine3: [null],
    state: [null],
    suburb: [null],
    postCode: [null]
  });
}