import { ImportFrequency } from './../../datasets/dataset';
import { ApiType } from '../enums/apiType';
export class ApiFeatures {
  apiType: ApiType;
  canExportJournals: boolean;
  userLevelApi: boolean;
  requiresTenantId: boolean;
  hasDivisions: boolean;
  implemented: boolean;
  supportedFrequencies: ImportFrequency[];
  isDisabled: boolean;
  message: string;
  constructor(data: any) {
    Object.assign(this, data);
  }
}
