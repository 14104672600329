import { DepreciationYearService } from '../../../../assets/depreciation-years/depreciation-year.service';
import { DepreciationType } from '../../../../assets/assets/depreciation-type';
import { DepreciationPool } from '../../../../assets/assets/depreciation-pool';
import {
  DepreciationReportType,
  DepreciationReportTypeInReport,
  monthlyDepreciationPeriods,
} from '../../../../assets/asset-reports/depreciation-report-type';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { ActiveFileService } from 'src/app/accounting';
import { MessageService } from 'src/app/core';
import {
  tap,
  switchMap,
  filter,
  catchError,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { Subscription, Subject, EMPTY } from 'rxjs';
import { File } from '../../../../index';
import { Entity } from 'src/app/firm';
import { DepreciationYear } from 'src/app/accounting/assets/depreciation-years/depreciation-year';

@Component({
  selector: 'crs-report-template-depreciation-page-detail',
  templateUrl: './report-template-depreciation-page-detail.component.html',
  styleUrls: ['./report-template-depreciation-page-detail.component.scss'],
})
export class ReportTemplateDepreciationPageDetailComponent
  implements OnInit, OnDestroy
{
  @Input('index') i: number;
  @Input() pageGroup: UntypedFormGroup;
  @Input() templateGroup: UntypedFormGroup;
  @Input() isAdd: boolean;
  private _detail: UntypedFormGroup;
  @Input() set detail(value: UntypedFormGroup) {
    this._detail = value;
    this.initialiseForm$.next();
  }
  get detail() {
    return this._detail;
  }

  depreciationReportTypes = DepreciationReportTypeInReport;
  depreciationPools = DepreciationPool;
  depreciationTypes = DepreciationType;

  subscriptions: Subscription[] = [];
  formSubscriptions: Subscription[] = [];

  file: File;
  entities: Entity[];
  entityId: string;
  years: DepreciationYear[];

  initialiseForm$ = new Subject();
  getDepreciationYears$ = new Subject();

  monthlyDepreciationPeriods = monthlyDepreciationPeriods;
  isMonthlyDepreciationEnabled = false;

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _activeFileService: ActiveFileService,
    private readonly _messageService: MessageService,
    private readonly _depreciationYearService: DepreciationYearService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.getDepreciationYears$
        .pipe(
          filter(() => !!this.file && !!this.detail),
          map(() => {
            return {
              fileId: this.file.id,
              entityId: this.detail.get('entityId').value,
            };
          }),
          distinctUntilChanged(),
          switchMap((i) =>
            this.getDepreciationYearsObservable(i.fileId, i.entityId)
          )
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.initialiseForm$
        .pipe(
          filter(() => !!this.file && !!this.detail),
          tap(() => this.initialiseForm()),
          tap(() => this.getDepreciationYears$.next())
        )
        .subscribe()
    );

    this.subscriptions.push(
      this._activeFileService.stream
        .pipe(
          tap((f) => {
            this.file = f;
            this.entities = f.entity ? [f.entity] : f.entities;
            this.defaultEntity();
            this.initialiseForm$.next();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.cleanup();
  }

  private initialiseForm() {
    this.cleanup();
    this.defaultEntity();
    this.formSubscriptions.push(
      this.detail.controls['reportType'].valueChanges
        .pipe(
          tap((t) => {
            this.detail.controls['pool'].setValidators(
              t === DepreciationReportType.Pooling ? Validators.required : null
            );
            this.detail.controls['depreciationType'].setValidators(
              t === DepreciationReportType.Pooling ? Validators.required : null
            );
          })
        )
        .subscribe()
    );
    this.formSubscriptions.push(
      this.detail.controls['entityId'].valueChanges
        .pipe(
          tap((i) => (this.entityId = i)),
          tap(() => this.getDepreciationYears$.next())
        )
        .subscribe()
    );
  }

  private getDepreciationYearsObservable(fileId: string, entityId: string) {
    return this._depreciationYearService.getAll(fileId, entityId).pipe(
      tap((y) => (this.years = y)),
      tap((y) => {
        const year = this.detail.get('year');
        if ((!year.value || year.value === '0') && y && y.length) {
          year.setValue(y[0].year);
          this.isMonthlyDepreciationEnabled =
            y[0].useMonthlyBusinessDepreciation;
          this.updateDepreciationMonthId();
        }
      }),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      })
    );
  }

  private updateDepreciationMonthId() {
    if (!this.isMonthlyDepreciationEnabled) {
      this.detail.get('toPeriod').setValue(null);
    } else {
      this.detail.get('toPeriod').setValue('12');
    }
  }

  private defaultEntity() {
    if (!this.detail || !this.file) return;
    let entityId = this.detail.get('entityId').value;
    if (!entityId || entityId === '00000000-0000-0000-0000-000000000000') {
      entityId = this.file.entity
        ? this.file.entity.id
        : this.file.entities[0].id;
    }
    this.entityId = entityId;
    this.detail.get('entityId').setValue(entityId);
  }

  private cleanup() {
    this.formSubscriptions.forEach((s) => s.unsubscribe());
    this.formSubscriptions = [];
  }

  showError(err) {
    this._messageService.error(err);
  }

  onSelectYearChange(event: any): void {
    this.isMonthlyDepreciationEnabled = event?.useMonthlyBusinessDepreciation;
    this.updateDepreciationMonthId();
  }
}
