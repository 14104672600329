<div class="row d-flex justify-content-center align-items-center" >
      Thank you for completing the Bank connection, now you can close the browser.
</div>
<br/>
<div class="row d-flex justify-content-center align-items-center" *ngIf="isAkahuProvider">
      <p>You can review or revoke your consent at any time by visiting <a [href]="akahuRevokeLinkUrl">{{ akahuRevokeLinkText }}</a> using your email address to login.</p>
</div>

<div style="text-align: center;">
      <div style="height: 20px;"></div>
      <img class="navbar-brand-img" src="./assets/images/AccessBankFeedsLogo.png" alt="Access Bank Feeds Logo" style="display: block; margin: 0 auto;">
</div>
