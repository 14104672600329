import { Subscription } from 'rxjs';
import { LedgerSide, ledgerSides } from './../../../../ledger/accounts/ledgerSide';
import { ReportContentInfo } from './../../report-content/report-content-info';
import { Component, Input, OnDestroy } from '@angular/core';
import { PlaceholderType } from './report-placeholder';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReportMetaData } from '../../report-output';

@Component({
  selector: 'crs-report-placeholder',
  templateUrl: './report-placeholder.component.html'
})
export class ReportPlaceholderComponent implements OnDestroy {

  form: UntypedFormGroup;
  component = this;

  @Input() index;
  @Input() element;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;

  isEditing = false;
  ledgerSides = ledgerSides;
  subscriptions: Subscription[] = [];

  constructor(readonly formBuilder: UntypedFormBuilder) {}

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  onEdit() {
    if (this.element.placeholderType !== PlaceholderType.AccountNote) return;

    this.clearSubscriptions();

    this.form = this.formBuilder.group({
      identifier: [null],
      title: [null],
      addGrandTotal: [false],
      grandTotalLedgerSide: [null]
    });

    this.subscriptions.push(
    this.form.get('addGrandTotal').valueChanges.subscribe(v => {
      this.form.get('grandTotalLedgerSide').setValidators(v ? Validators.required : null);
    }));

    this.form.patchValue(this.element);
    this.isEditing = true;
  }

  onSubmit = () => {
    this.form.markAsTouched();
    if (!this.form.valid) return false;

    Object.assign(this.element, this.form.value);
    return true;
  }

  onCancel() {
    this.isEditing = false;
    this.form = null;
  }

  clearSubscriptions() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

}
