import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, EMPTY } from 'rxjs';
import { tap, exhaustMap, catchError, finalize } from 'rxjs/operators';

import { DatabaseService } from './../database.service';
import { MessageService } from 'src/app/core';
import { getDefaultGridOptions } from 'src/app/shared';
import { DatabaseModel } from '../database';

@Component({
  selector: 'crs-database',
  templateUrl: './database.component.html'
})
export class DatabaseComponent implements OnInit {

  id: string;
  isAdd: boolean;
  busy = {
    load: null,
    submit: null
  };
  error: string = null;

  gridOptions = getDefaultGridOptions();

  interactionStream = new Subject();

  form = this._formBuilder.group({
    id: [null],
    name: [null, [Validators.required, Validators.maxLength(128)]],
    databaseName: [null, [Validators.required, Validators.maxLength(128)]],
    databaseServer: [null, Validators.required],
    multiTenant: [true, Validators.required]
  });

  constructor(private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _databaseService: DatabaseService,
    private readonly _messageService: MessageService) {

    }

  ngOnInit() {

    this.gridOptions.getRowNodeId = data => data.id;

    this._route.params.subscribe(() => {
      this.id = this._route.snapshot.paramMap.get('id');
      this.isAdd = this.id === 'add';
      this.loadDatabase();
    });

    this.configureInteraction();
  }

  submit() {
    this.interactionStream.next();
  }

  private configureInteraction() {
    this.interactionStream
      .pipe(
        tap(() => this.error = null),
        exhaustMap(() => this.handleInteraction())
      )
      .subscribe(() => {
        this.close();
      });
  }

  private handleInteraction(): Observable<any> {

    let observable: Observable<any>;
    const loadingStream = new Subject();

    const model = this.form.value as DatabaseModel;
    if (this.isAdd) {
      observable = this._databaseService.post(model);
    } else {
      model.id = parseInt(this.id, 10);
      observable = this._databaseService.put(model);
    }

    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete()));

    return observable;
  }

  private loadDatabase() {
        this.busy.load = this.isAdd ? EMPTY :
          this._databaseService.get(parseInt(this.id, 10)).pipe(
            tap(database => {
              this.form.patchValue(database);
            })
          ).subscribe();
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
    this._messageService.error(error, true);
  }

}
