import { DepreciationTypeOption } from './../../assets/depreciation-type';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, Subscription, Observable, EMPTY } from 'rxjs';
import { tap, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AssetPoolingService } from './../asset-pooling.service';
import { AssetsContextService } from './../../assets-context.service';
import { DepreciationPool } from './../../assets/depreciation-pool';
import { Asset } from '../../assets/asset';
import { AssetsContext } from '../../assets-context';

@Component({
  selector: 'crs-allocate-assets-to-pool',
  templateUrl: './allocate-assets-to-pool.component.html',
  styleUrls: ['./allocate-assets-to-pool.component.scss']
})
export class AllocateAssetsToPoolComponent implements OnInit, OnDestroy {

  @Input() params: Asset[];

  error = null;
  busy = {
    submit: null
  };
  assetsContext: AssetsContext;
  depreciationPools = DepreciationPool;

  form = this._formBuilder.group({
    depreciationPool: [null, Validators.required],
    taxation: this._formBuilder.group({
      addToPool: [true]
    }),
    accounting: this._formBuilder.group({
      addToPool: [true]
    }),
  });

  component = this;
  toggles = { depreciationPool: false};
  taxation = this.form.get('taxation') as UntypedFormGroup;
  accounting = this.form.get('accounting') as UntypedFormGroup;

  submitButtonStream = new Subject();
  subscriptions: Subscription[] = [];

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _activeModal: NgbActiveModal,
    private readonly _assetPoolingService: AssetPoolingService,
    private readonly _assetsContextService: AssetsContextService) { }

  ngOnInit() {

    this.subscriptions.push(
      this._assetsContextService.context$.pipe(
        tap(c => {
          this.assetsContext = c;
        }),
        catchError(e => {
          this.error = e;
          return EMPTY;
        }))
    .subscribe());

    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => this.error = null),
          exhaustMap(() => this.executeAllocationObservable())
        )
        .subscribe(client => {
          this._activeModal.close();
        }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  private executeAllocationObservable(): Observable<any> {
    const depreciationPool = this.form.value.depreciationPool as DepreciationPool;
    const addForTaxation = this.form.value.taxation.addToPool as boolean;
    const addForAccounting = this.form.value.accounting.addToPool as boolean;

    const type = addForTaxation && addForAccounting ?
      DepreciationTypeOption.All :
      addForTaxation ? DepreciationTypeOption.Taxation : DepreciationTypeOption.Accounting;

    const loadingStream = new Subject();
    this.busy.submit = loadingStream.subscribe();

    return this._assetPoolingService.allocateToPool(depreciationPool, type, this.params.map(p => p.id), this.assetsContext.year.year)
    .pipe(
      catchError(err => {
      this.error = err;
      return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  submit() {
    this.submitButtonStream.next();
  }

  cancel() {
    this._activeModal.dismiss();
  }
}
