import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User, UserModel, UserService, SecurityRole } from '../';

@Component({
  selector: 'crs-user-invite-accept',
  templateUrl: './user-invite-accept.component.html'
})
export class UserInviteAcceptComponent implements OnInit {

  token: string;

  isSuccess: true;
  firmName: null;
  error: null;

  constructor(private readonly _route: ActivatedRoute,
    private readonly _userService: UserService) {

  }

  ngOnInit() {

    this._route.queryParams.subscribe(params => {
      this.token = params['inviteToken'];

      this._userService.acceptInvite(this.token).subscribe(firm => {
        if (firm.name) this.firmName = firm.name;
        this.isSuccess = true;
      }, error => {
        this.error = error;
      });

    });



  }



}
