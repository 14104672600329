<p *ngIf="!auditContent || !auditContent.length">
  There are no assets that belong to this label.
</p>

<div
  style="overflow: hidden; flex-grow: 1"
  *ngIf="auditContent && auditContent.length"
>
  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    style="width: 100%"
    [gridOptions]="gridOptions"
    [rowData]="auditContent"
  />
</div>
