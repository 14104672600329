<label
  class="checkbox-container"
  [class.disabled]="isDisabled"
  [class.inverted]="isInverted"
>
  <input
    class="checkbox-input"
    type="checkbox"
    [disabled]="isDisabled"
    [ngModel]="isChecked"
    (ngModelChange)="onModelChange($event)"
  />
  <div class="checkbox-border">
    <div class="checkbox-filling" *ngIf="isChecked">
      <i aria-hidden="true" class="fa fa-check"></i>
    </div>
  </div>
  <ng-content> </ng-content>
</label>
