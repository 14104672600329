import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ApiService } from 'src/app/core';
import { BacoBankConnectionDto, BacoInvitationModel, BacoManualConnectionCreateRequest, TimeZoneResponse } from '../../interfaces';
import { catchError, publishReplay, refCount } from 'rxjs/operators';
import { DateHelpers } from 'src/app/shared/utilities/date-helpers';

@Injectable({
  providedIn: 'root'
})
export class BankConnectionClient {

  private readonly base: string = 'api/baco/bank-connection';
  private getTimeZonesCache$: Observable<TimeZoneResponse>;

  constructor(private readonly _apiService: ApiService) {
  }

  public getTimeZones(): Observable<TimeZoneResponse> {
    if (this.getTimeZonesCache$ == null)
      this.getTimeZonesCache$ = this._apiService.get<TimeZoneResponse>(`${this.base}/time-zones`)
    .pipe(
      catchError(error => {
        this.getTimeZonesCache$ = null;
        return throwError(error);
      }),
      publishReplay(1),
      refCount()
    );
    return this.getTimeZonesCache$;
  }

  public sendNewBankConnectionInvitation(feedId: string, model: BacoInvitationModel) {
    const body = {
      ...model,
      commencementLocalDate: DateHelpers.convertDateToJsonString(model.commencementLocalDate)
    };
    let url = `${this.base}/${feedId}/bank-connection-invite`;
    return this._apiService.post<string>(url, body);
  }

  public checkAbnExistOfTrustedAdvisor(trustedAdvisorId: string) {
    let url = `${ this.base }/trusted-advisor/${trustedAdvisorId}/check-abn`;
    return this._apiService.get<any>(url);
  }

  public createManualBankConnection(feedId: string, model: BacoManualConnectionCreateRequest) {
    let url = `${this.base}/${feedId}/manual-bank-connection`;
    return this._apiService.post<BacoBankConnectionDto>(url, model);
  }

  public getAcsissSettingsStatus() {
    const url = `${this.base}/acsiss-settings-status`;
    return this._apiService.get<boolean>(url);
  }

  public inviteTrustedAdviser(email: string) {
    let url = `${this.base}/invite-trusted-adviser`;
    url = this._apiService.addQuery(url, 'email', email);

    return this._apiService.get<string>(url);
  }

  public ReInviteClientByBankConnection(bankConnectionId: string, isDisconnected: boolean): Observable<string> {
    let url = `${this.base}/${bankConnectionId}/reinvite-bank-connection`;
    url = this._apiService.addQuery(url, 'isDisconnected', isDisconnected);
    return this._apiService.get<string>(url, {responseType: 'text'});
  }

  public RevokeBankConnection(bankConnectionId: string): Observable<boolean> {
    return this._apiService.get<boolean>(`${this.base}/${bankConnectionId}/revoke-bank-connection`);
  }
}
