import { AssetReportDepreciationRecord } from '../asset-report-depreciation-record';
import { Asset } from '../../assets/asset';

export class AssetReportTaxAudit {
  asset: Asset;
  value: number;
}

export class AssetReportTaxReturnSbe {

  deductionsForCertainAssets: number;
  deductionsForCertainAssetsAudit: AssetReportTaxAudit[];
  deductionForGeneralPool: number;
  deductionForGeneralPoolAudit: AssetReportTaxAudit[];

  constructor(data) {
    Object.assign(this, data);
  }

}

export class AssetReportTaxReturnNonSbe {

  intangibleAssetsFirstDeducted: number;
  intangibleAssetsFirstDeductedAudit: AssetReportTaxAudit[];
  otherAssetsFirstDeducted: number;
  otherAssetsFirstDeductedAudit: AssetReportTaxAudit[];
  haveYouSelfAssessedAny: boolean;
  totalAdjustableValues: number;
  totalAdjustableValuesAudit: AssetReportTaxAudit[];
  intangibleAssessableBalancingAdjustments: number;
  intangibleAssessableBalancingAdjustmentsAudit: AssetReportTaxAudit[];
  intangibleDeductibleBalancingAdjustments: number;
  intangibleDeductibleBalancingAdjustmentsAudit: AssetReportTaxAudit[];
  intangibleTerminationValue: number;
  intangibleTerminationValueAudit: AssetReportTaxAudit[];
  otherTerminationValue: number;
  otherTerminationValueAudit: AssetReportTaxAudit[];

  constructor(data) {
    Object.assign(this, data);
  }

}
