import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, EMPTY, Subscription } from 'rxjs';
import { tap, exhaustMap, finalize, catchError, map } from 'rxjs/operators';

import { PolicyLevel, Policy, PolicyVariant } from '../';
import { PolicyVariantService } from '../policy-variant.service';
import { MessageService, ModalService } from '../../../../../core';
import { entityTypesWithoutUnspecified } from '../../../../../firm';

enum Mode {
  Readonly = 0,
  Add = 1,
  AddInherit = 2,
  Edit = 3
}

@Component({
  selector: 'crs-policy-variant',
  templateUrl: './policy-variant.component.html'
})
export class PolicyVariantComponent implements OnInit, OnDestroy {

  @Output() removed = new EventEmitter<number>();

  private _variant: PolicyVariant;

  @Input() index: number;
  @Input() policy: Policy;

  @Input() set variant(value: PolicyVariant) {
    if (!this._variant || this._variant.id !== value.id) {
      this._variant = value;
      this.isAdd = value.id === 'add';
      this.loadedFull = this.isAdd;
      this.startOpen = this.isAdd;
      this.collapsed = !this.isAdd;
      this.updateMode();
    }
  }

  get variant(): PolicyVariant {
    return this._variant;
  }

  private _level: PolicyLevel;
  @Input()
  set level(value: PolicyLevel) {
    this._level = value;
    this.updateMode();
  }
  get level(): PolicyLevel {
    return this._level;
  }

  busy = {
    load: null,
    submit: null,
    delete: null
  };

  isAdd: boolean;
  loadedFull: boolean;
  collapsed = true;
  startOpen = false;
  mode: Mode;
  allowedToCustomise = true;
  entityTypes = entityTypesWithoutUnspecified;
  hideMasterContent: boolean;

  subscriptions: Subscription[] = [];

  levels = PolicyLevel;


  saveStream = new Subject();

  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(128)]],
    description: [null, [Validators.maxLength(1024)]],
    applicationDate: [null],
    earliestApplicationDate: [null],
    expiryDate: [null],
    reportingSuites: [],
    entityTypes: [],
  });

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _policyVariantService: PolicyVariantService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService) { }

  ngOnInit() {
    this.configureInteraction();
    this.updateMode();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  loadVariant() {
    if (this.loadedFull) return EMPTY;
    this.busy.load = this._policyVariantService.get(this.variant.id).subscribe(variant => {
      this.form.patchValue(variant);
      Object.assign(this.variant, variant);
      this.loadedFull = true;
      this.updateMode();
    }, err => this.showError(err));

    return this.busy.load;
  }

  private updateMode() {
    if (!this.policy || !this.variant) return;
    if (this.isAdd) this.mode = Mode.Add;
    else if (this.policy.level < this.level) this.mode = Mode.Readonly;
    else if (this.variant.level < this.level && this.mode !== Mode.AddInherit) this.mode = Mode.Readonly;
    else this.mode = Mode.Edit;

    this.allowedToCustomise = this.policy.level >= this.level;
  }

  showError(err) {
    this._messageService.error(err);
  }

  submit() {
    this.saveStream.next();
  }

  customise() {

    this.mode = Mode.AddInherit;
  }

  delete() {
    this._modalService.confirmation(
      'Are you sure you want to delete this Policy Variant? This action cannot be undone.',
      () => {
        if (this.variant.masterPolicyVariantId) {
          this.variant.id = this.variant.masterPolicyVariantId;
          this.reset();
        } else {
          this.busy.delete = this._policyVariantService.delete(this.variant.id)
            .pipe(tap(() => this.removed.emit(this.index))).subscribe(() => true, err => this.showError(err));
        }
      }, true);
  }

  cancel() {
    if (this.mode === Mode.Add) this.removed.emit(this.index);
    else this.reset();
  }

  confirmMasterChangesChecked() {
    const originalValue = this.variant.masterChanged;
    this.variant.masterChanged = false;
    this._policyVariantService.confirmMasterChangesChecked(this.variant.id).pipe(catchError(err => {
      this.showError(err);
      this.variant.masterChanged = originalValue;
      return EMPTY;
    })).subscribe();
  }

  private reset() {
    this.mode = null;
    this.loadedFull = false;
    return this.loadVariant();
  }

  private configureInteraction() {
    this.subscriptions.push(
    this.saveStream
      .pipe(
        exhaustMap(() => this.submitObservable())
      )
      .subscribe(() => {
        this._messageService.success('Successfully saved the ' + this.form.get('name').value + ' Policy Variant');
        this.reset();
      }));
  }

  private submitObservable() {
    let observable: Observable<any>;
    const loadingStream = new Subject();

    const model = this.form.value as PolicyVariant;
    model.content = this.variant.content;

    if (this.mode === Mode.Add) {
      model.policyId = this.policy.id;
      model.fileId = this.policy.fileId;
      model.reportTemplateId = this.policy.reportTemplateId;
      observable = this._policyVariantService.post(model).pipe(tap(id => this.variant.id = id));
    } else if (this.mode === Mode.AddInherit) {
      model.policyId = this.policy.id;
      model.masterPolicyVariantId = this.variant.id;
      model.fileId = this.policy.fileId;
      model.reportTemplateId = this.policy.reportTemplateId;
      observable = this._policyVariantService.createInherited(model).pipe(tap(id => this.variant.id = id));
    } else if (this.mode === Mode.Edit) {
      model.id = this.variant.id;
      observable = this._policyVariantService.put(model).pipe(map(() => model.id));
    }

    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(
      tap(id => {
        const value = this.form.value as PolicyVariant;
        value.id = id;
        this._variant = value;
        this.isAdd = false;
        this.loadedFull = true;
        this.updateMode();
        this.form.reset();
      }),
      catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete()));

    return observable;
  }

}
