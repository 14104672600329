import { CountryType } from '../components/feed-detail/bank-connection/add-new-bank-connection/add-new-bank-connection.enum';

export interface BacoInvitationModel {
  clientName: string;
  email: string;
  commencementLocalDate?: Date | string;
  country: CountryType;
  timeZone?: string | null;
  trustedAdvisorId: string | null;
}

export interface BacoManualConnectionCreateRequest {
  accountName: string;
  country: CountryType;
  timeZone?: string | null;
}

export class UpdateSchedulingSettingsModel {
  schedulingSettingsInterval: string;
  intervalAmount: number;
  commencementLocalDate?: Date | string;

  constructor(data) {
    this.schedulingSettingsInterval = data.schedulingSettingsInterval;
    this.intervalAmount = data.intervalAmount;
    this.commencementLocalDate = data.commencementLocalDate;
  }
}
