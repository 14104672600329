<div
  class="workpaper-status-dropdown"
  [ngClass]="[
    variant,
    isClearable && selectedValue !== null ? 'has-value' : ''
  ]"
>
  <ng-select
    bindLabel="label"
    bindValue="value"
    placeholder="Status"
    [appendTo]="appendTo"
    [clearable]="isClearable"
    [formControl]="statusControl"
    [items]="statusOptions"
    [ngClass]="[getStatusClass(selectedValue) || '']"
    [searchable]="false"
  >
    <ng-template let-index="index" let-item="item" ng-label-tmp>
      <div class="status-badge">
        <p [ngClass]="item.class">{{ item.label }}</p>
      </div>
    </ng-template>
    <ng-template let-index="index" let-item="item" ng-option-tmp>
      <div
        class="status-dropdown-option"
        [class.disabled]="removeNoWorkpaperOption && index === 0"
        [ngClass]="item.class"
      >
        <p [ngClass]="item.class">
          {{ item.label }}
        </p>
      </div>
    </ng-template>
  </ng-select>
  <i class="fa fa-chevron-down"></i>
</div>
