import { GridOptions } from 'ag-grid-community';
import { AssetsContextService } from '../../assets-context.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalService, MessageService } from 'src/app/core';
import { getDefaultGridOptions, renderers } from 'src/app/shared';
import { AssetsContext } from '../../assets-context';
import { DepreciationYearComponent } from '../depreciation-year/depreciation-year.component';

@Component({
  selector: 'crs-depreciation-years',
  templateUrl: './depreciation-years.component.html',
  styleUrls: ['./depreciation-years.component.scss'],
})
export class DepreciationYearsComponent implements OnInit, OnDestroy {
  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    depreciationYears: false,
  };

  context: AssetsContext;
  gridOptions: GridOptions;
  renderers = renderers;

  subscriptions: Subscription[] = [];

  constructor(
    private modalService: ModalService,
    private assetsContextService: AssetsContextService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      onRowClicked: (event) => this.selectDepreciationYear(event),
      rowClass: 'selectable-row',
      columnDefs: [
        {
          field: 'year',
          headerName: 'Year',
        },
        {
          field: 'startDate',
          headerName: 'Start Date',
          type: 'dateColumn',
        },
        {
          field: 'endDate',
          headerName: 'End Date',
          type: 'dateColumn',
        },
        {
          field: 'taxYear',
          headerName: 'Tax Year',
        },
        {
          field: 'useSmallBusinessDepreciation',
          headerName: 'SBR',
          type: 'booleanColumn',
          maxWidth: 120,
          minWidth: 120,
        },
        {
          field: 'useMonthlyBusinessDepreciation',
          headerName: 'Monthly Depreciation',
          type: 'booleanColumn',
          maxWidth: 180,
          minWidth: 120,
        },
        {
          field: 'isLocked',
          headerName: 'Locked',
          type: 'booleanColumn',
          cellRendererParams: { iconClass: 'fas fa-lock' },
          maxWidth: 120,
          minWidth: 120,
        },
      ],
    };

    const contextSubscription = this.assetsContextService.contextValid$
      .pipe(tap((c) => (this.context = c)))
      .subscribe();

    this.subscriptions.push(contextSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  addDepreciationYear() {
    this.modalService.openModal(DepreciationYearComponent, 'add', {
      priorYear: this.getLastYear(),
    });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  selectDepreciationYear(param) {
    this.modalService.openModal(DepreciationYearComponent, param.data.id, {
      deletable: this.getLastYear().id === param.data.id,
    });
  }

  private getLastYear() {
    return this.context.years[this.context.years.length - 1];
  }
}
