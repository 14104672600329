<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item">
      <a [routerLink]="['../']">Page Templates</a>
    </li>
    <li aria-current="page" class="breadcrumb-item active">
      {{ isAdd ? 'New' : '' }} Page Template
    </li>
  </ol>
</nav>

<form class="pad-x" novalidate [formGroup]="form" [ngBusy]="busy.load">
  <h2 class="form-group-title">Page Template</h2>

  <hr />

  <div class="row">
    <div class="col-md-8 col-xl-6">
      <div class="form-group required">
        <label class="control-label">Name</label>
        <input
          class="form-control"
          formControlName="name"
          placeholder="Name..."
          type="text"
        />
      </div>

      <div class="form-group required">
        <label class="control-label">Title</label>
        <input
          class="form-control"
          formControlName="title"
          placeholder="Title..."
          type="text"
        />
      </div>

      <div class="form-group">
        <label class="control-label">Category</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="category"
          [clearable]="false"
          [items]="categories | enumToArray"
        ></ng-select>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="canHaveMultiple"
            type="checkbox"
          />
          <label class="form-check-label"
            >Allow multiple in a single report?</label
          >
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="excludeFromContents"
            type="checkbox"
          />
          <label class="form-check-label">Exclude from contents page?</label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="inactive"
            type="checkbox"
          />
          <label class="form-check-label">Inactive</label>
        </div>
      </div>

      <div class="spacer-md"></div>
    </div>
  </div>

  <h2 class="form-group-title">Content</h2>

  <hr />

  <div class="form-group">
    <label class="control-label">Header</label>
    <ng-select
      bindLabel="name"
      bindValue="id"
      formControlName="headerId"
      [clearable]="false"
      [items]="headers"
    >
    </ng-select>
  </div>

  <div class="form-group">
    <label class="control-label">Content</label>

    <div class="card">
      <div class="card-body">
        <div class="content-page-wrapper">
          <crs-report-content [content]="content"></crs-report-content>
        </div>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <div class="form-group">
    <label class="control-label">Footer</label>
    <ng-select
      bindLabel="name"
      bindValue="value"
      formControlName="footerType"
      [clearable]="false"
      [items]="footerTypes | enumToArray"
    ></ng-select>
  </div>

  <!-- Custom Footer -->
  <div
    @fadeInOut
    class="card bg-light"
    *ngIf="form.controls['footerType'].value == 2"
  >
    <div class="card-header">Custom Footer</div>
    <div class="card-body">
      <div class="form-group">
        <label class="control-label">Use Template</label>
        <ng-select
          bindLabel="name"
          bindValue="id"
          formControlName="customFooterId"
          [items]="footers"
        ></ng-select>
      </div>

      <div class="form-group">
        <label class="control-label">or; Use Custom Text</label>
        <textarea
          class="form-control"
          formControlName="customFooter"
          rows="3"
          type="text"
        ></textarea>
      </div>
    </div>
  </div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="spacer-lg"></div>

  <div class="btn-group float-right">
    <button
      class="btn btn-outline-danger"
      type="submit"
      *ngIf="!isAdd"
      [promiseBtn]="busy.submit"
      (click)="delete()"
    >
      Delete
    </button>
  </div>

  <div class="btn-menu">
    <div class="btn-group" *ngIf="isAdd; else isEdit">
      <button
        class="btn btn-primary"
        type="submit"
        [promiseBtn]="busy.submit"
        (click)="save(true)"
      >
        Create Page Template
      </button>
    </div>
    <ng-template #isEdit>
      <div class="btn-group" *ngIf="!isMaster; else saveAsOnMasterTemplate">
        <button
          class="btn btn-primary"
          type="submit"
          [promiseBtn]="busy.submit"
          (click)="save(true)"
        >
          Save and Close
        </button>
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="btn btn-primary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          type="button"
        >
          <span class="sr-only">Save options</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" type="submit" (click)="save(false)">
            Save Only
          </button>
          <button class="dropdown-item" type="submit" (click)="saveAs()">
            Save As...
          </button>
        </div>
      </div>

      <ng-template #saveAsOnMasterTemplate>
        <button
          class="btn btn-primary"
          type="submit"
          [promiseBtn]="busy.submit"
          (click)="saveAs(true)"
        >
          Save As
        </button>
      </ng-template>
    </ng-template>

    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>

  <div class="spacer-md"></div>
</form>
