<div [formGroup]="detail">
  <!-- Reporting Suite -->
  <div class="form-group">
    <label class="control-label">Reporting Suite</label>
    <crs-reporting-suite-select
      formControlName="reportingSuite"
      [clearable]="false"
    ></crs-reporting-suite-select>
  </div>

  <!-- Auto mode -->
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        formControlName="auto"
        readonly
        type="checkbox"
        [id]="'auto' + i"
      />
      <label class="form-check-label" [for]="'auto' + i"
        >Automatically Select Accounting Policies</label
      >
    </div>
  </div>

  <!-- Accounting Policies -->
  <div
    *ngIf="detail && !detail.controls['auto'].value"
    [ngBusy]="busy.selectorsLoad"
  >
    <div class="spacer"></div>
    <label class="control-label w-100"> Accounting Policies </label>
    <div class="card" formArrayName="policySelectorsFull">
      <div class="card-header">
        <div class="row">
          <div class="col-6">Policy</div>
          <div class="col-4">Variant</div>
          <div class="col-2">Include?</div>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          *ngFor="let group of selectorsArray.controls; let i = index"
          [class.list-group-item-light]="group.get('selectionMode').value == 2"
          [class.list-group-item-success]="
            group.get('selectionMode').value == 1
          "
          [formGroupName]="i"
        >
          <div class="row">
            <div class="col-6">
              {{ policySelectorsList[i].name }}
            </div>
            <div class="col-4">
              <input
                class="form-control-plaintext"
                *ngIf="policySelectorsList[i].policyVariants?.length <= 1"
                [value]="policySelectorsList[i].defaultPolicyVariant.name"
              />
              <ng-select
                bindLabel="name"
                bindValue="id"
                formControlName="selectedPolicyVariantId"
                *ngIf="policySelectorsList[i].policyVariants?.length > 1"
                [items]="policySelectorsList[i].policyVariants"
                [placeholder]="policySelectorsList[i].defaultPolicyVariant.name"
              ></ng-select>
            </div>
            <div class="col-2">
              <ng-select
                bindLabel="name"
                bindValue="value"
                formControlName="selectionMode"
                [clearable]="false"
                [items]="policySelectionModes | enumToArray"
              ></ng-select>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <!-- Disclosures -->
  <div>
    <crs-busy *ngIf="disclosuresLoading"></crs-busy>

    <div class="spacer"></div>
    <label class="control-label w-100"> Disclosures </label>
    <div class="card" formArrayName="disclosuresFull">
      <div class="card-header">
        <div class="row">
          <div class="col-4">Policy</div>
          <div class="col-4">Variant</div>
          <div class="col-2">Include?</div>
          <div class="col-2 text-center">Customise</div>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          *ngFor="let group of disclosuresArray.controls; let i = index"
          [class.list-group-item-light]="group.get('selectionMode').value == 2"
          [class.list-group-item-success]="
            group.get('selectionMode').value == 1
          "
          [formGroupName]="i"
        >
          <div class="row">
            <div class="col-4">
              <input
                class="form-control-plaintext"
                [value]="disclosuresList[i].name"
              />
            </div>
            <div class="col-4">
              <input
                class="form-control-plaintext"
                *ngIf="disclosuresList[i].disclosureVariants?.length <= 1"
                [value]="disclosuresList[i].defaultDisclosureVariant.name"
              />
              <ng-select
                bindLabel="name"
                bindValue="id"
                formControlName="selectedPolicyVariantId"
                *ngIf="disclosuresList[i].disclosureVariants?.length > 1"
                [items]="disclosuresList[i].disclosureVariants"
                [placeholder]="disclosuresList[i].defaultDisclosureVariant.name"
              ></ng-select>
            </div>
            <div class="col-2">
              <ng-select
                bindLabel="name"
                bindValue="value"
                formControlName="selectionMode"
                [clearable]="false"
                [items]="disclosureSelectionModes | enumToArray"
              ></ng-select>
            </div>
            <div class="col-1 text-center">
              <input
                class="form-check-input"
                formControlName="customise"
                type="checkbox"
              />
            </div>
            <div class="col-1 text-center">
              <div
                class="d-inline-block ms-2"
                *ngIf="group.get('customise').value"
              >
                <a
                  role="button"
                  [routerLink]="[]"
                  (click)="
                    editDisclosureContent(disclosuresList[i], group.value)
                  "
                >
                  <i class="fas fa-edit"></i>
                  Edit Content
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
