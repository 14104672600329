import { AccountsViewSourceAccount } from './../../../../accounting/ledger/accounts/accounts/accounts-view-source-account';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RowNode } from 'ag-grid-community';
import { Account, SourceAccount } from 'src/app/accounting';
import { StandardAccount } from 'src/app/accounting/chart/headers-and-accounts/standard-account';

@Component({
  selector: 'crs-account-detail-renderer',
  template: `
    <div class="account-detail-renderer" title="">
      <span
        class="account-detail muted-cell"
        *ngIf="account && account.isHeader"
      >
        <small>
          {{ subHeadersCount }} sub headers | {{ accountsCount }} Accounts
        </small>
      </span>

      <span
        class="account-detail muted-cell"
        *ngIf="account && !account.isHeader"
        [title]="account.accountType?.name"
      >
        <span>
          <small>{{ account.accountType?.name }}</small>
        </span>
        <a
          container="body"
          ngbPopover="This account is a consolidated account"
          placement="right"
          popoverTitle="Consolidated"
          *ngIf="account.isConsolidated"
          [routerLink]="[]"
        >
          <small class="badge badge-light ms-2">Consolidate</small>
        </a>
      </span>

      <span
        class="source-account"
        *ngIf="sourceAccount?.source"
        [title]="sourceAccount.source.name"
      >
        <img
          class="icon-img-sm me-2"
          [alt]="sourceAccount.source.name"
          [src]="sourceAccount.source.sourceType?.icon"
        />
        <span>
          <small>{{ sourceAccount.source.name }}</small>
        </span>
        <a
          container="body"
          ngbPopover="{{ sourceAccount.division.name }} ({{
            sourceAccount.division.code
          }})"
          placement="right"
          popoverTitle="Division"
          *ngIf="sourceAccount?.division"
          [routerLink]="[]"
        >
          <small class="badge bg-primary ms-2">
            {{ sourceAccount.division.code }}
          </small>
        </a>
        <a
          container="body"
          ngbPopover="{{ sourceAccount.tradingAccount.name }} ({{
            sourceAccount.tradingAccount.code
          }})"
          placement="right"
          popoverTitle="Trading Account"
          *ngIf="sourceAccount?.tradingAccount"
          [routerLink]="[]"
        >
          <small class="badge bg-secondary ms-2">
            {{ sourceAccount.tradingAccount.code }}
          </small>
        </a>
      </span>
    </div>
  `,
})
export class AccountDetailRendererComponent
  implements ICellRendererAngularComp
{
  public sourceAccount: SourceAccount;
  public account: Account | StandardAccount;

  public subHeadersCount: number = 0;
  public accountsCount: number = 0;

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: any): boolean {
    if (!params.data) return true;
    if (params.data instanceof AccountsViewSourceAccount) {
      this.sourceAccount = params.data;
    } else {
      this.account = params.data;

      if (this.account.isHeader) {
        this.subHeadersCount = 0;
        this.accountsCount = 0;

        const childrenNodes = this.collectChildrenNodes(params.node);
        this.calculateCounts(childrenNodes);
      }
    }

    return true;
  }

  collectChildrenNodes(node: RowNode): RowNode[] {
    let childrenNodes: RowNode[] = [];
    if (node && node.childrenAfterGroup) {
      node.childrenAfterGroup.forEach((childNode) => {
        childrenNodes.push(childNode);
        const grandChildrenNodes = this.collectChildrenNodes(childNode);
        if (grandChildrenNodes.length > 0) {
          childrenNodes = childrenNodes.concat(grandChildrenNodes);
        }
      });
    }
    return childrenNodes;
  }

  calculateCounts(nodes: RowNode[]) {
    for (const node of nodes) {
      if (node.data?.isHeader) {
        this.subHeadersCount++;
      } else {
        this.accountsCount++;
      }
    }
  }
}
