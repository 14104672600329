<!-- Search -->
<div class="row">
  <div class="col-sm-12">
    <div class="icon-input-wrapper flex-grow-1 mr-2">
      <i aria-hidden="true" class="fas fa-search input-icon"></i>
      <input
        aria-describedby="searchIcon"
        placeholder="Search..."
        type="search"
        [formControl]="search"
      />
    </div>
  </div>
</div>

<div style="overflow: hidden; flex-grow: 1">
  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    style="width: 100%"
    [gridOptions]="gridOptions"
    [groupIncludeTotalFooter]="true"
    [rowData]="content.records"
  >
    <ag-grid-column
      field="asset.assetGroupName"
      headerName="Group"
      [enableRowGroup]="true"
      [hide]="true"
      [rowGroup]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.code"
      headerName="Code"
      [hide]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.identifier"
      headerName="Identifier"
      [hide]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.acquisitionDate"
      headerName="Acq Date"
      type="dateColumn"
    ></ag-grid-column>
    <ag-grid-column
      field="poolOpeningBalance"
      headerName="Opening Balance"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="poolAdditions"
      headerName="Additions"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="poolDisposals"
      headerName="Disposals"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="poolDepreciation"
      headerName="Depreciation"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="poolWriteOffs"
      headerName="Balance Ass(Ded)"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="poolClosingBalance"
      headerName="Closing Balance"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
  </ag-grid-angular>
</div>

<div class="spacer-lg"></div>

<div *ngIf="content.records.length">
  <table class="table">
    <caption class="sr-only">
      {{
        depreciationPools[poolType] | sentence
      }}
      Summary
    </caption>
    <thead class="thead-light">
      <tr>
        <th scope="col">
          <span *ngIf="poolType !== undefined">{{
            depreciationPools[poolType] | sentence
          }}</span>
          <span *ngIf="poolType === undefined">Pool</span>
          Summary
        </th>
        <th scope="col">$</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Opening Balance</td>
        <td>{{ content.openingBalance | money : 0 : true }}</td>
      </tr>
      <tr>
        <td>Add: Additions</td>
        <td>{{ content.additions | money : 0 : true }}</td>
      </tr>
      <tr>
        <td>Less: Total Depreciation Deduction</td>
        <td>{{ content.depreciation | money : 0 : true }}</td>
      </tr>
      <tr>
        <td>Less: Disposals</td>
        <td>{{ content.disposals | money : 0 : true }}</td>
      </tr>
      <tr>
        <td>Negative Balance Assessed</td>
        <td>{{ content.writeOffs | money : 0 : true }}</td>
      </tr>
      <tr>
        <td>Closing Balance</td>
        <td>{{ content.closingBalance | money : 0 : true }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="spacer-lg"></div>
