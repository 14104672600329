import { IEditableContentInfo } from '../elements/report-editable-element/editable-content-info';
import { ReportStyleInfo } from '../report-output';

/**
 * Represents a subset of content to be rendered inside of the 'report-content' component
 * Includes global meta-data relevant to rendering the detailed report elements contained in the 'content' property
*/
export class ReportContentInfo implements IEditableContentInfo {

  style: ReportStyleInfo = {
    rounding: 0, // default
    percentRounding: 1 // default
  } as ReportStyleInfo;

  /**
   * Is this report content readonly?
  */
  readonly: boolean;

  /**
   * Is this report content a 'template input' (false), or 'rendered output' (true)?
  */
  output: boolean;

  /**
   * An array of report elements which represents the ordered content to render
  */
  content: any[];

  constructor(obj?: Partial<ReportContentInfo>) {
    Object.assign(this, obj);
  }

}
