import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService, PagedResponse } from '../../../core';
import { StandardAccount } from '../../chart/headers-and-accounts/standard-account';
import { AccountType } from './accountType';

@Injectable({
  providedIn: 'root',
})
export class AccountTypeService {
  readonly baseAccountLevel: string = 'api/accounting/ledger/accounttypes';
  readonly baseStandardAccountLevel: string =
    'api/chart-of-accounts/standard-accounts';

  constructor(private apiService: ApiService) {}

  get(id: string) {
    return this.apiService.get<AccountType>(this.baseAccountLevel + '/' + id);
  }

  search$(
    search: string,
    accountId: string = null,
    page: number = 1,
    pageSize: number = 100
  ) {
    let url = this.baseAccountLevel + '/search';
    url = this.apiService.addQuery(url, 'search', search);
    url = this.apiService.addQuery(url, 'accountId', accountId);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    return this.apiService.get<PagedResponse<AccountType[]>>(url);
  }

  searchStandardAccounts$(
    isChild?: boolean | null,
    searchValue?: string | null,
    page?: number | null,
    pageSize?: number | null,
    orderBy?: number | null,
    orderByAsc?: number | null
  ): Observable<PagedResponse<AccountType[]>> {
    let url = `${this.baseStandardAccountLevel}/search/standard`;

    url = this.apiService.addQuery(url, 'isChild', isChild);
    url = this.apiService.addQuery(url, 'search', searchValue);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    url = this.apiService.addQuery(url, 'orderBy', orderBy);
    url = this.apiService.addQuery(url, 'orderByAsc', orderByAsc);

    return this.apiService.get<PagedResponse<StandardAccount>>(url).pipe(
      map((pagedResponse) => {
        const records = pagedResponse.records.map(
          (account) => new AccountType(account.accountType)
        );

        const distinctRecords = records.filter(
          (value, index, self) =>
            self.findIndex((a) => a.id === value.id) === index
        );
        return new PagedResponse<AccountType[]>({
          records: distinctRecords,
          recordsCount: pagedResponse.recordsCount,
          page: pagedResponse.page,
          pages: pagedResponse.pages,
          pageSize: pagedResponse.pageSize,
        });
      })
    );
  }
}
