<div class="modal-header">
  <h4 class="modal-title">
    Financial Table {{types[type]}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

  <div class="modal-body" [ngBusy]="[busy.load]">

    <!-- All Elements except spacer -->
    <div class="row" *ngIf="type !== 4">
      <div class="col-form-sm">
        <div class="form-group required">
          <label class="control-label">Level</label>
          <input type="number" class="form-control" min="0" step="1" formControlName="level" />
        </div>
      </div>
    </div>

    <!-- Header -->
    <div *ngIf="type === 1">

      <div class="form-group required">
        <label class="control-label">Title</label>
        <input type="text" class="form-control" formControlName="title" />
      </div>

      <div class="row">
        <div class="col-form-md">
          <div class="form-group">
            <label class="control-label">Style</label>
            <ng-select [items]="styles | enumToArray" bindValue="value" bindLabel="name" formControlName="style" [clearable]="false"></ng-select>
          </div>
        </div>
      </div>

    </div>

    <!-- Totals and Rows -->
    <div formGroupName="totalDefinition"  *ngIf="type === 2 || type === 3">

      <div class="form-group required">
        <label class="control-label">Title</label>
        <input type="text" class="form-control" formControlName="name" />
      </div>

      <div class="form-group">
        <label class="control-label">Title <small>When results are a mix of positive and negative</small></label>
        <input type="text" class="form-control" placeholder="Use Title" formControlName="alternateNameMixed" />
      </div>

      <div class="form-group">
        <label class="control-label">Title <small>When results are negative</small></label>
        <input type="text" class="form-control" placeholder="Use Title" formControlName="alternateNameNegative" />
      </div>

      <div class="row">
        <div class="col-form-sm">
          <div class="form-group">
            <label class="control-label">Ledger Side</label>
            <ng-select [items]="ledgerSides" bindValue="value" bindLabel="name" formControlName="ledgerSide" [clearable]="false"></ng-select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" formControlName="isPercent">
          <label class="form-check-label">Format result as percent %</label>
        </div>
      </div>

    </div>

    <!-- Spacer -->
    <div class="form-group required" *ngIf="type === 4">
      <label class="control-label">Height</label>
      <input type="number" class="form-control" formControlName="level" />
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  </div>

</form>
