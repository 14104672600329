import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subject, EMPTY, of, Subscription } from 'rxjs';
import { tap, exhaustMap, debounceTime, distinctUntilChanged, finalize, catchError, mergeMap } from 'rxjs/operators';

import { businessNumberValidator, getDefaultGridOptions } from '../../../shared';
import { Office, OfficeCreateModel, OfficeService, OfficeUserRole, OfficeUser, OfficeUserModel, OfficeUserService } from '../';
import { buildAddressForm } from '../../';
import { buildAuditorForm } from '../../model/auditor';
import { OfficeUserComponent, OfficeUserComponentParams } from '../office-user/office-user.component';
import { MessageService, ModalService, SessionService } from '../../../core';
import { ReportFormat, AssociationState } from '../../../accounting';
import { RowDataTransaction, RowNode, RowNodeTransaction } from 'ag-grid-community';

@Component({
  selector: 'crs-office',
  templateUrl: './office.component.html'
})
export class OfficeComponent implements OnInit, OnDestroy {

  id;
  isAdd: boolean;
  objectTitle = 'Office';
  busy = {
    load: null,
    loadUsers: null,
    submit: null
  };
  userObservable: Observable<OfficeUser[]>;
  error: string = null;

  isAdmin = false;

  officeUserRoles = OfficeUserRole;
  gridOptions = getDefaultGridOptions();

  interactionStream = new Subject();

  users = [];
  excludeIds = [];

  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(512)]],
    tradingName: [null, [Validators.required, Validators.maxLength(512)]],
    address: buildAddressForm(this._formBuilder),
    abn: [null, businessNumberValidator('abn')],
    brandColour: [null],
    defaultAssociationStateId: [null],
    defaultReportSaveFormat: [ReportFormat.Excel],
    auditor: buildAuditorForm(this._formBuilder),
    auditorSMSF: buildAuditorForm(this._formBuilder)
  });

  associationStates = AssociationState;
  reportFormats = ReportFormat;

  search = new UntypedFormControl();
  subscriptions: Subscription[] = [];

  constructor(private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _officeService: OfficeService,
    private readonly _officeUserService: OfficeUserService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    sessionService: SessionService) {
    this.isAdmin = sessionService.permissions.isAdmin;
  }

  ngOnInit() {

    this.gridOptions.getRowNodeId = data => data.user.id;

    this.subscriptions.push(this._route.params.subscribe(params => {
      this.id = this._route.snapshot.paramMap.get('id');
      this.isAdd = this.id === 'add';
      this.loadOffice();
      this.loadUsers();
    }));

    this.subscriptions.push(this.search.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged())
      .subscribe(data => {
        this.gridOptions.api.setQuickFilter(data);
      }));

    this.configureInteraction();
    this.setValidatorForAuditorFields('auditor');
    this.setValidatorForAuditorFields('auditorSMSF');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  setValidatorForAuditorFields(auditor: string){
    //const auditor: string = 'auditor';
    if(this.form.get(auditor)) {
      const auditorName = this.form.get(auditor + '.auditorName');
      const auditorTitle = this.form.get(auditor + '.auditorTitle');
      const auditorEmail = this.form.get(auditor + '.auditorEmail');
      const practiceName = this.form.get(auditor + '.practiceName');
      const addressLine1 = this.form.get(auditor + '.addressLine1');
      const state = this.form.get(auditor + '.state');
      const suburb = this.form.get(auditor + '.suburb');
      const postCode = this.form.get(auditor + '.postCode');

      this.form.get(auditor + '.isAuditorStatusEnabled').valueChanges.subscribe(isAuditorStatusEnabled => {
          if(isAuditorStatusEnabled) {
            // Set validators when Auditor switch is turned ON
            auditorName.setValidators([Validators.required]);
            auditorTitle.setValidators([Validators.required]);
            auditorEmail.setValidators([Validators.required]);
            practiceName.setValidators([Validators.required]);
            addressLine1.setValidators([Validators.required]);
            state.setValidators([Validators.required]);
            suburb.setValidators([Validators.required]);
            postCode.setValidators([Validators.required]);
          }
          else {
            // Reset auditor field validators when Auditor switch is turned OFF
            auditorName.setValidators(null);
            auditorTitle.setValidators(null);
            auditorEmail.setValidators(null);
            practiceName.setValidators(null);
            addressLine1.setValidators(null);
            state.setValidators(null);
            suburb.setValidators(null);
            postCode.setValidators(null);
          }
      });
    }
  }

  submit() {
    this.interactionStream.next();
  }

  private configureInteraction() {
    this.subscriptions.push(this.interactionStream
      .pipe(
        tap(() => this.error = null),
        exhaustMap(() => this.handleInteraction())
      )
      .subscribe(() => {
        this.close();
      }));
  }

  private handleInteraction(): Observable<any> {

    let observable: Observable<any>;
    const loadingStream = new Subject();

    const model = this.form.value as Office;

    const users = [];
    this.gridOptions.api.forEachNode(n => users.push(new OfficeUserModel(n.data)));

    if (this.isAdd) {
      observable = this._officeService.post(new OfficeCreateModel(model, users));
    } else {
      model.id = this.id as string;
      observable = this._officeService.put(model)
        .pipe(mergeMap(_ => this._officeUserService.updateUsersForOffice(model.id, users)));
    }

    this.busy.submit = loadingStream.subscribe();
    this.subscriptions.push(this.busy.submit);

    observable = observable.pipe(catchError(err => {
      this.showError(err);
      return EMPTY;
    }),
      finalize(() => loadingStream.complete()));

    return observable;
  }

  private loadOffice() {
    this.busy.load = this.isAdd ? EMPTY : this._officeService.get(this.id)
      .subscribe(office => {
        this.form.patchValue(office);
        if (office.address) this.form.controls['address'].patchValue(office.address);
      },
        err => this.showError(err));
    if ( !this.isAdd ) this.subscriptions.push(this.busy.load);
  }

  private loadUsers() {
    this.userObservable = this.isAdd ? of([]) : this._officeUserService.getAllForOffice(this.id);
    this.busy.loadUsers = this.userObservable.subscribe(data=>{
      this.excludeIds = data.map(o=>o.user.id);
    });
    this.subscriptions.push(this.busy.loadUsers);
  }

  addOfficeUser() {
    const office = this.form.value as Office;
    office.id = this.id as string;
    this._modalService.openModal(OfficeUserComponent, null, new OfficeUserComponentParams({ office: office } as OfficeUser, false, true, this.excludeIds)).then(u => {
      const trans : RowDataTransaction = { add: [u] };
      if (!this.checkRowDataTransaction(trans)) return;
      this.gridOptions.api.applyTransaction(trans);
      this.excludeIds.push(u.user.id);
      if (u.isDefaultPartner) this.setDefaultPartner(u);
    }).catch(() => true);
  }

  editOfficeUser(officeUser: OfficeUser) {
    const office = this.form.value as Office;
    office.id = this.id as string;
    officeUser.office = office;
    this._modalService.openModal(OfficeUserComponent, null, new OfficeUserComponentParams(officeUser, false, true)).then(v => {
      const u = v as OfficeUser;
      const trans : RowDataTransaction = { update: [u] };
      if (!this.checkRowDataTransaction(trans)) return;
      this.gridOptions.api.applyTransaction(trans);
      if (u.isDefaultPartner) this.setDefaultPartner(u);
    }).catch(() => true);
  }   

  removeOfficeUser(officeUser: OfficeUser) {
    this._modalService.confirmation(
      'Are you sure you want to remove this user from the practice?',
    () => {
      const trans : RowDataTransaction = { remove: [officeUser] };
      if (!this.checkRowDataTransaction(trans)) return;
      this.gridOptions.api.applyTransaction(trans);
      const index: number = this.excludeIds.indexOf(officeUser.user.id);
      this.excludeIds.splice(index, 1);
    })
  }

  delete() {
    if (this.isAdd || !this.isAdmin) return;
    this._modalService.confirmation(
      'Are you sure you want to delete this practice? This action cannot be undone.',
      () => this.subscriptions.push(this._officeService.delete(this.id).subscribe(() => {
        this._messageService.success('Successfully deleted practice.');
        this.close();
      }, this.showError)), true);
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  private setDefaultPartner(officeUser: OfficeUser) : void
  {
    if (!officeUser.isDefaultPartner) return;
    this.gridOptions.api.forEachNode(n => {
      let ou = n.data as OfficeUser;
      if (officeUser.user.id == ou.user.id) return;
      if (!ou.isDefaultPartner) return;
      ou.isDefaultPartner = false;
      this.gridOptions.api.applyTransaction({update: [ou]});
    })
  }

  private checkRowDataTransaction(tr: RowDataTransaction) : boolean
  {
    if (tr.add) {
      return true;
    }
    if (tr.update) {
      for (let i = 0; i < tr.update.length; i++)
      {
        const ou : OfficeUser = tr.update[ i ] as OfficeUser;
        const rowNode = this.gridOptions.api.getRowNode(ou.user.id)!;
        if (!ou.isDefaultPartner && rowNode.data.isDefaultPartner)
        {
          this.showError('You must set a new default partner');
          return false;
        }
      }
    }
    if (tr.remove) {
      if (this.excludeIds.length <= tr.remove.length)
      {
        this.showError('A practice must have at least one user');
        return false;
      }
      const ou : OfficeUser = tr.remove[ 0 ] as OfficeUser;
      if (ou.isDefaultPartner)
      {
        this.showError('You must set a new default partner');
        return false;
      }
    }
    return true;
  }
}
