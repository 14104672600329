<ng-select
  bindLabel="displayName"
  groupBy="classification"
  [closeOnSelect]="!multiple"
  [items]="accountsObservable | async"
  [loading]="loading"
  [placeholder]="placeholder"
  [virtualScroll]="true"
>
  <ng-template let-item="item" ng-optgroup-tmp>
    {{ classifications[item.classification] }}
  </ng-template>
  <ng-template
    let-index="index"
    let-item="item"
    let-item$="item$"
    ng-option-tmp
    *ngIf="multiple"
  >
    <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" />
    {{ item.displayName }}
  </ng-template>
</ng-select>
