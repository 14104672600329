import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import { EntityLight } from '../../../firm';
import { ReportStyle } from '../admin/report-styles/models';
import { ReportTemplateColumn, ReportTemplateColumnModel } from './reportTemplateColumn';
import { ReportTemplatePage, ReportTemplatePageModel } from './reportTemplatePage';

export class ReportTemplate {
  id: string;
  fileId: string;
  name: string;
  title: string;
  startDate: Date;
  endDate: Date;
  useDefaultDates: boolean;
  header: string;
  entity: EntityLight;
  isConsolidated: boolean;
  entityType: number;
  reportStyle: ReportStyle;
  columns: ReportTemplateColumn[] = [];
  pages: ReportTemplatePage[] = [];
  modifiedDate: Date;

  constructor(data) {
    Object.assign(this, data);
    if (data.startDate) this.startDate = DateHelpers.dateFromApiResponse(data.startDate);
    if (data.endDate) this.endDate = DateHelpers.dateFromApiResponse(data.endDate);
    this.useDefaultDates = !this.startDate && !this.endDate;
    if (data.entity) this.entity = new EntityLight(data.entity);
    if (data.reportStyle) this.reportStyle = new ReportStyle(data.reportStyle);
    if (data.columns) this.columns = data.columns.map(c => new ReportTemplateColumn(c)).sort(c => c.sortOrder);
    if (data.pages) this.pages = data.pages.map(c => new ReportTemplatePage(c));
    if (data.modifiedDate) this.modifiedDate =  DateHelpers.dateFromApiResponse(data.modifiedDate);
  }
}

export class ReportTemplateModel {
  id: string;
  masterId: number; // Used for 'generate' only - to grab defaults for uninitialised items
  fileId: string;
  name: string;
  title: string;
  startDate: Date;
  endDate: Date;
  header: string;
  entityId: string;
  isConsolidated: boolean;
  entityType: number;
  reportStyleId: string;
  columns: ReportTemplateColumnModel[];
  pages: ReportTemplatePageModel[];

  constructor(data: ReportTemplate) {
    Object.assign(this, data);
    if (data.useDefaultDates) {
      this.startDate = null;
      this.endDate = null;
    }
    if (data.entity) {
      delete (<any>this).entity;
      this.entityId = data.entity.id;
    }
    if (data.reportStyle) {
      delete (<any>this).reportStyle;
      this.reportStyleId = data.reportStyle.id;
    }
    if (data.columns) this.columns = data.columns.map(c => new ReportTemplateColumnModel(c));
    if (data.pages) this.pages = data.pages.map(c => new ReportTemplatePageModel(c));
  }
}

export class ESignatureDocument {
  messageTitle: string;
  message: string;
  clients: Array<any>;
  partners: Array<any>;
  auditors: Array<any>;
  others: Array<any>;
  requestorName: string;
  document: string;
  sendAsDraft: boolean;
  senderId: string;
}
