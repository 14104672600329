import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, from, empty, EMPTY } from 'rxjs';
import {
  tap,
  catchError,
  finalize,
  exhaustMap,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';

import { MatchingRuleComponent } from '../matching-rule/matching-rule.component';
import { MatchingRuleService } from '../';
import { MatchingRule, MatchMode, MatchType } from '../';
import { ModalService, MessageService } from '../../../../core';
import {
  getDefaultGridOptions,
  accountingRenderers,
  ProgressBarService,
} from '../../../../shared';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatchingRuleSetsService } from 'src/app/accounting/chart/matching-rule-sets/matching-rule-sets.service';
import { MatchingRuleSet } from 'src/app/accounting/chart/matching-rule-sets/matching-rule-set';
import { Location } from '@angular/common';

enum Interaction {
  Add,
  Edit,
  Move,
  Delete,
}

class InteractionData {
  interaction: Interaction;
  rule: MatchingRule;
  moveId: string;
  moveIndex: number;
}

@Component({
  selector: 'crs-matching-rules',
  templateUrl: './matching-rules.component.html',
  styleUrls: ['./matching-rules.component.scss'],
})
export class MatchingRulesComponent implements OnInit {
  fileId: string;
  matchingRuleSetId: string;
  isMaster = false;
  collapsed: boolean;
  error: string;

  matchModes = MatchMode;
  matchTypes = MatchType;

  busy = {
    matchingRules: false,
    interactionPending: false,
  };
  matchingRulesObservable: Observable<any>;
  matchingRuleSets: MatchingRuleSet[];
  rules: MatchingRule[];
  pendingRules: MatchingRule[];

  search = new UntypedFormControl();

  gridOptions: any = getDefaultGridOptions();
  renderers = accountingRenderers;
  sortActive: boolean;
  filterActive: boolean;
  gridApi;

  interactionStream = new Subject<InteractionData>();

  form = this.formBuilder.group({
    fileId: [null],
    name: ['', [Validators.required, Validators.maxLength(256)]],
  });

  constructor(
    private matchingRuleService: MatchingRuleService,
    private matchingRuleSetsService: MatchingRuleSetsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private messageService: MessageService,
    private progressBar: ProgressBarService,
    private formBuilder: UntypedFormBuilder,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.fileId = this.route.snapshot.parent.paramMap.get('id');
      this.isMaster = !this.fileId;
      this.matchingRuleSetId = params['id'];
      this.busy.matchingRules = true;
      this.getMatchingRuleSet();
      this.getMatchingRules();
    });
    this.configureInteractionStream();
    this.configureGridOptions();
  }

  configureInteractionStream() {
    this.interactionStream
      .pipe(
        tap(() => {
          this.error = null;
        }),
        exhaustMap((param) => this.handleInteraction(param))
      )
      .subscribe();
  }

  handleInteraction(param: InteractionData): Observable<any> {
    let observable: Observable<any> = EMPTY;

    if (param.interaction === Interaction.Add) {
      observable = from(this.addMatchingRuleHandle());
    }

    if (param.interaction === Interaction.Move) {
      this.busy.interactionPending = true;
      observable = this.move(param.moveId, param.moveIndex);
    }

    if (param.interaction === Interaction.Edit) {
      observable = from(this.editMatchingRuleHandle(param.rule));
    }

    if (param.interaction === Interaction.Delete) {
      observable = from(this.deleteMatchingRuleHandle(param.rule));
    }

    return observable.pipe(
      finalize(() => (this.busy.interactionPending = false)),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      })
    );
  }

  getMatchingRuleSet() {
    this.matchingRuleSetsService
      .get$(this.matchingRuleSetId)
      .subscribe((data) => {
        this.form.patchValue(data);
      });
  }

  getMatchingRules() {
    this.busy.matchingRules = true;
    let observable: Observable<MatchingRule[]>;
    if (this.isMaster) {
      observable = this.matchingRuleService.getAllMasters(
        this.matchingRuleSetId
      );
    } else {
      observable = this.matchingRuleService.getAll(
        this.fileId,
        this.matchingRuleSetId
      );
    }

    observable
      .pipe(
        catchError((err) => {
          this.showError(err);
          return of([]);
        }),
        finalize(() => (this.busy.matchingRules = false))
      )
      .subscribe((rules) => {
        this.rules = rules;
        this.pendingRules = rules;
      });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  addMatchingRule() {
    const param = new InteractionData();
    param.interaction = Interaction.Add;
    this.interactionStream.next(param);
  }

  private addMatchingRuleHandle(): Promise<any> {
    return this.modalService
      .openModal(MatchingRuleComponent, 'add', {
        fileId: this.fileId,
        matchingRuleSetId: this.matchingRuleSetId,
      })
      .then((id) => {
        this.busy.interactionPending = true;
        this.busy.matchingRules = true;
        this.matchingRuleService
          .get(id)
          .pipe(finalize(() => (this.busy.matchingRules = false)))
          .subscribe((rule) => {
            this.rules.push(rule);
            this.gridApi.setRowData(this.pendingRules);
            this.gridOptions.api.ensureNodeVisible(rule, 'bottom');
          });
      })
      .catch(() => true);
  }

  selectMatchingRule(rule: MatchingRule) {
    const param = new InteractionData();
    param.interaction = Interaction.Edit;
    param.rule = rule;
    this.interactionStream.next(param);
  }

  editMatchingRuleHandle(rule: MatchingRule): Promise<any> {
    return this.modalService
      .openModal(MatchingRuleComponent, rule.id, {
        fileId: this.fileId,
        matchingRuleSetId: this.matchingRuleSetId,
      })
      .then(() => {
        this.busy.interactionPending = true;
        return this.getMatchingRules();
      })
      .catch(() => true);
  }

  private move(moveId: string, moveIndex: number) {
    const progress = this.progressBar.ref();
    progress.start();
    return this.matchingRuleService.reorder(moveId, moveIndex + 1).pipe(
      tap(() => {
        this.rules = this.pendingRules;
        console.log('successfully moved row');
      }),
      catchError((err) => {
        this.pendingRules = this.rules;
        this.gridApi.setRowData(this.pendingRules);
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => progress.complete())
    );
  }

  deleteMatchingRule(rule: MatchingRule) {
    const param = new InteractionData();
    param.interaction = Interaction.Delete;
    param.rule = rule;
    this.interactionStream.next(param);
  }

  private deleteMatchingRuleHandle(rule: MatchingRule): Promise<any> {
    return this.modalService.confirmation(
      'Are you sure you want to delete this rule? This action cannot be undone.',
      () => {
        this.matchingRuleService.delete(rule.id).subscribe(() => {
          this.updateDataForDeletion(rule);
        }, this.showError);
      },
      true
    );
  }

  updateDataForDeletion(rule: MatchingRule) {
    this.rules.splice(this.rules.indexOf(rule), 1);
    let i = 1;
    this.rules.forEach((r) => {
      r.sortOrder = i;
      i++;
    });
    this.gridOptions.api.setRowData(this.rules);
  }

  configureGridOptions() {
    this.gridOptions.immutableData = true;
    this.gridOptions.animateRows = true;
    this.gridOptions.enableSorting = false;
    this.gridOptions.getRowNodeId = (node) => node.id;
    this.gridOptions.onRowDragMove = (param) => this.onRowDragMove(param);
    this.gridOptions.onRowDragEnd = (param) => this.onRowDragEnd(param);

    this.gridOptions.onGridReady = (param) => {
      this.gridApi = param.api;
    };
    this.gridOptions.onSortChanged = () => {
      const sortModel = this.gridApi.getSortModel();
      this.sortActive = sortModel && sortModel.length > 0;
      this.updateRowDragSuppression();
    };
    this.gridOptions.onFilterChanged = () => {
      this.filterActive = this.gridApi.isAnyFilterPresent();
      this.updateRowDragSuppression();
    };

    this.search.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((data) => {
        if (this.gridOptions.api) this.gridOptions.api.setQuickFilter(data);
      });
  }

  onRowDragMove(event) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
  }

  onRowDragEnd(event) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);

    const param = new InteractionData();
    param.interaction = Interaction.Move;
    param.moveId = event.node.data.id;
    param.moveIndex = event.node.childIndex;
    this.interactionStream.next(param);
  }

  executeUIMove(event) {
    const movingNode = event.node;
    const overNode = event.overNode;
    const rowNeedsToMove = movingNode !== overNode;
    if (rowNeedsToMove) {
      const fromIndex = this.rules.indexOf(movingNode.data);
      const toIndex = this.rules.indexOf(overNode.data);
      this.pendingRules = this.rules.slice();
      moveInArray(this.pendingRules, fromIndex, toIndex);
      event.api.setRowData(this.pendingRules);
      event.api.clearFocusedCell();
    }
    function moveInArray(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }

  updateRowDragSuppression() {
    const suppressRowDrag = this.sortActive || this.filterActive;
    this.gridApi.setSuppressRowDrag(suppressRowDrag);
  }

  routeToSelectedMatchingRuleSet() {
    const url = `./${this.matchingRuleSetId}`;
    this.router.navigate([url], { relativeTo: this.route });
  }
}
