import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }


  get<T>(url: string, options?: any): Observable<T> {
    const fullUrl = this.createFullUrl(url);
    return <any>this.http.get<T>(fullUrl, options)
      .pipe(
        tap(() => this.log('fetched data from ' + fullUrl)),
        map(data => data),
        catchError(this.handleError));
  }

  post<T>(url: string, content: any, options?: any): Observable<T> {
    const fullUrl = this.createFullUrl(url);
    return <any>this.http.post<T>(fullUrl, content, options)
      .pipe(
        tap(() => this.log('posted data to ' + fullUrl)),
        catchError(this.handleError));
  }

  put<T>(url, content: any): Observable<T> {
    const fullUrl = this.createFullUrl(url);
    return <any>this.http.put<T>(fullUrl, content)
      .pipe(
        tap(data => this.log('put data to ' + fullUrl)),
        catchError(this.handleError));
  }

  delete<T>(url): Observable<T> {
    const fullUrl = this.createFullUrl(url);
    return <any>this.http.delete<T>(fullUrl)
      .pipe(
        tap(() => this.log('deleted data from ' + fullUrl)),
        catchError(this.handleError));
  }

  private createFullUrl(url): string {
    return encodeURI(`${Config.baseUrls.api}/${url}`);
  }

  private log(message: any) {
    console.debug(message);
  }

  private handleError(res: HttpErrorResponse | any) {
    console.debug(res);
    if (res && res.errors) res = 'There were validation errors.';
    return observableThrowError(res || 'Server error');
  }

  addQuery(url: string, name: string, value: any, includeNull: boolean = false) {

    if (Array.isArray(value)) {
      value.forEach(v => url = this.buildQuery(url, name, v));
    } else url = this.buildQuery(url, name, value, includeNull);

    return url;
  }

  private buildQuery(url: string, name: string, value: any, includeNull: boolean = false) {
    const query = url.indexOf('?') > -1 ? '&' : '?';

    if (value === undefined || value === null && !includeNull) return url;

    let parsedValue = value;
    if (value instanceof Date) {
      parsedValue = DateHelpers.convertDateToJsonString(value);
    }

    return url + query + name + '=' + parsedValue;
  }
}