<form [formGroup]="formGroup" novalidate>

  <div class="card bg-light">
    <div class="card-header">
      <crs-source-icon [source]="formGroup.get('source').value"></crs-source-icon>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">

      <crs-form-error [error]="error"></crs-form-error>

      <div class="row">
        <div class="col-form-md">
          <div class="form-group">
            <label class="control-label">Journal Depreciation Type</label>
            <ng-select [items]="depreciationTypes | enumToArray" bindValue="value" bindLabel="name" formControlName="depreciationType" [clearable]="false"></ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8 col-md-10">
          <ag-grid-angular #agGrid
              class="ag-theme-material"
              [gridOptions]="journalGrid.gridOptions"
              [suppressHorizontalScroll]="true"
              [enableRangeSelection]="true"
              [suppressCellSelection]="false"
              [rowData]="journalAccounts"
              [frameworkComponents]="journalGrid.renderers"
              (cellValueChanged)="journalGrid.onValueChanged($event)"
            >
            <ag-grid-column headerName="" field="type" [editable]="true" [flex]="3"></ag-grid-column>
            <ag-grid-column headerName="" colId="new" type="optionsColumn" pinned="none" [minWidth]="45" [maxWidth]="45" [cellRendererParams]="{ngTemplate: newAccountCell}"></ag-grid-column>
            <ag-grid-column headerName="Account No" colId="accountNo" field="sourceAccount.accountNo" [minWidth]="110" [width]="110" [maxWidth]="160" [editable]="true" type="typeAheadColumn" [cellEditorParams]="journalGrid.accountNoSearchParams"></ag-grid-column>
            <ag-grid-column headerName="Account Name" colId="accountName" field="sourceAccount.accountName" [minWidth]="140" [width]="200" [maxWidth]="300" [editable]="true" type="typeAheadColumn" [cellEditorParams]="journalGrid.accountNameSearchParams"></ag-grid-column>
            <ag-grid-column headerName="Class" colId="classification" field="sourceAccount.classification" tooltipField="sourceAccount.classificationToolTipText" [minWidth]="80" [maxWidth]="110" [editable]="true" cellRenderer="classificationRenderer" type="typeAheadColumn" [cellEditorParams]="journalGrid.classificationEditorParams"></ag-grid-column>
          </ag-grid-angular>
        </div>
      </div>


        <!-- New Account Cell -->
      <ng-template #newAccountCell let-row>
        <a [routerLink]="[]" *ngIf="row && row.sourceAccount && !row.sourceAccount.id && row.sourceAccount.accountName" placement="right" ngbPopover="This account number and name combination has not been seen before. If you proceed, this account will be created by Access Ledger." popoverTitle="New Account">
          <span class="badge badge-danger">NEW</span>
        </a>
      </ng-template>

      <!-- Account Search Result-->
      <ng-template #accountSearchTemplate let-r="result" let-t="term">
        <ngb-highlight [result]="(r.accountNo ? r.accountNo + '  ' : '') + r.accountName" [term]="t"></ngb-highlight>
      </ng-template>

      <!-- Classification Result-->
      <ng-template #classificationTemplate let-r="result" let-t="term">
        <ngb-highlight [result]="journalGrid.classifications[r]" [term]="t"></ngb-highlight>
      </ng-template>


    </div>
  </div>
</form>
