<div class="dropdown">
  <button
    aria-expanded="false"
    aria-haspopup="true"
    class="btn btn-secondary"
    data-toggle="dropdown"
    id="dropdownCustomizePreviewMenuButton"
    type="button"
  >
    <span class="align-middle mr-2">Actions</span>
    <i aria-hidden="true" class="fas fa-chevron-down align-middle"></i>
  </button>

  <div
    aria-labelledby="dropdownCustomizePreviewMenuButton"
    class="dropdown-menu dropdown-menu-right"
  >
    <button
      class="dropdown-item"
      type="button"
      *ngIf="isLedgerAndBankFeedFeaturesEnabled"
      (click)="onClickTransferTransactions()"
    >
      <i class="fas fa-empty"></i>
      Transfer transactions
    </button>

    <button class="dropdown-item" type="button" (click)="onClickRevertTransactions()">
      <i class="fas fa-empty"></i>
      Revert
    </button>

    <button
      class="dropdown-item"
      type="button"
      (click)="transactionStore.refreshTransactions()"
    >
      <i aria-hidden="true" class="fas fa-refresh"></i>
      Refresh
    </button>

    <button class="dropdown-item" type="button" (click)="onClickUploadTransactions()">
      <i class="fas fa-upload"></i>
      Upload transactions
    </button>
  </div>
</div>

<!-- Keeping for now incase functionality is to be used elsewhere -->
<!-- <label class="control-label">Filters</label>

    <ng-container
      *ngIf="{
        value: transactionStore.descriptionEditable$ | async
      } as descriptionEditable"
    >
      <button
        class="dropdown-item"
        queryParamsHandling="merge"
        type="button"
        [queryParams]="{ descriptionEditable: !descriptionEditable.value }"
        [routerLink]="['./']"
      >
        <i
          class="fas fa-check-circle active"
          [class.hide]="!descriptionEditable.value"
        ></i>
        Editable description
      </button>
    </ng-container>

    <hr />

    <ng-container
      *ngIf="{
        value: transactionStore.displayByAccountCode$ | async
      } as displayByAccountCode"
    >
      <button
        class="dropdown-item"
        queryParamsHandling="merge"
        type="button"
        [queryParams]="{ displayByAccountCode: !displayByAccountCode.value }"
        [routerLink]="['./']"
      >
        <i
          class="fas fa-check-circle active"
          [class.hide]="!displayByAccountCode.value"
        ></i>
        Display account by account code
      </button>
    </ng-container>

   -->
