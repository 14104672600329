<div class="modal-header">
  <h4 class="modal-title">{{ isAdd ? 'Add' : 'Edit' }} account</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="onClickClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="onValidSubmit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Account No</label>
          <input
            class="form-control"
            formControlName="accountNo"
            type="text"
            [readonly]="!isCustom"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Account Name</label>
          <input
            class="form-control"
            formControlName="accountName"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Classification</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="sourceClassification"
            [clearable]="false"
            [items]="classifications | enumToArray"
            [readonly]="
              !isCustom || id === addAccountFormType.AddFromExistingHeader
            "
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label" for="headerSelect">Header</label>
          <crs-standard-account-select
            formControlName="headerAccount"
            [clearable]="false"
            [headersOnly]="true"
            [readonly]="
              !isCustom || id === addAccountFormType.AddFromExistingHeader
            "
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Account Type</label>
          <crs-account-type-select
            formControlName="accountType"
            [clearable]="false"
            [isGrouped]="true"
            [readonly]="
              !isCustom ||
              form.value.accountTypeMode === accountTypeModes.Inherited
            "
            [useStandardAccountsAPI]="true"
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Mode</label>
          <div class="input-group d-flex">
            <div class="flex-grow-1">
              <ng-select
                bindLabel="name"
                bindValue="value"
                formControlName="accountTypeMode"
                [clearable]="false"
                [items]="accountTypeModes | enumToArray"
                [readonly]="!isCustom"
              />
            </div>
            <div class="ms-2">
              <a
                class="btn-sm fas-btn-primary-ghost"
                placement="left"
                popoverTitle="Account Type Mode"
                [ngbPopover]="accountTypeContent"
                [routerLink]="[]"
              >
                <i aria-hidden="true" class="fas fa-info-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ng-template #accountTypeContent>
        <p>
          Adjust this to control whether to set this manually, or whether it
          should be inherited.
        </p>
      </ng-template>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Tax Code</label>
          <ng-select
            bindLabel="descriptionLabel"
            bindValue="taxCode"
            formControlName="masterTaxCode"
            [items]="taxCodes"
            [readonly]="!isCustom"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-8">
        <h3 class="py-2">Entity types</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Restrict entity types</label>
          <ng-select
            bindLabel="name"
            bindValue="id"
            formControlName="entityTypes"
            placeholder="Applies to all entity types"
            [clearable]="true"
            [closeOnSelect]="false"
            [items]="entityTypes"
            [multiple]="true"
            [readonly]="!isCustom"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add Account' : 'Save' }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="onClickClose()">
      Cancel
    </button>
  </div>
</form>
