<div [formGroup]="detail">
  <!-- Profit and Loss Customisations -->
  <div class="row" *ngIf="showSeparateTradingAccounts">
    <div class="col-form-sm">
      <!-- Profit Display Option -->
      <div class="form-group required">
        <label class="control-label">Display Option</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="profitDisplayOption"
          [clearable]="false"
          [items]="profitDisplayOptions | enumToArray"
        ></ng-select>
      </div>

      <!-- Profit Losss Layout Option -->
      <div class="form-group required">
        <label class="control-label">Layout Option</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="profitlossLayoutOption"
          [clearable]="false"
          [items]="profitlossLayoutOptions | enumToArray"
        ></ng-select>
      </div>

      <!-- Separate Trading Accounts -->
      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="separateTradingAccounts"
            readonly
            type="checkbox"
            [id]="'separateCheck' + i"
          />
          <label class="form-check-label" [for]="'separateCheck' + i"
            >Separate Trading Accounts</label
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Customise Columns -->
  <div class="form-group">
    <button
      class="btn btn-secondary"
      *ngIf="!detail?.controls['customiseColumns'].value"
      (click)="customiseColumns(true)"
    >
      <i aria-hidden="true" class="fas fa-columns"></i>
      Click to create custom columns
    </button>
    <button
      class="btn btn-secondary"
      *ngIf="detail?.controls['customiseColumns'].value"
      (click)="customiseColumns(false)"
    >
      <i aria-hidden="true" class="fas fa-history"></i>
      Revert to standard report columns
    </button>
  </div>

  <crs-report-template-columns
    *ngIf="detail?.controls['customiseColumns']?.value"
    [formArray]="detail.controls['customColumns']"
  ></crs-report-template-columns>

  <!-- Layout -->
  <div class="row" [ngBusy]="busy.load">
    <div class="col-form-lg">
      <ag-grid-angular
        #allocatedGrid
        class="ag-theme-material"
        style="width: 100%; height: 100%"
        [autoGroupColumnDef]="groupColumnDef"
        [gridOptions]="gridOptions"
        [rowData]="layout"
      >
        <ag-grid-column
          field="accountNo"
          headerName="Account No"
          [hide]="true"
        ></ag-grid-column>
        <ag-grid-column
          field="accountName"
          headerName="Account Name"
          [hide]="true"
        ></ag-grid-column>
        <ag-grid-column
          headerClass="centered"
          headerName="Disclosure"
          pinned="none"
          type="templateColumn"
          [cellRendererParams]="{ ngTemplate: disclosuresCell }"
          [maxWidth]="220"
          [minWidth]="200"
          [width]="220"
        ></ag-grid-column>
        <ag-grid-column
          headerClass="centered"
          headerName="Show in Note"
          pinned="none"
          type="optionsColumn"
          [cellRendererParams]="{ ngTemplate: optionsCell }"
          [maxWidth]="120"
          [minWidth]="120"
          [width]="120"
        ></ag-grid-column>
      </ag-grid-angular>

      <ng-template #optionsCell let-row>
        <div class="btn-mini-menu" *ngIf="row.layoutOption">
          <input
            title="Show in Note"
            type="checkbox"
            *ngIf="!row.layoutOption.hasDisclosure"
            [checked]="row.layoutOption.showAsNote"
            (click)="toggleShowAsNote(row)"
          />
          <input
            checked
            disabled
            title="Show in Note"
            type="checkbox"
            *ngIf="row.layoutOption.hasDisclosure"
          />
          <!-- Todo: Implement total and 'root' functions for layouts -->
          <!-- <a [routerLink]="" class="btn btn-sm fas-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
            <span class="sr-only"> Show Display Options </span>
          </a>
          <div class="dropdown-menu">
            <button type="button" class="btn dropdown-item" (click)="editTotals(row)">
              <i class="fas fa-stream" aria-hidden="true"></i>
              Edit Totals
            </button>
            <button type="button" class="btn dropdown-item" (click)="moveToRoot(row)" *ngIf="row.hierarchy.length > 1">
              <i class="fas fa-arrow-left" aria-hidden="true"></i>
              Move to Root Level
            </button>
            <button type="button" class="btn dropdown-item" (click)="moveFromRoot(row)" *ngIf="row.hierarchy.length > 1">
                <i class="fas fa-arrow-right" aria-hidden="true"></i>
                Restore Normal Placement
              </button>
          </div> -->
        </div>
      </ng-template>

      <ng-template #disclosuresCell let-row>
        <div *ngIf="row.layoutOption">
          <button
            class="btn btn-light w-100 mx-1 text-truncate p-1"
            type="button"
            *ngIf="row.layoutOption"
            (click)="editDisclosureOptions(row)"
          >
            <span
              class="badge badge-light w-100"
              *ngIf="row.layoutOption.disclosureOptionsModified"
            >
              <span *ngIf="row.layoutOption.disclosure">
                {{ row.layoutOption.disclosure.name }}
              </span>
              <span
                *ngIf="
                  !row.layoutOption.disclosure &&
                  row.layoutOption.disclosureLinkDisclosure
                "
              >
                {{ row.layoutOption.disclosureLinkDisclosure.name }}
              </span>
            </span>
            <span
              *ngIf="
                !row.layoutOption.disclosureOptionsModified &&
                row.layoutOption.disclosureLinkDisclosure
              "
            >
              <span class="badge badge-light"
                ><i class="fas fa-magic"></i
              ></span>
              {{ row.layoutOption.disclosureLinkDisclosure.name }}
            </span>
            <span
              *ngIf="
                !row.layoutOption.disclosure &&
                !row.layoutOption.disclosureLinkDisclosure
              "
            >
              <small><em>Click to Link</em></small>
            </span>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
