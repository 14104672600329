import { Classification } from '../../ledger/classifications/classification';
import { Account } from '../../ledger/accounts/account';
import { AccountType } from '../../ledger/account-types';

export enum MatchType {
  AccountName = 0,
  AccountNumber = 1,
  AccountType = 2,
}

export enum MatchMode {
  Anywhere = 0,
  BeginsWith = 1,
  EndsWith = 2,
  Entire = 3,
  IsBetween = 4,
}

export class MatchingRule {
  id: string;
  fileId: string;
  sortOrder: number;
  type: MatchType;
  classification: Classification;
  find: string;
  findCaseSensitive: boolean;
  mode: MatchMode;
  findAccountType: AccountType;
  findAccountTypeId: number;
  account: Account;
  masterAccount: any;
  accountId: string;
  accountType: AccountType;
  accountTypeId: number;
  overrideManualValues: boolean;
  setToManualAfterMatch: boolean;
  matchingRuleSetId: string;
  start: number;
  end: number;

  get findDescription(): string {
    if (this.type === MatchType.AccountType) {
      return (
        'All accounts which are ' +
        (this.findAccountType ? this.findAccountType.name : 'N/A')
      );
    } else {
      const type =
        this.type === MatchType.AccountName ? 'Account Name' : ' Account No';
      let mode = '';
      const classification = Classification[this.classification];
      if (this.mode === MatchMode.IsBetween)
        return `All ${classification} where the ${type} is between ${this.start} and ${this.end}`;
      if (this.mode === MatchMode.Anywhere) mode = 'contains the phrase';
      if (this.mode === MatchMode.BeginsWith) mode = 'begins with';
      if (this.mode === MatchMode.EndsWith) mode = 'ends with';
      if (this.mode === MatchMode.Entire) mode = 'equals';
      return `All ${classification} where the ${type} ${mode} '${this.find}'`;
    }
  }

  constructor(data) {
    Object.assign(this, data);
    if (data.account) this.account = new Account(data.account);
    if (data.findAccountType)
      this.findAccountType = new AccountType(data.findAccountType);
    if (data.accountType) this.accountType = new AccountType(data.accountType);
  }
}

export class MatchingRuleModel {
  id: string;
  fileId: string;
  sortOrder: number;
  type: MatchType;
  classification: Classification;
  find: string;
  findCaseSensitive: boolean;
  mode: MatchMode;
  findAccountTypeId: number;
  masterAccountId: string;
  accountId: string;
  accountTypeId: number;
  overrideManualValues: boolean;
  setToManualAfterMatch: boolean;
  matchingRuleSetId: string;
  start: number;
  end: number;

  constructor(data) {
    Object.assign(this, data);
    this.findAccountTypeId = data.findAccountType
      ? data.findAccountType.id
      : null;
    this.accountId = data.account ? data.account.id : null;
    this.masterAccountId = data.masterAccount ? data.masterAccount.id : null;
    this.accountTypeId = data.accountType ? data.accountType.id : null;
  }
}
