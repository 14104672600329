<crs-report-editable-element
  [index]="index"
  [reportContent]="reportContent"
  [reportMetaData]="reportMetaData"
  (edit)="onEdit()"
  [onSubmit]="onSubmit"
  (cancel)="onCancel()"
  [editable]="element?.isEditable ?? true"
  [cancellable]="true">

  <ng-container viewer>
    <ng-container *ngTemplateOutlet="sharedTableTemplate"></ng-container>
  </ng-container>

  <ng-container editor>
    <ng-container *ngTemplateOutlet="sharedTableTemplate"></ng-container>
  </ng-container>

  <div *ngIf="!element?.isEditable ?? true">
    <ng-container *ngTemplateOutlet="sharedTableTemplate"></ng-container>
  </div>

  <ng-container actions>
    <crs-report-table-actions></crs-report-table-actions>
  </ng-container>

  <ng-template #sharedTableTemplate>
    <table class="report-table financial-table" *ngIf="element" cdkDropList (cdkDropListDropped)="dropRow($event)">
      <caption class="sr-only">Report Table</caption>
      <crs-report-table-row *ngFor="let row of element.rows; let rowIndex = index;"
                            [element]="element"
                            [row]="row"
                            [isEditMode]="isEditing"
                            [reportMetaData]="reportMetaData"
                            [index]="rowIndex"
      ></crs-report-table-row>

    </table>
  </ng-template>
</crs-report-editable-element>
