<tr *ngIf="index === 0 && isEditMode" class="table-row" >
  <th class="th-table header-size no-select"
      title="Select this table"
      (click)="onSelectedTable()"
      [class.selected]="(selectedElementType$ | async) === tableElements.Table"
      [class.th-table]="!isSectionTable()">
    <div *ngIf="!isSectionTable()" class="table-selector">
      <mat-icon>network_cell</mat-icon>
    </div>
  </th>
  <th
    *ngFor="let column of element.columns; let cellIndex = index"
    class="th-column header-size no-select"
    title="Select this column"
    (click)="onSelectedColumn(cellIndex)"
    [class.selected]="((selectedElementType$ | async) === tableElements.Column && (selectedColumnIndex$ | async) === cellIndex)  || (selectedElementType$ | async) === tableElements.Table"
    scope="col"
    [colSpan]="row.cells.length === element.columns.length ?  row.cells[cellIndex].colSpan: 1"
    [crsCellSize]="column.width" [crsColumnDefs]="element.columns" [crsIsEditMode]="isEditMode"
    [class.align-center]="true"
    [class.th-column]="!isSectionTable()">
    <div *ngIf="!isSectionTable()" class="column-selector crop">
      <mat-icon>arrow_downward</mat-icon>
      <div class="crop-line crop-right-line"></div>
    </div>
  </th>
</tr>

<tr cdkDrag
    [cdkDragData]="row"
    class="table-row"
    [crsRowStyle]="{ level: row.level, style: row.style, rowType: row.rowType}"
    [class.financial-total] = "row.isTotalRow"
    [class.financial-group-header] = "row.rowType === tableRowTypes.Header">

  <td cdkDragHandle
      *ngIf="isEditMode" class="th-row header-size no-select"
      title="Select this row"
      [crsCellSize]="10" [cell-height]="row.height" [crsColumnDefs]="element.columns" [crsIsEditMode]="isEditMode"
      [class.selected]="((selectedElementType$ | async) === tableElements.Row && (selectedRowIndex$ | async) === index) || (selectedElementType$ | async) === tableElements.Table"
      [class.th-row]="!isSectionTable() || row.rowType === tableRowTypes.Row"
      (click)="onSelectedRow(index)">
    <ng-container *ngIf="!isSectionTable() || row.rowType === tableRowTypes.Row" [ngTemplateOutlet]="arrowRow"></ng-container>
  </td>

  <ng-container *ngFor="let cell of row.cells; let cellIndex = index;">

    <td *ngIf="cell.cellType !== cellTypes.financialFormula"

        class="cell-control"
        [class.financial-title]="cellIndex === 0"
        [class.financial-value]="cell.valueType === columnTypes.Number"
        [class.negative]="cell.valueType === columnTypes.Number && cell.cellValue < 0"
        [class.spanned-header-group]="cell.colSpan > 1 && cell.cellValue && row.rowType === tableRowTypes.HeaderColumnGroups"
        [class.header-column-groups-row]="cell.colSpan > 1 && cell.cellValue"

        [class.view]="!isEditMode"
        crs-report-table-cell
        [rowIndex]="index"
        [cellIndex]="cellIndex"
        [cell]="cell"
        [isEditMode]="isEditMode && row.rowType !== tableRowTypes.Spacer"
        [colSpan]="cell.colSpan"
        [isAlternative]="isAlternative"
        [isReadonly]="row.rowType === tableRowTypes.AutoTotal || row.rowType === tableRowTypes.AutoGrandTotal"
        [isSectionTableNoteHeader] = "row.rowType === tableRowTypes.ColumnHeader && isSectionTable() && cellIndex === 1"

        [crsCellSpan]="cell.colSpan"
        [crsCellSize]="element.columns[cellIndex].width"
        [crsRowType]="row.rowType"
        [crsRowHeight]="row.height"
        [crsRowLevel]="row.level"
        [crsColumnDefs]="element.columns"
        [crsIsEditMode]="isEditMode"

        [class.selected]="
        (cellIndex === (selectedColumnIndex$ | async) && (selectedElementType$ | async) === tableElements.Column) ||
        (index === (selectedRowIndex$ | async)  && (selectedElementType$ | async) === tableElements.Row) ||
        (index === (selectedRowIndex$ | async) && cellIndex === (selectedColumnIndex$ | async)  && (selectedElementType$ | async) === tableElements.Cell) ||
        (selectedElementType$ | async) === tableElements.Table"

        [class.align-left]="cell.alignment == alignments.Left"
        [class.align-center]="cell.alignment == alignments.Center"
        [class.align-right]="cell.alignment ==  alignments.Right"
        [class.align-justify]="cell.alignment ==  alignments.Justify"
        [class.text-underline]="cell.textUnderline"
        [class.text-bold]="isAlternative ? false : cell.textBold"
        [class.text-italic]="isAlternative"
        [class.drill-down]="hasDrillDown(cellIndex)" (click)="drillDown(cellIndex)"
    >
    </td>

    <crs-financial-formula *ngIf="cell.cellType === cellTypes.financialFormula"
                           [totalDefinition]="cell.totalDefinition"></crs-financial-formula>
  </ng-container>
</tr>

<ng-template #arrowRow>
  <div class="row-selector">
    <mat-icon>arrow_forward</mat-icon>
  </div>
</ng-template>




