<ng-container *ngIf="taxOptions$ | async as statefulTaxOptions">
  <ng-container *ngIf="statefulTaxOptions.state === 'ERROR'">
    <p>There was an error loading the taxes.</p>
  </ng-container>
  <ng-container *ngIf="sortedTaxOptions$ | async as sortedTaxOptions">
    <ng-select
      bindValue="taxId"
      [(ngModel)]="value"
      [appendTo]="appendTo"
      [clearable]="clearable"
      [items]="sortedTaxOptions"
      [loading]="(taxOptions$ | async)?.state === 'PENDING'"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [searchFn]="searchTaxNameOrCode"
      [selectOnTab]="true"
      [virtualScroll]="true"
      (change)="changed($event)"
    >
      <ng-template let-item="item" ng-label-tmp>
        {{ item.code }} - {{ item.name }}
      </ng-template>

      <ng-template let-item="item" let-search="searchTerm" ng-option-tmp>
        <div class="ng-option-tmp">
          <span [ngOptionHighlight]="search"
            >{{ item.code }} - {{ item.name }}</span
          >
        </div>
      </ng-template>
    </ng-select>
  </ng-container>
</ng-container>
