import { DateHelpers } from "src/app/shared/utilities/date-helpers";
import { ReportOutput } from "../reportViewer/report-output";

export class ReportDocumentListItem {
  id: string;
  name: string;
  title: string;
  modifiedDate: Date;
  reportSignStatus: number;
  financialYear : string;

  constructor(data) {
    Object.assign(this, data);
    if (data.modifiedDate) this.modifiedDate =  DateHelpers.dateFromApiResponse(data.modifiedDate);
  }
}

export class ReportSignStatus
{
  static REPORT_UNSIGNED : number = 0;
  static REPORT_SIGNED : number = 1;
}

export class ReportSaveModel {
  name: string;
  title: string;
  financialYear: string;

  constructor(data: ReportSaveModel) {
    Object.assign(this, data);
  }
}


export class ReportDocumentModel{
  ReportDocumentHeader : ReportDocumentListItem;
  ReportDocumentContents : ReportOutput;
}
