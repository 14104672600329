import {
  Directive,
  Input,
  HostListener,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: 'form[formGroup]',
})
export class FormValidationDirective implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Output() validSubmit = new EventEmitter<any>();

  @HostListener('submit')
  onSubmit() {
    this.storeOriginalStates(this.formGroup);
    this.markAsTouchedAndDirty(this.formGroup);
    if (this.formGroup.valid) {
      this.restoreOriginalStates(this.formGroup);
      this.validSubmit.emit(this.formGroup.value);
    }
  }

  private originalStates = new Map();

  markAsTouchedAndDirty = (formGroup: UntypedFormGroup) => {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];
      if (control instanceof UntypedFormGroup) {
        this.markAsTouchedAndDirty(control as UntypedFormGroup);
      } else if (control instanceof FormArray) {
        control.controls.forEach(this.markAsTouchedAndDirty);
      } else if (control.enabled) {
        control.markAsDirty();
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
  };

  storeOriginalStates = (formGroup: UntypedFormGroup) => {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];
      if (control instanceof UntypedFormGroup) {
        this.storeOriginalStates(control as UntypedFormGroup);
      } else {
        this.originalStates.set(control, {
          dirty: control.dirty,
          touched: control.touched,
        });
      }
    });
  };

  restoreOriginalStates = (formGroup: UntypedFormGroup) => {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];
      if (control instanceof UntypedFormGroup) {
        this.restoreOriginalStates(control as UntypedFormGroup);
      } else {
        const originalState = this.originalStates.get(control);
        originalState.dirty ? control.markAsDirty() : control.markAsPristine();
        originalState.touched
          ? control.markAsTouched()
          : control.markAsUntouched();
      }
    });
  };

  constructor() {}

  ngOnInit() {}
}
