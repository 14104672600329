import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subject, Subscription, of, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { getDefaultGridOptions } from 'src/app/shared';
import { DatasetSelectModalComponent } from './../dataset-select-modal/dataset-select-modal.component';

import { ApiError } from 'src/app/core/interceptors/api-error';
import {
  ConnectionService,
  Dataset,
  DatasetModel,
  DatasetService,
  ImportFrequency,
  Source,
  SourceService,
  TransactionDetail,
} from '../../';
import { DateService, MessageService, ModalService } from '../../../core';
import { ActiveFileService } from '../../active-file.service';
import { ApiAuthorisationModalComponent } from '../../connections/api-authorisation-modal/api-authorisation-modal.component';
import { ApiType } from '../../sourcedata';

enum Action {
  SaveAndClose = 0,
  SaveOnly = 1,
  SaveAndLoad = 2,
  RefreshOpeningBalances = 3,
  Delete = 4,
}

@Component({
  selector: 'crs-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss'],
})
export class DatasetComponent implements OnInit, OnDestroy {
  busy = {
    load: null,
    submit: null,
    reload: null,
    delete: null,
    loadingSource: false,
    login: null,
    refreshOpeningBalances: null,
  };
  fileId: string;
  isConsolidated: boolean;
  isAdd: boolean;
  isGroupDataset: boolean;
  isEntityLivestockEnabled: boolean;
  entityId: string;
  datasetId: string;
  defaultDateStart: Date;
  defaultDateEnd: Date;
  rollOverEndDate: Date;
  loginRequired: boolean = false;
  apiTenantIdRequired: boolean = false;

  form = this.formBuilder.group(
    {
      id: [null],
      name: ['', Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      source: [null, Validators.required],
      importFrequency: [null, Validators.required],
      transactionDetail: [null, Validators.required],
      lastImported: [null],
      isGroupDataset: [false],
      groupDatasets: [[]],
      openingBalanceDataset: [null],
      apiTenantId: [null],
      sourceDataset: [null],
    },
    { validators: trialBalanceValidator }
  );

  showOpeningBalanceSelector = new UntypedFormControl();

  subscriptions: Subscription[] = [];

  private _selectedSource: Source;
  set selectedSource(value: Source) {
    this._selectedSource = value;
    this.updateImportedFrequencies();
  }
  get selectedSource() {
    return this._selectedSource;
  }

  importFrequencies: { value: number; name: string }[];
  transactionDetail = TransactionDetail;
  transactionDetailItems = [
    { value: TransactionDetail.PeriodMovement, name: 'Period Movement' },
    {
      value: TransactionDetail.TrialBalance,
      name: 'Trial Balance (Annual Only)',
    },
  ];
  error: string = null;
  groupDatasets: Dataset[];

  gridOptions = getDefaultGridOptions();

  actionStream = new Subject<Action>();
  apiType = ApiType;

  constructor(
    private activeFileService: ActiveFileService,
    private datasetService: DatasetService,
    private sourceService: SourceService,
    private messageService: MessageService,
    private dateService: DateService,
    public route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private connectionService: ConnectionService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.defaultDateStart = this.dateService.getLastFinancialYear().startDate;
    this.defaultDateEnd = this.dateService.getLastFinancialYear().endDate;

    this.configureSave();

    // Subscribe to active file service
    this.subscriptions.push(
      this.activeFileService.stream.subscribe((file) => {
        this.fileId = file.id;
        this.isConsolidated = file.isConsolidated;
        if (!this.isConsolidated) this.entityId = file.entity.id;
      })
    );

    // When start date changes, update the rollOverEnd date to the date before (for filtering Opening Dataset control)
    this.subscriptions.push(
      this.form.controls.startDate.valueChanges
        .pipe(
          startWith(() => null),
          map(() => this.form.controls.startDate.value),
          distinctUntilChanged(),
          tap((date) => {
            if (!date) return;
            this.rollOverEndDate = new Date(date.getTime() - 864e5);
          }),
          catchError(() => EMPTY)
        )
        .subscribe()
    );

    // Take copy of group datasets when form changes and update to show in Grid
    this.subscriptions.push(
      this.form.controls.groupDatasets.valueChanges
        .pipe(catchError(() => of(this.form.controls.groupDatasets.value)))
        .subscribe((datasets) => {
          this.groupDatasets = datasets;
          if (this.gridOptions.api) this.gridOptions.api.setRowData(datasets);
        })
    );

    // When a source changes, download api features and other details so we can update form
    this.subscriptions.push(
      this.form.controls.source.valueChanges
        .pipe(
          filter((s) => s !== null),
          map((s) => s.id),
          distinctUntilChanged(),
          tap(() => (this.busy.loadingSource = true)),
          switchMap((id) => {
            return this.sourceService.get$(id).pipe(
              catchError((err) => {
                this.showError('Error getting source details. ' + err);
                return of(null);
              })
            );
          }),
          tap((source) => (this.selectedSource = source)),
          tap(() => (this.busy.loadingSource = false))
        )
        .subscribe()
    );

    // When someone deselects show opening balance remove id value from form
    this.subscriptions.push(
      this.showOpeningBalanceSelector.valueChanges
        .pipe(
          tap((value) => {
            if (!value)
              this.form.controls.openingBalanceDataset.setValue(false);
          })
        )
        .subscribe()
    );

    // If opening balance selected in code behind, update show Opening Balance Selector
    this.subscriptions.push(
      this.form.controls.openingBalanceDataset.valueChanges
        .pipe(
          tap((value) => {
            if (value) this.showOpeningBalanceSelector.setValue(true);
          })
        )
        .subscribe()
    );

    // Main Data Retrieval
    this.route.params.subscribe((params) => {
      this.datasetId = this.route.snapshot.paramMap.get('id');
      this.isAdd = this.datasetId === 'add';
      if (this.isAdd && this.isConsolidated) {
        this.entityId = this.route.snapshot.paramMap.get('entityId');
      }
      this.isGroupDataset =
        this.route.snapshot.paramMap.get('isGroupDataset') === 'true';
      this.form.controls.isGroupDataset.setValue(this.isGroupDataset);
      if (this.isGroupDataset) this.setGroupValidation();
      this.getDataSet();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  updateImportedFrequencies() {
    const source = this._selectedSource;
    if (!source) return;

    this.importFrequencies =
      source && source.sourceType && source.sourceType.apiTypeDetail
        ? source.sourceType.apiTypeDetail.supportedFrequencies.map((f) => {
            return { value: f, name: ImportFrequency[f] };
          })
        : null;

    // Update default values
    if (this.importFrequencies && this.importFrequencies.length) {
      const currentValue = this.form.controls.importFrequency.value;
      if (
        currentValue == null ||
        !this.importFrequencies.some((f) => f.value === currentValue)
      ) {
        this.form.controls.importFrequency.setValue(
          this.importFrequencies[0].value
        );
      }
    }

    // Update validations
    this.form.controls.importFrequency.setValidators(
      source.sourceType.apiType === ApiType.None ? null : [Validators.required]
    );
    this.form.controls.sourceDataset.setValidators(
      source.sourceType.isReportance ? [Validators.required] : null
    );
  }

  configureSave() {
    this.subscriptions.push(
      this.actionStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap((action) => this.getActionObservable(action))
        )
        .subscribe()
    );
  }

  save(stayOpen: boolean = false) {
    this.form.markAsTouched();
    if (!this.form.valid) return;
    if (stayOpen) this.actionStream.next(Action.SaveOnly);
    else this.actionStream.next(Action.SaveAndClose);
  }

  delete(stayOpen: boolean = false) {
    this.modalService.confirmation(
      'This action cannot be undone. Are you sure you want to delete this dataset?',
      () => this.actionStream.next(Action.Delete),
      true,
      this.form.controls['name'].value
    );
  }

  refreshOpeningBalances() {
    this.actionStream.next(Action.RefreshOpeningBalances);
  }

  login() {
    this.attemptLogin(() => {
      this.loginRequired = false;
      this.messageService.success('Successfully Logged In');
      const source = this.form.value.source as Source;
      if (source.sourceType.isDataHub) {
        this.apiTenantIdRequired = true;
        this.getTenantId();
      }
    });
  }

  loadData() {
    this.actionStream.next(Action.SaveAndLoad);
  }

  getActionObservable(action: Action): Observable<any> {
    if (action === Action.RefreshOpeningBalances)
      return this.getRefreshOpeningBalancesObservable();
    if (action === Action.Delete) return this.getDeleteObservable();

    let observable: Observable<any>;
    if (this.isAdd) {
      const model = new DatasetModel(this.form.value);
      model.fileId = this.activeFileService.file.id;
      model.entityId = this.entityId;
      observable = this.datasetService
        .post$(model)
        .pipe(tap((id) => this.form.controls.id.setValue(id)));
    } else {
      observable = this.datasetService
        .put$(new DatasetModel(this.form.value))
        .pipe(map(() => this.form.value.id));
    }

    const loadingStream = new Subject();
    if (action === Action.SaveAndClose) {
      this.busy.submit = loadingStream.subscribe();
      observable = observable.pipe(tap(() => this.close()));
    }
    if (action === Action.SaveOnly) {
      this.busy.submit = loadingStream.subscribe();
      observable = observable.pipe(
        tap((id) => {
          this.navigateToNewlySavedDataset();
          this.messageService.success('Successfully saved dataset.');
        })
      );
    }
    if (action === Action.SaveAndLoad) {
      this.busy.reload = loadingStream.subscribe();
      observable = observable.pipe(
        switchMap((id) => this.datasetService.import$(id)),
        tap(() => {
          this.messageService.success('Successfully loaded data from the API');
          this.form.patchValue({ lastImported: Date.now() });
          this.navigateToNewlySavedDataset();
        }),
        catchError((err) => {
          this.navigateToNewlySavedDataset();
          if (err instanceof ApiError) {
            if (
              err.messageString.includes('access') ||
              err.messageString.includes('connect')
            ) {
              this.messageService.error(
                'ZAP Data Hub cannot be connected, please attempt to Log in to ZAP Data Hub now.'
              );
              this.loginRequired = true;
              return EMPTY;
            }
          }
          return throwError(err);
        })
      );
    }

    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  getRefreshOpeningBalancesObservable(): Observable<any> {
    if (this.isAdd) return EMPTY;

    const openingBalanceDataset = this.form.value
      .openingBalanceDataset as Dataset;
    if (!openingBalanceDataset) return EMPTY;

    const loadingStream = new Subject();
    this.busy.refreshOpeningBalances = loadingStream.subscribe();

    return this.datasetService
      .refreshOpeningBalances$(this.form.value.id, openingBalanceDataset.id)
      .pipe(
        tap(() =>
          this.messageService.success(
            'Successfully rolled over balances from the opening Dataset.'
          )
        ),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => loadingStream.complete())
      );
  }

  getDeleteObservable(): Observable<any> {
    if (this.isAdd) return EMPTY;
    const loadingStream = new Subject();
    this.busy.delete = loadingStream.subscribe();

    return this.datasetService.delete$(this.form.value.id).pipe(
      tap(() => this.close()),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  getDataSet() {
    if (this.isAdd) {
      const dataset = new Dataset({
        entityId: this.entityId,
        startDate: this.defaultDateStart,
        endDate: this.defaultDateEnd,
        importFrequency: ImportFrequency.Annual,
        transactionDetail: TransactionDetail.PeriodMovement,
        source: null,
      });
      this.form.patchValue(dataset);
      this.groupDatasets = [];
    } else {
      this.busy.load = this.datasetService.get$(this.datasetId).subscribe(
        (dataset) => {
          this.form.patchValue(dataset);
          this.entityId = dataset.entity ? dataset.entity.id : null;
          this.isGroupDataset = dataset.isGroupDataset;
          this.groupDatasets = dataset.groupDatasets;

          if (this.isGroupDataset) {
            this.setGroupValidation();
          }

          this.isEntityLivestockEnabled = dataset.entity.isLivestockEnabled;
        },
        (err) => this.showError(err)
      );
    }
  }

  setGroupValidation() {
    this.form.controls.source.setValidators([]);
    this.form.controls.source.updateValueAndValidity();
  }

  navigateToNewlySavedDataset() {
    if (this.isAdd && this.form.value.id) {
      this.router.navigate(['../' + this.form.value.id], {
        relativeTo: this.route,
      });
    }
  }

  addDatasets() {
    this.modalService
      .openModal(DatasetSelectModalComponent, null, {
        fileId: this.activeFileService.file.id,
        startDate: this.form.controls.startDate.value,
        endDate: this.form.controls.endDate.value,
        excludeGroupDatasets: true,
        excludeIds: this.form.controls.groupDatasets.value.map((g) => g.id),
      })
      .then((result) => {
        const array = this.form.controls.groupDatasets.value;
        if (result instanceof Dataset) {
          if (array.all((d) => d.id !== result.id))
            array.push(result as Dataset);
        } else if (result instanceof Array) {
          result.forEach((dataset) => {
            if (dataset instanceof Dataset) {
              if (!array.some((d) => d.id === dataset.id)) {
                array.push(dataset as Dataset);
              }
            }
          });
        }
        this.form.controls.groupDatasets.setValue(array);
      })
      .catch(() => true);
  }

  removeGroupDataset(dataset: Dataset) {
    const array = this.form.controls.groupDatasets.value as Dataset[];
    array.splice(array.indexOf(dataset), 1);
    this.form.controls.groupDatasets.setValue(array);
  }

  attemptLogin(afterAction: () => void) {
    this.modalService
      .openModal(ApiAuthorisationModalComponent, null, {
        apiType: this.form.value.source.sourceType.apiType,
      })
      .then((value) =>
        value
          ? afterAction()
          : this.messageService.error('Successful login required to load data.')
      )
      .catch((err) =>
        this.messageService.error('Successful login required to load data.')
      );
  }

  close() {
    const param = this.entityId ? { entityId: this.entityId } : {};
    this.router.navigate(['../../datasets', param], { relativeTo: this.route });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error, true);
  }

  setTenantId(tenant: string) {
    const source = this.form.value.source as Source;
    this.connectionService
      .setTenantId(source.sourceType.apiType, tenant)
      .subscribe(
        (result) => {
          this.apiTenantIdRequired = !result;
          this.messageService.success('Successfully updated Tenant Id');
        },
        (error) => {
          this.messageService.error(error);
        }
      );
  }

  getTenantId() {
    const source = this.form.value.source as Source;
    this.connectionService.getTenant(source.sourceType.apiType).subscribe(
      (result) => {
        this.apiTenantIdRequired = result.canChange;
        this.form.patchValue({ apiTenantId: result.tenantId });
      },
      (error) => {
        this.messageService.error(error);
      }
    );
  }

  onSelectTransactionDetail(event$) {
    if (event$.value === TransactionDetail.TrialBalance)
      this.form.controls['importFrequency'].setValue(0);
  }
}

function testAnnualDates(startDate: Date, endDate: Date): boolean {
  return (
    startDate.getDate() === 1 &&
    startDate.getMonth() === 6 &&
    endDate.getDate() === 30 &&
    endDate.getMonth() === 5 &&
    startDate.getFullYear() + 1 === endDate.getFullYear()
  );
}

function trialBalanceValidator(
  control: AbstractControl<any, any>
): ValidationErrors | null {
  const startDate = control?.get('startDate')?.value;
  const endDate = control?.get('endDate')?.value;
  const importFrequency = control?.get('importFrequency')?.value;
  const transactionDetail = control?.get('transactionDetail')?.value;

  if (startDate === null) return null;
  if (endDate === null) return null;
  if (importFrequency === null) return null;
  if (transactionDetail === null) return null;

  return transactionDetail === TransactionDetail.TrialBalance &&
    (importFrequency !== ImportFrequency.Annual ||
      !testAnnualDates(startDate, endDate))
    ? { trialBalance: true }
    : null;
}
