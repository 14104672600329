import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EMPTY, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';

import { ActiveFileService } from 'src/app/accounting/active-file.service';
import {
  Division,
  DivisionService,
  TradingAccount,
} from 'src/app/accounting/setup';
import { Source, SourceService } from 'src/app/accounting/sourcedata';
import { accountingRenderers, getDefaultGridOptions } from 'src/app/shared';
import { Classification } from '../../classifications';
import {
  SourceAccount,
  SourceAccountService,
  hasEntriesStatuses,
} from '../../sourceAccounts';
import { TradingAccountService } from './../../../setup/tradingAccounts/trading-account.service';
import { SourceAccountDataSource } from './source-account-data-source';
import {
  AddSourceAccountFormType,
  SourceAccountFormComponent,
} from '../source-account-form/source-account-form.component';
import { MessageService, ModalService } from 'src/app/core';

@Component({
  selector: 'crs-source-accounts-table',
  templateUrl: './source-accounts-table.component.html',
  styleUrls: ['./source-accounts-table.component.scss'],
})
export class SourceAccountsTableComponent implements OnInit, OnDestroy {
  error: string = null;
  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;
  subscriptions: Subscription[] = [];

  searchControl = new UntypedFormControl();

  sourceControl = new UntypedFormControl();
  sources: Source[];

  classificationIdControl = new UntypedFormControl();
  classifications = Classification;

  divisionIdControl = new UntypedFormControl();
  divisions: Division[];

  tradingAccountIdControl = new UntypedFormControl();
  tradingAccounts: TradingAccount[];

  selectedStatusControl = new UntypedFormControl();
  statuses = hasEntriesStatuses;

  dataSource: SourceAccountDataSource;

  private fileId: string;
  private cacheSize = 100;
  private pageSize = this.cacheSize / 2;

  constructor(
    private activeFileService: ActiveFileService,
    private divisionService: DivisionService,
    private messageService: MessageService,
    private modalService: ModalService,
    private sourceAccountService: SourceAccountService,
    private sourceService: SourceService,
    private tradingAccountService: TradingAccountService
  ) {}

  ngOnInit(): void {
    const activeFileStreamSubscription =
      this.activeFileService.stream.subscribe((file) => {
        this.fileId = file?.id;
      });

    this.subscriptions.push(activeFileStreamSubscription);

    this.configureDropdownValueSubscriptions();

    this.dataSource = new SourceAccountDataSource(
      this.sourceAccountService,
      this.fileId,
      this.cacheSize
    );

    this.gridOptions.rowModelType = 'infinite';
    this.gridOptions.pagination = true;
    this.gridOptions.cacheBlockSize = this.cacheSize;
    this.gridOptions.paginationPageSize = this.pageSize;
    this.gridOptions.onGridReady = (params) => {
      params.api.setDatasource(this.dataSource);
    };

    this.configureControlSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onClickClearFilters(): void {
    if (!!this.searchControl.value) {
      this.searchControl.setValue('');
    }

    if (!!this.sourceControl.value) {
      this.sourceControl.setValue(null);
    }

    if (!!this.classificationIdControl.value) {
      this.classificationIdControl.setValue(null);
    }

    if (!!this.divisionIdControl.value) {
      this.divisionIdControl.setValue(null);
    }

    if (!!this.tradingAccountIdControl.value) {
      this.tradingAccountIdControl.setValue(null);
    }

    if (!!this.selectedStatusControl.value) {
      this.selectedStatusControl.setValue(null);
    }
  }

  onClickAddSourceAccount(): void {
    this.modalService
      .openModal(
        SourceAccountFormComponent,
        AddSourceAccountFormType.AddNew,
        this.sourceControl.value,
        { size: 'md' }
      )
      .then(() => {
        this.gridOptions.api.setDatasource(this.dataSource);
        this.messageService.success('Source account added successfully');
      })
      .catch(() => true);
  }

  onClickEditSourceAccount(account: SourceAccount): void {
    this.modalService
      .openModal(SourceAccountFormComponent, account.id, null, { size: 'md' })
      .then(() => {
        this.gridOptions.api.setDatasource(this.dataSource);
        this.messageService.success('Source account saved successfully.');
      })
      .catch(() => true);
  }

  onClickDeleteSourceAccount(account: SourceAccount): void {
    const confirmationMessage = `<p>Are you sure you want to delete Source Account "${account.accountName}"?</p></p>This action cannot be undone.</p>`;

    this.modalService.confirmation(
      confirmationMessage,
      () => this.deleteAccount(account),
      true
    );
  }

  private deleteAccount(account: SourceAccount): void {
    this.sourceAccountService
      .delete$(account.id)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap(() => {
          this.gridOptions.api.setDatasource(this.dataSource);
          this.messageService.success('Account deleted successfully.');
        })
      )
      .subscribe();
  }

  private configureDropdownValueSubscriptions(): void {
    const sourcesSubscription = this.sourceService
      .getAll$(this.fileId)
      .subscribe((sources) => (this.sources = sources));

    const divisionsSubscription = this.divisionService
      .getAll(this.fileId)
      .subscribe((divisions) => (this.divisions = divisions));

    const tradingAccountsSubscription = this.tradingAccountService
      .getAll(this.fileId)
      .subscribe((tradingAccounts) => (this.tradingAccounts = tradingAccounts));

    this.subscriptions.push(
      sourcesSubscription,
      divisionsSubscription,
      tradingAccountsSubscription
    );
  }

  private configureControlSubscriptions(): void {
    const searchControlSubscription = this.searchControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((search) => {
        this.dataSource.search = search;
        this.gridOptions.api.setDatasource(this.dataSource);
      });

    const sourceControlSubscription = this.sourceControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((source) => {
        this.dataSource.sourceId = source?.id ?? null;
        this.gridOptions.api.setDatasource(this.dataSource);
      });

    const classificationIdControlSubscription =
      this.classificationIdControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((classificationId) => {
          this.dataSource.classificationId = classificationId;
          this.gridOptions.api.setDatasource(this.dataSource);
        });

    const divisionIdControlSubscription = this.divisionIdControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((divisionId) => {
        this.dataSource.divisionId = divisionId;
        this.gridOptions.api.setDatasource(this.dataSource);
      });

    const tradingAccountIdControlSubscription =
      this.tradingAccountIdControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((tradingAccountId) => {
          this.dataSource.tradingAccountId = tradingAccountId;
          this.gridOptions.api.setDatasource(this.dataSource);
        });

    const selectedStatusControlSubscription =
      this.selectedStatusControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((selectedStatus) => {
          this.dataSource.selectedStatus = selectedStatus;
          this.gridOptions.api.setDatasource(this.dataSource);
        });

    this.subscriptions.push(
      searchControlSubscription,
      sourceControlSubscription,
      classificationIdControlSubscription,
      divisionIdControlSubscription,
      tradingAccountIdControlSubscription,
      selectedStatusControlSubscription
    );
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error, true);
  }
}
