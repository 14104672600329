import { IConnectionStatus } from './integration-ConnectionStatus.model';
import { Component, OnInit } from '@angular/core';
import { interval} from 'rxjs';
import { first } from 'rxjs/operators';
import { IntegrationServerDatasource } from './integration-server.datasource';

@Component({
  selector: 'crs-is-configuration',
  templateUrl: './integration-server.component.html',
  styleUrls: ['./integration-server.component.scss'],
  providers: [IntegrationServerDatasource]
})
export class IntegrationServerConfigurationComponent implements OnInit {
  public busy = false;
  public connected:IConnectionStatus;

  constructor(private _integrationServerDatasource: IntegrationServerDatasource) {
  }

  public ngOnInit() {
    this.busy = true;
    this._integrationServerDatasource.getStatus()
      .pipe(first())
      .subscribe(res => {
        this.busy = false;
        this.connected = res;
      });
  }

  public onConnect() {
    this.busy = true;

    this._integrationServerDatasource.buildLoginUri().pipe(first())
      .subscribe(res => {
        const state = res.state;

        window.open(res.url, '_blank');

        const intervalSubscription = interval(1500).subscribe(() => {
          const callbackUrl = localStorage.getItem('callback-integration-server');
          if (callbackUrl) {

            intervalSubscription.unsubscribe();
            this.busy = false;
            localStorage.removeItem('callback-integration-server');

            this._integrationServerDatasource.processCallbackUri(callbackUrl, state).subscribe(() => {
              this.busy=true;
              //re-check connection status after revoke
              this._integrationServerDatasource.getStatus()
                .pipe(first())
                .subscribe(statusResult => {
                  this.busy = false;
                  this.connected = statusResult;
                });
              });
          }
        });
      });
  }

  public onRevoke() {
    this.busy = true;
    this._integrationServerDatasource.revokeISConnection()
      .pipe(first())
      .subscribe(res => {
        //re-check connection status after revoke
        this._integrationServerDatasource.getStatus()
          .pipe(first())
          .subscribe(statusResult => {
            this.busy = false;
            this.connected = statusResult;
          });
      });

  }

  public onReconnect() {
    this.busy = true;
    this._integrationServerDatasource.revokeISConnection()
      .pipe(first())
      .subscribe(res => {
        //re-connect after revoke
        this.onConnect();
      });

  }

}
