import { getDefaultGridOptions } from 'src/app/shared';
import { DatasetService, Dataset } from '..';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  map,
  catchError,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';

interface IDatasetSelectParams {
  fileId: string;
  entityId: string;
  startDate: Date;
  endDate: Date;
  excludeGroupDatasets: boolean;
  excludeIds: string[];
}

@Component({
  selector: 'crs-dataset-select-modal',
  templateUrl: './dataset-select-modal.component.html',
})
export class DatasetSelectModalComponent implements OnInit {
  @Input() id: string;
  @Input() params: IDatasetSelectParams = {
    fileId: null,
    entityId: null,
    startDate: null,
    endDate: null,
    excludeGroupDatasets: false,
    excludeIds: [],
  };

  error: string;

  search = new UntypedFormControl();

  gridOptions: GridOptions;
  gridApi: GridApi;
  datasets$: Observable<Dataset[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private datasetService: DatasetService
  ) {}

  ngOnInit() {
    this.datasets$ = this.datasetService
      .getAll$(
        this.params.fileId,
        this.params.entityId,
        this.params.startDate,
        this.params.endDate,
        this.params.excludeGroupDatasets
      )
      .pipe(
        map(
          (datasets) =>
            datasets.filter((d) =>
              this.params.excludeIds.every((i) => i !== d.id)
            ),
          catchError((err) => {
            this.showError(err);
            return of([]);
          })
        )
      );

    this.gridOptions = {
      ...getDefaultGridOptions(),
      onGridReady: (event) => (this.gridApi = event.api),
      rowSelection: {
        mode: 'multiRow',
        enableClickSelection: true,
      },
      columnDefs: [
        {
          field: 'entity.legalName',
          headerName: 'Entity',
          minWidth: 250,
        },
        {
          field: 'name',
          headerName: 'Dataset Name',
          minWidth: 250,
        },
        {
          field: 'startDate',
          headerName: 'Start Date',
          type: 'dateColumn',
        },
        {
          field: 'endDate',
          headerName: 'End Date',
          type: 'dateColumn',
        },
        {
          field: 'source',
          headerName: 'Source',
          cellRenderer: 'sourceRenderer',
          maxWidth: 500,
          minWidth: 200,
        },
      ],
    };

    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((search) => {
          this.gridApi.setGridOption('quickFilterText', search);
        })
      )
      .subscribe();
  }

  showError(error) {
    this.error = error;
  }

  select() {
    this.error = null;
    const datasets = this.gridApi.getSelectedNodes().map((n) => n.data);
    if (!datasets.length) {
      this.showError('You must select at least one dataset');
    } else {
      this.activeModal.close(datasets);
    }
  }
}
