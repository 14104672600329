import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { AccountService } from 'src/app/accounting';
import { FileService } from 'src/app/accounting/files';
import { AccountUltraLight } from 'src/app/accounting/ledger/accounts/account-ultra-light';
import { ModalService } from 'src/app/core';
import {
  TradingAccount,
  TradingAccountLocation,
  TradingAccountService,
} from '../';
import { MessageService } from '../../../../core';
import { ActiveFileService } from '../../../active-file.service';
import { LiveStockAllocation, LivestockCategory } from '../livestockAccount';

@Component({
  selector: 'crs-trading-account',
  templateUrl: './trading-account.component.html',
  styleUrls: ['./trading-account.component.scss'],
})
export class TradingAccountComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() params: {
    isLivestockTradingAccount: boolean;
    tradingAccounts: TradingAccount[];
  };

  isAdd: boolean;
  objectTitle = 'Trading Account';
  tradingAccountLocations = TradingAccountLocation;

  fileId: string;
  isLivestockAvailable: boolean;

  livestockCategories = Object.values(LivestockCategory);

  isFetchingAccounts$ = new BehaviorSubject(false);
  isFetchingIsLivestockAvailable$ = new BehaviorSubject(false);
  accounts: AccountUltraLight[] = [];
  existingSavedLivestockAllocations: LiveStockAllocation[] = [];

  busy = {
    load: null,
    submit: null,
    delete: null,
  };
  error: string = null;

  form = this.formBuilder.group({
    fileId: [null],
    code: ['', [Validators.required, Validators.maxLength(64)]],
    name: ['', [Validators.required, Validators.maxLength(256)]],
    location: [TradingAccountLocation.Income, [Validators.required]],
    isPrimaryProduction: [false],
    isLivestockEnabled: [false],
    livestockAllocations: [[]],
  });

  private livestockEnabledSubscription: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private activeFileService: ActiveFileService,
    private tradingAccountService: TradingAccountService,
    private messageService: MessageService,
    private fileService: FileService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.isAdd = this.id === 'add';
    this.fileId = this.activeFileService.file.id;

    this.fetchIsLivestockAvailable();
    this.setupLivestockFormSync();

    if (this.params?.isLivestockTradingAccount) {
      this.defaultLivestockTradingAccount();
    }

    const tradingAccountId = this.isAdd ? null : this.id;
    const tradingAccounts: TradingAccount[] = this.params?.tradingAccounts;

    this.existingSavedLivestockAllocations = tradingAccounts
      .filter((tradingAccount) => tradingAccount.id !== this.id)
      .map((tradingAccount) => tradingAccount.livestockAllocations)
      .reduce((acc, val) => acc.concat(val), []);

    const allocationControls = this.livestockCategories.map((category) =>
      this.formBuilder.group({
        liveStockCategory: [category],
        tradingAccountId: [tradingAccountId],
        account: [null],
      })
    );

    this.form.setControl(
      'livestockAllocations',
      this.formBuilder.array(allocationControls)
    );

    this.getTradingAccount();
  }

  ngOnDestroy() {
    if (this.livestockEnabledSubscription) {
      this.livestockEnabledSubscription.unsubscribe();
    }
  }

  setupLivestockFormSync() {
    this.livestockEnabledSubscription = this.form
      .get('isLivestockEnabled')
      .valueChanges.subscribe((isEnabled) => {
        if (!this.accounts.length) {
          this.getAccountsList();
        }

        const isPrimaryProductionControl = this.form.get('isPrimaryProduction');

        if (isEnabled) {
          isPrimaryProductionControl.setValue(true);
          isPrimaryProductionControl.disable();
        } else {
          isPrimaryProductionControl.enable();
        }
      });
  }

  submit() {
    const tradingAccount = this.form.getRawValue() as TradingAccount; // we need the disabled values too

    if (tradingAccount.isLivestockEnabled) {
      tradingAccount.isPrimaryProduction = true;
    } else {
      tradingAccount.livestockAllocations = [];
    }

    if (this.isAdd) {
      tradingAccount.fileId = this.fileId;

      this.busy.submit = this.tradingAccountService
        .post(tradingAccount)
        .subscribe(
          (id) => this.activeModal.close(id),
          (err) => this.showError(err)
        );
    } else {
      tradingAccount.id = this.id;
      this.busy.submit = this.tradingAccountService
        .put(tradingAccount)
        .subscribe(
          () => this.activeModal.close('edit'),
          (err) => this.showError(err)
        );
    }
  }

  fetchIsLivestockAvailable(): void {
    this.isFetchingIsLivestockAvailable$.next(true);

    this.fileService
      .get$(this.fileId)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        finalize(() => this.isFetchingIsLivestockAvailable$.next(false))
      )
      .subscribe((file) => {
        this.isLivestockAvailable = file.entity.isLivestockEnabled;
      });
  }

  getTradingAccount() {
    if (!this.isAdd) {
      this.busy.load = true;

      this.tradingAccountService.get(this.id).subscribe(
        (data) => {
          // Ensure accounts are loaded
          if (this.accounts.length === 0) {
            this.getAccountsList(() => this.setupFormData(data));
          } else {
            this.setupFormData(data);
          }
        },
        (err) => this.showError(err)
      );
    }
  }

  setupFormData(data: TradingAccount) {
    const livestockCategoryOrder = [
      LivestockCategory.Sales,
      LivestockCategory.OpeningStock,
      LivestockCategory.Purchases,
      LivestockCategory.NaturalIncrease,
      LivestockCategory.KilledForRations,
      LivestockCategory.Deaths,
      LivestockCategory.ClosingStock,
      LivestockCategory.StockOnHand,
    ];

    const allocationsWithAccounts = data.livestockAllocations
      .map((allocation: LiveStockAllocation) => {
        const account = this.accounts.find(
          (account) => account.id === allocation.allocatedAccountId
        );

        return {
          ...allocation,
          account: account || null, // Replace allocatedAccountId with the found account
        };
      })
      .sort(
        // Sort based on the order in livestockCategoryOrder
        (a, b) =>
          livestockCategoryOrder.indexOf(a.liveStockCategory) -
          livestockCategoryOrder.indexOf(b.liveStockCategory)
      );

    this.form.patchValue({
      ...data,
      livestockAllocations: allocationsWithAccounts,
    });

    this.busy.load = false;
  }

  getAccountsList(callback?: () => void) {
    this.isFetchingAccounts$.next(true);

    this.accountService
      .searchAccounts$({
        fileId: this.activeFileService.file.id,
        search: '',
        includeHeaders: false,
        includeChildren: true,
        page: 0,
        pageSize: 1000,
      })
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap((response) => {
          const existingAllocatedAccountIds = new Set(
            this.existingSavedLivestockAllocations.map(
              (allocation) => allocation.allocatedAccountId
            )
          );
          this.accounts = response.records
            .map((account) => new AccountUltraLight(account))
            .filter((account) => !existingAllocatedAccountIds.has(account.id));
        }),
        finalize(() => this.isFetchingAccounts$.next(false))
      )
      .subscribe(() => {
        callback?.();
      });
  }

  checkForDuplicateAccounts() {
    const accountIds = this.form
      .get('livestockAllocations')
      .value.map((allocation) => allocation.account?.id)
      .filter((id) => id);

    const duplicateAccountIds = accountIds.filter(
      (id, index, array) => array.indexOf(id) !== index
    );

    if (duplicateAccountIds.length > 0) {
      const duplicateAccountNumbersOrNames = [...new Set(duplicateAccountIds)]
        .map((id) => this.accounts.find((account) => account.id === id))
        .map((account) => account?.accountNo ?? account?.accountName)
        .filter((account) => account);
      this.error = `The following accounts cannot be allocated to multiple livestock categories: ${duplicateAccountNumbersOrNames.join(
        ', '
      )}`;
    } else {
      this.error = null;
    }
  }

  searchAccountFn = (term: string, item: any) => {
    term = term.toLowerCase();

    return (
      item.accountName?.toLowerCase().indexOf(term) > -1 ||
      item.accountNo?.toLowerCase().indexOf(term) > -1
    );
  };

  delete() {
    this.modalService.confirmation(
      'This action cannot be undone. Are you sure you want to delete this trading account?',
      () => this.performDelete(),
      true
    );
  }

  private performDelete() {
    this.busy.delete = this.tradingAccountService.delete(this.id).subscribe(
      () => this.activeModal.close('delete'),
      (err) => this.showError(err)
    );
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error);
  }

  close() {
    this.activeModal.dismiss();
  }

  private defaultLivestockTradingAccount(): void {
    if (this.isAdd) {
      this.form.patchValue({
        isPrimaryProduction: true,
        isLivestockEnabled: true,
      });
    }

    this.form.get('isPrimaryProduction').disable();
    this.form.get('isLivestockEnabled').disable();
  }
}
