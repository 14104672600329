import { Component, Input } from '@angular/core';
import { Source } from 'src/app/accounting';

@Component({
  selector: 'crs-source-icon',
  templateUrl: './source-icon.component.html'
})
export class SourceIconComponent {
  @Input() source: Source;

  constructor() { }


}
