import { Injectable } from '@angular/core';
import { DownloadService, MessageService } from 'src/app/core';
import { ReportTemplateService } from '../../reportTemplates';
import { ReportOutput } from '../report-output';
import { ExportStrategy, ExportOption } from './export-strategy';
import { Observable } from 'rxjs';
import { ReportDownloadService } from '../../services';
import { finalize } from 'rxjs/operators';

type ExportToAccessDocumentsParams = {
  report?: ReportOutput;
  exportOption?: ExportOption;
  title?: string | null;
  selectedDocumentTagIds?: number[];
  shouldUpdateCustomTableAutoTotal?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class ReportExportStrategy extends ExportStrategy {
  constructor(
    private downloadService: DownloadService,
    private messageService: MessageService,
    private reportDownloadService: ReportDownloadService,
    private reportTemplateService: ReportTemplateService
  ) {
    super();
  }

  exportAsPdf(
    report: ReportOutput,
    title: string | null,
    shouldUpdateCustomTableAutoTotal?: boolean
  ): void {
    this.busy.exportPdf = true;

    if (shouldUpdateCustomTableAutoTotal) {
      this.reportTemplateService.updateCustomTableAutoTotal(report);
    }

    this.reportDownloadService
      .exportAsPdf$(report, title)
      .pipe(finalize(() => (this.busy.exportPdf = false)))
      .subscribe(
        (url) => this.downloadService.download(url),
        (err) => this.messageService.error(err)
      );
  }

  exportAsExcel(
    report: ReportOutput,
    title: string | null,
    shouldUpdateCustomTableAutoTotal?: boolean
  ): void {
    this.busy.exportExcel = true;

    if (shouldUpdateCustomTableAutoTotal) {
      this.reportTemplateService.updateCustomTableAutoTotal(report);
    }

    this.reportDownloadService
      .exportAsExcel$(report, title)
      .pipe(finalize(() => (this.busy.exportExcel = false)))
      .subscribe(
        (url) => this.downloadService.download(url),
        (err) => this.messageService.error(err)
      );
  }

  exportAsWord(
    report: ReportOutput,
    title: string | null,
    shouldUpdateCustomTableAutoTotal?: boolean
  ): void {
    this.busy.exportWord = true;

    if (shouldUpdateCustomTableAutoTotal) {
      this.reportTemplateService.updateCustomTableAutoTotal(report);
    }

    this.reportDownloadService
      .exportAsWord$(report, title)
      .pipe(finalize(() => (this.busy.exportWord = false)))
      .subscribe(
        (url) => this.downloadService.download(url),
        (err) => this.messageService.error(err)
      );
  }

  exportToAccessDocuments$({
    report,
    exportOption,
    title,
    selectedDocumentTagIds,
    shouldUpdateCustomTableAutoTotal = false,
  }: ExportToAccessDocumentsParams): Observable<boolean> {
    if (shouldUpdateCustomTableAutoTotal) {
      this.reportTemplateService.updateCustomTableAutoTotal(report);
    }

    const fileExtension = this.getFileExtension(exportOption);

    return this.reportDownloadService.exportToAccessDocuments$(
      report,
      fileExtension,
      title,
      selectedDocumentTagIds
    );
  }

  private getFileExtension(exportOption: ExportOption): string {
    switch (exportOption) {
      case ExportOption.Pdf: {
        return 'pdf';
      }
      case ExportOption.Excel: {
        return 'xlsx';
      }
      case ExportOption.Word: {
        return 'docx';
      }
      default: {
        return 'unknown';
      }
    }
  }
}
