import { ArrayHelpers } from './../../../shared/utilities/array-helpers';
import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  Validators,
  UntypedFormControl,
  UntypedFormBuilder,
} from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { Subject, EMPTY, Observable, of, Subscription } from 'rxjs';
import {
  tap,
  switchMap,
  catchError,
  finalize,
  debounceTime,
  distinctUntilChanged,
  delay,
} from 'rxjs/operators';
import { getDefaultGridOptions, accountingRenderers } from 'src/app/shared';
import { JournalType } from '..';
import { DatasetService } from '../..';
import {
  JournalCategoryForLedger,
  JournalCategory,
} from '../journals/journalCategory';
import { AccountTransaction } from '../ledgerReports/accountTransaction';
import { GroupByAccountType } from '../ledgerReports/groupByAccountType';
import { LedgerReportService } from '../ledgerReports/ledger-report.service';

let decimalPipe: DecimalPipe;

export class LedgerReportFilters {
  accounts: any[] = [];
  sourceAccounts: any[] = [];
  journalCategory: JournalCategoryForLedger = JournalCategoryForLedger.Normal;
  groupByAccountType: GroupByAccountType = GroupByAccountType.SourceAccount;
  startDate: Date;
  endDate: Date;
  includeApiLedgers = false;
}

@Component({
  selector: 'crs-ledger-report',
  templateUrl: './ledger-report.component.html',
  styleUrls: ['./ledger-report.component.scss'],
})
export class LedgerReportComponent implements OnInit, OnDestroy {
  private initialised = false;

  private _datasetId: string;
  @Input() set datasetId(value: string) {
    if (this._datasetId !== value) {
      this._datasetId = value;
      // If changing after initialisation then reset
      if (this.initialised) {
        this.getDatasetInfo();
      }
    }
  }
  get datasetId() {
    return this._datasetId;
  }

  @Input() filters: LedgerReportFilters;
  @Input() triggerImmediately: boolean;
  @Input() isModal: boolean;

  busy = {
    update: null,
    exportJournal: null,
  };

  form = this._formBuilder.group({
    accounts: [[]],
    allAccounts: [false],
    sourceAccounts: [[], Validators.required],
    journalCategory: [JournalCategoryForLedger.Normal, Validators.required],
    groupByAccountType: [GroupByAccountType.Account, Validators.required],
    startDate: [null, Validators.required],
    endDate: [null, Validators.required],
    includeApiLedgers: [false],
  });
  search = new UntypedFormControl();

  accountTransactions: AccountTransaction[];
  startDate: Date;
  endDate: Date;
  showAccounts = true;
  error: string;

  actionStream = new Subject();

  gridOptions = getDefaultGridOptions();

  groupByAccountTypes = GroupByAccountType;
  journalCategories = JournalCategoryForLedger;
  journalTypes = JournalType;

  isGroupDataset = false;

  subscriptions: Subscription[] = [];

  constructor(
    private readonly _ledgerReportService: LedgerReportService,
    private readonly _datasetService: DatasetService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _decimalPipe: DecimalPipe
  ) {
    decimalPipe = _decimalPipe;
  }

  ngOnInit() {
    this.customiseGridOptions(this.gridOptions);
    this.configureActionStream();
    this.configureValidation();
    this.getDatasetInfo();
    if (this.filters) {
      this.form.patchValue(this.filters);
    }

    if (this.triggerImmediately) {
      this.subscriptions.push(
        of(null)
          .pipe(delay(100)) // Give time for validators to catch up
          .subscribe(() => this.getReport())
      );
    }

    this.initialised = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  private configureActionStream() {
    const sub = this.actionStream
      .pipe(
        tap(() => (this.error = null)),
        switchMap(() => this.getReport$()),
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        })
      )
      .subscribe();

    this.subscriptions.push(sub);
  }

  private configureValidation() {
    const accountsControl = this.form.get('accounts');
    const sourceAccountsControl = this.form.get('sourceAccounts');

    const subscription = this.form.valueChanges.subscribe((valueChange) => {
      const type = valueChange.groupByAccountType as GroupByAccountType;
      const allAccounts = valueChange.allAccounts as boolean;

      this.showAccounts = type === GroupByAccountType.Account;

      if (type === GroupByAccountType.Account) {
        accountsControl.setValidators(
          allAccounts ? null : [Validators.required]
        );
        sourceAccountsControl.setValidators(null);
      } else {
        accountsControl.setValidators(null);
        sourceAccountsControl.setValidators(
          allAccounts ? null : [Validators.required]
        );
      }
    });

    this.subscriptions.push(subscription);
  }

  private getDatasetInfo() {
    if (!this.filters || !this.filters.startDate || !this.filters.endDate) {
      this.getAndApplyDatasetInfo();
    }
  }

  getReport() {
    this.form.markAsDirty();
    if (this.form.invalid) {
      return;
    }
    this.actionStream.next();
  }

  private getReport$() {
    const accountType = this.form.controls.groupByAccountType
      .value as GroupByAccountType;
    let accountIds =
      accountType === GroupByAccountType.Account
        ? this.form.controls.accounts.value.map((account) => account.id)
        : this.form.controls.sourceAccounts.value.map(
            (sourceAccount) => sourceAccount.id
          );
    let includeApiLedgers = this.form.controls.includeApiLedgers.value;

    if (this.form.controls.allAccounts.value) {
      accountIds = null;
      includeApiLedgers = false;
    }

    let category = this.form.controls.journalCategory
      .value as JournalCategoryForLedger;
    if (category === JournalCategoryForLedger.Normal) {
      category = null;
    }

    const loadingStream = new Subject();
    this.busy.update = loadingStream.subscribe();

    let get$: Observable<AccountTransaction[]>;
    if (accountType === GroupByAccountType.Account) {
      get$ = this._ledgerReportService.getLedgerByAccount$(
        this.datasetId,
        accountIds,
        this.form.controls.startDate.value as Date,
        this.form.controls.endDate.value as Date,
        (<any>category) as JournalCategory,
        includeApiLedgers
      );
    } else {
      get$ = this._ledgerReportService.getLedgerBySourceAccount$(
        this.datasetId,
        accountIds,
        this.form.controls.startDate.value as Date,
        this.form.controls.endDate.value as Date,
        (<any>category) as JournalCategory,
        includeApiLedgers
      );
    }

    return get$.pipe(
      catchError((err) => {
        this.showError(err);
        return of([] as AccountTransaction[]);
      }),
      tap((r) => {
        this.optimiseAccountsAppearance(r);
        this.accountTransactions = r;
        this.gridOptions.groupIncludeTotalFooter =
          ArrayHelpers.groupBy(r, (l) => l.sourceAccount.id).length > 1;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  private getAndApplyDatasetInfo() {
    this._datasetService.getLight$(this.datasetId).subscribe(
      (dataset) => {
        if (dataset.isGroupDataset) this.isGroupDataset = true;
        if (this.isGroupDataset) {
          this.form.controls['groupByAccountType'].setValue(
            GroupByAccountType.Account
          );
        }
        this.startDate = dataset.startDate;
        this.endDate = dataset.endDate;
        this.form.controls['startDate'].patchValue(dataset.startDate);
        this.form.controls['endDate'].patchValue(dataset.endDate);
      },
      (e) => {
        console.error('Error retrieving dataset default dates', e);
      }
    );
  }

  private optimiseAccountsAppearance(ledgers: AccountTransaction[]) {
    // for each account, calculate sub totals
    const subTotals1: { accountId: string; subTotal: number }[] = [];
    const subTotals2: { accountId: string; subTotal: number }[] = [];
    ledgers.forEach((ledger) => {
      if (ledger.account) {
        let subTotal = subTotals1.find(
          (a) => a.accountId === ledger.account.id
        );
        if (!subTotal)
          subTotals1.push(
            (subTotal = { accountId: ledger.account.id, subTotal: 0 })
          );
        subTotal.subTotal += ledger.balance;
        ledger.subTotal = subTotal.subTotal;
      } else if (ledger.sourceAccount) {
        let subTotal = subTotals2.find(
          (a) => a.accountId === ledger.sourceAccount.id
        );
        if (!subTotal)
          subTotals2.push(
            (subTotal = { accountId: ledger.sourceAccount.id, subTotal: 0 })
          );
        subTotal.subTotal += ledger.balance;
        ledger.subTotal = subTotal.subTotal;
      }
    });

    // for each account, how many source accounts?
    const accounts: { accountId: string; sourceAccountIds: string[] }[] = [];
    ledgers.forEach((ledger) => {
      if (!ledger.account) return;
      const account = accounts.find((a) => a.accountId === ledger.account.id);
      if (account) {
        if (
          account.sourceAccountIds.some((id) => id === ledger.sourceAccount.id)
        )
          return;
        account.sourceAccountIds.push(ledger.sourceAccount.id);
      } else {
        accounts.push({
          accountId: ledger.account.id,
          sourceAccountIds: [ledger.sourceAccount.id],
        });
      }
    });

    ledgers.forEach((ledger) => {
      if (!ledger.account || !ledger.sourceAccount) return;

      // If there is more than one source account for this account, leave as is
      const account = accounts.find(
        (account) => account.accountId === ledger.account.id
      );
      if (account && account.sourceAccountIds.length > 1) return;

      if (
        ledger.sourceAccount &&
        ledger.account.accountNo === ledger.sourceAccount.accountNo &&
        ledger.account.accountName === ledger.sourceAccount.accountName
      ) {
        ledger.account = null;
      }
    });
  }

  private customiseGridOptions(gridOptions: GridOptions) {
    gridOptions.suppressHorizontalScroll = true;
    gridOptions.enableRangeSelection = true;
    gridOptions.suppressCellSelection = false;
    gridOptions.frameworkComponents = accountingRenderers;
    gridOptions.groupDefaultExpanded = 2;
    //gridOptions.groupUseEntireRow = true;
    gridOptions.groupIncludeFooter = true;
    gridOptions.groupIncludeTotalFooter = true;

    // Column Group Formatting
    gridOptions.autoGroupColumnDef = {
      headerName: 'Account',
      cellRendererParams: {
        suppressCount: true,
        footerValueGetter: this.getFooter,
      },
    };

    // Full Row Group Formatting
    gridOptions.groupRowRendererParams = {
      suppressCount: true,
      footerValueGetter: this.getFooter,
    };

    const search$ = this.search.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((data) => {
        if (gridOptions.api) gridOptions.api.setQuickFilter(data);
      });
    this.subscriptions.push(search$);
  }

  memoCellRendererParams = {
    footerValueGetter: this.getFooter,
  };

  getFooter(params) {
    const balance = params.node.aggData.debit - params.node.aggData.credit;
    const balanceType = balance >= 0 ? 'Dr' : 'Cr';
    const balanceString = decimalPipe.transform(
      balance >= 0 ? balance : -balance,
      '1.2-2'
    );
    const accountName = params.value ? params.value : 'All Accounts';
    return 'Total ' + accountName + ' - ' + balanceString + ' ' + balanceType;
  }

  private showError(error) {
    this.error = error;
  }
}
