import { Component, OnInit } from '@angular/core';
import { Config } from '../../config';

@Component({
  selector: 'crs-nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.scss']
})
export class NavFooterComponent implements OnInit {

  version: string;
  year: number;

  constructor() {
    this.version = Config.version;
    this.year = new Date().getFullYear();

  }

  ngOnInit() {
  }

}
