import { Injectable } from '@angular/core';
import { map} from 'rxjs/operators';

import { ApiService } from 'src/app/core';
import { AccountLayout, MasterAccountLayout } from '../../';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  readonly base: string = 'api/accounting/reports/layout';

  constructor(private apiService: ApiService) { }

  getMasterPageAccountLayoutExisting(masterPageId: string) {
    return this.apiService
      .get<MasterAccountLayout[]>(this.base + '/master/' + masterPageId + '/layout')
      .pipe(map(list => list.map(l => new MasterAccountLayout(l))));
  }

  getMasterPageAccountLayoutNew(pageTypeId: number) {
    return this.apiService
      .get<MasterAccountLayout[]>(this.base + '/master/' + pageTypeId + '/layout')
      .pipe(map(list => list.map(l => new MasterAccountLayout(l))));
  }

  getTemplatePageAccountLayoutExisting(fileId: string, templatePageId: string) {
    let url = this.base + '/template/' + templatePageId + '/layout';
    url = this.apiService.addQuery(url, 'fileId', fileId);
    return this.apiService
      .get<AccountLayout[]>(url)
      .pipe(map(list => list.map(l => new AccountLayout(l))));
  }

  getTemplatePageAccountLayoutNew(fileId: string, pageTypeId: number) {
    let url = this.base + '/template/' + pageTypeId + '/layout';
    url = this.apiService.addQuery(url, 'fileId', fileId);
    return this.apiService
      .get<AccountLayout[]>(url)
      .pipe(map(list => list.map(l => new AccountLayout(l))));
  }
}
