<div class="row mb-3 pad-x">
  <div class="col-lg-12 account-toolbar">
    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
      <div class="icon-input-wrapper flex-grow-1">
        <i aria-hidden="true" class="fas fa-search input-icon"></i>
        <input
          aria-describedby="searchIcon"
          placeholder="Search accounts..."
          type="search"
          [formControl]="searchControl"
        />
      </div>

      <div class="source-select ml-2">
        <ng-select
          bindLabel="name"
          placeholder="Source"
          [formControl]="sourceControl"
          [items]="sources"
          [virtualScroll]="true"
        >
          <ng-template let-index="index" let-source="item" ng-option-tmp>
            <div class="d-flex">
              <img class="icon-img mr-2" [src]="source.sourceType.icon" />
              <crs-truncated-name [length]="18" [name]="source.name" />
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div class="class-select ml-2">
        <ng-select
          bindLabel="name"
          bindValue="value"
          placeholder="Class"
          [formControl]="classificationIdControl"
          [items]="classifications | enumToArray : true"
          [virtualScroll]="true"
        />
      </div>

      <div class="division-select ml-2">
        <ng-select
          bindLabel="name"
          bindValue="id"
          placeholder="Division"
          [formControl]="divisionIdControl"
          [items]="divisions"
          [virtualScroll]="true"
        />
      </div>

      <div class="trading-account-select ml-2">
        <ng-select
          bindLabel="name"
          bindValue="id"
          placeholder="Trading account"
          [formControl]="tradingAccountIdControl"
          [items]="tradingAccounts"
          [virtualScroll]="true"
        />
      </div>

      <div class="status-select ml-2">
        <ng-select
          bindLabel="name"
          bindValue="id"
          placeholder="Status"
          [formControl]="selectedStatusControl"
          [items]="statuses"
          [virtualScroll]="true"
        />
      </div>

      <div class="ml-2">
        <button
          aria-label="Clear filters"
          class="btn"
          type="button"
          [disabled]="
            !searchControl.value &&
            !sourceControl.value &&
            !classificationIdControl.value &&
            !divisionIdControl.value &&
            !tradingAccountIdControl.value &&
            !selectedStatusControl.value
          "
          (click)="onClickClearFilters()"
        >
          Clear filters
        </button>
      </div>

      <div class="vertical-line-toolbar mx-3"></div>

      <div class="ml-2">
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="dataSource.busy"
          (click)="onClickAddSourceAccount()"
        >
          <i aria-hidden="true" class="fas fa-plus"></i>
          Add Source Account
        </button>
      </div>
    </div>
  </div>
</div>

<hr style="margin: 0" />

<div class="row">
  <div class="col-md-12 d-flex">
    <div style="width: 100%">
      <crs-busy *ngIf="dataSource.busy"></crs-busy>

      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowSelection="multiple"
        style="width: 100%; height: 100%"
        [enableRangeSelection]="true"
        [frameworkComponents]="renderers"
        [gridOptions]="gridOptions"
        [suppressCellSelection]="false"
      >
        <ag-grid-column
          cellRenderer="sourceRenderer"
          colId="source"
          field="source"
          headerName="Source"
          [maxWidth]="400"
          [minWidth]="200"
        />
        <ag-grid-column
          colId="accountNo"
          field="accountNo"
          headerName="Account No"
          [maxWidth]="200"
          [minWidth]="100"
        />
        <ag-grid-column
          colId="accountName"
          field="accountName"
          headerName="Account Name"
          [minWidth]="250"
        />
        <ag-grid-column 
          colId="taxCode"
          field="taxCode"
          headerName="Tax Code"
          [maxWidth]="150"
          [minWidth]="100"
        />
        <ag-grid-column
          cellRenderer="classificationRenderer"
          colId="classification"
          field="classification"
          headerName="Class"
          tooltipField="classificationToolTipText"
          [maxWidth]="150"
          [minWidth]="100"
        />
        <ag-grid-column
          colId="division"
          field="division.name"
          headerName="Division"
        />
        <ag-grid-column
          colId="tradingAccount"
          field="tradingAccount.name"
          headerName="Trading Account"
          [maxWidth]="170"
          [minWidth]="100"
        />
        <ag-grid-column
          colId="hasEntries"
          field="hasEntriesText"
          headerName="Status"
          [maxWidth]="150"
          [minWidth]="100"
        />
        <ag-grid-column
          colId="activity"
          field="inactiveText"
          headerName="Activity"
          [maxWidth]="150"
          [minWidth]="100"
        />
        <ag-grid-column
          pinned="none"
          type="optionsColumn"
          [cellRendererParams]="{ ngTemplate: optionsCell }"
        />
      </ag-grid-angular>
    </div>
  </div>
</div>

<ng-template #optionsCell let-row>
  <div class="btn-mini-menu">
    <a
      aria-expanded="false"
      aria-haspopup="true"
      class="btn btn-sm fas-btn-primary-ghost"
      data-toggle="dropdown"
    >
      <i aria-hidden="true" class="fas fa-ellipsis-v"></i>
      <span class="sr-only">Show Account Options</span>
    </a>
    <div class="dropdown-menu">
      <button
        class="btn dropdown-item text-info"
        type="button"
        (click)="onClickEditSourceAccount(row)"
      >
        Edit Source Account
      </button>
      <button
        class="btn dropdown-item text-danger"
        type="button"
        *ngIf="!row?.hasEntries"
        (click)="onClickDeleteSourceAccount(row)"
      >
        Delete Source Account
      </button>
    </div>
  </div>
</ng-template>
