import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'crs-accounts-root',
  templateUrl: './accounts-root.component.html',
})
export class AccountsRootComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
