import { User } from '../users'
import { Office } from './office'

export enum OfficeUserRole {
  User = 0,
  Admin = 1
}

export class OfficeUser {
  id: number;
  user: User;
  office: Office;
  role: OfficeUserRole;
  isPartner: boolean;
  isManager: boolean;
  isDefaultPartner: boolean;

  constructor(data) {
    Object.assign(this, data);
    if (data.user) this.user = new User(data.user);
    if (data.office) this.office = new Office(data.office);
  }
}

export class OfficeUserModel {
  id: number;
  userId: string;
  officeId: string;
  role: OfficeUserRole;
  isPartner: boolean;
  isManager: boolean;
  isDefaultPartner: boolean;

  constructor(data) {
    Object.assign(this, data);
    if (data.user) this.userId = data.user.id;
    if (data.office && data.office.id !== 'add') this.officeId = data.office.id;
  }
}


