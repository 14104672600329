import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiError } from 'src/app/core/interceptors/api-error';

@Component({
  selector: 'crs-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {
  private _error: string | string[];
  @Input() isWarning: boolean = false;
  @Input() isInfo: boolean = false;

  @Input() set error(value: string | string[] | ApiError) {
    let error: string | string[];

    if (value instanceof ApiError) {
      error = value.messages;
      this.isWarning = value.isWarning;
    } else {
      error = value;
    }

    this._error = error;
    this.isArray = Array.isArray(error);
  }

  @Output() errorDismissed = new EventEmitter<any>();

  get error() {
    return this._error;
  }

  isArray = false;

  constructor() {}

  getErrorClasses() {
    if (this.isWarning) {
      return 'alert alert-warning alert-dismissible fade show';
    }
    if (this.isInfo) {
      return 'alert alert-info alert-dismissible fade show';
    }
    return 'alert alert-danger alert-dismissible fade show';
  }

  public dismissError() {
    this.error = null;
    this.errorDismissed.emit();
  }
}
