import { FinancialContentInfo } from './report-content-info';
import { ReportContentInfo } from './../../report-content/report-content-info';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { FinancialColumnHeaderRow } from './financial-table';
import { ReportMetaData } from '../../report-output';
import {NumberFormat} from '../../../enums';
import {FinancialElementType} from '../financial/financial-element-type';


@Component({
  selector: 'crs-report-financial',
  templateUrl: './report-financial.component.html'
})
export class ReportFinancialComponent {

  private _element: any;
  @Input() set element(value: any) {
    if (this._element !== value) {
      this._element = value;
      if (this._element) {
        this.dummyHeader = {
          financialType: 0,
          noteText: 'Note',
          columnHeaders: this.buildDummyHeaders(2)
        };
        this.financialContent.content = this._element.elements;
        this.financialContent.columns = this._element.header &&
          this._element.header.columnHeaders &&
          this._element.header.columnHeaders.length ?
            this._element.header.columnHeaders.map(c => c.metadata) :
            this.dummyHeader.columnHeaders.map(c => c.metadata);
      }
      this.calculateWidths();
    }
  }

  get element(): any {
    return this._element;
  }
  @Input() index: number;
  @Input() set reportContent(value: ReportContentInfo) {
    this.financialContent.reportContent = value;
  }
  get reportContent(): ReportContentInfo {
    return this.financialContent.reportContent;
  }

  get output() {
    return this.financialContent && this.financialContent.output;
  }

  get noOfColumns() {
    return this.financialContent.columns.length;
  }
  @Input() reportMetaData: ReportMetaData;
  firstColumnWidth;
  noteWidth = 50;
  columnWidth = 100;
  financialContent = new FinancialContentInfo();

  dummyHeader: FinancialColumnHeaderRow;

  public financialElementTypes = FinancialElementType;

  constructor(private readonly _sanitizer: DomSanitizer) {

  }

  private calculateWidths() {
    const columns = typeof this.element.noOfColumns === 'number' ? // XSS Check
      this.element.noOfColumns :
      this.dummyHeader.columnHeaders.length;
    const existingWidth = Math.min(columns, 4) * this.columnWidth + this.noteWidth;
    this.firstColumnWidth = this._sanitizer.bypassSecurityTrustStyle('calc(100% - ' + existingWidth + 'px)');
  }

  private buildDummyHeaders(number: number) {
    const columnHeaders = [];
    for (let i = 1; i <= number; i++) {
      const header =     {
        periodText: 'Period ' + i,
        signText: '$',
        Metadata: {
          numberFormat: NumberFormat.Normal
        }
      };
      columnHeaders.push(header);
    }
    return columnHeaders;
  }

  dropRow(event) {
    const oldIndex = event.previousIndex;
    const newIndex = event.currentIndex;
    if (oldIndex !== newIndex) moveItemInArray(this.financialContent.content, oldIndex, newIndex);
  }



}
