import { TaxLabelBalance } from './taxLabelBalance';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/core';
import { TaxForm } from './taxForm';

@Injectable({
  providedIn: 'root'
})
export class TaxFormService {

  readonly base: string = 'api/accounting/taxforms';

  constructor(private apiService: ApiService) { }

  getAll() {
    return this.apiService.get<TaxForm[]>(this.base).pipe(map(d => d));
  }

  getBalances(formId, datasetId) {
    return this.apiService.get<TaxLabelBalance[]>(this.base + '/' + formId + '/balances/' + datasetId)
      .pipe(map(d => d.map(i => new TaxLabelBalance(i))));
  }

  public getByFileId(fileId: string): Observable<TaxForm> {
    return this.apiService.get<TaxForm>(`${this.base}/file/${fileId}`);
  }

}
