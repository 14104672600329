import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

const HEADERS_URL = 'headers-accounts-and-tax-codes/headers';
const ACCOUNTS_URL = 'headers-accounts-and-tax-codes/accounts';

@Component({
  selector: 'crs-headers-accounts-and-tax-codes',
  templateUrl: './headers-accounts-and-tax-codes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadersAccountsAndTaxCodesComponent implements OnInit {
  headersOrAccountRoute = 'headers';

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.router.url.includes(HEADERS_URL)) {
          this.headersOrAccountRoute = 'headers';
        }
        if (this.router.url.includes(ACCOUNTS_URL)) {
          this.headersOrAccountRoute = 'accounts';
        }
      });
  }

  ngOnInit(): void {
    this.route.firstChild.url.subscribe((segments) => {
      if (segments[0].path !== 'tax-codes')
        this.headersOrAccountRoute = segments[0].path;
    });
  }
}
