export enum DepreciationType {
  Taxation = 1,
  Accounting = 2
}

export enum DepreciationTypeOption {
  All = 0,
  Taxation = 1,
  Accounting = 2
}


