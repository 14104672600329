import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import {
  catchError,
  exhaustMap,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import { ActiveFileService } from '../../active-file.service';
import { ApiType, SourceModel, SourceService } from '../../sourcedata';
import { MessageService } from 'src/app/core';

@Component({
  selector: 'crs-file-feeds-activate-chart-of-accounts.component',
  templateUrl: './file-feeds-activate-chart-of-accounts.component.html',
})
export class FileFeedsActivateChartOfAccountsComponent
  implements OnInit, OnDestroy
{
  busy = {
    load: null,
    submit: null,
  };
  error: string = null;

  private submitButtonStream$ = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(
    private activeFileService: ActiveFileService,
    private activeModal: NgbActiveModal,
    private messageService: MessageService,
    private sourceService: SourceService
  ) {}

  ngOnInit(): void {
    this.configureSubmit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onClickClose(): void {
    this.activeModal.dismiss();
  }

  onClickActivateChartOfAccounts(): void {
    this.submitButtonStream$.next();
  }

  private configureSubmit(): void {
    const submitButtonStreamSubscription = this.submitButtonStream$
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submit$())
      )
      .subscribe((source) => {
        this.activeModal.close(source);
      });

    this.subscriptions.push(submitButtonStreamSubscription);
  }

  private submit$(): Observable<any> {
    const loading$ = new Subject();
    this.busy.submit = loading$.subscribe();

    const fileId = this.activeFileService.file.id;
    const submit$ = this.sourceService.getTypesForFile$(fileId);

    return submit$.pipe(
      switchMap((sourceTypes) => {
        const chartOfAccountsSourceType = sourceTypes.find(
          (sourceType) => sourceType.apiType === ApiType.ChartOfAccounts
        );
        const sourceModel = new SourceModel({
          fileId,
          name: chartOfAccountsSourceType.name,
          sourceType: chartOfAccountsSourceType,
        });

        return this.sourceService.post$(sourceModel).pipe(
          map(() => sourceModel),
          catchError((error) => {
            this.showError(error);
            return EMPTY;
          })
        );
      }),
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      }),
      finalize(() => loading$.complete())
    );
  }

  private showError(error) {
    this.error = error;
    this.messageService.error(error);
  }
}
