<crs-report-editable-element
  [index]="index"
  [reportContent]="reportContent"
  [reportMetaData]="reportMetaData"
  (edit)="onEdit()"
  [onSubmit]="onSubmit"
  [editable]="true"
  [cancellable]="true"
>
  <div viewer>
    <p
      [class.align-center]="element.alignment == alignments.Center"
      [class.align-right]="element.alignment == alignments.Right"
      [class.align-justify]="element.alignment == alignments.Justify"
      [class.margin-top]="element.marginTop"
      [class.margin-bottom]="element.marginBottom"
      [class.muted]="element.style == styles.Muted"
      [class.indented]="element.style == styles.Indented"
      *ngIf="element?.text"
    >
      {{ element.text }}
    </p>
  </div>

  <div editor>
    <form [formGroup]="form" novalidate *ngIf="isEditing">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group required">
            <label class="control-label">Style</label>
            <ng-select
              [items]="styles | enumToArray"
              bindValue="value"
              bindLabel="name"
              formControlName="style"
              [clearable]="false"
            ></ng-select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group required">
            <label class="control-label">Alignment</label>
            <ng-select
              [items]="alignments | enumToArray"
              bindValue="value"
              bindLabel="name"
              formControlName="alignment"
              [clearable]="false"
            ></ng-select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check me-3">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="marginTop"
          />
          <label class="form-check-label">Margin Above</label>
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="marginBottom"
          />
          <label class="form-check-label">Margin Below</label>
        </div>
      </div>

      <textarea
        class="form-control required"
        formControlName="text"
        rows="4"
      ></textarea>
    </form>
  </div>
</crs-report-editable-element>
