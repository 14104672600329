import { ActivatedRoute } from '@angular/router';
import { TaxLabelBalance } from './../taxLabelBalance';
import { TaxFormService } from './../tax-form.service';
import { Component, OnInit } from '@angular/core';
import { Observable, EMPTY, of } from 'rxjs';
import { getDefaultGridOptions } from 'src/app/shared';
import { catchError, first } from 'rxjs/operators';
import { IntegrationServerDatasource } from 'src/app/configuration/integration-server/integration-server.datasource';

@Component({
  selector: 'crs-tax-form',
  templateUrl: './tax-form.component.html',
  styleUrls: ['./tax-form.component.scss']
})
export class TaxFormComponent implements OnInit {

  error: string;
  formId: number;
  datasetId: string;

  loading: boolean;
  reportObservable: Observable<TaxLabelBalance[]>;
  gridOptions = getDefaultGridOptions();
  showTaxLabelsThatAreNotTransferrable : string;
  showTaxTransferWarningLabel : boolean;
  connectedToIs: boolean;

  constructor(private readonly taxFormService: TaxFormService, private readonly _route: ActivatedRoute, private _integrationServerDatasource: IntegrationServerDatasource,) { }

  ngOnInit() {
    this._integrationServerDatasource.getStatus()
      .pipe(first())
      .subscribe(res => this.connectedToIs = res.isConnected);

    this.configureGrid();
    this._route.params.subscribe(params => {
      this.formId = +params.id;
      this.datasetId = this._route.snapshot.parent.parent.parent.paramMap.get('id');
      this.getReport();
      this.getTaxLabelsThatAreNotTransferrable();
    });
  }

  configureGrid() {
    this.gridOptions.groupDefaultExpanded = 1;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Form Label',
      cellRendererParams: {
        suppressCount: true
      }
    };
  }

  getReport() {
    this.loading = true;
    this.reportObservable = this.taxFormService.getBalances(this.formId, this.datasetId).pipe(catchError(err => {
      this.showError(err);
      return of([] as TaxLabelBalance[]);
    }));
  }

  // Access Ledger won't transfer few tax labels to HTC. Below logic will notify Access Ledger user on such labels if that exist in the request.
  getTaxLabelsThatAreNotTransferrable()
  {
      this.reportObservable.subscribe( taxLabelBalances =>
        {
           // Below mentioned tax labels are for 'Rent - Gross Rent, Rent - Other rental deduction and Rent - Interest deductions' in Partnership and trust entities.
           // If these tax labels are included in the tax form, show a warning message.
           let nonTransferrableTaxLabelBalance = taxLabelBalances.filter(x => x.taxLabelId == 336 ||
                                                                              x.taxLabelId == 337 ||
                                                                              x.taxLabelId == 338 ||
                                                                              x.taxLabelId == 436 ||
                                                                              x.taxLabelId == 437 ||
                                                                              x.taxLabelId == 438);
           if(nonTransferrableTaxLabelBalance?.length > 0) {
              this.showTaxTransferWarningLabel = true;

              this.showTaxLabelsThatAreNotTransferrable = "";
              let index = 0;
              nonTransferrableTaxLabelBalance.forEach(element => {
                index++;
                if(element.taxLabel.name)
                {
                  // Append the warning message
                  this.showTaxLabelsThatAreNotTransferrable += element.taxLabel.name;

                  // Add comma and a space until the last element
                  if(index < nonTransferrableTaxLabelBalance.length) this.showTaxLabelsThatAreNotTransferrable += ", ";
                }
              });
           }
           else {
              this.showTaxTransferWarningLabel = false;
           }
        });
  }

  showError(err) {
    this.error = err;
  }
}
