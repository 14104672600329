<div class="modal-header">
  <h4 class="modal-title">{{ modalTitle }}</h4>
  <div class="step-title">
    <span>{{ stepTitle }}</span>
    <button
      aria-label="Close"
      class="close"
      type="button"
      (click)="onClickClose()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<crs-step-progress-bar
  *ngIf="getCurrentStep() < formFlowSteps.RequestSent"
  [currentStep]="getCurrentStep()"
  [totalSteps]="totalSteps"
/>

<div class="modal-body">
  <crs-form-error [error]="error" />

  <mat-stepper #stepper>
    <mat-step [stepControl]="countryAndImportTypeForm">
      <form [formGroup]="countryAndImportTypeForm">
        <div class="form-container">
          <div class="col-6">
            <p class="control-label">Select country</p>
          </div>
          <div class="form-group col-6">
            <div class="d-flex select-country">
              <input
                formControlName="country"
                id="australia"
                type="radio"
                [value]="CountryType.Australia"
              />
              <label class="control-label pl-2" for="australia">
                Australia
              </label>
            </div>
            <div class="d-flex select-country">
              <input
                formControlName="country"
                id="newZealand"
                type="radio"
                [value]="CountryType.NewZealand"
              />
              <label class="control-label pl-2" for="newZealand">
                New Zealand
              </label>
            </div>
          </div>
        </div>
        <hr />
        <div class="form-container">
          <div class="col-6">
            <p class="control-label">Select import type</p>
          </div>
          <div class="form-group col-6">
            <div
              class="d-flex select-country"
              *ngIf="permissions.canSendClientRequest"
            >
              <input
                formControlName="connectionType"
                id="bankConnection"
                type="radio"
                [value]="BankConnectionTypeList.BankConnection"
              />
              <label class="control-label pl-2" for="bankConnection">
                Bank connections
              </label>
            </div>
            <div class="d-flex select-country">
              <input
                formControlName="connectionType"
                id="manualImport"
                type="radio"
                [value]="BankConnectionTypeList.ManualImport"
              />
              <label class="control-label pl-2" for="manualImport">
                Manual import
              </label>
            </div>
          </div>
        </div>
      </form>
    </mat-step>

    <ng-container
      *ngIf="
        selectedBankConnectionType === BankConnectionTypeList.BankConnection;
        then emailFormTemplate;
        else manualImportFormTemplate
      "
    >
    </ng-container>

    <ng-template #emailFormTemplate>
      <mat-step [stepControl]="emailForm">
        <form
          id="emailForm"
          [formGroup]="emailForm"
          (validSubmit)="onCheckEmailValidSubmit()"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group required">
                <label class="control-label">Email</label>
                <input
                  autocomplete="email"
                  class="form-control"
                  formControlName="email"
                  placeholder="Email"
                  type="text"
                />
              </div>
            </div>
          </div>
        </form>
      </mat-step>
    </ng-template>

    <ng-template #manualImportFormTemplate>
      <mat-step [stepControl]="manualInputForm">
        <form
          id="manualInputForm"
          [formGroup]="manualInputForm"
          (validSubmit)="onValidManualImportFormSubmit()"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="form-group required">
                <label class="control-label">Account name</label>
                <input
                  class="form-control"
                  formControlName="accountName"
                  placeholder="Account name"
                  type="text"
                />
              </div>
            </div>
          </div>
        </form>
      </mat-step>
    </ng-template>

    <mat-step [stepControl]="bankConnectionForm">
      <form
        id="bankConnectionForm"
        [formGroup]="bankConnectionForm"
        (validSubmit)="onValidBankConnectionFormSubmit()"
      >
        <div>
          <label class="mb-3">
            <strong>Email:</strong> {{ emailForm.controls.email.value }}
          </label>
        </div>
        <div
          class="client-info-container"
          *ngIf="existingBankAccounts.length"
          [class.mb-3]="existingBankAccounts.length"
        >
          <div class="form-group">
            <label class="mb-3">
              <strong>Select existing bank accounts</strong>
            </label>
            <ag-grid-angular
              class="ag-theme-material"
              id="myGrid"
              style="width: 100%"
              [gridOptions]="gridOptions"
              [rowData]="existingBankAccounts"
              (click)="onClickGrid()"
            >
              <ag-grid-column
                field="accountName"
                headerName="Bank account"
                suppressMenu="true"
              />
              <ag-grid-column
                field="accountNumber"
                headerName="Account number"
                suppressMenu="true"
              />
              <ag-grid-column
                field="feedName"
                headerName="Feed collection name"
                suppressMenu="true"
              />
              <ag-grid-column
                field="isSelected"
                headerName="Select"
                suppressMenu="true"
                type="booleanColumn"
                [cellClass]="getCellClass"
                [cellRendererParams]="{ isToggleable: true }"
                [maxWidth]="120"
                [minWidth]="120"
              />
            </ag-grid-angular>
          </div>
        </div>
        <div class="client-info-container">
          <div
            *ngIf="this.existingBankAccounts.length > 0; else newConnection"
            [ngClass]="{
              'form-group':
                this.bankConnectionForm.value.createNewBankConnection
            }"
          >
            <div
              class="panel-header-checkbox"
              *ngIf="
                this.countryAndImportTypeForm.value.country ===
                  CountryType.Australia;
                else newZealandConenction
              "
              [ngClass]="{
                'panel-header-border pb-2':
                  this.bankConnectionForm.value.createNewBankConnection
              }"
            >
              <crs-check-box formControlName="createNewBankConnection">
              </crs-check-box>
              <strong class="form-check-label">
                Create new bank connection
              </strong>
            </div>
            <ng-template #newZealandConenction>
              <div>
                Client can login to my.akahu.nz with the email address above to
                add new bank accounts.
              </div>
            </ng-template>
          </div>
          <ng-template #newConnection>
            <div
              [ngClass]="{
                'form-group panel-header-border pb-3':
                  this.bankConnectionForm.value.createNewBankConnection
              }"
            >
              <strong>Create new bank connection</strong>
            </div>
          </ng-template>

          <div
            *ngIf="
              this.bankConnectionForm.value.createNewBankConnection === true
            "
          >
            <div class="form-group">
              <strong>Enter client details</strong>
            </div>
            <div class="form-group required">
              <label class="control-label">Client Name</label>
              <input
                class="form-control"
                formControlName="clientName"
                placeholder="Client Name"
                type="text"
              />
            </div>
            <div class="form-group required">
              <label class="control-label">Advisor</label>
              <crs-user-select formControlName="user" [clearable]="false" />
            </div>
            <div class="form-group required mb-0">
              <label class="control-label">Commencement Date</label>
              <crs-date-picker formControlName="commencementLocalDate" />
            </div>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step>
      <div class="request-completed-text">
        <span class="d-flex mb-1">
          Client has
          {{
            selectedCountry === CountryType.Australia ? '60 minutes' : '7 days'
          }}
          to complete this bank connection. You can follow the status of the
          connection in the main page.
        </span>
      </div>
      <hr />
      <div class="form-inline row p-3">
        <p>
          The authorisation flow can be also completed by providing the client
          with the following link.
        </p>
      </div>
    </mat-step>
  </mat-stepper>
</div>

<div class="modal-footer footer-button-container">
  <button
    class="btn btn-secondary"
    type="button"
    *ngIf="getCurrentStep() !== formFlowSteps.RequestSent"
    (click)="onClickClose()"
  >
    Cancel
  </button>

  <!-- Request sent -->
  <button
    class="btn btn-secondary"
    *ngIf="getCurrentStep() === formFlowSteps.RequestSent"
    [cdkCopyToClipboard]="redirectUri"
    (cdkCopyToClipboardCopied)="onCopiedToClipboard()"
  >
    Copy authorisation link
  </button>

  <div>
    <button
      class="btn btn-secondary"
      type="button"
      *ngIf="
        getCurrentStep() > formFlowSteps.CountryAndImportType &&
        getCurrentStep() !== formFlowSteps.RequestSent
      "
      (click)="onClickPreviousStep()"
    >
      Previous
    </button>
    <button
      class="btn btn-primary ml-2"
      type="button"
      *ngIf="getCurrentStep() === formFlowSteps.CountryAndImportType"
      (click)="onClickNextStep()"
    >
      Next step
    </button>

    <!-- Check email form submit -->
    <button
      class="btn btn-primary ml-2"
      form="emailForm"
      type="submit"
      *ngIf="
        getCurrentStep() === formFlowSteps.EnterEmailOrManualImport &&
        selectedBankConnectionType === BankConnectionTypeList.BankConnection
      "
      [disabled]="emailForm.invalid"
      [promiseBtn]="busy.submit"
    >
      Next step
    </button>

    <!-- Bank connection form submit -->
    <button
      class="btn btn-primary ml-2"
      form="bankConnectionForm"
      type="submit"
      *ngIf="
        getCurrentStep() === formFlowSteps.CompleteSetup &&
        selectedBankConnectionType === BankConnectionTypeList.BankConnection
      "
      [disabled]="bankConnectionForm.invalid"
      [promiseBtn]="busy.submit"
      (validSubmit)="onValidBankConnectionFormSubmit()"
    >
      Add accounts
    </button>

    <!-- Manual input form submit -->
    <button
      class="btn btn-primary ml-2"
      form="manualInputForm"
      type="submit"
      *ngIf="
        getCurrentStep() === formFlowSteps.EnterEmailOrManualImport &&
        selectedBankConnectionType === BankConnectionTypeList.ManualImport
      "
      [disabled]="manualInputForm.invalid"
      [promiseBtn]="busy.submit"
      (validSubmit)="onValidManualImportFormSubmit()"
    >
      Add connection
    </button>

    <!-- Request sent -->
    <button
      class="btn btn-primary"
      type="button"
      *ngIf="getCurrentStep() === formFlowSteps.RequestSent"
      (click)="onClickClose()"
    >
      Done
    </button>
  </div>
</div>
