import { ProfitDisplayOption } from '../../../../enums/profit-display-option';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { MasterAccountLayout } from '../../../../../ledger/masterAccounts';
import { LayoutOption, LayoutService } from 'src/app/accounting/reports/layout';
import { getDefaultGridOptions, accountingRenderers } from 'src/app/shared';
import { MessageService } from './../../../../../../core/messages/message.service';
import { ReportPageType } from 'src/app/accounting/reports/reportPages';
import { ProfitLossLayoutOption } from 'src/app/accounting/reports/enums/profit-loss-layout-option';

@Component({
  selector: 'crs-report-master-financial-page-detail',
  templateUrl: './report-master-financial-page-detail.component.html',
  styleUrls: ['./report-master-financial-page-detail.component.scss'],
})
export class ReportMasterFinancialPageDetailComponent {

  @Input('index') i: number;
  @Input() pageId: string = null;
  @Input() detail: UntypedFormGroup;

  @Input() set open(value: boolean) {
    if (value) this.getLayout();
  }

  private _pageType: ReportPageType;
  @Input() set pageType (value: ReportPageType) {
    this._pageType = value;
    if (value) this.showSeparateTradingAccounts = value.canUseTradingAccounts;
  }
  get pageType() {
    return this._pageType;
  }

  loaded = false;
  busy = {
    load: null
  };

  layout: MasterAccountLayout[];
  showSeparateTradingAccounts = false;
  profitDisplayOptions = ProfitDisplayOption;
  profitlossLayoutOptions = ProfitLossLayoutOption;

  gridOptions = getDefaultGridOptions();
  groupColumnDef = {
    rowDrag: params => params.data && params.data.isSortable,
    headerName: 'Layout',
    width: 250,
    menuTabs: [],
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'accountHeaderRenderer'
    },
    valueGetter: params => {
      return params.data.accountName;
    }
  };

  constructor(private readonly _layoutService: LayoutService,
    private readonly _messageService: MessageService) {
    this.customiseGridOptions();
   }

  customiseGridOptions() {
    this.gridOptions.frameworkComponents = accountingRenderers;
    this.gridOptions.immutableData = true;
    this.gridOptions.treeData = true;
    this.gridOptions.animateRows = true;
    this.gridOptions.suppressCellSelection = true;
    this.gridOptions.groupDefaultExpanded = -1;
    this.gridOptions.getDataPath = data => data.hierarchy;
    this.gridOptions.getRowNodeId = data => data.id;
    this.gridOptions.onRowGroupOpened = this.toggleExpand;
    this.gridOptions.defaultColDef.sortable = false;
    this.gridOptions.onFirstDataRendered = this.updateExpandCollapse;
  }

  private updateExpandCollapse(params) {
    const api = params ? params.api : this.gridOptions.api;
    if (!api) return;
    api.forEachNode(n => {
      const data  = n.data as MasterAccountLayout;
      if (!data.layoutOption) return;
      n.expanded = data.layoutOption.layoutOption === LayoutOption.Show;
    });
    api.onGroupExpandedOrCollapsed();
  }

  getLayout() {
    if (this.loaded) { return; }

    const layoutObservable = !this.pageId ? this._layoutService.getMasterPageAccountLayoutNew(this.pageType.id) :
      this._layoutService.getMasterPageAccountLayoutExisting(this.pageId);

    this.busy.load = layoutObservable.subscribe(data => {
      this.layout = this.processLayout(data);
      this.detail.controls.layoutFull.setValue(this.layout);
      this.loaded = true;
    },
    err => this.showError(err));
  }

  processLayout(layout: MasterAccountLayout[]) {
    const placeholders = layout.slice();
    layout.forEach(l => {

      // Already has child accounts? skip.
      const position = l.hierarchy.length - 1;
      if (layout.some(f => f.hierarchy.length > position + 1 && f.hierarchy[position] === l.id)) return;

      const placeholder = new MasterAccountLayout({
        id: l.id * 10,
        accountName: '[Child accounts]',
        hierarchy: Array.from(l.hierarchy),
        isPlaceholder: true
      });
      placeholder.hierarchy.push(placeholder.id);
      placeholders.push(placeholder);
    });
    return placeholders;
  }

  toggleShowAsNote(row) {
    const data = row as MasterAccountLayout;
    if (!data.layoutOption) return;

    if (data.layoutOption.showAsNote) {
      const node = this.gridOptions.api.getRowNode(<string><any>data.id);
      data.layoutOption.layoutOption = node.expanded ? LayoutOption.Show : LayoutOption.Hide;
    } else {
      data.layoutOption.layoutOption = LayoutOption.ShowInNote;
    }
  }

  toggleExpand(node) {
    const data = node.data as MasterAccountLayout;
    if (!data.layoutOption) return;
    if (data.layoutOption.layoutOption !== LayoutOption.ShowInNote) {
      data.layoutOption.layoutOption = data.layoutOption.layoutOption === LayoutOption.Show ? LayoutOption.Hide : LayoutOption.Show;
    }
  }

  showError(err) {
    this._messageService.error(err);
  }

}
