<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/central'">Central</a></li>
    <li class="breadcrumb-item"><a [routerLink]="['../']">Organisations</a></li>
    <li aria-current="page" class="breadcrumb-item active">
      {{ isAdd ? 'New' : '' }} Firm
    </li>
  </ol>
</nav>

<form
  novalidate
  [formGroup]="form"
  [ngBusy]="busy.load"
  (validSubmit)="submit()"
>
  <h2 class="form-group-title">{{ isAdd ? 'New' : '' }} Organisation</h2>

  <hr />

  <div class="row">
    <div class="col-form-md">
      <div class="form-group" *ngIf="!isAdd">
        <label class="control-label">Id</label>
        <input class="form-control" formControlName="id" readonly type="text" />
      </div>

      <div class="form-group required">
        <label class="control-label">Tenant Id</label>
        <input
          class="form-control"
          formControlName="tenantId"
          placeholder="demo-firm"
          type="text"
          [readonly]="!isAdd"
        />
      </div>

      <div class="form-group required">
        <label class="control-label">Name</label>
        <input
          class="form-control"
          formControlName="name"
          placeholder="My Practice Sydney..."
          type="text"
        />
      </div>

      <div class="form-group">
        <label class="control-label">CRM Id</label>
        <input class="form-control" formControlName="crmId" type="text" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-form-sm">
      <div class="form-group required">
        <label class="control-label">Database</label>
        <crs-database-select
          formControlName="database"
          [readonly]="!isAdd"
        ></crs-database-select>
      </div>

      <div class="form-group required">
        <label class="control-label">Storage Account</label>
        <crs-storage-account-select
          formControlName="storageAccount"
          [readonly]="!isAdd"
        ></crs-storage-account-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-form-md">
      <div class="form-group">
        <label class="control-label">Workpapers Organisation Id</label>
        <input
          class="form-control"
          formControlName="workpapersFirmId"
          type="text"
        />
      </div>

      <div *ngIf="isAdd">
        <h2>First User</h2>
        <hr />

        <div class="form-group required">
          <label class="control-label">Email</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">@</span>
            </div>
            <input class="form-control" formControlName="email" type="text" />
          </div>
        </div>

        <div class="form-group required">
          <label class="control-label">First Name</label>
          <input class="form-control" formControlName="firstName" type="text" />
        </div>

        <div class="form-group required">
          <label class="control-label">Last Name</label>
          <input class="form-control" formControlName="lastName" type="text" />
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!isAdd">
    <div class="col-12">
      <h2 class="form-group-title">Practice Users</h2>

      <hr />

      <div class="btn-toolbar" role="toolbar">
        <div class="btn-menu">
          <button class="btn btn-secondary" type="button" (click)="addOwner()">
            <i aria-hidden="true" class="fas fa-plus inset"></i>
            Add Owner
          </button>
        </div>
      </div>

      <div *ngIf="!isAdd">
        <div>
          <ag-grid-angular
            #agGrid
            class="ag-theme-material"
            style="width: 100%"
            [gridOptions]="gridOptions"
            [rowData]="owners"
          >
            <ag-grid-column field="id" headerName="Id"></ag-grid-column>
            <ag-grid-column field="email" headerName="Email"></ag-grid-column>
            <ag-grid-column
              field="firstName"
              headerName="First Name"
            ></ag-grid-column>
            <ag-grid-column
              field="lastName"
              headerName="Last tName"
            ></ag-grid-column>
            <ag-grid-column
              field="securityRole"
              headerName="Role"
              type="enumColumn"
              [cellRendererParams]="{ enum: securityRoles }"
              [width]="120"
            ></ag-grid-column>
            <ag-grid-column
              type="optionsColumn"
              [cellRendererParams]="{ ngTemplate: optionsCell }"
            ></ag-grid-column>
          </ag-grid-angular>
        </div>

        <ng-template #optionsCell let-row>
          <div class="btn-mini-menu">
            <button
              class="btn-sm fas-btn-primary-ghost"
              title="Invite Expired, Resend Invite"
              type="button"
              *ngIf="row.invitePending"
              (click)="resendInvite(row.id)"
            >
              <i aria-hidden="true" class="fas fa-envelope"></i>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu form-bottom-menu">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      Save and Close
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>
</form>
