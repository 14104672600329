<div class="row" *ngIf="activatedRoute.children.length === 0">
  <div class="col-12">
    <crs-form-error [error]="error"></crs-form-error>
    <div>
      <crs-busy *ngIf="busy.loading"></crs-busy>
      <ag-grid-angular
        class="ag-theme-material ag-grid-full"
        [gridOptions]="gridOptions"
        [rowData]="reportDocuments"
        (cellValueChanged)="onValueChanged($event)"
      >
        <ag-grid-column
          field="name"
          headerName="Name"
          [editable]="isEditable"
          [minWidth]="300"
        ></ag-grid-column>
        <ag-grid-column
          field="modifiedDate"
          headerName="Date Modified"
          type="timeColumn"
          [editable]="false"
          [maxWidth]="300"
          [minWidth]="200"
        ></ag-grid-column>
        <ag-grid-column
          field="financialYear"
          headerName="Financial Year"
          [editable]="false"
          [maxWidth]="200"
          [minWidth]="100"
        ></ag-grid-column>
        <ag-grid-column
          type="optionsColumn"
          [cellRendererParams]="{ ngTemplate: optionsCellSignStatus }"
          [editable]="false"
          [maxWidth]="300"
          [minWidth]="135"
        ></ag-grid-column>
        <ag-grid-column
          type="optionsColumn"
          [cellRendererParams]="{ ngTemplate: optionsCellOffice }"
          [editable]="false"
          [maxWidth]="135"
          [minWidth]="135"
        ></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>

<ng-template #optionsCellSignStatus let-row>
  <div class="d-inline-block" *ngIf="row.reportSignStatus == 1">
    <div>
      <small class="signature-requested-panel">
        <span class="signature-requested-text">Signature Requested</span>
        <i aria-hidden="true" class="fas fa-lock signature-requested-lock"></i>
      </small>
    </div>
  </div>
</ng-template>

<ng-template #optionsCellOffice let-row>
  <div class="btn-mini-menu">
    <button
      class="btn-sm fas-btn-primary-ghost"
      title="Edit Report Document"
      type="button"
      (click)="editReportDocument(row)"
    >
      <i aria-hidden="true" class="fas fa-edit"></i>
    </button>
    <button
      class="btn-sm fas-btn-primary-ghost"
      title="Copy Report Document"
      type="button"
      (click)="copyReportDocument(row)"
    >
      <i aria-hidden="true" class="fas fa-copy"></i>
    </button>
    <button
      class="btn-sm fas-btn-ghost"
      title="Remove Report Document"
      type="button"
      (click)="openRemoveReportDocument(row)"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
    </button>
  </div>
</ng-template>
