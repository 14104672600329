import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { OfficeService } from '../';
import { MessageService } from '../../../core';
import { getDefaultGridOptions, accountingRenderers } from '../../../shared';

@Component({
  selector: 'crs-offices',
  templateUrl: './offices.component.html'
})
export class OfficesComponent implements OnInit {


  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    offices: false
  };
  officesObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;

  constructor(private readonly _officeService : OfficeService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService) { }

  ngOnInit() {
    this.getOffices();
  }

  getOffices() {
    this.busy.offices = true;
    this.officesObservable = this._officeService.getAll().pipe(tap(() => this.busy.offices = false), catchError(err => {
      this.showError(err);
      return of([]);
    }));
  }

  addOffice() {
    this._router.navigate(['./add'], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectOffice(param) {
    this._router.navigate(['./' + param.data.id], { relativeTo: this._route });
  }

}
