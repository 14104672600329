import { Component, Input } from '@angular/core';

@Component({
  selector: 'crs-icon-with-badge',
  templateUrl: './icon-with-badge.component.html',
  styleUrls: ['./icon-with-badge.component.scss'],
})
export class IconWithBadgeComponent {
  @Input() iconClass: string;
  @Input() count: number;
  @Input() disabled: boolean = false;
}
