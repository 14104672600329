import { WorkpaperService } from 'src/app/accounting/ledger/workpapers/workpaper-service';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { accountingRenderers, getDefaultGridOptions } from 'src/app/shared';
import { UntypedFormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { WorkpaperStatus } from 'src/app/shared/components/accounting/workpaper-status-dropdown/workpaper-status-dropdown.component';

@Component({
  selector: 'crs-workpapers-table',
  templateUrl: './workpapers-table.component.html',
  styleUrls: ['./workpapers-table.component.scss'],
})
export class WorkpapersTableComponent implements OnInit, OnChanges {
  @Input() datasetId: string = '';
  @Input() showNonAccountWorkpapers: boolean = false;
  @Input() selectedWorkpaperStatus: number | null = null;
  @Input() search: UntypedFormControl;

  gridOptions = {
    ...getDefaultGridOptions(),
    isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
    doesExternalFilterPass: this.doesExternalFilterPass.bind(this),
    columnDefs: [
      { headerName: 'Non account workpapers', field: 'title' },
      {
        cellRendererFramework:
          accountingRenderers.trialBalanceWorkpaperDetailsRendererComponent,
        colId: 'workpaperDetails',
        field: 'workpaperDetails',
        headerName: 'Workpaper details',
        headerClass: ['centered', 'border-left-cell'],
        cellStyle: {
          'background-color': '#f9f9fa',
          'border-left': '1px solid #d1d1d1',
        },
        flex: 3,
        minWidth: 300,
        maxWidth: 300,
        cellRendererParams: {
          datasetId: this.datasetId,
          getAccountId: (params) => params.data?.accountId,
          getAccountName: (params) => params.data?.accountName,
          refreshGrid: this.refreshGrid.bind(this),
          isFooter: (params) => params?.node.footer,
          isComparative: false,
        },
      },
    ],
    rowData: [],
  };

  constructor(private workpaperService: WorkpaperService) {}

  ngOnInit(): void {
    this.getWorkpapers();

    this.search.valueChanges
      .pipe(tap((v) => this.gridOptions.api.setQuickFilter(v)))
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWorkpaperStatus) {
      this.applyStatusFilter();
    }
  }

  getWorkpapers(): void {
    this.workpaperService
      .getWorkpapersByDataset$({
        datasetId: this.datasetId,
        showNonAccountWorkpapers: this.showNonAccountWorkpapers,
      })
      .subscribe((workpapers) => {
        const workpapersRowData = workpapers.map((workpaper) => {
          return {
            title: workpaper.title,
            workpaperDetails: workpaper,
          };
        });

        this.gridOptions.api.setRowData(workpapersRowData);
        this.applyStatusFilter();
      });
  }

  isExternalFilterPresent() {
    return !!this.search.value || this.selectedWorkpaperStatus !== null;
  }

  doesExternalFilterPass(node) {
    const searchTerm = this.search.value
      ? this.search.value?.toLowerCase()
      : '';

    const workpaperTitle = node.data?.title?.toLowerCase() || '';
    const workpaperStatus =
      node.data.workpaperDetails?.status || WorkpaperStatus.NoWorkpaper;

    const matchesSearchTerm = workpaperTitle.includes(searchTerm);

    const matchesStatus =
      this.selectedWorkpaperStatus === null ||
      workpaperStatus === this.selectedWorkpaperStatus;

    return matchesSearchTerm && matchesStatus;
  }

  applyStatusFilter(): void {
    if (this.gridOptions.api) {
      this.gridOptions.api.onFilterChanged();
    }
  }

  onGridReady(params) {
    this.gridOptions.api = params.api;
    this.gridOptions.columnApi = params.columnApi;
  }

  refreshGrid(): void {
    this.getWorkpapers();
  }
}
