import { LayoutOption } from './../../../layout/layoutOption';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DisclosureLinkOption, DisclosureLink } from '../disclosure-link';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, tap } from 'rxjs/operators';
import { HeaderMatch } from '../../../layout';

@Component({
  selector: 'crs-disclosure-link-modal',
  templateUrl: './disclosure-link-modal.component.html',
  styleUrls: ['./disclosure-link-modal.component.scss']
})
export class DisclosureLinkModalComponent implements OnInit, OnDestroy {

  @Input() params: {
    disclosureLink: DisclosureLink,
    fileId: string
  };

  form = this._formBuilder.group({
    headerMatch: this._formBuilder.group({
      masterAccount: null,
      account: null,
      systemAccount: null
    }),
    linkOption: [DisclosureLinkOption.AppendDetail, Validators.required],
    forceLinkOption: false,
    layoutOption: null,
    placeholderIdentifier: null
  });

  linkOptions = DisclosureLinkOption;
  layoutOptions = LayoutOption;

  subscriptions: Subscription[] = [];

  get accountSelectType(): 'master' | 'account' {
    return this.params.fileId ? 'account' : 'master';
  }

  get headerMatchDisplay() {
    const value = this.form.get('headerMatch').value;
    const match = new HeaderMatch(value);
    return match.display;
  }

  error: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.params.disclosureLink) {
      this.form.patchValue(this.params.disclosureLink);
    }

    const headerMatch = this.form.get('headerMatch');
    this.subscriptions.push(
      headerMatch.get('masterAccount').valueChanges.pipe(
        filter(v => v),
        tap(() => headerMatch.get('account').setValue(null)),
        tap(() => headerMatch.get('systemAccount').setValue(null))
      ).subscribe());
    this.subscriptions.push(
      headerMatch.get('account').valueChanges.pipe(
        filter(v => v),
        tap(() => headerMatch.get('masterAccount').setValue(null)),
        tap(() => headerMatch.get('systemAccount').setValue(null))
      ).subscribe());
    this.subscriptions.push(
      headerMatch.get('systemAccount').valueChanges.pipe(
        filter(v => v),
        tap(() => headerMatch.get('account').setValue(null)),
        tap(() => headerMatch.get('masterAccount').setValue(null))
      ).subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  submit() {
    const disclosureLink = new DisclosureLink(this.form.value);
    if (!disclosureLink.headerMatch.isValid()) {
      this.error = 'You must supply an account';
    } else {
      this._activeModal.close(disclosureLink);
    }
  }

  cancel() {
    this._activeModal.dismiss();
  }


}
