import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'src/app/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { StandardAccountSettingService } from '../../chart/headers-and-accounts/standard-account-setting.service';
import { TaxCodesTableComponent } from './tax-codes-table/tax-codes-table.component';

@Component({
  selector: 'crs-tax-codes',
  templateUrl: './tax-codes.component.html',
  styleUrls: ['./tax-codes.component.scss'],
})
export class TaxCodesComponent implements OnInit {
  @ViewChild('taxCodeTable') taxCodeTable: TaxCodesTableComponent;
  search = '';
  selectedPostingAccount = null;
  selectedBasCode = null;

  basCodes: string[];
  postingAccounts: string[];

  error: string;

  isActivated$: Observable<boolean>;
  isFetchingActivated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private messageService: MessageService,
    private standardAccountSettingService: StandardAccountSettingService
  ) {}

  ngOnInit() {
    this.isActivated$ = this.getActivated$();
  }

  getActivated$(): Observable<boolean> {
    this.isFetchingActivated$.next(true);

    return this.standardAccountSettingService.isActive$().pipe(
      catchError((error) => {
        this.messageService.error(error);
        return EMPTY;
      }),
      finalize(() => this.isFetchingActivated$.next(false))
    );
  }

  onClearFilters() {
    this.search = '';
    this.selectedPostingAccount = null;
    this.selectedBasCode = null;
  }

  onFetchBasCodes(basCodes: string[]) {
    this.basCodes = basCodes;
  }

  onFetchPostingAccounts(postingAccounts: string[]) {
    this.postingAccounts = postingAccounts;
  }

  showError(err: any) {
    this.error = err;
  }
}
