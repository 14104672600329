export class ReportSignatureConstants {
  // Responsible person signatories
  static CLIENT_DECLARATION_SUFFIX = 21;
  static DIRECTOR_REPORT = 101;
  static COMMITTEE_REPORT = 102;
  static CERTIFICATE_BY_MEMBERS_REPORT = 104;

  // Auditor signatories
  static AUDITOR_DECLARATION_REPORT = 23;
  static INDEPENDENT_AUDITOR_REPORT_COMPANY = 12;
  static INDEPENDENT_AUDITOR_REPORT_ASSOCIATION = 14;
  static INDEPENDENT_AUDITOR_REPORT_SMSF = 15;

  // Partner signatories
  static COMPILATION_REPORT = 11;
  static ACCOUNTANT_REPORT_DISCLAIMER = 13;
}

export class SignatureElementTypes {
  static GENERAL = 2;
  static AUDITOR = 12;
  static AUDITOR_SMSF = 13;
}
