import { CarCostLimitManager } from './../cost-limits';
import { DepreciationType } from './../depreciation-type';
import { AssetGroupService } from './../../asset-groups/asset-group.service';
import { AssetsContextService } from './../../assets-context.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, UntypedFormArray, AbstractControl } from '@angular/forms';
import { Subscription, Subject, Observable, EMPTY, merge } from 'rxjs';
import { tap, exhaustMap, map, catchError, finalize, pairwise, startWith, delay, debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService, ModalService } from 'src/app/core';
import { AssetAdditionComponent } from './../../asset-addition/asset-addition.component';
import { AssetService } from './../asset.service';
import { AssetModel, Asset } from '../asset';
import { getDefaultGridOptions } from 'src/app/shared';
import { AssetAddition } from '../../asset-addition/asset-addition';
import { AssetsContext } from '../../assets-context';
import { DepreciationGroup } from '../depreciation-group';
import { DepreciationRecord } from './../depreciation-record';
import { DepreciationRecordFormSet } from './depreciation-record-form-set';
import { AssetType } from '../asset-type';

enum SaveAction {
  SaveAndClose = 0,
  SaveOnly = 1,
  Delete
}

@Component({
  selector: 'crs-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit, OnDestroy {

  private maxPercent = 999.999;

  busy = {
    load: null,
    submit: null,
    delete: null,
    calculate: null
  };

  assetsContext: AssetsContext;
  subscriptions: Subscription[] = [];

  // These are triggered when controls which have a bearing on the number of depreciation year records to show are changed
  taxationRefreshYears$ = new Subject();
  accountingRefreshYears$ = new Subject();

  saveStream = new Subject<SaveAction>();

  id: string;
  initialAssetGroupId: number;
  isAdd: boolean;

  defaultDateStart: Date;
  defaultDateEnd: Date;
  rollOverEndDate: Date;

  component = this;

  form = this.formBuilder.group({
    id: [null],
    assetGroup: [null, Validators.required],
    code: ['', [Validators.maxLength(32)]],
    name: ['', [Validators.required, Validators.maxLength(1024)]],
    identifier: ['', [Validators.maxLength(64)]],
    description: ['', [Validators.maxLength(2048)]],
    assetType: [AssetType.Tangible, Validators.required],
    acquisitionDate: [null, Validators.required],
    additions: [[]],
    taxation: this.buildDepreciationGroupForm('taxation'),
    accounting: this.buildDepreciationGroupForm('accounting')
  }, { updateOn: 'blur'});

  toggles: any = {};

  taxation = this.form.get('taxation') as UntypedFormGroup;
  accounting = this.form.get('accounting') as UntypedFormGroup;
  depreciationRecords: DepreciationRecordFormSet[] = [];
  get additions() { return this.form.get('additions').value as AssetAddition[]; }

  assetDetailsCollapsed = false;
  purchaseDetailsCollapsed = true;
  depreciationDetailsCollapsed = true;
  openingBalancesCollapsed = true;
  disposalDetailsCollapsed = true;
  openingBalances = {
    any: false,
    both: false,
    taxation: false,
    accounting: false
  };

  showCalculationOutput = false;
  calculationOutput: Asset;
  depreciationTypes = DepreciationType;
  assetTypes = AssetType;
  calculationOutputType = new UntypedFormControl(DepreciationType.Taxation);


  additionGridOptions = getDefaultGridOptions();
  depreciationGridOptions = getDefaultGridOptions();

  error: string = null;
  calculationError: string = null;


  constructor(
    private messageService: MessageService,
    private assetsContextService: AssetsContextService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private readonly assetService: AssetService,
    private readonly assetGroupService: AssetGroupService) { }

  // --------------------
  // -- Initialisation --
  // --------------------

  ngOnInit() {

    // Assets Context
    this.subscriptions.push(
      this.assetsContextService.context$.pipe(
        tap(c => {
          this.assetsContext = c;
        }),
        catchError(() => {
          return EMPTY;
        }))
    .subscribe());

    // Save stream
    this.subscriptions.push(this.saveStream
      .pipe(
        tap(() => this.error = null),
        exhaustMap(action => this.getSaveObservable(action))
      )
      .subscribe());

    // Acquistion Cost stream
    this.subscriptions.push(this.form.get('acquisitionDate').valueChanges.pipe(
      // tslint:disable-next-line:deprecation - this is valid
      startWith(null),
      pairwise(),
      tap(([prev, next]: [Date, Date]) => {
        this.updateDepreciationDate(this.taxation.get('commenceDepreciationDate'), prev, next);
        this.updateDepreciationDate(this.accounting.get('commenceDepreciationDate'), prev, next);
      })
      ).subscribe());

    // Output Type Selector Stream
    this.subscriptions.push(this.calculationOutputType.valueChanges.pipe(
      tap(t => this.refreshDepreciationRecordRowData())
    ).subscribe());

    // Main Data Retrieval
    this.route.paramMap.subscribe(p => {
      this.id = p.get('id');
      this.initialAssetGroupId = parseInt(p.get('groupId'), 10);
      this.isAdd = this.id === 'add';
      this.getAsset();
    });

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // -------------------------------------
  // --- Subscription Event Handlers ---
  // -----------------------------------

  private updateDepreciationDate(control: AbstractControl, prev: Date, next: Date) {
    let change = true;
    if (control.value) {
      const date = control.value as Date;
      if (date === null || prev === null || date.getTime() !== prev.getTime()) change = false;
    }

    if (change) control.setValue(next);
  }

  // --------------------
  // --- Form Helpers ---
  // --------------------

  private buildDepreciationGroupForm(type: 'accounting' | 'taxation') {
    const form = this.formBuilder.group({
      cost: [0, Validators.required],
      costLimit: [0],
      commenceDepreciationDate: [null, Validators.required],
      hasOpeningBalances: [false, {updateOn: 'change'}],
      commenceCalculationYear: [null],
      yearEnteredPool: [null],
      openingCarryingAmount: [0],
      openingDeclineNotDeducted: [0],
      disposalValue: [0],
      disposalDate: [null],
      disposalAveragePrivatePercentEditable: [0, [Validators.max(this.maxPercent)]],
      depreciationRecords: this.formBuilder.array([])
    });

    // subscribe to has opening balances
    const openingbalances = form.get('hasOpeningBalances') as UntypedFormControl;
    this.subscriptions.push(openingbalances.valueChanges.subscribe(v => {
      if (type === 'taxation') this.openingBalances.taxation = v;
      if (type === 'accounting') this.openingBalances.accounting = v;
      this.openingBalances.any = this.openingBalances.taxation || this.openingBalances.accounting;
      this.openingBalances.both =  this.openingBalances.taxation && this.openingBalances.accounting;
      form.get('commenceCalculationYear').setValidators(v ? Validators.required : null);
    }));

    const refreshStream$: Subject<any> = type === 'taxation' ? this.taxationRefreshYears$ : this.accountingRefreshYears$;

    // Changes to start/end dates to update refresh stream
    const commenceDepreciationDate = form.get('commenceDepreciationDate') as UntypedFormControl;
    const disposalDate = form.get('disposalDate') as UntypedFormControl;
    const hasOpeningBalances = form.get('hasOpeningBalances') as UntypedFormControl;
    const commenceCalculationYear = form.get('commenceCalculationYear') as UntypedFormControl;
    this.subscriptions.push(
      merge(commenceDepreciationDate.valueChanges,
        disposalDate.valueChanges,
        hasOpeningBalances.valueChanges,
        commenceCalculationYear.valueChanges).pipe(tap(() => {
        refreshStream$.next();
      })).subscribe()
    );

    // subscribe to refresh stream
    this.subscriptions.push(
      refreshStream$.asObservable().pipe(
        // gives a chance for the parent formGroup values to update after any control value changes, and groups changes together
        debounceTime(200),
        tap(() => {
          try {
            this.rebuildGroupRecordsFormArray(type === 'taxation' ? this.taxation : this.accounting);
            this.refreshDepreciationRecordFormSets();
          } catch (error) {
            console.error('error recalculating depreciation records', error);
          }
        })).subscribe()
    );

    return form;
  }

  /// Builds a form array of depreciation records which will then be filled(patched) with the supplied records
  private buildDepreciationRecordsFormArray(records: DepreciationRecord[], groupForm: UntypedFormGroup) {
    const array = groupForm.get('depreciationRecords') as UntypedFormArray;

    if (!records || !records.length) {
      array.clear();
    } else if (array.length > records.length) {
      while (array.length > records.length) {
        array.removeAt(array.length - 1);
      }
    } else {
      while (array.length < records.length) {
        array.push(this.formBuilder.group({
          year: [null],
          inherited: [true, {updateOn: 'change'}],
          expanded: false,
          carryingAmountAdjustment: [null],
          depreciationMethod: [null, Validators.required],
          rateEditable: [null, [Validators.max(this.maxPercent)]],
          manualDepreciation: [],
          isRateSelfAssessed: [false, {updateOn: 'change'}],
          residualValue: [0],
          privatePercentEditable: [0, [Validators.max(this.maxPercent)]],
          depreciationPool: [null, Validators.required]
        }));
      }
    }

    if (records) array.patchValue(records);
  }

  /// Recalculates the required years for either the taxation and accounting form group and recreates the form array
  private rebuildGroupRecordsFormArray(groupForm: UntypedFormGroup) {
    const group = new DepreciationGroup(groupForm.value);
    group.rebuildDepreciationRecords(this.assetsContext.years, this.assetsContext.year.useSmallBusinessDepreciation);
    this.buildDepreciationRecordsFormArray(group.depreciationRecords, groupForm);
  }

  /// Updates the depreciationRecords property to reflect the latest values in the various form arrays
  private refreshDepreciationRecordFormSets() {
    const taxationArray = (<UntypedFormArray>this.taxation.get('depreciationRecords')).controls;
    const accountingArray = (<UntypedFormArray>this.accounting.get('depreciationRecords')).controls;

    let earliestYear: number = null;
    let latestYear: number = null;

    if (!taxationArray.length && !accountingArray.length) {
      earliestYear = null;
      latestYear = null;
    } else if (!taxationArray.length) {
      earliestYear = accountingArray[0].value.year;
      latestYear = accountingArray[accountingArray.length - 1].value.year;
    } else if (!accountingArray.length) {
      earliestYear = taxationArray[0].value.year;
      latestYear = taxationArray[taxationArray.length - 1].value.year;
    } else {
      earliestYear = Math.min(taxationArray[0].value.year, accountingArray[0].value.year);
      latestYear = Math.max(taxationArray[taxationArray.length - 1].value.year,
        accountingArray[accountingArray.length - 1].value.year);
    }

    if (!earliestYear || !latestYear) {
      this.depreciationRecords = [];
      return;
    }

    // enables shortcut method to prevent recreating array
    const allInPlace = !!this.depreciationRecords.length &&
      this.depreciationRecords[0].year.year === earliestYear &&
      this.depreciationRecords[this.depreciationRecords.length - 1].year.year === latestYear;

    if (!allInPlace) this.depreciationRecords = [];

    for (let i = latestYear; i >= earliestYear; i--) { // reverse order for display purposes, show years descending
      const year = this.assetsContext.years.find(y => y.year === i);
      if (!year) continue;

      const taxationGroup = taxationArray.find(t => t.value.year === i) as UntypedFormGroup;
      const accountingGroup = accountingArray.find(t => t.value.year === i) as UntypedFormGroup;

      const set = new DepreciationRecordFormSet(this.form, year, taxationGroup, accountingGroup);
      if (allInPlace) {
        set.expanded = this.depreciationRecords[i - earliestYear].expanded;
        this.depreciationRecords[i - earliestYear] = set;
      } else {
        set.expanded = year.id === this.assetsContext.year.id;
        this.depreciationRecords.push(set);
      }
    }
  }

  private getAsset() {
    let observable: Observable<any>;
    if (this.isAdd) {
      observable = this.assetGroupService.get(this.initialAssetGroupId).pipe(
        tap(g => {
          this.form.get('assetGroup').patchValue(g);
          this.rebuildGroupRecordsFormArray(this.taxation);
          this.rebuildGroupRecordsFormArray(this.accounting);
          this.refreshDepreciationRecordFormSets();
        })
      );
    } else {
      observable = this.assetService.get(this.id).pipe(
        tap(a => {
          this.buildDepreciationRecordsFormArray(a.taxation.depreciationRecords, this.taxation);
          this.buildDepreciationRecordsFormArray(a.accounting.depreciationRecords, this.accounting);
          this.refreshDepreciationRecordFormSets();
          this.form.patchValue(a);
          this.additionGridOptions.api.setRowData(this.additions);
        }),
        tap(a => this.calculationOutput = a)
      );
    }

    this.busy.load = observable.pipe(
      tap(() => this.refreshToggles()),
      catchError(err => {
        this.showError(err);
        return EMPTY;
      })
    ).subscribe();

  }

  private refreshToggles() {
    this.toggles = {};
    Object.keys(this.taxation.controls).forEach(key => {
      this.toggles[key] = this.taxation.controls[key].value !== this.accounting.controls[key].value;
    });
  }

  // ---------------------
  // -- Save and Delete --
  // ---------------------

  save(stayOpen: boolean = false) {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;
    if (stayOpen) this.saveStream.next(SaveAction.SaveOnly);
    else this.saveStream.next(SaveAction.SaveAndClose);
  }

  delete(stayOpen: boolean = false) {
    this.modalService.confirmation('This action cannot be undone. Are you sure you want to delete this asset?',
    () => this.saveStream.next(SaveAction.Delete), true);
  }

  private getSaveObservable(action: SaveAction): Observable<any> {
    if (action === SaveAction.Delete) return this.deleteObservable();

    let observable: Observable<any>;
    if (this.isAdd) {
      const model = new AssetModel(this.form.value);
      observable = this.assetService.post(model).pipe(tap(id => this.form.controls.id.setValue(id)));
    } else {
      observable = this.assetService
        .put(new AssetModel(this.form.value)).pipe(map(() => this.form.value.id));
    }

    const loadingStream = new Subject();
    if (action === SaveAction.SaveAndClose) {
      this.busy.submit = loadingStream.subscribe();
      observable = observable.pipe(tap(() => this.close(true)));
    } else {
      this.busy.submit = loadingStream.subscribe();
      observable = observable.pipe(tap(id => {
        this.navigateToNewlySavedAsset();
        this.messageService.success('Successfully saved dataset.');
      }));
    }

    return observable.pipe(
      catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  private navigateToNewlySavedAsset() {
    if (this.isAdd && this.form.value.id) {
      this.router.navigate(['../' + this.form.value.id], { relativeTo: this.route });
    }
  }

  private deleteObservable(): Observable<any> {
    if (this.isAdd) return EMPTY;
    const loadingStream = new Subject();
    this.busy.delete = loadingStream.subscribe();

    return this.assetService.delete(this.form.value.id).pipe(
      tap(() => this.close(true)),
      catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  // --------------------------
  // -- Other User Functions --
  // --------------------------

  applyCarLimit() {
    const date = this.taxation.get('commenceDepreciationDate').value;
    const limit = new CarCostLimitManager().getCostLimit(date);
    if (limit) this.taxation.get('costLimit').setValue(limit.value);
  }

  // ---------------
  // -- Additions --
  // ---------------

  addAddition() {
    this.modalService.openModal(AssetAdditionComponent, null, { isAdd: true, addition: new AssetAddition({}) })
      .then(result => {
        this.additions.push(result);
        this.additionGridOptions.api.setRowData(this.additions);
      }).catch(() => true);
  }

  editAddition(addition: AssetAddition) {
    this.modalService.openModal(AssetAdditionComponent, null, { isAdd: false, addition: new AssetAddition(addition) })
      .then(result => {
        const index = this.additions.indexOf(addition);
        this.additions[index] = result;
        this.additionGridOptions.api.setRowData(this.additions);
      }).catch(() => true);
  }

  removeAddition(addition: AssetAddition) {
    this.modalService.confirmation(
      'Are you sure you want to remove this addition?',
      () => {
        const index = this.additions.indexOf(addition);
        this.additions.splice(index, 1);
        this.additionGridOptions.api.setRowData(this.additions);
      });
  }

  // ------------------------
  // -- Calculation Output --
  // ------------------------

  toggleCalculationOutput() {
    this.calculationError = null;
    this.showCalculationOutput = !this.showCalculationOutput;
    if (!this.calculationOutput) {
      this.calculate();
    }
    this.refreshDepreciationRecordRowData();
  }

  calculate() {
    this.calculationError = null;

    const loadingStream = new Subject();
    this.busy.calculate = loadingStream.subscribe();

    const model = new AssetModel(this.form.value);

    this.assetService.calculate(model).pipe(
      tap(a => {
        this.calculationOutput = a;
        this.refreshDepreciationRecordRowData();
      }),
      catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    ).subscribe();
  }

  refreshDepreciationRecordRowData() {
    if (!this.calculationOutput) this.depreciationGridOptions.api.setRowData([]);
    let records;
    if (this.calculationOutputType.value === DepreciationType.Taxation) {
      records = this.calculationOutput.taxation.depreciationRecords;
    } else {
      records = this.calculationOutput.accounting.depreciationRecords;
    }
    records.sort((a, b) => b.year - a.year);
    this.depreciationGridOptions.api.setRowData(records);
  }

  close(refreshRequired = false) {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error, true);
  }

  showCalculationError(error) {
    this.calculationError = error;
  }

}
