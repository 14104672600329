/**
 * Represents a collection of content and key attributes that can be edited in a collection
*/
export interface IEditableContentInfo {

  /**
   * Is this report content readonly?
  */
  readonly readonly: boolean;

  /**
   * Is this report content a 'template input' (false), or 'rendered output' (true)?
  */
  readonly output: boolean;

  /**
   * An array of report elements which represents the ordered content to render
  */
  readonly content: any[];

}
