import { ApiError } from "src/app/core/interceptors/api-error";

export interface Stateful<T> {
  data: T | null;
  state: StatefulState;
  errorMessage: string;
}

export class StatefulHelpers {
  public static pending<T>() : Stateful<T> {
    return {
      data: null,
      state: 'PENDING',
      errorMessage: null
    };
  }

  public static error<T>(error) : Stateful<T> {
    let message: string;
    if (typeof error === 'string') message = error;
    else if (error instanceof ApiError) {
      message = error.messageString;
    }
    return {
      data: null,
      state: 'ERROR',
      errorMessage: message
    };
  }
  
  public static success<T>(data: T) : Stateful<T> {
    return {
      data: data,
      state: 'SUCCESS',
      errorMessage: null
    };
  }
}

export type StatefulState = 'PENDING' | 'ERROR' | 'SUCCESS';

