export enum CountryType {
  Australia = 0,
  NewZealand = 1,
}

export enum BankConnectionType {
  BankConnection = 'BankConnection',
  ManualImport = 'ManualImport',
}

export enum FormFlowSteps {
  CountryAndImportType = 0,
  EnterEmailOrManualImport = 1,
  CompleteSetup = 2,
  RequestSent = 3,
}
