import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { MatchingRuleSet } from './matching-rule-set';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MatchingRuleSetsService {
  readonly base: string = 'api/accounting/matching-rule-sets';

  constructor(private apiService: ApiService) {}

  get$(id: string) {
    return this.apiService
      .get<MatchingRuleSet>(this.base + '/' + id)
      .pipe(map((ruleSet) => new MatchingRuleSet(ruleSet)));
  }

  getAllMasters$() {
    return this.apiService
      .get<MatchingRuleSet[]>(this.base)
      .pipe(
        map((ruleSets) =>
          ruleSets.map((ruleSet) => new MatchingRuleSet(ruleSet))
        )
      );
  }

  getAll$(fileId: string) {
    const url = `${this.base}/for-file/${fileId}`;

    return this.apiService
      .get<MatchingRuleSet[]>(url)
      .pipe(
        map((ruleSets) =>
          ruleSets.map((ruleSet) => new MatchingRuleSet(ruleSet))
        )
      );
  }
}
