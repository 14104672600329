import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

import { ReportDocumentService } from '../../services';
import { getDefaultGridOptions, IDialogPanel } from '../../../../shared';
import {
  ReportDocumentListItem,
  ReportDocumentModel,
  ReportSignStatus,
} from '../../models';
import { Confirmation, ModalService } from '../../../../core';
import { ReportOutput } from '../../reportViewer/report-output';
import { ReportViewerComponent } from '../../reportViewer/report-viewer/report-viewer.component';
import { ReportViewerParams } from '../../reportViewer/models';
import { ActiveFileService } from 'src/app/accounting/active-file.service';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-report-documents',
  templateUrl: './report-documents.component.html',
  styleUrls: ['./report-documents.component.scss'],
})
export class ReportDocumentsComponent implements OnInit, OnDestroy {
  @ViewChild('optionsCellSignStatus', { static: true, read: TemplateRef })
  optionsCellSignStatus!: TemplateRef<ReportDocumentListItem>;
  @ViewChild('optionsCellOffice', { static: true, read: TemplateRef })
  optionsCellOffice!: TemplateRef<ReportDocumentListItem>;

  public busy = {
    loading: false,
  };

  public reportDocuments: ReportDocumentListItem[] = [];
  public gridOptions: GridOptions;
  public error: string;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public activatedRoute: ActivatedRoute,
    private readonly _reportDocumentService: ReportDocumentService,
    private readonly _modalService: ModalService,
    private readonly _route: ActivatedRoute,
    private activeFileService: ActiveFileService
  ) {}

  public ngOnInit() {
    this.configureGridOptions();

    this._route.params.subscribe((params) => {
      this._loadReportDocuments();
      if (params.id) {
        const documentToEdit = new ReportDocumentListItem({});
        documentToEdit.id = params.id;
        this.editReportDocument(documentToEdit);
      }
    });
  }

  public ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      singleClickEdit: false,
      columnDefs: [
        {
          field: 'name',
          headerName: 'Name',
          editable: this.isEditable.bind(this),
          minWidth: 300,
        },
        {
          field: 'modifiedDate',
          headerName: 'Date Modified',
          type: 'timeColumn',
          editable: false,
          maxWidth: 300,
          minWidth: 200,
        },
        {
          field: 'financialYear',
          headerName: 'Financial Year',
          editable: false,
          maxWidth: 200,
          minWidth: 100,
        },
        {
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCellSignStatus },
          editable: false,
          maxWidth: 300,
          minWidth: 135,
        },
        {
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCellOffice },
          editable: false,
          maxWidth: 135,
          minWidth: 135,
        },
      ],
    };
  }

  public editReportDocument(reportDocument: ReportDocumentListItem) {
    this._reportDocumentService
      .get(reportDocument.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((report: any) =>
        this._renderViewer(new ReportOutput(report), reportDocument.id)
      );
  }

  public copyReportDocument(reportDocument: ReportDocumentListItem): void {
    this._reportDocumentService
      .copy(reportDocument.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => this._loadReportDocuments(),
        error: this._showError,
      });
  }

  public isEditable(
    params: import('ag-grid-community').EditableCallbackParams
  ) {
    if (params.data.reportSignStatus == ReportSignStatus.REPORT_SIGNED) {
      return false;
    }
    return true;
  }

  public openRemoveReportDocument(reportDocument: ReportDocumentListItem) {
    const confirmation = new Confirmation({
      title: 'Delete Document',
      text: `Are you sure you would like to delete the ${reportDocument.name}? This action cannot be undone.`,
      action: () => this._removeReportDocument(reportDocument.id),
      danger: true,
    });
    return this._modalService.confirmation2(confirmation);
  }

  private _removeReportDocument(reportDocumentId: string): void {
    this._reportDocumentService
      .delete(reportDocumentId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => this._loadReportDocuments(),
        error: this._showError,
      });
  }

  private _loadReportDocuments(): void {
    this.busy.loading = true;
    this._reportDocumentService
      .getAll(this.activeFileService.file.id)
      .pipe(
        catchError((err) => {
          this._showError(err);
          return of([]);
        }),
        finalize(() => {
          this.busy.loading = false;
        })
      )
      .subscribe((reportDocuments: ReportDocumentListItem[]) => {
        this.reportDocuments = reportDocuments;
      });
  }

  private _showError(error) {
    this.error = error;
  }

  private _renderViewer(report: ReportOutput, reportDocumentId: string) {
    const reportDocumentListItem = this.reportDocuments.find(
      (x) => x.id == reportDocumentId
    );
    if (reportDocumentListItem) {
      reportDocumentListItem.title = report.title;
      reportDocumentListItem.financialYear = report.endDate
        .getFullYear()
        .toString();
    }

    const data = new ReportViewerParams({
      report,
      reportDocumentId,
      reportDocumentListItem,
      gridOptions: this.gridOptions,
      shouldUpdateCustomTableAutoTotal: true,
    });
    this._modalService.overlay(ReportViewerComponent, {
      data,
    } as IDialogPanel<ReportViewerParams>);
  }

  onValueChanged(event) {
    let model = new ReportDocumentModel();
    model.ReportDocumentHeader = event.node.data;

    this._reportDocumentService
      .update(event.node.data.id, model)
      .pipe(
        takeUntil(this._destroy$),
        catchError((err) => {
          this._showError(err);
          return of([]);
        })
      )
      .subscribe();
  }
}
