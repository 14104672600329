import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-dollar-renderer',
  template: `{{ (this.prefix && params.value) ? this.prefix : '' }}{{ params.value | money : rounding : showZero }}`
})
export class DollarRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public rounding: number = null;
  public showZero = false;
  public prefix: string;

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: any): boolean {
    this.params = params;
    this.rounding = this.params['rounding'];
    this.showZero = !!this.params['showZero'];
    this.prefix = this.params['prefix'];

    return true;
  }
}
