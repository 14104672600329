import { IElement } from '../element';
import { ReportElementTypeEnum } from '../../../enums';

export class FinancialTable implements IElement {
  elementType = ReportElementTypeEnum.FinancialTable;
  header = new FinancialColumnHeaderRow();
  noOfColumns = 2;
  elements: any[] = [];
  isNew = true;
}

export class FinancialColumnHeaderRow {
  financialType: 0;
  noteText = 'Note';
  columnHeaders = [];
}
