<div class="buttons-menu" *ngIf="!financialContent.readonly">
  <i class="fas fa-bars me-2 draggable" aria-hidden="true"></i>
  <button
    type="button"
    mat-mini-fab
    class="menu-button"
    (click)="edit()"
    *ngIf="editable"
  >
    <mat-icon>edit</mat-icon>
  </button>
  <button type="button" mat-mini-fab class="menu-button" (click)="delete()">
    <mat-icon>delete</mat-icon>
  </button>
</div>
