<ng-container *ngIf="reportPortal$ | async as reportPortal; else state">
  <ng-container *ngIf="!isLoading; else state">
    <div class="w-100">
      <ng-template [cdkPortalOutlet]="reportPortal"></ng-template>
    </div>
  </ng-container>
</ng-container>

<ng-template #state>
  <crs-error-view *ngIf="errorMessage" [message]="errorMessage" />
  <crs-busy *ngIf="!errorMessage" />
</ng-template>
