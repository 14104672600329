import { Plurality } from './../model/plurality';
import { User } from '../users/user';
import { EntityType } from './entityType';
import { Office } from '../offices';
import { Team } from '../teams';
import { Address } from '../model/address';
import { TrusteeDetail } from '../model/trusteeDetail';

export class EntityLight {
  id: string;
  code: string;
  legalName: string;
  files: any[];

  constructor(data: any) {
    Object.assign(this, data);
    if (this.files) {
      this.files.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
}

export class Entity {
  id: string;
  clientId: string;
  code: string;
  clientRef: string;
  legalName: string;
  tradingName: string;
  entityTypeId: number;
  entityType: EntityType;
  abn: string;
  abnDivision: string;
  acn: string;
  registeredOffice: Address;
  principalPlaceOfBusiness: Address;
  principalActivities: string;
  description: string;
  responsible: Plurality;
  responsiblePersons: ResponsiblePerson[];
  owners: Plurality;
  isConsolidatedGroup: boolean;
  isRegisteredForGst: boolean;
  isLivestockEnabled: boolean;
  office: Office;
  team: Team;
  partner: User;
  manager: User;
  associationStateId: number;
  notForProfit: boolean;
  reportingEntity: boolean;
  trusteeDetail: TrusteeDetail;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.office) this.office = new Office(data.office);
    if (data.team) this.team = new Team(data.team);
    if (data.partner) this.partner = new User(data.partner);
    if (data.manager) this.manager = new User(data.manager);
    if (!data.registeredOffice) this.registeredOffice = new Address();
    if (!data.principalPlaceOfBusiness)
      this.principalPlaceOfBusiness = new Address();
    if (data.associationStateId)
      this.associationStateId = parseInt(data.associationStateId, 10);
    if (data.trusteeDetail)
      this.trusteeDetail = new TrusteeDetail(data.trusteeDetail);
  }
}

export class EntityModel {
  id: string;
  clientId: string;
  code: string;
  clientRef: string;
  legalName: string;
  tradingName: string;
  entityTypeId: number;
  entityType: EntityType;
  abn: string;
  abnDivision: string;
  acn: string;
  registeredOffice: Address;
  principalPlaceOfBusiness: Address;
  principalActivities: string;
  description: string;
  responsible: Plurality;
  responsiblePersons: ResponsiblePerson[];
  owners: Plurality;
  isConsolidatedGroup: boolean;
  isRegisteredForGst: boolean;
  isLiveStockEnabled: boolean;
  teamId: string;
  partnerId: string;
  managerId: string;
  associationStateId: number;
  notForProfit: boolean;
  reportingEntity: boolean;
  trusteeDetail: TrusteeDetail;

  constructor(data: Entity) {
    Object.assign(this, data);
    if (data.team) this.teamId = data.team.id;
    if (data.partner) this.partnerId = data.partner.id;
    if (data.manager) this.managerId = data.manager.id;
  }
}

export class ResponsiblePerson {
  title: string;
  firstName: string;
  lastName: string;
  isSigning: boolean;
  inactive: boolean;
  email: string;
  appointedDate: string;
  resignedDate: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
