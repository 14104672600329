import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ReportTableService, ReportTableMessageService } from './services';
import { ReportTable, ReportTableRow } from './';
import { ReportHeaderColumn, ReportMetaData } from '../../report-output';
import { ReportContentInfo } from '../../report-content/report-content-info';
import { Alignment } from '../report-header/report-header';
import { TableRowType } from './enums';

@Component({
  selector: 'crs-report-table',
  templateUrl: './report-table.component.html',
  viewProviders: [ ReportTableService, ReportTableMessageService ]
})
export class ReportTableComponent implements OnInit, OnDestroy {
  @Input() set element(value: ReportTable) {
    this._element = value;
  }

  get element(): ReportTable {
    return this._element;
  }

  private _element: ReportTable;
  private _reportContentInfo: ReportContentInfo;

  @Input() set reportContent(value: ReportContentInfo) {
    this._reportContentInfo = value;
    this.isOutput = this._reportContentInfo.output;
  }

  get reportContent(): ReportContentInfo {
    return this._reportContentInfo;
  }

  @Input() reportMetaData: ReportMetaData;
  @Input() index: number;
  @Input() reportColumns: ReportHeaderColumn[] = [];
  public isEditing = false;
  public alignments = Alignment;
  public tableRowTypes = TableRowType;
  public isOutput: boolean;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly _reportTableService: ReportTableService) {
  }

  public ngOnInit(): void {
    this._reportTableService.setReportTableData({
      ...this._element,
      isOutput: this.isOutput,
    });
    this._reportTableService.setReportStyle(this._reportContentInfo.style);

    if (this.isOutput) {
      this._reportTableService.setAutoColumnHeaders(this.reportColumns);
    }

    this._reportTableService.elementRemoved$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.deletedElement());

    if (!this.reportMetaData) this.reportMetaData = new ReportMetaData();

    this._reportTableService.setRowAndColumnTotalValues();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onEdit() {
    this.isEditing = true;
  }

  onSubmit = () => {
    this.isEditing = false;
    this.reportContent.content[this.index] = this._reportTableService.saveChanges();
    this._reportTableService.clearSelection();
    return true;
  };

  onCancel() {
    this.isEditing = false;
    this.reportContent.content[this.index] = this._reportTableService.cancelAllChanges();
    this._reportTableService.clearSelection();
  }

  dropRow(event) {
    const oldIndex = event.previousIndex;
    const newIndex = event.currentIndex;
    if (oldIndex !== newIndex) {
      this.moveRows(
        this.reportContent.content[this.index].rows,
        oldIndex,
        newIndex
      );
    }
  }

  private deletedElement(): void {
    // delete the table because all rows or columns were removed
    if (!this.element.rows.length || !this.element.columns.length) {
      this.reportContent.content.splice(this.index, 1);
      this.reportMetaData.isModified = true;
      return;
    }
  }

  private moveRows(
    rows: ReportTableRow[],
    oldIndex: number,
    newIndex: number
  ): void {
    const movingRulesCheck = this._reportTableService.checkSectionMovingRules(rows, oldIndex, newIndex);
    if (!movingRulesCheck) {
      return;
    }

    moveItemInArray(rows, oldIndex, newIndex);
  }
}
