import { MessageService } from './../../../../../../core/messages/message.service';
import { DownloadService } from './../../../../../../core/services/download.service';
import { ReportExcelService } from './../../services/report-excel.service';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UploadedFile } from 'src/app/shared/components/file-upload/file-upload.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'crs-excel-style-editor',
  templateUrl: './excel-style-editor.component.html',
  styleUrls: ['./excel-style-editor.component.scss']
})
export class ExcelStyleEditorComponent implements OnInit {

  @Input() id: string;
  @Input() formGroup: UntypedFormGroup;

  get isAdd() {
    return this.id === 'add';
  }

  error: string;

  busy = {
    master: false,
    template: false
  };

  constructor(private readonly _reportExcelService: ReportExcelService,
    private readonly _downloadService: DownloadService,
    private readonly _messageService: MessageService) { }

  ngOnInit(): void {
  }

  onFileUpload(uploadedFiles: UploadedFile[]) {
    this.error = null;
    const control = this.formGroup.get('uploadedFile');
    if (uploadedFiles && uploadedFiles.length) {
      control.setValue(uploadedFiles[0]);
    } else {
      control.setValue(null);
    }
  }

  downloadMaster() {
    this.busy.master = true;
    this._reportExcelService.downloadMaster()
      .pipe(finalize(() => {
        this.busy.master = false;
      }))
      .subscribe(
        url => this._downloadService.download(url),
        err => this._messageService.error(err)
      );
  }

  downloadTemplate() {
    this.busy.template = true;
    this._reportExcelService.downloadTemplate(this.id)
      .pipe(finalize(() => {
        this.busy.template = false;
      }))
      .subscribe(
        url => this._downloadService.download(url),
        err => this._messageService.error(err)
      );
  }

}
