import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
  forwardRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SourceService, ActiveFileService } from 'src/app/accounting';

@Component({
  selector: 'crs-source-select-simple',
  templateUrl: './source-select-simple.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SourceSelectComponentSimple),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SourceSelectComponentSimple
  implements OnInit, ControlValueAccessor
{
  @Input() includeInactive = false;
  @Input() readonly = false;

  source: any;

  @ViewChild(NgSelectComponent, { static: true })
  private valueAccessor: ControlValueAccessor;

  sourcesObservable: Observable<any>;
  loading = false;
  onChange;

  constructor(
    private sourceService: SourceService,
    private activeFileService: ActiveFileService
  ) {}

  writeValue(value: any): void {
    this.source = value;
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  ngOnInit() {
    (this.valueAccessor as NgSelectComponent).bindLabel = 'name';
    this.loadData();
  }

  loadData() {
    if (this.readonly) return;
    this.loading = true;
    this.sourcesObservable = this.sourceService
      .getAll$(this.activeFileService.file.id)
      .pipe(tap(() => (this.loading = false)));
  }
}
