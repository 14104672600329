<div
  class="ledger-report-container"
  [ngClass]="{ 'flex-column': isModal, 'flex-row': !isModal }"
>
  <div class="ledger-report-form" [ngClass]="{ 'fixed-width': !isModal }">
    <form novalidate [formGroup]="form" (validSubmit)="getReport()">
      <div class="row">
        <div [ngClass]="{ 'col-sm-6': isModal, 'col-sm-12': !isModal }">
          <div class="form-group required" *ngIf="showAccounts">
            <label class="control-label">Accounts</label>
            <crs-account-select
              formControlName="accounts"
              placeholder="Please select an account"
              *ngIf="!form.get('allAccounts').value"
              [includeHeaders]="false"
              [multiple]="true"
            ></crs-account-select>
            <span class="form-text text-muted">
              <div class="form-check">
                <input
                  class="form-check-input"
                  formControlName="allAccounts"
                  type="checkbox"
                />
                <span>Select all Accounts</span>
              </div>
            </span>
          </div>

          <div class="form-group required" *ngIf="!showAccounts">
            <label class="control-label">Source Accounts</label>
            <crs-source-account-select
              formControlName="sourceAccounts"
              placeholder="Please select an account"
              *ngIf="!form.get('allAccounts').value"
              [datasetId]="datasetId"
              [multiple]="true"
            ></crs-source-account-select>
            <span class="form-text text-muted">
              <div class="form-check">
                <input
                  class="form-check-input"
                  formControlName="allAccounts"
                  type="checkbox"
                />
                <span>Select all Accounts</span>
              </div>
            </span>
          </div>

          <div class="form-group">
            <label class="control-label">Display</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="journalCategory"
              [clearable]="false"
              [items]="journalCategories | enumToArray"
            ></ng-select>
          </div>

          <div class="form-group" *ngIf="!isGroupDataset">
            <label class="control-label">Account Type</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="groupByAccountType"
              [clearable]="false"
              [items]="groupByAccountTypes | enumToArray"
            ></ng-select>
          </div>
        </div>
        <div [ngClass]="{ 'col-sm-6': isModal, 'col-sm-12': !isModal }">
          <div class="form-group">
            <label class="control-label">Start Date</label>
            <crs-date-picker
              formControlName="startDate"
              [max]="this.endDate"
              [min]="this.startDate"
            ></crs-date-picker>
          </div>

          <div class="form-group">
            <label class="control-label">End Date</label>
            <crs-date-picker
              formControlName="endDate"
              [max]="this.endDate"
              [min]="this.startDate"
            ></crs-date-picker>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div class="btn-menu">
        <button
          class="btn btn-primary"
          type="submit"
          [promiseBtn]="busy.update"
        >
          Run Report
        </button>
      </div>
    </form>
  </div>

  <div class="vertical-line-full"></div>

  <div class="ledger-report-table-container">
    <div class="ledger-report-table-toolbar">
      <!-- Search -->
      <div class="row" *ngIf="accountTransactions">
        <div class="col-sm-7">
          <div class="icon-input-wrapper flex-grow-1 mr-2">
            <i aria-hidden="true" class="fas fa-search input-icon"></i>
            <input
              aria-describedby="searchIcon"
              placeholder="Search..."
              type="search"
              [formControl]="search"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Grid -->
    <div [class.d-none]="!accountTransactions">
      <ag-grid-angular
        class="ag-theme-material"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="accountTransactions"
      >
        <ag-grid-column
          field="account.displayName"
          headerName="Account"
          [enableRowGroup]="true"
          [hide]="true"
          [rowGroup]="true"
        ></ag-grid-column>
        <ag-grid-column
          field="sourceAccount.displayName"
          headerName="Source Account"
          [enableRowGroup]="true"
          [hide]="true"
          [rowGroup]="true"
        ></ag-grid-column>
        <ag-grid-column
          field="date"
          headerName="Date"
          type="dateColumn"
        ></ag-grid-column>
        <ag-grid-column
          field="journalType"
          headerName="Journal Type"
          type="enumColumn"
          [cellRendererParams]="{ enum: journalTypes }"
          [maxWidth]="250"
        ></ag-grid-column>
        <ag-grid-column
          field="journalNo"
          headerName="Journal No"
          [width]="110"
        ></ag-grid-column>
        <ag-grid-column field="memo" headerName="Memo"></ag-grid-column>
        <ag-grid-column
          field="lineMemo"
          headerName="Line Memo"
          [hide]="true"
        ></ag-grid-column>
        <ag-grid-column
          field="journalMemo"
          headerName="Journal Memo"
          [hide]="true"
        ></ag-grid-column>
        <ag-grid-column
          cellRenderer="divisionRenderer"
          field="division"
          headerName="Division"
          [width]="100"
        ></ag-grid-column>
        <ag-grid-column
          colId="debit"
          field="debit"
          headerName="Debit"
          type="dollarColumn"
          [cellClass]="['dollar-cell', 'ag-numeric-cell', 'highlight-column']"
        ></ag-grid-column>
        <ag-grid-column
          colId="credit"
          field="credit"
          headerName="Credit"
          type="dollarColumn"
          [cellClass]="['dollar-cell', 'ag-numeric-cell', 'highlight-column']"
        ></ag-grid-column>
        <ag-grid-column
          colId="subTotal"
          field="subTotal"
          headerName="Running Balance"
          type="numberColumn"
        ></ag-grid-column>
        <ag-grid-column
          field="quantity"
          headerName="Quantity"
          type="numberColumn"
          [minWidth]="100"
          [width]="120"
        ></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>
