import { DateHelpers } from './../../../shared/utilities/date-helpers';
import { JournalLine, JournalLineModel } from './journalLine';
import { JournalCategory } from './journalCategory';

export enum JournalType {
  Journal = 0,
  BankFeeds = 1,

  ImportedTrialBalance = 10,
  ImportedMovementJournal = 11,
  ImportedTransaction = 12,
  ImportedOpeningBalance = 13,

  BalancingJournal = 100,
  OpeningBalanceRolledOver = 101,
  Distribution = 102,
  Depreciation = 103,
}

export class Journal {
  id: string = null;
  datasetId: string = null;
  journalCategory: JournalCategory;
  journalType: JournalType = null;
  date: Date = null;
  startDate: Date = null;
  journalNo: string = null;
  memo: string = null;
  balance = 0;
  canExport: boolean;
  isExportable: boolean = null;
  isReadonly: boolean = null;
  exportedJournalId: string;
  journalLines: JournalLine[] = [];
  isGstEnabled: boolean;

  journalTypeToolTipText: string;

  constructor(data) {
    Object.assign(this, data);
    if (data.journalLines) {
      this.journalLines = data.journalLines.map((a) => new JournalLine(a));
    }
    this.date = DateHelpers.dateFromApiResponse(data.date);
    this.startDate = DateHelpers.dateFromApiResponse(data.startDate);

    this.journalTypeToolTipText = JournalType[this.journalType];
  }
}

export class JournalModel {
  id: string = null;
  datasetId: string = null;
  journalType: JournalType = null;
  date: Date = null;
  startDate: Date = null;
  journalNo: string = null;
  memo: string = null;
  balance = 0;
  isExportable: boolean = null;
  journalLines: JournalLineModel[] = [];
  gstLineId?: string;
  gstParentId?: string;

  constructor(data) {
    Object.assign(this, data);
    if (data.journalLines) {
      this.journalLines = data.journalLines
        .filter(
          (l) =>
            (l.balance !== 0 && l.balance != null) ||
            (l.quantity !== 0 && l.quantity != null)
        )
        .map((a) => new JournalLineModel(a));
    }
  }
}
