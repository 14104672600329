import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, empty, EMPTY } from 'rxjs';
import {
  tap,
  catchError,
  exhaustMap,
  finalize,
  switchMap,
} from 'rxjs/operators';

import {
  ReportPageTypeService,
  ReportPageCategory,
  ReportPageType,
} from '../../reportPages';
import { MessageService } from '../../../../core';
import {
  getDefaultGridOptions,
  accountingRenderers,
  ProgressBarService,
} from '../../../../shared';
import {
  GridApi,
  GridOptions,
  RowDragEndEvent,
  RowDragMoveEvent,
} from 'ag-grid-community';

enum Interaction {
  Move,
  Add,
}

class InteractionData {
  interaction: Interaction;
  moveId: number;
  moveIndex: number;
}

@Component({
  selector: 'crs-report-page-types',
  templateUrl: './report-page-types.component.html',
})
export class ReportPageTypesComponent implements OnInit {
  @ViewChild('optionsCell', { static: true })
  optionsCell!: ElementRef;

  collapsed: boolean;
  error: string;

  busy = {
    reportPageTypes: false,
    interactionPending: false,
  };

  gridOptions: GridOptions;
  gridApi: GridApi;
  renderers = accountingRenderers;
  sortActive: boolean;
  filterActive: boolean;

  reportPageTypes: ReportPageType[] = [];
  reportPageCategories: ReportPageCategory;
  pendingReportPageTypes: ReportPageType[] = [];

  interactionStream = new Subject<InteractionData>();

  constructor(
    private reportPageTypeService: ReportPageTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private progressBar: ProgressBarService
  ) {}

  ngOnInit() {
    this.configureGridOptions();
    this.configureInteractionStream();
    this.getReportPageTypes();
  }

  configureInteractionStream() {
    this.interactionStream
      .pipe(
        tap(() => {
          this.error = null;
        }),
        exhaustMap((param) => this.handleInteraction(param))
      )
      .subscribe();
  }

  handleInteraction(param: InteractionData): Observable<any> {
    let observable: Observable<any> = EMPTY;

    if (param.interaction === Interaction.Add) {
      this.router.navigate(['./add'], { relativeTo: this.route });
      return EMPTY;
    }

    if (param.interaction === Interaction.Move) {
      observable = this.move(param);
    }

    this.busy.interactionPending = true;
    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => (this.busy.interactionPending = false))
    );
  }

  private move(param: InteractionData) {
    const progress = this.progressBar.ref();
    progress.start();
    return this.reportPageTypeService
      .reorder(param.moveId, param.moveIndex + 1)
      .pipe(
        switchMap(() => this.reportPageTypeService.getAllForAdmin()),
        tap((data) => {
          this.reportPageTypes = data;
          this.gridApi.setGridOption('rowData', this.reportPageTypes);
        }),
        tap(() => {
          this.reportPageTypes = this.pendingReportPageTypes;
          console.log('successfully moved row');
        }),
        catchError((err) => {
          this.pendingReportPageTypes = this.reportPageTypes;
          this.gridApi.setGridOption('rowData', this.pendingReportPageTypes);
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => progress.complete())
      );
  }

  add() {
    const param = new InteractionData();
    param.interaction = Interaction.Add;
    this.interactionStream.next(param);
  }

  edit(id) {
    this.router.navigate(id, { relativeTo: this.route });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  getReportPageTypes() {
    this.busy.reportPageTypes = true;
    this.reportPageTypeService
      .getAllForAdmin()
      .pipe(
        tap(() => (this.busy.reportPageTypes = false)),
        catchError((err) => {
          this.showError(err);
          return of([]);
        })
      )
      .subscribe((result) => {
        this.reportPageTypes = result;
      });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  // Grid
  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.id,
      onGridReady: (event) => (this.gridApi = event.api),
      onRowDragMove: (param) => this.onRowDragMove(param),
      onRowDragEnd: (param) => this.onRowDragEnd(param),
      components: this.renderers,
      groupDefaultExpanded: -1,
      groupDisplayType: 'groupRows',

      columnDefs: [
        {
          field: 'category',
          headerName: 'Category',
          hide: true,
          rowGroup: true,
          valueGetter: (params) => ReportPageCategory[params.data.category],
        },
        {
          cellRenderer: 'badgeRenderer',
          field: 'name',
          headerName: 'Name',
          cellRendererParams: { badgeText: 'New', monthsToCheck: 3 },
          rowDrag: true,
        },
        {
          field: 'isMaster',
          headerName: 'Master',
          type: 'booleanColumn',

          maxWidth: 120,
          minWidth: 120,
        },
        {
          field: 'canHaveMultiple',
          headerName: 'Multiple',
          type: 'booleanColumn',
          maxWidth: 120,
          minWidth: 120,
        },
        {
          field: 'active',
          headerName: 'Active',
          type: 'booleanColumn',
          cellClass: ['boolean-cell', 'centered', 'text-success'],
          maxWidth: 100,
          minWidth: 100,
        },
        {
          headerName: 'Edit',
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCell },
          headerClass: 'centered',
          maxWidth: 100,
          minWidth: 100,
        },
      ],
    };
  }

  onRowDragMove(event: RowDragMoveEvent) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
  }

  onRowDragEnd(event: RowDragEndEvent) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
    const param = new InteractionData();
    param.interaction = Interaction.Move;
    param.moveId = event.node.data.id;
    param.moveIndex = event.overNode.childIndex;
    this.interactionStream.next(param);
  }

  executeUIMove(event: RowDragMoveEvent | RowDragEndEvent) {
    const movingNode = event.node;
    const overNode = event.overNode;
    const rowNeedsToMove =
      movingNode !== overNode &&
      overNode.data &&
      movingNode.data.category === overNode.data.category;
    if (rowNeedsToMove) {
      const fromIndex = this.reportPageTypes.indexOf(movingNode.data);
      const toIndex = this.reportPageTypes.indexOf(overNode.data);
      this.pendingReportPageTypes = this.reportPageTypes.slice();
      moveInArray(this.pendingReportPageTypes, fromIndex, toIndex);
      this.gridApi.setGridOption('rowData', this.pendingReportPageTypes);
      this.gridApi.clearFocusedCell();
    }
    function moveInArray(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }
}
