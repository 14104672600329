import { IElement } from '../element';
import { Alignment } from '../report-header/report-header';
import { ReportElementTypeEnum } from '../../../enums';

export enum ParagraphStyle {
      Normal = 0,
      Muted = 1,
      Indented = 2
}

export class ReportParagraph implements IElement {
  elementType = ReportElementTypeEnum.Paragraph;
  style: ParagraphStyle = ParagraphStyle.Normal;
  alignment: Alignment = Alignment.Left;
  marginTop = false;
  marginBottom = true;
  text: string;
  isNew = true;

  constructor(data?: Partial<ReportParagraph>) {
    if (data) Object.assign(this, data);
  }
}
