import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subject } from 'rxjs';
import {
  takeUntil,
  switchMap,
  filter,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { BacoFeedStore } from './baco-feed.store';
import { BacoTransactionStore } from './baco-transaction.store';
import { TransactionService } from '../../services';
import { BacoRuleStore } from './baco-rule.store';

@Component({
  selector: 'crs-feed-detail',
  templateUrl: './feed-detail.component.html',
  styleUrls: ['./feed-detail.component.scss'],
  providers: [
    BacoFeedStore,
    BacoTransactionStore,
    TransactionService,
    BacoRuleStore,
  ],
})
export class FeedDetailComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  public feed$ = this._feedStore.feed$.pipe(
    filter((feedState) => feedState?.data !== null), // Continue until data is not null
    distinctUntilChanged() // Prevent emitting the same value
  );

  constructor(
    private readonly _feedStore: BacoFeedStore,
    private readonly _route: ActivatedRoute
  ) {}

  ngOnInit() {
    var feedId$ = this._route.paramMap.pipe(
      takeUntil(this._destroy$),
      switchMap((params) => {
        const paramId = params.get('feedId') ?? null;
        return of(paramId);
      })
    );
    this._feedStore.loadFeed(feedId$);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
