import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../../core';
import { ReportStyle, ReportStyleModel } from './models/report-style';

@Injectable({
  providedIn: 'root'
})
export class ReportStyleService {

  readonly base: string = 'api/accounting/reports/styles';

  constructor(private apiService: ApiService) { }

  get(id: string) {
    return this.apiService.get<ReportStyle>(this.base + '/' + id).pipe(map(d => new ReportStyle(d)));
  }

  getAll() {
    return this.apiService.get<ReportStyle[]>(this.base).pipe(map(l => l.map(d => new ReportStyle(d))));
  }

  post(reportStyle: ReportStyleModel) {
    return this.apiService.post<any>(this.base, reportStyle);
  }

  put(reportStyle: ReportStyleModel) {
    return this.apiService.put(this.base + '/' + reportStyle.id, reportStyle);
  }

  delete(id: string) {
    return this.apiService.delete(this.base + '/' + id);
  }
}
