import { ReportMasterFormBuilder } from './../report-master-form-builder';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import {
  concat,
  from,
  of,
  Subject,
  Observable,
  EMPTY,
  Subscription,
} from 'rxjs';
import {
  tap,
  map,
  switchMap,
  finalize,
  exhaustMap,
  catchError,
} from 'rxjs/operators';

import { MessageService, ModalService } from '../../../../../core';
import { SaveAsComponent } from '../../../reportTemplates/save-as/save-as.component';
import { ReportMasterModel, ReportMasterService, ReportMaster } from '../';
import { HeaderTemplate, FooterTemplate } from '../../../reportTemplates';
import { entityTypesWithoutUnspecified } from './../../../../../firm/entities/entityType';

class SubmitOption {
  closeOnSuccess: boolean;
  saveAs: boolean;
  constructor(closeOnSuccess: boolean, saveAs: boolean) {
    this.closeOnSuccess = closeOnSuccess;
    this.saveAs = saveAs;
  }
}

@Component({
  selector: 'crs-report-master',
  templateUrl: './report-master.component.html',
})
export class ReportMasterComponent implements OnInit, OnDestroy {
  id: number;
  isAdd: boolean;

  objectTitle = 'Report Master';
  error: string = null;

  entityTypes = entityTypesWithoutUnspecified;

  subscriptions: Subscription[] = [];

  headers: HeaderTemplate[] = [];
  footers: FooterTemplate[] = [];

  submitButtonStream = new Subject<SubmitOption>();
  generateButtonStream = new Subject<void>();
  busy = {
    load: null,
    submit: null,
    generate: null,
  };

  form: UntypedFormGroup = this._reportMasterFormBuilder.buildForm(null);

  constructor(
    private readonly _reportMasterFormBuilder: ReportMasterFormBuilder,
    private readonly _reportMasterService: ReportMasterService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _location: Location
  ) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      const id = params.id;
      this.id = parseInt(id, 10);
      this.isAdd = id === 'add';
      this.getData();
    });

    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap((options) => {
            return this.submitObservable(options);
          })
        )
        .subscribe((options) => {
          if (options.closeOnSuccess) this.close();
          else this._messageService.success('Successfully saved report.');
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * Creates a form for the new template and subscribes to the interested variables
   * @param reportTemplate the template to create a form for
   */
  private initialiseNewForm(master: ReportMaster) {
    this.form = this._reportMasterFormBuilder.buildForm(master);
  }

  getData() {
    const newReport = of(null);

    const getExistingReport = this._reportMasterService.get(this.id).pipe(
      tap((master) => {
        this.initialiseNewForm(master);
      })
    );

    const getReportMaster = this.isAdd ? newReport : getExistingReport;

    const getPackage = this._reportMasterService.getPackage().pipe(
      tap((response) => {
        this.headers = response.headers;
        this.footers = response.footers;
      })
    );

    this.busy.load = concat(getReportMaster, getPackage).subscribe(
      () => {},
      (err) => this.showError(err)
    );
  }

  save(closeOnSuccess: boolean) {
    const submitOption = new SubmitOption(closeOnSuccess, false);
    this.submitButtonStream.next(submitOption);
  }

  saveAs() {
    const submitOption = new SubmitOption(true, true);
    this.submitButtonStream.next(submitOption);
  }

  submitObservable(options: SubmitOption): Observable<SubmitOption> {
    let observable: Observable<SubmitOption>;
    this.form.controls['pages'].updateValueAndValidity();
    this.form.markAsDirty();
    if (this.form.invalid) return EMPTY;

    const reportMaster = new ReportMasterModel(this.form.value);

    if (options.saveAs) {
      const saveAsModal = this._modalService.openModal(SaveAsComponent, null, {
        text: 'Please provide a name for the new report master',
      });
      observable = from(saveAsModal).pipe(
        switchMap((name) => {
          reportMaster.name = name as string;
          return this._reportMasterService.post(reportMaster);
        }),
        tap((id) => {
          this.isAdd = false;
          if (!options.closeOnSuccess) this.updateUrl();
        }),
        map(() => options)
      );
    } else if (this.isAdd) {
      observable = this._reportMasterService.post(reportMaster).pipe(
        tap((id) => {
          this.isAdd = false;
          if (!options.closeOnSuccess) this.updateUrl();
        }),
        map(() => options)
      );
    } else {
      reportMaster.id = this.id;
      observable = this._reportMasterService
        .put(reportMaster)
        .pipe(map(() => options));
    }

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(
      tap(() => this._messageService.success('Successfully saved report.')),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );

    return observable;
  }

  /**
   * After saving a new report template, updates url to reflect address of newly saved report
   */
  private updateUrl() {
    const url = this._router
      .createUrlTree(['../', this.id], { relativeTo: this._route })
      .toString();

    this._location.go(url);
  }

  delete() {
    return this._modalService.confirmation(
      'Are you sure you want to delete this master template? This action cannot be undone.',
      () => {
        this._reportMasterService.delete(this.id).subscribe(() => {
          this.close();
        }, this.showError);
      },
      true
    );
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  showError(err) {
    this.error = err;
    this._messageService.error(err);
  }
}
