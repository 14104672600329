import { MasterBasCodeService } from './../../../chart/master-bas-codes/master-bas-code.service';
import { SourceAccountService } from './../../sourceAccounts/source-account.service';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { getDefaultGridOptions } from 'src/app/shared/ag-grid/defaultGridOptions';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { MessageService, ModalService } from 'src/app/core';
import { accountingRenderers } from 'src/app/shared/ag-grid';
import {
  TaxCodeFormComponent,
  TaxCodeFormType,
} from '../tax-code-form/tax-code-form.component';
import { TaxCode } from '../tax-code';
import { TaxCodeService } from '../tax-code.service';
import { ActiveFileService } from 'src/app/accounting/active-file.service';
import { SourceService, SourceTypeId } from 'src/app/accounting/sourcedata';
import { SourceAccount } from '../../sourceAccounts';
import { MasterBasCode } from 'src/app/accounting/chart/master-bas-codes/master-bas-code';
import { SortHelpers } from 'src/app/shared/utilities/sort-helpers';

@Component({
  selector: 'crs-tax-codes-table',
  templateUrl: './tax-codes-table.component.html',
  styleUrls: ['./tax-codes-table.component.scss'],
})
export class TaxCodesTableComponent implements OnInit, OnChanges {
  @Input() search: string;
  @Input() selectedPostingAccountNo: string;
  @Input() selectedBasCode: string;

  @Output() fetchBasCodes: EventEmitter<Partial<string>[]> = new EventEmitter();
  @Output() fetchPostingAccounts: EventEmitter<Partial<string>[]> =
    new EventEmitter();

  busy = {
    taxCodes: false,
    accounts: false,
    basCodes: false,
  };

  sourceAccounts: SourceAccount[] = null;
  masterBasCodes: MasterBasCode[] = [];
  error: string = null;
  fileId: string;
  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;
  subscriptions: Subscription[] = [];
  taxCodes: TaxCode[] = null;

  sortValueComparator = SortHelpers.sortValueComparator;

  constructor(
    private sourceService: SourceService,
    private sourceAccountService: SourceAccountService,
    private activeFileService: ActiveFileService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private modalService: ModalService,
    private taxCodeService: TaxCodeService,
    private masterBasCodeService: MasterBasCodeService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.activeFileService.stream.subscribe((file) => {
        if (file) {
          this.fileId = file.id;
        }

        this.fetchTaxCodes();
        this.fetchAllBasCodes();
        this.fetchAllPostingAccounts();
      })
    );
  }

  ngOnChanges({
    search: searchChange,
    selectedPostingAccountNo: selectedPostingAccountNoChange,
    selectedBasCode: selectedBasCodeChange,
  }: SimpleChanges): void {
    if (!this.gridOptions.api) return;

    if (searchChange) this.gridOptions.api.setQuickFilter(this.search);

    if (selectedBasCodeChange) this.setFilter('basCode', this.selectedBasCode);

    if (selectedPostingAccountNoChange)
      this.setFilter('postingAccountNo', this.selectedPostingAccountNo);
  }

  private setFilter(column: string, value: string): void {
    const filter = this.gridOptions.api.getFilterInstance(column);
    if (value === null) filter.setModel(value);
    else if (value == '') filter.setModel({ values: [value, null] });
    else filter.setModel({ values: [value] });
    this.gridOptions.api.onFilterChanged();
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error, true);
  }

  onClickEditTaxCode(taxCode: TaxCode): void {
    this.modalService
      .openModal(TaxCodeFormComponent, taxCode.id, {
        fileId: this.fileId,
        masterBasCodes: this.masterBasCodes,
        postingAccounts: this.sourceAccounts,
        taxCodesTable: this,
        taxCode: taxCode,
      })
      .then(() => {
        this.fetchTaxCodes();
        this.messageService.success('Tax code saved successfully.');
      })
      .catch(() => true);
  }

  onClickDeleteTaxCode(taxCode: TaxCode): void {
    const confirmationMessage =
      '<p>Are you sure you want to delete this tax code?</p></p>This action cannot be undone.</p>';

    this.modalService.confirmation(
      confirmationMessage,
      () => this.deleteTaxCode(taxCode),
      true
    );
  }

  isCustomTaxCode(taxCode: TaxCode): boolean {
    return taxCode.standardTaxCodeId === null;
  }

  private deleteTaxCode(taxCode: TaxCode) {
    this.taxCodeService
      .deleteTaxCode$(taxCode.id)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap(() => {
          this.fetchTaxCodes();
          this.messageService.success('Tax code deleted successfully.');
        })
      )
      .subscribe();
  }

  createNewTaxCode(): void {
    this.modalService
      .openModal(TaxCodeFormComponent, TaxCodeFormType.Add, {
        fileId: this.fileId,
        postingAccounts: this.sourceAccounts,
        masterBasCodes: this.masterBasCodes,
        taxCodesTable: this,
      })
      .then(() => {
        this.fetchTaxCodes();
        this.messageService.success('Tax code added successfully');
      })
      .catch(() => true);
  }

  isUniqueTaxCode(taxCode: TaxCode): boolean {
    let found = this.taxCodes.find((code) => code.taxCode === taxCode.taxCode);
    return found === undefined || found.id === taxCode.id;
  }

  private fetchTaxCodes(): void {
    this.busy.taxCodes = true;

    this.taxCodeService
      .getAll$(this.fileId)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap((taxCodes: TaxCode[]) => {
          this.taxCodes = taxCodes;
          this.fetchPostingAccounts.emit(this.getPostingAccounts());
          this.fetchBasCodes.emit(this.getBasCodes());
        }),
        finalize(() => {
          this.busy.taxCodes = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private fetchAllPostingAccounts(): void {
    this.busy.accounts = true;

    this.getSourceAccounts$()
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap((accounts: SourceAccount[]) => (this.sourceAccounts = accounts)),
        finalize(() => {
          this.busy.accounts = false;
        })
      )
      .subscribe();
  }

  private getSourceAccounts$(): Observable<SourceAccount[]> {
    return this.sourceService.getAll$(this.fileId).pipe(
      switchMap((sources) => {
        const accountantsChartSource = sources.find(
          (item) => item.sourceTypeId === SourceTypeId.ChartOfAccounts
        );

        if (!accountantsChartSource) {
          throw new Error('Chart of Accounts source not found.');
        }

        return this.sourceAccountService.getForSource(
          accountantsChartSource.id
        );
      }),
      map((accounts) =>
        accounts.sort((a, b) => Number(a.accountNo) - Number(b.accountNo))
      )
    );
  }

  private fetchAllBasCodes(): void {
    this.busy.basCodes = true;

    this.getMasterBasCodes$()
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap((codes: MasterBasCode[]) => (this.masterBasCodes = codes)),
        finalize(() => (this.busy.basCodes = false))
      )
      .subscribe();
  }

  private getMasterBasCodes$(): Observable<MasterBasCode[]> {
    return this.masterBasCodeService.getMasterBasCodesForTax$();
  }

  private getPostingAccounts(): string[] {
    return [
      ...new Set(
        this.taxCodes.map((taxCode) => taxCode.postingAccountNo ?? '')
      ),
    ].sort();
  }

  private getBasCodes(): string[] {
    return [
      ...new Set(this.taxCodes.map((taxCode) => taxCode.basCode ?? '')),
    ].sort(this.sortValueComparator);
  }
}
