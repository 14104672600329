import {
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { RuleGrid } from './rule.grid';
import { GridApi, GridReadyEvent, RowDragEvent } from 'ag-grid-community';
import { EMPTY, Observable, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  takeUntil,
} from 'rxjs/operators';
import { MessageService, ModalService } from 'src/app/core';
import { RuleComponent } from '../rule/rule.component';
import {
  AllocationType,
  BacoAccountDto,
  BacoRuleDto,
  Stateful,
  StringConditionOptionNames,
  ValueConditionOptionNames,
} from 'src/app/baco/interfaces';
import { BacoFeedStore } from '../../baco-feed.store';
import { BacoRuleClient } from 'src/app/baco/common';
import { BacoRuleStore } from '../../baco-rule.store';

@Component({
  selector: 'crs-rule-table',
  templateUrl: './rule-table.component.html',
  styleUrls: ['./rule-table.component.scss'],
})
export class RuleTableComponent implements OnInit, OnDestroy {
  @ViewChild('titleCell', { static: true }) titleCell: TemplateRef<any>;
  @ViewChild('accountCell', { static: true }) accountCell: TemplateRef<any>;
  @ViewChild('optionsCell', { static: true }) optionsCell: TemplateRef<any>;

  @Input()
  public set search(value: string) {
    this._updateSearch$.next(value);
  }

  public ruleGrid: RuleGrid;
  public rules$ = this._ruleStore.rules$.pipe(map((x) => x.data));
  public status$ = this._ruleStore.rules$.pipe(
    map((x) => x.state),
    distinctUntilChanged()
  );
  public error$ = this._ruleStore.rules$.pipe(map((x) => x.errorMessage));

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _updateSearch$: Subject<string> = new Subject<string>();
  private gridApi: GridApi;

  public accounts$: Observable<Stateful<BacoAccountDto[]>> =
    this._feedStore.accounts$;
  public accounts: BacoAccountDto[] = [];

  constructor(
    private readonly _ruleApi: BacoRuleClient,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    private readonly _feedStore: BacoFeedStore,
    private readonly _ruleStore: BacoRuleStore,

    private injector: Injector
  ) {}

  public ngOnInit(): void {
    this.accounts$
      .pipe(takeUntil(this._destroy$))
      .subscribe((statefulAccounts) => {
        console.log('statefulAccounts: ', statefulAccounts);
        if (statefulAccounts.state === 'SUCCESS') {
          this.accounts = statefulAccounts.data;
        } else {
          this.accounts = [];
        }
      });
    this.ruleGrid = new RuleGrid(
      this.titleCell,
      this.accountCell,
      this.optionsCell
    );

    this.ruleGrid.gridOptions.onGridReady = (event) => this.gridReady(event);
    this.ruleGrid.gridOptions.onRowDragEnd = (event) =>
      this.onRowDragEnd(event);
  }

  public onRowDragEnd(event: RowDragEvent): void {
    const ruleDto = event.node.data as BacoRuleDto;

    const rules = this._ruleStore.rules$.getValue().data;
    const currentOrder = rules.indexOf(ruleDto) + 1;
    const newSortOrder = event.overIndex + 1;
    if (currentOrder === newSortOrder) return;
    event.api.showLoadingOverlay();
    this._ruleApi
      .reorder(ruleDto.id, newSortOrder)
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => event.api.hideOverlay()),
        catchError((err) => {
          this._messageService.error(err);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this._ruleStore.changeRuleOrder(ruleDto, newSortOrder - 1);
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
    this._updateSearch$.complete();
  }

  public getRuleDescriptionCondition(rule: BacoRuleDto): string {
    const descFilter = rule.descriptionCondition;
    if (descFilter == null) return '';
    const conditionName = StringConditionOptionNames.find(
      (t) => t.value == (descFilter.conditionOption as number)
    )?.name;
    return `Description ${conditionName} '${descFilter.conditionValue}'`;
  }

  public getRuleAmountCondition(rule: BacoRuleDto): string {
    const amountFilter = rule.amountCondition;
    if (amountFilter == null) return '';
    const conditionName = ValueConditionOptionNames.find(
      (t) => t.value == (amountFilter.conditionOption as number)
    )?.name;
    return `Amount ${conditionName} $${amountFilter.conditionValue}`;
  }

  private getAccountString(account: BacoAccountDto): string {
    if (!account) return '';
    if (!account.accountNo) return account.accountName || '';
    return `${account.accountNo} / ${account.accountName}`;
  }

  private getAllocationConditions(
    rule: BacoRuleDto,
    allocationType: AllocationType
  ): { value: number; account: string }[] {
    return rule.allocations
      .filter((t) => t.allocationType === allocationType)
      .map((t) => {
        const account = this.accounts.find(
          (a) => a.accountId === t.bacoAccountId
        );
        const accountString = this.getAccountString(account);
        return { value: t.allocationValue, account: accountString };
      });
  }

  public getAmountAccountConditions(
    rule: BacoRuleDto
  ): { amount: number; account: string }[] {
    const conditions = this.getAllocationConditions(
      rule,
      AllocationType.Amount
    );
    if (conditions?.length > 0) {
      return conditions.map((c) => ({
        amount: c.value,
        account: c.account,
      }));
    }
    return null;
  }

  public getPercentageAccountConditions(
    rule: BacoRuleDto
  ): { percentage: number; account: string }[] {
    const conditions = this.getAllocationConditions(
      rule,
      AllocationType.Percentage
    );
    if (conditions?.length > 0) {
      return conditions.map((c) => ({
        percentage: c.value,
        account: c.account,
      }));
    }
    return null;
  }

  public onEditRule(rule: BacoRuleDto) {
    const feedId = this._feedStore.feed$.getValue().data.id;
    this._modalService
      .openModal(
        RuleComponent,
        rule.id,
        {
          feedId: feedId,
          rule: rule,
        },
        {
          windowClass: 'rule-modal',
          injector: this.injector,
        }
      )
      .then((rule: BacoRuleDto) => {})
      .catch(() => true);
  }

  public onDeleteRule(ruleId: string) {
    this._modalService.confirmation(
      'Are you sure you want to delete this rule?',
      () => {
        this.gridApi.setGridOption('loading', true);
        this._ruleApi
          .archive(ruleId)
          .pipe(
            catchError((err) => {
              this._messageService.error(err);
              return EMPTY;
            }),
            takeUntil(this._destroy$),
            finalize(() => this.gridApi.setGridOption('loading', false))
          )
          .subscribe(() => {
            this._ruleStore.removeRule(ruleId);
          });
      }
    );
  }

  private gridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.rules$.pipe(takeUntil(this._destroy$)).subscribe((data) => {
      this.gridApi.setGridOption('rowData', data);
    });

    this.status$.pipe(takeUntil(this._destroy$)).subscribe((x) => {
      if (x === 'PENDING') {
        this.gridApi.setGridOption('loading', true);
      } else {
        this.gridApi.setGridOption('loading', false);
      }
    });

    this._updateSearch$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe((search) => {
        this.gridApi.setGridOption('quickFilterText', search);
      });
  }
}
