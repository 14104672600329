<crs-form-error [error]="error"></crs-form-error>

<div class="position-relative pad-x">
  <crs-busy *ngIf="busy.loading"></crs-busy>

  <ng-container *ngIf="manageData">
    <div
      class="mt-5"
      *ngFor="
        let balances of [manageData.generalPool, manageData.lowValuePool];
        let i = index
      "
    >
      <h2>{{ depreciationPools[balances.pool] | sentence }}</h2>

      <div class="row">
        <div class="col-lg-10 col-xl-8">
          <div class="row">
            <div class="col-label"></div>
            <div class="col-control col-header">Taxation</div>
            <div class="col-toggle"></div>
            <div class="col-control col-header">Accounting</div>
          </div>

          <div class="row">
            <label class="col-label col-form-label"
              >Balance Prior to Depreciation
              {{ assetsContext.year.year }}</label
            >
            <div class="col-control" *ngFor="let detail of [balances.taxation]">
              <div
                class="form-control money-control"
                [id]="'pool-taxation-balanceBeforeDepreciation-' + i"
              >
                {{ detail.balanceBeforeDepreciation | formatNumber : 0 : '-' }}
              </div>
            </div>
            <div class="col-toggle"></div>
            <div
              class="col-control"
              *ngFor="let detail of [balances.accounting]"
            >
              <div
                class="form-control money-control"
                [id]="'pool-accounting-balanceBeforeDepreciation-' + i"
              >
                {{ detail.balanceBeforeDepreciation | formatNumber : 0 : '-' }}
              </div>
            </div>
          </div>

          <div class="row">
            <label class="col-label col-form-label"
              >Less: Depreciation {{ assetsContext.year.year }}</label
            >
            <div class="col-control" *ngFor="let detail of [balances.taxation]">
              <div
                class="form-control money-control"
                [id]="'pool-taxation-depreciation-' + i"
              >
                {{ detail.depreciation | formatNumber : 0 : '-' }}
              </div>
              <hr />
            </div>
            <div class="col-toggle"></div>
            <div
              class="col-control"
              *ngFor="let detail of [balances.accounting]"
            >
              <div
                class="form-control money-control"
                [id]="'pool-accounting-depreciation-' + i"
              >
                {{ detail.depreciation | formatNumber : 0 : '-' }}
              </div>
              <hr />
            </div>
          </div>

          <div class="row">
            <label class="col-label col-form-label"
              >Initial Closing Balance for {{ assetsContext.year.year }}</label
            >
            <div class="col-control" *ngFor="let detail of [balances.taxation]">
              <div
                class="form-control money-control"
                [id]="'pool-taxation-initialBalance-' + i"
              >
                <strong>{{
                  detail.initialBalance | formatNumber : 0 : '-'
                }}</strong>
              </div>
            </div>
            <div class="col-toggle"></div>
            <div
              class="col-control"
              *ngFor="let detail of [balances.accounting]"
            >
              <div
                class="form-control money-control"
                [id]="'pool-accounting-initialBalance-' + i"
              >
                <strong>{{
                  detail.initialBalance | formatNumber : 0 : '-'
                }}</strong>
              </div>
            </div>
          </div>

          <div class="row">
            <label class="col-label col-form-label"
              >Add: Balance Assessed</label
            >
            <div class="col-control" *ngFor="let detail of [balances.taxation]">
              <div
                class="form-control money-control"
                [id]="'pool-taxation-assessableAmount-' + i"
              >
                {{ detail.assessableAmount | formatNumber : 0 : '-' }}
              </div>
            </div>
            <div class="col-toggle"></div>
            <div
              class="col-control"
              *ngFor="let detail of [balances.accounting]"
            >
              <div
                class="form-control money-control"
                [id]="'pool-accounting-assessableAmount-' + i"
              >
                {{ detail.assessableAmount | formatNumber : 0 : '-' }}
              </div>
            </div>
          </div>

          <div class="row">
            <label class="col-label col-form-label"
              >Less: Balance Written Off</label
            >
            <div class="col-control" *ngFor="let detail of [balances.taxation]">
              <div
                class="form-control money-control"
                [id]="'pool-taxation-writeOffs-' + i"
              >
                {{ detail.writeOffs | formatNumber : 0 : '-' }}
              </div>
              <hr />
            </div>
            <div class="col-toggle"></div>
            <div
              class="col-control"
              *ngFor="let detail of [balances.accounting]"
            >
              <div
                class="form-control money-control"
                [id]="'pool-accounting-writeOffs-' + i"
              >
                {{ detail.writeOffs | formatNumber : 0 : '-' }}
              </div>
              <hr />
            </div>
          </div>

          <div class="row">
            <label class="col-label col-form-label"
              >Closing Balance for {{ assetsContext.year.year }}</label
            >
            <div class="col-control" *ngFor="let detail of [balances.taxation]">
              <div
                class="form-control money-control"
                [id]="'pool-taxation-closingBalance-' + i"
              >
                <strong>{{
                  detail.closingBalance | formatNumber : 0 : '-'
                }}</strong>
              </div>
            </div>
            <div class="col-toggle"></div>
            <div
              class="col-control"
              *ngFor="let detail of [balances.accounting]"
            >
              <div
                class="form-control money-control"
                [id]="'pool-accounting-closingBalance-' + i"
              >
                <strong>{{
                  detail.closingBalance | formatNumber : 0 : '-'
                }}</strong>
              </div>
            </div>
          </div>

          <div
            class="row"
            *ngIf="balances.taxation.error && balances.accounting.error"
          >
            <label class="col-label col-form-label"></label>
            <div class="col-full-width">
              <span class="alert alert-danger d-block">
                This pool balance is only partly written off. Please rectify by
                selecting one of the options below:</span
              >
            </div>
          </div>

          <div
            class="row"
            *ngIf="
              (balances.taxation.error && !balances.accounting.error) ||
              (!balances.taxation.error && balances.accounting.error)
            "
          >
            <label class="col-label col-form-label"></label>
            <div class="col-control" *ngIf="true">
              <span
                class="alert alert-danger d-block"
                *ngIf="balances.taxation.error"
              >
                This pool balance is only partly written off. Please rectify by
                selecting one of the options below:</span
              >
            </div>
            <div class="col-toggle"></div>
            <div class="col-control" *ngIf="true">
              <span
                class="alert alert-danger d-block"
                *ngIf="balances.accounting.error"
              >
                This pool balance is only partly written off. Please rectify by
                selecting one of the options below:</span
              >
            </div>
          </div>

          <crs-manage-pool-actions
            [assetsContext]="assetsContext"
            [balances]="balances"
          ></crs-manage-pool-actions>
        </div>
      </div>
    </div>
  </ng-container>
</div>
