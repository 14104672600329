import { DepreciationPool } from './../../assets/depreciation-pool';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { getDefaultGridOptions } from 'src/app/shared';
import { tap } from 'rxjs/operators';

import { AssetReportGrid } from '../asset-report-grid';
import { AssetReportPool } from './asset-report-pool';

@Component({
  selector: 'crs-asset-report-pool',
  templateUrl: './asset-report-pool.component.html',
  styleUrls: ['./asset-report-pool.component.scss']
})
export class AssetReportPoolComponent implements OnInit, OnDestroy {

  @Input() content: AssetReportPool;
  @Input() poolType: DepreciationPool;

  depreciationPools = DepreciationPool;

  search = new UntypedFormControl();
  subscriptions: Subscription[] = [];

  gridOptions = getDefaultGridOptions();
  assetReportDollarParams = AssetReportGrid.dollarParams;

  constructor() { }

  ngOnInit() {

    AssetReportGrid.customiseGrid(this.gridOptions);

    this.subscriptions.push(
    this.search.valueChanges.pipe(
      tap(v => this.gridOptions.api.setQuickFilter(v)))
    .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }
}
