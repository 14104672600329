import {
  Component,
  OnInit,
  Input,
  ViewChild,
  forwardRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Observable, concat, of, merge } from 'rxjs';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  map,
  catchError,
  exhaustMap,
} from 'rxjs/operators';

import { PagedResponse } from 'src/app/core';
import { ActiveFileService, AccountService } from 'src/app/accounting';
import { MasterAccountUltraLight } from 'src/app/accounting/ledger/masterAccounts/master-account-ultra-light';

@Component({
  selector: 'crs-master-account-select',
  templateUrl: './master-account-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MasterAccountSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MasterAccountSelectComponent
  implements OnInit, ControlValueAccessor
{
  @Input() multiple = false;
  @Input() placeholder: string = null;
  @Input() clearable = true;

  @ViewChild(NgSelectComponent, { static: true })
  private valueAccessor: ControlValueAccessor;

  scroll$ = new Subject<void>();
  accounts$: Observable<MasterAccountUltraLight[]>;
  accounts: MasterAccountUltraLight[];
  loading = false;
  accountInput = new Subject<string>();

  searchTerm: string;
  pagedResponse: PagedResponse<any>;

  constructor(
    private accountService: AccountService,
    private activeFileService: ActiveFileService
  ) {}

  private searchAccounts(
    search,
    page: number = null
  ): Observable<PagedResponse<MasterAccountUltraLight[]>> {
    if (!page) page = 1;
    return this.accountService.searchMasters(search, page, 100);
  }

  getNext() {
    if (
      this.pagedResponse &&
      this.pagedResponse.page < this.pagedResponse.pages
    ) {
      this.scroll$.next();
    }
  }

  writeValue(value: any | any[]): void {
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  ngOnInit() {
    (this.valueAccessor as NgSelectComponent).bindLabel = 'accountName';
    (this.valueAccessor as NgSelectComponent).multiple = this.multiple;

    this.accounts$ = concat(
      of([] as MasterAccountUltraLight[]),
      merge(
        this.accountInput.pipe(
          startWith(''),
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => (this.loading = true)),
          tap((term) => (this.searchTerm = term)),
          switchMap((term) =>
            this.searchAccounts(term, 1).pipe(
              tap((pagedResponse) => (this.pagedResponse = pagedResponse)),
              map((pagedResponse) =>
                pagedResponse.records.map((a) => new MasterAccountUltraLight(a))
              ),
              tap((accounts) => (this.accounts = accounts))
            )
          ),
          catchError((err) => {
            console.error('Error searching for accounts', err);
            return of([] as MasterAccountUltraLight[]);
          }),
          tap(() => (this.loading = false))
        ),
        this.scroll$.pipe(
          tap(() => (this.loading = true)),
          exhaustMap((term) =>
            this.searchAccounts(
              this.searchTerm,
              this.pagedResponse.page + 1
            ).pipe(
              tap((response) => (this.pagedResponse = response)),
              map((response) =>
                response.records.map(
                  (account) => new MasterAccountUltraLight(account)
                )
              ),
              map((accounts) => this.accounts.concat(accounts)),
              tap((accounts) => (this.accounts = accounts))
            )
          ),
          catchError((err) => {
            console.error('Error retrieving next page', err);
            return of([] as MasterAccountUltraLight[]);
          }),
          tap(() => (this.loading = false))
        )
      )
    );
  }
}
