import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { cashflowReportModeOptions } from "./cashflow-report-mode-options";

@Component({
  selector: 'crs-report-template-page',
  templateUrl: './report-template-page.component.html'
})
export class ReportTemplatePageComponent {

  @Input('index') i: number;
  @Input() pageId: string = null;
  @Input() templateGroup: UntypedFormGroup;
  @Input() formGroup: UntypedFormGroup;
  @Output() removed = new EventEmitter<number>();
  cashflowReportModeOptions = cashflowReportModeOptions;
  isExpanded = false;
  editingTitle = false;

  constructor() {     
  } 
}
