<ng-template #resultTemplate let-result="result" let-term="term">
  <ngb-highlight
    [result]="property ? result[property] : result"
    [term]="term"
  ></ngb-highlight>
</ng-template>

<div>
  <div class="input-group">
    <input
      #typeahead
      class="form-control"
      type="text"
      [editable]="editable"
      [formControl]="selected"
      [inputFormatter]="formatter"
      [ngbTypeahead]="search"
      [resultTemplate]="template ? template : resultTemplate"
      (focus)="focus$.next($event.target.value)"
      (selectItem)="onSelect($event)"
    />

    <div class="input-group-append" *ngIf="clearable">
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="clearSelection($event)"
      >
        X
      </button>
    </div>
  </div>
</div>
