<!-- Input Editor -->
<crs-report-editable-element [index]="index" [reportContent]="reportContent" [reportMetaData]="reportMetaData" (edit)="onEdit()" [onSubmit]="onSubmit" [editable]="true" [cancellable]="true" *ngIf="!reportContent.output">

  <div viewer>
    <div class="signature-block">

      <hr class="signature-rule" />

      <p class="signature-name">
        {{element.allResponsible ? 'Responsible Persons' : element.name}}
      </p>
      <p class="signature-date">
        Date: {{element.useReportDate ? 'Use Report Date' : ''}}
      </p>

    </div>
  </div>

  <div editor>
    <form [formGroup]="form" novalidate *ngIf="isEditing">

      <div class="form-group" *ngIf="form?.get('allResponsible')?.value != true">
        <label class="control-label">Signatory Name</label>
        <input type="text" class="form-control" formControlName="name" />
      </div>

      <div class="form-group">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" formControlName="allResponsible">
          <label class="form-check-label">Require signature from all responsible persons</label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" formControlName="useReportDate">
          <label class="form-check-label">Use Report Date</label>
        </div>
      </div>

    </form>
  </div>

</crs-report-editable-element>

<!-- Output Editor -->
<crs-report-editable-element [index]="index" [reportContent]="reportContent" (edit)="onEdit()" [reportMetaData]="reportMetaData" [onSubmit]="onSubmit" [editable]="true" [cancellable]="true" *ngIf="reportContent.output">

  <div viewer>
    <div class="signature-block">

      <hr />

      <p class="signature-name">
        {{element.name}}
      </p>
      <p class="signature-date">
        Date: {{element.date | date:'d MMMM yyyy'}}
      </p>

    </div>
  </div>

  <div editor>
    <form [formGroup]="form" novalidate *ngIf="isEditing">

      <div class="form-group">
        <label class="control-label">Signatory Name</label>
        <input type="text" class="form-control" formControlName="name" />
      </div>

      <div class="form-group">
        <label class="control-label">Date</label>
        <crs-date-picker formControlName="date" [clearable]="true"></crs-date-picker>
      </div>

    </form>
  </div>

</crs-report-editable-element>

