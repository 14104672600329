import { HeaderMatch, HeaderMatchModel } from './../../layout/headerMatch';
import { LayoutOption } from '../../layout/layoutOption';

export enum DisclosureLinkOption {
    AppendDetail = 0,
    LinkOnly = 1
}

export class DisclosureLink {
  headerMatch: HeaderMatch;
  linkOption: DisclosureLinkOption;
  forceLinkOption: boolean;
  layoutOption: LayoutOption;
  forceLayoutOption: boolean;
  placeholderIdentifier: string;

  constructor(data) {
    Object.assign(this, data);
    if (data.headerMatch) this.headerMatch = new HeaderMatch(data.headerMatch);
  }
}

export class DisclosureLinkModel {
  headerMatch: HeaderMatchModel;
  linkOption: DisclosureLinkOption;
  forceLinkOption: boolean;
  layoutOption: LayoutOption;
  forceLayoutOption: boolean;
  placeholderIdentifier: string;

  constructor(data) {
    Object.assign(this, data);
    if (data.headerMatch) this.headerMatch = new HeaderMatchModel(data.headerMatch);
  }
}
