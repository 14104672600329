<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.divisions"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-no-bottom">
      <button
        class="btn btn-secondary"
        title="Create Division"
        type="button"
        (click)="addDivision()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Division
      </button>
    </div>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="divisionsObservable | async"
        (rowClicked)="selectDivision($event)"
      >
        <ag-grid-column field="code" headerName="Code"></ag-grid-column>
        <ag-grid-column field="name" headerName="Name"></ag-grid-column>
      </ag-grid-angular>
    </div>
  </div>
</div>
