import { GridOptions } from 'ag-grid-community';
import {
  agGridExcelStyles,
  agGridExportCellCallback,
} from './ag-grid-excel-styles';
import { columnTypes } from './columnTypes';
import { renderers } from './renderers/renderers';

const defaultGridOptions: GridOptions = {
  suppressCellSelection: true,
  animateRows: true,
  domLayout: 'autoHeight',
  stopEditingWhenCellsLoseFocus: true,
  components: renderers,
  columnTypes: columnTypes,
  suppressAggFuncInHeader: true,
  enableBrowserTooltips: true,
  singleClickEdit: true,
  popupParent: document.querySelector('body'),
  suppressPropertyNamesCheck: true,
  excelStyles: [...agGridExcelStyles],
  colResizeDefault: 'shift',
  defaultExcelExportParams: {
    processCellCallback: <any>agGridExportCellCallback,
  },
  defaultCsvExportParams: {
    processCellCallback: <any>agGridExportCellCallback,
  },
  defaultColDef: {
    flex: 1,
    resizable: true,
  },
  onGridSizeChanged: (e) => {
    if (e.api.getDisplayedRowCount() > 0) {
      e.api.sizeColumnsToFit();
    }
  },
  onFirstDataRendered: (e) => e.api.sizeColumnsToFit(),
  onColumnVisible: (e) => e.api.sizeColumnsToFit(),
  getRowStyle: (params) => {
    if (params.node.footer) {
      return { fontWeight: 'bold', color: '#5b5b5e' };
    }
  },
} as GridOptions;

export function getDefaultGridOptions(): GridOptions {
  const excludeDeepCopy = ['popupParent'];
  function deepCopy(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => deepCopy(item));
    }

    const clonedObj: any = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clonedObj[key] = excludeDeepCopy.includes(key)
          ? obj[key]
          : deepCopy(obj[key]);
      }
    }

    return clonedObj;
  }

  const options = deepCopy(defaultGridOptions) as GridOptions;

  // AAEP-12856: Fix for `sortable` option always set to false on the object, when table is loaded from router link
  options.defaultColDef.sortable = true;

  return options;
}
