import { DisclosureAutoSelectionMethod } from './disclosure-template';
import { DisclosureLevel } from './disclosure-level';
import { DisclosureVariant } from './disclosure-variant';

export enum DisclosureSelectionMode {
  Auto = 0,
  Yes = 1,
  No = 2
}

export class DisclosureSelector {
  id: string;
  disclosureChainId: string;
  fileId: string;
  name: string;
  level: DisclosureLevel;
  masterDisclosureId: string;
  autoSelectionMethod: DisclosureAutoSelectionMethod;
  disclosureVariants: DisclosureVariant[];

  defaultDisclosureVariant: DisclosureVariant;
  selectedDisclosureVariantId: string;
  selectionMode: DisclosureSelectionMode;

  constructor(data) {
    Object.assign(this, data);
    if (data.disclosureVariants) this.disclosureVariants = data.disclosureVariants.map(v => new DisclosureVariant(v));
    if (data.defaultDisclosureVariant) this.defaultDisclosureVariant = new DisclosureVariant(data.defaultDisclosureVariant);
  }
}

export class DisclosureSelectorModel {
  disclosureChainId: string;
  selectionMode: DisclosureSelectionMode;
  selectedDisclosureVariantId: string;

  constructor(data) {
    this.disclosureChainId = data.disclosureChainId;
    this.selectionMode = data.selectionMode;
    this.selectedDisclosureVariantId = data.selectedDisclosureVariantId;
  }
}

