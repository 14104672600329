import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LedgerReportFilters, LedgerReportComponent } from './../ledger-report/ledger-report.component';

export class LedgerReportModalParams {
  datasetId: string;

  /**
   * If filters are supplied, will build those filters rather than the default and trigger an immediate run of the report.
   */
  filters: LedgerReportFilters;

  constructor(datasetId: string, filters: LedgerReportFilters) {
    this.datasetId = datasetId;
    this.filters = filters;
  }
}

@Component({
  selector: 'crs-ledger-report-modal',
  templateUrl: './ledger-report-modal.component.html'
})
export class LedgerReportModalComponent {

  @Input() params: LedgerReportModalParams;

  constructor(private readonly _activeModal: NgbActiveModal) { }

  close() {
    this._activeModal.close();
  }

}
