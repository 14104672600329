import { Observable, concat, EMPTY, of } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { MessageService } from 'src/app/core';
import { Account } from '..';
import { AccountService } from '../account.service';
import {
  DragContext,
  IMoveRequest,
  MoveAction,
  IAccountGridManager,
  MoveSource,
} from './accountGridModel';
import { AccountContext } from './accounts.component';
import { StandardAccountService } from 'src/app/accounting/chart/headers-and-accounts/standard-account.service';
import { StandardAccountContext } from 'src/app/accounting/chart/headers-and-accounts/standard-account-headers-grid/standard-account-headers-grid.component';

export class MoveProcessor {
  private readonly _accountGridManagers: IAccountGridManager[] = [];

  constructor(
    private readonly _context: AccountContext | StandardAccountContext,
    private readonly _accountService: AccountService | StandardAccountService,
    private readonly _messageService: MessageService
  ) {}

  addAccountGridManagerToManagedCollection(
    gridManager: IAccountGridManager
  ): void {
    if (gridManager) {
      this._accountGridManagers.push(gridManager);
    }
  }

  getSelectionAsMoveSource(): MoveSource {
    if (!this._accountGridManagers) return null;
    for (let index = 0; index < this._accountGridManagers.length; ++index) {
      const m = this._accountGridManagers[index];
      const source = m.getMoveSource(null);
      if (source.movingRows.length) return source;
    }

    return null;
  }

  // This method visualises to the user what is likely to happen when the requested operation is committed.
  // This is the 'feedback' given to a user as they drag an item around for example
  visualise(request: DragContext) {
    // Get previous node
    const previousResult = request.previousMoveResult;
    const result = request.moveResult;

    // If the request has not changed, do nothing
    if (!previousResult && !result) return;

    if (
      (previousResult && !result) ||
      (!previousResult && result) ||
      !result.equals(previousResult)
    ) {
      // If it has moved, update the grid manager for before and after the move
      const affectedGridManagers: IAccountGridManager[] = [];
      if (request.previousMoveResult) {
        request.previousMoveResult.gridManager.visualiseRemove(
          request.previousMoveResult
        );
        affectedGridManagers.push(request.previousMoveResult.gridManager);
      }
      if (request.moveResult) {
        request.moveResult.gridManager.visualiseAdd(request.moveResult);
        if (
          !affectedGridManagers.length ||
          request.moveResult.gridManager.id !== affectedGridManagers[0].id
        ) {
          affectedGridManagers.push(request.moveResult.gridManager);
        }
      }
      affectedGridManagers.forEach((g) => g.refreshGrid());
    }
  }

  /**
   * This executes the move request, first in the UI, and then in the back-end
   * @param request The move request object
   */
  execute(request: IMoveRequest) {
    const affectedManagers = [request.moveSource.gridManager];
    if (request.moveResult.gridManager.id !== affectedManagers[0].id) {
      affectedManagers.push(request.moveResult.gridManager);
    }

    try {
      // Restore backups so we are working with the original unchanged data before any visualisation occured
      affectedManagers.forEach((m) => m.restoreBackup());

      if (request.moveResult.action === MoveAction.None) return of(null);

      // Execute quick UI
      this.executeUI(request);
      affectedManagers.forEach((m) => {
        m.refreshGrid();
      });

      // Execute in the database and update UI
      return this.executeBackendAndRefresh(request, affectedManagers).pipe(
        catchError((err) => {
          this.showError(err);
          affectedManagers.forEach((m) => m.restoreBackup());
          return EMPTY;
        }),
        finalize(() =>
          affectedManagers.forEach((m) => {
            m.refreshGrid();
            m.completeLoader();
          })
        )
      );
    } catch (error) {
      console.log('Error updating UI for drag action', error);
      affectedManagers.forEach((m) => {
        console.log('restoring backup for', m.id);
        // this rarely works, because the grid has likely broken mid-render if there are issues with hierarchies/loops,
        // so it won't be able to come in and refresh
        m.completeLoader();
        m.restoreBackup();
        m.refreshGrid();
      });
      return of();
    }
  }

  /**
   * This method fully executes the move in the UI. This is done prior to any results being committed to the
   * database. After the database changes, this will be refreshed. This step is taken to make the movement appear
   * instantaneous to the user, even though it needs to be saved and retrieved from the database.
   * @param request The move request object to execute in the UI
   */
  private executeUI(request: IMoveRequest) {
    console.log('Executing UI Drop', request);

    const flattenedRows = request.moveSource
      .getFlattenedRows()
      .map((r) => r.shallowCopy());
    console.log('[AccountGrid] Flattened Rows', flattenedRows);

    const action = request.moveResult.action;
    const overNode = request.moveResult.overNode;
    request.moveSource.gridManager.removeRows(flattenedRows);

    // If linking, then these accounts will disappear, no need to insert new ones
    if (action === MoveAction.Link) return;

    // Reassign Hierarchy of moving rows to fit 'target' row
    const baseHierarchy = this.getBaseHierarchy(overNode, action);

    request.moveSource.movingRows.forEach((r) => {
      const originalLength = r.account.hierarchy.length;
      const data = overNode.data as Account;

      // Update hierarchy of moving account
      const account = flattenedRows.find(
        (f) => f.id === r.account.id
      ) as Account;
      account.parentId =
        action === MoveAction.InsertInto ? data.id : data.parentId;
      account.hierarchy = baseHierarchy.concat([account.id]);

      // Update hierarchy of children of moving account
      r.affectedChildren.forEach((c) => {
        const child = flattenedRows.find((f) => f.id === c.id);
        child.hierarchy.splice(0, originalLength, ...account.hierarchy);
      });
    });

    // Insert moved rows
    if (action === MoveAction.InsertAbove) {
      request.moveResult.gridManager.insertRows(overNode, flattenedRows, 0);
    }
    if (action === MoveAction.InsertBelow) {
      request.moveResult.gridManager.insertRows(overNode, flattenedRows, 1);
    }
    if (action === MoveAction.InsertInto) {
      request.moveResult.gridManager.insertRows(overNode, flattenedRows, 1);
    }
  }

  /**
   * This method executes the move, and refreshes the relevant items in case the server side commit is different
   * in any way to what the UI has shown
   */
  private executeBackendAndRefresh(
    request: IMoveRequest,
    managers: IAccountGridManager[]
  ) {
    console.log('Executing Backend', request);

    managers.forEach((m) => m.startLoader());

    const action = request.moveResult.action;
    const overNode = request.moveResult.overNode;
    const fileId = (this._context as AccountContext).fileId || null;

    // Execute the move in the backend
    const processObservable = this._accountService.move(
      overNode.id,
      action,
      request.moveSource.movingRows.map((r) => r.account.id),
      fileId
    );

    // Retrieve accounts for the affected parents so as to refresh them
    const newParentId = this.getBaseHierarchy(overNode, action).pop();
    const movedFromParentIds = request.moveSource.movingRows.map(
      (r) => r.account.parentId
    );

    let refreshObservable: Observable<any>;
    if (managers.length === 1)
      refreshObservable = managers[0].refreshIdsObservable([
        ...new Set(movedFromParentIds.concat(newParentId)),
      ]);
    else {
      refreshObservable = concat(
        request.moveSource.gridManager.refreshIdsObservable([
          ...new Set(movedFromParentIds),
        ]),
        request.moveResult.gridManager.refreshIdsObservable([newParentId])
      ).pipe(
        catchError((err) => {
          console.log(
            'error refreshing data, pulling all accounts in instead',
            err
          );
          // this._refreshAll(); // Todo: refresh method conflicts with grid redraw... rethink
          return EMPTY;
        })
      );
    }

    return concat(processObservable, refreshObservable);
  }

  // Helper Method
  private getBaseHierarchy(overNode, action) {
    const hierarchy = overNode.data.hierarchy.slice();
    if (action !== MoveAction.InsertInto) hierarchy.pop();
    return hierarchy;
  }

  private showError(error) {
    this._messageService.error(error);
  }
}
