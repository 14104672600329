import { Office } from '../offices'

export class Team {
  id: string;
  name: string;
  office: Office;
  teamUsers = [];

  constructor(data) {
    Object.assign(this, data);
  }
}

export class TeamModel {
  id: string;
  name: string;
  officeId: string;
  teamUsers = [];

  constructor(data, users) {
    Object.assign(this, data);
    this.teamUsers = users;
    if (data.office) this.officeId = data.office.id;
  }
}


