import { Injectable } from '@angular/core';
import { Firm, FirmCreateModel, FirmModel } from './firm';
import { ApiService } from 'src/app/core';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { FirmUser } from '../firmUsers/firmUser';

@Injectable({
  providedIn: 'root'
})
export class FirmService {

  readonly base: string = 'api/central/firms';

  constructor(private readonly _apiService: ApiService) { }

  get(id: number) {
    return this._apiService.get<Firm>(this.base + '/' + id).pipe(map(d => new Firm(d)));
  }

  getAll() {
    return this._apiService.get<Firm[]>(this.base).pipe(map(l => l.map(d => new Firm(d))));
  }

  getOwners(firmId: number) {
    return this._apiService.get<FirmUser[]>(this.base + '/' + firmId + '/owners').pipe(map(d => d.map(u => new FirmUser(u))));
  }

  addOwner(firmId: number, user: FirmUser) {
    return this._apiService.post<any>(this.base + '/' + firmId + '/owners', user).pipe(map(d => new FirmUser(d)));
  }

  resendInvite(firmId: number, userId: string) {
    return this._apiService.post<any>(this.base + '/' + firmId + '/owners/' + userId + '/resend-invite', null);
  }

  post(firm: FirmCreateModel) {
    return this._apiService.post<any>(this.base, firm);
  }

  put(firm: FirmModel): Observable<any> {
    return this._apiService.put(this.base + '/' + firm.id, firm);
  }
}
