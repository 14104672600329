import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IBacoTransactionComment } from '../../../../../interfaces';

@Injectable()
export class BacoTransactionCommentManageService {

  public comments$: BehaviorSubject<IBacoTransactionComment[]> = new BehaviorSubject<IBacoTransactionComment[]>([]);
  public submitComment$: Subject<string> = new Subject<string>();

  public get commentTransactionId(): string {
    return this._transactionId;
  }

  private _comments: IBacoTransactionComment[] = [];
  private _transactionId: string;

  constructor() {
  }

  public seTCommentsState(transactionId: string, comments: IBacoTransactionComment[]): void {
    this._transactionId = transactionId;
    this._comments = comments;
    this.comments$.next(this._comments);
  }

  public createdComment(comment: IBacoTransactionComment): void {
    this._comments = [...this._comments, comment];
    this.comments$.next(this._comments);
  }

  public insertComment(comment: IBacoTransactionComment, index: number): void {
    this._comments = [...this._comments];
    this._comments.splice(index, 0, comment);
    this.comments$.next(this._comments);
  }

  public updatedComment(oldComment?: IBacoTransactionComment, newComment?: IBacoTransactionComment): void {
    if (oldComment && newComment) {
      const commentIndex = this._comments.indexOf(oldComment);
      if (commentIndex > -1) {
        this._comments.splice(commentIndex, 1, newComment);
      }
    }
    this._comments = [...this._comments];
    this.comments$.next(this._comments);
  }

  public removedComment(comment: IBacoTransactionComment): number {
    const index = this._comments.indexOf(comment);
    this._comments.splice(index, 1);
    this._comments = [...this._comments];
    this.comments$.next(this._comments);
    return index;
  }
}
