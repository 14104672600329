import { Component, Input, ViewEncapsulation, ViewChild, forwardRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, EMPTY } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

import { ReportingSuiteService, ReportingSuite } from '../';

@Component({
  selector: 'crs-reporting-suite-select',
  templateUrl: './reporting-suite-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ReportingSuiteSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReportingSuiteSelectComponent implements ControlValueAccessor, OnInit {

  @Input() clearable = true;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() placeholder: '';

  @ViewChild(NgSelectComponent, { static: true }) private _valueAccessor: NgSelectComponent;

  value: ReportingSuite | ReportingSuite[];
  values: string;

  reportingSuites$: Observable<ReportingSuite[]>;
  private _loaded = false;
  private _loading = false;

  constructor(private readonly _reportingSuiteService: ReportingSuiteService) { }

  writeValue(value: ReportingSuite | ReportingSuite[]): void {
    this.value = value;
    if (value instanceof Array && value) {
      this.values = value.map(v => v.name).join(', ');
    }
    this._valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }

  onOpen() {
    if (!this._loaded && !this._loading) {
      this.load();
    }
  }

  load() {
    this._loading = true;
    this.reportingSuites$ = this._reportingSuiteService.getAll(true).pipe(
      tap(() => this._loaded = true),
      catchError(error => {
        console.log(error);
        return EMPTY; // empty list on error
      }),
      finalize(() => this._loading = false));

  }

  ngOnInit() {
    this._valueAccessor.bindLabel = 'name'; // Binding not working with html tag for some reason
    this._valueAccessor.multiple = this.multiple; // don't do in template as it fails to display existing values in form
  }

}
