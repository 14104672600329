<form
  class="pad-x"
  novalidate
  [formGroup]="form"
  [ngBusy]="busy.load"
  (validSubmit)="submit()"
>
  <div class="d-flex align-items-center gap-4">
    <h2 class="form-group-title d-inline-flex align-items-center gap-2">
      {{ isAdd ? 'New' : '' }} Disclosure Template
      <span class="badge bg-light text-dark me-1" *ngIf="readonly">
        <i aria-hidden="true" class="fas fa-lock"></i>
      </span>
    </h2>

    <div class="d-inline-block" *ngIf="readonly">
      <button
        class="btn btn-sm btn-secondary"
        [promiseBtn]="busy.inherit"
        (click)="inheritFromThis()"
      >
        Customise
      </button>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-md-8 col-xl-6">
      <div
        class="badge rounded-pill bg-secondary mb-4"
        *ngIf="readonly && disclosureTemplate"
      >
        <ng-container
          *ngIf="
            levels[disclosureTemplate.level] === 'Access';
            then handisoft;
            else other
          "
        ></ng-container>
        <ng-template #handisoft> Access Ledger Master </ng-template>
        <ng-template #other>
          {{ levels[disclosureTemplate.level] }} Master
        </ng-template>
      </div>

      <div class="form-group required">
        <label class="control-label">Name</label>
        <input
          class="form-control"
          formControlName="name"
          type="text"
          [readonly]="readonly"
          placeholder="Name of the disclosure template"
        />
      </div>
    </div>
    <div class="col-md-11 col-xl-9">
      <div class="form-group">
        <label class="control-label">Description</label>
        <textarea
          class="form-control"
          formControlName="description"
          rows="4"
          [readonly]="readonly"
          placeholder="Description of the disclosure template"
        ></textarea>
      </div>
      <div class="form-group">
        <label class="control-label">Show by Default</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="autoSelectionMethod"
          [clearable]="false"
          [items]="disclosureAutoSelectionMethods | enumToArray"
          [readonly]="readonly"
        ></ng-select>
        <small
          class="form-text text-muted"
          *ngIf="form.controls['autoSelectionMethod'].value == 0"
        >
          <i aria-hidden="true" class="fas fa-info-circle"></i>
          Nominate the accounts under each variant at the
          <strong>Link to Accounts</strong> label
        </small>
      </div>
      <div
        class="form-group"
        *ngIf="form.controls['autoSelectionMethod'].value == 1"
      >
        <label class="control-label"
          >Default when any of the following Account Types appear</label
        >
        <crs-account-type-select
          formControlName="accountTypes"
          [multiple]="true"
          [readonly]="readonly"
        ></crs-account-type-select>
      </div>
    </div>
  </div>

  <div *ngIf="!isAdd">
    <h2 class="form-group-title">Variants</h2>

    <hr />

    <div *ngIf="disclosureTemplate" class="d-grid gap-2">
      <crs-disclosure-variant
        *ngFor="let v of disclosureTemplate.disclosureVariants; let i = index"
        [disclosureTemplate]="disclosureTemplate"
        [index]="i"
        [level]="level"
        [variant]="v"
        (removed)="disclosureTemplate.disclosureVariants.splice(i, 1)"
      ></crs-disclosure-variant>

      <div class="card card-insert" *ngIf="disclosureTemplate.level >= level">
        <div class="card-body">
          <button
            class="btn btn-primary"
            type="button"
            (click)="addDisclosureVariant()"
          >
            <i aria-hidden="true" class="fas fa-plus inset"></i>
            Add Disclosure Template Variant
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu form-bottom-menu">
    <button
      class="btn btn-primary"
      type="submit"
      *ngIf="!readonly"
      [promiseBtn]="busy.submit"
    >
      Save
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      *ngIf="readonly"
      [promiseBtn]="busy.inherit"
      (click)="inheritFromThis()"
    >
      Customise
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      {{ readonly || !form.dirty ? 'Close' : 'Cancel' }}
    </button>
    <button
      class="btn btn-outline-danger float-right"
      title="Delete"
      type="button"
      *ngIf="!isAdd && isAdmin && !readonly"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      {{
        disclosureTemplate?.masterTemplateId
          ? 'Delete Customised Version'
          : 'Delete'
      }}
    </button>
  </div>
</form>
