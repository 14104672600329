import { Component, OnInit, Input } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AssetAddition } from './asset-addition';

@Component({
  selector: 'crs-asset-addition',
  templateUrl: './asset-addition.component.html',
  styleUrls: ['./asset-addition.component.scss']
})
export class AssetAdditionComponent implements OnInit {

  static modalSize = 'lg';

  @Input() params: {
    isAdd: boolean,
    addition: AssetAddition
  };

  isAdd: boolean;
  objectTitle = 'Asset Addition';

  error: string = null;

  form = this._formBuilder.group({
    description: ['', [Validators.required, Validators.maxLength(2048)]],
    taxation: this._formBuilder.group({
      acquisitionDate: [null, Validators.required],
      cost: []
    }),
    accounting: this._formBuilder.group({
      acquisitionDate: [null, Validators.required],
      cost: []
    })
  });

  toggles: any = {};
  component = this;

  taxation = this.form.get('taxation') as UntypedFormGroup;
  accounting = this.form.get('accounting') as UntypedFormGroup;

  constructor(private  readonly _activeModal: NgbActiveModal,
    private readonly _formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    if (!this.isAdd) {
      this.form.patchValue(this.params.addition);
    }
  }

  submit() {
    const addition = new AssetAddition(this.form.value);
    this._activeModal.close(addition);
  }

  showError(error) {
    this.error = error;
  }

  dismiss() {
    this._activeModal.dismiss();
  }

}
