import { LedgerSide, ledgerSides } from './../../../../../ledger/accounts/ledgerSide';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { HeaderStyle } from './../../report-header/report-header';
import { FinancialElementType } from './../financial-element-type';

@Component({
  selector: 'crs-financial-element-editor',
  templateUrl: './financial-element-editor.component.html'
})
export class FinancialElementEditorComponent implements OnInit {

  form: UntypedFormGroup;
  type: FinancialElementType;

  @Input() params: {
    element: any,
    output: boolean
  };

  busy = {
    load: null
  };

  styles = HeaderStyle;
  ledgerSides = ledgerSides;
  types = FinancialElementType;

  error: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.type = this.params.element.financialType;
    this.form = this.getForm(this.params.element.financialType);
    this.form.patchValue(this.params.element);
  }

  submit() {
    const value = this.form.value;
    if (this.type === FinancialElementType.Row || this.type === FinancialElementType.Total) {
      value.title = value.totalDefinition.name;
      const totalDefinition = this.params.element.totalDefinition;
      Object.assign(totalDefinition, value.totalDefinition);
      Object.assign(this.params.element, value);
      this.params.element.totalDefinition = totalDefinition;
    } else {
      Object.assign(this.params.element, value);
    }
    this._activeModal.close();
  }

  cancel() {
    this._activeModal.dismiss();
  }

  private getForm(financialType: FinancialElementType) {
    switch (financialType) {
      case FinancialElementType.Header:
        return this._formBuilder.group({
          level: [1, Validators.maxLength(256)],
          title: [null, [Validators.required, Validators.maxLength(256)]],
          style: [HeaderStyle.Normal]
        });
      case FinancialElementType.Row:
        return this._formBuilder.group({
          level: [1, Validators.required],
          title: [null, [Validators.required, Validators.maxLength(256)]],
          totalDefinition: this.getTotalDefinition()
        });
      case FinancialElementType.Total:
        return this._formBuilder.group({
          level: [1, Validators.required],
          title: [null, [Validators.required, Validators.maxLength(256)]],
          totalDefinition: this.getTotalDefinition()
        });
      case FinancialElementType.Spacer:
        return this._formBuilder.group({
          height: [10, Validators.required]
        });
    }
  }

  private getTotalDefinition() {
    return this._formBuilder.group({
      name: [null, [Validators.required, Validators.maxLength(256)]],
      alternateNameMixed: [null, Validators.maxLength(256)],
      alternateNameNegative: [null, Validators.maxLength(256)],
      ledgerSide: [LedgerSide.Credit],
      isPercent: [false]
    });
  }


}
