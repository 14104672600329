import { PolicyVariant } from './policyVariant';
import { PolicyLevel } from './policyLevel';
import { AccountType } from '../../../ledger/account-types/accountType';

export enum PolicyLocation {
  General = 0,
  'Note 1' = 1
}

export enum PolicyAutoSelectionMethod {
  IfRelatedAccountTypeAppears = 0,
  Always = 1,
  Never = 2
}

export class Policy {
  id: string;
  fileId: string;
  reportTemplateId: string;
  name: string;
  location: PolicyLocation;
  description: string;
  autoSelectionMethod: PolicyAutoSelectionMethod;
  isMaster: boolean;
  level: PolicyLevel;
  masterPolicyId: string;
  policyVariants: PolicyVariant[];
  accountTypes: AccountType[];

  constructor(data) {
    Object.assign(this, data);
    if (data.policyVariants) this.policyVariants = data.policyVariants.map(v => new PolicyVariant(v));
    if (data.accountTypes) this.accountTypes = data.accountTypes.map(t => new AccountType(t));
  }
}

