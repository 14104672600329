<div class="modal-header">
  <h4 class="modal-title">Transfer account to a different feed collection</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="onClickClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="onValidSubmit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div>
      <label class="mb-3">
        <strong>Account name:</strong> {{ this.params.accountName }}
      </label>
    </div>
    <hr />

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Select destination feed collection</label>
          <ng-select
            aria-describedby="searchIcon"
            bindLabel="name"
            formControlName="feed"
            placeholder="Search feed collections / entity..."
            [clearable]="false"
            [items]="feeds"
          />
        </div>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="form.invalid"
      [promiseBtn]="busy.submit"
    >
      Transfer account
    </button>
    <button class="btn btn-secondary" type="button" (click)="onClickClose()">
      Cancel
    </button>
  </div>
</form>
