import { Component, OnInit, Input } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { FirmService } from '../../firms/firm.service';
import { FirmUser } from '../firmUser';
import { MessageService } from 'src/app/core';
import { Patterns } from '../../../shared/validators';

@Component({
  selector: 'crs-owner-create-modal',
  templateUrl: './owner-create-modal.component.html',
  styleUrls: ['./owner-create-modal.component.scss']
})
export class OwnerCreateModalComponent implements OnInit {

  @Input() id: string;

  busy = {
    submit: null
  };

  form = this._formBuilder.group({
    email: [null, [Validators.required, Validators.maxLength(512), Validators.pattern(Patterns.emailRegExp)]],
    firstName: [null, [Validators.required, Validators.maxLength(512)]],
    lastName: [null, [Validators.required, Validators.maxLength(512)]],
  });

  error: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private _firmService: FirmService,
    private _messageService: MessageService) { }

  ngOnInit() {
  }

  submit() {
    const model = this.form.value as FirmUser;
    this.busy.submit = this._firmService.addOwner(parseInt(this.id), model)
      .subscribe(s => this.activeModal.close(), e => this.showError(e));
  }

  showError(err) {
    this._messageService.error(err);
  }

}
