import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../../core';
import { DisclosureVariant, DisclosureVariantModel } from './disclosure-variant';

@Injectable({
  providedIn: 'root'
})
export class DisclosureVariantService {

  readonly base = 'api/accounting/reports/notes/disclosures/variants';

  constructor(private readonly _apiService: ApiService) { }

  get(id: string) {
    return this._apiService.get<DisclosureVariant>(this.base + '/' + id).pipe(map(c => new DisclosureVariant(c)));
  }

  post(disclosureVariant: DisclosureVariantModel): Observable<string> {
    return this._apiService.post<string>(this.base, disclosureVariant);
  }

  createInherited(variant: DisclosureVariantModel): Observable<string> {
    return this._apiService.post<string>(this.base + '/inherit', variant);
  }

  reorderFileDisclosureVariant(disclosureVariantId: string, behindDisclosureVariantId: string) {
    return this._apiService.put(this.base + '/' + disclosureVariantId + '/reorder/file', behindDisclosureVariantId);
  }

  confirmMasterChangesChecked(disclosureVariantId: string) {
    return this._apiService.put(this.base + '/' + disclosureVariantId + '/confirmMasterChangesChecked', null);
  }

  put(disclosureVariant: DisclosureVariantModel) {
    return this._apiService.put(this.base + '/' + disclosureVariant.id, disclosureVariant);
  }

  delete(id: string) {
    return this._apiService.delete(this.base + '/' + id);
  }

}
