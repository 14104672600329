import { Component, Input } from '@angular/core';
import { FinancialContentInfo } from '../../report-financial/report-content-info';

@Component({
  selector: '[crs-report-financial-spacer]',
  templateUrl: './report-financial-spacer.component.html'
})
export class ReportFinancialSpacerComponent {

  @Input() element;
  @Input() index: number;
  @Input() isEditing = false;
  @Input() financialContent: FinancialContentInfo;

}
