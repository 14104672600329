import { DepreciationPool } from '../assets/depreciation-pool';
import { DepreciationMethod } from '../assets/depreciation-method';
import { getDepreciationMethodAbbrev } from '../assets/get-depreciation-method-abbrev';

export class AssetReportDepreciationRecord {
  depreciationMethod: DepreciationMethod;
  depreciationPool: DepreciationPool;
  depreciationMethodDescription: string;

  constructor(data: any) {
    Object.assign(this, data);
    this.depreciationMethodDescription = getDepreciationMethodAbbrev(this.depreciationMethod, this.depreciationPool);
  }

}
