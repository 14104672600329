import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, Subject, of } from 'rxjs';
import { tap, finalize, switchMap, catchError } from 'rxjs/operators';

import { ActiveFileService } from '../../active-file.service';
import { Dataset, DatasetService } from '../';
import { Entity } from '../../../firm';
import { EntityComponent } from '../../../firm/entities/entity/entity.component';
import { ModalService, MessageService } from '../../../core';
import { getDefaultGridOptions } from '../../../shared';
import { EntitySelectModalComponent } from 'src/app/firm/entities/entity-select-modal/entity-select-modal.component';
import { FileService } from '../..';

@Component({
  selector: 'crs-datasets',
  templateUrl: './datasets.component.html',
  styleUrls: ['./datasets.component.scss'],
})
export class DatasetsComponent implements OnInit, OnDestroy {
  busy = {
    datasets: false,
    entities: null,
  };
  fileSubscription: Subscription;
  fileId: string;
  isConsolidated: boolean;
  entityId: string;
  entities: Entity[];
  datasetsObservable: Observable<any>;
  error: string;

  gridOptions = getDefaultGridOptions();

  constructor(
    private datasetService: DatasetService,
    private activeFileService: ActiveFileService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.entityId = this.route.snapshot.paramMap.get('entityId');
    });

    this.fileSubscription = this.activeFileService.stream.subscribe((file) => {
      this.fileId = file.id;
      this.entities = file.entities;
      this.isConsolidated = file.isConsolidated;
      this.getEntitiesAndDatasets();
    });
  }

  ngOnDestroy() {
    this.fileSubscription.unsubscribe();
  }

  getEntitiesAndDatasets(id: string = null) {
    if (this.isConsolidated) {
      if (this.entityId == null)
        this.entityId =
          this.entities && this.entities.length ? this.entities[0].id : null;
      this.getDatasets();
    } else this.getDatasets();
  }

  getDatasets() {
    this.busy.datasets = true;
    let observable: Observable<Dataset[]>;
    if (this.isConsolidated) {
      observable = this.datasetService.getAll$(this.fileId, this.entityId);
    } else {
      observable = this.datasetService.getAll$(this.fileId);
    }
    this.datasetsObservable = observable.pipe(
      catchError((err) => {
        this.showError(err);
        return of([]);
      }),
      finalize(() => (this.busy.datasets = false))
    );
  }

  addDataset(isGroupDataset: boolean = false) {
    if (this.isConsolidated) {
      this.router.navigate(
        ['./add', { entityId: this.entityId, isGroupDataset: isGroupDataset }],
        { relativeTo: this.route }
      );
    } else {
      this.router.navigate(['./add', { isGroupDataset: isGroupDataset }], {
        relativeTo: this.route,
      });
    }
  }

  selectDataset(param) {
    this.router.navigate(['./', param.data.id], { relativeTo: this.route });
  }

  addEntity() {
    this.modalService
      .openModal(EntitySelectModalComponent, null, {
        clientId: this.activeFileService.file.clientId,
      })
      .then((entity) => {
        this.busy.entities = true;
        this.fileService
          .addEntity$(this.fileId, entity.id)
          .pipe(
            switchMap(() => this.fileService.get$(this.fileId)),
            finalize(() => (this.busy.entities = false))
          )
          .subscribe((file) => this.activeFileService.updatefile(file, true));
      })
      .catch(() => true);
  }

  removeEntity(entity: Entity) {
    this.busy.entities = true;
    this.fileService
      .removeEntity$(this.fileId, entity.id)
      .pipe(
        tap(() => {
          if ((this.entityId = entity.id)) this.entityId = null;
        }),
        switchMap(() => this.fileService.get$(this.fileId)),
        finalize(() => (this.busy.entities = false))
      )
      .subscribe((file) => this.activeFileService.updatefile(file, true));
  }

  editEntity(id: string) {
    this.modalService
      .openModal(EntityComponent, id)
      .then(() => this.getEntitiesAndDatasets());
  }

  activateEntity(entityId) {
    this.router.navigate(['./'], {
      queryParams: { entityId: entityId },
      relativeTo: this.route,
    });
    if (this.entityId !== entityId) {
      this.entityId = entityId;
      this.getDatasets();
    }
  }

  showError(err) {
    this.error = err;
  }
}
