import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { JournalType } from '../../ledger';
import { MessageService } from '../../../core';
import { getDefaultGridOptions, SentencePipe } from '../../../shared';
import { JournalCategory } from '../../ledger/journals/journalCategory';
import { JournalService } from '../../ledger/journals/journal.service';
import { AutoJournalService } from './../../ledger/auto-journals/auto-journal.service';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-dataset-journals',
  templateUrl: './dataset-journals.component.html',
  styleUrls: ['./dataset-journals.component.scss'],
  providers: [SentencePipe],
})
export class DatasetJournalsComponent implements OnInit {
  datasetId: string;
  collapsed: boolean;

  busy = {
    journals: false,
    autoJournals: null,
  };
  journalsObservable: Observable<any>;

  gridOptionsMain: GridOptions;
  gridOptionsSmall: GridOptions;
  journalTypes = JournalType;
  journalCategories = JournalCategory;
  grouped = true;

  constructor(
    private journalService: JournalService,
    private readonly autoJournalService: AutoJournalService,
    public route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.configureGridOptions();

    this.route.params.subscribe((params) => {
      this.datasetId = this.route.snapshot.parent.paramMap.get('id');
      this.getJournals();
    });
  }

  configureGridOptions() {
    this.gridOptionsMain = {
      ...getDefaultGridOptions(),
      onRowClicked: (event) => {
        this.selectJournal(event);
      },
      rowClass: 'selectable-row',
      suppressRowClickSelection: true,
      suppressCellFocus: true,
      columnDefs: [
        {
          field: 'date',
          headerName: 'Date',
          type: 'dateColumn',
        },
        {
          field: 'journalNo',
          headerName: 'Journal No',
          maxWidth: 180,
          minWidth: 120,
        },
        {
          field: 'journalTypeToolTipText',
          headerName: 'Journal Type',
          maxWidth: 250,
        },
        {
          field: 'memo',
          headerName: 'Memo',
          minWidth: 120,
        },
        {
          field: 'balance',
          headerName: 'Balance',
          type: 'dollarColumn',
        },
      ],
    };

    this.gridOptionsSmall = {
      ...getDefaultGridOptions(),
      onRowClicked: (event) => {
        this.selectJournal(event);
      },
      suppressCellFocus: true,
      rowClass: 'selectable-row',
      columnDefs: [
        {
          field: 'date',
          headerName: 'Date',
          type: 'dateColumn',
        },
        {
          field: 'journalNo',
          headerName: 'Journal No',
          maxWidth: 180,
          minWidth: 120,
        },
      ],
    };
  }

  getJournals() {
    this.busy.journals = true;
    this.journalsObservable = this.journalService
      .getForDataset$(this.datasetId)
      .pipe(tap(() => (this.busy.journals = false)));
  }

  addJournal() {
    this.router.navigate(['./add'], { relativeTo: this.route });
  }

  selectJournal(param) {
    if (!param.data.id) return;
    this.router.navigate(['./', param.data.id], { relativeTo: this.route });
  }

  processAutoJournals() {
    this.busy.autoJournals = this.autoJournalService
      .processAll$(this.datasetId)
      .pipe(
        tap((r) => {
          if (r) this.messageService.success(r);
        }),
        finalize(() => {
          this.getJournals();
        })
      )
      .subscribe(
        () => true,
        (err) => this.showError(err)
      );
  }

  showError(err) {
    this.messageService.error(err);
  }
}
