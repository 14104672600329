<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<crs-busy *ngIf="busy.load || (isFetchingIsLivestockAvailable$ | async)" />

<form [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-10 col-lg-8">
        <div class="form-group required">
          <label class="control-label">Code</label>
          <input class="form-control" formControlName="code" type="text" />
        </div>

        <div class="form-group required">
          <label class="control-label">Name</label>
          <input class="form-control" formControlName="name" type="text" />
        </div>

        <div class="form-group">
          <label class="control-label">Profit and Loss Location</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="location"
            [items]="tradingAccountLocations | enumToArray"
          />
        </div>

        <div style="width: 175px">
          <div class="py-2">
            <crs-check-box formControlName="isPrimaryProduction">
              Primary Production
            </crs-check-box>
          </div>

          <div class="pb-2" *ngIf="isLivestockAvailable">
            <crs-check-box formControlName="isLivestockEnabled">
              Livestock
            </crs-check-box>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="form.get('isLivestockEnabled').getRawValue()">
      <hr />
      <h5>Livestock account allocation</h5>
      <hr />
      <div class="small-table">
        <div class="small-table-item-header">
          <div><strong>Livestock category</strong></div>
          <div [style.maxWidth]="'250px'">
            <strong>Allocated account</strong>
          </div>
        </div>

        <div formArrayName="livestockAllocations">
          <div
            class="small-table-item"
            *ngFor="
              let allocation of form.get('livestockAllocations').controls;
              let i = index
            "
            [formGroupName]="i"
          >
            <div class="small-table-item-text">
              {{ livestockCategories[i] }}
            </div>

            <div class="chart-code-select" [style.maxWidth]="'250px'">
              <ng-select
                formControlName="account"
                placeholder="Select account"
                [clearable]="true"
                [items]="accounts"
                [loading]="isFetchingAccounts$ | async"
                [searchFn]="searchAccountFn"
                (change)="checkForDuplicateAccounts()"
              >
                <ng-template let-item="item" ng-label-tmp>
                  {{ item.accountNo }}
                  <span *ngIf="item.accountNo && item.accountName"> - </span>
                  {{ item.accountName }}
                </ng-template>

                <ng-template let-item="item" ng-option-tmp>
                  <span class="label">
                    {{ item.accountNo }}
                    <span *ngIf="item.accountNo && item.accountName"> - </span>
                    {{ item.accountName }}
                  </span>
                </ng-template>
              </ng-select>
              <i class="fa fa-chevron-down"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <crs-form-error [error]="error" />
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-delete"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      [promiseBtn]="busy.delete"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!!this.error"
      [promiseBtn]="busy.submit"
    >
      {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>
</form>
