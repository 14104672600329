import { getDefaultGridOptions } from 'src/app/shared';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

import { ImageService } from '../image.service';
import { Image } from '../image.class';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-images',
  templateUrl: './images.component.html',
})
export class ImagesComponent implements OnInit {
  error: string;

  busy = {
    loading: false,
  };

  images$: Observable<Image[]>;
  gridOptions: GridOptions;

  constructor(
    private readonly _imageService: ImageService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      columnDefs: [{ field: 'name', headerName: 'Name' }],
    };

    this.refresh();
  }

  private refresh() {
    this.busy.loading = true;

    this.images$ = this._imageService.getAll().pipe(
      catchError((err) => {
        this.showError(err);
        return of([]);
      }),
      finalize(() => (this.busy.loading = false))
    );
  }

  addImage() {
    this._router.navigate(['./add'], { relativeTo: this._route });
  }

  selectImage(image: Image) {
    this._router.navigate(['./' + image.id], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
  }
}
