<ng-select [items]="usersObservable | async"
           bindLabel="displayName"
           [loading]="loading"
           [typeahead]="userInput"
           [virtualScroll]="true"
           [clearable]="clearable"
           [class.d-none]="readonly && user"
           [readonly]="readonly">
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <img [src]="item.icon.source" class="user-icon-img align-middle" [alt]="item.displayName" />
    <span [ngOptionHighlight]="search">{{item.displayName}}</span>
  </ng-template>
  <ng-template ng-label-tmp let-item="item" let-search="searchTerm">
    <img [src]="item.icon.source" class="user-icon-img align-middle" [alt]="item.displayName"/>
    <span [ngOptionHighlight]="search">{{item.displayName}}</span>
  </ng-template>
</ng-select>

<div class="form-control-plaintext" *ngIf="readonly && user">
  <crs-user-icon [user]="user"></crs-user-icon>
</div>

