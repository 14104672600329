import { RowNode } from 'ag-grid-community';
import { BacoTransactionDto } from 'src/app/baco/interfaces';
import { FilterActionEnum } from './filter-action.enum';

export class TransactionExternalFilter {

  private actions: FilterActionEnum[] = [];

  public add(action: FilterActionEnum): void {
    if (!this.actions.includes(action)) {
      this.actions.push(action);
    }
  }

  public remove(action: FilterActionEnum): void {
    this.actions = this.actions.filter(x => x !== action);
  }

  public isExternalFilterPresent(): boolean {
    const haveToApplyExternalFilter = !!this.actions.length;
    return haveToApplyExternalFilter;
  }

  public doesExternalFilterPass(node: RowNode): boolean {
    let flag = true;
    if (node.data) {
      const transaction = node.data as BacoTransactionDto;
      this.actions.forEach((action) => {
        switch (action) {
          case FilterActionEnum.HideCodedTransaction:
            flag = flag && transaction.codedBy === null;
            break;
          case FilterActionEnum.HideLockedTransaction:
            flag = flag && !transaction.locked;
            break;
        }
      });
    }
    return flag;
  }
}
