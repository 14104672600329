import { TotalDefinition } from './../../../../layout/totalDefinition';
import { FinancialFormulaEditorComponent } from './../financial-formula-editor/financial-formula-editor.component';
import { ModalService } from './../../../../../../core/modals/modal.service';
import { FinancialContentInfo } from './../../report-financial/report-content-info';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'crs-financial-formula',
  templateUrl: './financial-formula.component.html'
})
export class FinancialFormulaComponent {

  constructor(private readonly _modalService: ModalService) { }

  @Input() totalDefinition: TotalDefinition;
  @Input() financialContent: FinancialContentInfo;

  get formula() {
    return this.totalDefinition.formula;
  }

  editFormula() {

      this._modalService.openModal(FinancialFormulaEditorComponent, null, { formula: this.totalDefinition.formula }).then(r => {
        this.totalDefinition.formula = r;
      }).catch(() => true);
  }

}
