import { BacoCodedBy, RuleMovementType, TransactionImportType } from '../enums';
import { BacoAccountDto } from './baco-account.interface';
import { BacoTaxOptionDto } from './tax-option.interface';

export interface BacoTransactionDto {
  id: string;
  transactionDate: string;
  transactionLocalDate: string;
  reference: string | null;
  type: string | null;
  description: string | null;
  originalDescription: string | null;
  moneyIn?: number;
  moneyOut?: number;
  amount: number;
  movementType: RuleMovementType;
  quantity: number | null;
  locked: boolean;
  codedBy: BacoCodedBy | null;
  accountSelections: BacoAccountSelectionDto[];
  messages: BacoCommentDto[];
  tax: number;
  transactionImportType: TransactionImportType;
}

export interface BacoCommentDto {
  id: number;
  userName: string;
  message: string;
}

export interface BacoAccountSelectionDto {
  account: BacoAccountDto | null;
  totalAmount: number;
  visibleAmount: number;
  description: string | null;
  quantity: number | null;
  tax: number | null;
  taxSelection: BacoTaxOptionDto | null;
}

export interface BacoTransactionValidationResultDto {
  validationStatus: BacoTransactionValidationValidationStatus;
  validationMessages: string[];
}
export enum BacoTransactionValidationValidationStatus {
  Ok,
  Error,
  Warning,
}
export type BacoLoadableTransactionDto = BacoTransactionDto & {
  loading: boolean;
};

export interface BacoTransactionCountDto {
  codedTransactionsCount: number;
  uncodedTransactionsCount: number;
  totalTransactionsCount: number;
}

export function getTransactionAccountSelectionsIdentifier(
  transaction: BacoTransactionDto
) {
  if (
    !transaction.accountSelections ||
    transaction.accountSelections.length === 0
  ) {
    return '';
  }

  if (transaction.accountSelections.length === 1) {
    return transaction.accountSelections[0].account?.accountId;
  }

  return getMultipleAccountSelectionsIdentifier(transaction);
}

export function getTransactionTaxSelectionsIdentifier(
  transaction: BacoTransactionDto
) {
  if (
    !transaction.accountSelections ||
    transaction.accountSelections.length === 0
  ) {
    return '';
  }

  if (transaction.accountSelections.length === 1) {
    return transaction.accountSelections[0].taxSelection?.taxId;
  }

  return getMultipleAccountSelectionsIdentifier(transaction);
}

function getMultipleAccountSelectionsIdentifier(
  transaction: BacoTransactionDto
) {
  const id = transaction.accountSelections
    .map((x) => x.account?.accountId + '_' + x.taxSelection?.taxId)
    .join();
  return id;
}
