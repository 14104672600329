import { DateHelpers } from './../../../../../../shared/utilities/date-helpers';
import { ReportOutputService } from './../../../report-output.service';
import { ModalService } from './../../../../../../core/modals/modal.service';
import { LedgerReportModalComponent, LedgerReportModalParams } from './../../../../../ledger/ledger-report-modal/ledger-report-modal.component';
import { FinancialContentInfo } from './../../report-financial/report-content-info';
import { Component, Input } from '@angular/core';
import { LedgerReportFilters } from 'src/app/accounting/ledger/ledger-report/ledger-report.component';
import { GroupByAccountType } from 'src/app/accounting/ledger/ledgerReports/groupByAccountType';
import { ActiveFileService } from 'src/app/accounting';

@Component({
  selector: '[crs-report-financial-row]',
  templateUrl: './report-financial-row.component.html'
})
export class ReportFinancialRowComponent {

  @Input() element;
  @Input() index: number;
  @Input() isEditing = false;
  @Input() financialContent: FinancialContentInfo;
  get columnMetadata() {
    return this.financialContent ? this.financialContent.columns : null;
  }

  get rounding() {
    return this.financialContent.reportContent.style.rounding;
  }

  get percentFormatString() {
    const rounding = this.financialContent.reportContent.style.percentRounding;
    return '1.' + rounding + '-' + rounding;
  }

  constructor(private readonly _modalService: ModalService,
    private readonly _activeFileService: ActiveFileService,
    private readonly _reportOutputService: ReportOutputService) {}

  hasDrillDown(column: number) {
    const metadata = this.columnMetadata[column];
    return metadata && !!metadata.datasetId && (this.element.elementIdType === 3 || this.element.elementIdType === 4);
  }

  drillDown(column: number) {
    const metadata = this.columnMetadata[column];
    const filters = new LedgerReportFilters();
    filters.groupByAccountType = GroupByAccountType.Account;
    filters.startDate = DateHelpers.dateFromApiResponse(metadata.startDate);
    filters.endDate = DateHelpers.dateFromApiResponse(metadata.endDate);
    const params = new LedgerReportModalParams(metadata.datasetId, filters);

    this._reportOutputService.getIncludedAccounts(this._activeFileService.file.id, this.element.elementId, this.element.elementIdType)
      .subscribe(a => {
        params.filters.accounts = a;
        this._modalService.openModal(LedgerReportModalComponent, null, params);
      });
  }

}
