import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
  forwardRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject, of, EMPTY, concat, merge } from 'rxjs';
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  map,
  catchError,
  switchMap,
  startWith,
  exhaustMap,
  finalize,
} from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

import { PagedResponse } from 'src/app/core';
import { AccountService, ActiveFileService } from 'src/app/accounting';
import { AccountUltraLight } from 'src/app/accounting/ledger/accounts/account-ultra-light';

@Component({
  selector: 'crs-account-select',
  templateUrl: './account-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccountSelectComponent implements OnInit, ControlValueAccessor {
  @Input() includeHeaders = true;
  @Input() includeChildren = true;
  @Input() multiple = false;
  @Input() placeholder: string = null;
  @Input() clearable = true;

  @ViewChild(NgSelectComponent, { static: true })
  private valueAccessor: ControlValueAccessor;

  scroll$ = new Subject<void>();
  accounts$: Observable<AccountUltraLight[]>;
  accounts: AccountUltraLight[];
  loading = false;
  accountInput = new Subject<string>();

  searchTerm: string;
  pagedResponse: PagedResponse<any>;

  constructor(
    private accountService: AccountService,
    private activeFileService: ActiveFileService
  ) {}

  private searchAccounts(
    search,
    page: number = null
  ): Observable<PagedResponse<AccountUltraLight[]>> {
    if (!page) page = 1;
    return this.accountService.searchAccounts$({
      fileId: this.activeFileService.file.id,
      search,
      includeHeaders: this.includeHeaders,
      includeChildren: this.includeChildren,
      page,
      pageSize: 250,
    });
  }

  getNext() {
    if (
      this.pagedResponse &&
      this.pagedResponse.page < this.pagedResponse.pages
    ) {
      this.scroll$.next();
    }
  }

  writeValue(value: any | any[]): void {
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  ngOnInit() {
    (this.valueAccessor as NgSelectComponent).bindLabel = 'displayName';
    (this.valueAccessor as NgSelectComponent).multiple = this.multiple;

    this.accounts$ = concat(
      of([] as AccountUltraLight[]),
      merge(
        this.accountInput.pipe(
          startWith(''),
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => (this.loading = true)),
          tap((term) => (this.searchTerm = term)),
          switchMap((term) =>
            this.searchAccounts(term, 1).pipe(
              tap((r) => (this.pagedResponse = r)),
              map((r) => r.records.map((a) => new AccountUltraLight(a))),
              tap((r) => (this.accounts = r))
            )
          ),
          catchError((err) => {
            console.error('Error searching for accounts', err);
            return of([] as AccountUltraLight[]);
          }),
          tap(() => (this.loading = false))
        ),
        this.scroll$.pipe(
          tap(() => (this.loading = true)),
          exhaustMap((term) =>
            this.searchAccounts(
              this.searchTerm,
              this.pagedResponse.page + 1
            ).pipe(
              tap((response) => (this.pagedResponse = response)),
              map((response) =>
                response.records.map(
                  (account) => new AccountUltraLight(account)
                )
              ),
              map((accounts) => this.accounts.concat(accounts)),
              tap((accounts) => (this.accounts = accounts))
            )
          ),
          catchError((err) => {
            console.error('Error retrieving next page', err);
            return of([] as AccountUltraLight[]);
          }),
          tap(() => (this.loading = false))
        )
      )
    );
  }
}
