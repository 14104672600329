import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AccountingService {

  public currentFileId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public currentEntityId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }
}
