import {
  takeUntil,
  catchError,
  map,
  switchMap,
  tap,
  finalize,
  debounceTime,
  distinctUntilChanged,
  startWith,
} from 'rxjs/operators';
import { Subject, EMPTY, Observable, of, combineLatest } from 'rxjs';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  EnhancedConfirmation,
  MessageService,
  ModalService,
} from 'src/app/core';
import { BankConnectionSettingsComponent } from '../bank-connection-settings';
import { BankAccountState, BankConnectionState } from 'src/app/baco/enums';
import {
  BacoAccountDto,
  BacoAccountTransferDto,
  BacoBankAccountDto,
  BacoBankConnectionDto,
  Feed,
} from 'src/app/baco/interfaces';
import { BankAccountClient, FeedClient } from 'src/app/baco/common';
import { BacoFeedStore } from '../../baco-feed.store';
import { BankConnectionSettingsService } from 'src/app/baco/services';
import { TransactionProvider } from '../bank-connection.enum';
import { BacoTransactionStore } from '../..';
import { Patterns } from 'src/app/shared/validators';
import { BankTransferComponent } from '../bank-transfer';

@Component({
  selector: 'crs-bank-connection-table',
  templateUrl: './bank-connection-table.component.html',
  styleUrls: ['./bank-connection-table.component.scss'],
})
export class BankConnectionTableComponent implements OnInit, OnDestroy {
  @Input() search = new UntypedFormControl();

  busy = {
    accounts: false,
    feeds: null,
  };

  error: string = null;
  bankConnections: BacoBankConnectionDto[];
  bankConnectionStates = BankConnectionState;
  bankAccountStates = BankAccountState;
  transactionProvider = TransactionProvider;

  private _destroy: Subject<boolean> = new Subject<boolean>();

  hasUnSubscribedWebHooks: boolean = false;
  fileId: string;
  bacoAccounts: BacoAccountDto[];

  private searchAccountsSubject = new Subject<string>();
  filteredAccounts$: Observable<BacoAccountDto[]>;

  constructor(
    private readonly _feedStore: BacoFeedStore,
    private readonly _bankConnectionSettingsService: BankConnectionSettingsService,
    private readonly _feedClient: FeedClient,
    private readonly _transactionStore: BacoTransactionStore,
    private readonly _bankAccountClient: BankAccountClient,
    private _modalService: ModalService,
    private _messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.fileId = this._feedStore.feed$.getValue().data?.ledgerSourceId;
    this.fetchAccounts();
    this.getbankConnections();

    this.filteredAccounts$ = combineLatest([
      this._feedStore.accounts$, // Assuming this is your source of accounts
      this.searchAccountsSubject
        .asObservable()
        .pipe(startWith(''), debounceTime(300), distinctUntilChanged()),
    ]).pipe(
      map(([accounts, searchTerm]) => {
        return this.filterAndSortAccounts(accounts.data || [], searchTerm);
      })
    );
  }

  private getbankConnections() {
    return this._feedStore.bankConnections$
      .pipe(
        takeUntil(this._destroy),
        map((x) => x.data),
        switchMap((bankConnections) => {
          if (bankConnections && bankConnections.length > 0) {
            const feedId = this._feedStore.feed$.getValue().data.id;
            return this._feedClient.getBankAccounts(feedId).pipe(
              map((bankAccounts) =>
                bankConnections.map((connection) => ({
                  ...connection,
                  bankAccounts: bankAccounts.filter(
                    (account) => account.bacoBankConnectionId === connection.id
                  ),
                }))
              )
            );
          }

          return of(bankConnections);
        })
      )
      .subscribe((data) => {
        this.bankConnections = data?.filter(
          (bankConnectionDto) =>
            bankConnectionDto.state !== BankConnectionState.Active ||
            bankConnectionDto.bankAccounts?.length
        );
        this.hasUnSubscribedWebHooks =
          !!this.bankConnections &&
          this.bankConnections.some((x) => !x.webhooksState);
      });
  }

  public updateBankAccountChartCodes(event, bankAccount) {
    const bankAccountId = bankAccount.id;
    const feedId = this._feedStore.feed$.getValue().data.id;
    const ledgerAccountCode = event.accountNo;
    const ledgerAccountName = event.accountName;

    // Use Patterns.isValidGuid to validate if event.sourceId is a valid GUID since XPA bankfeeds assigns the accountCode as the sourceId.
    const ledgerSourceAccountId = Patterns.isValidGuid.test(event.sourceId)
      ? event.sourceId
      : null;

    this._feedClient
      .updateBankAccountDetail(feedId, {
        bankAccountId,
        ledgerSourceAccountId,
        ledgerAccountCode,
        ledgerAccountName,
      })
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this._messageService.success('Chart code updated');
        this._transactionStore.refreshBankAccounts();
      });
  }

  public updateBankAccountIsHidden(event, bankAccount) {
    const feedId = this._feedStore.feed$.getValue().data.id;
    const { id } = bankAccount;
    const isHidden = event.target.checked;

    this._feedClient
      .updateBankAccountIsHidden(feedId, {
        bankAccountId: id,
        isHidden,
      })
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this._messageService.success('Bank account hidden status updated');
        this._transactionStore.refreshBankAccounts();
      });
  }

  public ngOnDestroy() {
    this._destroy.next(true);
    this._destroy.complete();
  }

  public openBankConnectionSettingsModal(bank: BacoBankConnectionDto): void {
    this._modalService
      .openModal(BankConnectionSettingsComponent, bank.id, bank, {
        windowClass: 'bank-connection-settings',
      })
      .then((result: any) => {
        if (result.reload) {
          this._feedStore.refreshBankConnections();
        }
      })
      .finally(() => {
        this._transactionStore.refreshTransactions();
      })
      .catch(() => true);
  }

  subscribeAllMissingAkahuWebhooks() {
    //TODO: Add loading for UI button
    const feedId = this._feedStore.feed$.getValue().data.id;

    return this._bankConnectionSettingsService
      .subscribeAllAkahuWebhooks(feedId)
      .pipe(takeUntil(this._destroy))
      .subscribe((data) => {
        this.bankConnections = data;
        this.hasUnSubscribedWebHooks =
          !!this.bankConnections &&
          this.bankConnections.some((x) => !x.webhooksState);
        this._messageService.success(
          'All Akahu Webhooks has been subscribed successfully for the current Feed.'
        );
      });
  }

  // Used to determine which field to bind to the chart dropdown as legacy dropdowns may not have ledgerSourceAccountId
  getBindValueAndModel(bankAccount: any): { bindValue: string; model: any } {
    if (
      !bankAccount.ledgerSourceAccountId &&
      (bankAccount.ledgerAccountCode || bankAccount.ledgerAccountName)
    ) {
      return { bindValue: 'accountNo', model: bankAccount.ledgerAccountCode };
    }

    return { bindValue: 'sourceId', model: bankAccount.ledgerSourceAccountId };
  }

  private fetchAccounts(): void {
    this.busy.accounts = true;

    this.getAccounts$()
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap((accounts: BacoAccountDto[]) => {
          this.bacoAccounts = accounts.map((account) => ({
            ...account,
            combinedLabel: this.getCombinedLabel(account),
          }));
        }),
        finalize(() => {
          this.busy.accounts = false;
        })
      )
      .subscribe();
  }

  private showError(error): void {
    this.error = error;
    this._messageService.error(error, true);
  }

  private getAccounts$(): Observable<BacoAccountDto[]> {
    const feedId = this._feedStore.feed$.getValue().data.id;
    return this._feedClient.getAccounts(feedId).pipe(
      map((accounts) =>
        accounts.sort((a, b) => {
          if (a && b) {
            if (!a.accountNo || !b.accountNo) {
              return 0;
            }
            const [accountA] = a.accountNo?.split('/');
            const [accountB] = b.accountNo?.split('/');

            return Number(accountA) - Number(accountB);
          }

          return Number(a.accountNo) - Number(b.accountNo);
        })
      )
    );
  }

  private filterAndSortAccounts(
    accounts: BacoAccountDto[],
    searchTerm: string
  ): BacoAccountDto[] {
    searchTerm = searchTerm?.toLowerCase();

    if (!searchTerm)
      return accounts.map((account) => ({
        ...account,
        combinedLabel: this.getCombinedLabel(account),
      }));

    const filtered = accounts
      .filter(
        (account) =>
          account.accountName?.toLowerCase().includes(searchTerm) ||
          account.accountNo?.toLowerCase().includes(searchTerm)
      )
      .map((account) => ({
        ...account,
        combinedLabel: this.getCombinedLabel(account),
      }))
      .sort((a, b) => {
        // Prioritize matches at the start of the string
        const nameStartsWithA = a.accountName
          ?.toLowerCase()
          .startsWith(searchTerm);
        const nameStartsWithB = b.accountName
          ?.toLowerCase()
          .startsWith(searchTerm);
        const numberStartsWithA = a.accountNo
          ?.toString()
          .startsWith(searchTerm);
        const numberStartsWithB = b.accountNo
          ?.toString()
          .startsWith(searchTerm);

        // Prioritize matches at the start of the string for both name and number
        if (nameStartsWithA && !nameStartsWithB) return -1;
        if (!nameStartsWithA && nameStartsWithB) return 1;
        if (numberStartsWithA && !numberStartsWithB) return -1;
        if (!numberStartsWithA && numberStartsWithB) return 1;

        // If both start with the search term or neither does, fall back to sorting by accountName then accountNo
        const nameCompare = a.accountName.localeCompare(b.accountName);
        if (nameCompare !== 0) return nameCompare;

        return a.accountNo?.localeCompare(b.accountNo);
      });

    return filtered;
  }

  public addNewBankConnection(bankConnection: BacoBankConnectionDto) {
    this.bankConnections.push(bankConnection);
  }

  public toDateString(date: string) {
    if (!date) {
      return 'N/A';
    }
    return new Date(date).toDateString();
  }

  public getCombinedLabel(account: BacoAccountDto) {
    return account.accountNo
      ? `${account.accountNo} - ${account.accountName}`
      : account.accountName;
  }

  public onClickTranferAccount(bankAccount: BacoBankAccountDto) {
    this._feedClient.getFeed(bankAccount.bacoFeedId).subscribe((sourceFeed) =>
      this._modalService
        .openModal(BankTransferComponent, null, bankAccount, {
          windowClass: 'transfer-connection-modal',
        })
        .then((destFeed: Feed) => {
          this._bankAccountClient
            .getTransactionsCount(bankAccount.id)
            .subscribe(({ codedTransactionsCount }) => {
              const warningText =
                codedTransactionsCount > 0
                  ? `<strong>Are you sure you want to proceed?</strong><br>Transferring this account to a new feed will remove the existing coding on ${codedTransactionsCount} transactions.`
                  : '';
              const additionalInfoText = `Transfer <strong>"${bankAccount.accountName}"</strong> account<br>from: <strong>"${sourceFeed.name}"</strong> feed collection<br>to: <strong>"${destFeed.name}"</strong>?`;

              const confirmation = new EnhancedConfirmation({
                title: 'Transfer account?',
                alertText: warningText,
                alertClass: 'alert-warning',
                additionalInfoText,
                approveAction: () => true,
                cancelAction: () => false,
                danger: false,
                shouldFocusOnSubmitButton: true,
                approveBtn: 'Transfer account',
                cancelButton: 'Cancel',
              });

              this._modalService.confirmationEx(confirmation).then((result) => {
                if (result === true) {
                  const bacoAccountTransferDto: BacoAccountTransferDto = {
                    bankAccountId: bankAccount.id,
                    bankAccountConnectionId: bankAccount.bacoBankConnectionId,
                    destinationFeedId: destFeed.id,
                    sourceFeedId: bankAccount.bacoFeedId,
                  };
                  this.busy.feeds = this._feedClient
                    .transferBankAccounts([bacoAccountTransferDto])
                    .subscribe(
                      () => {
                        this._messageService.success(
                          'Account transferred successfully.'
                        );
                        this._feedStore.refreshBankConnections();
                        this._transactionStore.refreshBankAccounts();
                      },
                      (error) => this.showError(error)
                    );
                }
              });
            });
        })
        .catch(() => true)
    );
  }
}
