<strong>Font Urls</strong>
<br>

<!-- <small>You can add the Url for fonts from <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">https://fonts.google.com/</a> to incorporate them into your reports.</small> -->

<div class="row m-0 my-3" *ngFor="let fontUrl of value">
    <div class="col-3">
        {{fontUrl.name}}
    </div>
    <div class="col-4 text-truncate">
        {{fontUrl.url}}
    </div>
    <div class="col-5">
        <button class="btn btn-outline-danger btn-sm" (click)="delete(fontUrl)">
            <i class="fas fa-trash" aria-hidden="true"></i>
        </button>
    </div>
</div>
<div class="row m-0 mt-2">

    <div class="col-3 pl-0">
        <input type="text" class="form-control" [(ngModel)]="newFontName" placeholder="Name">
    </div>

    <div class="col-4">
        <input type="text" class="form-control" [(ngModel)]="newFontUrl" placeholder="Url (eg. https://fonts.googleapis.com/css2?family=Roboto&display=swap)">
    </div>

    <div class="col-5 pt-1">
        <button class="btn btn-sm" [disabled]="!(newFontName && newFontUrl)"
            [ngClass]="newFontName && newFontUrl ? 'btn-outline-primary' : 'btn-outline-secondary'" (click)="add()">
            <i class="fas fa-plus" aria-hidden="true"></i>
        </button>
    </div>
</div>
