import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'crs-check-renderer',
  template: `
    <div class="crs-check-wrapper" (click)="onClick()">
      <i *ngIf="value" [ngClass]="iconClass"></i>
      <i *ngIf="!value && falseIconClass" [ngClass]="falseIconClass"></i>
    </div>
  `,
  styles: [
    '.crs-check-wrapper { cursor: pointer; width: 100%; height: 100%; }',
  ],
})
export class CheckRendererComponent implements ICellRendererAngularComp {
  value: boolean;
  inverse: boolean;
  iconClass: string;
  falseIconClass: string;
  private isToggleable: boolean;

  private params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.iconClass = params['iconClass'] || 'fas fa-check';
    this.falseIconClass = params['falseIconClass'] || '';
    this.inverse = !!params['inverse'];
    this.isToggleable = params['isToggleable'] || false;

    this.params = params;
    this.refresh(params);
  }

  refresh(params: ICellRendererParams): boolean {
    if (!params.data) {
      // Handle the case where params.data is undefined (group rows)
      this.value = false; // Or set to null if appropriate
    } else {
      // Proceed with normal logic
      this.value = params.data[params.colDef.field] == !this.inverse;
    }
    return true;
  }

  onClick() {
    if (this.isToggleable && this.params.data) {
      this.params.data[this.params.colDef.field] = !this.value;
      this.refresh(this.params);
    }
  }
}
