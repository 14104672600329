<div
  class="account-select-dropdown"
  [ngClass]="[variant, selectedValue ? 'has-value' : '']"
>
  <ng-select
    bindLabel="accountName"
    bindValue="id"
    placeholder="Select Account"
    [(ngModel)]="selectedAccount"
    [appendTo]="appendTo"
    [compareWith]="compareAccounts"
    [disabled]="isDisabled"
    [items]="accountsObservable | async"
    [loading]="isFetchingAccounts$ | async"
    [searchFn]="searchFn"
    [virtualScroll]="true"
    (change)="handleChange($event)"
  >
    <ng-template let-item="item" ng-label-tmp>
      {{ item.accountNo }} - {{ item.accountName }}
    </ng-template>
    <ng-template let-item="item" ng-option-tmp>
      <span class="label"> {{ item.accountNo }} - {{ item.accountName }} </span>
    </ng-template>
  </ng-select>
  <i class="fa fa-chevron-down"></i>
</div>
