import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  FeedDetailComponent,
  ReportPageComponent,
  RulePageComponent,
  RuleComponent,
  TransactionPageComponent,
  TransactionTableComponent,
  TransactionTableFooterComponent,
  CustomizePreviewMenuComponent,
  MainMenuComponent,
  BankSelectComponent,
  SplitTransactionComponent,
  BankConnectionSettingsComponent,
  BankConnectionPageComponent,
  BankConnectionTableComponent,
  RuleTableComponent,
  UploadTransactionComponent,
  ConditionSelectComponent,
  TransactionAccountSelectComponent,
  TransactionTaxSelectComponent,
  AddNewBankConnectionComponent,
  AddNewBankConnectionWizardComponent,
  TransactionCommentsComponent,
  TransactionCommentHistoryComponent,
  TransactionCommentComponent,
  TransactionCommentEditorComponent,
  TransactionStateIconComponent,
  BankConnectionCompletionComponent,
} from './components';
import { BankConnectionSettingsService, TransactionService } from './services';
import { BACO_DATE_FORMAT } from './baco.tokens';
import {
  BACO_TRANSACTION_TABLE_RENDERS,
  BACO_TRANSACTION_TABLE_EDITORS,
} from './ag-grid';
import { FeedCreateComponent } from './components/feed-create/feed-create.component';
import { BacoComponent } from './baco.component';
import { FeedsComponent } from './components/feeds/feeds.component';
import { CommentDateAgoPipe } from './pipes';
import { ReportsModule } from '../accounting/reports/reports.module';
import { FirmModule } from '../firm/firm.module';
import { TransferTransactionComponent } from './components/feed-detail/transactions/transfer-transaction';
import { AccountingCoreModule } from '../accounting/core/accounting-core.module';
import { BankTransferComponent } from './components/feed-detail/bank-connection/bank-transfer/bank-transfer.component';
import { BankConnectionTransactionValidationComponent } from './components/feed-detail/bank-connection/bank-connection-transaction-validation/bank-connection-transaction-validation.component';

@NgModule({
  declarations: [
    BACO_TRANSACTION_TABLE_RENDERS,
    BACO_TRANSACTION_TABLE_EDITORS,
    BacoComponent,
    FeedCreateComponent,
    FeedsComponent,
    FeedDetailComponent,
    BankConnectionPageComponent,
    AddNewBankConnectionComponent,
    AddNewBankConnectionWizardComponent,
    BankConnectionTableComponent,
    BankConnectionSettingsComponent,
    BankConnectionTransactionValidationComponent,
    MainMenuComponent,
    ReportPageComponent,
    RulePageComponent,
    RuleComponent,
    RuleTableComponent,
    TransactionPageComponent,
    BankSelectComponent,
    CustomizePreviewMenuComponent,
    TransactionTableComponent,
    TransactionTableFooterComponent,
    SplitTransactionComponent,
    TransferTransactionComponent,
    UploadTransactionComponent,
    ConditionSelectComponent,
    TransactionAccountSelectComponent,
    TransactionTaxSelectComponent,
    TransactionCommentsComponent,
    TransactionCommentComponent,
    TransactionCommentEditorComponent,
    TransactionCommentHistoryComponent,
    CommentDateAgoPipe,
    TransactionStateIconComponent,
    BankConnectionCompletionComponent,
    BankTransferComponent,
  ],
  providers: [
    BankConnectionSettingsService,
    DatePipe,
    {
      provide: BACO_DATE_FORMAT,
      useValue: 'yyyy-MM-dd',
    },
  ],
  imports: [
    AccountingCoreModule,
    CommonModule,
    SharedModule,
    BrowserAnimationsModule,
    ReportsModule,
    FirmModule,
    ClipboardModule,
  ],
  exports: [
    BACO_TRANSACTION_TABLE_RENDERS,
    BACO_TRANSACTION_TABLE_EDITORS,
    BacoComponent,
    FeedCreateComponent,
    FeedsComponent,
    FeedDetailComponent,
    BankConnectionPageComponent,
    AddNewBankConnectionComponent,
    AddNewBankConnectionWizardComponent,
    BankConnectionTableComponent,
    BankConnectionSettingsComponent,
    BankConnectionTransactionValidationComponent,
    MainMenuComponent,
    ReportPageComponent,
    RulePageComponent,
    RuleComponent,
    RuleTableComponent,
    TransactionPageComponent,
    BankSelectComponent,
    CustomizePreviewMenuComponent,
    TransactionTableComponent,
    TransactionTableFooterComponent,
    SplitTransactionComponent,
    TransferTransactionComponent,
    UploadTransactionComponent,
    ConditionSelectComponent,
    TransactionAccountSelectComponent,
    TransactionTaxSelectComponent,
    TransactionCommentsComponent,
    TransactionCommentComponent,
    TransactionCommentEditorComponent,
    TransactionCommentHistoryComponent,
    CommentDateAgoPipe,
    TransactionStateIconComponent,
    BankConnectionCompletionComponent,
  ],
})
export class BacoModule {}
