import { DatabaseService } from './../database.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { MessageService } from 'src/app/core';
import { getDefaultGridOptions } from 'src/app/shared';

@Component({
  selector: 'crs-databases',
  templateUrl: './databases.component.html'
})
export class DatabasesComponent implements OnInit {

  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    databases: false
  };
  databases$: Observable<any>;

  gridOptions = getDefaultGridOptions();

  constructor(private readonly _databaseService: DatabaseService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService) { }

  ngOnInit() {
    this.getDatabases();
  }

  getDatabases() {
    this.busy.databases = true;
    this.databases$ = this._databaseService.getAll().pipe(
      finalize(() => this.busy.databases = false),
      catchError(err => {
        this.showError(err);
        return of([]);
      }));
  }

  addDatabase() {
    this._router.navigate(['./add'], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectDatabase(param) {
    this._router.navigate(['./' + param.data.id], { relativeTo: this._route });
  }

}
