import { ElementRef } from '@angular/core';
import { JournalGrid } from '../../journals/journal/journal-grid';
import { SourceAccount } from '../../sourceAccounts';
import { GridApi } from 'ag-grid-community';
import { DistributionRow } from './distribution';

export class DistributionGrid extends JournalGrid {
  constructor(
    public classificationTemplate: ElementRef,
    public accountSearchTemplate: ElementRef,
    public taxCodeSearchTemplate: ElementRef,
    public totalsChanged?: () => void,
    public valuesChanged?: () => void
  ) {
    super(
      classificationTemplate,
      accountSearchTemplate,
      taxCodeSearchTemplate,
      totalsChanged,
      valuesChanged
    );
  }

  distributionAccountNoSearchParams = () => {
    return this.distributionAccountSearchParams('accountNo');
  };

  distributionAccountNameSearchParams = () => {
    return this.distributionAccountSearchParams('accountName');
  };

  onDistributionAccountSelect = (
    item: SourceAccount,
    node: { gridApi: GridApi; data: DistributionRow }
  ) => {
    node.data.distributionSourceAccount = new SourceAccount(item);
    node.gridApi.applyTransaction({
      update: [node.data],
    });
  };

  onValueChanged(event) {
    if (
      (event.column.colId === 'distributionAccountNo' ||
        event.column.colId === 'distributionAccountName') &&
      event.newValue !== event.oldValue
    ) {
      // Update matched distribution account
      event.node.data.distributionSourceAccount.id = this.matchId(
        event.data.distributionSourceAccount,
        this.accounts
      );
      this.gridApi.applyTransaction({
        update: [event.node.data],
      });
    }

    if (event.column.colId === 'distributionClassification') {
      event.node.data.distributionSourceAccount.classification = event.newValue;
      this.gridApi.applyTransaction({
        update: [event.node.data],
      });
    }

    super.onValueChanged(event);
  }

  cellEditingStarted(event) {
    const isClassificationAndHasSourceAccountId =
      event.column.colId === 'classification' &&
      event.node.data.sourceAccount.id;

    const isClassificationAndHasDistributionSourceAccountId =
      event.column.colId === 'distributionClassification' &&
      event.node.data.distributionSourceAccount.id;

    if (
      isClassificationAndHasSourceAccountId ||
      isClassificationAndHasDistributionSourceAccountId
    ) {
      this.gridOptions.api.stopEditing();
    } else {
      this.gridOptions.api?.startEditingCell(event);
    }
  }

  private distributionAccountSearchParams(property) {
    return {
      getList: () => this.accounts,
      property,
      template: this.accountSearchTemplate,
      searchFn: this.accountSearch,
      onSelect: this.onDistributionAccountSelect,
      editable: true,
      waitForDropdown: true,
    };
  }
}
