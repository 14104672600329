import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core';
import {
  BacoBankConnectionDto,
  BacoAccountDto,
  BacoTaxOptionDto,
  BacoRuleDto,
  BacoBankAccountDto,
  BacoRuleCreateModel,
  BacoLedgerToLink,
  BacoSyncChartOfAccountsModel,
  BacoAccountTransferDto,
} from '../../interfaces';
import { BacoFeedCreateRequest } from '../../interfaces/baco-feed-create-request.interface';
import { Feed, FeedDetailed } from '../../interfaces/feed-interface';
import {
  BacoBankAccountUpdateIsHiddenRequest,
  BacoBankAccountUpdateRequest,
} from '../../interfaces/baco-bank-account-update-request';

@Injectable({
  providedIn: 'root',
})
export class FeedClient {
  private readonly base: string = 'api/baco/feed-collections';

  constructor(private readonly _apiService: ApiService) {}

  public createFeed(model: BacoFeedCreateRequest): Observable<string> {
    return this._apiService.post<string>(`${this.base}`, model);
  }

  public updateFeedDetail(
    feedId: string,
    model: BacoFeedCreateRequest
  ): Observable<boolean> {
    return this._apiService.put<boolean>(`${this.base}/${feedId}`, model);
  }

  public archiveFeed(feedId: string): Observable<boolean> {
    return this._apiService.delete<boolean>(`${this.base}/${feedId}`);
  }

  public getFeeds(): Observable<Feed[]> {
    return this._apiService.get<Feed[]>(`${this.base}/`);
  }

  public getFeed(feedId: string): Observable<FeedDetailed> {
    return this._apiService.get<FeedDetailed>(`${this.base}/${feedId}/`);
  }

  public getForFile$(fileId: string): Observable<Feed[]> {
    return this._apiService.get<Feed[]>(`${this.base}/for-file/${fileId}`);
  }

  public getLinkedLedger$(feedId: string): Observable<Feed> {
    return this._apiService.get<Feed>(`${this.base}/${feedId}/link-ledger`);
  }

  public linkLedger$(
    feedId: string,
    ledgerToLink: BacoLedgerToLink
  ): Observable<boolean> {
    return this._apiService.put<boolean>(
      `${this.base}/${feedId}/link-ledger`,
      ledgerToLink
    );
  }

  public getBankConnections(
    feedId: string
  ): Observable<BacoBankConnectionDto[]> {
    return this._apiService.get<BacoBankConnectionDto[]>(
      `${this.base}/${feedId}/bank-connections`
    );
  }

  public getUserBankConnections(
    emailAddress: string
  ): Observable<BacoBankConnectionDto[]> {
    return this._apiService.get<BacoBankConnectionDto[]>(
      `${this.base}/${emailAddress}/user-bank-connections`
    );
  }

  public syncChartOfAccounts$(
    feedId: string,
    syncChartOfAccountsModel: BacoSyncChartOfAccountsModel
  ): Observable<boolean> {
    return this._apiService.put<boolean>(
      `${this.base}/${feedId}/chart-of-accounts`,
      syncChartOfAccountsModel
    );
  }

  public getBankAccounts(feedId: string): Observable<BacoBankAccountDto[]> {
    return this._apiService.get<BacoBankAccountDto[]>(
      `${this.base}/${feedId}/bank-accounts`
    );
  }

  public getUserBankAccounts(
    emailAddress: string
  ): Observable<BacoBankAccountDto[]> {
    return this._apiService.get<BacoBankAccountDto[]>(
      `${this.base}/${emailAddress}/user-bank-accounts`
    );
  }

  public updateBankAccountDetail(
    feedId: string,
    model: BacoBankAccountUpdateRequest
  ): Observable<string> {
    return this._apiService.put<string>(
      `${this.base}/${feedId}/bank-accounts`,
      [model]
    );
  }

  public transferBankAccounts(
    model: BacoAccountTransferDto[]
  ): Observable<string[]> {
    return this._apiService.put<string[]>(
      `${this.base}/transfer-bank-accounts`,
      model
    );
  }

  public updateBankAccountIsHidden(
    feedId: string,
    model: BacoBankAccountUpdateIsHiddenRequest
  ): Observable<string> {
    const { bankAccountId, ...rest } = model;

    return this._apiService.put<string>(
      `${this.base}/${feedId}/bank-account/${bankAccountId}/is-hidden`,
      rest
    );
  }

  public getAccounts(feedId: string): Observable<BacoAccountDto[]> {
    return this._apiService.get<BacoAccountDto[]>(
      `${this.base}/${feedId}/accounts`
    );
  }

  public getTaxOptions(feedId: string): Observable<BacoTaxOptionDto[]> {
    return this._apiService.get<BacoTaxOptionDto[]>(
      `${this.base}/${feedId}/tax-options`
    );
  }

  public getRules(feedId: string): Observable<BacoRuleDto[]> {
    return this._apiService.get<BacoRuleDto[]>(`${this.base}/${feedId}/rules`);
  }

  public createRule(feedId: string, model: BacoRuleCreateModel) {
    return this._apiService.post<BacoRuleDto>(
      `${this.base}/${feedId}/rule`,
      model
    );
  }
}
