import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'crs-busy',
  templateUrl: './busy.component.html'
})
export class BusyComponent implements OnInit {

  @Input() small = false;
  constructor() { }

  ngOnInit() {
  }

}
