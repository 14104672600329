<div class="page-wrapper min-width-tablet">
  <div class="header-container container-grey-bg" *ngIf="file">
    <div class="banner-content">
      <!-- Breadcrumb button -->
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <a
            class="btn btn-ghost"
            *ngIf="isConsolidated"
            [routerLink]="['/clients', file.clientId]"
          >
            <i aria-hidden="true" class="fas fa-arrow-left inset"></i>
            Clients
          </a>
          <a
            class="btn btn-ghost"
            *ngIf="!isConsolidated"
            [routerLink]="['/clients', file.entity.clientId]"
          >
            <i aria-hidden="true" class="fas fa-arrow-left inset"></i>
            Clients
          </a>
        </div>

        <!-- file settings -->
        <div class="dropdown">
          <button
            aria-expanded="false"
            aria-haspopup="true"
            class="btn btn-ghost"
            data-bs-toggle="dropdown"
            id="dropdownMenuFileSettings"
            type="button"
          >
            <span class="align-middle me-2">File Settings</span>
            <i aria-hidden="true" class="fas fa-cog align-middle"></i>
          </button>
          <div
            aria-labelledby="dropdownMenuFileSettings"
            class="dropdown-menu dropdown-menu-right"
          >
            <a
              class="dropdown-item"
              [routerLink]="['/accounting', file.id, 'sources']"
              >Sources</a
            >
            <a
              class="dropdown-item"
              [routerLink]="['/accounting', file.id, 'divisions']"
              >Divisions</a
            >
            <a
              class="dropdown-item"
              [routerLink]="['/accounting', file.id, 'tradingAccounts']"
              >Trading Accounts</a
            >
            <a
              class="dropdown-item"
              [routerLink]="['/accounting', file.id, 'accountingPolicies']"
              >Accounting Policies</a
            >
            <a
              class="dropdown-item"
              [routerLink]="['/accounting', file.id, 'disclosureTemplates']"
              >Disclosure Templates</a
            >
            <a
              class="dropdown-item"
              [routerLink]="['/accounting', file.id, 'matching-rule-sets']"
              >Matching Rule Sets</a
            >
            <a
              class="dropdown-item"
              *ngIf="showTaxCodes"
              [routerLink]="['/accounting', file.id, 'tax-codes']"
              >Tax Codes</a
            >
            <div class="dropdown-divider" *ngIf="file.entity"></div>
            <button
              class="btn dropdown-item"
              type="button"
              *ngIf="file.entity"
              (click)="editEntity()"
            >
              <i aria-hidden="true" class="fas fa-cog"></i>
              Edit Entity
            </button>
            <div class="dropdown-divider"></div>
            <button class="btn dropdown-item" type="button" (click)="delete()">
              <i aria-hidden="true" class="fas fa-trash"></i>
              Delete File
            </button>
          </div>
        </div>
      </div>

      <!-- File name -->
      <div class="d-flex align-middle pt-2">
        <h2 class="file-name-heading">{{ file.name }}</h2>
        <a
          class="ms-2"
          *ngIf="!file.default"
          [routerLink]="[]"
          (click)="editFile()"
        >
          <i aria-hidden="true" class="fas fa-pencil-alt fas-btn-muted"></i>
        </a>
      </div>
    </div>

    <!-- Navigation tabs -->
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let route of routes">
        <a
          class="nav-link"
          [class.active]="currentRoute == route.route"
          [routerLink]="['/accounting', file.id, route.route]"
          >{{ route.name }}
          <span
            class="badge"
            *ngIf="route.badge"
            [ngClass]="route.badge.classes"
          >
            {{ route.badge.name }}
          </span>
        </a>
      </li>
      <li class="nav-item ms-auto" *ngIf="nonStandardRoute">
        <a class="nav-link active" [routerLink]="[]">
          {{ currentRoute | sentence }}
          <a class="close" [routerLink]="['/accounting', file.id]">
            <i aria-hidden="true" class="fas fa-times ms-1"></i>
          </a>
        </a>
      </li>
    </ul>
  </div>

  <!-- Tab route -->
  <div class="content-container-wrapper">
    <div class="tabbed-route-container">
      <router-outlet *ngIf="file"></router-outlet>
    </div>
  </div>
</div>
