import { StandardAccount } from '../standard-account';
import { IAccountsViewAccount } from '../../../ledger/accounts/accounts/accounts-view-account';
import {
  StandardAccountContext,
  StandardAccountHeadersGridComponent,
} from './standard-account-headers-grid.component';
import {
  MoveRequest,
  DragContext,
  MoveResult,
  MoveAction,
  MovingRow,
  IAccountGridManager,
  MoveSource,
} from '../../../ledger/accounts/accounts/accountGridModel';
import { getDefaultGridOptions, accountingRenderers } from 'src/app/shared';
import { Observable, Subscription, EMPTY, Subject } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';
import { NgProgressRef } from '../../../../shared';
import { MessageService } from '../../../../core';

import { MoveProcessor } from 'src/app/accounting/ledger/accounts/accounts/moveProcessor';

import {
  GetContextMenuItemsParams,
  MenuItemDef,
  RowDragEvent,
  GridReadyEvent,
  RowNode,
  GridOptions,
  GridApi,
} from 'ag-grid-community';
import { AccountsViewSourceAccount } from 'src/app/accounting/ledger/accounts/accounts/accounts-view-source-account';

/**
 * This class manages all aspects of an account grid, including display, refreshing and drag/drop.
 * It is instantiated for the 'Header' accounts
 */
export class StandardAccountGridManager implements IAccountGridManager {
  private readonly placeholderId = 'placeholder';

  constructor(
    public readonly id: 'headersGrid',
    private readonly _standardAccountsComponent: StandardAccountHeadersGridComponent,
    private readonly _context: StandardAccountContext,
    public readonly moveProcessor: MoveProcessor,
    public readonly _dragContext: DragContext,
    private readonly _messageService: MessageService,
    private readonly _refreshAll: (
      context: StandardAccountContext
    ) => Observable<IAccountsViewAccount[]>,
    private readonly _refreshIds: (
      context: StandardAccountContext,
      ids: string[]
    ) => Observable<IAccountsViewAccount[]>,
    private readonly _progressBar: NgProgressRef
  ) {
    this.configureGridOptions();
  }

  busy: Subscription;

  selection$ = new Subject<string>();

  store: IAccountsViewAccount[] = [];
  backupStore: IAccountsViewAccount[];
  gridOptions: GridOptions;
  gridApi: GridApi;

  get api() {
    return this.gridApi;
  }

  sortActive = false;
  filterActive = false;

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.id,
      onGridReady: (event) => {
        this.gridApi = event.api;
        this.configureDragDrop(event);
      },
      getDataPath: (data) => data.hierarchy,
      getContextMenuItems: (param) => this.getContextMenu(param),
      onRowDragEnter: (param) => this.onRowDragEnter(param),
      onRowDragMove: (param) => this.onRowDragMove(param),
      onRowDragLeave: (param) => this.onRowDragLeave(param),
      onRowDragEnd: (param) => this.onRowDragEnd(param),
      onRowSelected: () => this.selection$.next(this.id),
      fullWidthCellRenderer: (params) =>
        '<div class="account-placeholder"> Insert here... </div>',
      components: accountingRenderers,
      domLayout: 'normal',
      context: this._dragContext,
      treeData: true,
      defaultColDef: {
        sortable: false,
      },
      groupDefaultExpanded: 1,
      rowClassRules: {
        'account-link': this.getAccountLinkClass,
      },
    };
  }

  isSourceAccount(param: RowNode) {
    return param.data instanceof AccountsViewSourceAccount;
  }

  configureDragDrop(params: GridReadyEvent) {
    const api = this._standardAccountsComponent.headersGridMgr.api;
    const dropZoneParams = api?.getRowDropZoneParams({});
    params.api?.addRowDropZone(dropZoneParams);
  }

  expandAll() {
    this.api.forEachNode((node) => (node.expanded = true));
    this.api.onGroupExpandedOrCollapsed();
  }

  collapseAll() {
    this.api.forEachNode((node) => (node.expanded = false));
    this.api.onGroupExpandedOrCollapsed();
  }

  collapseGroupsToLevelOne() {
    this.gridApi?.forEachNode((node) => {
      if (!node) {
        return;
      }
      if (node.level >= 1) {
        // Collapse groups at level 1 or higher
        this.gridApi.setRowNodeExpanded(node, false);
      } else {
        // Expand groups at level 0
        this.gridApi.setRowNodeExpanded(node, true);
      }
    });
  }

  getAccountLinkClass(params: { data: StandardAccount; context: DragContext }) {
    const result = params.context.moveResult;
    if (!result || !result.overNode.data || !params.data) return false;

    if (
      result.overNode.data.id === params.data.id &&
      (result.action === MoveAction.Link ||
        result.action === MoveAction.InsertInto)
    ) {
      return true;
    }
    return false;
  }

  // --------------------------------------------
  // ----------- Context Menu Methods -----------
  // --------------------------------------------

  getContextMenu(param: GetContextMenuItemsParams) {
    if (param.node.data instanceof AccountsViewSourceAccount) return [];
    const targetAccount = param.node.data as StandardAccount;

    const cssClasses = ['clickable'];
    let menu = [];

    // GROUPING {
    if (targetAccount.isSortable && param.api.getSelectedRows().length > 1) {
      const parent = param.node.parent.data as StandardAccount;
      menu.push({
        name: 'Quick Group',
        icon: '<i class="fas fa-layer-group" aria-hidden="true"></i>',
        action: () =>
          this._standardAccountsComponent.quickGroup(
            parent,
            param.node.childIndex + 1
          ),
        cssClasses: cssClasses,
      });
    }

    // NORMAL EDIT MENU ITEMS - HEADER
    if (targetAccount.isHeader) {
      menu.push({
        name: 'Add Custom Header',
        icon: '<i class="fas fa-stream" aria-hidden="true"></i>',
        action: () =>
          this._standardAccountsComponent.onClickAddHeader(targetAccount),
        cssClasses: cssClasses,
      });
      if (targetAccount.isSortable) {
        menu.push({
          name: 'Edit',
          icon: '<i class="fas fa-edit" aria-hidden="true"></i>',
          action: () =>
            this._standardAccountsComponent.onClickEditHeader(targetAccount),
          cssClasses: cssClasses,
        });
      }
      if (targetAccount.isSortable) {
        menu.push({
          name: 'Delete',
          icon: '<i class="fas fa-trash" aria-hidden="true"></i>',
          action: () =>
            this._standardAccountsComponent.onClickDeleteHeader(targetAccount),
          cssClasses: cssClasses,
        });
      }
      menu.push('separator');
    }

    // NORMAL EDIT MENU ITEMS - ACCOUNT
    if (!targetAccount.isHeader && !targetAccount.systemAccount) {
      menu.push({
        name: 'Edit',
        icon: '<i class="fas fa-edit" aria-hidden="true"></i>',
        action: () =>
          this._standardAccountsComponent.onClickEditAccount(targetAccount),
        cssClasses: cssClasses,
      });
      menu.push('separator');
    }

    // DRAG DROP MENU ITEMS
    const source = this.moveProcessor.getSelectionAsMoveSource();
    if (!source) {
      menu.push({
        name: 'Please select some accounts to move here',
        disabled: true,
      });
    } else {
      const actions = this.getAvailableActions(targetAccount, source);
      menu = menu.concat(
        actions.map((a) => {
          const menuItem = this.getMoveActionMenuItem(a);
          menuItem.action = () =>
            this.moveProcessor
              .execute(
                new MoveRequest(source, new MoveResult(this, a, param.node))
              )
              .subscribe();
          menuItem.cssClasses = ['clickable'];
          return menuItem;
        })
      );
    }

    return menu;
  }

  private getAvailableActions(
    targetAccount: StandardAccount,
    source: MoveSource
  ): MoveAction[] {
    if (source.movingRows.some((r) => r.account.id === targetAccount.id)) {
      return [];
    }

    if (targetAccount.hierarchy.length === 1) {
      return [MoveAction.InsertInto];
    }

    if (
      source.movingRows.some(
        (r) => r.isHeader && r.account.isParentOf(targetAccount)
      )
    ) {
      return [];
    }

    const actions: MoveAction[] = [];
    actions.push(MoveAction.InsertAbove);
    if (targetAccount.isHeader) {
      actions.push(MoveAction.InsertInto);
    } else if (!source.isMovingAHeader) {
      actions.push(MoveAction.Link);
    }
    actions.push(MoveAction.InsertBelow);

    return actions;
  }

  // Todo: move this to a 'prototypes' class
  private getMoveActionMenuItem(action: MoveAction): MenuItemDef {
    if (action === MoveAction.InsertAbove) {
      return {
        name: 'Insert Selection Before',
        icon: '<i class="fas fa-chevron-up" aria-hidden="true" />',
      };
    }
    if (action === MoveAction.InsertInto) {
      return {
        name: 'Insert Selection into this Account',
        icon: '<i class="fas fa-level-down-alt" aria-hidden="true" />',
      };
    }
    if (action === MoveAction.Link) {
      return {
        name: 'Combine Selected accounts',
        icon: '<i class="fas fa-link" aria-hidden="true" />',
      };
    }
    if (action === MoveAction.InsertBelow) {
      return {
        name: 'Insert Selection After',
        icon: '<i class="fas fa-chevron-down" aria-hidden="true" />',
      };
    }
    return {
      name: MoveAction[action],
    };
  }

  // --------------------------------------------
  // --------- Drag and Drop Handlers -----------
  // --------------------------------------------

  onRowDragEnter(event: RowDragEvent) {
    if (this._progressBar.isStarted) return;

    if (event.event.type === 'mousemove') return;

    const node = event.node;
    if (!node.isSelected()) node.setSelected(true, true);
    this.startContext(node);
    this.createBackup();
    if (!event.nodes) event.nodes = [event.node];
    this.refreshGrid();
  }

  onRowDragMove(event: RowDragEvent) {
    if (!this._dragContext.isActiveDrag) return;
    this.updateDragResultContext(event);
    this.moveProcessor.visualise(this._dragContext);
  }

  onRowDragEnd(event: RowDragEvent) {
    if (!this._dragContext.moveSource) return;
    this.updateDragResultContext(event);
    this.moveProcessor
      .execute(this._dragContext)
      .pipe(finalize(() => this._dragContext.clear()))
      .subscribe();
  }

  onRowDragLeave(event: RowDragEvent) {
    this.restoreBackup();
    this.refreshGrid();
  }

  private buildMovingRows() {
    const rows = this.api.getSelectedNodes().map((r) => new MovingRow(r.data));
    rows.forEach((r) => {
      r.addAffectedChildren(this.store);
    });
    // Remove children of headers that are already selected
    return rows.filter(
      (r) =>
        !rows.some((sr) =>
          sr.affectedChildren.some((c) => c.id === r.account.id)
        )
    );
  }

  // --------------------------------------------
  // ----------- UI Update Methods --------------
  // --------------------------------------------
  startLoader() {
    this._progressBar.start();
  }

  completeLoader() {
    this._progressBar.complete();
  }

  visualiseRemove(previousResult: MoveResult) {
    this.removePlaceholder();
    if (previousResult && previousResult.overNode)
      this.refreshNode(previousResult.overNode);
  }

  visualiseAdd(result: MoveResult) {
    if (result && result.overNode) {
      this.refreshNode(result.overNode);
      this.insertPlaceholder(result);
    }
  }

  private refreshNode(node) {
    const index = this.store.findIndex((r) => r.id === node.data.id);
    if (index >= 0) {
      const newAccount = this.shallowCopyNodeData(node.data);
      this.store.splice(index, 1, newAccount);
    }
  }

  private removePlaceholder() {
    const previousIndex = this.store.findIndex(
      (r) => r.id === this.placeholderId
    );
    if (previousIndex >= 0) this.store.splice(previousIndex, 1);
  }

  private insertPlaceholder(moveResult: MoveResult) {
    const node = moveResult.overNode;
    const action = moveResult.action;

    if (!node || !node.data) return;

    if (action === MoveAction.Link || action === MoveAction.None) return;

    const placeholder = new StandardAccount({
      id: this.placeholderId,
      hierarchy: this.getBaseHierarchy(node, action).concat(this.placeholderId),
    });

    // Remove Old - ocassionally race condition means it hasn't been removed prior to this re-insert
    this.removePlaceholder();
    // Insert New
    const index =
      this.store.findIndex((r) => r.id === node.data.id) +
      (action === MoveAction.InsertAbove ? 0 : 1);
    if (index >= 0) this.store.splice(index, 0, placeholder);
  }

  private getBaseHierarchy(node, action) {
    const hierarchy = node.data.hierarchy.slice();
    if (action !== MoveAction.InsertInto) hierarchy.pop();
    return hierarchy;
  }

  removeRows(rows: IAccountsViewAccount[]) {
    rows.forEach((r) => {
      const index = this.store.findIndex((a) => a.id === r.id);
      if (index >= 0) this.store.splice(index, 1);
    });
  }

  insertRows(overNode: any, rows: IAccountsViewAccount[], adj: number = 0) {
    const index = this.store.findIndex((r) => r.id === overNode.data.id);
    let i = 0;
    rows.forEach((r) => {
      this.store.splice(index + adj + i, 0, r);
      i++;
    });
  }

  // --------------------------------------------
  // ------------- Data Methods -----------------
  // --------------------------------------------

  setStore(accounts: IAccountsViewAccount[]) {
    this.store = accounts;
    this.createBackup();
    this.refreshGrid();
  }

  restoreBackup() {
    this.store = this.backupStore
      .slice()
      .map((a) => this.shallowCopyNodeData(a));
  }

  createBackup() {
    this.backupStore = this.store
      .slice()
      .map((a) => this.shallowCopyNodeData(a));
  }

  refreshId(id) {
    this._progressBar.start();

    return this.refreshIdsObservable([id])
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => {
          this._progressBar.complete();
        })
      )
      .subscribe();
  }

  refreshAll() {
    this._progressBar.start();
    return this.refreshAllObservable()
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => {
          this._progressBar.complete();
        })
      )
      .subscribe();
  }

  refreshAllObservable() {
    return this._refreshAll(this._context).pipe(
      tap((data) => {
        this.setStore(data);
        this.createBackup();
        this.api?.setGridOption('rowData', this.store);
      }),
      catchError((e) => {
        this.api?.setGridOption('rowData', this.store);
        throw e;
      })
    );
  }

  refreshIdsObservable(ids: string[]) {
    return this._refreshIds(this._context, ids).pipe(
      tap((data) => {
        this.refreshStore(ids, data);
        this.createBackup();
        this.api?.setGridOption('rowData', this.store);
      }),
      catchError((e) => {
        this.api?.setGridOption('rowData', this.store);
        throw e;
      })
    );
  }

  /**
   * For the newly supplied header ids and accounts (retrieved from the API/database)
   * updates the relevant grid ids with these new values
   * @param headerIds The header ids affected
   * @param accounts The new accounts for these header ids (per the API response)
   */
  private refreshStore(headerIds: string[], accounts: IAccountsViewAccount[]) {
    headerIds.forEach((i) => {
      const index = this.store.findIndex((r) => r.id === i);
      const oldHeader = this.store[index];
      const oldAccounts = this.store.filter((a) => a.isChildOf(oldHeader));
      this.removeRows(oldAccounts);

      const newHeader = accounts.find((a) => a.id === i);
      const newAccounts = accounts.filter((a) => a.isChildOf(newHeader));

      this.store.splice(index, 0, ...newAccounts);
    });
  }

  refreshGrid() {
    this.api?.setGridOption('rowData', this.store);
  }

  private shallowCopyNodeData(data: IAccountsViewAccount) {
    return data.shallowCopy();
  }

  // --------------------------------------------
  // ------------- Drag Context -----------------
  // --------------------------------------------

  getMoveSource(node = null): MoveSource {
    return new MoveSource(this, node, this.buildMovingRows());
  }

  private startContext(node) {
    this._dragContext.startDragging(this.getMoveSource(node));
  }

  private clearContext() {
    this._dragContext.clear();
  }

  private updateDragResultContext(event) {
    if (!event.overNode || !event.overNode.data) {
      this._dragContext.setNewMoveResult(null);
      return;
    }
    if (event.overNode.data.id === this.placeholderId) return;
    this._dragContext.setNewMoveResult(this.getDragResult(event));
  }

  private getDragResult(event): MoveResult {
    return new MoveResult(this, this.getAction(event), event.overNode);
  }

  private getAction(event): MoveAction {
    if (this._progressBar.isStarted) return MoveAction.None;
    if (event.overNode.data instanceof AccountsViewSourceAccount)
      return MoveAction.None;

    const y = event.y;
    const data = event.overNode.data as StandardAccount;
    const top = event.overNode.rowTop;
    const height = event.overNode.rowHeight;

    if (
      this._dragContext.moveSource.movingRows.some(
        (r) => r.account.id === data.id
      )
    )
      return MoveAction.None;
    if (data.hierarchy.length === 1) return MoveAction.InsertInto;
    if (
      this._dragContext.moveSource.movingRows.some(
        (r) => r.isHeader && r.account.isParentOf(data)
      )
    )
      return MoveAction.None;

    const topCentre = top + height * 0.32;
    const bottomCentre = top + height * 0.68;
    const middle = top + height * 0.5;
    if (y <= topCentre) return MoveAction.InsertAbove;
    if (y > topCentre && y < bottomCentre) {
      if (data.isHeader) return MoveAction.InsertInto;
      else if (this._dragContext.moveSource.isMovingAHeader)
        return y > middle ? MoveAction.InsertAbove : MoveAction.InsertBelow;
      else return MoveAction.Link;
    }
    if (y >= bottomCentre) return MoveAction.InsertBelow;
    return MoveAction.None;
  }

  private showError(error) {
    console.log(error);
    this._messageService.error(error);
  }
}
