import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, PagedResponse } from '../../core';
import { Entity, EntityModel } from '../entities/entity';

@Injectable({
  providedIn: 'root',
})
export class EntityService {
  readonly base: string = 'api/entities';

  constructor(private apiService: ApiService) {}

  private _refreshEntitiesSource = new Subject<void>();
  refreshEntities$ = this._refreshEntitiesSource.asObservable();

  search(search: string, page: number, pageSize: number) {
    var url = this.base;
    url = this.apiService.addQuery(url, 'search', search);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    return this.apiService.get<PagedResponse<Entity>>(url);
  }

  get$(id: string): Observable<Entity> {
    return this.apiService
      .get<Entity>(`${this.base}/${id}`)
      .pipe(map((entity) => new Entity(entity)));
  }

  post$(entity: EntityModel): Observable<any> {
    return this.apiService
      .post<any>(this.base, entity)
      .pipe(map((entity) => entity.id));
  }

  put$(entity: EntityModel): Observable<any> {
    return this.apiService.put<any>(`${this.base}/${entity.id}`, entity);
  }

  delete$(id: string) {
    return this.apiService.delete<Entity>(`${this.base}/${id}`);
  }

  triggerRefreshEntities() {
    this._refreshEntitiesSource.next();
  }
}
