<div class="center">

  <img width="70" src="./assets/images/logo.png" alt="Access Logo">

  <div class="spacer-md"></div>

  <p><strong>
    <span *ngIf="isSuccess">Successfully Accepted</span>
    <span *ngIf="error">Error Accepting</span>
    <span *ngIf="!isSuccess && !error">Processing</span>
    Invite
  </strong></p>

  <div class="spacer-md"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <p *ngIf="isSuccess">
    You now have access to {{firmName ? firmName : 'this organisation'}}. Click to go to <a routerLink="/clients">Clients</a>.
  </p>

  <p *ngIf=" !isSuccess && !error">
    Processing...
  </p>

</div>
