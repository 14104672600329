<div class="standard-account-headers-wrapper">
  <crs-progress-bar id="headersBar"></crs-progress-bar>

  <crs-busy *ngIf="busy.headers"></crs-busy>

  <ag-grid-angular
    #headersGrid
    class="ag-theme-material ag-theme-material-hierarchy"
    style="width: 100%; height: 100%"
    [autoGroupColumnDef]="headersGroupColumnDef"
    [getRowClass]="getRowClass"
    [gridOptions]="headersGridMgr.gridOptions"
    [headerHeight]="0"
  >
    <ag-grid-column
      cellClass="muted-cell"
      field="accountNo"
      headerClass="muted-header"
      headerName="Account No"
      [filter]="true"
      [hide]="true"
    ></ag-grid-column>

    <ag-grid-column
      cellClass="muted-cell"
      field="accountName"
      headerClass="muted-header"
      headerName="Account Name"
      [filter]="true"
      [hide]="true"
    ></ag-grid-column>

    <ag-grid-column
      cellClass="muted-cell"
      field="accountType.name"
      headerClass="muted-header"
      headerName="Account Type"
      [filter]="true"
      [hide]="true"
    ></ag-grid-column>

    <ag-grid-column
      field="isConsolidated"
      headerClass="muted-header"
      headerName="Consolidated"
      type="booleanColumn"
      [cellClass]="['boolean-cell', 'centered', 'muted-cell']"
      [hide]="true"
    ></ag-grid-column>

    <ag-grid-column
      cellRenderer="accountDetailRenderer"
      field="accountType"
      headerClass="muted-header"
      headerName=""
      id="detail"
      maxWidth="200"
    ></ag-grid-column>

    <ag-grid-column
      maxWidth="50"
      minWidth="50"
      pinned="none"
      type="optionsColumn"
      [cellRendererParams]="{ ngTemplate: optionsCell }"
      [resizable]="false"
    ></ag-grid-column>

    <ag-grid-column
      field="rowDrag"
      headerName=""
      maxWidth="50"
      minWidth="50"
      [resizable]="false"
      [rowDrag]="isRowDraggable"
    ></ag-grid-column>
  </ag-grid-angular>

  <ng-template #optionsCell let-row>
    <div class="btn-mini-menu" *ngIf="row?.isHeader">
      <a
        aria-expanded="false"
        aria-haspopup="true"
        class="btn-sm fas-btn-primary-ghost"
        data-toggle="dropdown"
        [routerLink]="[]"
      >
        <i aria-hidden="true" class="fas fa-ellipsis-v"></i>
        <span class="sr-only"> Show Header Account Options </span>
      </a>
      <div class="dropdown-menu">
        <button
          class="btn dropdown-item text-info"
          type="button"
          (click)="onClickAddHeader(row)"
        >
          <i aria-hidden="true" class="fas fa-stream"></i>
          Add Custom Header
        </button>
        <button
          class="btn dropdown-item text-info"
          type="button"
          (click)="onClickEditHeader(row)"
        >
          <i aria-hidden="true" class="fas fa-edit"></i>
          Edit Header
        </button>
        <button
          class="btn dropdown-item text-info"
          type="button"
          *ngIf="isAccountsActivated"
          (click)="onClickAddAccount(row)"
        >
          <i aria-hidden="true" class="fas fa-stream"></i>
          Add Account
        </button>
        <button
          class="btn dropdown-item text-danger"
          type="button"
          *ngIf="row.isSortable"
          (click)="onClickDeleteHeader(row)"
        >
          <i aria-hidden="true" class="fas fa-trash"></i>
          Delete Header
        </button>
      </div>
    </div>
    <div
      class="btn-mini-menu"
      *ngIf="!row?.isHeader && !row?.isSystemAccount && !row?.isSourceAccount"
    >
      <a
        class="btn-sm fas-btn-primary-ghost"
        [routerLink]="[]"
        (click)="onClickEditAccount(row)"
      >
        <i aria-hidden="true" class="fas fa-edit"></i>
      </a>
    </div>
  </ng-template>
</div>
