import { Component, OnInit, Input, ViewEncapsulation, ViewChild, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

import { Office, OfficeUser, OfficeService } from '../';

@Component({
  selector: 'crs-office-select',
  templateUrl: './office-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OfficeSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OfficeSelectComponent implements OnInit, ControlValueAccessor {

  @Input() clearable: boolean = true;
  @Input() readonly: boolean = false;

  @ViewChild(NgSelectComponent, { static: true }) private _valueAccessor: ControlValueAccessor;

  officesObservable: Observable<Office[]>;
  compareWith = (a, b) => a === b;
  office;

  constructor(private readonly _officeService: OfficeService) {
    
  }

  writeValue(value: any | any[]): void {
    (this._valueAccessor as NgSelectComponent).bindLabel = 'name'; //Sometimes this hasn't been set prior to writing value, possible bug in ng-select
    this._valueAccessor.writeValue(value);
    this.office = value;
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }

  ngOnInit() {
    this.officesObservable = this._officeService.getAll();
  }


}
