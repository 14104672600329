import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
  concat,
  from,
  of,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  finalize,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { File } from 'src/app/accounting/files/file';
import { DocumentIntegrationValue } from 'src/app/configuration/enums';
import { IntegrationServerDatasource } from 'src/app/configuration/integration-server/integration-server.datasource';
import {
  ReportTemplate,
  ReportTemplateModel,
  ReportTemplateService,
} from '../';
import { DocumentIntegrationService } from '../../../../configuration/document-integration/document-integration.datasource';
import {
  DownloadService,
  MessageService,
  ModalService,
} from '../../../../core';
import { Entity, entityTypes } from '../../../../firm';
import { IDialogPanel } from '../../../../shared';
import { ActiveFileService } from '../../../active-file.service';
import { Dataset } from '../../../datasets';
import { DisclosureSelectorsService } from '../../notes/disclosures/disclosure-selectors.service';
import { ReportViewerParams } from '../../reportViewer/models';
import { ReportExportModalComponent } from '../../reportViewer/report-export-modal/report-export-modal.component';
import { ReportOutput } from '../../reportViewer/report-output';
import { ExportOption } from '../../reportViewer/report-viewer/export-strategy';
import { ReportViewerComponent } from '../../reportViewer/report-viewer/report-viewer.component';
import { ReportDownloadService } from '../../services';
import { ReportTemplateDataService } from '../report-template-data.service';
import { ReportTemplateFormBuilder } from '../report-template-form-builder';
import { SaveAsComponent } from '../save-as/save-as.component';
import { ESignatureSendingModalComponent } from './esignature-sending-modal/esignature-sending-modal.component';

class SubmitOption {
  closeOnSuccess: boolean;
  saveAs: boolean;

  constructor(closeOnSuccess: boolean, saveAs: boolean) {
    this.closeOnSuccess = closeOnSuccess;
    this.saveAs = saveAs;
  }
}

enum GenerateOption {
  Normal = 0,
  Excel = 1,
  Pdf = 2,
  Word = 3,
}

@Component({
  selector: 'crs-report-template',
  templateUrl: './report-template.component.html',
  providers: [
    DisclosureSelectorsService,
    DocumentIntegrationService,
    IntegrationServerDatasource,
  ],
})
export class ReportTemplateComponent implements OnInit, OnDestroy {
  id: string;
  isAdd: boolean;
  masterId: number;
  fileId: string;
  isConsolidatedFile: boolean;
  objectTitle = 'Report Template';
  error: string | any[] = null;

  entities: Entity[] = [];
  entityTypes = entityTypes;
  public generateOptions = GenerateOption;

  filteredDatasets: Dataset[] = [];
  subscriptions: Subscription[] = [];
  formSubscriptions: Subscription[] = [];

  submitButtonStream = new Subject<SubmitOption>();
  generateButtonStream = new Subject<GenerateOption>();
  busy = {
    load: false,
    submit: null,
    generate: null,
  };

  form: UntypedFormGroup = this.reportTemplateFormBuilder.buildForm(null);

  file: File;
  isConnectedToIs: boolean = false;
  isDocumentIntegrationAccessDocuments: boolean = false;
  isDocumentIntegrationPleaseSign: boolean = false;

  isFetchingConnectedToIs$ = new BehaviorSubject(false);
  isFetchingDocumentIntegrationSetting$ = new BehaviorSubject(false);

  constructor(
    public data: ReportTemplateDataService,
    private activeFileService: ActiveFileService,
    private disclosureSelectorsService: DisclosureSelectorsService,
    private documentIntegrationService: DocumentIntegrationService,
    private downloadService: DownloadService,
    private integrationServerDatasource: IntegrationServerDatasource,
    private location: Location,
    private messageService: MessageService,
    private modalService: ModalService,
    private reportDownloadService: ReportDownloadService,
    private reportTemplateFormBuilder: ReportTemplateFormBuilder,
    private reportTemplateService: ReportTemplateService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params.id;
      this.isAdd = this.id === 'add';
      this.masterId = parseInt(params.masterId, 10);
      this.fileId = this.activeFileService.file.id;
      this.entities = this.activeFileService.file.entities;
      this.isConsolidatedFile = this.activeFileService.file.isConsolidated;
      this.getData();

      this.fetchFile();
      this.fetchIsConnectedToIntegrationServer();
      this.fetchIsDocumentIntegrationSetting();
    });

    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => (this.error = null)),
          // switchMap(v => this.validateForm(v)),
          exhaustMap((submitOption) => {
            return this.submit$(submitOption);
          })
        )
        .subscribe((submitOption) => {
          if (submitOption.closeOnSuccess) {
            this.onClickClose();
          }
        })
    );

    this.subscriptions.push(
      this.generateButtonStream
        .pipe(
          tap(() => (this.error = null)),
          // switchMap(v => this.validateForm(v)),
          exhaustMap((generateOption) => this.generate$(generateOption))
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.disposeCurrentForm();
  }

  /**
   * Creates a form for the new template and subscribes to the interested variables
   * @param reportTemplate the template to create a form for
   */
  private initialiseNewForm(template: ReportTemplate) {
    this.disposeCurrentForm();
    this.form = this.reportTemplateFormBuilder.buildForm(template);

    if (this.isConsolidatedFile)
      this.form.get('entity').setValidators(Validators.required);

    const notesPage = template.pages.find(
      (page) => page.noteDetail && page.noteDetail.reportingSuite
    );
    this.disclosureSelectorsService.updateContextProperties({
      fileId: this.fileId,
      templateId: this.isAdd ? 'add' : template.id,
      entityType: template.entityType,
      reportingSuiteId: notesPage
        ? notesPage.noteDetail.reportingSuite.id
        : null,
    });

    this.formSubscriptions.push(
      this.form.controls['entityType'].valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(
          (entityType) =>
            this.disclosureSelectorsService.updateContextProperties({
              entityType,
            }),
          (error) => {
            console.log('error filtering datasets on entity change.', error);
          }
        )
    );

    this.formSubscriptions.push(
      this.form.controls['entity'].valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(
          () => this.filterDatasets(),
          (error) => {
            console.log('error filtering datasets on entity change.', error);
          }
        )
    );

    this.formSubscriptions.push(
      this.form.controls['useDefaultDates'].valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((useDefaultDates) => {
          this.form
            .get('startDate')
            .setValidators(useDefaultDates ? null : [Validators.required]);
          this.form
            .get('endDate')
            .setValidators(useDefaultDates ? null : [Validators.required]);
        })
    );
  }

  /**
   * Disposes of subscriptions and other variables associated with the current form.
   * Call this prior to initialising a new form
   */
  private disposeCurrentForm() {
    this.formSubscriptions.forEach((subscription) => subscription.unsubscribe);
    this.formSubscriptions = [];
  }

  private validateForm(value: any): Observable<any> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.error = 'Please fix validation errors.';
      return EMPTY;
    }
    return of(value);
  }

  /**
   * Loads data and other package information for the report
   */
  getData() {
    const file = this.activeFileService.file;

    const newReport = this.masterId
      ? this.reportTemplateService
          .getDefaultFromMaster(this.fileId, this.masterId as number)
          .pipe(
            map((template) => {
              template.entityType = file.entity ? file.entity.entityTypeId : 8;
              return template;
            })
          )
      : of(
          new ReportTemplate({
            entityType: file.entity ? file.entity.entityTypeId : 8,
            pages: [],
            columns: [],
          })
        );

    const getExistingReport = this.reportTemplateService.get(this.id);

    let getReportTemplate = this.isAdd ? newReport : getExistingReport;
    getReportTemplate = getReportTemplate.pipe(
      tap((template) => {
        this.initialiseNewForm(template);
      })
    );

    const getPackage = this.data.refresh().pipe(
      tap((response) => {
        this.filterDatasets();
      })
    );

    this.busy.load = true;
    concat(getReportTemplate, getPackage)
      .pipe(finalize(() => (this.busy.load = false)))
      .subscribe(
        () => true,
        (err) => this.showError(err)
      );
  }

  private filterDatasets() {
    const entity = <Entity>(
      (<UntypedFormArray>this.form.controls['entity']).value
    );
    if (entity) {
      this.filteredDatasets = this.data.datasets.filter(
        (dataset) => dataset.entity.id === entity.id
      );
    } else {
      this.filteredDatasets = this.data.datasets;
    }
  }

  onClickSave(closeOnSuccess: boolean) {
    const submitOption = new SubmitOption(closeOnSuccess, false);
    this.submitButtonStream.next(submitOption);
  }

  onClickSaveAs() {
    const submitOption = new SubmitOption(false, true);
    this.submitButtonStream.next(submitOption);
  }

  onClickGenerate(option: GenerateOption = GenerateOption.Normal) {
    this.generateButtonStream.next(option);
  }

  submit$(options: SubmitOption): Observable<SubmitOption> {
    let observable$: Observable<SubmitOption>;
    this.form.controls['columns'].updateValueAndValidity();
    this.form.controls['pages'].updateValueAndValidity();
    this.form.markAsDirty();

    const reportTemplate = new ReportTemplateModel(this.form.value);
    reportTemplate.fileId = this.fileId;

    if (options.saveAs) {
      const saveAsModal = this.modalService.openModal(SaveAsComponent, null, {
        text: 'Please provide a name for the new report template',
      });
      observable$ = from(saveAsModal).pipe(
        catchError(() => EMPTY),
        switchMap((name) => {
          reportTemplate.name = name as string;
          return this.reportTemplateService.post(reportTemplate);
        }),
        tap((id) => {
          this.isAdd = false;
          this.id = id as string;
          if (!options.closeOnSuccess) this.updateUrl();
        }),
        map(() => options)
      );
    } else if (this.isAdd) {
      observable$ = this.reportTemplateService.post(reportTemplate).pipe(
        tap((id) => {
          this.isAdd = false;
          this.id = id as string;
          if (!options.closeOnSuccess) this.updateUrl();
        }),
        map(() => options)
      );
    } else {
      reportTemplate.id = this.id;
      observable$ = this.reportTemplateService
        .put(reportTemplate)
        .pipe(map(() => options));
    }

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    observable$ = observable$.pipe(
      tap(() => this.messageService.success('Successfully saved report.')),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );

    return observable$;
  }

  generate$(option: GenerateOption): Observable<any> {
    const canOpenReportExportModal =
      this.isConnectedToIs && this.isDocumentIntegrationAccessDocuments;

    const loading$ = new Subject<void>();
    this.busy.generate = loading$.subscribe();

    this.form.controls['columns'].updateValueAndValidity();
    this.form.controls['pages'].updateValueAndValidity();

    const reportTemplate = new ReportTemplateModel(this.form.value);
    reportTemplate.fileId = this.fileId;
    reportTemplate.masterId = this.masterId;

    let method$: Observable<any>;

    switch (option) {
      case GenerateOption.Normal: {
        method$ = this.reportTemplateService
          .generate$(reportTemplate)
          .pipe(tap((report) => this.renderViewer(report, reportTemplate)));
        break;
      }
      case GenerateOption.Excel: {
        method$ = this.reportTemplateService.generate$(reportTemplate).pipe(
          tap((report) => {
            if (canOpenReportExportModal) {
              this.modalService.openModal(ReportExportModalComponent, null, {
                canOpenReportExportModal,
                exportOption: ExportOption.Excel,
                report,
                reportDocumentListItem: null,
                shouldUpdateCustomTableAutoTotal: true,
              });

              loading$.complete();
              return;
            }

            this.reportTemplateService.updateCustomTableAutoTotal(report);

            this.reportDownloadService
              .exportAsExcel$(
                report,
                this.getTitle(this.form.value as ReportTemplate)
              )
              .pipe(finalize(() => {}))
              .subscribe(
                (url) => this.downloadService.download(url),
                (err) => this.messageService.error(err)
              );
          })
        );
        break;
      }
      case GenerateOption.Pdf: {
        method$ = this.reportTemplateService.generate$(reportTemplate).pipe(
          tap((report) => {
            if (canOpenReportExportModal) {
              this.modalService.openModal(ReportExportModalComponent, null, {
                canOpenReportExportModal,
                exportOption: ExportOption.Pdf,
                report,
                reportDocumentListItem: null,
                shouldUpdateCustomTableAutoTotal: true,
              });

              loading$.complete();
              return;
            }

            this.reportTemplateService.updateCustomTableAutoTotal(report);

            this.reportDownloadService
              .exportAsPdf$(
                report,
                this.getTitle(this.form.value as ReportTemplate)
              )
              .pipe(finalize(() => {}))
              .subscribe(
                (url) => this.downloadService.download(url),
                (err) => this.messageService.error(err)
              );
          })
        );
        break;
      }
      case GenerateOption.Word: {
        method$ = this.reportTemplateService.generate$(reportTemplate).pipe(
          tap((report) => {
            if (canOpenReportExportModal) {
              this.modalService.openModal(ReportExportModalComponent, null, {
                canOpenReportExportModal,
                exportOption: ExportOption.Word,
                report,
                reportDocumentListItem: null,
                shouldUpdateCustomTableAutoTotal: true,
              });

              loading$.complete();
              return;
            }

            this.reportTemplateService.updateCustomTableAutoTotal(report);

            this.reportDownloadService
              .exportAsWord$(
                report,
                this.getTitle(this.form.value as ReportTemplate)
              )
              .pipe(finalize(() => {}))
              .subscribe(
                (url) => this.downloadService.download(url),
                (err) => this.messageService.error(err)
              );
          })
        );
        break;
      }
    }

    return method$.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => {
        loading$.complete();
      })
    );
  }

  /**
   * Generating the report object for saving it before eSign.
   */
  private getReport$(): Observable<ReportOutput> {
    this.busy.load = true;

    const reportTemplate = new ReportTemplateModel(this.form.value);
    reportTemplate.fileId = this.fileId;
    reportTemplate.masterId = this.masterId;

    if (!reportTemplate) {
      return null;
    }

    return this.reportTemplateService
      .generate$(reportTemplate)
      .pipe(finalize(() => (this.busy.load = false)));
  }

  private getTitle(template: ReportTemplate): string {
    const entityName = !this.activeFileService.file.isConsolidated
      ? this.activeFileService.file.entity.legalName
      : template.entity.legalName;
    const date = template.useDefaultDates
      ? (template.columns[0].endDate ?? template.columns[0].dataset.endDate)
      : template.endDate;
    return `${date.getFullYear()} ${template.title} - ${entityName}`;
  }

  /**
   * After saving a new report template, updates url to reflect address of newly saved report
   */
  private updateUrl(): void {
    const url = this.router
      .createUrlTree(['../', this.id], { relativeTo: this.route })
      .toString();

    this.location.go(url);
  }

  private renderViewer(
    report: ReportOutput,
    reportTemplate: ReportTemplateModel
  ): void {
    const data = new ReportViewerParams({
      report,
      reportTemplate,
      shouldUpdateCustomTableAutoTotal: true,
    });
    this.modalService.overlay(ReportViewerComponent, {
      data,
    } as IDialogPanel<ReportViewerParams>);
  }

  onClickDelete() {
    return this.modalService.confirmation(
      'Are you sure you want to delete this report template? This action cannot be undone.',
      () => {
        this.reportTemplateService.delete(this.id).subscribe(() => {
          this.onClickClose();
        }, this.showError);
      },
      true
    );
  }

  onClickClose() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private showError(err) {
    this.error = err;
    this.messageService.error(err);
  }

  onClickOpenESignatureModal() {
    const template = new ReportTemplateModel(this.form.value);
    template.fileId = this.fileId;
    const title = this.getTitle(this.form.value as ReportTemplate);

    const { entity } = this.activeFileService.file;

    this.getReport$().subscribe((report) => {
      this.reportTemplateService.updateCustomTableAutoTotal(report);
      this.modalService
        .openModal(
          ESignatureSendingModalComponent,
          null,
          { template, title, entity, report },
          { windowClass: 'esign-modal' }
        )
        .then((r) => true)
        .catch(() => true);
    });
  }

  private fetchFile(): void {
    this.activeFileService?.stream?.subscribe((file) => {
      this.file = file;
    });
  }

  private fetchIsConnectedToIntegrationServer(): void {
    this.isFetchingConnectedToIs$.next(true);

    this.integrationServerDatasource
      .getStatus()
      .pipe(
        catchError((error) => {
          this.messageService.error(error);
          return EMPTY;
        }),
        first(),
        finalize(() => this.isFetchingConnectedToIs$.next(false))
      )
      .subscribe((res) => {
        this.isConnectedToIs = res.isConnected;
      });
  }

  private fetchIsDocumentIntegrationSetting(): void {
    this.isFetchingDocumentIntegrationSetting$.next(true);

    this.documentIntegrationService
      .getDocumentIntegrationSetting$()
      .pipe(
        catchError((error) => {
          this.messageService.error(error);
          return EMPTY;
        }),
        first(),
        finalize(() => this.isFetchingDocumentIntegrationSetting$.next(false))
      )
      .subscribe((eSignature) => {
        this.isDocumentIntegrationAccessDocuments =
          eSignature?.value === DocumentIntegrationValue.AccessDocuments;
        this.isDocumentIntegrationPleaseSign =
          eSignature?.value === DocumentIntegrationValue.PleaseSign;
      });
  }
}
