import { WorkpaperBusinessFitStarter } from './workpaper-business-fit-starter';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkpapersBusinessFitService {
  readonly base = 'api/accounting/workpapers-business-fit';

  constructor(private readonly _apiService: ApiService) {}

  getStarters(): Observable<WorkpaperBusinessFitStarter[]> {
    return this._apiService
      .get<WorkpaperBusinessFitStarter[]>(this.base + '/starters')
      .pipe(map((r) => r.map((s) => new WorkpaperBusinessFitStarter(s))));
  }

  getStarter(id: string, fileId: string): Observable<string> {
    return this._apiService
      .get<any>(this.base + '/starters/' + id + '/download?fileId=' + fileId)
      .pipe(map((r) => r.data));
  }
}
