import { tap, catchError } from 'rxjs/operators';
import { ReportAuditorModel } from './report-auditor-element';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReportContentInfo } from '../../report-content/report-content-info';
import { ReportAuditorComponentService } from './report-auditor-component.service';
import { MessageService, ModalService } from 'src/app/core';
import { of } from 'rxjs';
import { ReportMetaData } from '../../report-output';

@Component({
  selector: 'crs-report-auditor',
  templateUrl: './report-auditor.component.html',
  styleUrls: ['./report-auditor.component.scss']
})
export class ReportAuditorComponent implements OnInit {

  form: UntypedFormGroup;
  component = this;
  isEditing = false;
  busy = {
    load: null,
    submit: null,
    delete: null
  };
  error: string = null;

  @Input('columnClass') columnClass?; string;
  @Input() element;
  @Input() index: number;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;
  @Input() auditorNameWithTitle : string;
  @Input() auditorAddress: string;
  auditorFromPractice: any;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
              private readonly _reportAuditorService: ReportAuditorComponentService,
              private readonly _messageService: MessageService,
              private readonly _modalService: ModalService) {
  }

  ngOnInit(): void {
    if (!this.columnClass) this.columnClass = 'col-12';
    this.fetchAuditorFromPractice().subscribe(() => {
      // Since the complete MVP solution will not be available soon we need to remove auto updating auditor details to practice
      // setting from report.
      // Will revisit this feature in the future with proper solution design

      // If audior details are changed in practice, update it in page element.
      // if(this.isAuditorDetailsBeUpdated())
      // {
        // this.updateAuditorElementIfPracticeAuditorInfoChanged();
      // }

      this.formAuditorNameWithTitle();
      this.formAuditorAddress();
    });
  }

  // If more than one report with auditor components are included and if the user update practice details,
  // all auditor details to be updated in page load
  fetchAuditorFromPractice(showLoader = true) {
    this.busy.load = showLoader;
    return this._reportAuditorService.get(this.element.practiceId).pipe(tap( auditorFromPractice => {
           this.auditorFromPractice = auditorFromPractice;
           this.busy.load = false;
          }),
          catchError(err => {
                     this.busy.load = false;
                     this.showError(err);
                     return of([]);
          }));
  }

  isAuditorDetailsBeUpdated()
  {
      if(!this.auditorFromPractice) return false;

      if(this.element.auditorPracticeName !== this.auditorFromPractice.practiceName ||
        this.element.auditorName !== this.auditorFromPractice.auditorName ||
        this.element.auditorTitle !== this.auditorFromPractice.auditorTitle ||
        this.element.auditorAddress.trim() !== this.auditorFromPractice.addressLine1.trim() ||
        this.element.auditorState !== this.auditorFromPractice.state ||
        this.element.auditorSuburb !== this.auditorFromPractice.suburb ||
        this.element.auditorPostCode !== this.auditorFromPractice.postCode )
        {
          // Auditor details in practice and report element are not same
          return true;
        }
      return false;
  }

  updateAuditorElementIfPracticeAuditorInfoChanged()
  {
      this.element.auditorPracticeName = this.auditorFromPractice.practiceName;
      this.element.auditorName = this.auditorFromPractice.auditorName;
      this.element.auditorTitle = this.auditorFromPractice.auditorTitle;
      this.element.auditorAddress = this.auditorFromPractice.addressLine1.trim();
      this.element.auditorState = this.auditorFromPractice.state;
      this.element.auditorSuburb = this.auditorFromPractice.suburb;
      this.element.auditorPostCode = this.auditorFromPractice.postCode;
  }

  // Viewer to show the address, suburb, state and post code in a single line.
  formAuditorAddress() {
    this.auditorAddress = '';
    if(this.element.auditorAddress)
    {
      this.auditorAddress = this.element.auditorAddress;
    }

    if(this.element.auditorSuburb)
    {
      this.auditorAddress += ', ' + this.element.auditorSuburb;
    }

    if(this.element.auditorState)
    {
      this.auditorAddress += ', ' + this.element.auditorState;
    }

    if(this.element.auditorPostCode)
    {
      this.auditorAddress += ', ' + this.element.auditorPostCode;
    }
  }

  // Viewer to show Auditor name and title in a single line.
  formAuditorNameWithTitle() {
    this.auditorNameWithTitle = '';
    if(this.element.auditorName)
    {
      this.auditorNameWithTitle = this.element.auditorName + ', ';
    }

    if(this.element.auditorTitle)
    {
      this.auditorNameWithTitle += this.element.auditorTitle;
    }
  }

  onEdit() {
      this.form = this._formBuilder.group({
      auditorPracticeName: [null],
      auditorAddress: [null],
      auditorSuburb:[null],
      auditorState:[null],
      auditorPostCode:[null],
      auditorName:[null],
      auditorTitle:[null],
      auditorEmail:[null],
      date:[null],
      updateAuditorDetails:[null]
    });
    this.form.patchValue(this.element);
    this.isEditing = true;
  }

  onSubmit = () => {
    this.form.markAsTouched();
    if (!this.form.valid) return false;

    Object.assign(this.element, this.form.value);

    // Since the complete MVP solution will not be available soon we need to remove auto updating auditor details to practice
    // setting from report.
    // Will revisit this feature in the future with proper solution design
    //if(this.form.get('updateAuditorDetails').value == 1)
    //{
        // this.showAuditorDetailsWarningAndUpdateAuditorDetails();
        // this.element.updateAuditorDetails = false;
    // }

    this.formAuditorNameWithTitle();
    this.formAuditorAddress();

    return true;
  }

  showAuditorDetailsWarningAndUpdateAuditorDetails() {
    this._modalService.confirmation(`
    <div class="alert alert-warning">Auditor's details already exist for this practice. Are you sure you want to overwrite them?</div>

    <p>This action <strong>cannot</strong> be undone.</p>`,
    () => this.updateAuditorDetailsToPractice(), true);
  }

  updateAuditorDetailsToPractice() {
    this.error = null;

    const reportAuditor = new ReportAuditorModel(this.form.value);
      reportAuditor.practiceId = this.element.practiceId;

      this.busy.submit = this._reportAuditorService
      .put(reportAuditor)
      .subscribe(() => err => this.showError(err));
  }

  onCancel() {
    this.isEditing = false;
    this.form = null;
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }
}
