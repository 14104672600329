import { ReportTableCell } from './report-table-cell';
import { TableRowType } from '../enums';
import { HeaderStyle } from '../../financial/header-style';

export class ReportTableRow {
  constructor(data?: Partial<ReportTableRow>) {
    if (data) Object.assign(this, data);
  }

  rowType: TableRowType;
  style?: HeaderStyle;
  level?: number;
  height: number;
  cells: ReportTableCell[];
  enableAutoColumnHeader?: boolean = true;
  allowAutoColumnHeader?: boolean = true;
  sections: string[];

  get isHeaderRow(): boolean {
    return this.rowType == TableRowType.ColumnHeader || this.rowType == TableRowType.Header || this.rowType == TableRowType.HeaderColumnGroups;
  }

  get isTotalRow(): boolean {
    return this.rowType == TableRowType.SubTotal || this.rowType == TableRowType.GrandTotal || this.rowType == TableRowType.GroupTotal;
  }
}
