import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService, PagedResponse } from '../../../core';
import {
  SourceAccount,
  SourceAccountModel,
  SourceAccountToUpsert,
} from './sourceAccount';
import { Observable } from 'rxjs';

type GetSourceAccountsForFileParams = {
  fileId: string;
  search?: string | null;
  classificationId?: string | null;
  sourceId?: string | null;
  divisionId?: string | null;
  tradingAccountId?: string | null;
  hasEntries?: boolean | null;
  includeInactive?: boolean | null;
  page?: number | null;
  pageSize?: number | null;
  orderBy?: string | null;
  orderByAsc?: boolean | null;
};

@Injectable({ providedIn: 'root' })
export class SourceAccountService {
  readonly base: string = 'api/accounting/ledger/source-accounts';

  constructor(private apiService: ApiService) {}

  getForDataset$(datasetId: string): Observable<SourceAccount[]> {
    return this.apiService
      .get<SourceAccount[]>(`${this.base}/for-dataset/${datasetId}`)
      .pipe(
        map((sourceAccounts) =>
          sourceAccounts.map((account) => new SourceAccount(account))
        )
      );
  }

  getForSource(sourceId: string): Observable<SourceAccount[]> {
    return this.apiService
      .get<SourceAccount[]>(`${this.base}/for-source/${sourceId}`)
      .pipe(
        map((sourceAccounts) =>
          sourceAccounts.map((account) => new SourceAccount(account))
        )
      );
  }

  getForDatasetPaginated$(
    datasetId: string,
    page?: number,
    pageSize?: number,
    includeInactive?: boolean
  ): Observable<PagedResponse<SourceAccount>> {
    let url = `${this.base}/dataset/${datasetId}`;

    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    url = this.apiService.addQuery(url, 'includeInactive', includeInactive);

    return this.apiService.get<PagedResponse<SourceAccount>>(url).pipe(
      map((response) => new PagedResponse<SourceAccount>(response)),
      map((response) => {
        response.records = response.records.map(
          (account) => new SourceAccount(account)
        );
        return response;
      })
    );
  }

  getForSourcePaginated$(
    sourceId: string,
    page?: number,
    pageSize?: number,
    includeInactive?: boolean
  ): Observable<PagedResponse<SourceAccount>> {
    let url = `${this.base}/source/${sourceId}`;

    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    url = this.apiService.addQuery(url, 'includeInactive', includeInactive);

    return this.apiService.get<PagedResponse<SourceAccount>>(url).pipe(
      map((response) => new PagedResponse<SourceAccount>(response)),
      map((response) => {
        response.records = response.records.map(
          (account) => new SourceAccount(account)
        );
        return response;
      })
    );
  }

  getForFilePaginated$({
    fileId,
    search,
    classificationId,
    sourceId,
    divisionId,
    tradingAccountId,
    hasEntries,
    includeInactive,
    page,
    pageSize,
    orderBy,
    orderByAsc,
  }: GetSourceAccountsForFileParams): Observable<PagedResponse<SourceAccount>> {
    const encodedSearchValue = search ? encodeURIComponent(search) : '';

    let url = `${this.base}/file/${fileId}`;

    url = this.apiService.addQuery(url, 'search', encodedSearchValue);
    url = this.apiService.addQuery(url, 'sourceId', sourceId);
    url = this.apiService.addQuery(url, 'divisionId', divisionId);
    url = this.apiService.addQuery(url, 'tradingAccountId', tradingAccountId);
    url = this.apiService.addQuery(
      url,
      'searchClassification',
      classificationId
    );
    url = this.apiService.addQuery(url, 'hasEntries', hasEntries);
    url = this.apiService.addQuery(url, 'includeInactive', includeInactive);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    url = this.apiService.addQuery(url, 'orderBy', orderBy);
    url = this.apiService.addQuery(url, 'orderByAsc', orderByAsc);

    return this.apiService.get<PagedResponse<SourceAccount>>(url).pipe(
      map((response) => new PagedResponse<SourceAccount>(response)),
      map((response) => {
        response.records = response.records.map(
          (account) => new SourceAccount(account)
        );
        return response;
      })
    );
  }

  get$(accountId: string): Observable<SourceAccount> {
    return this.apiService
      .get(`${this.base}/${accountId}`)
      .pipe(map((account) => new SourceAccount(account)));
  }

  post$(accountToAdd: SourceAccountToUpsert): Observable<string> {
    return this.apiService.post(this.base, accountToAdd);
  }

  put$(
    accountId: string,
    updateModel: SourceAccountModel | SourceAccountToUpsert
  ): Observable<{}> {
    return this.apiService.put(`${this.base}/${accountId}`, updateModel);
  }

  delete$(accountId: string): Observable<{}> {
    return this.apiService.delete(`${this.base}/${accountId}`);
  }

  unlink(id: string) {
    return this.apiService.put(`${this.base}/${id}/unlink`, null);
  }
}
