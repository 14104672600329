import { IntIdNamePair, GuidIdNamePair } from '../../../shared';
import { Classification } from '../classifications/classification';
import { IAccount, Account } from './account';
import { AccountType } from '../account-types';
import { AccountTypeMode } from './accountTypeMode';
import { AutoMatchMode } from './autoMatchMode';
import { SourceAccount } from '../sourceAccounts';
import { MatchingRule } from '../../setup/matchingRules/matchingRule';

export class ChildAccount implements IAccount {
  id: string;
  parentId: string;
  parent: Account;
  accountNo: string;
  accountName: string;
  sourceClassification: Classification;
  accountType: AccountType;
  accountTypeMode: AccountTypeMode;
  autoMatchMode: AutoMatchMode;
  division: GuidIdNamePair;
  tradingAccount: GuidIdNamePair;
  headerMatchingRule: MatchingRule;
  accountTypeMatchingRule: MatchingRule;
  isHeader: boolean;
  systemAccount: number;
  isSortable: boolean;
  isReassignable: boolean;
  sourceAccounts: SourceAccount[] = [];
  hierarchy: string[] = [];

  get displayName(): string {
    if (this.accountNo) return this.accountNo + ' ' + this.accountName;
    else return this.accountName;
  }

  getUpdateModel() {
    const model = Object.assign(this);
    if (model.accountType) {
      model.accountTypeId = model.accountType.id;
      model.accountType = null;
    }
    if (model.division) {
      model.divisionId = model.division.id;
      model.division = null;
    }
    if (model.tradingAccount) {
      model.tradingAccountId = model.tradingAccount.id;
      model.tradingAccount = null;
    }
    return model;
  }

  constructor(data) {
    Object.assign(this, data);
    if (data.sourceAccounts) {
      this.sourceAccounts = data.sourceAccounts.map(a => new SourceAccount(a));
    }
    if (data.parent) {
      this.parent = new Account(data.parent);
    }
    if (data.headerMatchingRule) {
      this.headerMatchingRule = new MatchingRule(data.headerMatchingRule);
    }
    if (data.accountTypeMatchingRule) {
      this.accountTypeMatchingRule = new MatchingRule(data.accountTypeMatchingRule);
    }
  }
}
