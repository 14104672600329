import { ReportMetaData } from './../../../report-output';
import { FinancialElementType } from './../financial-element-type';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'crs-financial-insert-element',
  templateUrl: './financial-insert-element.component.html',
  styleUrls: ['./financial-insert-element.component.scss']
})
export class FinancialInsertElementComponent {

  @Input() index: number;
  @Input() content: any[];
  @Input() reportMetaData: ReportMetaData;

  addItem(type: number) {
    const newElement = this.getNewElement(type);
    if (!newElement) return;
    this.content.splice(this.index + 1, 0, newElement);

    if(!this.reportMetaData) return;
    this.reportMetaData.isModified = true;
  }

  getNewElement(type: number) {
    const base = <any>{
      financialType: type
    };
    if (type !== FinancialElementType.Spacer) {
      base.level = 1;
      base.title = 'New Row...';
    }
    if (type === FinancialElementType.Row || type === FinancialElementType.Total) {
      base.totalDefinition = {
        name: 'New Row...',
        formula: null
      };
    }
    return base;
  }

}
