export class FirmUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  invitePending: boolean;

  constructor(data) {
    Object.assign(this, data);
  }
}
