<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-10 col-lg-8">
        <div class="form-group">
          <label class="control-label">Match Type</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="type"
            [items]="matchTypes | enumToArray"
          ></ng-select>
        </div>

        <h2 class="form-group-title">Match Conditions</h2>

        <div *ngIf="form.value.type !== 2">
          <div class="form-group">
            <label class="control-label">For the Classification</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="classification"
              [items]="classifications | enumToArray"
            ></ng-select>
          </div>

          <div class="form-group">
            <label class="control-label">Search Mode</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="mode"
              [items]="matchModes"
            ></ng-select>
          </div>

          <div class="form-group" *ngIf="form.value.mode !== 4; else between">
            <label class="control-label">Find</label>
            <input class="form-control" formControlName="find" type="text" />
            <small class="form-text text-muted" id="findHelp"
              >Use '*' to match any text</small
            >

            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input"
                  formControlName="findCaseSensitive"
                  type="checkbox"
                />
                <label class="form-check-label">Case Sensitive</label>
              </div>
            </div>
          </div>

          <ng-template #between>
            <div class="form-group">
              <label class="control-label">Start Number</label>
              <input
                class="form-control"
                formControlName="start"
                type="number"
              />
            </div>

            <div class="form-group">
              <label class="control-label">End Number</label>
              <input class="form-control" formControlName="end" type="number" />
            </div>
          </ng-template>
        </div>

        <div *ngIf="form.value.type === 2">
          <div class="form-group">
            <label class="control-label">Account Type</label>
            <crs-account-type-select
              formControlName="findAccountType"
            ></crs-account-type-select>
          </div>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="overrideManualValues"
              type="checkbox"
            />
            <label class="form-check-label"
              >Apply to all accounts, including manually set ones</label
            >
          </div>
        </div>

        <h2 class="form-group-title">Link to</h2>
        <small class="form-text text-muted"
          >When a match is found, link the account to a header and/or an account
          type.</small
        >
        <br />

        <div class="form-group" *ngIf="!isMaster">
          <label class="control-label">Header</label>
          <crs-account-select
            formControlName="account"
            [includeChildren]="false"
          ></crs-account-select>
        </div>

        <div class="form-group" *ngIf="isMaster">
          <label class="control-label">Master Account</label>
          <crs-master-account-select
            formControlName="masterAccount"
          ></crs-master-account-select>
        </div>

        <div class="form-group">
          <label class="control-label">Account Type</label>
          <crs-account-type-select
            formControlName="accountType"
          ></crs-account-type-select>
        </div>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      Save {{ objectTitle }}
    </button>
    <div class="btn-group mr-2" *ngIf="!isMaster">
      <button
        class="btn btn-secondary"
        title="Apply just this rule to the accounts"
        type="button"
        [promiseBtn]="busy.apply"
        (click)="apply(false)"
      >
        <i aria-hidden="true" class="fas fa-play"></i>
        Run Rule Now
      </button>
      <button
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        type="button"
      >
        <span class="sr-only">Auto Match Options</span>
      </button>
      <div class="dropdown-menu">
        <button class="dropdown-item" type="button" (click)="apply(false)">
          Tag as Auto (default)
        </button>
        <button class="dropdown-item" type="button" (click)="apply(true)">
          Tag as Manual
        </button>
      </div>
    </div>

    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>
</form>
