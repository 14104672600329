import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { TeamService } from '../';
import { MessageService } from '../../../core';
import { getDefaultGridOptions, accountingRenderers } from '../../../shared';

@Component({
  selector: 'crs-teams',
  templateUrl: './teams.component.html'
})
export class TeamsComponent implements OnInit {


  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    teams: false
  };
  teamsObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;

  constructor(private readonly _teamService: TeamService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService) { }

  ngOnInit() {
    this.getTeams();
  }

  getTeams() {
    this.busy.teams = true;
    this.teamsObservable = this._teamService.getAll().pipe(tap(() => this.busy.teams = false), catchError(err => {
      this.showError(err);
      return of([]);
    }));
  }

  addTeam() {
    this._router.navigate(['./add'], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectTeam(param) {
    this._router.navigate(['./' + param.data.id], { relativeTo: this._route });
  }

}
