import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, of } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { IntegrationServerDatasource } from 'src/app/configuration/integration-server/integration-server.datasource';
import { MessageService } from 'src/app/core';
import { AccountingService } from '../../accounting.service';
import { TaxFormService } from '../../ledger/taxForms/tax-form.service';
import { TaxForm } from '../../ledger/taxForms/taxForm';
import { TaxTransferService } from './tax-transfer.service';
import { DatasetService } from '../dataset.service';
import { SourceTypeId } from '../../sourcedata';

@Component({
  selector: 'crs-dataset-balances',
  templateUrl: './dataset-balances.component.html',
  styleUrls: ['./dataset-balances.component.scss'],
  providers: [IntegrationServerDatasource],
})
export class DatasetBalancesComponent implements OnInit, OnDestroy {
  public isTransferInAction: Subscription;
  public connectedToIs = false;
  public tabs = [];
  public availableTaxFormId: string;
  public datasetId = '';

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    private accountingService: AccountingService,
    private datasetService: DatasetService,
    private integrationServerDatasource: IntegrationServerDatasource,
    private messageService: MessageService,
    private taxFormService: TaxFormService,
    private taxTransferService: TaxTransferService
  ) {}

  public ngOnInit(): void {
    const url = this.router.url;
    const splits = url.split('/');
    const datasetIndex = splits.findIndex((segment) =>
      segment.includes('datasets')
    );

    if (datasetIndex >= 0 && splits.length > datasetIndex + 1) {
      this.datasetId = splits[datasetIndex + 1];
    }

    this.buildTabs();
    this.integrationServerDatasource
      .getStatus()
      .pipe(first())
      .subscribe((res) => (this.connectedToIs = res.isConnected));

    this.loadSupportedTaxForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public onTaxTransfer() {
    const url = this.router.url;
    const splits = url.split('/');

    const taxFormId = splits[splits.length - 1];

    this.isTransferInAction = this.taxTransferService
      .transfer(taxFormId, this.datasetId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () =>
          this.messageService.success('Tax form was successfully exported!'),
        (err) => this.messageService.error(err)
      );
  }

  private loadSupportedTaxForm(): void {
    this.accountingService.currentFileId$
      .pipe(
        switchMap((currentFileId: string) => {
          if (!currentFileId) return of(null);
          else return this.taxFormService.getByFileId(currentFileId);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((taxForm: TaxForm) => {
        if (taxForm) {
          this.availableTaxFormId = taxForm.id;
          this.buildTabs();
        }
      });
  }

  private buildTabs(): void {
    this.datasetService
      .get$(this.datasetId)
      .pipe(
        tap((data) => {
          const datasetSourceTypeId = data.source.sourceTypeId;
          const isSourceTypeCOA =
            datasetSourceTypeId === SourceTypeId.ChartOfAccounts;

          const trialBalanceTab = {
            name: 'Trial Balance',
            link: './trial-balances',
            disabled: false,
          };
          const ledgerTab = {
            name: 'Ledger',
            link: './ledger',
            disabled: false,
          };
          const taxReportsTab = {
            name: 'Tax Reports',
            link: './tax-report',
            disabled: false,
          };
          const taxFormTab = {
            name: 'Tax Form',
            link: `./tax-forms/${this.availableTaxFormId}`,
            disabled: !this.availableTaxFormId,
          };

          this.tabs = [
            trialBalanceTab,
            ledgerTab,
            ...(isSourceTypeCOA ? [taxReportsTab] : []),
            taxFormTab,
          ];
        })
      )
      .subscribe();
  }
}
