export enum NumberFormat {
  Normal = 0,
  Percent = 1,
  FavourableUnfavourable = 2,
  FavourableUnfavourablePercent = 3
}

export class NumberFormatUtils {
  public static isPercent(numberFormat: NumberFormat) {
    return numberFormat === NumberFormat.Percent || numberFormat === NumberFormat.FavourableUnfavourablePercent;
  }
}
