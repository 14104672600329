import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Config } from './core/config';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  appInsights: ApplicationInsights;

  constructor() {
    const instrumentationKey: string = Config.appInsights.instrumentationKey;
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
      },
    });
    if (!instrumentationKey || instrumentationKey?.trim()?.length === 0) {
      return;
    }
    this.appInsights.loadAppInsights();
  }

  trackPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({
      name: document.title || name,
      uri: uri || window.location.href,
    });
  }

  startTrackPage(name?: string) {
    this.appInsights.startTrackPage(name);
  }

  stopTrackPage(name?: string, url?: string) {
    this.appInsights.stopTrackPage(name, url);
  }

  trackEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }
}
