import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientEntitySearchResult } from '../../clients/clientEntitySearchResult';

@Component({
  selector: 'crs-entity-search-result',
  templateUrl: './entity-search-result.component.html',
  styleUrls: ['./entity-search-result.component.scss']
})
export class EntitySearchResultComponent {

  @Input() entity: ClientEntitySearchResult;
  @Input() clearable = false;
  @Output() selected = new EventEmitter<ClientEntitySearchResult>();
  @Output() clear = new EventEmitter<boolean>();

  constructor() { }

}
