<div class="modal-header">
  <h4 class="modal-title">Add comment</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" [ngBusy]="[busy.load]">
  <div class="row">
    <div class="col-12">
      <div class="mt-0 ms-3 me-3">
        <crs-transaction-comment-history [comments]="comments$ | async">
        </crs-transaction-comment-history>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="mt-5 ms-3 me-3">
        <crs-transaction-comment-editor #editor>
        </crs-transaction-comment-editor>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer ms-3 me-3">
  <button type="button" class="btn btn-primary" (click)="submit()">
    Add comment
  </button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    Cancel
  </button>
</div>
