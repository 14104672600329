import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../core';
import { ReportPageType, ReportPageTypeGroup, ReportPageCategory } from './reportPageType';

@Injectable({
  providedIn: 'root'
})
export class ReportPageService {

  readonly base = 'api/accounting/reports/pages';

  constructor(private readonly _apiService: ApiService) { }



}

