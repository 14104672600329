<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form novalidate [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <h2 class="form-group-title">Client Group Details</h2>
    <hr />

    <div class="form-group">
      <label class="control-label">Code</label>
      <input class="form-control" formControlName="code" type="text" />
    </div>
    <div class="form-group required">
      <label class="control-label">Name</label>
      <input class="form-control" formControlName="name" type="text" />
    </div>

    <h2 class="form-group-title">Management</h2>
    <hr />

    <div class="form-group required">
      <label class="control-label">Practice</label>
      <crs-office-select
        formControlName="office"
        [clearable]="false"
      ></crs-office-select>
    </div>
    <div class="form-group">
      <label class="control-label">Team</label>
      <crs-team-select formControlName="team"></crs-team-select>
    </div>
    <div class="form-group">
      <label class="control-label">Partner</label>
      <crs-user-select
        formControlName="partner"
        [filterByPartner]="true"
      ></crs-user-select>
    </div>
    <div class="form-group">
      <label class="control-label">Manager</label>
      <crs-user-select
        formControlName="manager"
        [filterByManager]="true"
      ></crs-user-select>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-delete"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      [promiseBtn]="busy.delete"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
  </div>
</form>
