import { DateHelpers } from './../../../../shared/utilities/date-helpers';
import { SourceAccount } from '../..';

export enum DistributionFrequency {
  Annual = 1,
  Quarterly = 2,
  Monthly = 3,
}

export class DistributionRow {
  id: number;
  distributionSourceAccount: SourceAccount = new SourceAccount({});
  sourceAccount: SourceAccount = new SourceAccount({});
  memo: string;
  excludeIfLoss = true;

  private _balance = 0;
  get balance(): number {
    return this._balance;
  }
  set balance(value: number) {
    this._balance = this.convertToNumber(value, false);
  }

  private _percent = 0;
  get percent(): number {
    return this._percent;
  }
  set percent(value: number) {
    this._percent = this.convertToNumber(value, false);
  }

  constructor(data: any) {
    Object.assign(this, data);

    if (data?.distributionSourceAccount) {
      this.distributionSourceAccount = new SourceAccount(
        data.distributionSourceAccount
      );
    }

    if (data?.sourceAccount) {
      this.sourceAccount = new SourceAccount(data.sourceAccount);
    }
  }

  private convertToNumber(
    balance: number | string,
    round: boolean = true
  ): number {
    let number = 0;

    if (typeof balance === 'string') {
      number = parseFloat(balance);
    } else {
      number = balance;
    }

    if (isNaN(number) || number == null) {
      number = 0;
    }

    if (round) {
      return Number(Math.round(<any>(number + 'e2')) + 'e-2');
    }

    return number;
  }
}

export class DistributionRowToSave {
  id: number;
  distributionSourceAccount: SourceAccount = new SourceAccount({});
  sourceAccount: SourceAccount = new SourceAccount({});
  memo: string;
  excludeIfLoss: boolean;
  balance: number;
  percent: number;

  constructor(data: DistributionRow) {
    if (data.distributionSourceAccount) {
      this.distributionSourceAccount = new SourceAccount(
        data.distributionSourceAccount
      );
    }

    if (data.sourceAccount) {
      this.sourceAccount = new SourceAccount(data.sourceAccount);
    }

    this.id = data.id;
    this.memo = data.memo;
    this.excludeIfLoss = data.excludeIfLoss;
    this.balance = data.balance;
    this.percent = data.percent;
  }
}
