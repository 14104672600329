import { TaxCodeService } from './../../tax-codes/tax-code.service';
import { FileService } from './../../../files/file.service';
import { ModalService } from './../../../../core/modals/modal.service';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  forkJoin,
  Subject,
  EMPTY,
  Observable,
  of,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  tap,
  exhaustMap,
  catchError,
  finalize,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';

import { MessageService } from '../../../../core';
import { Journal, JournalLine, JournalType, JournalModel } from '../';
import { JournalCategory } from '../journalCategory';
import { JournalService } from '../journal.service';
import { JournalPackage } from '../journal-package';
import { JournalGrid } from './journal-grid';
import { SourceTypeId } from 'src/app/accounting/sourcedata';
import { DatasetService } from 'src/app/accounting/datasets';
import { generateUniqueId } from 'src/app/shared/utilities/unique-id-helper';

@Component({
  selector: 'crs-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss'],
})
export class JournalComponent implements OnInit, OnDestroy {
  @ViewChild('classificationTemplate', { static: true })
  classificationTemplate: ElementRef;
  @ViewChild('accountSearchTemplate', { static: true })
  accountSearchTemplate: ElementRef;
  @ViewChild('taxCodeSearchTemplate', { static: true })
  taxCodeSearchTemplate: ElementRef;
  @ViewChild('newAccountCell', { static: true })
  newAccountCell: ElementRef;
  @ViewChild('optionsCell', { static: true })
  optionsCell!: ElementRef;

  disableGstCheck: boolean = false;
  id: string;
  datasetId: string;
  fileId: string;
  isAdd: boolean;
  isRegisteredForGst: boolean = false;
  objectTitle = 'Journal';
  busy = {
    load: null,
    submit: null,
    undoBalancingJournal: null,
    delete: null,
  };

  form = this.formBuilder.group({
    journalNo: ['', [Validators.maxLength(256)]],
    date: ['', Validators.required],
    startDate: [''],
    memo: ['', Validators.maxLength(2048)],
    isExportable: [false],
    isGstEnabled: [false],
  });

  journal: Journal;
  outOfBalance: number;

  journalTypes = JournalType;
  journalCategories = JournalCategory;

  packageCache: { [id: string]: JournalPackage } = {};

  search = new UntypedFormControl();
  journalGrid: JournalGrid;

  get gridApi() {
    return this.journalGrid.gridApi;
  }

  error: string;

  submitStream = new Subject<void>();
  subscriptions: Subscription[] = [];

  canDrag = (params) => !params.node.group;
  rowDragText = (params) => 'Journal Row...';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private journalService: JournalService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private location: Location,
    private fileService: FileService,
    private datasetService: DatasetService,
    private taxCodeService: TaxCodeService
  ) {}

  ngOnInit() {
    this.getNewJournal();

    this.journalGrid = new JournalGrid(
      this.classificationTemplate,
      this.accountSearchTemplate,
      this.taxCodeSearchTemplate,
      this.updateOutOfBalance
    );

    this.defineColumnDefs();

    this.configureSearch();
    this.configureSubmitStream();

    this.route.params.subscribe(async () => {
      this.disableGstCheck = true;

      this.fileId =
        this.route.snapshot.parent.parent?.parent?.parent.paramMap.get('id');
      this.datasetId = this.route.snapshot.parent.parent.paramMap.get('id');
      this.id = this.route.snapshot.paramMap.get('id');
      this.isAdd = this.id === 'add';

      this.busy.load = forkJoin({
        gstRegistration: this.getIsEntityGstRegistered$(),
        data: this.getDataObservable$(),
      }).subscribe({
        next: ({ data }) => {
          if (this.isRegisteredForGst) {
            this.taxCodeService
              .getAll$(this.fileId)
              .subscribe((taxcodes) => (this.journalGrid.taxCodes = taxcodes));
          }

          this.setJournal(data.journal);
          this.journalGrid.accounts = data.package.sourceAccounts;
          this.journalGrid.divisions = data.package.divisions;

          if (this.isAdd) {
            this.form.get('date').patchValue(data.package.defaultDate);
            this.form
              .get('journalNo')
              .patchValue(data.package.defaultJournalNo);
            this.form.get('isGstEnabled').patchValue(this.isRegisteredForGst);
          }

          this.toggleGSTColumns(this.form.value.isGstEnabled);
        },
        error: (err) => this.showError(err),
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  defineColumnDefs() {
    this.journalGrid.gridOptions = {
      ...this.journalGrid.gridOptions,
      onGridReady: (event) => (this.journalGrid.gridApi = event.api),
      components: this.journalGrid.renderers,
      grandTotalRow: 'bottom',
      rowDragManaged: true,
      suppressHorizontalScroll: true,
      columnDefs: [
        {
          colId: 'drag',
          headerName: '',
          maxWidth: 30,
          minWidth: 30,
          rowDrag: this.canDrag,
          rowDragText: this.rowDragText,
          sortable: false,
          filter: false,
        },
        {
          colId: 'new',
          headerName: '',
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.newAccountCell },
          maxWidth: 45,
          minWidth: 45,
          sortable: false,
          filter: false,
        },
        {
          colId: 'accountNo',
          field: 'sourceAccount.accountNo',
          headerName: 'Account No',
          type: 'typeAheadColumn',
          cellEditorParams: this.journalGrid.accountNoSearchParams(),
          editable: true,
          maxWidth: 160,
          minWidth: 110,
          width: 110,
        },
        {
          colId: 'accountName',
          field: 'sourceAccount.accountName',
          headerName: 'Account Name',
          type: 'typeAheadColumn',
          cellEditorParams: this.journalGrid.accountNameSearchParams(),
          editable: true,
          maxWidth: 300,
          minWidth: 140,
          width: 200,
        },
        {
          cellRenderer: 'classificationRenderer',
          colId: 'classification',
          field: 'sourceAccount.classification',
          headerName: 'Class',
          tooltipField: 'sourceAccount.classificationToolTipText',
          type: 'typeAheadColumn',
          cellEditorParams: this.journalGrid.classificationEditorParams(),
          editable: this.isClassEditable.bind(this),
          maxWidth: 110,
          minWidth: 80,
        },
        {
          colId: 'debit',
          field: 'debit',
          headerName: 'Debit',
          type: 'dollarColumn',
          cellClass: ['dollar-cell', 'ag-numeric-cell', 'highlight-column'],
          editable: true,
          onCellValueChanged: (params) =>
            this.journalGrid.checkIfGstAndCalculateTaxAmount(params.data),
          // this.journalGrid.checkIfGstAndCalculateTaxAmount.bind(
          //   this.journalGrid
          // ),
        },
        {
          colId: 'credit',
          field: 'credit',
          headerName: 'Credit',
          type: 'dollarColumn',
          cellClass: ['dollar-cell', 'ag-numeric-cell', 'highlight-column'],
          editable: true,
          onCellValueChanged: (params) =>
            this.journalGrid.checkIfGstAndCalculateTaxAmount(params.data),
        },
        {
          colId: 'quantity',
          field: 'quantity',
          headerName: 'Quantity',
          type: 'numberColumn',
          editable: true,
          minWidth: 100,
          width: 120,
          valueFormatter: this.numberFormatter,
        },
        {
          cellEditor: 'agRichSelectCellEditor',
          cellRenderer: 'divisionRenderer',
          colId: 'division',
          field: 'division',
          headerName: 'Division',
          cellEditorParams: this.journalGrid.divisionEditorParams(),
          editable: true,
          minWidth: 80,
          width: 120,
        },
        {
          colId: 'taxCode',
          field: 'taxCode',
          headerName: 'Tax Code',
          type: 'typeAheadColumn',
          cellEditorParams: this.journalGrid.taxCodeSearchParams(),
          editable: true,
          minWidth: 100,
          onCellValueChanged: (params) =>
            this.journalGrid.checkIfGstAndCalculateTaxAmount(params.data),
          width: 250,
        },
        {
          colId: 'taxAmount',
          field: 'taxAmount',
          headerName: 'Tax Amount',
          type: 'dollarColumn',
          editable: true,
          minWidth: 100,
          valueGetter: this.getAbsoluteValue.bind(this),
          valueSetter: this.taxAmountSetter.bind(this),
          width: 250,
        },
        {
          colId: 'memo',
          field: 'memo',
          headerName: 'Memo',
          type: 'commentsColumn',
          editable: true,
          minWidth: 100,
          width: 250,
        },
        {
          colId: 'delete',
          headerName: '',
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCell },
          maxWidth: 40,
          sortable: false,
          filter: false,
        },
      ],
    };
  }

  numberFormatter(params): string {
    return params.value !== undefined && params.value !== null
      ? Number(params.value).toFixed(2)
      : '';
  }

  private configureSearch() {
    const search$ = this.search.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((search) => {
        if (this.gridApi) {
          this.gridApi.setGridOption('quickFilterText', search);
        }
      });
    this.subscriptions.push(search$);
  }

  private configureSubmitStream() {
    const submit$ = this.submitStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submitObservable()),
        catchError((e) => {
          this.showError(e);
          return EMPTY;
        })
      )
      .subscribe(() => this.close());
    this.subscriptions.push(submit$);
  }

  submit() {
    this.submitStream.next();
  }

  private submitObservable() {
    if (this.journal.isReadonly) {
      this.showError('This journal is readonly');
      return EMPTY;
    }

    const journal = <Journal>this.form.value;
    journal.journalLines = this.parseJournalLines(this.journal.journalLines);

    if (this.journal.journalLines.findIndex((x) => x.isValidAccName) !== -1) {
      this.showError('Account Name must be supplied.');
      return EMPTY;
    }

    const journalModel = new JournalModel(journal);
    let observable: Observable<any> = null;

    if (this.isAdd) {
      journalModel.datasetId = this.datasetId;
      journalModel.journalType = JournalType.Journal;
      observable = this.journalService.post$(journalModel);
    } else {
      journalModel.id = this.id;
      observable = this.journalService.put$(journalModel);
    }

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  removeGstFromJournalLine(journalLine: JournalLine) {
    journalLine.taxCode = null;
    journalLine.basCode = null;
    journalLine.gstLineId = null;
  }

  parseJournalLines(journalLines: JournalLine[]) {
    const parsedJournalLines: JournalLine[] = [];
    journalLines.forEach((journalLine) => {
      if (!this.form.value.isGstEnabled) {
        this.removeGstFromJournalLine(journalLine);
        return parsedJournalLines.push(journalLine);
      }

      if (journalLine.taxCode) {
        const {
          sourceAccount,
          balanceMinusTax,
          taxCode,
          taxAmount = 0,
          gstLineId,
          gstParentId,
          quantity = 0,
          ...rest
        } = journalLine;

        const taxPostingAccount = this.journalGrid.taxCodes?.find(
          ({ taxCode }) => taxCode === journalLine.taxCode
        );

        const taxPostingAccountNo = taxPostingAccount?.postingAccountNo;
        const basCode = taxPostingAccount?.basCode;

        // Set the tax line source account as the account with the same accountNo as the tax posting account No.
        // Otherwise fall back to the Journal line source account when no taxPostingAccountNo exists.
        const taxSourceAccount =
          this.journalGrid.accounts.find(
            (account) => account.accountNo === taxPostingAccountNo
          ) || sourceAccount;

        const balanceGstLineId = gstLineId || generateUniqueId();

        const balanceLine = {
          ...rest,
          sourceAccount,
          balance: balanceMinusTax || journalLine.balance - taxAmount,
          quantity,
          gstLineId: taxAmount ? balanceGstLineId : null,
          taxCode,
          basCode,
        } as JournalLine;

        const taxLine = {
          sourceAccount: taxSourceAccount,
          balance: taxAmount,
          gstParentId: balanceGstLineId,
          taxCode,
          basCode,
        } as JournalLine;

        parsedJournalLines.push(balanceLine);
        parsedJournalLines.push(taxLine);
        return;
      }

      this.removeGstFromJournalLine(journalLine);
      return parsedJournalLines.push(journalLine);
    });

    return parsedJournalLines;
  }

  private getIsEntityGstRegistered$(): Observable<any> {
    return combineLatest([
      this.datasetService.get$(this.datasetId),
      this.fileService.get$(this.fileId),
    ]).pipe(
      tap(([dataset, entityData]) => {
        this.isRegisteredForGst =
          dataset?.source.sourceTypeId === SourceTypeId.ChartOfAccounts &&
          entityData.entity.isRegisteredForGst;
      })
    );
  }

  private getDataObservable$(): Observable<any> {
    const packageCache = this.isAdd ? null : this.packageCache[this.datasetId];
    return forkJoin({
      journal: this.isAdd
        ? of(this.getNewJournal())
        : this.journalService.get$(this.id, true),
      package: packageCache
        ? of(packageCache)
        : this.journalService.getPackage$(this.datasetId, this.isAdd),
    });
  }

  private getNewJournal() {
    this.journal = new Journal({
      journalType: JournalType.Journal,
      journalLines: [],
      isExportable: true,
    });
    return this.journal;
  }

  private setJournal(journal: Journal) {
    this.form.patchValue(<any>journal);
    this.journal = journal;

    if (this.gridApi) {
      this.gridApi.setGridOption('rowData', this.journal.journalLines);
    }
    this.padRows(5);
    this.updateOutOfBalance();
  }

  delete() {
    this.modalService.confirmation(
      'This action cannot be undone. Are you sure you want to delete this journal?',
      () =>
        (this.busy.delete = this.journalService.delete$(this.id).subscribe({
          next: () => this.close(),
          error: (e) => this.showError(e),
        })),
      true
    );
  }

  undoBalancingJournal() {
    if (this.journal.journalType !== JournalType.BalancingJournal) {
      this.showError(
        'This journal is not a balancing journal, unable to undo.'
      );
    }
    this.busy.undoBalancingJournal = this.journalService
      .undoBalancingJournal$(this.id)
      .subscribe({
        next: () => {
          this.messageService.success(
            'Successfully deleted balancing journal.'
          );
          this.close();
        },
        error: (err) => this.showError(err),
      });
  }

  copyAsNewJournal() {
    this.id = 'add';
    this.isAdd = true;
    this.journal.journalType = JournalType.Journal;
    this.journal.isExportable = true;
    this.journal.journalCategory = null;
    const journalNoControl = this.form.get('journalNo');
    const journalNo = journalNoControl.value;
    if (journalNo) journalNoControl.setValue(journalNo + '-Copy');
    const addUrl = this.router
      .createUrlTree(['../add'], { relativeTo: this.route })
      .toString();
    this.location.go(addUrl);
  }

  switchDebitCredit() {
    this.journal.journalLines.forEach((l) => {
      if (l.balance) l.balance = -l.balance;
    });
    this.gridApi.setGridOption('rowData', this.journal.journalLines);
  }

  private showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  close() {
    this.router.navigate(['../../journals'], { relativeTo: this.route });
  }

  handleGstCheckboxChange(event) {
    this.form.get('isGstEnabled').patchValue(!!event?.target?.checked);
    this.toggleGSTColumns(this.form.value.isGstEnabled);
  }

  toggleGSTColumns(showGstColumns: boolean): void {
    const currentColumnDefs = this.journalGrid.gridOptions.columnDefs;

    for (let colDef of currentColumnDefs) {
      if (
        'colId' in colDef &&
        (colDef.colId === 'taxCode' || colDef.colId === 'taxAmount')
      ) {
        (colDef as any).hide = !showGstColumns;
      }
    }

    // this.journalGrid.gridApi.setColumnDefs(currentColumnDefs);
    this.gridApi.setGridOption('columnDefs', currentColumnDefs);
    this.journalGrid.isGstEnabled = showGstColumns;

    if (
      !this.disableGstCheck &&
      this.journalGrid.taxCodes.length &&
      this.journalGrid.isGstEnabled
    ) {
      this.journal.journalLines.forEach((journalLine) => {
        journalLine.taxCode =
          journalLine.taxCode || journalLine.sourceAccount.taxCode;

        return this.journalGrid.checkIfGstAndCalculateTaxAmount(journalLine);
      });
    }

    this.disableGstCheck = false;
  }

  // ------- Grid Events ------
  onCellDoubleClicked(event) {
    if (event.column.colId === 'debit' || event.column.colId === 'credit') {
      // Automate setting of
      const array = this.journal.journalLines;
      let debit = 0;
      let credit = 0;
      const length = array.length;
      for (let i = 0; i < length; i++) {
        if (array[i] === event.node.data) continue;
        debit += array[i].debit;
        credit += array[i].credit;
      }
      if (debit - credit !== 0) {
        this.gridApi.stopEditing(true);
        if (event.column.colId === 'debit' && credit > debit)
          event.node.data.debit = credit - debit;
        if (event.column.colId === 'credit' && debit > credit)
          event.node.data.credit = debit - credit;
        this.gridApi.applyTransaction({
          update: [event.node.data],
        });
        this.updateOutOfBalance();
      }
    }
  }

  // ------ Journal Line Functions -----

  padRows(number: number) {
    const current = this.journal.journalLines.length;
    if (current < number) {
      this.addRows(number - current);
    }
  }

  addRows(number: number) {
    const newLines = new Array<JournalLine>();
    for (let i = 1; i <= number; i++) {
      newLines.push(new JournalLine(null));
    }
    Array.prototype.push.apply(this.journal.journalLines, newLines);
    if (this.gridApi) {
      this.gridApi.applyTransaction({
        add: newLines,
      });
    }
  }

  removeJournalLine(line) {
    this.gridApi.applyTransaction({ remove: [line] });
    const index = this.journal.journalLines.indexOf(line);
    this.journal.journalLines.splice(index, 1);
    this.updateOutOfBalance();
  }

  shouldDisplayTaxColumn() {
    if (!this.isRegisteredForGst || !this.form.value.isGstEnabled) {
      return false;
    }
    return true;
  }

  checkIfGstAndCalculateTaxAmount = (rowData) => {
    this.journalGrid.checkIfGstAndCalculateTaxAmount(rowData.data);
  };

  getAbsoluteValue(params) {
    return params.data?.taxAmount ? Number(Math.abs(params.data.taxAmount)) : 0;
  }

  taxAmountSetter = (params) => {
    const numValue = parseFloat(params.newValue);

    // NaN check
    if (isNaN(numValue)) {
      return false; // Reject the change
    }
    const balance = params.data['balance'];
    const isCredit = balance < 0;
    const taxAmount = isCredit ? -Math.abs(numValue) : numValue;

    if (
      (isCredit && taxAmount < balance) ||
      (!isCredit && taxAmount > balance)
    ) {
      this.messageService.error(
        'The tax amount cannot be larger than the balance'
      );
      return false;
    }

    params.data['taxAmount'] = taxAmount;

    const balanceMinusTax = balance - taxAmount;
    params.data['balanceMinusTax'] = balanceMinusTax;

    return true;
  };

  isClassEditable(params) {
    return (
      !params?.data?.sourceAccount?.classification ||
      !params?.data?.sourceAccount?.id
    );
  }

  private updateOutOfBalance() {
    const array = this.journal?.journalLines;
    let debit = 0;
    let credit = 0;

    const length = array?.length;
    for (let i = 0; i < length; i++) {
      debit += array[i].debit;
      credit += array[i].credit;
    }

    this.outOfBalance = Math.abs(debit - credit);
  }
}
