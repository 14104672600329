import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { getDefaultGridOptions } from 'src/app/shared';
import { ReportStyleService } from '../report-style.service';

@Component({
  selector: 'crs-report-styles',
  templateUrl: './report-styles.component.html',
  styleUrls: ['./report-styles.component.scss']
})
export class ReportStylesComponent implements OnInit {

  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    reportStyles: false
  };
  reportStylesObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();

  constructor(private readonly _reportStyleService: ReportStyleService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router) { }

  ngOnInit() {
    this.getReportStyles();
  }

  getReportStyles() {
    this.busy.reportStyles = true;
    this.reportStylesObservable = this._reportStyleService.getAll().pipe(tap(() => this.busy.reportStyles = false), catchError(err => {
      this.busy.reportStyles = false;
      this.showError(err);
      return of([]);
    }));
  }

  addReportStyle() {
    this._router.navigate(['../styles/add'], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
  }

  selectReportStyle(param) {
    this._router.navigate(['../styles/' + param.data.id], { relativeTo: this._route });
  }

}
