<div class="center">
  <div class="spacer-md"></div>
  <div class="spacer-md"></div>

  <p><strong>Chart of accounts not yet activated</strong></p>

  <button class="btn btn-primary" (click)="selectCOA()">
    Activate chart of accounts
  </button>
</div>
