<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.depreciationYears"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-x">
      <button
        class="btn btn-secondary"
        title="Create Division"
        type="button"
        (click)="addDepreciationYear()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Depreciation Year
      </button>
    </div>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        [gridOptions]="gridOptions"
        [rowData]="context?.yearsDescending"
      />
    </div>
  </div>
</div>
