import {
  Classification,
  CurrentClassification,
} from '../classifications/classification';
import { AccountType } from '../account-types';
import { AccountTypeMode } from './accountTypeMode';
import { AutoMatchMode } from './autoMatchMode';
import { IAccountsViewAccount } from './accounts/accounts-view-account';

export enum SystemAccount {
  Assets = 100,

  Liabilities = 200,

  Equity = 300,

  RetainedEarnings = 330,
  OpeningBalanceRetainedEarnings = 331,
  CurrentEarnings = 332,
  Distributions = 333,
  TransfersToOrFromReserves = 334,
  AdjustmentsToRetainedEarnings = 335,
  IncomeTax = 760,

  Unallocated = 900,
  UnallocatedAssets = 910,
  UnallocatedLiabilities = 920,
  UnallocatedEquity = 930,
  UnallocatedRevenue = 940,
  UnallocatedCostOfSales = 950,
  UnallocatedExpenses = 960,
  UnallocatedOther = 970,
}

export interface IAccount {
  id: string;
  parentId: string;
  accountNo: string;
  accountName: string;
  sourceClassification: Classification;
  accountType: AccountType;
  accountTypeMode: AccountTypeMode;
  autoMatchMode: AutoMatchMode;
  isHeader: boolean;
  systemAccount: SystemAccount;
  isSortable: boolean;
  isReassignable: boolean;
  hierarchy: string[];
  displayName: string;
}

export class Account implements IAccount, IAccountsViewAccount {
  id: string;
  masterAccountId: number;
  parentId: string;
  parent: Account;
  accountNo: string;
  accountName: string;
  classification: Classification;
  sourceClassification: Classification;
  classificationLabel: string;
  accountType: AccountType;
  accountTypeId: number;
  accountTypeMode: AccountTypeMode;
  autoMatchMode: AutoMatchMode;
  isHeader: boolean;
  systemAccount: number;
  isSortable: boolean;
  isReassignable: boolean;
  isConsolidated: boolean;
  hierarchy: string[] = [];
  sourceAccounts: any[];
  children: Account[];
  openingBalanceAccountId: string;
  level?: number;
  get displayName(): string {
    if (this.accountNo) return this.accountNo + ' ' + this.accountName;
    else return this.accountName;
  }

  isParentOf(account: IAccountsViewAccount) {
    const position = this.hierarchy.length - 1;
    return (
      account.hierarchy.length > position + 1 &&
      account.hierarchy[position] === this.id
    );
  }

  isChildOf(account: IAccountsViewAccount) {
    const position = account.hierarchy.length - 1;
    return (
      this.hierarchy.length > position + 1 &&
      this.hierarchy[position] === account.id
    );
  }

  shallowCopy(): Account {
    return new Account(this);
  }

  constructor(data) {
    Object.assign(this, data);
    if (data.masterAccountId) {
      this.masterAccountId = parseInt(data.masterAccountId, 10);
    }
    if (data.level) {
      this.level = parseInt(data.level, 10);
    }
    if (data.parent) {
      this.parent = new Account(data.parent);
    }
    if (data.classification) {
      if (data.currentClassification) {
        this.classificationLabel =
          CurrentClassification[data.currentClassification] +
          ' ' +
          Classification[data.classification];
      } else {
        this.classificationLabel = Classification[data.classification];
      }
    }
    if (data.hierarchy) this.hierarchy = [...data.hierarchy];
  }
}
