import { SourceService, SourceTypeId } from 'src/app/accounting';
import { EntityComponent } from './../firm/entities/entity/entity.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { ModalService, MessageService, SessionService } from '../core';
import { FileService, File } from './';
import { ActiveFileService } from './active-file.service';
import { FileDeleteComponent } from './files/file-delete/file-delete.component';
import { Subscription, of, EMPTY, Subject } from 'rxjs';
import { FileComponent } from './files/file/file.component';
import { tap, catchError, takeUntil, switchMap, filter } from 'rxjs/operators';
import { IRouteLink } from '../shared/interfaces';
import { AccountingService } from './accounting.service';
import { DocumentIntegrationService } from '../configuration/document-integration/document-integration.datasource';

@Component({
  selector: 'crs-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss'],
  providers: [DocumentIntegrationService],
})
export class AccountingComponent implements OnInit, OnDestroy {
  fileId: string;
  file: File = null;
  activeFileSubscription: Subscription;
  isConsolidated: boolean;
  currentRoute: string;
  nonStandardRoute: boolean;
  showTaxCodes: boolean = false;

  public routes: IRouteLink[] = [];

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly accountingService: AccountingService,
    private fileService: FileService,
    private activeFileService: ActiveFileService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly _modalService: ModalService,
    private readonly _messageService: MessageService,
    private readonly _documentIntegrationService: DocumentIntegrationService,
    private sessionService: SessionService,
    private sourceService: SourceService
  ) {}

  ngOnInit() {
    this._buildRouteLinks();

    this.route.url.subscribe(() => this.refreshRoute());

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.refreshRoute();
      });

    this.activeFileSubscription = this.activeFileService.stream
      .pipe(
        tap((file) => {
          this.fileId = file.id;
          this.file = file;
          this.isConsolidated = file.isConsolidated;
        }),
        switchMap(() => this.sourceService.sourcesChanged$),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.checkIfTaxCodesAreAvailable();
      });
  }

  ngOnDestroy() {
    this.activeFileSubscription.unsubscribe();

    this._destroy$.next(true);
    this._destroy$.complete();
  }

  refreshRoute() {
    if (this.route.children && this.route.children.length > 0) {
      this.currentRoute = this.route.children[0].routeConfig.path;
      const slashIndex = this.currentRoute.indexOf('/');
      if (slashIndex >= 1)
        this.currentRoute = this.currentRoute.substr(0, slashIndex);
    } else {
      this.currentRoute = null;
    }
    this.nonStandardRoute =
      this.routes.every((r) => r.route !== this.currentRoute) &&
      this.currentRoute !== null;

    this.route.params
      .pipe(
        switchMap((params) => {
          const paramFileId = params['id'];
          if (!!paramFileId) return of(paramFileId);
          else {
            const entityId = params['entityId'];
            this.accountingService.currentEntityId$.next(entityId);
            return this.fileService.getDefaultFileId$(entityId);
          }
        }),
        catchError((err) => {
          this._messageService.error(err);
          return EMPTY;
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((fileId) => {
        this.accountingService.currentFileId$.next(fileId);
        if (this.fileId !== fileId) {
          this.loadFile(fileId);
        }
      });
  }

  private loadFile(id: string) {
    return this.fileService
      .get$(id)
      .pipe(
        tap((file) => this.activeFileService.updatefile(file, true)),
        catchError((err) => {
          this._messageService.error(err);
          return EMPTY;
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  editFile() {
    this._modalService
      .openModal(FileComponent, this.file.id, this.file)
      .then(() => this.loadFile(this.file.id))
      .catch(() => true);
  }

  editEntity() {
    this._modalService
      .openModal(EntityComponent, this.file.entity.id)
      .then(() => this.loadFile(this.file.id))
      .catch(() => true);
  }

  delete() {
    const clientId = this.isConsolidated
      ? this.file.clientId
      : this.file.entity.clientId;
    this._modalService
      .openModal(FileDeleteComponent)
      .then(() => {
        this.router.navigate(['/clients', clientId]);
        this.activeFileService.updatefile(null, true);
      })
      .catch(() => true);
  }

  checkIfTaxCodesAreAvailable() {
    this.sourceService.getAll$(this.fileId).subscribe((sources) => {
      this.showTaxCodes = sources.some(
        (item) => item.sourceTypeId === SourceTypeId.ChartOfAccounts
      );
    });
  }

  private _buildRouteLinks() {
    const storageKey = 'opened-entity-count';
    const count = +localStorage.getItem(storageKey) + 1;

    const isLedgerAndBankFeedFeaturesEnabled =
      this.sessionService.featureFlags.ledger &&
      this.sessionService.featureFlags.bankFeed;
    const bankFeedRoute = {
      name: 'Bank Feeds',
      route: 'feeds',
    };

    this.routes = [
      { name: 'Datasets', route: 'datasets' },
      { name: 'Accounts', route: 'accounts' },
      ...(isLedgerAndBankFeedFeaturesEnabled ? [bankFeedRoute] : []),
      { name: 'Reports', route: 'reports' },
      { name: 'Assets', route: 'assets' },
    ];

    this._documentIntegrationService
      .eSignatureDefined$()
      .pipe(takeUntil(this._destroy$))
      .subscribe((isDefined: boolean) => {
        if (isDefined) {
          const newItem = {
            name: 'E-Signature',
            route: 'e-signature',
            badge: count < 5 ? { name: 'NEW', classes: 'bg-primary' } : null,
          };
          this.routes.push(newItem);
        }
      });
    localStorage.setItem(storageKey, count.toString());
  }
}
