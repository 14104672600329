import { ColumnType } from "./reportTemplateColumn";

export class ColumnTypeUtils {
  public static isNumeric(columnType: ColumnType): boolean {
    const numericTypes = [
      ColumnType.Number,
      ColumnType.AutoTotal,
    ];
    return numericTypes.includes(columnType);
  }
}
