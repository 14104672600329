<div class="row">
  <div class="col-12">
    <form
      novalidate
      [formGroup]="form"
      [ngBusy]="[busy.load]"
      (validSubmit)="onValidSubmit()"
    >
      <!-- Grid -->
      <div>
        <ag-grid-angular
          #agGrid
          class="ag-theme-material"
          [enableRangeSelection]="true"
          [frameworkComponents]="distributionGrid.renderers"
          [gridOptions]="distributionGrid.gridOptions"
          [groupIncludeTotalFooter]="true"
          [rowData]="distributionRows"
          [rowDragManaged]="true"
          [suppressCellSelection]="false"
          [suppressHorizontalScroll]="true"
          (cellEditingStarted)="distributionGrid.cellEditingStarted($event)"
          (cellValueChanged)="distributionGrid.onValueChanged($event)"
        >
          <ag-grid-column
            colId="drag"
            headerName=""
            [maxWidth]="30"
            [minWidth]="30"
            [rowDrag]="canDrag"
            [rowDragText]="rowDragText"
          />

          <ag-grid-column
            headerName="Dividend/Distribution accounts"
            [headerClass]="['centered', 'distribution-header-group']"
            [marryChildren]="true"
          >
            <ag-grid-column
              colId="new"
              headerName=""
              pinned="none"
              type="optionsColumn"
              [cellRendererParams]="{ ngTemplate: newDistributionAccountCell }"
              [cellStyle]="{ 'border-left': '1px solid #F2F5F6' }"
              [headerClass]="['border-left-cell']"
              [maxWidth]="45"
              [minWidth]="45"
            />
            <ag-grid-column
              colId="distributionAccountNo"
              field="distributionSourceAccount.accountNo"
              headerName="Account No"
              type="typeAheadColumn"
              [cellEditorParams]="
                distributionGrid.distributionAccountNoSearchParams
              "
              [editable]="true"
              [maxWidth]="160"
              [minWidth]="110"
              [width]="110"
            />
            <ag-grid-column
              colId="distributionAccountName"
              field="distributionSourceAccount.accountName"
              headerName="Account Name"
              type="typeAheadColumn"
              [cellEditorParams]="
                distributionGrid.distributionAccountNameSearchParams
              "
              [editable]="true"
              [maxWidth]="300"
              [minWidth]="140"
              [width]="200"
            />
            <ag-grid-column
              cellRenderer="classificationRenderer"
              colId="distributionClassification"
              field="distributionSourceAccount.classification"
              headerName="Class"
              tooltipField="distributionSourceAccount.classificationToolTipText"
              type="typeAheadColumn"
              [cellEditorParams]="distributionGrid.classificationEditorParams"
              [cellStyle]="{ 'border-right': '1px solid #F2F5F6' }"
              [editable]="true"
              [headerClass]="['border-right-cell']"
              [maxWidth]="150"
              [minWidth]="120"
            />
          </ag-grid-column>

          <ag-grid-column
            headerName="Payable/Liability accounts"
            [headerClass]="['centered', 'distribution-header-group']"
            [marryChildren]="true"
          >
            <ag-grid-column
              colId="new"
              headerName=""
              pinned="none"
              type="optionsColumn"
              [cellRendererParams]="{ ngTemplate: newAccountCell }"
              [cellStyle]="{ 'border-left': '1px solid #F2F5F6' }"
              [headerClass]="['border-left-cell']"
              [maxWidth]="45"
              [minWidth]="45"
            />
            <ag-grid-column
              colId="accountNo"
              field="sourceAccount.accountNo"
              headerName="Account No"
              type="typeAheadColumn"
              [cellEditorParams]="distributionGrid.accountNoSearchParams"
              [editable]="true"
              [maxWidth]="160"
              [minWidth]="110"
              [width]="110"
            />
            <ag-grid-column
              colId="accountName"
              field="sourceAccount.accountName"
              headerName="Account Name"
              type="typeAheadColumn"
              [cellEditorParams]="distributionGrid.accountNameSearchParams"
              [editable]="true"
              [maxWidth]="300"
              [minWidth]="140"
              [width]="200"
            />
            <ag-grid-column
              cellRenderer="classificationRenderer"
              colId="classification"
              field="sourceAccount.classification"
              headerName="Class"
              tooltipField="sourceAccount.classificationToolTipText"
              type="typeAheadColumn"
              [cellEditorParams]="distributionGrid.classificationEditorParams"
              [cellStyle]="{ 'border-right': '1px solid #F2F5F6' }"
              [editable]="true"
              [headerClass]="['border-right-cell']"
              [maxWidth]="150"
              [minWidth]="120"
            />
          </ag-grid-column>
          <ag-grid-column
            field="memo"
            headerName="Memo"
            type="commentsColumn"
            [editable]="true"
          />
          <ag-grid-column
            colId="balance"
            field="balance"
            headerName="Balance"
            type="dollarColumn"
            [cellClass]="['dollar-cell', 'ag-numeric-cell', 'highlight-column']"
            [editable]="true"
          />
          <ag-grid-column
            aggFunc="sum"
            colId="percent"
            field="percent"
            headerName="Percent"
            type="percentColumn"
            [cellClass]="[
              'percent-cell',
              'ag-numeric-cell',
              'highlight-column'
            ]"
            [editable]="true"
          />
          <ag-grid-column
            headerName="Losses*"
            pinned="none"
            type="optionsColumn"
            [cellRendererParams]="{ ngTemplate: lossesCheckbox }"
            [headerClass]="['centered']"
            [maxWidth]="120"
            [minWidth]="100"
            [width]="120"
          />
          <ag-grid-column
            colId="delete"
            headerName=""
            pinned="none"
            type="optionsColumn"
            [cellRendererParams]="{ ngTemplate: optionsCell }"
            [headerClass]="['centered']"
            [maxWidth]="40"
          />
        </ag-grid-angular>
      </div>

      <button
        class="btn btn-secondary mt-1"
        type="button"
        (click)="addRows(DEFAULT_NUMBER_OF_ROWS)"
      >
        Add Lines
      </button>

      <small class="form-text text-muted float-right">
        *Will distribute even if there is a loss
      </small>

      <!-- New Distribution Account Cell -->
      <ng-template #newDistributionAccountCell let-row>
        <a
          ngbPopover="This account number and name combination has not been seen before. If you proceed, this account will be created by Access Ledger."
          placement="right"
          popoverTitle="New Account"
          *ngIf="
            row?.distributionSourceAccount &&
            !row.distributionSourceAccount.id &&
            row.distributionSourceAccount.accountName
          "
          [routerLink]="[]"
        >
          <span class="badge badge-danger">NEW</span>
        </a>
      </ng-template>

      <!-- New Account Cell -->
      <ng-template #newAccountCell let-row>
        <a
          ngbPopover="This account number and name combination has not been seen before. If you proceed, this account will be created by Access Ledger."
          placement="right"
          popoverTitle="New Account"
          *ngIf="
            row?.sourceAccount &&
            !row.sourceAccount.id &&
            row.sourceAccount.accountName
          "
          [routerLink]="[]"
        >
          <span class="badge badge-danger">NEW</span>
        </a>
      </ng-template>

      <!-- Account Search Result-->
      <ng-template #accountSearchTemplate let-result="result" let-term="term">
        <ngb-highlight
          [result]="
            (result.accountNo ? result.accountNo + '  ' : '') +
            result.accountName
          "
          [term]="term"
        />
      </ng-template>

      <!-- Classification Result-->
      <ng-template #classificationTemplate let-result="result" let-term="term">
        <ngb-highlight
          [result]="distributionGrid.classifications[result]"
          [term]="term"
        />
      </ng-template>

      <!-- Losses Tick Box -->
      <ng-template #lossesCheckbox let-row>
        <div class="btn-mini-menu" *ngIf="row">
          <input
            type="checkbox"
            [checked]="!row.excludeIfLoss"
            (click)="onClickToggleLoss(row)"
          />
        </div>
      </ng-template>

      <!-- Delete Column-->
      <ng-template #optionsCell let-row>
        <div class="btn-mini-menu" *ngIf="row">
          <button
            class="btn-sm fas-btn-ghost"
            title="Delete Row"
            type="button"
            (click)="onClickDeleteRow(row)"
          >
            <i aria-hidden="true" class="fas fa-trash"></i>
          </button>
        </div>
      </ng-template>

      <div class="spacer-md"></div>

      <crs-form-error [error]="error" />

      <div class="btn-menu">
        <button
          class="btn btn-primary"
          type="submit"
          [promiseBtn]="busy.submit"
        >
          Save
        </button>
        <button
          class="btn btn-delete"
          title="Clear All"
          type="button"
          [promiseBtn]="busy.delete"
          (click)="onClickClearAll()"
        >
          <i aria-hidden="true" class="fas fa-trash"></i>
          Clear All
        </button>
      </div>
    </form>
  </div>
</div>
