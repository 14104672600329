import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { BACO_DATE_FORMAT } from 'src/app/baco/baco.tokens';
import { BacoUtils } from 'src/app/baco/baco.utils';
import {
  ITransactionLineParameters,
  BacoLoadableTransactionDto,
  BacoTaxOptionDto,
  BacoAccountDto,
  AllocationType,
  BacoRuleAllocation,
  BacoRuleDto,
  StringConditionOption,
} from 'src/app/baco/interfaces';
import { TransactionService } from 'src/app/baco/services';
import { BacoFeedStore } from '../../baco-feed.store';
import { Injector } from '@angular/core';
import { RuleComponent } from '../../rules';
import {
  EnhancedConfirmation,
  MessageService,
  ModalService,
} from '../../../../../core';
import { RuleMovementType } from '../../../../enums';

@Component({
  selector: 'crs-split-transaction',
  templateUrl: './split-transaction.component.html',
  styleUrls: ['./split-transaction.component.scss'],
})
export class SplitTransactionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() params: {
    transaction: BacoLoadableTransactionDto;
    autoDelete?: boolean;
  };

  public transaction: BacoLoadableTransactionDto;

  public form = new FormGroup({
    transactionDate: new FormControl<string>(''),
    amount: new FormControl<number>(0),
    type: new FormControl<string>(''),
    description: new FormControl<string>(''),
    splittedTransactions: new FormArray<FormGroup<SplitTransactionFormRow>>(
      [],
      [this.amountSumValidator()]
    ),
  });

  public get splittedTransactions(): FormArray<
    FormGroup<SplitTransactionFormRow>
  > {
    return this.form?.controls?.splittedTransactions;
  }

  @ViewChildren('amountInput', { read: ElementRef })
  theInputs: QueryList<ElementRef>;
  public submitted = false;

  public unallocatedAmount = 0;
  public totalTaxAmount: number = 0;

  private _destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly _transactionService: TransactionService,
    private readonly _activeModal: NgbActiveModal,
    private readonly _feedStore: BacoFeedStore,
    private readonly _datePipe: DatePipe,
    private readonly injector: Injector,
    private readonly _modalService: ModalService,
    private readonly _messageService: MessageService,
    @Inject(BACO_DATE_FORMAT) private dateFormat: string
  ) {}

  public ngOnInit(): void {
    this.transaction = this.params.transaction;
    this.form.patchValue({
      transactionDate: this._datePipe.transform(
        this.transaction.transactionLocalDate,
        this.dateFormat
      ),
      amount: this.transaction.amount,
      type: this.transaction.type,
      description: this.transaction.description,
      splittedTransactions: [],
    });

    this.form.disable();

    this._transactionService.setDefaultTransactionIfEmpty(this.transaction);
    const lineParameters = this._transactionService.toRequest(this.transaction);

    lineParameters.forEach((value) => this.addNewLine(value));

    if (this.params.autoDelete) {
      this.onDeleteSplitCoding();
    }
  }

  public ngAfterViewInit(): void {
    this.updateTotalTaxAmount();
  }

  public ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  public submit(): void {
    if (!this.form.dirty) {
      this._messageService.info('No changes detected to save');
      return;
    }
    this.submitted = true;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const params: ITransactionLineParameters[] =
      this.splittedTransactions.value.map((val) => {
        return {
          amount: val.amount ?? 0,
          taxAmount: val.taxAmount ?? 0,
          quantity: val.quantity,
          bacoAccountId: val.bacoAccountId,
          bacoTaxOptionId: val.bacoTaxOptionId,
          description: val.description,
        };
      });

    this._transactionService.splitTransaction(this.transaction, params);
    this._activeModal.close();
  }

  public consolidateTransaction(
    item: Partial<{
      amount: number;
      percentage: number;
      quantity: number;
      bacoAccountId: string;
      bacoTaxOptionId: string;
      taxAmount: number;
      description: string;
    }>
  ) {
    const scale = item.percentage !== 0 ? 100 / item.percentage : 0;

    return {
      amount: parseFloat((item.amount * scale).toFixed(2)),
      percentage: 100,
      taxAmount: 0,
      description: null,
      quantity: 0,
    };
  }

  public onDeleteSplitCoding(): void {
    const transactionToRemain = this.splittedTransactions.value[0];
    // returns the transaction back to its original state
    const transactionAfterConsolidation =
      this.consolidateTransaction(transactionToRemain);

    const confirmation = new EnhancedConfirmation({
      title: 'Delete split coding',
      approveAction: () => {
        this._transactionService.splitTransaction(this.transaction, [
          transactionAfterConsolidation,
        ]);
        this._activeModal.close();
      },
      cancelAction: () => this._modalService.closeOverlay(),
      danger: true,
      approveBtn: 'Delete',
      shouldFocusOnSubmitButton: true,
    });

    confirmation.additionalInfoText = `Please confirm you want to remove the split from the transaction? This will uncode the transaction and return it to an amount of ${transactionAfterConsolidation.amount}.`;
    this._modalService.confirmationEx(confirmation).then();
  }

  public onCancel(): void {
    this._activeModal.close();
  }

  public onTaxOptionSelectionChanged(
    newValue: BacoTaxOptionDto,
    lineIndex: number
  ): void {
    const formRow = this.getFormRow(lineIndex);
    const taxOption = this._feedStore.getTaxOption(newValue?.taxId);
    formRow.taxAmount.setValue(
      BacoUtils.calculateTax(formRow.amount.value, taxOption?.percentage ?? 0),
      { onlySelf: false, emitEvent: true }
    );
    formRow.taxAmount.enable();
    this.updateTotalTaxAmount();
    this.splittedTransactions.updateValueAndValidity();
  }

  public onAccountSelectionChanged(
    newValue: BacoAccountDto,
    lineIndex: number
  ): void {
    const formRow = this.getFormRow(lineIndex);
    const accountOption = this._feedStore.getAccount(newValue.accountId);

    if (accountOption?.taxOptionId) {
      const taxOption = this._feedStore.getTaxOption(accountOption.taxOptionId);
      if (taxOption) {
        const bacoTaxOptionIdControl = formRow.bacoTaxOptionId;
        bacoTaxOptionIdControl.setValue(accountOption.taxOptionId, {
          onlySelf: false,
          emitEvent: true,
        });
        this.onTaxOptionSelectionChanged(taxOption, lineIndex);
      }
    }
    this.splittedTransactions.updateValueAndValidity();
  }

  public onPercentageChanged(newValue: number, lineIndex: number): void {
    const formRow = this.getFormRow(lineIndex);
    const percentageAmount = BacoUtils.getPercentageAmount(
      this.transaction.amount,
      formRow.percentage.value
    );
    formRow.amount.setValue(percentageAmount, {
      onlySelf: false,
      emitEvent: true,
    });

    this.callOnTaxOptionSelectionChanged(formRow, lineIndex);
    this.splittedTransactions.updateValueAndValidity();
  }

  public onAmountChanged(newValue: number, lineIndex: number): void {
    const formRow = this.getFormRow(lineIndex);
    const percentageValue = BacoUtils.getPercentageValue(
      this.transaction.amount,
      formRow.amount.value
    );
    formRow.percentage.setValue(percentageValue, {
      onlySelf: false,
      emitEvent: true,
    });

    this.callOnTaxOptionSelectionChanged(formRow, lineIndex);
    this.splittedTransactions.updateValueAndValidity();
  }

  public onTaxAmountChanged(newValue: number, lineIndex: number): void {
    this.updateTotalTaxAmount();
    this.splittedTransactions.updateValueAndValidity();
  }

  public onErrorDismissed() {
    this.submitted = false;
  }

  public addNewLine(parameter: ITransactionLineParameters = null): void {
    const myRow = new FormGroup<SplitTransactionFormRow>({
      amount: new FormControl<number>(
        parameter?.amount ?? this.unallocatedAmount ?? 0,
        {
          nonNullable: true,
        }
      ),
      percentage: new FormControl<number>(null),
      quantity: new FormControl<number>(Math.max(parameter?.quantity ?? 0, 0)),
      bacoAccountId: new FormControl<string>(parameter?.bacoAccountId, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      bacoTaxOptionId: new FormControl<string>(parameter?.bacoTaxOptionId),
      taxAmount: new FormControl<number>(parameter?.taxAmount),
      description: new FormControl<string>(parameter?.description),
    });
    const accountOption = this._feedStore.getAccount(
      myRow.controls.bacoAccountId.value
    );
    if (!parameter && accountOption?.taxOptionId) {
      const taxOption = this._feedStore.getTaxOption(accountOption.taxOptionId);
      myRow.controls.bacoTaxOptionId.setValue(accountOption.taxOptionId);
    }

    this.setTaxAmount(myRow.controls);

    const percentageControl = myRow.controls.percentage;
    const percentageValue = BacoUtils.getPercentageValue(
      this.transaction.amount,
      myRow.controls.amount.value
    );
    percentageControl.setValue(percentageValue);

    if (this.transaction.locked) {
      myRow.disable();
    }

    this.form.controls.splittedTransactions.push(myRow);

    setTimeout(() => this.theInputs.last?.nativeElement?.select(), 1);
  }

  public addAsRule() {
    const rule: BacoRuleDto = {
      id: null,
      name: `${RuleMovementType[this.transaction.movementType]} - ${
        this.transaction.description ??
        this.transaction.originalDescription ??
        this.transaction.type
      }`,
      sortOrder: '0',
      movementType: this.transaction.movementType,
      descriptionCondition: {
        conditionOption: StringConditionOption.Exact,
        conditionValue:
          this.transaction.description ?? this.transaction.originalDescription,
      },
      amountCondition: null,
      allocations: this.createBacoRuleAllocations(),
    };

    this._modalService
      .openModal(
        RuleComponent,
        null,
        {
          feedId: this._feedStore.feed$.getValue().data.id,
          transaction: this.transaction,
          rule,
        },
        { windowClass: 'rule-modal', injector: this.injector }
      )
      .then((rule: BacoRuleDto) => {
        this._messageService.success(`Successfully created rule ${rule.name}.`);
        this._activeModal.close();
      })
      .catch(() => true);
  }

  public deleteLine(rowIndex: number) {
    this.splittedTransactions.controls.splice(rowIndex, 1);

    this.updateTotalTaxAmount();

    this.splittedTransactions.markAsUntouched();
    this.splittedTransactions.markAsPristine();
    this.splittedTransactions.updateValueAndValidity();
  }

  private setTaxAmount(splitTransactionFormRow: SplitTransactionFormRow) {
    const taxOptionId = splitTransactionFormRow.bacoTaxOptionId.value;
    if (taxOptionId && splitTransactionFormRow.taxAmount.value === null) {
      const taxOption = this._feedStore.getTaxOption(taxOptionId);
      splitTransactionFormRow.taxAmount.setValue(
        BacoUtils.calculateTax(
          splitTransactionFormRow.amount.value,
          taxOption.percentage
        )
      );
      splitTransactionFormRow.taxAmount.enable();
    } else if (splitTransactionFormRow.taxAmount.value !== null) {
      splitTransactionFormRow.taxAmount.clearValidators();
      splitTransactionFormRow.taxAmount.setValue(
        splitTransactionFormRow.taxAmount.value
      );
    } else {
      splitTransactionFormRow.taxAmount.clearValidators();
      splitTransactionFormRow.taxAmount.disable();
      splitTransactionFormRow.taxAmount.setValue(null);
    }
  }

  private getFormRow(lineIndex: number) {
    return this.splittedTransactions.controls[lineIndex].controls;
  }

  private callOnTaxOptionSelectionChanged(formRow: any, lineIndex: number) {
    const taxOption = this._feedStore.getTaxOption(
      formRow.bacoTaxOptionId.value
    );
    this.onTaxOptionSelectionChanged(taxOption, lineIndex);
  }

  amountSumValidator(): ValidatorFn {
    return (
      control: FormArray<FormGroup<SplitTransactionFormRow>>
    ): ValidationErrors | null => {
      let currentTotal = control.controls
        .map((t) => t.controls.amount)
        .filter((t) => t)
        .reduce((total, t) => t.value + total, 0);

      currentTotal = BacoUtils.roundToTwo(currentTotal);

      const transactionTotal = this.transaction?.amount ?? 0;

      this.unallocatedAmount = BacoUtils.roundToTwo(
        transactionTotal - currentTotal
      );

      return this.unallocatedAmount !== 0 ? { invalidAmountSum: true } : null;
    };
  }

  private updateTotalTaxAmount(): void {
    setTimeout(
      () =>
        (this.totalTaxAmount = this.splittedTransactions.controls
          .map((c) => c.controls.taxAmount.value)
          .reduce((taxAmount, value) => taxAmount + value, 0))
    );
  }

  private createBacoRuleAllocations(): BacoRuleAllocation[] {
    return this.splittedTransactions.value.map((val) => {
      return {
        allocationType: AllocationType.Percentage,
        allocationValue: BacoUtils.getPercentageValue(
          this.transaction.amount,
          val.amount
        ),
        bacoAccountId: val.bacoAccountId,
        bacoTaxOptionId: val.bacoTaxOptionId,
        description: val.description,
      };
    });
  }
}

export interface SplitTransactionFormRow {
  amount: FormControl<number>;
  percentage: FormControl<number>;
  quantity: FormControl<number>;
  bacoAccountId: FormControl<string>;
  bacoTaxOptionId: FormControl<string>;
  taxAmount: FormControl<number>;
  description: FormControl<string>;
}
