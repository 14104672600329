import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Alignment, HeaderStyle } from '../../report-header/report-header';
import { Confirmation, ModalService } from '../../../../../../core';
import { TableElement } from '../../../../enums';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ElementSizeMultiplier } from '../../../../enums/element-size.multiplier';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ReportTableService } from '../services';
import { TableRowType } from '../enums';
import { CELL_NUMBER_FORMATS, TABLE_ROW_TYPES, TABLE_VALUE_TYPES } from '../report-table.contants';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ColumnType } from '../../../../reportTemplates';

@Component({
  selector: 'crs-report-table-actions',
  templateUrl: './report-table-actions.component.html',
  styleUrls: [ './report-table-actions.component.scss' ],
  providers: [ NgbActiveModal ]
})
export class ReportTableActionsComponent implements OnInit, OnDestroy {

  public columnValueTypes = TABLE_VALUE_TYPES;
  public alignments = Alignment;
  public numberFormats = CELL_NUMBER_FORMATS;
  public tableRowTypes = TABLE_ROW_TYPES;
  public sizeX = ElementSizeMultiplier;
  public styles = HeaderStyle;
  public tableElements = TableElement;

  public toolsState$ = this._reportTableService.toolsState$;

  public selectedElementType$ = this.toolsState$.pipe(map(x => x.selectedElementType));

  public enableAddAction$ = this.toolsState$.pipe(map(x => {
    if (x.selectedElementType === TableElement.Cell || x.selectedElementType === TableElement.Table)
      return false;

    if (this.isSectionTable) {
      return x.selectedElementType === TableElement.Row && x.selectedRowType === TableRowType.Row;
    } else {
      return !!x.selectedElementType;
    }
  }));

  public enableRemoveAction$ = this.toolsState$.pipe(map(x => {
    if (x.selectedElementType === TableElement.Cell || x.selectedElementType === TableElement.Table)
      return false;

    if (this.isSectionTable) {
      return x.selectedElementType === TableElement.Row && x.selectedRowType === TableRowType.Row;
    } else {
      return (x.selectedElementType === TableElement.Row && this.currentRowCount > 1) ||
        (x.selectedElementType === TableElement.Column && this.currentColumnCount > 1);
    }
  }));

  public enableStyleAction$ = this.toolsState$.pipe(map(state => state.selectedRowType === TableRowType.Header));

  public enableLevelAction$ = this.toolsState$
    .pipe(map(state =>
      state.selectedRowType === TableRowType.Header ||
      state.selectedRowType === TableRowType.Row ||
      state.selectedRowType === TableRowType.Total));

  public enableFormattingAction$ = this.toolsState$.pipe(map(state =>
    (state.selectedElementType === TableElement.Cell && state.selectedCellType === ColumnType.Number) ||
    (state.selectedElementType === TableElement.Column && state.selectedColumnType === ColumnType.Number)
  ));

  public enableAutoColumnHeader$ = this.toolsState$
    .pipe(map(state => !this._reportTableService.reportTableData.isOutput &&
       state.selectedRowType === TableRowType.ColumnHeader));

  public allowAutoColumnHeader$ = this.toolsState$
      .pipe(map(state => state.allowAutoColumnHeader));

  public get isSectionTable(): boolean {
    return this._reportTableService.isSectionTable;
  }

  private get currentRowCount(): number {
    return this._reportTableService.reportTableData.rows.length;
  }

  private get currentColumnCount(): number {
    return this._reportTableService.reportTableData.columns.length;
  }

  public showSubMenu = false;

  public actionFormGroup: UntypedFormGroup;

  private _destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly _reportTableService: ReportTableService,
              private elementRef: ElementRef,
              private readonly _modalService: ModalService,
              private readonly _cdf: ChangeDetectorRef,
              private readonly _formBuilder: UntypedFormBuilder) {
  }

  @HostListener('document:click', [ '$event' ])
  handleDocumentClick(e: Event) {
    if (this.elementRef && !this.elementRef.nativeElement.contains(e.target)) {
      this.showSubMenu = false;
    }
  }

  public ngOnInit(): void {
    this._buildForm();
    this._listening();
  }

  public ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  private _listening() {
    this._reportTableService.toolsState$
      .pipe(takeUntil(this._destroy))
      .subscribe((state) => this.actionFormGroup.patchValue(state));
  }

  public align(align: any): void {
    this._reportTableService.align(align);
  }

  public onAdd(): void {
    this._reportTableService.insertElement();
  }

  public onRemove(): void {
    if (this._reportTableService.sectionHasOnlyOneRow()) {
      this._modalService.showInformationDialog('Section should have at least one row', false, 'OK, Got It', 'Section', () => {});
      return;
    }

    if (this._reportTableService.sectionHasOnlyOneColumn()) {
      this._modalService.showInformationDialog('Section should have at least one column', false, 'OK, Got It', 'Section', () => {});
      return;
    }

    if (this._reportTableService.elementHasData()) {
      const confirmation = new Confirmation({
        title: 'Delete table element',
        text: `Are you sure you want to delete this element?`,
        action: () => this._reportTableService.removeElement(),
        danger: true,
        shouldFocusOnSubmitButton : true
      });
      this._modalService.confirmation2(confirmation);
    } else {
      this._reportTableService.removeElement();
    }
  }

  public onSelectedRowType() {
    const rowType = this.actionFormGroup.get('selectedRowType').value;
    if (rowType !== TableRowType.Row && this._reportTableService.sectionHasOnlyOneRow()) {
      this._modalService.showInformationDialog('Section should have at least one row', false, 'OK, Got It', 'Section', () => {})
      .finally(() => {
        this._reportTableService.clearSelection();
      });

      return;
    }
    
    this._reportTableService.transformToRowType(rowType);
  }

  public onSetValueType(controlName: string) {
    this._reportTableService.setValueType(this.actionFormGroup.get(controlName).value);
  }

  public getBack() {
    // TODO: fix history
    // this._reportTableService.history(-1);
    this._cdf.detectChanges();
  }

  public getForward() {
    // this._reportTableService.history(+1);
    this._cdf.detectChanges();
  }

  public toggleSubMenu() {
    this.showSubMenu = !this.showSubMenu;
  }

  public onSelectedSizeX(controlName: string) {
    this._reportTableService.setColumnSizeX(this.actionFormGroup.get(controlName).value);
  }

  public onSelectedRowStyle(): void {
    this._reportTableService.setRowStyle(this.actionFormGroup.get('rowStyle').value);
  }

  public onSelectRowLevel(): void {
    this._reportTableService.setRowLevel(this.actionFormGroup.get('rowLevel').value);
  }

  public textUnderLine(flag: boolean) {
    this._reportTableService.textUnderLine(flag);
  }

  public textBold(flag: boolean) {
    this._reportTableService.textBold(flag);
  }

  public textItalic(flag: boolean) {
    this._reportTableService.textItalic(flag);
  }

  public onSelectRounding(controlName: string): void {
    this._reportTableService.setRounding(this.actionFormGroup.get(controlName).value);
  }

  public onSelectFormatting(controlName: string) {
    this._reportTableService.setFormatting(this.actionFormGroup.get(controlName).value);
  }

  public onToggleAutoColumnHeader(controlName: string): void {
    this._reportTableService.toggleAutoColumnHeader(this.actionFormGroup.get(controlName).value);
  }

  public enableWidthAction() {
    const autoColumnCount = this._reportTableService.reportTableData.columns.filter(x => x.widthType === ElementSizeMultiplier.auto).length;

    let isColumnAutoWidth = false;
    this.toolsState$.pipe(map(x =>
      (x.selectedElementType === TableElement.Column && x.columnSizeX === ElementSizeMultiplier.auto)
    )).subscribe(x => isColumnAutoWidth = x);

    return autoColumnCount > 1 || !isColumnAutoWidth;
  }

  private _buildForm(): void {
    this.actionFormGroup = this._formBuilder.group({
      selectedRowType: [ null ],
      rowStyle: [ HeaderStyle.Normal ],
      rowLevel: [ 1 ],
      enableAutoColumnHeader: [ true ],
      allowAutoColumnHeader: [ true ],

      columnRounding: [ null ],
      columnFormatting: [ null ],
      selectedColumnType: [ null ],
      columnSizeX: [ null ],

      cellRounding: [ null ],
      cellFormatting: [ null ],
      selectedCellType: [ null ]
    });

    this.allowAutoColumnHeader$.subscribe(value => {
      if (value)
        this.actionFormGroup.get('enableAutoColumnHeader').enable();
      else
        this.actionFormGroup.get('enableAutoColumnHeader').disable();
    });
  }
}
