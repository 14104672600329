import { PolicyLevel } from './policyLevel';
import { ReportingSuite } from '../reportingSuites';
import { DateHelpers } from 'src/app/shared/utilities/date-helpers';

export class PolicyVariant {
  id: string;
  policyId: string;
  fileId: string;
  reportTemplateId: string;
  name: string;
  description: string;
  applicationDate: Date;
  earliestApplicationDate: Date;
  expiryDate: Date;
  level: PolicyLevel;
  reportingSuites: ReportingSuite[];
  entityTypes: number[];
  masterPolicyVariantId: string;
  masterChanged: boolean;

  content = [];
  masterPolicyVariantContent = [];

  constructor(data) {
    Object.assign(this, data);
    if (data.reportingSuites) {
      this.reportingSuites = data.reportingSuites.map(s => new ReportingSuite(s));
    }
    this.applicationDate = DateHelpers.dateFromApiResponse(data.applicationDate);
    this.earliestApplicationDate = DateHelpers.dateFromApiResponse(data.earliestApplicationDate);
    this.expiryDate = DateHelpers.dateFromApiResponse(data.expiryDate);
  }
}
