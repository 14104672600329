import { Config } from './config';

export class AuthConfig {

  // Url of the Identity Provider
  public static issuer = '[from Config]';
  public static clientId = 'ab4bf539-563f-4451-a04e-0304a9efc8fc';
  public static scope = '[from Config]';
  public static redirectUri = '[from Config]';
  public static responseType = 'code';
  public static showDebugInformation = true;
  public static b2c_1a_v1_fa_sign_in = '[from Config]';
  public static b2c_1a_v1_fa_sign_up = '[from Config]';
  public static b2c_1a_v1_fa_password_change = '[from Config]';
  public static b2c_1a_v1_fa_sign_in_name_change = '[from Config]';
  public static strictDiscoveryDocumentValidation = false;

  static init() {
    this.issuer = Config.identity.issuer;
    this.clientId = Config.identity.clientId;
    this.b2c_1a_v1_fa_sign_in = Config.identity.disoveryDocumentUrl;
    this.b2c_1a_v1_fa_sign_up = Config.identity.disoveryDocumentUrl.replace('b2c_1a_v1_fa_sign_in', 'b2c_1a_v1_fa_sign_up');
    this.b2c_1a_v1_fa_password_change = Config.identity.disoveryDocumentUrl.replace('b2c_1a_v1_fa_sign_in', 'b2c_1a_v1_fa_password_change');
    this.b2c_1a_v1_fa_sign_in_name_change = Config.identity.disoveryDocumentUrl.replace('b2c_1a_v1_fa_sign_in', 'b2c_1a_v1_fa_sign_in_name_change');
    this.scope = 'openid ' + Config.identity.scope;
    this.redirectUri = Config.baseUrls.site;
  }

}
