import { ReportOutput } from '../reportViewer/report-output';

export class ReportDocumentUtility {
  static getTitle(report: ReportOutput): string {
    const entityName = report?.entity?.name;
    const title = report?.title;

    if (entityName) {
      const year =
        typeof report?.endDate === 'string'
          ? new Date(report?.endDate)?.getFullYear()
          : report?.endDate?.getFullYear();

      return `${year} ${title} - ${entityName}`;
    }

    return title;
  }
}
