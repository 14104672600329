import { ReportSignStatus } from './../../models/report-document-list';
import { ReportHeaderColumn, ReportMetaData } from 'src/app/accounting/reports/reportViewer/report-output';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { ReportStyleInfo } from '../report-output';
import { ReportContentInfo } from './report-content-info';

@Component({
  selector: 'crs-report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['./report-content.component.scss']
})
export class ReportContentComponent {

  @Input() set content(value: any[]) {
    this.reportContent.content = value;
  }
  get content() {
    return this.reportContent.content;
  }
  @Input() set readonly(value: boolean) {
    this.reportContent.readonly = value;
  }
  get readonly() {
    return this.reportContent.readonly;
  }
  @Input() set output(value: boolean) {
    this.reportContent.output = value;
  }
  get output() {
    return this.reportContent.output;
  }

  @Input() set style(value: ReportStyleInfo) {
    this.reportContent.style = value;
  }
  get style() {
    return this.reportContent.style;
  }

  @Input() set reportSignStatus(value: number) {
    // If report is signed, report won't be editable
    if(value == ReportSignStatus.REPORT_SIGNED)
    {
      this.reportContent.readonly = true;
    }
  }

  @Input() reportMetaData: ReportMetaData;

  @Input() reportColumns: ReportHeaderColumn[];

  reportContent = new ReportContentInfo();

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.content, event.previousIndex, event.currentIndex);
  }
}
