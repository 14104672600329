export class Database {
  id: number;
  name: string;
  databaseName: string;
  databaseServer: any;
  multiTenant: boolean;

  constructor(data) {
    Object.assign(this, data);
  }
}

export class DatabaseModel {
  id: number;
  name: string;
  databaseName: string;
  databaseServerId: any;
  multiTenant: boolean;

  constructor(data: Database) {
    this.id = data.id;
    this.name = data.name;
    this.databaseName = data.databaseName;
    if (data.databaseServer) this.databaseServerId = data.databaseServer.id;
    this.multiTenant = data.multiTenant;
  }
}
