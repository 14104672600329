<div [ngBusy]="[busy.load]">

  <crs-css-font-urls-editor [(ngModel)]="fontUrls"></crs-css-font-urls-editor>

  <hr>

  <crs-css-classes-editor [(ngModel)]="cssEditModel.classGroups" #cssClassesEditor></crs-css-classes-editor>

  <button type="button" class="mt-4 btn btn-success" (click)="previewReport()" [promiseBtn]="busy.preview">
    Preview Report
  </button>

</div>
