import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'crs-file-feeds-root',
  templateUrl: './file-feeds-root.component.html',
})
export class FileFeedsRootComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
