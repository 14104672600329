import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { JournalCategory } from './../journals/journalCategory';
import { GroupByAccountType } from './groupByAccountType';

import { ApiService } from 'src/app/core';
import { ConsolidationMode } from '../../reports/reportTemplates';
import {
  AccountTransaction,
  AccountWithTransactions,
} from './accountTransaction';
import { TrialBalanceReportRow } from './trialBalanceReportRow';

@Injectable({ providedIn: 'root' })
export class LedgerReportService {
  readonly base: string = 'api/accounting/ledger/reports';

  constructor(private apiService: ApiService) {}

  getTrialBalance$(
    datasetId: string,
    byAccountType: GroupByAccountType,
    startDate?: Date,
    endDate?: Date,
    category?: JournalCategory,
    consolidationMode: ConsolidationMode = ConsolidationMode.None,
    comparativeDatasetIds?: string[],
  ) {
    let url = `${this.base}/${datasetId}/trial-balance`;
    url = this.apiService.addQuery(url, 'byAccountType', byAccountType);
    url = this.apiService.addQuery(url, 'startDate', startDate);
    url = this.apiService.addQuery(url, 'endDate', endDate);
    url = this.apiService.addQuery(url, 'category', category);
    url = this.apiService.addQuery(url, 'consolidationMode', consolidationMode);

    const comparativeDatasetIdsString = comparativeDatasetIds?.toString() ?? null;
    url = this.apiService.addQuery(url, 'comparativeDatasetIds', comparativeDatasetIdsString);

    return this.apiService
      .get<TrialBalanceReportRow[]>(url)
      .pipe(
        map((dataset) =>
          dataset.map((journal) => new TrialBalanceReportRow(journal))
        )
      );
  }

  getLedgerBySourceAccount$(
    datasetId: string,
    accountIds: string[],
    startDate?: Date,
    endDate?: Date,
    category?: JournalCategory,
    includeApiLedgers: boolean = false
  ) {
    let url = `${this.base}/${datasetId}/source-accounts`;
    url = this.apiService.addQuery(url, 'accountIds', accountIds);
    url = this.apiService.addQuery(url, 'startDate', startDate);
    url = this.apiService.addQuery(url, 'endDate', endDate);
    url = this.apiService.addQuery(url, 'category', category);
    url = this.apiService.addQuery(url, 'includeApiLedgers', includeApiLedgers);

    return this.apiService
      .get<AccountTransaction[]>(url)
      .pipe(
        map((data) =>
          data.map(
            (accountTransaction) => new AccountTransaction(accountTransaction)
          )
        )
      );
  }

  getLedgerByAccount$(
    datasetId: string,
    accountIds: string[],
    startDate?: Date,
    endDate?: Date,
    category?: JournalCategory,
    includeApiLedgers: boolean = false
  ) {
    let url = this.base + '/' + datasetId + '/accounts';
    url = this.apiService.addQuery(url, 'accountIds', accountIds);
    url = this.apiService.addQuery(url, 'startDate', startDate);
    url = this.apiService.addQuery(url, 'endDate', endDate);
    url = this.apiService.addQuery(url, 'category', category);
    url = this.apiService.addQuery(url, 'includeApiLedgers', includeApiLedgers);

    return this.apiService.get<AccountWithTransactions[]>(url).pipe(
      map((data) =>
        data
          .map((a) => new AccountWithTransactions(a))
          .map((a) => a.transactions as AccountTransaction[])
          .reduce((a, b) => a.concat(b))
      )
    );
  }
}
