import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { StandardTaxCode } from './standard-tax-code';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StandardTaxCodeService {
  readonly base: string = 'api/chart-of-accounts/tax-code';

  constructor(private apiService: ApiService) {}

  get$(): Observable<StandardTaxCode[]> {
    return this.apiService
      .get<StandardTaxCode[]>(this.base)
      .pipe(
        map((taxCodes) =>
          taxCodes.map((taxCode) => new StandardTaxCode(taxCode))
        )
      );
  }

  getMasterTaxCodes$(): Observable<StandardTaxCode[]> {
    return this.apiService
      .get<StandardTaxCode[]>(this.base + '/master-tax-code')
      .pipe(
        map((taxCodes) =>
          taxCodes.map((taxCode) => new StandardTaxCode(taxCode))
        )
      );
  }

  getTaxCode$(taxCodeId: string): Observable<StandardTaxCode> {
    return this.apiService
      .get<StandardTaxCode>(`${this.base}/${taxCodeId}`)
      .pipe(map((taxCode) => new StandardTaxCode(taxCode)));
  }

  postTaxCode$(taxCodeToAdd: StandardTaxCode): Observable<string> {
    return this.apiService.post(this.base, taxCodeToAdd);
  }

  putTaxCode$(
    taxCodeId: string,
    taxCodeToUpdate: StandardTaxCode
  ): Observable<{}> {
    return this.apiService.put(`${this.base}/${taxCodeId}`, taxCodeToUpdate);
  }

  deleteTaxCode$(taxCodeId: string): Observable<{}> {
    return this.apiService.delete(`${this.base}/${taxCodeId}`);
  }
}
