import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Workpaper,
  WorkpaperCreateModel,
  WorkpaperUpdateModel,
  WorkpaperDocument,
  WorkpaperComment,
} from '../workpaper';
import { WorkpaperStatus } from 'src/app/shared/components/accounting/workpaper-status-dropdown/workpaper-status-dropdown.component';
import { WorkpaperService } from '../workpaper-service';
import { MessageService, ModalService } from 'src/app/core';
import { Subject, Subscription, EMPTY, Observable } from 'rxjs';
import { catchError, exhaustMap, finalize, tap } from 'rxjs/operators';
import { AccountUltraLight } from '../../accounts/account-ultra-light';
import { User, UserService } from 'src/app/firm';

export enum AddWorkpaperFormType {
  AddNew = 'addNew',
}

@Component({
  selector: 'crs-workpaper-form',
  templateUrl: './workpaper-form.component.html',
  styleUrls: ['./workpaper-form.component.scss'],
})
export class WorkpaperFormComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() id: AddWorkpaperFormType | string;
  @Input() params: {
    datasetId: string;
    workpaper?: Workpaper;
    focusDocument?: boolean;
    focusComment?: boolean;
  };

  @ViewChild('newDocumentUrlInput') newDocumentUrlInput: ElementRef;
  @ViewChild('newCommentInput') newCommentInput: ElementRef;
  @ViewChildren('commentTextarea') commentTextareas: QueryList<ElementRef>;

  isAdd: boolean;
  busy = {
    load: null,
    submit: null,
  };
  error: string = null;

  form: FormGroup;
  submitButtonStream = new Subject<void>();
  subscriptions: Subscription[] = [];
  isAccountDisabled: boolean = false;
  profileUser: User;
  editingCommentIndex: number | null = null;
  originalCommentValue: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private workpaperService: WorkpaperService,
    private messageService: MessageService,
    private modalService: ModalService,
    public activeModal: NgbActiveModal,
    public userService: UserService
  ) {
    this.form = this.formBuilder.group({
      accountId: [null],
      title: ['', Validators.required],
      status: [WorkpaperStatus.NotStarted],
      documents: this.formBuilder.array([]),
      newDocumentUrl: [''],
      comments: this.formBuilder.array([]),
      newComment: [''],
    });
  }

  ngOnInit(): void {
    this.isAdd = this.id === AddWorkpaperFormType.AddNew;
    this.isAccountDisabled = !this.isAdd;
    this.configureSubmit();

    if (!this.isAdd && !!this.params.workpaper) {
      this.form.patchValue({
        accountId: this.params.workpaper.accountId,
        title: this.params.workpaper.title,
        status: this.params.workpaper.status,
      });

      this.params.workpaper.documents.forEach((doc) => {
        this.addDocument(doc.id, doc.url, doc.type);
      });

      this.params.workpaper.comments.forEach((comment) => {
        const commentUserId = comment.userInfo?.userId;
        const commentUserName = comment.userInfo?.userName;
        const commentCreated = comment.createdDate;

        this.addComment(
          comment.id,
          comment.comment,
          commentUserId,
          commentUserName,
          commentCreated
        );
      });
    }

    this.userService.profile().subscribe((user) => {
      return (this.profileUser = user);
    });
  }

  ngAfterViewInit(): void {
    if (this.params?.focusDocument) {
      this.focusElement(this.newDocumentUrlInput);
    }

    if (this.params?.focusComment) {
      this.focusElement(this.newCommentInput);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get documents() {
    return this.form.get('documents') as FormArray;
  }

  get comments() {
    return this.form.get('comments') as FormArray;
  }

  addDocument(id: string = null, url: string = '', type: number = 0) {
    this.documents.push(
      this.formBuilder.group({
        id: [id],
        url: [url, Validators.required],
        type: [type],
      })
    );
  }

  addComment(
    id: string = null,
    comment: string = '',
    commentUserId: string = '',
    commentUserName: string = '',
    commentCreated: string = ''
  ) {
    this.comments.push(
      this.formBuilder.group({
        id: [id],
        comment: [comment, Validators.required],
        commentUserId: [commentUserId],
        commentUserName: [commentUserName],
        commentCreated: [commentCreated],
      })
    );
  }

  removeDocument(index: number) {
    this.documents.removeAt(index);
  }

  removeComment(index: number) {
    this.comments.removeAt(index);
  }

  addNewDocument() {
    const newDocumentUrlControl = this.form.get('newDocumentUrl');
    if (newDocumentUrlControl.value) {
      this.addDocument(null, newDocumentUrlControl.value);
      newDocumentUrlControl.reset();
    }
  }

  onAccountChange(event: { id: string; account: AccountUltraLight }) {
    const isFormTitleEmpty = !this.form.get('title').value;
    if (isFormTitleEmpty) {
      this.form.get('title').setValue('Account workpaper');
    }
  }

  addNewComment() {
    const newCommentControl = this.form.get('newComment');
    if (newCommentControl.value) {
      const newDate = new Date().toISOString().slice(0, -1);

      this.addComment(
        null,
        newCommentControl.value,
        this.profileUser.id,
        this.profileUser.displayName,
        newDate
      );
      newCommentControl.reset();
    }
  }

  editComment(index: number) {
    this.editingCommentIndex = index;
    this.originalCommentValue = this.comments.at(index).get('comment').value;
    this.focusCommentTextarea(index);
  }

  cancelEditComment() {
    if (this.editingCommentIndex !== null) {
      this.comments
        .at(this.editingCommentIndex)
        .get('comment')
        .setValue(this.originalCommentValue);
      this.editingCommentIndex = null;
    }
  }

  saveComment() {
    this.editingCommentIndex = null;
  }

  close() {
    this.activeModal.dismiss();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.submitButtonStream.next();
  }

  configureSubmit() {
    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.submitObservable())
        )
        .subscribe(
          () => {
            this.isAdd
              ? this.messageService.success('Workpaper added successfully.')
              : this.messageService.success('Workpaper updated successfully.');

            this.activeModal.close();
          },
          (error) => {
            this.messageService.error('Failed to add workpaper: ' + error);
          }
        )
    );
  }

  submitObservable(): Observable<any> {
    if (
      this.form.get('newDocumentUrl').value ||
      this.form.get('newComment').value
    ) {
      this.addNewDocument();
      this.addNewComment();
    }

    if (this.isAdd) {
      return this.createWorkpaper();
    } else {
      return this.updateWorkpaper();
    }
  }

  createWorkpaper(): Observable<any> {
    const formData = this.form.value;
    const workpaperModel = new WorkpaperCreateModel({
      accountId: formData.accountId,
      title: formData.title,
      status: formData.status || WorkpaperStatus.NotStarted,
      datasetId: this.params.datasetId,
      documents: formData.documents.map((doc) => new WorkpaperDocument(doc)),
      comments: formData.comments.map(
        (comment) => new WorkpaperComment(comment)
      ),
    });

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return this.workpaperService.post$(workpaperModel).pipe(
      catchError((err) => {
        this.error = err;
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  updateWorkpaper(): Observable<any> {
    const formData = this.form.value;

    const workpaperId = this.params.workpaper.id;

    const workpaperUpdateModel = new WorkpaperUpdateModel({
      title: formData.title,
      status: formData.status,
      documents: formData.documents.map((doc) => ({
        id: doc.id,
        url: doc.url,
        type: doc.type,
      })),
      comments: formData.comments.map((comment) => ({
        id: comment.id,
        comment: comment.comment,
      })),
    });

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return this.workpaperService.put$(workpaperId, workpaperUpdateModel).pipe(
      catchError((err) => {
        this.error = err;
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  removeWorkpaper() {
    const workpaperId = this.params.workpaper.id;

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return this.workpaperService.delete$(workpaperId).subscribe(
      () => {
        loadingStream.complete();
        this.messageService.success('Workpaper deleted successfully.');
        this.activeModal.close();
      },
      (err) => this.messageService.error(err)
    );
  }

  onStatusChange(newStatus: string): void {
    this.form.get('status').setValue(newStatus);
  }

  deleteWorkpaperConfirmation() {
    this.modalService.confirmation(
      `Are you sure you want to delete the workpaper? <br><br> This action cannot be undone.`,
      () => this.removeWorkpaper(),
      true
    );
  }

  getExternalUrl(url: string): string {
    if (!/^https?:\/\//i.test(url)) {
      return 'https://' + url;
    }
    return url;
  }

  focusElement(inputControl: any): void {
    setTimeout(() => {
      if (inputControl) {
        inputControl?.ngControl?.valueAccessor?._elementRef?.nativeElement.focus();
      }
    }, 100);
  }

  focusCommentTextarea(index: number): void {
    setTimeout(() => {
      const commentTextarea = this.commentTextareas.toArray()[index] as any;
      if (commentTextarea) {
        commentTextarea?.ngControl?.valueAccessor?._elementRef?.nativeElement.focus();
      }
    }, 100);
  }
}
