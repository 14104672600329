import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../core';
import { MatchingRule, MatchingRuleModel } from './matchingRule';

@Injectable({
  providedIn: 'root',
})
export class MatchingRuleService {
  readonly base: string = 'api/accounting/matchingrules';

  constructor(private apiService: ApiService) {}

  get(id: string) {
    return this.apiService
      .get<MatchingRule>(this.base + '/' + id)
      .pipe(map((r) => new MatchingRule(r)));
  }

  getAll(
    fileId: string,
    matchingRuleSetId: string
  ): Observable<MatchingRule[]> {
    let url = `${this.base}/forfile/${fileId}`;
    url = this.apiService.addQuery(url, 'matchingRuleSetId', matchingRuleSetId);

    return this.apiService
      .get<any>(url)
      .pipe(map((l) => l.map((r) => new MatchingRule(r))));
  }

  getAllMasters(matchingRuleSetId: string): Observable<MatchingRule[]> {
    let url = `${this.base}/master`;
    url = this.apiService.addQuery(url, 'matchingRuleSetId', matchingRuleSetId);
    return this.apiService
      .get<any>(url)
      .pipe(map((l) => l.map((r) => new MatchingRule(r))));
  }

  post(rule: MatchingRuleModel) {
    return this.apiService.post<any>(this.base, rule);
  }

  put(rule: MatchingRuleModel) {
    return this.apiService.put(this.base + '/' + rule.id, rule);
  }

  reorder(id: string, index: number) {
    return this.apiService.put(this.base + '/' + id + '/reorder', index);
  }

  delete(id: string) {
    return this.apiService.delete(this.base + '/' + id);
  }

  applyAll(fileId: string, matchingRuleSetId: string) {
    const url = `${this.base}/for-file/${fileId}/apply/${matchingRuleSetId}`;
    return this.apiService.post<boolean>(url, null);
  }

  applyRule(rule: MatchingRuleModel) {
    return this.apiService.post<any>(this.base + '/apply', rule);
  }
}
