import { OnDestroy, Injectable } from '@angular/core';
import { ReportTemplateService } from '../../reportTemplates';
import { MessageService } from 'src/app/core';
import { BehaviorSubject, Subscription, EMPTY, Subject } from 'rxjs';
import { Disclosure } from './disclosure';
import { switchMap, tap, catchError, finalize, first } from 'rxjs/operators';

class DisclosuresContext {
  fileId: string;
  templateId: string;
  reportingSuiteId: number;
  entityType: number;
  get isAdd() {
    return this.fileId === 'add' || !this.fileId;
  }

  equalTo(context: DisclosuresContext) {
    return this.fileId === context.fileId &&
     this.templateId === context.templateId &&
     this.reportingSuiteId === context.reportingSuiteId &&
     this.entityType === context.entityType;
  }

  get valid() {
    return this.fileId &&
    this.templateId &&
    this.reportingSuiteId &&
    this.entityType !== undefined
    && this.entityType !== null;
  }
}

@Injectable()
export class DisclosureSelectorsService implements OnDestroy {

  isLoading = false;
  private required = false;

  private currentContext = new DisclosuresContext();

  private _disclosures$ = new BehaviorSubject<Disclosure[]>(null);
  private disclosures$ = this._disclosures$.asObservable();

  private update$ = new BehaviorSubject<DisclosuresContext>(this.currentContext);
  private updateSubscription: Subscription;

  /**
   * Stream that is to be triggered/subscribed based on when an existing Disclosure
   * object in the list of most recent Disclosures[] has a value changed
   */
  disclosuresUpdated$ = new Subject();

  constructor(private readonly _reportTemplateService: ReportTemplateService,
    private readonly _messageService: MessageService) {

   }

  ngOnDestroy() {
    if (this.updateSubscription) this.updateSubscription.unsubscribe();
    this.updateSubscription = null;
  }

  getDisclosureStream() {
    if (!this.required) {
      this.required = true;
      this.updateSubscription = this.update$.pipe(
        switchMap(c => this.getDisclosures(c)),
        ).subscribe();
    }
    return this.disclosures$;
  }

  updateContextProperties(propertyBag: {
    fileId?: string,
    templateId?: string,
    reportingSuiteId?: number,
    entityType?: number
  }) {
    this.update$.pipe(first()).subscribe(context => {
        const newContext = new DisclosuresContext();
        newContext.fileId = context.fileId;
        newContext.templateId = context.templateId;
        newContext.reportingSuiteId = context.reportingSuiteId;
        newContext.entityType = context.entityType;
        Object.assign(newContext, propertyBag);
        this.update$.next(newContext);
      }
    );
  }

  private getDisclosures(context: DisclosuresContext) {

    if (!this.required || !context.valid) {
      return EMPTY;
    }

    if (context.equalTo(this.currentContext)) {
      return EMPTY; // already loaded
    }

    this.isLoading = true;

   return this._reportTemplateService.getDisclosures(
      context.isAdd ? 'add' : context.templateId,
      context.fileId,
      context.reportingSuiteId,
      context.entityType)
      .pipe(
        tap(result => this._disclosures$.next(result)),
        tap(() => this.currentContext = context),
        catchError(err => {
          this.showError(err);
          return [];
        }),
        finalize(() => this.isLoading = false)
      );
  }

  private showError(err) {
    this._messageService.error(err);
  }

}


