import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentence',
})
export class SentencePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (typeof value === 'string' && value && value.length) {
      let newString = '';

      // Replacing dashes with spaces
      value = value.replace(/-/g, ' ');

      if (value.length >= 1) {
        newString = value[0].toUpperCase();
      }

      for (let i = 1; i < value.length; i++) {
        if (
          value[i] === value[i].toUpperCase() &&
          value[i - 1] === value[i - 1].toUpperCase()
        ) {
          if (
            i < value.length - 1 &&
            value[i + 1] !== value[i + 1].toUpperCase()
          ) {
            newString += ' ';
          }
        }

        newString += value[i];

        if (value[i] !== value[i].toUpperCase()) {
          if (
            i < value.length - 1 &&
            value[i + 1] === value[i + 1].toUpperCase()
          ) {
            newString += ' ';
          }
        }
      }

      return newString;
    }

    return value;
  }
}
