<div class="modal-header">
  <h4 class="modal-title">Select {{ params.title }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="content">
    <div class="row">
      <div class="col-xl-6 col-lg-7 col-md-9 col-sm-10">
        <p>Select one or more {{ params.title }} from the list below:</p>

        <div class="spacer"></div>

        <div class="form-group">
          <div class="icon-input-wrapper flex-grow-1 mr-2">
            <i aria-hidden="true" class="fas fa-search input-icon"></i>
            <input
              aria-describedby="searchIcon"
              placeholder="Search..."
              type="search"
              [formControl]="search"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ag-grid-angular
          class="ag-theme-material"
          rowClass="selectable-row"
          style="width: 100%"
          [gridOptions]="gridOptions"
          [rowData]="params.variants"
        >
          <ag-grid-column
            field="policyName"
            headerName="Policy"
            *ngIf="params.type === 'policy'"
            [checkboxSelection]="true"
          ></ag-grid-column>
          <ag-grid-column
            field="templateName"
            headerName="Disclosure"
            *ngIf="params.type === 'disclosure'"
            [checkboxSelection]="true"
          ></ag-grid-column>
          <ag-grid-column
            field="name"
            headerName="Variant"
            [width]="120"
          ></ag-grid-column>
        </ag-grid-angular>

        <div class="spacer"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="select()">
    Select
  </button>
  <button class="btn btn-secondary" type="button" (click)="cancel()">
    Cancel
  </button>
</div>
