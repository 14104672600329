import { Component, OnInit, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { getDefaultGridOptions, ProgressBarService } from 'src/app/shared';
import { DownloadService } from '../../../core/services/download.service';
import { WorkpapersBusinessFitService } from '../workpaper-business-fit.service';
import { ActivatedRoute } from '@angular/router';
import { WorkpaperBusinessFitStarter } from '../workpaper-business-fit-starter';
import { ApiError } from 'src/app/core/interceptors/api-error';

@Component({
  selector: 'crs-workpapers-business-fit',
  templateUrl: './workpapers-business-fit.component.html',
  styleUrls: ['./workpapers-business-fit.component.scss'],
})
export class WorkpapersBusinessFitComponent implements OnInit, OnDestroy {
  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    load: null,
  };

  starters: WorkpaperBusinessFitStarter[];
  fileSubscription: Subscription;
  notSetup = false;

  gridOptions = getDefaultGridOptions();

  constructor(
    private workpapersBusinessFitService: WorkpapersBusinessFitService,
    private downloadService: DownloadService,
    private route: ActivatedRoute,
    private progressBar: ProgressBarService
  ) {}

  ngOnInit() {
    this.fileSubscription = this.route.params.subscribe(() => {
      this.fileId = this.route.snapshot.parent.paramMap.get('id');
      this.getStarters();
    });
  }

  ngOnDestroy() {
    this.fileSubscription.unsubscribe();
  }

  getStarters() {
    this.error = null;
    this.busy.load = this.workpapersBusinessFitService
      .getStarters()
      .pipe(
        catchError((err) => {
          if (err instanceof ApiError) {
            if (err.messageString.includes('not setup')) {
              this.notSetup = true;
              return of([]);
            }
          }
          this.showError(err);
          return of([]);
        })
      )
      .subscribe((starters) => (this.starters = starters));
  }

  showError(error) {
    this.error = error;
  }

  selectStarter(param) {
    this.error = null;

    const progressBar = this.progressBar.ref();
    progressBar.start();

    this.workpapersBusinessFitService
      .getStarter(param.data.id, this.fileId)
      .pipe(
        finalize(() => {
          progressBar.complete();
        })
      )
      .subscribe(
        (url) => this.downloadService.download(url),
        (err) => this.showError(err)
      );
  }
}
