import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/core';
import {
  LivestockCalculator,
  LivestockCalculatorPostJournal,
  LivestockCalculatorToUpsert,
} from './livestock-calculator';

@Injectable({ providedIn: 'root' })
export class LivestockCalculatorService {
  readonly base: string = 'api/accounting/ledger/livestock-calculator';

  constructor(private apiService: ApiService) {}

  get$(id: string): Observable<LivestockCalculator> {
    return this.apiService
      .get(`${this.base}/${id}`)
      .pipe(map((calculator) => new LivestockCalculator(calculator)));
  }

  getForTradingAccount$(
    id: string,
    datasetId: string
  ): Observable<LivestockCalculator> {
    return this.apiService
      .get(`${this.base}/for-trading-account/${id}/dataset/${datasetId}`)
      .pipe(map((calculator) => new LivestockCalculator(calculator)));
  }

  post$(createModel: LivestockCalculatorToUpsert): Observable<string> {
    return this.apiService.post(this.base, createModel);
  }

  postJournal$(
    id: string,
    postJournalModel: LivestockCalculatorPostJournal
  ): Observable<boolean> {
    return this.apiService.post(
      `${this.base}/${id}/post-journal`,
      postJournalModel
    );
  }

  put$(id: string, upsertModel: LivestockCalculatorToUpsert): Observable<{}> {
    return this.apiService.put(`${this.base}/${id}`, upsertModel);
  }

  delete$(id: string): Observable<{}> {
    return this.apiService.delete(`${this.base}/${id}`);
  }
}
