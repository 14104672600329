import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GridOptions,
  IDatasource,
  IGetRowsParams,
  GridApi,
} from 'ag-grid-community';
import { EMPTY } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
} from 'rxjs/operators';

import { ModalService } from 'src/app/core';
import { User, UserService } from '../';
import { MessageService, SessionService } from '../../../core';
import { accountingRenderers, getDefaultGridOptions } from '../../../shared';

@Component({
  selector: 'crs-users',
  templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
  @ViewChild('optionsCell', { static: true, read: TemplateRef })
  optionsCell!: TemplateRef<User>;

  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    users: false,
  };

  search = new UntypedFormControl();

  gridOptions: GridOptions;
  gridApi: GridApi;
  renderers = accountingRenderers;
  datasource: UserDatasource;
  isAdmin = false;
  licenseInfo: any;
  isBankFeedFeatureFlagOnly: boolean;

  private _cacheSize = 50;
  private _pageSize = this._cacheSize / 2;

  constructor(
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _sessionService: SessionService,
    private readonly _userService: UserService
  ) {
    this.isAdmin = _sessionService.permissions.isAdmin;
  }

  ngOnInit() {
    this.search.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        console.log('value: ', value);
        this.datasource.setFilter(value as string);
        this.gridApi.setGridOption('datasource', this.datasource);
      });

    this.getLicenseInfo();

    this.gridOptions = {
      ...getDefaultGridOptions(),
      columnDefs: [
        { field: 'firstName', headerName: 'First Name' },
        { field: 'lastName', headerName: 'Last Name' },
        { field: 'email', headerName: 'Email' },
        {
          field: 'status',
          headerName: 'Status',
          maxWidth: 120,
          minWidth: 120,
        },
        {
          headerName: '',
          minWidth: 90,
          width: 90,
          type: 'optionsColumn',
          cellRendererParams: {
            ngTemplate: this.optionsCell,
          },
        },
      ],
      rowModelType: 'infinite',
      rowGroupPanelShow: 'never',
      functionsReadOnly: true,
      pagination: true,
      cacheBlockSize: this._cacheSize,
      paginationPageSize: this._pageSize,
      onGridReady: (params) => {
        this.gridApi = params.api;
        this.gridApi.setGridOption('datasource', this.datasource);
      },
    };

    this.datasource = new UserDatasource(this._userService, this._cacheSize);
  }

  getLicenseInfo() {
    if (this.isAdmin) {
      this._userService.getLicenseInfo().subscribe((licenseInfo) => {
        this.licenseInfo = licenseInfo;
        this.isBankFeedFeatureFlagOnly =
          !licenseInfo.ledger && licenseInfo.bankFeed;
      });
    }
  }

  addUser() {
    if (this.canAddUsers()) {
      this._router.navigate(['./add'], { relativeTo: this._route });
    } else {
      this._messageService.error(
        'You have reached your user license limit. Please contact sales on 1800 660 670 to purchase additional licenses.'
      );
    }
  }

  canAddUsers() {
    // No restriction when adding new user when only bank feed feature flag is on. restriction only applies when Ledger feature flag is on.
    return (
      (!this.licenseInfo.ledger && this.licenseInfo.bankFeed) ||
      parseInt(this.licenseInfo.activeUserCount) <
        parseInt(this.licenseInfo.licenseCount)
    );
  }

  confirmRemoveUser(user: User) {
    this._modalService.confirmation(
      `
    <div class="alert alert-danger">Are you sure you wish to remove this user license?</div>

    <p>User will no longer be able to access Access Ledger and will need to be re-granted access in the future.</p>
    <p></p>
    <p>After clicking Yes the user will immediately no longer have access.</p>`,
      () => this.removeUser(user.id)
    );
  }

  removeUser(userId: string) {
    this._userService
      .delete(userId)
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        })
      )
      .subscribe((value) => {
        this.datasource.setFilter(value as string);
        this.gridApi.setGridOption('datasource', this.datasource);
        this.getLicenseInfo();
      });
  }

  editUser(user: User) {
    this._router.navigate(['./' + user.id], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }
}

class UserDatasource implements IDatasource {
  private _filter;
  busy: boolean;

  constructor(
    private readonly _userService: UserService,
    private readonly _cacheSize: number
  ) {}

  setFilter(filter: string) {
    this._filter = filter;
  }

  getRows(params: IGetRowsParams): void {
    const page = params.startRow / this._cacheSize + 1;
    const sortBy: string = params.sortModel.length
      ? params.sortModel[0].colId
      : null;
    const sortByAsc: boolean = params.sortModel.length
      ? params.sortModel[0].sort === 'asc'
      : true;

    this.busy = true;

    this._userService
      .find(
        this._filter,
        false,
        false,
        false,
        true,
        page,
        this._cacheSize,
        sortBy,
        sortByAsc
      )
      .pipe(finalize(() => (this.busy = false)))
      .subscribe(
        (response) =>
          params.successCallback(response.records, response.recordsCount),
        () => params.failCallback()
      );
  }
}
