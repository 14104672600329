<div class="modal-header">
  <h4 class="modal-title">Move Assets</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

<div class="modal-body">

  <div class="content">
    <p>The {{params.length | formatNumber:0}} selected assets will be allocated to the below pool for the {{assetsContext?.year?.year}} year.</p>

    <div class="row">
      <div class="col-xl-4 col-lg-6">
        <div class="form-group required">
          <label class="control-label">Depreciation Pool</label>
          <ng-select [items]="depreciationPools | enumToArray" bindValue="value" bindLabel="name" formControlName="depreciationPool" [clearable]="false"></ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-8 col-md-10">

        <div class="row">
          <div class="col-label"></div>
          <div class="col-control col-header">Taxation</div>
          <div class="col-toggle"></div>
          <div class="col-control col-header">Accounting</div>
        </div>

        <!-- Disposal Value -->
        <div class="row">
          <label class="col-label col-form-label">Allocate to Pool?</label>
          <div class="col-control">
            <div class="form-check text-center" [formGroup]="taxation">
              <input type="checkbox" class="form-check-input" formControlName="addToPool">
            </div>
          </div>
          <div class="col-toggle">
            <crs-asset-difference-toggle key="addToPool" [parent]="component"></crs-asset-difference-toggle>
          </div>
          <div class="col-control">
            <div class="form-check text-center" [formGroup]="accounting">
              <input type="checkbox" class="form-check-input" formControlName="addToPool" disabled *ngIf="!toggles['addToPool']">
              <input type="checkbox" class="form-check-input" formControlName="addToPool" *ngIf="toggles['addToPool']">
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="spacer-lg"></div>

    <p class="alert alert-warning">Note, assets which already belong to a pool will be skipped. This action cannot be undone.</p>

  </div>

  <crs-form-error [error]="error"></crs-form-error>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-primary" [promiseBtn]="busy.submit">
    Allocate Assets to Pool
  </button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
</div>

</form>
