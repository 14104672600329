import { ModalService } from 'src/app/core';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, of, empty, EMPTY, Subscription } from 'rxjs';
import { map, exhaustMap, finalize, catchError, tap } from 'rxjs/operators';

import { MessageService, SessionService } from '../../../core';
import { Client, ClientService, ClientModel } from '../../';
import { OfficeSelectComponent } from '../../offices/office-select/office-select.component';

enum Action {
  Submit,
  Delete,
}

@Component({
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit, OnDestroy {
  @ViewChild(OfficeSelectComponent) private officeSelect: OfficeSelectComponent;

  @Input() id: string;
  isAdd: boolean;
  objectTitle = 'Client Group';
  busy = {
    load: null,
    submit: null,
    delete: null,
  };
  form = this.formBuilder.group({
    code: ['', [Validators.maxLength(10)]],
    name: ['', Validators.required],
    office: [null, Validators.required],
    team: [null],
    partner: [null],
    manager: [null],
  });
  error: string = null;

  submitButtonStream = new Subject<Action>();
  subscriptions: Subscription[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private clientService: ClientService,
    private messageService: MessageService,
    private sessionService: SessionService
  ) {}

  ngOnInit() {
    this.configureSubmit();
    this.isAdd = this.id === 'add';
    if (this.isAdd) {
      const office = this.sessionService.user.offices[0];
      this.form.patchValue({ office: office });
    } else {
      this.busy.load = this.clientService.get(this.id).subscribe(
        (data) => {
          this.form.patchValue(data);
        },
        (err) => this.showError(err)
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  configureSubmit() {
    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap((action) =>
            action === Action.Delete
              ? this.deleteObservable()
              : this.submitObservable()
          )
        )
        .subscribe((client) => {
          this.activeModal.close(client);
        })
    );
  }

  submit() {
    this.submitButtonStream.next(Action.Submit);
  }

  delete() {
    this.modalService.confirmation(
      'This action cannot be undone. Are you sure you want to delete this client group?',
      () => this.submitButtonStream.next(Action.Delete),
      true
    );
  }

  submitObservable(): Observable<Client> {
    let observable: Observable<Client>;
    const client = new Client(this.form.value);
    const clientModel = new ClientModel(client);
    if (this.isAdd) {
      observable = this.clientService.post(clientModel).pipe(
        map((id) => {
          client.id = id;
          return client;
        })
      );
    } else {
      clientModel.id = this.id;
      observable = this.clientService.put(clientModel).pipe(map(() => client));
    }

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        // tslint:disable-next-line: deprecation
        return empty();
      }),
      finalize(() => loadingStream.complete())
    );
  }

  deleteObservable(): Observable<any> {
    if (this.isAdd) return EMPTY;
    const loadingStream = new Subject<void>();
    this.busy.delete = loadingStream.subscribe();

    return this.clientService.delete(this.id).pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }
}
