<div class="create-container p-3">
  <form [formGroup]="formGroup" (validSubmit)="onSubmit()">
    <div class="form-container row">
      <div class="inner-container col-12">
        <h3 class="control-label mb-3">Create Feed Collection</h3>
        <div class="form-group required">
          <label class="control-label">Feed Collection Name</label>
          <input
            class="form-control"
            formControlName="feedName"
            placeholder="Feed Collection Name"
            type="text"
          />
        </div>
        <div class="form-group">
          <label class="control-label"
            >Reference
            <a
              class="btn-sm fas-btn-primary-ghost"
              placement="left"
              popoverTitle="Information"
              [ngbPopover]="referencePopoverConcent"
              [routerLink]="[]"
            >
              <i aria-hidden="true" class="fas fa-info-circle"></i>
            </a>
          </label>
          <input
            class="form-control"
            formControlName="billingRef"
            placeholder="Reference"
            type="text"
          />
        </div>
        <button
          class="btn btn-primary my-1 w-100"
          type="submit"
          [disabled]="!formValid"
          [promiseBtn]="busy.submit"
        >
          Create Collection
        </button>
      </div>
      <crs-form-error [error]="error"></crs-form-error>
    </div>
  </form>
</div>

<ng-template #referencePopoverConcent>
  <p>Reference is displayed in Billing Report</p>
</ng-template>
