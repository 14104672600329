import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../../core';
import { PolicyVariant } from './policyVariant';

@Injectable({
  providedIn: 'root'
})
export class PolicyVariantService {

  readonly base = 'api/accounting/reports/notes/policies/variants';

  constructor(private readonly _apiService: ApiService) { }

  get(id: string) {
    return this._apiService.get<PolicyVariant>(this.base + '/' + id).pipe(map(c => new PolicyVariant(c)));
  }

  post(policyVariant: PolicyVariant): Observable<string> {
    return this._apiService.post<string>(this.base, policyVariant);
  }

  createInherited(variant: PolicyVariant): Observable<string> {
    return this._apiService.post<string>(this.base + '/inherit', variant);
  }

  reorderFilePolicyVariant(policyVariantId: string, behindPolicyVariantId: string) {
    return this._apiService.put(this.base + '/' + policyVariantId + '/reorder/file', behindPolicyVariantId);
  }

  confirmMasterChangesChecked(policyVariantId: string) {
    return this._apiService.put(this.base + '/' + policyVariantId + '/confirmMasterChangesChecked', null);
  }

  put(policyVariant: PolicyVariant) {
    return this._apiService.put(this.base + '/' + policyVariant.id, policyVariant);
  }

  delete(id: string) {
    return this._apiService.delete(this.base + '/' + id);
  }

}
