<p *ngIf="!auditContent || !auditContent.length">There are no assets that belong to this label.</p>

<div style="overflow: hidden; flex-grow: 1" *ngIf="auditContent && auditContent.length">
  <ag-grid-angular #agGrid
                   style="width: 100%;"
                   class="ag-theme-material"
                   [gridOptions]="gridOptions"
                   [rowData]="auditContent"
                   [groupIncludeTotalFooter]="true">
    <ag-grid-column headerName="Group" field="asset.assetGroupName" [rowGroup]="true" [hide]="true" [enableRowGroup]="true"></ag-grid-column>
    <ag-grid-column headerName="Code" field="asset.code"></ag-grid-column>
    <ag-grid-column headerName="Identifier" field="asset.identifier" [hide]="true"></ag-grid-column>
    <ag-grid-column headerName="Value" field="value" type="dollarColumn" [cellRendererParams]="assetReportDollarParams"></ag-grid-column>
  </ag-grid-angular>
</div>
