import { ReportContentInfo } from './../../report-content/report-content-info';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Image } from '../../../admin/images/image.class';

import { Alignment } from '..';
import { ReportImage } from './report-image';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ReportMetaData } from '../../report-output';
import { ImageService } from '../../../admin/images/image.service';

@Component({
    selector: 'crs-report-image',
    styleUrls: ['./report-image.component.scss'],
    templateUrl: './report-image.component.html'
})
export class ReportImageComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup;
    component = this;

    @Input() element: ReportImage;
    @Input() index: number;
    @Input() reportContent: ReportContentInfo;
    @Input() reportMetaData: ReportMetaData;

    subscriptions: Subscription[] = [];

    get image() {
      return this.element.image as Image;
    }
    get height() {
      if (this.element.height) return this.element.height + 'px';
      if (!this.element.width && this.image && this.image.defaultHeight) return this.image.defaultHeight + 'px';
      return 'auto';
    }
    get width() {
      if (this.element.width) return this.element.width + 'px';
      if (!this.element.height && this.image && this.image.defaultWidth) return this.image.defaultWidth + 'px';
      return 'auto';
    }

    imageData$: Observable<string>;

    alignments = Alignment;

    isEditing = false;
    isCustom = this.formBuilder.control(false);

    constructor(readonly formBuilder: UntypedFormBuilder, readonly imageService: ImageService) { }

    ngOnInit() {
      const sub = this.isCustom.valueChanges.pipe(tap(v => {
        this.form.get('image').setValidators(!v ? Validators.required : null);
        this.form.get('customUrl').setValidators(v ? Validators.required : null);
      })).subscribe();
      this.subscriptions.push(sub);
      if (this.element.image)
        this.imageData$ = this.imageService
          .get(this.element.image.id)
          .pipe(map((image) => image.imageData));
    }

    ngOnDestroy() {
      this.subscriptions.forEach(i => i.unsubscribe());
    }

    onEdit() {
        this.form = this.formBuilder.group({
            customUrl: [null],
            image: [null],
            alignment: [Alignment.Left],
            marginTop: [false],
            marginBottom: [false],
            height: [null],
            width: [null],
        });
        this.form.patchValue(this.element);
        this.isCustom.setValue(!!this.element.customUrl);
        this.isEditing = true;
    }

    onSubmit = () => {
        this.form.markAsTouched();
        if (!this.form.valid) return false;

        Object.assign(this.element, this.form.value);
        if (this.isCustom.value) {
          this.element.image = null;
        } else {
          this.element.customUrl = null;
          this.imageData$ = this.imageService
            .get(this.element.image.id)
            .pipe(map((image) => image.imageData));
        }
        if (!this.element.height) this.element.height = null;
        if (!this.element.width) this.element.width = null;

        return true;
    }

    onCancel() {
        this.isEditing = false;
        this.form = null;
    }

}
