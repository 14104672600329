import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

import { Journal, JournalType } from '../../ledger';
import { ModalService, MessageService } from '../../../core';
import { getDefaultGridOptions, SentencePipe } from '../../../shared';
import { JournalCategory } from '../../ledger/journals/journalCategory';
import { JournalService } from '../../ledger/journals/journal.service';
import { AutoJournalService } from './../../ledger/auto-journals/auto-journal.service';

@Component({
  selector: 'crs-dataset-journals',
  templateUrl: './dataset-journals.component.html',
  styleUrls: ['./dataset-journals.component.scss'],
  providers: [SentencePipe],
})
export class DatasetJournalsComponent implements OnInit {
  datasetId: string;
  collapsed: boolean;

  busy = {
    journals: false,
    autoJournals: null,
  };
  journalsObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();
  journalTypes = JournalType;
  journalCategories = JournalCategory;
  grouped = true;

  constructor(
    private journalService: JournalService,
    private readonly autoJournalService: AutoJournalService,
    public route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private messageService: MessageService,
    private sentencePipe: SentencePipe
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.datasetId = this.route.snapshot.parent.paramMap.get('id');
      this.getJournals();
    });
  }

  getJournals() {
    this.busy.journals = true;
    this.journalsObservable = this.journalService
      .getForDataset$(this.datasetId)
      .pipe(tap(() => (this.busy.journals = false)));
  }

  addJournal() {
    this.router.navigate(['./add'], { relativeTo: this.route });
  }

  selectJournal(param) {
    if (!param.node.isSelected()) return;
    this.router.navigate(['./', param.data.id], { relativeTo: this.route });
  }

  deleteJournal(journal: Journal) {
    const journalName =
      journal.journalType === JournalType.Journal
        ? '<strong>Journal No ' + journal.journalNo + '</strong>'
        : 'this <strong>' +
          this.sentencePipe.transform(JournalType[journal.journalType]) +
          '</strong>';
    this.modalService.confirmation(
      'Are you sure you want to delete ' +
        journalName +
        '? This action cannot be undone.',
      () => {
        this.journalService.delete$(journal.id).subscribe(
          () => this.gridOptions.api.applyTransaction({ remove: [journal] }),
          (err) => this.messageService.error(err)
        );
      },
      true
    );
  }

  // prepareBalancingJournal() {
  //   this.router.navigate(['../balances/trial-balances'], {
  //     queryParams: {journalCategory: JournalCategory.PendingExport},
  //     relativeTo: this.route
  //   });
  // }

  processAutoJournals() {
    this.busy.autoJournals = this.autoJournalService
      .processAll$(this.datasetId)
      .pipe(
        tap((r) => {
          if (r) this.messageService.success(r);
        }),
        finalize(() => {
          this.getJournals();
        })
      )
      .subscribe(
        () => true,
        (err) => this.showError(err)
      );
  }

  showError(err) {
    this.messageService.error(err);
  }
}
