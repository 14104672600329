import { Directive, DoCheck, ElementRef, Inject, Input, IterableDiffer, IterableDiffers, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ElementSizeMultiplier } from 'src/app/accounting/reports/enums';
import { TableRowType } from '../enums';
import { IReportTableConfig } from '../interfaces';
import { REPORT_TABLE_CONFIG } from '../report-table.contants';

@Directive({
  selector: '[crsCellSize]'
})
export class CellSizeDirective implements OnChanges, DoCheck {

  @Input('crsCellSize') width = this._config.cellDefaultWidth;
  @Input('crsRowType') rowType: TableRowType;
  @Input('crsRowHeight') height = this._config.cellDefaultHeight;
  @Input('crsRowLevel') level: number;
  @Input('crsColumnDefs') columnDefs = [];
  @Input('crsIsEditMode') isEditMode = false;

  private _differ: IterableDiffer<[]>;

  constructor(private element: ElementRef,
              private _render: Renderer2,
              private _differs: IterableDiffers,
              @Inject(REPORT_TABLE_CONFIG) private readonly _config: IReportTableConfig) {
    this._differ = this._differs.find([]).create(null);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.width || !!changes.isEditMode || !!changes.rowType || !!changes.columnDefs) {
      this._updateRowWidths();
    }

    if (!!changes.height || !!changes.rowType || !!changes.level) {
      this._updateRowHeight();
    }
  }

  public ngDoCheck(): void {
    if (this._differ) {
      const changes = this._differ.diff(this.columnDefs);
      if (changes) {
        this._updateRowWidths();
      }
    }
  }

  private _updateRowWidths(): void {
    const widths = [];
    if (this.isEditMode) {
      widths.push('23px');
    }

    this.columnDefs.forEach(c => widths.push(
        (c.widthType === ElementSizeMultiplier['auto'] || parseInt(c.widthType) ===  ElementSizeMultiplier['auto'])
          ? '1fr' : `${c.width}px`));
    const gridTemplateColumns = widths.join(' ');
    if (this.element.nativeElement.parentElement.style?.gridTemplateColumns !== gridTemplateColumns) {
      this._render.setStyle(this.element.nativeElement.parentElement, 'grid-template-columns', gridTemplateColumns);
    }
  }

  private _updateRowHeight(): void {
    const heightValue = this.rowType === TableRowType.Spacer ? `${this.height ?? this._config.cellDefaultHeight}px` : '';

    if (this.element.nativeElement.parentElement.style?.height !== heightValue) {
      this._render.setStyle(this.element.nativeElement.parentElement, 'height', heightValue);
    }
  }
}
