import { Component, Input } from '@angular/core';

@Component({
  selector: 'crs-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss'],
})
export class StepProgressBarComponent {
  @Input() currentStep: number = 0;
  @Input() totalSteps: number = 3;

  get steps() {
    return new Array(this.totalSteps);
  }

  constructor() {}
}
