import { AccountingCoreModule } from './../../../../core/accounting-core.module';
import {NgModule} from '@angular/core';
import {
  FinancialEditableElementComponent,
  FinancialElementEditorComponent,
  FinancialFormulaComponent,
  ReportFinancialColumnHeaderRowComponent,
  ReportFinancialSpacerComponent,
  ReportFinancialRowComponent,
  ReportFinancialTotalComponent,
  ReportFinancialHeaderComponent,
  FinancialFormulaEditorComponent,
  FinancialInsertElementComponent
} from './';

import {SharedModule} from '../../../../../shared/shared.module';

const components = [
  FinancialFormulaComponent,
  FinancialFormulaEditorComponent,
  FinancialEditableElementComponent,
  FinancialInsertElementComponent,
  FinancialElementEditorComponent,
  ReportFinancialColumnHeaderRowComponent,
  ReportFinancialSpacerComponent,
  ReportFinancialRowComponent,
  ReportFinancialTotalComponent,
  ReportFinancialHeaderComponent
];

@NgModule({
  declarations: [components],
  imports: [
    SharedModule,
    AccountingCoreModule
  ],
  exports: [components]
})
export class FinancialModule {

}
