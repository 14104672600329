import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ModalService } from './../../../../../../core/modals/modal.service';
import { FinancialContentInfo } from './../../report-financial/report-content-info';
import { FinancialElementEditorComponent } from '../financial-element-editor/financial-element-editor.component';

@Component({
  selector: 'crs-financial-editable-element',
  templateUrl: './financial-editable-element.component.html',
  styleUrls: ['./financial-editable-element.component.scss']
})
export class FinancialEditableElementComponent {

  @Input() editable = true;
  @Input() element: any;
  @Input() index: number;
  @Input() financialContent: FinancialContentInfo;

  @Input() onSubmit: () => boolean;
  @Output() cancel = new EventEmitter<void>();

  constructor(private readonly _modalService: ModalService) { }

  insert() {
    this.financialContent.content.splice(this.index, 0, {});
  }

  edit() {
    if (this.editable) {
      this._modalService.openModal(FinancialElementEditorComponent, null, { output: this.financialContent.output, element: this.element })
        .then(r => true)
        .catch(() => true);
    }
  }

  delete() {
    this.financialContent.content.splice(this.index, 1);
  }

}
