import { Component, Input } from '@angular/core';

@Component({
  selector: 'crs-report-footer',
  templateUrl: './report-footer.component.html',
  styleUrls: [ './report-footer.component.scss' ]
})
export class ReportFooterComponent {
  @Input() activePageIndex: number;
  @Input() footer: any;
}
