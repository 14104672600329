import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';

import { getDefaultGridOptions } from 'src/app/shared';
import { ActiveFileService } from '../..';
import { ESignatureService } from '../esignature.service';

@Component({
  selector: 'crs-esignature-request-history',
  templateUrl: './esignature-request-history.component.html',
  styleUrls: ['./esignature-request-history.component.scss']
})
export class ESignatureRequestHistoryComponent implements OnInit {
  busy = {
    load: null
  };
  error: string;
  requestHistories = [];
  gridOptions = getDefaultGridOptions();

  constructor(private readonly _eSignatureService: ESignatureService,
    public route: ActivatedRoute,
    private router: Router,
    private activeFileService: ActiveFileService
  ) { }

  ngOnInit(): void {
    this.getRequestHistoires();
  }

  getRequestHistoires() {
    let fileId = this.activeFileService.file.id;
    this.busy.load = this._eSignatureService.getRequestHistories(fileId).pipe(
      tap(histories => this.requestHistories = histories),
      catchError(err => {
        this.showError(err);
        return of([]);
      })).subscribe();
  }

  close() {
    this.router.navigate(['../../e-signature'], { relativeTo: this.route });
  }

  showError(error) {
    this.error = error;
  }

}
