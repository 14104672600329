<div class="d-flex justify-content-between">
  <div class="w-100">
    <input
      #input
      class="form-control"
      type="text"
      [(ngModel)]="value"
      [placeholder]="transaction.originalDescription ?? ''"
    />
  </div>
  <div>
    <button
      class="btn"
      *ngIf="showRevertBtn && transaction.originalDescription"
      (click)="revertOriginalValue()"
    >
      <i
        aria-hidden="true"
        class="fas fa-undo pt-1"
        [title]="transaction.originalDescription ?? ''"
      ></i>
    </button>
  </div>
</div>
