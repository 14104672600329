import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Image, ImageModel } from './image.class';
import { ApiService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    private _dataUrl = 'api/accounting/reports/images';

    constructor(private _apiService: ApiService) { }

    public getAll(fileId?: string): Observable<Image[]> {
      let url = this._dataUrl;
      url = this._apiService.addQuery(url, 'fileId', fileId);
        return this._apiService.get<Image[]>(url).pipe(map(r => r.map(i => new Image(i))));
    }

    public get(id: string): Observable<Image> {
      return this._apiService.get<Image>(`${this._dataUrl}/${id}`).pipe(map(i => new Image(i)));
    }

    public post(image: ImageModel): Observable<boolean> {
        return this._apiService.post<boolean>(this._dataUrl, image);
    }

    public postVariants(images: ImageModel[]): Observable<boolean> {
        return this._apiService.post<boolean>(`${this._dataUrl}/variants`, images);
    }

    public put(image: ImageModel): Observable<any> {
        return this._apiService.put(`${this._dataUrl}/${image.id}`, image);
    }

    public delete(id: string): Observable<boolean> {
        return this._apiService.delete<boolean>(`${this._dataUrl}/${id}`);
    }
}
