<div class="row mb-3 pad-x">
  <div class="col-lg-12 account-toolbar">
    <div class="btn-toolbar d-flex align-items-center" role="toolbar">
      <div class="mt-1">
        <div class="form-check">
          <input
            class="form-check-input"
            id="showAllCheck"
            type="checkbox"
            [checked]="accountContext.showDetailed"
            (click)="onClickShowSourceAccounts()"
          />
            Show Source Accounts
        </div>
      </div>

      <div class="vertical-line-toolbar mx-3"></div>

      <div class="icon-input-wrapper flex-grow-1 me-2">
        <i aria-hidden="true" class="fas fa-search input-icon"></i>
        <input
          aria-describedby="searchIcon"
          placeholder="Search..."
          type="search"
          [formControl]="search"
        />
      </div>

      <div class="btn-group d-inline-block me-2" role="group">
        <button
          class="btn btn-secondary"
          title="Expand All"
          (click)="allocatedGridMgr.expandAll(); unallocatedGridMgr.expandAll()"
        >
          <i aria-hidden="true" class="far fa-plus-square"></i>
        </button>
        <button
          class="btn btn-secondary"
          title="Collapse All"
          (click)="
            allocatedGridMgr.collapseAll(); unallocatedGridMgr.collapseAll()
          "
        >
          <i aria-hidden="true" class="far fa-minus-square"></i>
        </button>
      </div>

      <div class="matching-rule-set-select me-2">
        <ng-select
          bindLabel="name"
          bindValue="id"
          placeholder="Default Matching Rules"
          [(ngModel)]="accountContext.matchingRuleSetId"
          [clearable]="false"
          [items]="matchingRuleSets"
          [virtualScroll]="true"
          (change)="refresh()"
        >
        </ng-select>
      </div>

      <div class="btn-group me-2">
        <button
          class="btn btn-secondary"
          title="Apply the Matching Rules to auto allocate accounts"
          type="button"
          [promiseBtn]="observables.autoMatch"
          (click)="autoMatch()"
        >
          Auto Allocate
        </button>
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
          data-bs-toggle="dropdown"
          type="button"
        >
          <span class="sr-only">Auto Match Options</span>
        </button>
        <div class="dropdown-menu">
          <a
            class="dropdown-item"
            [routerLink]="[
              '../../../',
              accountContext.fileId,
              'matching-rule-sets',
              accountContext.matchingRuleSetId,
              'matching-rules',
            ]"
          >
            Manage
            {{ getMatchingRuleSetName(accountContext.matchingRuleSetId) }}
          </a>
          <button
            class="dropdown-item"
            type="button"
            (click)="createInstantRule()"
          >
            Create Instant Rule
          </button>
        </div>
      </div>

      <button
        class="btn btn-secondary"
        title="Collapse All"
        title="Refresh"
        (click)="refresh()"
      >
        <i aria-hidden="true" class="fas fa-sync-alt"></i>
      </button>

      <div class="form-inline ms-5" *ngIf="accountContext.isConsolidated">
        <label class="me-2">Terminology</label>
        <ng-select
          bindLabel="name"
          bindValue="id"
          [clearable]="false"
          [formControl]="entityType"
          [items]="entityTypes"
        />
      </div>

      <div class="class-select ms-2">
        <ng-select
          bindLabel="name"
          bindValue="value"
          placeholder="Class"
          [(ngModel)]="accountContext.searchClassification"
          [items]="classifications | enumToArray: true"
          [virtualScroll]="true"
          (change)="refresh()"
        />
      </div>

      <div class="account-type-select ms-2">
        <ng-select
          bindLabel="name"
          bindValue="id"
          placeholder="Account type"
          [(ngModel)]="accountContext.accountTypeId"
          [items]="accountTypes"
          [virtualScroll]="true"
          (change)="refresh()"
        >
          <ng-template let-index="index" let-item="item" ng-option-tmp>
            <crs-truncated-name
              [length]="18"
              [name]="item.name"
            ></crs-truncated-name>
          </ng-template>
        </ng-select>
      </div>

      <div class="ms-2">
        <button
          aria-label="Clear filters"
          class="btn"
          type="button"
          [disabled]="
            !accountContext.accountTypeId &&
            !accountContext.searchClassification &&
            !searchHasValue()
          "
          (click)="onClearFilters()"
        >
          Clear filters
        </button>
      </div>
    </div>
  </div>
</div>

<hr style="margin: 0" />

<div class="row pad-x">
  <!-- allocated table left -->
  <div class="col-md-7 pe-0 d-flex">
    <div class="allocated-table">
      <crs-progress-bar id="allocatedBar"></crs-progress-bar>

      <crs-busy *ngIf="busy.allocated"></crs-busy>

      <ag-grid-angular
        #allocatedGrid
        class="ag-theme-material ag-theme-material-hierarchy"
        style="width: 100%; height: 100%"
        [autoGroupColumnDef]="allocatedGroupColumnDef"
        [getRowClass]="getRowClass"
        [gridOptions]="allocatedGridMgr.gridOptions"
      />
    </div>

    <div class="vertical-line-full mx-2"></div>
  </div>

  <!-- unallocated table right -->
  <div class="col-md-5 ps-0">
    <div class="unallocated-table">
      <crs-progress-bar id="unallocatedBar"></crs-progress-bar>

      <crs-busy *ngIf="busy.unallocated"></crs-busy>

      <ag-grid-angular
        #unallocatedGrid
        class="ag-theme-material ag-theme-material-hierarchy"
        style="width: 100%; height: 100%"
        [autoGroupColumnDef]="unallocatedGroupColumnDef"
        [getRowClass]="getRowClass"
        [gridOptions]="unallocatedGridMgr.gridOptions"
      />
    </div>
  </div>

  <ng-template #optionsCell let-row>
    <div class="btn-mini-menu" *ngIf="row?.isHeader">
      <a
        aria-haspopup="true"
        class="btn btn-sm fas-btn-primary-ghost"
        data-bs-toggle="dropdown"
        [routerLink]="[]"
      >
        <i aria-hidden="true" class="fas fa-ellipsis-v"></i>
        <span class="sr-only"> Show Header Account Options </span>
      </a>
      <div class="dropdown-menu">
        <button
          class="btn dropdown-item text-info"
          type="button"
          (click)="addHeader(row)"
        >
          <i aria-hidden="true" class="fas fa-stream"></i>
          Add Custom Header
        </button>
        <button
          class="btn dropdown-item text-info"
          type="button"
          *ngIf="row.isSortable"
          (click)="editHeader(row)"
        >
          <i aria-hidden="true" class="fas fa-edit"></i>
          Edit
        </button>
        <button
          class="btn dropdown-item"
          type="button"
          (click)="orderBy(row, 0)"
        >
          <i aria-hidden="true" class="fas fa-sort-numeric-down"></i>
          Sort by Account Number
        </button>
        <button
          class="btn dropdown-item"
          type="button"
          (click)="orderBy(row, 1)"
        >
          <i aria-hidden="true" class="fas fa-sort-alpha-down"></i>
          Sort by Account Name
        </button>
        <button
          class="btn dropdown-item text-danger"
          type="button"
          *ngIf="row.isSortable"
          (click)="deleteHeader(row)"
        >
          <i aria-hidden="true" class="fas fa-trash"></i>
          Delete
        </button>
      </div>
    </div>
    <div
      class="btn-mini-menu"
      *ngIf="!row?.isHeader && !row?.isSystemAccount && !row?.isSourceAccount"
    >
      <a
        class="fas-btn-primary-ghost"
        [routerLink]="[]"
        (click)="editAccount(row)"
      >
        <i aria-hidden="true" class="fas fa-edit"></i>
      </a>
    </div>
  </ng-template>
</div>
