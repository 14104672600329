import { Injectable } from '@angular/core';
import {
  ReportOutput,
  ReportPageOutput,
} from 'src/app/accounting/reports/reportViewer/report-output';
import { DatePipe } from '@angular/common';

export type PageColumnInput = { title: string; width: number; align?: number };
export type CellInput = { value: any; valueType?: number; alignment?: number };

export enum TableRowType {
  ColumnHeader = 0,
  Row = 1,
  SubTotal = 2,
  GrandTotal = 3,
  Header = 4,
  GroupTotal = 5,
  HeaderColumnGroups = 6,
  Total = 7,
  Spacer = 8,
  AutoTotal = 9,
  AutoGrandTotal = 10,
}

@Injectable({
  providedIn: 'root',
})
export class ReportOutputBuilder {
  constructor(private readonly _datePipe: DatePipe) {}

  createReportOutput(data: {
    fileId: string;
    dateFormat: string;
    title?: string;
    branding?: any;
    startDate?: Date;
    endDate?: Date;
    office?: any;
    entity?: any;
    partner?: any;
    reportHeaderColumns?: any[];
    pages: ReportPageOutput[];
  }): ReportOutput {
    const {
      fileId,
      pages,
      title = 'Report',
      branding = { primaryColour: '#000000' },
      startDate = new Date(),
      endDate = new Date(),
      office = {},
      entity = {},
      partner = {},
      reportHeaderColumns = [],
    } = data;

    return new ReportOutput({
      branding,
      style: {},
      title,
      fileId,
      created: new Date(),
      office,
      entity,
      startDate,
      endDate,
      isBothAuditorDeclarationAndAuditorReportIncluded: false,
      isAuditorRequired: false,
      isAuditorSMSFRequired: false,
      partner,
      warnings: [],
      reportHeaderColumns,
      hasCharts: false,
      reportSignStatus: '0',
      url: null,
      pagesWithWarnings: [],
      pages,
    });
  }

  createPageColumns(columns: PageColumnInput[]) {
    return columns.map(({ title, width, align = 1 }) => ({
      title,
      width,
      widthType: 2,
      valueType: 0,
      align,
      numberFormat: 0,
      rounding: '0',
      datasetId: null,
      startDate: '0001-01-01T00:00:00',
      endDate: '0001-01-01T00:00:00',
    }));
  }

  createCell(value: any, valueType: number = 0, alignment: number = 1) {
    return {
      cellValue: value,
      colSpan: '1',
      valueType,
      alignment,
      numberFormat: 0,
      rounding: '0',
      textUnderline: null,
      textBold: null,
      textItalic: null,
      cellType: null,
      totalDefinition: null,
      parentColumn: null,
      elementIdType: 0,
      elementId: null,
    };
  }

  createHeaderRow(headerCells: CellInput[]) {
    return {
      height: 0,
      rowType: TableRowType.ColumnHeader,
      cells: headerCells.map((col) =>
        this.createCell(col.value, col.valueType, col.alignment)
      ),
    };
  }

  createDataRow(dataCells: CellInput[]) {
    return {
      height: 0,
      rowType: TableRowType.Row,
      cells: dataCells.map((cell) =>
        this.createCell(cell.value, cell.valueType, cell.alignment)
      ),
    };
  }

  createDataRows(dataRows: CellInput[][]) {
    return dataRows.map((rowCells) => this.createDataRow(rowCells));
  }
}
