<div
  class="h-100 d-flex align-items-center"
  tabindex="0"
  *ngIf="currentState$ | async as currentState"
>
  <ng-container
    *ngIf="!currentState.isSplit"
    [ngTemplateOutlet]="nonSplit"
    [ngTemplateOutletContext]="{
      canEdit: currentState.canEdit,
      selectedAccount: currentState.selectedAccount
    }"
  >
  </ng-container>
</div>

<ng-template
  #nonSplit
  let-canEdit="canEdit"
  let-selectedAccount="selectedAccount"
>
  <ng-container *ngIf="canEdit">
    <crs-transaction-account-select
      class="w-100"
      [autofocus]="true"
      [clearable]="true"
      [ngModel]="selectedAccount?.accountId"
      [showSplit]="true"
      (change)="onSelectionChanged($event)"
      (splitSelected)="splitTransaction()"
    >
    </crs-transaction-account-select>
  </ng-container>
  <ng-container *ngIf="!canEdit">
    <ng-container *ngIf="displayByAccountCode$ | async; else fullName">
      {{ selectedAccount?.accountNo }}
    </ng-container>
    <ng-template #fullName>
      {{ selectedAccount?.accountName }}
    </ng-template>
  </ng-container>
</ng-template>
