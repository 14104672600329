import { AssetReportTaxAudit } from '../asset-report-tax-return/asset-report-tax-return';
import { Component, OnInit, Input } from '@angular/core';
import { getDefaultGridOptions } from 'src/app/shared';
import { AssetReportGrid } from '../asset-report-grid';

@Component({
  selector: 'crs-asset-report-tax-audit',
  templateUrl: './asset-report-tax-audit.component.html'
})
export class AssetReportTaxAuditComponent implements OnInit {

  @Input() auditContent: AssetReportTaxAudit[];

  gridOptions = getDefaultGridOptions();
  assetReportDollarParams = AssetReportGrid.dollarParams;

  constructor() { }

  ngOnInit() {
    AssetReportGrid.customiseGrid(this.gridOptions);
  }

}
