import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AssetsContextService } from './../../assets-context.service';
import { DepreciationYearService } from '../depreciation-year.service';
import { MessageService, DateService, ModalService } from 'src/app/core';
import { DepreciationYear, DepreciationYearModel } from '../depreciation-year';
import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import {
  catchError,
  distinctUntilChanged,
  tap,
  filter,
  debounceTime,
  take,
} from 'rxjs/operators';
import { EMPTY, pipe, Subscription } from 'rxjs';
import { Dataset } from 'src/app/accounting';
import { DatasetSelectModalComponent } from 'src/app/accounting/datasets/dataset-select-modal/dataset-select-modal.component';
import { getDefaultGridOptions } from 'src/app/shared';

@Component({
  selector: 'crs-depreciation-year',
  templateUrl: './depreciation-year.component.html',
  styleUrls: ['./depreciation-year.component.scss'],
})
export class DepreciationYearComponent implements OnInit, OnDestroy {
  @Input() id: string;

  @Input() set params(value: { priorYear: null; deletable: boolean }) {
    if (!value) return;
    this.priorYear = value.priorYear;
    this.deletable = value.deletable;
  }

  isAdd: boolean;
  objectTitle = 'Depreciation Year';
  priorYear: DepreciationYear;
  deletable = false;

  defaultStartDate: Date;
  defaultEndDate: Date;

  gridOptions = getDefaultGridOptions();

  stripTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  checkIfWithinFiscalYear() {
    const startDate: Date = this.form.controls.startDate?.value;
    const endDate: Date = this.form.controls.endDate?.value;

    if (startDate === null || endDate === null) {
      return false;
    }

    let userStartDate = this.stripTime(startDate).toISOString();
    let userEndDate = this.stripTime(endDate).toISOString();

    const currentYear = startDate.getFullYear();
    const startFY = new Date(currentYear, 6, 1); // July 1st of the current year
    const endFY = new Date(currentYear + 1, 5, 30); // June 30th of the next year

    if (
      userStartDate === startFY.toISOString() &&
      userEndDate === endFY.toISOString()
    ) {
      return true;
    }
    return false;
  }

  busy = {
    load: null,
    submit: null,
    delete: null,
  };
  error: string = null;
  subscriptions: Subscription[] = [];

  form = this.formBuilder.group({
    year: [null, [Validators.required]],
    startDate: [null, [Validators.required]],
    endDate: [null, [Validators.required]],
    taxYear: [null, [Validators.required]],
    useSmallBusinessDepreciation: [false, [Validators.required]],
    useMonthlyBusinessDepreciation: [false, [Validators.required]],
    isLocked: [false, [Validators.required]],
    datasets: [[]],
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private assetsContextService: AssetsContextService,
    private depreciationYearService: DepreciationYearService,
    private messageService: MessageService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.isAdd = this.id === 'add';
    this.getDepreciationYear();
    this.subscriptions.push(
      this.form
        .get('datasets')
        .valueChanges.pipe(
          tap((v) => this.gridOptions.api.setRowData(v)),
          catchError((e) => {
            console.log('Error loading datasets into grid.', e);
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  submit() {
    const depreciationYear = this.form.value as DepreciationYear;
    const model = new DepreciationYearModel(depreciationYear);
    this.error = null;

    if (this.isAdd) {
      model.fileId = this.assetsContextService.currentContext.file.id;
      model.entityId = this.assetsContextService.currentContext.entity.id;
      this.busy.submit = this.depreciationYearService.post(model).subscribe(
        () => this.closeAndReload(true),
        (err) => this.showError(err)
      );
    } else {
      model.id = this.id;
      this.busy.submit = this.depreciationYearService.put(model).subscribe(
        () => this.closeAndReload(),
        (err) => this.showError(err)
      );
    }
  }

  getDepreciationYear() {
    if (this.isAdd) {
      this.ngOnDestroy();
      const financialYear = this.dateService.getLastFinancialYear();
      this.defaultStartDate = this.priorYear
        ? DateHelpers.addDays(this.priorYear.endDate, 1)
        : financialYear.startDate;
      this.defaultEndDate = this.priorYear
        ? DateHelpers.addYears(this.priorYear.endDate, 1)
        : financialYear.endDate;
      this.form.patchValue({
        year: this.priorYear
          ? this.priorYear.year + 1
          : this.defaultEndDate.getUTCFullYear(),
        startDate: this.defaultStartDate,
        endDate: this.defaultEndDate,
        taxYear: this.priorYear
          ? this.priorYear.taxYear + 1
          : this.defaultEndDate.getUTCFullYear(),
        useSmallBusinessDepreciation: this.priorYear
          ? this.priorYear.useSmallBusinessDepreciation
          : true,
        useMonthlyBusinessDepreciation: false,
      });
      // Allow predicting dates if this is the first year
      if (!this.priorYear) {
        this.subscriptions.push(
          this.form.controls['year'].valueChanges
            .pipe(
              debounceTime(300),
              distinctUntilChanged(),
              filter((t) => t > 2000 && t < 2100),
              tap((t) => {
                const fy = this.dateService.getFinancialYear(t);
                this.form.patchValue({
                  startDate: fy.startDate,
                  endDate: fy.endDate,
                  taxYear: t,
                });
              })
            )
            .subscribe()
        );
      }
    }
    if (!this.isAdd) {
      this.busy.load = this.depreciationYearService.get(this.id).subscribe(
        (data) => {
          this.defaultStartDate = data.startDate;
          this.defaultEndDate = data.endDate;
          this.form.patchValue(data);
        },
        (err) => this.showError(err)
      );
    }
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  addDatasets() {
    this.modalService
      .openModal(DatasetSelectModalComponent, null, {
        fileId: this.assetsContextService.currentContext.file.id,
        entityId: this.assetsContextService.currentContext.entity.id,
        startDate: this.form.controls.startDate.value,
        endDate: this.form.controls.endDate.value,
        excludeGroupDatasets: true,
        excludeIds: this.form.controls.datasets.value.map((g) => g.id),
      })
      .then((result) => {
        const array = this.form.controls.datasets.value;
        if (result instanceof Dataset) {
          if (array.all((d) => d.id !== result.id))
            array.push(result as Dataset);
        } else if (result instanceof Array) {
          result.forEach((dataset) => {
            if (dataset instanceof Dataset) {
              if (!array.some((d) => d.id === dataset.id)) {
                array.push(dataset as Dataset);
              }
            }
          });
        }
        this.form.controls.datasets.setValue(array);
      })
      .catch(() => true);
  }

  removeDataset(dataset: Dataset) {
    const array = this.form.controls.datasets.value as Dataset[];
    array.splice(array.indexOf(dataset), 1);
    this.form.controls.datasets.setValue(array);
  }

  delete() {
    this.error = null;
    this.modalService.confirmation(
      `
    <div class="alert alert-danger">Are you absolutely sure you want to delete this year?</div>

    <p>This action <strong>cannot</strong> be undone. This will permanently delete the year and all associated depreciation records.</p>
    `,
      () => this.executeDelete(),
      true,
      this.form.controls['year'].value
    );
  }

  private executeDelete() {
    this.busy.delete = this.depreciationYearService.delete(this.id).subscribe(
      () => this.closeAndReload(),
      (err) => this.showError(err)
    );
  }

  closeAndReload(selectNewYear?: boolean) {
    this.assetsContextService.refreshYears(selectNewYear);
    this.activeModal.close();
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
