import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import {
  BacoLoadableTransactionDto,
  BacoTaxOptionDto,
  getTransactionTaxSelectionsIdentifier,
} from '../../../interfaces';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridApi, ICellEditorParams } from 'ag-grid-community';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  BacoTransactionStore,
  TransactionTaxSelectComponent,
} from 'src/app/baco/components';
import { TransactionService } from 'src/app/baco/services';

@Component({
  selector: 'crs-transaction-tax-code-render',
  templateUrl: './transaction-tax-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionTaxEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  @ViewChild(TransactionTaxSelectComponent, { static: false })
  taxSelect: TransactionTaxSelectComponent;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (
      this.elementRef.nativeElement.parentElement &&
      this.elementRef.nativeElement.parentElement.classList.contains(
        'ag-cell-focus'
      )
    ) {
      if (event.key === 'Escape' && this.currentState$.value.canEdit) {
        this.api.stopEditing();
      }
    }
  }

  public currentState$ = new BehaviorSubject<TaxStateModel>({
    canEdit: false,
    isSplit: false,
    selectedTax: null,
  });

  private params: ICellEditorParams;
  private transaction: BacoLoadableTransactionDto;
  private api: GridApi;

  constructor(
    private readonly transactionService: TransactionService,
    private readonly _transactionStore: BacoTransactionStore,
    private elementRef: ElementRef
  ) {}

  getValue() {
    return getTransactionTaxSelectionsIdentifier(this.transaction);
  }

  public onSelectionChanged(newValue: BacoTaxOptionDto): void {
    this.transactionService.updateTaxCoding(this.transaction, newValue);
    this.params.stopEditing();
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.api = params.api;
    this.setDisplayValue(params.data);
  }

  private setDisplayValue(data: any) {
    this.transaction = data as BacoLoadableTransactionDto;

    this.currentState$.next({
      canEdit: !this.transaction.locked,
      isSplit: this.transaction.accountSelections.length > 1,
      selectedTax: this.transaction.accountSelections[0]?.taxSelection ?? null,
    });
  }

  ngAfterViewInit(): void {
    if (this.taxSelect) {
      this.taxSelect.open();
    }
  }
}

export interface TaxStateModel {
  canEdit: boolean;
  isSplit: boolean;
  selectedTax: BacoTaxOptionDto | null;
}
