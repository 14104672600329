import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ReportMetaData } from '../../report-output';

@Component({
  selector: 'crs-report-snippet',
  templateUrl: './report-snippet.component.html'
})
export class ReportSnippetComponent {

  form: UntypedFormGroup;
  component = this;

  constructor(readonly formBuilder: UntypedFormBuilder) {}

  @Input() element: any;
  @Input() index: number;
  @Input() reportContent: any[];
  @Input() reportMetaData: ReportMetaData;
  isEditing = false;

  onEdit() {
    this.form = this.formBuilder.group({
      name: [null],
      html: [null],
    });
    this.form.patchValue(this.element);
    this.isEditing = true;
  }

  onSubmit = () => {
    this.form.markAsTouched();
    if (!this.form.valid) return false;

    Object.assign(this.element, this.form.value);
    return true;
  }

  onCancel() {
    this.isEditing = false;
    this.form = null;
  }
}
