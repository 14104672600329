import { DateHelpers } from './../../../shared/utilities/date-helpers';
import { DepreciationRecord, DepreciationRecordModel } from './depreciation-record';
import { DepreciationYear } from '../depreciation-years/depreciation-year';
import { DepreciationMethod } from './depreciation-method';

export class DepreciationGroup {
  cost: number;
  costLimit: number;
  commenceDepreciationDate: Date;

  hasOpeningBalances: boolean;
  commenceCalculationYear?: number;
  yearEnteredPool: number;
  openingCarryingAmount: number;
  openingDeclineNotDeducted: number;

  disposalValue: number;
  disposalDate: Date;
  disposalAveragePrivatePercent: number;
  disposalAveragePrivatePercentEditable: number;

  depreciationRecords: DepreciationRecord[];

  constructor(data) {
    Object.assign(this, data);
    this.commenceDepreciationDate = DateHelpers.dateFromApiResponse(data.commenceDepreciationDate);
    this.disposalAveragePrivatePercent = data.disposalAveragePrivatePercent;
    this.disposalAveragePrivatePercentEditable = data.disposalAveragePrivatePercentEditable !== undefined ?
      data.disposalAveragePrivatePercentEditable :
      this.disposalAveragePrivatePercent === null ? null : this.disposalAveragePrivatePercent * 100;
      this.hasOpeningBalances = data.commenceCalculationYear != null;

    if (data.depreciationRecords) {
      this.depreciationRecords = data.depreciationRecords
        .sort((a, b) => a.year - b.year)
        .map(r => new DepreciationRecord(r));
    }
  }

  rebuildDepreciationRecords(years: DepreciationYear[], smallBusinessDefaults: boolean) {
    const firstYear = this.commenceCalculationYear ?
      years.find(y => y.year === this.commenceCalculationYear) :
      years.find(y => y.fallsInYear(this.commenceDepreciationDate));

    if (!firstYear) return [];

    let lastYear = this.disposalDate &&
      this.depreciationRecords.every(r => r.depreciationMethod !== DepreciationMethod.Pool) ?
        years.find(y => y.fallsInYear(this.disposalDate))
        : null;
    if (!lastYear) lastYear = years[years.length - 1];

    // quick exit (efficiency if repeating this frequently)
    if (this.depreciationRecords.length && this.depreciationRecords[0].year === firstYear.year &&
      this.depreciationRecords[this.depreciationRecords.length - 1].year === lastYear. year) return;

    const activeYears = years.filter(y => y.year >= firstYear.year);
    if (!activeYears.length) {
      this.depreciationRecords = [];
      return;
    }

    let ceaseYears = false; // Used to determine if later years should be removed
    activeYears.forEach((year, i) => {
      let recordInPosition = this.depreciationRecords.length <= i ? null : this.depreciationRecords[i];

      if (ceaseYears) {
        // Excessive later records
        if (recordInPosition) this.depreciationRecords.splice(i, 1);
        return;
      }

      // Missing records
      if (!recordInPosition || recordInPosition.year > year.year) {
        this.depreciationRecords.splice(i, 0, DepreciationRecord.buildDefault(year.year, i > 0, smallBusinessDefaults));
      }

      // Excessive earlier records
      while (recordInPosition && recordInPosition.year < year.year) {
        this.depreciationRecords.splice(i, 1);
        recordInPosition = this.depreciationRecords[i];
      }

      ceaseYears = this.disposalDate &&
        recordInPosition.depreciationMethod !== DepreciationMethod.Pool &&
        year.fallsInYear(this.disposalDate);
    });
  }
}

export class DepreciationGroupModel {
  cost: number;
  costLimit: number;
  commenceDepreciationDate: Date;

  commenceCalculationYear: number;
  yearEnteredPool: number;
  openingCarryingAmount: number;
  openingDeclineNotDeducted: number;

  disposalValue: number;
  disposalDate: Date;
  disposalAveragePrivatePercent: number;

  depreciationRecords: DepreciationRecordModel[];

  constructor(data: DepreciationGroup) {

    this.cost = data.cost;
    this.costLimit = data.costLimit;
    this.commenceDepreciationDate = data.commenceDepreciationDate;

    if (data.hasOpeningBalances) {
      this.commenceCalculationYear = data.commenceCalculationYear;
      this.yearEnteredPool = data.yearEnteredPool;
      this.openingCarryingAmount = data.openingCarryingAmount;
      this.openingDeclineNotDeducted = data.openingDeclineNotDeducted;
    }

    this.disposalValue = data.disposalValue;
    this.disposalDate = data.disposalDate;
    this.disposalAveragePrivatePercent = data.disposalAveragePrivatePercent === null ?
      null :
      data.disposalAveragePrivatePercentEditable / 100;

    this.depreciationRecords = data.depreciationRecords.map(r => new DepreciationRecordModel(r));

  }
}
