import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'crs-report-template-root',
  templateUrl: './report-template-root.component.html'
})
export class ReportTemplateRootComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
