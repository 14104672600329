import { RowNode } from 'ag-grid-community';
import { Observable } from 'rxjs';

import { Account } from '../';
import { AccountsViewSourceAccount } from './accounts-view-source-account';
import { IAccountsViewAccount } from './accounts-view-account';

export interface IAccountGridManager {
  id: string;
  visualiseRemove(result: MoveResult);
  visualiseAdd(result: MoveResult);
  restoreBackup();
  createBackup();
  removeRows(accounts: IAccountsViewAccount[]);
  insertRows(overNode: any, accounts: IAccountsViewAccount[], adj: number);
  startLoader();
  completeLoader();
  refreshIdsObservable(ids: string[]): Observable<any>;
  refreshGrid();
  getMoveSource(node): MoveSource;
}

// Represents a request to move the 'moveSource' to the 'moveResult'
export interface IMoveRequest {
  moveSource: MoveSource;
  moveResult: MoveResult;
}

export class MoveRequest implements IMoveRequest {
  moveSource: MoveSource;
  moveResult: MoveResult;
  constructor(moveSource: MoveSource, moveResult: MoveResult) {
    this.moveSource = moveSource;
    this.moveResult = moveResult;
  }
}

export enum MoveAction {
  None = 0,
  Link = 1,
  InsertAbove = 2,
  InsertBelow = 3,
  InsertInto = 4,
}

export class MoveResult {
  action: MoveAction;
  overNode: any;
  gridManager: IAccountGridManager;
  constructor(
    gridManager: IAccountGridManager,
    action: MoveAction,
    overNode: any
  ) {
    this.action = action;
    this.overNode = overNode;
    this.gridManager = gridManager;
  }
  equals(result: MoveResult) {
    return (
      result.action === this.action &&
      result.overNode.id === this.overNode.id &&
      result.gridManager.id === this.gridManager.id
    );
  }
}

export class MoveSource {
  gridManager: IAccountGridManager;
  selectedRow: RowNode = null;
  movingRows: MovingRow[] = [];
  isMovingAHeader: Boolean = false;

  constructor(
    gridManager: IAccountGridManager,
    node: any,
    movingRows: MovingRow[]
  ) {
    this.gridManager = gridManager;
    this.selectedRow = node;
    this.movingRows = movingRows;
    this.isMovingAHeader = movingRows.some((r) => r.account.isHeader);
  }

  getFlattenedRows(): IAccountsViewAccount[] {
    const rows: IAccountsViewAccount[] = [];
    this.movingRows.forEach((r) => {
      rows.push(r.account);
      r.affectedChildren.forEach((c) => rows.push(c));
    });
    return rows;
  }
}

export class DragContext implements IMoveRequest {
  // Is the mouse currently in a 'drag' position at any point?
  isDragging = false;

  moveSource: MoveSource = null;
  moveResult: MoveResult = null;
  previousMoveResult: MoveResult = null;

  // Are we currently dragging a set of accounts?
  get isActiveDrag(): boolean {
    return this.isDragging && !!this.moveSource;
  }

  startDragging(moveSource: MoveSource) {
    this.clear();
    this.moveSource = moveSource;
  }

  setNewMoveResult(result: MoveResult) {
    this.previousMoveResult = this.moveResult;
    this.moveResult = result;
  }

  clear() {
    this.moveSource = null;
    this.previousMoveResult = this.moveResult;
    this.moveResult = null;
  }
}

// A row that is selected for dragging, we store the 'children' of this row here
export class MovingRow {
  account: Account;
  affectedChildren: IAccountsViewAccount[];
  readonly isHeader: boolean;
  constructor(account: Account) {
    this.account = account;
    this.affectedChildren = [];
    this.isHeader = account.isHeader;
  }

  addAffectedChildren(accounts: IAccountsViewAccount[]) {
    if (this.account.isHeader || this.account.sourceAccounts) {
      this.affectedChildren = accounts.filter((a) =>
        this.account.isParentOf(a)
      );
    }
  }
}
