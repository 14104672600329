import { ActivatedRoute } from '@angular/router';
import { DepreciationType } from './../depreciation-type';
import { Dataset } from './../../../datasets/dataset';
import { DepreciationPool } from './../depreciation-pool';
import { Component, Input, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { Subject, Subscription, EMPTY } from 'rxjs';
import {
  startWith,
  pairwise,
  tap,
  distinct,
  distinctUntilChanged,
} from 'rxjs/operators';

import { DepreciationRecordFormSet } from '../asset/depreciation-record-form-set';
import { DepreciationMethod } from '../depreciation-method';

class LinkedFieldSet {
  field: string;
  taxationControl: UntypedFormControl;
  accountingControl: UntypedFormControl;
  private _showTaxation;
  private _showAccounting;
  showTaxation: boolean;
  showAccounting: boolean;
  showNone: boolean;
  showBoth: boolean;
  private inheritedTaxation: boolean;
  private inheritedAccounting: boolean;

  constructor(data) {
    Object.assign(this, data);
    this._showTaxation = !!this.taxationControl;
    this._showAccounting = !!this.accountingControl;
    this.updateShows();
  }

  // Visibility only refers to a field that should be 'shown', but does not need the required validator
  setRequired(
    required: boolean,
    type: DepreciationType,
    includeVisible: boolean = true
  ) {
    if (type === DepreciationType.Taxation) {
      if (includeVisible) this._showTaxation = required;
      if (this.taxationControl)
        this.taxationControl.setValidators(
          required ? Validators.required : null
        );
    } else {
      if (includeVisible) this._showAccounting = required;
      if (this.accountingControl)
        this.accountingControl.setValidators(
          required ? Validators.required : null
        );
    }
    this.updateShows();
  }

  setVisibility(visible: boolean, type: DepreciationType) {
    if (type === DepreciationType.Taxation) {
      this._showTaxation = visible;
    } else {
      this._showAccounting = visible;
    }
    this.updateShows();
  }

  setInherited(inherited: boolean, type: DepreciationType) {
    if (type === DepreciationType.Taxation) this.inheritedTaxation = inherited;
    if (type === DepreciationType.Accounting)
      this.inheritedAccounting = inherited;
    if (inherited) this.setRequired(false, type, false);
    this.updateShows();
  }

  getControl(type: DepreciationType) {
    if (type === DepreciationType.Taxation) return this.taxationControl;
    else return this.accountingControl;
  }

  private updateShows() {
    this.showTaxation = this._showTaxation && !this.inheritedTaxation;
    this.showAccounting = this._showAccounting && !this.inheritedAccounting;
    this.showNone = !this.showTaxation && !this.showAccounting;
    this.showBoth = this.showTaxation && this.showAccounting;
  }
}

class RecordSummary {
  identical: boolean;
  taxationInherited: boolean;
  taxationText: string;
  accountingInherited: boolean;
  accountingText: string;

  set(type: DepreciationType, inherited: boolean, method: DepreciationMethod) {
    const text = inherited ? 'Inherited' : DepreciationMethod[method];
    if (type === DepreciationType.Taxation) {
      this.taxationInherited = inherited;
      this.taxationText = text;
    } else {
      this.accountingInherited = inherited;
      this.accountingText = text;
    }
    this.updateIdentical();
  }

  private updateIdentical() {
    this.identical = this.taxationText === this.accountingText;
  }
}

@Component({
  selector: 'crs-depreciation-record-detail',
  templateUrl: './depreciation-record-detail.component.html',
  styleUrls: ['./depreciation-record-detail.component.scss'],
})
export class DepreciationRecordDetailComponent implements OnDestroy {
  private _formSet: DepreciationRecordFormSet;
  @Input() set formSet(value: DepreciationRecordFormSet) {
    this._formSet = value;
    this.init();
  }
  get formSet() {
    return this._formSet;
  }

  @Input() taxationRefreshStream: Subject<any>;
  @Input() accountingRefreshStream: Subject<any>;

  toggles: any = {};
  fieldSets: {
    inherited: LinkedFieldSet;
    depreciationMethod: LinkedFieldSet;
    rateEditable: LinkedFieldSet;
    depreciationPool: LinkedFieldSet;
    manualDepreciation: LinkedFieldSet;
    residualValue: LinkedFieldSet;
    privatePercentEditable: LinkedFieldSet;
    carryingAmountAdjustment: LinkedFieldSet;
  } = <any>{};

  taxation: UntypedFormGroup;
  accounting: UntypedFormGroup;

  component = this;

  subscriptions: Subscription[] = [];
  depreciationMethods = DepreciationMethod;
  depreciationPools = DepreciationPool;
  recordSummary = new RecordSummary();

  constructor() {}

  ngOnDestroy() {
    this.resetSubscriptions();
  }

  private resetSubscriptions() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  private init() {
    this.taxation = this._formSet.taxation;
    this.accounting = this._formSet.accounting;
    this.refreshTogglesAndFieldSets();
    this.resetSubscriptions();
    this.addSubscriptions();
  }

  private addSubscriptions() {
    this.subscriptions.push(
      this.addDepreciationMethodSubscription(DepreciationType.Taxation)
    );
    this.subscriptions.push(
      this.addDepreciationMethodSubscription(DepreciationType.Accounting)
    );
    this.subscriptions.push(
      this.addInheritedSubscription(DepreciationType.Taxation)
    );
    this.subscriptions.push(
      this.addInheritedSubscription(DepreciationType.Accounting)
    );
  }

  private addInheritedSubscription(type: DepreciationType): Subscription {
    const group =
      type === DepreciationType.Taxation ? this.taxation : this.accounting;
    if (!group) return EMPTY.subscribe();
    const inheritedControl = group.get('inherited');
    return inheritedControl.valueChanges
      .pipe(
        startWith(inheritedControl.value),
        distinctUntilChanged(),
        tap((m) => this.setRecordSummary(type)),
        tap((m) => {
          this.setAllFieldsInherited(m, type);
          if (!m)
            this.updateMethodRequired(
              group.get('depreciationMethod').value,
              type
            );
        })
      )
      .subscribe();
  }

  private addDepreciationMethodSubscription(
    type: DepreciationType
  ): Subscription {
    const group =
      type === DepreciationType.Taxation ? this.taxation : this.accounting;
    if (!group) return EMPTY.subscribe();
    return group
      .get('depreciationMethod')
      .valueChanges.pipe(
        startWith(group.get('depreciationMethod').value),
        distinctUntilChanged(),
        tap((m) => this.setRecordSummary(type)),
        tap((m) => this.updateMethodRequired(m, type)),
        pairwise(),
        tap(([prev, next]: [DepreciationMethod, DepreciationMethod]) => {
          if (
            prev === DepreciationMethod.Pool ||
            next === DepreciationMethod.Pool
          ) {
            const stream =
              type === DepreciationType.Taxation
                ? this.taxationRefreshStream
                : this.accountingRefreshStream;
            if (stream) {
              stream.next(null);
            }
          }
        })
      )
      .subscribe();
  }

  // Updates the required status of the different fields based on the depreciation method selected
  private updateMethodRequired(
    method: DepreciationMethod,
    type: DepreciationType
  ) {
    if (this.fieldSets.inherited.getControl(type).value) return;

    // Rate
    const rateRequired =
      method !== DepreciationMethod.Pool &&
      method !== DepreciationMethod.ImmediateWriteOff &&
      method !== DepreciationMethod.Manual;
    this.fieldSets.rateEditable.setRequired(rateRequired, type);

    // Manual Depreciation
    const manualRequired = method === DepreciationMethod.Manual;
    this.fieldSets.manualDepreciation.setRequired(manualRequired, type);

    // Pool Selection
    const poolRequired = method === DepreciationMethod.Pool;
    this.fieldSets.depreciationPool.setRequired(poolRequired, type);

    // Residual Value
    const residualVisible =
      (method === DepreciationMethod.PrimeCost ||
        method === DepreciationMethod.DiminishingValue) &&
      type === DepreciationType.Accounting;
    this.fieldSets.residualValue.setVisibility(residualVisible, type);
  }

  private refreshTogglesAndFieldSets() {
    if (!this.taxation && !this.accounting) return;
    const bothPresent = !!this.taxation && !!this.accounting;
    const formGroup = this.taxation ? this.taxation : this.accounting;
    this.toggles = {};
    this.fieldSets = <any>{};
    Object.keys(formGroup.controls).forEach((key) => {
      if (bothPresent)
        this.toggles[key] =
          this.taxation.controls[key].value !==
          this.accounting.controls[key].value;
      this.fieldSets[key] = new LinkedFieldSet({
        field: key,
        taxationControl: this.taxation
          ? (this.taxation.controls[key] as UntypedFormControl)
          : null,
        accountingControl: this.accounting
          ? (this.accounting.controls[key] as UntypedFormControl)
          : null,
      });
    });
  }

  private setAllFieldsInherited(inherited: boolean, type: DepreciationType) {
    Object.keys(this.fieldSets).forEach((key) => {
      const fieldSet = this.fieldSets[key] as LinkedFieldSet;
      if (key !== 'inherited') {
        fieldSet.setInherited(inherited, type);
      }
    });
  }

  private setRecordSummary(type: DepreciationType) {
    this.recordSummary.set(
      type,
      this.fieldSets.inherited.getControl(type).value,
      this.fieldSets.depreciationMethod.getControl(type).value
    );
  }
}
