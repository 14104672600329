import { Classification } from '../classifications'

export class AccountType {
  id: number;
  name: string;
  classification: Classification;

  constructor(data) {
    Object.assign(this, data);
  }
}
