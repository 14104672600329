<div class="list-group-item">

  <div class="d-flex justify-content-between align-items-center h-100">

    <div class="clickable d-inline-block flex-grow-1" (click)="selected.emit(client)">

      <div class="d-inline-block">
        <i class="fas fa-folder-open mr-2  fas-btn" aria-hidden="true"></i>
      </div>

      <div class="d-inline-block">
        <strong>{{client.name}}</strong><br/>
        <small>{{client.code}}</small>
      </div>

    </div>

    <div class="d-inline-block ml-auto">
      <div class="clickable" *ngIf="clearable" (click)="clear.emit(true)">
          <i class="far fa-times-circle" aria-hidden="true"></i>
      </div>
    </div>

  </div>

</div>

