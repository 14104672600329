<div class="element-content">
  <div class="buttons-menu" *ngIf="!reportContent.readonly">
    <span (mousedown)="$event.stopPropagation()">
      <button
        aria-label="edit"
        class="round-button menu-button edit-button"
        mat-mini-fab
        type="button"
        *ngIf="editable && !isEditing"
        (click)="setEdit()"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        aria-label="delete"
        class="round-button menu-button delete-button"
        mat-mini-fab
        type="button"
        (click)="delete()"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </span>

    <button
      aria-label="reorder"
      cdkDragHandle
      class="round-button menu-button reorder-button"
      mat-mini-fab
      style="cursor: move"
      type="button"
    >
      <i aria-hidden="true" class="fas fa-arrows-alt fa-sm"></i>
    </button>
  </div>

  <div class="hover-trigger">&nbsp;</div>
  <ng-content *ngIf="!editable"></ng-content>
  <ng-container *ngIf="editable">
    <div (mousedown)="$event.stopPropagation()">
      <ng-container
        *ngTemplateOutlet="
          !reportContent.readonly && isEditing ? editor : viewer
        "
      ></ng-container>
    </div>
  </ng-container>
  <!-- Editor -->
  <ng-template #editor>
    <div class="editor-content">
      <ng-content select="[editor]"></ng-content>
      <div class="d-flex justify-content-end align-items-center h-100">
        <div class="mt-2 pr-2">
          <ng-content select="[actions]"></ng-content>
        </div>

        <div class="btn-menu mt-2" style="text-align: right">
          <button
            class="btn btn-primary btn-sm"
            type="button"
            (click)="submit()"
          >
            <span *ngIf="!cancellable">Close</span
            ><span *ngIf="cancellable">Save</span>
          </button>
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            *ngIf="cancellable"
            (click)="cancel.emit(); isEditing = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- Viewer -->
  <ng-template #viewer>
    <div
      *ngIf="editable"
      [class.can-click-to-edit]="
        !reportContent.readonly && !isEditing && canClickToEdit
      "
      (click)="!canClickToEdit || reportContent.readonly || setEdit()"
    >
      <ng-content select="[viewer]"></ng-content>
    </div>
  </ng-template>
</div>
