import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService, PagedResponse } from '../../core';
import { File, FileModel, FileSearchResult } from './file';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  readonly base: string = 'api/accounting/files';

  constructor(private apiService: ApiService) {}

  get$(id: string) {
    return this.apiService
      .get<File>(this.base + '/' + id)
      .pipe(map((f) => new File(f)));
  }

  getDefaultFileId$(entityId: string): Observable<string> {
    return this.apiService.get<string>(`${this.base}/${entityId}/default`);
  }

  find$(
    search: string,
    page: number,
    pageSize: number,
    orderBy: string = null,
    orderByAsc: boolean = true
  ) {
    let url = this.base;
    url = this.apiService.addQuery(url, 'search', search);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    url = this.apiService.addQuery(url, 'orderBy', orderBy);
    url = this.apiService.addQuery(url, 'orderByAsc', orderByAsc);
    return this.apiService.get<PagedResponse<FileSearchResult>>(url).pipe(
      map((response) => {
        response.records = response.records.map((d) => new FileSearchResult(d));
        return response;
      })
    );
  }

  getForClient$(clientId: string, excludeEntityFiles: boolean = false) {
    let url = this.base + '/for-client/' + clientId;
    url = this.apiService.addQuery(
      url,
      'excludeEntityFiles',
      excludeEntityFiles
    );
    return this.apiService.get<File[]>(url);
  }

  getForEntity$(entityid: string) {
    return this.apiService.get<File[]>(this.base + '/for-client/' + entityid);
  }

  post$(model: FileModel) {
    return this.apiService.post<string>(this.base, model);
  }

  put$(model: FileModel) {
    return this.apiService.put<string>(this.base + '/' + model.id, model);
  }

  addEntity$(id: string, entityId: string) {
    return this.apiService.put<File>(
      this.base + '/' + id + '/add-entity/' + entityId,
      null
    );
  }

  removeEntity$(id: string, entityId: string) {
    return this.apiService.put<File>(
      this.base + '/' + id + '/remove-entity/' + entityId,
      null
    );
  }

  postDefault$(model: FileModel) {
    return this.apiService.post<string>(this.base + '/default', model);
  }

  delete$(id: string, fileName: string) {
    let url = this.base + '/' + id;
    url = this.apiService.addQuery(url, 'fileName', fileName);
    return this.apiService.delete(url);
  }
}
