import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { TradingAccountComponent } from '../trading-account/trading-account.component';
import { TradingAccountService, TradingAccountLocation } from '../';
import { ModalService, MessageService } from '../../../../core';
import { getDefaultGridOptions, accountingRenderers } from '../../../../shared';

@Component({
  selector: 'crs-trading-accounts',
  templateUrl: './trading-accounts.component.html',
  styleUrls: ['./trading-accounts.component.scss'],
})
export class TradingAccountsComponent implements OnInit {
  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    tradingAccounts: false,
  };
  tradingAccountsObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;
  locations = TradingAccountLocation;

  constructor(
    private tradingAccountService: TradingAccountService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(() => {
      this.fileId = this.route.snapshot.parent.paramMap.get('id');
      this.getTradingAccounts();
    });
  }

  getTradingAccounts() {
    this.busy.tradingAccounts = true;
    this.tradingAccountsObservable = this.tradingAccountService
      .getAll(this.fileId)
      .pipe(
        tap(() => (this.busy.tradingAccounts = false)),
        catchError((err) => {
          this.showError(err);
          return of([]);
        })
      );
  }

  addTradingAccount() {
    this.tradingAccountsObservable.subscribe((tradingAccounts) => {
      this.modalService
        .openModal(TradingAccountComponent, 'add', { tradingAccounts })
        .then(
          () => this.getTradingAccounts(),
          () => true
        );
    });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  selectTradingAccount(param) {
    this.tradingAccountsObservable.subscribe((tradingAccounts) => {
      this.modalService
        .openModal(TradingAccountComponent, param.data.id, { tradingAccounts })
        .then(
          () => this.getTradingAccounts(),
          () => true
        );
    });
  }

  typesCellRenderer(params) {
    const isPrimaryProduction = params.data.isPrimaryProduction;
    const isLivestockEnabled = params.data.isLivestockEnabled;

    if (isPrimaryProduction && isLivestockEnabled) {
      return 'Primary production, Livestock';
    } else if (isPrimaryProduction) {
      return 'Primary production';
    } else if (isLivestockEnabled) {
      return 'Livestock';
    }
    return '';
  }
}
