<div
  class="h-100 d-flex align-items-center"
  *ngIf="currentState$ | async as currentState"
>
  <ng-container *ngIf="!currentState.isSplit">
    <ng-container *ngIf="currentState.canEdit">
      <crs-transaction-tax-select
        class="w-100"
        [clearable]="true"
        [ngModel]="currentState.selectedTax?.taxId"
        (change)="onSelectionChanged($event)"
      >
      </crs-transaction-tax-select>
    </ng-container>
  </ng-container>
</div>
