import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { tap, finalize, catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';

import { ClientService } from '..';
import { ClientEntitySearchResult } from '../clientEntitySearchResult';
import { PagedResponse } from 'src/app/core';

export class ClientEntitySelection {
  client: ClientEntitySearchResult;
  entity: ClientEntitySearchResult;
  constructor(client: ClientEntitySearchResult, entity: ClientEntitySearchResult) {
    this.client = client;
    this.entity = entity;
  }
}

@Component({
  selector: 'crs-client-entity-search',
  templateUrl: './client-entity-search.component.html'
})
export class ClientEntitySearchComponent implements OnInit {


  response: PagedResponse<ClientEntitySearchResult>;
  @Input() clientId: string;
  @Input() shouldDisplay: boolean;
  @Input() shouldDisplayClients: boolean;
  @Output() select = new EventEmitter<ClientEntitySelection>();

  selected = new ClientEntitySelection(null, null);

  busy: boolean;
  error: string;
  private searchStream = new Subject<string>();

  constructor(private readonly clientService: ClientService) {


    this.searchStream
    .pipe(
      distinctUntilChanged(),
      switchMap(search => this.getSearchObservable(search)),
      catchError(e => {
        this.showError(e);
        return EMPTY;
      })
    )
    .subscribe();
  }

  ngOnInit(): void {
    this.getSearchObservable(null, this.clientId).subscribe();
  }

  private getSearchObservable(search: string, clientId: string = null) {
    this.busy = true;
    // Todo: reference page sizes from config and implement continuous scroll
    return this.clientService
      .findCombined(
        search,
        clientId ? clientId : (this.selected.client ? this.selected.client.id : null),
        false,
        1,
        25)
      .pipe(
        tap(data => this.response = data ),
        finalize(() => this.busy = false)
      );
  }

  search(search: string) {
    this.searchStream.next(search);
  }

  selectClient(client: ClientEntitySearchResult) {
    this.selected = new ClientEntitySelection(client, null);
    this.select.emit(this.selected);
    this.search(null);
  }

  selectEntity(entity: ClientEntitySearchResult) {
    this.selected = new ClientEntitySelection(entity.client, entity);
    this.select.emit(this.selected);
  }

  showError(error) {
    this.error = error;
  }

}
