<div class="pad">
  <div class="panel" [ngBusy]="[busy.load]">
    <div class="panel-heading">
      <div class="d-flex">
        <div class="flex-grow-1" *ngIf="isAdd">
          <span class="primary">
            New <span *ngIf="isGroupDataset">Consolidated</span> Dataset
          </span>
        </div>

        <div class="flex-grow-1" *ngIf="!isAdd">
          <span class="primary">
            <span *ngIf="isGroupDataset">Consolidated</span> Dataset
          </span>
          {{ form.value?.name }}
        </div>

        <div class="close" (click)="close()">
          Close <i aria-hidden="true" class="fas fa-times ms-1"></i>
        </div>
      </div>
    </div>

    <ul class="nav-tabs nav navbar-expand-sm" *ngIf="!isAdd">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="./"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Information
        </a>
      </li>
      <li class="nav-item" *ngIf="!isAdd && !isGroupDataset">
        <a class="nav-link" routerLink="./journals" routerLinkActive="active">
          Journals
        </a>
      </li>
      <li class="nav-item" *ngIf="!isAdd">
        <a class="nav-link" routerLink="./balances" routerLinkActive="active">
          Balances
        </a>
      </li>
      <li class="nav-item" *ngIf="!isAdd && !isGroupDataset">
        <a
          class="nav-link"
          routerLink="./distributions"
          routerLinkActive="active"
        >
          Distributions
        </a>
      </li>
      <li
        class="nav-item"
        *ngIf="!isAdd && !isGroupDataset && isEntityLivestockEnabled"
      >
        <a class="nav-link" routerLink="./livestock" routerLinkActive="active">
          Livestock
        </a>
      </li>
    </ul>

    <div class="panel-body" *ngIf="route.children.length > 0">
      <router-outlet></router-outlet>
    </div>

    <form novalidate *ngIf="route.children.length === 0" [formGroup]="form">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-8 col-xl-6">
            <div class="form-group required">
              <label class="control-label">Name</label>
              <input
                class="form-control"
                formControlName="name"
                title="Name"
                type="text"
                [placeholder]="
                  'eg. ' + defaultDateEnd.getFullYear() + ' Actual'
                "
              />
            </div>

            <div class="row">
              <div class="col-md-8 col-lg-6">
                <div class="form-group required">
                  <label class="control-label">Start Date</label>
                  <crs-date-picker
                    formControlName="startDate"
                    [defaultDate]="defaultDateStart"
                    [readonly]="!isAdd"
                  />
                </div>

                <div class="form-group required">
                  <label class="control-label">End Date</label>
                  <crs-date-picker
                    formControlName="endDate"
                    [defaultDate]="defaultDateEnd"
                    [readonly]="!isAdd"
                  />
                </div>
              </div>
            </div>

            <div *ngIf="!isGroupDataset">
              <h2 class="form-group-title">Source</h2>

              <div class="form-group required">
                <label class="control-label">Source</label>
                <crs-source-select
                  formControlName="source"
                  [readonly]="!isAdd"
                />
              </div>

              <div>
                <crs-busy *ngIf="busy.loadingSource" />

                <div
                  class="form-group required"
                  *ngIf="
                    selectedSource?.sourceType?.apiType &&
                    selectedSource?.sourceType?.apiType === apiType.DataHub
                  "
                >
                  <label class="control-label">Transaction Details</label>
                  <ng-select
                    bindLabel="name"
                    bindValue="value"
                    formControlName="transactionDetail"
                    [clearable]="false"
                    [items]="transactionDetailItems"
                    (change)="onSelectTransactionDetail($event)"
                  />
                  <div class="text-danger" *ngIf="form?.errors?.trialBalance">
                    Trial balance can only be selected when dataset date range
                    is annual.
                  </div>
                </div>

                <div
                  class="form-group required"
                  *ngIf="
                    selectedSource?.sourceType?.apiType &&
                    selectedSource?.sourceType?.apiType !==
                      apiType.ChartOfAccounts &&
                    form?.controls?.transactionDetail?.value !==
                      transactionDetail.TrialBalance
                  "
                >
                  <label class="control-label">Import Frequency</label>
                  <ng-select
                    bindLabel="name"
                    bindValue="value"
                    formControlName="importFrequency"
                    [clearable]="false"
                    [items]="importFrequencies"
                  />
                </div>

                <!-- Excel Type -->
                <!--
                <div class="alert alert-info" *ngIf="form.value.source && !form.value.source.sourceType.apiType">
                  <p>Use our Excel add-in to import data into this dataset.</p>
                  <p>You can <a href="https://support.hownowhq.com/support/solutions/articles/5000860171-installation" target="_blank" rel="noopener noreferrer">click here</a> for installation details,
                  or get it direct from the <a href="https://appsource.microsoft.com/en-us/product/office/WA200001894?src=office" target="_blank" rel="noopener noreferrer">Microsoft AppSource</a></p>
                </div>
                -->

                <!-- Reportance Type -->
                <div
                  class="form-group required"
                  *ngIf="selectedSource?.sourceType?.isReportance"
                >
                  <label class="control-label">Import from Dataset</label>
                  <crs-dataset-select
                    formControlName="sourceDataset"
                    [endDate]="form.value.endDate"
                    [fileId]="selectedSource.cirrusFile.id"
                    [startDate]="form.value.startDate"
                  />
                </div>

                <div *ngIf="form.value.source && loginRequired">
                  <hr />
                  <label class="control-label">
                    <b>Connect to ZAP Data Hub</b>
                    <br />
                    Login to ZAP Data Hub to load data
                  </label>
                  <br />
                  <button
                    class="btn btn-secondary"
                    title="Log in button"
                    type="button"
                    [promiseBtn]="busy.login"
                    (click)="login()"
                  >
                    <img
                      alt="Log In to zap"
                      src="{{ form.value.source.sourceType.image }}"
                      width="30"
                    />&ensp;Log In
                  </button>
                  <hr />
                </div>

                <div *ngIf="form.value.source && apiTenantIdRequired">
                  <hr />
                  <label class="control-label">
                    <b>Enter your Tenant Id to load data.</b>
                  </label>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="control-label">Tenant Id</label>
                        <input
                          autocomplete="off"
                          class="form-control not-readonly"
                          formControlName="apiTenantId"
                          placeholder="Enter your Tenant Id"
                          type="text"
                          [attr.disabled]="!apiTenantIdRequired ? true : null"
                        />
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="btn-menu text-right mt-4">
                        <button
                          class="btn btn-secondary"
                          type="button"
                          *ngIf="apiTenantIdRequired"
                          (click)="setTenantId(form.value.apiTenantId)"
                        >
                          Set Tenant Id
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>

                <div
                  class="form-group"
                  *ngIf="
                    form.value.source &&
                    form.value.source.sourceType.apiType &&
                    selectedSource?.sourceType?.apiType !== 2
                  "
                >
                  <button
                    class="btn btn-major"
                    type="button"
                    [disabled]="loginRequired || apiTenantIdRequired"
                    [promiseBtn]="busy.reload"
                    (click)="loadData()"
                  >
                    <div style="display: inline-block">
                      <div>
                        <i
                          aria-hidden="true"
                          class="fas fa-cloud-download-alt"
                        ></i>
                      </div>
                      <div
                        title="Download the data from the ZAP Data Hub and create movement journals. Reload to override the existing journals."
                      >
                        Load Data
                      </div>
                      <div
                        class="btn-major-subtext"
                        *ngIf="form.value.lastImported"
                      >
                        {{ form.value.lastImported | date: 'dd MMM yy h:mm a' }}
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              <div class="spacer-md"></div>

              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    placeholder="Show Opening Balance Selector"
                    type="checkbox"
                    [formControl]="showOpeningBalanceSelector"
                  />
                  <label class="form-check-label">
                    Roll over opening balances
                    <a
                      class="btn-sm fas-btn-primary-ghost"
                      ngbPopover="Select this if you wish to import the opening balance for this data from another dataset in Access Ledger"
                      placement="right"
                      popoverTitle="Information"
                      [routerLink]="[]"
                    >
                      <i aria-hidden="true" class="fas fa-info-circle"></i>
                    </a>
                  </label>
                </div>
              </div>

              <div
                class="form-group required"
                *ngIf="showOpeningBalanceSelector?.value"
              >
                <label class="control-label">Opening Balance Dataset</label>
                <crs-dataset-select
                  formControlName="openingBalanceDataset"
                  [endDate]="rollOverEndDate"
                  [fileId]="fileId"
                />
                <button
                  class="btn btn-secondary btn-sm mt-2"
                  title="Collapse All"
                  title="Refresh Opening Balances"
                  type="button"
                  *ngIf="!isAdd"
                  [promiseBtn]="busy.refreshOpeningBalances"
                  (click)="refreshOpeningBalances()"
                >
                  <i aria-hidden="true" class="fas fa-sync-alt"></i>
                  Refresh Opening Balances
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="isGroupDataset">
          <div class="col-12">
            <div>
              <h2 class="form-group-title">Linked Datasets</h2>

              <button
                class="btn btn-secondary"
                type="button"
                (click)="addDatasets()"
              >
                Add Datasets
              </button>

              <div style="overflow: hidden; flex-grow: 1">
                <ag-grid-angular
                  #agGrid
                  class="ag-theme-material"
                  style="width: 100%"
                  [gridOptions]="gridOptions"
                  [rowData]="groupDatasets"
                >
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>

        <div class="spacer"></div>

        <crs-form-error [error]="error" />
      </div>

      <div class="panel-footer">
        <div class="btn-group" *ngIf="!isGroupDataset">
          <button
            class="btn btn-primary"
            type="submit"
            [promiseBtn]="busy.submit"
            (click)="save(false)"
          >
            {{ isAdd ? 'Create' : 'Save' }} Dataset
          </button>
          <button
            aria-expanded="false"
            aria-haspopup="true"
            class="btn btn-primary dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown"
            type="button"
          >
            <span class="sr-only">{{ isAdd ? 'Create' : 'Save' }} options</span>
          </button>
          <div class="dropdown-menu">
            <button class="dropdown-item" type="submit" (click)="save(false)">
              {{ isAdd ? 'Create' : 'Save' }} and close
            </button>
            <button class="dropdown-item" type="submit" (click)="save(true)">
              {{ isAdd ? 'Create and edit' : 'Save only' }}
            </button>
          </div>
        </div>

        <button
          class="btn btn-primary"
          type="button"
          *ngIf="isGroupDataset"
          (click)="save(false)"
        >
          {{ isAdd ? 'Create' : 'Save' }} Dataset
        </button>

        <button class="btn btn-secondary" type="button" (click)="close()">
          Cancel
        </button>

        <button
          class="btn btn-delete"
          title="Delete"
          type="button"
          *ngIf="!isAdd"
          [promiseBtn]="busy.delete"
          (click)="delete()"
        >
          <i aria-hidden="true" class="fas fa-trash"></i>
          Delete
        </button>
      </div>
    </form>
  </div>
</div>

<!-- Placing outside the conditional isGroupDataset render so the optionscell can be found in the initial grid options render -->
<ng-template #optionsCell let-row>
  <div class="btn-mini-menu">
    <button
      class="btn-sm fas-btn-ghost"
      title="Remove Dataset"
      type="button"
      (click)="removeGroupDataset(row)"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
    </button>
  </div>
</ng-template>
