<crs-report-editable-element [index]="index" [reportContent]="reportContent" [reportMetaData]="reportMetaData" [editable]="false">

  <div *ngIf="reportContent.output" [style.height.px]="+element.height + 20">
    <div #chartContainer></div>
  </div>

  <div class="border p-2 bg-light" *ngIf="!reportContent.output" [style.height.px]="element.height">
    <i class="far fa-chart-bar" aria-hidden="true"></i>
    Chart Placeholder
  </div>

</crs-report-editable-element>

