export class StringHelpers {

  public static convertToSentence(value: any): any {

    if (typeof value === 'string' && value && value.length) {

      let newString = '';
      if (value.length >= 1) {
        newString = value[0].toUpperCase();
      }
      for (let i = 1; i < value.length; i++) {
        if (value[i] === value[i].toUpperCase() && value[i - 1] === value[i - 1].toUpperCase() && value[i - 1] !== '_') {
          if (i < value.length - 1 && value[i + 1] !== value[i + 1].toUpperCase()) {
            newString += ' ';
          }
        }

        if(value[i] !== '_'){
          newString += value[i];
        }

        if (value[i] !== value[i].toUpperCase() && value[i] !== '_') {
          if (i < value.length - 1 && value[i + 1] === value[i + 1].toUpperCase() && value[i + 1] !== '_') {
            newString += ' ';
          }
        }
      }

      return newString;
    }

  }
}
