import { entityTypes } from './../../../../firm/entities/entityType';
import { MoveProcessor } from './moveProcessor';
import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Subscription,
  merge,
  EMPTY,
  of,
  Observable,
  fromEvent,
  from,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  finalize,
  catchError,
  mapTo,
  filter,
  map,
} from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

import { AccountComponent } from '../account/account.component';
import { AccountHeaderComponent } from '../account-header/account-header.component';
import { accountingRenderers, ProgressBarService } from '../../../../shared';
import { MessageService, ModalService } from '../../../../core';
import { ActiveFileService } from '../../../active-file.service';
import { MatchingRuleService } from '../../../setup/matchingRules/matching-rule.service';
import { Account, AccountService } from '../';
import { MatchingRuleComponent } from '../../../setup/matchingRules/matching-rule/matching-rule.component';
import { AccountGridManager } from './accountGridManager';
import {
  DragContext,
  MoveRequest,
  MoveResult,
  MoveAction,
} from './accountGridModel';
import { AccountsViewSourceAccount } from './accounts-view-source-account';
import { IAccountsViewAccount } from './accounts-view-account';
import { AccountType, AccountTypeService } from '../../account-types';
import { Classification } from '../../classifications';
import { MatchingRuleSetsService } from 'src/app/accounting/chart/matching-rule-sets/matching-rule-sets.service';
import { MatchingRuleSet } from 'src/app/accounting/chart/matching-rule-sets/matching-rule-set';

export class AccountContext {
  fileId: string;
  entityType: number;
  showDetailed: boolean;
  isConsolidated: boolean;
  searchClassification: number | null = null;
  accountTypeId: number | null = null;
  matchingRuleSetId: string;
}

@Component({
  selector: 'crs-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit, OnDestroy {
  @ViewChild('optionsCell', { static: true, read: TemplateRef })
  optionsCell: TemplateRef<Account>;

  busy = {
    allocated: false,
    unallocated: false,
  };
  observables = {
    allocated: null,
    unallocated: null,
    autoMatch: null,
  };
  subscriptions: Subscription[] = [];
  gridComponents = accountingRenderers;
  accountContext = new AccountContext();
  entityTypes = entityTypes;
  matchingRuleSets: MatchingRuleSet[];
  classifications = Classification;
  accountTypes: AccountType[][];

  search = new UntypedFormControl();
  entityType = new UntypedFormControl();
  error = null;
  allocatedGridMgr: AccountGridManager;
  unallocatedGridMgr: AccountGridManager;

  allocatedGroupColumnDef = {
    headerName: 'ALLOCATED',
    width: 350,
    menuTabs: [],
    flex: 1.5,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'accountHeaderRenderer',
    },
    valueGetter: (params) => {
      return params.data.displayName;
    },
  };

  unallocatedGroupColumnDef = {
    headerName: 'UNALLOCATED',
    width: 250,
    menuTabs: [],
    flex: 1.5,
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: 'accountHeaderRenderer',
    },
    valueGetter: (params) => {
      return params.data.displayName;
    },
  };

  dragContext = new DragContext();

  constructor(
    private accountService: AccountService,
    private activeFileService: ActiveFileService,
    private matchingRuleService: MatchingRuleService,
    private matchingRuleSetsService: MatchingRuleSetsService,
    private messageService: MessageService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private progressBar: ProgressBarService,
    private accountTypeService: AccountTypeService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.activeFileService.stream.subscribe((file) => {
        if (file) {
          this.accountContext.fileId = file.id;
          this.accountContext.entityType = file.defaultEntityTypeId;
          this.accountContext.isConsolidated = file.isConsolidated;
          this.entityType.setValue(this.accountContext.entityType);
        }
        if (this.accountContext.fileId) this.getAccounts();
      })
    );

    this.accountTypeService.search$('').subscribe((data) => {
      this.accountTypes = data.records;
    });

    this.matchingRuleSetsService
      .getAll$(this.accountContext.fileId)
      .subscribe((data) => {
        this.matchingRuleSets = data;
        this.accountContext.matchingRuleSetId = data[0].id;
      });

    const getAllocatedMethod = (context: AccountContext) =>
      this.getAccountsFromService(context, false);
    const refreshMethod = (context: AccountContext, ids) =>
      this.refreshAccountsFromService(context, false, ids);
    const getUnallocatedMethod = (context: AccountContext) =>
      this.getAccountsFromService(context, true);
    const refreshUnallocatedMethod = (context: AccountContext, ids) =>
      this.refreshAccountsFromService(context, true, ids);

    const moveProcessor = new MoveProcessor(
      this.accountContext,
      this.accountService,
      this.messageService
    );

    this.allocatedGridMgr = new AccountGridManager(
      'allocatedGrid',
      this,
      this.accountContext,
      moveProcessor,
      this.dragContext,
      this.messageService,
      getAllocatedMethod,
      refreshMethod,
      this.progressBar.ref('allocatedBar')
    );

    this.unallocatedGridMgr = new AccountGridManager(
      'unallocatedGrid',
      this,
      this.accountContext,
      moveProcessor,
      this.dragContext,
      this.messageService,
      getUnallocatedMethod,
      refreshUnallocatedMethod,
      this.progressBar.ref('unallocatedBar')
    );

    moveProcessor.addAccountGridManagerToManagedCollection(
      this.allocatedGridMgr
    );
    moveProcessor.addAccountGridManagerToManagedCollection(
      this.unallocatedGridMgr
    );

    this.configureGridColumnDefs();

    // Are we mid drag? Used by dragContext to determine if we are dragging from one grid to another
    this.subscriptions.push(
      merge(
        fromEvent(document, 'mousedown').pipe(
          mapTo(true),
          tap(() => this.dragContext.clear())
        ),
        fromEvent(document, 'mouseleave').pipe(mapTo(false)),
        fromEvent(document, 'mouseup').pipe(mapTo(false))
      ).subscribe((isDragging) => (this.dragContext.isDragging = isDragging))
    );

    // Accounts Filter/Search
    this.subscriptions.push(
      this.search.valueChanges
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((search) => {
          if (this.allocatedGridMgr.api) {
            this.allocatedGridMgr.api.setGridOption('quickFilterText', search);
          }
          if (this.unallocatedGridMgr.api) {
            this.unallocatedGridMgr.api.setGridOption(
              'quickFilterText',
              search
            );
          }
        })
    );

    // Account Selection cause other grid to de-select
    this.subscriptions.push(
      merge(
        this.allocatedGridMgr.selection$,
        this.unallocatedGridMgr.selection$
      )
        .pipe(
          tap((gridManagerId) => {
            if (this.allocatedGridMgr.id !== gridManagerId) {
              this.allocatedGridMgr.api.deselectAll();
            }
            if (this.unallocatedGridMgr.id !== gridManagerId) {
              this.unallocatedGridMgr.api.deselectAll();
            }
          })
        )
        .subscribe()
    );

    // Entity Type Selection
    this.subscriptions.push(
      this.entityType.valueChanges
        .pipe(
          filter((t) => this.accountContext.entityType !== t),
          tap((t) => (this.accountContext.entityType = t)),
          tap(() => this.getAccounts())
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onClearFilters(): void {
    this.search.setValue('');
    this.accountContext.searchClassification = null;
    this.accountContext.accountTypeId = null;
    this.refresh();
  }

  configureGridColumnDefs() {
    this.allocatedGridMgr.gridOptions.columnDefs = [
      {
        cellClass: 'muted-cell',
        field: 'accountNo',
        headerClass: 'muted-header',
        headerName: 'Account No',
        filter: true,
        hide: true,
      },
      {
        field: 'accountName',
        headerName: 'Account Name',
        hide: true,
        cellClass: 'muted-cell',
      },
      {
        field: 'accountType.name',
        headerName: 'Account Type',
        hide: true,
        cellClass: 'muted-cell',
      },
      {
        field: 'isConsolidated',
        headerName: 'Consolidated',
        type: 'booleanColumn',
        hide: true,
        cellClass: ['boolean-cell', 'centered', 'muted-cell'],
      },
      {
        field: 'accountType',
        headerName: '',
        cellRenderer: 'accountDetailRenderer',
        maxWidth: 200,
        headerClass: 'muted-header',
      },
      {
        maxWidth: 50,
        minWidth: 50,
        type: 'optionsColumn',
        field: 'options',
        cellRendererParams: {
          ngTemplate: this.optionsCell,
        },
        resizable: false,
      },
      {
        field: 'rowDrag',
        headerName: '',
        maxWidth: 50,
        minWidth: 50,
        rowDrag: (params) => this.isRowDraggable(params),
        resizable: false,
      },
    ];

    this.unallocatedGridMgr.gridOptions.columnDefs = [
      {
        field: 'accountNo',
        headerName: 'Account No',
        hide: true,
      },
      {
        field: 'accountName',
        headerName: 'Account Name',
        hide: true,
      },
      {
        field: 'accountType',
        headerName: '',
        cellRenderer: 'accountDetailRenderer',
        maxWidth: 150,
        headerClass: 'muted-header',
      },
      {
        maxWidth: 50,
        minWidth: 50,
        type: 'optionsColumn',
        field: 'options',
        cellRendererParams: {
          ngTemplate: this.optionsCell,
        },
        resizable: false,
      },
      {
        field: 'rowDrag',
        headerName: '',
        maxWidth: 50,
        minWidth: 50,
        rowDrag: (params) => this.isRowDraggable(params),
        resizable: false,
      },
    ];
  }

  getAccounts() {
    this.busy.allocated = true;
    this.busy.unallocated = true;

    (this.getAccountsFromService(this.accountContext, false) as Observable<any>)
      .pipe(
        catchError((e) => {
          this.showError(e);
          return of([]);
        }),
        tap((a) => this.allocatedGridMgr.setStore(a)),
        finalize(() => (this.busy.allocated = false))
      )
      .subscribe();
    (this.getAccountsFromService(this.accountContext, true) as Observable<any>)
      .pipe(
        catchError((e) => {
          this.showError(e);
          return of([]);
        }),
        tap((a) => this.unallocatedGridMgr.setStore(a)),
        finalize(() => (this.busy.unallocated = false))
      )
      .subscribe();
  }

  isSelectable(row) {
    return row.data.isSortable;
  }

  getRowClass(params: any) {
    return params.data.isHeader ? 'grid-header' : 'grid-account';
  }

  isRowDraggable(params) {
    return params.data && params.data.isSortable;
  }

  searchHasValue() {
    return !!this.search.value;
  }

  autoMatch() {
    this.observables.autoMatch = this.matchingRuleService
      .applyAll(
        this.accountContext.fileId,
        this.accountContext.matchingRuleSetId
      )
      .subscribe(
        () => this.getAccounts(),
        (err) => this.showError(err)
      );
  }

  refresh() {
    this.getAccounts();
  }

  onClickShowSourceAccounts(): void {
    this.accountContext.showDetailed = !this.accountContext.showDetailed;
    this.refresh();
  }

  getMatchingRuleSetName(matchingRuleSetId: string): string {
    const matchingRuleSet = this.matchingRuleSets?.find(
      (ruleSet) => ruleSet.id === matchingRuleSetId
    );

    return matchingRuleSet ? matchingRuleSet.name : 'Default Matching Rules';
  }

  createInstantRule() {
    this.modalService
      .openModal(MatchingRuleComponent, 'add', {
        fileId: this.accountContext.fileId,
        matchingRuleSetId: this.accountContext.matchingRuleSetId,
      })
      .then(() => this.getAccounts())
      .catch(() => this.getAccounts());
  }

  editAccount(account: Account) {
    this.modalService
      .openModal(AccountComponent, account.id)
      .then(() => this.getGridManager(account).refreshId(account.parentId))
      .catch(() => true);
  }

  quickGroup(parent: Account, sortOrder: number) {
    const gridManager = this.getGridManager(parent);
    const modalPromise = this.modalService.openModal(
      AccountHeaderComponent,
      'add',
      {
        parent: parent,
        sortOrder: sortOrder,
      }
    );

    from(modalPromise)
      .pipe(
        catchError(() => EMPTY),
        tap(() => gridManager.startLoader()),
        switchMap((id) =>
          gridManager.refreshIdsObservable([parent.id]).pipe(map(() => id))
        ),
        map((id) => {
          gridManager.api.getRowNode(id);
        }),
        switchMap((node) => {
          const moveRequest = new MoveRequest(
            gridManager.moveProcessor.getSelectionAsMoveSource(),
            new MoveResult(gridManager, MoveAction.InsertInto, node)
          );
          return gridManager.moveProcessor.execute(moveRequest);
        }),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => {
          gridManager.completeLoader();
          console.log('completed quick group');
        })
      )
      .subscribe();
  }

  addHeader(account: Account) {
    this.modalService
      .openModal(AccountHeaderComponent, 'add', account)
      .then(() => this.getGridManager(account).refreshId(account.id))
      .catch(() => true);
  }

  editHeader(account: Account) {
    account.children =
      this.getChildAccounts(account, this.getGridManager(account).store) ?? [];
    this.modalService
      .openModal(AccountHeaderComponent, account.id, account)
      .then(() => this.getGridManager(account).refreshId(account.parentId))
      .catch(() => true);
  }

  getChildAccounts(headerAccount: Account, accounts: any[]) {
    const filteredAccounts = accounts.filter(
      (account) =>
        account?.hierarchy.includes(headerAccount.id) &&
        !account.isHeader &&
        account.level == headerAccount.level + 1
    );
    return filteredAccounts;
  }

  orderBy(account: Account, orderBy: number) {
    const gridManager = this.getGridManager(account);
    const id = account.id;
    gridManager.startLoader();
    this.accountService
      .reorder(id, orderBy)
      .pipe(
        switchMap(() => gridManager.refreshIdsObservable([id])),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => gridManager.completeLoader())
      )
      .subscribe();
  }

  deleteHeader(account: Account) {
    const gridManager = this.getGridManager(account);
    gridManager.startLoader();
    this.accountService
      .deleteHeader(account)
      .pipe(
        switchMap(() => gridManager.refreshIdsObservable([account.parentId])),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => gridManager.completeLoader())
      )
      .subscribe();
  }

  private getGridManager(account: Account) {
    if (this.unallocatedGridMgr.store.some((a) => a.id === account.id))
      return this.unallocatedGridMgr;
    else return this.allocatedGridMgr;
  }

  private showError(err) {
    this.messageService.error(err);
  }

  private getAccountsFromService(
    context: AccountContext,
    unallocated: boolean
  ) {
    let observable: Observable<Account[]>;
    if (!unallocated) {
      observable = this.accountService.getHierarchy(
        context.fileId,
        context.entityType,
        context.showDetailed,
        context.searchClassification,
        context.accountTypeId
      );
    } else {
      observable = this.accountService.getUnallocatedHierarchy(
        context.fileId,
        context.entityType,
        context.showDetailed,
        context.searchClassification,
        context.accountTypeId
      );
    }
    if (context.showDetailed)
      return observable.pipe(map((l) => this.mapDetail(l)));
    else return observable;
  }

  private refreshAccountsFromService(
    context: AccountContext,
    unallocated: boolean,
    ids: string[]
  ) {
    let observable: Observable<Account[]>;
    if (!unallocated) {
      observable = this.accountService.refreshHierarchy(
        context.fileId,
        context.entityType,
        context.showDetailed,
        ids
      );
    } else {
      observable = this.accountService.refreshUnallocatedHierarchy(
        context.fileId,
        context.entityType,
        context.showDetailed,
        ids
      );
    }
    if (context.showDetailed)
      return observable.pipe(map((l) => this.mapDetail(l)));
    else return observable;
  }

  /**
   * Maps the accounts with detailed information into an array which includes the source accounts as children of
   * the accounts with their own hierarchy property
   * @param accounts the accounts collection which includes 'source accounts' as their children
   */
  private mapDetail(accounts: Account[]): IAccountsViewAccount[] {
    const fullList: IAccountsViewAccount[] = [];
    accounts.forEach((a) => {
      fullList.push(a);
      if (!a.sourceAccounts || !a.sourceAccounts.length) return;
      a.sourceAccounts.forEach((sa) => {
        const hierarchy = [...a.hierarchy];
        hierarchy.push(sa.id);
        const sourceAccount = new AccountsViewSourceAccount(sa, hierarchy);
        fullList.push(sourceAccount);
      });
    });
    return fullList;
  }
}
