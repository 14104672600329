import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService, PagedResponse } from '../../../core';
import {
  StandardAccount,
  StandardAccountNameVariant,
  StandardAccountToUpsert,
} from './standard-account';
import { StandardHeaderAccount } from './standard-header-account';

type GetAccountsParams = {
  isChild?: boolean | null;
  classificationId?: string | null;
  accountTypeId?: string | null;
  searchValue?: string | null;
  headerAccountId?: string | null;
  entityType?: number | null;
  page?: number | null;
  pageSize?: number | null;
  orderBy?: number | null;
  orderByAsc?: boolean | null;
  isLightSearch?: boolean | null;
};

@Injectable({ providedIn: 'root' })
export class StandardAccountService {
  readonly base: string = 'api/chart-of-accounts/standard-accounts';
  private static DEFAULT_PAGE_SIZE = 100;

  constructor(private apiService: ApiService) {}

  get$(id: string) {
    return this.apiService
      .get(this.base + '/' + id)
      .pipe(map((account) => new StandardAccount(account)));
  }

  getAccountAndParent$(id: string) {
    return this.apiService.get(this.base + '/' + id).pipe(
      map((account) => new StandardAccount(account)),
      switchMap((account) => {
        if (account.parentId && !account.parent) {
          return this.get$(account.parentId).pipe(
            map((parent) => {
              const updatedAccount = account.shallowCopy();
              updatedAccount.parent = new StandardAccount(parent);
              return updatedAccount;
            })
          );
        } else {
          return of(account);
        }
      })
    );
  }

  getHierarchy$(
    entityType: number,
    classificationId?: string | null,
    accountTypeId?: string | null,
    searchValue?: string | null,
    headerAccountId?: string | null
  ): Observable<StandardAccount[]> {
    let url = `${this.base}/hierarchy`;
    url = this.apiService.addQuery(url, 'entityType', entityType);
    url = this.apiService.addQuery(
      url,
      'searchClassification',
      classificationId
    );
    url = this.apiService.addQuery(url, 'accountTypeId', accountTypeId);
    url = this.apiService.addQuery(url, 'search', searchValue);
    url = this.apiService.addQuery(url, 'headerAccountId', headerAccountId);

    return this.apiService
      .get<StandardAccount[]>(url)
      .pipe(
        map((accounts) =>
          accounts.map((account) => new StandardAccount(account))
        )
      );
  }

  refreshHierarchy$(
    entityType: number,
    ids: string[]
  ): Observable<StandardAccount[]> {
    let url = `${this.base}/hierarchy/refresh`;
    url = this.apiService.addQuery(url, 'entityType', entityType);
    url = this.apiService.addQuery(url, 'ids', ids);
    return this.apiService
      .get<StandardAccount[]>(url)
      .pipe(
        map((accounts) =>
          accounts.map((account) => new StandardAccount(account))
        )
      );
  }

  getAccounts$({
    isChild,
    classificationId,
    accountTypeId,
    searchValue,
    headerAccountId,
    entityType,
    page,
    pageSize,
    orderBy,
    orderByAsc,
    isLightSearch
  }: GetAccountsParams): Observable<PagedResponse<StandardAccount>> {
    const encodedSearchValue = searchValue
      ? encodeURIComponent(searchValue)
      : '';

    let url = `${this.base}/search/standard`;

    url = this.apiService.addQuery(url, 'isChild', isChild);
    url = this.apiService.addQuery(
      url,
      'searchClassification',
      classificationId
    );
    url = this.apiService.addQuery(url, 'accountTypeId', accountTypeId);
    url = this.apiService.addQuery(url, 'search', encodedSearchValue);
    url = this.apiService.addQuery(url, 'headerAccountId', headerAccountId);
    url = this.apiService.addQuery(url, 'entityType', entityType);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    url = this.apiService.addQuery(url, 'orderBy', orderBy);
    url = this.apiService.addQuery(url, 'orderByAsc', orderByAsc);
    url = this.apiService.addQuery(url, 'isLightSearch', isLightSearch);

    return this.apiService
      .get<PagedResponse<StandardAccount>>(url)
      .pipe(map((response) => new PagedResponse<StandardAccount>(response)));
  }

  move(targetId: string, action: number, movingIds: string[]) {
    let url = `${this.base}/move`;
    url = this.apiService.addQuery(url, 'targetId', targetId);
    url = this.apiService.addQuery(url, 'action', action);
    return this.apiService.put(url, movingIds);
  }

  postHeader$(account: StandardHeaderAccount, position: number = null) {
    let url = this.base;
    url = this.apiService.addQuery(url, 'position', position);
    return this.apiService.post(url, account);
  }

  putHeader$(account: StandardHeaderAccount, accountId: string) {
    return this.apiService.put(`${this.base}/${accountId}`, account);
  }

  putAccountLight$(
    accountId: string,
    accountToUpsert: StandardAccountToUpsert
  ) {
    let url = `${this.base}/light`;
    url = this.apiService.addQuery(url, 'id', accountId);
    return this.apiService.put(url, accountToUpsert);
  }

  delete$(accountId: string): Observable<{}> {
    return this.apiService.delete(`${this.base}/${accountId}`);
  }

  postChildAccount$(accountToAdd: StandardAccountToUpsert): Observable<string> {
    return this.apiService.post(this.base, accountToAdd);
  }

  putChildAccount$(
    accountToUpdate: StandardAccountToUpsert,
    accountId: string
  ): Observable<{}> {
    return this.apiService.put(`${this.base}/${accountId}`, accountToUpdate);
  }
}
