import { ReportStyle } from '../../models';
import { EditableStyleInfo } from '../../models';
import { ReportTemplateService } from '../../../../reportTemplates';
import { ModalService } from '../../../../../../core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReportOutput } from 'src/app/accounting/reports/reportViewer/report-output';
import { ReportViewerComponent } from 'src/app/accounting/reports/reportViewer/report-viewer/report-viewer.component';
import { ICssEditModel, ICssFontUrl } from '../../models';
import { CssProcessingService, ReportCssService } from '../../services';
import { CssClassesEditorComponent } from '../classes-editor/classes-editor.component';
import { MessageService } from 'src/app/core';
import { catchError, take} from 'rxjs/operators';
import { forkJoin, of, ReplaySubject, Subscription } from 'rxjs';
import { ReportViewerParams } from '../../../../reportViewer/models';
import { IDialogPanel } from '../../../../../../shared';

@Component({
  selector: 'crs-css-editor',
  templateUrl: './css-editor.component.html',
  styleUrls: ['./css-editor.component.scss']
})
export class CssEditorComponent implements OnInit {
  @Input('fontUrls')
  public fontUrls: ICssFontUrl[];

  private _customCss: string;
  @Input() set customCss(value: string) {
    this._customCss = value;
    this.refreshTemplateModel();
  }
  get customCss() {
    return this._customCss;
  }

  @Input() style: ReportStyle;

  info: EditableStyleInfo;

  rawCss = '';
  cssEditModel: ICssEditModel = {
      variables: [],
      classGroups: []
  };
  busy = {
    load: null,
    preview: null
  };

  getTestReportSubscription: Subscription;
  testReport$ = new ReplaySubject<ReportOutput>();


  @ViewChild('cssClassesEditor')
  private _cssClassesEditor: CssClassesEditorComponent;

  constructor(
    private _reportTemplateService: ReportTemplateService,
    private _cssProcessingService: CssProcessingService,
    private _reportCssService: ReportCssService,
    private _modalService: ModalService,
    private _messageService: MessageService) { }

  ngOnInit(): void {

    this.busy.load = this._reportCssService.getEditableDefaultsPdfCss()
      .subscribe(r => {
        this.info = r;
        this.refreshTemplateModel();
      }, err => this.showError(err));
  }

  public previewReport() {

    if (!this.getTestReportSubscription) {
      this.getTestReportSubscription = this._reportTemplateService.getTestReport().subscribe(report => {
        this.testReport$.next(report);
      }, e => this.testReport$.next(null));
    }

    const report$ = this.testReport$.pipe(
      take(1)
    );

    const brandingCss$ = this._reportCssService.getBrandedCss().pipe(
      catchError(e => {
        console.log('Error retrieving branded css', e);
        return of('');
      }));

    this.busy.preview = forkJoin({
      report: report$,
      brandingCss: brandingCss$
    }).subscribe(s => {
      if (!s.report) {
        this.showError('Error retrieving test report.');
        return;
      }

      s.report.style.brandingCss = s.brandingCss;
      const customCss = this.buildCustomCss();

      const fontUrlsCss = this._cssProcessingService.parseFontUrlsToRaw(this.fontUrls);
      const customRawCss = this._cssProcessingService.replaceVariables(customCss, s.report);

      s.report.style.customCss = customRawCss;
      s.report.style.cssImports = fontUrlsCss;
      s.report.style.rounding = this.style.rounding;
      s.report.style.percentRounding = this.style.percentRounding;

      const data = new ReportViewerParams({
        report: s.report,
        restrictSave: true,
        restrictSaveDocument: true
      });
      this._modalService.overlay(ReportViewerComponent, { data } as IDialogPanel<ReportViewerParams>);

    }, e => this.showError(e));

  }

  private refreshTemplateModel() {
    if (this.info) {
      this.cssEditModel = this._cssProcessingService.getCustomWithDefaults(this.info, this.customCss);
      this._cssClassesEditor.classGroups = this.cssEditModel.classGroups;
    }
    if (this._cssClassesEditor) this._cssClassesEditor.reset();
  }

  private showError(err) {
    this._messageService.error(err);
  }

  buildCustomCss() {
    return this._cssProcessingService.parseToRawCss(this.cssEditModel);
  }

}
