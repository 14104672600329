import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-date-renderer',
  template: `
    <span *ngIf="!hasTime">{{ value | date : 'd MMM yyyy' }}</span>
    <span *ngIf="hasTime">
      {{ value | date : 'd MMM yy' }}
      <span class="text-muted">{{ value | date : 'h:mm a' }}</span>
    </span>
  `,
})
export class DateRendererComponent implements ICellRendererAngularComp {
  value: Date | string;
  hasTime = false;

  agInit(params: any): void {
    this.hasTime = params['hasTime'] === true;
    this.refresh(params);
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }
}
