import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../';

@Component({
  selector: 'crs-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent implements OnInit {

  @Input() user: User;

  ngOnInit(){}

  publicProfile() {
    console.log('get public profile'); //todo: get public profile of user
  }
}
