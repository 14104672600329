export class TaxCode {
  basCode: string;
  description: string;
  descriptionLabel?: string;
  effective_Date: Date;
  fileId: string;
  id: string;
  isCapital: boolean;
  postingAccountNo: string;
  rate: number;
  sourceId?: string;
  standardTaxCodeId?: string;
  taxCode: string;

  constructor(data) {
    Object.assign(this, data);

    this.descriptionLabel = `${data.taxCode} - ${data.description}`;
  }
}
