import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-division-renderer',
  template: `{{ division ? division.name : '' }}`,
})
export class DivisionRendererComponent implements ICellRendererAngularComp {
  division;

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: any): boolean {
    this.division = params.value;
    return true;
  }
}
