import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import { AssetAddition } from '../asset-addition/asset-addition';
import { DepreciationGroup, DepreciationGroupModel } from './depreciation-group';
import { AssetGroup } from '../asset-groups/asset-group';
import { AssetType } from './asset-type';

export class Asset {
  id: string;
  assetGroup: AssetGroup;
  linkedAssetId: string;
  code: string;
  name: string;
  identifier: string;
  description: string;
  assetType: AssetType;
  acquisitionDate: Date;
  cost: number;
  taxation: DepreciationGroup;
  accounting: DepreciationGroup;
  additions: AssetAddition[];

  constructor(data) {
    this.id = data.id;
    if (data.assetGroup) this.assetGroup = new AssetGroup(data.assetGroup);
    this.linkedAssetId = data.linkedAssetId;
    this.code = data.code;
    this.name = data.name;
    this.identifier = data.identifier;
    this.description = data.description;
    this.assetType = data.assetType;
    this.acquisitionDate = DateHelpers.dateFromApiResponse(data.acquisitionDate);
    this.cost = data.cost;

    if (data.taxation) this.taxation = new DepreciationGroup(data.taxation);
    if (data.accounting) this.accounting = new DepreciationGroup(data.accounting);
    if (data.additions) this.additions = data.additions.map(d => new AssetAddition(d));
  }
}

export class AssetModel {
  id: string;
  assetGroupId: number;
  linkedAssetId: string;
  code: string;
  name: string;
  identifier: string;
  description: string;
  assetType: AssetType;
  acquisitionDate: Date;
  taxation: DepreciationGroupModel;
  accounting: DepreciationGroupModel;
  additions: AssetAddition[];

  constructor(data: Asset) {
    this.id = data.id;
    this.assetGroupId = data.assetGroup ? data.assetGroup.id : null;
    this.linkedAssetId = data.linkedAssetId;
    this.code = data.code;
    this.name = data.name;
    this.identifier = data.identifier;
    this.description = data.description;
    this.assetType = data.assetType;
    this.acquisitionDate = DateHelpers.dateFromApiResponse(data.acquisitionDate);
        if (data.taxation) this.taxation = new DepreciationGroupModel(data.taxation);
    if (data.accounting) this.accounting = new DepreciationGroupModel(data.accounting);
    if (data.additions) this.additions = data.additions.map(d => new AssetAddition(d));
  }
}
