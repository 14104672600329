import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../core';
import { Office, OfficeCreateModel } from './office';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  readonly base: string = 'api/offices';

  constructor(private readonly _apiService: ApiService) { }

  get(id: string) {
    return this._apiService.get<Office>(this.base + '/' + id).pipe(map(d => new Office(d)));
  }

  getAll() {
    return this._apiService.get<Office[]>(this.base).pipe(map(l => l.map(d => new Office(d))));
  }

  post(office: OfficeCreateModel) {
    return this._apiService.post<any>(this.base, office);
  }

  put(office: Office) {
    return this._apiService.put(this.base + '/' + office.id, office);
  }

  delete(id: string) {
    return this._apiService.delete(this.base + '/' + id);
  }
}
