import { User } from '../users'
import { Team } from './team'

export enum TeamUserRole {
  User = 0,
  Admin = 1
}

export class TeamUser {
  id: number;
  user: User;
  team: Team;
  role: TeamUserRole;
  isManager: boolean;

  constructor(data) {
    Object.assign(this, data);
    if (data.user) this.user = new User(data.user);
    if (data.team) this.team = new Team(data.team);
  }
}

export class TeamUserModel {
  id: number;
  userId: string;
  teamId: string;
  role: TeamUserRole;
  isManager: boolean;

  constructor(data) {
    Object.assign(this, data);
    if (data.user) this.userId = data.user.id;
    if (data.team && data.team.id !== 'add') this.teamId = data.team.id;
  }
}


