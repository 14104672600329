import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../..';
import { ActivatedRoute } from '@angular/router';

export enum ErrorPageType {
  NotAuthorised = 0,
  ServerDown = 1,
  InactiveUser = 2,
  AccessDenied = 3,
  UnregisteredUser = 4,
  UserError = 4,
  LedgerLicenseNotEnabled = 5,
  BankFeedLicenseNotEnabled = 6,
}

@Component({
  selector: 'crs-error-page',
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  private errorType: ErrorPageType;
  title: string;
  message: string;
  showLogout: boolean;

  private subscriptions: Subscription[] = [];

  constructor(private readonly _authService: AuthService,
    private readonly _route: ActivatedRoute) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this._route.params.subscribe(params => {
        this.errorType = parseInt(params.errorType, 10);
        this.processErrorType(this.errorType, params.errorMessage);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  logout() {
    this._authService.logout();
  }

  private processErrorType(type: ErrorPageType, message: string = null) {
    switch (type) {
      case ErrorPageType.NotAuthorised:
      case ErrorPageType.AccessDenied:
        this.processNotAuthorisedError();
        break;
      case ErrorPageType.ServerDown:
        this.processServerDownError();
        break;
      case ErrorPageType.InactiveUser:
        this.processInactiveError();
        break;
      case ErrorPageType.UnregisteredUser:
        this.processUnregisteredError();
        break;
      case ErrorPageType.UserError:
        this.processUserError(message);
        break;
      case ErrorPageType.LedgerLicenseNotEnabled:
        this.processNoLedgerFeatureError();
        break;
      case ErrorPageType.BankFeedLicenseNotEnabled:
        this.processNoBankFeedsFeatureError();
        break;
      default:
        this.processUnknownError();
        break;
    }
  }

  private processNoLedgerFeatureError() {
    this.message = 'Your Ledger license provides access to the Admin Centre and to access Access Bank Feeds via third-party application.';
    this.showLogout = false;
  }

  private processNoBankFeedsFeatureError() {
    this.title = 'You are not authorised';
    this.message = 'Your Ledger license does not provide access to the Bank Feeds Modules.';
    this.showLogout = false;
  }

  private processNotAuthorisedError() {
    this.title = 'You are not authorised';
    this.message = 'Sorry, you do not have appropriate permissions. Click logout below to try logging in as a different user.';
    this.showLogout = true;
  }

  private processUnregisteredError() {
    this.title = 'You are not registered for Access Ledger';
    this.message = 'Sorry, you do not appear to be registered for Access Ledger. If this is in error, or you would like to be registered, please contact Access support or sales.';
    this.showLogout = true;
  }

  private processInactiveError() {
    this.title = 'Inactive account';
    this.message = 'Your account has been deactivated. Please contact your Administrator to reactivate your account if access is required.';
    this.showLogout = true;
  }

  private processServerDownError() {
    this.title = 'Server Unavailable';
    this.message = 'It appears we are having difficulty connecting to the Access Ledger servers. Please try again later.';
    this.showLogout = false;
  }

  private processUserError(message) {
    this.title = 'User Error';
    this.message = message ?? 'There has been an unexpected error, please contact Support.';
    this.showLogout = false;
  }

  private processUnknownError() {
    this.title = 'Unexpected Error';
    this.message = 'There has been an unexpected error, please contact Support.';
    this.showLogout = false;
  }

}
