<div class="row">
  <label class="col-label col-form-label"></label>
  <div class="col-control" *ngFor="let detail of [balances.taxation]">
    <button
      class="btn btn-secondary mb-3 text-truncate"
      type="button"
      *ngIf="detail.closingBalance != 0"
      [title]="taxationWriteOffBtn"
      (click)="writeOff(balances.pool, 1, balances.locked)"
    >
      <i aria-hidden="true" class="fas fa-balance-scale me-2"></i>
      {{ taxationWriteOffBtn }}
    </button>
    <button
      class="btn btn-secondary mb-3 text-truncate"
      type="button"
      *ngIf="detail.initialBalance != detail.closingBalance"
      [title]="restoreBtn"
      (click)="reset(balances.pool, 1, balances.locked)"
    >
      <i aria-hidden="true" class="fas fa-undo"></i>
      {{ restoreBtn }}
    </button>
  </div>
  <div class="col-toggle">
    <button
      class="fas-btn-primary-ghost"
      type="button"
      *ngIf="
        balances.locked &&
        (balances.taxation.initialBalance != 0 ||
          balances.taxation.initialBalance != balances.taxation.closingBalance)
      "
      (click)="balances.locked = false"
    >
      <i aria-hidden="true" class="fas fa-link"></i>
    </button>
  </div>
  <div class="col-control" *ngFor="let detail of [balances.accounting]">
    <button
      class="btn btn-secondary mb-3 text-truncate"
      type="button"
      *ngIf="detail.closingBalance != 0"
      [disabled]="balances.locked"
      [title]="accountingWriteOffBtn"
      (click)="writeOff(balances.pool, 2)"
    >
      <i aria-hidden="true" class="fas fa-balance-scale me-2"></i>
      {{ accountingWriteOffBtn }}
    </button>
    <button
      class="btn btn-secondary mb-3 text-truncate"
      type="button"
      *ngIf="detail.initialBalance != detail.closingBalance"
      [disabled]="balances.locked"
      [title]="restoreBtn"
      (click)="reset(balances.pool, 2)"
    >
      <i aria-hidden="true" class="fas fa-undo"></i>
      {{ restoreBtn }}
    </button>
  </div>
</div>
