import { DateHelpers } from 'src/app/shared/utilities/date-helpers';

export class AssetAdditionDetail {
  acquisitionDate: Date;
  cost: number;

  constructor(data: any) {
    this.acquisitionDate = DateHelpers.dateFromApiResponse(data.acquisitionDate);
    this.cost = data.cost;
  }
}

export class AssetAddition {
  description: string;
  taxation: AssetAdditionDetail;
  accounting: AssetAdditionDetail;

  constructor(data: any) {
    this.description = data.description;
    if (data.taxation) this.taxation = new AssetAdditionDetail(data.taxation);
    if (data.accounting) this.accounting = new AssetAdditionDetail(data.accounting);
  }
}


