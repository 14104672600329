import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/core';
import { UploadedImageFile } from 'src/app/shared/components/file-upload/file-upload.component';
import { Image } from '../../image.class';

@Component({
  selector: 'crs-image-variant',
  templateUrl: './image-variant.component.html',
  styleUrls: ['./image-variant.component.scss']
})
export class ImageVariantComponent implements OnInit {

  @Input() id: string;


  @Input('params')
  private set params(data: any) {
    this.uploadedImageFile = data.uploadedFile
    this.image = data.image

  }

  image: Image;
  uploadedImageFile: UploadedImageFile

  constructor(private readonly _modalService: ModalService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  submit(image) {
    this.activeModal.close({ image: image });
  }

  close(isDelete) {
    if (isDelete) {
      this.activeModal.close({ image: this.image, isDelete: true });
      return;
    }
    this.activeModal.dismiss();
  }
}
