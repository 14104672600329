import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core';
import { Database, DatabaseModel } from './database';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  readonly base: string = 'api/central/databases';

  constructor(private readonly _apiService: ApiService) { }

  get(id: number) {
    return this._apiService.get<Database>(this.base + '/' + id).pipe(map(l => new Database(l)));
  }

  getAll() {
    return this._apiService.get<Database[]>(this.base).pipe(map(l => l.map(d => new Database(d))));
  }

  post(database: DatabaseModel) {
    return throwError('We have not yet implemented database creation');
    // return this._apiService.post<any>(this.base, database);
  }

  put(database: DatabaseModel) {
    return throwError('We do not allow you to edit a database details through the UI at this stage, it is a very dangerous operation!');
    // return this._apiService.put(this.base + '/' + database.id, database);
  }
}
