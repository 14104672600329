<button type="button" mat-mini-fab class="round-button plus-button" data-toggle="dropdown" (click)="onResetMore()">
  <mat-icon>add</mat-icon>
</button>

<div class="dropdown-menu no-select" #menu>
  <div class="main-menu d-flex justify-content-around">
    <span *ngFor="let item of mainMenu" class="menu-item" (click)="addItem(item.type, item.param)">{{item.name}}</span>
    <span class="menu-item placeholders" (click)="onShowPlaceholdersMenu($event)" *ngIf="!output">
      Placeholders
      <mat-icon>keyboard_arrow_down</mat-icon>
      <div class="placeholders-dropdown">
        <div class="d-flex align-items-center placeholder-item">
          Placeholder
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
        <ng-container *ngFor="let item of placeholdersMenu; let i = index">
          <div (click)="addItem(item.type, item.param)" class="menu-item" [ngClass]="i === 0 ? 'mt-3 mb-2' : 'my-2'">
            {{item.name}}
          </div>
        </ng-container>
      </div>
    </span>
    <span class="menu-item" (click)="onMore($event)" *ngIf="secondaryMenus.length !== 0 && moreCounter < secondaryMenus.length">
      More
      <mat-icon class="more-icon">more_horiz</mat-icon>
    </span>
  </div>

  <ng-container *ngFor="let secondaryMenu of secondaryMenus; let i = index">
    <div class="secondary-menu d-flex justify-content-around" *ngIf="moreCounter > i">
      <span *ngFor="let item of secondaryMenu" class="menu-item" (click)="addItem(item.type, item.param)">{{item.name}}</span>
    </div>

    <hr *ngIf="moreCounter > i + 1">
  </ng-container>
</div>
