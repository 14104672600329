<div class="pad">
  <a
    class="btn btn-secondary btn-box"
    routerLink="./request-histories"
    *ngIf="!route.children.length"
  >
    <i class="fas fa-file-signature"></i>
    Request History
  </a>
</div>
<router-outlet></router-outlet>
