import { Injectable } from '@angular/core';
import { concat, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core';

@Injectable({
  providedIn: 'root',
})
export class AutoJournalService {
  readonly base: string = 'api/accounting/ledger/auto-journals';

  constructor(private apiService: ApiService) {}

  processAll$(datasetId: string) {
    return concat(
      this.processDepreciation$(datasetId),
      this.processDistribution$(datasetId)
    );
  }

  private processDistribution$(datasetId: string) {
    let url = `${this.base}/process/distribution`;
    url = this.apiService.addQuery(url, 'datasetId', datasetId);
    return this.apiService.put<any>(url, null).pipe(map((t) => t.data));
  }

  private processDepreciation$(datasetId: string) {
    let url = `${this.base}/process/depreciation`;
    url = this.apiService.addQuery(url, 'datasetId', datasetId);
    return this.apiService.put<any>(url, null).pipe(map((t) => t.data));
  }
}
