import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Classification } from '../../../ledger';
import {
  MatchingRule,
  MatchingRuleService,
  MatchType,
  MatchMode,
  MatchingRuleModel,
} from '../';
import { MessageService } from '../../../../core';

@Component({
  selector: 'crs-matching-rule',
  templateUrl: './matching-rule.component.html',
  styleUrls: ['./matching-rule.component.scss'],
})
export class MatchingRuleComponent implements OnInit {
  @Input() id: string;

  @Input() set params(value) {
    this.fileId = value.fileId;
    this.matchingRuleSetId = value.matchingRuleSetId;
  }

  isAdd: boolean;
  private _fileId: string;

  set fileId(value: string) {
    this._fileId = value;
    this.isMaster = !value;
  }
  get fileId(): string {
    return this._fileId;
  }

  isMaster: boolean;
  objectTitle = 'Matching Rule';
  matchingRuleSetId: string;

  busy = {
    load: null,
    submit: null,
    apply: null,
  };
  error: string = null;

  form = this.formBuilder.group({
    fileId: [null],
    type: [MatchType.AccountName, Validators.required],
    classification: [null, [Validators.required]],
    find: ['', [Validators.required, Validators.maxLength(256)]],
    findCaseSensitive: [false],
    mode: [MatchMode.Anywhere, Validators.required],
    findAccountType: [null],
    masterAccount: [null],
    account: [null],
    accountType: [null],
    overrideManualValues: [false],
    setToManualAfterMatch: [false],
    start: [null],
    end: [null],
  });

  matchTypes = MatchType;
  matchModesAccount = [
    { name: 'Anywhere', value: MatchMode.Anywhere },
    { name: 'Begins With', value: MatchMode.BeginsWith },
    { name: 'Between', value: MatchMode.IsBetween },
    { name: 'Ends With', value: MatchMode.EndsWith },
    { name: 'Entire', value: MatchMode.Entire },
  ];
  matchModesName = [
    { name: 'Anywhere', value: MatchMode.Anywhere },
    { name: 'Begins With', value: MatchMode.BeginsWith },
    { name: 'Ends With', value: MatchMode.EndsWith },
    { name: 'Entire', value: MatchMode.Entire },
  ];
  matchModes = this.matchModesName;
  classifications = Classification;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private matchingRuleService: MatchingRuleService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.isAdd = this.id === 'add';

    this.getMatchingRule();
    this.configureValidators();
  }

  configureValidators() {
    this.form.get('type').valueChanges.subscribe((t) => {
      if (t == null || t == undefined) return;
      const type = <MatchType>t;
      const mode = <MatchMode>this.form.get('mode').value;
      if (mode === MatchMode.IsBetween && type !== MatchType.AccountNumber)
        this.form.controls['mode'].setValue(MatchMode.Anywhere);
      this.matchModes =
        type === MatchType.AccountNumber
          ? this.matchModesAccount
          : this.matchModesName;
      this.form
        .get('classification')
        .setValidators(
          type === MatchType.AccountType ? null : [Validators.required]
        );
      this.form
        .get('find')
        .setValidators(
          type === MatchType.AccountType || mode === MatchMode.IsBetween
            ? [Validators.maxLength(256)]
            : [Validators.required, Validators.maxLength(256)]
        );
      this.form
        .get('mode')
        .setValidators(
          type === MatchType.AccountType ? null : [Validators.required]
        );
      this.form
        .get('findAccountType')
        .setValidators(
          type !== MatchType.AccountType ? null : [Validators.required]
        );
      this.form.updateValueAndValidity();
    });
  }

  submit() {
    this.error = null;
    const matchingRule = new MatchingRuleModel(<MatchingRule>this.form.value);

    if (this.isAdd) {
      matchingRule.fileId = this.fileId;
      matchingRule.matchingRuleSetId = this.matchingRuleSetId;

      this.busy.submit = this.matchingRuleService.post(matchingRule).subscribe(
        (id) => this.activeModal.close(id),
        (err) => this.showError(err)
      );
    } else {
      matchingRule.id = this.id;
      this.busy.submit = this.matchingRuleService.put(matchingRule).subscribe(
        () => this.activeModal.close(),
        (err) => this.showError(err)
      );
    }
  }

  getMatchingRule() {
    if (!this.isAdd) {
      this.busy.load = this.matchingRuleService.get(this.id).subscribe(
        (data) => {
          this.form.patchValue(data);
          this.fileId = data.fileId;
        },
        (err) => this.showError(err)
      );
    }
  }

  apply(setToManual: boolean = false) {
    if (!this.form.valid) {
      this.form.markAsTouched();
      return;
    }
    this.error = null;

    const matchingRule = new MatchingRuleModel(<MatchingRule>this.form.value);
    matchingRule.fileId = this.fileId;
    matchingRule.setToManualAfterMatch = setToManual;

    this.busy.apply = this.matchingRuleService
      .applyRule(matchingRule)
      .subscribe(
        (id) => this.messageService.success('Rule successfully applied'),
        (err) => this.showError(err)
      );
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  close() {
    this.activeModal.dismiss();
  }
}
