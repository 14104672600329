import { ReportMetaData } from 'src/app/accounting/reports/reportViewer/report-output';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IEditableContentInfo } from './editable-content-info';

@Component({
  selector: 'crs-report-editable-element',
  templateUrl: './report-editable-element.component.html',
  styleUrls: ['./report-editable-element.component.scss']
})
export class ReportEditableElementComponent {

  @Input() editable = false;
  @Input() canClickToEdit = true;
  @Input() cancellable = false;
  @Input() index: number;
  @Input() reportContent: IEditableContentInfo;
  @Input() reportMetaData: ReportMetaData;

  @Input() onSubmit: () => boolean;
  @Output() edit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() deleteEvent = new EventEmitter<void>();

  private _isEditing;
  get isEditing() {
    return this._isEditing;
  }
  set isEditing(value) {
    this._isEditing = value;
  }

  public delete() {
    this.reportContent.content.splice(this.index, 1);
    if(this.reportMetaData) this.reportMetaData.isModified = true;
    this.deleteEvent.emit();
  }

  public setEdit() {
    if (this.editable) {
      this.isEditing = true;
      this.edit.emit();
    }
  }

  public submit() {
    if (this.onSubmit) {
      if (this.onSubmit()) {
        this.isEditing = false;
        if(this.reportMetaData) this.reportMetaData.isModified = true;
      }
    } else this.isEditing = false;
  }
}
