import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EMPTY, Observable, Subject, Subscription, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  tap,
} from 'rxjs/operators';
import { ModalService } from 'src/app/core';
import { MessageService } from 'src/app/core/messages/message.service';
import { accountingRenderers, getDefaultGridOptions } from 'src/app/shared';
import { StandardAccount } from '../standard-account';
import { StandardAccountFormComponent } from '../standard-account-form/standard-account-form.component';
import { StandardAccountService } from '../standard-account.service';

export interface ShouldRefresh {
  refresh: boolean;
}
@Component({
  selector: 'crs-standard-accounts-table',
  templateUrl: './standard-accounts-table.component.html',
})
export class StandardAccountsTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  private static DEFAULT_PAGE = 1;
  private static PAGE_SIZE = 1000;

  @Input() accountTypeId: string;
  @Input() classificationId: string;
  @Input() search: string;
  @Input() selectedAccountEntityType: number;
  @Input() selectedHeaderAccountId: string;

  // Object instead of primitive type boolean because we want to trigger ngOnChanges when BehaviourSubject emits true twice
  // See: https://stackoverflow.com/a/50762307/10158227
  @Input() shouldRefreshAccounts: ShouldRefresh;

  @Output() fetchHeaderAccounts: EventEmitter<Partial<StandardAccount>[]> =
    new EventEmitter();

  busy = {
    accounts: false,
    load: null,
    submit: null,
  };
  error: string = null;
  subscriptions: Subscription[] = [];

  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;

  accounts: StandardAccount[];
  search$ = new Subject<string>();

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private modalService: ModalService,
    private standardAccountService: StandardAccountService
  ) {}

  ngOnInit(): void {
    this.search$.next(this.search);
    this.getHeaderAccounts();

    // Accounts Filter/Search
    this.subscriptions.push(
      this.search$
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((searchValue) => {
          this.fetchStandardAccounts(searchValue);
          this.cdr.detectChanges();
        })
    );
  }

  ngOnChanges({
    accountTypeId: accountTypeIdChange,
    classificationId: classificationIdChange,
    search: searchChange,
    selectedAccountEntityType: selectedAccountEntityTypeChange,
    selectedHeaderAccountId: selectedHeaderAccountIdChange,
    shouldRefreshAccounts: shouldRefreshAccountsChange,
  }: SimpleChanges): void {
    this.search$.next(this.search);

    if (
      accountTypeIdChange ||
      classificationIdChange ||
      selectedAccountEntityTypeChange ||
      selectedHeaderAccountIdChange ||
      shouldRefreshAccountsChange
    ) {
      this.fetchStandardAccounts(this.search);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onClickEditAccount(account: StandardAccount): void {
    this.modalService
      .openModal(StandardAccountFormComponent, account.id)
      .then(() => {
        this.fetchStandardAccounts(this.search);
        this.messageService.success('Account saved successfully.');
      })
      .catch(() => true);
  }

  onClickDeleteAccount(account: StandardAccount): void {
    const confirmationMessage =
      '<p>Are you sure you want to delete this account?</p></p>This action cannot be undone.</p>';

    this.modalService.confirmation(
      confirmationMessage,
      () => this.deleteAccount(account),
      true
    );
  }

  private deleteAccount(account: StandardAccount): void {
    this.standardAccountService
      .delete$(account.id)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap(() => {
          this.fetchStandardAccounts(this.search);
          this.messageService.success('Account deleted successfully.');
        })
      )
      .subscribe();
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error, true);
  }

  private fetchStandardAccounts(searchValue?: string): void {
    this.busy.accounts = true;

    this.getAccounts$(
      true,
      searchValue,
      null,
      StandardAccountsTableComponent.PAGE_SIZE
    )
      .pipe(
        catchError((error) => {
          this.showError(error);
          return of([]);
        }),
        tap((accounts: StandardAccount[]) => (this.accounts = accounts)),
        finalize(() => {
          this.busy.accounts = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private getHeaderAccounts(): void {
    this.busy.accounts = true;

    this.getAccounts$(
      false,
      null,
      null,
      StandardAccountsTableComponent.PAGE_SIZE
    )
      .pipe(
        catchError((error) => {
          this.showError(error);
          return of([]);
        }),
        tap((accounts: StandardAccount[]) => this.emitHeaderAccounts(accounts)),
        finalize(() => {
          this.busy.accounts = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private getAccounts$(
    isChild?: boolean,
    searchValue?: string,
    page?: number,
    pageSize?: number
  ): Observable<StandardAccount[]> {
    return this.standardAccountService
      .getAccounts$({
        isChild,
        classificationId: this.classificationId,
        accountTypeId: this.accountTypeId,
        searchValue,
        headerAccountId: this.selectedHeaderAccountId,
        entityType: this.selectedAccountEntityType,
        page,
        pageSize,
      })
      .pipe(
        map((pagedResponse) =>
          pagedResponse?.records.map((account) => new StandardAccount(account))
        )
      );
  }

  private emitHeaderAccounts(accounts: StandardAccount[]): void {
    const headerAccounts = accounts.filter((account) => account.isHeader);

    this.fetchHeaderAccounts.emit(headerAccounts);
  }
}
