<div [class.mb-3]="title" [formGroup]="formGroup">
  <h3 class="mt-2" *ngIf="title">
    {{ title }}

    <button
      class="ml-3 btn btn-sm btn-secondary"
      type="button"
      *ngIf="copyFrom"
      (click)="executeCopyFrom()"
    >
      <i aria-hidden="true" class="far fa-copy"></i>
      Copy From Above
    </button>
  </h3>

  <div class="row">
    <div [ngClass]="columnClass">
      <div class="form-group">
        <label class="control-label">Address</label>
        <div class="input-group-vertical">
          <input
            class="form-control"
            formControlName="addressLine1"
            type="text"
          />
          <input
            class="form-control"
            formControlName="addressLine2"
            type="text"
          />
          <input
            class="form-control"
            formControlName="addressLine3"
            type="text"
            *ngIf="!twoAddressLines"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div [ngClass]="columnClass">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label">Suburb</label>
            <input class="form-control" formControlName="suburb" type="text" />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label class="control-label">State</label>
            <input class="form-control" formControlName="state" type="text" />
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label class="control-label">Post Code</label>
            <input
              class="form-control"
              formControlName="postCode"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
