<ng-container *ngIf="feed$ | async as feedState; else loading">
  <ng-container [ngSwitch]="feedState.state">
    <ng-container *ngSwitchCase="'PENDING'" [ngTemplateOutlet]="loading">
    </ng-container>
    <ng-container
      *ngSwitchCase="'ERROR'"
      [ngTemplateOutlet]="error"
      [ngTemplateOutletContext]="{ err: feedState.errorMessage }"
    >
    </ng-container>
    <ng-container
      *ngSwitchDefault
      [ngTemplateOutlet]="success"
      [ngTemplateOutletContext]="{ feed: feedState.data }"
    >
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <crs-busy></crs-busy>
</ng-template>

<ng-template #error let-err="err">
  <crs-error-view [message]="err"></crs-error-view>
</ng-template>

<ng-template #success let-feed="feed">
  <div class="page-wrapper min-width-tablet">
    <div class="header-container">
      <crs-main-menu></crs-main-menu>
    </div>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
