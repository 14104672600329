import { DepreciationTypeOption } from './../assets/depreciation-type';
import { DepreciationPool } from './../assets/depreciation-pool';
import { Injectable } from '@angular/core';

import { ApiService } from 'src/app/core';
import { AssetsContext } from '../assets-context';
import { ManagePoolBalances } from './manage-pool-balances';
import { map } from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetPoolingService {

  public balanceAction$ = new Subject<Observable<any>>();

  readonly base: string = 'api/accounting/assets/pooling';

  constructor(private apiService: ApiService) { }

  getPoolManage(context: AssetsContext) {
    let url = this.base + '/manage';
    url = this.addContextParams(url, context);
    return this.apiService.get<ManagePoolBalances>(url).pipe(map(r => new ManagePoolBalances(r)));
  }

  writeOffBalances(context: AssetsContext, pool: DepreciationPool, type: DepreciationTypeOption, reset: boolean) {
    const content = {
      fileId: context.file.id,
      entityId: context.entity.id,
      year: context.year.year,
      pool: pool,
      type: type,
      reset: reset
    };
    return this.apiService.put(this.base + '/write-off', content);
  }

  allocateToPool(pool: DepreciationPool, type: DepreciationTypeOption, assetIds: string[], year: number) {
    const model = {
      pool: pool,
      type: type,
      assetIds: assetIds,
      year: year
    };
    return this.apiService.put(this.base + '/allocate-to-pool', model);
  }

  private addContextParams(url, context: AssetsContext): string {
    url = this.apiService.addQuery(url, 'fileId', context.file.id);
    url = this.apiService.addQuery(url, 'entityId', context.entity.id);
    url = this.apiService.addQuery(url, 'year', context.year.year);
    return url;
  }

}


