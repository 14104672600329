import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SessionService } from '../services/session.service';
import { ErrorPageType } from '../components';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BankFeedsAuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private sessionService: SessionService,
    private _router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.sessionService.active) {
      if (this.sessionService.featureFlags.bankFeed) {
        if (this.sessionService.user.email === 'ssa-arh@theaccessgroup.com') {
          this._router.navigate(['/system-admin']);
          return false;
        }

        if (next.data.master) return this.sessionService.permissions.isMaster;
        const requiredRole = this.getRequiredRole(next);
        if (requiredRole) {
          return this.sessionService.user.role >= requiredRole;
        }
        return true;
      }

      if (this.sessionService.featureFlags.ledger) {
        this._router.navigate(['error', ErrorPageType.BankFeedLicenseNotEnabled]);
        return false;
      }

    }

    this.authService.login(state.url);

    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }

  getRequiredRole(snapshot: ActivatedRouteSnapshot): number {
    let route = snapshot;
    while (route != null) {
      if (route.data.role) return route.data.role;
      route = route.parent;
    }
    return 0; // user
  }
}
