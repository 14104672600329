import { DateTime } from 'luxon';

export enum ReportPageCategory {
  Presentation,
  FinancialStatements,
  Legal,
  Dashboards
}

export enum FooterType {
  None = 0,
  ReportDefault = 1,
  Custom = 2
}

export class ReportPageType {
  canHaveMultiple: boolean;
  canUseTradingAccounts: boolean;
  category: ReportPageCategory;
  content = [];
  createdDate: DateTime;
  customFooter: string;
  customFooterId: number;
  excludeFromContents: boolean;
  hasCashFlowDetail : boolean;
  headerId: number;
  id: number;
  inactive: boolean;
  isBeta: boolean;
  isMaster: boolean;
  isSystem: boolean;
  masterId: number;
  name: string;
  title: string;

  constructor(data) {
    Object.assign(this, data);
  }
}

export class ReportPageTypeGroup {
  key: ReportPageCategory;
  items: ReportPageType[];

  constructor(key: ReportPageCategory, items: ReportPageType[]) {
    this.key = key;
    this.items = items;
  }
}
