import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  CellEditingStoppedEvent,
  CellFocusedEvent,
  GridApi,
  GridReadyEvent,
} from 'ag-grid-community';
import { of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { ITransactionTableContext, TransactionGrid } from './config';
import { BacoCodedBy, TransactionImportType } from 'src/app/baco/enums';
import {
  BacoLoadableTransactionDto,
  ITransactionsSummary,
  BacoRuleDto,
} from 'src/app/baco/interfaces';
import { BacoTransactionStore } from '../../baco-transaction.store';
import { TransactionService } from 'src/app/baco/services';
import { NumberHelpers } from 'src/app/shared/utilities/number-helpers';
import { TransactionCommentsComponent } from '../transaction-comments';
import { ModalService } from 'src/app/core';
import { RuleComponent } from '../../rules';
import { BacoFeedStore } from '../../baco-feed.store';

@Component({
  selector: 'crs-transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: ['./transaction-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionTableComponent implements OnInit, OnDestroy {
  @ViewChild('stateCell', { static: true }) stateCell: TemplateRef<any>;
  @ViewChild('optionsCell', { static: true }) optionsCell: TemplateRef<any>;

  public gridApi: GridApi;

  public transactionCodedBy = BacoCodedBy;
  public transactionGrid: TransactionGrid;
  public transactionImportType = TransactionImportType;
  public transactionsSummary$ = this.bacoTransactionStore.transactions$.pipe(
    filter((x) => x.state === 'SUCCESS'),
    switchMap((x) =>
      of({
        total: x.data?.length ?? 0,
        uncoded: x.data?.length
          ? x.data.filter((x) => x.codedBy == null).length
          : 0,
        codedByUser: x.data?.length
          ? x.data.filter((x) => x.codedBy == BacoCodedBy.User).length
          : 0,
        codeByRule: x.data?.length
          ? x.data.filter((x) => x.codedBy == BacoCodedBy.Rule).length
          : 0,
      } as ITransactionsSummary)
    )
  );

  public context: ITransactionTableContext;
  public transactions$ = this.bacoTransactionStore.transactions$.pipe(
    map((x) => x.data)
  );
  public status$ = this.bacoTransactionStore.transactions$.pipe(
    map((x) => x.state),
    distinctUntilChanged()
  );
  public error$ = this.bacoTransactionStore.transactions$.pipe(
    map((x) => x.errorMessage)
  );
  public bankAccounts$ = this.bacoTransactionStore.bankAccounts$;
  public selectedBank$ = this.bacoTransactionStore.selectedBank$;

  private _destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    public readonly transactionService: TransactionService,
    public readonly bacoTransactionStore: BacoTransactionStore,
    private readonly _modalService: ModalService,
    private readonly injector: Injector,
    private readonly _bacoFeedStore: BacoFeedStore
  ) {
    this.context = {
      parentComponent: this,
    };
  }
  public ngOnInit(): void {
    this.transactionGrid = new TransactionGrid(
      this.stateCell,
      this.optionsCell
    );

    this.transactionGrid.gridOptions.onGridReady = (param) =>
      this.gridReady(param);
    this.transactionGrid.gridOptions.onCellEditingStopped = (param) =>
      this.onCellEditingStopped(param);
    this.transactionGrid.gridOptions.onCellFocused = (
      event: CellFocusedEvent
    ) => this.onCellFocused(event);
  }

  public ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }

  public onAddRule(transaction: BacoLoadableTransactionDto): void {
    this._modalService
      .openModal(
        RuleComponent,
        null,
        {
          feedId: this._bacoFeedStore.feed$.getValue().data.id,
          transaction: transaction,
        },
        { windowClass: 'rule-modal', injector: this.injector }
      )
      .then((rule: BacoRuleDto) => {})
      .catch(() => true);
  }

  public onOpenComments(transactionId: string): void {
    this._modalService
      .openModal(TransactionCommentsComponent, transactionId, null, {
        windowClass: 'comments-modal',
      })
      .then()
      .catch(() => true);
  }

  public onClickDeleteTransaction(
    transaction: BacoLoadableTransactionDto
  ): void {
    const confirmationMessage =
      '<p>Are you sure you want to delete this transaction?</p></p>This action cannot be undone.</p>';

    this._modalService.confirmation(
      confirmationMessage,
      () => this.transactionService.deleteTransaction(transaction.id),
      true
    );
  }

  public focusOnNextUncodedRow() {
    const cellPosition = this.gridApi.getFocusedCell();
    const startRowIndex = cellPosition ? cellPosition.rowIndex : -1;
    let found = false;

    this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if (
        !found &&
        index >= startRowIndex &&
        (rowNode.data as BacoLoadableTransactionDto)?.codedBy === null
      ) {
        found = true;
        setTimeout(() => {
          this.gridApi.ensureIndexVisible(index);
          this.gridApi.setFocusedCell(index, cellPosition.column.getColId());
        }, 200);
      }
    });

    if (!found) {
      // If no uncoded row is found after the current row, loop from the beginning
      this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
        if (
          !found &&
          (rowNode.data as BacoLoadableTransactionDto)?.codedBy === null
        ) {
          found = true;
          setTimeout(() => {
            this.gridApi.ensureIndexVisible(index);
            this.gridApi.setFocusedCell(index, cellPosition.column.getColId());
          }, 200);
        }
      });
    }
  }

  private onCellFocused(cellFocusedEvent: CellFocusedEvent) {
    if (
      typeof cellFocusedEvent.column !== 'string' &&
      cellFocusedEvent.column.getColId() === 'account'
    ) {
      this.gridApi.stopEditing(true);
      this.gridApi.startEditingCell({
        rowIndex: cellFocusedEvent.rowIndex,
        colKey: cellFocusedEvent.column,
      });
    }
  }

  private onCellEditingStopped(param: CellEditingStoppedEvent) {
    if (param.oldValue === param.newValue) return;

    const { data, colDef } = param;
    const transaction = data as BacoLoadableTransactionDto;
    if (colDef.field === 'description') {
      if (
        !param.newValue &&
        param.data.originalDescription === param.oldValue
      ) {
        return;
      }

      this.transactionService.updateDescription(
        transaction,
        param.newValue ?? null
      );
      return;
    }

    const oldNumValue = NumberHelpers.parseToNumberOrNull(param.oldValue);
    const newNumValue = NumberHelpers.parseToNumberOrNull(param.newValue);

    if (colDef.colId === 'quantity') {
      if (oldNumValue === newNumValue) {
        return;
      }
      this.transactionService.updateQuantity(transaction, newNumValue);
      return;
    }

    if (colDef.field === 'tax') {
      if (oldNumValue === newNumValue) {
        return;
      }
      this.transactionService.updateTaxAmount(transaction, newNumValue);
      return;
    }
  }

  private gridReady(param: GridReadyEvent) {
    this.gridApi = param.api;

    this.transactions$
      .pipe(takeUntil(this._destroy))
      .subscribe((transactions) => {
        this.updateGridData(transactions);
      });

    this.status$.pipe(takeUntil(this._destroy)).subscribe((x) => {
      if (x === 'PENDING') {
        this.gridApi.setGridOption('loading', true);
      } else {
        this.gridApi.setGridOption('loading', false);
      }
    });
  }

  public updateGridData(transactions: BacoLoadableTransactionDto[]): void {
    if (this.gridApi) {
      this.gridApi.setGridOption('rowData', transactions);
    }
  }
}
