import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { ApiService } from '../../../core';
import { Dataset } from '../../datasets';
import { Division, TradingAccount } from '../../setup';
import { ReportElementTypeEnum } from '../enums';
import { Disclosure } from '../notes/disclosures/disclosure';
import { PolicySelector } from '../notes/policies';
import { ReportingSuite } from '../notes/reportingSuites';
import { ReportTableUtility } from '../reportViewer/elements/report-table/services/report-table-autocalc-utility';
import { ReportOutput } from '../reportViewer/report-output';
import { FooterTemplate } from './footerTemplate';
import { HeaderTemplate } from './headerTemplate';
import { ReportTemplate, ReportTemplateModel } from './reportTemplate';

@Injectable({
  providedIn: 'root',
})
export class ReportTemplateService {
  readonly base: string = 'api/accounting/reports/templates';

  constructor(private apiService: ApiService) {}

  get(id: string) {
    return this.apiService
      .get<ReportTemplate>(`${this.base}/${id}`)
      .pipe(map((d) => new ReportTemplate(d)));
  }

  getPolicies(
    id: string,
    fileId: string,
    reportingSuiteId: number,
    entityType: number
  ) {
    let url =
      id === 'add'
        ? `${this.base}/new/policies`
        : `${this.base}/${id}/policies`;
    url = this.apiService.addQuery(url, 'fileId', fileId);
    url = this.apiService.addQuery(url, 'reportingSuiteId', reportingSuiteId);
    url = this.apiService.addQuery(url, 'entityType', entityType);
    return this.apiService
      .get<PolicySelector[]>(url)
      .pipe(map((d) => d.map((s) => new PolicySelector(s))));
  }

  getDisclosures(
    id: string,
    fileId: string,
    reportingSuiteId: number,
    entityType: number
  ) {
    let url =
      id === 'add'
        ? `${this.base}/new/disclosures`
        : `${this.base}/${id}/disclosures`;
    url = this.apiService.addQuery(url, 'fileId', fileId);
    url = this.apiService.addQuery(url, 'reportingSuiteId', reportingSuiteId);
    url = this.apiService.addQuery(url, 'entityType', entityType);
    return this.apiService
      .get<Disclosure[]>(url)
      .pipe(map((d) => d.map((s) => new Disclosure(s))));
  }

  getDefaultFromMaster(fileId: string, id: number) {
    return this.apiService
      .get<ReportTemplate>(`${this.base}/${fileId}/default/${id}`)
      .pipe(map((d) => new ReportTemplate(d)));
  }

  getAll(fileId: string) {
    return this.apiService
      .get<ReportTemplate[]>(`${this.base}/forfile/${fileId}`)
      .pipe(map((l) => l.map((d) => new ReportTemplate(d))));
  }

  getPackage(fileId: string) {
    return this.apiService
      .get<any>(`${this.base}/package?fileId=${fileId}`)
      .pipe(
        map((l) => {
          return {
            datasets: l.datasets.map((dataset) => new Dataset(dataset)),
            divisions: l.divisions.map((division) => new Division(division)),
            tradingAccounts: l.tradingAccounts.map(
              (tradingAccount) => new TradingAccount(tradingAccount)
            ),
            headers: l.headers.map((header) => new HeaderTemplate(header)),
            footers: l.footers.map((footer) => new FooterTemplate(footer)),
            defaultReportingSuite: new ReportingSuite(l.defaultReportingSuite),
          };
        })
      );
  }

  post(reportTemplate: ReportTemplateModel) {
    return this.apiService.post<any>(this.base, reportTemplate);
  }

  generate$(reportTemplate: ReportTemplateModel): Observable<ReportOutput> {
    return this.apiService
      .post<ReportOutput>(`${this.base}/generate`, reportTemplate)
      .pipe(map((report) => new ReportOutput(report)));
  }

  eSignFromTemplate(esModel: any) {
    const url = this.base + '/generate/e-signature';
    return this.apiService.post<boolean>(url, esModel);
  }

  eSignFromReport(esModel: any) {
    const url = this.base + '/export/e-signature';
    return this.apiService.post<boolean>(url, esModel);
  }

  eSignUserQuery(email: string) {
    const url = `${this.base}/get-pleasesign-user`;
    return this.apiService.get<any>(`${url}?email=${email}`);
  }

  put(reportTemplate: ReportTemplateModel) {
    return this.apiService.put(
      `${this.base}/${reportTemplate.id}`,
      reportTemplate
    );
  }

  delete(id: string) {
    return this.apiService.delete(`${this.base}/${id}`);
  }

  public getTestReport(): Observable<ReportOutput> {
    return this.apiService
      .get<ReportTemplate>(`${this.base}/test`)
      .pipe(map((response) => new ReportOutput(response)));
  }

  public updateCustomTableAutoTotal(report: ReportOutput) {
    if (report?.pages == null) return;

    report.pages.forEach((page) => {
      page.pageContent.content.forEach((element) => {
        if (element.elementType == ReportElementTypeEnum.SectionTable) {
          ReportTableUtility.setRowAndColumnTotalValues(element);
        } else if (element.elementType == ReportElementTypeEnum.Table) {
          ReportTableUtility.setAutoColumnHeaders(
            report.reportHeaderColumns,
            element
          );
        }
      });
    });

    return report;
  }
}
