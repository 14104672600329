import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, EMPTY } from 'rxjs';
import {
  tap,
  catchError,
  exhaustMap,
  finalize,
  switchMap,
} from 'rxjs/operators';

import {
  DisclosureTemplate,
  DisclosureTemplateService,
  DisclosureLevel,
} from '../';
import { MessageService } from '../../../../../core';
import {
  getDefaultGridOptions,
  ProgressBarService,
  NgProgressRef,
} from '../../../../../shared';
import {
  GridOptions,
  GridApi,
  RowDragLeaveEvent,
  RowDragMoveEvent,
  RowDragEndEvent,
} from 'ag-grid-community';

enum Interaction {
  Move,
  Add,
}

class InteractionData {
  interaction: Interaction;
  moveId: string;
  moveBehindId: string;
}

@Component({
  selector: 'crs-file-disclosure-templates',
  templateUrl: './file-disclosure-templates.component.html',
})
export class FileDisclosureTemplatesComponent implements OnInit {
  fileId: string;
  error: string;

  busy = {
    load: false,
    interactionPending: false,
  };
  disclosureTemplatesObservable: Observable<any>;

  gridOptions: GridOptions;
  gridApi: GridApi;
  sortActive: boolean;
  filterActive: boolean;
  disclosureTemplates: DisclosureTemplate[] = [];
  pendingDisclosures: DisclosureTemplate[] = [];

  progress: NgProgressRef;

  interactionStream = new Subject<InteractionData>();

  isMaster(params) {
    return !!params.data && params.data.level < 2;
  }

  constructor(
    private readonly _disclosureTemplateService: DisclosureTemplateService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService,
    private readonly _progressBar: ProgressBarService
  ) {}

  ngOnInit() {
    this._route.params.subscribe(() => {
      this.fileId = this._route.snapshot.parent.paramMap.get('id');
      this.getPolicies().subscribe();
    });

    this.configureGridOptions();
    this.configureInteractionStream();
    this.progress = this._progressBar.ref('gridLoadingBar');
  }

  getPolicies(showLoader = true) {
    this.busy.load = showLoader;
    return this._disclosureTemplateService.getAll(this.fileId).pipe(
      tap((disclosureTemplates) => {
        this.busy.load = false;
        this.disclosureTemplates = disclosureTemplates;
        this.pendingDisclosures = null;
      }),
      catchError((err) => {
        this.busy.load = false;
        this.showError(err);
        return of([]);
      })
    );
  }

  addDisclosureTemplate() {
    this._router.navigate(
      ['./file/add', { level: DisclosureLevel.File, fileId: this.fileId }],
      { relativeTo: this._route }
    );
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectDisclosureTemplate(param) {
    if (!param.data) return;
    this._router.navigate(
      [
        './file/' + param.data.id,
        { level: DisclosureLevel.File, fileId: this.fileId },
      ],
      { relativeTo: this._route }
    );
  }

  configureInteractionStream() {
    this.interactionStream
      .pipe(
        tap(() => {
          this.error = null;
        }),
        exhaustMap((param) => this.handleInteraction(param))
      )
      .subscribe();
  }

  handleInteraction(param: InteractionData): Observable<any> {
    let observable: Observable<any> = EMPTY;

    if (param.interaction === Interaction.Add) {
      this.addDisclosureTemplate();
      return EMPTY;
    }

    if (param.interaction === Interaction.Move) {
      this.progress.start();
      observable = this._disclosureTemplateService
        .reorderFileDisclosureTemplate(param.moveId, param.moveBehindId)
        .pipe(
          switchMap(() => this._disclosureTemplateService.getAll(this.fileId)),
          tap((d) => {
            this.disclosureTemplates = d;
            this.gridApi.setGridOption('rowData', this.disclosureTemplates);
            this.pendingDisclosures = null;
          }),
          switchMap(() => this.getPolicies(false)),
          catchError((err) => {
            this.pendingDisclosures = null;
            this.gridApi.setGridOption('rowData', this.disclosureTemplates);
            this.showError(err);
            return EMPTY;
          })
        );
    }

    this.busy.interactionPending = true;
    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => {
        this.progress.complete();
        this.busy.interactionPending = false;
      })
    );
  }

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.id,
      onGridReady: (event) => (this.gridApi = event.api),
      onRowDragMove: (param) => this.onRowDragMove(param),
      onRowDragLeave: (param) => this.onRowDragLeave(param),
      onRowDragEnd: (param) => this.onRowDragEnd(param),
      isRowSelectable: (param) => {
        return param.data && param.data.level >= 2;
      },
      columnDefs: [
        {
          cellRenderer: 'badgeRenderer',
          field: 'name',
          headerName: 'Name',
          cellRendererParams: { badgeText: 'New', monthsToCheck: 3 },
          rowDrag: this.rowDrag.bind(this),
        },
        {
          field: 'level',
          headerName: 'Master',
          type: 'booleanColumn',
          cellClass: ['boolean-cell', 'centered'],
          cellRendererParams: { iconClass: 'fas fa-lock' },
          maxWidth: 100,
          minWidth: 100,
          valueGetter: this.isMaster.bind(this),
        },
      ],
    };
  }

  rowDrag(params) {
    return (
      params.node &&
      params.node.data &&
      params.node.data.level >= DisclosureLevel.File
    );
  }

  onRowDragMove(event: RowDragMoveEvent) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
  }

  onRowDragLeave(event: RowDragLeaveEvent) {
    this.pendingDisclosures = this.disclosureTemplates;
    event.api.setGridOption('rowData', this.pendingDisclosures);
  }

  onRowDragEnd(event: RowDragEndEvent) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);

    const param = new InteractionData();
    param.interaction = Interaction.Move;
    param.moveId = event.node.data.id;
    param.moveBehindId =
      event.overNode.childIndex === 0
        ? null
        : event.overNode.parent.childrenAfterGroup[
            event.overNode.childIndex - 1
          ].data.id;
    this.interactionStream.next(param);
  }

  executeUIMove(event: RowDragEndEvent | RowDragMoveEvent) {
    const movingNode = event.node;
    const overNode = event.overNode;
    const rowNeedsToMove =
      movingNode !== overNode &&
      overNode.data &&
      movingNode.data.location === overNode.data.location;
    if (rowNeedsToMove) {
      const fromIndex = this.disclosureTemplates.indexOf(movingNode.data);
      const toIndex = this.disclosureTemplates.indexOf(overNode.data);
      this.pendingDisclosures = this.disclosureTemplates.slice();
      moveInArray(this.pendingDisclosures, fromIndex, toIndex);
      this.gridApi.setGridOption('rowData', this.pendingDisclosures);
      this.gridApi.clearFocusedCell();
    }
    function moveInArray(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }
}
