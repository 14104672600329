import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../../core';
import { ReportMaster, ReportMasterModel,  } from './reportMaster';
import { HeaderTemplate } from '../../reportTemplates/headerTemplate';
import { FooterTemplate } from '../../reportTemplates/footerTemplate';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportMasterService {

  readonly base: string = 'api/accounting/reports/masters';

  constructor(private apiService: ApiService) { }

  get(id: number) {
    return this.apiService.get<ReportMaster>(this.base + '/' + id).pipe(map(d => new ReportMaster(d)));
  }

  getAll() {
    return this.apiService.get<ReportMaster[]>(this.base).pipe(map(l => l.map(d => new ReportMaster(d))));
  }


  getAllForFile(fileId: string): Observable<ReportMaster[]> {
    return this.apiService.get<ReportMaster[]>(this.base + '/forfile/' + fileId).pipe(map(l => l.map(d => new ReportMaster(d))));
  }

  getPackage() {
    return this.apiService.get<any>(this.base + '/package').pipe(map(l => {
      return {
        headers: l.headers.map(h => new HeaderTemplate(h)),
        footers: l.footers.map(h => new FooterTemplate(h))
      };
    }));
  }

  post(reportMaster: ReportMasterModel) {
    return this.apiService.post<any>(this.base, reportMaster);
  }

  put(reportMaster: ReportMasterModel) {
    return this.apiService.put(this.base + '/' + reportMaster.id, reportMaster);
  }

  reorder(id: number, index: number) {
    return this.apiService.put(this.base + '/' + id + '/reorder', index);
  }

  delete(id: number) {
    return this.apiService.delete(this.base + '/' + id);
  }
}
