<div class="panel" [ngBusy]="[busy.load]">
  <div class="panel-heading">
    <div class="d-flex">
      <div class="flex-grow-1" *ngIf="isAdd">
        <span class="primary">New Asset</span>
      </div>

      <div class="flex-grow-1" *ngIf="!isAdd">
        <span class="primary">Asset</span>
        {{ form.value?.name }}
      </div>

      <div class="close" (click)="close()">
        Close <i aria-hidden="true" class="fas fa-times ms-1"></i>
      </div>
    </div>
  </div>

  <form novalidate [formGroup]="form">
    <div class="panel-body d-grid gap-3">
      <!-- -------------------- -->
      <!-- Asset Details Header -->
      <!-- -------------------- -->
      <div class="card card-pop">
        <h2
          aria-controls="collapseAssetDetails"
          class="card-header card-header-collapsible form-group-title"
          data-bs-target="#collapseAssetDetails"
          data-bs-toggle="collapse"
          [attr.aria-expanded]="assetDetailsCollapsed"
          (click)="assetDetailsCollapsed = !assetDetailsCollapsed"
        >
          Asset Details
          <i
            aria-hidden="true"
            class="fas"
            [ngClass]="{
              'fa-caret-down': assetDetailsCollapsed,
              'fa-caret-up': !assetDetailsCollapsed,
            }"
          ></i>
        </h2>
        <div
          aria-labelledby="collapseAssetDetails"
          class="collapse show"
          id="collapseAssetDetails"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-form-md required">
                <div class="form-group">
                  <label class="control-label">Asset Group</label>
                  <crs-asset-group-select
                    formControlName="assetGroup"
                    [clearable]="false"
                  ></crs-asset-group-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-form-sm">
                <div class="form-group">
                  <label class="control-label">Code</label>
                  <input
                    class="form-control"
                    formControlName="code"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-form-md">
                <div class="form-group required">
                  <label class="control-label">Name</label>
                  <input
                    class="form-control"
                    formControlName="name"
                    type="text"
                  />
                </div>

                <div class="form-group required">
                  <label class="control-label">Asset Type</label>
                  <ng-select
                    bindLabel="name"
                    bindValue="value"
                    formControlName="assetType"
                    [clearable]="false"
                    [items]="assetTypes | enumToArray"
                  ></ng-select>
                </div>

                <div class="form-group required">
                  <label class="control-label">Acquisition Date</label>
                  <crs-date-picker
                    formControlName="acquisitionDate"
                  ></crs-date-picker>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-form-sm">
                <div class="form-group">
                  <label class="control-label">Identifier</label>
                  <input
                    class="form-control"
                    formControlName="identifier"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="control-label">Description</label>
              <textarea
                class="form-control"
                formControlName="description"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- ----------------------- -->
      <!-- Purchase Details Header -->
      <!-- ----------------------- -->
      <div class="card card-pop">
        <h2
          aria-controls="collapsePurchaseDetails"
          class="card-header card-header-collapsible form-group-title"
          data-bs-target="#collapsePurchaseDetails"
          data-bs-toggle="collapse"
          [attr.aria-expanded]="purchaseDetailsCollapsed"
          (click)="purchaseDetailsCollapsed = !purchaseDetailsCollapsed"
        >
          Purchase
          <i
            aria-hidden="true"
            class="fas"
            [ngClass]="{
              'fa-caret-down': purchaseDetailsCollapsed,
              'fa-caret-up': !purchaseDetailsCollapsed,
            }"
          ></i>
        </h2>
        <div
          aria-labelledby="collapsePurchaseDetails"
          class="collapse"
          id="collapsePurchaseDetails"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-label"></div>
              <div class="col-control col-header">Taxation</div>
              <div class="col-toggle"></div>
              <div class="col-control col-header">Accounting</div>
            </div>

            <!-- Cost -->
            <div class="row required">
              <label class="col-label col-form-label">Cost</label>
              <div class="col-control">
                <div class="form-group" [formGroup]="taxation">
                  <input
                    class="form-control"
                    currencyMask
                    formControlName="cost"
                  />
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="cost"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
              <div class="col-control">
                <div class="form-group" [formGroup]="accounting">
                  <input
                    class="form-control"
                    currencyMask
                    formControlName="cost"
                    [readOnly]="!toggles['cost']"
                  />
                </div>
              </div>
            </div>

            <!-- Cost Limit -->
            <div
              class="row"
              *ngIf="
                form.get('assetType').value == 2 ||
                taxation.get('costLimit').value
              "
            >
              <label class="col-label col-form-label">
                Cost Limit <small>(Motor Vehicles)</small>
              </label>
              <div class="col-control">
                <div class="form-group">
                  <div class="input-group" [formGroup]="taxation">
                    <div class="input-group-prepend">
                      <button
                        class="btn btn-outline-primary"
                        title="Apply Car Cost Limit"
                        type="button"
                        (click)="applyCarLimit()"
                      >
                        <i aria-hidden="true" class="fas fa-magic"></i>
                      </button>
                    </div>
                    <input
                      class="form-control"
                      currencyMask
                      formControlName="costLimit"
                    />
                  </div>
                </div>
              </div>
              <div class="col-toggle">
                <!-- <crs-asset-difference-toggle key="costLimit" [parent]="component"></crs-asset-difference-toggle> -->
              </div>
              <div class="col-control">
                <!-- <div class="form-group" [formGroup]="accounting">
                  <input class="form-control" currencyMask formControlName="costLimit" [readOnly]="!toggles['costLimit']">
                </div> -->
              </div>
            </div>

            <!-- Commence Depreciation Date -->
            <div class="row required">
              <label class="col-label col-form-label"
                >Date to Commence Depreciation</label
              >
              <div class="col-control">
                <div class="form-group" [formGroup]="taxation">
                  <crs-date-picker
                    formControlName="commenceDepreciationDate"
                  ></crs-date-picker>
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="commenceDepreciationDate"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
              <div class="col-control">
                <div class="form-group" [formGroup]="accounting">
                  <crs-date-picker
                    formControlName="commenceDepreciationDate"
                    [readonly]="!toggles['commenceDepreciationDate']"
                  ></crs-date-picker>
                </div>
              </div>
            </div>

            <!-- Additions -->
            <div class="row">
              <div class="col">
                <div class="jumbotron p-3 mt-3 mb-3">
                  <h3>Additions History</h3>

                  <div class="btn-toolbar" role="toolbar">
                    <div class="btn-menu">
                      <button
                        class="btn btn-secondary"
                        type="button"
                        (click)="addAddition()"
                      >
                        <i aria-hidden="true" class="fas fa-plus inset"></i>
                        Add Addition
                      </button>
                    </div>
                  </div>

                  <div class="px-3 bg-white" *ngIf="additions">
                    <ag-grid-angular
                      #agGrid
                      class="ag-theme-material"
                      style="width: 100%"
                      [gridOptions]="additionGridOptions"
                      [rowData]="additions"
                    />
                  </div>

                  <ng-template #optionsCell let-row>
                    <div class="btn-mini-menu">
                      <button
                        class="btn-sm fas-btn-primary-ghost"
                        title="Edit Addition"
                        type="button"
                        (click)="editAddition(row)"
                      >
                        <i aria-hidden="true" class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn-sm fas-btn-ghost"
                        title="Remove Addition"
                        type="button"
                        (click)="removeAddition(row)"
                      >
                        <i aria-hidden="true" class="fas fa-trash"></i>
                      </button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ----------------------- -->
      <!-- Depreciation Details Header -->
      <!-- ----------------------- -->
      <div class="card card-pop">
        <h2
          aria-controls="collapseDepreciationDetails"
          class="card-header card-header-collapsible form-group-title"
          data-bs-target="#collapseDepreciationDetails"
          data-bs-toggle="collapse"
          [attr.aria-expanded]="depreciationDetailsCollapsed"
          (click)="depreciationDetailsCollapsed = !depreciationDetailsCollapsed"
        >
          Depreciation
          <i
            aria-hidden="true"
            class="fas"
            [ngClass]="{
              'fa-caret-down': depreciationDetailsCollapsed,
              'fa-caret-up': !depreciationDetailsCollapsed,
            }"
          ></i>
        </h2>
        <div
          aria-labelledby="collapseDepreciationDetails"
          class="collapse"
          id="collapseDepreciationDetails"
        >
          <div class="card-body">
            <div class="alert alert-info" *ngIf="!depreciationRecords.length">
              Update your acquisition and disposal details to ensure at least
              one year is available to calculate depreciation.
            </div>

            <div class="btn-menu">
              <button
                class="btn btn-secondary"
                title="Calculate"
                type="button"
                *ngIf="depreciationRecords.length"
                (click)="toggleCalculationOutput()"
              >
                <span *ngIf="!showCalculationOutput">
                  <i aria-hidden="true" class="fas fa-door-open"></i>
                  Show Calculation Output
                </span>
                <span *ngIf="showCalculationOutput">
                  <i aria-hidden="true" class="fas fa-door-closed"></i>
                  Return to Depreciation Calculation
                </span>
              </button>
              <button
                class="btn btn-secondary"
                title="Calculate"
                type="button"
                *ngIf="showCalculationOutput"
                [promiseBtn]="busy.calculate"
                (click)="calculate()"
              >
                <i aria-hidden="true" class="fas fa-calculator"></i>
                Recalculate
              </button>
              <div
                class="ms-4 d-inline-block float-right"
                *ngIf="showCalculationOutput"
              >
                <ng-select
                  bindLabel="name"
                  bindValue="value"
                  [clearable]="false"
                  [formControl]="calculationOutputType"
                  [items]="depreciationTypes | enumToArray"
                ></ng-select>
              </div>
            </div>

            <div [class.d-none]="showCalculationOutput">
              <ng-container *ngFor="let set of depreciationRecords">
                <crs-depreciation-record-detail
                  [accountingRefreshStream]="accountingRefreshYears$"
                  [formSet]="set"
                  [taxationRefreshStream]="taxationRefreshYears$"
                ></crs-depreciation-record-detail>
              </ng-container>
            </div>

            <crs-form-error
              *ngIf="showCalculationOutput"
              [error]="calculationError"
            ></crs-form-error>

            <div class="px-3 bg-white" [class.d-none]="!showCalculationOutput">
              <ag-grid-angular
                #agGrid
                class="ag-theme-material"
                style="width: 100%"
                [gridOptions]="depreciationGridOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------ -->
      <!-- Opening Balance Details Header -->
      <!-- ------------------------------ -->
      <div class="card card-pop">
        <h2
          aria-controls="collapseOpeningBalances"
          class="card-header card-header-collapsible form-group-title"
          data-bs-target="#collapseOpeningBalances"
          data-bs-toggle="collapse"
          [attr.aria-expanded]="openingBalancesCollapsed"
          (click)="openingBalancesCollapsed = !openingBalancesCollapsed"
        >
          Opening Balances
          <i
            aria-hidden="true"
            class="fas"
            [ngClass]="{
              'fa-caret-down': openingBalancesCollapsed,
              'fa-caret-up': !openingBalancesCollapsed,
            }"
          ></i>
        </h2>
        <div
          aria-labelledby="collapseOpeningBalances"
          class="collapse"
          id="collapseOpeningBalances"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-label"></div>
              <div class="col-control col-header">Taxation</div>
              <div class="col-toggle"></div>
              <div class="col-control col-header">Accounting</div>
            </div>

            <!-- Has Opening Balances (Start calculation in a year after purchase) -->
            <div class="row">
              <label class="col-label col-form-label"
                >Start calculation in a year after purchase</label
              >
              <div class="col-control">
                <div class="form-check text-center" [formGroup]="taxation">
                  <input
                    class="form-check-input"
                    formControlName="hasOpeningBalances"
                    type="checkbox"
                  />
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="hasOpeningBalances"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
              <div class="col-control">
                <div class="form-check text-center" [formGroup]="accounting">
                  <input
                    class="form-check-input"
                    formControlName="hasOpeningBalances"
                    type="checkbox"
                    [readOnly]="true"
                  />
                </div>
              </div>
            </div>

            <!-- Commence Calculation Year -->
            <div class="row required" *ngIf="openingBalances.any">
              <label class="col-label col-form-label"
                >Commence Calculation In</label
              >
              <div class="col-control">
                <div
                  class="form-group"
                  *ngIf="openingBalances.taxation"
                  [formGroup]="taxation"
                >
                  <ng-select
                    bindLabel="year"
                    bindValue="year"
                    formControlName="commenceCalculationYear"
                    [clearable]="false"
                    [items]="assetsContext?.yearsDescending"
                  ></ng-select>
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="commenceCalculationYear"
                  [class.d-none]="!openingBalances.both"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
              <div class="col-control">
                <div
                  class="form-group"
                  *ngIf="openingBalances.accounting"
                  [formGroup]="accounting"
                >
                  <ng-select
                    bindLabel="year"
                    bindValue="year"
                    formControlName="commenceCalculationYear"
                    [clearable]="false"
                    [items]="assetsContext?.yearsDescending"
                    [readonly]="!toggles['commenceCalculationYear']"
                  ></ng-select>
                </div>
              </div>
            </div>

            <!-- Year Entered Pool -->
            <div class="row" *ngIf="openingBalances.any">
              <label class="col-label col-form-label">Year Entered Pool</label>
              <div class="col-control">
                <div
                  class="form-group"
                  *ngIf="openingBalances.taxation"
                  [formGroup]="taxation"
                >
                  <input
                    class="form-control"
                    formControlName="yearEnteredPool"
                    step="1"
                    type="number"
                  />
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="yearEnteredPool"
                  [class.d-none]="!openingBalances.both"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
              <div class="col-control">
                <div
                  class="form-group"
                  *ngIf="openingBalances.accounting"
                  [formGroup]="accounting"
                >
                  <input
                    class="form-control"
                    formControlName="yearEnteredPool"
                    step="1"
                    type="number"
                    [readOnly]="!toggles['yearEnteredPool']"
                  />
                </div>
              </div>
            </div>

            <!-- Opening Carrying Amount -->
            <div class="row" *ngIf="openingBalances.any">
              <label class="col-label col-form-label"
                >Opening Carrying Amount</label
              >
              <div class="col-control">
                <div
                  class="form-group"
                  *ngIf="openingBalances.taxation"
                  [formGroup]="taxation"
                >
                  <input
                    class="form-control"
                    currencyMask
                    formControlName="openingCarryingAmount"
                  />
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="openingCarryingAmount"
                  [class.d-none]="!openingBalances.both"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
              <div class="col-control">
                <div
                  class="form-group"
                  *ngIf="openingBalances.accounting"
                  [formGroup]="accounting"
                >
                  <input
                    class="form-control"
                    currencyMask
                    formControlName="openingCarryingAmount"
                    [readOnly]="!toggles['openingCarryingAmount']"
                  />
                </div>
              </div>
            </div>

            <!-- Total Decline in Value not Deducted -->
            <div class="row" *ngIf="openingBalances.any">
              <label class="col-label col-form-label"
                >Total Decline in value not deducted</label
              >
              <div class="col-control">
                <div
                  class="form-group"
                  *ngIf="openingBalances.taxation"
                  [formGroup]="taxation"
                >
                  <input
                    class="form-control"
                    currencyMask
                    formControlName="openingDeclineNotDeducted"
                  />
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="openingDeclineNotDeducted"
                  [class.d-none]="true"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------------------------ -->
      <!-- Disposal Details Header -->
      <!-- ------------------------------ -->
      <div class="card card-pop">
        <h2
          aria-controls="collapseDisposalDetails"
          class="card-header card-header-collapsible form-group-title"
          data-bs-target="#collapseDisposalDetails"
          data-bs-toggle="collapse"
          [attr.aria-expanded]="disposalDetailsCollapsed"
          (click)="disposalDetailsCollapsed = !disposalDetailsCollapsed"
        >
          Disposal
          <i
            aria-hidden="true"
            class="fas"
            [ngClass]="{
              'fa-caret-down': disposalDetailsCollapsed,
              'fa-caret-up': !disposalDetailsCollapsed,
            }"
          ></i>
        </h2>
        <div
          aria-labelledby="collapseDisposalDetails"
          class="collapse"
          id="collapseDisposalDetails"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-label"></div>
              <div class="col-control col-header">Taxation</div>
              <div class="col-toggle"></div>
              <div class="col-control col-header">Accounting</div>
            </div>

            <!-- Disposal Value -->
            <div class="row">
              <label class="col-label col-form-label">Disposal Value</label>
              <div class="col-control">
                <div class="form-group" [formGroup]="taxation">
                  <input
                    class="form-control"
                    currencyMask
                    formControlName="disposalValue"
                  />
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="disposalValue"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
              <div class="col-control">
                <div class="form-group" [formGroup]="accounting">
                  <input
                    class="form-control"
                    currencyMask
                    formControlName="disposalValue"
                    [readOnly]="!toggles['disposalValue']"
                  />
                </div>
              </div>
            </div>

            <!-- Disposal Date -->
            <div class="row">
              <label class="col-label col-form-label">Disposal Date</label>
              <div class="col-control">
                <div class="form-group" [formGroup]="taxation">
                  <crs-date-picker
                    formControlName="disposalDate"
                  ></crs-date-picker>
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="disposalDate"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
              <div class="col-control">
                <div class="form-group" [formGroup]="accounting">
                  <crs-date-picker
                    formControlName="disposalDate"
                    [readonly]="!toggles['disposalDate']"
                  ></crs-date-picker>
                </div>
              </div>
            </div>

            <!-- Disposal Value -->
            <div class="row">
              <label class="col-label col-form-label"
                >Average Private Use
                <small>(for pooled disposals only)</small></label
              >
              <div class="col-control">
                <div class="form-group" [formGroup]="taxation">
                  <div class="input-group">
                    <input
                      class="form-control"
                      currencyMask
                      formControlName="disposalAveragePrivatePercentEditable"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-toggle">
                <crs-asset-difference-toggle
                  key="disposalAveragePrivatePercentEditable"
                  [class.d-none]="true"
                  [parent]="component"
                ></crs-asset-difference-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="spacer"></div>

      <crs-form-error [error]="error"></crs-form-error>
    </div>

    <div class="panel-footer">
      <div class="btn-group">
        <button
          class="btn btn-primary"
          type="submit"
          [promiseBtn]="busy.submit"
          (click)="save(false)"
        >
          {{ isAdd ? 'Create' : 'Save' }} Asset
        </button>
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="btn btn-primary dropdown-toggle dropdown-toggle-split"
          data-bs-toggle="dropdown"
          type="button"
        >
          <span class="sr-only">{{ isAdd ? 'Create' : 'Save' }} options</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" type="submit" (click)="save(false)">
            {{ isAdd ? 'Create' : 'Save' }} and close
          </button>
          <button class="dropdown-item" type="submit" (click)="save(true)">
            {{ isAdd ? 'Create and edit' : 'Save only' }}
          </button>
        </div>
      </div>

      <button class="btn btn-secondary" type="button" (click)="close()">
        Cancel
      </button>

      <button
        class="btn btn-delete"
        title="Delete"
        type="button"
        *ngIf="!isAdd"
        [promiseBtn]="busy.delete"
        (click)="delete()"
      >
        <i aria-hidden="true" class="fas fa-trash"></i>
        Delete
      </button>
    </div>
  </form>
</div>
