import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core';
import { IStandardResponse } from 'src/app/core/services/standard-response.interface';
import { map } from 'rxjs/operators';
import { EditableStyleInfo } from '../models/editable-style-info';

@Injectable({
    providedIn: 'root'
})
export class ReportCssService {
    private _dataUrl = 'api/accounting/reports/styles/css/';

    constructor(private _apiService: ApiService) { }

    public getEditableDefaultsPdfCss(): Observable<EditableStyleInfo> {
      let url = `${this._dataUrl}editable-defaults`;
      return this._apiService.get<EditableStyleInfo>(url)
            .pipe(map(r => new EditableStyleInfo(r)));
    }

    public getBrandedCss(officeId?: string): Observable<string> {
      let url = `${this._dataUrl}branding-css`;
      url = this._apiService.addQuery(url, 'officeId', officeId);
        return this._apiService.get<IStandardResponse<string>>(url)
            .pipe(map(response => response.data));
    }

    public getWhiteListCssProperties(): Observable<string[]> {
        return this._apiService.get<IStandardResponse<string[]>>(`${this._dataUrl}whitelist`)
            .pipe(map(response => response.data));
    }
}
