<ng-select
  bindLabel="name"
  [compareWith]="compareWithFn"
  [groupBy]="groupByFn"
  [groupValue]="groupValueFn"
  [items]="datasets"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [virtualScroll]="true"
>
  <ng-template
    let-index="index"
    let-item="item"
    let-search="searchTerm"
    ng-option-tmp
  >
    <div class="d-flex w-100">
      <div class="me-2 align-top text-center icon-img">
        <img
          class="icon-img"
          *ngIf="item?.source?.sourceType?.icon"
          [alt]="item?.source?.name"
          [src]="item?.source?.sourceType?.icon"
        />
        <i
          aria-hidden="true"
          class="fas fa-th"
          *ngIf="item?.isGroupDataset"
        ></i>
      </div>
      <div class="me-2">
        <div class="" [ngOptionHighlight]="search" [title]="item.name">
          {{ item.name }}
        </div>
        <div>
          <small class="text-muted">
            {{ item.startDate | date: 'dd MMM yyyy' }} to
            {{ item.endDate | date: 'dd MMM yyyy' }}
          </small>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template let-item="item" ng-label-tmp>
    <img
      class="icon-img me-2 align-middle"
      *ngIf="item?.source?.sourceType?.icon"
      [alt]="item?.source?.name"
      [src]="item?.source?.sourceType?.icon"
    />
    <span
      class="d-inline-block icon-img me-2 text-center"
      *ngIf="item?.isGroupDataset"
    >
      <i aria-hidden="true" class="fas fa-th"></i>
    </span>
    <span [ngOptionHighlight]="search">
      {{ item.name }}
    </span>
  </ng-template>
</ng-select>
