import { IElement } from '../element';

export enum HeaderStyle {
    Normal = 0,
    Alternative = 1,
    PageTitle = 2
}

export enum Alignment {
    Left = 0,
    Center = 1,
    Right = 2,
    Justify = 3
}

export class ReportHeader implements IElement {
  elementType = 0;
  level = 1;
  style: HeaderStyle = HeaderStyle.Normal;
  alignment: Alignment = Alignment.Left;
  marginTop = false;
  marginBottom = true;
  text: string;
  isNew = true;

  constructor(data?: Partial<ReportHeader>) {
    if (data) Object.assign(this, data);
  }
}
