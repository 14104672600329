import { TotalDefinition } from './totalDefinition';
import { LayoutOption } from './layoutOption';
import { HeaderMatchModel } from './headerMatch';
import { AccountLayout, MasterAccountLayout } from '../..';
import { Disclosure } from '../notes/disclosures/disclosure';
import { DisclosureLinkOption, DisclosureLink } from '../notes/disclosures/disclosure-link';

export class HeaderLayoutOption {
  layoutOption: LayoutOption = LayoutOption.Show;
  showAsRootLevelHeader = false;
  totals: TotalDefinition[] = [];

  get showAsNote() {
    return this.layoutOption === LayoutOption.ShowInNote;
  }

  get isDefault() {
    return this.layoutOption === LayoutOption.Show &&
    this.showAsNote === false &&
    !this.totals.length &&
    !this.disclosureTemplateChainId &&
    this.linkOption == null &&
    !this.placeholderIdentifier;
  }

  // --------- DISCLOSURES ---------

  disclosureTemplateChainId: string;

  private _disclosure: Disclosure;

  /**
   * Manually selected disclosure (that relates to disclosureTemplateChainId)
   */
  get disclosure() {
    if (!this._disclosure || this._disclosure.disclosureTemplateChainId !== this.disclosureTemplateChainId) return null;
    return this._disclosure;
  }
  /**
   * Manually selected disclosure (that relates to disclosureTemplateChainId)
   */
  set disclosure(value: Disclosure) {
    this._disclosure = value;
    this.disclosureTemplateChainId = value ? value.disclosureTemplateChainId : null;
  }

  linkOption: DisclosureLinkOption = null;

  /**
   * The default disclosure link
   */
  disclosureLink: DisclosureLink = null;

  /**
   * The disclosure that corresponds to the default disclosureLink
   */
  disclosureLinkDisclosure: Disclosure = null;

    /**
   * The name placeholder identifier to append account details to. If empty it will go to the default placeholder.
   * Required only if linkOption = AppendDetail.
   */
  placeholderIdentifier: string;

  get isDefaultDisclosure() {
    return !this.disclosureTemplateChainId;
  }

  get hasDisclosure() {
    return this.disclosureLink || this.disclosure;
  }

  get forceLinkOption() {
    return this.isDefaultDisclosure && this.disclosureLink && this.disclosureLink.forceLinkOption;
  }

  get disclosureOptionsModified() {
    return this.disclosureTemplateChainId || this.linkOption != null || this.placeholderIdentifier;
  }

  constructor(data) {
    if (!data) return;
    Object.assign(this, data);
    if (data.totals) this.totals = data.totals.map(t => new TotalDefinition(t));
  }
}

export class HeaderLayoutOptionModel {
  headerMatch: HeaderMatchModel;
  layoutOption: LayoutOption = LayoutOption.Show;
  showAsRootLevelHeader = false;
  totals: TotalDefinition[] = [];
  disclosureTemplateChainId: string;
  linkOption: DisclosureLinkOption;
  placeholderIdentifier: string;

  constructor(data: any) {
    this.headerMatch = new HeaderMatchModel(data.headerMatch);
    this.layoutOption = data.layoutOption;
    this.showAsRootLevelHeader = data.showAsRootLevelHeader;
    if (data.totals) this.totals = data.totals.map(t => new TotalDefinition(t));
    this.linkOption = data.linkOption;
    this.disclosureTemplateChainId = data.disclosureTemplateChainId;
    this.placeholderIdentifier = data.placeholderIdentifier;
  }

  /**
   * Creates a HeaderLayoutOptionModel model for an AccountLayout
   * @param accountLayout the AccountLayout to convert to the HeaderLayoutOptionModel
   */
  static createForAccountLayout(accountLayout: AccountLayout) {
    const layoutOption = <any>accountLayout.layoutOption;
    layoutOption.headerMatch = new HeaderMatchModel({accountId: accountLayout.id});
    return new HeaderLayoutOptionModel(layoutOption);
  }

  /**
   * Creates a HeaderLayoutOptionModel model for an MasterAccountLayout
   * @param masterAccountLayout the MasterAccountLayout to convert to the HeaderLayoutOptionModel
   */
  static createForMasterAccountLayout(accountLayout: MasterAccountLayout) {
    const layoutOption = <any>accountLayout.layoutOption;
    layoutOption.headerMatch = new HeaderMatchModel({masterAccountId: accountLayout.id});
    return new HeaderLayoutOptionModel(layoutOption);
  }

}




