export class Patterns {
  public static emailRegExp = new RegExp(
    '(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))'
  );
  public static twoDecimalPlacesRegExp = new RegExp(/^\d+(\.\d{1,2})?$/);
  public static isValidGuid = new RegExp(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  );
  public static phoneNumberRegExp = new RegExp(/^\+[1-9]{1}[0-9]{3,14}$/);
}
