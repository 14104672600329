import { EntityTypeEnum } from 'src/app/firm';
import { StandardAccountNameVariant } from './standard-account';
import { AccountClass } from '../../ledger/accounts/account-class';

export class StandardHeaderAccount {
  accountClass: number = AccountClass.Header;
  accountName: string;
  accountTypeId: number;
  accountTypeMode: number;
  classification: number;
  currentClassification: number;
  ledgerSide: number;
  masterAccountId: number;
  parentId: string;
  parentMasterAccountId: number;
  rootMasterAccountId: number;
  sortOrder: number;
  entityTypes?: number[];
  accountNameVariants?: StandardAccountNameVariant[];
  openingBalanceAccountId: string;

  constructor(data) {
    Object.assign(this, data);

    this.accountTypeId = Number(data.accountType.id);
    this.classification = data.sourceClassification;
    this.accountName = data.accountName;
    this.accountTypeMode = Number(data.accountTypeMode);
    this.openingBalanceAccountId = data.openingBalanceAccountId;

    if (data.headerAccount) {
      this.currentClassification = data.headerAccount.currentClassification;
      this.ledgerSide = Number(data.headerAccount.ledgerSide);
      this.parentId = data.headerAccount.id;
      this.parentMasterAccountId = data.headerAccount.masterAccountId;
      this.rootMasterAccountId = Number(data.headerAccount.rootMasterAccountId);
      this.sortOrder = Number(data.headerAccount.sortOrder);
    }

    if (data.entityTypes) {
      this.entityTypes = data.entityTypes;
    }

    if (data.showMultipleHeaderNames) {
      const accountNameVariants: StandardAccountNameVariant[] = [];

      if (data.accountNameForCompany === '' || data.accountNameForCompany) {
        accountNameVariants.push({
          entityType: EntityTypeEnum.Company,
          accountName: data.accountNameForCompany,
        });
      }

      if (data.accountNameForUnitTrust === '' || data.accountNameForUnitTrust) {
        accountNameVariants.push({
          entityType: EntityTypeEnum.UnitTrust,
          accountName: data.accountNameForUnitTrust,
        });
      }

      if (
        data.accountNameForDiscretionaryTrust === '' ||
        data.accountNameForDiscretionaryTrust
      ) {
        accountNameVariants.push({
          entityType: EntityTypeEnum.DiscretionaryTrust,
          accountName: data.accountNameForDiscretionaryTrust,
        });
      }

      if (
        data.accountNameForPartnership === '' ||
        data.accountNameForPartnership
      ) {
        accountNameVariants.push({
          entityType: EntityTypeEnum.Partnership,
          accountName: data.accountNameForPartnership,
        });
      }

      if (
        data.accountNameForSoleTrader === '' ||
        data.accountNameForSoleTrader
      ) {
        accountNameVariants.push({
          entityType: EntityTypeEnum.SoleTrader,
          accountName: data.accountNameForSoleTrader,
        });
      }

      if (data.accountNameForSuper === '' || data.accountNameForSuper) {
        accountNameVariants.push({
          entityType: EntityTypeEnum.SelfManagedSuperannuationFund,
          accountName: data.accountNameForSuper,
        });
      }

      if (
        data.accountNameForAssociation === '' ||
        data.accountNameForAssociation
      ) {
        accountNameVariants.push({
          entityType: EntityTypeEnum.Association,
          accountName: data.accountNameForAssociation,
        });
      }

      this.accountNameVariants = accountNameVariants;
    }

    return {
      accountClass: this.accountClass,
      accountName: this.accountName,
      accountTypeId: this.accountTypeId,
      accountTypeMode: this.accountTypeMode,
      classification: this.classification,
      currentClassification: this.currentClassification,
      ledgerSide: this.ledgerSide,
      masterAccountId: this.masterAccountId,
      parentId: this.parentId,
      parentMasterAccountId: this.parentMasterAccountId,
      rootMasterAccountId: this.rootMasterAccountId,
      sortOrder: this.sortOrder,
      entityTypes: this.entityTypes,
      accountNameVariants: this.accountNameVariants,
      openingBalanceAccountId: this.openingBalanceAccountId,
    };
  }
}
