<div class="pad">

  <div class="header-container">
    <a
    class="btn btn-ghost"
  
    [routerLink]="['/clients', clientId]"
  >
    <i aria-hidden="true" class="fas fa-arrow-left inset"></i>
    Clients
  </a>
    <div>
      <ul class="crumbtrail-nav">
        <li>
          <span class="align-middle"> Import Desktop HandiLedger File </span>
        </li>
      </ul>
    </div>
  </div>
  
  <form [formGroup]="form" (validSubmit)="submit()">
    <div class="jumbotron py-3">
      <p>
        This wizard will step you through importing a Desktop HandiLedger (.zip)
        file into Access Ledger. The below table details are imported during this
        process.
      </p>
  
      <table class="table">
        <caption class="sr-only">
          Listing what de do and do not import
        </caption>
        <thead>
          <tr>
            <th scope="col">What do we import?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                <li>Entities</li>
                <li>Divisions</li>
                <li>Journals</li>
                <li>Chart of Accounts</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
  
      <p>To get started, follow the steps below.</p>
    </div>
  
    <div class="content pad">
      <div class="row">
        <div class="col-form-md">
          <h4>1) <small>Upload the HandiLedger File (.zip)</small></h4>
          <p class="mb-2">
            Please note, if you want to import the same year for the same entity,
            delete the original dataset or ensure the datasets have unique names.
          </p>
          <crs-file-upload
            folder="3"
            [acceptedFiles]="['.zip']"
            (filesUploaded)="onFileUpload($event)"
          ></crs-file-upload>
  
          <div *ngIf="this.uploadedFile">
            <div class="spacer-md"></div>
  
            <h4>2) <small>Analyse the File</small></h4>
            <p>
              Now that you have successfully uploaded the file, click 'Prepare
              File for Import' and we will analyse the file and ask a few
              questions about how you want to import the data.
            </p>
  
            <button
              class="btn btn-secondary"
              type="button"
              [promiseBtn]="busy.prepare"
              (click)="prepare()"
            >
              Prepare File for Import
            </button>
          </div>
        </div>
      </div>
  
      <!-- After File Downloaded-->
      <div *ngIf="prepareResult" [ngBusy]="busy.prepare">
        <div class="spacer-md"></div>
  
        <ng-container
          *ngIf="prepareResult.warnings && prepareResult.warnings.length"
        >
          <div class="alert-warning">
            <h4>Please note the following:</h4>
            <p *ngFor="let warning of prepareResult.warnings">{{ warning }}</p>
          </div>
          <div class="spacer-md"></div>
        </ng-container>
  
        <div class="row">
          <div class="col-lg-10">
            <h4>3) <small>Configure the Import</small></h4>
  
            <p>
              Tell us whether to link the entities in this file to existing
              entities in HandiLedger, or create new ones.
            </p>
  
            <table class="table">
              <caption class="sr-only">
                Lists entities to be imported and how they will be mapped
              </caption>
              <thead class="thead-light">
                <tr>
                  <th scope="col">
                    Map <small><br />HandiLedger (.zip) file</small>
                  </th>
                  <th colspan="2" scope="col">
                    To <small><br />Access Ledger</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label
                      *ngFor="
                        let importEntity of [entityMap.get('importEntity').value]
                      "
                    >
                      <i aria-hidden="true" class="fas fa-th-list me-2"></i>
                      {{ entityMap.get('importEntity').value.name
                      }}<small class="text-muted ms-2">{{
                        entityMap.get('importEntity').value.clientCode
                      }}</small>
                    </label>
                  </td>
                  <td
                    *ngFor="
                      let destinationEntity of [
                        entityMap.get('destinationEntity').value,
                      ]
                    "
                  >
                    <div class="d-inline-block me-2">
                      <span *ngIf="!entityMap.get('destinationEntity').value"
                        ><em
                          >We will create this as a new entity for you</em
                        ></span
                      >
                      <span *ngIf="entityMap.get('destinationEntity').value">
                        <i aria-hidden="true" class="fas fa-th-list me-2"></i>
                        {{ entityMap.get('destinationEntity').value?.legalName }}
                        <small class="text-muted ms-2">{{
                          entityMap.get('destinationEntity').value?.code
                        }}</small>
                      </span>
                    </div>
                  </td>
  
                  <td>
                    <div class="btn-menu btn-mini-menu">
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        *ngIf="!entityMap.get('destinationEntity').value"
                        (click)="addEntity(entityMap.get('destinationEntity'))"
                      >
                        Link to Existing
                      </button>
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        *ngIf="entityMap.get('destinationEntity').value"
                        (click)="addEntity(entityMap.get('destinationEntity'))"
                      >
                        Change Entity
                      </button>
                      <button
                        class="btn btn-secondary btn-sm"
                        type="button"
                        *ngIf="entityMap.get('destinationEntity').value"
                        (click)="clearEntity(entityMap.get('destinationEntity'))"
                      >
                        Clear
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="row" *ngIf="partnerRequired">
          <div class="col-form-md">
            <p>Please select the partner for newly created Entities.</p>
            <div class="form-group required">
              <label class="control-label">Partner</label>
              <crs-user-select
                formControlName="partner"
                [clearable]="false"
                [filterByPartner]="true"
              ></crs-user-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="spacer-lg"></div>
  
    <crs-form-error [error]="error"></crs-form-error>
  
    <div class="panel-footer">
      <div class="btn-menu">
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="!prepareResult"
          [promiseBtn]="busy.import"
        >
          Import HandiLedger File
        </button>
        <button
          class="btn btn-secondary"
          type="button"
          *ngIf="!error"
          (click)="close()"
        >
          Cancel
        </button>
        <button
          class="btn btn-secondary"
          type="button"
          *ngIf="error"
          (click)="close()"
        >
          Close
        </button>
      </div>
    </div>
  </form>
</div>
