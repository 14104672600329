import { AssetService } from './../../assets/asset.service';
import { Subscription, Subject, Observable, empty, EMPTY } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap, exhaustMap, catchError, finalize } from 'rxjs/operators';
import { AssetGroup } from '../asset-group';
import { Asset } from '../../assets/asset';

@Component({
  selector: 'crs-move-assets-to-new-group',
  templateUrl: './move-assets-to-new-group.component.html'
})
export class MoveAssetsToNewGroupComponent implements OnInit, OnDestroy {

  error = null;
  busy = {
    submit: null
  };

  @Input() params: Asset[];

  form = this._formBuilder.group({
    assetGroup: [null, Validators.required]
  });

  submitButtonStream = new Subject();
  subscriptions: Subscription[] = [];

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _activeModal: NgbActiveModal,
    private readonly _assetService: AssetService) { }

  ngOnInit() {

    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => this.error = null),
          exhaustMap(() => this.executeMoveObservable())
        )
        .subscribe(client => {
          this._activeModal.close();
        }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  private executeMoveObservable(): Observable<any> {
    const assetGroup = this.form.value.assetGroup as AssetGroup;

    const loadingStream = new Subject();
    this.busy.submit = loadingStream.subscribe();

    return this._assetService.move(assetGroup.id, this.params.map(p => p.id))
    .pipe(
      catchError(err => {
      this.error = err;
      return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  submit() {
    this.submitButtonStream.next();
  }

  cancel() {
    this._activeModal.dismiss();
  }

}
