<button mat-mini-fab class="round-button plus-button" data-bs-toggle="dropdown">
  <mat-icon>add</mat-icon>
</button>

<div class="dropdown-menu">
  <span class="menu-item" (click)="addItem(1)">Header</span>
  <span class="menu-item" (click)="addItem(2)">Row</span>
  <span class="menu-item" (click)="addItem(3)">Total</span>
  <span class="menu-item" (click)="addItem(4)">Spacer</span>
</div>
