import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, EMPTY } from 'rxjs';
import { tap, switchMap, catchError, filter } from 'rxjs/operators';

import { DepreciationYearService } from './depreciation-years/depreciation-year.service';
import { DepreciationYear } from './depreciation-years/depreciation-year';
import { AssetsContext } from './assets-context';
import { File } from '../';
import { Entity } from 'src/app/firm';

@Injectable({
  providedIn: 'root'
})
export class AssetsContextService implements OnDestroy {

  currentContext: AssetsContext;

  private _contextStream = new BehaviorSubject<AssetsContext>(null);
  context$ = this._contextStream.asObservable();
  contextValid$ = this.context$.pipe(filter(c => !!c && !!c.entity && !!c.file));

  private updateStream = new Subject<boolean>();
  private updateSubscription: Subscription;

  constructor(readonly depreciationYearService: DepreciationYearService) {
    this.updateSubscription = this.updateStream.pipe(
      switchMap(s => this.getDepreciationYears(s)),
      tap(() => this._contextStream.next(this.currentContext))
      ).subscribe();
  }

  ngOnDestroy() {
    // Not sure that this is ever called as the service is a singleton that exists for the lifetime of the app?
    this.updateSubscription.unsubscribe();
  }

  private getDepreciationYears(selectLatestYear?: boolean) {
    if (!this.currentContext.entity) return EMPTY;
    return this.depreciationYearService.getAll(this.currentContext.file.id, this.currentContext.entity.id).pipe(
      tap(r => {
        this.currentContext.setYears(r);
        this.currentContext.getStarted = r && !r.length;
        if (r && r.length) {
          if (this.currentContext.year) this.currentContext.year = r.find(y => y.id === this.currentContext.year.id);
          if (!this.currentContext.year || selectLatestYear) this.currentContext.year = this.currentContext.yearsDescending[0];
        }
      }),
      catchError(e => {
        console.log('Error retrieving depreciation years.', e);
        this.currentContext = AssetsContext.errorContext();
        this._contextStream.next(this.currentContext);
        return EMPTY;
      })
    );
  }

  updateFile(file: File) {
    if (this.currentContext && this.currentContext.file && this.currentContext.file.id === file.id) return;
    this.currentContext = new AssetsContext(file);
    this.updateStream.next();
  }

  updateEntity(entity: Entity) {
    if (this.currentContext.entity.id === entity.id) return;
    this.currentContext.entity = entity;
    this.updateStream.next();
  }

  updateYear(year: DepreciationYear) {
    if (this.currentContext.year.id === year.id) return;
    this.currentContext.year = year;
    this._contextStream.next(this.currentContext);
  }

  refreshYears(selectLatestYear?: boolean) {
    this.updateStream.next(selectLatestYear);
  }
}


