import { AssetReportDepreciationRecord } from './../asset-report-depreciation-record';

export class AssetReportPool {

  openingBalance: number;
  additions: number;
  disposals: number;
  depreciation: number;
  writeOffs: number;
  closingBalance: number;
  records: AssetReportDepreciationRecord[] = [];

  constructor(data) {
    Object.assign(this, data);
    if (data.records) this.records = data.records.map(r => new AssetReportDepreciationRecord(r));
  }

}
