<form novalidate [formGroup]="form" [ngBusy]="[busy.load]">
  <div class="row pad-x">
    <div class="col-md-8 col-xl-6">
      <h2 class="form-group-title">General</h2>

      <div class="form-group required">
        <label class="control-label">Master Name</label>
        <input class="form-control" formControlName="name" type="text" />
      </div>

      <div class="form-group required">
        <label class="control-label">Report Title</label>
        <input class="form-control" formControlName="title" type="text" />
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="isDefault"
            type="checkbox"
          />
          <label class="form-check-label"
            >This is the Default Report Master</label
          >
        </div>
      </div>

      <div class="form-group">
        <label class="control-label">Style</label>
        <crs-report-style-select
          formControlName="reportStyle"
          placeholder="Default"
        ></crs-report-style-select>
      </div>

      <div class="form-group required">
        <label class="control-label">Default Reporting Suite</label>
        <crs-reporting-suite-select
          formControlName="defaultReportingSuite"
          [clearable]="false"
        ></crs-reporting-suite-select>
      </div>

      <div class="form-group required">
        <label class="control-label">Default Footer</label>
        <ng-select
          bindLabel="name"
          bindValue="id"
          formControlName="defaultFooterId"
          [clearable]="false"
          [items]="footers"
        ></ng-select>
      </div>

      <div class="form-group">
        <label class="control-label">Restrict to Entity Type?</label>
        <ng-select
          bindLabel="name"
          bindValue="id"
          formControlName="entityType"
          placeholder="Can be used for all entity types"
          [items]="entityTypes"
        ></ng-select>
      </div>
    </div>
  </div>

  <div class="pad-x">
    <h2 class="form-group-title">Pages</h2>

    <crs-report-master-pages
      formArrayName="pages"
      [formArray]="form.get('pages')"
      [masterGroup]="form"
    ></crs-report-master-pages>

    <div class="spacer-md"></div>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu">
      <div class="btn-group">
        <button
          class="btn btn-primary"
          type="submit"
          [promiseBtn]="busy.submit"
          (click)="save(true)"
        >
          Save and Close
        </button>
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="btn btn-primary dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          type="button"
        >
          <span class="sr-only">Save options</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" type="submit" (click)="save(false)">
            Save Only
          </button>
          <button class="dropdown-item" type="submit" (click)="saveAs()">
            Save As...
          </button>
        </div>
      </div>

      <button class="btn btn-secondary" type="button" (click)="close()">
        Cancel
      </button>

      <button
        class="btn btn-outline-danger float-right"
        title="Delete"
        type="button"
        *ngIf="!isAdd"
        (click)="delete()"
      >
        <i aria-hidden="true" class="fas fa-trash"></i>
        Delete
      </button>
    </div>
  </div>
</form>

<div class="spacer-lg"></div>
