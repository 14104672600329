import { ReportContentInfo } from '../../report-content/report-content-info';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReportHeader, ReportHorizontalRule, ReportParagraph, ReportSpacer } from '../';
import { FinancialTable } from '../report-financial/financial-table';
import { ReportImage } from '../report-image/report-image';
import { ReportPlaceholder } from '../report-placeholder/report-placeholder';
import { IElementLine } from './element-line.interface';
import { MAIN_MENU, PLACEHOLDERS_MENU, SECONDARY_MENUS } from './menu.const';
import { ReportHeaderColumn, ReportMetaData } from '../../report-output';
import { ReportElementTypeEnum } from '../../../enums';
import { ReportTableBuilderService } from '../report-table/services';

@Component({
  selector: 'crs-insert-element-line',
  templateUrl: './insert-element-line.component.html',
  styleUrls: ['./insert-element-line.component.scss'],
})

export class InsertElementLineComponent implements OnInit {
  @ViewChild('menu')
  private _menu: ElementRef;

  @Input() index: number;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;
  @Input() reportColumns: ReportHeaderColumn[] = [];

  get content() {
    return this.reportContent.content;
  }

  get output() {
    return this.reportContent.output;
  }

  get readonly() {
    return this.reportContent.readonly;
  }

  constructor(private readonly _reportTableBuilderService: ReportTableBuilderService) {
  }

  public moreCounter = 0;

  public mainMenu: IElementLine[] = MAIN_MENU;
  public secondaryMenus: IElementLine[][] = SECONDARY_MENUS;
  public placeholdersMenu: IElementLine[] = PLACEHOLDERS_MENU;

  public placeholdersOpened = false;

  ngOnInit() {
    if (this.reportContent.output) {
      this.secondaryMenus = SECONDARY_MENUS.map(sm => sm.filter(s => s.type !== ReportElementTypeEnum.FinancialTable));
    }
  }

  addItem(type: number = -1, param: any) {
    const newElement = this.getNewElement(type, param);
    if (!newElement) return;
    this.content.splice(this.index + 1, 0, newElement);

    if(this.reportMetaData) this.reportMetaData.isModified = true;
    this._menu.nativeElement.click();
  }

  getNewElement(type: number, param: any) {
    switch (type) {
      case ReportElementTypeEnum.Header:
        return new ReportHeader({text: 'New Header...'});
      case ReportElementTypeEnum.Paragraph:
        return new ReportParagraph({text: 'New Paragraph...'});
      case ReportElementTypeEnum.Signature:
        return {elementType: ReportElementTypeEnum.Signature, name: null, allResponsible: false, useReportDate: false};
      case ReportElementTypeEnum.Snippet:
        return {elementType: ReportElementTypeEnum.Snippet, name: null, html: 'New Snippet...'};
      case ReportElementTypeEnum.HorizontalRule:
        return new ReportHorizontalRule();
      case ReportElementTypeEnum.FinancialTable:
        return new FinancialTable();
      case ReportElementTypeEnum.PlaceHolder:
        return new ReportPlaceholder({placeholderType: param});
      case ReportElementTypeEnum.Spacer:
        return new ReportSpacer();
      case ReportElementTypeEnum.Table:
        return this._reportTableBuilderService.buildBlankTable(this.reportColumns);
      case ReportElementTypeEnum.Image:
        return new ReportImage();
      case ReportElementTypeEnum.SectionTable:
        return this._reportTableBuilderService.buildBlankTable(this.reportColumns, { addTotalColumn: true, addTotalRow: true });
      default:
        throw new Error('Not implemented');
    }
  }

  public onMore(event) {
    event.stopPropagation();

    this.moreCounter++;
  }

  public onResetMore() {
    this.moreCounter = 0;
  }

  public onShowPlaceholdersMenu(event) {
    event.stopPropagation();

    this.placeholdersOpened = true;
  }
}
