import { AssetReportGrid } from './../asset-report-grid';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { getDefaultGridOptions } from 'src/app/shared';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'crs-asset-report-full-summary',
  templateUrl: './asset-report-full-summary.component.html'
})
export class AssetReportFullSummaryComponent implements OnInit, OnDestroy {

  @Input() content;

  search = new UntypedFormControl();
  subscriptions: Subscription[] = [];

  gridOptions = getDefaultGridOptions();
  assetReportDollarParams = AssetReportGrid.dollarParams;

  constructor() { }

  ngOnInit() {

    AssetReportGrid.customiseGrid(this.gridOptions);

    this.subscriptions.push(
    this.search.valueChanges.pipe(
      tap(v => this.gridOptions.api.setQuickFilter(v)))
    .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

}
