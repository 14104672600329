import { IListItem } from '../../../../../shared';
import { TableRowType } from './enums';
import { ColumnType } from '../../../reportTemplates';
import { InjectionToken } from '@angular/core';
import { IReportTableConfig } from './interfaces';
import { NumberFormat } from '../../../enums';

export const REPORT_TABLE_CONFIG = new InjectionToken<IReportTableConfig>('REPORT_TABLE_CONFIG');

export const TABLE_ROW_TYPES: IListItem[] = [
  { value: TableRowType.Header, name: 'Header' },
  { value: TableRowType.Row, name: 'Row' },
  { value: TableRowType.Total, name: 'Total' },
  { value: TableRowType.Spacer, name: 'Spacer' },
  { value: TableRowType.ColumnHeader, name: 'Column Header' }
];

export const TABLE_VALUE_TYPES: IListItem[] = [
  { value: ColumnType.Text, name: 'Text' },
  { value: ColumnType.Number, name: 'Number' }
];

export const GROUP_TYPES: TableRowType[] = [ TableRowType.Header, TableRowType.Spacer ];

export const CELL_NUMBER_FORMATS: IListItem[] = [
  { value: NumberFormat.Normal, name: 'Normal' },
  { value: NumberFormat.Percent, name: 'Percent' }
];
