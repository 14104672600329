import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-enum-renderer',
  template: `{{ enumList[value] | sentence }}`,
})
export class EnumRendererComponent implements ICellRendererAngularComp {
  public value: string;
  public enumList: any;

  agInit(params: any): void {
    this.enumList = params['enum'];
    this.refresh(params);
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }
}
