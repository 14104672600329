import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { TaxReportEnum } from './dataset-tax-report.component';
import { Observable } from 'rxjs';
import { ReportOutput } from '../../reports/reportViewer/report-output';

@Injectable({
  providedIn: 'root',
})
export class TaxReportService {
  readonly base: string = 'api/accounting/tax-reports';

  constructor(private apiService: ApiService) {}

  getTaxReport$(
    datasetId: string,
    reportType: TaxReportEnum,
    fileId: string,
    startDate: Date,
    endDate: Date,
  ): Observable<ReportOutput> {
    let url = `${this.base}/${datasetId}/${reportType}`;
    url = this.apiService.addQuery(url, 'fileId', fileId);
    url = this.apiService.addQuery(url, 'startDate', startDate);
    url = this.apiService.addQuery(url, 'endDate', endDate);

    return this.apiService.get<any>(url);
  }
}
