<div class="modal-header">
  <h4 class="modal-title">Ledger Report</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <crs-ledger-report
    [datasetId]="params.datasetId"
    [filters]="params.filters"
    [isModal]="true"
    [triggerImmediately]="true"
  ></crs-ledger-report>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary" type="button" (click)="close()">
    Close
  </button>
</div>
