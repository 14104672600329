<ng-container
  *ngIf="filteredBankAccounts$ | async as statefulBankAccounts; else loading"
>
  <ng-container [ngSwitch]="statefulBankAccounts.state">
    <ng-container *ngSwitchCase="'PENDING'" [ngTemplateOutlet]="loading">
    </ng-container>
    <ng-container
      *ngSwitchCase="'ERROR'"
      [ngTemplateOutlet]="error"
      [ngTemplateOutletContext]="{ err: statefulBankAccounts.errorMessage }"
    >
    </ng-container>
    <ng-container
      *ngSwitchDefault
      [ngTemplateOutlet]="success"
      [ngTemplateOutletContext]="{ bankAccounts: statefulBankAccounts.data }"
    >
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <crs-busy></crs-busy>
</ng-template>

<ng-template #error let-err="err">
  <p>{{ err }}</p>
</ng-template>

<ng-template #success let-bankAccounts="bankAccounts">
  <div class="dropdown">
    <button
      aria-expanded="false"
      aria-haspopup="true"
      class="btn btn-secondary dropdown-toggle float-right"
      data-toggle="dropdown"
      id="dropdownMenuBank"
      type="button"
    >
      <ng-container
        *ngIf="selectedBank$ | async as selectedBank; else loadingBankSelection"
      >
        <ng-container *ngIf="selectedBank; else loadingBankSelection">
          {{ selectedBank.accountName
          }}<ng-container *ngIf="selectedBank.accountNumber">
            / {{ selectedBank.accountNumber }}</ng-container
          >
        </ng-container>
      </ng-container>
      <ng-template #loadingBankSelection>
        <i>Loading...</i>
      </ng-template>
    </button>
    <div aria-labelledby="dropdownMenuBank" class="dropdown-menu">
      <div
        class="dropdown-item mb-1 d-flex justify-content-between btn-secondary-bank"
        *ngFor="let bank of bankAccounts"
      >
        <button
          class="btn d-flex justify-content-between btn-secondary-bank text-truncate p-0 pt-1"
          [ngClass]="{
            active: bank.state === bankAccountStates.Active,
            inactive: bank.state !== bankAccountStates.Active
          }"
          (click)="onSelectBank(bank, $event)"
        >
          <span class="bank-name"
            >{{ bank.accountName
            }}<ng-container *ngIf="bank.accountNumber">
              / {{ bank.accountNumber }}</ng-container
            ></span
          >
          <ng-container *ngIf="bankAccountStates.Inactive == bank.state">
            <span
              class="bank-state"
              [ngClass]="{
                inactive: bank.state == bankAccountStates.Inactive
              }"
              >{{ bankAccountStates[bank.state] }}</span
            >
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</ng-template>
