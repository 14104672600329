<div class="modal-header">
  <h4 class="modal-title">
    {{ isEdit ? 'Update feed' : 'Enable bank feeds' }}
  </h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="onClickClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="onValidSubmit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group required">
          <label class="control-label">Feed collection name</label>
          <input class="form-control" formControlName="feedName" type="text" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label">Reference</label>
          <input
            class="form-control"
            formControlName="billingRef"
            type="text"
          />
        </div>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="form.invalid"
      [promiseBtn]="busy.submit"
    >
      {{ isEdit ? 'Update feed' : 'Enable bank feeds' }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="onClickClose()">
      Cancel
    </button>
  </div>
</form>
