import { StandardAccountService } from './../../../../chart/headers-and-accounts/standard-account.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  forwardRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Observable, concat, of, merge } from 'rxjs';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  map,
  catchError,
  exhaustMap,
} from 'rxjs/operators';

import { PagedResponse } from 'src/app/core';
import { StandardAccount } from 'src/app/accounting/chart/headers-and-accounts/standard-account';

@Component({
  selector: 'crs-standard-account-select',
  templateUrl: './standard-account-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StandardAccountSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StandardAccountSelectComponent
  implements OnInit, ControlValueAccessor
{
  private static PAGE_SIZE = 100;

  @Input() multiple = false;
  @Input() placeholder: string = null;
  @Input() clearable = true;
  @Input() headersOnly = false;
  @Input() readonly = false;
  @Input() filterValues?: string[];
  @Input() removeFilteredChildren = false;

  @ViewChild(NgSelectComponent, { static: true })
  private valueAccessor: ControlValueAccessor;

  scroll$ = new Subject();

  standardAccounts$: Observable<StandardAccount[]>;
  standardAccounts: StandardAccount[];

  loading = false;
  accountInput = new Subject<string>();

  searchTerm: string;
  pagedResponse: PagedResponse<StandardAccount>;

  constructor(private standardAccountService: StandardAccountService) {}

  private searchAccounts$(
    searchValue,
    page: number = null
  ): Observable<PagedResponse<StandardAccount>> {
    if (!page) page = 1;
    return this.standardAccountService.getAccounts$({
      isChild: false,
      searchValue,
      page,
      pageSize: StandardAccountSelectComponent.PAGE_SIZE,
    });
  }

  getNext() {
    if (
      this.pagedResponse &&
      this.pagedResponse.page < this.pagedResponse.pages
    ) {
      this.scroll$.next();
    }
  }

  writeValue(value: any | any[]): void {
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  private filterAccountsAndChildren(
    records: StandardAccount[]
  ): StandardAccount[] {
    const children = new Set();

    return records
      .map((account) => {
        if (
          this.filterValues.includes(account.id) ||
          this.filterValues.includes(account.parentId) ||
          children.has(account.parentId)
        ) {
          children.add(account.id);
        }
        return account;
      })
      .filter(
        (account) =>
          !children.has(account.id) && !children.has(account.parentId)
      );
  }

  private handleAccountsFilter(records: StandardAccount[]): StandardAccount[] {
    if (this.filterValues) {
      // will remove children of the filtered parent from the list
      if (this.removeFilteredChildren) {
        records = this.filterAccountsAndChildren(records);
      } else {
        records = records.filter(
          (account) => !this.filterValues.includes(account.id)
        );
      }
    }

    return this.headersOnly
      ? records
          .filter((account) => account.isHeader)
          .map((account) => new StandardAccount(account))
      : records.map((account) => new StandardAccount(account));
  }

  ngOnInit() {
    (this.valueAccessor as NgSelectComponent).bindLabel = 'accountName';
    (this.valueAccessor as NgSelectComponent).multiple = this.multiple;

    this.standardAccounts$ = concat(
      of([] as StandardAccount[]),
      merge(
        this.accountInput.pipe(
          startWith(''),
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => (this.loading = true)),
          tap((term) => (this.searchTerm = term)),
          switchMap((term) =>
            this.searchAccounts$(term, 1).pipe(
              tap((response) => (this.pagedResponse = response)),
              map((response) => this.handleAccountsFilter(response.records)),
              tap((accounts) => (this.standardAccounts = accounts))
            )
          ),
          catchError((err) => {
            console.error('Error searching for accounts', err);
            return of([] as StandardAccount[]);
          }),
          tap(() => (this.loading = false))
        ),
        this.scroll$.pipe(
          tap(() => (this.loading = true)),
          exhaustMap((term) =>
            this.searchAccounts$(
              this.searchTerm,
              this.pagedResponse.page + 1
            ).pipe(
              tap((response) => (this.pagedResponse = response)),
              map((response) => this.handleAccountsFilter(response.records)),
              map((accounts) => this.standardAccounts.concat(accounts)),
              tap((accounts) => (this.standardAccounts = accounts))
            )
          ),
          catchError((err) => {
            console.error('Error retrieving next page', err);
            return of([] as StandardAccount[]);
          }),
          tap(() => (this.loading = false))
        )
      )
    );
  }
}
