import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core';
import { IStandardResponse } from 'src/app/core/services/standard-response.interface';
import { ReportOutput } from '../reportViewer/report-output';

@Injectable({
  providedIn: 'root',
})
export class ReportDownloadService {
  readonly base: string = 'api/documents';

  constructor(private apiService: ApiService) {}

  exportAsPdf$(report: ReportOutput, title: string) {
    let url = `${this.base}/pdf/download`;
    url = this.apiService.addQuery(url, 'title', title);

    return this.apiService
      .post<IStandardResponse<string>>(url, report)
      .pipe(map((response) => response.data));
  }

  exportAsExcel$(report: ReportOutput, title: string) {
    let url = `${this.base}/excel/download`;
    url = this.apiService.addQuery(url, 'title', title);

    return this.apiService
      .post<IStandardResponse<string>>(url, report)
      .pipe(map((response) => response.data));
  }

  exportAsWord$(report: ReportOutput, title: string) {
    let url = `${this.base}/word/download`;
    url = this.apiService.addQuery(url, 'title', title);

    return this.apiService
      .post<IStandardResponse<string>>(url, report)
      .pipe(map((response) => response.data));
  }

  exportToAccessDocuments$(
    report: ReportOutput,
    fileExtension: string,
    title: string,
    selectedDocumentTagIds: number[]
  ): Observable<boolean> {
    let url = `${this.base}/export/access-documents`;
    url = this.apiService.addQuery(url, 'title', title);
    url = this.apiService.addQuery(url, 'extension', fileExtension);

    selectedDocumentTagIds?.forEach((id) => {
      url = this.apiService.addQuery(url, 'documentTags', id);
    });

    return this.apiService
      .post<IStandardResponse<boolean>>(url, report)
      .pipe(map((response) => response.data));
  }
}
