<div class="modal-header">
  <h4 class="modal-title">Import Assets</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

<div class="modal-body">

  <div class="content">
    <div class="row">
      <div class="col-form-md">

        <h4> 1) <small>Download the Excel Template</small></h4>
        Download the <a href="./assets/excel-templates/AssetImport.xlsm">import template</a> to add asset details.

        <div class="spacer-md"></div>

        <h4> 2) <small>Upload the Excel Template</small></h4>
        <crs-file-upload folder="2" (filesUploaded)="onFileUpload($event)" [acceptedFiles]="['.xlsx', '.xlsm']"></crs-file-upload>
        <div class="spacer-md"></div>

        <h4> 3) <small>Choose your Import Options</small></h4>
        Configure the options below for your import
        <div class="spacer"></div>

        <div class="form-group required">
          <label class="control-label">Depreciation Year</label>
          <ng-select [items]="assetsContext?.yearsDescending" bindValue="year" bindLabel="year" formControlName="depreciationYear"></ng-select>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" formControlName="copyTaxationToAccounting">
            <label class="form-check-label">Copy taxation values to accounting values</label>
          </div>
        </div>
      </div>
      <div class="col-form-max">
        <div class="alert alert-info"><b>Please note:</b> If you already imported assets for the prior years, you only need to import new assets for the new depreciation year. New assets can be identified through <b>Acquisition date</b> in the import template.</div>
      </div>
      <div class="col-form-md">

        <div class="spacer-md"></div>

      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">

        <!-- Preparation Result - Ready -->
        <div class="alert alert-success" *ngIf="prepareResult && prepareResult.successfulImportedAssets > 0">

          <h3>Ready to Import</h3>
          <p>Total Assets ready to import: {{prepareResult.successfulImportedAssets}}</p>

          <div *ngIf="prepareResult.errors.length">
            <p>Note, the following issues with {{prepareResult.unsuccessfulImportedAssets}} asset/s in the import file. All affected assets WILL NOT be imported.</p>
            <span *ngFor="let e of prepareResult.errors">{{e}}<br /></span>
          </div>

          <p>
            Click 'Import Assets' below to import these assets
          </p>

        </div>

        <!-- Preparation Result - Problems -->
        <div class="alert alert-warning" *ngIf="prepareResult && prepareResult.successfulImportedAssets == 0">

          <h3>Problem with Import</h3>
          <p>No assets are able to be imported.</p>

          <div *ngIf="prepareResult.errors.length">
            <p>Note, the following issues with {{prepareResult.unsuccessfulImportedAssets}} asset/s in the import file.</p>
            <span *ngFor="let e of prepareResult.errors">{{e}}<br /></span>
          </div>

        </div>

      </div>
    </div>
  </div>

  <crs-form-error [error]="error"></crs-form-error>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-primary" [promiseBtn]="busy.import" [disabled]="prepareResult && prepareResult.successfulImportedAssets == 0">
    <span *ngIf="!prepareResult">Prepare Import</span>
    <span *ngIf="prepareResult">Import Assets</span>
  </button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
</div>

</form>
