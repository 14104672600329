import { StandardAccountService } from './../../../../chart/headers-and-accounts/standard-account.service';
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
  forwardRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ControlValueAccessor,
  DefaultValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  map,
  catchError,
  switchMap,
  startWith,
} from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

import { PagedResponse } from 'src/app/core';
import {
  AccountType,
  AccountTypeService,
} from 'src/app/accounting/ledger/account-types';
import { Classification, ActiveFileService } from 'src/app/accounting';

@Component({
  selector: 'crs-account-type-select',
  templateUrl: './account-type-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountTypeSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccountTypeSelectComponent
  implements OnInit, ControlValueAccessor
{
  private static PAGE_SIZE = 100;

  @Input() useStandardAccountsAPI: boolean = false;
  @Input() clearable = true;
  @Input() readonly: boolean;
  @Input() multiple: boolean;
  @Input() accountId: string = null;
  @Input() isGrouped?: boolean = true;

  @ViewChild(NgSelectComponent, { static: true })
  private valueAccessor: NgSelectComponent;

  accountTypesObservable: Observable<PagedResponse<AccountType[]>>;
  loading = false;
  accountTypeInput = new Subject<string>();
  classifications = Classification;

  constructor(
    private accountTypeService: AccountTypeService,
    private activeFileService: ActiveFileService
  ) {}

  private searchAccountTypes(search): Observable<PagedResponse<AccountType[]>> {
    if (this.useStandardAccountsAPI) {
      return this.accountTypeService.searchStandardAccounts$(
        false,
        search,
        null,
        AccountTypeSelectComponent.PAGE_SIZE
      );
    }

    return this.accountTypeService.search$(search, this.accountId, 1, 50);
  }

  writeValue(value: any | any[]): void {
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  onOpen() {
    this.accountTypeInput.next('');
  }

  displayGrouping(): string | null {
    return this.isGrouped ? 'classification' : null;
  }

  ngOnInit() {
    this.valueAccessor.bindLabel = 'name'; // Binding not working with html tag for some reason
    this.valueAccessor.multiple = this.multiple;
    // @ts-ignore
    // TODO: Fix this
    this.accountTypesObservable = this.accountTypeInput.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.loading = true)),
      switchMap((term) =>
        this.searchAccountTypes(term).pipe(
          map((response) => response.records),
          catchError((error) => {
            console.log(error);
            return of(null); // empty list on error
          }),
          tap(() => (this.loading = false))
        )
      )
    );
  }
}
