<ng-container
  *ngIf="bankAccounts$ | async as statefulBankAccount; else loading"
>
  <ng-container [ngSwitch]="statefulBankAccount.state">
    <ng-container *ngSwitchCase="'PENDING'" [ngTemplateOutlet]="loading">
    </ng-container>
    <ng-container
      *ngSwitchCase="'ERROR'"
      [ngTemplateOutlet]="error"
      [ngTemplateOutletContext]="{ err: statefulBankAccount.errorMessage }"
    >
    </ng-container>
    <ng-container
      *ngSwitchDefault
      [ngTemplateOutlet]="success"
      [ngTemplateOutletContext]="{ bankAccounts: statefulBankAccount.data }"
    >
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <crs-busy></crs-busy>
</ng-template>

<ng-template #error let-err="err">
  <crs-error-view [message]="err"></crs-error-view>
</ng-template>

<ng-template #success let-bankAccounts="bankAccounts">
  <ng-container *ngIf="checkActiveBankAccounts(bankAccounts); else noBankState">
    <div class="transactions-toolbar pad">
      <div *ngIf="isLedgerAndBankFeedFeaturesEnabled">
        <crs-date-picker placeholder="Start date" [formControl]="startDate" />
      </div>

      <div *ngIf="isLedgerAndBankFeedFeaturesEnabled">
        <crs-date-picker
          placeholder="End date"
          [formControl]="endDate"
          [min]="startDate?.value"
        />
      </div>

      <crs-bank-select />

      <div class="icon-input-wrapper transaction-search">
        <i aria-hidden="true" class="fas fa-search input-icon"></i>
        <input
          aria-describedby="searchIcon"
          placeholder="Quick Transaction Search..."
          type="search"
          [formControl]="search"
        />
      </div>

      <div class="dropdown">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="btn btn-secondary"
          data-bs-toggle="dropdown"
          id="dropdownMenuButtonLock"
          type="button"
          [ngClass]="{ filtered: isLockFiltered() }"
        >
          <span class="align-middle me-2">Lock status</span>
          <i class="fas fa-filter" *ngIf="isLockFiltered()"></i>
          <i aria-hidden="true" class="fas fa-chevron-down align-middle"></i>
        </button>
        <div
          aria-labelledby="dropdownMenuImportButton"
          class="dropdown-menu dropdown-menu-right"
        >
          <button
            class="dropdown-item"
            (click)="toggleFilter('locked', true); $event.stopPropagation()"
          >
            <i
              class="fas fa-check-circle active"
              [class.hide]="!isFilterSelected('locked', true)"
            ></i>
            Locked
          </button>

          <button
            class="dropdown-item"
            (click)="toggleFilter('locked', false); $event.stopPropagation()"
          >
            <i
              class="fas fa-check-circle active"
              [class.hide]="!isFilterSelected('locked', false)"
            ></i>
            Unlocked
          </button>
        </div>
      </div>

      <div class="dropdown">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          class="btn btn-secondary"
          data-bs-toggle="dropdown"
          id="dropdownMenuButtonTransaction"
          type="button"
          [ngClass]="{ filtered: isTransactionFiltered() }"
        >
          <span class="align-middle me-2">Transaction Status</span>
          <i class="fas fa-filter" *ngIf="isTransactionFiltered()"></i>
          <i aria-hidden="true" class="fas fa-chevron-down align-middle"></i>
        </button>
        <div
          aria-labelledby="dropdownMenuImportButton"
          class="dropdown-menu dropdown-menu-right"
        >
          <button
            class="dropdown-item"
            (click)="
              toggleFilter('codedBy', BacoCodedBy.User);
              $event.stopPropagation()
            "
          >
            <i
              class="fas fa-check-circle active"
              [class.hide]="!isFilterSelected('codedBy', BacoCodedBy.User)"
            ></i>
            Coded by user
          </button>

          <button
            class="dropdown-item"
            (click)="
              toggleFilter('codedBy', BacoCodedBy.Rule);
              $event.stopPropagation()
            "
          >
            <i
              class="fas fa-check-circle active"
              [class.hide]="!isFilterSelected('codedBy', BacoCodedBy.Rule)"
            ></i>
            Coded by rule
          </button>

          <button
            class="dropdown-item"
            (click)="toggleFilter('codedBy', null); $event.stopPropagation()"
          >
            <i
              class="fas fa-check-circle active"
              [class.hide]="!isFilterSelected('codedBy', null)"
            ></i>
            Uncoded
          </button>
        </div>
      </div>

      <div>
        <crs-customize-preview-menu
          [selectedEndDate]="endDate.value"
          [selectedStartDate]="startDate.value"
        />
      </div>
    </div>

    <div class="pad-x overflow-auto">
      <crs-transaction-table #transactionTable></crs-transaction-table>
    </div>
  </ng-container>
</ng-template>

<ng-template #noBankState>
  <crs-error-view
    message="You do not have any active bank accounts in this feed collection."
    title="No bank accounts"
  ></crs-error-view>
</ng-template>
