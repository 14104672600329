<div class="mt-3">
  <h2 class="mb-3">Livestock calculator</h2>

  <crs-busy
    *ngIf="
      (isFetchingTradingAccount$ | async) ||
      (isFetchingLivestockCalculator$ | async) ||
      (isUpsertingCalculator$ | async)
    "
  />

  <form [formGroup]="form" (submit)="onValidSubmit()">
    <div class="row">
      <div class="col-md-6 col-sm-8">
        <div class="form-group">
          <label class="control-label">Cost calculation method</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="costCalculationMethod"
            [items]="costCalculationMethods | enumToArray"
            (change)="onChangeCostCalculationMethod($event)"
          />
        </div>
      </div>
    </div>

    <div
      class="row"
      *ngIf="
        form.value.costCalculationMethod === costCalculationMethods.MarketValue
      "
    >
      <div class="col-md-6 col-sm-8">
        <div class="form-group">
          <label class="control-label">Closing stock value</label>
          <input
            class="form-control"
            formControlName="closingStockValue"
            step="0.01"
            type="number"
            (blur)="onBlurClosingStockValue($event)"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-sm-8">
        <div class="form-group">
          <label class="control-label">Natural increase unit price</label>
          <input
            class="form-control"
            formControlName="naturalIncreaseUnitPrice"
            step="0.01"
            type="number"
            (blur)="onBlurNaturalIncreaseUnitPrice($event)"
          />
        </div>
      </div>
    </div>

    <hr />

    <crs-form-error [error]="error" />

    <div class="table-responsive" *ngIf="!this.isAdd">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="border-right-0" scope="col" style="width: 1%"></th>
            <th class="border-left-0" scope="col" style="width: 15%"></th>
            <th scope="col" style="width: 10%">Account No</th>
            <th scope="col">Account Name</th>
            <th scope="col" style="width: 10%">Ledger quantity</th>
            <th scope="col" style="width: 10%">Final quantity</th>
            <th scope="col" style="width: 10%">Balance</th>
            <th
              scope="col"
              style="width: 10%"
              *ngIf="
                form.value.costCalculationMethod ===
                costCalculationMethods.AverageCost
              "
            >
              Average cost
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="2" scope="rowgroup"><h5 class="mb-0">Sales</h5></th>
            <td colspan="7"></td>
          </tr>
          <ng-container
            *ngTemplateOutlet="
              livestockRow;
              context: {
                $implicit: livestockCategories.Sales,
                data: livestockData[LivestockCategory.Sales],
              }
            "
          />

          <tr>
            <th colspan="2" scope="rowgroup">
              <h5 class="mb-0">Cost of sales</h5>
            </th>
            <td colspan="7"></td>
          </tr>
          <ng-container
            *ngTemplateOutlet="
              livestockRow;
              context: {
                $implicit: livestockCategories.OpeningStock,
                data: livestockData[LivestockCategory.OpeningStock],
              }
            "
          />
          <ng-container
            *ngTemplateOutlet="
              livestockRow;
              context: {
                $implicit: livestockCategories.Purchases,
                data: livestockData[LivestockCategory.Purchases],
              }
            "
          />
          <ng-container
            *ngTemplateOutlet="
              livestockInputRow;
              context: {
                $implicit: livestockCategories.NaturalIncrease,
                data: livestockData[LivestockCategory.NaturalIncrease],
                formControlName: 'naturalIncreaseFinalQuantity',
                parentForm: form,
              }
            "
          />

          <tr class="container-grey-bg" id="cost-of-sales-addition-subgroup">
            <td class="border-right-0"></td>
            <td class="border-left-0"></td>
            <td></td>
            <td></td>
            <td class="text-right"></td>
            <td class="text-right font-weight-bold">
              {{ totalCostOfSalesQuantity() | formatNumber }}
            </td>
            <td class="text-right font-weight-bold">
              {{ totalCostOfSalesBalance()?.toFixed(2) || 0 | formatNumber }}
            </td>
            <td
              class="text-right"
              *ngIf="
                form.value.costCalculationMethod ===
                costCalculationMethods.AverageCost
              "
            ></td>
          </tr>

          <tr
            *ngIf="
              form.value.costCalculationMethod ===
              costCalculationMethods.AverageCost
            "
          >
            <td class="border-right-0"></td>
            <td class="border-left-0">Average cost per head</td>
            <td></td>
            <td></td>
            <td class="text-right"></td>
            <td class="text-right"></td>
            <td class="text-right"></td>
            <td
              class="text-right"
              *ngIf="
                form.value.costCalculationMethod ===
                costCalculationMethods.AverageCost
              "
            >
              {{ totalAverageCostPerHead()?.toFixed(2) | number }}
            </td>
          </tr>

          <tr>
            <td colspan="2" scope="rowgroup"><h5 class="mb-0">Less</h5></td>
            <td colspan="7"></td>
          </tr>
          <ng-container
            *ngTemplateOutlet="
              livestockRow;
              context: {
                $implicit: livestockCategories.KilledForRations,
                data: livestockData[LivestockCategory.KilledForRations],
              }
            "
          />
          <ng-container
            *ngTemplateOutlet="
              livestockInputRow;
              context: {
                $implicit: livestockCategories.Deaths,
                data: livestockData[LivestockCategory.Deaths],
                formControlName: 'deathsFinalQuantity',
                parentForm: form,
              }
            "
          />
          <ng-container
            *ngTemplateOutlet="
              livestockInputRow;
              context: {
                $implicit: livestockCategories.ClosingStock,
                data: livestockData[LivestockCategory.ClosingStock],
                formControlName: 'closingStockFinalQuantity',
                parentForm: form,
              }
            "
          />

          <tr class="container-grey-bg" id="cost-of-sales-less-subgroup">
            <td class="border-right-0"></td>
            <td class="border-left-0"></td>
            <td></td>
            <td></td>
            <td class="text-right"></td>
            <td class="text-right font-weight-bold">
              {{ totalLessQuantity() | formatNumber }}
            </td>
            <td class="text-right font-weight-bold">
              {{ totalLessBalance()?.toFixed(2) | formatNumber }}
            </td>
            <td
              class="text-right"
              *ngIf="
                form.value.costCalculationMethod ===
                costCalculationMethods.AverageCost
              "
            ></td>
          </tr>

          <tr>
            <td class="border-right-0"></td>
            <td class="border-left-0">Total cost of sales</td>
            <td></td>
            <td></td>
            <td class="text-right"></td>
            <td class="text-right font-weight-bold">
              {{
                totalCostOfSalesQuantity() - totalLessQuantity() | formatNumber
              }}
            </td>
            <td class="text-right font-weight-bold">
              {{
                (totalCostOfSalesBalance() - totalLessBalance())?.toFixed(2)
                  | formatNumber
              }}
            </td>
            <td
              class="text-right"
              *ngIf="
                form.value.costCalculationMethod ===
                costCalculationMethods.AverageCost
              "
            ></td>
          </tr>
          <tr>
            <td class="border-right-0"></td>
            <td class="border-left-0">
              Gross profit for {{ tradingAccount?.name }}
            </td>
            <td></td>
            <td></td>
            <td class="text-right"></td>
            <td class="text-right font-weight-bold"></td>
            <td class="text-right font-weight-bold">
              {{ getGrossProfitBalance()?.toFixed(2) | formatNumber }}
            </td>
            <td
              class="text-right"
              *ngIf="
                form.value.costCalculationMethod ===
                costCalculationMethods.AverageCost
              "
            ></td>
          </tr>

          <tr>
            <td colspan="2" scope="rowgroup">
              <h5 class="mb-0">Stock on hand</h5>
            </td>
            <td colspan="7"></td>
          </tr>
          <tr
            [class.row-error]="
              !livestockData[LivestockCategory.StockOnHand]?.accountNo &&
              !livestockData[LivestockCategory.StockOnHand]?.accountName
            "
          >
            <td class="border-right-0"></td>
            <td class="border-left-0">{{ livestockCategories.StockOnHand }}</td>
            <td>
              {{
                livestockData[LivestockCategory.StockOnHand]?.accountNo || '-'
              }}
            </td>
            <td>
              {{
                livestockData[LivestockCategory.StockOnHand]?.accountName || '-'
              }}
            </td>
            <td class="text-right">
              {{
                livestockData[LivestockCategory.StockOnHand]?.totalQuantity
                  | formatNumber
              }}
            </td>
            <td class="text-right">
              {{ form.value.closingStockFinalQuantity | formatNumber }}
            </td>
            <td class="text-right">
              {{ totalClosingStockBalance()?.toFixed(2) | formatNumber }}
            </td>
            <td
              class="text-right"
              *ngIf="
                form.value.costCalculationMethod ===
                costCalculationMethods.AverageCost
              "
            ></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="unbalanced-alert" *ngIf="!isFinalQuantityBalanced()">
      <div>
        <crs-form-error
          [error]="[
            'Table not balanced',
            'Double click indicated cells to balance that table or input new values',
          ]"
          [isInfo]="true"
        />
      </div>
    </div>
    <div class="footer pb-3" *ngIf="!this.isAdd">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="
          !form.valid || hasUnassignedAccounts() || !isFinalQuantityBalanced()
        "
        [promiseBtn]="busy.submit"
      >
        Save and post
      </button>
      <button class="btn btn-secondary" type="button" (click)="onClickClose()">
        Close
      </button>
    </div>
  </form>
</div>

<ng-template #livestockRow let-category let-data="data">
  <tr [class.row-error]="!data?.accountNo && !data?.accountName">
    <td class="border-right-0"></td>
    <td class="border-left-0">{{ category }}</td>
    <td>{{ data?.accountNo || '-' }}</td>
    <td>{{ data?.accountName || '-' }}</td>
    <td class="text-right">{{ data?.totalQuantity || 0 | formatNumber }}</td>
    <td class="text-right">{{ data?.finalQuantity || 0 | formatNumber }}</td>
    <td class="text-right">{{ getTotalBalance(data) | formatNumber }}</td>
    <td
      class="text-right"
      *ngIf="
        form.value.costCalculationMethod === costCalculationMethods.AverageCost
      "
    >
      {{ data?.averageCost?.toFixed(2) }}
    </td>
  </tr>
</ng-template>

<ng-template
  #livestockInputRow
  let-category
  let-data="data"
  let-formControlName="formControlName"
  let-parentForm="parentForm"
>
  <tr [class.row-error]="!data?.accountNo && !data?.accountName">
    <td class="border-right-0"></td>
    <td class="border-left-0">{{ category }}</td>
    <td>{{ data?.accountNo || '-' }}</td>
    <td>{{ data?.accountName || '-' }}</td>
    <td class="text-right">{{ data?.totalQuantity || 0 | formatNumber }}</td>
    <td class="text-right light-gray">
      <div class="input-group w-100 d-flex flex-nowrap">
        <span
          class="input-group-addon me-1"
          title="Double click scales to balance table"
          (dblclick)="handleQtyBalanceOnDoubleClick(formControlName)"
        >
          <i aria-hidden="true" class="fa fa-balance-scale"></i>
        </span>
        <form class="flex-grow-1" [formGroup]="parentForm">
          <input
            class="form-control bg-transparent border-0 text-right pe-0 w-100"
            step="0.01"
            style="height: initial; padding: initial"
            type="number"
            [formControlName]="formControlName"
            (blur)="onLivestockFinalQuantityInputBlur($event, formControlName)"
          />
        </form>
      </div>
    </td>
    <td class="text-right">{{ getTotalBalance(data) | formatNumber }}</td>
    <td
      class="text-right"
      *ngIf="
        form.value.costCalculationMethod === costCalculationMethods.AverageCost
      "
    >
      {{ data?.averageCost?.toFixed(2) }}
    </td>
  </tr>
</ng-template>
