<ng-container #flow="flow" [flowConfig]="config"></ng-container>

<input class="flow-button" type="file"
flowButton
[flow]="flow.flowJs"
[flowAttributes]="attributes">

<div *ngFor="let transfer of (flow.transfers$ | async).transfers; trackBy: trackTransfer" class="alert mt-2 mb-0" [class.alert-info]="!transfer.complete" [class.alert-success]="transfer.success" [class.alert-danger]="transfer.error">

  <span class="mb-1">{{transfer.name}} ({{transfer.size | number:0}} bytes)</span>

  <div class="progress">
    <div class="progress-bar" role="progressbar"
    [class.progress-bar-striped]="!transfer.complete"
    [class.progress-bar-animated]="!transfer.complete"
    [class.bg-danger]="transfer.error"
    [style.width] ="(transfer.progress * 100) + '%'" [attr.aria-valuenow]="transfer.progress * 100" aria-valuemin="0" aria-valuemax="100"></div>
  </div>

  <div *ngIf="transfer.error">
    Error uploading file
  </div>

  <div class="btn-mini-menu mt-1">
    <button type="button" class="btn btn-sm btn-primary" (click)="transfer.flowFile.resume()" *ngIf="transfer.paused">Resume</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="transfer.flowFile.pause()" *ngIf="!transfer.complete">Pause</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="transfer.flowFile.cancel()" *ngIf="!transfer.complete">Cancel</button>
    <button type="button" class="btn btn-sm btn-warning" (click)="transfer.flowFile.retry()" *ngIf="transfer.complete && transfer.error">Retry</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="transfer.flowFile.cancel()" *ngIf="transfer.complete">Remove</button>
  </div>

</div>
