<crs-report-editable-element
  [index]="index"
  [reportContent]="reportContent"
  [reportMetaData]="reportMetaData"
  (edit)="onEdit()"
  [onSubmit]="onSubmit"
  [editable]="true"
  [cancellable]="true"
>
  <div viewer>
    <h2
      [ngClass]="
        'header' +
        (element.style == 1 ? '-alternative' : '') +
        (element.style == 2 ? '-pagetitle' : '') +
        ' ' +
        'level-' +
        element.level
      "
      [class.align-center]="element.alignment == 1"
      [class.align-right]="element.alignment == 2"
      [class.margin-top]="element.marginTop"
      [class.margin-bottom]="element.marginBottom"
      *ngIf="element?.text"
    >
      {{ element.text }}
    </h2>
  </div>

  <div editor>
    <form [formGroup]="form" novalidate *ngIf="isEditing">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group required">
            <label class="control-label">Level</label>
            <ng-select
              [items]="levels"
              formControlName="level"
              [clearable]="false"
            ></ng-select>
          </div>
        </div>

        <div class="col-md-5">
          <div class="form-group required">
            <label class="control-label">Style</label>
            <ng-select
              [items]="styles | enumToArray"
              bindValue="value"
              bindLabel="name"
              formControlName="style"
              [clearable]="false"
            ></ng-select>
          </div>
        </div>

        <div class="col-md-5">
          <div class="form-group required">
            <label class="control-label">Alignment</label>
            <ng-select
              [items]="alignments | enumToArray"
              bindValue="value"
              bindLabel="name"
              formControlName="alignment"
              [clearable]="false"
            ></ng-select>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-check me-3">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="marginTop"
          />
          <label class="form-check-label">Margin Above</label>
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="marginBottom"
          />
          <label class="form-check-label">Margin Below</label>
        </div>
      </div>

      <textarea class="form-control required" formControlName="text" rows="2">
      </textarea>
    </form>
  </div>
</crs-report-editable-element>
