<a class="user-icon" href="#" ng-click="publicProfile()">
  <img ngxGravatar 
    [src]="user.icon.source" 
    [email]="user.icon.email"
    [size]="user.icon.size" 
    class="user-icon-img" 
    [title]="user.displayName" 
    [alt]="user.displayName" />
  <span>{{user.displayName}}</span>
</a>
