<div [formGroup]="detail">
  <!-- Profit and Loss Customisations -->
  <div class="row" *ngIf="showSeparateTradingAccounts">
    <div class="col-form-sm">
      <!-- Profit Display Option -->
      <div class="form-group required">
        <label class="control-label">Display Option</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="profitDisplayOption"
          [clearable]="false"
          [items]="profitDisplayOptions | enumToArray"
        ></ng-select>
      </div>

      <!-- Profit Losss Layout Option -->
      <div class="form-group required">
        <label class="control-label">Layout Option</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="profitlossLayoutOption"
          [clearable]="false"
          [items]="profitlossLayoutOptions | enumToArray"
        ></ng-select>
      </div>

      <!-- Separate Trading Accounts -->
      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="separateTradingAccounts"
            placeholder="Separate Trading Accounts"
            readonly
            type="checkbox"
            [id]="'separateCheck' + i"
          />
          <label class="form-check-label" [for]="'separateCheck' + i"
            >Separate Trading Accounts</label
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Layout -->
  <div class="row" [ngBusy]="busy.load">
    <div class="col-sm-9">
      <ag-grid-angular
        #allocatedGrid
        class="ag-theme-material"
        style="width: 100%; height: 100%"
        [autoGroupColumnDef]="groupColumnDef"
        [gridOptions]="gridOptions"
        [rowData]="layout"
      />
    </div>
  </div>
</div>

<ng-template #optionsCell let-row>
  <div class="btn-mini-menu" *ngIf="row.layoutOption && !row.isPlaceholder">
    <input
      title="Show in Note"
      type="checkbox"
      [checked]="row.layoutOption.showAsNote"
      (click)="toggleShowAsNote(row)"
    />
    <!-- Todo: Implement total and 'root' functions for layouts -->
    <!-- <a [routerLink]="" class="btn btn-sm fas-btn" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
          <span class="sr-only"> Show Display Options </span>
        </a>
        <div class="dropdown-menu">
          <button type="button" class="btn dropdown-item" (click)="editTotals(row)">
            <i class="fas fa-stream" aria-hidden="true"></i>
            Edit Totals
          </button>
          <button type="button" class="btn dropdown-item" (click)="moveToRoot(row)" *ngIf="row.hierarchy.length > 1">
            <i class="fas fa-arrow-left" aria-hidden="true"></i>
            Move to Root Level
          </button>
          <button type="button" class="btn dropdown-item" (click)="moveFromRoot(row)" *ngIf="row.hierarchy.length > 1">
              <i class="fas fa-arrow-right" aria-hidden="true"></i>
              Restore Normal Placement
            </button>
        </div> -->
  </div>
</ng-template>
