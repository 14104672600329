export interface IBacoTransactionComment {
  id: string;
  userInfo: IUserInfo,
  createdDate: Date,
  message: string;
}

export interface IUserInfo {
  userId: string;
  userName: string;
  userImage: string;
}
