import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core';

@Injectable({
  providedIn: 'root',
})
export class TaxTransferService {
  readonly base: string = 'api/accounting/taxforms';

  constructor(private apiService: ApiService) {}

  transfer(taxFormId: string, datasetId: string) {
    let url = `${this.base}/transfer`;
    url = this.apiService.addQuery(url, 'formId', taxFormId);
    url = this.apiService.addQuery(url, 'datasetId', datasetId);

    return this.apiService.get<any>(url).pipe(map((s) => s.data));
  }
}
