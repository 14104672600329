import { Injectable } from '@angular/core';

import { ApiService } from '../../core';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  readonly base: string = 'api/signup';

  constructor(private readonly _apiService: ApiService) { }

  public post(model: any) {
    return this._apiService.post<any>(`${this.base}/register`, model);
  }

  public checkTenantId(tenantId: string) {
    return this._apiService.get<boolean>(`${this.base}/tenant-id/${tenantId}/exists`);
  }

  public checkInvitePending(tenantId: string, email: string) {
    return this._apiService.get<boolean>(`${this.base}/tenant-id/${tenantId}/owners/${email}/invitePending`);
  }

  public resendInvite(tenantId: string, email: string) {
    return this._apiService.post<any>(`${this.base}/tenant-id/${tenantId}/owners/${email}/resend-invite`, null);
  }

  public checkOwnerExists(tenantId: string, email: string) {
    return this._apiService.get<boolean>(`${this.base}/tenant-id/${tenantId}/owners/${email}/exists`);
  }

  public makeOwner(tenantId: string, model: any) {
    return this._apiService.post<any>(`${this.base}/tenant-id/${tenantId}/owners`, model);
  }

}
