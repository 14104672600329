<div class="modal-header">
  <h4 class="modal-title" [class.text-danger]="danger">
    {{ title || 'Confirm' }}
  </h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form novalidate [formGroup]="form" (validSubmit)="confirm()">
  <div class="modal-body modal-body-padding">
    <div *ngIf="text">{{ text }}</div>

    <div *ngIf="additionalInfoText" [innerHTML]="additionalInfoText"></div>

    <div
      class="alert {{ alertClass || 'alert-info' }} mt-2"
      *ngIf="alertText"
      [innerHTML]="alertText"
    ></div>
  </div>

  <div class="modal-footer">
    <button
      class="btn"
      type="submit"
      [class.btn-danger]="danger"
      [class.btn-primary]="!danger"
    >
      {{ approveBtnName }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      {{ cancelBtnName }}
    </button>
  </div>
</form>
