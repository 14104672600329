import { GridOptions } from 'ag-grid-community';

export class AssetReportGrid {

  public static dollarParams = {
    rounding: 0,
    showZero: true
  };

  public static customiseGrid(options: GridOptions) {
    options.groupDefaultExpanded = 1;
    options.groupIncludeFooter = true;
    options.defaultColDef = {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true
    };
    options.autoGroupColumnDef = {
      headerName: 'Name',
      field: 'asset.name',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        footerValueGetter: 'x + " Total"',
        innerRenderer: 'hyperlinkRenderer',
        innerRendererParams: this.hyperlinkParams
       }
    };
  }

  private static hyperlinkParams(param) {
    if (!param.data || !param.data.asset) return null;
    return {
      routeDirect: ['./', param.data.asset.id],
      footerValueGetter: '"Total " + x'
    };
  }

}
