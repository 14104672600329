<ng-select
  bindLabel="name"
  [items]="sources$ | async"
  [loading]="loading"
  [readonly]="readonly"
  [virtualScroll]="true"
>
  <ng-template
    let-index="index"
    let-search="searchTerm"
    let-source="item"
    ng-option-tmp
  >
    <img
      class="icon-img mr-2 align-middle"
      [alt]="source?.sourceType?.name"
      [src]="source?.sourceType?.icon"
    />
    <span [ngOptionHighlight]="search">{{ source.name }}</span>
  </ng-template>
  <ng-template let-source="item" ng-label-tmp>
    <img
      class="icon-img mr-2 align-middle"
      *ngIf="source?.sourceType?.icon"
      [alt]="source?.sourceType?.name"
      [src]="source.sourceType.icon"
    />
    {{ source.name }}
  </ng-template>
  <ng-template ng-footer-tmp>
    <div class="ng-dropdown-panel-items">
      <div class="ng-option" (click)="onClickAddSource()">
        Add New Source...
      </div>
    </div>
  </ng-template>
</ng-select>
