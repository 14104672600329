import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DateHelpers } from '../../utilities/date-helpers';

type BadgeCellRendererParams = ICellRendererParams & {
  badgeText: string;
  monthsToCheck?: number;
  preventBadgeOnValue?: string[];
  hideBadge?: boolean;
  variant?: 'success' | 'danger' | 'warning';
};

@Component({
  selector: 'crs-badge-renderer',
  template: `
    <span>
      <span>{{ cellValue }}</span>
      &nbsp;
      <small>
        <span
          class="badge ml-1"
          *ngIf="showBadge"
          [ngClass]="'badge-' + variant"
        >
          {{ badgeText }}
        </span>
      </small>
    </span>
  `,
})
export class BadgeRendererComponent implements ICellRendererAngularComp {
  public cellValue: string;
  public showBadge: boolean = false;
  public badgeText: string;
  public variant: 'success' | 'danger' | 'warning' = 'success';

  // gets called once before the renderer is used
  agInit(params: BadgeCellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.showBadge = this.shouldBadgeBeDisplayed(params);
    this.badgeText = params.badgeText || 'No badgeText detected';
    this.variant = params.variant || this.variant;
  }

  // gets called whenever the cell refreshes
  refresh(params: BadgeCellRendererParams): boolean {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  newBadgeRenderer(params: BadgeCellRendererParams) {
    const { data, monthsToCheck } = params;
    const { isMaster, createdDate } = data;

    if (!createdDate) {
      throw Error(
        'No createdDate included in the params of the badgeRenderer cellRenderer'
      );
    }

    // generally the badge component will check isMaster and createdDate however if isMaster is undefined rather than a boolean it will still run against non master items
    if (isMaster === false) {
      return false;
    }

    return DateHelpers.isWithinLastXMonths(createdDate, monthsToCheck || 3);
  }

  shouldBadgeBeDisplayed(params: BadgeCellRendererParams) {
    if (
      params.preventBadgeOnValue?.includes(this.cellValue) ||
      params.hideBadge
    ) {
      return false;
    }

    if (params.badgeText === 'New') {
      return this.newBadgeRenderer(params);
    }

    return true;
  }

  getValueToDisplay(params: BadgeCellRendererParams) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
