import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountingCoreModule } from './../core/accounting-core.module';

import { SharedModule } from 'src/app/shared/shared.module';

import { AssetsComponent } from './assets/assets/assets.component';
import { DepreciationYearsComponent } from './depreciation-years/depreciation-years/depreciation-years.component';
import { DepreciationYearComponent } from './depreciation-years/depreciation-year/depreciation-year.component';
import { AssetGroupsComponent } from './asset-groups/asset-groups/asset-groups.component';
import { AssetGroupComponent } from './asset-groups/asset-group/asset-group.component';
import { AssetComponent } from './assets/asset/asset.component';
import { AssetDifferenceToggleComponent } from './assets/asset/asset-difference-toggle/asset-difference-toggle.component';
import { AssetAdditionComponent } from './asset-addition/asset-addition.component';
import { DepreciationRecordDetailComponent } from './assets/depreciation-record-detail/depreciation-record-detail.component';
import { AssetGroupSelectComponent } from './asset-groups/asset-group-select/asset-group-select.component';
import { AssetReportsComponent } from './asset-reports/asset-reports/asset-reports.component';
import { AssetReportFullSummaryComponent } from './asset-reports/asset-report-full-summary/asset-report-full-summary.component';
import { AssetReportDetailComponent } from './asset-reports/asset-report-detail/asset-report-detail.component';
import { MoveAssetsToNewGroupComponent } from './asset-groups/move-assets-to-new-group/move-assets-to-new-group.component';
import { AssetReportPoolComponent } from './asset-reports/asset-report-pool/asset-report-pool.component';
import { AssetReportTaxReturnComponent } from './asset-reports/asset-report-tax-return/asset-report-tax-return.component';
import { AssetReportTaxAuditComponent } from './asset-reports/asset-report-tax-audit/asset-report-tax-audit.component';
import { AssetPoolingComponent } from './asset-pooling/asset-pooling/asset-pooling.component';
import { AllocateAssetsToPoolComponent } from './asset-pooling/allocate-assets-to-pool/allocate-assets-to-pool.component';
import { AssetsImportComponent } from './assets-import/assets-import.component';
import { AssetsRootComponent } from './assets-root/assets-root.component';
import { AssetGroupJournalSettingsComponent } from './asset-groups/asset-group/asset-group-journal-settings/asset-group-journal-settings.component';
import { ManagePoolActionsComponent } from './asset-pooling/manage-pool-actions/manage-pool-actions.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AccountingCoreModule
  ],
  declarations: [
    AssetsComponent,
    DepreciationYearsComponent,
    DepreciationYearComponent,
    AssetGroupsComponent,
    AssetGroupComponent,
    AssetComponent,
    AssetDifferenceToggleComponent,
    AssetAdditionComponent,
    DepreciationRecordDetailComponent,
    AssetGroupSelectComponent,
    AssetReportsComponent,
    AssetReportFullSummaryComponent,
    AssetReportDetailComponent,
    MoveAssetsToNewGroupComponent,
    AssetReportPoolComponent,
    AssetReportTaxReturnComponent,
    AssetReportTaxAuditComponent,
    AssetPoolingComponent,
    AllocateAssetsToPoolComponent,
    AssetsImportComponent,
    AssetsRootComponent,
    AssetGroupJournalSettingsComponent,
    ManagePoolActionsComponent
  ],
  exports: [
    AssetGroupSelectComponent
  ]
})
export class AssetsModule { }
