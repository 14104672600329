<!-- Search -->
<div class="row">
  <div class="col-sm-12">
    <div class="icon-input-wrapper flex-grow-1 me-2">
      <i aria-hidden="true" class="fas fa-search input-icon"></i>
      <input
        aria-describedby="searchIcon"
        placeholder="Search..."
        type="search"
        [formControl]="search"
      />
    </div>
  </div>
</div>

<div style="overflow: hidden; flex-grow: 1">
  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    style="width: 100%"
    [gridOptions]="gridOptions"
    [rowData]="content.records"
  />
</div>

<div class="spacer-lg"></div>

<div *ngIf="content.records.length">
  <table class="table">
    <caption class="sr-only">
      {{
        depreciationPools[poolType] | sentence
      }}
      Summary
    </caption>
    <thead class="thead-light">
      <tr>
        <th scope="col">
          <span *ngIf="poolType !== undefined">{{
            depreciationPools[poolType] | sentence
          }}</span>
          <span *ngIf="poolType === undefined">Pool</span>
          Summary
        </th>
        <th scope="col">$</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Opening Balance</td>
        <td>{{ content.openingBalance | money: 0 : true }}</td>
      </tr>
      <tr>
        <td>Add: Additions</td>
        <td>{{ content.additions | money: 0 : true }}</td>
      </tr>
      <tr>
        <td>Less: Total Depreciation Deduction</td>
        <td>{{ content.depreciation | money: 0 : true }}</td>
      </tr>
      <tr>
        <td>Less: Disposals</td>
        <td>{{ content.disposals | money: 0 : true }}</td>
      </tr>
      <tr>
        <td>Negative Balance Assessed</td>
        <td>{{ content.writeOffs | money: 0 : true }}</td>
      </tr>
      <tr>
        <td>Closing Balance</td>
        <td>{{ content.closingBalance | money: 0 : true }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="spacer-lg"></div>
