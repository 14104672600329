export enum ElectronicSignatureType {
  None = 0,
  PleaseSign = 1,
  "Access Documents" = 2,
}

export enum DocumentIntegrationValue {
  None = 'None',
  PleaseSign = 'PleaseSign',
  AccessDocuments = 'Access Documents',
}
