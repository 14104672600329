<div class="row">
    <div class="col-sm-12">
        <div class="form-group">
            <div [formGroup]="formGroup">
                <label class="control-label">Trustee Type</label>
                <ng-select [items]="trusteeTypes" bindValue="id" bindLabel="name" formControlName="trusteeTypeId" [clearable]="false"></ng-select>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <div class="form-group">
            <div [formGroup]="formGroup" *ngIf="formGroup.get('trusteeTypeId').value == this.corporateTrusteeType">
                <label class="control-label">Company Name (corporate trustee)</label>
                <input type="text" class="form-control" formControlName="companyName" />
            </div>
        </div>
    </div>
</div>