import { AssetReportGrid } from './../asset-report-grid';
import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { getDefaultGridOptions } from 'src/app/shared';
import { tap } from 'rxjs/operators';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'crs-asset-report-full-summary',
  templateUrl: './asset-report-full-summary.component.html',
})
export class AssetReportFullSummaryComponent implements OnInit, OnDestroy {
  @Input() content;

  search = new UntypedFormControl();
  subscriptions: Subscription[] = [];

  gridOptions: GridOptions;
  gridApi: GridApi;
  assetReportDollarParams = AssetReportGrid.dollarParams;

  constructor() {}

  ngOnInit() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      onGridReady: (event) => (this.gridApi = event.api),
      columnDefs: [
        {
          field: 'asset.assetGroupName',
          headerName: 'Group',
          enableRowGroup: true,
          hide: true,
          rowGroup: true,
        },
        {
          field: 'asset.code',
          headerName: 'Code',
          hide: true,
        },
        {
          field: 'asset.identifier',
          headerName: 'Identifier',
          hide: true,
        },
        {
          field: 'asset.acquisitionDate',
          headerName: 'Acq Date',
          type: 'dateColumn',
        },
        {
          field: 'accounting.closingCost',
          headerName: 'Cost',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'accounting.additions',
          headerName: 'Additions',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          headerName: 'Taxation',
          headerClass: 'ag-column-header',
          children: [
            {
              field: 'taxation.openingCarryingAmount',
              headerName: 'OWDV',
              type: 'dollarColumn',
              cellRendererParams: this.assetReportDollarParams,
            },
            {
              field: 'taxation.depreciationMethodDescription',
              headerName: 'T',
              width: 60,
            },
            {
              field: 'taxation.rate',
              headerName: 'Rate',
              type: 'percentColumn',
            },
            {
              field: 'taxation.depreciation',
              headerName: 'Depr',
              type: 'dollarColumn',
              cellRendererParams: this.assetReportDollarParams,
            },
            {
              field: 'taxation.closingCarryingAmount',
              headerName: 'CWDV',
              type: 'dollarColumn',
              cellRendererParams: this.assetReportDollarParams,
            },
            {
              field: 'taxation.balancingAdjustment',
              headerName: 'Bal Adj',
              type: 'dollarColumn',
              cellRendererParams: this.assetReportDollarParams,
            },
          ],
        },
        {
          headerName: 'Accounting',
          headerClass: 'ag-column-header',
          children: [
            {
              field: 'accounting.openingCarryingAmount',
              headerName: 'OWDV',
              type: 'dollarColumn',
              cellRendererParams: this.assetReportDollarParams,
            },
            {
              field: 'accounting.depreciationMethodDescription',
              headerName: 'T',
              width: 60,
            },
            {
              field: 'accounting.rate',
              headerName: 'Rate',
              type: 'percentColumn',
            },
            {
              field: 'accounting.depreciation',
              headerName: 'Depr',
              type: 'dollarColumn',
              cellRendererParams: this.assetReportDollarParams,
            },
            {
              field: 'accounting.closingCarryingAmount',
              headerName: 'CWDV',
              type: 'dollarColumn',
              cellRendererParams: this.assetReportDollarParams,
            },
            {
              field: 'accounting.balancingAdjustment',
              headerName: 'Bal Adj',
              type: 'dollarColumn',
              cellRendererParams: this.assetReportDollarParams,
            },
          ],
        },
      ],
    };

    AssetReportGrid.customiseGrid(this.gridOptions);

    this.subscriptions.push(
      this.search.valueChanges
        .pipe(
          tap((search) => {
            this.gridApi.setGridOption('quickFilterText', search);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }
}
