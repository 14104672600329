import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'crs-bank-connection-completion',
  templateUrl: './bank-connection-completion.component.html',
  styleUrls: ['./bank-connection-completion.component.scss']
})
export class BankConnectionCompletionComponent {

  isAkahuProvider: boolean;
  akahuRevokeLinkUrl = 'https://my.akahu.nz/';
  akahuRevokeLinkText = 'my.akahu.nz';
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.isAkahuProvider = false;
    this.route.queryParams.subscribe(params => {
      const providerName = params['provider'];
      if(providerName == "Akahu")
      {
        this.isAkahuProvider = true;
      }
    });
  }
}
