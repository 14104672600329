<div [formGroup]="formGroup">

  <div class="card-header card-header-split">

    <!-- Replace with <ng-content> tag below once angular material gets a fix for doing cdDRagHandle in child components, refer https://github.com/angular/material2/issues/13784</ng-content>-->
    <!--<div class="card-header-left draggable" cdkDragHandle>
      <i class="fas fa-bars" aria-hidden="true"></i>
      Page {{i + 1}}
    </div>-->

    <ng-content></ng-content>

    <div class="card-header-center">

      <span class="d-inline-block">
        <div class="form-inline">
          <div class="form-group">
            <div class="form-control-static">
              {{ formGroup?.controls['customTitle']?.value != null ? formGroup?.controls['customTitle']?.value:  formGroup?.controls['pageType']?.value?.title }}
              <small><span *ngIf="formGroup?.controls['pageType']?.value?.isBeta" class="badge badge-success ml-1 new-tag">NEW</span></small>
              <span class="badge badge-primary" *ngIf="formGroup?.controls['customTitle']?.value != null">{{formGroup?.controls['pageType']?.value?.title}}</span>
            </div>
          </div>
        </div>
      </span>

    </div>

    <div class="card-header-right">
      <div class="d-block">
        <a [routerLink]="[]" role="button" [attr.aria-expanded]="isExpanded" [attr.aria-controls]="'#page-content-'+i" (click)="isExpanded = !isExpanded">
          Customise
          <i class="fas fa-chevron-down" aria-hidden="true"></i>
        </a>

        <button type="button" title="remove column" class="close ml-5" (click)="removed.emit(i)">
          <i class="fas fa-times" aria-hidden="true"></i>
        </button>
      </div>

    </div>

  </div>

  <div [id]="'page-content-'+i" [ngbCollapse]="!isExpanded">

    <div class="card-body">

      <div class="row">
        <div class="col-sm-9">

          <!-- Title -->
          <div class="form-group">
            <label class="control-label">Title</label>
            <div class="input-group" *ngIf="!editingTitle && !formGroup?.controls['customTitle']?.value">
              <input type="text" readonly class="form-control" [value]="formGroup?.controls['pageType']?.value?.title">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="editingTitle = true">Customise</button>
              </div>
            </div>
            <div class="input-group" *ngIf="editingTitle || formGroup?.controls['customTitle']?.value">
              <input type="text" class="form-control" placeholder="Enter custom title..." formControlName="customTitle" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="editingTitle = false; formGroup?.controls['customTitle']?.setValue(null)">Revert</button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <crs-report-master-financial-page-detail [index]="i" [open]="isExpanded" [pageId]="pageId" [pageType]="formGroup?.controls['pageType']?.value" [detail]="formGroup.controls['financialDetail']" *ngIf="formGroup?.controls['pageType']?.value?.hasFinancialDetail"></crs-report-master-financial-page-detail>
      <crs-report-template-cashflow-page-detail [index]="i" [open]="isExpanded" [pageId]="pageId" [pageType]="formGroup?.controls['pageType']?.value" [detail]="formGroup?.controls['cashflowDetail']" *ngIf="formGroup?.controls['pageType']?.value?.hasCashFlowDetail"></crs-report-template-cashflow-page-detail>
    </div>

  </div>

</div>

