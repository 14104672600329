<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/central'">Central</a></li>
    <li class="breadcrumb-item"><a [routerLink]="['../']">Databases</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{isAdd ? 'New' : ''}} Database</li>
  </ol>
</nav>

<form [formGroup]="form" (validSubmit)="submit()" novalidate [ngBusy]="busy.load">

  <h2 class="form-group-title">{{isAdd ? 'New' : ''}} Database</h2>

  <hr/>

  <div class="row">
    <div class="col-form-md">

      <div class="form-group" *ngIf="!isAdd">
        <label class="control-label">Id</label>
        <input type="text" class="form-control" formControlName="id" readonly>
      </div>

      <div class="form-group required">
        <label class="control-label">Name</label>
        <input type="text" class="form-control" placeholder="Database Name" formControlName="name" />
      </div>

      <div class="form-group">
        <label class="control-label">Database Name</label>
        <input type="text" class="form-control" placeholder="cirrus.tenants.[...]" formControlName="databaseName" [readonly]="!isAdd" />
      </div>

      <div class="form-group">
        <label class="control-label">Database Server</label>
        <input type="text" readonly class="form-control" [value]="form.get('databaseServer')?.value?.name" readonly="true">
      </div>

      <div class="form-group">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" formControlName="multiTenant" [readonly]="!isAdd">
          <label class="form-check-label">Multi-Tenant</label>
          <small class="form-text text-muted">
             More than one organisation uses this database
          </small>
        </div>
      </div>

    </div>
  </div>

  <div class="spacer"></div>

  <crs-form-error [error]="error"></crs-form-error>

<div class="btn-menu form-bottom-menu">
  <!-- <button type="submit" class="btn btn-primary" [promiseBtn]="busy.submit">Save and Close</button> -->
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
</div>

</form>
