<div class="pad">
  <div class="panel" [ngBusy]="[busy.load]">
    <div class="panel-heading">
      <div class="d-flex">
        <div class="flex-grow-1">
          <span class="primary">Request History</span>
        </div>

        <div class="close" (click)="close()">
          Close <i aria-hidden="true" class="fas fa-times ml-1"></i>
        </div>
      </div>
    </div>

    <div class="panel-body">
      <div style="overflow: hidden; flex-grow: 1">
        <ag-grid-angular
          #agGrid
          class="ag-theme-material"
          style="width: 100%"
          [gridOptions]="gridOptions"
          [rowData]="requestHistories"
        >
          <ag-grid-column
            field="document"
            headerName="Documents"
            [editable]="false"
          ></ag-grid-column>
          <ag-grid-column
            field="requestor"
            headerName="Requestor"
            [editable]="false"
          ></ag-grid-column>
          <ag-grid-column
            field="dateSent"
            headerName="Date Sent"
            type="dateColumn"
            [editable]="false"
          ></ag-grid-column>
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
