import { Subscription } from 'rxjs';
import { AssetComponent } from './../asset.component';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'crs-asset-difference-toggle',
  templateUrl: './asset-difference-toggle.component.html',
  styleUrls: ['./asset-difference-toggle.component.scss']
})
export class AssetDifferenceToggleComponent implements OnInit, OnDestroy {

  @Input() key: string;

  @Input() parent: {
    toggles: any,
    taxation: UntypedFormGroup,
    accounting: UntypedFormGroup
  };

  taxationValueSubscription: Subscription;

  constructor() { }

  ngOnInit() {
    const taxationControl = this.parent.taxation.controls[this.key];
    const accountingControl = this.parent.accounting.controls[this.key];
    this.taxationValueSubscription = taxationControl.valueChanges
      .pipe(
        filter(() => !this.parent.toggles[this.key]),
        tap(v => {
          accountingControl.setValue(v);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.taxationValueSubscription.unsubscribe();
  }

  toggle() {
    const toggles = this.parent.toggles;

    // Set toggle value
    toggles[this.key] = !toggles[this.key];

    // Update linked value
    if (!toggles[this.key]) {
      this.parent.accounting.controls[this.key].patchValue(this.parent.taxation.controls[this.key].value);
    }

    //this.parent.accounting.controls[this.key].disabled = !!toggles[this.key];
  }

}
