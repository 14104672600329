import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiError } from '../interceptors/api-error';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({providedIn: 'root'})
export class MessageService {

  constructor(private toastr: ToastrService) {}

  success(message: string, hideIfAtBottom = false) {
    console.log('[MessageService] Success message: ' + message);
    setTimeout(() =>  this.toastr.success(message, '', { enableHtml: true }));
  }

  error(error: string | ApiError, hideIfAtBottom = false) {

    let message: string;
    if (typeof error === 'string') message = error;
    else if (error instanceof ApiError) {
      message = error.messageString;
    }

    console.log('[MessageService] Error message: ' + message, error);

    // Determine if document is scrolled to the bottom
    if (hideIfAtBottom) {
      if ((document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight
        === document.documentElement.scrollHeight) {
        return;
      }
    }

    setTimeout(() => this.toastr.error(message, '', { enableHtml: true }));
  }

  instructionalError(error: string | ApiError, title: string, hideIfAtBottom = false) {

    let message: string;
    if (typeof error === 'string') message = error;
    else if (error instanceof ApiError) {
      message = error.messageString;
    }

    console.log('[MessageService] Error message: ' + message, error);

    // Determine if document is scrolled to the bottom
    if (hideIfAtBottom) {
      if ((document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight
        === document.documentElement.scrollHeight) {
        return;
      }
    }

    setTimeout(() => this.toastr.error(message, title, { enableHtml: true, closeButton: true, disableTimeOut: true }));
  }

  public info(message: string, override?: Partial<IndividualConfig>): Promise<number> {
    let toastId = null;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const settings: Partial<IndividualConfig> =  !!override ? { ...override, enableHtml: true } : { enableHtml: true };
        toastId =  this.toastr.info(message, '', settings).toastId;
        resolve(toastId);
      });
    });
  }

  public remove(toastId: number): boolean {
    return this.toastr.remove(toastId);
  }
}
