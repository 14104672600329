<div class="modal-header">
  <h4 class="modal-title" [class.text-danger]="danger">{{ title || 'Confirm' }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="confirm()" novalidate>

<div class="modal-body" [ngClass]="{'modal-body-padding': hideCancelButton}">
  <div [innerHTML]="text"></div>

  <div class="mt-3 mb-3" *ngIf="passPhrase">
    Please type in <strong><span [innerHTML]="passPhraseHtml"></span></strong> to confirm.
    <div class="form-group required">
      <input type="text" class="form-control" formControlName="passPhrase" />
    </div>
  </div>
</div>

<div class="modal-footer">
  <button #submitBtn type="submit" class="btn" [class.btn-primary]="!danger" [class.btn-danger]="danger">{{ approveBtnName }}</button>
  <button *ngIf="!hideCancelButton" type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">{{ cancelBtnName }}</button>
</div>

</form>
