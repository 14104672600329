<div class="content">
  <div class="container-grey-bg">
    <div class="clients-title-header">
      <h1 class="m-0">Clients</h1>
    </div>
  </div>

  <div class="clients-toolbar pad">
    <div class="client-search">
      <div class="icon-input-wrapper">
        <i aria-hidden="true" class="fas fa-search input-icon"></i>
        <input
          aria-describedby="searchIcon"
          placeholder="Search clients and entities..."
          type="search"
          [formControl]="search"
        />
      </div>
    </div>

    <div class="">
      <div class="clients-toolbar-buttons" *ngIf="!activeSearchSession">
        <button
          class="btn btn-primary"
          title="Create Client"
          type="button"
          (click)="addClient()"
        >
          <i aria-hidden="true" class="fas fa-plus inset"></i>
          New Client Group
        </button>

        <button
          class="btn btn-secondary"
          type="button"
          [disabled]="checkIsDisabled()"
          [title]="
            !activeSearchSession && !activeClientId
              ? 'Add client entity (Only available when a client is selected)'
              : 'Add client entity'
          "
          (click)="addEntity()"
        >
          New Entity
        </button>

        <button
          class="btn btn-secondary"
          type="button"
          [disabled]="checkIsDisabled()"
          [title]="
            !activeSearchSession && !activeClientId
              ? 'Add consolidated file (Only available when a client is selected)'
              : 'Add consolidated file'
          "
          (click)="addFile(false, null)"
        >
          New Consolidated File
        </button>

        <div class="dropdown">
          <button
            aria-expanded="false"
            aria-haspopup="true"
            class="btn btn-secondary"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton1"
            type="button"
            [disabled]="checkIsDisabled() || (isAPSChart$ | async) === true"
          >
            <span class="align-middle me-2">Import</span>
            <i aria-hidden="true" class="fas fa-chevron-down align-middle"></i>
          </button>

          <div
            aria-labelledby="dropdownMenuImportButton"
            class="dropdown-menu dropdown-menu-right"
            [title]="
              !activeSearchSession && !activeClientId
                ? 'Import HandiLedger file from integration server (Only available when a client is selected)'
                : 'Import HandiLedger file from integration server'
            "
          >
            <a class="dropdown-item" (click)="importDesktopHandiLedgerFile()">
              Import Desktop HandiLedger File (.zip)
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="client-lists-wrapper d-flex h-100 overflow-auto">
    <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 p-0 overflow-auto">
      <div class="left-menu">
        <div>
          <crs-busy *ngIf="busy.clients"></crs-busy>

          <div
            class="pad"
            *ngIf="
              !activeSearchSession && response && !response?.records?.length
            "
          >
            <button
              class="btn btn-insert m-0 w-100"
              title="Create Client"
              type="button"
              (click)="addClient()"
            >
              <i aria-hidden="true" class="fas fa-plus inset"></i>
              New Client Group
            </button>
          </div>

          <div class="list-group-primary" *ngIf="response">
            <div
              class="list-group-item clickable"
              *ngFor="let client of response?.records"
              [ngClass]="{ active: client.id == activeClientId }"
              (click)="activateClient(client.id)"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-inline-block">
                  <i aria-hidden="true" class="fas fa-users fas-btn me-2"></i>
                </div>

                <div class="d-inline-block align-middle">
                  <strong>{{ client.name }}</strong>
                  <br />
                  <small>{{ client.code }}</small>
                </div>

                <div class="d-inline-block ms-auto">
                  <div *ngIf="!activeSearchSession">
                    <i
                      aria-hidden="true"
                      class="fas fa-pencil-alt fas-btn-muted"
                      (click)="editClient(client.id)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="spacer"></div>

          <div class="d-flex justify-content-center">
            <crs-paging
              *ngIf="!activeSearchSession"
              [pagedResponse]="response"
              (navigate)="refreshClients($event)"
            ></crs-paging>
          </div>
        </div>
      </div>
    </div>

    <div
      #entitiesContainer
      class="col-xl-4 col-lg-5 col-md-6 col-sm-6 p-0 center-menu overflow-auto"
    >
      <div class="pad" *ngIf="!activeSearchSession && !activeClientId">
        <div class="empty-display">
          No entities to display, select a client group
        </div>
      </div>

      <div *ngIf="activeSearchSession">
        <crs-busy *ngIf="busy.entities"></crs-busy>
        <div *ngIf="entityResponse && entityResponse.recordsCount == 0">
          No entities found...
        </div>

        <div
          class="list-group-primary"
          *ngIf="entityResponse && entityResponse.records"
        >
          <ng-container *ngFor="let entity of entityResponse.records">
            <div
              class="list-group-item clickable"
              (click)="activateClient(entity.clientId)"
            >
              <div
                class="d-flex justify-content-between align-items-center h-100"
              >
                <div class="d-inline-block">
                  <i
                    aria-hidden="true"
                    [class]="
                      getIconClass(entity.entityTypeId) + ' fas-btn me-2'
                    "
                  ></i>
                  {{ entity.legalName }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <router-outlet
        *ngIf="!activeSearchSession && activeClientId"
      ></router-outlet>
    </div>
  </div>
</div>
