import { OAuthService } from 'angular-oauth2-oidc';
import { Component, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Config } from 'src/app/core';
import { FlowDirective, Transfer } from '@flowjs/ngx-flow';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export class UploadedFile {
  folder: number;
  uploadPath: string;
  fileName: string;
  constructor(folder: number, uploadPath: string, fileName: string) {
    this.folder = folder;
    this.uploadPath = uploadPath;
    this.fileName = fileName;
  }
}

export class UploadedImageFile {
  transfer: any;
  uploadedFile: UploadedFile;

  constructor(transfer: any, uploadedFile: UploadedFile) {
    this.transfer = transfer;
    this.uploadedFile = uploadedFile;
  }
}

@Component({
  selector: 'crs-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements AfterViewInit, OnDestroy {

  @ViewChild('flow', {static: false}) flow: FlowDirective;
  private _folder: number;
  @Input() set folder(value: number) {
    if (typeof value === 'string') value = parseInt(value, 10);
    this._folder = value;
    this.refreshTarget();

    if (this.flow && this.flow.flowJs) {
      this.flow.flowJs.opts.target = this.config.target;
    }
  }
  get folder() {
    return this._folder;
  }
  @Input() singleFile = true;
  private _acceptedFiles: string[];
  @Input() set acceptedFiles(value: string[]) {
    if (this._acceptedFiles !== value) {
      this._acceptedFiles = value;
      this.buildAttributes();
    }
  }
  get acceptedFiles() {
    return this._acceptedFiles;
  }

  @Input() replaceIdentifierWithFileName = true;

  @Output() filesUploaded: EventEmitter<UploadedFile[]> = new EventEmitter();
  @Output() flowFilesUploaded: EventEmitter<{
    transfer: Transfer,
    uploadedFile: UploadedFile
  }[]> = new EventEmitter();

  attributes: any = {};
  subscriptions: Subscription[] = [];

  config: any = {
    target: `${Config.baseUrls.api}/api/documents/upload`,
    singleFile: this.singleFile,
    chunkSize: 1024 * 512,
    headers: {
      authorization: 'Bearer ' + this._authService.getAccessToken()
    }
  };

  constructor(private readonly _authService: OAuthService) { }

  refreshTarget() {
    const folder = this.folder ? `&folder=${this.folder}` : '';

    this.config.target = `${Config.baseUrls.api}/api/documents/upload?${folder}`;
  }

  ngAfterViewInit(): void {

    this.subscriptions.push(this.flow.events$.subscribe(event => {
      if (event.type === 'fileAdded') {
        const uploadedFile = event.event[0];

        if (!this.replaceIdentifierWithFileName && uploadedFile['uniqueIdentifier']) {
          const fileIdentifier = uploadedFile['uniqueIdentifier'];

          uploadedFile['uniqueIdentifier'] = `${fileIdentifier.split('-')[0]}-${uploadedFile['name']}`;
        }
      }
      if (event.type === 'filesSubmitted') {
        this.flow.upload();
      }
    }));

    this.subscriptions.push(
      this.flow.transfers$.pipe(
        map(ft => ft.transfers.filter(t => t.success)),
        map(ts => {
          return ts.map(t => {
            return {
              transfer: t,
              uploadedFile: new UploadedFile(this.folder, 'flow-' + t.id, t.name)
            };
          });
        }),
        tap(ts => {
          this.filesUploaded.emit(ts.map(t => t.uploadedFile));
          this.flowFilesUploaded.emit(ts);
        })
      ).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  trackTransfer(transfer: Transfer) {
    return transfer.id;
  }

  buildAttributes() {
    if (this.acceptedFiles != null) {
      this.attributes = {
        accept: this.acceptedFiles.join(', ')
      };
    } else this.attributes = {};
  }

}
