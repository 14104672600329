import { AssetType } from './../../assets/asset-type';
import { AssetsContextService } from './../../assets-context.service';
import { AssetGroupService } from './../asset-group.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
  FormGroup,
} from '@angular/forms';
import { Subject, Subscription, Observable, EMPTY } from 'rxjs';
import { tap, exhaustMap, catchError, finalize } from 'rxjs/operators';
import {
  AssetGroup,
  AssetGroupJournalSetting,
  AssetGroupModel,
  DepreciationDefault,
} from '../asset-group';
import { ActivatedRoute, Router } from '@angular/router';
import { DepreciationType } from '../../assets/depreciation-type';

@Component({
  selector: 'crs-asset-group',
  templateUrl: './asset-group.component.html',
})
export class AssetGroupComponent implements OnInit, OnDestroy {
  static modalSize = 'lg';

  id: string;
  isAdd: boolean;
  objectTitle = 'Asset Group';
  busy = {
    loading: false,
    submit: null,
    delete: null,
  };
  error: string = null;
  subscriptions: Subscription[] = [];

  form = this.buildForm();
  get settingsForms() {
    return this.form.get('journalSettings') as UntypedFormArray;
  }

  addSource = this._formBuilder.control(null);
  get readyToAddSource() {
    const source = this.addSource.value;
    if (!source) return false;
    const array = this.form.get('journalSettings')
      .value as Array<AssetGroupJournalSetting>;
    return !array.some((s) => s.source.id === source.id);
  }

  assetTypes = AssetType;

  submitButtonStream = new Subject<void>();

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _assetGroupService: AssetGroupService,
    private readonly _assetContextService: AssetsContextService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router
  ) {}

  ngOnInit() {
    this.configureSubmit();
    this.id = this._route.snapshot.paramMap.get('groupId');
    this.isAdd = this.id === 'add';
    if (!this.isAdd) {
      this.busy.loading = true;
      this._assetGroupService
        .get(parseInt(this.id, 10))
        .pipe(finalize(() => (this.busy.loading = false)))
        .subscribe(
          (data) => (this.form = this.buildForm(data)),
          (err) => this.showError(err)
        );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  configureSubmit() {
    this.subscriptions.push(
      this.submitButtonStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.submitObservable())
        )
        .subscribe((client) => {
          this.close();
        })
    );
  }

  submit() {
    this.submitButtonStream.next();
  }

  submitObservable(): Observable<any> {
    let observable: Observable<any>;
    const formValue = this.form.value;
    formValue.journalSettings.forEach((s) => {
      s.depreciationSourceAccount = s.gridAccounts[0].sourceAccount;
      s.accumulatedSourceAccount = s.gridAccounts[1].sourceAccount;
    });
    const assetGroup = new AssetGroup(formValue);
    const assetGroupModel = new AssetGroupModel(assetGroup);
    if (this.isAdd) {
      const context = this._assetContextService.currentContext;
      assetGroupModel.fileId = context.file.id;
      assetGroupModel.entityId = context.entity.id;
      observable = this._assetGroupService.post(assetGroupModel);
    } else {
      assetGroupModel.id = parseInt(this.id, 10);
      observable = this._assetGroupService.put(assetGroupModel);
    }

    const loadingStream = new Subject<void>();
    this.busy.submit = loadingStream.subscribe();

    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  addSetting() {
    if (!this.readyToAddSource) return;
    const source = this.addSource.value;
    this.settingsForms.push(
      this.initJournalSetting(
        new AssetGroupJournalSetting({
          source: source,
        })
      )
    );
    this.addSource.patchValue(null);
  }

  private buildForm(setting: AssetGroup = null) {
    const form = this._formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(1024)]],
      defaultAssetType: [null],
      taxationDefaults: [new DepreciationDefault({})],
      accountingDefaults: [new DepreciationDefault({})],
      journalSettings: this._formBuilder.array([]),
    });

    if (!setting) return form;

    form.patchValue(setting);

    const array = form.get('journalSettings') as UntypedFormArray;
    if (setting.journalSettings) {
      setting.journalSettings.forEach((s) => {
        array.push(this.initJournalSetting(s));
      });
    }

    return form;
  }

  private initJournalSetting(setting: AssetGroupJournalSetting) {
    const settingForm = this._formBuilder.group({
      source: [null, [Validators.required, Validators.required]],
      depreciationType: [DepreciationType.Taxation, Validators.required],
      depreciationSourceAccount: [],
      accumulatedSourceAccount: [],
      gridAccounts: [
        [
          {
            type: 'Depreciation Account',
            sourceAccount: setting.depreciationSourceAccount,
          },
          {
            type: 'Accumulated Depreciation Account',
            sourceAccount: setting.accumulatedSourceAccount,
          },
        ],
      ],
    });
    if (setting) settingForm.patchValue(setting);
    return settingForm;
  }

  removeSetting(index: number) {
    this.settingsForms.removeAt(index);
  }

  showError(error) {
    this.error = error;
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }
}
