import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService, PagedResponse } from '../../core';
import { AccountingFile, ApiType, SourceTenant } from '../sourcedata';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  readonly base: string = 'api/accounting/connections';

  constructor(private apiService: ApiService) { }

  getAuthorisationUrl(apiType: ApiType) {
    return this.apiService.get<string>(this.base + '/' + apiType + '/authorisationUrl', {responseType: 'text'});
  }

  getAccountingFiles(apiType: ApiType) {
    return this.apiService.get<AccountingFile[]>(this.base + '/' + apiType + '/accountingFiles')
      .pipe(map(c => c.map(c1 => new AccountingFile(c1))));
  }

  processCallback(apiType: ApiType, callBackUrl: string, fileId: string = null) {
    const content = {
      url: callBackUrl,
      fileId: fileId
    };
    return this.apiService.post<AccountingFile>(this.base + '/' + apiType + '/processCallback', content);
  }

  testConnection(apiType: ApiType, file: AccountingFile, username: string = null, password: string = null) {
    const content = {
      file: file,
      username: username,
      password: password
    };
    return this.apiService.post<boolean>(this.base + '/' + apiType + '/test', content);
  }

  setTenantId(apiType: ApiType, tenant: string) {
    return this.apiService.put<boolean>(this.base + '/' + apiType + '/tenant/' + tenant, {});
  }

  removeTenantId(apiType: ApiType) {
    return this.apiService.delete<boolean>(this.base + '/' + apiType + '/tenant');
  }

  getTenant(apiType: ApiType) {
    return this.apiService.get<SourceTenant>(this.base + '/' + apiType + '/tenant');
  }
}
