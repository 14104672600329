import { MessageService } from './../../../core/messages/message.service';
import { ActiveFileService } from './../../active-file.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { finalize, catchError } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { ConnectionService } from '../';
import { Config } from '../../../core';
import { ApiType, AccountingFile } from '../..';

export class LoginResult {
  result = false;
  file: AccountingFile;

  constructor(result: boolean, file: AccountingFile = null) {
    this.result = result;
    this.file = file;
  }
}

@Component({
  selector: 'crs-api-authorisation',
  templateUrl: './api-authorisation.component.html',
  styleUrls: ['./api-authorisation.component.scss']
})
export class ApiAuthorisationComponent implements OnInit, OnDestroy {

  private _apiType: ApiType;
  get apiType(): ApiType {
    return this._apiType;
  }
  @Input()   set apiType(type: ApiType) {
    if (this._apiType !== type) this.getUrl();
    this._apiType = type;
  }

  @Output() loginResponseReceived: EventEmitter<LoginResult> = new EventEmitter();

  url: string;
  active: boolean;
  iframeMode = false;
  processing: boolean;
  intervalSubscriptions: Subscription[] = [];

  constructor(private connectionService: ConnectionService,
    private activeFileService: ActiveFileService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.getUrl();
  }

  ngOnDestroy() {
    this.unsubscribeToAllWindows();
  }

  showError(err) {
    this.messageService.error(err);
  }

  getUrl() {
    if (!this.apiType) this.url = null;
    else {
      this.active = true;
      this.connectionService.getAuthorisationUrl(this.apiType).subscribe(u => {
        if (true) {
          // new window
          this.iframeMode = false;
          this.initialiseWindow(u); // use a window instead of the iframe
        } else {
          // iframe
          this.url = u;
          this.iframeMode = true;
        }
      });
    }
  }

  // Creates a window and listens for the call-back
  private initialiseWindow(url: string) {
    const storageKey = this.getStorageKey();

    localStorage.removeItem(storageKey);
    this.unsubscribeToAllWindows();

    window.open(url, '_blank');

    const intervalSubscription = interval(1500).subscribe(() => {
      const returnUrl = localStorage.getItem(storageKey);
      if (returnUrl) {
        localStorage.removeItem(storageKey);
        this.processCallback(returnUrl);
        intervalSubscription.unsubscribe();
      }
    });

    this.intervalSubscriptions.push(intervalSubscription);
  }

  private getStorageKey() {
    if (this._apiType === ApiType.DataHub) {
      return 'callback-datahub';
    }
    
    return 'callback';
  }

  onLoad(e) {
    if (!this.iframeMode) return;
    try {
      const url = e.contentWindow.location.href;
      if (url && url.startsWith(Config.baseUrls.site)) {
        this.processCallback(url);
      }
    } catch (err) {
      // swallow, not allowed access to cross-origin frame
    }
  }

  private processCallback(callbackUrl) {
    if (!this.active) return;
    this.processing = true;
    this.connectionService.processCallback(this.apiType, callbackUrl, this.activeFileService.file.id)
      .pipe(
        finalize(() => {
        this.processing = false;
        this.active = false;
      }))
      .subscribe(response => {
        this.processLoginResult(new LoginResult(true, response));
      }, e => {
        this.showError(e);
        this.processLoginResult(new LoginResult(false));
      });
  }

  cancel() {
    this.unsubscribeToAllWindows();
    this.processLoginResult(new LoginResult(false));
  }

  private processLoginResult(response: LoginResult) {
    console.log(`Login Result for ${ApiType[this.apiType]}: `, response);
    this.active = false;
    this.url = null;
    this.loginResponseReceived.emit(response);
  }

  private unsubscribeToAllWindows() {
    this.intervalSubscriptions.forEach(s => {
      if (!s.closed) s.unsubscribe();
    });
    this.intervalSubscriptions = [];
  }

}
