export enum EntityTypeEnum {
  Company = 1,
  UnitTrust = 2,
  DiscretionaryTrust = 3,
  Partnership = 4,
  SoleTrader = 5,
  SelfManagedSuperannuationFund = 6,
  Association = 7,
  Unspecified = 8,
}

export class EntityType {
  id: number;
  name: string;

  constructor(id: number = null, name: string = null) {
    this.id = id;
    this.name = name;
  }
}

export const entityTypes = [
  new EntityType(EntityTypeEnum.Company, 'Company'),
  new EntityType(EntityTypeEnum.UnitTrust, 'Unit Trust'),
  new EntityType(EntityTypeEnum.DiscretionaryTrust, 'Discretionary Trust'),
  new EntityType(EntityTypeEnum.Partnership, 'Partnership'),
  new EntityType(EntityTypeEnum.SoleTrader, 'Sole Trader'),
  new EntityType(EntityTypeEnum.SelfManagedSuperannuationFund, 'Self Managed Superannuation Fund'),
  new EntityType(EntityTypeEnum.Association, 'Association'),
  new EntityType(EntityTypeEnum.Unspecified, 'Unspecified'),
];

export const entityTypesWithoutUnspecified = [
  new EntityType(EntityTypeEnum.Company, 'Company'),
  new EntityType(EntityTypeEnum.UnitTrust, 'Unit Trust'),
  new EntityType(EntityTypeEnum.DiscretionaryTrust, 'Discretionary Trust'),
  new EntityType(EntityTypeEnum.Partnership, 'Partnership'),
  new EntityType(EntityTypeEnum.SoleTrader, 'Sole Trader'),
  new EntityType(EntityTypeEnum.SelfManagedSuperannuationFund, 'Self Managed Superannuation Fund'),
  new EntityType(EntityTypeEnum.Association, 'Association'),
];
