<div class="modal-header">
  <h4 class="modal-title">
    Formula Editor
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form" (validSubmit)="submit()" novalidate>
  <div class="modal-body" [ngBusy]="[busy.load]">

    <div class="row">
      <div class="col-md-8">

        <div class="form-group required">
          <label class="control-label">Formula</label>
          <div>
            <div class="form-control editable-content h-100" (click)="onFormulaChange()" (keyup)="onFormulaChange()"
              (keydown)="onFormulaChange()">
              <mat-chip-list #matChipList contenteditable="true" placeholder="Enter formula here..." class="w-100"
                [innerHTML]="form.controls['safeFormulaHtml'].value">
              </mat-chip-list>
            </div>
          </div>
      </div>
    </div>
    <div class="col-md-4">

        <div class="card bg-light">
          <div class="card-header">
            {{ editingIndex === null ? 'Insert': 'Edit' }} Component
          </div>
          <div class="card-body">

            <div class="form-group">
              <ng-select [items]="placeholderTypes" formControlName="selectedPlaceholderType" placeholder="Select type of element to insert" [searchable]="false" [clearable]="false"></ng-select>
            </div>

            <div *ngIf="form.controls['selectedPlaceholderType'].value">

              <div class="form-group" [ngSwitch]="form.controls['selectedPlaceholderType'].value">
                <div *ngSwitchCase="'Standard Formula'">
                  <ng-select [items]="standardFormulaPlaceholders" formControlName="placeholderValue" placeholder="Select a preset formula" [searchable]="false" [clearable]="false">
                  </ng-select>
                </div>

                <div *ngSwitchCase="'MasterAccountId'">
                  <crs-master-account-select formControlName="placeholderValue" placeholder="Select Master Account" [clearable]="false">
                  </crs-master-account-select>
                </div>

                <div *ngSwitchCase="'AccountId'">
                  <crs-account-select formControlName="placeholderValue" placeholder="Select an Account" [includeChildren]="false" [clearable]="false"></crs-account-select>
                </div>

              </div>
            </div>

            <div>
              <button type="button" class="btn"
                [ngClass]="!form.controls['selectedPlaceholderType'].value || !form.controls['placeholderValue'].value ? 'btn-secondary' : 'btn-primary'"
                [disabled]="!form.controls['selectedPlaceholderType'].value || !form.controls['placeholderValue'].value"
                (click)="onAddPlaceholder()">
                {{editingIndex === null ? 'Insert': 'Save'}}
              </button>
              <button type="button" *ngIf="editingIndex !== null" class="ml-2 btn btn-secondary"
                (click)="onCancelEdit()">Cancel</button>
            </div>

          </div>

        </div>

      </div>

    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>
</form>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="submit()">Save</button>
  <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
</div>
