import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormArray } from "@angular/forms";
import { moveItemInArray } from "@angular/cdk/drag-drop";

import {
  ReportPageType,
  ReportPageTypeGroup,
  ReportPageTypeService,
  ReportPageCategory,
} from "../../../reportPages";
import { ReportMasterFormBuilder } from "../report-master-form-builder";
import { DateHelpers } from "src/app/shared/utilities/date-helpers";

@Component({
  selector: "crs-report-master-pages",
  templateUrl: "./report-master-pages.component.html",
})
export class ReportMasterPagesComponent implements OnInit {
  @Input() formArray: UntypedFormArray;
  @Input() masterGroup: UntypedFormGroup;

  pageTypes: ReportPageTypeGroup[] = [];
  filteredPageTypes: ReportPageTypeGroup[] = [];
  pageCategories = ReportPageCategory;

  constructor(
    private readonly _reportMasterFormBuilder: ReportMasterFormBuilder,
    private readonly _reportPageTypeService: ReportPageTypeService
  ) {}

  ngOnInit() {
    this._reportPageTypeService.getAllGrouped().subscribe((types) => {
      this.pageTypes = types;
      this.filterPageTypes();
    });
  }

  addPage(type: ReportPageType) {
    this.formArray.push(this._reportMasterFormBuilder.initPage(type));
    this.filterPageTypes();
  }

  removePage(i: number) {
    this.formArray.removeAt(i);
    this.filterPageTypes();
  }

  dropPage(event) {
    const oldIndex = event.previousIndex;
    const newIndex = event.currentIndex;
    if (oldIndex !== newIndex)
      moveItemInArray(this.formArray.controls, oldIndex, newIndex);
  }

  filterPageTypes() {
    this.filteredPageTypes = this.pageTypes
      .map((g) => {
        return new ReportPageTypeGroup(
          g.key,
          g.items.filter(
            (t) =>
              t.canHaveMultiple ||
              this.formArray.controls.every((f) => f.value.pageType.id !== t.id)
          )
        );
      })
      .filter((g) => g.items.length);
  }

  shouldDisplayNewBadge(type: ReportPageType) {
    if (!type.isMaster) {
      return false;
    }
    const monthsToCheck = 3;
    return DateHelpers.isWithinLastXMonths(type.createdDate, monthsToCheck);
  }
}
