<div class="modal-header">
  <h4 class="modal-title">Select Datasets</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="content">
    <div class="row">
      <div class="col-xl-6 col-lg-7 col-md-9 col-sm-10">
        <p>Select one or more datasets from the list below:</p>

        <div class="spacer"></div>

        <div class="form-group">
          <div class="icon-input-wrapper">
            <i aria-hidden="true" class="fas fa-search input-icon"></i>
            <input
              aria-describedby="searchIcon"
              placeholder="Search..."
              type="search"
              [formControl]="search"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div style="overflow: hidden; flex-grow: 1">
          <ag-grid-angular
            #agGrid
            class="ag-theme-material"
            style="width: 100%"
            [gridOptions]="gridOptions"
            [rowData]="datasets$ | async"
          />
        </div>

        <div class="spacer"></div>

        <crs-form-error [error]="error"></crs-form-error>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="select()">
    Select
  </button>
  <button
    class="btn btn-secondary"
    type="button"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>
</div>
