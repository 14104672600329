<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<crs-form-error [error]="error"></crs-form-error>
<crs-busy *ngIf="schedulingFormBusy.loading"></crs-busy>
<crs-busy *ngIf="importingFormBusy.loading"></crs-busy>

<div class="bank-connection-name">
  <h3>{{ params?.name }}</h3>
</div>

<div class="import-and-validate-container">
  <form
    class="import-now-form"
    [formGroup]="importNowForm"
    (validSubmit)="onImportNowSubmit()"
  >
    <div
      class="import-now-container"
      *ngIf="params.state === BankConnectionStates.Active"
    >
      <ng-container *ngIf="lastRefreshLocalDate">
        <div class="form-group flex-1">
          <label class="control-label">Last Updated At: </label>
          <span>{{ lastRefreshLocalDate | date: 'dd MMM yy h:mm a' }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!lastRefreshLocalDate">
        <div class="form-group flex-1">
          <label class="control-label">No imports have been made yet.</label>
        </div>
      </ng-container>
      <div class="form-group flex-2">
        <label class="control-label">Retrieving Data Start Date</label>
        <crs-date-picker formControlName="dataStartDate"></crs-date-picker>
      </div>
      <div class="form-group flex-1 ms-2">
        <label>&nbsp;</label>
        <button
          class="btn btn-primary"
          type="submit"
          *ngIf="params.state == BankConnectionStates.Active"
          [promiseBtn]="importingFormBusy.submit"
        >
          <i class="fas fa-file-import"></i> Import Now
        </button>
      </div>
    </div>
  </form>

  <form
    class="validate-data-form"
    [formGroup]="validateDataForm"
    (validSubmit)="onValidateDataSubmit()"
  >
    <div
      class="validate-data-container"
      *ngIf="params.state === BankConnectionStates.Active"
    >
      <div class="form-group flex-1"></div>
      <div class="form-group flex-2">
        <label class="control-label">Validating Data Start Date</label>
        <crs-date-picker
          formControlName="validatingDataStartDate"
          placeholder="Fetching transactions start date..."
        ></crs-date-picker>
      </div>
      <div class="form-group flex-1 ms-2">
        <label>&nbsp;</label>
        <button
          class="btn btn-primary"
          type="submit"
          *ngIf="params.state == BankConnectionStates.Active"
          [disabled]="validatingFormBusy.loading"
          [promiseBtn]="validatingFormBusy.submit"
        >
          Validate
        </button>
      </div>
    </div>
  </form>

  <div class="d-flex flex-column align-items-center pb-1">
    <div class="progress-message" *ngIf="progressMessage">
      {{ progressMessage }}
    </div>
  </div>
  <crs-progress-bar id="progressBar"></crs-progress-bar>
</div>

<form
  [formGroup]="schedulingSettingsForm"
  (validSubmit)="onSchedulingSettingsSubmit()"
>
  <div class="modal-body">
    <ng-container
      *ngIf="
        params.state == BankConnectionStates.Active &&
        params.transactionProvider !== transactionProvider.Akahu
      "
    >
      <h3>Scheduling Settings:</h3>
      <div class="form-group">
        <div class="row">
          <div class="col-4">Import every</div>
          <div class="col-4">
            <input
              class="form-control"
              formControlName="intervalAmount"
              step="1"
              type="number"
            />
          </div>
          <div class="col-4">
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="schedulingSettingsInterval"
              [clearable]="false"
              [items]="schedulingSettingsIntervalList | enumToArray"
            ></ng-select>
          </div>
        </div>
        <div class="row">
          <div class="form-group offset-4 p-3">
            <label class="control-label">Commencement Date</label>
            <crs-date-picker
              formControlName="commencementLocalDate"
              [readonly]="!!lastRefreshLocalDate"
            ></crs-date-picker>
          </div>
        </div>
      </div>
    </ng-container>
    <div
      class="status-info p-2"
      *ngIf="params.state == BankConnectionStates.Disconnected"
    >
      <div
        class="text-left"
        *ngIf="params.transactionProvider !== transactionProvider.Basiq"
      >
        <span class="fas fa-info-circle pe-1"></span>
        <i style="font-size: 13px"
          >This connection has expired. The client needs to reconnect to the
          bank.</i
        >
      </div>
      <div
        class="text-left"
        *ngIf="params.transactionProvider === transactionProvider.Basiq"
      >
        <span class="fas fa-info-circle pe-1"></span>
        <i style="font-size: 13px"
          >This connection has expired. Create a new connection.</i
        >
      </div>
      <button
        class="btn btn-danger"
        type="button"
        *ngIf="params.transactionProvider !== transactionProvider.Basiq"
        [promiseBtn]="schedulingFormBusy.loading"
        (click)="reInviteClient(true)"
      >
        Request reconnection
      </button>
    </div>
    <div
      class="status-info p-2"
      *ngIf="params.state === BankConnectionStates.Pending"
    >
      <div class="text-left">
        <span class="fas fa-info-circle pe-1"></span>
        <i style="font-size: 13px"
          >The client has received an invitation, They must complete the
          authorisation process to set up their bank account settings and
          acquire bank feeds..</i
        >
      </div>
      <button
        class="btn btn-secondary"
        type="button"
        [disabled]="importingFormBusy.loading"
        [promiseBtn]="schedulingFormBusy.loading"
        (click)="reInviteClient(false)"
      >
        Re-Invite Client
      </button>
    </div>
  </div>

  <div
    class="modal-footer"
    *ngIf="params.state === BankConnectionStates.Active"
  >
    <button
      class="btn btn-outline-danger"
      type="button"
      (click)="disconnectBankConnection()"
    >
      Disconnect
    </button>
    <div class="submit-container">
      <button
        class="btn btn-primary submit-btn"
        type="submit"
        [promiseBtn]="schedulingFormBusy.submit"
      >
        Save Settings
      </button>
      <button class="btn btn-secondary" type="button" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</form>
