import { RuleTableComponent } from './rule-table/rule-table.component';
import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/core';
import { RuleComponent } from './rule/rule.component';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ErrorPageType } from 'src/app/core/components';
import { Subject } from 'rxjs';
import { BacoRuleDto } from 'src/app/baco/interfaces';
import { BacoFeedStore } from '../baco-feed.store';

@Component({
  selector: 'crs-rule-page',
  templateUrl: './rule-page.component.html',
  styleUrls: ['./rule-page.component.scss']
})
export class RulePageComponent implements OnInit, OnDestroy {

  search: string;

  private _destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _bacoFeedStore: BacoFeedStore,
    private _modalService: ModalService, 
    private injector: Injector) {
  }

  public addNewRule() {
    var feedId = this._bacoFeedStore.feed$.getValue()?.data?.id;
    this._modalService.openModal(RuleComponent, null, { feedId: feedId }, { windowClass: 'rule-modal', injector: this.injector })
      .then(() => {
      })
      .catch(() => true);
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this._destroy.next(true);
    this._destroy.complete();
  }
}
