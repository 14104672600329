<div class="bg-light d-flex py-2 pad-x">
  <div class="mr-auto align-self-center">
    <a [routerLink]="[]" (click)="onClickClose()">
      <i aria-hidden="true" class="fas fa-angle-double-left"></i>
      Return to Report Templates
    </a>
  </div>

  <div class="btn-group">
    <button
      class="btn btn-success btn-sm"
      type="submit"
      [promiseBtn]="busy.generate"
      (click)="onClickGenerate(generateOptions.Normal)"
    >
      Generate Report
    </button>
    <button
      aria-expanded="false"
      aria-haspopup="true"
      class="btn btn-success btn-sm dropdown-toggle dropdown-toggle-split"
      data-toggle="dropdown"
      type="button"
    >
      <span class="sr-only">Generate report options</span>
    </button>
    <div class="dropdown-menu">
      <button
        class="dropdown-item"
        type="submit"
        (click)="onClickGenerate(generateOptions.Excel)"
      >
        <i aria-hidden="true" class="fas fa-file-excel"></i> Excel
      </button>
      <button
        class="dropdown-item"
        type="submit"
        (click)="onClickGenerate(generateOptions.Pdf)"
      >
        <i aria-hidden="true" class="fas fa-file-pdf"></i> PDF
      </button>
      <button
        class="dropdown-item"
        type="submit"
        (click)="onClickGenerate(generateOptions.Word)"
      >
        <i aria-hidden="true" class="fas fa-file-word"></i> Word
      </button>
      <button
        class="dropdown-item"
        type="submit"
        *ngIf="isDocumentIntegrationPleaseSign"
        (click)="onClickOpenESignatureModal()"
      >
        <i aria-hidden="true" class="fas fa-file-pdf"></i> E-Signature
      </button>
    </div>
  </div>
</div>

<form class="pad-x" novalidate [formGroup]="form">
  <div class="spacer-md"></div>

  <crs-busy *ngIf="busy.load" />

  <div class="row">
    <div class="col-md-8 col-xl-6">
      <h2 class="form-group-title">General</h2>

      <div class="form-group required">
        <label class="control-label">Template Name</label>
        <input class="form-control" formControlName="name" type="text" />
      </div>

      <div class="form-group required">
        <label class="control-label">Report Title</label>
        <input class="form-control" formControlName="title" type="text" />
      </div>

      <div class="form-group">
        <label class="control-label">Style</label>
        <crs-report-style-select
          formControlName="reportStyle"
          placeholder="Default"
        />
      </div>

      <div *ngIf="isConsolidatedFile">
        <div class="form-group required">
          <label class="control-label">Entity</label>
          <ng-select
            bindLabel="legalName"
            formControlName="entity"
            [items]="entities"
          />
        </div>

        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="isConsolidated"
              type="checkbox"
            />
            <label class="form-check-label">
              Include Other Entities
              <small class="text-muted">(Consolidated Report)</small>
            </label>
          </div>
        </div>

        <div
          class="form-group required"
          *ngIf="form.get('isConsolidated').value"
        >
          <label class="control-label">
            Entity Type
            <small class="text-muted">(for Report Terminology)</small>
          </label>
          <ng-select
            bindLabel="name"
            bindValue="id"
            formControlName="entityType"
            [clearable]="false"
            [items]="entityTypes"
          ></ng-select>
        </div>
      </div>

      <div class="form-group">
        <label class="control-label">Default Footer</label>
        <ng-select
          bindLabel="name"
          bindValue="id"
          formControlName="defaultFooterId"
          [clearable]="false"
          [items]="data.footers"
        />
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="useDefaultDates"
            type="checkbox"
          />
          <label class="form-check-label">
            Use Report Dates from First Column
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="row pad-x" *ngIf="form.get('useDefaultDates').value === false">
    <div class="col-sm-10 col-md-6 col-lg-4">
      <div class="form-group required">
        <label class="control-label">Start Date</label>
        <crs-date-picker formControlName="startDate" [clearable]="true" />
      </div>
      <div class="form-group required">
        <label class="control-label">End Date</label>
        <crs-date-picker formControlName="endDate" [clearable]="true" />
      </div>
    </div>
  </div>

  <h2 class="form-group-title">Columns</h2>

  <crs-report-template-columns
    formArrayName="columns"
    [formArray]="form.get('columns')"
  />

  <h2 class="form-group-title">Pages</h2>

  <crs-report-template-pages
    formArrayName="pages"
    [formArray]="form.get('pages')"
    [templateGroup]="form"
  />

  <div class="spacer-md"></div>

  <crs-form-error [error]="error" />

  <div class="btn-menu">
    <div class="btn-group">
      <button
        class="btn btn-success"
        type="submit"
        [promiseBtn]="busy.generate"
        (click)="onClickGenerate(generateOptions.Normal)"
      >
        Generate Report
      </button>
      <button
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-success dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        type="button"
      >
        <span class="sr-only">Generate report options</span>
      </button>
      <div class="dropdown-menu">
        <button
          class="dropdown-item"
          type="submit"
          (click)="onClickGenerate(generateOptions.Excel)"
        >
          <i aria-hidden="true" class="fas fa-file-excel"></i> Excel
        </button>
        <button
          class="dropdown-item"
          type="submit"
          (click)="onClickGenerate(generateOptions.Pdf)"
        >
          <i aria-hidden="true" class="fas fa-file-pdf"></i> PDF
        </button>
        <button
          class="dropdown-item"
          type="submit"
          (click)="onClickGenerate(generateOptions.Word)"
        >
          <i aria-hidden="true" class="fas fa-file-word"></i> Word
        </button>
        <button
          class="dropdown-item"
          type="submit"
          *ngIf="isDocumentIntegrationPleaseSign"
          (click)="onClickOpenESignatureModal()"
        >
          <i aria-hidden="true" class="fas fa-file-pdf"></i> E-Signature
        </button>
      </div>
    </div>

    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        [promiseBtn]="busy.submit"
        (click)="onClickSave(true)"
      >
        Save and Close
      </button>
      <button
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        type="button"
      >
        <span class="sr-only">Save options</span>
      </button>
      <div class="dropdown-menu">
        <button
          class="dropdown-item"
          type="submit"
          (click)="onClickSave(false)"
        >
          Save Only
        </button>
        <button class="dropdown-item" type="submit" (click)="onClickSaveAs()">
          Save As...
        </button>
      </div>
    </div>

    <button class="btn btn-secondary" type="button" (click)="onClickClose()">
      Cancel
    </button>

    <button
      class="btn btn-outline-danger float-right"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      (click)="onClickDelete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
  </div>
</form>

<div class="spacer-lg"></div>
