import { ModalService } from './../../../../core/modals/modal.service';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { MessageService } from '../../../../core';
import { ConnectionService } from '../../../connections/';
import {
  Source,
  SourceService,
  SourceType,
  AccountingFile,
  AccountSystem,
  SourceModel,
  SourceTenant,
  ApiType,
} from '../../';
import { getDefaultGridOptions } from '../../../../shared';
import { ActiveFileService } from '../../../active-file.service';
import { Subscription } from 'rxjs';
import { GridReadyEvent, GridApi, GridOptions } from 'ag-grid-community';
import { LoginResult } from 'src/app/accounting/connections/api-authorisation/api-authorisation.component';

@Component({
  selector: 'crs-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss'],
})
export class SourceComponent implements OnInit, OnDestroy {
  @Input() id: string;
  isAdd: boolean;
  objectTitle = 'Source';
  busy = {
    load: null,
    loadSourceTypes: null,
    loadFiles: null,
    submit: null,
    connect: null,
    delete: null,
  };

  form = this.formBuilder.group({
    name: ['', Validators.required],
    sourceType: [null, Validators.required],
    cirrusFile: [null],
    apiFileId: [null],
    apiFileName: [null],
    apiFileUri: [null],
    apiTenantId: [null],
    apiUsername: [null],
    apiPassword: [null],
    accountSystem: [AccountSystem.None, Validators.required],
  });

  sourceTypes: SourceType[] = [];
  accountSystems = AccountSystem;
  files: AccountingFile[] = [];
  selectedFile: AccountingFile;
  error: string = null;
  connectError: string = null;

  showPassword = false;
  apiTenantIdSet = false;

  cirrusFileSubscription: Subscription;

  gridOptions: GridOptions;
  gridApi: GridApi;
  search = new UntypedFormControl();

  isSet = false;
  private _pageSize = 10;

  private afterLoginAction: (file: AccountingFile) => void;
  private afterFailedLoginAction: () => void;
  private searchSubscription: Subscription;
  loginInProgress = false;
  loginRequired = false;
  resetLogin: any[] = [{}];

  sourceTypesCollapsed = false;
  fileListCollapsed = true;
  tenantSelectCollapsed = true;
  accountingFilesCollapsed = true;
  connectSectionCollapsed = true;
  fileNameCollapsed = true;
  apiType = ApiType;

  @ViewChild('#collapseSourceTypesHeader')
  collapseSourceTypesHeader: ElementRef<HTMLElement>;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private sourceService: SourceService,
    private connectionService: ConnectionService,
    private messageService: MessageService,
    private activeFileService: ActiveFileService,
    private modalService: ModalService
  ) {}

  submit() {
    this.error = null;
    const source = new SourceModel(this.form.value);
    source.sourceTypeId = this.form.value.sourceType.id;
    if (this.isAdd) {
      source.fileId = this.activeFileService.file.id;
      this.busy.submit = this.sourceService.post$(source).subscribe(
        (id) => {
          const newSource = <Source>this.form.value;
          newSource.id = id;
          this.sourceService.notifySourcesChange();
          this.activeModal.close(newSource);
        },
        (err) => this.showError(err)
      );
    } else {
      source.id = this.id;
      this.busy.submit = this.sourceService.put$(source).subscribe(
        () => this.activeModal.close(source),
        (err) => this.showError(err)
      );
    }
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  ngOnInit() {
    this.isAdd = this.id === 'add';
    this.getSourceTypes();
    this.getSource();
    this.configureGridOptions();

    this.searchSubscription = this.search.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((search) => {
        this.gridApi.setGridOption('quickFilterText', search);
      });
  }

  subscribeToCirrusfileChanges() {
    if (this.cirrusFileSubscription) this.cirrusFileSubscription.unsubscribe();
    this.cirrusFileSubscription = this.form.controls.cirrusFile.valueChanges
      .pipe(
        tap((file) => {
          this.selectedFile = !file
            ? null
            : new AccountingFile({
                fileId: file.id,
                fileName: file.name,
              });
          this.assignSelectedFile();
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.cirrusFileSubscription) this.cirrusFileSubscription.unsubscribe();
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
  }

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      pagination: true,
      paginationPageSize: this._pageSize,
      rowSelection: {
        mode: 'singleRow',
      },
      columnDefs: [{ field: 'fileName', headerName: 'Name' }],
    };
  }

  getSource() {
    if (!this.isAdd) {
      this.busy.load = this.sourceService.get$(this.id).subscribe(
        (data) => {
          this.selectedFile = new AccountingFile({
            fileId: data.apiFileId,
            fileName: data.apiFileName,
            uri: data.apiFileUri,
          });
          this.form.patchValue(data);
          this.subscribeToCirrusfileChanges();
          this.getAccountingFiles(data.sourceType);
        },
        (err) => this.showError(err)
      );
    }
    this.subscribeToCirrusfileChanges();
  }

  selectSourceType(type: SourceType) {
    this.form.patchValue({ sourceType: type });
    this.getAccountingFiles(type);

    if (type.isReportance) this.fileListCollapsed = false;
    else if (type.id == 1) this.fileNameCollapsed = false;
    else this.tenantSelectCollapsed = false;

    this.sourceTypesCollapsed = true;
    let typeName = null;
    if (type.apiType == ApiType.ChartOfAccounts) {
      this.fileNameCollapsed = false;
      typeName = type.name;
    }
    this.form.patchValue({ name: typeName });
  }

  selectFile(event) {
    this.selectedFile = <AccountingFile>event.data;

    this.accountingFilesCollapsed = true;
    this.connectSectionCollapsed = false;
  }

  getAccountingFiles(type: SourceType) {
    this.files = null;
    this.loginRequired = false;
    if (type.isReportance || !type.apiTypeDetail.userLevelApi) return;

    if (type.apiTypeDetail.requiresTenantId && !this.apiTenantIdSet) {
      this.attemptLogin(() => this.getTenantId(type));
    } else {
      this.tenantSelectCollapsed = true;
      this.accountingFilesCollapsed = false;

      this.busy.loadFiles = this.connectionService
        .getAccountingFiles(type.apiType)
        .subscribe(
          (r) => {
            this.files = r;
            this.selectedFile = r.find(
              (f) => f.fileId === this.form.get('apiFileId').value
            );
          },
          (e) => {
            this.messageService.error(
              'ZAP Data Hub cannot be connected, please attempt a model process within ZAP Data Hub. If this is unsuccessful please ask your ZAP Data Hub Administrator to raise a support ticket with ZAP.'
            );
          }
        );
    }
  }

  private getTenantId(type: SourceType) {
    this.connectionService.getTenant(type.apiType).subscribe((r) => {
      this.form.patchValue({ apiTenantId: r.tenantId });
      this.apiTenantIdSet = !r.canChange;

      if (this.apiTenantIdSet) this.getAccountingFiles(type);
    });
  }

  getSourceTypes() {
    if (!this.isAdd) return;
    this.busy.loadSourceTypes = this.sourceService
      .getTypesForFile$(this.activeFileService.file.id)
      .subscribe({
        next: (types) => {
          this.sourceTypes = types;
        },
        error: (err) => this.showError(err),
      });
  }

  addFile() {
    const type: SourceType = <SourceType>this.form.value.sourceType;
    this.loginAndThen(
      () => {
        const previousFilesIds = this.files.map((c) => c.fileId);
        this.connectionService
          .getAccountingFiles(type.apiType)
          .subscribe((r) => {
            this.files = r;
            const addedFile = r.filter(
              (c) => !previousFilesIds.includes(c.fileId)
            )[0];
            if (addedFile) {
              this.selectFile({ data: addedFile });
            }
          });
      },
      () => false
    );
  }

  // Connect Methods
  connect() {
    this.connectError = null;
    const type: SourceType = <SourceType>this.form.value.sourceType;
    if (type.apiTypeDetail.userLevelApi) {
      this.busy.connect = this.connectionService
        .testConnection(type.apiType, this.selectedFile)
        .subscribe(
          () => this.assignSelectedFile(),
          (e) => this.reportConnectError(e)
        );
    } else {
      this.loginAndThen(
        (r) => {
          this.selectedFile = r;
          this.assignSelectedFile();
        },
        () => false
      );
    }
  }

  setTenantId(tenant: string) {
    const type: SourceType = <SourceType>this.form.value.sourceType;

    this.connectionService.setTenantId(type.apiType, tenant).subscribe(
      (r) => {
        this.apiTenantIdSet = r;
        if (r) this.getAccountingFiles(type);
      },
      (e) => {
        this.messageService.error(e);
      }
    );
  }

  removeTenantId() {
    const type: SourceType = <SourceType>this.form.value.sourceType;

    this.connectionService.removeTenantId(type.apiType).subscribe(
      (r) => {
        this.apiTenantIdSet = false;
        this.selectedFile = null;
        if (r) this.getTenantId(type);
      },
      (e) => {
        this.messageService.error(e);
      }
    );
  }

  reportConnectError(error: string) {
    this.connectError = error;
    this.form.patchValue({
      apiFileId: null,
      apiFileName: null,
      apiFileUri: null,
    });
  }

  assignSelectedFile() {
    if (!this.selectedFile) return;
    if (
      !this.form.value.name ||
      this.form.value.apiFileId !== this.selectedFile.fileId
    ) {
      this.form.patchValue({ name: this.selectedFile.fileName });
    }
    this.form.patchValue({
      apiFileId: this.selectedFile.fileId,
      apiFileName: this.selectedFile.fileName,
      apiFileUri: this.selectedFile.uri,
    });

    this.connectSectionCollapsed = true;
    this.fileNameCollapsed = false;
  }

  delete() {
    this.modalService.confirmation(
      'This action cannot be undone. Are you sure you want to delete this source?',
      () =>
        (this.busy.delete = this.sourceService.delete$(this.id).subscribe(
          (s) => {
            this.sourceService.notifySourcesChange();
            this.close();
          },
          (e) => this.showError(e)
        )),
      true
    );
  }

  // Grid Methods

  onGridReady(param: GridReadyEvent) {
    this.gridApi = param.api;
    this.updateSelection();
  }

  updateSelection() {
    if (!this.gridApi) return;
    if (this.selectedFile) {
      const node = this.gridApi.getRowNode(this.selectedFile.fileId.toString());
      if (node) node.setSelected(true);
    }
  }

  getRowNodeId(node: AccountingFile) {
    return node.fileId;
  }

  // Login Methods
  private loginAndThen(
    action: (file: AccountingFile) => void,
    failedAction?: () => void
  ) {
    this.afterLoginAction = action;
    this.afterFailedLoginAction = failedAction
      ? failedAction
      : () => {
          this.loginRequired = true;
          this.messageService.error('Successful login required.');
        };
    this.loginInProgress = true;
  }

  attemptLogin(afterAction: () => void) {
    this.loginAndThen(afterAction);
    this.resetLogin[0] = {};
  }

  attemptLoginAndLoadFiles() {
    this.loginAndThen(() =>
      this.getAccountingFiles(this.form.value.sourceType)
    );
    this.resetLogin[0] = {};
  }

  attemptLoginAndRemoveTenant() {
    this.loginAndThen(() => this.removeTenantId());
    this.resetLogin[0] = {};
  }

  /*
    Triggered when an api login returns a result (emitted from the api-authorisation component)
  */
  onLoginResponse(value: LoginResult) {
    this.loginRequired = !value.result;
    this.loginInProgress = false;
    if (value.result) this.afterLoginAction(value.file);
    else this.afterFailedLoginAction();
  }

  close() {
    this.activeModal.dismiss();
  }
}
