import { Component, Input } from '@angular/core';
import { ManagePoolBalancesGroup } from '../manage-pool-balances';
import { DepreciationPool } from '../../assets/depreciation-pool';
import { DepreciationType, DepreciationTypeOption } from '../../assets/depreciation-type';
import { AssetPoolingService } from '../asset-pooling.service';
import { AssetsContext } from '../../assets-context';

@Component({
  selector: 'crs-manage-pool-actions',
  templateUrl: './manage-pool-actions.component.html'
})
export class ManagePoolActionsComponent {

  @Input() balances: ManagePoolBalancesGroup;
  @Input() assetsContext: AssetsContext;

  public restoreBtn = 'Restore the pool balance';

  public get taxationWriteOffBtn(): string {
    return this._getWriteOffBtn(this.balances.taxation.initialBalance);
  }

  public get accountingWriteOffBtn(): string {
    return this._getWriteOffBtn(this.balances.accounting.initialBalance);
  }

  constructor(private readonly _assetPoolingService: AssetPoolingService) { }

  writeOff(pool: DepreciationPool, type: DepreciationType, locked: boolean = false) {
    let typeOption = <any>type as DepreciationTypeOption;
    if (locked) typeOption = DepreciationTypeOption.All;
    const action = this._assetPoolingService.writeOffBalances(this.assetsContext, pool, typeOption, false);
    this._assetPoolingService.balanceAction$.next(action);
  }

  reset(pool: DepreciationPool, type: DepreciationType, locked: boolean) {
    let typeOption = <any>type as DepreciationTypeOption;
    if (locked) typeOption = DepreciationTypeOption.All;
    const action = this._assetPoolingService.writeOffBalances(this.assetsContext, pool, typeOption, true);
    this._assetPoolingService.balanceAction$.next(action);
  }

  private _getWriteOffBtn(initialBalance: number): string {
    return `${initialBalance > 0 ? 'Write off' : ''}${initialBalance < 0 ? 'Assess' : ''} the balance of the pool`;
  }

}
