import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/core';
import { BacoRuleDto, BacoRuleCreateModel } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class BacoRuleClient {

  readonly base: string = 'api/baco/rules';

  constructor(private apiService: ApiService) { }

  list(feedId: string) {
    return this.apiService.get<BacoRuleDto[]>(`api/baco/feed-collection/${feedId}/rules`);
  }

  get(id: string) {
    return this.apiService.get<BacoRuleDto>(`${this.base}/${id}`);
  }

  update(id: string, model: BacoRuleCreateModel) {
    return this.apiService.put<BacoRuleDto>(`${this.base}/${id}`, model);
  }

  reorder(id: string, sortOrder: number) {
    return this.apiService.put(`${this.base}/${id}/reorder`, sortOrder);
  }

  archive(id: string) {
    return this.apiService.delete<void>(`${this.base}/${id}`);
  }

}
