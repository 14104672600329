<div class="row justify-content-center">
  <div class="panel cl-sm-12 col-md-6 col-lg-4">
    <div class="panel-heading">
      <div class="d-flex">
        <span class="primary">Register</span>
      </div>
    </div>

    <div class="panel-body">
      <form *ngIf="registerOnProcess" [formGroup]="form">
        <div class="form-group required">
          <label class="control-label">Organisation Name</label>
          <input
            class="form-control"
            formControlName="name"
            placeholder="Organisation name"
            type="text"
          />
        </div>

        <div class="form-group required">
          <label class="control-label">Tenant Id</label>
          <input
            class="form-control"
            formControlName="tenantId"
            placeholder="Tenant Id"
            type="text"
          />
        </div>

        <div class="form-group required">
          <label class="control-label">Licenses</label>
          <input
            class="form-control"
            formControlName="licenseCount"
            placeholder="Licenses"
            type="number"
          />
        </div>

        <br />

        <h2 class="form-group-title">User</h2>

        <div formGroupName="user">
          <div class="form-group required">
            <label class="control-label">First Name</label>
            <input
              class="form-control"
              formControlName="firstName"
              placeholder="First name"
              type="text"
            />
          </div>

          <div class="form-group required">
            <label class="control-label">Last Name</label>
            <input
              class="form-control"
              formControlName="lastName"
              placeholder="Last name"
              type="text"
            />
          </div>

          <div class="form-group required">
            <label class="control-label">Email</label>
            <input
              class="form-control"
              formControlName="email"
              placeholder="Email"
              type="text"
            />
          </div>
        </div>

        <crs-form-error [error]="error"></crs-form-error>
      </form>

      <div *ngIf="!registerOnProcess">
        We send you further instruction. Please check your email.
      </div>
    </div>

    <div class="panel-footer">
      <div class="btn-group" *ngIf="registerOnProcess">
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="form.invalid"
          (click)="register()"
        >
          Sign up
        </button>
      </div>
      <div
        class="btn-group"
        *ngIf="registerOnProcess && tenantExists && userExists"
      >
        <button
          class="btn btn-secondary"
          type="button"
          *ngIf="invitePending"
          (click)="resendInvite()"
        >
          Resend Invite
        </button>
        <button
          class="btn btn-secondary"
          type="button"
          *ngIf="!invitePending"
          (click)="makeOwner()"
        >
          Promote to Owner
        </button>
      </div>
      <div
        class="btn-group"
        *ngIf="
          registerOnProcess &&
          tenantExists &&
          !userExists &&
          form.get('user').valid
        "
      >
        <button class="btn btn-secondary" type="button" (click)="makeOwner()">
          Create new Owner
        </button>
      </div>
      <div class="btn-group" *ngIf="!registerOnProcess">
        <button class="btn btn-primary" (click)="startNew()">
          Register another organisation
        </button>
      </div>
    </div>
  </div>
</div>
