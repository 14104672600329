import { CurrentClassification } from './../classifications/classification';
import { Classification } from '../classifications/classification';

export class MasterAccount {
  id: number;
  parentId: number;
  classification: Classification;
  currentClassification: CurrentClassification;
  accountNo: string;
  accountName: string;
  hierarchy: number[] = [];

  get displayName(): string {
    if (this.accountNo) return this.accountNo + ' ' + this.accountName;
    else return this.accountName;
  }

  constructor(data) {
    Object.assign(this, data);
    this.id = parseInt(data.id, 10);
  }
}


