import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Observable, Subject, of, EMPTY } from 'rxjs';
import { tap, exhaustMap, finalize, catchError, map } from 'rxjs/operators';

import {
  Policy,
  PolicyService,
  PolicyLocation,
  PolicyLevel,
  PolicyVariant,
} from '../';
import {
  MessageService,
  ModalService,
  SessionService,
} from '../../../../../core';
import { PolicyAutoSelectionMethod } from '../policy';

enum Action {
  Submit,
  InheritFromThis,
}

@Component({
  selector: 'crs-policy',
  templateUrl: './policy.component.html',
})
export class PolicyComponent implements OnInit {
  id;
  level: PolicyLevel;

  // Add parameters only
  fileId: string;
  reportTemplateId: string;

  readonly: boolean;

  isAdd: boolean;
  busy = {
    load: null,
    submit: null,
    inherit: null,
  };
  error: string = null;

  isAdmin = false;
  isMaster: boolean;

  locations = PolicyLocation;
  policyAutoSelectionMethods = PolicyAutoSelectionMethod;
  levels = PolicyLevel;

  interactionStream = new Subject<Action>();

  policy: Policy;
  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(128)]],
    location: [PolicyLocation['Note 1'], [Validators.required]],
    description: [null, [Validators.maxLength(1024)]],
    autoSelectionMethod: [
      PolicyAutoSelectionMethod.IfRelatedAccountTypeAppears,
    ],
    accountTypes: [],
  });
  search = new UntypedFormControl();

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _policyService: PolicyService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    sessionService: SessionService
  ) {
    this.isAdmin = sessionService.permissions.isAdmin;
  }

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.id = this._route.snapshot.paramMap.get('id');
      this.isAdd = this.id === 'add';
      this.level = parseInt(
        this._route.snapshot.paramMap.get('level'),
        10
      ) as PolicyLevel;
      this.fileId = this._route.snapshot.paramMap.get('fileId');

      if (this.isAdd) {
        this.reportTemplateId =
          this._route.snapshot.paramMap.get('reportTemplateId');
      }

      this.loadPolicy();
    });

    this.configureInteraction();
  }

  updateReadonly() {
    this.readonly =
      !this.isAdd && this.policy && this.policy.level < this.level;
  }

  submit() {
    this.interactionStream.next(Action.Submit);
  }

  inheritFromThis() {
    this.interactionStream.next(Action.InheritFromThis);
  }

  private configureInteraction() {
    this.interactionStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap((action) => this.handleInteraction(action))
      )
      .subscribe((x) => {
        if (x.action === Action.InheritFromThis || this.isAdd) {
          this._router.navigate(
            ['../' + x.result, { level: this.level, fileId: this.fileId }],
            { relativeTo: this._route }
          );
        } else this.close();
      });
  }

  private handleInteraction(action: Action) {
    let observable: Observable<any>;
    if (action === Action.Submit) observable = this.submitObservable();
    else if (action === Action.InheritFromThis)
      observable = this.inheritFromThisObservable();
    else observable = EMPTY;

    return observable.pipe(
      map((result) => {
        return {
          action: action,
          result: result,
        };
      })
    );
  }

  private submitObservable() {
    let observable: Observable<any>;
    const loadingStream = new Subject<void>();

    const model = this.form.value as Policy;

    if (this.isAdd) {
      model.fileId = this.fileId;
      model.reportTemplateId = this.reportTemplateId;
      observable = this._policyService.post(model);
    } else {
      model.id = this.id as string;
      observable = this._policyService.put(model);
    }

    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );

    return observable;
  }

  private inheritFromThisObservable() {
    const loadingStream = new Subject<void>();
    this.busy.inherit = loadingStream.subscribe();

    return this._policyService
      .createInherited(this.policy, this.fileId, this.reportTemplateId)
      .pipe(
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        }),
        finalize(() => loadingStream.complete())
      );
  }

  private loadPolicy() {
    const observable = this.isAdd
      ? of(
          new Policy({
            level: this.level,
            fileId: this.fileId,
            reportTemplateId: this.reportTemplateId,
          })
        )
      : this._policyService.get(this.id);

    this.busy.load = observable.subscribe(
      (policy) => {
        this.policy = policy;
        this.form.patchValue(policy);
        this.isMaster = policy.isMaster;
        this.updateReadonly();
      },
      (err) => this.showError(err)
    );
  }

  addPolicyVariant() {
    this.policy.policyVariants.push(
      new PolicyVariant({
        id: 'add',
        policyId: this.policy.id,
      })
    );
  }

  delete() {
    if (this.isAdd || !this.isAdmin) return;
    this._modalService.confirmation(
      'Are you sure you want to delete this Policy? This action cannot be undone.',
      () =>
        this._policyService.delete(this.id).subscribe(() => {
          this._messageService.success('Successfully deleted policy.');
          this.close();
        }, this.showError),
      true
    );
  }

  close() {
    this._router.navigate(['../../'], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
    this._messageService.error(error);
  }
}
