import { UploadedFile } from 'src/app/shared/components/file-upload/file-upload.component';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { AssetsContext } from '../assets-context';
import { AssetImportResult } from './asset-import-result';

@Injectable({
  providedIn: 'root'
})
export class AssetsImportService {

  readonly base: string = 'api/accounting/assets/import';

  constructor(private apiService: ApiService) { }

  prepareImport(context: AssetsContext, year: number, copyTaxationToAccounting: boolean, uploadedFile: UploadedFile) {
    const url = this.base + '/prepare';
    const content = {
      fileId: context.file.id,
      entityId: context.entity.id,
      year: year,
      copyTaxationToAccounting: copyTaxationToAccounting,
      uploadedFile: uploadedFile
    };
    return this.apiService.post<AssetImportResult>(url, content);
  }

  import(context: AssetsContext, year: number, copyTaxationToAccounting: boolean, processorFilePath: string) {
    const url = this.base;
    const content = {
      fileId: context.file.id,
      entityId: context.entity.id,
      year: year,
      copyTaxationToAccounting: copyTaxationToAccounting,
      processorFilePath: processorFilePath
    };
    return this.apiService.post<AssetImportResult>(url, content);
  }
}
