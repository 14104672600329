<div *ngIf="!isEditing"
     class="financial-cell"
     (focus)="onFocus($event)"
     (click)="onSelectedCell()" tabindex="0">
  <div class="financial-cell-content" [ngClass]="{'custom-table-note-cell-header-content': isSectionTableNoteHeader}">
    <ng-container [ngSwitch]="cell.valueType">
      <ng-container *ngSwitchCase="columnValueTypes.Text">{{ cell.cellValue }}
      </ng-container>
      <ng-container *ngSwitchCase="columnValueTypes.Number">
        <ng-container [ngSwitch]="cellNumberFormat">

          <ng-container *ngSwitchCase="numberFormats.Normal">
            {{ cell.cellValue | formatNumber:rounding:'-' }}
          </ng-container>

          <ng-container *ngSwitchCase="numberFormats.Percent">
            {{cell.cellValue | formatPercent:rounding:'-' }}
          </ng-container>

          <ng-container *ngSwitchCase="numberFormats.FavourableUnfavourable">
            {{ cell.cellValue | formatNumber:rounding:'-' }}
            <span *ngIf="cell.cellValue > 0">F</span><span *ngIf="cell.cellValue < 0">U</span>
          </ng-container>

          <ng-container *ngSwitchCase="numberFormats.FavourableUnfavourablePercent">
            {{ cell.cellValue | formatPercent:rounding:'-' }}
            <span *ngIf="cell.cellValue > 0">F</span><span *ngIf="cell.cellValue < 0">U</span>
          </ng-container>

        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="columnValueTypes.Date">{{ cell.cellValue | date:'d/MM/yyyy' }}
      </ng-container>
    </ng-container>
  </div>
</div>

<textarea *ngIf="isEditing && !readonly" #inputarea
          class="form-control cell-input autosize"
          rows="1"
          (input)="autosize($event.target)"
          (blur)="adjustValue()"
          (keydown)="onKeyDown($event)"
          (beforeinput)="onBeforeinput($event)"
          (focus)="onFocus($event)"
          [class.financial-cell-padding]="cellIndex === 0"
          [formControl]="cellControl"
          [class.align-center]="cell.alignment == alignments.Center"
          [class.align-right]="cell.alignment ==  alignments.Right"
          [class.align-justify]="cell.alignment ==  alignments.Justify"
          [class.text-underline]="cell.textUnderline"
          [class.text-bold]="isAlternative ? false : cell.textBold"
          [class.text-italic]="isAlternative"></textarea>

<textarea *ngIf="isEditing && readonly"
      class="form-control cell-control cell-input autosize"
      [readonly]="readonly"
      rows="1"
      [class.financial-cell-padding]="cellIndex === 0"
      [value]="cell.cellValue"
      [class.align-right]="true"
      [class.text-bold]="true"></textarea>
