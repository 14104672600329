import { UploadedFile } from 'src/app/shared/components/file-upload/file-upload.component';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { PrepareDesktopMigrationResult } from './prepare-desktop-migration';
import { DesktopMigrationModel } from './desktop-migration';

@Injectable({
  providedIn: 'root'
})
export class DesktopMigrationService {

  readonly base: string = 'api/accounting/desktop-migrate';

  constructor(private apiService: ApiService) { }

  prepareImport(clientId: string, uploadedFile: UploadedFile) {
    const url = this.base + '/prepare';
    const content = {
      clientId: clientId,
      uploadedFile: uploadedFile
    };
    return this.apiService.post<PrepareDesktopMigrationResult>(url, content);
  }

  import(model: DesktopMigrationModel) {
    const url = this.base + '/import';
    return this.apiService.post<PrepareDesktopMigrationResult>(url, model);
  }

}
