import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: '[instructional-toast-component]',
  styles: [`
    :host {
      background-color: #FFFFFF;
      background-size: 16px !important;
      background-position: 10px center !important;
      position: absolute;
      overflow: hidden;
      margin: 80px 0 22px;
      width: 320px !important;
      height: 116px;
      border: 1px solid #C7384F;
      padding: 20px 1.25rem 0.75rem 52px;
      box-sizing: border-box;
      box-shadow: 1px 4px 20px rgba(0, 0, 0, 0.2) !important;
      color: black;
      pointer-events: all;
      cursor: pointer;
      font-family: Open Sans;
      font-size: 13px !important;
      line-height: 16px;
    }
    :host(.toast-error) {
      background-image: url(/assets/images/icon-exclamation-circle.svg) !important;

    }
    .title{
      position: absolute;
      width: 224px;
      height: 16px;
      left: 48px;
      top: 20px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
    }
    .toast-close-button{
      position: absolute !important;
      right: 11px !important;
      top: 20px !important;
    }
    .message{
      position: absolute;
      width: 252px;
      height: 54px;
      left: 48px;
      top: 42px;

      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      /* or 150% */
      color: rgba(0, 0, 0, 0.9);
    }
    .btn-pink {
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0);
    }
  `],
  template: `
  <div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="title">
      <div *ngIf="title" [attr.aria-label]="title">
        {{ title }}
      </div>
    </div>
    <div class="message">
      <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
          [class]="options.messageClass" [attr.aria-label]="message">
          {{ message }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
          [class]="options.messageClass" [innerHTML]="message">
      </div>
    </div>
    <div class="text-right">
      <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class InstructionalToast extends Toast {
  // used for demo purposes
  undoString = 'undo';

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }
}
