import { GridApi, GridOptions } from 'ag-grid-community';
import { DisclosureLinkModalComponent } from './../disclosure-link-modal/disclosure-link-modal.component';
import { LayoutOption } from 'src/app/accounting/reports/layout';
import { getDefaultGridOptions } from 'src/app/shared';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, EMPTY, Subscription } from 'rxjs';
import { tap, exhaustMap, finalize, catchError, map } from 'rxjs/operators';

import {
  DisclosureLevel,
  DisclosureTemplate,
  DisclosureVariant,
  DisclosureVariantModel,
} from '../';
import { DisclosureVariantService } from '../disclosure-variant.service';
import { MessageService, ModalService } from '../../../../../core';
import { entityTypesWithoutUnspecified } from './../../../../../firm/entities/entityType';
import { DisclosureLinkOption } from '../disclosure-link';
import { ActiveFileService } from 'src/app/accounting';

enum Mode {
  Readonly = 0,
  Add = 1,
  AddInherit = 2,
  Edit = 3,
}

@Component({
  selector: 'crs-disclosure-variant',
  templateUrl: './disclosure-variant.component.html',
})
export class DisclosureVariantComponent implements OnInit, OnDestroy {
  @ViewChild('optionsCell', { static: true })
  optionsCell!: ElementRef;

  @Output() removed = new EventEmitter<number>();

  private _variant: DisclosureVariant;

  @Input() index: number;
  @Input() disclosureTemplate: DisclosureTemplate;

  @Input() set variant(value: DisclosureVariant) {
    if (!this._variant || this._variant.id !== value.id) {
      this._variant = value;
      this.isAdd = value.id === 'add';
      this.loadedFull = this.isAdd;
      this.startOpen = this.isAdd;
      this.collapsed = !this.isAdd;
      this.updateMode();
    }
  }

  get variant(): DisclosureVariant {
    return this._variant;
  }

  private _level: DisclosureLevel;
  @Input()
  set level(value: DisclosureLevel) {
    this._level = value;
    this.updateMode();
  }

  get level(): DisclosureLevel {
    return this._level;
  }

  busy = {
    load: null,
    submit: null,
    delete: null,
  };

  isAdd: boolean;
  loadedFull: boolean;
  collapsed = true;
  startOpen = false;
  mode: Mode;
  modes = Mode;
  allowedToCustomise = true;
  entityTypes = entityTypesWithoutUnspecified;
  hideMasterContent: boolean;

  levels = DisclosureLevel;
  disclosureLinkOptions = DisclosureLinkOption;
  layoutOptions = LayoutOption;

  gridOptions: GridOptions;
  gridApi: GridApi;

  saveStream = new Subject<void>();

  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(128)]],
    description: [null, [Validators.maxLength(1024)]],
    applicationDate: [null],
    earliestApplicationDate: [null],
    expiryDate: [null],
    requiresCustomisation: [false],
    reportingSuites: [],
    entityTypes: [],
    disclosureLinks: [[]],
  });

  get disclosureLinks() {
    return this.form.get('disclosureLinks').value as any[];
  }

  subscriptions: Subscription[] = [];

  /**
   * Used by ag-grid to get default value for placeholder identifier in the linked accounts table
   */
  getPlaceholderIdentifier(r) {
    return r.data && r.data.placeholderIdentifier
      ? r.data.placeholderIdentifier
      : 'Default';
  }

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _disclosureVariantService: DisclosureVariantService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    private readonly _activeFileService: ActiveFileService
  ) {}

  ngOnInit() {
    this.configureInteraction();
    this.updateMode();
    this.configureGridOptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  private configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      onGridReady: (event) => (this.gridApi = event.api),
      columnDefs: [
        {
          field: 'headerMatch.display',
          headerName: 'Account',
          flex: 2,
        },
        {
          field: 'linkOption',
          headerName: 'Link Option',
          type: 'enumColumn',
          cellRendererParams: { enum: this.disclosureLinkOptions },
          width: 120,
        },
        {
          field: 'forceLinkOption',
          headerName: 'Force',
          type: 'booleanColumn',
          maxWidth: 120,
        },
        {
          field: 'layoutOption',
          headerName: 'Layout (Optional)',
          type: 'enumColumn',
          cellRendererParams: { enum: this.layoutOptions },
          width: 120,
        },
        {
          headerName: 'Named Placeholder',
          valueGetter: this.getPlaceholderIdentifier,
        },
        {
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCell },
        },
      ],
    };
  }

  loadVariant() {
    if (this.loadedFull) return EMPTY;
    this.busy.load = this._disclosureVariantService
      .get(this.variant.id)
      .subscribe(
        (variant) => {
          this.form.patchValue(variant);
          Object.assign(this.variant, variant);
          this.loadedFull = true;
          this.updateMode();
        },
        (err) => this.showError(err)
      );

    return this.busy.load;
  }

  private updateMode() {
    if (!this.disclosureTemplate || !this.variant) return;
    if (this.isAdd) {
      this.mode = Mode.Add;
    } else if (this.disclosureTemplate.level < this.level) {
      this.mode = Mode.Readonly;
    } else if (
      this.variant.level < this.level &&
      this.mode !== Mode.AddInherit
    ) {
      this.mode = Mode.Readonly;
    } else {
      this.mode = Mode.Edit;
    }

    this.allowedToCustomise = this.disclosureTemplate.level >= this.level;
  }

  showError(err) {
    this._messageService.error(err);
  }

  submit() {
    this.saveStream.next();
  }

  customise() {
    this.mode = Mode.AddInherit;
  }

  delete() {
    this._modalService.confirmation(
      'Are you sure you want to delete this Disclosure Variant? This action cannot be undone.',
      () => {
        if (this.variant.masterDisclosureVariantId) {
          this.variant.id = this.variant.masterDisclosureVariantId;
          this.reset();
        } else {
          this.busy.delete = this._disclosureVariantService
            .delete(this.variant.id)
            .pipe(tap(() => this.removed.emit(this.index)))
            .subscribe(
              () => true,
              (err) => this.showError(err)
            );
        }
      },
      true
    );
  }

  cancel() {
    if (this.mode === Mode.Add) {
      this.removed.emit(this.index);
    } else {
      this.reset();
    }
  }

  confirmMasterChangesChecked() {
    const originalValue = this.variant.masterChanged;
    this.variant.masterChanged = false;
    this._disclosureVariantService
      .confirmMasterChangesChecked(this.variant.id)
      .pipe(
        catchError((err) => {
          this.showError(err);
          this.variant.masterChanged = originalValue;
          return EMPTY;
        })
      )
      .subscribe();
  }

  private reset() {
    this.mode = null;
    this.loadedFull = false;
    return this.loadVariant();
  }

  private configureInteraction() {
    this.subscriptions.push(
      this.saveStream
        .pipe(exhaustMap((action) => this.submitObservable()))
        .subscribe((x) => {
          this._messageService.success(
            'Successfully saved the ' +
              this.form.get('name').value +
              ' Disclosure Variant'
          );
          this.reset();
        })
    );
  }

  private submitObservable() {
    let observable: Observable<any>;
    const loadingStream = new Subject<void>();

    const model = new DisclosureVariantModel(this.form.value);
    model.content = this.variant.content;

    if (this.mode === Mode.Add) {
      model.templateId = this.disclosureTemplate.id;
      model.fileId = this.disclosureTemplate.fileId;
      observable = this._disclosureVariantService
        .post(model)
        .pipe(tap((id) => (this.variant.id = id)));
    } else if (this.mode === Mode.AddInherit) {
      model.templateId = this.disclosureTemplate.id;
      model.masterDisclosureVariantId = this.variant.id;
      model.fileId = this.disclosureTemplate.fileId;
      observable = this._disclosureVariantService
        .createInherited(model)
        .pipe(tap((id) => (this.variant.id = id)));
    } else if (this.mode === Mode.Edit) {
      model.id = this.variant.id;
      observable = this._disclosureVariantService
        .put(model)
        .pipe(map(() => model.id));
    }

    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(
      tap((id) => {
        const value = this.form.value as DisclosureVariant;
        value.id = id;
        this._variant = value;
        this.isAdd = false;
        this.loadedFull = true;
        this.updateMode();
        this.form.reset();
      }),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );

    return observable;
  }

  addLinkToAccount() {
    this._modalService
      .openModal(DisclosureLinkModalComponent, null, {
        fileId:
          this.level === DisclosureLevel.File
            ? this._activeFileService.file.id
            : null,
      })
      .then((l) => {
        const array = this.disclosureLinks;
        array.push(l);
        this.updateDisclosureLinksGrid();
      })
      .catch(() => true);
  }

  editLinkToAccount(disclosureLink) {
    this._modalService
      .openModal(DisclosureLinkModalComponent, null, {
        disclosureLink: disclosureLink,
        fileId:
          this.level === DisclosureLevel.File
            ? this._activeFileService.file.id
            : null,
      })
      .then((l) => {
        const array = this.disclosureLinks;
        const index = array.indexOf(disclosureLink);
        array[index] = l;
        this.updateDisclosureLinksGrid();
      })
      .catch(() => true);
    this.updateDisclosureLinksGrid();
  }

  removeLinkToAccount(disclosureLink) {
    const array = this.disclosureLinks;
    const index = array.indexOf(disclosureLink);
    array.splice(index, 1);
    this.updateDisclosureLinksGrid();
  }

  private updateDisclosureLinksGrid() {
    this.gridApi.setGridOption('rowData', this.disclosureLinks);
  }
}
