<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-10 col-lg-8">
        <div class="form-group">
          <label class="control-label">Year</label>
          <input
            class="form-control"
            formControlName="year"
            type="number"
            [readOnly]="!isAdd || priorYear"
          />
        </div>

        <div class="row">
          <div class="col-md-8 col-lg-6">
            <div class="form-group required">
              <label class="control-label">Start Date</label>
              <crs-date-picker
                formControlName="startDate"
                [defaultDate]="defaultStartDate"
                [readonly]="!isAdd || priorYear"
              ></crs-date-picker>
            </div>

            <div class="form-group required">
              <label class="control-label">End Date</label>
              <crs-date-picker
                formControlName="endDate"
                [defaultDate]="defaultEndDate"
                [readonly]="!isAdd"
              ></crs-date-picker>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="control-label">Tax Year</label>
          <input class="form-control" formControlName="taxYear" type="number" />
        </div>

        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="useSmallBusinessDepreciation"
              type="checkbox"
            />
            <label class="form-check-label"
              >Use Small Business Depreciation</label
            >
          </div>
        </div>

        <div class="form-group">
          <div class="form-check" *ngIf="checkIfWithinFiscalYear() === true">
            <input
              class="form-check-input"
              formControlName="useMonthlyBusinessDepreciation"
              type="checkbox"
            />
            <label class="form-check-label"
              >Use Monthly Depreciation</label
            >
          </div>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="isLocked"
              type="checkbox"
            />
            <label class="form-check-label">Lock</label>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h2 class="form-group-title">Linked Datasets</h2>
        <p>
          <small>
            Link datasets for Access Ledger to automatically process
            depreciation journals to
          </small>
        </p>

        <button class="btn btn-secondary" type="button" (click)="addDatasets()">
          Add Datasets
        </button>

        <ag-grid-angular
          #agGrid
          class="ag-theme-material"
          [class.d-none]="!form.get('datasets').value.length"
          [gridOptions]="gridOptions"
        >
          <ag-grid-column
            field="name"
            headerName="Dataset Name"
            [minWidth]="250"
          ></ag-grid-column>
          <ag-grid-column
            cellRenderer="sourceRenderer"
            field="source"
            headerName="Source"
            [maxWidth]="500"
            [minWidth]="200"
          ></ag-grid-column>
          <ag-grid-column
            type="optionsColumn"
            [cellRendererParams]="{ ngTemplate: optionsCell }"
          ></ag-grid-column>
        </ag-grid-angular>

        <ng-template #optionsCell let-row>
          <div class="btn-mini-menu">
            <button
              class="btn-sm fas-btn-ghost"
              type="button"
              (click)="removeDataset(row)"
            >
              <i aria-hidden="true" class="fas fa-trash"></i>
            </button>
          </div>
        </ng-template>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-delete"
      title="Delete"
      type="button"
      *ngIf="!isAdd && deletable"
      [promiseBtn]="busy.delete"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="dismiss()">
      Cancel
    </button>
  </div>
</form>
