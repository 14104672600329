<div class="center pt-2">
  <img
    alt="Access Logo"
    src="./assets/images/logo.png"
    [width]="logoSize === 'lg' ? 250 : 150"
  />

  <div class="spacer-md"></div>

  <p class="m-0">
    <strong>{{ title }}</strong>
  </p>

  <div class="spacer-md"></div>

  <p>
    {{ message }}
  </p>
  <a class="btn btn-primary" href="" type="button" *ngIf="showReturn">
    Return to the Home Page
  </a>
</div>
