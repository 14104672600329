<div class="d-flex justify-content-between align-items-center">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="'/admin'">Admin Centre</a>
      </li>
      <li aria-current="page" class="breadcrumb-item active">Users</li>
    </ol>
  </nav>

  <div
    class="text-right pad-x"
    *ngIf="licenseInfo && !isBankFeedFeatureFlagOnly && isAdmin"
  >
    Licensed Users: {{ licenseInfo.activeUserCount }} out of
    {{ licenseInfo.licenseCount }}
  </div>
</div>

<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="datasource.busy"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-toolbar d-flex pad-no-bottom" role="toolbar">
      <div class="btn-menu">
        <button
          class="btn btn-secondary"
          title="Create User"
          type="button"
          (click)="addUser()"
        >
          <i aria-hidden="true" class="fas fa-plus inset"></i>
          New User
        </button>
      </div>

      <div class="icon-input-wrapper flex-grow-1 ml-3">
        <i aria-hidden="true" class="fas fa-search input-icon"></i>
        <input
          aria-describedby="searchIcon"
          placeholder="Search..."
          type="search"
          [formControl]="search"
        />
      </div>
    </div>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
      >
        <ag-grid-column
          field="firstName"
          headerName="First Name"
        ></ag-grid-column>
        <ag-grid-column
          field="lastName"
          headerName="Last Name"
        ></ag-grid-column>
        <ag-grid-column field="email" headerName="Email"></ag-grid-column>
        <!--<ag-grid-column headerName="Inactive" field="inactive" [minWidth]="120" [maxWidth]="120" type="booleanColumn"></ag-grid-column>-->
        <ag-grid-column
          field="status"
          headerName="Status"
          [maxWidth]="120"
          [minWidth]="120"
        ></ag-grid-column>
        <ag-grid-column
          type="optionsColumn"
          [cellRendererParams]="{ ngTemplate: optionsCellUser }"
          [maxWidth]="90"
          [minWidth]="90"
        ></ag-grid-column>
      </ag-grid-angular>

      <ng-template #optionsCellUser let-row>
        <div class="btn-mini-menu">
          <button
            class="btn-sm fas-btn-primary-ghost"
            title="Edit User"
            type="button"
            (click)="editUser(row)"
          >
            <i aria-hidden="true" class="fas fa-edit"></i>
          </button>
          <!--<button type="button" class="fas-btn" (click)="confirmRemoveUser(row)" title="Remove User" *ngIf="isAdmin">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>-->
        </div>
      </ng-template>
    </div>
  </div>
</div>
