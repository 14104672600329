<fieldset>
  <legend>Report Columns</legend>

  <div class="d-flex gap-3 py-4" cdkDropListGroup>
    <div
      class="card card-pop"
      *ngFor="let c of formArray.controls; let i = index"
      [id]="'column' + i"
      cdkDropList
      (cdkDropListDropped)="dropColumn($event)"
    >
      <crs-report-template-column
        cdkDrag
        [cdkDragData]="i"
        [index]="i"
        [item]="c"
        [datasets]="data.datasets"
        [divisions]="data.divisions"
        [tradingAccounts]="data.tradingAccounts"
        (removed)="removeColumn($event)"
      >
        <!-- Empty placeholder so it doesn't show in 'sub-group' -->
        <div *cdkDragPlaceholder></div>

        <!-- Workaround for cdkDragHandle - Delete this once angular material gets a fix for allowing this in child components, refer https://github.com/angular/material2/issues/13784</ng-content>-->
        <div
          class="card-header d-flex justify-content-between align-items-center draggable"
          cdkDragHandle
        >
          <div class="d-flex align-items-center gap-2">
            <i class="fas fa-bars" aria-hidden="true"></i>
            Column {{ i + 1 }}
          </div>
          <button
            type="button"
            title="remove column"
            class="close"
            (click)="removeColumn(i)"
          >
            <i class="fas fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </crs-report-template-column>
    </div>

    <div class="card card-insert">
      <div class="card-body">
        <div class="btn-group">
          <button type="button" class="btn btn-primary" (click)="addColumn()">
            <i class="fas fa-plus inset" aria-hidden="true"></i>
            Add Column
          </button>
          <button
            type="button"
            class="btn btn-primary dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="sr-only">Column Type Options</span>
          </button>
          <div class="dropdown-menu">
            <button type="button" class="dropdown-item" (click)="addColumn()">
              Add Normal Column
            </button>
            <button type="button" class="dropdown-item" (click)="addColumn(1)">
              Add Calculation Column
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
