export class ApiError {
  code: number;
  messages: string | string[];
  messageString: string;
  isWarning: boolean;

  constructor(code: number, messages: string | string[], isWarning:boolean) {
    this.code = code;
    this.messages = messages;
    this.isWarning = isWarning;
    if (Array.isArray(messages)) {
      this.messageString = messages.join('\n');
    } else {
      this.messageString = messages;
    }
  }
}
