<form
  class="pad-x"
  novalidate
  [formGroup]="form"
  [ngBusy]="busy.load"
  (validSubmit)="submit()"
>
  <h2 class="form-group-title d-inline-block">
    {{ isAdd ? 'New' : '' }} Policy
    <span class="badge text-dark me-1" *ngIf="readonly">
      <i aria-hidden="true" class="fas fa-lock"></i>
    </span>
  </h2>

  <div class="d-inline-block ms-4" *ngIf="readonly">
    <button
      class="btn btn-sm btn-secondary"
      [promiseBtn]="busy.inherit"
      (click)="inheritFromThis()"
    >
      Customise
    </button>
  </div>

  <hr />

  <div class="row">
    <div class="col-md-8 col-xl-6">
      <ng-container
        *ngIf="levels[policy?.level] === 'Sage'; then sage; else other"
      ></ng-container>
      <ng-template #sage>
        <div
          class="badge rounded-pill bg-secondary mb-4"
          *ngIf="readonly && policy"
        >
          Access Ledger Master
        </div>
      </ng-template>
      <ng-template #other>
        <div
          class="badge rounded-pill bg-secondary mb-4"
          *ngIf="readonly && policy"
        >
          {{ levels[policy?.level] }} Master
        </div>
      </ng-template>

      <div class="form-group required">
        <label class="control-label">Name</label>
        <input
          class="form-control"
          formControlName="name"
          type="text"
          [readonly]="readonly"
          title="name"
        />
      </div>

      <div class="form-group">
        <label class="control-label">Location</label>
        <input
          class="form-control"
          readonly
          type="text"
          *ngIf="readonly || !isAdd"
          [value]="locations[form.controls['location'].value]"
          title="Location"
        />
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="location"
          *ngIf="!readonly && isAdd"
          [clearable]="false"
          [items]="locations | enumToArray"
        ></ng-select>
      </div>
    </div>
    <div class="col-md-11 col-xl-9">
      <div class="form-group">
        <label class="control-label">Description</label>
        <textarea
          class="form-control"
          formControlName="description"
          rows="4"
          [readonly]="readonly"
          title="description"
        ></textarea>
      </div>
      <div class="form-group">
        <label class="control-label">Show by Default</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="autoSelectionMethod"
          [clearable]="false"
          [items]="policyAutoSelectionMethods | enumToArray"
          [readonly]="readonly"
        ></ng-select>
      </div>
      <div
        class="form-group"
        *ngIf="form.controls['autoSelectionMethod'].value == 0"
      >
        <label class="control-label"
          >Default when any of the following Account Types appear</label
        >
        <crs-account-type-select
          formControlName="accountTypes"
          [multiple]="true"
          [readonly]="readonly"
        ></crs-account-type-select>
      </div>
    </div>
  </div>

  <div *ngIf="!isAdd">
    <h2 class="form-group-title">Variants</h2>

    <hr />

    <div *ngIf="policy" class="d-grid gap-2">
      <crs-policy-variant
        *ngFor="let v of policy.policyVariants; let i = index"
        [index]="i"
        [level]="level"
        [policy]="policy"
        [variant]="v"
        (removed)="policy.policyVariants.splice(i, 1)"
      ></crs-policy-variant>

      <div class="card card-insert" *ngIf="policy?.level >= level">
        <div class="card-body">
          <button
            class="btn btn-primary"
            type="button"
            (click)="addPolicyVariant()"
          >
            <i aria-hidden="true" class="fas fa-plus inset"></i>
            Add Policy Variant
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="spacer"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu form-bottom-menu">
    <button
      class="btn btn-primary"
      type="submit"
      *ngIf="!readonly"
      [promiseBtn]="busy.submit"
    >
      Save
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      *ngIf="readonly"
      [promiseBtn]="busy.inherit"
      (click)="inheritFromThis()"
    >
      Customise
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      {{ readonly || !form.dirty ? 'Close' : 'Cancel' }}
    </button>
    <button
      class="btn btn-outline-danger float-right"
      title="Delete"
      type="button"
      *ngIf="!isAdd && isAdmin && !readonly"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      {{ policy?.masterPolicyId ? 'Delete Customised Version' : 'Delete' }}
    </button>
  </div>
</form>
