<div class="row">
  <div class="col-xl-5" [class.d-none]="showAudit" [class.d-xl-block]="showAudit">
    <div>

      <!-- SBE Return -->
      <table *ngIf="smallBusinessDisclosures" role="presentation">
        <tbody>
          <tr>
            <td colspan=5>
              <h1>Small business entity simplified depreciation</h1>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Deduction for certain assets
            </td>
            <td class="letter-label">
              A
            </td>
            <td class="dollar-label">
              $
            </td>
            <td class="label">
              <div class="text-box">
                {{ sbeContent.deductionsForCertainAssets | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(sbeContent.deductionsForCertainAssetsAudit)"></i>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Deduction for general <br />
              small business pool
            </td>
            <td class="letter-label">
              B
            </td>
            <td class="dollar-label">
              $
            </td>
            <td class="label">
              <div class="text-box">
                {{ sbeContent.deductionForGeneralPool | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(sbeContent.deductionForGeneralPoolAudit)"></i>
            </td>
          </tr>
        </tbody>
      </table>

        <!-- Non-SBE Return - Capital Allowances -->
      <table *ngIf="!smallBusinessDisclosures" role="presentation">
        <tbody>
          <tr>
            <td colspan=5>
              <h1>Capital Allowances</h1>
            </td>
          </tr>
          <!-- Depreciating Aasets first deducted in this income year-->
          <tr>
            <td colspan=5>
              <h2>Depreciating assets first deducted in this income year</h2>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Intangible depreciating assets first deducted
            </td>
            <td class="letter-label">A</td>
            <td class="dollar-label">$</td>
            <td class="label">
              <div class="text-box">
                {{ nonSbeContent.intangibleAssetsFirstDeducted | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(nonSbeContent.intangibleAssetsFirstDeductedAudit)"></i>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Other depreciating assets first deducted
            </td>
            <td class="letter-label">B</td>
            <td class="dollar-label">$</td>
            <td class="label">
              <div class="text-box">
                {{ nonSbeContent.otherAssetsFirstDeducted | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(nonSbeContent.otherAssetsFirstDeductedAudit)"></i>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Have you self-assessed the <br />
              effective life of any of these assets?
            </td>
            <td class="letter-label">C</td>
            <td class="dollar-label"></td>
            <td class="label">
              <div class="checkbox-container">
                Yes <div class="checkbox" [class.checked]="nonSbeContent.haveYouSelfAssessedAny"></div>
                No <div class="checkbox" [class.checked]="!nonSbeContent.haveYouSelfAssessedAny"></div>
              </div>
            </td>
            <td class="info-label"></td>
          </tr>
          <!-- For all depreciating assets -->
          <tr>
            <td colspan=5>
              <h2>For all depreciating assets</h2>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Did you recalculate the effective life for <br />
              any of your assets this income year?
            </td>
            <td class="letter-label">D</td>
            <td class="dollar-label"></td>
            <td class="label">
              <div class="checkbox-container">
                Yes <div class="checkbox"></div>
                No <div class="checkbox"></div>
              </div>
            </td>
            <td class="info-label"></td>
          </tr>
          <tr>
            <td class="text-label">
              Total adjustable values at end of income year
            </td>
            <td class="letter-label">E</td>
            <td class="dollar-label">$</td>
            <td class="label">
              <div class="text-box">
                {{ nonSbeContent.totalAdjustableValues | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(nonSbeContent.totalAdjustableValuesAudit)"></i>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Assessable balancing adjustments on the <br />
              disposal of intangible depreciating assets
            </td>
            <td class="letter-label">F</td>
            <td class="dollar-label">$</td>
            <td class="label">
              <div class="text-box">
                {{ nonSbeContent.intangibleAssessableBalancingAdjustments | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(nonSbeContent.intangibleAssessableBalancingAdjustmentsAudit)"></i>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Deductible balancing adjustments on the <br />
              disposal of intangible depreciating assets
            </td>
            <td class="letter-label">G</td>
            <td class="dollar-label">$</td>
            <td class="label">
              <div class="text-box">
                {{ nonSbeContent.intangibleDeductibleBalancingAdjustments | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(nonSbeContent.intangibleDeductibleBalancingAdjustmentsAudit)"></i>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Termination value of intangible depreciating assets
            </td>
            <td class="letter-label">H</td>
            <td class="dollar-label">$</td>
            <td class="label">
              <div class="text-box">
                {{ nonSbeContent.intangibleTerminationValue | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(nonSbeContent.intangibleTerminationValueAudit)"></i>
            </td>
          </tr>
          <tr>
            <td class="text-label">
              Termination value of other depreciating assets
            </td>
            <td class="letter-label">I</td>
            <td class="dollar-label">$</td>
            <td class="label">
              <div class="text-box">
                {{ nonSbeContent.otherTerminationValue | money:0:true }}
              </div>
            </td>
            <td class="info-label">
              <i class="fas fa-info-circle fas-btn-primary" aria-hidden="true" (click)="selectAudit(nonSbeContent.otherTerminationValueAudit)"></i>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="col-xl-7">

    <!-- Audit Detail Panel -->
    <div class="panel" *ngIf="showAudit">
      <div class="panel-heading">
        <div class="d-flex">
          <div class="flex-grow-1">
            <span class="primary">Tax Return Label Detail</span>
          </div>
          <div class="close" (click)="closeAudit()">
            Close <i class="fas fa-times ml-1" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="panel-body">

        <crs-asset-report-tax-audit [auditContent]="auditContent"></crs-asset-report-tax-audit>

      </div>
      <div class="panel-footer">
        <button type="button" class="btn btn-primary" (click)="closeAudit()"> Close</button>
      </div>
    </div>

  </div>
</div>





