import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-number-renderer',
  template: `
    {{ value | formatNumber : rounding : nullValue }}
    <span *ngIf="showPercent">%</span>
  `,
})
export class NumberRendererComponent implements ICellRendererAngularComp {
  public value: number;
  public isPercent = false;
  public showPercent = false;
  public rounding: number;
  public zeroValue = null;
  public nullValue = null;

  agInit(params: any): void {
    this.refresh(params);
  }

  refresh(params: any): boolean {
    this.value = params.value;
    this.isPercent = params['isPercent'];
    this.showPercent = this.isPercent;
    this.rounding = params['rounding'];
    this.zeroValue = params['zeroValue'];
    this.nullValue = params['nullValue'];

    if (this.isPercent) {
      this.value = params.value * 100;
      if (isNaN(this.value)) {
        this.showPercent = false;

        // Temporary fix for asset reports showing 'NaN' for header entries.
        // Percentage values need not show NaN in any other use case.
        this.value = null;
      }
      this.nullValue = this.value === 0 ? 0 : null;
    }

    return true;
  }
}
