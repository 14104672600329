<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item"><a [routerLink]="'../'">Image Library</a></li>
    <li aria-current="page" class="breadcrumb-item active">
      {{ isAdd ? 'New' : '' }} Image
    </li>
  </ol>
</nav>

<crs-base-image
  *ngIf="!!image"
  [image]="image"
  (close)="close()"
  (submit)="submit($event)"
>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">Practice Variants</div>
        <div class="card-body">
          <div class="btn-menu">
            <button
              class="btn btn-secondary"
              title="Create Image"
              type="button"
              (click)="addImageVariant()"
            >
              <i aria-hidden="true" class="fas fa-plus inset"></i>
              New Image
            </button>
          </div>

          <ag-grid-angular
            #agGrid
            class="ag-theme-material"
            rowClass="selectable-row"
            [gridOptions]="gridOptions"
            [rowData]="imageVariants"
            (rowClicked)="selectImageVariant($event.data)"
          >
            <ag-grid-column field="name" headerName="Name"></ag-grid-column>
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</crs-base-image>
