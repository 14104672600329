import { IAccountsViewAccount } from './../../ledger/accounts/accounts/accounts-view-account';
import { AutoMatchMode } from './../../ledger/accounts/autoMatchMode';
import { AccountTypeMode } from './../../ledger/accounts/accountTypeMode';
import { AccountType } from './../../ledger/account-types/accountType';
import {
  Classification,
  CurrentClassification,
} from './../../ledger/classifications/classification';
import { IAccount } from './../../ledger/accounts/account';
import { EntityTypeEnum } from 'src/app/firm';

export enum StandardChartTypeEnum {
  APS = 1,
  Handisoft = 2,
}

export interface StandardAccountSetting {
  id: string;
  masterSettingId: number;
  settingName: string;    
}

export interface StandardAccountToUpsert {
  accountName?: string;
  accountNo?: string;
  classification?: Classification;
  accountTypeId?: number;
  accountTypeMode?: AccountTypeMode;
  masterTaxCode?: string;
  currentClassification?: Classification;
  ledgerSide?: number;
  level?: number;
  parentId?: string;
  parentMasterAccountId?: number;
  rootMasterAccountId?: number;
  sortOrder?: number;
  entityTypes?: number[];
  accountNameVariants: StandardAccountNameVariant[];
  openingBalanceAccountId: string;
}

export interface StandardAccountNameVariant {
  entityType: EntityTypeEnum;
  accountName: string;
}

export class StandardAccount implements IAccount, IAccountsViewAccount {
  accountName: string;
  accountNameVariants: StandardAccountNameVariant[];
  accountNo: string;
  accountType: AccountType;
  accountTypeId: number;
  accountTypeMode: AccountTypeMode;
  autoMatchMode: AutoMatchMode;
  classification: Classification;
  classificationLabel: string;
  classificationTopLevelLabel: string;
  currentClassification: Classification;
  hierarchy: string[] = [];
  id: string;
  isConsolidated: boolean;
  isHeader: boolean;
  isReassignable: boolean;
  isSortable: boolean;
  masterAccountId: number;
  masterTaxCode: string;
  parent: StandardAccount;
  parentId: string;
  parentMasterAccountId: string;
  sortOrder: number;
  sourceAccounts: any[];
  sourceClassification: Classification;
  systemAccount: number;
  level?: number;
  children: StandardAccount[];
  openingBalanceAccountId: string;
  // Entity types
  isCompany: boolean;
  isUnitTrust: boolean;
  isDiscretionaryTrust: boolean;
  isPartnership: boolean;
  isSoleTrader: boolean;
  isSuper: boolean;
  isAssociation: boolean;

  get displayName(): string {
    if (this.accountNo) return this.accountNo + ' ' + this.accountName;
    else return this.accountName;
  }

  isParentOf(account: IAccountsViewAccount) {
    const position = this.hierarchy.length - 1;
    return (
      account.hierarchy.length > position + 1 &&
      account.hierarchy[position] === this.id
    );
  }

  isChildOf(account: IAccountsViewAccount) {
    const position = account.hierarchy.length - 1;
    return (
      this.hierarchy.length > position + 1 &&
      this.hierarchy[position] === account.id
    );
  }

  shallowCopy(): StandardAccount {
    return new StandardAccount(this);
  }

  constructor(data) {
    Object.assign(this, data);

    if (data.masterAccountId) {
      this.masterAccountId = parseInt(data.masterAccountId, 10);
    }

    if (data.parent) {
      this.parent = new StandardAccount(data.parent);
    }

    if (data.level) {
      this.level = parseInt(data.level, 10);
    }

    if (data.classification) {
      const currentClassification =
        CurrentClassification[data.currentClassification];
      const classification = Classification[data.classification];

      this.classificationLabel = data.currentClassification
        ? `${currentClassification} ${classification}`
        : classification;

      this.classificationTopLevelLabel = classification;
    }

    if (data.hierarchy) {
      this.hierarchy = [...data.hierarchy];
    }
  }
}
