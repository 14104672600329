<div [formGroup]="item">
  <!-- Replace with <ng-content> tag below once angular material gets a fix for doing cdDRagHandle in child components, refer https://github.com/angular/material2/issues/13784</ng-content>-->
  <!--<div class="card-header draggable" cdkDragHandle>
    <i class="fas fa-bars" aria-hidden="true"></i>
    Column {{i + 1}}
    <button type="button" title="remove column" class="close" (click)="removed.emit(i)">
      <i class="fas fa-times" aria-hidden="true"></i>
    </button>
  </div>-->

  <ng-content></ng-content>

  <div class="card-body">
    <!-- Data Column -->
    <div *ngIf="item.controls.columnType.value == 0">
      <div class="form-group required">
        <label class="control-label">Dataset</label>
        <crs-dataset-select formControlName="dataset" [datasets]="datasets" />
      </div>

      <div class="form-group">
        <label class="control-label">Column Header</label>
        <div class="input-group-vertical">
          <input
            class="form-control text-center"
            formControlName="headerText"
            type="text"
          />
          <input
            class="form-control text-center"
            formControlName="periodText"
            type="text"
          />
          <input
            class="form-control text-center"
            formControlName="signText"
            type="text"
          />
        </div>
      </div>

      <div class="form-group">
        <label class="control-label">
          <i aria-hidden="true" class="fas fa-columns text-primary"></i>
          Spread by
        </label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="spreadBy"
          [items]="columnSpreadBy | enumToArray"
        />
      </div>

      <div
        class="form-group"
        *ngIf="item.controls.dataset?.value?.isGroupDataset"
      >
        <label class="control-label">
          <i aria-hidden="true" class="fas fa-copy text-primary"></i>
          Consolidation Mode
        </label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="consolidationMode"
          [clearable]="false"
          [items]="consolidationModes | enumToArray"
        />
      </div>

      <div class="mt-4">
        <a data-bs-toggle="collapse" [href]="'#filter' + i">
          <i aria-hidden="true" class="fas fa-filter me-1"></i>
          <span class="me-2">Filters</span>
          <span
            class="badge bg-primary me-2"
            *ngIf="item.getActiveFilters() > 0"
          >
            {{ item.getActiveFilters() }}
            <span class="sr-only">used filters</span>
          </span>

          <i aria-hidden="true" class="fas fa-angle-double-down"></i>
        </a>
      </div>

      <div
        class="collapse"
        [class.show]="item.getActiveFilters()"
        [id]="'filter' + i"
      >
        <hr />

        <div class="form-group">
          <crs-date-picker
            formControlName="startDate"
            placeholder="By Start Date..."
            [clearable]="true"
          ></crs-date-picker>
        </div>

        <div class="form-group">
          <crs-date-picker
            formControlName="endDate"
            placeholder="By End Date..."
            [clearable]="true"
          ></crs-date-picker>
        </div>

        <div class="form-group">
          <ng-select
            bindLabel="name"
            formControlName="division"
            placeholder="By Division..."
            [compareWith]="compareWithFn"
            [items]="divisions"
          />
        </div>

        <div class="form-group">
          <ng-select
            bindLabel="name"
            bindValue="id"
            formControlName="tradingAccount"
            placeholder="By Trading Account..."
            [compareWith]="compareWithFn"
            [items]="tradingAccounts"
          />
        </div>
      </div>
    </div>

    <!-- Calculation Column -->
    <div *ngIf="item.controls.columnType.value == 1">
      <div class="form-group required">
        <label class="control-label"> Calculation Type </label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="calculationType"
          [clearable]="false"
          [items]="calculationTypes | enumToArray"
        />
      </div>

      <div class="form-group">
        <label class="control-label">Column Header</label>
        <div class="input-group-vertical">
          <input
            class="form-control text-center"
            formControlName="headerText"
            type="text"
          />
          <input
            class="form-control text-center"
            formControlName="periodText"
            type="text"
          />
          <input
            class="form-control text-center"
            formControlName="signText"
            type="text"
          />
        </div>
      </div>
    </div>
  </div>
</div>
