export enum DepreciationReportType {
  FullSummary = 0,
  Taxation = 1,
  Accounting = 2,
  Pooling = 3,
  TaxDisclosures = 4,
}

/// Depreciation reports available in the main report template system
export enum DepreciationReportTypeInReport {
  FullSummary = 0,
  Taxation = 1,
  Accounting = 2,
  Pooling = 3,
}

export const monthlyDepreciationPeriods = [
  { id: 1, name: 'Jul' },
  { id: 2, name: 'Aug' },
  { id: 3, name: 'Sep' },
  { id: 4, name: 'Oct' },
  { id: 5, name: 'Nov' },
  { id: 6, name: 'Dec' },
  { id: 7, name: 'Jan' },
  { id: 8, name: 'Feb' },
  { id: 9, name: 'Mar' },
  { id: 10, name: 'Apr' },
  { id: 11, name: 'May' },
  { id: 12, name: 'Jun' },
];
