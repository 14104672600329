<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form novalidate [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="form-group">
      <div class="row">
        <label class="control-label col-4">Rule title</label>
        <div class="col-8">
          <input
            class="form-control"
            formControlName="name"
            type="text"
            title="Rule title"
          />
        </div>
      </div>
    </div>

    <hr />

    <h5 class="mb-4">Definition</h5>

    <div class="form-group row">
      <label class="control-label col-4">Type</label>
      <div class="col-4 d-flex">
        <input
          formControlName="movementType"
          id="MoneyIn"
          type="radio"
          [value]="0"
        />
        <label class="ps-2" for="MoneyIn">Money In</label>
      </div>
      <div class="col-4 d-flex">
        <input
          formControlName="movementType"
          id="MoneyOut"
          type="radio"
          [value]="1"
        />
        <label class="ps-2" for="MoneyOut">Money Out</label>
      </div>
    </div>

    <ng-container [formGroup]="form.controls.descriptionCondition">
      <div class="form-group">
        <div class="row">
          <label class="control-label col-4">Description</label>
          <div class="col-4">
            <crs-condition-select
              formControlName="conditionOption"
              valueType="text"
              (clear)="onClearDescription()"
            ></crs-condition-select>
          </div>
          <div class="col-4">
            <input
              #conditionValueInput
              class="form-control"
              formControlName="conditionValue"
              type="text"
              [placeholder]="descriptionPlaceholder"
              title="Condition description "
            />
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container [formGroup]="form.controls.amountCondition">
      <div class="form-group">
        <div class="row">
          <label class="control-label col-4">Amount</label>
          <div class="col-4">
            <crs-condition-select
              formControlName="conditionOption"
              valueType="number"
              (clear)="onClearAmount()"
            ></crs-condition-select>
          </div>
          <div class="col-4">
            <input
              class="form-control"
              formControlName="conditionValue"
              type="number"
              title="Condition value amount"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <crs-form-error
      class="mt-3 d-block"
      *ngIf="
        form.hasError('descriptionOrAmount') &&
        (form.controls.descriptionCondition.touched ||
          form.controls.amountCondition.touched)
      "
      [error]="'You need to set at least 1 condition.'"
    >
    </crs-form-error>

    <hr />

    <div class="row mb-4">
      <div class="col-4">
        <h5>Allocation</h5>
      </div>
      <div class="col-4 d-flex">
        <input
          formControlName="allocateBy"
          id="percentageOnly"
          type="radio"
          [value]="'Percentage Only'"
        />
        <label class="ps-2" for="percentageOnly">Percentage Only</label>
      </div>
      <div class="col-4 d-flex">
        <input
          formControlName="allocateBy"
          id="amountAndpercentage"
          type="radio"
          [value]="'Amount & Percentage'"
        />
        <label class="ps-2" for="amountAndpercentage"
          >Amount & percentage</label
        >
      </div>
    </div>

    <ng-container
      *ngIf="form.controls.allocateBy.value === 'Amount & Percentage'"
    >
      <ng-container
        [ngTemplateOutlet]="allocationTable"
        [ngTemplateOutletContext]="{
          allocationControls: form.controls.amountAllocations,
          allocationType: allocationTypes.Amount,
        }"
      >
      </ng-container>
      <hr />
    </ng-container>

    <div class="row mb-4">
      <h5 class="col-12">Remainder (%)</h5>
    </div>

    <ng-container
      [ngTemplateOutlet]="allocationTable"
      [ngTemplateOutletContext]="{
        allocationControls: form.controls.percentageAllocations,
        allocationType: allocationTypes.Percentage,
      }"
    >
    </ng-container>

    <crs-form-error
      class="mt-3 d-block"
      *ngIf="
        form.controls.percentageAllocations.hasError('percentageSum') &&
        form.controls.percentageAllocations.touched
      "
      [error]="'Total of percentages should equal to 100'"
    ></crs-form-error>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!form.valid"
      [promiseBtn]="busy.submit"
    >
      {{ id ? 'Save' : 'Add' }} {{ objectTitle }}
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
  </div>
</form>

<ng-template
  #allocationTable
  let-allocationControls="allocationControls"
  let-allocationType="allocationType"
>
  <div class="row mb-4" [formArray]="allocationControls">
    <div class="col-12">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" *ngIf="allocationType === allocationTypes.Amount">
              Amount
            </th>
            <th
              scope="col"
              *ngIf="allocationType === allocationTypes.Percentage"
            >
              Percentage
            </th>
            <th scope="col">Account</th>
            <th scope="col">Tax Code</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <ng-container
              *ngFor="
                let allocationControl of allocationControls.controls;
                let i = index
              "
            >
              <tr [formGroup]="allocationControl">
                <td class="form-group position-relative">
                  <input
                    class="form-control ps-4"
                    formControlName="allocationValue"
                    type="number"
                    [placeholder]="
                      allocationType === allocationTypes.Amount
                        ? 'Amount'
                        : 'Percentage'
                    "
                    title="Allocation Value"
                  />
                  <i
                    class="value-icon"
                    *ngIf="allocationType === allocationTypes.Amount"
                    >$</i
                  >
                  <i
                    class="value-icon pb-1"
                    *ngIf="allocationType === allocationTypes.Percentage"
                    >%</i
                  >
                </td>
                <td class="form-group">
                  <crs-transaction-account-select
                    formControlName="bacoAccountId"
                    appendTo=".modal-body"
                    (change)="
                      onAccountSelectionChanged($event, allocationControl)
                    "
                  >
                  </crs-transaction-account-select>
                </td>
                <td class="form-group">
                  <crs-transaction-tax-select
                    formControlName="bacoTaxOptionId"
                    [clearable]="true"
                    (change)="onTaxOptionSelectionChanged($event, i)"
                    appendTo=".modal-body"
                  ></crs-transaction-tax-select>
                </td>
                <td class="form-group">
                  <input
                    class="form-control"
                    formControlName="description"
                    placeholder="Start typing..."
                    type="text"
                  />
                </td>
                <td class="p-0">
                  <button
                    class="btn btn-sm d-block m-auto pt-3 pb-3"
                    title="Delete"
                    type="button"
                    *ngIf="i > 0"
                    (click)="deleteAllocationRow(allocationType, i)"
                  >
                    <i
                      aria-hidden="true"
                      class="fas fa-trash text-primary pt-2"
                    ></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="col-12 d-flex justify-content-end">
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="addAllocationRow(allocationType)"
      >
        Add line
      </button>
    </div>
  </div>
</ng-template>
