import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, exhaustMap, finalize, tap } from 'rxjs/operators';
import { ActiveFileService } from 'src/app/accounting/active-file.service';
import {
  Division,
  DivisionService,
  TradingAccount,
  TradingAccountService,
} from 'src/app/accounting/setup';
import { ApiType, Source } from 'src/app/accounting/sourcedata';
import { Classification } from '../../classifications';
import {
  SourceAccountService,
  SourceAccountToUpsert,
} from '../../sourceAccounts';
import { TaxCodeService } from '../../tax-codes/tax-code.service';
import { TaxCode } from '../../tax-codes/tax-code';

export enum AddSourceAccountFormType {
  AddNew = 'addNew',
}

@Component({
  selector: 'crs-source-account-form',
  templateUrl: './source-account-form.component.html',
})
export class SourceAccountFormComponent implements OnInit, OnDestroy {
  @Input() id: AddSourceAccountFormType | string;
  @Input() set params(filteredSource: Source) {
    if (filteredSource) {
      this.filteredSource = filteredSource;
    }
  }

  busy = {
    load: null,
    submit: null,
  };
  error: string = null;

  isAdd: boolean;

  form = this.formBuilder.group({
    source: [null, [Validators.required]],
    accountNo: ['', [Validators.required, Validators.maxLength(128)]],
    accountName: ['', [Validators.required, Validators.maxLength(256)]],
    sourceClassification: [null, Validators.required],
    division: [null],
    taxCode: [null],
    tradingAccount: [null],
    isInactive: [false],
  });

  divisions: Division[];
  taxCodes: TaxCode[];
  tradingAccounts: TradingAccount[];
  classifications = Classification;
  apiType = ApiType;

  private fileId: string;
  private filteredSource: Source;
  private submitButtonStream$ = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(
    private activeFileService: ActiveFileService,
    private activeModal: NgbActiveModal,
    private divisionService: DivisionService,
    private formBuilder: UntypedFormBuilder,
    private sourceAccountService: SourceAccountService,
    private taxCodeService: TaxCodeService,
    private tradingAccountService: TradingAccountService
  ) {}

  ngOnInit(): void {
    this.isAdd = this.id === AddSourceAccountFormType.AddNew;

    this.fetchFileId();
    this.fetchSourceAccount();
    this.fetchDivisions();
    this.fetchTaxCodes();
    this.fetchTradingAccounts();
    this.configureSubmit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onClickClose(): void {
    this.activeModal.dismiss();
  }

  onValidSubmit(): void {
    this.submitButtonStream$.next();
  }

  private fetchFileId(): void {
    const activeFileStreamSubscription =
      this.activeFileService.stream.subscribe((file) => {
        this.fileId = file?.id;
      });

    this.subscriptions.push(activeFileStreamSubscription);
  }

  private fetchSourceAccount(): void {
    if (this.id === AddSourceAccountFormType.AddNew) {
      if (this.filteredSource) {
        this.form.patchValue({ source: this.filteredSource });
      }

      return;
    }

    this.busy.load = this.sourceAccountService.get$(this.id).subscribe(
      (account) => {
        this.form.patchValue({
          source: account.source,
          accountNo: account.accountNo,
          accountName: account.accountName,
          sourceClassification: account.classification,
          division: account.division?.id ?? null,
          taxCode: account.taxCode,
          tradingAccount: account.tradingAccount?.id ?? null,
          isInactive: account.inactive,
        });
      },
      (error) => this.showError(error)
    );
  }

  private fetchDivisions(): void {
    const divisionsSubscription = this.divisionService
      .getAll(this.fileId)
      .subscribe((divisions) => (this.divisions = divisions));

    this.subscriptions.push(divisionsSubscription);
  }

  private fetchTaxCodes(): void {
    const taxCodesSubscription = this.taxCodeService
      .getAll$(this.fileId)
      .subscribe((taxCodes) => (this.taxCodes = taxCodes));

    this.subscriptions.push(taxCodesSubscription);
  }

  private fetchTradingAccounts(): void {
    const tradingAccountsSubscription = this.tradingAccountService
      .getAll(this.fileId)
      .subscribe((tradingAccounts) => (this.tradingAccounts = tradingAccounts));

    this.subscriptions.push(tradingAccountsSubscription);
  }

  private configureSubmit(): void {
    const submitButtonStreamSubscription = this.submitButtonStream$
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submit$())
      )
      .subscribe((id) => {
        this.activeModal.close(id);
      });

    this.subscriptions.push(submitButtonStreamSubscription);
  }

  private submit$(): Observable<any> {
    const sourceAccountToUpsert: SourceAccountToUpsert = {
      sourceId: this.form.value.source.id,
      accountNo: this.form.value.accountNo?.trim(),
      accountName: this.form.value.accountName?.trim(),
      classification: this.form.value.sourceClassification,
      divisionId: this.form.value.division,
      taxCode: this.form.value.taxCode,
      tradingAccountId: this.form.value.tradingAccount,
      inactive: this.form.value.isInactive,
    };

    const loading$ = new Subject();
    this.busy.submit = loading$.subscribe();

    const submit$ = this.isAdd
      ? this.sourceAccountService.post$(sourceAccountToUpsert)
      : this.sourceAccountService.put$(this.id, sourceAccountToUpsert);

    return submit$.pipe(
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      }),
      finalize(() => loading$.complete())
    );
  }

  private showError(error): void {
    this.error = error;
  }
}
