import { catchError, tap, distinctUntilChanged, filter, map, switchMap, finalize } from 'rxjs/operators';
import { AssetsContextService } from './../../assets-context.service';
import { AssetGroupService } from './../asset-group.service';
import { Component, OnInit, forwardRef, ChangeDetectionStrategy, ViewEncapsulation, Input, ViewChild, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription, of, Subject, BehaviorSubject } from 'rxjs';
import { AssetGroup } from '../asset-group';
import { NgSelectComponent } from '@ng-select/ng-select';

class SelectContext {
  fileId: string;
  entityId: string;
}

@Component({
  selector: 'crs-asset-group-select',
  templateUrl: './asset-group-select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AssetGroupSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AssetGroupSelectComponent implements OnInit, OnDestroy {

  private _fileId: string;
  @Input() set fileId(value: string) {
    this._fileId = value;
    this.activeContext.fileId = value;
  }
  @Input() set entityId(value: string) {
    this.activeContext.entityId = value;
  }
  @Input() clearable = true;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() placeholder: string;

  activeContext = new SelectContext();
  opened$ = new Subject();
  loading = false;

  assetGroups$: Observable<AssetGroup[]>;
  subscriptions: Subscription[] = [];

  @ViewChild(NgSelectComponent, { static: true }) private _valueAccessor: NgSelectComponent;

  compareWith = (a, b) => a.id === b.id;

  constructor(private readonly _assetGroupService: AssetGroupService,
    private readonly _assetsContextService: AssetsContextService) { }

  writeValue(value: any | any[]): void {
    this._valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }

  ngOnInit() {
    this._valueAccessor.bindLabel = 'name'; // Binding not working with html tag for some reason
    this._valueAccessor.multiple = this.multiple; // don't do in template as it fails to display existing values in form

    this.subscriptions.push(this.opened$
      .pipe(
        map(() => this.activeContext),
        distinctUntilChanged((x, y) => x.fileId === y.fileId && x.entityId === y.entityId),
        filter(c => !!c.fileId && !!c.entityId),
        tap(c => this.getObservable(c.fileId, c.entityId)))
      .subscribe());

    if (!this._fileId) {
      // Get context from AssetsContextService only if we are not supplying it directly throug the fileId and entityId inputs
      this.subscriptions.push(this._assetsContextService.contextValid$
        .pipe(
          tap(c => {
            this.activeContext.fileId = c.file.id;
            this.activeContext.entityId = c.entity.id;
          }))
        .subscribe());
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  onOpen() {
    this.opened$.next();
  }

  private getObservable(fileId: string, entityId: string) {
    this.loading = true;
    this.assetGroups$ = this._assetGroupService
      .getAll(fileId, entityId)
      .pipe(
        catchError(err => {
        console.log(err);
        return of([]);
      }),
      finalize(() => this.loading = false));
  }

}
