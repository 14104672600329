<!-- Search -->
<div class="row">
  <div class="col-sm-12">
    <div class="icon-input-wrapper flex-grow-1 me-2">
      <i aria-hidden="true" class="fas fa-search input-icon"></i>
      <input
        aria-describedby="searchIcon"
        placeholder="Search..."
        type="search"
        [formControl]="search"
      />
    </div>
  </div>
</div>

<div style="overflow: hidden; flex-grow: 1">
  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    style="width: 100%"
    [gridOptions]="gridOptions"
    [rowData]="content"
  />
</div>
