import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportStylesComponent } from './report-styles/report-styles.component';
import { ReportStyleComponent } from './report-style/report-style.component';
import { CssEditorComponent } from './css/css-editor/css-editor.component';
import { CssClassesEditorComponent } from './css/classes-editor/classes-editor.component';
import { ReportStyleSelectComponent } from './report-style-select/report-style-select.component';
import { ExcelStyleEditorComponent } from './excel/excel-style-editor/excel-style-editor.component';
import { CssFontUrlsEditorComponent } from './css/font-urls-editor/font-urls-editor.component';
import { WordStyleEditorComponent } from './word/word-style-editor/word-style-editor.component';

@NgModule({
    declarations: [
      CssClassesEditorComponent,
      CssEditorComponent,
      ReportStylesComponent,
      ReportStyleComponent,
      ReportStyleSelectComponent,
      ExcelStyleEditorComponent,
      CssFontUrlsEditorComponent,
      WordStyleEditorComponent
    ],
    imports: [SharedModule],
    exports: [
      ReportStyleSelectComponent
    ]
})
export class ReportStylesModule {
}
