import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { HeaderStyle, Alignment, ReportHeader } from './report-header';
import { ReportContentInfo } from '../../report-content/report-content-info';
import { ReportMetaData } from '../../report-output';

@Component({
  selector: 'crs-report-header',
  templateUrl: './report-header.component.html'
})
export class ReportHeaderComponent {

  form: UntypedFormGroup;
  component = this;

  constructor(readonly formBuilder: UntypedFormBuilder) {}

  @Input() element: ReportHeader;
  @Input() index: number;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;

  isEditing = false;
  styles = HeaderStyle;
  alignments = Alignment;
  levels = [1, 2, 3, 4];

  onEdit() {
    this.form = this.formBuilder.group({
      level: [1, Validators.required],
      style: [HeaderStyle.Normal, Validators.required],
      alignment: [Alignment.Left, Validators.required],
      marginTop: [false, Validators.required],
      marginBottom: [true, Validators.required],
      text: [null, Validators.required],
    });
    this.form.patchValue(this.element);
    this.isEditing = true;
  }

  onSubmit = () => {
    this.form.markAsTouched();
    if (!this.form.valid) return false;

    Object.assign(this.element, this.form.value);
    return true;
  }

  onCancel() {
    this.isEditing = false;
    this.form = null;
  }

}
