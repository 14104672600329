import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError, switchMap } from 'rxjs/operators';

import { getDefaultGridOptions } from 'src/app/shared';
import { ActiveFileService } from '../..';
import { ESignatureService } from '../esignature.service';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-esignature-request-history',
  templateUrl: './esignature-request-history.component.html',
  styleUrls: ['./esignature-request-history.component.scss'],
})
export class ESignatureRequestHistoryComponent implements OnInit {
  busy = {
    load: null,
  };
  error: string;
  requestHistories = [];
  gridOptions: GridOptions;

  constructor(
    private readonly _eSignatureService: ESignatureService,
    public route: ActivatedRoute,
    private router: Router,
    private activeFileService: ActiveFileService
  ) {}

  ngOnInit(): void {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      columnDefs: [
        {
          field: 'document',
          headerName: 'Documents',
          editable: false,
        },
        {
          field: 'requestor',
          headerName: 'Requestor',
          editable: false,
        },
        {
          field: 'dateSent',
          headerName: 'Date Sent',
          type: 'dateColumn',
          editable: false,
        },
      ],
    };

    this.getRequestHistoires();
  }

  getRequestHistoires() {
    let fileId = this.activeFileService.file.id;
    this.busy.load = this._eSignatureService
      .getRequestHistories(fileId)
      .pipe(
        tap((histories) => (this.requestHistories = histories)),
        catchError((err) => {
          this.showError(err);
          return of([]);
        })
      )
      .subscribe();
  }

  close() {
    this.router.navigate(['../../e-signature'], { relativeTo: this.route });
  }

  showError(error) {
    this.error = error;
  }
}
