<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item"><a [routerLink]="'../'">Report Styles</a></li>
    <li aria-current="page" class="breadcrumb-item active">Report Style</li>
  </ol>
</nav>

<form class="pad-x" [formGroup]="form" [ngBusy]="[busy.load]">
  <div class="row">
    <div class="col-form-md">
      <div class="form-group required">
        <label class="control-label">Style Name</label>
        <input class="form-control" formControlName="name" type="text" />
      </div>

      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="isDefault"
            type="checkbox"
          />
          <label class="form-check-label"
            >This is the Default Report Style</label
          >
        </div>
      </div>

      <div class="form-group">
        <label class="control-label">Rounding</label>
        <input
          class="form-control"
          formControlName="rounding"
          placeholder="Default"
          step="1"
        />
        <small class="form-text text-muted">
          If supplied, will be the standard number of decimal places to display
          when rendering a report.
        </small>
      </div>

      <div class="form-group">
        <label class="control-label">Percent Rounding</label>
        <input
          class="form-control"
          formControlName="percentRounding"
          placeholder="Default"
          step="1"
        />
        <small class="form-text text-muted">
          If supplied, will be the standard number of decimal places to display
          for a percentage when rendering a report.
        </small>
      </div>
    </div>
  </div>

  <div class="spacer-md"></div>

  <div class="card">
    <h2
      aria-controls="collapseCssDetail"
      class="card-header card-header-collapsible form-group-title"
      data-target="#collapseCssDetail"
      data-toggle="collapse"
      [attr.aria-expanded]="cssCollapsed"
      (click)="cssCollapsed = !cssCollapsed"
    >
      <i aria-hidden="true" class="fas fa-file-pdf text-green"></i>
      CSS/PDF Styles
      <i aria-hidden="true" class="fas fa-caret-down" *ngIf="cssCollapsed"></i>
      <i aria-hidden="true" class="fas fa-caret-up" *ngIf="!cssCollapsed"></i>
    </h2>
    <div
      aria-labelledby="collapseCssDetail"
      class="collapse show"
      id="collapseCssDetail"
    >
      <div class="card-body">
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="hasCss"
              type="checkbox"
            />
            <label class="form-check-label">Customise CSS</label>
          </div>
        </div>

        <div class="mt-4" [class.d-none]="!form.get('hasCss').value">
          <crs-css-editor
            [customCss]="customCss"
            [fontUrls]="fontUrls"
            [style]="form.value"
          ></crs-css-editor>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <h2
      aria-controls="collapseExcelDetail"
      class="card-header card-header-collapsible form-group-title"
      data-target="#collapseExcelDetail"
      data-toggle="collapse"
      [attr.aria-expanded]="excelCollapsed"
      (click)="excelCollapsed = !excelCollapsed"
    >
      <i aria-hidden="true" class="fas fa-file-excel"></i>
      Excel Styles
      <i
        aria-hidden="true"
        class="fas fa-caret-down"
        *ngIf="excelCollapsed"
      ></i>
      <i aria-hidden="true" class="fas fa-caret-up" *ngIf="!excelCollapsed"></i>
    </h2>
    <div
      aria-labelledby="collapseExcelDetail"
      class="collapse show"
      id="collapseExcelDetail"
    >
      <div class="card-body">
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="hasExcel"
              type="checkbox"
            />
            <label class="form-check-label">Customise Excel</label>
          </div>
        </div>

        <div class="mt-4" *ngIf="form.get('hasExcel').value">
          <crs-excel-style-editor
            [formGroup]="form.get('excelStyle')"
            [id]="id"
          ></crs-excel-style-editor>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <h2
      aria-controls="collapseWordDetail"
      class="card-header card-header-collapsible form-group-title"
      data-target="#collapseWordDetail"
      data-toggle="collapse"
      [attr.aria-expanded]="wordCollapsed"
      (click)="wordCollapsed = !wordCollapsed"
    >
      <i aria-hidden="true" class="fas fa-file-word"></i>
      Word Styles
      <i aria-hidden="true" class="fas fa-caret-down" *ngIf="wordCollapsed"></i>
      <i aria-hidden="true" class="fas fa-caret-up" *ngIf="!wordCollapsed"></i>
    </h2>
    <div
      aria-labelledby="collapseWordDetail"
      class="collapse show"
      id="collapseWordDetail"
    >
      <div class="card-body">
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="hasWord"
              type="checkbox"
            />
            <label class="form-check-label">Customise Word</label>
          </div>
        </div>

        <div class="mt-4" *ngIf="form.get('hasWord').value">
          <crs-word-style-editor
            [formGroup]="form.get('wordStyle')"
            [id]="id"
          ></crs-word-style-editor>
        </div>
      </div>
    </div>
  </div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu">
    <div class="btn-group">
      <button
        class="btn btn-primary"
        type="submit"
        [promiseBtn]="busy.submit"
        (click)="save(true)"
      >
        Save and Close
      </button>
      <button
        aria-expanded="false"
        aria-haspopup="true"
        class="btn btn-primary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        type="button"
      >
        <span class="sr-only">Save options</span>
      </button>
      <div class="dropdown-menu">
        <button class="dropdown-item" type="submit" (click)="save(false)">
          Save Only
        </button>
        <button class="dropdown-item" type="submit" (click)="saveAs()">
          Save As...
        </button>
      </div>
    </div>

    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>

    <button
      class="btn btn-outline-danger float-right"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
  </div>
</form>

<div class="spacer-lg"></div>
