<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li aria-current="page" class="breadcrumb-item active">Report Styles</li>
  </ol>
</nav>

<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.reportStyles"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-no-bottom">
      <button
        class="btn btn-secondary"
        title="Create Trading Account"
        type="button"
        (click)="addReportStyle()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Style
      </button>
    </div>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="reportStylesObservable | async"
        (rowClicked)="selectReportStyle($event)"
      />
    </div>
  </div>
</div>
