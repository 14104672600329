<div>
  <nav
    class="navbar navbar-expand-sm navbar-light d-flex justify-content-between"
  >
    <a class="navbar-brand" routerLink="clients">
      <img alt="Access Ledger Logo" class="navbar-brand-img" [src]="logoSrc" />
    </a>

    <ul class="navbar-nav">
      <div class="nav-item dropdown" *ngIf="session.active && session.user">
        <a
          aria-expanded="false"
          aria-haspopup="true"
          class="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          href="#"
          id="navbarDropdownMenuLink"
        >
          <img
            class="user-icon-img-lg mx-1 align-middle"
            ngxGravatar
            [email]="session.user.icon.email"
            [size]="session.user.icon.size"
            [src]="session.user.icon.source"
            [title]="session.user.displayName"
            alt="{{ session.user.displayName }}"
          />
          <span class="d-none d-sm-inline align-middle">
            {{ session.user.displayName }}
          </span>
        </a>
        <div
          aria-labelledby="navbarDropdownMenuLink"
          class="user-dropdown dropdown-menu dropdown-menu-end"
        >
          <a class="dropdown-item" routerLink="profile">Profile</a>
          <a
            class="dropdown-item"
            routerLink="admin"
            *ngIf="session.permissions.isAdmin"
          >
            Admin Centre
          </a>
          <a
            class="dropdown-item"
            routerLink="central"
            *ngIf="session.permissions.isMaster"
          >
            Central
          </a>

          <div class="dropdown sub-menu">
            <a
              aria-expanded="false"
              aria-haspopup="true"
              class="dropdown-item dropdown-toggle"
              data-bs-toggle="dropdown"
              href="#"
              id="navbarDropdownSubMenuLink"
            >
              Knowledge Base Articles
            </a>
            <div
              aria-labelledby="navbarDropdownSubMenuLink"
              class="user-dropdown dropdown-menu dropdown-menu-right"
            >
              <a
                class="dropdown-item"
                rel="noopener noreferrer"
                target="_blank"
                *ngFor="let article of articles"
                [href]="article.link"
              >
                {{ article.title }}
              </a>
            </div>
          </div>

          <a
            class="dropdown-item"
            href="https://accessgroup.my.site.com/Support/s/login/"
            rel="noopener noreferrer"
            target="_blank"
            >Contact Support</a
          >
          <a
            class="dropdown-item"
            href="https://www.theaccessgroup.com/en-au/support/handisoft-final-accounts/"
            rel="noopener noreferrer"
            target="_blank"
            >Release Notes</a
          >

          <!-- <a href="http://support.reportance.com.au" target="_blank" rel="noopener noreferrer" class="btn dropdown-item">Help and Support</a> -->
          <div class="dropdown-divider"></div>
          <button class="btn dropdown-item" type="button" (click)="logout()">
            <i aria-hidden="true" class="fas fa-power-off"></i>
            Logout
          </button>
        </div>
      </div>
      <!-- <div class="nav-item dropdown" *ngIf="!session.active || !session.user">
        <a routerLink="" class="nav-link" (click)="login()">
          Loading profile...
        </a>
      </div> -->
    </ul>
  </nav>
</div>
