import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEnAU from '@angular/common/locales/en-AU';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { OAuthModule } from 'angular-oauth2-oidc';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayModule } from '@angular/cdk/overlay';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { AuthInitService } from './services/auth-init.service';
import { NavFooterComponent } from './components/nav-footer/nav-footer.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ConfigService } from './services/config.service';
import { configFactory } from './factories/configFactory';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { InstructionalToast } from './messages/instructional.toast';
import { SystemAdminLandingPageComponent } from './components/system-admin/landing-page/admin-landing-page.component';

registerLocaleData(localeEnAU, 'en-AU');

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    OverlayModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      easeTime: 500
    }),
    NgbModule
  ],
  declarations: [NavMenuComponent, NavFooterComponent, NotFoundComponent, ErrorPageComponent, InstructionalToast, SystemAdminLandingPageComponent],
  exports: [NavMenuComponent, NavFooterComponent],
  providers: [
    AuthInitService,
    ConfigService,
    { provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true },
    { provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true }],
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

    throwIfAlreadyLoaded(parentModule, 'CoreModule');

  }
}
