<ng-container *ngIf="accounts$ | async as statefulAccounts">
  <ng-container *ngIf="statefulAccounts.state === 'ERROR'">
    <p>There was an error loading the accounts.</p>
  </ng-container>
  <ng-container *ngIf="sortedAccounts$ | async as sortedAccounts">
    <ng-select
      bindValue="accountId"
      class="transaction-account-select"
      [(ngModel)]="value"
      [appendTo]="appendTo"
      [clearable]="clearable"
      [items]="sortedAccounts"
      [loading]="statefulAccounts.state === 'PENDING'"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [searchFn]="searchAccountNameOrNumber"
      [selectOnTab]="true"
      [virtualScroll]="true"
      (change)="changed($event)"
      (search)="onSearch($event)"
    >
      <ng-container *ngIf="showSplit">
        <ng-template ng-header-tmp>
          <div
            class="d-flex justify-content-between btn-split-transaction"
            (click)="onSplitClicked()"
          >
            <strong>Split transaction</strong>
          </div>
        </ng-template>
      </ng-container>

      <ng-template let-item="item" ng-label-tmp>
        {{
          (displayByAccountCode$ | async) ? item.accountNo : item.accountName
        }}
      </ng-template>

      <ng-template let-item="item" let-search="searchTerm" ng-option-tmp>
        <div class="ng-option-tmp d-flex justify-content-between">
          <span [ngOptionHighlight]="search">{{ item.accountName }}</span>
          <span class="ps-2" [ngOptionHighlight]="search">{{
            item.accountNo
          }}</span>
        </div>
      </ng-template>
    </ng-select>
  </ng-container>
</ng-container>
