<!-- Search -->
<div class="row">
  <div class="col-sm-12">
    <div class="icon-input-wrapper flex-grow-1 mr-2">
      <i aria-hidden="true" class="fas fa-search input-icon"></i>
      <input
        aria-describedby="searchIcon"
        placeholder="Search..."
        type="search"
        [formControl]="search"
      />
    </div>
  </div>
</div>

<div style="overflow: hidden; flex-grow: 1">
  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    style="width: 100%"
    [gridOptions]="gridOptions"
    [rowData]="content"
  >
    <ag-grid-column
      field="asset.assetGroupName"
      headerName="Group"
      [enableRowGroup]="true"
      [hide]="true"
      [rowGroup]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.code"
      headerName="Code"
      [hide]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.identifier"
      headerName="Identifier"
      [hide]="true"
    ></ag-grid-column>
    <ag-grid-column
      field="asset.acquisitionDate"
      headerName="Acq Date"
      type="dateColumn"
    ></ag-grid-column>
    <ag-grid-column
      field="closingCost"
      headerName="Cost"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="privatePercent"
      headerName="Pvt"
      type="percentColumn"
    ></ag-grid-column>
    <ag-grid-column
      field="openingCarryingAmount"
      headerName="OAV"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="carryingAmountAdjustment"
      headerName="Adjustments"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="additions"
      headerName="Additions"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="disposalValue"
      headerName="Disposals"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="depreciationMethodDescription"
      headerName="T"
      [width]="60"
    ></ag-grid-column>
    <ag-grid-column
      field="rate"
      headerName="Rate"
      type="percentColumn"
    ></ag-grid-column>
    <ag-grid-column
      field="depreciation"
      headerName="Depr"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="privateDepreciation"
      headerName="Pvt"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="balancingAdjustment"
      headerName="Bal Adj"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
    <ag-grid-column
      field="closingCarryingAmount"
      headerName="CAV"
      type="dollarColumn"
      [cellRendererParams]="assetReportDollarParams"
    ></ag-grid-column>
  </ag-grid-angular>
</div>
