<div [formGroup]="formGroup">
  <div class="form-group">
    <label class="control-label">Public Key</label>
    <span class="key-input-wrapper form-control">
      <input #publicKey formControlName="publicKey" type="text" />
      <span
        class="clear"
        title="Clear"
        *ngIf="showClearButton('publicKey')"
        (click)="onClickClearInput('publicKey')"
      >
        &times;
      </span>
    </span>
  </div>
  <div class="form-group">
    <label class="control-label">Secret Key</label>
    <span class="key-input-wrapper form-control">
      <input #secretKey formControlName="secretKey" type="text" />
      <span
        class="clear"
        title="Clear"
        *ngIf="showClearButton('secretKey')"
        (click)="onClickClearInput('secretKey')"
      >
        &times;
      </span>
    </span>
  </div>
</div>
