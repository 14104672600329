import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fill'
})
export class FillPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var array = new Array(value);
    for (var i = 0; i < value; i++) {
      array[i] = i;
    }
    return array;
  }

}
