import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StandardTaxCodeService } from '../standard-tax-code.service';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { StandardTaxCode } from '../standard-tax-code';
import { StandardTaxCodesTableComponent } from '../standard-tax-codes-table/standard-tax-codes-table.component';
import { StandardAccount } from '../../headers-and-accounts/standard-account';
import { StandardAccountService } from '../../headers-and-accounts/standard-account.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { MasterBasCodeService } from '../../master-bas-codes/master-bas-code.service';
import { MasterBasCode } from '../../master-bas-codes/master-bas-code';

export enum TaxCodeFormType {
  Edit = 'edit',
  Add = 'add',
}

@Component({
  selector: 'crs-tax-code-form',
  templateUrl: './standard-tax-code-form.component.html',
})
export class StandardTaxCodeFormComponent implements OnInit {
  @Input() id: string;
  @Input() set params(value: {
    taxCode: StandardTaxCode;
    taxCodesTable: StandardTaxCodesTableComponent;
  }) {
    this.taxCodesTable = value.taxCodesTable;

    if (value.taxCode) {
      this.form.patchValue({
        taxCode: value.taxCode.taxCode,
        description: value.taxCode.description,
        rate: value.taxCode.rate,
        effective_Date: value.taxCode.effective_Date,
        postingAccountNo: value.taxCode.postingAccountNo,
        basCode: value.taxCode.basCode,
        masterTaxCodeId: value.taxCode.masterTaxCodeId,
        isCapital: value.taxCode.isCapital,
      });
    }
  }

  busy = {
    load: null,
    submit: null,
  };
  error: string = null;
  isAdd: boolean;
  isCustom: boolean = false;
  isCapitalAllowed: boolean;

  form = this.formBuilder.group({
    taxCode: [
      '',
      [
        Validators.required,
        Validators.maxLength(128),
        this.uniqueTaxCodeValidator(),
      ],
    ],
    description: ['', [Validators.required, Validators.maxLength(256)]],
    rate: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    effective_Date: [
      '2000-07-01T00:00:00',
      [Validators.required, Validators.maxLength(256)],
    ],
    postingAccountNo: [null],
    basCode: [null],
    masterTaxCodeId: [null],
    isCapital: [false],
  });

  postingAccounts: StandardAccount[];
  masterBasCodes: MasterBasCode[];
  taxCodesTable: StandardTaxCodesTableComponent;
  submitButtonStream$ = new Subject();

  isFetchingPostingAccounts$ = new BehaviorSubject(false);
  isFetchingBasCodes$ = new BehaviorSubject(false);

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private standardAccountService: StandardAccountService,
    private standardTaxCodeService: StandardTaxCodeService,
    private masterBasCodeService: MasterBasCodeService
  ) {}

  ngOnInit(): void {
    this.isAdd = this.id === TaxCodeFormType.Add;
    this.isCustom = this.form.controls['masterTaxCodeId'].value === null;

    this.fetchAllPostingAccounts();
    this.fetchAllBasCodes();
  }

  onClickClose(): void {
    this.activeModal.dismiss();
  }

  onValidSubmit(): void {
    this.submit$().subscribe(
      () => {},
      (err) => this.showError(err),
      () => this.activeModal.close()
    );
  }

  searchFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.accountNo?.toLowerCase().indexOf(term) > -1 ||
      item.accountName?.toLowerCase().indexOf(term) > -1
    );
  }

  searchBasCodesFn(term: string, item: any) {
    term = term.toLowerCase();
    return (
      item.basCode?.toLowerCase().indexOf(term) > -1 ||
      item.description?.toLowerCase().indexOf(term) > -1
    );
  }

  onChangeBasCode(event: MasterBasCode) {
    this.isCapitalAllowed = event?.isCapitalAllowed ?? false;

    if (!this.isCapitalAllowed) {
      this.form.patchValue({ isCapital: false });
    }
  }

  private submit$(): Observable<any> {
    const taxCode = new StandardTaxCode(this.form.value);
    return this.isAdd
      ? this.standardTaxCodeService.postTaxCode$(taxCode)
      : this.standardTaxCodeService.putTaxCode$(this.id, taxCode);
  }

  private fetchAllPostingAccounts(): void {
    this.isFetchingPostingAccounts$.next(true);

    this.getAccounts$()
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap((accounts: StandardAccount[]) => (this.postingAccounts = accounts)),
        finalize(() => this.isFetchingPostingAccounts$.next(false))
      )
      .subscribe();
  }

  private getAccounts$(): Observable<StandardAccount[]> {
    return this.standardAccountService
      .getAccounts$({ isChild: true, pageSize: 1000, isLightSearch: true })
      .pipe(
        map((pagedResponse) =>
          pagedResponse?.records
            .map((account) => new StandardAccount(account))
            .sort((a, b) => Number(a.accountNo) - Number(b.accountNo))
        )
      );
  }

  private fetchAllBasCodes(): void {
    this.isFetchingBasCodes$.next(true);

    this.getBasCodes$()
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap((codes: MasterBasCode[]) => {
          this.masterBasCodes = codes;

          const basCode = this.masterBasCodes.find(
            ({ basCode }) => basCode === this.form.get('basCode').value
          );
          this.isCapitalAllowed = basCode?.isCapitalAllowed ?? false;
        }),
        finalize(() => this.isFetchingBasCodes$.next(false))
      )
      .subscribe();
  }

  private getBasCodes$(): Observable<MasterBasCode[]> {
    return this.masterBasCodeService.getMasterBasCodesForTax$();
  }

  private showError(error): void {
    this.error = error;
  }

  private uniqueTaxCodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '') {
        return null;
      }

      const taxCode = new StandardTaxCode({
        id: this.id,
        taxCode: control.value,
      });

      const invalid = this.taxCodesTable.isUniqueTaxCode(taxCode) === false;
      return invalid ? { duplicateTaxCode: true } : null;
    };
  }
}
