import { getDefaultGridOptions } from 'src/app/shared';
import { DatasetService, Dataset } from '..';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  map,
  catchError,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

interface IDatasetSelectParams {
  fileId: string;
  entityId: string;
  startDate: Date;
  endDate: Date;
  excludeGroupDatasets: boolean;
  excludeIds: string[];
}

@Component({
  selector: 'crs-dataset-select-modal',
  templateUrl: './dataset-select-modal.component.html',
})
export class DatasetSelectModalComponent implements OnInit {
  @Input() id: string;
  @Input() params: IDatasetSelectParams = {
    fileId: null,
    entityId: null,
    startDate: null,
    endDate: null,
    excludeGroupDatasets: false,
    excludeIds: [],
  };

  error: string;

  search = new UntypedFormControl();

  gridOptions = getDefaultGridOptions();
  datasets$: Observable<Dataset[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private datasetService: DatasetService
  ) {}

  ngOnInit() {
    this.datasets$ = this.datasetService
      .getAll$(
        this.params.fileId,
        this.params.entityId,
        this.params.startDate,
        this.params.endDate,
        this.params.excludeGroupDatasets
      )
      .pipe(
        map(
          (datasets) =>
            datasets.filter((d) =>
              this.params.excludeIds.every((i) => i !== d.id)
            ),
          catchError((err) => {
            this.showError(err);
            return of([]);
          })
        )
      );

    this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((term) => this.gridOptions.api.setQuickFilter(term))
      )
      .subscribe();

    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.rowMultiSelectWithClick = true;
  }

  showError(error) {
    this.error = error;
  }

  select() {
    this.error = null;
    const datasets = this.gridOptions.api.getSelectedNodes().map((n) => n.data);
    if (!datasets.length) {
      this.showError('You must select at least one dataset');
    } else {
      this.activeModal.close(datasets);
    }
  }
}
