import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Observable, Subject, empty, Subscription } from 'rxjs';
import {
  tap,
  exhaustMap,
  debounceTime,
  distinctUntilChanged,
  finalize,
  catchError,
} from 'rxjs/operators';

import {
  Team,
  TeamModel,
  TeamService,
  TeamUserRole,
  TeamUser,
  TeamUserModel,
} from '../';
import {
  TeamUserComponent,
  TeamUserComponentParams,
} from '../team-user/team-user.component';
import { MessageService, ModalService, SessionService } from '../../../core';
import { getDefaultGridOptions } from '../../../shared';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'crs-team',
  templateUrl: './team.component.html',
})
export class TeamComponent implements OnInit, OnDestroy {
  @ViewChild('optionsCell', { static: true, read: TemplateRef })
  optionsCell!: TemplateRef<TeamUser>;

  id;
  isAdd: boolean;
  objectTitle = 'Team';
  busy = {
    load: null,
    submit: null,
  };
  userObservable: Observable<TeamUser[]>;
  error: string = null;

  teamUserRoles = TeamUserRole;
  gridOptions: GridOptions;
  gridApi: GridApi;

  isAdmin = false;

  interactionStream = new Subject<void>();

  users = [];
  excludeIds = [];
  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(512)]],
    office: [null],
  });
  search = new UntypedFormControl();
  subscriptions: Subscription[] = [];

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _teamService: TeamService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    sessionService: SessionService
  ) {
    this.isAdmin = sessionService.permissions.isAdmin;
  }

  private gridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  ngOnInit() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.user.id,
      columnDefs: [
        { field: 'user', headerName: 'Name', type: 'userColumn' },
        {
          field: 'role',
          headerName: 'Security',
          type: 'enumColumn',
          cellRendererParams: { enum: this.teamUserRoles },
          width: 120,
        },
        {
          field: 'isManager',
          headerName: 'Manager',
          type: 'booleanColumn',
          maxWidth: 120,
          minWidth: 120,
        },
        {
          headerName: '',
          minWidth: 90,
          width: 90,
          type: 'optionsColumn',
          cellRendererParams: {
            ngTemplate: this.optionsCell,
          },
        },
      ],
      onGridReady: (event) => this.gridReady(event),
    };

    this.subscriptions.push(
      this._route.params.subscribe((params) => {
        this.id = this._route.snapshot.paramMap.get('id');
        this.isAdd = this.id === 'add';
        this.loadTeam();
      })
    );

    this.subscriptions.push(
      this.search.valueChanges
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((search) => {
          this.gridApi.setGridOption('quickFilterText', search);
        })
    );

    this.configureInteraction();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  submit() {
    this.interactionStream.next();
  }

  private configureInteraction() {
    this.subscriptions.push(
      this.interactionStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.handleInteraction())
        )
        .subscribe(() => {
          this.close();
        })
    );
  }

  private handleInteraction(): Observable<any> {
    let observable: Observable<any>;
    var loadingStream = new Subject<void>();

    var users = [];
    this.gridApi.forEachNode((n) => users.push(new TeamUserModel(n.data)));
    const model = new TeamModel(this.form.value as Team, users);

    if (this.isAdd) {
      observable = this._teamService.post(model);
    } else {
      model.id = this.id as string;
      observable = this._teamService.put(model);
    }

    this.busy.submit = loadingStream.subscribe();
    this.subscriptions.push(this.busy.submit);

    observable = observable.pipe(
      catchError((err) => {
        this.showError(err);
        return empty();
      }),
      finalize(() => loadingStream.complete())
    );

    return observable;
  }

  private loadTeam() {
    this.busy.load = this.isAdd
      ? empty()
      : this._teamService.get(this.id).subscribe(
          (team) => {
            this.form.patchValue(team);
            this.users = team.teamUsers.map((u) => new TeamUser(u));
            this.excludeIds = this.users.map((u) => u.user.id);
          },
          (err) => this.showError(err)
        );
    if (!this.isAdd) this.subscriptions.push(this.busy.load);
  }

  addTeamUser() {
    let team = this.form.value as Team;
    team.id = this.id as string;
    this._modalService
      .openModal(
        TeamUserComponent,
        null,
        new TeamUserComponentParams(
          { team: team } as TeamUser,
          false,
          true,
          this.excludeIds
        )
      )
      .then((u) => {
        this.gridApi.applyTransaction({ add: [u] });
        this.excludeIds.push(u.user.id);
      })
      .catch(() => true);
  }

  editTeamUser(teamUser: TeamUser) {
    let team = this.form.value as Team;
    team.id = this.id as string;
    teamUser.team = team;
    this._modalService
      .openModal(
        TeamUserComponent,
        null,
        new TeamUserComponentParams(teamUser, false, true)
      )
      .then((u) => {
        this.gridApi.applyTransaction({ update: [u] });
      })
      .catch(() => true);
  }

  removeTeamUser(teamUser: TeamUser) {
    this.gridApi.applyTransaction({ remove: [teamUser] });
    const index: number = this.excludeIds.indexOf(teamUser.user.id);
    this.excludeIds.splice(index, 1);
  }

  delete() {
    if (this.isAdd || !this.isAdmin) return;
    this._modalService.confirmation(
      'Are you sure you want to delete this team? This action cannot be undone.',
      () =>
        this.subscriptions.push(
          this._teamService.delete(this.id).subscribe(() => {
            this._messageService.success('Successfully deleted team.');
            this.close();
          }, this.showError)
        ),
      true
    );
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
    this._messageService.error(error);
  }
}
