import { Directive } from '@angular/core';
import { AgGridColumn } from 'ag-grid-angular';

@Directive({
  selector: 'ag-grid-column'
})
export class AgGridColumnDirective {

  constructor(column: AgGridColumn) {
    column.minWidth = 100;
    column.headerTooltip = column.headerName;
    column.tooltipValueGetter = (params) => {
      return params.value;
    }
  }
}
