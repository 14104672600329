import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function businessNumberValidator(type: 'abn' | 'acn' | 'tfn'): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    const error: ValidationErrors = { invalidBusinessNumber: true };

    if (control.value != null && control.value.length > 0) {
      var stripped = control.value.replace(/ /g, '');
      if (type === 'abn' && stripped.length !== 11) return error;
      if (type === 'acn' && stripped.length !== 9) return error;
      if (type === 'tfn' && stripped.length !== 9) return error;

      if (type === 'abn') {
        const weightings = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
        var count: number = 0;
        for (var i = 0; i < stripped.length; i++) {
          var digit = stripped.charAt(i) as number;
          if (i === 0) digit -= 1;
          count += digit * weightings[i];
        }
        var result = count / 89;
        if (!Number.isInteger(result)) return error;
      }

      if(type == 'acn')
      {
          if(!IsValidACN(stripped))
          {
            return error;
          }
      }
    }

    return null;
  };

  // The Australian Company Number (ACN) is a nine digit number with the last digit being a check digit calculated using a modified modulus 10 calculation.
  // Validation logic is referred from https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/
  function IsValidACN(stripped)
  {
     // Apply weighting to digits 1 to 8 and sum the products.
     const weightings = [8, 7, 6, 5, 4, 3, 2, 1];
     var product: number = 0;

     for (var i = 0; i < stripped.length - 1; i++) {
      var digit = stripped.charAt(i) as number;
      product += digit * weightings[i];
    }

    // Divide by 10 to obtain remainder
    var remainder: number = product % 10;

    // Complement the remainder to 10
    var compliment: number = 10 - remainder;

    // If the complement equals 10, set it to 0.
    if(compliment === 10)
    {
      compliment = 0;
    }

    var checkSumInputDigit = stripped.charAt(8);
    if(!checkSumInputDigit) return false;

    var checkDigit: number = parseInt(checkSumInputDigit);

    // If the calculated checkDigit equals the last digit, ACN is VALID.
    if(compliment === checkDigit)
    {
      return true;
    }
    return false;
  }
}
