export class TaxSection {
  id: number;
  labelNo: string;
  name: string;
  get displayName() {
    return this.labelNo + ' ' + this.name;
  }
  totalLabelNo: string;
  totalName: string;

  constructor(data){
    Object.assign(this, data);
  }
}
