import { ModalService } from './../../../core/modals/modal.service';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, delay, finalize, tap } from 'rxjs/operators';

import { MessageService } from 'src/app/core/messages/message.service';
import { entityTypes } from 'src/app/firm';
import { Classification } from '../../ledger';
import { AccountType } from '../../ledger/account-types';
import { AccountTypeService } from './../../ledger/account-types/account-type.service';
import { StandardAccount } from './standard-account';
import {
  AddHeaderFormType,
  StandardAccountsHeaderFormComponent,
} from './standard-accounts-header-form/standard-accounts-header-form.component';
import { StandardAccountSettingService } from './standard-account-setting.service';
import { StandardAccountHeadersGridComponent } from './standard-account-headers-grid/standard-account-headers-grid.component';
import { StandardAccountService } from './standard-account.service';
import {
  AddAccountFormType,
  StandardAccountFormComponent,
} from './standard-account-form/standard-account-form.component';
import { ShouldRefresh } from './standard-accounts-table/standard-accounts-table.component';

@Component({
  selector: 'crs-headers-and-accounts',
  templateUrl: './headers-and-accounts.component.html',
  styleUrls: ['./headers-and-accounts.component.scss'],
})
export class HeadersAndAccountsComponent implements OnInit, OnDestroy {
  @ViewChild(StandardAccountHeadersGridComponent)
  standardAccountHeadersComponent: StandardAccountHeadersGridComponent;

  useHeaders: boolean;

  selectedEntityType = 1;
  entityTypes = entityTypes;

  search = '';

  classificationId = null;
  classifications = Classification;
  accountTypeId = null;
  accountTypes: AccountType[][];
  isActivated$: Observable<boolean>;
  isFetchingActivated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  shouldRefreshAccounts$: BehaviorSubject<ShouldRefresh> = new BehaviorSubject({
    refresh: false,
  });
  subscription: Subscription;
  headerAccounts: StandardAccount[];

  selectedAccountEntityType = null;
  selectedHeaderAccountId = null;

  constructor(
    private accountTypeService: AccountTypeService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private standardAccountSettingService: StandardAccountSettingService,
    private standardAccountService: StandardAccountService
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe((segments) => {
      this.useHeaders = segments[0].path === 'headers';
    });

    this.isActivated$ = this.getActivated$();

    this.accountTypeService.search$('').subscribe((data) => {
      this.accountTypes = data.records;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  onClearFilters(): void {
    this.search = '';
    this.accountTypeId = null;
    this.classificationId = null;
    this.selectedAccountEntityType = null;
    this.selectedHeaderAccountId = null;
  }

  onFetchHeaderAccounts(headerAccounts: StandardAccount[]): void {
    this.headerAccounts = headerAccounts;
  }

  onClickAddHeader(): void {
    const gridManager = this.standardAccountHeadersComponent.getGridManager();
    const context = this.standardAccountHeadersComponent.standardAccountContext;
    this.modalService
      .openModal(StandardAccountsHeaderFormComponent, AddHeaderFormType.AddNew)
      .then(async (id) => {
        const newAccount = await this.standardAccountService
          .refreshHierarchy$(context.entityType, id)
          .toPromise();

        const newStore = [...gridManager.store, ...newAccount];
        gridManager.setStore(newStore);
        gridManager.refreshAll();
        this.messageService.success('Header added successfully');
      })
      .catch(() => true);
  }

  onClickAddAccount(): void {
    this.modalService
      .openModal(StandardAccountFormComponent, AddAccountFormType.AddNew)
      .then(() => {
        this.messageService.success('Account added successfully');
        this.shouldRefreshAccounts$.next({ refresh: true });
      })
      .catch(() => true);
  }

  getActivated$(): Observable<boolean> {
    this.isFetchingActivated$.next(true);

    return this.standardAccountSettingService.isActive$().pipe(
      catchError((error) => {
        this.messageService.error(error);
        return EMPTY;
      }),
      finalize(() => this.isFetchingActivated$.next(false))
    );
  }
}
