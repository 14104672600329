export enum ReportElementTypeEnum {
  Header = 0,
  Paragraph = 1,
  Signature = 2,
  Snippet = 3,
  HorizontalRule = 4,
  FinancialTable = 5,
  PlaceHolder = 6,
  Spacer = 7,
  Table = 10,
  Image = 11,
  SectionTable = 15
}
