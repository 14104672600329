import { ComparativeTrialBalanceReportRow } from './../../../../accounting/ledger/ledgerReports/trialBalanceReportRow';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  Workpaper,
  WorkpaperCreateModel,
  WorkpaperUpdateModel,
} from 'src/app/accounting/ledger/workpapers/workpaper';
import { WorkpaperFormComponent } from 'src/app/accounting/ledger/workpapers/workpaper-form/workpaper-form.component';
import { WorkpaperService } from 'src/app/accounting/ledger/workpapers/workpaper-service';
import { MessageService, ModalService } from 'src/app/core';
import { WorkpaperStatus } from 'src/app/shared/components/accounting/workpaper-status-dropdown/workpaper-status-dropdown.component';

@Component({
  selector: 'crs-trial-balance-workpaper-details-renderer',
  template: `
    <div class="workpaper-details-container" *ngIf="!this.isFooterRow">
      <crs-workpaper-status-dropdown
        appendTo=".trial-balance-table"
        variant="ghost"
        *ngIf="!isComparative"
        [(selectedValue)]="selectedStatus"
        [removeNoWorkpaperOption]="true"
        (selectedValueChange)="onStatusChange($event)"
      ></crs-workpaper-status-dropdown>

      <div
        [class]="
          !isComparative
            ? 'workpaper-details-items-container'
            : 'comparative-workpaper-details-items-container'
        "
      >
        <crs-icon-with-badge
          [count]="documentUrlsCount"
          [disabled]="!workpaperDetails"
          [iconClass]="'fas fa-external-link-alt'"
          (click)="editWorkpaper(true, false)"
        ></crs-icon-with-badge>
        <crs-icon-with-badge
          [count]="commentsCount"
          [disabled]="!workpaperDetails"
          [iconClass]="'far fa-comment'"
          (click)="editWorkpaper(false, true)"
        ></crs-icon-with-badge>
      </div>

      <div class="add-edit-workpaper ps-2" *ngIf="!isComparative">
        <i
          aria-hidden="true"
          class="fas fa-pencil-alt fas-btn-primary-ghost"
          *ngIf="workpaperDetails"
          (click)="editWorkpaper(false, false)"
        ></i>
        <i
          aria-hidden="true"
          class="fas fa-plus inset fas-btn-primary-ghost"
          *ngIf="!workpaperDetails"
          (click)="autoAddWorkpaper()"
        ></i>
      </div>
    </div>
  `,
  styles: [
    `
      .workpaper-details-container,
      .workpaper-details-items-container,
      .comparative-workpaper-details-items-container {
        display: flex;
        gap: 0.6rem;
        align-items: center;
      }

      .workpaper-details-container {
        justify-content: flex-end;
      }

      .workpaper-details-items-container {
        border-left: 1px solid #d1d1d1;
        width: 300px;
        justify-content: center;
        padding-left: 6px;
      }
      .comparative-workpaper-details-items-container {
        width: 300px;
        justify-content: center;
        padding-left: 6px;
      }

      .add-edit-workpaper {
        border-left: 1px solid #d1d1d1;
      }
    `,
  ],
})
export class TrialBalanceWorkpaperDetailsRendererComponent
  implements ICellRendererAngularComp
{
  documentUrlsCount: number = 0;
  commentsCount: number = 0;
  workpaperDetails: Workpaper | undefined;
  selectedStatus: number = 0;
  datasetId: string;
  accountId: string;
  accountName: string;
  refreshGrid: () => void;
  isFooterRow: boolean;
  isComparative: boolean;

  constructor(
    private workpaperService: WorkpaperService,
    private messageService: MessageService,
    private modalService: ModalService
  ) {}

  agInit(params: any): void {
    this.datasetId = params.datasetId;
    this.accountId = params.getAccountId(params);
    this.accountName = params.getAccountName(params);
    this.refreshGrid = params.refreshGrid;
    this.isFooterRow = params.isFooter(params);
    this.isComparative = params.isComparative;

    if (params.value) {
      this.workpaperDetails = params.value;
      this.documentUrlsCount = this.workpaperDetails.documents.length;
      this.commentsCount = this.workpaperDetails.comments.length;
      this.selectedStatus = this.workpaperDetails.status;
    } else {
      this.selectedStatus = 0;
    }

    const comparatives: ComparativeTrialBalanceReportRow =
      params?.data?.comparatives || {};

    const comparativeWorkpapers =
      comparatives[this.datasetId]?.workpaperDetails;

    if (this.isComparative && !!comparativeWorkpapers) {
      this.workpaperDetails = comparativeWorkpapers;
      this.documentUrlsCount = this.workpaperDetails.documents.length;
      this.commentsCount = this.workpaperDetails.comments.length;
      this.selectedStatus = this.workpaperDetails.status;
    }
  }

  refresh(): boolean {
    return false;
  }

  onStatusChange(newStatus: number) {
    this.selectedStatus = newStatus;

    if (!this.workpaperDetails) {
      this.autoAddWorkpaper(newStatus);
    } else {
      this.updateWorkpaperStatus(newStatus);
    }
  }

  autoAddWorkpaper(newStatus?: number) {
    const workpaperCreateModel = new WorkpaperCreateModel({
      accountId: this.accountId,
      title: 'Account Workpaper',
      status: newStatus || WorkpaperStatus.NotStarted,
      datasetId: this.datasetId,
    });

    this.workpaperService.post$(workpaperCreateModel).subscribe((data) => {
      this.messageService.success(
        `Workpaper added for <br/> "${this.accountName}"`
      );
      this.refreshGrid();
    });
  }

  editWorkpaper(focusDocument?: boolean, focusComment?: boolean) {
    if (!this.workpaperDetails) {
      return;
    }

    if (focusDocument && this.workpaperDetails.documents.length === 1) {
      let documentUrl = this.workpaperDetails.documents[0].url;
      if (
        !documentUrl.startsWith('http://') &&
        !documentUrl.startsWith('https://')
      ) {
        documentUrl = `https://${documentUrl}`;
      }
      return window.open(documentUrl, '_blank');
    }

    this.modalService
      .openModal(WorkpaperFormComponent, this.workpaperDetails.id, {
        datasetId: this.datasetId,
        workpaper: this.workpaperDetails,
        focusDocument,
        focusComment,
      })
      .then(() => {
        this.refreshGrid();
      })
      .catch(() => true);
  }

  updateWorkpaperStatus(newStatus: number) {
    const workpaperUpdateModel = new WorkpaperUpdateModel({
      title: this.workpaperDetails.title,
      status: newStatus,
      documents: this.workpaperDetails.documents,
      comments: this.workpaperDetails.comments,
    });

    this.workpaperService
      .put$(this.workpaperDetails.id, workpaperUpdateModel)
      .subscribe(() => {
        const successMessageName = this.accountName
          ? this.accountName
          : this.workpaperDetails.title;

        this.messageService.success(
          `Workpaper status updated for <br/>"${successMessageName}"`
        );
        this.refreshGrid();
      });
  }
}
