import { AssetReportTaxAudit } from '../asset-report-tax-return/asset-report-tax-return';
import { Component, OnInit, Input } from '@angular/core';
import { getDefaultGridOptions } from 'src/app/shared';
import { AssetReportGrid } from '../asset-report-grid';

@Component({
  selector: 'crs-asset-report-tax-audit',
  templateUrl: './asset-report-tax-audit.component.html',
})
export class AssetReportTaxAuditComponent implements OnInit {
  @Input() auditContent: AssetReportTaxAudit[];

  gridOptions = getDefaultGridOptions();
  assetReportDollarParams = AssetReportGrid.dollarParams;

  constructor() {}

  ngOnInit() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      grandTotalRow: 'bottom',
      columnDefs: [
        {
          headerName: 'Group',
          field: 'asset.assetGroupName',
          rowGroup: true,
          hide: true,
          enableRowGroup: true,
        },
        {
          headerName: 'Code',
          field: 'asset.code',
        },
        {
          headerName: 'Identifier',
          field: 'asset.identifier',
          hide: true,
        },
        {
          headerName: 'Value',
          field: 'value',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
      ],
    };
    AssetReportGrid.customiseGrid(this.gridOptions);
  }
}
