import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FeedClient, FeedTestClient } from '../../common';
import { BacoBankAccountDto } from '../../interfaces';
import { Feed } from '../../interfaces/feed-interface';

@Component({
  selector: 'crs-feeds',
  templateUrl: './feeds.component.html',
  styleUrls: ['./feeds.component.scss']
})
export class FeedsComponent implements OnInit, OnDestroy {
  public feeds$ = new BehaviorSubject<Feed[]>([]);
  public bankAccounts$ = new BehaviorSubject<BacoBankAccountDto[]>(null);
  public selectedFeed: Feed;

  constructor(private readonly _feedClient: FeedClient, private readonly _feedTestClient: FeedTestClient) {
  }

  ngOnInit(): void {
    this.getFeeds();
  }

  private getFeeds() {
    this._feedClient.getFeeds()
      .subscribe(x => this.feeds$.next(x));
  }

  ngOnDestroy(): void {
  }

  public selectFeed(feed: Feed) {
    this.selectedFeed = feed;
    this._feedClient.getBankAccounts(feed.id)
      .subscribe(x => this.bankAccounts$.next(x));
  }
  
  public archiveFeed(feed: Feed) {
    this._feedClient.archiveFeed(feed.id)
      .subscribe(x => {
        this.getFeeds();
        this.bankAccounts$.next([]);
      });
  }

  public createBankAccount(feedId) {
    this._feedTestClient.addRandomAccount(feedId)
      .subscribe(x => this.selectFeed(this.selectedFeed));
  }

  public createMoreTransactions(bankId) {
    this._feedTestClient.addRandomTransactions(bankId)
      .subscribe(x => this.selectFeed(this.selectedFeed));
  }

}
