import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey('CompanyName=Corpsoft Australia_on_behalf_of_SAGE SOFTWARE AUSTRALIA PTY LTD,LicensedApplication=Final Accounts,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-013101,ExpiryDate=2_February_2022_[v2]_MTY0Mzc2MDAwMDAwMA==34eff3c691bf07f0c9fc92419f5625f5');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
