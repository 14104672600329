import { DesktopMigrationModel } from './../desktop-migration';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { catchError, tap, exhaustMap, map, finalize } from 'rxjs/operators';
import { Subject, Subscription, Observable, EMPTY } from 'rxjs';

import { DesktopMigrationService } from '../desktop-migration.service';
import { UploadedFile } from 'src/app/shared/components/file-upload/file-upload.component';
import { UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PrepareDesktopMigrationResult, EntityMapModel } from '../prepare-desktop-migration';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/core';
import { EntitySelectModalComponent } from 'src/app/firm/entities/entity-select-modal/entity-select-modal.component';
import { Entity } from 'src/app/firm';

enum SubmitAction {
  Import,
  Prepare
}

@Component({
  selector: 'crs-desktop-migration',
  templateUrl: './desktop-migration.component.html',
  styleUrls: ['./desktop-migration.component.scss']
})
export class DesktopMigrationComponent implements OnInit, OnDestroy {

  clientId: string;
  error = null;
  busy = {
    import: null,
    prepare: null
  };

  prepareResult: PrepareDesktopMigrationResult;
  component = this;

  form = this._formBuilder.group({
    partner: [null],
    entityMap: this._formBuilder.group({
      importEntity: [null, Validators.required],
      destinationEntity: [null]
    })
  });
  partnerRequired: boolean;
  divisionsRequired: boolean;

  get entityMap() {
    return this.form.get('entityMap') as UntypedFormGroup;
  }

  importStream = new Subject<SubmitAction>();
  subscriptions: Subscription[] = [];
  uploadedFile: UploadedFile;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _modalService: ModalService,
    private readonly _migrationService: DesktopMigrationService) { }

  ngOnInit() {

    this.subscriptions.push(this._route.paramMap.subscribe(m => {
      this.clientId = m.get('clientId');
    }));

    this.subscriptions.push(this.form.get('entityMap').valueChanges.pipe(
      tap(v => {
        if (!v.destinationEntity) {
          this.partnerRequired = true;
        }
        this.form.get('partner').setValidators(this.partnerRequired ? Validators.required : null);
      })
    ).subscribe());

    this.subscriptions.push(
      this.importStream
        .pipe(
          tap(() => this.error = null),
          exhaustMap(action => this.importObservable(action))
        )
        .subscribe(action => {
          if (action === SubmitAction.Import) this.close();
        }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  parsePreparationResult(result: PrepareDesktopMigrationResult) {
    this.prepareResult = result;
    this.divisionsRequired = result.hasDivisions;
    this.entityMap.reset();

    this.entityMap.patchValue(result.entityMap)
  }

  clearPreparationResult() {
    this.prepareResult = null;
    this.divisionsRequired = null;
  }

  onFileUpload(uploadedFiles: UploadedFile[]) {
    this.error = null;
    this.uploadedFile = uploadedFiles ? uploadedFiles.length ? uploadedFiles[0] : null : null;
    this.clearPreparationResult();
  }

  private importObservable(action: SubmitAction): Observable<any> {
    const loadingStream = new Subject();

    let observable: Observable<any>;

    if (action === SubmitAction.Prepare) {
      this.busy.prepare = loadingStream.subscribe();
      observable = this._migrationService.prepareImport(this.clientId, this.uploadedFile)
        .pipe(
          tap(r => this.parsePreparationResult(r))
        );
    } else {
      this.busy.import = loadingStream.subscribe();
      const model = this.form.value as DesktopMigrationModel;
      model.clientId = this.clientId;
      model.partnerId = this.form.get('partner').value ? this.form.get('partner').value.id : null;
      model.entityMap = new EntityMapModel(this.entityMap.value);
      model.processorFilePath = this.prepareResult.processorFilePath;
      model.fileName = this.uploadedFile.fileName;
      observable = this._migrationService.import(model).pipe(
        tap(fileId => this._router.navigate(['/accounting', fileId]))
      );
    }

    return observable.pipe(
      map(r => action),
      catchError(err => {
        this.error = err;
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  prepare() {
    this.error = null;
    if (!this.uploadedFile) {
      this.error = 'You must upload a HandiLedger file to import.';
      return;
    }
    this.importStream.next(SubmitAction.Prepare);
  }

  addEntity(control: UntypedFormControl) {
    this._modalService.openModal(EntitySelectModalComponent, null, { clientId: this.clientId }).then(result => {
      const entity = {
        id: result.id,
        legalName: result.name,
        code: result.code
      } as Entity;
      control.patchValue(entity);
    }).catch(() => true);
  }

  clearEntity(control: UntypedFormControl) {
    control.patchValue(null);
  }

  submit() {
    this.error = null;
    this.importStream.next(SubmitAction.Import);
  }

  close() {
    this._router.navigate(['/clients', this.clientId]);
  }
}
