<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-10 col-lg-8">
        <div class="form-group">
          <label class="control-label">Code</label>
          <input class="form-control" formControlName="code" type="text" />
        </div>

        <div class="form-group">
          <label class="control-label">Name</label>
          <input class="form-control" formControlName="name" type="text" />
        </div>
      </div>
    </div>

    <!-- <div class="btn-menu">
      <button class="btn btn-primary" type="button" (click)="showFromSourceForm = !showFromSourceForm"
              [attr.aria-expanded]="!isCollapsed" aria-controls="linkFromSourceForm">Link to Source Division</button>
      <a [routerLink]="" class="fas-btn" placement="right" ngbPopover="Link jobs or tracking categories in the client accounting software to this division." popoverTitle="Information">
        <i class="fas fa-info-circle" aria-hidden="true"></i>
      </a>
    </div> -->

    <div
      class="card collapse"
      id="linkFromSourceForm"
      [ngbCollapse]="!showFromSourceForm"
    >
      <div class="card-header">Select Source Division</div>
      <div class="card-body">
        <crs-source-division-create
          [selectSource]="true"
          (completed)="addSourceDivision($event); showFromSourceForm = false"
        ></crs-source-division-create>
      </div>
    </div>

    <ag-grid-angular
      #agGrid
      class="ag-theme-material"
      style="width: 100%"
      [class.d-none]="!sourceDivisions.length"
      [gridOptions]="gridOptions"
      [rowData]="sourceDivisions"
      (gridReady)="onGridReady($event)"
    >
      <ag-grid-column
        field="code"
        headerName="Code"
        [minWidth]="100"
        [width]="100"
      ></ag-grid-column>
      <ag-grid-column
        field="name"
        headerName="Name"
        [minWidth]="200"
        [width]="200"
      ></ag-grid-column>
      <ag-grid-column
        cellRenderer="sourceRenderer"
        field="source"
        headerName="Source"
        [minWidth]="200"
        [width]="200"
      ></ag-grid-column>
      <ag-grid-column
        pinned="none"
        type="optionsColumn"
        [cellRendererParams]="{ ngTemplate: optionsCell }"
      ></ag-grid-column>
    </ag-grid-angular>

    <ng-template #optionsCell let-row>
      <div class="btn-mini-menu">
        <button
          class="btn-sm fas-btn-ghost"
          type="button"
          (click)="removeSourceDivision(row)"
        >
          <i aria-hidden="true" class="fas fa-trash"></i>
        </button>
      </div>
    </ng-template>

    <crs-form-error [error]="error"></crs-form-error>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-delete"
      title="Delete"
      type="button"
      *ngIf="!isAdd"
      [promiseBtn]="busy.delete"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>
</form>
