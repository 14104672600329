import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'businessNumber'
})
export class BusinessNumberPipe implements PipeTransform {

  transform(value: string, type: 'abn' | 'acn' | 'tfn'): string {

    if (value == null) return value;
    var stripped = value.replace(/ /g, '');

    if (type === 'abn' && stripped.length === 11) {
      return stripped.substring(0, 2) + ' ' + stripped.substring(2, 5) + ' ' + stripped.substring(5, 8) + ' ' + stripped.substring(8, 11);
    }

    if (type === 'acn' && stripped.length === 9) {
      return stripped.substring(0, 3) + ' ' + stripped.substring(3, 6) + ' ' + stripped.substring(6, 9);
    }

    if (type === 'tfn' && stripped.length === 9) {
      return stripped.substring(0, 3) + ' ' + stripped.substring(3, 6) + ' ' + stripped.substring(6, 9);
    }

    return value;
  }

  parse(value: string, type: 'abn' | 'acn' | 'tfn'): string {
    if (value == null) return value;

    var stripped = value.replace(/ /g, '');

    if (type === 'abn' && stripped.length === 11) return stripped;
    if (type === 'acn' && stripped.length === 9) return stripped;
    if (type === 'tfn' && stripped.length === 9) return stripped;

    return value;
  }

}
