import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { ReportAuditorModel } from './report-auditor-element';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportAuditorComponentService {
  readonly base = 'api/accounting/reports/auditor-report';

  constructor(private readonly _apiService: ApiService) { }

  get(practiceId: any) {
    return this._apiService.get(this.base + '/' + practiceId);
  }
  put(auditor: ReportAuditorModel) : Observable<any> {
    var id = auditor.practiceId;
    return this._apiService.put(this.base + '/' + id, auditor);
  }
}
