<div class="modal-header">
  <h4 class="modal-title">
    <span *ngIf="!hideTeam">Team </span> <span *ngIf="!hideUser">User </span> Details
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

  <div class="modal-body" [ngBusy]="[busy.load]">

    <div class="form-group" [class.required]="!readonlyUser" *ngIf="!hideUser">
      <label class="control-label">User</label>
      <crs-user-select formControlName="user" [readonly]="readonlyUser" [excludeIds]="excludeIds"></crs-user-select>
    </div>

    <div class="form-group" [class.required]="!readonlyTeam" *ngIf="!hideTeam">
      <label class="control-label">Team</label>
      <crs-team-select [clearable]="false" formControlName="team" [readonly]="readonlyTeam"></crs-team-select>
    </div>

    <div class="row">
      <div class="col-sm-8 col-md-5 col-lg-4">

        <div class="form-group required">
          <label class="control-label">Security</label>
          <ng-select [items]="roles | enumToArray" bindValue="value" bindLabel="name" formControlName="role" [clearable]="false"></ng-select>
        </div>

      </div>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="isManager">
        <label class="form-check-label">Team Manager</label>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close click')">Cancel</button>
  </div>

</form>
