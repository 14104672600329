import { AssetImportResult } from './asset-import-result';
import { AssetsImportService } from './assets-import.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, EMPTY, Observable, concat } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AssetsContextService } from '../assets-context.service';
import { AssetsContext } from '../assets-context';
import {
  tap,
  exhaustMap,
  catchError,
  finalize,
  map,
  distinctUntilChanged,
} from 'rxjs/operators';
import { UploadedFile } from 'src/app/shared/components/file-upload/file-upload.component';

export enum SubmitAction {
  Prepare,
  Import,
}

@Component({
  selector: 'crs-assets-import',
  templateUrl: './assets-import.component.html',
  styleUrls: ['./assets-import.component.scss'],
})
export class AssetsImportComponent implements OnInit, OnDestroy {
  error = null;
  busy = {
    import: null,
    test: null,
  };

  form = this._formBuilder.group({
    depreciationYear: [null, Validators.required],
    copyTaxationToAccounting: [false],
  });

  assetsContext: AssetsContext;
  component = this;

  importStream = new Subject<SubmitAction>();
  subscriptions: Subscription[] = [];
  uploadedFiles: UploadedFile[] = [];

  prepareResult: AssetImportResult;

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _activeModal: NgbActiveModal,
    private readonly _assetImportService: AssetsImportService,
    private readonly _assetsContextService: AssetsContextService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this._assetsContextService.contextValid$.subscribe(
        (c) => (this.assetsContext = c),
        (err) => (this.error = 'Error getting depreciation years. ' + err)
      )
    );

    this.subscriptions.push(
      this.importStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap((action) => this.importObservable(action))
        )
        .subscribe((action) => {
          if (action === SubmitAction.Import) this._activeModal.close();
        })
    );

    this.subscriptions.push(
      concat(
        this.form
          .get('depreciationYear')
          .valueChanges.pipe(distinctUntilChanged()),
        this.form
          .get('copyTaxationToAccounting')
          .valueChanges.pipe(distinctUntilChanged())
      )
        .pipe(
          tap(() => (this.error = null)),
          tap(() => (this.prepareResult = null))
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  onFileUpload(uploadedFiles: UploadedFile[]) {
    this.error = null;
    this.uploadedFiles = uploadedFiles;
    this.prepareResult = null;
  }

  private importObservable(action: SubmitAction): Observable<any> {
    const depreciationYear = this.form.value.depreciationYear as number;
    const copyToAccounting = this.form.value
      .copyTaxationToAccounting as boolean;

    const loadingStream = new Subject<void>();
    this.busy.import = loadingStream.subscribe();

    let observable: Observable<any>;

    if (action === SubmitAction.Prepare) {
      observable = this._assetImportService
        .prepareImport(
          this.assetsContext,
          depreciationYear,
          copyToAccounting,
          this.uploadedFiles[0]
        )
        .pipe(tap((r) => (this.prepareResult = r)));
    } else {
      observable = this._assetImportService.import(
        this.assetsContext,
        depreciationYear,
        copyToAccounting,
        this.prepareResult.processorFilePath
      );
    }

    return observable.pipe(
      map((r) => action),
      catchError((err) => {
        this.error = err;
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  submit() {
    this.error = null;
    if (!this.uploadedFiles.length) {
      this.error = 'You must upload an Excel file to import.';
      return;
    }
    this.importStream.next(
      this.prepareResult ? SubmitAction.Import : SubmitAction.Prepare
    );
  }

  cancel() {
    this._activeModal.dismiss();
  }
}
