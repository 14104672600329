import { Component, Input } from '@angular/core';

@Component({
  selector: 'crs-truncated-name',
  templateUrl: './truncated-name.component.html',
  styleUrls: ['./truncated-name.component.scss'],
})
export class TruncatedNameComponent {
  @Input() name: string = '';
  @Input() length: number = 20;
  @Input() hideTooltip?: boolean = false;

  get showTooltip() {
    return !this.hideTooltip && this.name?.length > this.length;
  }

  get truncatedName() {
    return this.name?.length > this.length
      ? this.name.slice(0, this.length) + '..'
      : this.name;
  }
}
