  <nav aria-label="Navigate Result Pages" class="nav-pagination" *ngIf="pagedResponse">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link page-text" aria-label="Total Records">
        <span *ngIf="pagedResponse.recordsCount == 0">No records</span>
        <span *ngIf="pagedResponse.recordsCount == 1"><strong>1</strong> record </span>
        <span *ngIf="pagedResponse.recordsCount > 1">
          <strong>{{pagedResponse.recordsCount}}</strong> records
        </span>
      </a>
    </li>
    <li class="page-item">
      <a class="page-link page-text">
        <span class="d-none d-md-inline">Showing </span> <strong>{{pagedResponse.pageSize}}</strong>
        <!--<select ng-model="pagedResponse.pageSize" ng-change="changePageSize(pagedResponse.pageSize)" ng-options="o as o for o in pageSizes track by o"></select>-->
        records per page
      </a>
    </li>
  </ul>
</nav>

<nav aria-label="Navigate Result Pages" class="nav-pagination" *ngIf="pagedResponse">
  <ul class="pagination">
    <ng-container *ngIf="pagesList[0] != 1">
      <li class="page-item">
        <a class="page-link" (click)="goToPage(1, pagedResponse.pageSize)" aria-label="Start">
          <span aria-hidden="true">&laquo;</span>
          <span class="d-none d-md-inline"> Start</span>
        </a>
      </li>
    </ng-container>
    <ng-container *ngIf="pagedResponse.pages > 1 && pagedResponse.page != 1">
      <li class="page-item">
        <a class="page-link" (click)="goToPage(pagedResponse.page - 1, pagedResponse.pageSize)"aria-label="Previous">
          <span aria-hidden="true">&lsaquo;</span>
          <span class="d-none d-md-inline"> Previous</span>
        </a>
      </li>
    </ng-container>
    <ng-container *ngIf="pagedResponse.pages > 1">
      <li class="page-item" [ngClass]="{'active': p == pagedResponse.page}" *ngFor="let p of pagesList">
        <a class="page-link" (click)="goToPage(p, pagedResponse.pageSize)">
          {{p}}
        </a>
      </li>
    </ng-container>
    <li class="page-item" *ngIf="pagedResponse.page < pagedResponse.pages">
      <a class="page-link" (click)="goToPage(pagedResponse.page + 1, pagedResponse.pageSize)" aria-label="Next">
        <span class="d-none d-md-inline">Next</span>
        <span aria-hidden="true"> &rsaquo;</span>
      </a>
    </li>
    <li class="page-item" *ngIf="pagedResponse.pages > pagedResponse.page + 2 && pagedResponse.pages > 5">
      <a class="page-link" (click)="goToPage(pagedResponse.pages, pagedResponse.pageSize)" aria-label="End">
        <span class="d-none d-md-inline">End</span>
        <span aria-hidden="true"> &raquo;</span>
      </a>
    </li>
  </ul>
</nav>
