<div [formGroup]="actionFormGroup" class="wrapper-actions" *ngIf="toolsState$| async as toolsState">
  <div *ngIf="!isSectionTable" class="action-group">
    <!--  Temporarily hidden for future feature implementation-->
    <!--    <button type="button" [disabled]="!(selectedElementType$ | async)" class="button-tool"-->
    <!--            [class.active]="toolsState.textUnderline" (click)="textUnderLine(!toolsState.textUnderline)">-->
    <!--      <mat-icon> format_color_text</mat-icon>-->
    <!--    </button>-->
    <!--    <button type="button" [disabled]="!(selectedElementType$ | async)" class="button-tool"-->
    <!--            [class.active]="toolsState.textBold" (click)="textBold(!toolsState.textBold)">-->
    <!--      <mat-icon> format_bold</mat-icon>-->
    <!--    </button>-->
    <!--    <button type="button" [disabled]="!(selectedElementType$ | async)" class="button-tool active"-->
    <!--            [class.active]="toolsState.textItalic" (click)="textItalic(!toolsState.textItalic)">-->
    <!--      <mat-icon> format_italic</mat-icon>-->
    <!--    </button>-->
    <button type="button" [disabled]="!(selectedElementType$ | async)" class="button-tool"
            [class.active]="toolsState.alignment == alignments.Left" (click)="align(alignments.Left)" tabindex="-1">
      <mat-icon title="align left"> format_align_left</mat-icon>
    </button>
    <button type="button" [disabled]="!(selectedElementType$ | async)" class="button-tool"
            [class.active]="toolsState.alignment == alignments.Center" (click)="align(alignments.Center)" tabindex="-1">
      <mat-icon title="align center"> format_align_center</mat-icon>
    </button>
    <button type="button" [disabled]="!(selectedElementType$ | async)" class="button-tool"
            [class.active]="toolsState.alignment == alignments.Right" (click)="align(alignments.Right)" tabindex="-1">
      <mat-icon title="align right"> format_align_right</mat-icon>
    </button>
    <!--  Temporarily hidden for future feature implementation-->
    <!--    <button type="button" [disabled]="!(selectedElementType$ | async)" class="button-tool"-->
    <!--            [class.active]="toolsState.alignment == alignments.Justify" (click)="align(alignments.Justify)">-->
    <!--      <mat-icon title="align justify"> format_align_justify</mat-icon>-->
    <!--    </button>-->
  </div>
  <!--  Temporarily hidden for future feature implementation-->
  <!--  <div class="splitter"></div>-->
  <!--  <div class="action-group">-->
  <!--    <button type="button" class="button-tool">-->
  <!--      <mat-icon>format_list_numbered</mat-icon>-->
  <!--    </button>-->
  <!--    <button type="button" class="button-tool">-->
  <!--      <mat-icon disabled> format_list_bulleted</mat-icon>-->
  <!--    </button>-->
  <!--  </div>-->
  <div *ngIf="!isSectionTable" class="splitter"></div>
  <div class="action-group">
    <button type="button" [disabled]="!(enableAddAction$ | async)" (click)="onAdd()" class="button-tool">
      <mat-icon title="Add element">add</mat-icon>
    </button>
    <button type="button" [disabled]="!(enableRemoveAction$ | async)" (click)="onRemove()" class="button-tool">
      <mat-icon title="Remove element">delete</mat-icon>
    </button>
    <!--  Temporarily hidden for future feature implementation-->
    <!--    <button type="button" class="button-tool">-->
    <!--      <mat-icon (click)="getBack()">replay</mat-icon>-->
    <!--    </button>-->

    <!--    <button type="button" class="button-tool">-->
    <!--      <mat-icon (click)="getForward()">refresh</mat-icon>-->
    <!--    </button>-->
  </div>
  <div *ngIf="!isSectionTable" class="splitter"></div>
  <div *ngIf="!isSectionTable" class="action-group">
    <button [disabled]="!(selectedElementType$ | async)" type="button" (click)="toggleSubMenu()"
            class="button-tool" tabindex="-1">
      <mat-icon>settings</mat-icon>
    </button>
    <div class="sub-menu-wrapper" *ngIf="showSubMenu" [ngSwitch]="selectedElementType$ | async">
      <ng-container [ngTemplateOutlet]="rowActions" *ngSwitchCase="tableElements.Row"></ng-container>
      <ng-container [ngTemplateOutlet]="columnActions" *ngSwitchCase="tableElements.Column"></ng-container>
      <ng-container [ngTemplateOutlet]="cellActions" *ngSwitchCase="tableElements.Cell"></ng-container>
    </div>
  </div>
</div>

<!--row menu-->
<ng-template #rowActions>
  <div class="wrapper-actions">
    <div class="action-group">
      <div class="element-wrapper">
        <div class="title">Row type</div>
        <ng-select title="Row" class="row-type"
                   [items]="tableRowTypes"
                   bindValue="value" bindLabel="name"
                   (change)="onSelectedRowType()"
                   [formControl]="actionFormGroup.get('selectedRowType')"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>

    <ng-container
      *ngIf="{
        enableLevelAction: enableLevelAction$ | async,
        enableStyleAction: enableStyleAction$ | async,
        enableAutoColumnHeader: enableAutoColumnHeader$ | async
      } as condition">
      <ng-container *ngIf="condition.enableLevelAction || condition.enableStyleAction || condition.enableAutoColumnHeader">
        <div class="splitter"></div>
        <div class="action-group">
          <div class="element-wrapper" *ngIf="condition.enableLevelAction">
            <div class="title">Level</div>
            <input type="number" (input)="onSelectRowLevel()" class="form-control row-level"
                   [formControl]="actionFormGroup.get('rowLevel')"/>
          </div>

          <div class="element-wrapper" *ngIf="condition.enableStyleAction">
            <div class="title">Style</div>
            <ng-select class="row-style"
                       [items]="styles | enumToArray"
                       bindValue="value"
                       bindLabel="name"
                       [formControl]="actionFormGroup.get('rowStyle')"
                       (change)="onSelectedRowStyle()"
                       [clearable]="false"></ng-select>
          </div>

          <div class="element-wrapper" *ngIf="condition.enableAutoColumnHeader">
            <div class="title">Auto-Column Header?</div>
            <input type="checkbox" (change)="onToggleAutoColumnHeader('enableAutoColumnHeader')" class="auto-column-header-control"
                [formControl]="actionFormGroup.get('enableAutoColumnHeader')"/>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!--column menu-->
<ng-template #columnActions>
  <div class="wrapper-actions">
    <div class="action-group">
      <div class="element-wrapper">
        <div class="title">Column type</div>
        <ng-select title="Column type" class="data-type"
                   [items]="columnValueTypes" bindValue="value" bindLabel="name"
                   [formControl]="actionFormGroup.get('selectedColumnType')"
                   (change)="onSetValueType('selectedColumnType')"
                   [clearable]="false">
        </ng-select>
      </div>
    </div>

    <ng-container *ngIf="enableWidthAction()">
      <div class="splitter"></div>
      <div class="action-group">
        <div class="element-wrapper">
          <div class="title">Width</div>
          <ng-select
            [items]="sizeX | enumToArray"
            class="sizeXs"
            bindValue="value"
            bindLabel="name"
            (change)="onSelectedSizeX('columnSizeX')"
            [formControl]="actionFormGroup.get('columnSizeX')"
            [clearable]="false">
          </ng-select>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="enableFormattingAction$ | async">
      <div class="splitter"></div>
      <div class="action-group">
        <!--  Temporarily hidden for future feature implementation-->
        <!--      <div class="element-wrapper">-->
        <!--        <div class="title">Rounding</div>-->
        <!--        <input type="number"-->
        <!--               (input)="onSelectRounding('columnRounding')"-->
        <!--               [formControl]="actionFormGroup.get('columnRounding')"-->
        <!--               class="form-control rounding-control"/>-->
        <!--      </div>-->

        <div class="element-wrapper">
          <div class="title">Formatting</div>
          <ng-select class="formatting-control"
                     [formControl]="actionFormGroup.get('columnFormatting')"
                     [items]="numberFormats"
                     bindValue="value"
                     bindLabel="name"
                     (change)="onSelectFormatting('columnFormatting')"
                     [clearable]="false">
          </ng-select>
        </div>
      </div>
    </ng-container>



    <!--  Temporarily hidden for future feature implementation-->
    <!--    <div class="splitter"></div>-->
    <!--    <div class="action-group">-->
    <!--      <ng-container *ngTemplateOutlet="storageButton"></ng-container>-->
    <!--    </div>-->
  </div>
</ng-template>

<!--cell menu-->
<ng-template #cellActions>
  <div class="wrapper-actions">
    <div class="action-group">
      <div class="element-wrapper">
        <div class="title">Type</div>
        <ng-select title="Type" class="data-type"
                   [items]="columnValueTypes" bindValue="value" bindLabel="name"
                   [formControl]="actionFormGroup.get('selectedCellType')"
                   (change)="onSetValueType('selectedCellType')"
                   [clearable]="false"></ng-select>
      </div>
    </div>

    <ng-container *ngIf="enableFormattingAction$ | async">
      <div class="splitter"></div>

      <div class="action-group">
        <!--  Temporarily hidden for future feature implementation-->
        <!--      <div class="element-wrapper">-->
        <!--        <div class="title">Rounding</div>-->
        <!--        <input type="number" (input)="onSelectRounding('cellRounding')"-->
        <!--               [formControl]="actionFormGroup.get('cellRounding')"-->
        <!--               class="form-control rounding-control"/>-->
        <!--      </div>-->

        <div class="element-wrapper">
          <div class="title">Formatting</div>
          <ng-select class="formatting-control"
                     [formControl]="actionFormGroup.get('cellFormatting')"
                     [items]="numberFormats"
                     bindValue="value"
                     bindLabel="name"
                     (change)="onSelectFormatting('cellFormatting')"
                     [clearable]="false"></ng-select>
        </div>
      </div>
    </ng-container>

    <!--  Temporarily hidden for future feature implementation-->
    <!--    <div class="splitter"></div>-->
    <!--    <div class="action-group">-->
    <!--      <ng-container *ngTemplateOutlet="storageButton"></ng-container>-->
    <!--    </div>-->

  </div>
</ng-template>

<!--  Temporarily hidden for future feature implementation-->
<!--<ng-template #storageButton>-->
<!--  <button type="button" class="button-tool">-->
<!--    <mat-icon>storage</mat-icon>-->
<!--  </button>-->
<!--</ng-template>-->


