import { IConnectionStatus } from './integration-ConnectionStatus.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService, MessageService } from '../../core';

@Injectable()
export class IntegrationServerDatasource {
  constructor(
    private apiService: ApiService,
    private messageService: MessageService
  ) {}

  public getStatus(): Observable<IConnectionStatus> {
    return this.apiService
      .get<IConnectionStatus>(`api/configuration/integration-server/status`)
      .pipe(
        tap((res) => {
          if (res.isConnected && !res.isValid) {
            this._instructionalError(
              'Integration Server connection needs to be reauthorised. Please contact your Administrator or Customer Support to assist.',
              'Reauthorisation Required'
            );
          }
        }),
        map(
          (res): IConnectionStatus => ({
            isConnected: res.isConnected,
            isValid: res.isValid,
            userName: res.userName,
          })
        ),
        catchError((e) => {
          this._showError('Unable to obtain IS connection status');

          return of({
            isConnected: false,
            isValid: false,
          });
        })
      );
  }

  public revokeISConnection(): Observable<boolean> {
    return this.apiService
      .delete(`api/configuration/integration-server/revoke`)
      .pipe(
        map((res): boolean => true),
        catchError((e) => {
          this._showError(e);
          return of(false);
        })
      );
  }

  public buildLoginUri(): Observable<{ url: string; state: string }> {
    return this.apiService.get<any>(
      `api/configuration/integration-server/auth-url`
    );
  }

  public processCallbackUri(callbackUrl: string, state: string) {
    return this.apiService.post(
      'api/configuration/integration-server/callback/process',
      { callbackUrl, state }
    );
  }

  private _showError(err) {
    this.messageService.error(err);
  }

  private _instructionalError(err, title: string) {
    this.messageService.instructionalError(err, title);
  }
}
