import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BacoTransactionCommentManageService, BacoTransactionCommentService } from '../services';
import { EMPTY, fromEvent, merge, Observable, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MessageService } from '../../../../../../core';

@Component({
  selector: 'crs-transaction-comment-editor',
  templateUrl: './transaction-comment-editor.component.html',
  styleUrls: [ './transaction-comment-editor.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionCommentEditorComponent implements OnInit, OnDestroy {

  @ViewChild('text') public textArea: ElementRef<HTMLTextAreaElement>;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public readonly _commentService: BacoTransactionCommentService,
              private readonly _commentManagerService: BacoTransactionCommentManageService,
              private readonly _messageService: MessageService) {
  }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    merge(this._commentManagerService.submitComment$, this.userMessageCompleted$())
      .pipe(
        switchMap((message) => {
          return this.addComment$(message);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private addComment$(message) {
    if (!message?.trim()?.length) {
      return EMPTY;
    }
    this.textArea.nativeElement.value = null;
    return this._commentService.create(message)
      .pipe(
        catchError(err => {
          this.textArea.nativeElement.value = message;
          this._messageService.error(err);
          return EMPTY;
        }),
      );
  }

  private userMessageCompleted$(): Observable<string> {
    return fromEvent(this.textArea.nativeElement, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) => {
          const text = (<HTMLInputElement>event.target).value;
          return event.key === 'Enter' && !event.shiftKey && !!text?.trim()?.length;
        }),
        map((event: KeyboardEvent) => (<HTMLTextAreaElement>event.target).value));
  }
}
