<div class="modal-header">
  <h4 class="modal-title">
    Create New File
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

  <div class="modal-body" [ngBusy]="[busy.load]">

    <div class="alert alert-info">
      Please supply a name for the new accounting file. Please note, it is unusual to have more than one Access Ledger file for an entity - this would usually be reserved for cases where you want to experiment with copies of data, or want to arrange an alternative chart of accounts.
    </div>

    <div class="form-group required">
      <label class="control-label">Name</label>
      <input type="text" class="form-control" formControlName="name" />
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Create</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close click')">Cancel</button>
  </div>

</form>
