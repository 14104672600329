import {
  ColDef,
  GridOptions,
  RowNode,
  SuppressKeyboardEventParams,
} from 'ag-grid-community';
import { FilterActionEnum } from './filter-action.enum';
import { BACO_TRANSACTION_RENDERS } from './transaction.renders';
import { TransactionExternalFilter } from './transaction-external.filter';
import { getDefaultGridOptions } from 'src/app/shared';
import { TemplateRef } from '@angular/core';
import {
  TransactionAccountRendererComponent,
  TransactionTaxRendererComponent,
} from 'src/app/baco/ag-grid/renders';
import {
  TransactionDescriptionEditorComponent,
  TransactionAccountEditorComponent,
  TransactionTaxEditorComponent,
} from 'src/app/baco/ag-grid/editors';
import {
  getTransactionAccountSelectionsIdentifier,
  getTransactionTaxSelectionsIdentifier,
} from 'src/app/baco/interfaces';

export class TransactionGrid {
  public renderers = BACO_TRANSACTION_RENDERS;

  public gridOptions: GridOptions = {
    ...getDefaultGridOptions(),
    getRowNodeId: (transaction) => transaction.id,
    enableRangeSelection: false,
    suppressCellSelection: false,
    frameworkComponents: this.renderers,
    groupIncludeTotalFooter: false,
    undoRedoCellEditing: true,
    animateRows: true,
    isExternalFilterPresent: () =>
      this.externalFilter.isExternalFilterPresent(),
    doesExternalFilterPass: (node: RowNode) =>
      this.externalFilter.doesExternalFilterPass(node),
    rowClassRules: {
      'row-disabled': (params) => params.data.locked,
    },
    isRowSelectable: (node: RowNode) => {
      return !node.data.locked;
    },
    enableCellChangeFlash: true,
    pagination: true,
    paginationPageSize: 12,
    immutableData: false,
    rowHeight: 60,
  };

  public externalFilter = new TransactionExternalFilter();

  public get gridApi() {
    return this.gridOptions.api;
  }

  constructor(stateCell: TemplateRef<any>, optionsCell: TemplateRef<any>) {
    const columnDefs: ColDef[] = [
      {
        headerName: 'Code status',
        colId: 'stateCell',
        minWidth: 120,
        maxWidth: 130,
        type: 'optionsColumn',
        headerClass: '',
        cellRendererParams: {
          ngTemplate: stateCell,
        },
        valueGetter: (params) =>
          `${params.data.loading}_${params.data.codedBy}_${params.data.locked}`,
        suppressNavigable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'end',
        },
      },
      {
        headerName: 'Date & time',
        field: 'transactionLocalDate',
        maxWidth: 130,
        type: 'dateColumn',
        suppressNavigable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
        },
      },
      {
        headerName: 'Ref',
        field: 'reference',
        maxWidth: 110,
        suppressNavigable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
        },
      },
      {
        headerName: 'Type',
        field: 'type',
        maxWidth: 120,
        suppressNavigable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        valueGetter: (params) =>
          params.data.description ?? params.data.originalDescription,
        editable: (params) => !params.data.locked,
        suppressNavigable: (params) => params.data.locked,
        cellEditorFramework: TransactionDescriptionEditorComponent,
        flex: 1,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
        },
      },
      {
        headerName: 'Money out',
        field: 'moneyOut',
        type: 'dollarColumn',
        cellRendererParams: { prefix: '$' },
        width: 110,
        suppressNavigable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'end',
          textAlign: 'center',
        },
      },
      {
        headerName: 'Money in',
        field: 'moneyIn',
        type: 'dollarColumn',
        cellRendererParams: { prefix: '$' },
        width: 110,
        suppressNavigable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'end',
          textAlign: 'center',
        },
      },
      {
        headerName: 'Tax',
        field: 'tax',
        type: 'dollarColumn',
        cellRendererParams: { prefix: '$' },
        editable: (params) =>
          !params.data.locked && params.data.accountSelections.length <= 1,
        suppressNavigable: (params) =>
          !(!params.data.locked && params.data.accountSelections.length <= 1),
        width: 110,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'end',
          textAlign: 'center',
        },
      },
      {
        headerName: 'QTY',
        colId: 'quantity',
        type: 'numberColumn',
        maxWidth: 85,
        editable: (params) =>
          !params.data.locked && params.data.accountSelections.length <= 1,
        suppressNavigable: (params) =>
          !(!params.data.locked && params.data.accountSelections.length <= 1),
        valueGetter: (params) =>
          params.data.accountSelections.length === 1
            ? params.data.accountSelections[0].quantity
            : null,
        cellStyle: {
          textAlign: 'center',
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'end',
        },
      },
      {
        headerName: 'Account',
        colId: 'account',
        cellClass: ['ng-select-cell'],
        editable: (params) =>
          !params.data.locked && params.data.accountSelections.length <= 1,
        suppressNavigable: (params) => params.data.locked,
        valueGetter: (params) =>
          getTransactionAccountSelectionsIdentifier(params.data),
        filterValueGetter: (params) =>
          params.data.accountSelections
            ?.map(
              (t: any) => t.account?.accountNo + ' ' + t.account?.accountName
            )
            .join(' '),
        cellEditorFramework: TransactionAccountEditorComponent,
        cellRendererFramework: TransactionAccountRendererComponent,
        minWidth: 170,
        width: 200,
      },
      {
        headerName: 'Tax Code',
        colId: 'tax',
        cellClass: ['ng-select-cell'],
        editable: (params) =>
          !params.data.locked && params.data.accountSelections.length <= 1,
        suppressNavigable: (params) =>
          !(!params.data.locked && params.data.accountSelections.length <= 1),
        valueGetter: (params) =>
          getTransactionTaxSelectionsIdentifier(params.data),
        filterValueGetter: (params) =>
          params.data.accountSelections
            ?.map((t: any) => t.taxSelection?.code + ' ' + t.taxSelection?.name)
            .join(' '),
        cellEditorFramework: TransactionTaxEditorComponent,
        cellRendererFramework: TransactionTaxRendererComponent,
        minWidth: 170,
        width: 200,
      },
      {
        headerName: '',
        colId: 'editOptions',
        minWidth: 100,
        width: 90,
        type: 'optionsColumn',
        cellRendererParams: {
          ngTemplate: optionsCell,
        },
        valueGetter: (params) => `${params.data.hasComments}`,
        suppressNavigable: true,
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'end',
        },
      },
    ];

    this.gridOptions.columnDefs = columnDefs;
  }

  public externalFilterChanged(action: FilterActionEnum) {
    this.externalFilter.add(action);
    this.gridApi.onFilterChanged();
  }

  public removeFilterAction(action: FilterActionEnum) {
    this.externalFilter.remove(action);
    this.gridApi.onFilterChanged();
  }
}
