import { GridOptions } from 'ag-grid-community';

export class AssetReportGrid {
  public static dollarParams = {
    rounding: 0,
    showZero: true,
  };

  public static customiseGrid(options: GridOptions) {
    options.groupDefaultExpanded = 1;
    options.groupIncludeFooter = true;
    options.defaultColDef = {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
    };
    options.autoGroupColumnDef = {
      headerName: 'Name',
      field: 'asset.name',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        footerValueGetter: (params) => {
          const groupKey =
            params.node.key || (params.node.parent && params.node.parent.key);
          return groupKey ? `${groupKey} Total` : '';
        },
        innerRenderer: 'hyperlinkRenderer',
        innerRendererParams: (params) =>
          AssetReportGrid.hyperlinkParams(params),
      },
    };
  }

  private static hyperlinkParams(params) {
    if (!params.data || !params.data.asset) return null;
    return {
      routeDirect: ['./', params.data.asset.id],
    };
  }
}
