export class NumberHelpers {
  public static parseToNumberOrNull(val: any): number | null {
    if (val == null) {
      return null;
    }

    const num = Number(val);
    if (isNaN(num)) {
      return null;
    }

    return num;
  }
}
