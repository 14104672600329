import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { SourceComponent } from '../source/source.component';
import { Source } from '../source';
import { SourceService } from '../source.service';
import { ModalService, MessageService } from '../../../../core';
import { getDefaultGridOptions, accountingRenderers } from '../../../../shared';

@Component({
  selector: 'crs-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss'],
})
export class SourcesComponent implements OnInit {
  fileId: string;
  collapsed: boolean;

  busy = {
    sources: false,
  };
  sourcesObservable: Observable<any>;
  error: string;

  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;

  constructor(
    private sourceService: SourceService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.fileId = this.route.snapshot.parent.paramMap.get('id');
      this.getSources();
    });
  }

  getSources() {
    this.busy.sources = true;
    this.sourcesObservable = this.sourceService.getAll$(this.fileId).pipe(
      tap(() => (this.busy.sources = false)),
      catchError((err) => {
        this.showError(err);
        return of([]);
      })
    );
  }

  addSource() {
    this.modalService.openModal(SourceComponent, 'add').then(() => {
      this.getSources();
    });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  selectSource(param) {
    this.modalService
      .openModal(SourceComponent, param.data.id)
      .catch(() => true)
      .finally(() => this.getSources());
  }
}
