import { SourceAccount } from './../../ledger/sourceAccounts/sourceAccount';
import { DepreciationType } from './../assets/depreciation-type';
import { DepreciationPool } from './../assets/depreciation-pool';
import { DepreciationMethod } from './../assets/depreciation-method';
import { AssetType } from './../assets/asset-type';
import { Source } from '../..';

export class AssetGroupJournalSetting {
  source: Source;
  depreciationType: DepreciationType;
  depreciationSourceAccount = new SourceAccount({});
  accumulatedSourceAccount = new SourceAccount({});

  constructor(data: any) {
    if (data.source) this.source = new Source(data.source);
    this.depreciationType = data.depreciationType ? data.depreciationType : DepreciationType.Taxation;
    if (data.depreciationSourceAccount) this.depreciationSourceAccount = new SourceAccount(data.depreciationSourceAccount);
    if (data.accumulatedSourceAccount) this.accumulatedSourceAccount = new SourceAccount(data.accumulatedSourceAccount);
  }
}

export class AssetGroupJournalSettingModel {
  sourceId: string;
  depreciationType: DepreciationType;
  depreciationSourceAccount = new SourceAccount({});
  accumulatedSourceAccount = new SourceAccount({});

  constructor(data: AssetGroupJournalSetting) {
    this.sourceId = data.source.id;
    this.depreciationType = data.depreciationType;
    this.depreciationSourceAccount = new SourceAccount(data.depreciationSourceAccount);
    this.accumulatedSourceAccount = new SourceAccount(data.accumulatedSourceAccount);
  }
}

export class DepreciationDefault {

  depreciationMethod: DepreciationMethod;
  depreciationPool: DepreciationPool;
  rate: number;

  constructor(data: any) {
    this.depreciationMethod = data.depreciationMethod;
    this.depreciationPool = data.depreciationPool;
    this.rate = data.rate;
  }

}

export class AssetGroup {
  id: number;
  name: string;
  defaultAssetType: AssetType;
  taxationDefaults: DepreciationDefault;
  accountingDefaults: DepreciationDefault;
  journalSettings: AssetGroupJournalSetting[] = [];

  constructor(data) {
    this.id = parseInt(data.id, 10);
    this.name = data.name;
    this.defaultAssetType = data.defaultAssetType;
    if (data.taxationDefaults) this.taxationDefaults = new DepreciationDefault(data.taxationDefaults);
    if (data.accountingDefaults) this.accountingDefaults = new DepreciationDefault(data.accountingDefaults);
    if (data.journalSettings) this.journalSettings = data.journalSettings.map(s => new AssetGroupJournalSetting(s));
  }
}

export class AssetGroupModel {
  id: number;
  fileId: string;
  entityId: string;
  name: string;
  defaultAssetType: AssetType;
  taxationDefaults: DepreciationDefault;
  accountingDefaults: DepreciationDefault;
  journalSettings: AssetGroupJournalSettingModel[];

  constructor(data: AssetGroup) {
    this.id = data.id;
    this.name = data.name;
    this.defaultAssetType = data.defaultAssetType;
    this.taxationDefaults = data.taxationDefaults;
    this.accountingDefaults = data.accountingDefaults;
    this.journalSettings = data.journalSettings.map(j => new AssetGroupJournalSettingModel(j));
  }
}
