<div class="sidebar">

  <ul ngbNav #sidenav="ngbNav" class="nav nav-tabs">
    <li class="nav-item" ngbNavItem>
      <a class="nav-link" ngbNavLink>
        <i class="far fa-file" aria-hidden="true"></i>
      </a>
      <ng-template ngbNavContent>
        <ng-container [ngTemplateOutlet]="reportsidenav">
        </ng-container>
      </ng-template>
    </li>
    <li class="nav-item" ngbNavItem *ngIf="warningPages.length || report.warnings?.length">
      <a class="nav-link" ngbNavLink>
        <ng-container *ngFor="let severity of [reportSeverity]">
          <i class="fas fa-exclamation-circle text-danger" aria-hidden="true" *ngIf="severity == 2"></i>
          <i class="fas fa-exclamation-triangle text-warning" aria-hidden="true" *ngIf="severity == 1"></i>
          <i class="fas fa-exclamation-triangle text-info" aria-hidden="true" *ngIf="severity == 0"></i>
        </ng-container>
      </a>
      <ng-template ngbNavContent>
        <ng-container [ngTemplateOutlet]="errorsidenav">
        </ng-container>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="sidenav">
  </div>

  <!-- Contents Side Nav -->
  <ng-template #reportsidenav>
    <nav aria-label="Report Navigation">

      <div class="list-group list-group-flush">
        <h1>Contents:</h1>

        <a [routerLink]="[]" queryParamsHandling="merge"
           (click)="selectPage(page)"
           class="list-group-item list-group-item-action"
           [class.active]="i == activePageIndex"
           *ngFor="let page of report.pages; let i = index">
          <div class="d-flex">
            <i class="far fa-file mt-1 mr-2" aria-hidden="true"></i> {{page.title}}
            <ng-container *ngIf="page.warnings && page.warnings.length">
              <ng-container *ngFor="let severity of [maxSeverity(page.warnings)]">
                <i class="fas fa-exclamation-circle mt-1 ml-1 text-danger" aria-hidden="true" *ngIf="severity == 2"></i>
                <i class="fas fa-exclamation-triangle mt-1 ml-1 text-warning" aria-hidden="true"
                   *ngIf="severity == 1"></i>
                <i class="fas fa-exclamation-triangle mt-1 ml-1 text-info" aria-hidden="true" *ngIf="severity == 0"></i>
              </ng-container>
            </ng-container>
          </div>
        </a>

      </div>

    </nav>
  </ng-template>

  <!-- Errors Side Nav -->
  <ng-template #errorsidenav>
    <h1>Alerts:</h1>

    <div class="report-alerts">
      <crs-report-warning [warnings]="report.warnings" [small]="true"></crs-report-warning>
    </div>

    <ng-container *ngFor="let page of warningPages; let i = index">
      <div class="page-alerts" [class.active]="report.pages.indexOf(page) == activePageIndex">
        <h2>
          <a [routerLink]="[]" (click)="selectPage(page)">
            {{page.title}}:
          </a>
        </h2>
        <crs-report-warning [report]="report" [warnings]="page.warnings" [small]="true"></crs-report-warning>
      </div>
    </ng-container>

  </ng-template>


</div>
