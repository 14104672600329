<h1>Style Guide</h1>

<h2>Text</h2>

<p>
  Use the 'display-X' classes on &lt;h1&gt; for titles, use normal &lt;h1&gt;
  for headings within body text
</p>

<div class="bd-example">
  <h1 class="display-1">Display 1</h1>
  <h1 class="display-2">Display 2</h1>
  <h1 class="display-3">Display 3</h1>
  <h1 class="display-4">Display 4</h1>
</div>

<p>Other header examples</p>

<div class="bd-example">
  <h3>
    This is a header &lt;h3&gt; with some
    <small class="text-muted">&lt;small class="text-muted"&gt; text</small>
  </h3>
</div>

<p>Some normal paragraph text examples:</p>

<div class="bd-example">
  <p>This is paragraph &lt;p&gt; text.</p>

  <p>
    This is paragraph &lt;p&gt; text with some <small>&lt;small&gt; text</small>
  </p>

  <p>
    This is paragraph &lt;p&gt; text with some
    <strong>&lt;strong&gt; text</strong>
  </p>

  <p>
    This is paragraph &lt;p&gt; text with some
    <small class="text-muted">&lt;small class="text-muted"&gt; text</small>
  </p>
</div>

<h2>Buttons</h2>

<h3>Primary Button Styles</h3>

<div class="bd-example">
  <button class="btn btn-primary" type="button">Primary</button>
  <div class="spacer"></div>
  <button class="btn btn-secondary" type="button">Secondary</button>
  <div class="spacer"></div>
  <button class="btn btn-success" type="button">Success</button>
  <div class="spacer"></div>
  <button class="btn btn-danger" type="button">Danger</button>
  <div class="spacer"></div>
</div>

<h3>Special Styles</h3>

<div class="bd-example">
  <button class="btn btn-insert" type="button">Insert</button>
  <div class="spacer"></div>
  <button class="btn btn-secondary" type="button">Select</button>
  <div class="spacer"></div>
</div>

<h3>Errata</h3>

<p>For icons, use:</p>

<div class="bd-example">
  <button class="btn btn-primary" type="button">
    <i aria-hidden="true" class="fas fa-star inset"></i>
    Primary Button with Icon
  </button>
  <div class="spacer"></div>

  <button class="btn btn-insert" type="button">
    <i aria-hidden="true" class="fas fa-star inset"></i>
    Insert Button with Icon
  </button>
  <div class="spacer"></div>

  <button class="btn btn-secondary" type="button">
    <i aria-hidden="true" class="fas fa-star inset"></i>
    Select Button with Icon
  </button>
  <div class="spacer"></div>
</div>
<p>Dropdown:</p>

<div class="bd-example">
  <div class="dropdown">
    <button
      aria-expanded="false"
      aria-haspopup="true"
      class="btn btn-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      id="dropdownMenuButton"
      type="button"
    >
      Dropdown button
    </button>
    <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
      <a class="dropdown-item" href="#">Action</a>
      <a class="dropdown-item" href="#">Another action</a>
      <a class="dropdown-item" href="#">Something else here</a>
    </div>
  </div>
</div>

<p>Used in a button menu, wrap in &lt;div class="btn-menu" &gt;&lt;/div&gt;</p>

<div class="bd-example">
  <div class="btn-menu">
    <button class="btn btn-secondary" type="button">Option 1</button>
    <button class="btn btn-secondary" type="button">Option 2</button>
    <button class="btn btn-secondary" type="button">Option 3</button>
  </div>
</div>

<h2>Modals and Panels</h2>

<div class="bd-example-modal">
  <div
    class="modal"
    role="dialog"
    style="{position: relative, display: block}"
    tabindex="-1"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!-- BEGIN -->
        <div class="modal-header">
          <h1 class="modal-title">Modal Title</h1>
          <button aria-label="Close" class="close" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="form-group">
            <label class="control-label">Code</label>
            <input class="form-control" type="text" />
          </div>

          <div class="form-group">
            <label class="control-label">Name</label>
            <input class="form-control" type="text" />
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="submit">Save</button>
          <button class="btn btn-secondary" type="button">Cancel</button>
        </div>
        <!-- END -->
      </div>
    </div>
  </div>
</div>
