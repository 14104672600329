import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';

import { ColumnSpreadBy, ColumnCalculationType, ConsolidationMode } from '../';
import { Dataset } from '../../../datasets';
import { Division, TradingAccount } from '../../../setup';

@Component({
  selector: 'crs-report-template-column',
  templateUrl: './report-template-column.component.html',
})
export class ReportTemplateColumnComponent {
  @Input('index') i: number;
  private _item: UntypedFormGroup;

  @Input()
  set item(value: UntypedFormGroup) {
    this._item = value;
    this._item.controls['dataset'].setValidators([Validators.required]);
    this._item.updateValueAndValidity();
  }

  get item(): UntypedFormGroup {
    return this._item;
  }
  @Output() removed = new EventEmitter<number>();

  @Input() datasets: Dataset[] = [];
  @Input() divisions: Division[] = [];
  @Input() tradingAccounts: TradingAccount[] = [];

  columnSpreadBy = ColumnSpreadBy;
  calculationTypes = ColumnCalculationType;
  consolidationModes = ConsolidationMode;

  compareWithFn = (a: any, b: any) =>
    (a === null && b === null) || a.id === b.id;

  ngOnInit() {}
}
