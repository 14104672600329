<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body" [ngBusy]="[busy.load]">
    <div class="row">
      <div class="col-md-8">
        <div class="form-group required">
          <label class="control-label">Header Name</label>
          <input
            class="form-control"
            formControlName="accountName"
            type="text"
          />
        </div>

        <div class="form-group">
          <label class="control-label">Classification</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            formControlName="sourceClassification"
            [clearable]="false"
            [items]="classifications | enumToArray"
          ></ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Header</label>
          <div class="d-flex">
            <div class="flex-grow-1">
              <input
                class="form-control"
                read-only
                readonly
                type="text"
                [value]="parent?.displayName"
              />
            </div>
            <div class="ml-2">
              <a
                class="btn-sm fas-btn-primary-ghost"
                placement="left"
                popoverTitle="Change Custom Header Parent"
                [ngbPopover]="headerTooltipContent"
                [routerLink]="[]"
              >
                <i aria-hidden="true" class="fas fa-info-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Account Type</label>
          <crs-account-type-select
            formControlName="accountType"
            [clearable]="false"
            [readonly]="form.value.accountTypeMode == 0"
          ></crs-account-type-select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="control-label">Mode</label>
          <div class="input-group d-flex">
            <div class="flex-grow-1">
              <ng-select
                bindLabel="name"
                bindValue="value"
                formControlName="accountTypeMode"
                [clearable]="false"
                [items]="accountTypeModes | enumToArray"
              ></ng-select>
            </div>
            <div class="ml-2">
              <a
                class="btn-sm fas-btn-primary-ghost"
                placement="left"
                popoverTitle="Account Type Mode"
                [ngbPopover]="accountTypeContent"
                [routerLink]="[]"
              >
                <i aria-hidden="true" class="fas fa-info-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ng-template #headerTooltipContent>
        <p>
          To relocate custom headers to a different parent header, simply click
          on the
          <svg
            fill="#009999"
            height="16"
            stroke="#009999"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="2" x2="14" y1="5" y2="5" />
            <line x1="2" x2="14" y1="11" y2="11" />
          </svg>
          icon located in the last column of the grid, and then drag and drop it
          to the desired destination.
        </p>
      </ng-template>

      <ng-template #accountTypeContent>
        <p>
          Adjust this to control whether to set this manually, or whether it
          should be inherited.
        </p>
      </ng-template>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <label class="control-label">Opening Balance Account</label>
          <ng-select
            bindLabel="displayName"
            bindValue="id"
            formControlName="openingBalanceAccountId"
            [clearable]="true"
            [items]="openingBalanceAccounts"
            [readonly]="openingBalanceAccounts.length < 1"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      Save {{ objectTitle }}
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
  </div>
</form>
