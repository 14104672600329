import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { SecurityRole } from 'src/app/firm/users';
import { ApiService } from './api.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  readonly base: string = 'api/feature-flags';

  private featureFlagsSubject = new BehaviorSubject<Record<
    string,
    boolean
  > | null>(null);
  private loadingSubject = new BehaviorSubject<boolean>(true);

  featureFlags$ = this.featureFlagsSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  constructor(
    private sessionService: SessionService,
    private oauthService: OAuthService,
    private apiService: ApiService
  ) {}

  private createUserContext() {
    const user = this.sessionService.user;
    const claims: any = this.oauthService.getIdentityClaims();

    if (!user) {
      throw new Error('User not found');
    }

    const roleName = SecurityRole[user.role];

    return {
      id: user.id,
      fullName: `${user.firstName} ${user.lastName}`?.trim(),
      email: user.email,
      role: roleName,
      firmId: claims.fa_firm_id,
      tenantId: user.tenantId,
    };
  }

  // Loads all feature flags for the current user. The user context is NOT saved in launchdarkly
  loadFeatureFlagsForUser(): Promise<void> {
    const url = `${this.base}`;
    const userContext = this.createUserContext();

    return this.apiService
      .post<Record<string, boolean>>(url, userContext)
      .pipe(
        tap((flags) => {
          this.featureFlagsSubject.next(flags);
          this.loadingSubject.next(false);
        }),
        map(() => undefined) // Return void since we're using a Promise
      )
      .toPromise();
  }

  // checks if a feature is enabled for the current user. The user context is saved in launchdarkly
  isFeatureEnabled(key: string): Observable<boolean> {
    let url = `${this.base}/is-feature-enabled/${key}`;
    const userContext = this.createUserContext();

    return this.apiService
      .post<boolean>(url, userContext)
      .pipe(map((bool) => bool));
  }
}
