<div class="row">
  <div class="col-form-md">
    <div class="form-group required">
      <label class="control-label">Edit Element</label>
      <ng-select
        [items]="classGroups"
        bindLabel="selector.friendlyName"
        [formControl]="classGroupControl"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <span [ngOptionHighlight]="search">{{
            item.selector.friendlyName
          }}</span
          >&nbsp;
          <small class="badge bg-primary" *ngIf="item.customCss"
            >Customised</small
          >
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>

<div class="card" *ngIf="selectedClassGroup">
  <div class="card-body">
    <h4>{{ selectedClassGroup.selector.friendlyName }}</h4>
    <p>
      {{ selectedClassGroup.selector.description }}
    </p>

    <strong>CSS Properties</strong>

    <button
      type="button"
      class="btn btn-light btn-sm ms-2"
      (click)="onRawCssInputModeChange()"
    >
      <i class="fas fa-code" aria-hidden="true"></i>
    </button>

    <div class="card my-2" *ngIf="selectedClassGroup">
      <ul class="list-group list-group-flush" *ngIf="isWhiteListEditing">
        <li class="list-group-item py-1" *ngFor="let property of properties">
          <div class="row">
            <div class="col-md-5 col-lg-4 d-flex align-items-center">
              <span class="align-middle">{{ property.name }}</span>
            </div>
            <div class="col-md-5 col-lg-4">
              <input
                type="text"
                [(ngModel)]="property.value"
                [placeholder]="property.defaultValue"
                class="form-control"
                (blur)="rebuildCss()"
              />
            </div>
            <div class="col-md-2 col-lg-2 d-flex align-items-center">
              <button
                *ngIf="hasValue(property.value)"
                class="btn btn-outline-danger btn-sm"
                (click)="removeProperty(property)"
              >
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </li>
        <li class="list-group-item py-1">
          <div class="row">
            <div class="col-md-5 col-lg-4">
              <ng-select
                [items]="whiteListProperties"
                placeholder="Select new property to add..."
                [(ngModel)]="pendingProperty.name"
                [clearable]="true"
              >
              </ng-select>
            </div>
            <div class="col-md-5 col-lg-4">
              <input
                type="text"
                [(ngModel)]="pendingProperty.value"
                class="form-control"
                *ngIf="pendingProperty.name"
              />
            </div>
            <div class="col-md-2 col-lg-2 d-flex align-items-center">
              <button
                *ngIf="hasValue(pendingProperty.value)"
                class="btn btn-outline-primary btn-sm"
                (click)="addProperty()"
              >
                Add
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <textarea
      *ngIf="!isWhiteListEditing"
      placeholder="Type to add custom css..."
      class="form-control"
      rows="7"
      [(ngModel)]="selectedClassGroup.customCss"
    >
    </textarea>
  </div>
</div>
