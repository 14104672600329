import { ColumnType } from '../../../../reportTemplates';
import { ElementSizeMultiplier, NumberFormat } from '../../../../enums';

export class ReportTableColumnDefinition {
  constructor(data?: Partial<ReportTableColumnDefinition>) {
    if (data) Object.assign(this, data);
  }

  widthType: ElementSizeMultiplier;
  width: number;
  valueType: ColumnType;
  numberFormat: NumberFormat;
  rounding: number;

  datasetId: any;
  startDate: any;
  endDate: any;
}
