import { Address } from './../address';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'crs-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  @Input('formGroup') formGroup: UntypedFormGroup;
  @Input('title') title?: string;
  @Input('twoAddressLines') twoAddressLines?: boolean;
  @Input('columnClass') columnClass?; string;
  @Input() copyFrom: Address;

  constructor() { }

  ngOnInit() {
    if (!this.columnClass) this.columnClass = 'col-12';
  }

  executeCopyFrom() {
    this.formGroup.patchValue(this.copyFrom);
  }
}
