import { AssetPoolingComponent } from './../accounting/assets/asset-pooling/asset-pooling/asset-pooling.component';
import { AssetReportsComponent } from './../accounting/assets/asset-reports/asset-reports/asset-reports.component';
import { DepreciationYearsComponent } from '../accounting/assets/depreciation-years/depreciation-years/depreciation-years.component';
import { AssetsRootComponent } from '../accounting/assets/assets-root/assets-root.component';
import { AssetsComponent } from '../accounting/assets/assets/assets/assets.component';
import { AssetGroupsComponent } from '../accounting/assets/asset-groups/asset-groups/asset-groups.component';
import { AssetComponent } from '../accounting/assets/assets/asset/asset.component';
import { AssetGroupComponent } from '../accounting/assets/asset-groups/asset-group/asset-group.component';
import { Route } from "@angular/router";

const defaultRoute: Route = {
  path: '',
  pathMatch: 'full',
  redirectTo: 'asset-groups'
};

const assetRoute = {
  path: ':groupId/assets/:id',
  component: AssetComponent
};

const assetRouteDirect = {
  path: ':id',
  component: AssetComponent
};

const assetsRoute = {
  path: ':groupId/assets',
  component: AssetsComponent
};

const assetGroupRoute = {
  path: 'asset-groups/:groupId',
  component: AssetGroupComponent
};

const assetGroupsRoute = {
  path: 'asset-groups',
  component: AssetGroupsComponent,
  children: [assetsRoute, assetRoute]
};

const assetReportsRoute = {
  path: 'reports',
  component: AssetReportsComponent,
  data: { title: 'Asset Reports' },
  children: [assetRouteDirect]
};

const assetPoolingRoute = {
  path: 'pooling',
  component: AssetPoolingComponent,
  data: { title: 'Asset Pooling' }
};

const depreciationYearRoute = {
  path: 'depreciation-years',
  component: DepreciationYearsComponent
};

export const assetsRootRoute = {
  path: 'assets',
  component: AssetsRootComponent,
  data: { title: 'Assets' },
  children: [
    defaultRoute,
    assetGroupsRoute,
    assetGroupRoute,
    depreciationYearRoute,
    assetReportsRoute,
    assetPoolingRoute
  ]
};

