import { ReportContentInfo } from './../../report-content/report-content-info';
import { IEditableContentInfo } from '../report-editable-element/editable-content-info';
/**
 * Represents the content of a financial table element
 * Includes a link to global meta data for the report content to which this table belongs
*/
export class FinancialContentInfo implements IEditableContentInfo {

  /**
   * Is this report content readonly?
  */
  get readonly() {
    return this.reportContent.readonly;
  }

  /**
    * Is this report content a 'template input' (false), or 'rendered output' (true)?
  */
  get output() {
    return this.reportContent.output;
  }

  /**
    * The financial table is currently being edited
  */
  isEditing: boolean;

  /**
   * Global info for the larger 'report-content' to which the financial content belongs
   */
  reportContent: ReportContentInfo;

  /**
   * An array of financial elements which represents the ordered content to render
  */
  content: any[];

  /**
   * Metadata for the columns in this report table
  */
  columns: any[];

}
