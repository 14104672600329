import { UntypedFormBuilder } from "@angular/forms";

export class TrusteeDetail{
  trusteeTypeId: number;
  companyName: string
  
  constructor(data) {
    this.trusteeTypeId = parseInt(data.trusteeTypeId, 10);
    this.companyName = data.companyName;
  }

}

export function buildTrusteeDetailForm(formBuilder: UntypedFormBuilder) {
  return formBuilder.group({
    trusteeTypeId: 1,
    companyName: [null]
  });
}