import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportOutput, ReportPageOutput, Warning } from '../report-output';

@Component({
  selector: 'crs-report-sidebar',
  templateUrl: './report-sidebar.component.html',
  styleUrls: [ './report-sidebar.component.scss' ]
})
export class ReportSidebarComponent {

  @Input() public activePageIndex: number;
  @Input() public report: ReportOutput;
  @Output() public selectedPage: EventEmitter<ReportPageOutput> = new EventEmitter<ReportPageOutput>();

  get warningPages() {
    return this.report.pages.filter(p => p.warnings && p.warnings.length);
  }

  public selectPage(page: ReportPageOutput): void {
    this.selectedPage.emit(page);
  }

  get reportSeverity(): number {
    let severity = this.maxSeverity(this.report.warnings);
    let i = 0;
    const pages = this.warningPages.slice();
    while (severity < 2 && i < pages.length) {
      severity = Math.max(severity, this.maxSeverity(pages[i].warnings));
      i++;
    }
    return severity;
  }

  public maxSeverity(warnings: Warning[]) {
    if (!warnings || !warnings.length) return -1;
    const severities = warnings.map(w => w.severity);
    return Math.max(...severities);
  }
}
