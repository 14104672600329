import { StandardAccountService } from '../standard-account.service';
import { StandardAccount, StandardAccountToUpsert } from '../standard-account';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, Subscription, EMPTY } from 'rxjs';
import { tap, exhaustMap, catchError, finalize } from 'rxjs/operators';

import {
  AccountTypeMode,
  TenantAccountTypeMode,
} from '../../../ledger/accounts/accountTypeMode';
import { Classification } from 'src/app/accounting/ledger';
import { AccountType } from 'src/app/accounting/ledger/account-types';
import { StandardHeaderAccount } from '../standard-header-account';
import { EntityTypeEnum, entityTypesWithoutUnspecified } from 'src/app/firm';

export enum AddHeaderFormType {
  AddFromExisting = 'addFromExisting',
  AddNew = 'addNew',
}

@Component({
  selector: 'crs-standard-accounts-header-form',
  templateUrl: './standard-accounts-header-form.component.html',
  styleUrls: ['./standard-accounts-header-form.component.scss'],
})
export class StandardAccountsHeaderFormComponent implements OnInit, OnDestroy {
  @Input() id: AddHeaderFormType | string;
  @Input() set params(
    value: StandardAccount | { parent: StandardAccount; sortOrder: number }
  ) {
    if (value instanceof StandardAccount) {
      this._parent = value;
    } else {
      this._parent = value.parent;
      this._sortOrder = value.sortOrder;
    }
  }

  private _parent: StandardAccount;
  get parent() {
    return this._parent;
  }

  private _sortOrder: number;
  get sortOrder() {
    return this._sortOrder;
  }

  isAdd: boolean;

  busy = {
    load: null,
    submit: null,
    apply: null,
  };
  error: string = null;

  addHeaderFormType = AddHeaderFormType;

  form = this.formBuilder.group({
    accountName: ['', [Validators.required, Validators.maxLength(256)]],
    sourceClassification: [null, Validators.required],
    headerAccount: [null, Validators.required],
    accountType: [null, Validators.required],
    accountTypeMode: [TenantAccountTypeMode.Inherited],
    showMultipleHeaderNames: [false],
    accountNameForCompany: ['', [Validators.maxLength(256)]],
    accountNameForUnitTrust: ['', [Validators.maxLength(256)]],
    accountNameForDiscretionaryTrust: ['', [Validators.maxLength(256)]],
    accountNameForPartnership: ['', [Validators.maxLength(256)]],
    accountNameForSoleTrader: ['', [Validators.maxLength(256)]],
    accountNameForSuper: ['', [Validators.maxLength(256)]],
    accountNameForAssociation: ['', [Validators.maxLength(256)]],
    entityTypes: [null],
    openingBalanceAccountId: [null],
  });

  accountTypes: AccountType[][];
  accountTypeModes = TenantAccountTypeMode;
  classifications = Classification;
  headerAccounts: StandardAccount[] = [];
  entityTypes = entityTypesWithoutUnspecified;
  openingBalanceAccounts: StandardAccount[] = [];

  submitButtonStream$ = new Subject<void>();
  subscriptions: Subscription[] = [];
  isCustom = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private standardAccountService: StandardAccountService
  ) {}

  ngOnInit() {
    this.isAdd =
      this.id === AddHeaderFormType.AddFromExisting ||
      this.id === AddHeaderFormType.AddNew;

    this.getOpeningBalanceAccounts();
    this.getAccount();
    this.configureSubmit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  close() {
    this.activeModal.dismiss();
  }

  submit() {
    this.submitButtonStream$.next();
  }

  private getOpeningBalanceAccounts() {
    if (this._parent?.children?.length > 0) {
      this.openingBalanceAccounts.push(...this._parent.children);
    }
  }

  private configureSubmit() {
    this.subscriptions.push(
      this.submitButtonStream$
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.submitObservable$())
        )
        .subscribe((id) => {
          this.activeModal.close(id);
        })
    );
  }

  private submitObservable$(): Observable<any> {
    const account = new StandardHeaderAccount(this.form.value);
    const accountToUpsert: StandardAccountToUpsert = {
      accountNo: this.form.value.accountNo?.trim(),
      accountName: account.accountName?.trim(),
      classification: this.form.value.sourceClassification,
      accountTypeId: Number(this.form.value.accountType.id),
      accountTypeMode:
        this.form.value.accountTypeMode ?? AccountTypeMode.Inherited,
      masterTaxCode: this.form.value.masterTaxCode,
      entityTypes: this.form.value.entityTypes,
      accountNameVariants: account.accountNameVariants ?? [],
      openingBalanceAccountId: account.openingBalanceAccountId,
    };

    if (this.form.value.headerAccount) {
      const headerAccount = this.form.value.headerAccount;

      Object.assign(accountToUpsert, {
        currentClassification: headerAccount.currentClassification,
        ledgerSide: Number(headerAccount.ledgerSide),
        level: headerAccount.hierarchy.length + 1,
        parentId: headerAccount.id,
        parentMasterAccountId: Number(headerAccount.masterAccountId),
        rootMasterAccountId: Number(headerAccount.rootMasterAccountId),
        sortOrder: Number(headerAccount.sortOrder),
      });
    }

    let submit$ = this.isAdd
      ? this.standardAccountService.postHeader$(account, account.sortOrder)
      : this.standardAccountService.putHeader$(account, this.id);

    // only update the name on non custom headers
    if (!this.isCustom) {
      submit$ = this.standardAccountService.putAccountLight$(
        this.id,
        accountToUpsert
      );
    }

    const loading$ = new Subject<void>();
    this.busy.submit = loading$.subscribe();

    return submit$.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loading$.complete())
    );
  }

  private getAccount() {
    this.configureControlSubscriptions();

    if (this.id === AddHeaderFormType.AddNew) {
      this.isCustom = true;
      return;
    }
    if (this.isAdd) {
      this.form.patchValue({
        sourceClassification: this.parent.classification,
        accountType: this.parent.accountType,
        headerAccount: this.parent,
      });
      this.isCustom = true;

      return;
    }

    this.busy.load = this.standardAccountService
      .getAccountAndParent$(this.id)
      .subscribe(
        (account) => {
          this.isCustom = account.isSortable;

          if (!this.isCustom) {
            this.removeValidationsForSystemHeader();

            if (account.accountNameVariants?.length > 0) {
              this.patchAccountNameVariants(account);
            }
          }

          this._parent = account.parent;
          this.form.patchValue({
            accountName: account.accountName,
            sourceClassification: account.classification,
            headerAccount: account.parent,
            accountType: account.accountType,
            accountTypeMode: account.accountTypeMode,
            openingBalanceAccountId: account.openingBalanceAccountId,
          });

          const isForAllEntityTypes =
            account.isCompany &&
            account.isUnitTrust &&
            account.isDiscretionaryTrust &&
            account.isPartnership &&
            account.isSoleTrader &&
            account.isSuper &&
            account.isAssociation;

          if (isForAllEntityTypes) {
            return;
          }

          const hasRestrictedEntityTypes =
            account.isCompany ||
            account.isUnitTrust ||
            account.isDiscretionaryTrust ||
            account.isPartnership ||
            account.isSoleTrader ||
            account.isSuper ||
            account.isAssociation;

          if (hasRestrictedEntityTypes) {
            const entityTypes = this.getRestrictedEntityTypes(account);
            this.form.patchValue({ entityTypes });
          }
        },
        (err) => this.showError(err)
      );
  }

  private configureControlSubscriptions() {
    // Account Type Mode to 'Inherit' will grab parent's account type
    this.form.get('accountTypeMode').valueChanges.subscribe((account) => {
      if (
        account === TenantAccountTypeMode.Inherited &&
        this.form.value.headerAccount
      ) {
        this.form.patchValue({
          accountType: this.form.value.headerAccount.accountType,
        });
      }
    });

    this.form
      .get('headerAccount')
      .valueChanges.subscribe((headerAccount: StandardAccount) => {
        this._parent = headerAccount?.parent;

        if (
          this.form.value.accountTypeMode === TenantAccountTypeMode.Inherited &&
          headerAccount
        ) {
          this.form.patchValue({ accountType: headerAccount.accountType });
        }
      });
  }

  private showError(error) {
    this.error = error;
  }

  private removeValidationsForSystemHeader() {
    this.form = this.formBuilder.group({
      accountName: ['', [Validators.required, Validators.maxLength(256)]],
      sourceClassification: [null],
      headerAccount: [null],
      accountType: [null],
      accountTypeMode: [TenantAccountTypeMode.Inherited],
      showMultipleHeaderNames: [false],
      accountNameForCompany: ['', [Validators.maxLength(256)]],
      accountNameForUnitTrust: ['', [Validators.maxLength(256)]],
      accountNameForDiscretionaryTrust: ['', [Validators.maxLength(256)]],
      accountNameForPartnership: ['', [Validators.maxLength(256)]],
      accountNameForSoleTrader: ['', [Validators.maxLength(256)]],
      accountNameForSuper: ['', [Validators.maxLength(256)]],
      accountNameForAssociation: ['', [Validators.maxLength(256)]],
      entityTypes: [null],
      openingBalanceAccountId: [null],
    });
  }

  private getRestrictedEntityTypes(account: StandardAccount): number[] {
    const entityTypes: number[] = [];

    if (account.isCompany) {
      entityTypes.push(EntityTypeEnum.Company);
    }

    if (account.isUnitTrust) {
      entityTypes.push(EntityTypeEnum.UnitTrust);
    }

    if (account.isDiscretionaryTrust) {
      entityTypes.push(EntityTypeEnum.DiscretionaryTrust);
    }

    if (account.isPartnership) {
      entityTypes.push(EntityTypeEnum.Partnership);
    }

    if (account.isSoleTrader) {
      entityTypes.push(EntityTypeEnum.SoleTrader);
    }

    if (account.isSuper) {
      entityTypes.push(EntityTypeEnum.SelfManagedSuperannuationFund);
    }

    if (account.isAssociation) {
      entityTypes.push(EntityTypeEnum.Association);
    }

    return entityTypes;
  }

  private patchAccountNameVariants(account: StandardAccount): void {
    this.form.patchValue({ showMultipleHeaderNames: true });

    for (const { entityType, accountName } of account.accountNameVariants) {
      if (entityType === EntityTypeEnum.Company) {
        this.form.patchValue({ accountNameForCompany: accountName });
      }

      if (entityType === EntityTypeEnum.UnitTrust) {
        this.form.patchValue({
          accountNameForUnitTrust: accountName,
        });
      }

      if (entityType === EntityTypeEnum.DiscretionaryTrust) {
        this.form.patchValue({
          accountNameForDiscretionaryTrust: accountName,
        });
      }

      if (entityType === EntityTypeEnum.Partnership) {
        this.form.patchValue({
          accountNameForPartnership: accountName,
        });
      }

      if (entityType === EntityTypeEnum.SoleTrader) {
        this.form.patchValue({
          accountNameForSoleTrader: accountName,
        });
      }

      if (entityType === EntityTypeEnum.SelfManagedSuperannuationFund) {
        this.form.patchValue({ accountNameForSuper: accountName });
      }

      if (entityType === EntityTypeEnum.Association) {
        this.form.patchValue({
          accountNameForAssociation: accountName,
        });
      }
    }
  }
}
