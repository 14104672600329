import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MasterBasCode } from './master-bas-code';

@Injectable({ providedIn: 'root' })
export class MasterBasCodeService {
  readonly base: string = 'api/chart-of-accounts/bas-code';

  constructor(private apiService: ApiService) {}

  getMasterBasCodes$(): Observable<MasterBasCode[]> {
    return this.apiService
      .get<MasterBasCode[]>(this.base + '/master-bas-codes')
      .pipe(
        map((masterBasCodes) =>
          masterBasCodes.map(
            (masterBasCode) => new MasterBasCode(masterBasCode)
          )
        )
      );
  }

  getMasterBasCodesForTax$(): Observable<MasterBasCode[]> {
    return this.apiService
      .get<MasterBasCode[]>(this.base + '/master-bas-codes')
      .pipe(
        map((masterBasCodes) =>
          masterBasCodes
            .filter((masterBasCode) => masterBasCode.isTaxCode)
            .map((masterBasCode) => new MasterBasCode(masterBasCode))
        )
      );
  }
}
