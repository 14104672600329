import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BacoTaxOptionDto, Stateful } from '../../../interfaces';
import { DefaultValueAccessor } from '../../../../shared/components/value-accessors';
import { BacoFeedStore } from '../../feed-detail';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';

@Component({
  selector: 'crs-transaction-tax-select',
  templateUrl: './transaction-tax-select.component.html',
  styleUrls: ['./transaction-tax-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TransactionTaxSelectComponent),
      multi: true,
    },
  ],
})
export class TransactionTaxSelectComponent
  extends DefaultValueAccessor<string>
  implements AfterViewInit
{
  @Input() appendTo = 'body';
  @Input() readonly = false;
  @Input() clearable = false;
  @Input() placeholder = 'Select Tax';
  @Input() autofocus = false;
  @Output() change = new EventEmitter<BacoTaxOptionDto>();

  @ViewChild(NgSelectComponent, { static: false })
  valueAccessor: ControlValueAccessor;
  @ViewChild(NgSelectComponent, { static: false }) ngSelect: NgSelectComponent;

  public taxOptions$: Observable<Stateful<BacoTaxOptionDto[]>> =
    this.feedStore.taxOptions$;

  private search$ = new BehaviorSubject<string>('');
  public sortedTaxOptions$: Observable<BacoTaxOptionDto[]>;

  constructor(protected feedStore: BacoFeedStore) {
    super();
  }

  ngOnInit(): void {
    this.sortedTaxOptions$ = combineLatest([
      this.feedStore.taxOptions$.pipe(map((stateful) => stateful.data || [])),
      this.search$
        .asObservable()
        .pipe(startWith(''), debounceTime(300), distinctUntilChanged()),
    ]).pipe(
      map(([taxOptions, searchTerm]) =>
        this.sortAndFilterTaxOptions(taxOptions, searchTerm)
      )
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.ngSelect && !this.autofocus) {
        this.ngSelect.focus();
      }
    });
  }

  public open() {
    setTimeout(() => {
      if (this.ngSelect && !this.ngSelect.isOpen) {
        this.ngSelect.open();
      }
    });
  }

  public close() {
    if (this.ngSelect.isOpen) {
      this.ngSelect.close();
    }
  }
  changed(data: BacoTaxOptionDto) {
    this.change.emit(data);
  }

  searchTaxNameOrCode = (
    searchTerm: string,
    taxOption: BacoTaxOptionDto
  ): boolean => {
    this.search$.next(searchTerm?.toLowerCase());
    return true;
  };

  private sortAndFilterTaxOptions(
    taxOptions: BacoTaxOptionDto[],
    searchTerm: string
  ): BacoTaxOptionDto[] {
    if (!searchTerm) {
      return taxOptions;
    }

    return taxOptions
      .filter(
        (option) =>
          option.code.toLowerCase().includes(searchTerm) ||
          option.name.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => {
        // Check for startsWith match on the code
        const codeStartsWithA = a.code.toLowerCase().startsWith(searchTerm);
        const codeStartsWithB = b.code.toLowerCase().startsWith(searchTerm);

        if (codeStartsWithA && !codeStartsWithB) {
          return -1; // A's code starts with the search term, B's does not
        }
        if (!codeStartsWithA && codeStartsWithB) {
          return 1; // B's code starts with the search term, A's does not
        }

        // If both or neither code starts with the search term, check for name startsWith
        const nameStartsWithA = a.name.toLowerCase().startsWith(searchTerm);
        const nameStartsWithB = b.name.toLowerCase().startsWith(searchTerm);

        if (nameStartsWithA && !nameStartsWithB) {
          return -1;
        }
        if (!nameStartsWithA && nameStartsWithB) {
          return 1;
        }

        // If both or neither name starts with the search term, fallback to code comparison
        const codeCompare = a.code.localeCompare(b.code);
        if (codeCompare !== 0) return codeCompare;

        // Finally, compare by name if all else is equal
        return a.name.localeCompare(b.name);
      });
  }
}
