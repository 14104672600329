import { Component, Input } from '@angular/core';

@Component({
  selector: '[crs-report-financial-column-header-row]',
  templateUrl: './report-financial-column-header-row.component.html'
})
export class ReportFinancialColumnHeaderRowComponent {

  @Input() element;

}
