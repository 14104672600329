<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li aria-current="page" class="breadcrumb-item active">
      Document Integration
    </li>
  </ol>
</nav>

<div class="pad">
  <div class="alert alert-success">
    Please indicate your preferred method for document integration. This choice
    is applied for all users within this organisation in Access Ledger
  </div>
  <form novalidate [formGroup]="form" [ngBusy]="busy.load">
    <div class="row">
      <div class="col-md-6 col-lg-6">
        <div class="form-group">
          <label class="control-label">Default Document Integration</label>
          <ng-select
            bindLabel="name"
            bindValue="value"
            [clearable]="false"
            [formControl]="signatureTypeControl"
            [items]="signatures | enumToArray : true"
          />
        </div>

        <div
          id="PleaseSignConfig"
          *ngIf="isPleaseSign && !busy.pleaseSignKeysLoading"
        >
          <crs-please-sign
            [copyFrom]="form.controls['pleaseSign'].value"
            [formGroup]="form.controls['pleaseSign']"
          />
        </div>

        <div
          id="AccessDocumentsConfig"
          *ngIf="
            isAccessDocuments &&
            !busy.checkingAccessDocuments &&
            !busy.checkingUserInput
          "
        >
          <div *ngIf="isAccessDocumentsReachable === true">
            <i class="fas fa-check-circle text-success"></i>
            Document integration has been set up successfully.
            <div class="spacer"></div>
            You can now request signatures and save documents directly to Access
            Documents.
          </div>
          <div *ngIf="isAccessDocumentsReachable === false">
            <i class="fas fa-exclamation-circle text-danger"></i>
            We are unable to establish a connection to Access Documents for this
            Practice.
            <div class="spacer"></div>
            Please ensure that:
            <ul>
              <li>
                Integration has been set up using the Integration Server option
                in the Configuration section
              </li>
              <li>
                The Access Documents product has been set up for integration
              </li>
            </ul>
            Please contact Customer support if you require assistance.
          </div>
        </div>

        <crs-form-error [error]="error" />
        <div class="spacer-md"></div>

        <div class="btn-menu">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="
              (isPleaseSign && !form.controls['pleaseSign'].valid) ||
              (isAccessDocuments && isAccessDocumentsReachable !== true)
            "
            [promiseBtn]="busy.submit"
            (click)="onClickSubmit()"
          >
            Save and Close
          </button>
          <button
            class="btn btn-secondary"
            type="button"
            (click)="onClickCancel()"
          >
            Cancel
          </button>
        </div>
        <div
          class="lds-container"
          *ngIf="busy.pleaseSignKeysLoading || busy.checkingAccessDocuments"
        >
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
