import {
  ReportSignatureConstants,
  SignatureElementTypes,
} from './esignature-report-pageTypes';
import { ResponsiblePerson } from 'src/app/firm';
import { ReportOutput } from './../../../reportViewer/report-output';
import {
  ESignatureRecipientModel,
  RecipientCategory,
  ReportsHavingSignatures,
} from './esignature-recipient-model';

export class ESignatureClientValidationRules {
  constructor() {
    Object.assign(this);
  }

  isSignatureIncluded(
    report: ReportOutput,
    reportsHavingSignatures: ReportsHavingSignatures[]
  ) {
    let isSignatureIncluded: boolean = false;
    report.pages.forEach((page) => {
      var reportWithSignatures = new ReportsHavingSignatures();
      reportWithSignatures.reportPageMasterId = page.pageTypeMasterId;
      reportWithSignatures.eSignatureRecipientModel = [];
      page.pageContent.content.forEach((element) => {
        if (
          element.elementType == SignatureElementTypes.GENERAL &&
          element?.name
        ) {
          reportWithSignatures.eSignatureRecipientModel.push(
            this.getSignatureAttributes(element)
          );
        }
        if (
          (element.elementType == SignatureElementTypes.AUDITOR &&
            element?.auditorName) ||
          element.elementType == SignatureElementTypes.AUDITOR_SMSF
        ) {
          reportWithSignatures.eSignatureRecipientModel.push(
            this.getAuditorSignatureAttributes(element)
          );
        }
      });

      if (reportWithSignatures?.eSignatureRecipientModel?.length > 0) {
        reportsHavingSignatures.push(reportWithSignatures);
        isSignatureIncluded = true;
      }
    });
    return isSignatureIncluded;
  }

  getResponsiblePersonsSignatories(
    reportWithSignatures: ReportsHavingSignatures[]
  ) {
    let responsiblePersons: ESignatureRecipientModel[] = [];
    reportWithSignatures.forEach((page) => {
      if (
        page.reportPageMasterId ==
          ReportSignatureConstants.CLIENT_DECLARATION_SUFFIX ||
        page.reportPageMasterId == ReportSignatureConstants.DIRECTOR_REPORT ||
        page.reportPageMasterId == ReportSignatureConstants.COMMITTEE_REPORT ||
        page.reportPageMasterId ==
          ReportSignatureConstants.CERTIFICATE_BY_MEMBERS_REPORT
      ) {
        page.reportPageCategory = RecipientCategory.CLIENTS_CATEGORY;
        this.getSignatureRecipientModels(page, responsiblePersons);
      }
    });
    return responsiblePersons;
  }

  getAuditorsSignatories(reportWithSignatures: ReportsHavingSignatures[]) {
    let auditorSignatoryRecipients: ESignatureRecipientModel[] = [];
    reportWithSignatures.forEach((page) => {
      if (
        page.reportPageMasterId ==
          ReportSignatureConstants.INDEPENDENT_AUDITOR_REPORT_COMPANY ||
        page.reportPageMasterId ==
          ReportSignatureConstants.INDEPENDENT_AUDITOR_REPORT_ASSOCIATION ||
        page.reportPageMasterId ==
          ReportSignatureConstants.INDEPENDENT_AUDITOR_REPORT_SMSF ||
        page.reportPageMasterId ==
          ReportSignatureConstants.AUDITOR_DECLARATION_REPORT
      ) {
        page.reportPageCategory = RecipientCategory.AUDITORS_CATEGORY;
        this.getSignatureRecipientModels(page, auditorSignatoryRecipients);
      }
    });
    return auditorSignatoryRecipients;
  }

  getPartnersSignatories(reportWithSignatures: ReportsHavingSignatures[]) {
    let partnerSignatoryRecipients: ESignatureRecipientModel[] = [];
    reportWithSignatures.forEach((page) => {
      if (
        page.reportPageMasterId ==
          ReportSignatureConstants.COMPILATION_REPORT ||
        page.reportPageMasterId ==
          ReportSignatureConstants.ACCOUNTANT_REPORT_DISCLAIMER
      ) {
        page.reportPageCategory = RecipientCategory.PARTNERS_CATEGORY;
        this.getSignatureRecipientModels(page, partnerSignatoryRecipients);
      }
    });
    return partnerSignatoryRecipients;
  }

  getOthersSignatories(reportWithSignatures: ReportsHavingSignatures[]) {
    let othersSignatoryRecipients: ESignatureRecipientModel[] = [];
    reportWithSignatures.forEach((page) => {
      if (!page.reportPageCategory) {
        page.reportPageCategory = RecipientCategory.OTHERS_CATEGORY;
        this.getSignatureRecipientModels(page, othersSignatoryRecipients);
      }
    });
    return othersSignatoryRecipients;
  }

  getSignatureRecipientModels(
    page: ReportsHavingSignatures,
    recipientModels: ESignatureRecipientModel[]
  ) {
    if (page?.eSignatureRecipientModel) {
      page.eSignatureRecipientModel.forEach((signatory) => {
        if (
          !recipientModels.find((s) => s.fullName == signatory.fullName) &&
          signatory
        ) {
          recipientModels.push(signatory);
        }
      });
    }
  }

  getSignatureAttributes(element) {
    var signatureElement = new ESignatureRecipientModel();
    signatureElement.fullName = element.name;
    signatureElement.sigName = element.sigName;
    return signatureElement;
  }

  getAuditorSignatureAttributes(element) {
    var signatureElement = new ESignatureRecipientModel();
    signatureElement.fullName = element.auditorName;
    signatureElement.sigName = element.sigName;
    return signatureElement;
  }

  mapClientSignatory(
    clientSignatories: ESignatureRecipientModel[],
    filteredResponsiblePersons: ResponsiblePerson[]
  ) {
    filteredResponsiblePersons.forEach((person) => {
      const sigName = `${person.firstName} ${person.lastName}`
        .trim()
        .replace(/\s/g, '_');
      const responsiblePerson = clientSignatories.find(
        (x) => x.sigName == sigName
      );
      if (responsiblePerson) {
        responsiblePerson.email = person.email;
      }
    });
  }

  mapPartnerSignatory(
    partnerSignatories: ESignatureRecipientModel[],
    partnersFromPractice: ESignatureRecipientModel
  ) {
    let sigName = partnersFromPractice.fullName?.replace(/\s/g, '_');
    const signatory = partnerSignatories.find((x) => x.sigName == sigName);
    if (signatory) {
      signatory.email = partnersFromPractice.email;
    }
  }
}
