<div class="modal-header">
  <h4 class="modal-title">{{objectTitle}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<form [formGroup]="form" (validSubmit)="submit()">
  <div class="modal-body">

    <div class="form-group">
      <label class="control-label">Description</label>
      <textarea class="form-control" formControlName="description" rows="3"></textarea>
    </div>

    <div class="row">
      <div class="col-label"></div>
      <div class="col-control col-header">Taxation</div>
      <div class="col-toggle"></div>
      <div class="col-control col-header">Accounting</div>
    </div>

    <div class="row required">
      <label class="col-label col-form-label">Cost</label>
      <div class="col-control">
        <div class="form-group" [formGroup]="taxation">
          <input class="form-control" currencyMask formControlName="cost">
        </div>
      </div>
      <div class="col-toggle">
        <crs-asset-difference-toggle key="cost" [parent]="component"></crs-asset-difference-toggle>
      </div>
      <div class="col-control">
        <div class="form-group" [formGroup]="accounting">
          <input class="form-control" currencyMask formControlName="cost" [readOnly]="!toggles['cost']">
        </div>
      </div>
    </div>

    <div class="row required">
      <label class="col-label col-form-label">Acquisition Date</label>
      <div class="col-control">
        <div class="form-group" [formGroup]="taxation">
          <crs-date-picker formControlName="acquisitionDate"></crs-date-picker>
        </div>
      </div>
      <div class="col-toggle">
        <crs-asset-difference-toggle key="acquisitionDate" [parent]="component"></crs-asset-difference-toggle>
      </div>
      <div class="col-control">
        <div class="form-group" [formGroup]="accounting">
          <crs-date-picker formControlName="acquisitionDate" [readonly]="!toggles['acquisitionDate']"></crs-date-picker>
        </div>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">
      {{ isAdd ? 'Add' : 'Update'}} {{objectTitle}}
    </button>
    <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
  </div>

</form>
