import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, exhaustMap, finalize, tap } from 'rxjs/operators';

import { AccountTypeMode, Classification } from 'src/app/accounting/ledger';
import { AccountType } from 'src/app/accounting/ledger/account-types';
import { EntityTypeEnum, entityTypesWithoutUnspecified } from 'src/app/firm';
import { StandardTaxCode } from '../../standard-tax-codes/standard-tax-code';
import { StandardTaxCodeService } from '../../standard-tax-codes/standard-tax-code.service';
import { StandardAccount, StandardAccountToUpsert } from '../standard-account';
import { StandardAccountService } from '../standard-account.service';

export enum AddAccountFormType {
  AddFromExistingHeader = 'addFromExistingHeader',
  AddNew = 'addNew',
}

@Component({
  selector: 'crs-standard-account-form',
  templateUrl: './standard-account-form.component.html',
  styleUrls: ['./standard-account-form.component.scss'],
})
export class StandardAccountFormComponent implements OnInit, OnDestroy {
  @Input() id: AddAccountFormType | string;
  @Input() set params(
    value: StandardAccount | { parent: StandardAccount; sortOrder: number }
  ) {
    if (value instanceof StandardAccount) {
      this.parent = value;
    } else {
      this.parent = value.parent;
      this.sortOrder = value.sortOrder;
    }
  }

  parent: StandardAccount;
  sortOrder: number;

  busy = {
    load: null,
    submit: null,
    apply: null,
  };
  error: string = null;
  isAdd: boolean;
  isCustom = false;

  form = this.formBuilder.group({
    accountNo: ['', [Validators.required, Validators.maxLength(128)]],
    accountName: ['', [Validators.required, Validators.maxLength(256)]],
    sourceClassification: [null, Validators.required],
    headerAccount: [null, Validators.required],
    accountType: [null, Validators.required],
    accountTypeMode: [AccountTypeMode.Inherited],
    masterTaxCode: [null],
    entityTypes: [null],
  });

  addAccountFormType = AddAccountFormType;
  accountTypes: AccountType[][];
  accountTypeModes = AccountTypeMode;
  classifications = Classification;
  entityTypes = entityTypesWithoutUnspecified;
  taxCodes: StandardTaxCode[];

  private submitButtonStream$ = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private standardAccountService: StandardAccountService,
    private standardTaxCodeService: StandardTaxCodeService
  ) {}

  ngOnInit(): void {
    this.isAdd =
      this.id === AddAccountFormType.AddFromExistingHeader ||
      this.id === AddAccountFormType.AddNew;

    this.fetchAccount();
    this.fetchTaxCodes();
    this.configureControlSubscriptions();
    this.configureSubmit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onClickClose(): void {
    this.activeModal.dismiss();
  }

  onValidSubmit(): void {
    this.submitButtonStream$.next();
  }

  private fetchAccount(): void {
    if (this.id === AddAccountFormType.AddNew) {
      this.isCustom = true;
      return;
    }

    if (this.id === AddAccountFormType.AddFromExistingHeader) {
      this.form.patchValue({
        sourceClassification: this.parent.classification,
        accountType: this.parent.accountType,
        headerAccount: this.parent,
      });
      this.isCustom = true;
      return;
    }

    this.busy.load = this.standardAccountService
      .getAccountAndParent$(this.id)
      .subscribe(
        (account) => {
          this.isCustom = !account.masterAccountId;
          this.parent = account.parent;

          this.form.patchValue({
            accountNo: account.accountNo,
            accountName: account.accountName,
            sourceClassification: account.classification,
            headerAccount: account.parent,
            accountType: account.accountType,
            accountTypeMode: account.accountTypeMode,
            masterTaxCode: account.masterTaxCode,
          });

          const isForAllEntityTypes =
            account.isCompany &&
            account.isUnitTrust &&
            account.isDiscretionaryTrust &&
            account.isPartnership &&
            account.isSoleTrader &&
            account.isSuper &&
            account.isAssociation;

          if (isForAllEntityTypes) {
            return;
          }

          const hasRestrictedEntityTypes =
            account.isCompany ||
            account.isUnitTrust ||
            account.isDiscretionaryTrust ||
            account.isPartnership ||
            account.isSoleTrader ||
            account.isSuper ||
            account.isAssociation;

          if (hasRestrictedEntityTypes) {
            const entityTypes = this.getRestrictedEntityTypes(account);
            this.form.patchValue({ entityTypes });
          }
        },
        (error) => this.showError(error)
      );
  }

  private configureControlSubscriptions(): void {
    // Account Type Mode to 'Inherit' will grab parent's account type
    this.form
      .get('accountTypeMode')
      .valueChanges.subscribe((accountTypeMode) => {
        if (
          accountTypeMode === AccountTypeMode.Inherited &&
          this.form.value.headerAccount
        ) {
          this.form.patchValue({
            accountType: this.form.value.headerAccount.accountType,
          });
        }
      });

    // When Header is changed and Account Type Mode is 'Inherit', inherits the header's account type
    this.form
      .get('headerAccount')
      .valueChanges.subscribe((headerAccount: StandardAccount) => {
        this.parent = headerAccount?.parent;

        if (
          this.form.get('accountTypeMode').value ===
            AccountTypeMode.Inherited &&
          headerAccount
        ) {
          this.form.patchValue({ accountType: headerAccount.accountType });
        }
      });
  }

  private configureSubmit(): void {
    this.subscriptions.push(
      this.submitButtonStream$
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap(() => this.submit$())
        )
        .subscribe((id) => {
          this.activeModal.close(id);
        })
    );
  }

  private submit$(): Observable<any> {
    const account = new StandardAccount(this.form.value);

    const accountToUpsert: StandardAccountToUpsert = {
      accountNo: this.form.value.accountNo?.trim(),
      accountName: this.form.value.accountName?.trim(),
      classification: this.form.value.sourceClassification,
      accountTypeId: Number(this.form.value.accountType.id),
      accountTypeMode:
        this.form.value.accountTypeMode ?? AccountTypeMode.Inherited,
      masterTaxCode: this.form.value.masterTaxCode,
      entityTypes: this.form.value.entityTypes,
      accountNameVariants: account.accountNameVariants ?? [],
      openingBalanceAccountId: account.openingBalanceAccountId,
    };

    if (this.form.value.headerAccount) {
      const headerAccount = this.form.value.headerAccount;

      Object.assign(accountToUpsert, {
        currentClassification: headerAccount.currentClassification,
        ledgerSide: Number(headerAccount.ledgerSide),
        level: headerAccount.hierarchy.length + 1,
        parentId: headerAccount.id,
        parentMasterAccountId: Number(headerAccount.masterAccountId),
        rootMasterAccountId: Number(headerAccount.rootMasterAccountId),
        sortOrder: Number(headerAccount.sortOrder),
      });
    }

    let submit$ = this.isAdd
      ? this.standardAccountService.postChildAccount$(accountToUpsert)
      : this.standardAccountService.putChildAccount$(accountToUpsert, this.id);
    // Only update the account name on system account/non-custom account
    if (!this.isCustom) {
      submit$ = this.standardAccountService.putAccountLight$(
        this.id,
        accountToUpsert
      );
    }

    const loading$ = new Subject();
    this.busy.submit = loading$.subscribe();

    return submit$.pipe(
      catchError((error) => {
        this.showError(error);
        return EMPTY;
      }),
      finalize(() => loading$.complete())
    );
  }

  private showError(error): void {
    this.error = error;
  }

  private fetchTaxCodes(): void {
    this.standardTaxCodeService
      .get$()
      .pipe(
        catchError((error) => {
          this.showError(error);
          return of([]);
        }),
        tap((taxCodes: StandardTaxCode[]) => (this.taxCodes = taxCodes))
      )
      .subscribe();
  }

  private getRestrictedEntityTypes(account: StandardAccount): number[] {
    const entityTypes: number[] = [];

    if (account.isCompany) {
      entityTypes.push(EntityTypeEnum.Company);
    }

    if (account.isUnitTrust) {
      entityTypes.push(EntityTypeEnum.UnitTrust);
    }

    if (account.isDiscretionaryTrust) {
      entityTypes.push(EntityTypeEnum.DiscretionaryTrust);
    }

    if (account.isPartnership) {
      entityTypes.push(EntityTypeEnum.Partnership);
    }

    if (account.isSoleTrader) {
      entityTypes.push(EntityTypeEnum.SoleTrader);
    }

    if (account.isSuper) {
      entityTypes.push(EntityTypeEnum.SelfManagedSuperannuationFund);
    }

    if (account.isAssociation) {
      entityTypes.push(EntityTypeEnum.Association);
    }

    return entityTypes;
  }
}
