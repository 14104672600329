import { FileSearchResult } from './../file';
import {
  Component,
  OnInit,
  forwardRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, of } from 'rxjs';
import { PagedResponse } from 'src/app/core';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  map,
  catchError,
} from 'rxjs/operators';
import { FileService } from '../file.service';

@Component({
  selector: 'crs-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FileSelectComponent implements OnInit {
  @Input() filterByPartner = false;
  @Input() filterByManager = false;
  @Input() includeInactive = false;
  @Input() readonly = false;
  @Input() clearable = true;

  @ViewChild(NgSelectComponent, { static: true })
  private valueAccessor: ControlValueAccessor;

  filesObservable: Observable<FileSearchResult[]>;
  loading = false;
  selectedStream: Subject<any> = new Subject<FileSearchResult>();
  userInput = new Subject<string>();

  groupByFn = (file: FileSearchResult) => file.client.name;

  constructor(private fileService: FileService) {}

  private search(search): Observable<PagedResponse<FileSearchResult>> {
    return this.fileService.find$(search, 1, 100);
  }

  writeValue(value: any | any[]): void {
    this.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  private configureStream() {
    this.filesObservable = this.userInput.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.loading = true)),
      switchMap((term) =>
        this.search(term).pipe(
          map((response) => response.records),
          catchError((error) => {
            console.log(error);
            return of([]);
          }), // empty list on error
          tap(() => (this.loading = false))
        )
      )
    );
  }

  ngOnInit() {
    // Sometimes this hasn't been set prior to writing value, possible bug in ng-select
    (this.valueAccessor as NgSelectComponent).bindLabel = 'name';
    this.configureStream();
  }
}
