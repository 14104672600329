import { ReportOutput } from '../report-output';

export enum ExportOption {
  Pdf = 'pdf',
  Excel = 'excel',
  Word = 'word',
}

export interface ExportStatus {
  exportPdf: boolean;
  exportExcel: boolean;
  exportWord: boolean;
  sendESign: boolean;
  saveReportData: boolean;
}

export abstract class ExportStrategy {
  busy: ExportStatus = {
    exportPdf: false,
    exportExcel: false,
    exportWord: false,
    sendESign: false,
    saveReportData: false,
  };

  abstract exportAsPdf(
    report: ReportOutput,
    title: string,
    shouldUpdateCustomTableAutoTotal?: boolean
  ): void;

  abstract exportAsExcel(
    report: ReportOutput,
    title: string | null,
    shouldUpdateCustomTableAutoTotal?: boolean
  ): void;

  abstract exportAsWord(
    report: ReportOutput,
    title: string | null,
    shouldUpdateCustomTableAutoTotal?: boolean
  ): void;
}
