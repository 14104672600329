import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, Subject, empty, throwError } from 'rxjs';
import {
  tap,
  exhaustMap,
  debounceTime,
  distinctUntilChanged,
  finalize,
  catchError,
  takeUntil,
} from 'rxjs/operators';

import {
  User,
  UserModel,
  UserService,
  SecurityRole,
  HandisoftSecurityRole,
} from '../';
import { TeamUserRole, TeamUser, OfficeUser, OfficeUserRole } from '../../';
import {
  OfficeUserComponent,
  OfficeUserComponentParams,
} from '../../offices/office-user/office-user.component';
import {
  TeamUserComponent,
  TeamUserComponentParams,
} from '../../teams/team-user/team-user.component';
import {
  MessageService,
  ModalService,
  SessionService,
  Config,
} from '../../../core';
import { getDefaultGridOptions } from '../../../shared';
import { AuthService } from 'src/app/core';
import { Patterns } from '../../../shared/validators';
import { GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-user',
  templateUrl: './user.component.html',
})
export class UserComponent implements OnInit, OnDestroy {
  @ViewChild('optionsCellOffice', { static: true, read: TemplateRef })
  optionsCellOffice!: TemplateRef<OfficeUser>;
  @ViewChild('optionsCellTeam', { static: true, read: TemplateRef })
  optionsCellTeam!: TemplateRef<TeamUser>;

  id;
  isAdd: boolean;
  objectTitle = 'User';
  busy = {
    load: null,
    submit: null,
  };
  error: string = null;
  config = Config;

  securityRoles = SecurityRole;
  handisoftSecurityRoles = HandisoftSecurityRole;

  officeUserRoles = OfficeUserRole;
  officeGridOptions: GridOptions;
  officeGridApi: GridApi;
  userOffices = [];
  userOfficesCount = 0;

  teamUserRoles = TeamUserRole;
  teamGridOptions: GridOptions;
  teamGridApi: GridApi;
  userTeams = [];
  userTeamsCount = 0;

  isProfileView = false;
  isOwnProfile = false;
  isAdmin = false;

  interactionStream = new Subject<void>();
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  user: User;
  form = this._formBuilder.group({
    id: [],
    email: [
      null,
      [
        Validators.required,
        Validators.maxLength(512),
        Validators.pattern(Patterns.emailRegExp),
      ],
    ],
    firstName: [null, [Validators.required, Validators.maxLength(512)]],
    lastName: [null, [Validators.required, Validators.maxLength(512)]],
    role: [0, Validators.required],
    inactive: [false],
    trustedAdvisor: [false],
    canSendClientRequest: [false],
  });

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _userService: UserService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    private readonly _sessionService: SessionService
  ) {
    this.isAdmin = _sessionService.permissions.isAdmin;
  }

  checkboxClicked() {
    // This is handled seperately from form value changes.
    // Reason is, value changes listens to everything (including changes triggered by API)
    // However we want to show confirmation modal only if it is user click.
    const trustedAdvisorCtrl = this.form.controls['trustedAdvisor'];

    if (!trustedAdvisorCtrl.value) {
      return;
    }

    this._modalService
      .confirmation(
        'Please confirm this user meets the requirements to be a Trusted Advisor',
        () => {
          // Do nothing. ValueChanges subscription will handle it.
        },
        false
      )
      .catch((x) => {
        trustedAdvisorCtrl.setValue(false);
      });
  }

  ngOnInit() {
    this.configureOfficeGridOptions();
    this.configureTeamGridOptions();

    const trustedAdvisorCtrl = this.form.controls['trustedAdvisor'];
    const canSendClientRequestCtrl = this.form.controls['canSendClientRequest'];

    trustedAdvisorCtrl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((val) => {
        if (val === true) {
          canSendClientRequestCtrl.clearValidators();
          canSendClientRequestCtrl.setValue(true);
          canSendClientRequestCtrl.addValidators([Validators.requiredTrue]);
          canSendClientRequestCtrl.disable();
        } else {
          canSendClientRequestCtrl.clearValidators();
          canSendClientRequestCtrl.enable();
        }
      });

    this._route.params.pipe(takeUntil(this._destroy$)).subscribe((params) => {
      this.id = this._route.snapshot.paramMap.get('id');
      if (!this.id) {
        this.id = this._sessionService.user.id;
        this.isProfileView = true;
      }
      this.isAdd = this.id === 'add';
      this.isOwnProfile = this.id === this._sessionService.user.id;
      if (this.isOwnProfile) {
        this._userService.clearInvites().subscribe();
      }
      this.loadUser();
    });

    this.configureInteraction();
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  submit() {
    this.interactionStream.next();
  }

  private configureOfficeGridOptions() {
    this.officeGridOptions = {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.office.id,
      onGridReady: (event) => (this.officeGridApi = event.api),
      columnDefs: [
        { field: 'office.name', headerName: 'Name' },
        {
          field: 'role',
          headerName: 'Security',
          type: 'enumColumn',
          cellRendererParams: { enum: this.officeUserRoles },
          width: 120,
        },
        {
          field: 'isPartner',
          headerName: 'Partner',
          type: 'booleanColumn',
          maxWidth: 120,
          minWidth: 120,
        },
        {
          field: 'isManager',
          headerName: 'Manager',
          type: 'booleanColumn',
          maxWidth: 120,
          minWidth: 120,
        },
        {
          headerName: '',
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCellOffice },
          maxWidth: 90,
          minWidth: 90,
        },
      ],
    };
  }

  private configureTeamGridOptions() {
    this.teamGridOptions = {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.team.id,
      onGridReady: (event) => (this.teamGridApi = event.api),
      columnDefs: [
        { field: 'team.name', headerName: 'Name' },
        {
          field: 'role',
          headerName: 'Security',
          type: 'enumColumn',
          cellRendererParams: { enum: this.teamUserRoles },
          width: 120,
        },
        {
          field: 'isManager',
          headerName: 'Manager',
          type: 'booleanColumn',
          maxWidth: 120,
          minWidth: 120,
        },
        {
          headerName: '',
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.optionsCellTeam },
          maxWidth: 90,
          minWidth: 90,
        },
      ],
    };
  }

  private configureInteraction() {
    this.interactionStream
      .pipe(
        tap(() => (this.error = null)),
        takeUntil(this._destroy$),
        exhaustMap(() => this.handleInteraction())
      )
      .subscribe(() => {
        this.close();
      });
  }

  private handleInteraction(): Observable<any> {
    let observable: Observable<any>;
    var loadingStream = new Subject<void>();

    const user = new User(this.form.getRawValue());
    user.userOffices = [];
    user.userTeams = [];
    this.officeGridApi.forEachNode((n) => user.userOffices.push(n.data));
    this.teamGridApi.forEachNode((n) => user.userTeams.push(n.data));
    const model = new UserModel(user);

    if (this.isAdd) {
      observable = this._userService.post(model);
    } else {
      model.id = this.id as string;
      observable = this.isAdmin
        ? this._userService.putWithSecurity(model)
        : this._userService.put(model);
    }

    this.busy.submit = loadingStream
      .pipe(takeUntil(this._destroy$))
      .subscribe();

    observable = observable.pipe(
      catchError((err) => {
        this.showError(err);
        return empty();
      }),
      finalize(() => loadingStream.complete())
    );

    return observable;
  }

  private loadUser() {
    if (this.isAdd) return;

    const userObservable = this.isOwnProfile
      ? this._userService.profile()
      : this._userService.get(this.id);

    this.busy.load = userObservable.pipe(takeUntil(this._destroy$)).subscribe(
      (user) => {
        this.user = user;
        this.form.patchValue(user);
        this.userOffices = user.userOffices.map((u) => new OfficeUser(u));
        this.userOfficesCount = this.userOffices.length;
        this.userTeams = user.userTeams.map((u) => new TeamUser(u));
        this.userTeamsCount = this.userTeams.length;
      },
      (err) => this.showError(err)
    );
  }

  changeSignIn() {
    this._authService.changeSignIn();
  }

  changePassword() {
    this._authService.changePassword();
  }

  resendInvite() {
    this._userService
      .resendInvite(this.id)
      .pipe(
        tap(() => this._messageService.success('Successfully resent invite.')),
        catchError((err) => {
          this.showError(err);
          return throwError(err);
        })
      )
      .subscribe();
  }

  addOfficeUser() {
    let user = this.form.getRawValue() as User;
    user.id = this.id as string;
    this._modalService
      .openModal(
        OfficeUserComponent,
        null,
        new OfficeUserComponentParams({ user: user } as OfficeUser, true, false)
      )
      .then((u) => {
        this.officeGridApi.applyTransaction({ add: [u] });
        this.userOfficesCount = this.officeGridApi.getDisplayedRowCount();
        this.officeGridApi.sizeColumnsToFit();
      })
      .catch(() => true);
  }

  editOfficeUser(officeUser: OfficeUser) {
    let user = this.form.getRawValue() as User;
    user.id = this.id as string;
    officeUser.user = user;
    this._modalService
      .openModal(
        OfficeUserComponent,
        null,
        new OfficeUserComponentParams(officeUser, true, false)
      )
      .then((u) => {
        this.officeGridApi.applyTransaction({ update: [u] });
      })
      .catch(() => true);
  }

  removeOfficeUser(officeUser: OfficeUser) {
    this.officeGridApi.applyTransaction({ remove: [officeUser] });
    this.userOfficesCount = this.officeGridApi.getDisplayedRowCount();
  }

  addTeamUser() {
    let user = this.form.getRawValue() as User;
    user.id = this.id as string;
    this._modalService
      .openModal(
        TeamUserComponent,
        null,
        new TeamUserComponentParams({ user: user } as TeamUser, true, false)
      )
      .then((u) => {
        this.teamGridApi.applyTransaction({ add: [u] });
        this.userTeamsCount = this.teamGridApi.getDisplayedRowCount();
        this.teamGridApi.sizeColumnsToFit();
      })
      .catch(() => true);
  }

  editTeamUser(teamUser: TeamUser) {
    let user = this.form.getRawValue() as User;
    user.id = this.id as string;
    teamUser.user = user;
    this._modalService
      .openModal(
        TeamUserComponent,
        null,
        new TeamUserComponentParams(teamUser, true, false)
      )
      .then((u) => {
        this.teamGridApi.applyTransaction({ update: [u] });
      })
      .catch(() => true);
  }

  removeTeamUser(teamUser: TeamUser) {
    this.teamGridApi.applyTransaction({ remove: [teamUser] });
    this.userTeamsCount = this.teamGridApi.getDisplayedRowCount();
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
    this._messageService.error(error);
  }
}
