import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'crs-dataset-ledger',
  templateUrl: './dataset-ledger.component.html'
})
export class DatasetLedgerComponent implements OnInit {

  datasetId: string;

  constructor(private readonly _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.params.subscribe(() => {
      this.datasetId = this._route.snapshot.parent.parent.paramMap.get('id');
    });
  }

}
