import { Component } from '@angular/core';

@Component({
  selector: 'crs-master-matching-rules',
  templateUrl: './master-matching-rules.component.html',
  styleUrls: ['./master-matching-rules.component.scss']
})
export class MasterMatchingRulesComponent {

}
