<div class="modal-content b-comment mt-3 p-3">
  <div class="d-flex justify-content-between">
    <div class="d-flex justify-content-between user-info">
      <img
        class="profile me-1"
        *ngIf="comment.userInfo?.userImage"
        [src]="comment.userInfo.userImage"
      />
      <span class="user-name">
        {{ comment.userInfo?.userName }}
      </span>
    </div>
    <div class="date-info">
      {{ comment.createdDate | commentDateAgo }}
    </div>
  </div>
  <div class="d-flex justify-content-between" [formGroup]="form">
    <div
      class="comment me-2 text-justify"
      *ngIf="!editMode"
      [innerHTML]="replaceNewLine(comment.message)"
    ></div>
    <textarea
      class="form-control"
      class="me-5 mt-2 ng-star-inserted p-2 w-100"
      formControlName="message"
      *ngIf="editMode"
    ></textarea>
    <div class="d-flex justify-content-center b-actions" *ngIf="hasActions">
      <button
        class="btn-sm fas-btn-primary-ghost"
        title="Save Edit"
        type="button"
        *ngIf="editMode"
        (click)="saveEdit()"
      >
        <i aria-hidden="true" class="fas fa-check"></i>
      </button>
      <button
        class="btn-sm fas-btn-primary-ghost"
        title="Edit comment"
        type="button"
        *ngIf="!editMode"
        (click)="toggleEditMode()"
      >
        <i aria-hidden="true" class="fas fa-edit"></i>
      </button>
      <button
        class="btn-sm fas-btn-ghost"
        title="Cancel Edit"
        type="button"
        *ngIf="editMode"
        (click)="toggleEditMode()"
      >
        <i aria-hidden="true" class="fas fa-ban"></i>
      </button>
      <button
        class="btn-sm fas-btn-ghost"
        title="Remove comment"
        type="button"
        (click)="delete()"
      >
        <i aria-hidden="true" class="fas fa-trash"></i>
      </button>
    </div>
  </div>
</div>
