<div role="alert" *ngIf="error" [ngClass]="getErrorClasses()">
  <span style="display: block; white-space: pre-wrap" *ngIf="!isArray">
    <i
      aria-hidden="true"
      class="fa fa-times-circle mr-1 error-icon"
      *ngIf="!isWarning && !isInfo"
    ></i>
    <i
      aria-hidden="true"
      class="fa fa-exclamation-triangle mr-1 warning-icon"
      *ngIf="isWarning"
    ></i>
    <i
      aria-hidden="true"
      class="fa fa-exclamation-circle mr-1 error-icon"
      *ngIf="isInfo"
    ></i>
    {{ error }}
  </span>

  <ng-container *ngIf="isArray">
    <div class="d-flex">
      <i
        aria-hidden="true"
        class="fa fa-times-circle mr-1 py-1 error-icon"
        *ngIf="!isWarning && !isInfo"
      ></i>
      <i
        aria-hidden="true"
        class="fa fa-exclamation-triangle mr-1 py-1 warning-icon"
        *ngIf="isWarning"
      ></i>
      <i
        aria-hidden="true"
        class="fa fa-exclamation-circle mr-1 py-1 error-icon"
        *ngIf="isInfo"
      ></i>
      <div>
        <p *ngFor="let err of error">
          <span style="display: block; white-space: pre-wrap">{{ err }}</span>
        </p>
      </div>
    </div>
  </ng-container>

  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="dismissError()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
