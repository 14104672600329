import { Source } from '../sourcedata';
import { User, Entity } from '../../firm';
import { DateHelpers } from 'src/app/shared/utilities/date-helpers';

export enum ImportFrequency {
  Annual = 0,
  Quarterly = 1,
  Monthly = 2,
  'All Transactions' = 6,
}

export enum TransactionDetail {
  PeriodMovement, 
  TrialBalance,
}

export class Dataset {
  id: string;
  fileId: string;
  entity: Entity;
  entityId: string;
  name: string;
  source: Source;
  importFrequency: ImportFrequency;
  transactionDetail: TransactionDetail;
  startDate: Date;
  endDate: Date;
  isGroupDataset: boolean;
  openingBalanceDataset: Dataset;
  lastImported: Date;
  lastImportedBy: User;
  groupDatasets: Dataset[];

  constructor(data: any) {
    Object.assign(this, data);
    if (data.source) this.source = new Source(data.source);
    if (data.openingBalanceDataset) {
      this.openingBalanceDataset = new Dataset(data.openingBalanceDataset);
    }
    if (data.lastImportedBy) {
      this.lastImportedBy = new User(data.lastImportedBy);
    }
    if (data.entity) this.entity = new Entity(data.entity);
    if (data.groupDatasets)
      this.groupDatasets = data.groupDatasets.map((g) => new Dataset(g));
    this.startDate = DateHelpers.dateFromApiResponse(data.startDate);
    this.endDate = DateHelpers.dateFromApiResponse(data.endDate);
    this.lastImported = DateHelpers.dateFromApiResponse(data.lastImported);
  }
}

export class DatasetModel {
  id: string;
  fileId: string;
  entityId: string;
  name: string;
  sourceId: string;
  importFrequency: ImportFrequency;
  transactionDetail: TransactionDetail;
  startDate: Date;
  endDate: Date;
  isGroupDataset: boolean;
  sourceDatasetId: string;
  openingBalanceDatasetId: string;
  groupDatasetIds: string[];

  constructor(data: any) {
    this.id = data.id;
    this.fileId = data.fileId;
    this.entityId = data.entityId;
    this.name = data.name;
    this.sourceId = data.sourceId;
    this.importFrequency = data.importFrequency;
    this.transactionDetail = data.transactionDetail;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.isGroupDataset = data.isGroupDataset;
    this.sourceDatasetId = data.sourceDatasetId;
    this.openingBalanceDatasetId = data.openingBalanceDatasetId;
    this.groupDatasetIds = data.groupDatasetIds;

    if (data.entity) this.entityId = data.entity.id;
    if (data.source) this.sourceId = data.source.id;
    if (data.sourceDataset) this.sourceDatasetId = data.sourceDataset.id;
    if (data.openingBalanceDataset)
      this.openingBalanceDatasetId = data.openingBalanceDataset.id;
    if (data.groupDatasets)
      this.groupDatasetIds = data.groupDatasets.map((g) => g.id);
  }
}
