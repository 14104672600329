<form [formGroup]="form" (submit)="submit()" novalidate>
  <div class="form-group" *ngIf="selectDivision">
    <label class="control-label">Division</label>
    <ng-select
      [items]="divisions | async"
      bindLabel="name"
      formControlName="division"
      [clearable]="false"
    ></ng-select>
  </div>
  <div class="form-group" *ngIf="selectSource">
    <label class="control-label">Source</label>
    <ng-select
      [items]="sources | async"
      bindLabel="name"
      formControlName="source"
      [clearable]="false"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm"
      >
        <img
          [src]="item?.sourceType?.icon"
          class="icon-img me-2 align-middle"
          [alt]="item?.sourceType?.name"
        />
        <span [ngOptionHighlight]="search">{{ item.name }}</span>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        <img
          [src]="item.sourceType.icon"
          class="icon-img me-2 align-middle"
          [alt]="item?.sourceType?.name"
          *ngIf="item?.sourceType?.icon"
        />
        {{ item.name }}
      </ng-template>
    </ng-select>
  </div>
  <div class="form-group">
    <label class="control-label">Source Division</label>
    <ng-select
      [items]="sourceDivisions | async"
      [loading]="loading"
      [typeahead]="userInput"
      [virtualScroll]="true"
      formControlName="sourceDivision"
    >
      <ng-template ng-label-tmp let-item="item" let-search="searchTerm">
        <span [ngOptionHighlight]="search"
          ><strong>{{ item.code }}</strong> {{ item.name }}</span
        >
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <span [ngOptionHighlight]="search"
          ><strong>{{ item.code }}</strong> {{ item.name }}</span
        >
      </ng-template>
    </ng-select>
  </div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu">
    <button type="submit" class="btn btn-primary">Add</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
      Cancel
    </button>
  </div>
</form>
