<div class="pad-x">
  <div>
    <a [routerLink]="[]" (click)="close()">
      <i aria-hidden="true" class="fas fa-angle-double-left"></i>
      Return to Asset Groups
    </a>
    <div class="spacer"></div>
  </div>

  <h2 class="form-group-title">{{ objectTitle }}</h2>

  <form [formGroup]="form" (validSubmit)="submit()">
    <div>
      <crs-busy *ngIf="busy.loading"></crs-busy>

      <div class="row">
        <div class="col-form-md">
          <div class="form-group">
            <label class="control-label">Name</label>
            <input class="form-control" formControlName="name" type="text" />
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">Automated Depreciation</div>
        <div class="card-body">
          <div class="mt-3">
            <crs-asset-group-journal-settings
              *ngFor="let settingForm of settingsForms.controls; let i = index"
              [formGroup]="settingForm"
              (remove)="removeSetting(i)"
            ></crs-asset-group-journal-settings>
          </div>

          <div class="card card-insert-secondary">
            <div class="card-body">
              Select a source to add depreciation accounts:
              <div class="row">
                <div class="col-md-6 col-sm-8">
                  <div class="d-flex">
                    <crs-source-select-simple
                      class="flex-grow-1"
                      [formControl]="addSource"
                    ></crs-source-select-simple>
                    <button
                      class="btn btn-secondary ml-2"
                      type="button"
                      [disabled]="!readyToAddSource"
                      (click)="addSetting()"
                    >
                      Add
                    </button>
                  </div>
                  <small
                    class="form-text text-muted"
                    *ngIf="!readyToAddSource && addSource.value"
                  >
                    You have already set up journal accounts for this source
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <crs-form-error [error]="error"></crs-form-error>
    </div>

    <div class="btn-menu">
      <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
        {{ isAdd ? 'Add' : 'Save' }} {{ objectTitle }}
      </button>
      <button class="btn btn-secondary" type="button" (click)="close()">
        Cancel
      </button>
    </div>
  </form>
</div>
