<fieldset>
  <div cdkDropList (cdkDropListDropped)="dropPage($event)">
    <div
      cdkDrag
      class="card card-pop my-4"
      *ngFor="let p of formArray.controls; let i = index"
      [cdkDragData]="i"
      [id]="'column' + i"
    >
      <crs-report-master-page
        [formGroup]="p"
        [index]="i"
        [masterGroup]="masterGroup"
        [pageId]="p.value.id"
        (removed)="removePage($event)"
      >
        <!-- Workaround for cdkDragHandle - Delete this once angular material gets a fix for allowing this in child components, refer https://github.com/angular/material2/issues/13784</ng-content> -->
        <div cdkDragHandle class="card-header-left draggable">
          <i aria-hidden="true" class="fas fa-bars"></i>
          Page {{ i + 1 }}
        </div>
      </crs-report-master-page>
    </div>

    <div class="card card-insert">
      <div class="card-body">
        <div class="btn-group">
          <button
            aria-expanded="false"
            aria-haspopup="true"
            class="btn btn-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            id="insertPageDropDownButton"
            type="button"
          >
            <i aria-hidden="true" class="fas fa-plus inset"></i>
            Add Page
          </button>
          <div
            aria-labelledby="insertPageDropDownButton"
            class="dropdown-menu dropdown-menu-overflow"
          >
            <ng-container
              *ngFor="let group of filteredPageTypes; let groupIndex = index"
            >
              <div class="dropdown-divider" *ngIf="groupIndex !== 0"></div>
              <h6 class="dropdown-header">
                {{ pageCategories[group.key] | sentence }}
              </h6>
              <button
                class="dropdown-item"
                id="dropdown-group-{{ groupIndex }}-item-{{ itemIndex }}"
                type="button"
                *ngFor="let type of group.items; let itemIndex = index"
                (click)="addPage(type)"
              >
                {{ type.name }}
                <small
                  ><span
                    class="badge badge-success ms-1 new-tag"
                    *ngIf="shouldDisplayNewBadge(type)"
                    >New</span
                  ></small
                >

                <small
                  ><span
                    class="badge badge-success ms-1 new-tag"
                    *ngIf="!type.isMaster"
                    >Custom</span
                  ></small
                >
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
