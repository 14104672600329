<div class="row" *ngIf="activatedRoute.children.length === 0">
  <div class="col-12" [ngBusy]="busy.load">
    <crs-form-error [error]="error"></crs-form-error>

    <div>
      <crs-busy *ngIf="busy.load"></crs-busy>
      <ag-grid-angular
        class="ag-theme-material ag-grid-full"
        [gridOptions]="gridOptions"
        [rowData]="templates"
        (cellValueChanged)="onValueChanged($event)"
      />
    </div>
  </div>
</div>
<ng-template #optionsCellOffice let-row>
  <div class="btn-mini-menu">
    <button
      class="btn-sm fas-btn-primary-ghost"
      title="Edit Report Template"
      type="button"
      (click)="editReportTemplate(row)"
    >
      <i aria-hidden="true" class="fas fa-edit"></i>
    </button>
    <button
      class="btn-sm fas-btn-ghost"
      title="Remove Report Template"
      type="button"
      (click)="openRemoveReportTemplate(row)"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
    </button>
  </div>
</ng-template>
