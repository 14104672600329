import { Injectable, Injector } from '@angular/core';
import { ApiService } from '../../../../../core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ReportOutput } from '../../../../../accounting/reports/reportViewer/report-output';
import { BacoTransactionStore } from '../../baco-transaction.store';
import { BacoReportEnum } from '../../../../enums';
import { BacoFeedStore } from '../../baco-feed.store';

@Injectable({
  providedIn: 'root',
})
export class BacoReportService {
  readonly base: string = 'api/baco/reports';

  constructor(
    private readonly apiService: ApiService,
    private injector: Injector
  ) {}

  public get$(reportType: BacoReportEnum): Observable<ReportOutput> {
    const transactionStore = this.injector.get(BacoTransactionStore);
    const bacoFeedStore = this.injector.get(BacoFeedStore);

    return combineLatest([
      bacoFeedStore.feed$.pipe(filter((feed) => feed.state == 'SUCCESS')),
      transactionStore.dateRange$,
    ]).pipe(
      switchMap(([feedState, dateRange]) => {
        let url = `${this.base}/${reportType}`;
        url = this.apiService.addQuery(url, 'feedId', feedState.data.id);
        url = this.apiService.addQuery(url, 'startDate', dateRange.startDate);
        url = this.apiService.addQuery(url, 'endDate', dateRange.endDate);
        url = this.apiService.addQuery(
          url,
          'timeZoneOffsetInMin',
          new Date().getTimezoneOffset()
        );
        return this.apiService.get<ReportOutput>(url);
      })
    );
  }
}
