<div style="overflow: hidden; flex-grow: 1">
  <crs-busy *ngIf="busy.accounts" />

  <ag-grid-angular
    #agGrid
    class="ag-theme-material"
    rowSelection="multiple"
    style="width: 100%; height: 100%"
    [enableRangeSelection]="true"
    [frameworkComponents]="renderers"
    [gridOptions]="gridOptions"
    [rowData]="accounts"
    [suppressCellSelection]="false"
  >
    <ag-grid-column
      colId="accountNo"
      field="accountNo"
      headerName="Account No"
    />
    <ag-grid-column
      colId="accountName"
      field="accountName"
      headerName="Account Name"
    />
    <ag-grid-column
      cellRenderer="classificationRenderer"
      colId="classification"
      field="classification"
      headerName="Class"
      tooltipField="classificationTopLevelLabel"
    />
    <ag-grid-column colId="header" field="headerName" headerName="Header" />
    <ag-grid-column
      colId="accountType"
      field="accountType.name"
      headerName="Account Type"
    />
    <ag-grid-column
      colId="entityTypes"
      field="entityTypes"
      headerName="Entity Types"
    />
    <ag-grid-column
      colId="taxCode"
      field="masterTaxCode"
      headerName="Tax Code"
    />
    <ag-grid-column
      pinned="none"
      type="optionsColumn"
      [cellRendererParams]="{ ngTemplate: optionsCell }"
    />
  </ag-grid-angular>
</div>

<ng-template #optionsCell let-row>
  <div class="btn-mini-menu">
    <a
      aria-expanded="false"
      aria-haspopup="true"
      class="btn-sm fas-btn-primary-ghost"
      data-toggle="dropdown"
    >
      <i aria-hidden="true" class="fas fa-ellipsis-v"></i>
      <span class="sr-only">Show Account Options</span>
    </a>
    <div class="dropdown-menu">
      <button
        class="btn dropdown-item text-info"
        type="button"
        (click)="onClickEditAccount(row)"
      >
        Edit Account
      </button>
      <button
        class="btn dropdown-item text-danger"
        type="button"
        *ngIf="row.isSortable"
        (click)="onClickDeleteAccount(row)"
      >
        Delete Account
      </button>
    </div>
  </div>
</ng-template>
