import { SecurityRole } from './securityRole';
import {} from 'ngx-gravatar';

export class User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  tenantId: string;
  role: SecurityRole;
  inactive: boolean;
  invitePending: boolean;
  inviteExpired: boolean;
  status: string;
  offices: any[];
  userOffices: any[];
  userTeams: any[];
  trustedAdvisor: boolean;
  canSendClientRequest: boolean;
  bankFeed: boolean;
  ledger: boolean;
  createdDate: Date;

  get displayName() {
    return this.firstName + ' ' + this.lastName;
  }
  set displayName(value) {}

  get icon(): UserImage {
    if (!this.firstName || !this.lastName) return null;
    var size = 50;
    var fallback = `https://ui-avatars.com/api/${this.firstName[0]}${this.lastName[0]}/${size}/E5173F/fff`;
    return new UserImage(this.email, size, fallback);
  }

  get image(): UserImage {
    if (!this.firstName || !this.lastName) return null;
    var size = 150;
    var fallback = `https://ui-avatars.com/api/${this.firstName[0]}${this.lastName[0]}/${size}/E5173F/fff`;
    return new UserImage(this.email, size, fallback);
  }

  constructor(data: any) {
    Object.assign(this, data);
    if (data.offices) this.offices = data.offices;
    if (data.userOffices) this.userOffices = data.userOffices;
    if (data.userTeams) this.userTeams = data.userTeams;
  }
}

export class UserModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: SecurityRole;
  inactive: boolean;
  userOffices: any[];
  userTeams: any[];

  constructor(data: User) {
    Object.assign(this, data);
    if (data.userOffices)
      this.userOffices = data.userOffices.map((uo) => {
        return {
          officeId: uo.office ? uo.office.id : uo.officeId,
          role: uo.role,
          isPartner: uo.isPartner,
          isManager: uo.isManager,
          isDefaultPartner: uo.isDefaultPartner,
        };
      });
    if (data.userTeams)
      this.userTeams = data.userTeams.map((uo) => {
        return {
          teamId: uo.team ? uo.team.id : uo.teamId,
          role: uo.role,
          isManager: uo.isManager,
        };
      });
  }
}

export class UserImage {
  email: string;
  size: number;
  source: string;

  constructor(email: string, size: number, source: string) {
    this.email = email;
    this.size = size;
    this.source = source;
  }
}
