<div class="d-flex justify-content-between pad">
  <div class="icon-input-wrapper flex-grow-1 mr-2">
    <i aria-hidden="true" class="fas fa-search input-icon"></i>
    <input
      aria-describedby="searchIcon"
      placeholder="Rule Search..."
      type="search"
      [(ngModel)]="search"
    />
  </div>

  <button class="btn btn-primary" (click)="addNewRule()">
    <span>Add rule </span>
    <i aria-hidden="true" class="fas fa-plus"></i>
  </button>
</div>
<div class="row">
  <crs-rule-table class="col" [search]="search"></crs-rule-table>
</div>
