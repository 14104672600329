<div class="modal-header">
  <h4 class="modal-title">
    Disclosure Options
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

  <div class="modal-body">

    <div class="row">
      <div class="col-form-md">

        <div class="form-group">
          <label class="control-label">Disclosure</label>
          <ng-select [items]="params.disclosures" [placeholder]="params.layoutOption.disclosureLinkDisclosure ? 'Auto: ' + params.layoutOption.disclosureLinkDisclosure.name : null"
            bindLabel="name" formControlName="disclosure"></ng-select>
          <small class="form-text text-muted" *ngIf="isDefaultLinkOption && params.layoutOption.disclosureLinkDisclosure">
            You can remove this automated disclosure by setting the <strong>Include?</strong> setting of this disclosure to <strong>No</strong> in the <strong>Notes to the Financial Statements</strong> page
          </small>
        </div>

        <div class="form-group" [class.required]="!isDefaultLinkOption" *ngIf="!hasNoDisclosure">
          <label class="control-label">Link Option</label>
          <ng-select [items]="linkOptions | enumToArray" [placeholder]="defaultLinkOption"
            bindLabel="name" bindValue="value" [readonly]="isDefaultLinkOption && params.layoutOption.forceLinkOption" formControlName="linkOption"></ng-select>
        </div>

        <div class="form-group" *ngIf="!hasNoDisclosure && isAppendDetail">
          <label class="control-label">Placeholder Identifier</label>
          <input class="form-control" type="search" [placeholder]="defaultPlaceholder" formControlName="placeholderIdentifier">
        </div>

      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  </div>

</form>
