import { Injectable } from '@angular/core';
import { ApiService } from '../../core';
import { Observable, of } from 'rxjs';
import {
  BacoBankConnectionDto,
  BacoConnectionValidationDto,
  BacoTransactionValidationDto,
  UpdateSchedulingSettingsModel,
} from '../interfaces';
import { DateHelpers } from 'src/app/shared/utilities/date-helpers';
import { delay } from 'rxjs/operators';
import {
  RuleMovementType,
  TransactionImportType,
  BankAccountState,
} from '../enums';

@Injectable()
export class BankConnectionSettingsService {
  private readonly base: string = 'api/baco/bank-connection-settings';

  constructor(private readonly _apiService: ApiService) {}

  public updateSchedulingSettings(
    bankConnectionId: string,
    model: UpdateSchedulingSettingsModel
  ) {
    const body = {
      ...model,
      commencementLocalDate: DateHelpers.convertDateToJsonString(
        model.commencementLocalDate
      ),
    };
    return this._apiService.post<string>(
      `${this.base}/${bankConnectionId}/update-scheduling-settings`,
      body
    );
  }

  public importFeedDataNow(
    bankConnectionId: string,
    dataStartDate: Date
  ): Observable<boolean> {
    let url = `${this.base}/${bankConnectionId}/import-feeds-now`;
    url = this._apiService.addQuery(url, 'dataStartDate', dataStartDate);

    return this._apiService.get<boolean>(url);
  }

  public requestAccountReconnection(
    bankConnectionId: string
  ): Observable<boolean> {
    return this._apiService.get<boolean>(
      `${this.base}/${bankConnectionId}/request-account-reconnection`
    );
  }

  public getBankAccountSettings(
    bankConnectionId: string
  ): Observable<UpdateSchedulingSettingsModel> {
    return this._apiService.get<UpdateSchedulingSettingsModel>(
      `${this.base}/${bankConnectionId}`
    );
  }

  public subscribeAllAkahuWebhooks(
    bankConnectionId: string
  ): Observable<BacoBankConnectionDto[]> {
    return this._apiService.get<BacoBankConnectionDto[]>(
      `${this.base}/${bankConnectionId}/subscribe-akahu-webhooks`
    );
  }

  public retrieveValidationStartDate(
    bankConnectionId: string
  ): Observable<Date> {
    let url = `${this.base}/${bankConnectionId}/fetch-validation-start-date`;
    return this._apiService.get(url);
  }

  public validateData(
    bankConnectionId: string,
    validationStartDate: Date
  ): Observable<BacoConnectionValidationDto> {
    let url = `${this.base}/${bankConnectionId}/validate`;
    url = this._apiService.addQuery(
      url,
      'validationStartDate',
      validationStartDate
    );
    return this._apiService.get(url);
  }

  public deleteMismatchedTransactions(
    bankConnectionId: string,
    mismatchedTransactionIds: string[]
  ): Observable<boolean> {
    let url = `${this.base}/${bankConnectionId}/delete-mismatched-transactions`;
    const body = { mismatchedTransactionIds };
    return this._apiService.post<boolean>(url, body);
  }
}
