import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../../core';
import { DisclosureTemplate } from './disclosure-template';

@Injectable({
  providedIn: 'root'
})
export class DisclosureTemplateService {

  readonly base = 'api/accounting/reports/notes/disclosure-templates';

  constructor(private readonly _apiService: ApiService) { }

  getAll(fileId: string = null) {
    let url = this.base;
    url = this._apiService.addQuery(url, 'fileId', fileId);
    return this._apiService.get<DisclosureTemplate[]>(url).pipe(map(c => c.map(p => new DisclosureTemplate(p))));
  }

  get(id: string) {
    return this._apiService.get<DisclosureTemplate>(this.base + '/' + id).pipe(map(c => new DisclosureTemplate(c)));
  }

  post(policy: DisclosureTemplate): Observable<string> {
    if (policy.fileId) return this._apiService.post<string>(this.base + '/master/file', policy);
    else return this._apiService.post<string>(this.base + '/master/firm', policy);
  }

  createInherited(master: DisclosureTemplate, fileId: string = null): Observable<string> {
    const model = {
      masterTemplateId: master.id,
      fileId: fileId
    };
    return this._apiService.post<string>(this.base + '/inherit', model);
  }

  reorderFirmDisclosureTemplate(policyId: string, index: number) {
    let url = this.base + '/' + policyId + '/reorder/firm';
    url = this._apiService.addQuery(url, 'index', index);
    return this._apiService.put(url, null);
  }

  reorderFileDisclosureTemplate(policyId: string, orderBehindId: string) {
    let url = this.base + '/' + policyId + '/reorder/file';
    url = this._apiService.addQuery(url, 'orderBehindId', orderBehindId);
    return this._apiService.put(url, null);
  }

  put(policy: DisclosureTemplate) {
    return this._apiService.put(this.base + '/' + policy.id, policy);
  }

  delete(id: string) {
    return this._apiService.delete(this.base + '/' + id);
  }
}
