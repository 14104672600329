<div class="modal-header">
  <h4 class="modal-title">{{ objectTitle }}</h4>
  <button aria-label="Close" class="close" type="button" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <crs-form-error [error]="error" />

  <mat-stepper #stepper>
    <mat-step [stepControl]="stepOneForm">
      <form [formGroup]="stepOneForm">
        <div class="form-container row">
          <div class="col-6">
            <i class="fas fa-globe-asia align-middle global-icon"></i>
            <h3 class="control-label">Choose your country</h3>
            <span>Please select your country first to select your bank</span>
          </div>
          <div class="form-group col-6">
            <div class="d-flex select-country">
              <input
                formControlName="country"
                id="australia"
                type="radio"
                [value]="CountryList.Australia"
              />
              <label class="control-label pl-2" for="australia">
                Australia
              </label>
            </div>
            <div class="d-flex select-country">
              <input
                formControlName="country"
                id="newZealand"
                type="radio"
                [value]="CountryList.NewZealand"
              />
              <label class="control-label pl-2" for="newZealand">
                New Zealand
              </label>
            </div>
          </div>
        </div>
        <hr />
        <div class="form-container row">
          <div class="col-6">
            <h3 class="control-label">Select Option</h3>
          </div>
          <div class="form-group col-6">
            <div
              class="d-flex select-country"
              *ngIf="permissions.canSendClientRequest"
            >
              <input
                formControlName="connectionType"
                id="bankConnection"
                type="radio"
                [value]="BankConnectionTypeList.BankConnection"
              />
              <label class="control-label pl-2" for="bankConnection">
                Bank Connection
              </label>
            </div>
            <div class="d-flex select-country">
              <input
                formControlName="connectionType"
                id="manualImport"
                type="radio"
                [value]="BankConnectionTypeList.ManualImport"
              />
              <label class="control-label pl-2" for="manualImport">
                Manual import
              </label>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="bankConnectionForm">
      <form [formGroup]="bankConnectionForm" (validSubmit)="onSubmit()">
        <div class="row">
          <div class="col-6 email-text">
            <h3 class="control-label">Enter Client Details</h3>
            <span>
              Please provide the details of the client that will complete the
              bank connection. Client will receive an email with instructions in
              few minutes.
            </span>
          </div>
          <div class="client-info-container col-6">
            <div class="form-group required">
              <label class="control-label">Client Name</label>
              <input
                class="form-control"
                formControlName="clientName"
                placeholder="Client Name"
                type="text"
              />
            </div>
            <div class="form-group required">
              <label class="control-label">Email</label>
              <input
                class="form-control"
                formControlName="email"
                placeholder="Email"
                type="text"
              />
            </div>
            <div class="form-group required">
              <label class="control-label">Advisor</label>
              <crs-user-select
                formControlName="user"
                [clearable]="false"
              ></crs-user-select>
            </div>
            <div class="form-group required">
              <label class="control-label">Commencement Date</label>
              <crs-date-picker
                formControlName="commencementLocalDate"
              ></crs-date-picker>
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary request-btn"
                type="submit"
                [disabled]="bankConnectionForm.invalid"
                [promiseBtn]="busy.submit"
              >
                Send Request
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="manualInputForm">
      <form [formGroup]="manualInputForm" (validSubmit)="onSubmit()">
        <div class="row">
          <div class="col-6 email-text">
            <h3 class="control-label">Add Bank Details</h3>
            <span>
              Please provide the account details of for manual import.
            </span>
          </div>
          <div class="client-info-container col-6">
            <div class="form-group required">
              <label class="control-label">Account Name</label>
              <input
                class="form-control"
                formControlName="accountName"
                placeholder="Account Name"
                type="text"
              />
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary request-btn"
                type="submit"
                [disabled]="manualInputForm.invalid"
                [promiseBtn]="busy.submit"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <div class="request-completed-text">
        <h3>Request is sent</h3>
        <span class="d-flex mb-1">
          Client has 7 days to complete this bank connection. You can follow the
          status of the connection in the main page.
        </span>
      </div>
      <hr />
      <div class="form-inline row p-3">
        <p>
          The authorisation flow can be also completed by providing the client
          with the following link.
        </p>
        <button
          class="btn btn-primary mt-1"
          [cdkCopyToClipboard]="redirectUri"
          (cdkCopyToClipboardCopied)="copiedToClipboard()"
        >
          Authorisation Link
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>

<div class="modal-footer" *ngIf="getCurrentStep() == formFlowStepsLegacy.FirstStep">
  <button
    class="btn btn-primary"
    type="button"
    [disabled]="stepOneForm.invalid"
    (click)="goForward()"
  >
    Continue
  </button>
  <button class="btn btn-secondary" type="button" (click)="closeModal()">
    Cancel
  </button>
</div>
