import { Entity, Client } from 'src/app/firm';

export class File {
  id: string;
  clientId: string;
  entity: Entity;
  entities: Entity[];
  name: string;
  isConsolidated: boolean;
  default: boolean;
  defaultEntityTypeId: number;

  constructor(data) {
    Object.assign(this, data);
    if (data.entity) {
      this.entity = new Entity(data.entity);
      this.defaultEntityTypeId = this.entity.entityTypeId;
    }
    if (data.entities) {
      this.entities = data.entities.map(e => new Entity(e));
      if (!this.defaultEntityTypeId && this.entities && this.entities.length) this.defaultEntityTypeId = this.entities[0].entityTypeId;
    }
  }
}

export class FileModel {
  id: string;
  clientId: string;
  entityId: string;
  name: string;

  constructor(data) {
    this.id = data.id;
    this.clientId = data.clientId;
    this.entityId = data.entityId;
    this.name = data.name;
  }
}

export class FileSearchResult {
  id: string;
  name: string;
  client: Client;
  entity: Entity;

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    if (data.client) this.client = new Client(data.client);
    if (data.entity) this.entity = new Entity(data.entity);
  }
}
