import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/core";
import { SourceType } from "../../sourcedata";
import { map } from "rxjs/operators";
import { StandardAccountSetting } from "./standard-account";

@Injectable({
    providedIn: 'root',
  })

  export class StandardAccountSettingService {
    readonly base: string = 'api/chart-of-accounts/settings';
  
    constructor(private apiService: ApiService) {}

    getSetting$(): Observable<StandardAccountSetting> {
      let url = this.base;
      return this.apiService.get<StandardAccountSetting>(url);
    }

    getTypes() {
      let url = this.base + '/master-settings';
      return this.apiService.get<SourceType[]>(url).pipe(map(c => c.map(c1 => 
        new SourceType(c1)
        )));
    }

    activate(id: number, chart: string = '') {
      let url = this.base + '/activate/';
      url = this.apiService.addQuery(url, 'settingId', id);
      url = this.apiService.addQuery(url, 'chartName', chart);
      return this.apiService.post(url, chart);
    }
  
    deactivate() {
      let url = this.base + '/deactivate/';
      return this.apiService.post(url, true);
    }
  
    isActive$(): Observable<boolean> {
      let url = `${this.base}/is-active/`;
      return this.apiService.get<boolean>(url, true);
    }
  }