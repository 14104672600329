import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { UserIconComponent } from './users/user-icon/user-icon.component';
import { ClientsComponent } from './clients/clients/clients.component';
import { ClientComponent } from './clients/client/client.component';
import { EntitiesComponent } from './entities/entities/entities.component';
import { EntityComponent } from './entities/entity/entity.component';
import { UserSelectComponent } from './users/user-select/user-select.component';
import { AdminCentreComponent } from './admin/admin-centre/admin-centre.component';
import { OfficesComponent } from './offices/offices/offices.component';
import { OfficeComponent } from './offices/office/office.component';
import { TeamComponent } from './teams/team/team.component';
import { TeamsComponent } from './teams/teams/teams.component';
import { OfficeUserComponent } from './offices/office-user/office-user.component';
import { OfficeSelectComponent } from './offices/office-select/office-select.component';
import { TeamSelectComponent } from './teams/team-select/team-select.component';
import { TeamUserComponent } from './teams/team-user/team-user.component';
import { FileCreateComponent } from './entities/file-create/file-create.component';
import { UsersComponent } from './users/users/users.component';
import { UserComponent } from './users/user/user.component';
import { UserInviteAcceptComponent } from './users/user-invite-accept/user-invite-accept.component';
import { AddressFormComponent } from './model/address-form/address-form.component';
import { EntitySelectModalComponent } from './entities/entity-select-modal/entity-select-modal.component';
import { ClientEntitySearchComponent } from './clients/client-entity-search/client-entity-search.component';
import { ClientSearchResultComponent } from './clients/client-search-result/client-search-result.component';
import { EntitySearchResultComponent } from './entities/entity-search-result/entity-search-result.component';
import { RegisterComponent } from './register/register.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuditorComponent } from './auditor/auditor.component';
import { TrusteeFormComponent } from './model/trustee-form/trustee-form.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    UserIconComponent,
    ClientsComponent,
    ClientComponent,
    EntitiesComponent,
    EntityComponent,
    UserSelectComponent,
    AdminCentreComponent,
    OfficesComponent,
    OfficeComponent,
    TeamComponent,
    TeamsComponent,
    OfficeUserComponent,
    OfficeSelectComponent,
    TeamSelectComponent,
    TeamUserComponent,
    FileCreateComponent,
    UsersComponent,
    UserComponent,
    UserInviteAcceptComponent,
    AddressFormComponent,
    EntitySelectModalComponent,
    ClientEntitySearchComponent,
    ClientSearchResultComponent,
    EntitySearchResultComponent,
    RegisterComponent,
    SignUpComponent,
    AuditorComponent,
    TrusteeFormComponent
  ],
  exports: [
    UserIconComponent,
    UserSelectComponent,
    OfficeSelectComponent
  ]
})
export class FirmModule { }
