import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { MessageService, ModalService } from 'src/app/core';
import { accountingRenderers } from 'src/app/shared/ag-grid';
import { getDefaultGridOptions } from 'src/app/shared/ag-grid/defaultGridOptions';
import { StandardTaxCode } from '../standard-tax-code';
import {
  StandardTaxCodeFormComponent,
  TaxCodeFormType,
} from '../standard-tax-code-form/standard-tax-code-form.component';
import { StandardTaxCodeService } from '../standard-tax-code.service';
import { SortHelpers } from 'src/app/shared/utilities/sort-helpers';

@Component({
  selector: 'crs-standard-tax-codes-table',
  templateUrl: './standard-tax-codes-table.component.html',
})
export class StandardTaxCodesTableComponent implements OnInit, OnChanges {
  @Input() search: string;
  @Input() selectedPostingAccountNo: string;
  @Input() selectedBasCode: string;

  @Output() fetchBasCodes: EventEmitter<Partial<string>[]> = new EventEmitter();
  @Output() fetchPostingAccounts: EventEmitter<Partial<string>[]> =
    new EventEmitter();

  busy = {
    taxCodes: false,
    accounts: false,
  };
  error: string = null;
  gridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;
  taxCodes: StandardTaxCode[] = null;

  sortValueComparator = SortHelpers.sortValueComparator;

  constructor(
    private modalService: ModalService,
    private standardTaxCodeService: StandardTaxCodeService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchTaxCodes();
  }

  ngOnChanges({
    search: searchChange,
    selectedPostingAccountNo: selectedPostingAccountNoChange,
    selectedBasCode: selectedBasCodeChange,
  }: SimpleChanges): void {
    if (!this.gridOptions.api) {
      return;
    }

    if (searchChange) {
      this.gridOptions.api.setQuickFilter(this.search);
    }

    if (selectedBasCodeChange) {
      this.setFilter('basCode', this.selectedBasCode);
    }

    if (selectedPostingAccountNoChange) {
      this.setFilter('postingAccountNo', this.selectedPostingAccountNo);
    }
  }

  private setFilter(column: string, value: string): void {
    const filter = this.gridOptions.api.getFilterInstance(column);

    if (value === null) {
      filter.setModel(value);
    } else if (value == '') {
      filter.setModel({ values: [value, null] });
    } else {
      filter.setModel({ values: [value] });
    }

    this.gridOptions.api.onFilterChanged();
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error, true);
  }

  onClickEditTaxCode(taxCode: StandardTaxCode): void {
    this.modalService
      .openModal(StandardTaxCodeFormComponent, taxCode.id, {
        taxCodesTable: this,
        taxCode: taxCode,
      })
      .then(() => {
        this.fetchTaxCodes();
        this.messageService.success('Tax code saved successfully.');
      })
      .catch(() => true);
  }

  onClickDeleteTaxCode(taxCode: StandardTaxCode): void {
    const confirmationMessage =
      '<p>Are you sure you want to delete this tax code?</p></p>This action cannot be undone.</p>';

    this.modalService.confirmation(
      confirmationMessage,
      () => this.deleteTaxCode(taxCode),
      true
    );
  }

  isCustomTaxCode(taxCode: StandardTaxCode): boolean {
    return taxCode.masterTaxCodeId === null;
  }

  private deleteTaxCode(taxCode: StandardTaxCode) {
    this.standardTaxCodeService
      .deleteTaxCode$(taxCode.id)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap(() => {
          this.fetchTaxCodes();
          this.messageService.success('Tax code deleted successfully.');
        })
      )
      .subscribe();
  }

  createNewTaxCode(): void {
    this.modalService
      .openModal(StandardTaxCodeFormComponent, TaxCodeFormType.Add, {
        taxCodesTable: this,
      })
      .then(() => {
        this.fetchTaxCodes();
        this.messageService.success('Tax code added successfully');
      })
      .catch(() => true);
  }

  isUniqueTaxCode(taxCode: StandardTaxCode): boolean {
    const found = this.taxCodes.find(
      (existingTaxCode) => existingTaxCode.taxCode === taxCode.taxCode
    );
    return found === undefined || found.id === taxCode.id;
  }

  private fetchTaxCodes(): void {
    this.busy.taxCodes = true;

    this.standardTaxCodeService
      .get$()
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        tap((taxCodes: StandardTaxCode[]) => {
          this.taxCodes = taxCodes;
          this.fetchBasCodes.emit(this.getBasCodes());
          this.fetchPostingAccounts.emit(this.getPostingAccounts());
        }),
        finalize(() => {
          this.busy.taxCodes = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private getPostingAccounts(): string[] {
    return [
      ...new Set(
        this.taxCodes.map((taxCode) => taxCode.postingAccountNo ?? '')
      ),
    ].sort();
  }

  private getBasCodes(): string[] {
    return [
      ...new Set(this.taxCodes.map((taxCode) => taxCode.basCode ?? '')),
    ].sort(this.sortValueComparator);
  }
}
