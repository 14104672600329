<div class="page-wrapper">
  <div class="header-container">
    <crs-nav-menu></crs-nav-menu>
  </div>

  <div class="lds-container" *ngIf="authInitialising">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="content-container-wrapper" *ngIf="!authInitialising">
    <div class="content-container" *ngIf="modalService.overlayPortal">
      <ng-template [cdkPortalOutlet]="modalService.overlayPortal"></ng-template>
    </div>

    <div class="content-container" [class.d-none]="modalService.overlayPortal">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!--<div class="footer-container">
    <crs-nav-footer></crs-nav-footer>
  </div>-->
</div>
