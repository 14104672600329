<div class="row">
  <div class="col-12">
    <div class="tax-code-toolbar pad-no-bottom">
      <div class="btn-toolbar d-flex mb-2" role="toolbar">
        <div class="icon-input-wrapper flex-grow-1 me-2">
          <i aria-hidden="true" class="fas fa-search input-icon"></i>
          <input
            aria-describedby="searchIcon"
            placeholder="Search..."
            type="search"
            [(ngModel)]="search"
          />
        </div>

        <div class="header-class-select ms-2">
          <ng-select
            placeholder="Posting Account"
            [(ngModel)]="selectedPostingAccount"
            [items]="postingAccounts"
          >
          </ng-select>
        </div>

        <div class="header-class-select ms-2">
          <ng-select
            placeholder="BAS Code"
            [(ngModel)]="selectedBasCode"
            [items]="basCodes"
          >
          </ng-select>
        </div>

        <div class="ms-2">
          <button
            aria-label="Clear filters"
            class="btn"
            type="button"
            [disabled]="!search && !selectedPostingAccount && !selectedBasCode"
            (click)="onClearFilters()"
          >
            Clear filters
          </button>
        </div>

        <div class="vertical-line-toolbar mx-3"></div>

        <div class="ms-2">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!(isActivated$ | async)"
            (click)="taxCodeTable.createNewTaxCode()"
          >
            <i aria-hidden="true" class="fas fa-plus"></i>
            Add Tax Code
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row pad-x">
  <div class="col-12">
    <crs-tax-codes-table
      #taxCodeTable
      *ngIf="isActivated$ | async"
      [search]="search"
      [selectedBasCode]="selectedBasCode"
      [selectedPostingAccountNo]="selectedPostingAccount"
      (fetchBasCodes)="onFetchBasCodes($event)"
      (fetchPostingAccounts)="onFetchPostingAccounts($event)"
    />
    <crs-busy *ngIf="isFetchingActivated$ | async" />
    <crs-admin-accounts *ngIf="(isActivated$ | async) === false" />
  </div>
</div>
