import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { merge, EMPTY, Subscription, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  startWith,
  tap,
  finalize,
  catchError,
  first,
  map,
  concatMap,
} from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

import {
  PagedResponse,
  PagingService,
  IPageReference,
  ModalService,
  MessageService,
} from '../../../core';
import {
  Client,
  ClientService,
  Entity,
  EntityService,
  EntityTypeEnum,
} from '../../';
import { ClientComponent } from '../client/client.component';
import { EntityComponent } from '../../entities/entity/entity.component';
import { FileModel, FileService } from 'src/app/accounting';
import { FileCreateComponent } from '../../entities/file-create/file-create.component';
import { IntegrationServerDatasource } from 'src/app/configuration/integration-server/integration-server.datasource';
import { StandardAccountSettingService } from 'src/app/accounting/chart/headers-and-accounts/standard-account-setting.service';
import {
  StandardAccountSetting,
  StandardChartTypeEnum,
} from 'src/app/accounting/chart/headers-and-accounts/standard-account';

@Component({
  selector: 'crs-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  providers: [IntegrationServerDatasource],
})
export class ClientsComponent implements OnInit {
  busy = {
    clients: false,
    entities: false,
    loading: null,
    creating: null,
  };
  response: PagedResponse<Client>;
  entityResponse: PagedResponse<Entity>;
  search = new UntypedFormControl();
  activeSearchSession: boolean;
  activeClientId: string;
  isHandisoftChart$: Observable<boolean>;
  isAPSChart$: Observable<boolean>;

  addEntitySub: Subscription;
  connectedToIs: boolean = false;

  @ViewChild('entitiesContainer', { static: true })
  entitiesContainer: ElementRef;

  constructor(
    private clientService: ClientService,
    private entityService: EntityService,
    private modalService: ModalService,
    private pagingService: PagingService,
    private messageService: MessageService,
    private standardAccountSettingService: StandardAccountSettingService,
    private readonly fileService: FileService,
    private router: Router,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private integrationServerDatasource: IntegrationServerDatasource
  ) {}

  ngOnInit() {
    if (this.route.firstChild) {
      this.activeClientId = this.route.firstChild.snapshot.paramMap.get('id');
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd && event.url === '/clients') {
        this.activeClientId = '';
      }
    });

    // Search subscription
    this.search.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        tap((search) => {
          if (search) this.activeClientId = null;
          this.activeSearchSession = !!search;
          this.busy.clients = true;
          this.busy.entities = true;
          this.response = null;
          this.entityResponse = null;
        }),
        switchMap((search) =>
          merge(this.clientSearch(search), this.entitySearch(search)).pipe(
            catchError((e) => {
              this.messageService.error('Error conducting client search.');
              return EMPTY;
            })
          )
        )
      )
      .subscribe();

    this.integrationServerDatasource
      .getStatus()
      .pipe(first())
      .subscribe((res) => {
        this.connectedToIs = true;
      });

    this.isHandisoftChart$ = this.getChart$().pipe(
      map((chart) => {
        return chart?.masterSettingId == StandardChartTypeEnum.Handisoft;
      })
    );

    this.isAPSChart$ = this.getChart$().pipe(
      map((chart) => {
        return chart?.masterSettingId == StandardChartTypeEnum.APS;
      })
    );
  }

  addFile(isDefault: boolean, entityId: string) {
    const model = new FileModel({
      clientId: this.activeClientId,
      entityId: entityId,
      name: null,
    });

    if (isDefault)
      this.runAddFileObservable(this.fileService.postDefault$(model));
    else {
      this.modalService
        .openModal(FileCreateComponent, null, model, { size: null })
        .then((fileCreateModel) =>
          this.runAddFileObservable(this.fileService.post$(fileCreateModel))
        )
        .catch(() => true);
    }
  }

  checkIsDisabled() {
    if (
      (!this.activeSearchSession && !this.activeClientId) ||
      (this.addEntitySub && !this.addEntitySub.closed)
    ) {
      return true;
    }
    return false;
  }

  private runAddFileObservable(observable: Observable<string>) {
    this.busy.creating = observable
      .pipe(
        tap((id) =>
          this.router.navigate(['/accounting', id], {
            relativeTo: this.route,
          })
        ),
        catchError((err) => {
          this.showError(err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  clientSearch(search: string) {
    this.busy.clients = true;
    return this.getClients(search, this.response).pipe(
      tap((data) => (this.response = data)),
      finalize(() => (this.busy.clients = false))
    );
  }

  entitySearch(search: string) {
    this.busy.entities = true;
    return this.getEntities(search, this.response).pipe(
      tap((data) => (this.entityResponse = data)),
      finalize(() => (this.busy.entities = false))
    );
  }

  addClient() {
    this.modalService.openModal(ClientComponent, 'add').then(
      (client) => {
        this.activateClient(client.id);
        this.refreshClients(this.response);
      },
      () => true
    );
  }

  addEntity() {
    this.addEntitySub = this.clientService
      .get(this.activeClientId)
      .subscribe((client) => {
        this.modalService
          .openModal(EntityComponent, 'add', client)
          .then((r) => {
            if (r.createFile) {
              this.addFile(true, r.entityId);
            } else {
              this.entityService.triggerRefreshEntities();
            }
          })
          .catch(() => true);
      }, this.showError);
  }

  editClient(id: string) {
    this.modalService.openModal(ClientComponent, id).then(
      (client) => {
        if (!client) {
          this.activeClientId = null;
          this.router.navigate(['./'], { relativeTo: this.route });
          this.refreshClients(this.response);
        } else {
          this.activeClientId = client.id;
          this.refreshClients(this.response);
        }
      },
      () => true
    );
  }

  activateClient(id: string) {
    if (this.activeClientId === id) return;
    this.activeSearchSession = false;
    this.activeClientId = id;
    if (this.response.records.every((c) => c.id !== id)) {
      if (this.search.value) this.search.setValue(null);
      else this.refreshClients(null);
    }
    this.router.navigate(['/clients', id]);
    if (id)
      this.entitiesContainer.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
  }

  refreshClients(reference: IPageReference) {
    this.getClients(this.search.value, reference).subscribe(
      (data) => (this.response = data)
    );
  }

  getClients(search: string, reference: IPageReference) {
    if (reference == null)
      reference = { page: 1, pageSize: this.pagingService.pageSize };
    return this.clientService.find(
      search,
      this.activeClientId,
      reference.page,
      reference.pageSize
    );
  }

  getEntities(search: string, reference: IPageReference) {
    if (reference == null)
      reference = { page: 1, pageSize: this.pagingService.pageSize };
    return this.entityService.search(
      search,
      reference.page,
      reference.pageSize
    );
  }

  private getChart$(): Observable<StandardAccountSetting> {
    return this.standardAccountSettingService.isActive$().pipe(
      concatMap((isActive) => {
        return isActive
          ? this.standardAccountSettingService.getSetting$()
          : of({
              id: '',
              masterSettingId: 0,
              settingName: '',
            });
      })
    );
  }

  importDesktopHandiLedgerFile() {
    this.busy.loading = this.isHandisoftChart$.subscribe((isHandisoftChart) => {
      if (isHandisoftChart) {
        this.router.navigate(['/import-desktop', this.activeClientId]);
      } else {
        this.modalService.showInformationDialog(
          "In order to use the Handiledger Migration Tool, the accountant's chart of accounts with Handisoft template must first be activated.<br><br>Please contact your system administrator and ask them to enable accountant's chart of accounts within your organisation.",
          false,
          'Close',
          'Chart of accounts not active',
          () => true
        );
      }
    });
  }

  getIconClass(entityTypeId: any): string {
    switch (entityTypeId) {
      case EntityTypeEnum.SoleTrader:
        return 'fas fa-user';
      case EntityTypeEnum.Partnership:
        return 'fas fa-user-friends';
      default:
        return 'fas fa-building'; // default icon
    }
  }

  showError(error) {
    this.messageService.error(error);
  }
}
