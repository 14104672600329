import { Entity } from 'src/app/firm';

export class PrepareDesktopMigrationResult {
  processorFilePath: string;
  fileName: string;
  entityMap: EntityMap;
  hasDivisions: boolean;
  warnings: string[];

  constructor(data: any) {
    this.fileName = data.fileName;
    this.processorFilePath = data.processorFilePath;
    if (data.entityMap) {
      this.entityMap = new EntityMap(data.entityMap);
    }
    this.hasDivisions = data.hasDivisions;
    this.warnings = data.warnings;
  }
}

export class EntityMap {
  importEntity: any;
  destinationEntity: Entity;
  createNew: boolean;

  constructor(data: any) {
    this.importEntity = data.importEntity;
    if (data.destinationEntity) {
      this.destinationEntity = new Entity(data.destinationEntity);
    }
    this.createNew = !this.destinationEntity;
  }
}

export class EntityMapModel {
  importEntityId: number;
  destinationEntityId: string;

  constructor(data: EntityMap) {
    this.importEntityId = parseInt(data.importEntity.id, 10);
    this.destinationEntityId = data.destinationEntity ? data.destinationEntity.id : null;
  }
}
