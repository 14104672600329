import { Component } from '@angular/core';
import { IRouteLink } from 'src/app/shared';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BacoReportEnum } from '../../../enums';

export const BACO_REPORT_TYPES = {};
BACO_REPORT_TYPES[BacoReportEnum.Transactions] = 'transactions';
BACO_REPORT_TYPES[BacoReportEnum.UncodedTransactions] = 'uncoded-transactions';
BACO_REPORT_TYPES[BacoReportEnum.GstDetail] = 'gst-detail';
BACO_REPORT_TYPES[BacoReportEnum.GstSummary] = 'gst-summary';
BACO_REPORT_TYPES[BacoReportEnum.FeedCollectionSummary] = 'feed-collection-summary';

@Component({
  selector: 'crs-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: [ './main-menu.component.scss' ]
})
export class MainMenuComponent {

  public routes: IRouteLink[] = [];
  public reportRoutes: IRouteLink[] = [];

  public isReportPage = false;

  constructor(private _router: Router, private readonly _route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isReportPage = this._router.url.includes('feed-view/reports');
      }
    });

    this.buildRouteLinks();
    this.buildReportRouteLinks();
  }

  private buildRouteLinks() {
    this.routes = [
      { name: 'Bank Connections', route: 'bank-connections' },
      { name: 'Transactions', route: 'transactions' },
      { name: 'Reports', route: 'reports' },
      { name: 'Rules', route: 'rules' },
    ];
  }

  private buildReportRouteLinks() {
    this.reportRoutes = [
      { name: 'Transaction Report', route: BACO_REPORT_TYPES[BacoReportEnum.Transactions] },
      { name: 'Uncoded Transaction Report', route: BACO_REPORT_TYPES[BacoReportEnum.UncodedTransactions] },
      { name: 'GST Detail Report', route: BACO_REPORT_TYPES[BacoReportEnum.GstDetail] },
      { name: 'GST Summary', route: BACO_REPORT_TYPES[BacoReportEnum.GstSummary] },
      { name: 'Feed Collection Summary', route: BACO_REPORT_TYPES[BacoReportEnum.FeedCollectionSummary] },
    ];
  }
}
