import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core';
import { UploadedFile } from 'src/app/shared/components/file-dragdrop-upload/file-dragdrop-upload.component';
import {
  BacoTransactionCountDto,
  BacoTransactionDto,
  BacoTransactionValidationResultDto,
} from '../../interfaces';
import { IDateRange } from '../../components';

@Injectable({
  providedIn: 'root',
})
export class BankAccountClient {
  private readonly base: string = 'api/baco/bank-account';

  constructor(private readonly _apiService: ApiService) {}

  public getTransactions(
    bankAccountId: string,
    startDate: Date,
    endDate: Date
  ): Observable<BacoTransactionDto[]> {
    let url = `${this.base}/${bankAccountId}/transactions`;
    url = this._apiService.addQuery(url, 'bankId', bankAccountId);
    url = this._apiService.addQuery(url, 'startDate', startDate);
    url = this._apiService.addQuery(url, 'endDate', endDate);
    return this._apiService.get<BacoTransactionDto[]>(url);
  }

  public getTransactionsCount(
    bankAccountId: string
  ): Observable<BacoTransactionCountDto> {
    let url = `${this.base}/${bankAccountId}/transactions-count`;
    url = this._apiService.addQuery(url, 'bankId', bankAccountId);
    return this._apiService.get<BacoTransactionCountDto>(url);
  }

  public uploadTransactions(
    bankAccountId: string,
    files: UploadedFile[]
  ): Observable<boolean> {
    let url = `${this.base}/${bankAccountId}/upload-transactions`;
    return this._apiService.post<boolean>(url, files);
  }

  public validateTransactions(
    bankAccountId: string,
    dateRange: IDateRange,
    files: UploadedFile[]
  ): Observable<BacoTransactionValidationResultDto> {
    let url = `${this.base}/${bankAccountId}/validate-transactions`;
    url = this._apiService.addQuery(url, 'startDate', dateRange.startDate);
    url = this._apiService.addQuery(url, 'endDate', dateRange.endDate);
    return this._apiService.post<BacoTransactionValidationResultDto>(
      url,
      files
    );
  }

  public revertTransactions(
    bankAccountId: string,
    dateRange: IDateRange
  ): Observable<boolean> {
    const localISODateString = (date: Date) =>
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')}`;
    const localDateRange = {
      startDate: localISODateString(dateRange.startDate),
      endDate: localISODateString(dateRange.endDate),
    };
    return this._apiService.put<boolean>(
      `${this.base}/${bankAccountId}/revert-transactions`,
      localDateRange
    );
  }

  public transferTransactions(
    bankAccountId: string,
    datasetId: string,
    dateRange: IDateRange
  ): Observable<boolean> {
    const localISODateString = (date: Date) =>
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')}`;
    const localDateRange = {
      startDate: localISODateString(dateRange.startDate),
      endDate: localISODateString(dateRange.endDate),
    };
    return this._apiService.post<boolean>(
      `${this.base}/${bankAccountId}/transfer-transactions/${datasetId}`,
      localDateRange
    );
  }
}
