<div class="modal-header">
  <h4 class="modal-title">
    <span *ngIf="!hideOffice">Practice </span> <span *ngIf="!hideUser">User </span> Details
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (validSubmit)="submit()" novalidate>

  <div class="modal-body">

    <div class="form-group" [class.required]="!readonlyUser" *ngIf="!hideUser">
      <label class="control-label">User</label>
      <crs-user-select formControlName="user" [readonly]="readonlyUser" [excludeIds]="excludeIds"></crs-user-select>
    </div>

    <div class="form-group" [class.required]="!readonlyOffice" *ngIf="!hideOffice">
      <label class="control-label">Practice</label>
      <crs-office-select [clearable]="false" formControlName="office" [readonly]="readonlyOffice"></crs-office-select>
    </div>

    <div class="row">
      <div class="col-sm-8 col-md-5 col-lg-4">

        <div class="form-group required">
          <label class="control-label">Security</label>
          <ng-select [items]="roles | enumToArray" bindValue="value" bindLabel="name" formControlName="role" [clearable]="false"></ng-select>
        </div>

      </div>
    </div>


    <div class="form-group">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="isPartner" [attr.disabled]="form.value.isDefaultPartner ? true : null">
        <label class="form-check-label">Partner</label>
      </div>
    </div>

    <div class="form-group" *ngIf="form.value.isPartner">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="isDefaultPartner">
        <label class="form-check-label">Default</label>
      </div>
    </div>

    <div class="form-group">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="isManager">
        <label class="form-check-label">Manager</label>
      </div>
    </div>

    <crs-form-error [error]="error"></crs-form-error>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Close click')">Cancel</button>
  </div>

</form>
