<div class="row" *ngIf="!notSetup">
  <div class="col-sm-9 col-md-8 col-lg-6" [ngBusy]="busy.load">
    <crs-form-error [error]="error"></crs-form-error>

    <div class="spacer-md"></div>

    <p>
      Select a workpaper starter file to download
      <i aria-hidden="true" class="fas fa-download"></i>
    </p>

    <crs-progress-bar></crs-progress-bar>

    <div>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material"
        rowClass="selectable-row"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="starters"
        (rowClicked)="selectStarter($event)"
      />
    </div>
  </div>
</div>

<div class="center" *ngIf="notSetup">
  <!-- No Entities -->
  <img
    alt="Reportance Logo"
    src="https://cirrussitestorage.blob.core.windows.net/assets/logo/reportance-r-192.png"
    width="70"
  />

  <div class="spacer-md"></div>

  <p><strong>Integrate with Business Fitness Smart Workpapers</strong></p>

  <div class="spacer-md"></div>

  <p>
    Reportance supports a deep integration with Business Fitness Smart
    Workpapers. Please contact support at Business Fitness to configure your
    integration.
  </p>
  <p>
    You can
    <a
      href="https://www.businessfitness.com.au/solutions/smart-workpapers/"
      rel="noopener noreferrer"
      target="_blank"
      >click here</a
    >
    to find out more about Smart Workpapers.
  </p>
</div>
