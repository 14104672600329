import { ReportContentInfo } from './../../report-content/report-content-info';
import { Component, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ReportMetaData } from '../../report-output';

enum ChartTypes {
  Bar = 1,
  Line = 2
}

@Component({
  selector: 'crs-report-chart',
  templateUrl: './report-chart.component.html'
})

export class ReportChartComponent implements AfterViewInit {
  @ViewChild('chartContainer', { static: false })
  private _chartContainer: ElementRef;

  @Input() index;
  @Input() element;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;

  public ngAfterViewInit() {
    const options = this.buildChartOptions();

    if (this.reportContent.output) {
      Highcharts.chart(this._chartContainer.nativeElement, options);
      window.dispatchEvent(new Event('resize'));
    }
  }

  private buildChartOptions(): any {
    const options = {
      credits: false,
      chart: {
        height: this.element.height
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: this.element.columnLabels,
      }],
      yAxis: [],
      series: [],
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      }
    };
    const scales = Array.from(new Set(this.element.series.map(c => c.yScale)));
    const hasSecondScale = scales.length > 1;

    const firstScale = this.buildScale(0);
    options.yAxis.push(firstScale);

    if (hasSecondScale) {
      const secondScale = this.buildScale(1);
      secondScale.opposite = true;

      options.yAxis.push(secondScale);
    }

    this.element.series.forEach(series => {
      let isSecondScaleSeries = false;

      if (hasSecondScale) {
        isSecondScaleSeries = series.yScale === scales[1];
      }

      options.series.push(
        {
          color: this.getColour(series.colour),
          name: series.title,
          type: series.chartType === ChartTypes.Bar ? 'column' : 'spline',
          data: series.values,
          yAxis: isSecondScaleSeries ? 1 : undefined,
          tooltip: {
            valueSuffix: ` ${series.yScale}`
          }
        }
      );
    });

    return options;
  }

  private getColour(seriesColour) {
    if (seriesColour === 0) return '#0c9eff';
    if (seriesColour === 1) return '#C00000';
    if (seriesColour === 2) return '#A5A5A5';
    return null;
  }

  private buildScale(ind: number): any {
    const scaleValue = this.element.series[ind].yScale;

    const formatter = scaleValue === '$' ? (data) => {
      return data.value.toLocaleString();
    } : (data) => {
      return `${data.value} ${scaleValue}`;
    };

    return {
      labels: {
        formatter: formatter
      },
      min: 0,
      title: {
        text: scaleValue,
      },
    };
  }
}
