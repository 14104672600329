<div [formGroup]="detail">
    <div class="form-group required">
        <div class="row">
            <div class="col-sm-9">
                <label class="control-label">Mode Option</label>
                <ng-select [items]="cashflowReportModeOptions | enumToArray" bindValue="value" bindLabel="name" formControlName="cashflowMode" [clearable]="false"></ng-select>
            </div>
        </div>
    </div>
  </div>
  