import { BusyConfig } from 'ng-busy';
import { BusyTemplateComponent } from './busy-template.component';
export function busyConfigFactory() {
  return new BusyConfig({
    backdrop: false,
    template: BusyTemplateComponent,
    delay: 0,
    minDuration: 200
  });
}
