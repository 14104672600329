import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ActiveFileService } from 'src/app/accounting/active-file.service';
import { UserService } from '../../users';

@Component({
  selector: 'crs-admin-centre',
  templateUrl: './admin-centre.component.html',
  styleUrls: ['./admin-centre.component.scss'],
})
export class AdminCentreComponent implements OnInit {
  licenseInfo: any;

  constructor(
    public route: ActivatedRoute,
    private readonly _userService: UserService,
    private _activeFileService: ActiveFileService
  ) {}

  ngOnInit() {
    this.getLicenseInfo();
    this._activeFileService.updatefile(null, true);
  }

  getLicenseInfo() {
    this._userService.getLicenseInfo().subscribe((result) => {
      this.licenseInfo = result;
    });
  }
}
