<ng-select
  #select
  bindLabel="displayName"
  groupBy="classificationLabel"
  [clearable]="clearable"
  [closeOnSelect]="!multiple"
  [items]="accounts$ | async"
  [loading]="loading"
  [placeholder]="placeholder"
  [typeahead]="accountInput"
  [virtualScroll]="true"
  (scrollToEnd)="getNext()"
>
  <!-- <ng-template ng-optgroup-tmp let-item="item">
    {{item.label}}
  </ng-template> -->
  <ng-template
    let-index="index"
    let-item="item"
    let-item$="item$"
    ng-option-tmp
    *ngIf="multiple"
  >
    <input id="item-{{ index }}" type="checkbox" [checked]="item$.selected" />
    {{ item.displayName }}
  </ng-template>
</ng-select>
