import { AccountUltraLight } from './../../ledger/accounts/account-ultra-light';
import { MasterAccountUltraLight } from './../../ledger/masterAccounts/master-account-ultra-light';
import { Account } from '../..';

export class HeaderMatch {
  masterAccount: MasterAccountUltraLight;
  masterAccountId: number;
  systemAccount: number;
  account: AccountUltraLight;
  accountId: string;

  get display() {
    if (this.account) return this.account.displayName;
    if (this.masterAccount) return this.masterAccount.accountName;
    if (this.systemAccount) return 'System Account';
    return null;
  }

  constructor(data) {
    Object.assign(this, data);
    if (data.masterAccount) this.masterAccount = new MasterAccountUltraLight(data.masterAccount);
    if (data.account) this. account = new AccountUltraLight(data.account);
  }

  matchToAccount(data: Account) {
    const thisMasterAccountId = this.masterAccount ? this.masterAccount.id : this.masterAccountId;
    const thisAccountId = this.account ? this.account.id : this.accountId;

    return thisMasterAccountId && this.masterAccountId === data.masterAccountId ||
      thisAccountId && this.accountId === data.id ||
      this.systemAccount && this.systemAccount === data.systemAccount;
  }

  matchToModel(data: HeaderMatch | HeaderMatchModel) {
    const thisMasterAccountId = this.masterAccount ? this.masterAccount.id : this.masterAccountId;
    const thisAccountId = this.account ? this.account.id : this.accountId;

    const matchMasterAccountId = data instanceof HeaderMatch &&  data.masterAccount ? data.masterAccount.id : data.masterAccountId;
    const matchAccountId = data instanceof HeaderMatch && data.account ? data.account.id : data.accountId;

    return thisMasterAccountId && (this.masterAccountId === matchMasterAccountId) ||
    thisAccountId && this.accountId === matchAccountId ||
      this.systemAccount && this.systemAccount === data.systemAccount;
  }

  isValid() {
    return this.masterAccount || this.masterAccountId || this.account || this.accountId || this.systemAccount;
  }
}

export class HeaderMatchModel {
  masterAccountId: number;
  systemAccount: number;
  accountId: string;

  constructor(data) {
    this.systemAccount = parseInt(data.systemAccount, 10);
    this.masterAccountId = parseInt(data.masterAccountId, 10);
    this.accountId = data.accountId;
    if (data.masterAccount) this.masterAccountId = data.masterAccount.id;
    if (data.account) this.accountId = data.account.id;
  }

  matchToAccount(data: Account) {
    return this.masterAccountId && this.masterAccountId === data.masterAccountId ||
      this.accountId && this.accountId === data.id ||
      this.systemAccount && this.systemAccount === data.systemAccount;
  }

  matchToModel(data: HeaderMatch | HeaderMatchModel) {
    const matchMasterAccountId = data instanceof HeaderMatch &&  data.masterAccount ? data.masterAccount.id : data.masterAccountId;
    const matchAccountId = data instanceof HeaderMatch && data.account ? data.account.id : data.accountId;

    return this.masterAccountId && (this.masterAccountId === matchMasterAccountId) ||
      this.accountId && this.accountId === matchAccountId ||
      this.systemAccount && this.systemAccount === data.systemAccount;
  }
}
