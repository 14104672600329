import { DisclosureLevel } from './disclosure-level';
import { AccountType } from '../../../ledger/account-types/accountType';
import { DisclosureVariant } from './disclosure-variant';

export enum DisclosureAutoSelectionMethod {
  IfLinkedAccountAppears = 0,
  IfRelatedAccountTypeAppears = 1,
  Always = 2,
  Never = 3
}

export class DisclosureTemplate {
  id: string;
  fileId: string;
  name: string;
  description: string;
  autoSelectionMethod: DisclosureAutoSelectionMethod;
  isMaster: boolean;
  level: DisclosureLevel;
  masterTemplateId: string;
  disclosureVariants: DisclosureVariant[];
  accountTypes: AccountType[];
  createdDate: Date;

  constructor(data) {
    Object.assign(this, data);
    if (data.disclosureVariants) this.disclosureVariants = data.disclosureVariants.map(v => new DisclosureVariant(v));
    if (data.accountTypes) this.accountTypes = data.accountTypes.map(t => new AccountType(t));
  }
}

