import {
  FeedDetailComponent,
  BankConnectionPageComponent,
  FeedCreateComponent,
  ReportPageComponent,
  RulePageComponent,
  TransactionPageComponent,
  BankConnectionCompletionComponent
} from './components';
import { BacoComponent } from './baco.component';
import { Route } from '@angular/router';
import { FeedsComponent } from './components/feeds/feeds.component';
import { SystemAdminGuard } from '../core/guards/system-admin.guard';
import { BankFeedsAuthGuard } from '../core/guards/bank-feeds-auth.guard';

const defaultRoute: Route = {
  path: '',
  pathMatch: 'full',
  redirectTo: 'feed-create'
};

export const feedCreate = {
  path: 'feed-create',
  component: FeedCreateComponent,
}

export const feeds = {
  path: 'feeds',
  component: FeedsComponent,
}

export const feedDetail: Route = {
  path: 'feed/:feedId',
  component: FeedDetailComponent,
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'bank-connections'
    },
    {
      path: 'transactions',
      component: TransactionPageComponent,
    },
    {
      path: 'reports/:report-key',
      component: ReportPageComponent
    },
    {
      path: 'rules',
      component: RulePageComponent,
    },
    {
      path: 'bank-connections',
      component: BankConnectionPageComponent,
    },
  ]
};

export const BacoSystemAdminRoute = {
  path: 'system-admin',
  canActivate: [SystemAdminGuard],
  children: [
    {
      path: 'reports/:report-key',
      component: ReportPageComponent,
    },
  ]
};

export const BacoAuthCompletionRoute = {
  path: 'baco/bank-connection-completion',
  component: BankConnectionCompletionComponent
}

export const BacoRoute = {
  path: 'baco',
  component: BacoComponent,
  canActivate: [BankFeedsAuthGuard],
  canActivateChild: [BankFeedsAuthGuard],
  children: [
    defaultRoute,
    feeds,
    feedCreate,
    feedDetail,
  ]
};
