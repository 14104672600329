import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { File, FileModel } from '../../../accounting';

@Component({
  selector: 'crs-file-create',
  templateUrl: './file-create.component.html'
})
export class FileCreateComponent implements OnInit {

  form = this._formBuilder.group({
    name: [null, Validators.required]
  });

  @Input() params: FileModel;

  busy = {
    load: null
  };
  error: string;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  submit() {
    this.params.name = this.form.controls['name'].value;
    this.activeModal.close(this.params);
  }

  test() {
    console.log(this.form);
  }


}
