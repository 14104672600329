import { GridApi, GridOptions } from 'ag-grid-community';
import { ReportSignStatus } from './../../models/report-document-list';
import {
  ReportMetaData,
  ReportOutput,
} from 'src/app/accounting/reports/reportViewer/report-output';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { MessageService, ModalService } from 'src/app/core';
import {
  ReportDocumentListItem,
  ReportDocumentModel,
  ReportSaveModel,
} from '../../models';
import { ReportDocumentService } from '../../services';
import { ReportTemplateModel } from '../../reportTemplates';
import { ReportDocumentUtility } from '../report-document-utility';

export class ReportDocumentSaveModelParams {
  reportDocumentListItem: ReportDocumentListItem;
  report: ReportOutput;
  reportTemplate: ReportTemplateModel;
  reportSaveDataBusyStatus: boolean;
  isSaveAndClose: boolean;
  reportMetaData: ReportMetaData;
  gridOptions: GridOptions;
  constructor(data: Partial<ReportDocumentSaveModelParams>) {
    Object.assign(this, data);
  }
}

@Component({
  selector: 'crs-report-documents-save',
  templateUrl: './report-documents-save.component.html',
  styleUrls: ['./report-documents-save.component.scss'],
})
export class ReportDocumentsSaveComponent implements OnInit {
  form = this._formBuilder.group({
    name: [null],
    title: [null],
    financialYear: [null],
  });

  busy = {
    submit: false,
  };
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() params: {
    reportDocumentListItem: ReportDocumentListItem;
    report: ReportOutput;
    reportTemplate: ReportTemplateModel;
    reportSaveDataBusyStatus: boolean;
    isSaveAndClose: boolean;
    reportMetaData: ReportMetaData;
    gridOptions: GridOptions;
  };

  gridApi: GridApi;

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _reportDataService: ReportDocumentService,
    private _messageService: MessageService,
    private readonly _modalService: ModalService,
    private _activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    const model = {
      name:
        this.params.reportDocumentListItem?.name ??
        ReportDocumentUtility.getTitle(this.params.report),
      title:
        this.params.reportDocumentListItem?.title ??
        this.params.reportTemplate?.title,
      financialYear:
        this.params.reportDocumentListItem?.financialYear ??
        this.params.report?.endDate?.getFullYear()?.toString(),
    };
    this.form.patchValue(model);

    if (this.params.gridOptions) {
      this.params.gridOptions.onGridReady = (event) => this.gridReady(event);
    }
  }

  public ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  gridReady(event) {
    this.gridApi = event.api;
  }

  cancel() {
    this._activeModal.dismiss();
  }

  submit() {
    this.busy.submit = true;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.showError(
        'Mandatory fields have not been completed or are missing information. Please review and complete prior to saving.'
      );
      return;
    }
    const reportTemplate = this.params.reportTemplate;
    const reportSaveModel = new ReportSaveModel(this.form.value);
    let model = new ReportDocumentModel();

    if (this.params.reportDocumentListItem) {
      this.params.reportDocumentListItem.name = reportSaveModel.name;
      model.ReportDocumentHeader = this.params.reportDocumentListItem;
      model.ReportDocumentContents = this.params.report;
    } else {
      reportTemplate.name = reportSaveModel.name;
    }

    this.params.reportSaveDataBusyStatus = true;

    const action$ = this.params.reportDocumentListItem?.id
      ? this._reportDataService.update(
          this.params.reportDocumentListItem?.id,
          model
        )
      : this._reportDataService
          .create({
            report: this.params.report,
            reportTemplateCreate: reportTemplate,
          })
          .pipe(
            tap((reportDocumentId: string) => {
              if (this.params.reportDocumentListItem)
                this.params.reportDocumentListItem.id = reportDocumentId;
            })
          );

    action$
      .pipe(
        catchError((err) => {
          this._messageService.error(err);
          return EMPTY;
        }),
        takeUntil(this._destroy$),
        finalize(() => {
          this.params.reportSaveDataBusyStatus = false;
          this.busy.submit = false;
          this._activeModal.close();
        })
      )
      .subscribe(() => {
        let saveUiMessage = this.params.isSaveAndClose
          ? `'${reportSaveModel.name}' has been saved`
          : `Report Document '${reportSaveModel.name}' saved`;
        this._messageService.success(saveUiMessage);
        this.params.reportMetaData.isModified = false; // reset contents modified flag as the document is saved.

        // Update report document storage grid
        if (this.params.reportDocumentListItem) {
          this.gridApi.applyTransaction({
            update: [this.params.reportDocumentListItem],
          });
        }

        if (this.params.isSaveAndClose) {
          this._modalService.closeOverlay();
        }
      });
  }

  showError(error) {
    this._messageService.error(error);
  }
}
