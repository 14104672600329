<crs-form-error [error]="error"></crs-form-error>

<!-- Grid -->
<div class="alert alert-warning" *ngIf="showTaxTransferWarningLabel && connectedToIs">
  Please note that some labels are not transferrable ({{showTaxLabelsThatAreNotTransferrable}}).
</div>
<ag-grid-angular style="width: 100%;"
                  class="ag-theme-material"
                  [gridOptions]="gridOptions"
                  [rowData]="reportObservable | async">
  <ag-grid-column headerName="Section" field="taxLabel.section.name" [rowGroup]="true" [hide]="true" [enableRowGroup]="true"></ag-grid-column>
  <ag-grid-column headerName="Label" field="taxLabel.displayName" [rowGroup]="true" [hide]="true" [enableRowGroup]="true"></ag-grid-column>
  <ag-grid-column headerName="Account" field="displayName"></ag-grid-column>
  <ag-grid-column headerName="" colId="balance" field="balance" type="dollarColumn" [cellClass]="['dollar-cell', 'ag-numeric-cell', 'highlight-column']" aggFunc="sum"></ag-grid-column>
</ag-grid-angular>
