import { AssetReportDepreciationRecord } from '../asset-report-depreciation-record';

export class  AssetReportFullSummaryReportRow {
  taxation: AssetReportDepreciationRecord;
  accounting: AssetReportDepreciationRecord;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.taxation) this.taxation = new AssetReportDepreciationRecord(data.taxation);
    if (data.accounting) this.accounting = new AssetReportDepreciationRecord(data.accounting);
  }
}


