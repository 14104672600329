import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { getDefaultGridOptions } from 'src/app/shared';
import { tap } from 'rxjs/operators';
import { AssetReportGrid } from '../asset-report-grid';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'crs-asset-report-detail',
  templateUrl: './asset-report-detail.component.html',
})
export class AssetReportDetailComponent implements OnInit, OnDestroy {
  @Input() content;

  search = new UntypedFormControl();
  subscriptions: Subscription[] = [];

  gridOptions: GridOptions;
  gridApi: GridApi;
  assetReportDollarParams = AssetReportGrid.dollarParams;

  constructor() {}

  ngOnInit() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      columnDefs: [
        {
          field: 'asset.assetGroupName',
          headerName: 'Group',
          enableRowGroup: true,
          hide: true,
          rowGroup: true,
        },
        {
          field: 'asset.code',
          headerName: 'Code',
          hide: true,
        },
        {
          field: 'asset.identifier',
          headerName: 'Identifier',
          hide: true,
        },
        {
          field: 'asset.acquisitionDate',
          headerName: 'Acq Date',
          type: 'dateColumn',
        },
        {
          field: 'closingCost',
          headerName: 'Cost',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'privatePercent',
          headerName: 'Pvt',
          type: 'percentColumn',
        },
        {
          field: 'openingCarryingAmount',
          headerName: 'OAV',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'carryingAmountAdjustment',
          headerName: 'Adjustments',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'additions',
          headerName: 'Additions',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'disposalValue',
          headerName: 'Disposals',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'depreciationMethodDescription',
          headerName: 'T',
          width: 60,
        },
        {
          field: 'rate',
          headerName: 'Rate',
          type: 'percentColumn',
        },
        {
          field: 'depreciation',
          headerName: 'Depr',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'privateDepreciation',
          headerName: 'Pvt',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'balancingAdjustment',
          headerName: 'Bal Adj',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
        {
          field: 'closingCarryingAmount',
          headerName: 'CAV',
          type: 'dollarColumn',
          cellRendererParams: this.assetReportDollarParams,
        },
      ],
      onGridReady: (event) => this.gridReady(event),
    };

    AssetReportGrid.customiseGrid(this.gridOptions);

    this.subscriptions.push(
      this.search.valueChanges
        .pipe(
          tap((search) => {
            this.gridApi.setGridOption('quickFilterText', search);
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  private gridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }
}
