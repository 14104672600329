import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from 'src/app/core';
import { BacoTransactionDto, IBacoTransactionComment, ITransactionLineParameters } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class TransactionClient {
  private readonly base: string = 'api/baco/transactions';

  constructor(private readonly _apiService: ApiService) {
  }

  public getTransaction(transactionId: string): Observable<BacoTransactionDto> {
    let url = `${this.base}/${transactionId}`;
    return this._apiService.get<BacoTransactionDto>(url);
  }

  public getTransactionAccounts(
    transactionId: string
  ): Observable<ITransactionLineParameters[]> {
    return this._apiService.get<ITransactionLineParameters[]>(
      `${this.base}/${transactionId}/accounts`
    );
  }

  public splitTransaction(
    transactionId: string,
    parameters: ITransactionLineParameters[]
  ): Observable<BacoTransactionDto> {
    return this._apiService.post<BacoTransactionDto>(
      `${this.base}/${transactionId}/split`,
      parameters
    );
  }

  public getComments(
    transactionId: string
  ): Observable<IBacoTransactionComment[]> {
    return this._apiService.get<IBacoTransactionComment[]>(
      `${this.base}/${transactionId}/comments`
    );
  }

  public deleteTransaction$(transactionId: string): Observable<boolean> {
    return this._apiService.delete(`${this.base}/${transactionId}`);
  }

  public updateDescription(
    transactionId: string,
    description: string | null
  ): Observable<void> {
    const url = `${this.base}/${transactionId}/description`;
    return this._apiService.put(url, { description });
  }
}
