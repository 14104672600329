import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class FeedTestClient {

  private readonly base: string = 'api/baco/test-feed';

  constructor(private readonly _apiService: ApiService) {
  }

  public updateFeed(feedId: string) : Observable<void> {
    return this._apiService.put<void>(`${this.base}/update-feed?feedId=${feedId}`, null);
  }

  public addRandomAccount(bankConnectionId: string) : Observable<void> {
    return this._apiService.put<void>(`${this.base}/random-account?feedId=${bankConnectionId}`, null);
  }

  public addRandomTransactions(bankAccountId: string) : Observable<void> {
    return this._apiService.put<void>(`${this.base}/random-transactions?bankAccountId=${bankAccountId}`, null);
  }
}