export enum JournalCategory {
  Imported = 1,
  NotExporting = 2,
  PendingExport = 3,
  Exported = 4,
  Balancing = 5
}

export enum JournalCategoryWithTrialBalanceOption {
  TrialBalance = 0,
  ImportedBalances = 1,
  RollOver = 2,
  Adjustment = 3,
  // PendingExport = 3,
  // Exported = 4,
  // Balancing = 5
}

export enum JournalCategoryForLedger {
  Normal = 0,
  Imported = 1,
  RollOver = 2,
  Adjustment = 3,
  // PendingExport = 3,
  // Exported = 4,
  // Balancing = 5
}
