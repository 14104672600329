<div class="bank-connections-toolbar">
  <div class="feed-collecion-name">
    <span>
      <strong class="me-2">Feed collection name:</strong>
      <strong class="text-gray"> {{ getFeedValue('name') }}</strong>
    </span>
  </div>

  <div class="feed-reference">
    <span>
      <strong class="me-2">Reference:</strong>
      <strong class="text-gray">
        {{ getFeedValue('billingRef') || 'N/A' }}
      </strong>
    </span>
  </div>

  <div class="toolbar-right-container">
    <button class="fas-btn-ghost" title="Edit Addition" type="button">
      <i
        aria-hidden="true"
        class="fas fa-pencil-alt"
        (click)="onClickEditBankFeed()"
      ></i>
    </button>

    <button
      class="btn btn-block btn-primary add-bank-connection-btn mt-0"
      type="button"
      (click)="onClickAddBankAccounts()"
    >
      <i aria-hidden="true" class="fas fa-plus"></i>
      Add bank accounts
    </button>
  </div>
</div>

<crs-bank-connection-table #bankConnectionTable [search]="search" />
