import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../core';
import { Division, DivisionModel } from './division';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  readonly base: string = 'api/accounting/divisions';

  constructor(private apiService: ApiService) { }

  get(id: string) {
    return this.apiService.get<Division>(this.base + '/' + id).pipe(map(d => new Division(d)));
  }

  getAll(fileId: string) {
    return this.apiService.get<Division[]>(this.base + '/forfile/' + fileId).pipe(map(l => l.map(d => new Division(d))));
  }

  post(division: DivisionModel) {
    return this.apiService.post<any>(this.base, division);
  }


  put(division: DivisionModel) {
    return this.apiService.put(this.base + '/' + division.id, division);
  }

  delete(id: string) {
    return this.apiService.delete(this.base + '/' + id);
  }
}
