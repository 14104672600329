import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import {
  catchError,
  exhaustMap,
  finalize,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { ActiveFileService } from 'src/app/accounting/active-file.service';
import { File } from 'src/app/accounting/files';
import {
  EnhancedConfirmation,
  MessageService,
  ModalService,
} from 'src/app/core';
import { Patterns } from 'src/app/shared/validators';
import { TradingAccount, TradingAccountService } from '../../setup';
import { LivestockCategory } from '../../setup/tradingAccounts/livestockAccount';
import { CostCalculationMethod } from './cost-calculation-method';
import {
  LivestockCalculation,
  LivestockCalculatorPostJournal,
  LivestockCalculatorToUpsert,
} from './livestock-calculator';
import { LivestockCalculatorService } from './livestock-calculator.service';

@Component({
  selector: 'crs-livestock',
  templateUrl: './livestock.component.html',
  styleUrls: ['./livestock.component.scss'],
})
export class LivestockComponent implements OnInit, OnDestroy {
  livestockCalculatorId: string;
  tradingAccountId: string;
  isAdd: boolean;

  file: File;
  datasetId: string;
  tradingAccount: TradingAccount;
  costCalculationMethods = CostCalculationMethod;
  livestockCategories = LivestockCategory;

  isUpsertingCalculator$ = new BehaviorSubject(false);
  isFetchingTradingAccount$ = new BehaviorSubject(false);
  isFetchingLivestockCalculator$ = new BehaviorSubject(false);

  busy = {
    submit: null,
  };

  error = null;
  unassignedAccountsError = [
    'Not all of the accounts assigned to these livestock categories have an assigned source code.',
    'Assign source accounts in the “Accounts” tab to post the livestock trading account',
  ];

  // keep track of previous value, so that onBlur we can check if value has changed
  closingStockPreviousValue: number;
  naturalIncreaseUnitPricePreviousValue: number;

  form = this.formBuilder.group({
    costCalculationMethod: [null],
    closingStockValue: [
      '',
      [Validators.pattern(Patterns.twoDecimalPlacesRegExp)],
    ],
    naturalIncreaseUnitPrice: [
      '',
      [Validators.pattern(Patterns.twoDecimalPlacesRegExp)],
    ],
    naturalIncreaseFinalQuantity: [
      '',
      [Validators.pattern(Patterns.twoDecimalPlacesRegExp)],
    ],
    deathsFinalQuantity: [
      '',
      [Validators.pattern(Patterns.twoDecimalPlacesRegExp)],
    ],
    closingStockFinalQuantity: [
      '',
      [Validators.pattern(Patterns.twoDecimalPlacesRegExp)],
    ],
  });

  livestockData: { [key in LivestockCategory]: LivestockCalculation | null } = {
    [LivestockCategory.Sales]: null,
    [LivestockCategory.Purchases]: null,
    [LivestockCategory.OpeningStock]: null,
    [LivestockCategory.NaturalIncrease]: null,
    [LivestockCategory.KilledForRations]: null,
    [LivestockCategory.Deaths]: null,
    [LivestockCategory.ClosingStock]: null,
    [LivestockCategory.StockOnHand]: null,
  };
  LivestockCategory = LivestockCategory;

  private submitButtonStream$ = new Subject<void>();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private activeFileService: ActiveFileService,
    private formBuilder: UntypedFormBuilder,
    private livestockCalculatorService: LivestockCalculatorService,
    private messageService: MessageService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private tradingAccountService: TradingAccountService
  ) {}

  ngOnInit() {
    this.routeParamsSubscription();
    this.fetchFile();
    this.configureSubmit();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onChangeCostCalculationMethod(event): void {
    this.upsertCalculator({ costCalculationMethod: event?.value || null });
  }

  onBlurClosingStockValue(event: FocusEvent): void {
    const errors = this.form.get('closingStockValue').errors;
    const value = (event.target as HTMLInputElement).value;
    const isValueSame = Number(value) === this.closingStockPreviousValue;

    if (errors || !value || isValueSame) {
      return;
    }

    this.upsertCalculator({ closingStockValue: Number(value) });
  }

  onBlurNaturalIncreaseUnitPrice(event: FocusEvent): void {
    const value = (event.target as HTMLInputElement).value;
    const errors = this.form.get('naturalIncreaseUnitPrice').errors;
    const isValueSame =
      Number(value) === this.naturalIncreaseUnitPricePreviousValue;

    if (errors || !value || isValueSame) {
      return;
    }

    this.upsertCalculator({ naturalIncreaseUnitPrice: Number(value) });
  }

  onLivestockFinalQuantityInputBlur(
    event: FocusEvent,
    formControlName: string
  ): void {
    const value = (event.target as HTMLInputElement).value;

    this.form.patchValue({ [formControlName]: Number(value)?.toFixed(2) });
  }

  onClickClose(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onValidSubmit(): void {
    this.submitButtonStream$.next();
  }

  private configureSubmit(): void {
    this.submitButtonStream$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => (this.error = null)),
        exhaustMap(() => this.submit$())
      )
      .subscribe((result) => {
        if (!!result) {
          this.messageService.success('Posted a livestock journal.');
          this.form.markAsPristine();
          this.error = null;
        }
      });
  }

  private submit$(): Observable<any> {
    const loading$ = new Subject<void>();
    this.busy.submit = loading$.subscribe();

    const postJournalModel: LivestockCalculatorPostJournal = {
      naturalIncreaseFinalQuantity: Number(
        this.form.get('naturalIncreaseFinalQuantity').value
      ),
      deathsFinalQuantity: Number(this.form.get('deathsFinalQuantity').value),
      closingStockFinalQuantity: Number(
        this.form.get('closingStockFinalQuantity').value
      ),
      totalClosingStockBalance: this.totalClosingStockBalance(),
    };

    return this.livestockCalculatorService
      .postJournal$(this.livestockCalculatorId, postJournalModel)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        finalize(() => loading$.complete())
      );
  }

  private routeParamsSubscription() {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (routeParams) => {
        this.form.reset();
        this.error = null;

        this.tradingAccountId = routeParams.tradingAccountId;
        this.datasetId = this.route.snapshot.parent.parent.paramMap.get('id');

        this.fetchTradingAccount();
        this.fetchLivestockCalculator();

        // Keep track of previous values
        this.closingStockPreviousValue = Number(
          this.form.get('closingStockValue').value
        );
        this.naturalIncreaseUnitPricePreviousValue = Number(
          this.form.get('naturalIncreaseUnitPrice').value
        );
      });
  }

  private fetchFile(): void {
    this.activeFileService.stream.subscribe((file) => {
      this.file = file;
    });
  }

  hasUnassignedAccounts(): boolean {
    return Object.values(this.livestockData).some(
      (calculation: LivestockCalculation | null) => {
        if (!calculation) {
          return false;
        }

        return !calculation.accountName || !calculation.accountNo;
      }
    );
  }

  handleQtyBalanceOnDoubleClick(formControlName: string): void {
    const formControlValue = this.form.value[formControlName];

    const sales =
      this.livestockData[LivestockCategory.Sales]?.totalQuantity || 0;

    const currentValue =
      this.totalCostOfSalesQuantity() - this.totalLessQuantity();

    const difference =
      formControlName === 'naturalIncreaseFinalQuantity'
        ? sales - currentValue + Number(formControlValue)
        : currentValue - sales + Number(formControlValue);

    this.form.patchValue({ [formControlName]: difference?.toFixed(2) });
  }

  getTotalBalance(data: any): string {
    if (!data) return;
    if (data.liveStockCategory === LivestockCategory.ClosingStock) {
      return this.totalClosingStockBalance()?.toFixed(2);
    }
    return data?.totalBalance?.toFixed(2) || 0;
  }

  totalCostOfSalesQuantity(): number {
    return (
      (this.livestockData[LivestockCategory.OpeningStock]?.finalQuantity || 0) +
        (this.livestockData[LivestockCategory.Purchases]?.finalQuantity || 0) +
        (Number(this.form.value.naturalIncreaseFinalQuantity) || 0) || 0
    );
  }

  totalCostOfSalesBalance(): number {
    return (
      this.livestockData[LivestockCategory.OpeningStock]?.totalBalance +
        this.livestockData[LivestockCategory.Purchases]?.totalBalance +
        this.livestockData[LivestockCategory.NaturalIncrease]?.totalBalance || 0
    );
  }

  totalLessQuantity(): number {
    return (
      (this.livestockData[LivestockCategory.KilledForRations]?.finalQuantity ||
        0) +
        (Number(this.form.value.deathsFinalQuantity) || 0) +
        (Number(this.form.value.closingStockFinalQuantity) || 0) || 0
    );
  }

  totalLessBalance(): number {
    return (
      this.livestockData[LivestockCategory.KilledForRations]?.totalBalance +
      this.livestockData[LivestockCategory.Deaths]?.totalBalance +
      this.totalClosingStockBalance()
    );
  }

  totalClosingStockBalance(): number {
    if (
      this.form.value.costCalculationMethod ===
      CostCalculationMethod.MarketValue
    ) {
      return Number(this.form.get('closingStockValue').value);
    }

    const closingStockFinalQuantity =
      Number(this.form.value.closingStockFinalQuantity) || 0;

    return this.totalAverageCostPerHead() * closingStockFinalQuantity || 0;
  }

  getGrossProfitBalance(): number {
    return (
      this.livestockData[LivestockCategory.Sales]?.totalBalance -
      (this.totalCostOfSalesBalance() - this.totalLessBalance())
    );
  }

  isFinalQuantityBalanced(): boolean {
    const sales =
      this.livestockData[LivestockCategory.Sales]?.totalQuantity || 0;

    const difference =
      sales - (this.totalCostOfSalesQuantity() - this.totalLessQuantity());

    return difference === 0;
  }

  totalAverageCostPerHead(): number {
    if (
      this.form.value.costCalculationMethod ===
      CostCalculationMethod.MarketValue
    ) {
      return;
    }

    const balances =
      this.livestockData[LivestockCategory.OpeningStock]?.totalBalance +
      this.livestockData[LivestockCategory.Purchases]?.totalBalance +
      (Number(this.form.value.naturalIncreaseFinalQuantity) || 0) *
        Number(this.form.get('naturalIncreaseUnitPrice').value);

    const quantity =
      this.livestockData[LivestockCategory.OpeningStock]?.finalQuantity +
      this.livestockData[LivestockCategory.Purchases]?.finalQuantity +
      (Number(this.form.value.naturalIncreaseFinalQuantity) || 0);

    if (quantity > 0) {
      const averageCostPerHead = balances / quantity;
      return Number(averageCostPerHead || 0);
    }

    return 0;
  }

  /**
   * Show custom confirmation modal for in-app navigation when navigating away via router.
   */
  canDeactivate(): Promise<boolean> | boolean {
    // Don't show 'navigating away' confirmation modal
    if (this.isAdd || !this.form.dirty) {
      return true;
    }

    const confirmation = new EnhancedConfirmation({
      title: 'Livestock trading account not posted',
      additionalInfoText: `
        Are you sure you want to leave livestock trading account "${this.tradingAccount?.name}" without posting?
        <br />
        <br />
        If you do, all changes will be discarded.
      `,
      approveAction: () => true,
      cancelAction: () => false,
      danger: true,
      showUnderstandConsequencesSuffix: false,
      approveBtn: 'Discard changes',
    });

    return this.modalService.confirmationEx(confirmation);
  }

  /**
   * Show browser's default confirmation dialog for when user try to refresh or close the tab, if the form is dirty.
   * This is due to `beforeunload` event does not support asynchronous operations like showing a custom modal and waiting for a user decision.
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.form.dirty) {
      $event.returnValue = true;
    }
  }

  private fetchTradingAccount(): void {
    this.isFetchingTradingAccount$.next(true);

    this.tradingAccountService
      .get(this.tradingAccountId)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        finalize(() => this.isFetchingTradingAccount$.next(false))
      )
      .subscribe((tradingAccount) => {
        this.tradingAccount = tradingAccount;
      });
  }

  private fetchLivestockCalculator(): void {
    this.isFetchingLivestockCalculator$.next(true);

    this.livestockCalculatorService
      .getForTradingAccount$(this.tradingAccountId, this.datasetId)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          // No livestock calculator created yet, so it's an Add.
          if (error.code === 404) {
            this.livestockCalculatorId = null;
            this.isAdd = true;

            this.form.patchValue({
              costCalculationMethod: null,
              closingStockValue: null,
              naturalIncreaseUnitPrice: null,
            });

            this.closingStockPreviousValue = Number(
              this.form.get('closingStockValue').value
            );
            this.naturalIncreaseUnitPricePreviousValue = Number(
              this.form.get('naturalIncreaseUnitPrice').value
            );

            return EMPTY;
          }

          this.showError(error);
          return EMPTY;
        }),
        finalize(() => this.isFetchingLivestockCalculator$.next(false))
      )
      .subscribe((calculator) => {
        this.livestockCalculatorId = calculator.id;
        this.mapLivestockCalculations(calculator.calculations);

        this.isAdd = false;

        const naturalIncreaseFinalQuantity =
          this.livestockData[
            LivestockCategory.NaturalIncrease
          ].totalQuantity?.toFixed(2);

        const deathsFinalQuantity =
          this.livestockData[LivestockCategory.Deaths].totalQuantity?.toFixed(
            2
          );

        const closingStockFinalQuantity =
          this.livestockData[
            LivestockCategory.ClosingStock
          ].totalQuantity?.toFixed(2);

        this.form.patchValue({
          costCalculationMethod: calculator.costCalculationMethod || null,
          closingStockValue: calculator.closingStockValue?.toFixed(2),
          naturalIncreaseUnitPrice:
            calculator.naturalIncreaseUnitPrice?.toFixed(2),
          naturalIncreaseFinalQuantity,
          deathsFinalQuantity,
          closingStockFinalQuantity,
        });

        this.closingStockPreviousValue = Number(
          this.form.get('closingStockValue').value
        );
        this.naturalIncreaseUnitPricePreviousValue = Number(
          this.form.get('naturalIncreaseUnitPrice').value
        );
      });
  }

  private mapLivestockCalculations(calculations): void {
    calculations.forEach((calc) => {
      const extendedCalc: LivestockCalculation = {
        ...calc,
        finalQuantity: calc.totalQuantity, // Set finalQuantity to totalQuantity by default
      };

      switch (calc.liveStockCategory) {
        case LivestockCategory.Sales:
        case LivestockCategory.OpeningStock:
        case LivestockCategory.Purchases:
        case LivestockCategory.NaturalIncrease:
        case LivestockCategory.KilledForRations:
        case LivestockCategory.Deaths:
        case LivestockCategory.ClosingStock:
        case LivestockCategory.StockOnHand:
          this.livestockData[calc.liveStockCategory] = extendedCalc;
          break;
      }
    });

    this.validateLivestockAccounts();
  }

  private validateLivestockAccounts(): void {
    this.error = this.hasUnassignedAccounts()
      ? this.unassignedAccountsError
      : null;
  }

  private upsertCalculator(
    partialUpsert: Partial<LivestockCalculatorToUpsert>
  ): void {
    const defaultUpsertModel: LivestockCalculatorToUpsert = {
      costCalculationMethod: this.form.get('costCalculationMethod').value,
      closingStockValue: Number(this.form.get('closingStockValue').value),
      naturalIncreaseUnitPrice: Number(
        this.form.get('naturalIncreaseUnitPrice').value
      ),
    };

    let action$: Observable<{}>;

    if (this.isAdd) {
      const createModel: LivestockCalculatorToUpsert = {
        ...defaultUpsertModel,
        ...partialUpsert,
        fileId: this.file.id,
        datasetId: this.datasetId,
        tradingAccountId: this.tradingAccountId,
      };

      action$ = this.livestockCalculatorService.post$(createModel);
    } else {
      const updateModel: LivestockCalculatorToUpsert = {
        ...defaultUpsertModel,
        ...partialUpsert,
      };

      action$ = this.livestockCalculatorService.put$(
        this.livestockCalculatorId,
        updateModel
      );
    }

    this.isUpsertingCalculator$.next(true);

    action$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => (this.error = null)),
        catchError((error) => {
          this.showError(error);
          return EMPTY;
        }),
        finalize(() => this.isUpsertingCalculator$.next(false))
      )
      .subscribe(() => {
        this.isAdd = false;
        this.fetchLivestockCalculator();
      });
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error);
  }
}
