<ng-container *ngIf="status$ | async as status; else loading">
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'ERROR'" [ngTemplateOutlet]="error">
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="success"> </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <crs-busy></crs-busy>
</ng-template>

<ng-template #error>
  <crs-error-view [message]="error$ | async"></crs-error-view>
</ng-template>

<ng-template #success>
  <div class="transaction-table-wrapper">
    <ag-grid-angular
      class="ag-theme-material transaction-table-container"
      style="width: 100%"
      [context]="context"
      [gridOptions]="transactionGrid.gridOptions"
    >
    </ag-grid-angular>

    <div class="transaction-table-footer-wrapper">
      <crs-transaction-table-footer
        [transactions-summary]="transactionsSummary$ | async"
      ></crs-transaction-table-footer>

      <div class="transaction-table-bank-refresh me-2">
        <ng-container
          *ngIf="selectedBank$ | async as selectedBank; else loadingRefreshDate"
        >
          <ng-container
            *ngIf="selectedBank && selectedBank.lastRefreshLocalDate"
          >
            <span class="last-refresh-text">
              Updated:
              {{ selectedBank.lastRefreshLocalDate | date: 'dd MMM yyyy' }}
            </span>
          </ng-container>
        </ng-container>
        <ng-template #loadingRefreshDate>
          <span>Loading...</span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #stateCell let-transaction>
  <crs-busy *ngIf="transaction.loading" [small]="true"></crs-busy>
  <div class="d-flex justify-content-between column-state">
    <div>
      <i class="fas fa-lock locked me-2" *ngIf="transaction.locked"></i>
    </div>
    <div>
      <crs-transaction-state-icon
        [coded-by]="transaction.codedBy"
      ></crs-transaction-state-icon>
    </div>
  </div>
</ng-template>

<ng-template #optionsCell let-transaction>
  <div class="d-flex justify-content-end">
    <div class="btn-mini-menu options-config">
      <a class="btn btn-sm fas-btn-primary-ghost" data-bs-toggle="dropdown">
        <i aria-hidden="true" class="fas fa-ellipsis-v"></i>
      </a>
      <div class="dropdown-menu">
        <button
          class="dropdown-item"
          type="button"
          *ngIf="transaction.hasComments"
          (click)="onOpenComments(transaction.id)"
        >
          <i aria-hidden="true" class="fas fa-flag"></i>
          View comments
        </button>
        <button
          class="dropdown-item"
          type="button"
          (click)="onOpenComments(transaction.id)"
        >
          <i class="fas fa-comments"></i>
          Add comment
        </button>
        <button
          class="dropdown-item"
          type="button"
          *ngIf="
            transaction.transactionImportType === transactionImportType.File &&
            !transaction.locked
          "
          (click)="onClickDeleteTransaction(transaction)"
        >
          <i class="fas fa-trash"></i>
          Delete transaction
        </button>
      </div>
    </div>

    <div class="btn-mini-menu add-rule-config">
      <a class="btn btn-sm fas-btn-primary-ghost" data-bs-toggle="dropdown">
        <i aria-hidden="true" class="fas fa-plus"></i>
      </a>
      <div class="dropdown-menu">
        <button
          class="dropdown-item"
          type="button"
          (click)="onAddRule(transaction)"
        >
          <i aria-hidden="true" class="fas fa-flag"></i>
          Add rule
        </button>
      </div>
    </div>
  </div>
</ng-template>
