<ng-select [items]="filesObservable | async"
           [groupBy]="groupByFn"
           [loading]="loading"
           [typeahead]="userInput"
           [virtualScroll]="true"
           [clearable]="clearable"
           [readonly]="readonly">

</ng-select>

