import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, PagedResponse } from '../../core';
import { Dataset, DatasetModel } from './dataset';
import { ApiFeatures } from '../sourcedata/sourceTypes/apiFeatures';

@Injectable({ providedIn: 'root' })
export class DatasetService {
  readonly base: string = 'api/accounting/datasets';

  constructor(private apiService: ApiService) {}

  get$(id: string) {
    return this.apiService
      .get<Dataset>(`${this.base}/${id}`)
      .pipe(map((dataset) => new Dataset(dataset)));
  }

  getLight$(id: string) {
    return this.apiService
      .get<Dataset>(`${this.base}/${id}/light`)
      .pipe(map((dataset) => new Dataset(dataset)));
  }

  getApiFeatures$(id: string): Observable<ApiFeatures> {
    return this.apiService
      .get<ApiFeatures>(`${this.base}/${id}/api-features`)
      .pipe(map((apiFeatures) => new ApiFeatures(apiFeatures)));
  }

  getAll$(
    fileId: string,
    entityId: string = null,
    startDate?: Date,
    endDate?: Date,
    excludeGroupDatasets: boolean = false
  ): Observable<Dataset[]> {
    let url = this.base + '/forfile/' + fileId;
    url = this.apiService.addQuery(url, 'entityId', entityId);
    url = this.apiService.addQuery(url, 'startDate', startDate);
    url = this.apiService.addQuery(url, 'endDate', endDate);
    url = this.apiService.addQuery(
      url,
      'excludeGroupDatasets',
      excludeGroupDatasets
    );
    return this.apiService
      .get<Dataset[]>(url)
      .pipe(map((datasets) => datasets.map((dataset) => new Dataset(dataset))));
  }

  post$(dataset: DatasetModel): Observable<string> {
    return this.apiService.post<string>(this.base, dataset);
  }

  import$(id: string) {
    return this.apiService.post<boolean>(`${this.base}/${id}/import`, null);
  }

  put$(dataset: DatasetModel) {
    return this.apiService.put(`${this.base}/${dataset.id}`, dataset);
  }

  refreshOpeningBalances$(id: string, openingBalanceDatasetId: string) {
    let url = `${this.base}/${id}/refresh-opening-balance-rollover`;
    if (openingBalanceDatasetId)
      url = this.apiService.addQuery(
        url,
        'expectingOpeningDatasetId',
        openingBalanceDatasetId
      );
    return this.apiService.put<any>(url, null);
  }

  delete$(id: string) {
    return this.apiService.delete(`${this.base}/${id}`);
  }
}
