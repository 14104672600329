import { DateHelpers } from "src/app/shared/utilities/date-helpers";

export class ESignatureRequestHistory {
  id: string;
  document: string;
  requestor: string;
  dateSent: Date;

  constructor(data) {
    Object.assign(this, data);    
    if (data.dateSent) this.dateSent =  DateHelpers.dateFromApiResponse(data.dateSent);
  }
  
}

