<div class="modal-header">
  <h4 class="modal-title">Transaction Validation</h4>
  <button aria-label="Close" class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ng-container
    *ngIf="
      numberOfMismatchedTransactions > 0;
      else noMismatchedTransactionsContent
    "
  >
    <p>
      We have identified some transactions in your existing records that do not
      match the latest transactions from your bank feed.
    </p>
    <p>
      Number of mismatched transactions: {{ numberOfMismatchedTransactions }}
    </p>
    <p>
      Would you like to delete these mismatched transactions from your records?
    </p>
  </ng-container>

  <ng-template #noMismatchedTransactionsContent>
    <p>No mismatched transactions found in your existing records.</p>
  </ng-template>
</div>

<div
  class="modal-footer justify-content-between"
  *ngIf="
    numberOfMismatchedTransactions > 0;
    else noMismatchedTransactionsFooter
  "
>
  <button
    class="btn btn-primary"
    [promiseBtn]="busy.generate"
    (click)="review()"
  >
    Review
  </button>
  <div class="d-flex align-items-center">
    <button class="btn btn-danger mr-2" (click)="delete()">Delete</button>
    <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
  </div>
</div>

<ng-template #noMismatchedTransactionsFooter>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="cancel()">Close</button>
  </div>
</ng-template>
