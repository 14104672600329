import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, EMPTY } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';

import { StorageAccount } from '../storage-account';
import { StorageAccountService } from './../storage-account.service';

@Component({
  selector: 'crs-storage-account-select',
  templateUrl: './storage-account-select.component.html',
    providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StorageAccountSelectComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class StorageAccountSelectComponent implements ControlValueAccessor, OnInit {

  @Input() clearable = true;
  @Input() readonly = false;

  @ViewChild(NgSelectComponent, { static: true }) private _valueAccessor: NgSelectComponent;

  storageAccount$: Observable<StorageAccount[]>;
  private _loaded = false;
  private _loading = false;

  constructor(private readonly _storageAccountService: StorageAccountService) { }

  writeValue(value: StorageAccount): void {
    this._valueAccessor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this._valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this._valueAccessor.registerOnTouched(fn);
  }

  onOpen() {
    if (!this._loaded && !this._loading) {
      this.load();
    }
  }

  load() {
    this._loading = true;
    this.storageAccount$ = this._storageAccountService.getAll().pipe(
      tap(() => this._loaded = true),
      catchError(error => {
        console.log(error);
        return EMPTY; // empty list on error
      }),
      finalize(() => this._loading = false));

  }

  searchNameAndNumber(term: string, item: StorageAccount) {
    term = term.toLowerCase();
    const searchText = (item.id + ' ' + item.name).toLowerCase();
    return searchText.indexOf(term) > -1;
  }

  ngOnInit() {
    this._valueAccessor.bindLabel = 'name'; // Binding not working with html tag for some reason
  }
}
