import { DepreciationPool } from './depreciation-pool';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { Asset, AssetModel } from './asset';
import { map } from 'rxjs/operators';
import { AssetsContext } from '../assets-context';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  readonly base: string = 'api/accounting/assets/assets';

  constructor(private apiService: ApiService) { }

  get(id: string) {
    return this.apiService.get<Asset>(this.base + '/' + id).pipe(map(c => new Asset(c)));
  }

  getAllForGroup(groupId: number, fileId: string, entityId: string, year: number, onlyNonPooled: boolean) {
    let url = this.base;
    url = this.apiService.addQuery(url, 'fileId', fileId);
    url = this.apiService.addQuery(url, 'entityId', entityId);
    url = this.apiService.addQuery(url, 'year', year);
    url = this.apiService.addQuery(url, 'groupId', groupId);
    url = this.apiService.addQuery(url, 'onlyNonPooled', onlyNonPooled);
    return this.apiService.get<Asset[]>(url).pipe(map(r => r.map(y => new Asset(y))));
  }

  post(model: AssetModel) {
    return this.apiService.post<any>(this.base, model);
  }

  put(model: AssetModel) {
    return this.apiService.put(this.base + '/' + model.id, model);
  }

  calculate(model: AssetModel) {
    return this.apiService.put<Asset>(this.base + '/calculate', model).pipe(map(a => new Asset(a)));
  }

  move(assetGroupId: number, assetIds: string[]) {
    const model = {
      assetGroupId: assetGroupId,
      assetIds: assetIds
    };
    return this.apiService.put(this.base + '/move', model);
  }

  delete(id: number) {
    return this.apiService.delete(this.base + '/' + id);
  }
}
