export enum TableRowType {
  ColumnHeader = 0,
  Row = 1,
  SubTotal = 2,
  GrandTotal = 3,
  Header = 4,
  GroupTotal = 5,
  HeaderColumnGroups = 6,
  Total = 7,
  Spacer = 8,
  AutoTotal = 9,
  AutoGrandTotal = 10
}
