import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
} from '@angular/forms';
import { Observable, Subject, forkJoin, concat, EMPTY, of } from 'rxjs';
import {
  tap,
  exhaustMap,
  catchError,
  finalize,
  switchMap,
} from 'rxjs/operators';

import {
  AccountService,
  ChildAccount,
  AccountTypeMode,
  AutoMatchMode,
} from '../';
import {
  Classification,
  SourceAccount,
  SourceAccountService,
} from '../../../ledger';
import { ActiveFileService } from '../../../active-file.service';
import { MessageService } from '../../../../core';
import {
  Division,
  DivisionService,
  TradingAccount,
  TradingAccountService,
} from '../../../setup';
import { SourceAccountModel } from '../../sourceAccounts';

@Component({
  selector: 'crs-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  @Input() id: string;

  objectTitle = 'Account';

  busy = {
    load: null,
    submit: null,
    apply: null,
    unlink: null,
  };
  error: string = null;

  form = this.formBuilder.group({
    accountNo: ['', [Validators.maxLength(128)]],
    accountName: ['', [Validators.required, Validators.maxLength(256)]],
    sourceClassification: [null, Validators.required],
    accountType: [null, Validators.required],
    accountTypeMode: [AccountTypeMode.Inherited, Validators.required],
    autoMatchMode: [AutoMatchMode.Auto, Validators.required],
    isConsolidated: [false],
    division: [null],
    tradingAccount: [null],
    sourceAccounts: this.formBuilder.array([]),
  });

  get sourceAccounts() {
    return this.form.get('sourceAccounts') as UntypedFormArray;
  }

  originalAccount: ChildAccount;
  gridRefreshRequired = false;

  accountTypeModes = AccountTypeMode;
  autoMatchModes = AutoMatchMode;
  classifications = Classification;

  divisions: Division[];
  tradingAccounts: TradingAccount[];

  submitButtonStream = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private accountService: AccountService,
    private activeFileService: ActiveFileService,
    private divisionService: DivisionService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private sourceAccountService: SourceAccountService,
    private tradingAccountService: TradingAccountService
  ) {}

  ngOnInit() {
    if (this.id === 'add') {
      this.showError('Unable to directly add an account');
      return;
    }
    this.getAccount();
    this.configureSubmit();
    this.activeFileService.stream
      .pipe(switchMap((file) => this.getDropdownData(file.id)))
      .subscribe();
  }

  configureSubmit() {
    this.submitButtonStream
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submitObservable())
      )
      .subscribe((client) => {
        this.activeModal.close();
      });
  }

  submit() {
    this.submitButtonStream.next();
  }

  unlink(sourceAccount: SourceAccount) {
    this.gridRefreshRequired = true;

    const loadingStream = new Subject();
    this.busy.unlink = loadingStream.subscribe();
    this.sourceAccountService.unlink(sourceAccount.id).subscribe(
      () => {
        const index = this.sourceAccounts.value.findIndex(
          (s) => s.id === sourceAccount.id
        );
        if (index >= 0) this.sourceAccounts.removeAt(index);
      },
      (err) => this.showError(err),
      () => loadingStream.complete()
    );
  }

  getDropdownData(id: string) {
    return forkJoin({
      divisions: this.divisionService
        .getAll(id)
        .pipe(tap((divisions) => (this.divisions = divisions))),
      tradingAccounts: this.tradingAccountService
        .getAll(id)
        .pipe(
          tap((tradingAccounts) => (this.tradingAccounts = tradingAccounts))
        ),
    });
  }

  submitObservable(): Observable<any> {
    const account = new ChildAccount(this.form.value);
    account.id = this.id;

    const submit = this.accountService.putChild(account.getUpdateModel());

    const loadingStream = new Subject();
    this.busy.submit = loadingStream.subscribe();

    return forkJoin({
      submit: submit,
      save: this.saveSourceAccountsObservable(),
    }).pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );
  }

  saveSourceAccountsObservable(): Observable<any> {
    const touchedControls = this.sourceAccounts.controls.filter(
      (control) => control.dirty
    );
    if (!touchedControls.length) return of(null);

    const saveArray: Observable<any>[] = [];
    touchedControls.forEach((control) => {
      saveArray.push(
        this.sourceAccountService.put$(
          control.value.id,
          new SourceAccountModel(control.value)
        )
      );
    });
    return concat(...saveArray);
  }

  getAccount() {
    this.busy.load = this.accountService.getChildAccount(this.id).subscribe(
      (childAccount) => {
        this.form.patchValue(childAccount);
        childAccount.sourceAccounts.forEach((s) => {
          this.sourceAccounts.push(this.createSourceAccountSubform(s));
        });
        this.originalAccount = childAccount;
        this.configureControlSubscriptions();
      },
      (err) => this.showError(err)
    );
  }

  createSourceAccountSubform(sourceAccount: SourceAccount): UntypedFormGroup {
    return this.formBuilder.group({
      sourceAccount: [sourceAccount],
      id: [sourceAccount.id],
      division: [sourceAccount.division],
      tradingAccount: [sourceAccount.tradingAccount],
      inactive: [sourceAccount.inactive],
    });
  }

  configureControlSubscriptions() {
    // Account Type Mode to 'Inherit' will grab parent's account type
    this.form
      .get('accountTypeMode')
      .valueChanges.subscribe((accountTypeMode) => {
        if (
          accountTypeMode === AccountTypeMode.Inherited &&
          this.originalAccount.parent
        ) {
          this.form.patchValue({
            accountType: this.originalAccount.parent.accountType,
          });
        }
      });

    // Default division selection will set all blank accounts to that value
    this.form.get('division').valueChanges.subscribe((division) => {
      if (division) {
        this.sourceAccounts.controls.forEach((sourceAccountControl) => {
          const divisionControl = sourceAccountControl.get('division');
          if (!divisionControl.value) {
            divisionControl.setValue(division);
            divisionControl.markAsDirty();
          }
        });
      }
    });

    // Default trading account selection will set all blank accounts to that value
    this.form.get('tradingAccount').valueChanges.subscribe((tradingAccount) => {
      if (tradingAccount) {
        this.sourceAccounts.controls.forEach((sourceAccountControl) => {
          const tradingAccountControl =
            sourceAccountControl.get('tradingAccount');
          if (!tradingAccountControl.value) {
            tradingAccountControl.setValue(tradingAccount);
            tradingAccountControl.markAsDirty();
          }
        });
      }
    });
  }

  showError(error) {
    this.error = error;
    this.messageService.error(error);
  }

  close() {
    if (this.gridRefreshRequired) this.activeModal.close();
    this.activeModal.dismiss();
  }
}
