import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'crs-source-type-renderer',
  template: `
    <span *ngIf="params.value">
      <img
        class="icon-img me-2"
        title="{{ params.value.name }}"
        [src]="params.value.icon"
      />
      <span>{{ params.value.name }}</span>
    </span>
  `,
})
export class SourceTypeRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
