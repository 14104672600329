import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SourceAccountService } from './../../../../ledger/sourceAccounts/source-account.service';
import { DepreciationType } from './../../../assets/depreciation-type';
import { JournalGrid } from 'src/app/accounting/ledger/journals/journal/journal-grid';

@Component({
  selector: 'crs-asset-group-journal-settings',
  templateUrl: './asset-group-journal-settings.component.html'
})
export class AssetGroupJournalSettingsComponent implements OnInit, OnDestroy {

  @ViewChild('classificationTemplate', { static: true }) classificationTemplate: ElementRef;
  @ViewChild('accountSearchTemplate', { static: true }) accountSearchTemplate: ElementRef;

  @Input() formGroup: UntypedFormGroup;
  @Output() remove = new EventEmitter<UntypedFormGroup>();

  get journalAccounts() { return this.formGroup.get('gridAccounts').value; }
  depreciationTypes = DepreciationType;
  error: string;

  journalGrid: JournalGrid;

  subscriptions: Subscription[] = [];

  constructor(private readonly _sourceAccountService: SourceAccountService) { }

  ngOnInit(): void {
    this.journalGrid = new JournalGrid(this.classificationTemplate, this.accountSearchTemplate, null, () => this.onValuesChanged());

    this._sourceAccountService.getForSource(this.formGroup.value.source.id)
      .subscribe(a => this.journalGrid.accounts = a, err => this.error = err);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onValuesChanged() {
    this.formGroup.get('depreciationSourceAccount').setValue(this.journalAccounts[0] ? this.journalAccounts[0].sourceAccount : null);
    this.formGroup.get('accumulatedSourceAccount').setValue(this.journalAccounts[1] ? this.journalAccounts[1].sourceAccount : null);
  }

  close() {
    this.remove.next(this.formGroup);
  }
}
