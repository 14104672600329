<div class="modal-header">
  <h4 class="modal-title">{{ modalTitle }}</h4>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="onClickClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<crs-busy *ngIf="isFetchingAccessDocumentsUserActive$ | async" />

<form [formGroup]="form" (validSubmit)="onValidSubmit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group required">
          <label class="control-label">Document Title</label>
          <input
            class="form-control"
            formControlName="documentTitle"
            type="text"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-check mb-3 d-inline-block">
          <input
            class="form-check-input"
            formControlName="saveToAccessDocuments"
            id="saveToAccessDocumentsCheckbox"
            type="checkbox"
          />
          <label class="form-check-label" for="saveToAccessDocumentsCheckbox">
            Save to Access Documents
          </label>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="isAccessDocumentsUserActive === false">
      <div class="col-md-12 mb-3">
        You do not have permission to perform that action because
        <br />
        your <b>User ID: {{ user.email }}</b>
        is not entitled to use Access Documents.
        <div class="spacer"></div>
        Please contact your Practice Administrator for assistance.
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label
            class="control-label"
            ngbTooltip="Document tags can be set up in Access Documents by your Administrator."
          >
            Document Tags
          </label>
          <ng-select
            bindLabel="tagText"
            bindValue="tagId"
            formControlName="selectedDocumentTagIds"
            placeholder="Select or type to search"
            [clearable]="true"
            [closeOnSelect]="false"
            [items]="documentTags"
            [loading]="isFetchingAccessDocumentsTags$ | async"
            [multiple]="true"
            [readonly]="!form.value.saveToAccessDocuments"
            [virtualScroll]="true"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-check mb-3 d-inline-block">
          <input
            class="form-check-input"
            formControlName="saveToLocalDevice"
            id="saveToLocalDeviceCheckbox"
            type="checkbox"
          />
          <label class="form-check-label" for="saveToLocalDeviceCheckbox">
            Save to local device
          </label>
        </div>
      </div>
    </div>

    <crs-form-error [error]="error" />
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="
        !form.valid ||
        (!form.value.saveToAccessDocuments && !form.value.saveToLocalDevice)
      "
      [promiseBtn]="busy.submit"
    >
      Export
    </button>
    <button class="btn btn-secondary" type="button" (click)="onClickClose()">
      Cancel
    </button>
  </div>
</form>
