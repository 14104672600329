import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, EMPTY } from 'rxjs';
import { tap, catchError, exhaustMap, finalize, switchMap } from 'rxjs/operators';

import { Policy, PolicyService, PolicyLocation, PolicyLevel } from '../';
import { MessageService } from '../../../../../core';
import { getDefaultGridOptions, ProgressBarService, NgProgressRef } from '../../../../../shared';
import File = PolicyLevel.File;


enum Interaction {
  Move,
  Add
}

class InteractionData {
  interaction: Interaction;
  moveId: string;
  moveBehindId: string;
}

@Component({
  selector: 'crs-file-policies',
  templateUrl: './file-policies.component.html'
})
export class FilePoliciesComponent implements OnInit {

  constructor(private readonly _policyService: PolicyService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService,
    private readonly _progressBar: ProgressBarService) {
  }

  fileId: string;
  error: string;

  busy = {
    load: false,
    interactionPending: false
  };
  policiesObservable: Observable<any>;

  gridOptions = getDefaultGridOptions();
  policyLocations = PolicyLocation;
  policies: Policy[] = [];
  pendingPolicies: Policy[] = [];

  progress: NgProgressRef;

  interactionStream = new Subject<InteractionData>();

  sortActive: boolean;
  filterActive: boolean;
  gridApi;

  isMaster(params) {
    return !!params.data && params.data.level < 2;
  }

  ngOnInit() {

    this._route.params.subscribe(() => {
      this.fileId = this._route.snapshot.parent.paramMap.get('id');
      this.getPolicies().subscribe();
    });

    this.configureGridOptions();
    this.configureInteractionStream();
    this.progress = this._progressBar.ref('gridLoadingBar');
  }

  getPolicies(showLoader = true) {
    this.busy.load = showLoader;
    return this._policyService.getAll(this.fileId).pipe(tap(policies => {
      this.busy.load = false;
      this.policies = policies;
      this.pendingPolicies = null;
    }), catchError(err => {
      this.showError(err);
      return of([]);
    }));
  }

  addPolicy() {
    this._router.navigate(['./file/add', {level: PolicyLevel.File, fileId: this.fileId}], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectPolicy(param) {
    if (!param.data) return;
    this._router.navigate(['./file/' + param.data.id, { level: PolicyLevel.File, fileId: this.fileId}], { relativeTo: this._route });
  }

  configureInteractionStream() {
    this.interactionStream.pipe(
      tap(() => { this.error = null; }),
      exhaustMap(param => this.handleInteraction(param))
    )
      .subscribe();
  }

  handleInteraction(param: InteractionData): Observable<any> {

    let observable: Observable<any> = EMPTY;

    if (param.interaction === Interaction.Add) {
      this.addPolicy();
      return EMPTY;
    }

    if (param.interaction === Interaction.Move) {
      this.progress.start();
      observable = this._policyService.reorderFilePolicy(param.moveId, param.moveBehindId)
        .pipe(
          switchMap(() => this._policyService.getAll(this.fileId)),
          tap(p => {
            this.policies = p;
            this.gridOptions.api.setRowData(this.policies);
            this.pendingPolicies = null;
          }),
          switchMap(() => this.getPolicies(false)),
          catchError(err => {
            this.pendingPolicies = null;
            this.gridApi.setRowData(this.policies);
            this.showError(err);
            return EMPTY;
          }));
    }

    this.busy.interactionPending = true;
    return observable.pipe(
      catchError(err => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => {
        this.progress.complete();
        this.busy.interactionPending = false;
      }));
  }

  configureGridOptions() {
    this.gridOptions.immutableData = true;
    this.gridOptions.animateRows = true;
    this.gridOptions.defaultColDef.sortable = false;

    this.gridOptions.getRowNodeId = node => node.id;
    this.gridOptions.onRowDragMove = param => this.onRowDragMove(param);
    this.gridOptions.onRowDragLeave = param => this.onRowDragLeave(param);
    this.gridOptions.onRowDragEnd = param => this.onRowDragEnd(param);
    this.gridOptions.groupDefaultExpanded = -1;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Location',
      cellRendererParams: {
        suppressCount: true
      }
    };
    this.gridOptions.groupUseEntireRow = true;
    this.gridOptions.groupRowInnerRenderer = 'enumRenderer';
    this.gridOptions.groupRowRendererParams = { enum: PolicyLocation };
    this.gridOptions.onGridReady = (param) => {
      this.gridApi = param.api;
    };
    this.gridOptions.onSortChanged = () => {
      const sortModel = this.gridApi.getSortModel();
      this.sortActive = sortModel && sortModel.length > 0;
      this.updateRowDragSuppression();
    };
    this.gridOptions.onFilterChanged = () => {
      this.filterActive = this.gridApi.isAnyFilterPresent();
      this.updateRowDragSuppression();
    };
    this.gridOptions.isRowSelectable = (param) => {
      return param.data && param.data.level >= 2;
    };
  }

  rowDrag(params) {
    return params.node && params.node.data && params.node.data.level >= PolicyLevel.File;
  }

  onRowDragMove(event) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
  }

  onRowDragLeave(event) {
    this.pendingPolicies = this.policies;
    event.api.setRowData(this.pendingPolicies);
  }

  onRowDragEnd(event) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);

    const param = new InteractionData();
    param.interaction = Interaction.Move;
    param.moveId = event.node.data.id;
    param.moveBehindId = event.overNode.childIndex === 0 ?
      null :
      event.overNode.parent.childrenAfterGroup[event.overNode.childIndex - 1].data.id;
    this.interactionStream.next(param);
  }

  executeUIMove(event) {
    const movingNode = event.node;
    const overNode = event.overNode;
    const rowNeedsToMove = movingNode !== overNode && overNode.data && movingNode.data.location === overNode.data.location;
    if (rowNeedsToMove) {
      const fromIndex = this.policies.indexOf(movingNode.data);
      const toIndex = this.policies.indexOf(overNode.data);
      this.pendingPolicies = this.policies.slice();
      moveInArray(this.pendingPolicies, fromIndex, toIndex);
      event.api.setRowData(this.pendingPolicies);
      event.api.clearFocusedCell();
    }
    function moveInArray(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }

  updateRowDragSuppression() {
    const suppressRowDrag = this.sortActive || this.filterActive;
    this.gridApi.setSuppressRowDrag(suppressRowDrag);
  }

}
