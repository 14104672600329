<!-- Header -->
<div
  class="header-container container-grey-bg"
  [class]="route.children.length ? 'pad-no-bottom' : 'pad'"
>
  <div>
    <a class="btn btn-ghost" [routerLink]="['/clients']">
      <i aria-hidden="true" class="fas fa-arrow-left inset"></i>
      Clients
    </a>
  </div>
  <div class="d-flex align-middle pt-2">
    <h2 class="file-name-heading">Admin Centre</h2>
  </div>
</div>

<!-- Navbar -->
<nav
  class="navbar navbar-expand-lg navbar-light bg-light"
  *ngIf="route.children.length"
>
  <ul class="navbar-nav pad-x">
    <li class="nav-item dropdown me-3">
      <a
        aria-expanded="false"
        aria-haspopup="true"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
        id="navbarDropdownMenuLink"
      >
        Organisation
      </a>
      <div aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu">
        <a class="dropdown-item" routerLink="offices">Practices</a>
        <a class="dropdown-item" routerLink="teams">Teams</a>
        <a class="dropdown-item" routerLink="users">Users</a>
      </div>
    </li>
    <li class="nav-item dropdown me-3" *ngIf="licenseInfo?.ledger">
      <a
        aria-expanded="false"
        aria-haspopup="true"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
        id="navbarDropdownMenuLinkCoA"
      >
        Chart of Accounts
      </a>
      <div aria-labelledby="navbarDropdownMenuLinkCoA" class="dropdown-menu">
        <!-- <a class="dropdown-item" href="#">Account Types</a> -->
        <a
          class="dropdown-item"
          routerLink="chart/headers-accounts-and-tax-codes"
        >
          Headers, Accounts and Tax Codes
        </a>
        <a class="dropdown-item" routerLink="chart/matching-rule-sets">
          Matching Rule Sets
        </a>
      </div>
    </li>
    <li class="nav-item dropdown me-3" *ngIf="licenseInfo?.ledger">
      <a
        aria-expanded="false"
        aria-haspopup="true"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
        id="navbarDropdownMenuLinkCoA"
      >
        Resources
      </a>
      <div aria-labelledby="navbarDropdownMenuLinkCoA" class="dropdown-menu">
        <!-- <a class="dropdown-item" href="#">Account Types</a> -->
        <a class="dropdown-item" routerLink="resources/image-library">
          Image Library
        </a>
      </div>
    </li>
    <li class="nav-item dropdown me-3" *ngIf="licenseInfo?.ledger">
      <a
        aria-expanded="false"
        aria-haspopup="true"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
        id="navbarDropdownMenuLinkCoA"
      >
        Configuration
      </a>
      <div aria-labelledby="navbarDropdownMenuLinkCoA" class="dropdown-menu">
        <a class="dropdown-item" routerLink="configuration/integration-server">
          Integration Server
        </a>
        <a
          class="dropdown-item"
          routerLink="configuration/document-integration"
        >
          Document Integration
        </a>
      </div>
    </li>
    <li class="nav-item dropdown me-3" *ngIf="licenseInfo?.ledger">
      <a
        aria-expanded="false"
        aria-haspopup="true"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
        id="reportingDropdownLinkReporting"
      >
        Reporting
      </a>
      <div
        aria-labelledby="reportingDropdownLinkReporting"
        class="dropdown-menu"
      >
        <a class="dropdown-item" routerLink="reports/styles">Report Styles</a>
        <a class="dropdown-item" routerLink="reports/masters">Report Masters</a>
        <a class="dropdown-item" routerLink="reports/page-templates">
          Page Templates
        </a>
        <a class="dropdown-item" routerLink="reports/notes">Notes</a>
      </div>
    </li>
  </ul>
</nav>

<!-- Main Content at Root -->
<div
  class="admin-center-content d-flex justify-content-between gap-3 pad"
  *ngIf="!route.children.length"
>
  <!-- First Column: Organisation and Chart of Accounts -->
  <div class="d-flex flex-column gap-3 w-100">
    <div class="card card-pop">
      <h5 class="card-header">Organisation</h5>
      <ul class="list-group list-group-flush">
        <a class="list-group-item list-group-item-action" *ngIf="licenseInfo">
          Ref Code: {{ licenseInfo.tenantId }}
        </a>
        <a class="list-group-item list-group-item-action" routerLink="offices"
          >Practices</a
        >
        <a class="list-group-item list-group-item-action" routerLink="teams"
          >Teams</a
        >
        <a class="list-group-item list-group-item-action" routerLink="users"
          >Users</a
        >
      </ul>
    </div>

    <div class="card card-pop" *ngIf="licenseInfo?.ledger">
      <h5 class="card-header">Chart of Accounts</h5>
      <ul class="list-group list-group-flush">
        <a
          class="list-group-item list-group-item-action"
          routerLink="chart/headers-accounts-and-tax-codes"
        >
          Headers, Accounts and Tax Codes
        </a>
        <a
          class="list-group-item list-group-item-action"
          routerLink="chart/matching-rule-sets"
        >
          Matching Rule Sets
        </a>
      </ul>
    </div>
  </div>

  <!-- Second Column: Resources and Configuration -->
  <div class="d-flex flex-column gap-3 w-100">
    <div class="card card-pop" *ngIf="licenseInfo?.ledger">
      <h5 class="card-header">Resources</h5>
      <ul class="list-group list-group-flush">
        <a
          class="list-group-item list-group-item-action"
          routerLink="resources/image-library"
        >
          Image Library
        </a>
      </ul>
    </div>

    <div class="card card-pop" *ngIf="licenseInfo?.ledger">
      <h5 class="card-header">Configuration</h5>
      <ul class="list-group list-group-flush">
        <a
          class="list-group-item list-group-item-action"
          routerLink="configuration/integration-server"
        >
          Integration Server
        </a>
        <a
          class="list-group-item list-group-item-action"
          routerLink="configuration/document-integration"
        >
          Document Integration
        </a>
      </ul>
    </div>
  </div>

  <!-- Third Column: Reporting -->
  <div class="d-flex flex-column gap-3 w-100">
    <div class="card card-pop" *ngIf="licenseInfo?.ledger">
      <h5 class="card-header">Reporting</h5>
      <ul class="list-group list-group-flush">
        <a
          class="list-group-item list-group-item-action"
          routerLink="reports/styles"
          >Report Styles</a
        >
        <a
          class="list-group-item list-group-item-action"
          routerLink="reports/masters"
          >Report Masters</a
        >
        <a
          class="list-group-item list-group-item-action"
          routerLink="reports/page-templates"
          >Page Templates</a
        >
        <a
          class="list-group-item list-group-item-action"
          routerLink="reports/notes"
          >Notes</a
        >
      </ul>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
