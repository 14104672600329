import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DesktopMigrationComponent } from 'src/app/accounting/migrations/desktop-migration/desktop-migration.component';
import { HeadersAccountsAndTaxCodesComponent } from './accounting/admin/headers-accounts-and-tax-codes/headers-accounts-and-tax-codes.component';
import { HeadersAndAccountsComponent } from './accounting/chart/headers-and-accounts/headers-and-accounts.component';
import { StandardTaxCodesComponent } from './accounting/chart/standard-tax-codes/standard-tax-codes.component';
import { ImageComponent } from './accounting/reports/admin/images/image/image.component';
import { ImagesComponent } from './accounting/reports/admin/images/images/images.component';
import { NotesComponent } from './accounting/reports/admin/notes/notes/notes.component';
import { ReportMasterComponent } from './accounting/reports/admin/report-masters/report-master/report-master.component';
import { ReportMastersComponent } from './accounting/reports/admin/report-masters/report-masters/report-masters.component';
import { ReportPageTypeComponent } from './accounting/reports/admin/report-page-type/report-page-type.component';
import { ReportPageTypesComponent } from './accounting/reports/admin/report-page-types/report-page-types.component';
import { ReportStyleComponent } from './accounting/reports/admin/report-styles/report-style/report-style.component';
import { ReportStylesComponent } from './accounting/reports/admin/report-styles/report-styles/report-styles.component';
import { DisclosureTemplateComponent } from './accounting/reports/notes/disclosures/disclosure-template/disclosure-template.component';
import { FirmDisclosureTemplateComponent } from './accounting/reports/notes/disclosures/firm-disclosure-template/firm-disclosure-template.component';
import { FirmDisclosureTemplatesComponent } from './accounting/reports/notes/disclosures/firm-disclosure-templates/firm-disclosure-templates.component';
import { FirmPoliciesComponent } from './accounting/reports/notes/policies/firm-policies/firm-policies.component';
import { FirmPolicyComponent } from './accounting/reports/notes/policies/firm-policy/firm-policy.component';
import { PolicyComponent } from './accounting/reports/notes/policies/policy/policy.component';
import { ReportingSuiteComponent } from './accounting/reports/notes/reportingSuites/reporting-suite/reporting-suite.component';
import { ReportingSuitesComponent } from './accounting/reports/notes/reportingSuites/reporting-suites/reporting-suites.component';
// import { CentralComponent } from './central/central.component';
// import { DatabaseComponent } from './central/databases/database/database.component';
// import { DatabasesComponent } from './central/databases/databases/databases.component';
// // import { FirmComponent } from './central/firms/firm/firm.component';
// import { FirmsComponent } from './central/firms/firms/firms.component';
import { DocumentIntegrationConfigurationComponent } from './configuration/document-integration';
import { IntegrationServerConfigurationComponent } from './configuration/integration-server';
import { AuthGuard } from './core';
import { ErrorPageComponent } from './core/components/error-page/error-page.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { SystemAdminLandingPageComponent } from './core/components/system-admin/landing-page/admin-landing-page.component';
import { SystemAdminGuard } from './core/guards/system-admin.guard';
import { AdminCentreComponent } from './firm/admin/admin-centre/admin-centre.component';
import { ClientsComponent } from './firm/clients/clients/clients.component';
import { EntitiesComponent } from './firm/entities/entities/entities.component';
import { OfficeComponent } from './firm/offices/office/office.component';
import { OfficesComponent } from './firm/offices/offices/offices.component';
import { RegisterComponent } from './firm/register/register.component';
import { SignUpComponent } from './firm/sign-up/sign-up.component';
import { TeamComponent } from './firm/teams/team/team.component';
import { TeamsComponent } from './firm/teams/teams/teams.component';
import { SecurityRole } from './firm/users';
import { UserInviteAcceptComponent } from './firm/users/user-invite-accept/user-invite-accept.component';
import { UserComponent } from './firm/users/user/user.component';
import { UsersComponent } from './firm/users/users/users.component';
import {
  accountingDefaultFileRoute,
  accountingRoute,
} from './routing/accounting-routing';
import {
  BacoRoute,
  BacoAuthCompletionRoute,
  BacoSystemAdminRoute,
} from './baco/baco-routes';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { MatchingRuleSetsComponent } from './accounting/chart/matching-rule-sets/matching-rule-sets.component';
import { MasterMatchingRulesComponent } from './accounting/chart/matching-rule-sets/master-matching-rules/master-matching-rules.component';
import { LedgerAuthGuard } from './core/guards/ledger-auth.guard';

const routes: Routes = [
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [LedgerAuthGuard],
    children: [
      {
        path: ':id',
        component: EntitiesComponent,
      },
    ],
    data: { title: 'Clients' },
  },
  {
    path: 'register',
    canActivate: [SystemAdminGuard],
    component: RegisterComponent,
    data: { title: 'Sign Up' },
  },
  {
    path: 'system-admin',
    canActivate: [SystemAdminGuard],
    component: SystemAdminLandingPageComponent,
    data: { title: 'System Admin' },
  },
  {
    path: 'import-desktop/:clientId',
    component: DesktopMigrationComponent,
    canActivate: [LedgerAuthGuard],
    data: { title: 'Import Desktop HandiLedger File' },
  },
  {
    path: 'profile',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { title: 'Profile' },
  },
  {
    path: 'profile/acceptinvite',
    component: UserInviteAcceptComponent,
    canActivate: [AuthGuard],
    data: { title: 'Invite Acceptance' },
  },
  {
    path: 'admin',
    component: AdminCentreComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      // Firm - added directly because production was failing on navigating to these when referencing the static collections?
      {
        path: 'users',
        component: UsersComponent,
        data: { title: 'Users' },
      },
      {
        path: 'users/:id',
        component: UserComponent,
        data: { title: 'User' },
      },
      {
        path: 'configuration/integration-server',
        component: IntegrationServerConfigurationComponent,
        data: { title: 'Integration Server Configuration' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'configuration/document-integration',
        component: DocumentIntegrationConfigurationComponent,
        data: { title: 'Document Integration Configuration' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'offices',
        component: OfficesComponent,
        data: { title: 'Offices' },
      },
      {
        path: 'offices/:id',
        component: OfficeComponent,
        data: { title: 'Office' },
      },
      {
        path: 'teams',
        component: TeamsComponent,
        data: { title: 'Teams' },
      },
      {
        path: 'teams/:id',
        component: TeamComponent,
        data: { title: 'Team' },
      },
      {
        path: 'chart/headers-accounts-and-tax-codes',
        component: HeadersAccountsAndTaxCodesComponent,
        canActivate: [LedgerAuthGuard],
        canActivateChild: [LedgerAuthGuard],
        data: { title: 'Headers, Accounts and Tax Codes' },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'headers',
          },
          {
            path: 'headers',
            component: HeadersAndAccountsComponent,
            data: { title: 'Headers & Accounts' },
          },
          {
            path: 'accounts',
            component: HeadersAndAccountsComponent,
            data: { title: 'Headers & Accounts' },
          },
          {
            path: 'tax-codes',
            component: StandardTaxCodesComponent,
            data: { title: 'Tax Codes' },
          },
        ],
      },
      {
        path: 'chart/matching-rule-sets',
        component: MatchingRuleSetsComponent,
        data: { title: 'Matching Rule Sets' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'chart/matching-rule-sets/:id/master-matching-rules',
        component: MasterMatchingRulesComponent,
        data: { title: 'Matching Rule Sets' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'reports/page-templates',
        component: ReportPageTypesComponent,
        data: { title: 'Report Page Templates' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'reports/page-templates/:id',
        component: ReportPageTypeComponent,
        data: { title: 'Report Page Template' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'reports/styles',
        component: ReportStylesComponent,
        data: { title: 'Report Styles' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'reports/styles/:id',
        component: ReportStyleComponent,
        data: { title: 'Report Styles' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'resources/image-library',
        component: ImagesComponent,
        data: { title: 'Image Library' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'resources/image-library/:id',
        component: ImageComponent,
        data: { title: 'Image' },
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'reports/masters',
        component: ReportMastersComponent,
        data: { title: 'Report Masters' },
        canActivate: [LedgerAuthGuard],
        canActivateChild: [LedgerAuthGuard],
        children: [
          {
            path: ':id',
            component: ReportMasterComponent,
          },
        ],
      },
      {
        path: 'reports/notes',
        component: NotesComponent,
        canActivate: [LedgerAuthGuard],
        canActivateChild: [LedgerAuthGuard],
        data: { title: 'Notes' },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'reporting-suites',
          },
          {
            path: 'reporting-suites',
            component: ReportingSuitesComponent,
            data: { title: 'Reporting Suites' },
          },
          {
            path: 'policies',
            component: FirmPoliciesComponent,
            data: { title: 'Accounting Policies' },
          },
          {
            path: 'disclosure-templates',
            component: FirmDisclosureTemplatesComponent,
            data: { title: 'Disclosures' },
          },
        ],
      },
      {
        path: 'reports/notes/reporting-suites/:id',
        data: { title: 'Reporting Suite' },
        component: ReportingSuiteComponent,
        canActivate: [LedgerAuthGuard],
      },
      {
        path: 'reports/notes/policies/firm',
        data: { title: 'Accounting Policy' },
        canActivate: [LedgerAuthGuard],
        component: FirmPolicyComponent,
        children: [
          {
            path: ':id',
            component: PolicyComponent,
          },
        ],
      },
      {
        path: 'reports/notes/disclosure-templates/firm',
        data: { title: 'Disclosure Template' },
        component: FirmDisclosureTemplateComponent,
        canActivate: [LedgerAuthGuard],
        children: [
          {
            path: ':id',
            component: DisclosureTemplateComponent,
          },
        ],
      },
    ],
    data: { title: 'Admin', role: SecurityRole.Admin },
  },
  // {
  //   path: 'central',
  //   component: CentralComponent,
  //   canActivate: [AuthGuard],
  //   canActivateChild: [AuthGuard],
  //   children: [
  //     {
  //       path: 'firms',
  //       component: FirmsComponent,
  //       data: { title: 'Firms', master: true },
  //     },
  //     {
  //       path: 'firms/:id',
  //       component: FirmComponent,
  //       data: { title: 'Firm', master: true },
  //     },
  //     {
  //       path: 'databases',
  //       component: DatabasesComponent,
  //       data: { title: 'Databases', master: true },
  //     },
  //     {
  //       path: 'databases/:id',
  //       component: DatabaseComponent,
  //       data: { title: 'Database', master: true },
  //     },
  //   ],
  //   data: { title: 'Central', master: true },
  // },
  {
    path: 'register',
    component: RegisterComponent,
    data: { title: 'Sign Up' },
  },
  {
    path: 'signup',
    component: SignUpComponent,
    data: { title: 'Sign Up' },
  },
  {
    path: 'b2c_1a_v1_fa_sign_up',
    component: SignUpComponent,
    canActivate: [AuthGuard],
    data: { title: 'Signed Up' },
  },
  {
    path: 'b2c_1a_v1_fa_password_change',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { title: 'Password Change' },
  },
  accountingRoute,
  accountingDefaultFileRoute,
  BacoRoute,
  BacoAuthCompletionRoute,
  BacoSystemAdminRoute,
  { path: 'error/:errorType/:errorMessage', component: ErrorPageComponent },
  { path: 'error/:errorType', component: ErrorPageComponent },
  { path: 'styles', component: StyleGuideComponent },
  { path: '', redirectTo: '/clients', pathMatch: 'full' },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: '404 Not Found' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
