<div [formGroup]="detail">
  <div class="row" *ngIf="!file.entity">
    <div class="col-sm-6">
      <div class="form-group required">
        <label class="control-label">Entity</label>
        <ng-select
          bindLabel="legalName"
          bindValue="id"
          formControlName="entityId"
          [clearable]="false"
          [items]="entities"
        ></ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group required">
        <label class="control-label">Year</label>
        <ng-select
          bindLabel="year"
          bindValue="year"
          formControlName="year"
          [clearable]="false"
          [items]="years"
          (change)="onSelectYearChange($event)"
        ></ng-select>
      </div>
    </div>
    <div
      class="col-sm-6"
      *ngIf="detail.value.reportType < 3 && isMonthlyDepreciationEnabled"
    >
      <div class="form-group required">
        <label class="control-label">To Period</label>
        <ng-select
          bindLabel="name"
          bindValue="id"
          formControlName="toPeriod"
          [clearable]="false"
          [items]="monthlyDepreciationPeriods"
        ></ng-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group required">
        <label class="control-label">Report</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="reportType"
          [clearable]="false"
          [items]="depreciationReportTypes | enumToArray"
        ></ng-select>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group" *ngIf="detail.value.reportType < 3">
        <label class="control-label">Asset Groups</label>
        <crs-asset-group-select
          formControlName="assetGroups"
          placeholder="Show All"
          [entityId]="entityId"
          [fileId]="file.id"
          [multiple]="true"
        ></crs-asset-group-select>
      </div>

      <div class="form-group required" *ngIf="detail.value.reportType == 3">
        <label class="control-label">Pool</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="pool"
          [clearable]="false"
          [items]="depreciationPools | enumToArray"
        ></ng-select>
      </div>

      <div class="form-group required" *ngIf="detail.value.reportType == 3">
        <label class="control-label">Depreciation Type</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="depreciationType"
          [clearable]="false"
          [items]="depreciationTypes | enumToArray"
        ></ng-select>
      </div>

      <div class="form-group" *ngIf="detail.value.reportType == 3">
        <div class="form-check">
          <input
            class="form-check-input"
            formControlName="showPoolSummary"
            type="checkbox"
          />
          <label class="form-check-label">Includes Pool Summary</label>
        </div>
      </div>
    </div>
  </div>
</div>
