import { Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiType } from '../../sourcedata/enums/apiType';
import { LoginResult } from '../api-authorisation/api-authorisation.component';

@Component({
  selector: 'crs-api-authorisation-modal',
  templateUrl: './api-authorisation-modal.component.html',
  styleUrls: ['./api-authorisation-modal.component.scss']
})
export class ApiAuthorisationModalComponent{

  @Input() params;

  constructor(private activeModal: NgbActiveModal) { }

  onLoginResponse(result: LoginResult) {
    this.activeModal.close(result.result);
  }



}
