<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li aria-current="page" class="breadcrumb-item active">
      Headers, Accounts and Tax Codes
    </li>
  </ol>
</nav>

<ul class="nav nav-tabs">
  <li class="nav-item">
    <a
      class="nav-link"
      routerLinkActive="active"
      [routerLink]="headersOrAccountRoute"
    >
      Headers & Accounts
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="tax-codes" routerLinkActive="active">
      Tax Codes
    </a>
  </li>
</ul>

<div class="spacer-md"></div>

<router-outlet></router-outlet>
