<div *ngIf="!reportContent.output" class="mb-3">
  <!-- Uneditable Placeholders -->
  <crs-report-editable-element
    [index]="index"
    [reportContent]="reportContent"
    [reportMetaData]="reportMetaData"
    *ngIf="element.placeholderType != 21"
  >
    <crs-report-table-of-contents
      [element]="element"
      [reportContent]="reportContent"
      *ngIf="element.placeholderType == 3"
    ></crs-report-table-of-contents>

    <div *ngIf="element.placeholderType != 3">
      <div
        class="border p-2 bg-light pb-3"
        [style.min-height.px]="50"
        [ngSwitch]="element.placeholderType"
      >
        <div *ngSwitchCase="0">
          <i class="fas fa-th-list me-1" aria-hidden="true"></i>
          Financial Statement Placeholder
        </div>
        <div *ngSwitchCase="1">
          <i class="fas fa-list me-1" aria-hidden="true"></i>
          Accounting Policies Placeholder
        </div>
        <div *ngSwitchCase="2">
          <i class="fas fa-list-alt me-1" aria-hidden="true"></i>
          Notes Placeholder
        </div>
        <div *ngSwitchCase="31">
          <i class="fas fa-list me-1" aria-hidden="true"></i>
          <strong>Placeholder</strong>
          <p>Cash Reconciliation</p>
        </div>
        <div *ngSwitchCase="32">
          <i class="fas fa-list me-1" aria-hidden="true"></i>
          <strong>Placeholder</strong>
          <p>
            Net Profit after Tax to Cash Flows from Operations Reconciliation
          </p>
        </div>
      </div>
    </div>
  </crs-report-editable-element>

  <!-- Account Note Placeholder-->
  <crs-report-editable-element
    [index]="index"
    [reportContent]="reportContent"
    [reportMetaData]="reportMetaData"
    (edit)="onEdit()"
    [onSubmit]="onSubmit"
    [editable]="element.placeholderType == 21"
    [cancellable]="true"
  >
    <div viewer>
      <div class="border p-2 bg-light pb-3" [style.min-height.px]="50">
        <i class="fas fa-list-alt me-1" aria-hidden="true"></i>
        <strong>Account Note Placeholder</strong>
        <small *ngIf="element.identifier">
          (<em>Identifier:</em> {{ element.identifier }})</small
        >
        <small *ngIf="!element.identifier"> <em> Default</em></small>
        <div>
          {{ element.title }}
        </div>
      </div>
    </div>

    <div editor>
      <form [formGroup]="form" novalidate *ngIf="isEditing">
        <div class="row">
          <div class="col-form-md">
            <div class="form-group">
              <label class="control-label">Identifier</label>
              <input
                type="text"
                class="form-control"
                formControlName="identifier"
                placeholder="Default"
              />
              <small class="form-text text-muted">
                If empty this is the default placeholder for all accounts linked
                to this disclosure. If more than one placeholder exists, add an
                identifier in order to link accounts specifically to this
                placeholder.
              </small>
            </div>
          </div>
          <div class="col-form-lg">
            <div class="form-group">
              <label class="control-label">Title</label>
              <input type="text" class="form-control" formControlName="title" />
              <small class="form-text text-muted">
                Optionally add a title to this placeholder. If only one account
                is linked to this placeholder, this field will be used as the
                title instead of the account name.
              </small>
            </div>

            <div class="form-group">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="addGrandTotal"
                />
                <label class="form-check-label">Add Grand Total</label>
              </div>
              <small class="form-text text-muted">
                If there is more than one account group linked to this
                placeholder, add a grand total
              </small>
            </div>
          </div>
          <div class="col-form-md">
            <div
              class="form-group required"
              *ngIf="form.get('addGrandTotal').value"
            >
              <label class="control-label">Grand Total Ledger Side</label>
              <ng-select
                [items]="ledgerSides"
                bindValue="value"
                bindLabel="name"
                formControlName="grandTotalLedgerSide"
                [clearable]="false"
              ></ng-select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </crs-report-editable-element>
</div>
