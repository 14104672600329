import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, Subject, from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  exhaustMap,
  finalize,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Dataset, DatasetService, SourceTypeId } from 'src/app/accounting';
import { DateService, MessageService, ModalService } from 'src/app/core';
import { ErrorMessage } from 'src/app/shared/validation';
import { BacoTransactionStore, IDateRange } from '../..';
import { DatePipe } from '@angular/common';
import { BACO_DATE_FORMAT } from 'src/app/baco/baco.tokens';
import { getDefaultGridOptions } from 'src/app/shared/ag-grid/defaultGridOptions';
import { BacoBankAccountDto } from 'src/app/baco/interfaces';
import { BankAccountClient } from 'src/app/baco/common';
import {
  CellClassParams,
  CellClickedEvent,
  GridApi,
  GridReadyEvent,
  ValueFormatterParams,
} from 'ag-grid-community';

interface BacoBankAccountDtoEx extends BacoBankAccountDto {
  details: string;
  notLockedTransactionCount: number;
  uncodedTransactionCount: number;
  transfer: boolean;
}

@Component({
  selector: 'crs-transfer-transaction',
  templateUrl: './transfer-transaction.component.html',
})
export class TransferTransactionComponent implements OnInit, OnDestroy {
  readonly CUSTOM_ERROR_MESSAGES: ErrorMessage[] = [
    {
      error: 'dateMustAlign',
      format: (_label, _error) =>
        `No datasets found. The transaction dates must align within a single dataset with an Accountant's Chart as the source`,
    },
  ];

  @Input() params: {
    fileId: string;
    startDate: Date;
    endDate: Date;
  };

  busy = {
    submit: null,
  };
  error: string = null;

  private gridApi!: GridApi;
  gridOptions = getDefaultGridOptions();
  bankAccounts!: BacoBankAccountDtoEx[];

  form = new FormGroup({
    startDate: new FormControl<Date>(null, {
      validators: [Validators.required],
    }),
    endDate: new FormControl<Date>(null, {
      validators: [Validators.required],
    }),
    destinationDataset: new FormControl<Dataset>(null, {
      validators: [this.transactionDatesMustAlignWithDatasetRangeValidator()],
    }),
  });

  destinationDatasets: Dataset[];

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private submitButtonStream$ = new Subject();

  constructor(
    private activeModal: NgbActiveModal,
    private datasetService: DatasetService,
    private dateService: DateService,
    private bankAccountClient: BankAccountClient,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public readonly transactionStore: BacoTransactionStore,
    private readonly modalService: ModalService,
    private readonly _datePipe: DatePipe,
    @Inject(BACO_DATE_FORMAT) private _dateFormat: string
  ) {}

  ngOnInit(): void {
    this.form.patchValue({
      startDate: this.params.startDate,
      endDate: this.params.endDate,
    });

    this.fetchDestinationDatasets();
    this.startDateSubscription();
    this.endDateSubscription();
    this.configureSubmit();
    this.configureGridOptions();

    const bankAccounts$ = this.transactionStore.bankAccounts$.pipe(
      map((stateful) =>
        stateful.data?.map((bankAccount) => ({
          ...bankAccount,
          transfer: false,
          details: '',
          notLockedTransactionCount: 0,
          uncodedTransactionCount: 0,
        }))
      )
    );

    bankAccounts$.subscribe((accounts) => {
      this.bankAccounts = accounts?.filter((account) => !account.isHidden);
      accounts?.forEach((account) => this.getTransactionDetails(account));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onClickClose(): void {
    this.activeModal.dismiss();
  }

  onValidSubmit(): void {
    const destinationDataset = this.form.get('destinationDataset').value;
    const bankAccountsToBeTransferred = this.bankAccounts?.filter(
      (account) => account.transfer === true
    );

    const total = bankAccountsToBeTransferred.reduce(
      (total, bankAccount) => total + bankAccount.notLockedTransactionCount,
      0
    );
    const transaction = total === 1 ? 'transaction' : 'transactions';

    this.modalService.confirmation(
      `Transfer ${total} ${transaction} to the dataset "${destinationDataset.name}"?`,
      () => this.submitButtonStream$.next(),
      false,
      null,
      'Transfer'
    );
  }

  private configureSubmit(): void {
    this.submitButtonStream$
      .pipe(
        tap(() => (this.error = null)),
        exhaustMap(() => this.submit$())
      )
      .subscribe();
  }

  private submit$(): Observable<any> {
    const loading$ = new Subject();
    this.busy.submit = loading$.subscribe();

    const destinationDataset = this.form.get('destinationDataset').value;
    const bankAccountsToBeTransferred = this.bankAccounts?.filter(
      (account) => account.transfer === true
    );

    return this.transactionStore.dateRange$.pipe(
      take(1),
      concatMap((dateRange: IDateRange) =>
        // Use from() to convert the array into an observable sequence
        from(bankAccountsToBeTransferred).pipe(
          // Use concatMap() to queue API calls for each bank account
          concatMap((bankAccount) =>
            // Call the API for each bank account sequentially
            this.bankAccountClient
              .transferTransactions(
                bankAccount.id,
                destinationDataset.id,
                dateRange
              )
              .pipe(
                catchError((error) => {
                  throw error;
                })
              )
          ),
          finalize(() => {
            loading$.complete();
          }),
          // Take until the component is destroyed to avoid memory leaks
          takeUntil(this.destroy$)
        )
      ),
      tap({
        error: (error) => {
          this.showError(error);
        },
        complete: () => {
          this.activeModal.close();
        },
      })
    );
  }

  private fetchDestinationDatasets(): void {
    this.datasetService
      .getAll$(this.params.fileId)
      .pipe(
        catchError((error) => {
          this.showError(error);
          return of([]);
        }),
        tap((datasets: Dataset[]) => {
          this.destinationDatasets = datasets.filter((dataset) => {
            if (dataset.source.sourceTypeId !== SourceTypeId.ChartOfAccounts) {
              return false;
            }

            const startDate = this.form.get('startDate').value;
            const endDate = this.form.get('endDate').value;

            const datasetStartDate = new Date(
              dataset.startDate.setHours(0, 0, 0, 0)
            );
            const datasetEndDate = new Date(
              dataset.endDate.setHours(23, 59, 59, 999)
            );

            const isStartDateWithinDatasetRange = startDate >= datasetStartDate;
            const isEndDateWithinDatasetRange = endDate <= datasetEndDate;

            return isStartDateWithinDatasetRange && isEndDateWithinDatasetRange;
          });
        }),
        tap(() => {
          this.form.get('destinationDataset').markAsTouched();

          const destinationDataset = this.form.get('destinationDataset').value;
          const selectedDestinationDatasetExists =
            this.destinationDatasets?.some(
              (dataset) => dataset?.id === destinationDataset?.id
            );

          if (!selectedDestinationDatasetExists) {
            this.form.get('destinationDataset').patchValue(null);
          }
        })
      )
      .subscribe();
  }

  private startDateSubscription(): void {
    this.form
      .get('startDate')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((startDate) => {
        if (!startDate) {
          this.form.get('destinationDataset').patchValue(null);
          return;
        }

        if (this.form.get('startDate').invalid) {
          return;
        }

        if (startDate > this.form.get('endDate').value) {
          this.form.get('endDate').patchValue(startDate);
        }

        const startDateString = this.dateService.toString(
          startDate,
          'yyyy-MM-dd'
        );
        const queryParams: Params = {
          startDate: startDateString,
        };

        this.updateQueryParamsWithoutReloading(queryParams);

        this.fetchDestinationDatasets();
      });
  }

  private endDateSubscription(): void {
    this.form
      .get('endDate')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((endDate) => {
        if (!endDate) {
          this.form.get('destinationDataset').patchValue(null);
          return;
        }

        if (this.form.get('endDate').invalid) {
          return;
        }

        const endDateString = this.dateService.toString(endDate, 'yyyy-MM-dd');
        const queryParams: Params = {
          endDate: endDateString,
        };

        this.updateQueryParamsWithoutReloading(queryParams);

        this.fetchDestinationDatasets();
      });
  }

  private showError(error): void {
    this.error = error;
    this.messageService.error(error);
  }

  private transactionDatesMustAlignWithDatasetRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.destinationDatasets?.length < 1) {
        return { dateMustAlign: true };
      }

      if (!control?.value) {
        return { required: true };
      }

      return null;
    };
  }

  private getTransactionDetails(bankAccount: BacoBankAccountDtoEx): void {
    this.transactionStore.dateRange$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(({ startDate, endDate }) => {
        this.bankAccountClient
          .getTransactions(bankAccount.id, startDate, endDate)
          .subscribe((transactions) => {
            const notLockedTransactions = transactions?.filter(
              (transaction) => !transaction.locked
            );

            bankAccount.notLockedTransactionCount =
              notLockedTransactions?.length || 0;

            const uncodedTransactions = transactions?.filter(
              (transaction) => transaction.codedBy === null
            );

            bankAccount.uncodedTransactionCount =
              uncodedTransactions?.length || 0;

            const transaction =
              bankAccount.notLockedTransactionCount === 1
                ? 'transaction'
                : 'transactions';

            const uncodedTransactionString =
              bankAccount.uncodedTransactionCount > 0
                ? `(${bankAccount.uncodedTransactionCount} uncoded)`
                : '';

            bankAccount.details = `${bankAccount.notLockedTransactionCount} ${transaction} ${uncodedTransactionString}`;

            const {
              ledgerAccountName,
              uncodedTransactionCount,
              notLockedTransactionCount,
            } = bankAccount;

            bankAccount.transfer =
              ledgerAccountName !== null &&
              notLockedTransactionCount > 0 &&
              uncodedTransactionCount === 0;

            const rowNode = this.gridApi?.getRowNode(bankAccount.id);
            rowNode?.updateData(bankAccount);
          });
      });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public onCellClicked($event: CellClickedEvent) {
    if ($event.node.data.ledgerAccountCode === null) return;
    if ($event.node.data.uncodedTransactionCount !== 0) return;
    if ($event.node.data.notLockedTransactionCount === 0) return;

    $event.node.setDataValue('transfer', !$event.node.data.transfer);
  }

  public getCellClass(params) {
    const {
      ledgerAccountName,
      uncodedTransactionCount,
      notLockedTransactionCount,
    } = params?.data;

    if (
      ledgerAccountName !== null &&
      uncodedTransactionCount === 0 &&
      notLockedTransactionCount > 0
    ) {
      return 'cell-checkbox';
    }

    return '';
  }

  public hasBankAccountToBeTransferred(): boolean {
    return this.bankAccounts?.some((bankAccount) => bankAccount.transfer);
  }

  private configureGridOptions() {
    this.gridOptions.getRowNodeId = (node) => node.id;
  }

  private updateQueryParamsWithoutReloading(queryParams: Params): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }

  formatChartCode = (params: ValueFormatterParams) => {
    const chartCode = params?.data?.ledgerAccountCode
      ? `${params?.data?.ledgerAccountCode} - ${params?.data?.ledgerAccountName}`
      : params?.data?.ledgerAccountName;

    return chartCode ?? `Set the account in 'Bank Connections'`;
  };

  styleChartCode = (params: CellClassParams) => {
    return params?.data?.ledgerAccountName ? null : { color: 'red' };
  };
}
