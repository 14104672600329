import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, PagedResponse } from '../../core';
import { Client, ClientModel } from './client';
import { ClientEntitiesAndFiles } from './clientEntitiesAndFiles';
import { ClientEntitySearchResult } from './clientEntitySearchResult';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  readonly base: string = 'api/clients';

  constructor(private apiService: ApiService) { }

  getAllNames() {
    return this.apiService.get<any[]>(`${this.base}/names`);
  }

  get(id: string) {
    return this.apiService.get<Client>(this.base + '/' + id).pipe(map(c => new Client(c)));
  }

  find(search: string, id: string, page: number, pageSize: number) {
    let url = this.base;
    url = this.apiService.addQuery(url, 'search', search);
    url = this.apiService.addQuery(url, 'id', id);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    return this.apiService.get<PagedResponse<Client>>(url);
  }

  findCombined(search: string, clientId: string, mustHaveFiles: boolean, page: number, pageSize: number) {
    let url = this.base + '/and-entities';
    url = this.apiService.addQuery(url, 'search', search);
    url = this.apiService.addQuery(url, 'clientId', clientId);
    url = this.apiService.addQuery(url, 'mustHaveFiles', mustHaveFiles);
    url = this.apiService.addQuery(url, 'page', page);
    url = this.apiService.addQuery(url, 'pageSize', pageSize);
    return this.apiService.get<PagedResponse<ClientEntitySearchResult>>(url);
  }

  getEntitiesAndFilesForClient(id: string) {
    return this.apiService.get<ClientEntitiesAndFiles>(this.base + '/' + id + '/entities').pipe(map(r => new ClientEntitiesAndFiles(r)));
  }


  post(client: ClientModel) : Observable<string> {
    return this.apiService.post<string>(this.base, client);
  }


  put(client: ClientModel) {
    return this.apiService.put(this.base + '/' + client.id, client);
  }

  delete(id: string) {
    return this.apiService.delete(this.base + '/' + id);
  }

}
