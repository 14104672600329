import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {


  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: any, rounding?: number, showZero?: boolean): any {

    if (!value && !showZero) return null;

    rounding = rounding === undefined || rounding == null ? 2 : rounding;
    return this.decimalPipe.transform(value, '1.' + rounding + '-' + rounding);
  }

}
