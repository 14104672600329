<div class="report-content" cdkDropList (cdkDropListDropped)="drop($event)">
  <div class="element-wrapper">

    <div class="shadow p-3 mb-0" *ngIf="!content?.length && !output && !readonly">
      No Content... <br />
      <small>
        Hover over this element and select the plus icon to add content
      </small>
    </div>

    <crs-insert-element-line [index]="-1" [reportContent]="reportContent" [reportMetaData]="reportMetaData" [reportColumns]="reportColumns" *ngIf="!readonly"></crs-insert-element-line>
  </div>

  <div class="element-wrapper" *ngFor="let element of content; let i = index" [ngSwitch]="element.elementType" cdkDrag>
    <crs-report-header [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="0"></crs-report-header>
    <crs-report-paragraph [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="1"></crs-report-paragraph>
    <crs-report-signature [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="2"></crs-report-signature>
    <crs-report-snippet [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="3"></crs-report-snippet>
    <crs-report-horizontal-rule [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="4"></crs-report-horizontal-rule>
    <crs-report-financial [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="5"></crs-report-financial>
    <crs-report-placeholder [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="6"></crs-report-placeholder>
    <crs-report-spacer [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="7"></crs-report-spacer>
    <crs-report-table-of-contents [element]="element" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="8"></crs-report-table-of-contents>
    <crs-report-chart [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="9"></crs-report-chart>
    <crs-report-table [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" [reportColumns]="reportColumns" *ngSwitchCase="10"></crs-report-table>
    <crs-report-image [element]="element"  [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="11"></crs-report-image>
    <crs-report-auditor [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" *ngSwitchCase="12"></crs-report-auditor>
    <crs-report-table [element]="element" [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" [reportColumns]="reportColumns" *ngSwitchCase="15"></crs-report-table>
    <crs-insert-element-line [index]="i" [reportContent]="reportContent" [reportMetaData]="reportMetaData" [reportColumns]="reportColumns" *ngIf="!readonly"></crs-insert-element-line>
  </div>

</div>
