<div [formGroup]="formGroup">
  <div class="row">
    <div class="col-form-md">

      <div class="form-group required">
        <label class="control-label">Auditor's Name</label>
        <input type="text" class="form-control" formControlName="auditorName"/>
      </div>

      <div class="form-group required">
        <label class="control-label">Auditor's Title</label>
        <input type="text" class="form-control" formControlName="auditorTitle"/>
      </div>
      <div class="form-group required">
        <label class="control-label">Auditor's Email</label>
        <input type="text" class="form-control" formControlName="auditorEmail"/>
      </div>
      <div class="form-group" *ngIf="auditorNumberRequired">
        <label class="control-label">Auditor's SMSF Number</label>
        <input type="text" class="form-control" formControlName="number"/>
      </div>

  </div>
  </div>
  <div class="form-group" *ngIf="copyFromAddress">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" (click)="executeCopyFrom($event)" formControlName="isAuditorDetailSameAsPractice">
      <label class="form-check-label">Practice details are the same as above</label>
    </div>
  </div>

  <div class="row">
      <div class="col-form-md">
        <div class="form-group required">
          <label class="control-label">Practice Name</label>
            <div class="input-group-vertical">
              <input type="text" class="form-control" formControlName="practiceName">
            </div>
        </div>
      </div>
  </div>

  <div class="row">
    <div [ngClass]="columnClass">
      <div class="form-group">
        <label class="control-label">Address</label>
        <div class="input-group-vertical">
          <input type="text" class="form-control" formControlName="addressLine1">
          <input type="text" class="form-control" formControlName="addressLine2">
          <input type="text" class="form-control" formControlName="addressLine3">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div [ngClass]="columnClass">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label">Suburb</label>
            <input type="text" class="form-control" formControlName="suburb">
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label class="control-label">State</label>
            <input type="text" class="form-control" formControlName="state">
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label class="control-label">Post Code</label>
            <input type="text" class="form-control" formControlName="postCode">
          </div>
        </div>
      </div>
    </div>
</div>
