<crs-report-editable-element [index]="index" [reportContent]="reportContent" [reportMetaData]="reportMetaData" (edit)="onEdit()" [onSubmit]="onSubmit" [editable]="true" [cancellable]="true">

  <div viewer>
    <div [innerHTML]="element.html" style="min-height:20px"></div>
  </div>

  <div editor>
    <form [formGroup]="form" novalidate *ngIf="isEditing">

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">Name</label>
            <input class="form-control" type="text" formControlName="name" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="control-label">Html</label>
        <textarea class="form-control" formControlName="html" rows="8"></textarea>
      </div>


    </form>
  </div>

</crs-report-editable-element>


