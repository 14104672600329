<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li aria-current="page" class="breadcrumb-item active">Page Templates</li>
  </ol>
</nav>

<div class="row">
  <div class="col-12">
    <crs-busy *ngIf="busy.reportPageTypes"></crs-busy>

    <crs-form-error [error]="error"></crs-form-error>

    <div class="btn-menu pad-no-bottom">
      <button
        class="btn btn-secondary"
        title="Create a new Page Template"
        type="button"
        (click)="add()"
      >
        <i aria-hidden="true" class="fas fa-plus inset"></i>
        New Page Template
      </button>
    </div>

    <div>
      <crs-progress-bar></crs-progress-bar>
      <ag-grid-angular
        #agGrid
        class="ag-theme-material ag-grid-full"
        style="width: 100%"
        [gridOptions]="gridOptions"
        [rowData]="reportPageTypes"
      />
    </div>
  </div>
</div>

<ng-template #optionsCell let-row>
  <div class="btn-mini-menu">
    <a
      class="btn-sm fas-btn-primary-ghost"
      title="Edit"
      *ngIf="!row?.isSystem"
      [routerLink]="['./', row.id]"
      (click)="scrollToTop()"
    >
      <i aria-hidden="true" class="fas fa-edit"></i>
    </a>
    <span
      title="This system page template may not be edited"
      *ngIf="row?.isSystem"
    >
      <i aria-hidden="true" class="fas fa-lock text-light-gray ps-2"></i>
    </span>
  </div>
</ng-template>
