<crs-form-error [error]="error"></crs-form-error>

<!-- Grid -->
<div
  class="alert alert-warning"
  *ngIf="showTaxTransferWarningLabel && connectedToIs"
>
  Please note that some labels are not transferrable ({{
    showTaxLabelsThatAreNotTransferrable
  }}).
</div>
<ag-grid-angular
  class="ag-theme-material"
  style="width: 100%"
  [gridOptions]="gridOptions"
  [rowData]="reportObservable | async"
/>
