import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService } from '../../../../core';
import { ReportingSuite, ReportingSuiteModel } from './reportingSuite';

class ReportingSuiteCache
{
  maxAgeSeconds: number = 30;
  time: Date;
  data: ReportingSuite[];
  light = false;
  current(): boolean {
    return (new Date().getTime() - this.time.getTime()) / 1000 <= this.maxAgeSeconds;
  }

  constructor(suites: ReportingSuite[], light: boolean) {
    this.data = suites;
    this.time = new Date();
    this.light = light;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReportingSuiteService {

  readonly base = 'api/accounting/reports/notes/reporting-suites';

  constructor(private readonly _apiService: ApiService) { }

  private _cache: ReportingSuiteCache;

  getAll(ultraLight: boolean = false) {
    if (this._cache && this._cache.current() && (!this._cache.light || ultraLight)) return of(this._cache.data);
    let url = this.base;
    if (ultraLight) url = url + '/light';
    return this._apiService.get<any[]>(url).pipe(
      map(c => c.map(p => new ReportingSuite(p))),
      tap(p => this._cache = new ReportingSuiteCache(p, ultraLight))
    );
  }

  get(id: string) {
    return this._apiService.get<ReportingSuite>(this.base + '/' + id).pipe(map(c => new ReportingSuite(c)));
  }

  getUnselectedPolicyVariants(suiteId: number) {
    let url = this.base + '/unselected-policy-variants';
    url = this._apiService.addQuery(url, 'suiteId', suiteId);
    return this._apiService.get<any[]>(url);
  }

  getUnselectedDisclosureVariants(suiteId: number) {
    let url = this.base + '/unselected-disclosure-variants';
    url = this._apiService.addQuery(url, 'suiteId', suiteId);
    return this._apiService.get<any[]>(url);
  }

  post(reportingSuite: ReportingSuiteModel): Observable<string> {
    this._cache = null;
    return this._apiService.post<string>(this.base, reportingSuite);
  }


  put(reportingSuite: ReportingSuiteModel) {
    this._cache = null;
    return this._apiService.put(this.base + '/' + reportingSuite.id, reportingSuite);
  }

  delete(id: string) {
    this._cache = null;
    return this._apiService.delete(this.base + '/' + id);
  }
}
