export enum AccountTypeMode {
  Inherited = 0,
  Manual = 1,
  Rule = 2,
}

export enum TenantAccountTypeMode {
  Inherited = 0,
  Manual = 1,
}
