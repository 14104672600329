import { OAuthService } from 'angular-oauth2-oidc';
import { Component, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Config } from 'src/app/core';
import { FlowDirective, Transfer } from '@flowjs/ngx-flow';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export class UploadedFile {
  folder: number;
  uploadPath: string;
  fileName: string;

  constructor(folder: number, uploadPath: string, fileName: string) {
    this.folder = folder;
    this.uploadPath = uploadPath;
    this.fileName = fileName;
  }
}

export class UploadedImageFile {
  transfer: any;
  uploadedFile: UploadedFile;

  constructor(transfer: any, uploadedFile: UploadedFile) {
    this.transfer = transfer;
    this.uploadedFile = uploadedFile;
  }
}

@Component({
  selector: 'crs-file-dragdrop-upload',
  templateUrl: './file-dragdrop-upload.component.html',
  styleUrls: [ './file-dragdrop-upload.component.scss' ]
})
export class FileDragDropUploadComponent implements AfterViewInit, OnDestroy {

  @ViewChild('flow', { static: false }) flow: FlowDirective;
  @ViewChild('importFile') private _importFileInput: ElementRef;
  @ViewChild('dropZone') private _dropZone: ElementRef;
  private _folder: number;
  @Input() set folder(value: number) {
    if (typeof value === 'string') value = parseInt(value, 10);
    this._folder = value;
    this.refreshTarget();

    if (this.flow && this.flow.flowJs) {
      this.flow.flowJs.opts.target = this.config.target;
    }
  }

  @Input('default-input') hasDefaultInput = false;
  @Input('input-text') public inputText = 'Click to upload';
  @Input('input-sub-text') public inputSubText = 'Click to upload';
  @Input() outerDropZoneRef?: ElementRef | HTMLElement;

  get folder() {
    return this._folder;
  }

  @Input() singleFile = true;
  private _acceptedFiles: string[];
  @Input() set acceptedFiles(value: string[]) {
    if (this._acceptedFiles !== value) {
      this._acceptedFiles = value;
      this.buildAttributes();
    }
  }

  get acceptedFiles() {
    return this._acceptedFiles;
  }

  @Input() replaceIdentifierWithFileName = true;

  @Output() filesUploaded: EventEmitter<UploadedFile[]> = new EventEmitter();
  @Output() flowFilesUploaded: EventEmitter<{
    transfer: Transfer,
    uploadedFile: UploadedFile
  }[]> = new EventEmitter();

  attributes: any = {};
  subscriptions: Subscription[] = [];

  config: any = {
    target: `${ Config.baseUrls.api }/api/documents/upload`,
    singleFile: this.singleFile,
    chunkSize: 1024 * 512,
    headers: {
      authorization: 'Bearer ' + this._authService.getAccessToken()
    }
  };

  constructor(private readonly _authService: OAuthService) {
  }

  refreshTarget() {
    const folder = this.folder ? `&folder=${ this.folder }` : '';

    this.config.target = `${ Config.baseUrls.api }/api/documents/upload?${ folder }`;
  }

  ngAfterViewInit(): void {

    this.subscriptions.push(this.flow.events$.subscribe(event => {
      if (event.type === 'fileAdded') {
        const uploadedFile = event.event[0];

        if (!this.replaceIdentifierWithFileName && uploadedFile['uniqueIdentifier']) {
          const fileIdentifier = uploadedFile['uniqueIdentifier'];

          uploadedFile['uniqueIdentifier'] = `${ fileIdentifier.split('-')[0] }-${ uploadedFile['name'] }`;
        }
      }
      if (event.type === 'filesSubmitted') {
        this.flow.upload();
      }
    }));

    this.subscriptions.push(
      this.flow.transfers$.pipe(
        map(ft => ft.transfers.filter(t => t.success)),
        map(ts => {
          return ts.map(t => {
            return {
              transfer: t,
              uploadedFile: new UploadedFile(this.folder, 'flow-' + t.id, t.name)
            };
          });
        }),
        tap(ts => {
          this.filesUploaded.emit(ts.map(t => t.uploadedFile));
          this.flowFilesUploaded.emit(ts);
        })
      ).subscribe());

    let dropZone = this._dropZone.nativeElement;
    if (this.outerDropZoneRef){
      dropZone = this.outerDropZoneRef instanceof ElementRef ? this.outerDropZoneRef.nativeElement : this.outerDropZoneRef;
    }
    this.flow.flowJs.assignDrop(dropZone);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  trackTransfer(transfer: Transfer) {
    return transfer.id;
  }

  buildAttributes() {
    if (this.acceptedFiles != null) {
      this.attributes = {
        accept: this.acceptedFiles.join(', ')
      };
    } else {
      this.attributes = {};
    }
  }

  public choseImportFile(): void {
    this._importFileInput.nativeElement.click();
  }
}
