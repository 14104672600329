<div class="list-group-item clickable" (click)="selected.emit(entity)">
  <div class="d-flex justify-content-between align-items-center h-100">
    <div class="d-inline-block">
      <i class="fas fa-th-list mr-2" aria-hidden="true"></i>
      {{entity.name}}
    </div>
  <div class="d-inline-block ml-auto">
      <div class="clickable" *ngIf="clearable" (click)="clear.emit(true)">
          <i class="far fa-times-circle" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
