import { DepreciationPool } from './depreciation-pool';
import { DepreciationMethod } from './depreciation-method';

export function getDepreciationMethodAbbrev(depreciationMethod: DepreciationMethod, depreciationPool: DepreciationPool) {
  switch (depreciationMethod) {
    case DepreciationMethod.PrimeCost: return 'PC';
    case DepreciationMethod.DiminishingValue: return 'DV';
    case DepreciationMethod.ImmediateWriteOff: return 'IWO';
    case DepreciationMethod.CapitalWorksDeductions: return 'CWD';
    case DepreciationMethod.Manual: return 'M';
    case DepreciationMethod.Pool:
      switch (depreciationPool) {
        case DepreciationPool.GeneralPool: return 'GP';
        case DepreciationPool.LowValuePool: return 'LVP';
        case DepreciationPool.SoftwareDevelopmentPool: return 'SDP';
      }
  }
  return 'Unsp';
}
