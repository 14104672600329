import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from '../../core';
import { EMPTY, Observable, Subject, asyncScheduler, defer, queueScheduler } from 'rxjs';
import {
  BacoAccountDto,
  BacoAccountSelectionDto,
  BacoLoadableTransactionDto,
  BacoTaxOptionDto,
  BacoTransactionDto,
  ITransactionLineParameters,
} from '../interfaces';
import { BacoFeedStore, BacoTransactionStore } from '../components';
import { catchError, concatMap, delay, finalize, groupBy, map, mergeMap, observeOn, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TransactionClient } from '../common';
import { BacoUtils } from '../baco.utils';
import { BacoCodedBy } from '../enums';

@Injectable()
export class TransactionService implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private requestQueue$ = new Subject<TransactionRequest>();
  private activeRequests = new Map<string, number>();

  constructor(
    private readonly _transactionClient: TransactionClient,
    private readonly _transactionStore: BacoTransactionStore,
    private readonly _messageService: MessageService,
    private readonly _bacoFeedStore: BacoFeedStore,
    private readonly _feedStore: BacoFeedStore
  ) {
    // User can update transactions very rapidly. With this observable we make sure updates for the same transaction
    // are queued using concatmap. We also keep track of count of requests for the queue.
    this.requestQueue$
      .pipe(
        takeUntil(this._destroy$),
        groupBy(x => x.id))
      .pipe(
        mergeMap(mm =>
          {
            return mm.pipe(
              tap(requestInfo => this.activeRequests.set(requestInfo.id, (this.activeRequests.get(requestInfo.id) || 0) + 1)),
              tap(requestInfo => this._transactionStore.setTransaction(requestInfo.id, requestInfo.transaction, true)),
              concatMap(requestInfo => requestInfo.request.pipe(switchMap(() => {
                const activeRequestCount = (this.activeRequests.get(requestInfo.id) || 1) - 1;
                this.activeRequests.set(requestInfo.id, activeRequestCount);

                if (activeRequestCount === 0) {
                  this._transactionStore.setTransactionLoadingState(requestInfo.id, false);
                  this.refreshTransactionItem(requestInfo.id).pipe(tap(() => {
                    if (this.activeRequests.get(requestInfo.id) === 0) {

                    }
                  }))
                }
                return EMPTY;
              }))),
              catchError((err) => {
                console.log(mm);
                this.activeRequests.set(mm.key, 0);
                this._messageService.error(err);
                return this.refreshTransactionItem(mm.key);
              })
            )
          }
        )
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public splitTransaction(
    transaction: BacoLoadableTransactionDto,
    splitParams: ITransactionLineParameters[]
  ) {
    const accounts = this._bacoFeedStore.accounts$.getValue();
    const taxOptions = this._bacoFeedStore.taxOptions$.getValue();

    transaction.accountSelections = splitParams.map((params) => {
      return {
        account: params.bacoAccountId
          ? accounts.data.find((x) => x.accountId === params.bacoAccountId)
          : null,
        taxSelection: params.bacoTaxOptionId
          ? taxOptions.data.find((x) => x.taxId === params.bacoTaxOptionId)
          : null,
        description: params.description,
        quantity: params.quantity,
        tax: params.taxAmount,
        totalAmount: params.amount,
        visibleAmount: params.amount - params.taxAmount,
      };
    });

    this.updateTransaction(transaction);
  }

  public changeAccountCoding(
    transaction: BacoLoadableTransactionDto,
    newAccountSelection: BacoAccountDto | null
  ) {
    if (
      transaction.accountSelections[0]?.account?.accountId ===
      newAccountSelection?.accountId
    ) {
      return;
    }

    const coding = this.validateSingleCoding(transaction);
    coding.account = newAccountSelection;

    const taxOption = newAccountSelection?.taxOptionId
      ? this._feedStore.getTaxOption(newAccountSelection.taxOptionId)
      : null;
    this.setTaxCoding(coding, taxOption);

    this.updateTransaction(transaction);
  }

  public updateTaxCoding(
    transaction: BacoLoadableTransactionDto,
    newTaxSelection: BacoTaxOptionDto | null
  ) {
    if (
      transaction.accountSelections[0]?.taxSelection?.taxId ===
      newTaxSelection?.taxId
    ) {
      return;
    }

    const coding = this.validateSingleCoding(transaction);
    this.setTaxCoding(coding, newTaxSelection);

    this.updateTransaction(transaction);
  }

  private setTaxCoding(
    coding: BacoAccountSelectionDto,
    newTaxSelection: BacoTaxOptionDto | null
  ) {
    if (coding.taxSelection?.taxId === newTaxSelection?.taxId) {
      return;
    }

    coding.taxSelection = newTaxSelection;
    coding.tax = BacoUtils.calculateTax(
      coding.totalAmount,
      newTaxSelection?.percentage ?? 0
    );
  }

  public updateTaxAmount(
    transaction: BacoLoadableTransactionDto,
    taxAmount: number | null
  ) {
    if (transaction.accountSelections[0]?.tax === taxAmount) {
      return;
    }

    const coding = this.validateSingleCoding(transaction);
    transaction.tax = taxAmount;
    coding.tax = taxAmount;

    this.updateTransaction(transaction);
  }

  public updateQuantity(
    transaction: BacoLoadableTransactionDto,
    newQuantity: number | null
  ) {
    if (transaction.accountSelections[0]?.quantity === newQuantity) {
      return;
    }

    const coding = this.validateSingleCoding(transaction);
    coding.quantity = newQuantity === 0 ? null : newQuantity;

    this.updateTransaction(transaction);
  }

  public updateDescription(
    transaction: BacoLoadableTransactionDto,
    newValue: string
  ) {
    if (newValue === '') {
      newValue = null;
    }
    transaction.description = newValue;
    if (transaction.codedBy === BacoCodedBy.Rule) {
      transaction.codedBy = BacoCodedBy.User;
    }

    const transactionId = transaction.id;
    this._transactionStore.setTransaction(transactionId, transaction, true);
    const update$ = this._transactionClient
      .updateDescription(transactionId, newValue);

    this.addToQueue(transactionId, transaction, update$);
  }

  private updateTransaction(updatedData: BacoLoadableTransactionDto) {
    const finalRequest = this.toRequest(updatedData);
    const transactionId = updatedData.id;


    updatedData.codedBy = updatedData.accountSelections.some(x => x.account == null) ? null : BacoCodedBy.User;
    updatedData.tax = updatedData.accountSelections.map(x => x.tax).reduce((sum, curr) => sum + curr, 0);
    this._transactionStore.setTransaction(transactionId, updatedData, true);

    this.addToQueue(transactionId, updatedData, this._transactionClient
      .splitTransaction(transactionId, finalRequest));
  }

  private addToQueue(transactionId: string, transaction: BacoLoadableTransactionDto, req: Observable<any>) {
    const request$ = defer(() => req);

    this.requestQueue$.next({
      id: transactionId,
      request: request$,
      transaction: transaction
    });
  }

  private refreshTransactionItem(transactionId: string) {
    const refreshItem$ = this._transactionClient
      .getTransaction(transactionId)
      .pipe(
        catchError((err) => {
          this._messageService.error(err);
          return EMPTY;
        }),
        map((item) => {
          return { ...item, loading: false } as BacoLoadableTransactionDto;
        }),
        tap(transaction => {
          if (this.activeRequests.get(transaction.id) === 0) {
            this._transactionStore.setTransaction(transaction.id, transaction, false);
          }
        })
      );

    return refreshItem$;
  }

  public setDefaultTransactionIfEmpty(transaction: BacoTransactionDto) {
    if (transaction.accountSelections.length === 0) {
      transaction.accountSelections.push({
        account: null,
        description: null,
        quantity: 0,
        tax: 0,
        taxSelection: null,
        totalAmount: transaction.amount,
        visibleAmount: transaction.amount,
      });
    }
  }

  public deleteTransaction(transactionId: string): void {
    this._transactionClient.deleteTransaction$(transactionId)
      .pipe(
        catchError((error) => {
          this._messageService.error(error);
          return EMPTY;
        }),
        tap(() => {
          this._messageService.success('Transaction deleted successfully');
          this._transactionStore.refreshTransactions();
        }),
      )
      .subscribe();
  }

  public validateSingleCoding(transaction: BacoTransactionDto) : BacoAccountSelectionDto {
    if (transaction.accountSelections.length > 1) {
      throw Error('You can not edit a transaction with splits this way.');
    }

    this.setDefaultTransactionIfEmpty(transaction);

    return transaction.accountSelections[0];
  }

  public toRequest(
    transaction: BacoTransactionDto
  ): ITransactionLineParameters[] {
    const finalData = transaction.accountSelections.map((item) => {
      return {
        amount: item.totalAmount,
        taxAmount: item.tax,
        bacoAccountId: item.account?.accountId,
        bacoTaxOptionId: item.taxSelection?.taxId,
        description: item?.description,
        quantity: item?.quantity,
      };
    });

    return finalData;
  }
}

interface TransactionRequest {
  id: string,
  transaction: BacoLoadableTransactionDto,
  request: Observable<any>
}
