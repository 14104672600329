<crs-form-error [error]="error" />

<!-- Display Options -->
<form novalidate [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label class="control-label">Reports</label>
        <ng-select
          bindLabel="name"
          bindValue="value"
          formControlName="reportCategory"
          [clearable]="false"
          [items]="taxReportCategories"
        />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
        <label class="control-label">Start Date</label>
        <crs-date-picker formControlName="startDate" [min]="this.startDate" [max]="this.endDate" />
      </div>
      <div class="form-group">
        <label class="control-label">End Date</label>
        <crs-date-picker formControlName="endDate" [min]="this.startDate" [max]="this.endDate" />
      </div>
    </div>
  </div>

  <button
    class="btn btn-primary"
    [disabled]="!form.value?.reportCategory"
    [promiseBtn]="busy.generate"
    (click)="generate(generateOptions.Normal)"
  >
    Run Report
  </button>
</form>
