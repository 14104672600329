import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  Subject,
} from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Feed } from '../../interfaces/feed-interface';
import { Stateful, StatefulHelpers } from '../../interfaces/stateful.interface';
import {
  BacoAccountDto,
  BacoBankConnectionDto,
  BacoTaxOptionDto,
} from '../../interfaces';
import { FeedClient } from '../../common';

@Injectable()
export class BacoFeedStore implements OnDestroy {
  public _destroy$: Subject<boolean> = new Subject<boolean>();

  public readonly feed$ = new BehaviorSubject<Stateful<Feed>>(
    StatefulHelpers.pending<Feed>()
  );
  public readonly accounts$ = new BehaviorSubject<Stateful<BacoAccountDto[]>>(
    StatefulHelpers.pending<BacoAccountDto[]>()
  );
  public readonly taxOptions$ = new BehaviorSubject<
    Stateful<BacoTaxOptionDto[]>
  >(StatefulHelpers.pending<BacoTaxOptionDto[]>());
  public readonly bankConnections$ = new BehaviorSubject<
    Stateful<BacoBankConnectionDto[]>
  >(StatefulHelpers.pending<BacoBankConnectionDto[]>());
  public readonly refreshBankConnections$ = new BehaviorSubject<void>(null);

  constructor(private readonly _feedClient: FeedClient) {}

  public getAccount(accountId: string): BacoAccountDto {
    const accounts = this.accounts$.getValue();
    const account = accounts.data.find((x) => x.accountId === accountId);
    return account;
  }

  public getTaxOption(taxId: string): BacoTaxOptionDto {
    const accounts = this.taxOptions$.getValue();
    const account = accounts.data.find((x) => x.taxId === taxId);
    return account;
  }

  public loadFeed(feedId$: Observable<string | null>) {
    feedId$
      .pipe(
        tap(() => {
          this.feed$.next(StatefulHelpers.pending<Feed>());
          this.accounts$.next(StatefulHelpers.pending<BacoAccountDto[]>());
          this.taxOptions$.next(StatefulHelpers.pending<BacoTaxOptionDto[]>());
        }),
        takeUntil(this._destroy$),
        switchMap((feedId) =>
          this._feedClient.getFeed(feedId).pipe(
            catchError((err) => {
              this.feed$.next(StatefulHelpers.error<Feed>(err));
              return EMPTY;
            })
          )
        ),
        tap((feedDetail) => {
          this.feed$.next(StatefulHelpers.success<Feed>(feedDetail));
          this.accounts$.next(
            StatefulHelpers.success<BacoAccountDto[]>(feedDetail.accounts)
          );
          this.taxOptions$.next(
            StatefulHelpers.success<BacoTaxOptionDto[]>(feedDetail.taxOptions)
          );
        })
      )
      .subscribe();

    combineLatest([this.feed$, this.refreshBankConnections$])
      .pipe(
        tap(() =>
          this.bankConnections$.next(
            StatefulHelpers.pending<BacoBankConnectionDto[]>()
          )
        ),
        filter(([statefulFeed]) => statefulFeed.state === 'SUCCESS'),
        switchMap(([statefulFeed]) =>
          this._feedClient.getBankConnections(statefulFeed.data.id).pipe(
            catchError((err) => {
              this.bankConnections$.next(
                StatefulHelpers.error<BacoBankConnectionDto[]>(err)
              );
              return EMPTY;
            })
          )
        ),
        takeUntil(this._destroy$),
        tap((result) =>
          this.bankConnections$.next(
            StatefulHelpers.success<BacoBankConnectionDto[]>(result)
          )
        )
      )
      .subscribe();
  }

  public refreshBankConnections() {
    this.refreshBankConnections$.next();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
