import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { getDefaultGridOptions } from 'src/app/shared';
import { ReportStyleService } from '../report-style.service';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'crs-report-styles',
  templateUrl: './report-styles.component.html',
  styleUrls: ['./report-styles.component.scss'],
})
export class ReportStylesComponent implements OnInit {
  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    reportStyles: false,
  };
  reportStylesObservable: Observable<any>;

  gridOptions: GridOptions;

  constructor(
    private readonly _reportStyleService: ReportStyleService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router
  ) {}

  ngOnInit() {
    this.configureGridOptions();
    this.getReportStyles();
  }

  configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      suppressCellFocus: true,
      columnDefs: [
        {
          field: 'name',
          headerName: 'Name',
        },
        {
          field: 'hasCss',
          headerName: 'PDF',
          type: 'booleanColumn',
          cellRendererParams: { iconClass: 'fas fa-file-pdf' },
          maxWidth: 100,
          minWidth: 100,
        },
        {
          field: 'hasExcel',
          headerName: 'Excel',
          type: 'booleanColumn',
          cellRendererParams: { iconClass: 'fas fa-file-excel' },
          maxWidth: 100,
          minWidth: 100,
        },
        {
          field: 'hasWord',
          headerName: 'Word',
          type: 'booleanColumn',
          cellRendererParams: { iconClass: 'fas fa-file-word' },
          maxWidth: 100,
          minWidth: 100,
        },
        {
          field: 'isDefault',
          headerName: 'Default',
          type: 'booleanColumn',
          cellClass: ['boolean-cell', 'text-success', 'centered'],
          maxWidth: 100,
          minWidth: 100,
        },
      ],
    };
  }

  getReportStyles() {
    this.busy.reportStyles = true;
    this.reportStylesObservable = this._reportStyleService.getAll().pipe(
      tap(() => (this.busy.reportStyles = false)),
      catchError((err) => {
        this.busy.reportStyles = false;
        this.showError(err);
        return of([]);
      })
    );
  }

  addReportStyle() {
    this._router.navigate(['../styles/add'], { relativeTo: this._route });
  }

  showError(error) {
    this.error = error;
  }

  selectReportStyle(param) {
    this._router.navigate(['../styles/' + param.data.id], {
      relativeTo: this._route,
    });
  }
}
