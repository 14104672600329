import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ReportTableRow } from '../models';
import { TableRowType } from '../enums';
import { HeaderStyle } from '../../financial/header-style';
import { ITableRowStyle } from '../interfaces';

const baseClasses = [
  'financial-column-header-row',
  'financial-header',
  'financial-header-alternative',
  `financial-row`,
  `financial-total`,
  'financial-spacer'
];

const levels = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15 ];

@Directive({
  selector: '[crsRowStyle]'
})
export class RowStyleDirective implements OnChanges {

  @Input('crsRowStyle') row: ITableRowStyle;

  constructor(private element: ElementRef,
              private _render: Renderer2) {
  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes.row.currentValue) {

      this._removeOldClasses();

      const rowClasses = this._getCurrentClasses(changes.row.currentValue);

      this._setClasses(rowClasses);
    }
  }

  private _getCurrentClasses(row: ReportTableRow): string[] {
    let rowClass = '';
    let levelClass = '';

    switch (row.rowType) {
      case TableRowType.ColumnHeader:
        rowClass = 'financial-column-header-row';
        break;
      case TableRowType.Header:
        rowClass = `financial-header${ row.style === HeaderStyle.Alternative ? '-alternative' : '' }`;
        levelClass = this._getLevel(row.level);
        break;
      case TableRowType.Row:
        rowClass = `financial-row`;
        levelClass = this._getLevel(row.level);
        break;
      case TableRowType.Total:
      case TableRowType.AutoTotal:
      case TableRowType.AutoGrandTotal:
      case TableRowType.GroupTotal:
      case TableRowType.GrandTotal:
        rowClass = `financial-total`;
        levelClass = this._getLevel(row.level);
        break;
      case TableRowType.Spacer:
        rowClass = 'financial-spacer';
        break;
    }

    return levelClass ? [ rowClass, levelClass ] : [ rowClass ];
  }

  private _setClasses(classNames: string[]): void {
    classNames.filter(c => c).forEach((className) => this._render.addClass(this.element.nativeElement, className));
  }

  private _removeOldClasses(): void {
    baseClasses.forEach((className) => {
      if (this._hasClass(className)) {
        this._render.removeClass(this.element.nativeElement, className);
      }

    });
    levels.forEach((value => {
      const className = this._getLevel(value);
      if (this._hasClass(className)) {
        this._render.removeClass(this.element.nativeElement, className);
      }
    }));
  }

  private _getLevel(level): string {
    return level ? `custom-level-${ level }` : '';
  }

  private _hasClass(className: string): boolean {
    return this.element.nativeElement.classList.contains(className);
  }
}
