import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, interval, Subscription } from 'rxjs';
import { sample, tap } from 'rxjs/operators';

@Directive({
  selector: '[crsMouseEvents]'
})
export class MouseEventsDirective implements OnInit, OnDestroy {

  @Input() mouseMoveThrottle = 100;

  @Output() mouseEnter: EventEmitter<any> = new EventEmitter<MouseEvent>();
  @Output() mouseMove: EventEmitter<any> = new EventEmitter<MouseEvent>();
  @Output() mouseUp: EventEmitter<any> = new EventEmitter<MouseEvent>();
  @Output() mouseLeave: EventEmitter<any> = new EventEmitter<MouseEvent>();

  mouseEnter$: Subscription;
  mouseMove$: Subscription;
  mouseUp$: Subscription;
  mouseLeave$: Subscription;

  constructor(private ngZone: NgZone, private elemRef: ElementRef) {
  }

  ngOnInit() {

    this.ngZone.runOutsideAngular(() => {
      this.mouseMove$ = fromEvent<MouseEvent>(this.elemRef.nativeElement, 'mouseenter')
        .pipe(
          tap(ev => this.mouseEnter.emit(ev)),
          tap(() => console.log('[MouseEvents] mouseenter')))
        .subscribe();

      this.mouseMove$ = fromEvent<MouseEvent>(this.elemRef.nativeElement, 'mousemove')
        .pipe(
          sample(interval(this.mouseMoveThrottle)),
          tap(ev => this.mouseMove.emit(ev)))
        .subscribe();

        this.mouseUp$ = fromEvent<MouseEvent>(this.elemRef.nativeElement, 'mouseup')
        .pipe(
          tap(ev => this.mouseUp.emit(ev)),
          tap(() => console.log('[MouseEvents] mouseup')))
        .subscribe();

        this.mouseLeave$ = fromEvent<MouseEvent>(this.elemRef.nativeElement, 'mouseleave')
        .pipe(
          tap(ev => this.mouseLeave.emit(ev)),
          tap(() => console.log('[MouseEvents] mouseleave')))
        .subscribe();
    });
  }

  ngOnDestroy() {
    this.mouseEnter$.unsubscribe();
    this.mouseMove$.unsubscribe();
    this.mouseUp$.unsubscribe();
    this.mouseLeave$.unsubscribe();
  }

}
