import { Component, Input, OnInit } from '@angular/core';
import { BacoTransactionCommentService } from '../services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IBacoTransactionComment } from '../../../../../interfaces';
import { SessionService } from '../../../../../../core';
import { Form, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'crs-transaction-comment',
  templateUrl: './transaction-comment.component.html',
  styleUrls: ['./transaction-comment.component.scss']
})
export class TransactionCommentComponent implements OnInit {
  @Input() public comment: IBacoTransactionComment;

  public hasActions = false;
  public editMode = false;
  public form: FormGroup;
  
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly _commentService: BacoTransactionCommentService,
    private readonly _sessionService: SessionService) {
  }

  public ngOnInit(): void {
    this.hasActions = !!this._sessionService.user?.id && this.comment.userInfo?.userId == this._sessionService.user.id;

    this.form = new FormGroup({
      message: new FormControl<string>(this.comment.message)
    });
  }

  public toggleEditMode(): void {
    this.form.get('message').setValue(this.comment.message);
    this.editMode = !this.editMode;
  }

  public saveEdit(): void {
    const message = this.form.get('message').value
    this.comment.message = message;
    this.editMode = false;
    this._commentService.update(this.comment, message).subscribe();
  }

  public delete(): void {
    this._commentService.delete(this.comment)
      .pipe(takeUntil(this._destroy$))
      .subscribe();
  }

  public replaceNewLine(message:string){
    return message.replace(/\n/g, '<br>');
  }
}
