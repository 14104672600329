import { SourceDivision, SourceDivisionModel } from '../../sourcedata/sourceDivisions/sourceDivision';

export class Division {
  id: string;
  fileId: string;
  code: string = '';
  name: string = '';
  sourceDivisions: SourceDivision[] = [];

  constructor(data) {
    Object.assign(this, data);
    if (data.sourceDivisions) this.sourceDivisions = data.sourceDivisions.map(d => new SourceDivision(d));
  }
}

export class DivisionModel {
  id: string;
  fileId: string;
  code: string = '';
  name: string = '';
  sourceDivisions: SourceDivisionModel[] = [];

  constructor(data) {
    Object.assign(this, data);
    if (data.sourceDivisions) this.sourceDivisions = data.sourceDivisions.map(d => new SourceDivisionModel(d));
  }
}


