import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { RowDragEvent } from 'ag-grid-community';
import { GridOptions } from 'ag-grid-community';

import { ReportMasterService } from '../';
import { getDefaultGridOptions, accountingRenderers } from '../../../../../shared';

@Component({
  selector: 'crs-report-masters',
  templateUrl: './report-masters.component.html'
})
export class ReportMastersComponent implements OnInit {

  fileId: string;
  collapsed: boolean;
  error: string;

  busy = {
    reportMasters: false
  };
  reportMastersObservable: Observable<any>;

  gridOptions: GridOptions = getDefaultGridOptions();
  renderers = accountingRenderers;

  constructor(private reportMasterService: ReportMasterService,
    public route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.configureGridOptions();
    this.getReportMasters();
  }

  getReportMasters() {
    this.busy.reportMasters = true;
    this.reportMastersObservable = this.reportMasterService.getAll().pipe(tap(() => this.busy.reportMasters = false), catchError(err => {
      this.busy.reportMasters = false;
      this.showError(err);
      return of([]);
    }));
  }

  addReportMaster() {
    this.router.navigate(['../masters/add'], { relativeTo: this.route });
  }

  showError(error) {
    this.error = error;
  }

  selectReportMaster(param) {
    this.router.navigate(['../masters/' + param.data.id], { relativeTo: this.route });
  }

  configureGridOptions() {
    this.gridOptions.onRowDragEnd = param => this.onRowDragEnd(param);
  }

  onRowDragEnd(event: RowDragEvent) {
    event.api.clearFocusedCell();
    const movingNode = event.node;
    const overNode = event.overNode;
    if (movingNode === overNode) return;
    const id = movingNode.data.id;
    this.reportMasterService.reorder(id, event.overIndex + 1)
      .subscribe(() => this.reportMastersObservable = this.reportMasterService.getAll());
  }
}
