import { ReportContentInfo } from './../../report-content/report-content-info';
import { Component, Input } from '@angular/core';
import { ReportSpacer } from './report-spacer';
import { UntypedFormControl } from '@angular/forms';
import { ReportMetaData } from '../../report-output';

@Component({
  selector: 'crs-report-spacer',
  templateUrl: './report-spacer.component.html'
})
export class ReportSpacerComponent {

  control: UntypedFormControl;

  @Input() readonly: boolean;
  @Input() index: number;
  @Input() reportContent: ReportContentInfo;
  @Input() reportMetaData: ReportMetaData;
  private _element: ReportSpacer;
  @Input() set element(value) {
    this._element = value;
    this.control = new UntypedFormControl(value.height);
  }

  get element() {
    this._element.height = this.control.value;
    return this._element;
  }



}
