import { Component, ElementRef, HostListener, Injector } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { AccountStateModel } from '../../editors';
import { BacoLoadableTransactionDto } from '../../../interfaces';
import { SplitTransactionComponent } from '../../../components';
import { TransactionService } from '../../../services';
import { ModalService } from '../../../../core';

@Component({
  selector: 'crs-transaction-account-renderer',
  templateUrl: './transaction-account-renderer.component.html',
  styleUrls: ['./transaction-account-render.component.scss'],
})
export class TransactionAccountRendererComponent
  implements ICellRendererAngularComp
{
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (
      this.elementRef.nativeElement.parentElement &&
      this.elementRef.nativeElement.parentElement.classList.contains(
        'ag-cell-focus'
      )
    ) {
      if (event.key === 'Enter' && this.currentState$.value.isSplit) {
        this.splitTransaction();
        this.elementRef.nativeElement.parentElement.classList.remove(
          'ag-cell-focus'
        );
        event.preventDefault();
      }
    }
  }

  protected currentState$ = new BehaviorSubject<AccountStateModel>({
    canEdit: false,
    isSplit: false,
    selectedAccount: null,
  });

  private transaction: BacoLoadableTransactionDto;

  constructor(
    private readonly _transactionService: TransactionService,
    private readonly _modalService: ModalService,
    private injector: Injector,
    private elementRef: ElementRef
  ) {}
  agInit(params: ICellRendererParams): void {
    this.setDisplayValue(params.data);
  }

  refresh(params: ICellRendererParams): boolean {
    this.setDisplayValue(params.data);
    return true;
  }

  protected splitTransaction() {
    this._modalService
      .openModal(
        SplitTransactionComponent,
        null,
        { transaction: this.transaction },
        { windowClass: 'split-modal', injector: this.injector }
      )
      .then((hasUpdates) => {})
      .catch(() => true);
  }

  protected deleteSplitCoding() {
    this._modalService
      .openModal(
        SplitTransactionComponent,
        null,
        { transaction: this.transaction, autoDelete: true },
        { windowClass: 'split-modal', injector: this.injector }
      )
      .catch(() => true);
  }

  private setDisplayValue(data: any) {
    this.transaction = data as BacoLoadableTransactionDto;

    this.currentState$.next({
      canEdit: !this.transaction.locked,
      isSplit: this.transaction.accountSelections.length > 1,
      selectedAccount: this.transaction.accountSelections[0]?.account,
    });
  }
}
