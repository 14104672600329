import { ReportingSuiteModel } from './../reportingSuite';
import { getDefaultGridOptions } from 'src/app/shared';
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Subject, Observable, EMPTY, Subscription } from 'rxjs';
import { tap, map, finalize, exhaustMap, catchError } from 'rxjs/operators';
import { ReportingSuiteService } from '../';
import {
  MessageService,
  ModalService,
  SessionService,
} from '../../../../../core';
import { AddSuiteVariantModalComponent } from '../add-suite-variant-modal/add-suite-variant-modal.component';
import { GridApi, GridOptions } from 'ag-grid-community';

class SubmitOption {
  closeOnSuccess: boolean;
  saveAs: boolean;
  constructor(closeOnSuccess: boolean, saveAs: boolean) {
    this.closeOnSuccess = closeOnSuccess;
    this.saveAs = saveAs;
  }
}

@Component({
  selector: 'crs-reporting-suite',
  templateUrl: './reporting-suite.component.html',
})
export class ReportingSuiteComponent implements OnInit, OnDestroy {
  @ViewChild('policyVariantOptionsCell', { static: true })
  policyVariantOptionsCell: ElementRef;

  @ViewChild('disclosureVariantOptionsCell', { static: true })
  disclosureVariantOptionsCell: ElementRef;

  id: string;
  isAdd: boolean;
  busy = {
    load: null,
    submit: null,
    addPolicyVariant: null,
    addDisclosureVariant: null,
  };
  error: string = null;

  isAdmin = false;
  isMaster: boolean;
  subscriptions: Subscription[] = [];
  interactionStream = new Subject<SubmitOption>();

  accountingPoliciesCollapsed = true;
  disclosureTemplatesCollapsed = true;

  form = this._formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(128)]],
    reportingFramework: [null, Validators.maxLength(32)],
    description: [null, [Validators.maxLength(1024)]],
    applicationDate: null,
    earliestApplicationDate: null,
    expiryDate: null,
  });
  policyVariants: any[] = [];
  disclosureVariants: any[] = [];

  policyVariantOptions: GridOptions;
  policyGridApi: GridApi;
  disclosureVariantOptions: GridOptions;
  disclosureGridApi: GridApi;

  search = new UntypedFormControl();

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _reportingSuiteService: ReportingSuiteService,
    private readonly _messageService: MessageService,
    private readonly _modalService: ModalService,
    sessionService: SessionService
  ) {
    this.isAdmin = sessionService.permissions.isAdmin;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  ngOnInit() {
    this.policyVariantOptions = this.getPolicyVariantGridOptions();
    this.disclosureVariantOptions = this.getDisclosureVariantGridOptions();

    this._route.params.subscribe(() => {
      this.id = this._route.snapshot.paramMap.get('id');
      this.isAdd = this.id === 'add';
      this.loadReportingSuite();
    });

    this.subscriptions.push(
      this.interactionStream
        .pipe(
          tap(() => (this.error = null)),
          exhaustMap((options) => {
            return this.handleInteraction(options);
          })
        )
        .subscribe((options) => {
          if (options.closeOnSuccess) this.close();
        })
    );
  }

  private getPolicyVariantGridOptions() {
    return {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.id,
      onGridReady: (event) => (this.policyGridApi = event.api),
      groupUseEntireRow: true,
      groupDefaultExpanded: 1,
      immutableData: true,
      columnDefs: [
        { field: 'policyName', headerName: 'Policy' },
        { field: 'name', headerName: 'Variant' },
        {
          field: 'isDefault',
          headerName: 'Default',
          type: 'booleanColumn',
          editable: true,
          maxWidth: 120,
          minWidth: 120,
        },
        {
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.policyVariantOptionsCell },
          maxWidth: 90,
          minWidth: 90,
          hide: !this.isAdmin,
        },
      ],
    };
  }

  private getDisclosureVariantGridOptions() {
    return {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.id,
      onGridReady: (event) => (this.disclosureGridApi = event.api),
      groupUseEntireRow: true,
      groupDefaultExpanded: 1,
      immutableData: true,
      columnDefs: [
        { field: 'templateName', headerName: 'Disclosure' },
        { field: 'name', headerName: 'Variant' },
        {
          field: 'isDefault',
          headerName: 'Default',
          type: 'booleanColumn',
          editable: true,
          maxWidth: 120,
          minWidth: 120,
        },
        {
          type: 'optionsColumn',
          cellRendererParams: { ngTemplate: this.disclosureVariantOptionsCell },
          maxWidth: 90,
          minWidth: 90,
          hide: !this.isAdmin,
        },
      ],
    };
  }

  submit() {
    this.interactionStream.next(new SubmitOption(false, false));
  }

  save(closeOnSuccess: boolean) {
    const submitOption = new SubmitOption(closeOnSuccess, false);
    this.interactionStream.next(submitOption);
  }

  saveAs() {
    const submitOption = new SubmitOption(true, true);
    this.interactionStream.next(submitOption);
  }

  private handleInteraction(options: SubmitOption): Observable<SubmitOption> {
    let observable: Observable<SubmitOption>;
    const loadingStream = new Subject<void>();

    const model = new ReportingSuiteModel(
      this.form.value,
      this.policyVariants,
      this.disclosureVariants
    );

    if (options.saveAs) {
      observable = this._reportingSuiteService.post(model).pipe(
        tap((id) => {
          this.isAdd = false;
        }),
        map(() => options)
      );
    } else if (this.isAdd || this.isMaster) {
      observable = this._reportingSuiteService.post(model).pipe(
        tap((id) => {
          this.isAdd = false;
        }),
        map(() => options)
      );
    } else {
      model.id = parseInt(this.id, 10);
      observable = this._reportingSuiteService
        .put(model)
        .pipe(map(() => options));
    }

    this.busy.submit = loadingStream.subscribe();

    observable = observable.pipe(
      tap(() => this._messageService.success('Successfully saved report.')),
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => loadingStream.complete())
    );

    return observable;
  }

  private loadReportingSuite() {
    this.busy.load = this.isAdd
      ? EMPTY
      : this._reportingSuiteService.get(this.id).subscribe(
          (reportingSuite) => {
            this.form.patchValue(reportingSuite);
            this.isMaster = reportingSuite.isMaster;
            this.policyVariants = reportingSuite.policyVariants;
            this.disclosureVariants = reportingSuite.disclosureVariants;
          },
          (err) => this.showError(err)
        );
  }

  addPolicyVariant() {
    this.busy.addPolicyVariant = this._reportingSuiteService
      .getUnselectedPolicyVariants(this.isAdd ? null : parseInt(this.id, 10))
      .pipe(
        map((variants) => {
          return variants.filter(
            (s) => !this.policyVariants.some((v) => v.id === s.id)
          );
        })
      )
      .subscribe({
        next: (variants) => {
          this._modalService
            .openModal(AddSuiteVariantModalComponent, null, {
              title: 'Policy Variants',
              variants: variants,
              type: 'policy',
            })
            .then((selected) => {
              console.log(selected);
              selected.forEach(
                (a) =>
                  (a.isDefault = !this.policyVariants.some(
                    (v) => v.isDefault && v.policyId === a.policyId
                  ))
              );
              this.policyVariants = this.policyVariants.concat(selected);
              this.policyGridApi.setGridOption('rowData', this.policyVariants);
            })
            .catch(() => true);
        },
        error: (err) => this.showError(err),
      });
  }

  removePolicyVariant(row) {
    this.policyVariants.splice(this.policyVariants.indexOf(row), 1);
    this.policyGridApi.setGridOption('rowData', this.policyVariants);
  }

  addDisclosureVariant() {
    this.busy.addDisclosureVariant = this._reportingSuiteService
      .getUnselectedDisclosureVariants(
        this.isAdd ? null : parseInt(this.id, 10)
      )
      .pipe(
        map((variants) => {
          return variants.filter(
            (s) => !this.disclosureVariants.some((v) => v.id === s.id)
          );
        })
      )
      .subscribe({
        next: (variants) => {
          this._modalService
            .openModal(AddSuiteVariantModalComponent, null, {
              title: 'Disclosure Variants',
              variants: variants,
              type: 'disclosure',
            })
            .then((selected) => {
              console.log(selected);
              selected.forEach(
                (a) =>
                  (a.isDefault = !this.disclosureVariants.some(
                    (v) => v.isDefault && v.disclosureId === a.disclosureId
                  ))
              );
              this.disclosureVariants =
                this.disclosureVariants.concat(selected);

              this.disclosureGridApi.setGridOption(
                'rowData',
                this.disclosureVariants
              );
            })
            .catch(() => true);
        },
        error: (err) => this.showError(err),
      });
  }

  removeDisclosureVariant(row) {
    this.disclosureVariants.splice(this.disclosureVariants.indexOf(row), 1);
    this.disclosureGridApi.setGridOption('rowData', this.disclosureVariants);
  }

  delete() {
    if (this.isAdd || !this.isAdmin) return;
    this._modalService.confirmation(
      'Are you sure you want to delete this Reporting Suite? This action cannot be undone.',
      () =>
        this._reportingSuiteService.delete(this.id).subscribe({
          next: () => {
            this._messageService.success(
              'Successfully deleted reportingSuite.'
            );
            this.close();
          },
          error: this.showError,
        }),
      true
    );
  }

  close() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  private showError(error) {
    this.error = error;
    this._messageService.error(error, true);
  }
}
