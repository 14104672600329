import { DateAdapter } from '@angular/material/core';
import { DateTime, Info } from 'luxon';
import { Injectable } from "@angular/core";


/** Creates an array and fills it with values.
//copied from NativeDateAdapter
*/
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

// TODO(mmalerba): Remove when we no longer support safari 9.
/** Whether the browser supports the Intl API. */
const SUPPORTS_INTL_API = typeof Intl !== 'undefined'; // copied from NativeDateAdapter

/** The default date names to use if Intl API is not available. */
const DEFAULT_DATE_NAMES = range(31, i => String(i + 1)); // copied from NativeDateAdapter

@Injectable()
export class LuxonDateAdapter extends DateAdapter<DateTime> {
  private _validFormats = [];

  public setValidFormats(formats: string[]) {
    this._validFormats = formats || [];
  }

  public getYear(date: DateTime): number {
    return date.year;
  }

  public getMonth(date: DateTime): number {
    return date.month - 1;
    // The Datepicker uses this to index into the 0 indexed getMonthNames array so far as I can tell.
    // Because Luxon uses 1-12 for months we need to subtract one.
  }

  public getDate(date: DateTime): number {
    return date.day;
  }

  public getDayOfWeek(date: DateTime): number {
    return date.weekday;
  }

  public getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return Info.months(style);
  }


  public getDateNames(): string[] {
    if (SUPPORTS_INTL_API) {
      const dtf = new Intl.DateTimeFormat(this.locale, { day: 'numeric' });
      return range(31, i => this._stripDirectionalityCharacters(
        dtf.format(new Date(2017, 0, i + 1))));
    }
    return DEFAULT_DATE_NAMES;
  }


  public getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return Info.weekdays(style).map(
        (_: string, i: number, arr: string[]) => arr[(i + 6) % 7]
      ); // Luxon assumes Monday is the first day of the week
  }

  public getYearName(date: DateTime): string {
    if (SUPPORTS_INTL_API) {
      const dtf = new Intl.DateTimeFormat(this.locale, { year: 'numeric' });
      const valueOfDate = date.valueOf();
      return this._stripDirectionalityCharacters(dtf.format(valueOfDate));
    }
    return String(this.getYear(date));
  }

  public getFirstDayOfWeek(): number {
    return 0; // assume Sunday.
  }

  public getNumDaysInMonth(date: DateTime): number {
    return date.daysInMonth;
  }

  public clone(date: DateTime): DateTime {
    return date; // there is no point in cloning a Luxon DateTime since they are immutable.
  }

  public createDate(year: number, month: number, date: number): DateTime {
    month += 1; // luxon utc uses 1-12 for dates, but datepicker passes in 0-11.
    const aDate = DateTime.utc(year, month, date).setZone('utc', { keepLocalTime: true });
    return aDate;
  }

  public today(): DateTime {
    return DateTime.utc().setZone('utc', { keepLocalTime: true });
  }

  public format(date: DateTime, displayFormat: any): string {
    return date.toFormat(displayFormat);
  }

  public addCalendarYears(date: DateTime, years: number): DateTime {
    return date.plus({ years: years });
  }

  public addCalendarMonths(date: DateTime, months: number): DateTime {
    return date.plus({ months: months });
  }

  public addCalendarDays(date: DateTime, days: number): DateTime {
    return date.plus({ days: days });
  }

  public toIso8601(date: DateTime): string {
    return date.toISO();
  }

  public isDateInstance(obj: any): boolean {
    return (obj instanceof DateTime);
  }

  public isValid(date: DateTime): boolean {
    return date.isValid;
  }

  public invalid(): DateTime {
    return DateTime.invalid('Invalid set via luxon-date-adapter.');
  }

  public parse(value: any, parseFormat: any): DateTime | null {
    if (this.isDateInstance(value)) {
      return value;
    }

    if (value instanceof Date) {
      return DateTime.fromJSDate(value);
    }

    if (value && typeof value === 'string') {
      // first try to parse an ISO date
      const aDateTime = DateTime.fromISO(value);
      if (aDateTime.isValid === true) {
        return aDateTime.setZone('utc', { keepLocalTime: true });
      }
      // otherwise try to parse according to specified format (useful for user entered values?).
      const formats = [parseFormat].concat(this._validFormats);

      let date = DateTime.invalid('Unparsable', value);
      for (let i = 0; i < formats.length; i++) {
        date = DateTime.fromFormat(value, formats[i], ).setZone('utc', { keepLocalTime: true });
        if (!date.invalid) {
          return date;
        }
      }

      return date;
    }

    return DateTime.invalid('Unparsable', value);
  }

  public deserialize(value: any): DateTime | null {
    let date;
    if (value instanceof Date) {
      date = DateTime.fromJSDate(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = DateTime.fromISO(value);
    }
    if (date && this.isValid(date)) {
      return date;
    }
    return super.deserialize(value);
  }

  // copied from NativeDateAdapter
  /**
  * Strip out unicode LTR and RTL characters. Edge and IE insert these into formatted dates while
  * other browsers do not. We remove them to make output consistent and because they interfere with
  * date parsing.
  * @param str The string to strip direction characters from.
  * @returns The stripped string.
  */
  private _stripDirectionalityCharacters(str: string) {
    return str.replace(/[\u200e\u200f]/g, '');
  }
}
