import { TemplateRef } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { RuleMovementType } from 'src/app/baco/enums';
import { BacoRuleDto } from 'src/app/baco/interfaces';
import { getDefaultGridOptions, renderers } from 'src/app/shared';

export class RuleGrid {

  public gridOptions : GridOptions = {
    ... getDefaultGridOptions(),
    getRowNodeId: (transaction) => transaction.id,
    frameworkComponents: renderers,
    enableRangeSelection: false,
    suppressCellSelection: true,
    groupIncludeTotalFooter: false,
    undoRedoCellEditing: true,
    animateRows: true,
    rowClassRules: {
      'row-disabled': (params) => params.data.locked
    },
    enableCellChangeFlash: true,
    immutableData: true,
    rowDragManaged: true,
  };

  public get gridApi() {
    return this.gridOptions.api;
  }

  constructor(titleCell: TemplateRef<any>, accountCell: TemplateRef<any>, optionsCell: TemplateRef<any>) {
    this.gridOptions.defaultColDef.sortable = false;
    this.gridOptions.defaultColDef.getQuickFilterText = params => {
      const rule = params.data as BacoRuleDto;
      const value =
        [
          rule.name,
          ...rule.allocations.map(t => t.description),
          ...rule.descriptionCondition?.conditionValue ?? '',
          ...rule.amountCondition?.conditionValue?.toString() ?? ''
        ].filter(t => !!t).join(' ');
      return value;
    }

    const columnDefs : ColDef[] = [
      {
        colId: 'drag',
        rowDrag: true,
        minWidth: 30,
        maxWidth: 30,
      },
      {
        headerName: 'Rule Title',
        colId: 'titleCell',
        wrapText: true,
        autoHeight: true,
        type: 'templateColumn',
        cellRendererParams: {
          ngTemplate: titleCell,
        },
        valueGetter: (params) =>  `${params.data.descriptionCondition?.value}${params.data.amountCondition?.value}`,
      },
      {
        headerName: 'Account code / name',
        colId: 'accountCell',
        wrapText: true,
        autoHeight: true,
        type: 'templateColumn',
        cellRendererParams: {
          ngTemplate: accountCell,
        },
        valueGetter: (params) =>  `${params.data.allocations.length}`,
      },
      {
        headerName: 'Type',
        type: 'enumColumn',
        field: 'movementType',
        maxWidth: 100,
        cellRendererParams: {
          enum: RuleMovementType,
        },
      },
      {
        headerName: '',
        colId: 'editOptions',
        minWidth: 100,
        width: 90,
        type: 'optionsColumn',
        cellRendererParams: {
          ngTemplate: optionsCell,
        },
        valueGetter: (params) => `${params.data.id}`,
      },
    ];

    this.gridOptions.columnDefs = columnDefs;
  }
}
