<div class="pad">
  <div class="panel" [ngBusy]="[busy.load]">
    <div class="panel-heading">
      <div class="d-flex">
        <div class="flex-grow-1">
          <span class="primary">Request History</span>
        </div>

        <div class="close" (click)="close()">
          Close <i aria-hidden="true" class="fas fa-times ms-1"></i>
        </div>
      </div>
    </div>

    <div class="panel-body">
      <div style="overflow: hidden; flex-grow: 1">
        <ag-grid-angular
          #agGrid
          class="ag-theme-material"
          style="width: 100%"
          [gridOptions]="gridOptions"
          [rowData]="requestHistories"
        />
      </div>
    </div>
  </div>
</div>
