<form novalidate [formGroup]="formGroup">
  <div class="card bg-light">
    <div class="card-header">
      <crs-source-icon
        [source]="formGroup.get('source').value"
      ></crs-source-icon>
      <button aria-label="Close" class="close" type="button" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <crs-form-error [error]="error"></crs-form-error>

      <div class="row">
        <div class="col-form-md">
          <div class="form-group">
            <label class="control-label">Journal Depreciation Type</label>
            <ng-select
              bindLabel="name"
              bindValue="value"
              formControlName="depreciationType"
              [clearable]="false"
              [items]="depreciationTypes | enumToArray"
            ></ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-8 col-md-10">
          <ag-grid-angular
            #agGrid
            class="ag-theme-material"
            [gridOptions]="journalGrid.gridOptions"
            [rowData]="journalAccounts"
            (cellValueChanged)="journalGrid.onValueChanged($event)"
          />
        </div>
      </div>

      <!-- New Account Cell -->
      <ng-template #newAccountCell let-row>
        <span
          class="badge badge-danger"
          title="This account number and name combination has not been seen before. If you proceed, this account will be created by Access Ledger."
          *ngIf="
            row &&
            row.sourceAccount &&
            !row.sourceAccount.id &&
            row.sourceAccount.accountName
          "
        >
          NEW
        </span>
      </ng-template>

      <!-- Account Search Result-->
      <ng-template #accountSearchTemplate let-r="result" let-t="term">
        <ngb-highlight
          [result]="(r.accountNo ? r.accountNo + '  ' : '') + r.accountName"
          [term]="t"
        ></ngb-highlight>
      </ng-template>

      <!-- Classification Result-->
      <ng-template #classificationTemplate let-r="result" let-t="term">
        <ngb-highlight
          [result]="journalGrid.classifications[r]"
          [term]="t"
        ></ngb-highlight>
      </ng-template>
    </div>
  </div>
</form>
