import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor() {}

  download(url: string): void {
    window.location.href = url;
  }
}
