import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'crs-save-as',
  templateUrl: './save-as.component.html'
})
export class SaveAsComponent implements OnInit {

  @Input() params: any;

  form = this._formBuilder.group({
    name: ['', Validators.required]
  });

  constructor(public activeModal: NgbActiveModal,
    private _formBuilder: UntypedFormBuilder,) { }

  ngOnInit() {
    this.form.patchValue({
      name: this.params.defaultName || ''
    });
  }

  confirm() {
    this.activeModal.close(this.form.controls['name'].value as string);
  }

}
