  <!-- Title -->
  <td class="financial-title">
    <div class="financial-cell">
      <crs-financial-editable-element
        [index]="index"
        [financialContent]="financialContent"
        *ngIf="isEditing" [editable]="false">
      </crs-financial-editable-element>
    </div>
  </td>

  <td>
    <div class="financial-cell" [style.height.px]="element.height">
    </div>
  </td>
