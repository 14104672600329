import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ReportMasterPage } from '..';

@Component({
  selector: 'crs-report-master-page',
  templateUrl: './report-master-page.component.html'
})
export class ReportMasterPageComponent {

  @Input('index') i: number;
  @Input() pageId: string = null;
  @Input() masterGroup: UntypedFormGroup;
  @Input() formGroup: UntypedFormGroup;
  @Output() removed = new EventEmitter<number>();

  isExpanded = false;
  editingTitle = false;

  constructor() { }

}
