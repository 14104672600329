import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

class DatePair {
  startDate: Date;
  endDate: Date;
}

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  private lastFinancialYear: DatePair;
  private thisFinancialYear: DatePair;

  convertToDateObj(utcString: string): Date {
    if (!utcString) return null;

    const year = parseInt(utcString.substring(0, 4), 10);
    const month = parseInt(utcString.substring(5, 7), 10) - 1;
    const day = parseInt(utcString.substring(8, 10), 10);
    return new Date(Date.UTC(year, month, day));
  }

  toString(date: Date, format: string): string {
    return formatDate(date, format, 'en-US');
  }

  getThisFinancialYear(): DatePair {
    if (this.thisFinancialYear) return this.thisFinancialYear;

    const today = new Date();
    let year = today.getFullYear();
    if (today.getMonth() >= 7) year = year + 1;

    this.thisFinancialYear = this.buildDatePair(year);
    return this.thisFinancialYear;
  }

  getLastFinancialYear(): DatePair {
    if (this.lastFinancialYear) return this.lastFinancialYear;

    const today = new Date();
    let year = today.getFullYear();
    if (today.getMonth() <= 6) year = year - 1;

    this.lastFinancialYear = this.buildDatePair(year);
    return this.lastFinancialYear;
  }

  getFinancialYear(year: number) {
    return this.buildDatePair(year);
  }

  private buildDatePair(year: number) {
    const pair = new DatePair();
    pair.startDate = new Date(Date.UTC(year - 1, 7 - 1, 1));
    pair.endDate = new Date(Date.UTC(year, 6 - 1, 30));
    return pair;
  }
}
