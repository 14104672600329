import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../shared/shared.module';
import { FinancialModule } from '../financial';
import { ReportTableComponent, ReportTableCellComponent, ReportTableRowComponent, ReportTableActionsComponent } from './';
import { ReportEditableElementModule } from '../report-editable-element';
import { ReportTableBuilderService } from './services';
import { IReportTableConfig } from './interfaces';
import { CellSizeDirective, RowStyleDirective, CellSpanDirective } from './directives';
import { REPORT_TABLE_CONFIG } from './report-table.contants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReportEditableElementModule,
    FinancialModule
  ],
  declarations: [
    ReportTableComponent,
    ReportTableCellComponent,
    ReportTableRowComponent,
    ReportTableActionsComponent,
    CellSizeDirective,
    CellSpanDirective,
    RowStyleDirective
  ],
  providers: [
    {
      provide: REPORT_TABLE_CONFIG,
      useValue: { cellDefaultWidth: 100, cellDefaultHeight: 29 } as IReportTableConfig
    },
    ReportTableBuilderService,
    NgbActiveModal
  ],
  exports: [
    ReportTableComponent,
    ReportTableCellComponent,
    ReportTableRowComponent,
    ReportTableActionsComponent
  ]
})
export class ReportTableModule {
}
