import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../core';

import { ReportTemplateModel } from '../reportTemplates';
import { ReportDocument, ReportDocumentListItem, ReportDocumentModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ReportDocumentService {

  readonly base: string = 'api/accounting/reports/documents';

  constructor(private _apiService: ApiService) { }

  public get(id: string): Observable<ReportDocument> {
    return this._apiService.get<ReportDocument>(`${this.base}/${id}`);
  }

  public getAll(fileId: string): Observable<ReportDocumentListItem[]> {
    return this._apiService.get<ReportDocumentListItem[]>( `${this.base}/file/${fileId}`).pipe(map(l => l.map(d => new ReportDocumentListItem(d))));
  }

  public create(createModel: { report: ReportDocument, reportTemplateCreate: ReportTemplateModel} ): Observable<any> {
    return this._apiService.post<string>(`${this.base}`, createModel);
  }

  public update(reportDocumentId: string, reportData: ReportDocumentModel): Observable<any> {
    return this._apiService.put<boolean>(`${this.base}/${reportDocumentId}`, reportData);
  }

  public copy(documentId: string): Observable<string> {
    return this._apiService.post<string>(`${this.base}/copy/${documentId}`, {});
  }

  public delete(id: string) {
    return this._apiService.delete(`${this.base}/${id}`);
  }
}
