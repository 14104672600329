//import { Division } from '../../setup/divisions';
import { Source } from '../sources/source';

export class SourceDivision {
  id: string;
  division: any;
  source: Source;
  sourceDivisionId: string;
  code: string;
  name: string;

  constructor(data: any) {
    Object.assign(this, data);
    //if (data.division) this.division = new Division(data.division);
    if (data.source) this.source = new Source(data.source);
  }
}

export class SourceDivisionModel {
  id: string;
  sourceId: string;
  divisionId: string;
  sourceDivisionId: string;
  code: string;
  name: string;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.source) this.sourceId = data.source.id;
    if (data.division) this.divisionId = data.division.id;
  }
}
