import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../core';
import { Source, SourceModel } from './source';
import { SourceDivision } from '../sourceDivisions/sourceDivision';
import { SourceType } from '../sourceTypes/sourceType';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SourceService {
  readonly base: string = 'api/accounting/sourcedata/sources';

  private sourcesChangedSubject = new BehaviorSubject<boolean>(false);
  public sourcesChanged$ = this.sourcesChangedSubject.asObservable();

  constructor(private apiService: ApiService) {}

  get$(id: string) {
    return this.apiService
      .get<Source>(this.base + '/' + id)
      .pipe(map((c) => new Source(c)));
  }

  getSourceDivisions$(id: string, search: string = null) {
    let url = this.base + '/' + id + '/divisions';
    url = this.apiService.addQuery(url, 'search', search);
    return this.apiService
      .get<SourceDivision[]>(url)
      .pipe(map((c) => c.map((c1) => new SourceDivision(c1))));
  }

  getAll$(fileId: string, limitToHasDivisions: boolean = false) {
    let url = `${this.base}/for-file/${fileId}`;
    if (limitToHasDivisions) url = url + '?limitToHasDivisions=true';
    return this.apiService
      .get<Source[]>(url)
      .pipe(map((c) => c.map((c1) => new Source(c1))));
  }

  getTypesForFile$(fileId: string) {
    let url = this.base + '/types/for-file/';
    url = this.apiService.addQuery(url, 'fileId', fileId);
    return this.apiService
      .get<SourceType[]>(url)
      .pipe(map((c) => c.map((c1) => new SourceType(c1))));
  }

  getTypes$() {
    let url = this.base + '/types';
    return this.apiService
      .get<SourceType[]>(url)
      .pipe(map((c) => c.map((c1) => new SourceType(c1))));
  }

  post$(source: SourceModel) {
    return this.apiService.post<any>(this.base, source);
  }

  put$(source: SourceModel) {
    return this.apiService.put(this.base + '/' + source.id, source);
  }

  delete$(id: string) {
    return this.apiService.delete(this.base + '/' + id);
  }

  notifySourcesChange() {
    this.sourcesChangedSubject.next(true);
  }
}
