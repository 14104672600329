import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

const ACCOUNTS_ALLOCATION = 'accounts-allocation';
const ACCOUNTS_ALLOCATION_URL = `accounts/${ACCOUNTS_ALLOCATION}`;

const SOURCE_ACCOUNTS = 'source-accounts';
const SOURCE_ACCOUNTS_URL = `accounts/${SOURCE_ACCOUNTS}`;

@Component({
  selector: 'crs-accounts-menu',
  templateUrl: './accounts-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountsMenuComponent implements OnInit {
  currentRoute = ACCOUNTS_ALLOCATION;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.router.url.includes(ACCOUNTS_ALLOCATION_URL)) {
          this.currentRoute = ACCOUNTS_ALLOCATION;
        }
        if (this.router.url.includes(SOURCE_ACCOUNTS_URL)) {
          this.currentRoute = SOURCE_ACCOUNTS;
        }
      });
  }

  ngOnInit(): void {
    this.currentRoute = this.activatedRoute.children[0].routeConfig.path;
  }
}
