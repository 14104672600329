import { Component, Input } from '@angular/core';
import { FinancialContentInfo } from '../../report-financial/report-content-info';

@Component({
  selector: '[crs-report-financial-total]',
  templateUrl: './report-financial-total.component.html'
})
export class ReportFinancialTotalComponent {

  @Input() element;
  @Input() index: number;
  @Input() isEditing = false;
  @Input() financialContent: FinancialContentInfo;
  get columnMetadata() {
    return this.financialContent ? this.financialContent.columns : null;
  }

  get rounding() {
    return this.financialContent.reportContent.style.rounding;
  }

  get percentFormatString() {
    const rounding = this.financialContent.reportContent.style.percentRounding;
    return '1.' + rounding + '-' + rounding;
  }

}
