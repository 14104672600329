import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'crs-report-content-modal',
  templateUrl: './report-content-modal.component.html',
  styleUrls: ['./report-content-modal.component.scss']
})
export class ReportContentModalComponent {

  @Input() params: {
    title: string,
    content: any[],
    templateContent: any[],
    readonly: boolean,
    output: boolean
  };

  hideMasterContent: boolean;

  constructor(private readonly _activeModal: NgbActiveModal) { }

  close() {
    this._activeModal.close();
  }

  cancel() {
    this._activeModal.dismiss();
  }

}
