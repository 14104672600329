import { TaxLabel } from './taxLabel';

export class TaxLabelBalance {
  taxLabelId: number;
  taxLabel: TaxLabel;
  accountId: string;
  accountNo: string;
  accountName: string;
  get displayName() {
    return (this.accountNo ? this.accountNo + ' ' : '') + this.accountName;
  }
  balance: number;

  constructor(data){
    Object.assign(this, data);
    if (data.taxLabel) this.taxLabel = new TaxLabel(data.taxLabel);
  }

}

