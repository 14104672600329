import {
  AfterViewInit,
  Component,
  forwardRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { DefaultValueAccessor } from '../../../../shared/components/value-accessors';
import { BacoTransactionDto } from '../../../interfaces';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'crs-transaction-description-editor',
  templateUrl: './transaction-description-editor.component.html',
  styleUrls: ['./transaction-description-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TransactionDescriptionEditorComponent),
      multi: true,
    },
  ],
})
export class TransactionDescriptionEditorComponent
  extends DefaultValueAccessor<string>
  implements ICellEditorAngularComp, AfterViewInit
{
  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;

  private params: ICellEditorParams;
  public transaction: BacoTransactionDto;
  public showRevertBtn = false;

  agInit(params: ICellEditorParams): void {
    this.transaction = params.data;
    this.showRevertBtn = true;
    this.value = this.transaction.description;
  }

  ngAfterViewInit() {
    setTimeout(() => this.input.element.nativeElement.focus());
  }

  getValue(): string {
    return this.value;
  }

  revertOriginalValue(): void {
    if (this.transaction.originalDescription) {
      this.value = this.transaction.originalDescription;
      this.showRevertBtn = false;
      this.params.stopEditing();
    }
  }
}
