<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/admin'">Admin Centre</a></li>
    <li class="breadcrumb-item"><a [routerLink]="['../']">Teams</a></li>
    <li aria-current="page" class="breadcrumb-item active">
      {{ isAdd ? 'New' : '' }} Team
    </li>
  </ol>
</nav>

<form
  class="pad-x"
  novalidate
  [formGroup]="form"
  [ngBusy]="busy.load"
  (validSubmit)="submit()"
>
  <h2 class="form-group-title">{{ isAdd ? 'New' : '' }} Team</h2>

  <hr />

  <div class="row">
    <div class="col-md-8 col-xl-6">
      <div class="form-group required">
        <label class="control-label">Name</label>
        <input
          class="form-control"
          formControlName="name"
          placeholder="Sydney..."
          type="text"
        />
      </div>

      <div class="form-group">
        <label class="control-label">Practice</label>
        <crs-office-select formControlName="office"></crs-office-select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h2 class="form-group-title">Team Users</h2>

      <hr />

      <div class="btn-toolbar" role="toolbar">
        <div class="btn-menu">
          <button
            class="btn btn-secondary"
            type="button"
            (click)="addTeamUser()"
          >
            <i aria-hidden="true" class="fas fa-plus inset"></i>
            Add User
          </button>
        </div>

        <div class="form-group ms-2 w-50">
          <div class="icon-input-wrapper flex-grow-1 me-2">
            <i aria-hidden="true" class="fas fa-search input-icon"></i>
            <input
              aria-describedby="searchIcon"
              placeholder="Search..."
              type="search"
              [formControl]="search"
            />
          </div>
        </div>
      </div>

      <div>
        <ag-grid-angular
          #agGrid
          class="ag-theme-material"
          [gridOptions]="gridOptions"
          [rowData]="users"
        />
      </div>

      <ng-template #optionsCell let-teamUser>
        <div class="btn-mini-menu">
          <button
            class="btn-sm fas-btn-primary-ghost"
            title="Edit Team User"
            type="button"
            (click)="editTeamUser(teamUser)"
          >
            <i aria-hidden="true" class="fas fa-edit"></i>
          </button>
          <button
            class="btn-sm fas-btn-ghost"
            title="Remove Team User"
            type="button"
            (click)="removeTeamUser(teamUser)"
          >
            <i aria-hidden="true" class="fas fa-trash"></i>
          </button>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="spacer"></div>

  <crs-form-error [error]="error"></crs-form-error>

  <div class="btn-menu form-bottom-menu">
    <button class="btn btn-primary" type="submit" [promiseBtn]="busy.submit">
      Save and Close
    </button>
    <button class="btn btn-secondary" type="button" (click)="close()">
      Cancel
    </button>
    <button
      class="btn btn-outline-danger float-right"
      title="Delete"
      type="button"
      *ngIf="!isAdd && isAdmin"
      (click)="delete()"
    >
      <i aria-hidden="true" class="fas fa-trash"></i>
      Delete
    </button>
  </div>
</form>
