import { Injectable } from '@angular/core';
import { ModalService } from '../../../../../../core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ReportTableMessageService {

  constructor(private readonly _modalService: ModalService,
              private readonly _activeModal: NgbActiveModal) {
  }

  public async invalidTypeDialog(action: () => any = null,
    message = 'Format is not valid for current data',
    title = 'Formatting'): Promise<any> {
    try {
      return await this._modalService.showInformationDialog(message, false, 'OK, Got It', title, action);
    } finally {
      this._activeModal.dismiss();
      if (action) {
        action();
      }
    }
  }

  public async invalidActionDialog(onConfirm: () => any = null, onClose: () => any = null): Promise<any> {
    try {
      return await this._modalService.showInformationDialog('This action is not allowed in the current configuration', false, 'OK, Got It', 'Invalid Action', onConfirm);
    } finally {
      this._activeModal.dismiss();
      if (onClose)
        onClose();
    }
  }
}
