import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { IntegrationServerConfigurationComponent } from './integration-server';
import { DocumentIntegrationConfigurationComponent } from './document-integration';
import { PleaseSignComponent } from './document-integration/please-sign/please-sign.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    IntegrationServerConfigurationComponent,
    DocumentIntegrationConfigurationComponent,
    PleaseSignComponent,
  ],
})
export class ConfigurationModule {}
