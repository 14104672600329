import { Address } from './address';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

export class AuditorModel {
  practiceName: string;
  auditorName: string;
  auditorTitle: string;
  auditorEmail: string;
  address: Address;
  number: string;
}

export class AuditorPracticeName {
  address: Address;
}

export function buildAuditorForm(formBuilder: UntypedFormBuilder) {
  return formBuilder.group({
    auditorName: [null, [CustomValidators.relativeToControl('isAuditorStatusEnabled', true, Validators.required), Validators.maxLength(512)]],
    auditorTitle : [null, [CustomValidators.relativeToControl('isAuditorStatusEnabled', true, Validators.required),Validators.maxLength(512)]],
    auditorEmail : [null, [CustomValidators.relativeToControl('isAuditorStatusEnabled', true, Validators.required),Validators.maxLength(512)]],
    practiceName: [null, [CustomValidators.relativeToControl('isAuditorStatusEnabled', true, Validators.required), Validators.maxLength(512)]],
    addressLine1: [null, [CustomValidators.relativeToControl('isAuditorStatusEnabled', true, Validators.required), Validators.maxLength(256)]],
    addressLine2: [null, [Validators.maxLength(256)]],
    addressLine3: [null, [Validators.maxLength(256)]],
    state: [null, [CustomValidators.relativeToControl('isAuditorStatusEnabled', true, Validators.required), Validators.maxLength(128)]],
    suburb: [null, [CustomValidators.relativeToControl('isAuditorStatusEnabled', true, Validators.required), Validators.maxLength(128)]],
    postCode: [null, [CustomValidators.relativeToControl('isAuditorStatusEnabled', true, Validators.required), Validators.maxLength(64)]],
    number: [null, [Validators.maxLength(64)]],
    isAuditorStatusEnabled: [null],
    isAuditorDetailSameAsPractice: [null]
  });
}
