import { DepreciationPool } from '../assets/depreciation-pool';
export class ManagePoolBalances {
  generalPool: ManagePoolBalancesGroup;
  lowValuePool: ManagePoolBalancesGroup;
  constructor(data) {
    this.generalPool = new ManagePoolBalancesGroup(data.generalPool);
    this.lowValuePool = new ManagePoolBalancesGroup(data.lowValuePool);
  }
}

export class ManagePoolBalancesGroup {
  pool: DepreciationPool;
  taxation: ManagePoolBalancesDetail;
  accounting: ManagePoolBalancesDetail;
  locked: boolean;

  constructor(data: ManagePoolBalancesGroup) {
    this.pool = data.pool;

    this.taxation = new ManagePoolBalancesDetail(data.taxation);
    this.accounting = new ManagePoolBalancesDetail(data.accounting);


    this.locked = this.taxation.initialBalance === this.accounting.initialBalance &&
      this.taxation.closingBalance === this.accounting.closingBalance;
  }
}

export class ManagePoolBalancesDetail {

  balanceBeforeDepreciation = 0;
  depreciation = 0;
  initialBalance = 0;
  writeOffs = 0;
  assessableAmount = 0;
  closingBalance = 0;

  error = false;

  constructor(data) {
    this.balanceBeforeDepreciation = data.balanceBeforeDepreciation;
    this.depreciation = data.depreciation;
    this.initialBalance = data.balanceAfterDepreciation;
    if (data.writeOffs < 0) this.writeOffs = -data.writeOffs;
    if (data.writeOffs > 0) this.assessableAmount = data.writeOffs;
    this.closingBalance = data.closingBalance;
    this.error = this.closingBalance !== 0 && data.writeOffs !== 0;
  }
}
