import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlOptions, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RegisterService } from './register.service';
import { Patterns } from '../../shared/validators';

@Component({
  selector: 'crs-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerOnProcess: boolean = true;
  tenantExists: boolean = false;
  userExists: boolean = false;
  invitePending: boolean = false;
  error: string = null;

  constructor(private _formBuilder: UntypedFormBuilder,
    private registerService: RegisterService) { }

  form = this._formBuilder.group({
    name: ['', Validators.required],
    tenantId: ['', Validators.required],
    licenseCount: ['', Validators.required],
    ledger: [true, Validators.required],
    bankFeed: [false, Validators.required],
    user: this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(Patterns.emailRegExp)]]
    })
  }, { validators: this.atLeastOneTrueValidator(['ledger', 'bankFeed']) } as AbstractControlOptions);

  public ngOnInit(): void {
    this.form.valueChanges.pipe(
      map(f => ({ 'tenantId': f.tenantId, 'email': f.user.email })), debounceTime(600), distinctUntilChanged())
      .subscribe(frm => {
        this.registerService.checkTenantId(this.form.value.tenantId)
          .subscribe(res => {
            const formError = res ? { taken: true } : null;
            this.form.controls.tenantId.setErrors(formError);
            this.tenantExists = res;
          }, res => this.tenantExists = false);
        this.registerService.checkOwnerExists(this.form.value.tenantId, this.form.value.user.email)
          .subscribe(result => this.userExists = result, result => this.userExists = false);
        this.registerService.checkInvitePending(this.form.value.tenantId, this.form.value.user.email)
          .subscribe(result => this.invitePending = result, result => this.invitePending = false);
      })
  }

  public register() {
    const reg = {
      name: this.form.value.name,
      tenantId: this.form.value.tenantId,
      firstName: this.form.value.user.firstName,
      lastName: this.form.value.user.lastName,
      email: this.form.value.user.email,
      licenseCount: this.form.value.licenseCount,
      ledger: this.form.value.ledger,
      bankFeed: this.form.value.bankFeed,
    };
    this.registerService.post(reg)
      .subscribe(result => this.registerOnProcess = false, err => this.showError(err));
  }

  public resendInvite() {
    this.registerService.resendInvite(this.form.value.tenantId, this.form.value.user.email)
      .subscribe(result => this.registerOnProcess = false, err => this.showError(err));
  }

  public makeOwner() {
    this.registerService.makeOwner(this.form.value.tenantId, this.form.value.user)
      .subscribe(result => this.registerOnProcess = false, err => this.showError(err));
  }

  public showError(error) {
    this.error = error;
  }

  public startNew() {
    this.form.reset();
    this.error = null;
    this.registerOnProcess = true;
    this.tenantExists = false;
    this.userExists = false;
    this.invitePending = false;
  }

  private atLeastOneTrueValidator(controlNames: string[]): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      for (let controlName of controlNames) {
        if (group.get(controlName)?.value) {
          return null;
        }
      }
      return { atLeastOneTrue: true };
    };
  }
}
