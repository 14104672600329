import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { Classification } from '../../../../accounting';

@Component({
  selector: 'crs-classification-renderer',
  template: '{{ value }}',
})
export class ClassificationRendererComponent
  implements ICellRendererAngularComp
{
  value: string;

  agInit(params: any): void {
    if (!params.value) this.value = null;
    else if (typeof params.value === 'string') this.value = params.value;
    else this.value = Classification[params.value];
  }

  refresh(): boolean {
    return false;
  }
}
