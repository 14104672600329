import { UntypedFormGroup } from '@angular/forms';
import { DepreciationYear } from '../../depreciation-years/depreciation-year';

export class DepreciationRecordFormSet {
  parent: UntypedFormGroup;
  year: DepreciationYear;
  expanded = false;
  taxation: UntypedFormGroup = null;
  accounting: UntypedFormGroup = null;
  parentSubmitted = false;

  constructor(parent: UntypedFormGroup, year: DepreciationYear, taxation: UntypedFormGroup, accounting: UntypedFormGroup) {
    this.parent = parent;
    this.year = year;
    if (taxation) this.taxation = taxation;
    if (accounting) this.accounting = accounting;
  }

}
