import { ColumnType } from '../../../../reportTemplates';
import { Alignment } from '../../report-header/report-header';
import { CellTypeEnum } from '../enums';
import { NumberFormat } from '../../../../enums';
import { TotalDefinition } from '../../../../layout';
import { ReportTableColumnDefinition } from './report-table-column-definition';

export class ReportTableCell {
  constructor(data?: Partial<ReportTableCell>) {
    if (data) Object.assign(this, data);
  }

  valueType: ColumnType;
  alignment: Alignment = Alignment.Center;
  numberFormat: NumberFormat;
  rounding: number;
  textUnderline: boolean;
  textBold: boolean;
  textItalic: boolean;
  colSpan: number;
  cellValue: string | number;
  cellType: CellTypeEnum = null;
  parentColumn: ReportTableColumnDefinition;

  totalDefinition: TotalDefinition;
  elementId: string;
  elementIdType: number;
  
  get isPercent(): boolean {
    return this.numberFormat === NumberFormat.Percent || this.numberFormat === NumberFormat.FavourableUnfavourablePercent;
  }

}
