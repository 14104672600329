import { Injectable, OnDestroy } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Dataset } from '../../datasets/dataset';
import { Division } from '../../setup/divisions/division';
import { TradingAccount } from '../../setup/tradingAccounts/tradingAccount';
import { HeaderTemplate } from './headerTemplate';
import { FooterTemplate } from './footerTemplate';
import { ReportTemplateService } from './report-template.service';
import { ActiveFileService } from '../../active-file.service';
import { Subscription } from 'rxjs';
import { ReportingSuite } from '../notes/reportingSuites';

@Injectable({
  providedIn: 'root'
})
export class ReportTemplateDataService implements OnDestroy {

  private _fileId: string;
  private _fileSubscription: Subscription;

  datasets: Dataset[] = [];
  divisions: Division[] = [];
  tradingAccounts: TradingAccount[] = [];
  headers: HeaderTemplate[] = [];
  footers: FooterTemplate[] = [];
  defaultReportingSuite: ReportingSuite;

  constructor(private readonly _fileService: ActiveFileService,
    private readonly _reportTemplateService: ReportTemplateService) {
    this._fileSubscription = this._fileService.stream.subscribe(file => this._fileId = file.id);
  }

  refresh() {
    return this._reportTemplateService.getPackage(this._fileId).pipe(tap(response => {
      this.datasets = response.datasets;
      this.divisions = response.divisions;
      this.tradingAccounts = response.tradingAccounts;
      this.headers = response.headers;
      this.footers = response.footers;
      this.defaultReportingSuite = response.defaultReportingSuite;
    }));
  }

  ngOnDestroy(): void {
    if (this._fileSubscription) this._fileSubscription.unsubscribe();
  }

}
