<div class="modal-header" *ngIf="_showPleaseSignSendingModel">
  <h4 class="modal-title">PleaseSign</h4>
  <button aria-label="Close" class="close" type="button" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<crs-busy *ngIf="busy.load"></crs-busy>

<form novalidate *ngIf="_showPleaseSignSendingModel" [formGroup]="form">
  <div class="modal-body">
    <div class="form-section">
      <h5 class="section-title text-primary">Message Details</h5>
      <div
        class="form-group row required justify-content-end"
        [errorsWidth]="'col-9'"
      >
        <label class="col-md-3"><b>Message Title</b></label>
        <div class="col-md-9">
          <input
            class="form-control"
            formControlName="messageTitle"
            type="text"
          />
        </div>
      </div>

      <div class="form-group row justify-content-end" [errorsWidth]="'col-9'">
        <label class="col-md-3"><b>Message</b></label>
        <div class="col-md-9">
          <textarea
            class="form-control"
            formControlName="message"
            rows="3"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <label class="col-md-3"><b>Document</b></label>
        <div class="col-md-9">{{ params.title }}</div>
      </div>
      <div class="row">
        <label class="col-md-3"><b>Operation</b></label>
        <div class="col-md-9">
          <input
            formControlName="sendAsDraft"
            id="inlineFormCheck"
            type="checkbox"
          />
          <label
            for="inlineFormCheck"
            ngbTooltip="this allows you to review the document in PleaseSign before sending. The document will be sent directly to the recipient if unchecked."
            style="padding-left: 10px"
            >Save as draft only (check and send in PleaseSign)</label
          >
        </div>
      </div>
    </div>

    <div class="form-section required" *ngIf="_isClientSignaturesRequired">
      <label class="section-title text-primary">Client</label>
      <div formArrayName="clients">
        <div *ngFor="let control of clients?.controls; let i = index">
          <div class="row" [formGroupName]="i">
            <div class="col-md-6 form-group required">
              <div class="input-group me-2 mb-2 flex-grow-1">
                <input
                  class="form-control"
                  formControlName="fullname"
                  placeholder="FirstName LastName"
                  type="text"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i aria-hidden="true" class="fas fa-user fas-btn"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 form-group required">
              <div class="input-group me-2 mb-2 flex-grow-1">
                <input
                  class="form-control"
                  formControlName="email"
                  placeholder="Email"
                  type="email"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i aria-hidden="true" class="fas fa-envelope fas-btn"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="form-section required"
      *ngIf="_isPartnerSignaturesRequired || _isAuditorSignaturesRequired"
    >
      <label class="section-title text-primary">Partner</label>
      <div formArrayName="partners" *ngIf="_isPartnerSignaturesRequired">
        <div *ngFor="let control of partners?.controls; let i = index">
          <div class="row" [formGroupName]="i">
            <div class="col-md-6 form-group required">
              <div class="input-group me-2 mb-2 flex-grow-1">
                <input
                  class="form-control"
                  formControlName="fullname"
                  placeholder="FirstName LastName"
                  type="text"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i aria-hidden="true" class="fas fa-user fas-btn"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 form-group required">
              <div class="input-group me-2 mb-2 flex-grow-1">
                <input
                  class="form-control"
                  formControlName="email"
                  placeholder="Email"
                  type="email"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i aria-hidden="true" class="fas fa-envelope fas-btn"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div formArrayName="auditors" *ngIf="_isAuditorSignaturesRequired">
        <div *ngFor="let control of auditors?.controls; let i = index">
          <div class="row" [formGroupName]="i">
            <div class="col-md-6 form-group required">
              <div class="input-group me-2 mb-2 flex-grow-1">
                <input
                  class="form-control"
                  formControlName="fullname"
                  placeholder="FirstName LastName"
                  type="text"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i aria-hidden="true" class="fas fa-user fas-btn"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 form-group required">
              <div class="input-group me-2 mb-2 flex-grow-1">
                <input
                  class="form-control"
                  formControlName="email"
                  placeholder="Email"
                  type="email"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i aria-hidden="true" class="fas fa-envelope fas-btn"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-section required" *ngIf="_isOtherSignaturesRequired">
      <label class="section-title text-primary">Others</label>
      <div formArrayName="others">
        <div *ngFor="let control of others?.controls; let i = index">
          <div class="row" [formGroupName]="i">
            <div class="col-md-6 form-group required">
              <div class="input-group me-2 mb-2 flex-grow-1">
                <input
                  class="form-control"
                  formControlName="fullname"
                  placeholder="FirstName LastName"
                  type="text"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i aria-hidden="true" class="fas fa-user fas-btn"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 form-group required">
              <div class="input-group me-2 mb-2 flex-grow-1">
                <input
                  class="form-control"
                  formControlName="email"
                  placeholder="Email"
                  type="email"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i aria-hidden="true" class="fas fa-envelope fas-btn"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-md-3 text-primary">Requestor</label>
      <div class="col-md-9">
        {{ reporter?.firstName }} {{ reporter?.lastName }}
      </div>
    </div>

    <div class="row">
      <label class="col-md-10 text-primary"
        >PleaseSign User Account Linked to Requestor Email Address</label
      >
      <div class="col-md-2">
        {{ _userPleaseSignStatus }}
      </div>
    </div>

    <crs-form-error [error]="error" (errorDismissed)="error = null" />

    <div class="modal-footer">
      <button
        class="btn btn-primary"
        type="submit"
        [promiseBtn]="busy.submit"
        (click)="submit()"
      >
        Send Request
      </button>
      <button class="btn btn-secondary" type="button" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</form>
