import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, EMPTY } from 'rxjs';
import {
  tap,
  catchError,
  exhaustMap,
  finalize,
  switchMap,
} from 'rxjs/operators';

import {
  DisclosureTemplate,
  DisclosureTemplateService,
  DisclosureLevel,
} from '../';
import { MessageService } from '../../../../../core';
import {
  getDefaultGridOptions,
  ProgressBarService,
  NgProgressRef,
} from '../../../../../shared';
import {
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDragEndEvent,
  RowDragLeaveEvent,
  RowDragMoveEvent,
} from 'ag-grid-community';

enum Interaction {
  Move,
  Add,
}

class InteractionData {
  interaction: Interaction;
  moveId: string;
  moveIndex: number;
}

@Component({
  selector: 'crs-firm-disclosure-templates',
  templateUrl: './firm-disclosure-templates.component.html',
})
export class FirmDisclosureTemplatesComponent implements OnInit {
  error: string;

  busy = {
    load: false,
    interactionPending: false,
  };
  disclosuresObservable: Observable<any>;

  gridOptions: GridOptions;
  gridApi: GridApi;

  sortActive: boolean;
  filterActive: boolean;

  disclosures: DisclosureTemplate[] = [];
  pendingDisclosures: DisclosureTemplate[] = [];

  progress: NgProgressRef;

  interactionStream = new Subject<InteractionData>();

  isMaster(params) {
    return !!params.data && params.data.level === 0;
  }

  constructor(
    private readonly _disclosureService: DisclosureTemplateService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _messageService: MessageService,
    private readonly _progressBar: ProgressBarService
  ) {}

  ngOnInit() {
    this.getDisclosures();
    this.configureGridOptions();
    this.configureInteractionStream();
    this.progress = this._progressBar.ref('gridLoadingBar');
  }

  getDisclosures() {
    this.busy.load = true;
    this._disclosureService
      .getAll()
      .pipe(
        tap((disclosures) => {
          this.busy.load = false;
          this.disclosures = disclosures;
        }),
        catchError((err) => {
          this.busy.load = false;
          this.showError(err);
          return of([]);
        })
      )
      .subscribe();
  }

  addDisclosure() {
    this._router.navigate(['./firm/add', { level: DisclosureLevel.Firm }], {
      relativeTo: this._route,
    });
  }

  showError(error) {
    this.error = error;
    this._messageService.error(error);
  }

  selectDisclosure(param) {
    if (!param.data) return;
    this._router.navigate(
      ['./firm/' + param.data.id, { level: DisclosureLevel.Firm }],
      { relativeTo: this._route }
    );
  }

  configureInteractionStream() {
    this.interactionStream
      .pipe(
        tap(() => {
          this.error = null;
        }),
        exhaustMap((param) => this.handleInteraction(param))
      )
      .subscribe();
  }

  handleInteraction(param: InteractionData): Observable<any> {
    let observable: Observable<any> = EMPTY;

    if (param.interaction === Interaction.Add) {
      this.addDisclosure();
      return EMPTY;
    }

    if (param.interaction === Interaction.Move) {
      this.progress.start();
      observable = this._disclosureService
        .reorderFirmDisclosureTemplate(param.moveId, param.moveIndex + 1)
        .pipe(
          switchMap(() => this._disclosureService.getAll()),
          tap((d) => {
            this.disclosures = d;
            this.gridApi.setGridOption('rowData', this.disclosures);
            this.pendingDisclosures = null;
          }),
          catchError((err) => {
            this.pendingDisclosures = null;
            this.gridApi.setGridOption('rowData', this.disclosures);
            this.showError(err);
            return EMPTY;
          })
        );
    }

    this.busy.interactionPending = true;
    return observable.pipe(
      catchError((err) => {
        this.showError(err);
        return EMPTY;
      }),
      finalize(() => {
        this.progress.complete();
        this.busy.interactionPending = false;
      })
    );
  }

  private configureGridOptions() {
    this.gridOptions = {
      ...getDefaultGridOptions(),
      getRowId: (params) => params.data.id,
      onRowDragMove: (param) => this.onRowDragMove(param),
      onRowDragLeave: (param) => this.onRowDragLeave(param),
      onRowDragEnd: (param) => this.onRowDragEnd(param),
      columnDefs: [
        {
          cellRenderer: 'badgeRenderer',
          field: 'name',
          headerName: 'Name',
          cellRendererParams: { badgeText: 'New', monthsToCheck: 3 },
          rowDrag: true,
        },
        {
          field: 'level',
          headerName: 'Master',
          type: 'booleanColumn',
          cellRendererParams: { iconClass: 'fas fa-lock' },
          maxWidth: 100,
          minWidth: 100,
          valueGetter: this.isMaster.bind(this),
        },
      ],
    };
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  onRowDragMove(event: RowDragMoveEvent) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);
  }

  onRowDragLeave(event: RowDragLeaveEvent) {
    this.pendingDisclosures = this.disclosures;
    event.api.setGridOption('rowData', this.pendingDisclosures);
  }

  onRowDragEnd(event: RowDragEndEvent) {
    if (this.busy.interactionPending) return;
    this.executeUIMove(event);

    const param = new InteractionData();
    param.interaction = Interaction.Move;
    param.moveId = event.node.data.id;
    param.moveIndex = event.overNode.childIndex;
    this.interactionStream.next(param);
  }

  executeUIMove(event: RowDragMoveEvent | RowDragEndEvent) {
    const movingNode = event.node;
    const overNode = event.overNode;
    const rowNeedsToMove =
      movingNode !== overNode &&
      overNode.data &&
      movingNode.data.location === overNode.data.location;
    if (rowNeedsToMove) {
      const fromIndex = this.disclosures.indexOf(movingNode.data);
      const toIndex = this.disclosures.indexOf(overNode.data);
      this.pendingDisclosures = this.disclosures.slice();
      moveInArray(this.pendingDisclosures, fromIndex, toIndex);
      event.api.setGridOption('rowData', this.pendingDisclosures);
      event.api.clearFocusedCell();
    }
    function moveInArray(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  }
}
