import { Component, Input } from '@angular/core';

@Component({
  selector: 'crs-error-view',
  templateUrl: './error-view.component.html',
})
export class ErrorViewComponent {
  @Input() title: string = 'Error';
  @Input() message: string;
  @Input() showReturn: boolean = false;
  @Input() logoSize: 'md' | 'lg' = 'md';

  constructor() {}
}
