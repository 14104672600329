export enum ElementSizeMultiplier {
  'auto' = 0,
  '0.5x' = 1,
  '1x' = 2,
  '1.5x' = 3,
  '2x' = 4,
  '3x' = 5,
  '4x' = 6,
  '5x' = 7
}

const totalWidth = 1000;

export function getSize(sizeX: ElementSizeMultiplier, unitSize: number, nonAutoColumnWidthSum: number = 0, autoColumnCount: number = 0) {
  let size = unitSize;
  switch (sizeX) {
    case ElementSizeMultiplier['0.5x']:
      size = unitSize / 2;
      break;

    case ElementSizeMultiplier['1x']:
      size = unitSize;
      break;

    case ElementSizeMultiplier['1.5x']:
      size = unitSize * 1.5;
      break;

    case ElementSizeMultiplier['2x']:
      size = unitSize * 2;
      break;
    case ElementSizeMultiplier['3x']:
      size = unitSize * 3;
      break;
    case ElementSizeMultiplier['4x']:
      size = unitSize * 4;
      break;
    case ElementSizeMultiplier['5x']:
      size = unitSize * 5;
      break;
    case ElementSizeMultiplier['auto']:
      let autoColumnsWidthSum = totalWidth - nonAutoColumnWidthSum;
      size = autoColumnsWidthSum / autoColumnCount;
      break;
  }
  return size;
}