import { DateHelpers } from 'src/app/shared/utilities/date-helpers';

export class ReportingSuite {
  id: number;
  name: string;
  reportingFramework: string;
  description: string;
  isMaster: boolean;
  applicationDate: Date;
  earliestApplicationDate: Date;
  expiryDate: Date;
  policyVariants: any[];
  disclosureVariants: any[];

  constructor(data) {
    Object.assign(this, data);
    if (typeof data.id === 'string') this.id = parseInt(data.id, 10);
    this.applicationDate = DateHelpers.dateFromApiResponse(data.applicationDate);
    this.earliestApplicationDate = DateHelpers.dateFromApiResponse(data.earliestApplicationDate);
    this.expiryDate = DateHelpers.dateFromApiResponse(data.expiryDate);
  }
}

export class ReportingSuiteModel {
  id: number;
  name: string;
  reportingFramework: string;
  description: string;
  isMaster: boolean;
  applicationDate: Date;
  earliestApplicationDate: Date;
  expiryDate: Date;
  policyVariants: any[];
  disclosureVariants: any[];

  constructor(data, policyVariants: any[], disclosureVariants: any[]) {
    Object.assign(this, data);
    if (policyVariants) {
      this.policyVariants = policyVariants.map(v => {
        return {
          policyVariantId: v.id,
          isDefault: v.isDefault
        };
      });
    }
    if (disclosureVariants) {
      this.disclosureVariants = disclosureVariants.map(v => {
        return {
          disclosureVariantId: v.id,
          isDefault: v.isDefault
        };
      });
    }
  }
}
