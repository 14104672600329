import { ErrorMessage } from './Models/ErrorMessage';

export const DEFAULT_ERRORS: ErrorMessage[] = [
  {
    error: 'required',
    format: (label) => `${label} is required`,
  },
  {
    error: 'pattern',
    format: (label) => `${label} is invalid`,
  },
  {
    error: 'minlength',
    format: (label, error) =>
      `${label} must be at least ${error.requiredLength} characters`,
  },
  {
    error: 'maxlength',
    format: (label, error) =>
      `${label} must be no longer than ${error.requiredLength} characters`,
  },
  {
    error: 'requiredTrue',
    format: (label, error) => `${label} is required`,
  },
  {
    error: 'email',
    format: (label, error) => `Invalid email address`,
  },
  {
    error: 'max',
    format: (label, error) => `${label} must be no greater than ${error.max}`,
  },
  {
    error: 'min',
    format: (label, error) => `${label} must be no less than ${error.min}`,
  },
  {
    error: 'invalidBusinessNumber',
    format: (label, error) => `${label} is not a valid ${label}`,
  },
  {
    error: 'passPhrase',
    format: (label, error) => `${label} is not the correct phrase`,
  },
  {
    error: 'invalidDateFormat',
    format: (label, error) => `${label} is not a valid date`,
  },
  {
    error: 'taken',
    format: (label, error) => `This value has already been taken`,
  },
  {
    error: 'mindate',
    format: (label, error) => `${label} must be after ${error.date}`,
  },
  {
    error: 'maxdate',
    format: (label, error) => `${label} must be before ${error.date}`,
  },
  {
    error: 'duplicateTaxCode',
    format: (label, error) => `Not a unique tax code`,
  },
  {
    error: 'invalidPhoneNumber',
    format: (label, error) =>
      `${label} is invalid. The phone number must include the country code eg +61... `,
  },
];
