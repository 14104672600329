import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'crs-customized-confirmation',
  templateUrl: './customized-confirmation.component.html',
  styleUrls: ['./customized-confirmation.component.scss'],
})
export class CustomizedConfirmationComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() additionalInfoText: string;
  @Input() passPhrase: string = null;
  @Input() danger = false;
  @Input() showUnderstandConsequencesSuffix = true;
  @Input() alertText: string;
  @Input() alertClass:
    | 'alert-danger'
    | 'alert-warning'
    | 'alert-info'
    | 'alert-success';

  @Input() approveBtn: string;
  @Input() cancelBtn: string;

  form = this.formBuilder.group({
    passPhrase: [null],
  });
  passPhraseHtml: string;

  @Input() cancelAction: () => any = () => console.log('do something');
  @Input() approveAction: () => any = () => console.log('do something');

  public get approveBtnName(): string {
    return `${this.approveBtn ?? 'Yes'}${
      this.danger && this.showUnderstandConsequencesSuffix
        ? ', I understand the consequences'
        : ''
    }`;
  }

  public get cancelBtnName(): string {
    return this.cancelBtn ?? 'Cancel';
  }
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    if (this.passPhrase) {
      this.passPhraseHtml = this.encodePassPhrase(this.passPhrase);
      this.form.controls['passPhrase'].setValidators([
        Validators.required,
        passPhraseValidator(this),
      ]);
    }
  }

  confirm() {
    if (this.passPhrase) {
      this.form.markAsTouched();
      if (!this.form.valid) return;
    }
    this.activeModal.close(this.approveAction());
  }

  close() {
    this.activeModal.close(this.cancelAction());
  }

  private encodePassPhrase(str) {
    str = str.toString();
    return (
      '<span>' +
      str
        .split('')
        .map(function (c) {
          if (c === ' ') return '&nbsp;';
          else return c;
        })
        .join('') +
      '</span>'
    );
  }
}

function passPhraseValidator(
  component: CustomizedConfirmationComponent
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!component.passPhrase) return null;
    const phrase = component.passPhrase.toString();
    if (control.value && control.value !== phrase) {
      return { passPhrase: true };
    }
    return null;
  };
}
