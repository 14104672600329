<div style="position: relative">
  <i
    aria-hidden="true"
    class="fas-btn-primary-ghost"
    [class.disabled]="disabled"
    [ngClass]="iconClass"
    [style.pointer-events]="disabled ? 'none' : 'auto'"
  >
  </i>
  <span class="badge" *ngIf="count > 0">{{ count }}</span>
</div>
